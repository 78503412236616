import React from 'react';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import {
  List,
  ListItem,
  Card,
  CardContent,
  Typography,
  CardActions,
  Grid,
  IconButton,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import useSoteriaStore from 'hooks/useSoteriaStore';
import useHelpAndSupport from 'hooks/useHelpAndSupport';
import useWhatsNew from 'hooks/useWhatsNew';
import StyledButtonSecondary from 'shared/Buttons/ButtonSecondary';
import packageJson from '../../../../../package.json';

global.appVersion = packageJson.version;

const useStyles = makeStyles(theme => ({
  container: { width: 300, padding: theme.spacing(2) },
  drawerHeaderContainer: { paddingBottom: theme.spacing(1.5) },
  whatsNewDrawerTitle: { fontSize: '1.125rem', fontWeight: 'bold' },
  closeDrawerIcon: { fontSize: '1.25rem' },
  listItem: { padding: 0, paddingBottom: theme.spacing(2) },
  card: {
    backgroundColor: theme.palette.background.default,
    width: 268,
    boxShadow: 'none'
  },
  cardTitle: { fontWeight: 'bold', fontSize: '0.875rem' },
  cardDescription: { fontSize: '0.75rem' },
  version: {
    marginTop: 10,
    fontSize: '0.875rem'
  }
}));

const WhatsNewList = () => {
  const classes = useStyles();
  const { t: translate } = useTranslation();
  const { setStore } = useSoteriaStore();
  const { toggleHelp } = useHelpAndSupport();
  const { whatsNewItems } = useWhatsNew();
  const currentVersion = global.appVersion;

  const handleClose = () => {
    setStore({ whatsNewIsOpen: false });
  };

  return (
    <Grid container className={classes.container} data-testid="whats-new-list">
      <Grid item xs={12}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          className={classes.drawerHeaderContainer}>
          <Grid item>
            <Grid container direction="column">
              <Grid item>
                <Typography
                  color="textPrimary"
                  className={classes.whatsNewDrawerTitle}>
                  {translate('whatsNewContent.title')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.version}>
                  {translate('whatsNewContent.version', {
                    versionNumber: currentVersion
                  })}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <IconButton onClick={handleClose} data-testid="close-whats-new" size="large">
              <CloseIcon className={classes.closeDrawerIcon} />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <List>
          {whatsNewItems.map((item, index) => (
            <ListItem key={index} className={classes.listItem}>
              <Card className={classes.card}>
                <CardContent>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    spacing={1}>
                    {item.icon && (
                      <Grid item>
                        <item.icon />
                      </Grid>
                    )}
                    <Grid item>
                      <Typography className={classes.cardTitle}>
                        {item.title}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography className={classes.cardDescription}>
                    {item.content}
                  </Typography>
                </CardContent>
                {'tutorial' in item && (
                  <CardActions>
                    <StyledButtonSecondary
                      label="Learn More"
                      onClick={() => toggleHelp(true, item.tutorial)}
                    />
                  </CardActions>
                )}
              </Card>
            </ListItem>
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default WhatsNewList;
