import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Fuse from 'fuse.js';

import useSoteriaQuery from 'hooks/useSoteriaQuery';
import StyledAutoComplete from 'shared/AutoComplete';
import { GET_ALL_PROJECTS } from 'graphql/project';
import { SOTERIA_FETCH_POLICIES } from 'constants/soteriaFetchPolicies';

const getFilteredOptions = (options, inputValue) => {
  if (inputValue) {
    const fuse = new Fuse(options, fuseOptions);
    return fuse.search(inputValue);
  } else {
    return options
      .map((option, index) => ({
        item: option,
        score: 1,
        refIndex: index
      }))
      .sort((a, b) => a.item.number - b.item.number);
  }
};

const fuseOptions = {
  threshold: 0.0,
  isCaseSensitive: false,
  includeScore: true,
  shouldSort: true,
  keys: ['number', 'name']
};

const ProjectSelect = ({
  selectedProject,
  handleChange,
  isDisabled = false,
  isRequired = false,
  placeholder,
  customError,
  ...rest
}) => {
  const [inputValue, handleInputChange] = useState('');

  const { data, loading: isLoading } = useSoteriaQuery({
    gql: GET_ALL_PROJECTS,
    soteriaQueryOptions: {
      soteriaFetchPolicy: SOTERIA_FETCH_POLICIES.onlineOnly
    }
  });

  const projectOptions = data?.projects ? data.projects : [];

  const filteredOptions = projectOptions
    ? getFilteredOptions(projectOptions, inputValue)
    : [];

  return (
    <StyledAutoComplete
      selectedValue={selectedProject}
      handleChange={handleChange}
      placeholder={placeholder}
      isRequired={isRequired}
      isDisabled={isDisabled}
      options={filteredOptions.map(option => ({
        label: `${option.item.number} (${option.item.name})`,
        value: option.item.number,
        ...option.item
      }))}
      isLoading={isLoading}
      handleInputChange={handleInputChange}
      customError={customError}
      {...rest}
    />
  );
};

ProjectSelect.propTypes = {
  isDisabled: PropTypes.bool,
  selectedProject: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  isRequired: PropTypes.bool,
  placeholder: PropTypes.node.isRequired,
  customError: PropTypes.string
};

export default ProjectSelect;
