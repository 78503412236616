import moment from 'moment';
import cloneDeep from 'clone-deep';
import { formatDate } from 'utils/dateTime';

export const getTrainingDisplayName = (
  name,
  otherName,
  FORMATTED_TRAINING_NAMES
) => {
  if (otherName && name === 'Other') {
    return otherName;
  } else if (name === 'BoomLift') {
    return FORMATTED_TRAINING_NAMES.boomLift;
  } else if (name === 'ConfinedSpace') {
    return FORMATTED_TRAINING_NAMES.confinedSpace;
  } else if (name === 'FallProtection') {
    return FORMATTED_TRAINING_NAMES.fallProtection;
  } else if (name === 'ScissorLift') {
    return FORMATTED_TRAINING_NAMES.scissorLift;
  } else if (name === 'ForkLift') {
    return FORMATTED_TRAINING_NAMES.forkLift;
  }

  return name;
};

// helper function to determine if the training is expired
export const getRowStatus = expirationDate => {
  if (expirationDate === null) {
    return {
      isExpired: false,
      isExpiringSoon: false,
      numberOfDaysUntilExpiration: null
    };
  }

  const todaysDate = moment(new Date(), 'MM/DD/YYYY');
  const formattedExpirationDate = moment(expirationDate, 'MM/DD/YYYY');

  const isExpired = moment(formattedExpirationDate).isSameOrBefore(todaysDate);

  const thirtyDaysBeforeExpiration = moment(
    formattedExpirationDate,
    'MM/DD/YYYY'
  ).add(-30, 'days');

  const isExpiringSoon = moment().isBetween(
    thirtyDaysBeforeExpiration,
    formattedExpirationDate
  );

  const numberOfDaysUntilExpiration = moment(
    formattedExpirationDate,
    'MM/DD/YYYY'
  ).diff(new moment(), 'days');

  return {
    isExpired,
    isExpiringSoon,
    numberOfDaysUntilExpiration
  };
};

// helper function to get row classname based on status
export const getStatusClassName = (expirationDate, classes) => {
  const rowStatus = getRowStatus(expirationDate);

  if (rowStatus.isExpired) {
    return classes.expiredRow;
  } else if (rowStatus.isExpiringSoon) {
    return classes.expiringSoon;
  }
  return;
};

// helper function to pre-format table data to match column structure in order to make sorting easier
export const getColumnFormattedTrainingData = (
  trainingData,
  FORMATTED_TRAINING_NAMES
) => {
  const tradePartnerPersonnelCopy = cloneDeep(trainingData ?? []);
  if (tradePartnerPersonnelCopy.length > 0) {
    return tradePartnerPersonnelCopy.map(training => {
      return {
        id: training.id,
        name: training.name,
        displayName: getTrainingDisplayName(
          training.name,
          training.otherName,
          FORMATTED_TRAINING_NAMES
        ),
        otherName: training.otherName,
        awardedDate: formatDate(training.awardedDate),
        expirationDate:
          training.expirationDate === null
            ? null
            : formatDate(training.expirationDate),
        files: training.files,
        notes: training.notes,
        status: getRowStatus(formatDate(training.expirationDate))
      };
    });
  } else {
    return tradePartnerPersonnelCopy;
  }
};

export const openTrainingDialog = async (
  rowData,
  actionType,
  setIndividualTrainingData,
  setTrainingDialogView,
  toggleTrainingDialog
) => {
  if (rowData.length > 0) {
    const [
      id,
      ,
      otherName,
      awardedDate,
      expirationDate,
      ,
      notes,
      fileObjects,
      ,
      nameField
    ] = rowData;

    const chooseOtherName = () => {
      if (nameField === 'Other' && otherName && otherName !== '') {
        return otherName;
      }
      return null;
    };

    const chooseExpirationDate = () => {
      if (expirationDate.props.children.props.children === 'Never Expires') {
        return null;
      }
      return expirationDate.props.children.props.children;
    };
    setIndividualTrainingData({
      id,
      name: nameField,
      otherName: chooseOtherName(),
      awardedDate: awardedDate.props.children.props.children,
      expirationDate: chooseExpirationDate(),
      notes,
      updatedFiles: fileObjects,
      files: []
    });
  } else {
    setIndividualTrainingData(null);
  }

  setTrainingDialogView(actionType);
  toggleTrainingDialog(true);
};

export const closeTrainingDialog = (
  toggleTrainingDialog,
  setTrainingDialogView
) => {
  toggleTrainingDialog(false);
  setTrainingDialogView(null);
};

// custom sorting function for training status column
export const getSortedTrainingStatuses = (
  object1,
  object2,
  order,
  TRAINING_STATUSES
) => {
  const getStatusName = data => {
    if (data.isExpiringSoon) {
      return TRAINING_STATUSES.expiringSoon;
    } else if (data.isExpired) {
      return TRAINING_STATUSES.expired;
    } else {
      return TRAINING_STATUSES.current;
    }
  };

  let val1 = getStatusName(object1.data);
  let val2 = getStatusName(object2.data);

  if (order === 'asc') {
    if (
      val1 === TRAINING_STATUSES.current &&
      val2 !== TRAINING_STATUSES.current
    ) {
      return -1;
    }

    if (
      val1 === TRAINING_STATUSES.expiringSoon &&
      val2 === TRAINING_STATUSES.expired
    ) {
      return -1;
    }

    if (
      val1 === TRAINING_STATUSES.expired &&
      val2 === TRAINING_STATUSES.expiringSoon
    ) {
      return 1;
    }
  }

  if (order === 'desc') {
    if (
      val1 !== TRAINING_STATUSES.current &&
      val2 === TRAINING_STATUSES.current
    ) {
      return -1;
    }
    if (
      val1 === TRAINING_STATUSES.expiringSoon &&
      val2 === TRAINING_STATUSES.expired
    ) {
      return 1;
    }

    if (
      val1 === TRAINING_STATUSES.expired &&
      val2 === TRAINING_STATUSES.expiringSoon
    ) {
      return -1;
    }
  }
};

// custom sorting function for training awarded and expiration date columns
export const getSortedDates = (object1, object2, order) => {
  if (order === 'asc') {
    if (object1.data === null) {
      return -1;
    }

    if (object2.data === null) {
      return 1;
    }

    if (moment(object1.data).isBefore(object2.data, 'day')) {
      return 1;
    } else {
      return -1;
    }
  }

  if (order === 'desc') {
    if (object1.data === null) {
      return 1;
    }

    if (object2.data === null) {
      return -1;
    }

    if (moment(object1.data).isBefore(object2.data, 'day')) {
      return -1;
    } else {
      return 1;
    }
  }
};
