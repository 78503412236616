import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { useHistory } from 'react-router-dom';

import { GET_SOTERIA_USER } from 'graphql/soteriaAdUser';
import NoProjectsNotice from 'components/project/NoProjectsNotice';
import LoadingSpinner from 'components/common/LoadingSpinner';
import useSoteriaQuery from 'hooks/useSoteriaQuery';

const getUniqueProjectIds = projects => {
  return Array.from(new Set(projects.map(project => project.id)));
};

export const LandingPage = () => {
  const [queryWasExecuted, setQueryWasExecuted] = useState(false);
  const {
    data: {
      currentSoteriaAdUser: {
        projects: projectsAsAdmin,
        safeProjects: projectsAsPersonnelOnProject,
        safeProjectsAsPartner: projectsAsPartnerOnProject
      } = {}
    } = {}
  } = useSoteriaQuery({
    gql: GET_SOTERIA_USER,
    queryHookOptions: {
      onCompleted: data => {
        if (data?.currentSoteriaAdUser) {
          setQueryWasExecuted(true);
        }
      }
    }
  });

  const history = useHistory();
  const pathSuffix = history.location.pathname.includes('observations-ai-poc')
    ? 'observations-ai-poc'
    : 'dashboard';
  const LAST_PROJECT_KEY = 'lastProjectVisited';

  const lastVisitedProject = JSON.parse(
    localStorage.getItem(LAST_PROJECT_KEY)
  ) ?? { projectId: null };

  const allProjectIds =
    !!projectsAsAdmin &&
    !!projectsAsPersonnelOnProject &&
    !!projectsAsPartnerOnProject
      ? getUniqueProjectIds([
          ...projectsAsAdmin,
          ...projectsAsPersonnelOnProject,
          ...projectsAsPartnerOnProject
        ])
      : null;

  useEffect(() => {
    if (queryWasExecuted && !!allProjectIds && allProjectIds.length > 0) {
      if (lastVisitedProject.projectId) {
        if (allProjectIds.includes(lastVisitedProject.projectId)) {
          history.push(
            `/projects/${lastVisitedProject.projectId}/${pathSuffix}`
          );
          return;
        } else {
          localStorage.removeItem('lastProjectVisited');
        }
      } else {
        history.push(`/projects/${allProjectIds[0]}/${pathSuffix}`);
      }
    }
  }, [allProjectIds, history, lastVisitedProject.projectId, queryWasExecuted]);

  if (allProjectIds && allProjectIds.length < 1) {
    return <NoProjectsNotice />;
  } else {
    return <LoadingSpinner />;
  }
};

LandingPage.propTypes = {
  history: PropTypes.any.isRequired
};

export default withRouter(LandingPage);
