import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { useHistory, useParams } from 'react-router-dom';
import { Grid, Card, Typography } from '@mui/material';

import useToast from 'hooks/useToast';
import StyledDialog from 'shared/Dialog';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import StyledButtonSecondary from 'shared/Buttons/ButtonSecondary';
import StyledNotice from 'shared/Notice';
import StyledConfirmationSwitch from 'shared/ConfirmationSwitch';
import {
  DELETE_TRADE_PARTNER_PERSONNEL,
  GET_ALL_TRADE_PARTNER_PERSONNEL_FOR_PROJECT,
  GET_RELATION_COUNTS_FOR_TPP
} from 'graphql/tradePartnerPersonnel';

const TradePartnerPersonnelDeleteCard = ({ tradePartnerPersonnel }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { projectId } = useParams();
  const [dialogIsOpen, toggleDialog] = useState(false);
  const [isConfirmed, toggleConfirmSwitch] = useState(false);
  const { displayToast } = useToast();

  const [
    deleteTradePartnerPersonnel,
    { loading: deleteTradePartnerLoading }
  ] = useMutation(DELETE_TRADE_PARTNER_PERSONNEL, {
    update(cache, { data: { deleteTradePartnerPersonnel } }) {
      let result = null;
      try {
        result = cache.readQuery({
          query: GET_ALL_TRADE_PARTNER_PERSONNEL_FOR_PROJECT,
          variables: { projectId }
        });
      } catch (err) {
        // https://github.com/apollographql/apollo-feature-requests/issues/1
      }
      if (result?.tradePartnerPersonnelsOnProject) {
        cache.writeQuery({
          query: GET_ALL_TRADE_PARTNER_PERSONNEL_FOR_PROJECT,
          variables: { projectId },
          data: {
            tradePartnerPersonnelsOnProject: result.tradePartnerPersonnelsOnProject.filter(
              tradePartnerPersonnel =>
                tradePartnerPersonnel.id !== deleteTradePartnerPersonnel.id
            )
          }
        });
      }
    }
  });

  const [
    getRelationCounts,
    {
      data: {
        relationCountsForTradePartnerPersonnel: {
          observationAsObservedPerson: countObservationsAsObservedPerson,
          observationAsSupervisor: countObservationsAsSupervisor,
          observationAsOriginator: countObservationsAsOriginator,
          safetyHours: countSafetyHours
        }
      } = { relationCountsForTradePartnerPersonnel: {} },
      loading: relationCountsLoading
    }
  ] = useLazyQuery(GET_RELATION_COUNTS_FOR_TPP, {
    fetchPolicy: 'no-cache'
  });

  const isLoading = deleteTradePartnerLoading;
  const isLazyLoading = isLoading || relationCountsLoading;

  const handleOpen = () => {
    getRelationCounts({
      variables: { id: tradePartnerPersonnel.id }
    });
    toggleDialog(true);
    toggleConfirmSwitch(false);
  };

  const handleClose = () => {
    toggleDialog(false);
    toggleConfirmSwitch(false);
  };

  const handleSubmit = () => {
    deleteTradePartnerPersonnel({
      variables: { id: tradePartnerPersonnel.id }
    })
      .then(() => {
        displayToast(
          t('tradePartnerPersonnelDeleteCard.toasts.success', {
            personnelName: tradePartnerPersonnel.personnel.fullName
          }),
          'success'
        );
        handleClose();
        history.push(`/projects/${projectId}/personnel`);
      })
      .catch(error => {
        console.error('Delete Trade Partner Personnel Error: ', error);
        displayToast(
          t('tradePartnerPersonnelDeleteCard.toasts.error'),
          'error'
        );
      });
  };

  const handleChange = event => {
    toggleConfirmSwitch(event.target.checked);
  };

  const warningMessages = [
    t('tradePartnerPersonnelDeleteCard.confirmation.subTitle')
  ];
  if (tradePartnerPersonnel.orientationHistory.length) {
    warningMessages.push('You are deleting orientation history.');
  }
  if (countSafetyHours) {
    warningMessages.push(
      `You are deleting ${countSafetyHours} time entries from Safety Hours, which may impact statistics related to the GOLD report.`
    );
  }
  if (countObservationsAsObservedPerson) {
    warningMessages.push(
      `You are deleting ${countObservationsAsObservedPerson} SAFE observations observing this person.`
    );
  }
  if (countObservationsAsSupervisor) {
    warningMessages.push(
      `You are deleting ${countObservationsAsSupervisor} SAFE observations supervised by this person.`
    );
  }
  if (countObservationsAsOriginator) {
    warningMessages.push(
      `You are deleting ${countObservationsAsOriginator} SAFE observations originated by this person.`
    );
  }

  return (
    <Fragment>
      <Card className="padding">
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Typography color="textPrimary" className="bold">
              {t('tradePartnerPersonnelDeleteCard.title')}
            </Typography>
          </Grid>
          <Grid item>
            <Grid container direction="row" justifyContent="space-between">
              <Grid item>
                <Typography gutterBottom color="textSecondary">
                  {t('tradePartnerPersonnelDeleteCard.remove')}
                </Typography>
              </Grid>
              <Grid item>
                <StyledButtonPrimary
                  disabled={isLoading}
                  label={t('tradePartnerPersonnelDeleteCard.button.delete')}
                  onClick={handleOpen}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
      <StyledDialog
        isOpen={dialogIsOpen}
        isLoading={isLazyLoading}
        handleClose={handleClose}
        disableBackdropClick={false}
        disableEscapeKeyDown={false}
        maxWidth="sm"
        title={t('tradePartnerPersonnelDeleteCard.confirmation.title')}
        content={
          <Grid container direction="column" spacing={1}>
            {warningMessages.map((message, index) => (
              <Grid item key={`warning${index}`}>
                <StyledNotice type="warning" message={message} />
              </Grid>
            ))}
            <Grid item>
              <StyledConfirmationSwitch
                disabled={isLazyLoading}
                checked={isConfirmed}
                onChange={handleChange}
                value={'isConfirmed'}
              />
            </Grid>
          </Grid>
        }
        actions={
          <Fragment>
            <StyledButtonSecondary
              label={t(
                'tradePartnerPersonnelDeleteCard.confirmation.actions.cancelButton'
              )}
              disabled={isLoading}
              onClick={handleClose}
            />
            <StyledButtonPrimary
              label={t(
                'tradePartnerPersonnelDeleteCard.confirmation.actions.submitButton'
              )}
              disabled={isLoading || !isConfirmed}
              onClick={handleSubmit}
            />
          </Fragment>
        }
      />
    </Fragment>
  );
};
TradePartnerPersonnelDeleteCard.propTypes = {
  tradePartnerPersonnel: PropTypes.object.isRequired
};
export default TradePartnerPersonnelDeleteCard;
