/* eslint-disable react/display-name */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';

import useRoles from 'hooks/useRoles';
import StyledTable from 'shared/Table';
import StyledTableCellContent from 'components/common/StyledTableCellContent';
import ActiveOrInactiveStatusContent from 'components/common/ActiveOrInactiveStatusContent';

const PersonnelJobHistoryList = ({ tradePartnerHistory }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { isEnterpriseAdmin } = useRoles();

  const columns = [
    {
      name: 'id',
      label: 'Id',
      options: {
        filter: false,
        display: 'excluded'
      }
    },
    {
      name: 'tradePartner.project.id',
      label: 'Project Id',
      options: {
        filter: false,
        display: 'excluded'
      }
    },
    {
      name: 'tradePartner.project.number',
      label: 'Project Number',
      options: {
        filter: false,
        searchable: true,
        sort: true,
        sortOrder: 'asc'
      }
    },
    {
      name: 'tradePartner.project.name',
      label: 'Project Name',
      options: {
        filter: false,
        searchable: true,
        sort: true,
        customBodyRender: value => {
          return <StyledTableCellContent value={value} />;
        }
      }
    },
    {
      name: 'tradePartner.name',
      label: 'Trade Partner',
      options: {
        filter: false,
        searchable: true,
        sort: true,
        customBodyRender: value => {
          return <StyledTableCellContent value={value} />;
        }
      }
    },
    {
      name: 'isSupervisor',
      label: 'Supervisor',
      options: {
        filter: true,
        sort: true,
        searchable: false,
        filterOptions: {
          names: [
            t('personnelJobHistoryList.isSupervisorCheck.supervisor'),
            t('personnelJobHistoryList.isSupervisorCheck.notSupervisor')
          ],
          logic: (isSupervisor, filters) => {
            if (filters.length) {
              const supervisor =
                isSupervisor === true
                  ? t('personnelJobHistoryList.isSupervisorCheck.supervisor')
                  : t(
                      'personnelJobHistoryList.isSupervisorCheck.notSupervisor'
                    );
              return !filters.includes(supervisor);
            }
            /* istanbul ignore next */
            return false; // this line can never be tested as filters will always exist
          }
        },
        customBodyRender: isSupervisor => (
          <Fragment>
            {isSupervisor && (
              <Typography style={{ fontSize: '0.875rem' }}>
                {t('personnelJobHistoryList.status.supervisor')}
              </Typography>
            )}
          </Fragment>
        )
      }
    },
    {
      name: 'isActive',
      label: 'Status',
      options: {
        filter: true,
        searchable: false,
        sort: true,
        filterOptions: {
          names: [
            t('personnelJobHistoryList.isActiveCheck.isActive'),
            t('personnelJobHistoryList.isActiveCheck.isInactive')
          ],
          logic: (isActive, filters) => {
            if (filters.length) {
              const jobStatus =
                isActive === true
                  ? t('personnelJobHistoryList.isActiveCheck.isActive')
                  : t('personnelJobHistoryList.isActiveCheck.isInactive');
              return !filters.includes(jobStatus);
            }
            return false;
          }
        },
        customBodyRender: value => (
          <ActiveOrInactiveStatusContent isActive={value} />
        )
      }
    }
  ];

  const options = {
    onRowClick: rowData => history.push(`/projects/${rowData[1]}`)
  };

  return (
    <StyledTable
      title={t('personnelJobHistoryList.tableTitle')}
      data={tradePartnerHistory ?? []}
      columns={columns}
      options={isEnterpriseAdmin ? options : {}}
    />
  );
};

PersonnelJobHistoryList.propTypes = {
  tradePartnerHistory: PropTypes.array
};

export default PersonnelJobHistoryList;
