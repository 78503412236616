import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import LoadingOverlay from 'react-loading-overlay';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Typography, Tooltip, Grid } from '@mui/material';

import useDebounce from 'hooks/useDebounce';
import withAuthorization from 'hocs/withAuthorization';
import AddCompanyDialog from 'components/company/AddCompanyDialog';
import ErrorNotice from 'components/common/ErrorNotice';
import ActiveOrInactiveStatusContent from 'components/common/ActiveOrInactiveStatusContent';
import StyledTable from 'shared/Table';
import BackToTopButton from 'shared/BackToTopButton';
import withOnlineAccessOnly from 'hocs/withOnlineAccessOnly';
import StyledTableCellContent from 'components/common/StyledTableCellContent';
import useCompaniesListState from 'store/companiesListState';
import { PAGINATED_COMPANIES, COMPANY_SEARCH } from 'graphql/company';
import { DISTRICT_NUMBERS } from 'constants/districts';
import LoadingSpinner from '../../../components/common/LoadingSpinner';

const useStyles = makeStyles(theme => ({
  limitedText: {
    fontSize: '0.875rem',
    maxWidth: 800,
    whiteSpace: 'noWrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    [theme.breakpoints.down('xl')]: { maxWidth: 600 },
    [theme.breakpoints.down('xl')]: { maxWidth: 400 },
    [theme.breakpoints.down('lg')]: { maxWidth: 300 },
    [theme.breakpoints.down('md')]: { maxWidth: 250 }
  }
}));

export const MasterCompanyListPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const [
    companiesListState,
    { handleCompaniesListStateChange }
  ] = useCompaniesListState();
  const [initialCompaniesListState] = useState(companiesListState);

  const FILTERS = {
    trades: 'Trades',
    districts: 'Regions',
    isActive: 'Active',
    inactive: 'Inactive'
  };

  const getApiSortOrder = order => {
    let apiSortOrderField = null;
    let direction = order.direction;

    switch (order.name) {
      case 'name':
        apiSortOrderField = 'name';
        break;
      case 'cityAndStateDisplay':
        apiSortOrderField = 'location_city';
        break;
      default:
        console.error(`Sort order, "${order.name}", is not being handled!`);
    }
    return apiSortOrderField && direction
      ? [
          {
            [apiSortOrderField]: direction.toUpperCase()
          }
        ]
      : null;
  };

  const { data, loading, error, fetchMore } = useQuery(COMPANY_SEARCH, {
    variables: {
      first: initialCompaniesListState.first,
      filter: initialCompaniesListState.filter,
      order: getApiSortOrder(initialCompaniesListState.order),
      search: initialCompaniesListState.search,
      skip: 0
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  });

  const companies =
    data?.companySearch?.results?.map(result => ({
      ...result.document,
      cityAndStateDisplay: `${result.document.city}, ${result.document.stateOrProvince}`
    })) ?? [];

  const columns = [
    {
      name: 'id',
      label: 'Company Id',
      options: {
        filter: false,
        display: 'excluded'
      }
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: false,
        sort: true,
        sortOrder: 'asc',
        searchable: true,
        // eslint-disable-next-line react/display-name
        customBodyRender: value => {
          return <StyledTableCellContent value={value} />;
        }
      }
    },
    {
      name: 'trades',
      label: 'Trades',
      options: {
        filter: true,
        filterType: 'textField',
        sort: false,
        searchable: false,
        filterList: companiesListState.filterList[2]?.length
          ? companiesListState.filterList[2]
          : null,
        filterOptions: {
          names: [FILTERS.trades],
          logic: (trades, filters) => {
            if (filters.length) {
              const tradeToFilterBy = trades;
              return !filters.includes(tradeToFilterBy);
            }
            /* istanbul ignore next */
            return false; // this line can never be tested as filters will always exist
          }
        },
        // eslint-disable-next-line react/display-name
        customBodyRender: value => {
          return (
            <StyledTableCellContent
              value={value.length > 0 ? value.join(', ').toLowerCase() : null}
            />
          );
        }
      }
    },
    {
      name: 'cityAndStateDisplay',
      label: 'Location',
      options: {
        filter: false,
        sort: true,
        // eslint-disable-next-line react/display-name
        customBodyRender: value => {
          return <StyledTableCellContent value={value} />;
        }
      }
    },
    {
      name: 'regions',
      label: 'Regions',
      options: {
        filter: true,
        sort: false,
        searchable: false,
        filterList: companiesListState.filterList[4]?.length
          ? companiesListState.filterList[4]
          : null,
        filterOptions: {
          names: DISTRICT_NUMBERS,
          logic: (districts, filters) => {
            if (filters.length) {
              if (districts.length > 0) {
                return !filters.includes(districts[0].toString());
              } else {
                return false;
              }
            }
          }
        },
        // eslint-disable-next-line react/display-name
        customBodyRender: value => {
          const districtsList = [...value].sort().join(', ');
          return (
            <Tooltip title={districtsList}>
              <Typography className={classes.limitedText}>
                {districtsList}
              </Typography>
            </Tooltip>
          );
        }
      }
    },
    {
      name: 'isActive',
      label: 'Status',
      options: {
        sort: false,
        searchable: false,
        filter: true,
        customFilterListOptions: { render: value => `Status: ${value}` },
        filterList: companiesListState.filterList[5]?.length
          ? companiesListState.filterList[5]
          : null,
        filterOptions: {
          names: [FILTERS.isActive, FILTERS.inactive],
          logic: (isActive, filters) => {
            if (filters.length) {
              const status = isActive ? FILTERS.isActive : FILTERS.inactive;
              return !filters.includes(status);
            }
            /* istanbul ignore next */
            return false; // this line can never be tested as filters will always exist
          }
        },
        // eslint-disable-next-line react/display-name
        customBodyRender: value => (
          <ActiveOrInactiveStatusContent isActive={value} />
        )
      }
    }
  ];

  const loadMore = variables => {
    return fetchMore({
      variables,
      updateQuery: (_previousResult, { fetchMoreResult, _queryVariables }) => {
        return fetchMoreResult;
      }
    });
  };

  const handleFilterChange = tableState => {
    const { filterList } = tableState;
    const filters = filterList.flat().map(filter => filter.toLowerCase());
    let selectedFilters = {};
    let districtArray = [];
    districtArray.push(parseInt(filterList[4]));

    if (filters.some(filter => DISTRICT_NUMBERS.includes(filter))) {
      selectedFilters = {
        ...selectedFilters,
        districts: districtArray
      };
    }

    if (filters.some(filter => filterList[2].includes(filter))) {
      selectedFilters = {
        ...selectedFilters,
        trades: filterList[2]
      };
    }

    if (filters.includes(FILTERS.isActive.toLowerCase())) {
      selectedFilters = {
        ...selectedFilters,
        isActive: true
      };
    }

    if (filters.includes(FILTERS.inactive.toLowerCase())) {
      selectedFilters = {
        ...selectedFilters,
        isActive: false
      };
    }

    handleCompaniesListStateChange({
      ...companiesListState,
      filter: selectedFilters,
      filterList,
      skip: 0,
      page: 0
    });

    loadMore({
      search: companiesListState.search,
      filter: selectedFilters,
      skip: 0,
      order: getApiSortOrder(companiesListState.order)
    });
  };

  const { debounced: debouncedHandleFilterChange } = useDebounce(
    handleFilterChange
  );

  const handlePageChange = tableState => {
    const { rowsPerPage, page } = tableState;

    let skip =
      page < companiesListState.page
        ? companiesListState.skip - rowsPerPage
        : companiesListState.skip + rowsPerPage;

    handleCompaniesListStateChange({
      ...companiesListState,
      page,
      skip
    });

    loadMore({
      search: companiesListState.search,
      skip,
      order: getApiSortOrder(companiesListState.order)
    });
  };

  const handleSortChange = tableState => {
    const { sortOrder } = tableState;

    handleCompaniesListStateChange({
      ...companiesListState,
      order: sortOrder,
      page: 0,
      skip: 0
    });

    loadMore({
      search: companiesListState.search,
      skip: 0,
      order: getApiSortOrder(sortOrder)
    });
  };

  const handleSearchChange = ({ tableState, debouncedHandleSearchChange }) => {
    const { searchText } = tableState;

    handleCompaniesListStateChange({
      ...companiesListState,
      skip: 0,
      page: 0,
      search: searchText
    });

    loadMore({
      search: searchText,
      skip: 0,
      filter: companiesListState.filter,
      order: getApiSortOrder(companiesListState.order)
    });
  };

  const { debounced: debouncedHandleSearchChange } = useDebounce(
    handleSearchChange
  );

  if (error) {
    console.error(error);
    return <ErrorNotice />;
  }

  const options = {
    download: false,
    searchPlaceholder: 'Search...',
    count: data?.paginatedCompanys?.total ?? null,
    rowsPerPage: companiesListState.first,
    searchText: companiesListState.search,
    page: companiesListState.page,
    rowsPerPageOptions: [],
    sortOrder: companiesListState.order,
    serverSide: true,
    onRowClick: rowData => history.push(`/companies/${rowData[0]}`),
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          handlePageChange(tableState);
          break;
        case 'sort':
          handleSortChange(tableState);
          break;
        case 'filterChange':
        case 'resetFilters':
          debouncedHandleFilterChange(tableState);
          break;
        case 'search':
        case 'onSearchClose':
          debouncedHandleSearchChange({
            tableState,
            companiesListState
          });
          break;
        default:
      }
    }
  };

  return (
    <Fragment>
      <LoadingOverlay
        active={loading}
        spinner
        fadeSpeed={50}
        styles={{
          overlay: base => ({
            ...base,
            background: theme.palette.background.overlay
          }),
          spinner: base => ({
            ...base,
            width: '50px',
            '& svg circle': {
              stroke: theme.palette.primary.main
            }
          })
        }}>
        <StyledTable
          title={t('masterCompanyListPage.title')}
          data={companies}
          columns={columns}
          options={options}
        />
      </LoadingOverlay>
      <AddCompanyDialog />
      <BackToTopButton />
    </Fragment>
  );
};

export default withOnlineAccessOnly(withAuthorization(MasterCompanyListPage));
