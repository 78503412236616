import { useCallback } from 'react';
import debounce from 'lodash.debounce';

const useDebounce = (callback, wait = 300) => {
  const debounced = useCallback(
    debounce(nextValue => callback(nextValue), wait),
    []
  );

  return { debounced };
};

export default useDebounce;
