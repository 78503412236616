export const features = {
  preIncidentIndicators: 'preIncidentIndicators'
};

export const enabledFeatures = {
  test: {
    [features.preIncidentIndicators]: true
  },
  dev: {
    [features.preIncidentIndicators]: true
  },
  qa: {
    [features.preIncidentIndicators]: true
  },
  beta: {
    [features.preIncidentIndicators]: true
  },
  prod: {
    [features.preIncidentIndicators]: true
  }
};
