import { atom, useRecoilState } from 'recoil';
import * as effects from 'store/effects';
import { LOCAL_STORAGE_OFFLINE_MODE_ENABLED_KEY } from 'constants/localStorage';

const getStoredStatus = () => {
  const storedOfflineModeStatus = localStorage.getItem(
    LOCAL_STORAGE_OFFLINE_MODE_ENABLED_KEY
  );
  if (storedOfflineModeStatus) {
    return JSON.parse(storedOfflineModeStatus);
  } else {
    return null;
  }
};

const defaultIsOfflineModeEnabledState = atom({
  key: 'isOfflineModeEnabled',
  default: getStoredStatus() ?? false
});

const defaultRefetchOfflineModeQueriesToCache = atom({
  key: 'handleRefetchOfflineModeQueriesToCache',
  default: null
});

function useOfflineModeCapabilities() {
  const [
    isOfflineModeEnabled,
    setIsManualOfflineModeEnabledState
  ] = useRecoilState(defaultIsOfflineModeEnabledState);
  const [
    refetchOfflineModeQueriesToCache,
    setRefetchOfflineModeQueriesToCache
  ] = useRecoilState(defaultRefetchOfflineModeQueriesToCache);

  const setIsManualOfflineModeEnabled = isEnabled => {
    effects.offlineMode.localStorageSave(isEnabled);
    setIsManualOfflineModeEnabledState(isEnabled);
  };

  return {
    isOfflineModeEnabled,
    setIsManualOfflineModeEnabled,
    refetchOfflineModeQueriesToCache,
    setRefetchOfflineModeQueriesToCache
  };
}

export default useOfflineModeCapabilities;
