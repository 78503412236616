import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { useField, useForm } from 'react-final-form-hooks';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography } from '@mui/material';

import useFormValidation from 'hooks/useFormValidation';
import useToast from 'hooks/useToast';
import StyledDialog from 'shared/Dialog';
import StyledInput from 'shared/Input';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import StyledButtonSecondary from 'shared/Buttons/ButtonSecondary';
import AddFile from 'components/common/AddFile';
import { generateTransactionKey } from 'utils';
import { CREATE_AHA_FILE } from 'graphql/aha/ahaFile';

const useStyles = makeStyles(theme => ({
  addFileLabel: { fontSize: '0.75rem', fontWeight: 'bold' },
  descriptionContainer: { marginBottom: theme.spacing(1) }
}));

const AddHenselPhelpsFileDialog = ({
  isAddFileDialogOpen,
  setIsAddFileDialogOpen,
  projectTemplateId,
  refetchQuery
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isRequired } = useFormValidation();
  const { displayToast } = useToast();
  const [addedFiles, setAddedFiles] = useState([]);
  const [filesToDelete, setFilesToDelete] = useState([]);
  const [transactionKey, setTransactionKey] = useState(
    generateTransactionKey()
  );
  const [isSaving, setIsSaving] = useState(false);

  const [createAhaFile, { loading }] = useMutation(CREATE_AHA_FILE);

  const isLoading = loading || isSaving;

  const { form, values, pristine } = useForm({
    /* istanbul ignore next */
    onSubmit: () => {} // this function required for useForm but is not used
  });

  const description = useField('description', form, isRequired);

  const canSubmit = () => {
    const { error, invalid } = form.getState();
    return !(invalid || error || pristine || addedFiles?.length < 1);
  };

  const handleSubmit = () => {
    setIsSaving(true);
    createAhaFile({
      variables: {
        input: {
          ahaProjectTemplateId: projectTemplateId,
          description: values.description,
          file: addedFiles[0],
          transactionKey: transactionKey
        }
      }
    })
      .then(async () => {
        if (refetchQuery) {
          await refetchQuery();
        }
        displayToast(t('addHenselPhelpsFileDialog.toasts.success'), 'success');
        handleClose();
      })
      .catch(error => {
        setIsSaving(false);
        console.error('Add Hensel Phelps AHA File Error: ', error);
        displayToast(t('addHenselPhelpsFileDialog.toasts.error'), 'error');
      });
  };

  const handleClose = () => {
    form.reset();
    form.resetFieldState('description');
    setAddedFiles([]);
    setFilesToDelete([]);
    setIsAddFileDialogOpen(false);
    setIsSaving(false);
    setTransactionKey(generateTransactionKey());
  };

  return (
    <StyledDialog
      isOpen={isAddFileDialogOpen}
      handleClose={() => setIsAddFileDialogOpen(false)}
      title={t('addHenselPhelpsFileDialog.title')}
      isLoading={isLoading}
      content={
        <Grid container justifyContent="center">
          <Grid item xs={12} className={classes.descriptionContainer}>
            <StyledInput
              input={description.input}
              meta={description.meta}
              disabled={isLoading}
              label={t('addHenselPhelpsFileDialog.descriptionFieldLabel')}
              autoFocus={true}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Typography color="textSecondary" className={classes.addFileLabel}>
              {t('addHenselPhelpsFileDialog.addFileLabel')}
            </Typography>
            <AddFile
              fileDataFromDb={[]}
              setAddedFiles={setAddedFiles}
              addedFiles={addedFiles}
              existingFiles={[]}
              setFilesToDelete={setFilesToDelete}
              filesToDelete={filesToDelete}
              maxNumFiles={1}
            />
          </Grid>
        </Grid>
      }
      actions={
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <StyledButtonSecondary
                  label={t('addHenselPhelpsFileDialog.actions.cancelButton')}
                  disabled={isLoading}
                  onClick={handleClose}
                />
              </Grid>
              <Grid item>
                <StyledButtonPrimary
                  label={t('addHenselPhelpsFileDialog.actions.submitButton')}
                  disabled={!canSubmit() || isLoading}
                  onClick={handleSubmit}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
    />
  );
};

AddHenselPhelpsFileDialog.propTypes = {
  isAddFileDialogOpen: PropTypes.bool,
  setIsAddFileDialogOpen: PropTypes.func,
  projectTemplateId: PropTypes.string,
  refetchQuery: PropTypes.func
};

export default AddHenselPhelpsFileDialog;
