import { useQuery } from '@apollo/react-hooks';
import {
  Badge,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import AddIcon from '@mui/icons-material/Add';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';
import FilterIcon from '@mui/icons-material/FilterList';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SearchIcon from '@mui/icons-material/Search';
import classnames from 'classnames';
import AddAhaDialog from 'components/ahas/AddAhaDialog';
import AddTradePartnerAhaDialog from 'components/ahas/AddTradePartnerAhaDialog';
import HenselPhelpsAhaTabContent from 'components/ahas/HenselPhelpsAhaTabContent';
import PendingReviewTabContent from 'components/ahas/PendingReviewTabContent';
import TradePartnerAhaTabContent from 'components/ahas/TradePartnerAhaTabContent';
import TabPanel from 'components/common/TabPanel';
import {
  HENSEL_PHELPS_FILTER_OPTIONS,
  HENSEL_PHELPS_SORT_ORDER_OPTIONS,
  SORT_ORDER_DIRECTION,
  TRADE_PARTNER_ISACTIVE_FILTER_OPTIONS,
  TRADE_PARTNER_SORT_ORDER_OPTIONS,
  FILTER_API_KEYS
} from 'constants/ahas';
import { detect } from 'detect-browser';
import { GET_PAGINATED_AHA_REVIEWS_ON_PROJECT_TOTAL_PENDING_COUNT } from 'graphql/aha/ahaReview';
import { GET_SAFE_PROJECT_FOR_AREAS } from 'graphql/safeProject';
import withAuthorization from 'hocs/withAuthorization';
import withOnlineAccessOnly from 'hocs/withOnlineAccessOnly';
import useAhaProjectTemplates from 'hooks/useAhaProjectTemplates';
import useTradePartnerTemplateCategories from 'hooks/useAhaTradePartnerTemplateCategories';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import BackToTopButton from 'shared/BackToTopButton';
import StyledButtonSecondary from 'shared/Buttons/ButtonSecondary';
import StyledFab from 'shared/Buttons/Fab';
import StyledChip from 'shared/Chip';
import useHenselPhelpsAhaListState from 'store/henselPhelpsAhaListState';
import useIsOnline from 'store/onlineDetection';
import usePendingReviewAhaListState from 'store/pendingReviewAhaListState';
import useTradePartnerAhaListState from 'store/tradePartnerAhaListState';
import useAhaStyles from './useAhaStyles';
import useSoteriaQuery from 'hooks/useSoteriaQuery';

const HP_AHAS_TAB = 'henselPhelpsAHAs';
const TP_AHAS_TAB = 'tradePartnerAhas';
const PENDING_REVIEW_TAB = 'pendingReview';

const a11yProps = index => {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`
  };
};

const StyledMenu = withStyles(theme => ({}))(props => (
  <Menu
    elevation={4}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left'
    }}
    {...props}
  />
));

const AhaDashboardPage = () => {
  const styles = useAhaStyles();
  const classes = styles();
  const { t } = useTranslation();
  const browser = detect();
  const { isOnline } = useIsOnline();
  const { projectId } = useParams();
  const allowedTabs = [HP_AHAS_TAB, TP_AHAS_TAB, PENDING_REVIEW_TAB];
  const [activeTabName, setActiveTabName] = useState(HP_AHAS_TAB);
  const [
    tradePartnerAhaListState,
    { handleTradePartnerAhaListStateChange }
  ] = useTradePartnerAhaListState(projectId);
  const [
    henselPhelpsAhaListState,
    { handleHenselPhelpsAhaListStateChange }
  ] = useHenselPhelpsAhaListState();
  const {
    projectTemplates,
    total: henselPhelpsAhasTotalCount,
    loading: isLoadingHenselPhelpsPaginatedProjectTemplates,
    loadMore: fetchMoreHenselPhelpsAhas,
    handleSearch: handleHpSearch,
    handleSortChange: handleHpSortChange,
    toggleSortDirection: toggleHpSortDirection,
    handleFilterChange: handleHpFilterChange
  } = useAhaProjectTemplates(projectId);
  const {
    tradePartnerTemplateCategories,
    total: tradePartnerAhasTotalCount,
    loading: isLoadingTradePartnerPaginatedProjectTemplates,
    loadMore: fetchMoreTradePartnerAhas,
    handleSearch: handleTpSearch,
    handleSortChange: handleTpSortChange,
    toggleSortDirection: toggleTpSortDirection,
    handleFilterChange: handleTpFilterChange,
    refetch: refetchTradePartnerTemplateCategories
  } = useTradePartnerTemplateCategories(projectId);
  const [activeTab, setActiveTab] = useState(0);
  const isHenselPhelpsAhasTab = activeTab === 0;
  const isPendingReviewTab = activeTab === 2;
  const [anchorEl, setAnchorEl] = useState(null);
  const [henselPhelpsSearch, setHenselPhelpsSearch] = useState(
    henselPhelpsAhaListState.search
  );
  const [tradePartnerSearch, setTradePartnerSearch] = useState(
    henselPhelpsAhaListState.search
  );
  const search = isHenselPhelpsAhasTab
    ? henselPhelpsSearch
    : tradePartnerSearch;
  const [
    addTradePartnerAHADialogIsOpen,
    toggleAddTradePartnerAHADialog
  ] = useState(false);
  const [
    addHenselPhelpsAHADialogIsOpen,
    toggleAddHenselPhelpsAHADialog
  ] = useState(false);
  const [pendingReviewAhaListState] = usePendingReviewAhaListState();

  const {
    data: safeProjectForAreasData,
    loading: isLoadingProjectForAreas
  } = useSoteriaQuery({
    gql: GET_SAFE_PROJECT_FOR_AREAS,
    queryHookOptions: {
      skip: !projectId,
      variables: { projectId }
    }
  });

  function getApiSortOrder(sortState) {
    return sortState.name && sortState.direction
      ? [
          {
            [sortState.name]: sortState.direction.toUpperCase()
          }
        ]
      : null;
  }

  const { data } = useQuery(
    GET_PAGINATED_AHA_REVIEWS_ON_PROJECT_TOTAL_PENDING_COUNT,
    {
      variables: {
        filter: pendingReviewAhaListState.filter,
        first: pendingReviewAhaListState.first,
        order: getApiSortOrder(pendingReviewAhaListState.order),
        projectId,
        search: pendingReviewAhaListState.search,
        skip: 0
      }
    }
  );

  const currentSortBy = isHenselPhelpsAhasTab
    ? henselPhelpsAhaListState.order
    : tradePartnerAhaListState.order;

  const handleTabChange = (_, newValue) => {
    const tab = allowedTabs[newValue];
    setActiveTabName(tab);
    setActiveTab(newValue);
  };

  const pendingReviewCount = data?.paginatedAhaReviewsOnProject?.total;

  const tabs = [
    {
      isActiveTab: activeTab === allowedTabs.indexOf(HP_AHAS_TAB),
      label: (
        <Typography className={classes.tabLabel}>
          {t('AHADashboardPage.HPTab')}
        </Typography>
      ),
      content: (() => {
        return (
          <HenselPhelpsAhaTabContent
            projectTemplates={projectTemplates}
            fetchMoreHenselPhelpsAhas={fetchMoreHenselPhelpsAhas}
            isLoadingPaginatedProjectTemplates={
              isLoadingHenselPhelpsPaginatedProjectTemplates
            }
            henselPhelpsAhasTotalCount={henselPhelpsAhasTotalCount}
          />
        );
      })()
    },
    {
      isActiveTab: activeTab === allowedTabs.indexOf(TP_AHAS_TAB),
      label: (
        <Typography className={classes.tabLabel}>
          {t('AHADashboardPage.TPTab')}
        </Typography>
      ),

      content: (() => {
        return (
          <TradePartnerAhaTabContent
            ahas={tradePartnerTemplateCategories}
            fetchMoreTradePartnerAhas={fetchMoreTradePartnerAhas}
            isLoadingPaginatedProjectTemplates={
              isLoadingTradePartnerPaginatedProjectTemplates ||
              isLoadingProjectForAreas
            }
            tradePartnerAhasTotalCount={tradePartnerAhasTotalCount}
            projectArea={
              tradePartnerAhaListState.filter[
                FILTER_API_KEYS.ahaFiles_projectArea
              ]
            }
          />
        );
      })()
    },
    {
      isActiveTab: activeTab === allowedTabs.indexOf(PENDING_REVIEW_TAB),
      label: (
        <Tooltip
          title={t('ahaDashboardPage.tab.pendingReviewCount', {
            count: pendingReviewCount
          })}>
          <Badge
            badgeContent={pendingReviewCount}
            value={pendingReviewCount}
            classes={{
              badge:
                activeTab === allowedTabs.indexOf(PENDING_REVIEW_TAB)
                  ? classes.redBadge
                  : classes.whiteBadge
            }}>
            <Typography className={classes.tabLabel}>
              {t('ahaDashboardPage.pendingReviewTab')}
            </Typography>
          </Badge>
        </Tooltip>
      ),

      content: (() => {
        return <PendingReviewTabContent />;
      })()
    }
  ];

  const chooseDialogToOpen = () => {
    isHenselPhelpsAhasTab
      ? toggleAddHenselPhelpsAHADialog(true)
      : toggleAddTradePartnerAHADialog(true);
  };

  const handleSearchChange = event => {
    const search = event?.target?.value ?? '';

    if (isHenselPhelpsAhasTab) {
      setHenselPhelpsSearch(search);
      handleHpSearch({
        search,
        state: henselPhelpsAhaListState,
        handleChange: handleHenselPhelpsAhaListStateChange
      });
    } else {
      setTradePartnerSearch(search);
      handleTpSearch({
        search,
        state: tradePartnerAhaListState,
        handleChange: handleTradePartnerAhaListStateChange
      });
    }
  };

  const handleSortChange = event => {
    isHenselPhelpsAhasTab
      ? handleHpSortChange(event)
      : handleTpSortChange(event);
  };

  const toggleSortDirectionChange = () => {
    isHenselPhelpsAhasTab ? toggleHpSortDirection() : toggleTpSortDirection();
  };

  const handleMenuButtonClick = event => {
    setAnchorEl(event?.currentTarget);
  };

  const handleFilterChange = (selectedFilterValue, fieldName) => {
    isHenselPhelpsAhasTab
      ? handleHpFilterChange(selectedFilterValue)
      : handleTpFilterChange(selectedFilterValue, fieldName);
  };

  const handleRadioOptionChange = (event, fieldName) => {
    const selectedFilterValue = event.target.value;
    handleFilterChange(selectedFilterValue, fieldName);
  };

  const handleToggleCheckboxOptions = checkbox => {
    handleFilterChange(checkbox);
  };

  const handleFilterMenuClose = () => {
    setAnchorEl(null);
  };

  const activeHenselPhelpsFilters = Object.values(
    henselPhelpsAhaListState.filter
  )
    .filter(value => !!value)
    .reduce((accumulator, item) => {
      if (Array.isArray(item)) {
        item.forEach(subItem => {
          accumulator.push(HENSEL_PHELPS_FILTER_OPTIONS[subItem]);
        });
      } else {
        accumulator.push(HENSEL_PHELPS_FILTER_OPTIONS[item]);
      }
      return accumulator;
    }, []);
  const activeTpFilters = Object.entries(tradePartnerAhaListState.filter)
    .filter(
      ([key, value]) => !!value && key !== FILTER_API_KEYS.ahaFiles_projectArea
    )
    .map(([_, value]) => TRADE_PARTNER_ISACTIVE_FILTER_OPTIONS[value]);
  activeTpFilters.push({
    label:
      tradePartnerAhaListState.filter[FILTER_API_KEYS.ahaFiles_projectArea] ??
      'All',
    value:
      tradePartnerAhaListState.filter[FILTER_API_KEYS.ahaFiles_projectArea] ??
      null,
    apiKey: FILTER_API_KEYS.ahaFiles_projectArea
  });

  const activeFilters = isHenselPhelpsAhasTab
    ? activeHenselPhelpsFilters.filter(hpFilter => hpFilter.label !== 'All')
    : activeTpFilters.filter(tpFilter => tpFilter.label !== 'All');

  const isSafariBrowser = browser && browser.name === 'safari';

  return (
    <>
      <div
        className={classnames(classes.root, {
          [`${classes.spinRoot}`]: search && search?.toLowerCase() === 'aha'
        })}>
        {(activeTabName === HP_AHAS_TAB || activeTabName === TP_AHAS_TAB) && (
          <StyledFab
            aria-label={
              activeTabName === TP_AHAS_TAB
                ? t('AHADashboardPage.addTpAHA')
                : t('AHADashboardPage.addHpAHA')
            }
            onClick={chooseDialogToOpen}
            icon={<AddIcon />}
            disabled={!isOnline}
            tooltipMessage={
              !isOnline ? t('AHADashboardPage.creationDisabled') : ''
            }
          />
        )}
        <Grid
          container
          alignContent="center"
          className={classes.headerContainer}>
          <Grid item xs={12}>
            <h1
              className={classnames(classes.title, {
                [`${classes.titleForSafariBrowser}`]: isSafariBrowser,
                [`${classes.titleOnPendingReviewTab}`]: isPendingReviewTab,
                [`${classes.spinTitle}`]:
                  search && search?.toLowerCase() === 'aha'
              })}>
              {t('AHADashboardPage.title')}
            </h1>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignItems="flex-start"
          className={classes.container}>
          <Grid item xs={12} sm={12} md={10} lg={10}>
            <Grid container>
              <Grid item xs={12}>
                {(activeTabName === HP_AHAS_TAB ||
                  activeTabName === TP_AHAS_TAB) && (
                  <Grid container justifyContent="space-between">
                    <Grid item xs={12} sm={12} md={8} lg={8}>
                      <Grid container spacing={1}>
                        <Grid item className={classes.searchContainer}>
                          <Typography
                            className={classes.listCustomizationLabel}
                            color="textSecondary">
                            Search
                          </Typography>
                          <TextField
                            className={classes.searchInput}
                            variant="outlined"
                            margin="dense"
                            placeholder={t('ahaSearch.searchFieldPlaceholder')}
                            onChange={handleSearchChange}
                            value={
                              isHenselPhelpsAhasTab
                                ? henselPhelpsSearch
                                : tradePartnerSearch
                            }
                            InputProps={{
                              classes: { root: classes.input },
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon
                                    className={classes.searchFieldIcon}
                                  />
                                </InputAdornment>
                              ),

                              endAdornment: (
                                <InputAdornment position="end">
                                  {((isHenselPhelpsAhasTab &&
                                    !!henselPhelpsAhaListState.search) ||
                                    (!isHenselPhelpsAhasTab &&
                                      !!tradePartnerAhaListState.search)) && (
                                    <IconButton
                                      size="small"
                                      onClick={() => {
                                        handleSearchChange('');
                                      }}
                                      className={classes.icon}>
                                      <ClearIcon
                                        className={classes.searchFieldIcon}
                                      />
                                    </IconButton>
                                  )}
                                </InputAdornment>
                              )
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <Typography
                            className={classes.listCustomizationLabel}
                            color="textSecondary">
                            Filter
                          </Typography>
                          <Grid container className={classes.filterContainer}>
                            <Grid item xs={12}>
                              <Grid
                                container
                                direction="row"
                                spacing={2}
                                alignItems="center">
                                <Grid item>
                                  <Button
                                    size={isSafariBrowser ? 'small' : 'medium'}
                                    className={classes.filterButton}
                                    onClick={handleMenuButtonClick}
                                    variant="outlined"
                                    startIcon={
                                      <FilterIcon
                                        className={classes.filterIcon}
                                      />
                                    }
                                    endIcon={
                                      <KeyboardArrowDownIcon
                                        className={classes.filterDropDownIcon}
                                      />
                                    }>
                                    <Typography
                                      className={classes.filterButtonLabel}>
                                      {t('henselPhelpsFilters.title')}
                                    </Typography>
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item>
                              {activeTabName === HP_AHAS_TAB && (
                                <StyledMenu
                                  anchorEl={anchorEl}
                                  keepMounted
                                  open={Boolean(anchorEl)}
                                  onClose={handleFilterMenuClose}>
                                  <Grid
                                    container
                                    direction="row"
                                    className={classes.filterGroupContainer}>
                                    <Grid item xs={12}>
                                      <Grid container justifyContent="flex-end">
                                        <IconButton
                                          size="small"
                                          onClick={handleFilterMenuClose}>
                                          <CloseIcon />
                                        </IconButton>
                                      </Grid>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={6}
                                      lg={6}
                                      className={classes.filterGroup}>
                                      <Typography>
                                        {t(
                                          'henselPhelpsFilters.lastModifiedFilter'
                                        )}
                                      </Typography>
                                      <RadioGroup
                                        className={classes.nested}
                                        name="timeFrame"
                                        value={
                                          henselPhelpsAhaListState?.filter
                                            ?.lastModifiedBefore ??
                                          henselPhelpsAhaListState?.filter
                                            ?.lastModifiedAfter ??
                                          ''
                                        }
                                        onChange={event =>
                                          handleRadioOptionChange(
                                            event,
                                            'timeFrame'
                                          )
                                        }>
                                        <FormControlLabel
                                          value={
                                            HENSEL_PHELPS_FILTER_OPTIONS
                                              .hpModifiedLastThirtyDays.value
                                          }
                                          control={
                                            <Radio
                                              classes={{
                                                root:
                                                  classes.clickableFilterOption
                                              }}
                                              disabled={
                                                isLoadingTradePartnerPaginatedProjectTemplates
                                              }
                                              size="small"
                                              color="primary"
                                            />
                                          }
                                          label={
                                            <Typography
                                              className={
                                                classes.filterOptionLabel
                                              }>
                                              {
                                                HENSEL_PHELPS_FILTER_OPTIONS
                                                  .hpModifiedLastThirtyDays
                                                  .label
                                              }
                                            </Typography>
                                          }
                                        />
                                        <FormControlLabel
                                          value={
                                            HENSEL_PHELPS_FILTER_OPTIONS
                                              .hpModifiedLastOverThirtyDays
                                              .value
                                          }
                                          control={
                                            <Radio
                                              classes={{
                                                root:
                                                  classes.clickableFilterOption
                                              }}
                                              disabled={
                                                isLoadingTradePartnerPaginatedProjectTemplates
                                              }
                                              size="small"
                                              color="primary"
                                            />
                                          }
                                          label={
                                            <Typography
                                              className={
                                                classes.filterOptionLabel
                                              }>
                                              {
                                                HENSEL_PHELPS_FILTER_OPTIONS
                                                  .hpModifiedLastOverThirtyDays
                                                  .label
                                              }
                                            </Typography>
                                          }
                                        />
                                        <FormControlLabel
                                          value={
                                            HENSEL_PHELPS_FILTER_OPTIONS
                                              .hpModifiedLastOverFortyFiveDays
                                              .value
                                          }
                                          control={
                                            <Radio
                                              classes={{
                                                root:
                                                  classes.clickableFilterOption
                                              }}
                                              disabled={
                                                isLoadingTradePartnerPaginatedProjectTemplates
                                              }
                                              size="small"
                                              color="primary"
                                            />
                                          }
                                          label={
                                            <Typography
                                              className={
                                                classes.filterOptionLabel
                                              }>
                                              {
                                                HENSEL_PHELPS_FILTER_OPTIONS
                                                  .hpModifiedLastOverFortyFiveDays
                                                  .label
                                              }
                                            </Typography>
                                          }
                                        />
                                        <FormControlLabel
                                          value={
                                            HENSEL_PHELPS_FILTER_OPTIONS
                                              .hpModifiedLastOverSixtyDays.value
                                          }
                                          control={
                                            <Radio
                                              classes={{
                                                root:
                                                  classes.clickableFilterOption
                                              }}
                                              disabled={
                                                isLoadingTradePartnerPaginatedProjectTemplates
                                              }
                                              size="small"
                                              color="primary"
                                            />
                                          }
                                          label={
                                            <Typography
                                              className={
                                                classes.filterOptionLabel
                                              }>
                                              {
                                                HENSEL_PHELPS_FILTER_OPTIONS
                                                  .hpModifiedLastOverSixtyDays
                                                  .label
                                              }
                                            </Typography>
                                          }
                                        />
                                        <FormControlLabel
                                          value={
                                            HENSEL_PHELPS_FILTER_OPTIONS
                                              .hpModifiedLastAllTime.value
                                          }
                                          control={
                                            <Radio
                                              classes={{
                                                root:
                                                  classes.clickableFilterOption
                                              }}
                                              disabled={
                                                isLoadingTradePartnerPaginatedProjectTemplates
                                              }
                                              size="small"
                                              color="primary"
                                            />
                                          }
                                          label={
                                            <Typography
                                              className={
                                                classes.filterOptionLabel
                                              }>
                                              {
                                                HENSEL_PHELPS_FILTER_OPTIONS
                                                  .hpModifiedLastAllTime.label
                                              }
                                            </Typography>
                                          }
                                        />
                                      </RadioGroup>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={6}
                                      lg={6}
                                      className={classes.filterGroup}>
                                      <FormControl
                                        variant="standard"
                                        component="fieldset"
                                        className={classes.formControl}>
                                        <Typography>Record Status</Typography>
                                        <Typography
                                          className={
                                            classes.filterGroupSubTitle
                                          }>
                                          Status of latest review
                                        </Typography>
                                        <FormGroup className={classes.nested}>
                                          <FormControlLabel
                                            classes={{
                                              root: classes.formControlLabelRoot
                                            }}
                                            control={
                                              <Checkbox
                                                classes={{
                                                  root:
                                                    classes.clickableFilterOption
                                                }}
                                                color="primary"
                                                size="small"
                                                checked={
                                                  henselPhelpsAhaListState?.filter?.ahaStatus?.includes(
                                                    'recordStatusPending'
                                                  ) ?? false
                                                }
                                                onChange={() =>
                                                  handleToggleCheckboxOptions(
                                                    'recordStatusPending'
                                                  )
                                                }
                                                name={'pending'}
                                              />
                                            }
                                            label={
                                              <Typography
                                                className={
                                                  classes.filterOptionLabel
                                                }>
                                                {
                                                  HENSEL_PHELPS_FILTER_OPTIONS
                                                    .recordStatusPending.label
                                                }
                                              </Typography>
                                            }
                                          />
                                          <FormControlLabel
                                            classes={{
                                              root: classes.formControlLabelRoot
                                            }}
                                            control={
                                              <Checkbox
                                                classes={{
                                                  root:
                                                    classes.clickableFilterOption
                                                }}
                                                color="primary"
                                                size="small"
                                                checked={
                                                  henselPhelpsAhaListState?.filter?.ahaStatus?.includes(
                                                    'recordStatusAccepted'
                                                  ) ?? false
                                                }
                                                onChange={() =>
                                                  handleToggleCheckboxOptions(
                                                    'recordStatusAccepted'
                                                  )
                                                }
                                                name={'accepted'}
                                              />
                                            }
                                            label={
                                              <Typography
                                                className={
                                                  classes.filterOptionLabel
                                                }>
                                                {
                                                  HENSEL_PHELPS_FILTER_OPTIONS
                                                    .recordStatusAccepted.label
                                                }
                                              </Typography>
                                            }
                                          />
                                          <FormControlLabel
                                            classes={{
                                              root: classes.formControlLabelRoot
                                            }}
                                            control={
                                              <Checkbox
                                                classes={{
                                                  root:
                                                    classes.clickableFilterOption
                                                }}
                                                color="primary"
                                                size="small"
                                                checked={
                                                  henselPhelpsAhaListState?.filter?.ahaStatus?.includes(
                                                    'recordStatusRejected'
                                                  ) ?? false
                                                }
                                                onChange={() =>
                                                  handleToggleCheckboxOptions(
                                                    'recordStatusRejected'
                                                  )
                                                }
                                                name={'rejected'}
                                              />
                                            }
                                            label={
                                              <Typography
                                                className={
                                                  classes.filterOptionLabel
                                                }>
                                                {
                                                  HENSEL_PHELPS_FILTER_OPTIONS
                                                    .recordStatusRejected.label
                                                }
                                              </Typography>
                                            }
                                          />
                                        </FormGroup>
                                      </FormControl>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={6}
                                      lg={6}
                                      className={classes.filterGroup}>
                                      <FormControl
                                        variant="standard"
                                        component="fieldset"
                                        className={classes.formControl}>
                                        <Typography>AHA Status</Typography>
                                        <FormGroup className={classes.nested}>
                                          <RadioGroup
                                            name="ahaStatus"
                                            value={
                                              henselPhelpsAhaListState?.filter
                                                ?.recordStatus ?? ''
                                            }
                                            onChange={event =>
                                              handleRadioOptionChange(
                                                event,
                                                'ahaStatus'
                                              )
                                            }>
                                            <FormControlLabel
                                              value={
                                                HENSEL_PHELPS_FILTER_OPTIONS
                                                  .ahaStatusActive.value
                                              }
                                              control={
                                                <Radio
                                                  classes={{
                                                    root:
                                                      classes.clickableFilterOption
                                                  }}
                                                  size="small"
                                                  color="primary"
                                                />
                                              }
                                              label={
                                                <Typography
                                                  className={
                                                    classes.filterOptionLabel
                                                  }>
                                                  {
                                                    HENSEL_PHELPS_FILTER_OPTIONS
                                                      .ahaStatusActive.label
                                                  }
                                                </Typography>
                                              }
                                            />
                                            <FormControlLabel
                                              value={
                                                HENSEL_PHELPS_FILTER_OPTIONS
                                                  .ahaStatusInactive.value
                                              }
                                              control={
                                                <Radio
                                                  classes={{
                                                    root:
                                                      classes.clickableFilterOption
                                                  }}
                                                  size="small"
                                                  color="primary"
                                                />
                                              }
                                              label={
                                                <Typography
                                                  className={
                                                    classes.filterOptionLabel
                                                  }>
                                                  {
                                                    HENSEL_PHELPS_FILTER_OPTIONS
                                                      .ahaStatusInactive.label
                                                  }
                                                </Typography>
                                              }
                                            />
                                            <FormControlLabel
                                              value={
                                                HENSEL_PHELPS_FILTER_OPTIONS
                                                  .ahaStatusAll.value
                                              }
                                              control={
                                                <Radio
                                                  classes={{
                                                    root:
                                                      classes.clickableFilterOption
                                                  }}
                                                  size="small"
                                                  color="primary"
                                                />
                                              }
                                              label={
                                                <Typography
                                                  className={
                                                    classes.filterOptionLabel
                                                  }>
                                                  {
                                                    HENSEL_PHELPS_FILTER_OPTIONS
                                                      .ahaStatusAll.label
                                                  }
                                                </Typography>
                                              }
                                            />
                                          </RadioGroup>
                                        </FormGroup>
                                      </FormControl>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={6}
                                      lg={6}
                                      className={classes.filterGroup}>
                                      <FormControl
                                        variant="standard"
                                        component="fieldset"
                                        className={classes.formControl}>
                                        <Typography>Attachment</Typography>
                                        <FormGroup className={classes.nested}>
                                          <RadioGroup
                                            name="hasAttachment"
                                            value={
                                              henselPhelpsAhaListState?.filter
                                                ?.hasAttachment ?? ''
                                            }
                                            onChange={event =>
                                              handleRadioOptionChange(
                                                event,
                                                'hasAttachment'
                                              )
                                            }>
                                            <FormControlLabel
                                              value={
                                                HENSEL_PHELPS_FILTER_OPTIONS
                                                  .hasAttachment.value
                                              }
                                              control={
                                                <Radio
                                                  classes={{
                                                    root:
                                                      classes.clickableFilterOption
                                                  }}
                                                  size="small"
                                                  color="primary"
                                                />
                                              }
                                              label={
                                                <Typography
                                                  className={
                                                    classes.filterOptionLabel
                                                  }>
                                                  {
                                                    HENSEL_PHELPS_FILTER_OPTIONS
                                                      .hasAttachment.label
                                                  }
                                                </Typography>
                                              }
                                            />
                                            <FormControlLabel
                                              value={
                                                HENSEL_PHELPS_FILTER_OPTIONS
                                                  .hasNoAttachments.value
                                              }
                                              control={
                                                <Radio
                                                  classes={{
                                                    root:
                                                      classes.clickableFilterOption
                                                  }}
                                                  size="small"
                                                  color="primary"
                                                />
                                              }
                                              label={
                                                <Typography
                                                  className={
                                                    classes.filterOptionLabel
                                                  }>
                                                  {
                                                    HENSEL_PHELPS_FILTER_OPTIONS
                                                      .hasNoAttachments.label
                                                  }
                                                </Typography>
                                              }
                                            />
                                            <FormControlLabel
                                              value={
                                                HENSEL_PHELPS_FILTER_OPTIONS
                                                  .hasOrHasNoAttachments.value
                                              }
                                              control={
                                                <Radio
                                                  classes={{
                                                    root:
                                                      classes.clickableFilterOption
                                                  }}
                                                  size="small"
                                                  color="primary"
                                                />
                                              }
                                              label={
                                                <Typography
                                                  className={
                                                    classes.filterOptionLabel
                                                  }>
                                                  {
                                                    HENSEL_PHELPS_FILTER_OPTIONS
                                                      .hasOrHasNoAttachments
                                                      .label
                                                  }
                                                </Typography>
                                              }
                                            />
                                          </RadioGroup>
                                        </FormGroup>
                                      </FormControl>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      style={{ marginTop: 24 }}>
                                      <Grid
                                        container
                                        justifyContent="flex-start">
                                        <StyledButtonSecondary
                                          label={'Close'}
                                          onClick={handleFilterMenuClose}
                                        />
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </StyledMenu>
                              )}
                              {activeTabName === TP_AHAS_TAB && (
                                <StyledMenu
                                  anchorEl={anchorEl}
                                  keepMounted
                                  open={Boolean(anchorEl)}
                                  onClose={handleFilterMenuClose}>
                                  <Grid
                                    container
                                    className={classes.filterGroupContainer}>
                                    <Grid item xs={12}>
                                      <Typography>
                                        {t('tradePartnerFilters.statusFilter')}
                                      </Typography>
                                    </Grid>
                                    <RadioGroup
                                      name="isActive"
                                      value={
                                        tradePartnerAhaListState?.filter
                                          ?.tradePartner_isActive ?? null
                                      }
                                      onChange={event =>
                                        handleRadioOptionChange(
                                          event,
                                          'isActive'
                                        )
                                      }>
                                      <FormControlLabel
                                        value={
                                          TRADE_PARTNER_ISACTIVE_FILTER_OPTIONS
                                            .active.value
                                        }
                                        control={<Radio color="primary" />}
                                        label={
                                          TRADE_PARTNER_ISACTIVE_FILTER_OPTIONS
                                            .active.label
                                        }
                                      />
                                      <FormControlLabel
                                        value={
                                          TRADE_PARTNER_ISACTIVE_FILTER_OPTIONS
                                            .inactive.value
                                        }
                                        control={<Radio color="primary" />}
                                        label={
                                          TRADE_PARTNER_ISACTIVE_FILTER_OPTIONS
                                            .inactive.label
                                        }
                                      />
                                      <FormControlLabel
                                        value={
                                          TRADE_PARTNER_ISACTIVE_FILTER_OPTIONS
                                            .allStatus.value
                                        }
                                        control={<Radio color="primary" />}
                                        label={
                                          TRADE_PARTNER_ISACTIVE_FILTER_OPTIONS
                                            .allStatus.label
                                        }
                                      />
                                    </RadioGroup>
                                  </Grid>
                                  <Grid
                                    container
                                    className={classes.filterGroupContainer}>
                                    <Grid item xs={12}>
                                      <Typography>
                                        {t(
                                          'tradePartnerFilters.projectAreaFilter'
                                        )}
                                      </Typography>
                                    </Grid>
                                    <Select
                                      className={classes.select}
                                      value={
                                        tradePartnerAhaListState?.filter
                                          ?.ahaFiles_projectArea ?? ''
                                      }
                                      onChange={event =>
                                        handleRadioOptionChange(
                                          event,
                                          'projectArea'
                                        )
                                      }
                                      displayEmpty={true}
                                      renderValue={value =>
                                        value ||
                                        t(
                                          'tradePartnerFilters.projectAreaFilterSelectPlaceholder'
                                        )
                                      }
                                      variant="outlined"
                                      margin="dense"
                                      autoWidth={false}
                                      inputProps={{
                                        classes: {
                                          select: classes.select
                                        }
                                      }}
                                      IconComponent={KeyboardArrowDownIcon}>
                                      {(
                                        safeProjectForAreasData?.safeProject
                                          ?.areas ?? []
                                      ).map((area, index) => (
                                        <MenuItem
                                          data-testid={`option-${area}`}
                                          key={index}
                                          value={area}>
                                          {area}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </Grid>
                                </StyledMenu>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Typography
                            className={classes.listCustomizationLabel}
                            color="textSecondary">
                            Sort
                          </Typography>
                          <Grid
                            container
                            direction="row"
                            alignItems="flex-end"
                            className={classes.sortContainer}>
                            <Grid item>
                              <Select
                                className={classes.select}
                                placeholder={t(
                                  'henselPhelpsSort.sortSelectPlaceholder'
                                )}
                                value={currentSortBy.value ?? ''}
                                onChange={handleSortChange}
                                variant="outlined"
                                margin="dense"
                                autoWidth={false}
                                displayEmpty
                                required
                                inputProps={{
                                  classes: {
                                    select: classes.select
                                  }
                                }}
                                IconComponent={KeyboardArrowDownIcon}>
                                {Object.values(
                                  activeTabName === HP_AHAS_TAB
                                    ? HENSEL_PHELPS_SORT_ORDER_OPTIONS
                                    : TRADE_PARTNER_SORT_ORDER_OPTIONS
                                ).map((option, index) => (
                                  <MenuItem
                                    data-testid={`option-${option.label}`}
                                    key={index}
                                    value={option.value}>
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </Grid>
                            <Grid item>
                              <Button
                                variant="outlined"
                                onClick={toggleSortDirectionChange}
                                className={classnames(
                                  classes.toggleSortDirectionButton,
                                  {
                                    [`${classes.toggleSortDirectionButtonForSafariBrowser}`]: isSafariBrowser
                                  }
                                )}>
                                {currentSortBy.direction ===
                                SORT_ORDER_DIRECTION.ascending ? (
                                  <Tooltip title={'Ascending'}>
                                    <ArrowUpwardIcon
                                      className={
                                        classes.sortDirectionButtonIcon
                                      }
                                    />
                                  </Tooltip>
                                ) : (
                                  <Tooltip title={'Descending'}>
                                    <ArrowDownwardIcon
                                      className={
                                        classes.sortDirectionButtonIcon
                                      }
                                    />
                                  </Tooltip>
                                )}
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item className={classes.chipsContainer}>
                          {activeFilters.length > 0 && (
                            <Typography
                              className={classes.activeFiltersLabel}
                              color="textSecondary">
                              Active Filters
                            </Typography>
                          )}
                          {activeFilters.map((filter, index) => (
                            <StyledChip
                              key={index}
                              className={classes.chip}
                              variant="default"
                              label={
                                <Typography className={classes.chipLabel}>
                                  {filter?.label}
                                </Typography>
                              }
                              onDelete={() =>
                                handleFilterChange(
                                  filter?.apiKey ===
                                    FILTER_API_KEYS.ahaFiles_projectArea
                                    ? null
                                    : filter?.value,
                                  filter?.apiKey ===
                                    FILTER_API_KEYS.ahaFiles_projectArea
                                    ? 'projectArea'
                                    : 'isActive'
                                )
                              }
                            />
                          ))}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Tabs
              indicatorColor="secondary"
              textColor="inherit"
              data-testid="Aha-tabs"
              value={activeTab}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="tabs"
              className={classes.tabs}>
              {tabs.map((tab, index) => (
                <Tab
                  key={index}
                  label={tab.label}
                  className={classnames(
                    classes.tab,
                    {
                      [`${classes.redTab}`]: tab.isActiveTab
                    },
                    {
                      [`${classes.whiteTab}`]: !tab.isActiveTab
                    }
                  )}
                  {...a11yProps(index)}
                />
              ))}
            </Tabs>
            <Grid container direction="column" spacing={1}>
              {tabs.map((tab, index) => (
                <TabPanel
                  key={index}
                  value={activeTab}
                  index={index}
                  role="tabpanel"
                  hidden={activeTab !== index}
                  id={`scrollable-auto-tabpanel-${index}`}
                  aria-labelledby={`scrollable-auto-tab-${index}`}>
                  {tab.content}
                </TabPanel>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <BackToTopButton />
      </div>
      <AddAhaDialog
        addHenselPhelpsAhaDialogIsOpen={addHenselPhelpsAHADialogIsOpen}
        setAddHenselPhelpsAhaDialogIsOpen={toggleAddHenselPhelpsAHADialog}
      />
      <AddTradePartnerAhaDialog
        addTradePartnerAHADialogIsOpen={addTradePartnerAHADialogIsOpen}
        toggleAddTradePartnerAHADialog={toggleAddTradePartnerAHADialog}
        refetchQuery={refetchTradePartnerTemplateCategories}
      />
    </>
  );
};

export default withOnlineAccessOnly(
  withAuthorization(AhaDashboardPage, {
    personnelOnProject: true
  })
);
