const DAILY = 'Daily';
const WEEKLY = 'Weekly';
const MONTHLY = 'Monthly';

export const ALLOWED_ORIENTATION_TYPES = [DAILY, WEEKLY, MONTHLY];

export const ORIENTATION_TYPE_OPTIONS = [
  { label: 'Does not repeat', value: 'Does not repeat' },
  { label: DAILY, value: DAILY },
  { label: WEEKLY, value: WEEKLY },
  { label: MONTHLY, value: MONTHLY }
];

export const ORIENTATION_SERIES_END_DATE_OPTIONS = [
  { label: '3 months', value: 3 },
  { label: '6 months', value: 6 },
  { label: '9 months', value: 9 },
  { label: '12 months', value: 12 }
];
