import React, { useState, Fragment } from 'react';
import { animateScroll as scroll } from 'react-scroll';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import makeStyles from '@mui/styles/makeStyles';
import { IconButton, Grid } from '@mui/material';

const useStyles = makeStyles(theme => ({
  container: { position: 'fixed', bottom: theme.spacing(1.5) },
  button: { backgroundColor: theme.palette.primary.main, padding: 0 },
  icon: { color: theme.palette.primary.contrastText, fontSize: 40 }
}));

const BackToTopButton = () => {
  const classes = useStyles();
  const [shouldShow, setShouldShow] = useState(false);

  useScrollPosition(
    ({ _, currPos }) => {
      const isShowing = currPos.y < -500;
      if (isShowing !== shouldShow) {
        setShouldShow(isShowing);
      }
    },
    [shouldShow, setShouldShow],
    null,
    false,
    300
  );

  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  return (
    <Fragment>
      {shouldShow && (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignContent="center"
          alignItems="center"
          className={classes.container}>
          <Grid item>
            <IconButton
              data-testid="back-to-top-button"
              variant="contained"
              color="primary"
              onClick={scrollToTop}
              className={classes.button}
              size="large">
              <KeyboardArrowUpIcon className={classes.icon} />
            </IconButton>
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
};

export default BackToTopButton;
