import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useApolloClient } from '@apollo/react-hooks';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SettingsIcon from '@mui/icons-material/Settings';
import makeStyles from '@mui/styles/makeStyles';
import { IconButton, Menu, Typography, Grid, Link } from '@mui/material';

import useConfirmDialog from 'hooks/useConfirmDialog';
import useCurrentUser from 'hooks/useCurrentUser';
import AdAvatar from 'components/ad/AdAvatar';
import useSoteriaApolloClient from 'config/apolloConfig';
import { useMsal } from '@azure/msal-react';
import { domain, Domain } from 'config/domain';

const useStyles = makeStyles(theme => ({
  container: {},
  menu: { zIndex: '2000 !important' },
  userNameText: { fontWeight: 'bold', fontSize: '0.875rem' },
  userUpnText: {
    width: '200px',
    fontSize: '0.75rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  text: { fontSize: '0.75rem', color: theme.palette.background.callToAction },
  appNameText: { textTransform: 'uppercase', fontSize: '0.75rem' },
  icon: { fontSize: '1rem', color: theme.palette.background.callToAction },
  link: { '&:hover': { cursor: 'pointer' } }
}));

const UserMenu = ({ toggleSettingsDialog, iconProps }) => {
  const classes = useStyles();
  const confirmAction = useConfirmDialog();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const { currentUser } = useCurrentUser();
  const client = useApolloClient();
  const { purgeOfflineCache } = useSoteriaApolloClient();
  const { instance: msalInstance } = useMsal();
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSettingsClick = () => {
    toggleSettingsDialog(true);
    handleClose();
  };

  // get the current project the use is on to save to local storage
  const LAST_PROJECT_KEY = 'lastProjectVisited';
  const match = useRouteMatch('/projects/:projectId');
  const projectId =
    match && match.params && match.params.projectId
      ? match.params.projectId
      : null;

  const handleLogOutClick = () => {
    handleClose();
    confirmAction(
      t('logOutConfirmDialog.title'),
      t('logOutConfirmDialog.subTitle'),
      t('logOutConfirmDialog.cancelButton'),
      t('logOutConfirmDialog.logOutButton')
    ).then(
      async result => {
        if (projectId !== null) {
          localStorage.setItem(
            LAST_PROJECT_KEY,
            JSON.stringify({ projectId: projectId })
          );
        }
        await client.clearStore();
        await purgeOfflineCache();
        await msalInstance.logoutRedirect({
          account: msalInstance.getActiveAccount()
        });
      },
      () => {
        // cancel was pressed
      }
    );
  };

  return (
    <Fragment>
      {currentUser && (
        <IconButton
          data-testid="user-menu-button"
          onClick={handleClick}
          size="large"
          {...iconProps}>
          <AdAvatar adContact={currentUser} size="small" />
        </IconButton>
      )}
      <Menu
        PaperProps={{
          style: { width: 300, height: 180, padding: '16px' },
          'data-testid': 'userMenu'
        }}
        className={classes.menu}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <Grid
          container
          direction="column"
          className={classes.container}
          spacing={4}>
          <Grid item>
            <Grid container direction="row" justifyContent="space-between">
              <Grid item>
                <Typography className={classes.appNameText}>
                  Safety Connect
                </Typography>
              </Grid>
              <Grid item>
                <Link
                  aria-label={'log out of safetyconnect.com'}
                  tabIndex="0"
                  onKeyDown={handleLogOutClick}
                  onClick={handleLogOutClick}
                  underline="hover">
                  <Typography className={classes.text}>
                    {t('navigation.appLogOut')}
                  </Typography>
                </Link>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="row" alignItems="center" spacing={1}>
              <Grid item>
                <AdAvatar adContact={currentUser ?? {}} />
              </Grid>
              <Grid item>
                <Grid container direction="column">
                  <Grid item>
                    <Typography className={classes.userNameText}>
                      {currentUser?.name}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography gutterBottom className={classes.userUpnText}>
                      {domain === Domain.PARTNER
                        ? currentUser?.email
                        : currentUser?.upn}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      direction="row"
                      spacing={1}
                      alignItems="center">
                      <Grid item>
                        <SettingsIcon className={classes.icon} />
                      </Grid>
                      <Grid item>
                        <Link
                          className={classes.link}
                          aria-label={'open the app settings menu'}
                          tabIndex="0"
                          onKeyDown={handleSettingsClick}
                          onClick={handleSettingsClick}
                          underline="hover">
                          <Typography className={classes.text}>
                            {t('navigation.appSettings')}
                          </Typography>
                        </Link>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Menu>
    </Fragment>
  );
};

UserMenu.propTypes = {
  toggleSettingsDialog: PropTypes.func.isRequired,
  iconProps: PropTypes.any
};

export default UserMenu;
