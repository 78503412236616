import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import OfflineIcon from '@mui/icons-material/CloudOff';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  offlineContainer: {
    backgroundColor: theme.palette.secondary.light,
    marginTop: 112,
    padding: theme.spacing(0.5, 1, 0, 1),
    boxShadow: '0 8px 6px -6px black',
    [theme.breakpoints.down('md')]: { marginTop: 56 }
  },
  offlineContainerWithButton: {
    backgroundColor: theme.palette.secondary.light,
    marginTop: 112,
    padding: theme.spacing(1, 1, 0.75, 1),
    boxShadow: '0 8px 6px -6px black',
    [theme.breakpoints.down('md')]: { marginTop: 56 }
  },
  icon: {
    color: theme.palette.secondary.contrastText,
    marginLeft: 24,
    [theme.breakpoints.down('md')]: { marginLeft: 0 }
  },
  label: {
    fontWeight: 'bold',
    display: 'inline',
    color: theme.palette.secondary.contrastText
  },
  offlineMessage: {
    fontWeight: 'bold',
    fontSize: '0.75rem',
    color: theme.palette.secondary.contrastText
  },
  buttonContainer: {
    marginLeft: 10
  },
  messageContainer: {
    width: 'auto'
  },
  leftHandContainer: {
    width: '100%',
    flexWrap: 'nowrap'
  }
}));

const StyledOfflineBanner = ({ content }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      className={classes.offlineContainer}>
      <Grid item>
        <Grid
          container
          direction="row"
          spacing={1}
          alignItems="center"
          className={classes.leftHandContainer}>
          <Grid item>
            <OfflineIcon className={classes.icon} />
          </Grid>
          <Grid item>
            <Typography className={classes.label}>
              {t('offlineBanner.offline')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container alignItems="center" className={classes.messageContainer}>
        <Grid item>
          <Typography className={classes.offlineMessage}>{content}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

StyledOfflineBanner.propTypes = {
  content: PropTypes.any
};

export default StyledOfflineBanner;
