import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import makeStyles from '@mui/styles/makeStyles';
import { IconButton, Menu, MenuItem, Typography, Tooltip } from '@mui/material';

import useHelpAndSupport from 'hooks/useHelpAndSupport';
import VersionDialog from '../VersionDialog';
import { SERVICENOW_URL } from 'constants/externalLinks';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  icon: { color: '#fff' },
  menu: { zIndex: '2000 !important' },
  menuListItem: { width: theme.spacing(25) },
  menuItemIcon: { fontSize: '1rem' }
}));

const HelpMenu = ({ iconProps }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { toggleHelp } = useHelpAndSupport();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTutorialsClick = () => {
    toggleHelp(true);
    handleClose();
  };

  const handleCheckForUpdatesClick = () => {
    setIsOpen(true);
    handleClose();
  };

  return (
    <Fragment>
      <Tooltip title={t('helpMenu.tooltip')}>
        <IconButton
          data-testid="helpIconButton"
          aria-label="open the help menu"
          onClick={handleClick}
          size="large"
          {...iconProps}>
          <HelpOutlineIcon className={classes.icon} />
        </IconButton>
      </Tooltip>
      <Menu
        className={classes.menu}
        MenuListProps={{ 'data-testid': 'helpMenu' }}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <MenuItem onClick={handleCheckForUpdatesClick}>
          <Typography>{t('helpMenu.checkForVersionUpdates')}</Typography>
        </MenuItem>
        <MenuItem
          className={classes.menuListItem}
          onClick={() => {
            window.open(SERVICENOW_URL, '_blank', 'noopener');
            handleClose();
          }}>
          <Typography>{t('helpMenu.support')}</Typography>
        </MenuItem>
        <MenuItem
          className={classes.menuListItem}
          onClick={() => {
            handleClose();
            window.open(SERVICENOW_URL, '_blank', 'noopener');
          }}>
          <Typography>{t('helpMenu.reportBug')}</Typography>
        </MenuItem>
        <MenuItem
          className={classes.menuListItem}
          onClick={() => {
            window.open(SERVICENOW_URL, '_blank', 'noopener');
            handleClose();
          }}>
          <Typography>{t('helpMenu.suggestion')}</Typography>
        </MenuItem>
        <MenuItem onClick={handleTutorialsClick}>
          <Typography>{t('helpMenu.tutorials')}</Typography>
        </MenuItem>
      </Menu>
      <VersionDialog isOpen={isOpen} setIsOpen={setIsOpen} />
    </Fragment>
  );
};

HelpMenu.propTypes = {
  iconProps: PropTypes.any
};

export default HelpMenu;
