import { useContext, useMemo } from 'react';
import EnabledFeaturesContext from 'context/enabledFeatures';
import CanaryFeaturesContext from 'context/canaryFeatures';
import useRoles from 'hooks/useRoles';

const useIsFeatureEnabledForCurrentUser = feature => {
  const { enabledFeatures } = useContext(EnabledFeaturesContext);
  const { canaryFeatures } = useContext(CanaryFeaturesContext);
  const { isCanary } = useRoles();

  return useMemo(() => {
    return enabledFeatures[feature] || (isCanary && canaryFeatures[feature]);
  }, [enabledFeatures, canaryFeatures, feature, isCanary]);
};

export default useIsFeatureEnabledForCurrentUser;
