import gql from 'graphql-tag';
import { getCachedUrl, getCachedFile } from 'utils';

export const typeDefs = gql`
  extend type SoteriaFile {
    cachedUrl: String @requires(fields: "url")
    cachedFile: String @requires(fields: "url")
  }
`;

// Resolvers
export const resolvers = {
  SoteriaFile: {
    cachedUrl: async soteriaFile => {
      // return null;
      return await getCachedUrl(soteriaFile.url);
    },
    cachedFile: async soteriaFile => {
      // return null;
      return await getCachedFile(
        soteriaFile.url,
        soteriaFile.name,
        soteriaFile.contentType
      );
    }
  }
};
