import { useTranslation } from 'react-i18next';
import moment from 'moment';

import useStringIncludesHenselPhelps from 'hooks/useStringIncludesHenselPhelps';
import { resolveDate, isValidPhoneNumber } from 'utils';

const emailValidator = require('email-validator');
const urlValidator = require('valid-url');

const useFormValidation = () => {
  const { t } = useTranslation();
  const { stringIncludesHenselPhelps } = useStringIncludesHenselPhelps();

  const isRequired = value => {
    return value ? undefined : t('formValidation.required');
  };

  const isEmail = value => {
    if (value) {
      return emailValidator.validate(value)
        ? undefined
        : t('formValidation.isInvalidEmail');
    }
    return undefined;
  };

  const isUrl = value => {
    if (value) {
      return urlValidator.isUri(value)
        ? undefined
        : t('formValidation.isInvalidUrl');
    }
    return undefined;
  };

  const isPositiveNumber = value => {
    if (value && parseFloat(value) < 0) {
      return t('formValidation.isNotPositiveNumber');
    }
    return undefined;
  };

  const containsHenselPhelps = value => {
    if (stringIncludesHenselPhelps(value)) {
      return t('formValidation.containsHenselPhelps');
    }
    return undefined;
  };

  const isRequiredOrDoesNotContainHenselPhelps = value => {
    return isRequired(value) ?? containsHenselPhelps(value);
  };

  const isNotFutureDate = value => {
    const now = new Date();
    if (resolveDate(value) > now) {
      return 'Future dates are not allowed';
    }
    return undefined;
  };

  const isValidDate = value => {
    if (!value) {
      return t('formValidation.required');
    }
    const isMomentObject = moment.isMoment(value);
    if (isMomentObject) {
      const isValidDate = value.isValid();
      if (!isValidDate) {
        return 'This date is invalid';
      }

      return undefined;
    }
  };

  const isValidPastDate = value => {
    return isValidDate(value) ?? isNotFutureDate(value);
  };

  const isValidTime = value => {
    if (!value) {
      return t('formValidation.required');
    }
    const isMomentObject = moment.isMoment(value);
    if (isMomentObject) {
      const isValidTime = value.isValid();
      if (!isValidTime) {
        return 'The time entered is invalid';
      }

      return undefined;
    }
  };

  const isPhoneNumber = value => {
    if (value && value !== '+') {
      if (!isValidPhoneNumber(value)) {
        return 'This phone number is invalid';
      }
    }
    return undefined;
  };

  return {
    isRequired,
    isEmail,
    isUrl,
    isPositiveNumber,
    isRequiredOrDoesNotContainHenselPhelps,
    isValidDate,
    isValidPastDate,
    isValidTime,
    isPhoneNumber
  };
};

export default useFormValidation;
