import React from 'react';
import { Grid, Typography, Divider } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  subTitle: { fontSize: '0.875rem' },
  title: { fontSize: '1.5rem' },
  bold: { fontWeight: 'bold' },
  notice: {
    borderLeft: '6px solid' + theme.palette.secondary.dark,
    backgroundColor: theme.palette.secondary.main,
    padding: theme.spacing(2)
  },
  accessNoticeContent: { fontSize: '0.75rem' }
}));

const Roles = () => {
  const classes = useStyles();
  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography className={classes.subTitle} color="textSecondary">
          Permissions Tutorial
        </Typography>
        <Typography className={classes.title} color="textPrimary">
          Roles
        </Typography>
      </Grid>
      <Grid item>
        <Typography color="textPrimary">
          Safety Connect permissions are distributed between two user roles.
          Some functions in Safety Connect may be limited to Enterprise Admins
          only. Look for the access notifications to determine which actions may
          have restricted access.
        </Typography>
      </Grid>
      <Grid item>
        <Typography color="textPrimary" className={classes.bold}>
          Enterprise Admin
        </Typography>
      </Grid>
      <Grid item>
        <Typography color="textPrimary">
          The following job titles have been assigned the permissions role of
          Enterprise Admin:
        </Typography>
      </Grid>
      <Grid item>
        <Grid container className={classes.notice} direction="column">
          <Grid item>
            <Typography className={classes.accessNoticeContent}>
              Corporate Director of Safety and Health, Director of Safety and
              Health, Executive Director, Regional Vice President, Operations
              Manager, Facility Services Coordinator, General Superintendent,
              Senior Safety Manager, Safety Manager, Office Administrator
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Divider></Divider>
      </Grid>
      <Grid item>
        <Typography color="textPrimary" className={classes.bold}>
          Project Admin
        </Typography>
      </Grid>
      <Grid item>
        <Typography color="textPrimary">
          The following job titles have been assigned the ability to become
          Project Admins through permissions requesting and approval:
        </Typography>
      </Grid>
      <Grid item>
        <Grid container className={classes.notice} direction="column">
          <Grid item>
            <Typography className={classes.accessNoticeContent}>
              Field Engineer, Office Engineer, Safety Engineer, Project
              Engineer, Lead Quality Control Engineer, Quality Control Engineer,
              Quality Control Manager, Area Superintendent, Project
              Superintendent, Project Manager, Senior Project Manager
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Roles;
