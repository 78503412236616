import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSubscription } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography } from '@mui/material';

import useAttendee from 'hooks/useAttendee';
import useRoles from 'hooks/useRoles';
import withAuthorization from 'hocs/withAuthorization';
import withOnlineAccessOnly from 'hocs/withOnlineAccessOnly';
import LoadingSpinner from 'components/common/LoadingSpinner';
import ErrorNotice from 'components/common/ErrorNotice';
import PageNotFoundNotice from 'components/common/PageNotFoundNotice';
import AttendeeHeader from 'components/attendees/AttendeeHeader';
import AttendeeInfoCard from 'components/attendees/AttendeeInfoCard';
import AttendeeJobHistoryList from 'components/attendees/AttendeeJobHistoryList';
import AttendeeTrainingList from 'components/attendees/AttendeeTrainingList';
import AttendeeFlags from 'components/attendees/AttendeeFlags';
import AttendeeDeleteCard from 'components/attendees/AttendeeDeleteCard';
import { ATTENDEE_SUBSCRIPTION } from 'graphql/attendee';

const useStyles = makeStyles(theme => ({
  title: { fontSize: '2rem' }
}));

const AttendeePage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { attendeeId } = useParams();
  const { isSupportRole } = useRoles();
  const { attendee, error, loading: isLoading } = useAttendee(attendeeId);

  useSubscription(ATTENDEE_SUBSCRIPTION, {
    variables: { attendeeId }
  });

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error) {
    if (error.message?.includes('not found')) {
      return <PageNotFoundNotice />;
    } else {
      return <ErrorNotice />;
    }
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography color="textPrimary" className={classes.title}>
            {t('orientationAttendeePage.pageTitle')}
          </Typography>
        </Grid>
        <AttendeeHeader attendee={attendee} />
        <Grid container direction="row" spacing={3}>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <Grid container direction="column" spacing={3}>
              <Grid item>
                <AttendeeInfoCard attendee={attendee} />
              </Grid>
              <Grid item>
                <AttendeeJobHistoryList
                  attendeeJobHistory={attendee.personnel?.tradePartnerHistory}
                />
              </Grid>
              <Grid item>
                <AttendeeTrainingList attendee={attendee} tableType="new" />
              </Grid>
              <Grid item>
                <AttendeeTrainingList
                  attendee={attendee}
                  tableType="historical"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Grid container direction="column" spacing={3}>
              <Grid item>
                <AttendeeFlags attendee={attendee} tableType="new" />
              </Grid>
              {isSupportRole && (
                <Grid item>
                  <AttendeeDeleteCard attendee={attendee} />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default withOnlineAccessOnly(withAuthorization(AttendeePage));
