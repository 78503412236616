import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography, Container } from '@mui/material';
import { useParams } from 'react-router-dom';

import useRoles from 'hooks/useRoles';
import RequestProjectAccess from 'components/project/RequestProjectAccess';
import StyledUserNoticeTemplate from 'components/common/UserNoticeTemplate';

const useStyles = makeStyles(theme => ({
  heading: { fontSize: '2rem', fontWeight: 'bold' },
  subHeading: { marginBottom: theme.spacing(1) },
  img: { width: 200, padding: theme.spacing(3) },
  icon: { margin: theme.spacing(0, 1, 0.5, 0) }
}));

const UnauthorizedPageNotice = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const { projectId } = useParams();
  const { isRoamingAdmin } = useRoles();
  const isProjectPage = !!projectId;

  return (
    <StyledUserNoticeTemplate
      image={
        <img
          className={classes.img}
          src={theme.unauthorizedAccess}
          alt=""
          role="presentation"
        />
      }
      content={
        <Container maxWidth="sm">
          <Grid container justifyContent="center" alignItems="center">
            <Grid item>
              <Typography gutterBottom className={classes.heading}>
                {t('unauthorizedPageNotice.heading')}
              </Typography>
            </Grid>
          </Grid>
          {isProjectPage &&
            t('unauthorizedProjectPageNotice.subHeading')
              .split('\n')
              .map(line => (
                <Typography className={classes.subHeading} key={line}>
                  {line}
                </Typography>
              ))}
          {!isProjectPage &&
            t('unauthorizedPageNotice.subHeading')
              .split('\n')
              .map(line => (
                <Typography className={classes.subHeading} key={line}>
                  {line}
                </Typography>
              ))}
          {isProjectPage && isRoamingAdmin && <RequestProjectAccess />}
        </Container>
      }
    />
  );
};

export default UnauthorizedPageNotice;
