import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import PictureAsPdf from '@mui/icons-material/PictureAsPdf';
import ErrorIcon from '@mui/icons-material/Error';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Tooltip, Typography, IconButton } from '@mui/material';
import SoteriaFileDisplayImage from 'components/common/SoteriaFileDisplayImage';
import { fileIsImage, fileIsPdf } from 'utils';
import { MAX_FILE_SIZE_IN_BYTES } from 'constants/maxFileSize';

const useStyles = makeStyles(theme => ({
  desktopInstructions: { [theme.breakpoints.down('lg')]: { display: 'none' } },
  mobileInstructions: {
    textAlign: 'center',
    padding: theme.spacing(2),
    [theme.breakpoints.up('md')]: { display: 'none' }
  },
  dropzone: {
    width: '100%',
    height: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    outline: 'none',
    border: '2px dashed' + theme.palette.secondary.dark,
    borderRadius: 4,
    '&:hover': { cursor: 'pointer' }
  },
  container: {
    width: 'inherit',
    height: 100,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.dark,
    outline: 'none',
    transition: 'border .24s ease-in-out',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  fileUploadErrorContainer: { color: theme.palette.error.main },
  fileUploadErrorDropzone: {
    color: theme.palette.error.main,
    border: '2px dashed' + theme.palette.error.main
  },
  uploadedFileContainer: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
    borderRadius: 4,
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      justifyContent: 'space-between'
    }
  },
  uploadedFilesLabel: {
    fontSize: '0.75rem',
    fontWeight: 'bold',
    marginTop: theme.spacing(2)
  },
  pdfIcon: { marginRight: theme.spacing(2) }
}));

const generateFilePreviews = files => {
  const filesWithPreviews = files.map(file => {
    if (file.type && file.type !== 'application/pdf') {
      Object.assign(file, {
        preview: URL.createObjectURL(file)
      });
      return file;
    }
    return files;
  });
  return filesWithPreviews;
};

const AddFile = ({
  fileDataFromDb,
  setAddedFiles,
  addedFiles,
  existingFiles,
  setFilesToDelete,
  filesToDelete,
  maxNumFiles = 0
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [fileUploadError, setFileUploadError] = useState(null);
  // All newly uploaded files have working image link while submitting to the API is loading
  const addedFilesWithPreviews = addedFiles.length
    ? generateFilePreviews(addedFiles)
    : [];
  const [allFiles, setAllFiles] = useState([
    ...addedFilesWithPreviews,
    ...existingFiles
  ]);

  const isDropzoneDisabled =
    maxNumFiles !== 0 && addedFiles.length === maxNumFiles;

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*, .pdf, .doc, .docx, .xls, .xlsx',
    multiple: false,
    maxFiles: maxNumFiles,
    disabled: isDropzoneDisabled,
    maxSize: MAX_FILE_SIZE_IN_BYTES,
    onDropAccepted: acceptedFile => {
      setFileUploadError(null);
      validateFiles(allFiles, acceptedFile[0]);
    },
    onDropRejected: rejectedFile => {
      const errorMessage = rejectedFile[0].errors[0].code;
      if (errorMessage === 'file-too-large') {
        setFileUploadError(
          t('addFile.largeFileError', {
            fileSize: Intl.NumberFormat(i18next.language, {
              style: 'unit',
              unit: 'megabyte'
            }).format(MAX_FILE_SIZE_IN_BYTES / 1000000)
          })
        );
        return;
      }
      setFileUploadError(t('addFile.generalFileUploadError'));
    }
  });

  // delete photo from UI that has not been sent to API yet
  const deleteLocalFile = fileName => {
    const allFilesExceptThisOne = allFiles.filter(
      file => file.name !== fileName
    );
    const addedFilesExceptThisOne = addedFiles.filter(
      file => file.name !== fileName
    );
    setAllFiles(allFilesExceptThisOne);
    setAddedFiles(addedFilesExceptThisOne);
    return;
  };

  const deleteExistingFile = id => {
    const updatedFiles = allFiles.filter(file => file.id !== id);
    setAllFiles(updatedFiles.length ? [...updatedFiles] : []);
    setFilesToDelete([...filesToDelete, { id: id }]);
  };

  const formatFile = file => {
    const fileWithPreview = generateFilePreviews([file]);
    if (!file.path) {
      Object.assign(fileWithPreview[0], {
        path: file.name
      });
    }
    return fileWithPreview[0];
  };

  const validateFiles = (filesArray, fileToCheck, counter = 1) => {
    const getNewFileName = oldFileName => {
      return (
        oldFileName.substring(0, oldFileName.lastIndexOf('.')) +
        ' (' +
        counter +
        ')' +
        oldFileName.substring(oldFileName.lastIndexOf('.'))
      );
    };

    const updateFileNameNumber = oldFileName => {
      return (
        oldFileName.substring(0, oldFileName.lastIndexOf('(')) +
        ' (' +
        counter +
        ')' +
        oldFileName.substring(oldFileName.lastIndexOf('.'))
      );
    };

    // if name matches, increment number and call fn again
    // if no matches exist, then create the next name iteration and return file object
    if (filesArray.some(file => file.name === fileToCheck.name)) {
      let newFileName;
      if (counter > 1) {
        newFileName = updateFileNameNumber(fileToCheck.name);
      } else {
        newFileName = getNewFileName(fileToCheck.name);
      }

      var blob = fileToCheck.slice(0, fileToCheck.size, fileToCheck.type);
      const newFile = new File([blob], newFileName, {
        size: fileToCheck.size,
        type: fileToCheck.type
      });
      validateFiles(filesArray, newFile, counter + 1);
    } else {
      const formattedFile = formatFile(fileToCheck);
      const allFormattedFiles = [...addedFiles, formattedFile];
      setAddedFiles(allFormattedFiles.flat());
      setAllFiles([...allFiles, formattedFile].flat());
      return;
    }
  };

  const RotateImage = (direction, file) => {
    if (direction !== 'left' && direction !== 'right') {
      return;
    }

    const blobFromFile = file.slice(0, file.size, file.type);
    const reader = new FileReader();
    const image = new Image();
    const canvas = document.createElement('canvas');

    //Convert Blob to base 64 image
    reader.readAsDataURL(blobFromFile);
    reader.onloadend = () => {
      const base64data = reader.result;
      image.src = base64data;
      image.onload = () => {
        rotateImageNinetyDegrees(direction, image, file, canvas);
        canvas.toBlob(blob => {
          createAndAddNewFileFromBlob(blob, file);
        });
      };
    };
  };

  const rotateImageNinetyDegrees = (direction, image, file, canvas) => {
    if (direction !== 'left' && direction !== 'right') {
      return;
    }

    const maxDim = Math.max(image.height, image.width);
    canvas.width = image.width;
    canvas.height = image.height;
    const ctx = canvas.getContext('2d');
    ctx.setTransform(1, 0, 0, 1, maxDim / 2, maxDim / 2);
    ctx.rotate((direction === 'left' ? -90 : 90) * (Math.PI / 180));
    ctx.drawImage(image, -maxDim / 2, -maxDim / 2);
    canvas.toDataURL(`image/${file.type}`);
  };

  const createAndAddNewFileFromBlob = (blob, file) => {
    const newFile = new File([blob], file.name, {
      size: file.size,
      type: file.type
    });
    Object.assign(newFile, {
      preview: URL.createObjectURL(newFile)
    });
    updateAddFilesAndAllFilesWithNewFile(newFile);
  };

  const updateAddFilesAndAllFilesWithNewFile = newFile => {
    const formattedFile = formatFile(newFile);
    const addedFilesExceptThisOne = addedFiles.filter(
      file => file.name !== newFile.name
    );
    const allFilesExceptThisOne = allFiles.filter(
      file => file.name !== newFile.name
    );
    setAddedFiles([...addedFilesExceptThisOne, formattedFile]);
    setAllFiles([...allFilesExceptThisOne, formattedFile]);
  };

  const thumbs = allFiles.map((file, index) => {
    const canRotateImage =
      file.type &&
      file.type.indexOf('image/') === 0 &&
      file.type !== 'image/gif';

    return (
      <Grid item key={index} xs={12}>
        <Grid
          container
          direction="row"
          alignItems="center"
          className={classes.uploadedFileContainer}>
          <Grid item>
            <Grid container direction="row" alignItems="center">
              {fileIsImage(file) && (
                <Grid item xs={12}>
                  <SoteriaFileDisplayImage file={file} />
                </Grid>
              )}
              <Grid item>
                {fileIsPdf(file) && !file.isMalware && (
                  <PictureAsPdf className={classes.pdfIcon} />
                )}
                {fileIsPdf(file) && file.isMalware && (
                  <ErrorIcon className={classes.pdfIcon} />
                )}
              </Grid>
              <Grid item>
                <Tooltip title={file.name}>
                  <Typography>{file.name}</Typography>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            {canRotateImage && (
              <>
                <IconButton
                  size="small"
                  onClick={() => RotateImage('left', file)}>
                  <RotateLeftIcon />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => RotateImage('right', file)}>
                  <RotateRightIcon />
                </IconButton>
              </>
            )}
            <IconButton
              size="small"
              onClick={() =>
                file.id
                  ? deleteExistingFile(file.id)
                  : deleteLocalFile(file.name)
              }>
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    );
  });

  return (
    <>
      <section
        className={classnames(classes.container, {
          [`${classes.fileUploadErrorContainer}`]: fileUploadError
        })}>
        <div
          className={classnames(classes.dropzone, {
            [`${classes.fileUploadErrorDropzone}`]: fileUploadError
          })}
          {...getRootProps()}>
          <input {...getInputProps()} />
          {fileUploadError && <p>{fileUploadError}</p>}
          {!fileUploadError && (
            <>
              <Typography
                color="textSecondary"
                className={classes.desktopInstructions}>
                {!isDropzoneDisabled
                  ? t('addFile.uploadImage')
                  : t('addFile.dropzoneDisabled')}
              </Typography>
              <Typography
                color="textSecondary"
                className={classes.mobileInstructions}>
                {!isDropzoneDisabled
                  ? t('addFile.takePhoto')
                  : t('addFile.dropzoneDisabled')}
              </Typography>
            </>
          )}
        </div>
      </section>
      {thumbs.length > 0 && (
        <Grid container>
          {addedFiles.length > 0 && (
            <Grid item>
              <Typography
                color="textSecondary"
                className={classes.uploadedFilesLabel}>
                {t('addFile.uploadedFiles')}
              </Typography>
            </Grid>
          )}
          {thumbs}
        </Grid>
      )}
    </>
  );
};

AddFile.propTypes = {
  fileDataFromDb: PropTypes.array,
  setAddedFiles: PropTypes.func,
  addedFiles: PropTypes.array,
  existingFiles: PropTypes.array,
  actionType: PropTypes.string,
  setFilesToDelete: PropTypes.func,
  filesToDelete: PropTypes.array,
  maxNumFiles: PropTypes.number
};

export default AddFile;
