import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { Typography, Grid, CircularProgress } from '@mui/material';

import AdAvatar from 'components/ad/AdAvatar';

const useStyles = makeStyles(theme => ({
  adminInfoContainer: {
    borderRadius: 4,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default
  },
  nameText: {
    fontWeight: 'bold',
    maxWidth: 370,
    [theme.breakpoints.down('xl')]: { maxWidth: 280 },
    [theme.breakpoints.down('lg')]: { maxWidth: 250 },
    [theme.breakpoints.down('md')]: { maxWidth: 200 }
  },
  titleText: { fontSize: '0.875rem' },
  linkText: {
    color: theme.palette.background.callToAction,
    fontSize: '0.875rem'
  }
}));

export const AdUserPermissionCard = ({
  user,
  actions,
  isLoading,
  azureClientId = process.env.REACT_APP_AZURE_CLIENT_ID
}) => {
  const classes = useStyles();

  const getMailTo = email => {
    return `mailto: ${email}`;
  };

  return (
    <Fragment>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className={classes.adminInfoContainer}>
        <Grid item xs={10}>
          <Grid container direction="row" spacing={1} alignItems="center">
            <Grid
              item
              sx={{
                display: { xs: 'none', sm: 'block' }
              }}>
              <AdAvatar adContact={user} azureClientId={azureClientId} />
            </Grid>
            <Grid item>
              <Typography className={classes.nameText} color="textPrimary">
                {user.name}
              </Typography>
              <Typography className={classes.titleText} color="textPrimary">
                {user.jobTitle}
              </Typography>
              <Typography component="span" noWrap={true}>
                <a href={getMailTo(user.email)} className={classes.linkText}>
                  {user.email}
                </a>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <Grid container>
            {isLoading && (
              <Grid item>
                <CircularProgress color="primary" />
              </Grid>
            )}
            {!isLoading && <Grid item>{actions}</Grid>}
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

AdUserPermissionCard.propTypes = {
  user: PropTypes.object,
  actions: PropTypes.node,
  isLoading: PropTypes.bool,
  azureClientId: PropTypes.string
};

export default AdUserPermissionCard;
