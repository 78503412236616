import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Fuse from 'fuse.js';
import { useTranslation } from 'react-i18next';

import StyledAutoComplete from 'shared/AutoComplete';

const getFilteredOptions = (
  options,
  inputValue,
  shouldFilterOutHenselPhelps
) => {
  if (shouldFilterOutHenselPhelps) {
    options = options.filter(
      option => option.name.toLowerCase() !== 'hensel phelps'
    );
  }
  if (inputValue) {
    const fuse = new Fuse(options, fuseOptions);
    return fuse.search(inputValue);
  } else {
    return options
      .map((option, index) => ({
        item: option,
        score: 1,
        refIndex: index
      }))
      .sort((a, b) =>
        a.item.name?.toLowerCase() > b.item.name?.toLowerCase() ? 1 : -1
      );
  }
};

const fuseOptions = {
  isCaseSensitive: false,
  includeScore: true,
  shouldSort: true,
  keys: ['name']
};

const SafeTradePartnerPersonnelSelect = ({
  selectedTradePartner,
  handleChange,
  tradePartnerOptions,
  isLoading,
  isRequired = false,
  isDisabled,
  placeholder,
  label,
  shouldFilterOutHenselPhelps = false,
  ...rest
}) => {
  const { t } = useTranslation();
  const [inputValue, handleInputChange] = useState('');

  const filteredOptions = tradePartnerOptions
    ? getFilteredOptions(
        tradePartnerOptions,
        inputValue,
        shouldFilterOutHenselPhelps
      )
    : [];

  return (
    <StyledAutoComplete
      label={label}
      placeholder={placeholder ?? t('tradePartnerSelectPlaceholder')}
      selectedValue={selectedTradePartner}
      handleInputChange={handleInputChange}
      handleChange={handleChange}
      options={filteredOptions.map(option => ({
        label: option.item.name,
        value: option.item.id,
        ...option.item
      }))}
      isRequired={isRequired}
      isDisabled={isDisabled}
      isLoading={isLoading}
      shouldFilterOutHenselPhelps={shouldFilterOutHenselPhelps}
      {...rest}
    />
  );
};

SafeTradePartnerPersonnelSelect.propTypes = {
  selectedTradePartner: PropTypes.any,
  handleChange: PropTypes.func.isRequired,
  tradePartnerOptions: PropTypes.array,
  isLoading: PropTypes.bool,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  shouldFilterOutHenselPhelps: PropTypes.bool
};

export default SafeTradePartnerPersonnelSelect;
