import gql from 'graphql-tag';

// Queries
export const GET_ONBOARDING_APPLICANTS = gql`
  query GetOnboardingApplicants($search: String!, $first: Int) {
    onboardingApplicants(search: $search, first: $first) {
      __typename
      id
      name
      firstName
      lastName
      email
      phone
    }
  }
`;
