/* eslint-disable react/display-name */
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cloneDeep from 'clone-deep';
import classNames from 'classnames';
import LoadingOverlay from 'react-loading-overlay';
import { useQuery } from '@apollo/react-hooks';
import { useParams, useHistory } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Typography } from '@mui/material';

import useDebounce from 'hooks/useDebounce';
import withOnlineAccessOnly from 'hocs/withOnlineAccessOnly';
import withAuthorization from 'hocs/withAuthorization';
import StyledTable from 'shared/Table';
import AddProjectPersonnelDialog from 'components/personnel/AddProjectPersonnelDialog';
import ActiveOrInactiveStatusContent from 'components/common/ActiveOrInactiveStatusContent';
import ErrorNotice from 'components/common/ErrorNotice';
import PageNotFoundNotice from 'components/common/PageNotFoundNotice';
import StyledTableCellContent from 'components/common/StyledTableCellContent';
import usePersonnelListPageTableState from 'store/personnelListPageTableState';
import { ORIENTATION_STATUSES } from 'constants/orientationStatuses';
import { GET_PAGINATED_TRADE_PARTNER_PERSONNEL_FOR_PROJECT } from 'graphql/tradePartnerPersonnel';
import useLazyQueryPromise from 'hooks/useLazyQueryPromise';
import { serveCsv, CSV_PAGES } from '../../../utils/csv';
import useToast from 'hooks/useToast';

const useStyles = makeStyles(theme => ({
  greenText: { color: theme.palette.success.main },
  orangeText: { color: theme.palette.warning.main },
  redText: { color: theme.palette.contrastText.red },
  reducedFontSize: { fontSize: '0.875rem' }
}));

const ProjectPersonnelListPage = () => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const { t } = useTranslation();
  const { projectId } = useParams();
  const { displayToast } = useToast();

  const [
    personnelTableState,
    { handleTableStateChange }
  ] = usePersonnelListPageTableState();

  const [initialState] = useState(personnelTableState);
  const [csvDownloadLoading, setCsvDownloadLoading] = useState(false);

  const FILTERS = {
    isSupervisor: t('projectPersonnelList.isSupervisorCheck.supervisor'),
    isNotSupervisor: t('projectPersonnelList.isSupervisorCheck.notSupervisor'),
    isActive: t('projectPersonnelList.isActiveCheck.active'),
    inactive: t('projectPersonnelList.isActiveCheck.inactive'),
    completed: t('projectPersonnelList.orientationStatus.complete'),
    incomplete: t('projectPersonnelList.orientationStatus.incomplete'),
    imported: t('projectPersonnelList.orientationStatus.imported')
  };

  const getApiSortOrder = order => {
    let apiSortOrderField = null;
    let direction = order.direction;

    switch (order.name) {
      case 'personnel.fullName':
        apiSortOrderField = 'personnel_lastName';
        break;
      case 'customId':
        apiSortOrderField = 'customId';
        break;
      case 'tradePartner.name':
        apiSortOrderField = 'tradePartner_name';
        break;
      case 'personnel.contactInformation.emailAddress.email':
        apiSortOrderField = 'personnel_email';
        break;
      case 'personnel.nickname':
        apiSortOrderField = 'personnel_nickname';
        break;
      case 'isActive':
        apiSortOrderField = 'isActive';
        direction = direction === 'asc' ? 'desc' : 'asc';
        break;
      default:
        console.error(`Sort order, "${order.name}", is not being handled!`);
    }
    return apiSortOrderField && direction
      ? [
          {
            [apiSortOrderField]: direction.toUpperCase()
          }
        ]
      : null;
  };

  const { data, loading, error, fetchMore } = useQuery(
    GET_PAGINATED_TRADE_PARTNER_PERSONNEL_FOR_PROJECT,
    {
      variables: {
        projectId,
        first: initialState.first,
        skip: initialState.skip,
        filter: initialState.filter,
        search: initialState.search,
        order: getApiSortOrder(initialState.order)
      },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true
    }
  );

  const [getPaginatedPersonnelsPromise] = useLazyQueryPromise(
    GET_PAGINATED_TRADE_PARTNER_PERSONNEL_FOR_PROJECT,
    {
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true
    }
  );

  const handleSearchChange = ({ tableState, debouncedHandleSearchChange }) => {
    const { searchText } = tableState;

    handleTableStateChange({
      ...personnelTableState,
      skip: 0,
      page: 0,
      search: searchText
    });

    loadMore({
      search: searchText,
      skip: 0,
      filter: personnelTableState.filter,
      order: getApiSortOrder(personnelTableState.order)
    });
  };

  const { debounced: debouncedHandleSearchChange } = useDebounce(
    handleSearchChange
  );

  const getOrientationStatus = value => {
    switch (value) {
      case ORIENTATION_STATUSES.completed:
        return FILTERS.completed;
      case ORIENTATION_STATUSES.incomplete:
        return FILTERS.incomplete;
      case ORIENTATION_STATUSES.imported:
        return FILTERS.imported;
      default:
        return null;
    }
  };

  const getFormattedTradePartnerPersonnels = () => {
    const tradePartnerPersonnelsCopy = cloneDeep(
      data?.paginatedTradePartnerPersonnelsOnProject?.tradePartnerPersonnels ??
        []
    );
    return tradePartnerPersonnelsCopy.map(tradePartnerPersonnel => {
      return {
        customId: tradePartnerPersonnel.customId,
        id: tradePartnerPersonnel.id,
        isActive: tradePartnerPersonnel.isActive,
        isSupervisor: tradePartnerPersonnel.isSupervisor,
        isPermitApprover: tradePartnerPersonnel.isPermitApprover,
        notes: tradePartnerPersonnel.notes,
        orientationStatus: tradePartnerPersonnel.orientationStatus,
        orientationHistory: tradePartnerPersonnel.orientationHistory,
        shouldBeAdLinked: tradePartnerPersonnel.shouldBeAdLinked,
        tradePartner: tradePartnerPersonnel.tradePartner,
        personnel: tradePartnerPersonnel.personnel,
        personnelFlagCount: tradePartnerPersonnel.personnel.flags.length
      };
    });
  };

  const tradePartnerPersonnels = data?.paginatedTradePartnerPersonnelsOnProject
    ?.tradePartnerPersonnels
    ? getFormattedTradePartnerPersonnels()
    : [];

  if (error) {
    if (error.message?.includes('not found')) {
      return <PageNotFoundNotice />;
    } else {
      return <ErrorNotice />;
    }
  }

  const loadMore = variables => {
    return fetchMore({
      variables,
      updateQuery: (_previousResult, { fetchMoreResult, _queryVariables }) => {
        return fetchMoreResult;
      }
    });
  };

  const handleFilterChange = tableState => {
    const { filterList } = tableState;
    const filters = filterList.flat().map(filter => filter.toLowerCase());
    let selectedFilters = {};

    if (filters.includes(FILTERS.isSupervisor.toLowerCase())) {
      selectedFilters = {
        ...selectedFilters,
        isSupervisor: true
      };
    }

    if (filters.includes(FILTERS.isNotSupervisor.toLowerCase())) {
      selectedFilters = {
        ...selectedFilters,
        isSupervisor: false
      };
    }

    if (filters.includes(FILTERS.isActive.toLowerCase())) {
      selectedFilters = {
        ...selectedFilters,
        isActive: true
      };
    }

    if (filters.includes(FILTERS.inactive.toLowerCase())) {
      selectedFilters = {
        ...selectedFilters,
        isActive: false
      };
    }

    if (filters.includes(FILTERS.completed.toLowerCase())) {
      selectedFilters = {
        ...selectedFilters,
        orientationStatus: 'Completed'
      };
    }

    if (filters.includes(FILTERS.imported.toLowerCase())) {
      selectedFilters = {
        ...selectedFilters,
        orientationStatus: 'Imported'
      };
    }

    if (filters.includes(FILTERS.incomplete.toLowerCase())) {
      selectedFilters = {
        ...selectedFilters,
        orientationStatus: 'Incomplete'
      };
    }

    handleTableStateChange({
      ...personnelTableState,
      filter: selectedFilters,
      filterList,
      skip: 0,
      page: 0
    });

    loadMore({
      search: personnelTableState.search,
      filter: selectedFilters,
      skip: 0,
      order: getApiSortOrder(personnelTableState.order)
    });
  };

  const handleSortChange = tableState => {
    const { sortOrder } = tableState;

    handleTableStateChange({
      ...personnelTableState,
      order: sortOrder,
      page: 0,
      skip: 0
    });

    loadMore({
      search: personnelTableState.search,
      skip: 0,
      filter: personnelTableState.filter,
      order: getApiSortOrder(sortOrder)
    });
  };

  const handlePageChange = tableState => {
    const { rowsPerPage, page } = tableState;

    let skip =
      page < personnelTableState.page
        ? personnelTableState.skip - rowsPerPage
        : personnelTableState.skip + rowsPerPage;

    handleTableStateChange({
      ...personnelTableState,
      page,
      skip
    });

    loadMore({
      search: personnelTableState.search,
      skip,
      filter: personnelTableState.filter,
      order: getApiSortOrder(personnelTableState.order)
    });
  };

  const columns = [
    {
      name: 'id',
      label: 'Trade Partner Personnel Id',
      options: {
        filter: false,
        display: 'excluded'
      }
    },
    {
      name: 'personnel.fullName',
      label: t('projectPersonnelList.columns.name'),
      options: {
        filter: false,
        sort: true,
        searchable: true,
        customBodyRender: value => {
          return <StyledTableCellContent value={value} />;
        }
      }
    },
    {
      name: 'personnel.nickname',
      label: t('projectPersonnelList.columns.nickName'),
      options: {
        filter: false,
        sort: true,
        searchable: false,
        customBodyRender: value => {
          return <StyledTableCellContent value={value} />;
        }
      }
    },
    {
      name: 'customId',
      label: t('projectPersonnelList.columns.customId'),
      options: {
        filter: false,
        sort: true,
        searchable: true
      }
    },
    {
      name: 'personnel.contactInformation.emailAddress.email',
      label: t('projectPersonnelList.columns.email'),
      options: {
        filter: false,
        sort: true,
        searchable: true,
        customBodyRender: value => {
          return <StyledTableCellContent value={value} />;
        }
      }
    },
    {
      name: 'tradePartner.name',
      label: t('projectPersonnelList.columns.tradePartner'),
      options: {
        filter: false,
        sort: true,
        searchable: true,
        customBodyRender: value => {
          return <StyledTableCellContent value={value} />;
        }
      }
    },
    {
      name: 'orientationStatus',
      label: t('projectPersonnelList.columns.orientation'),
      options: {
        filter: true,
        sort: false,
        searchable: false,
        filterList: personnelTableState.filterList[6]?.length
          ? personnelTableState.filterList[6]
          : null,
        filterOptions: {
          names: [FILTERS.completed, FILTERS.incomplete, FILTERS.imported],
          logic: (value, filters) => {
            if (filters.length) {
              const status = getOrientationStatus(value);
              return !filters.includes(status);
            }
            /* istanbul ignore next */
            return false; // this line can never be tested as filters will always exist
          }
        },
        customBodyRender: value => {
          const status = getOrientationStatus(value);
          return (
            <>
              <Typography
                className={classNames(
                  classes.reducedFontSize,
                  {
                    [`${classes.greenText}`]:
                      status === ORIENTATION_STATUSES.completed
                  },
                  {
                    [`${classes.redText}`]:
                      status === ORIENTATION_STATUSES.incomplete
                  },
                  {
                    [`${classes.orangeText}`]:
                      status === ORIENTATION_STATUSES.imported
                  }
                )}>
                {status}
              </Typography>
            </>
          );
        }
      }
    },
    {
      name: 'orientationHistory',
      label: t('projectPersonnelList.columns.orientationLatestCompletionDate'),
      options: {
        filter: false,
        display: 'excluded'
      }
    },
    {
      name: 'isSupervisor',
      label: t('projectPersonnelList.columns.supervisor'),
      options: {
        filter: true,
        sort: false,
        searchable: true,
        filterList: personnelTableState.filterList[7]?.length
          ? personnelTableState.filterList[7]
          : null,
        filterOptions: {
          names: [FILTERS.isSupervisor, FILTERS.isNotSupervisor],
          logic: (isSupervisor, filters) => {
            if (filters.length) {
              const supervisor = isSupervisor
                ? FILTERS.isSupervisor
                : FILTERS.isNotSupervisor;
              return !filters.includes(supervisor);
            }
            /* istanbul ignore next */
            return false; // this line can never be tested as filters will always exist
          }
        },
        customBodyRender: isSupervisor => (
          <Fragment>
            {isSupervisor && (
              <Typography className={classes.reducedFontSize}>
                {t('projectPersonnelList.status.supervisor')}
              </Typography>
            )}
          </Fragment>
        )
      }
    },
    {
      name: 'personnelFlagCount',
      label: t('projectPersonnelList.columns.flags'),
      options: {
        filter: false,
        sort: false,
        searchable: true,
        customBodyRender: personnelFlagCount => (
          <Fragment>
            {personnelFlagCount > 0 && (
              <Typography className={classes.reducedFontSize}>
                {personnelFlagCount}
              </Typography>
            )}
          </Fragment>
        )
      }
    },
    {
      name: 'isActive',
      label: t('projectPersonnelList.columns.status'),
      options: {
        sort: true,
        searchable: false,
        filter: true,
        filterList: personnelTableState.filterList[9]?.length
          ? personnelTableState.filterList[9]
          : null,
        filterOptions: {
          names: [FILTERS.isActive, FILTERS.inactive],
          logic: (isActive, filters) => {
            if (filters.length) {
              const status = isActive ? FILTERS.isActive : FILTERS.inactive;
              return !filters.includes(status);
            }
            /* istanbul ignore next */
            return false; // this line can never be tested as filters will always exist
          }
        },
        customBodyRender: value => (
          <ActiveOrInactiveStatusContent isActive={value} />
        )
      }
    }
  ];

  const options = {
    onDownload: (buildHead, buildBody, columns) => {
      setCsvDownloadLoading(true);

      const fullDataSet = [];
      let total = 0;
      let csvSkipState = 0;

      getPaginatedPersonnelsPromise({
        projectId,
        first: personnelTableState.first,
        skip: csvSkipState,
        filter: personnelTableState.filter,
        search: personnelTableState.search,
        order: getApiSortOrder(personnelTableState.order)
      })
        .then(async res => {
          total = res.data.paginatedTradePartnerPersonnelsOnProject?.total;
          fullDataSet.push(
            ...res?.data?.paginatedTradePartnerPersonnelsOnProject
              ?.tradePartnerPersonnels
          );
          csvSkipState += personnelTableState.first;

          if (fullDataSet.length < total) {
            await getRemainingPaginatedPersonnels();
          }

          serveCsv(
            fullDataSet,
            buildHead,
            buildBody,
            columns,
            t('projectPersonnelList.tableTitle'),
            CSV_PAGES.personnelList
          );
          setCsvDownloadLoading(false);
        })
        .catch(err => {
          console.error('Error', err);
          setCsvDownloadLoading(false);
          displayToast(t('projectPersonnelListPage.csvDownloadError'), 'error');
        });

      let getRemainingPaginatedPersonnels = async () => {
        while (fullDataSet.length < total) {
          await getPaginatedPersonnelsPromise({
            projectId,
            first: personnelTableState.first,
            skip: csvSkipState,
            filter: personnelTableState.filter,
            search: personnelTableState.search,
            order: getApiSortOrder(personnelTableState.order)
          })
            .then(nextRes => {
              fullDataSet.push(
                ...nextRes?.data?.paginatedTradePartnerPersonnelsOnProject
                  ?.tradePartnerPersonnels
              );
            })
            .catch(err => {
              console.error('Error', err);
              setCsvDownloadLoading(false);
              displayToast(
                t('projectPersonnelListPage.csvDownloadError'),
                'error'
              );
            });
          csvSkipState = csvSkipState += personnelTableState.first;
        }
      };

      // do not download csv on initial button click --> this is handled asynchronously above
      return false;
    },
    download: true,
    count: data?.paginatedTradePartnerPersonnelsOnProject?.total ?? null,
    rowsPerPage: personnelTableState.first,
    searchText: personnelTableState.search,
    page: personnelTableState.page,
    rowsPerPageOptions: [],
    sortOrder: personnelTableState.order,
    onRowClick: rowData =>
      history.push(`/projects/${projectId}/personnel/${rowData[0]}`),
    serverSide: true,
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          handlePageChange(tableState);
          break;
        case 'sort':
          handleSortChange(tableState);
          break;
        case 'filterChange':
        case 'resetFilters':
          handleFilterChange(tableState);
          break;
        case 'search':
        case 'onSearchClose':
          debouncedHandleSearchChange({ tableState, personnelTableState });
          break;
        default:
      }
    }
  };

  return (
    <Fragment>
      <LoadingOverlay
        text={
          csvDownloadLoading && (
            <p
              style={{
                color: theme.palette.primary.main,
                background: theme.palette.secondary.light
              }}>
              {t('projectPersonnelListPage.csvLoading')}
            </p>
          )
        }
        active={loading || csvDownloadLoading}
        spinner
        fadeSpeed={50}
        styles={{
          overlay: base => ({
            ...base,
            background: theme.palette.background.overlay
          }),
          spinner: base => ({
            ...base,
            width: '50px',
            '& svg circle': {
              stroke: theme.palette.primary.main
            }
          })
        }}>
        <StyledTable
          title={t('projectPersonnelList.tableTitle')}
          data={tradePartnerPersonnels}
          columns={columns}
          options={options}
        />
      </LoadingOverlay>
      <AddProjectPersonnelDialog
        refetchQuery={() =>
          loadMore({
            first: personnelTableState.first,
            skip: personnelTableState.skip,
            filter: personnelTableState.filter,
            search: personnelTableState.search,
            order: getApiSortOrder(personnelTableState.order)
          })
        }
      />
    </Fragment>
  );
};

export default withOnlineAccessOnly(
  withAuthorization(ProjectPersonnelListPage)
);
