import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import makeStyles from '@mui/styles/makeStyles';

import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';

const useStyles = makeStyles(theme => ({
  actionButton: {
    marginLeft: theme.spacing(2),
    color: theme.palette.invariant.black,
    backgroundColor: theme.palette.invariant.lightGray
  }
}));

const useToast = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dismiss = key => (
    <StyledButtonPrimary
      color="primary"
      className={classes.actionButton}
      label={t('useToast.actions.dismissButton')}
      onClick={() => {
        closeSnackbar(key);
      }}
    />
  );

  const displayToast = (message, type, Action, dismissible = true) => {
    const customCallback = key => {
      if (dismissible) {
        return (
          <Fragment>
            <Action toastKey={key} />
            <StyledButtonPrimary
              color="primary"
              className={classes.actionButton}
              label={t('useToast.actions.dismissButton')}
              onClick={() => {
                closeSnackbar(key);
              }}
            />
          </Fragment>
        );
      } else {
        return <Action />;
      }
    };

    const getAction = () => {
      if (Action) {
        return customCallback;
      } else if (type === 'error' || dismissible) {
        return dismiss;
      } else {
        return null;
      }
    };

    enqueueSnackbar(message, {
      variant: type,
      anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
      autoHideDuration: type === 'error' || type === 'info' ? null : 6000,
      persist: type === 'error' || type === 'warning' || !dismissible,
      action: getAction(),
      preventDuplicate: true
    });
  };
  return { displayToast, closeSnackbar };
};

export default useToast;
