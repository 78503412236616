import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Link,
  Typography
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import classnames from 'classnames';
import AddCategory from 'components/ahas/AddAhaDialog/AddCategory';
import EditableAhaCategory from 'components/ahas/AddAhaDialog/EditableAhaCategory';
import GlobalAhas from 'components/ahas/AddAhaDialog/GlobalAhas';
import AhaTemplateCategorySelect from 'components/ahas/AhaTemplateCategorySelect';
import {
  CREATE_GLOBAL_AHA_TEMPLATE,
  GLOBAL_TEMPLATE_SEARCH
} from 'graphql/aha/globalTemplate';
import {
  CREATE_GLOBAL_AHA_TEMPLATE_CATEGORY,
  GET_ALL_GLOBAL_TEMPLATE_CATEGORIES
} from 'graphql/aha/globalTemplateCategory';
import { CREATE_PROJECT_TEMPLATE } from 'graphql/aha/projectTemplate';
import useDebounce from 'hooks/useDebounce';
import useRoles from 'hooks/useRoles';
import useSafeProject from 'hooks/useSafeProject';
import useSoteriaQuery from 'hooks/useSoteriaQuery';
import useToast from 'hooks/useToast';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useField, useForm } from 'react-final-form-hooks';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import StyledButtonSecondary from 'shared/Buttons/ButtonSecondary';
import StyledDialog from 'shared/Dialog';
import StyledSearch from 'shared/Search';
import useAddAhaState from 'store/addAhaState';
import { generateTransactionKey } from 'utils';
import { isNullish } from 'utils/isNullish';
import useAddAhaDialogStyles from './useAddAhaDialogStyles';

const AddAhaDialog = ({
  isGlobal = false,
  addHenselPhelpsAhaDialogIsOpen,
  setAddHenselPhelpsAhaDialogIsOpen,
  refetchQueries
}) => {
  const styles = useAddAhaDialogStyles();
  const classes = styles();
  const history = useHistory();
  const { projectId } = useParams();
  const { safeProject } = useSafeProject(projectId);
  const { t } = useTranslation();
  const { displayToast } = useToast();
  const { isAhaAdmin } = useRoles();
  const [selectedAha, setSelectedAha] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const [isCategoryEditLoading, setIsCategoryEditLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [expandedCategories, setExpandedCategories] = useState([]);
  const [projectTxnKey, setProjectTxnKey] = useState(generateTransactionKey());
  const [globalTxnKey, setGlobalTxnKey] = useState(generateTransactionKey());
  const [addAhaState, { handleAddAhaStateChange }] = useAddAhaState();
  const [search, setSearch] = useState(addAhaState.search);
  const [
    unsavedGlobalTemplateCategory,
    setUnsavedGlobalTemplateCategory
  ] = useState(false);
  const [
    categoryIdsWithUnsavedChanges,
    setCategoryIdsWithUnsavedChanges
  ] = useState([]);
  const [
    globalTemplateCategoryTransactionKey,
    setGlobalTemplateCategoryTransactionKey
  ] = useState(generateTransactionKey());

  const { form, values } = useForm({
    /* istanbul ignore next */
    onSubmit: () => {}
  });

  const categoryField = useField('category', form);

  const {
    data,
    loading: isGetGlobalTemplateCategoriesLoading
  } = useSoteriaQuery({
    gql: GET_ALL_GLOBAL_TEMPLATE_CATEGORIES
  });

  const { data: searchData, loading: isSearchLoading } = useQuery(
    GLOBAL_TEMPLATE_SEARCH,
    {
      skip: isNullish(addAhaState?.search),
      variables: {
        search: addAhaState?.search
      },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true
    }
  );

  const [
    createAhaProjectTemplate,
    { loading: isCreateProjectTemplateLoading }
  ] = useMutation(CREATE_PROJECT_TEMPLATE);

  const [
    createGlobalAhaTemplate,
    { loading: isCreateGlobalTemplateLoading }
  ] = useMutation(CREATE_GLOBAL_AHA_TEMPLATE);

  const [
    createAhaGlobalTemplateCategory,
    { loading: isCreateLoading }
  ] = useMutation(CREATE_GLOBAL_AHA_TEMPLATE_CATEGORY, {
    refetchQueries: [{ query: GET_ALL_GLOBAL_TEMPLATE_CATEGORIES }],
    awaitRefetchQueries: true
  });

  const isLoading =
    isGetGlobalTemplateCategoriesLoading ||
    isCreateProjectTemplateLoading ||
    isCreateGlobalTemplateLoading ||
    isSearchLoading ||
    isCreateLoading ||
    isSaving;

  const allowedCategories = isGlobal
    ? data?.ahaGlobalTemplateCategories ?? []
    : data?.ahaGlobalTemplateCategories?.filter(
        category => category.name !== 'General'
      ) ?? [];

  const categories = isNullish(addAhaState.search)
    ? allowedCategories
    : searchData?.globalAhaTemplatesAsAhaGlobalTemplateCategorySubsets ?? [];

  const handleDebouncedSearchChange = ({ search, state }) => {
    handleAddAhaStateChange({
      ...state,
      search
    });
  };

  const { debounced: debouncedHandleSearchChange } = useDebounce(
    handleDebouncedSearchChange
  );

  const handleSearchChange = event => {
    const search = event ? event.target.value : '';

    setSearch(search);
    debouncedHandleSearchChange({
      search,
      state: addAhaState
    });
  };

  const handleAccordionClick = category => {
    if (!expandedCategories.includes(category.id)) {
      setExpandedCategories([...expandedCategories, category.id]);
    } else {
      setExpandedCategories(
        expandedCategories.filter(categoryId => categoryId !== category.id)
      );
    }
  };

  const handleCancelButtonClick = () => {
    if (
      isEditing &&
      !unsavedGlobalTemplateCategory &&
      !(categoryIdsWithUnsavedChanges.length > 0)
    ) {
      setIsEditing(false);
    } else {
      handleClose();
    }
  };

  const canSubmit = () => {
    return (
      selectedAha &&
      !unsavedGlobalTemplateCategory &&
      !(categoryIdsWithUnsavedChanges.length > 0)
    );
  };

  const getGlobalCategoryIdAsync = async () => {
    if (values.category?.__isNew__) {
      return createAhaGlobalTemplateCategory({
        variables: {
          input: {
            name: values.category?.value,
            transactionKey: globalTemplateCategoryTransactionKey
          }
        }
      }).then(({ data }) => {
        return data.createAhaGlobalTemplateCategory.id;
      });
    } else if (values.category?.value.id) {
      return Promise.resolve(values.category.value.id);
    } else if (selectedAha) {
      return selectedAha.category.id;
    } else {
      return Promise.reject('Error trying to get category ID');
    }
  };

  const handleSubmitSelectedTemplates = () => {
    setIsSaving(true);
    if (isGlobal) {
      getGlobalCategoryIdAsync()
        .then(categoryIdToSend =>
          createGlobalAhaTemplate({
            variables: {
              input: {
                ahaGlobalTemplateCategoryId: categoryIdToSend,
                templateIds: selectedAha?.id,
                transactionKey: globalTxnKey,
                type: selectedAha?.type ?? 'New AHA'
              }
            }
          })
        )
        .then(async response => {
          if (refetchQueries) {
            await refetchQueries();
          }
          setIsSaving(false);
          displayToast(
            t('addAhaDialog.toasts.createGlobalTemplate.success'),
            'success'
          );
          handleClose();
          history.push(
            `/ahas/${response.data.createGlobalAhaTemplate?.id}/edit?view=summary`
          );
        })
        .catch(error => {
          setIsSaving(false);
          console.error('Create Global AHA Template Error: ', error);
          displayToast(
            t('addAhaDialog.toasts.createGlobalTemplate.error'),
            'error'
          );
        });
    } else {
      createAhaProjectTemplate({
        variables: {
          input: {
            contractor: 'HENSEL PHELPS',
            location: safeProject.name,
            notes: '',
            projectId: projectId,
            templateIds: selectedAha?.id,
            transactionKey: projectTxnKey,
            templateType: selectedAha?.type ?? undefined
          }
        }
      })
        .then(response => {
          setIsSaving(false);
          displayToast(
            t('addAhaDialog.toasts.createProjectTemplate.success'),
            'success'
          );
          handleClose();
          history.push(
            `/projects/${projectId}/ahas/hensel-phelps-aha/${response.data.createAhaProjectTemplate.ahaTemplate.id}/edit?view=summary`
          );
        })
        .catch(error => {
          setIsSaving(false);
          displayToast(
            t('addAhaDialog.toasts.createProjectTemplate.error'),
            'error'
          );
        });
    }
  };

  const handleEditButtonClick = () => {
    setIsEditing(!isEditing);
  };

  const handleClose = () => {
    if (
      !unsavedGlobalTemplateCategory &&
      !(categoryIdsWithUnsavedChanges.length > 0)
    ) {
      setAddHenselPhelpsAhaDialogIsOpen(false);
      setIsEditing(false);
      setProjectTxnKey(generateTransactionKey());
      setGlobalTxnKey(generateTransactionKey());
      setGlobalTemplateCategoryTransactionKey(generateTransactionKey());
      setExpandedCategories([]);
      setSelectedAha(null);
      form.reset();
      form.resetFieldState('category');
    }
  };

  return <>
    <StyledDialog
      isOpen={addHenselPhelpsAhaDialogIsOpen}
      handleClose={handleClose}
      title={
        isEditing
          ? t('addAhaDialog.editCategoriesMode.title')
          : t('addAhaDialog.addAhaMode.title')
      }
      isLoading={isLoading}
      content={
        <Grid container>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    {isAhaAdmin && (
                      <>
                        {!isEditing && (
                          <Button
                            className={classes.editModeButton}
                            size="small"
                            variant="contained"
                            color="secondary"
                            disabled={isLoading}
                            startIcon={<EditIcon />}
                            onClick={handleEditButtonClick}>
                            <Typography
                              className={classes.editModeButtonLabel}>
                              {t('globalLibrary.actions.editButton')}
                            </Typography>
                          </Button>
                        )}
                        {isEditing && (
                          <Button
                            className={classes.editModeButton}
                            size="small"
                            variant="contained"
                            color="secondary"
                            startIcon={<CancelIcon />}
                            onClick={handleEditButtonClick}>
                            <Typography
                              className={classes.editModeButtonLabel}>
                              {t('globalLibrary.actions.toggleEditButton')}
                            </Typography>
                          </Button>
                        )}
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <StyledSearch
                  value={search}
                  onChange={handleSearchChange}
                  onClick={() => handleSearchChange('')}
                />
              </Grid>
            </Grid>
            <Grid
              container
              className={classes.titleContainer}
              alignItems="center">
              <Grid item>
                <Typography className={classes.title}>
                  {t('addAhaDialog.categorySectionTitle')}
                </Typography>
              </Grid>
              {isGlobal && !isEditing && (
                <Grid
                  item
                  xs={12}
                  className={classes.categorySelectContainer}>
                  <Typography gutterBottom>
                    Once an AHA has been selected, click submit to create it
                    under the existing category. However, if you would like
                    the selected AHA to exist under another category, use the
                    menu below to select one or create one if it is not
                    listed.
                  </Typography>
                  <AhaTemplateCategorySelect
                    isDisabled={isLoading}
                    selectedCategory={categoryField.input.value}
                    meta={categoryField.meta}
                    handleChange={categoryField.input.onChange}
                  />
                </Grid>
              )}
            </Grid>
            {categories?.length > 0 && (
              <>
                {categories.map(category => {
                  return (
                    <Accordion
                      key={category.id}
                      classes={{
                        root: classes.accordionRoot,
                        expanded: classes.accordionExpanded
                      }}
                      expanded={expandedCategories.includes(category.id)}>
                      <AccordionSummary
                        classes={{
                          root: classes.accordionSummaryRoot,
                          expanded: classes.expanded,
                          content:
                            isEditing &&
                            expandedCategories.includes(category.id)
                              ? classes.accordionSummaryContentExpanded
                              : classes.accordionSummaryContent
                        }}>
                        {expandedCategories.includes(category.id) ? (
                          <ExpandMoreIcon
                            className={classnames(classes.expandIcon, {
                              [`${classes.expandIconWhileEditing}`]: isEditing
                            })}
                            onClick={() => {
                              handleAccordionClick(category);
                            }}
                          />
                        ) : (
                          <KeyboardArrowRightIcon
                            className={classnames(classes.expandIcon, {
                              [`${classes.expandIconWhileEditing}`]: isEditing
                            })}
                            onClick={() => {
                              handleAccordionClick(category);
                            }}
                          />
                        )}
                        {!isEditing && (
                          <Link
                            color="initial"
                            className={classes.categoryName}
                            onClick={() => {
                              handleAccordionClick(category);
                            }}
                            underline="hover">
                            <Typography
                              className={
                                expandedCategories.includes(category.id)
                                  ? classes.headingExpanded
                                  : classes.heading
                              }>
                              {category.name}
                            </Typography>
                          </Link>
                        )}
                        {isEditing && (
                          <EditableAhaCategory
                            category={category}
                            categoryIdsWithUnsavedChanges={
                              categoryIdsWithUnsavedChanges
                            }
                            setCategoryIdsWithUnsavedChanges={
                              setCategoryIdsWithUnsavedChanges
                            }
                            setIsCategoryEditLoading={
                              setIsCategoryEditLoading
                            }
                          />
                        )}
                      </AccordionSummary>
                      <AccordionDetails
                        classes={{ root: classes.accordionDetailsRoot }}>
                        <GlobalAhas
                          category={category}
                          isEditing={isEditing}
                          selectedAha={selectedAha}
                          setSelectedAha={setSelectedAha}
                          isLoading={isLoading || isCategoryEditLoading}
                        />
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
              </>
            )}
            {!isLoading && categories?.length < 1 && (
              <Typography className={classes.noCategoriesMessage}>
                No categories found.
              </Typography>
            )}
          </Grid>
          {isEditing && (
            <Grid item xs={12}>
              <AddCategory
                setUnsavedGlobalTemplateCategory={
                  setUnsavedGlobalTemplateCategory
                }
              />
              {(unsavedGlobalTemplateCategory ||
                categoryIdsWithUnsavedChanges.length > 0) && (
                <Typography className={classes.errorText}>
                  {t('addAhaDialog.unsavedChanges.error')}
                </Typography>
              )}
            </Grid>
          )}
        </Grid>
      }
      actions={
        <Grid container justifyContent="space-between">
          <Grid item>
            <StyledButtonSecondary
              label={t('addAhaDialog.actions.cancelButton')}
              disabled={
                unsavedGlobalTemplateCategory ||
                categoryIdsWithUnsavedChanges.length > 0 ||
                isLoading
              }
              onClick={handleCancelButtonClick}
            />
          </Grid>
          <Grid item>
            {!isEditing && (
              <StyledButtonPrimary
                label={t('addAhaDialog.actions.submitButton')}
                disabled={!canSubmit() || isLoading}
                onClick={handleSubmitSelectedTemplates}
              />
            )}
          </Grid>
        </Grid>
      }
    />
  </>;
};

AddAhaDialog.propTypes = {
  isGlobal: PropTypes.bool,
  addHenselPhelpsAhaDialogIsOpen: PropTypes.bool,
  setAddHenselPhelpsAhaDialogIsOpen: PropTypes.func,
  refetchQueries: PropTypes.func
};

export default AddAhaDialog;
