import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';

import StyledAutoComplete from 'shared/AutoComplete';
import { GET_AD_CONTACTS } from 'graphql/adContact';

const AdUserPartnerSelect = ({
  selectedUser,
  handleChange,
  isDisabled = false,
  isRequired = false,
  placeholder,
  customError,
  autoFocus = false,
  ...rest
}) => {
  const [inputValue, handleInputChange] = useState('');

  const { data, loading } = useQuery(GET_AD_CONTACTS, {
    skip: !inputValue,
    variables: {
      search: inputValue,
      clientId: process.env.REACT_APP_AZURE_B2C_CLIENT_ID
    },
    fetchPolicy: 'no-cache'
  });

  const adContacts = data && data.adContacts ? data.adContacts : [];

  const filteredOptions = [
    ...adContacts.map(item => ({
      label: item.email,
      value: item.id,
      ...item
    }))
  ];

  return (
    <StyledAutoComplete
      selectedValue={selectedUser}
      handleChange={handleChange}
      placeholder={placeholder}
      isRequired={isRequired}
      isDisabled={isDisabled}
      options={filteredOptions}
      isLoading={loading}
      handleInputChange={handleInputChange}
      customError={customError}
      autoFocus={autoFocus}
      {...rest}
    />
  );
};

AdUserPartnerSelect.propTypes = {
  isDisabled: PropTypes.bool,
  selectedUser: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  isRequired: PropTypes.bool,
  placeholder: PropTypes.node.isRequired,
  customError: PropTypes.any,
  autoFocus: PropTypes.any
};

export default AdUserPartnerSelect;
