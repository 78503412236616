import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import { Avatar, Fade, Box } from '@mui/material';

import { useAdAvatar } from './hooks';

const useStyles = makeStyles(theme => ({
  small: { width: theme.spacing(4), height: theme.spacing(4) },
  medium: { width: theme.spacing(7), height: theme.spacing(7) },
  large: { width: theme.spacing(10), height: theme.spacing(10) },
  xlarge: { width: theme.spacing(18), height: theme.spacing(18) },
  xxlarge: { width: theme.spacing(24), height: theme.spacing(24) }
}));

const AdAvatar = ({
  adContact,
  size = 'medium',
  azureClientId = process.env.REACT_APP_AZURE_CLIENT_ID
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { thumbnailUrl, loading, initials } = useAdAvatar(
    adContact,
    azureClientId
  );

  const isSize = avatarSize => {
    return size.toLowerCase() === avatarSize.toLowerCase();
  };
  const avatarClass = classnames({
    [`${classes.small}`]: isSize('small'),
    [`${classes.medium}`]: isSize('medium'),
    [`${classes.large}`]: isSize('large'),
    [`${classes.xlarge}`]: isSize('xlarge'),
    [`${classes.xxlarge}`]: isSize('xxlarge')
  });
  return (
    <Box sx={{ position: 'relative' }}>
      <Avatar
        data-testid="skeleton-loader-avatar"
        className={avatarClass}
        sx={{ position: 'absolute', top: 0, left: 0 }}
      />
      <Fade in={loading === false} timeout={650}>
        <Avatar
          src={loading ? null : thumbnailUrl}
          data-testid="ad-avatar"
          alt={t('images.userAvatarAltText')}
          className={avatarClass}>
          <Avatar
            data-testid="ad-initials-avatar"
            alt={t('images.userAvatarAltText')}
            className={avatarClass}>
            {initials}
          </Avatar>
        </Avatar>
      </Fade>
    </Box>
  );
};

AdAvatar.propTypes = {
  adContact: PropTypes.object.isRequired,
  size: PropTypes.string,
  azureClientId: PropTypes.string
};

export default AdAvatar;
