import makeStyles from '@mui/styles/makeStyles';
const useGlobalEquipmentTabContentStyles = () => {
  return makeStyles(theme => ({
    accordionRoot: {
      marginTop: theme.spacing(1),
      borderRadius: 4
    },
    accordionSummaryRoot: {
      minHeight: 30,
      maxHeight: 30
    },
    accordionSummaryRootExpanded: {},
    accordionDetailsRoot: { marginLeft: theme.spacing(2) },
    globalEquipmentCard: {
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(0.3, 1),
      boxShadow: 'none',
      margin: theme.spacing(1, 0),
      height: 34.8,
      '& .MuiIconButton-root': {
        padding: 3
      }
    },
    globalEquipmentCardName: {
      fontSize: '0.875rem',
      display: 'inline',
      verticalAlign: '-10%'
    },
    categoryName: { fontSize: '0.875rem' },
    icon: { color: 'white' },
    searchInput: {
      '& .MuiInput-underline:before': {
        borderBottom: '2px solid' + theme.palette.secondary.main
      },
      '& .MuiInputBase-input': {
        fontSize: '1rem !important',
        fontWeight: 'bold !important',
        color: theme.palette.secondary.contrastText + '!important',
        padding: theme.spacing(1, 0, 0, 2),
        backgroundColor: theme.palette.background.paper,
        borderRadius: 4,
        height: 19
      },
      '&:hover': {
        borderBottomColor: theme.palette.primary.main
      }
    },
    addGlobalJobStepButton: {
      position: 'relative',
      bottom: -16,
      left: 0,
      borderRadius: 16
    },
    addGlobalJobStepButtonLabel: {
      fontSize: '0.75rem',
      fontWeight: 'bold'
    },
    addButtonContainer: { position: 'absolute', bottom: 16 },
    addButton: {
      position: 'relative',
      bottom: 0,
      left: 0,
      borderRadius: 16
    },
    addButtonLabel: { fontSize: '0.75rem', fontWeight: 'bold' }
  }));
};

export default useGlobalEquipmentTabContentStyles;
