import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';

import StyledDialog from 'shared/Dialog';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import StyledButtonSecondary from 'shared/Buttons/ButtonSecondary';

const StyledUnsavedChangesDialog = ({
  unsavedChangesDialogIsOpen,
  setUnsavedChangesDialogIsOpen,
  setDiscardWasClicked,
  content
}) => {
  const { t } = useTranslation();

  const handleClose = () => {
    setUnsavedChangesDialogIsOpen(false);
  };

  const handleDiscard = () => {
    setDiscardWasClicked(true);
  };

  return (
    <StyledDialog
      maxWidth="sm"
      isOpen={unsavedChangesDialogIsOpen}
      handleClose={handleClose}
      title={t('styledUnsavedChangesDialog.title')}
      content={
        <Grid container>
          <Grid item>{content}</Grid>
        </Grid>
      }
      actions={
        <Grid container justifyContent="flex-end">
          <Grid item>
            <StyledButtonSecondary label={'Back'} onClick={handleClose} />
          </Grid>
          <Grid item>
            <StyledButtonPrimary label={'Discard'} onClick={handleDiscard} />
          </Grid>
        </Grid>
      }
    />
  );
};

StyledUnsavedChangesDialog.propTypes = {
  unsavedChangesDialogIsOpen: PropTypes.bool,
  setUnsavedChangesDialogIsOpen: PropTypes.func,
  discardWasClicked: PropTypes.bool,
  setDiscardWasClicked: PropTypes.func,
  content: PropTypes.any
};

export default StyledUnsavedChangesDialog;
