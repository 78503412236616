import React from 'react';
import { Grid, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import VideoPlayer from '../../VideoPlayer';

const useStyles = makeStyles(theme => ({
  subTitle: { fontSize: '0.875rem' },
  title: { fontSize: '1.5rem' }
}));

const SetTradePartnerStatus = () => {
  const classes = useStyles();

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography className={classes.subTitle} color="textSecondary">
          Trade Partners Tutorial
        </Typography>
        <Typography className={classes.title} color="textPrimary">
          Toggle Trade Partner Status on Project
        </Typography>
      </Grid>
      <Grid item>
        <Typography color="textPrimary">
          Change a trade partner&apos;s status between active and inactive on a
          project.
        </Typography>
      </Grid>
      <Grid item>
        <VideoPlayer
          data-testid="set-trade-partner-status-video"
          url="/tutorials/tradePartners/SetTradePartnerStatus.mp4"
          tracks={[
            {
              kind: 'subtitles',
              src: '/tutorials/tradePartners/SetTradePartnerStatus.en.vtt',
              srcLang: 'en',
              default: true
            }
          ]}
        />
      </Grid>
    </Grid>
  );
};

export default SetTradePartnerStatus;
