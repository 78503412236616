import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import makeStyles from '@mui/styles/makeStyles';
import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';

import useRoles from 'hooks/useRoles';
import StyledButtonMuted from 'shared/Buttons/ButtonMuted';
import StyledNotice from 'shared/Notice';
import {
  formatPhoneNumber,
  formatInputAndInternationalPhoneNumber
} from 'utils/formatPhoneNumber';

const useStyles = makeStyles(theme => ({
  container: { padding: theme.spacing(1) },
  title: { fontWeight: 'bold' },
  websiteLink: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
      color: theme.palette.primary.main
    }
  },
  nameText: { display: 'inline' }
}));

const CompanyInfoCard = ({ company }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { isEnterpriseAdmin } = useRoles();

  const formatPhoneNumbers = companyPhoneNumber => {
    const companyInputPhoneNumber = formatPhoneNumber(companyPhoneNumber);
    const companyInternationalPhoneNumber = formatInputAndInternationalPhoneNumber(
      companyPhoneNumber
    )?.international;
    return companyInternationalPhoneNumber
      ? companyInternationalPhoneNumber
      : companyInputPhoneNumber;
  };

  return (
    <Accordion data-testid="expansion-panel" className={classes.container}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.title}>
          {t('companyInfoCard.title')}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Typography gutterBottom className={classes.nameText}>
              {company.name}
            </Typography>
            <Typography gutterBottom>{company.location?.display}</Typography>
            {company.contactInformation?.website && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={company.contactInformation?.website}
                alt="company website"
                className={classes.websiteLink}>
                <Typography gutterBottom>
                  {company.contactInformation?.website}
                </Typography>
              </a>
            )}
          </Grid>
          <Grid item>
            <Grid container>
              <Grid item xs={12}>
                {company.contactInformation?.phoneNumbers?.map(
                  (phoneNumber, index) => (
                    <Fragment key={index}>
                      {phoneNumber.number && (
                        <Grid container direction="row" spacing={1}>
                          <Grid item>
                            <Typography
                              color="textSecondary"
                              className="inline">
                              {phoneNumber.type}:
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography className="inline">
                              {formatPhoneNumbers(phoneNumber.number)}
                            </Typography>
                          </Grid>
                          {phoneNumber.extension && (
                            <Grid item>
                              <Typography className="inline">
                                {t('companyInfoCard.phoneExtension')}
                                {phoneNumber.extension}
                              </Typography>
                            </Grid>
                          )}
                        </Grid>
                      )}
                    </Fragment>
                  )
                )}
              </Grid>
            </Grid>
          </Grid>
          {!isEnterpriseAdmin && (
            <Grid item>
              <StyledNotice
                type="Notice"
                message={t('companyInfoCard.projectAdminCompanyUpdateMessage')}
              />
            </Grid>
          )}
          {isEnterpriseAdmin && (
            <Grid item>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <StyledButtonMuted
                    data-testid="update-button"
                    startIcon={<EditIcon />}
                    label={t('companyInfoCard.updateCompanyInformationButton')}
                    onClick={() => {
                      history.push(`/companies/${company.id}`);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

CompanyInfoCard.propTypes = {
  company: PropTypes.object.isRequired
};

export default CompanyInfoCard;
