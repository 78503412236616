import React, { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import makeStyles from '@mui/styles/makeStyles';
import { IconButton, Drawer } from '@mui/material';

import SideNavigationMenuList from '../SideNavigationMenuList';

const useStyles = makeStyles(theme => ({
  icon: { color: '#fff' }
}));

const SideNavigationMenu = () => {
  const classes = useStyles();
  const [isOpen, toggleIsOpen] = useState(false);

  const toggleDrawer = isOpen => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    toggleIsOpen(isOpen);
  };

  const onClose = () => {
    toggleIsOpen(false);
  };

  return (
    <div onKeyDown={toggleDrawer(false)}>
      <IconButton
        data-testid="primary-app-bar-menu-button"
        aria-label="open the main menu"
        onClick={toggleDrawer(true)}
        size="large">
        <MenuIcon className={classes.icon} />
      </IconButton>
      <Drawer
        data-testid="primary-app-bar-drawer"
        open={isOpen}
        onClose={toggleDrawer(false)}>
        <SideNavigationMenuList closeDrawer={onClose} />
      </Drawer>
    </div>
  );
};

export default SideNavigationMenu;
