import React from 'react';
import WarningIcon from '@mui/icons-material/Warning';
import PropTypes from 'prop-types';
import { Grid, Typography, Link } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { SERVICENOW_URL } from 'constants/externalLinks';

const useStyles = makeStyles(theme => ({
  subTitle: { fontSize: '0.875rem' },
  title: { fontSize: '1.5rem' },
  notice: {
    borderLeft: '6px solid' + theme.palette.secondary.dark,
    backgroundColor: theme.palette.secondary.main,
    padding: theme.spacing(2)
  },
  dangerTitle: {
    fontSize: '0.875rem',
    fontWeight: 'bold',
    textTransform: 'uppercase'
  },
  dangerContent: { fontSize: '0.75rem' },
  bold: { fontWeight: 'bold' }
}));

const DeleteCompany = ({ tutorials }) => {
  const classes = useStyles();

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography className={classes.subTitle} color="textSecondary">
          Companies Tutorial
        </Typography>
        <Typography className={classes.title} color="textPrimary">
          Delete Company
        </Typography>
      </Grid>
      <Grid item>
        <Grid container className={classes.notice} direction="column">
          <Grid item>
            <Grid container direction="row" spacing={1} alignItems="center">
              <Grid item>
                <WarningIcon />
              </Grid>
              <Grid item>
                <Typography className={classes.dangerTitle}>Danger</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Typography className={classes.dangerContent}>
              This action{' '}
              <span className={classes.bold}>
                permanently deletes a company
              </span>{' '}
              from the database, including its entire work history as a trade
              partner on projects. A company should normally only be deleted if
              it was created in error, and has not been used with any projects.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Typography>
          If you created a company by accident,
          <Link
            aria-label={'navigate to ServiceNow in a nwe window'}
            tabIndex="0"
            onKeyDown={() => {
              window.open(SERVICENOW_URL, '_blank', 'noopener');
            }}
            onClick={() => {
              window.open(SERVICENOW_URL, '_blank', 'noopener');
            }}
            underline="hover">
            {' '}
            contact support{' '}
          </Link>
          to request that it be removed.
        </Typography>
      </Grid>
    </Grid>
  );
};

DeleteCompany.propTypes = {
  tutorials: PropTypes.object.isRequired
};

export default DeleteCompany;
