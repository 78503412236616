import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import EventIcon from '@mui/icons-material/Event';
import CheckIcon from '@mui/icons-material/Check';
import { dateTimeToLuxonDateTime } from 'utils/dateTime';
import { DateTime } from 'luxon';

const useStyles = makeStyles(theme => ({
  datePicker: {
    '& .MuiInput-root > input': {
      marginTop: '-8px !important',
      height: 28.5,
      fontSize: '24px !important',
      paddingTop: 6,
      paddingBottom: 7,
      fontFamily: ['Roboto', 'ui-sans-serif', 'sans-serif'].join(',')
    },
    '& .MuiInput-root:before': {
      border: 0
    }
  },
  buttonContainer: {
    [theme.breakpoints.down('md')]: {
      marginTop: '-8px !important',
      marginBottom: '1.5rem'
    }
  },
  datePickerGridItem: {
    display: 'none'
  },
  datePickerText: {
    '& .MuiInput-underline:before': {
      borderBottom: 'none !important',
      '&:hover': {
        borderBottomColor: 'none'
      }
    },
    '& .MuiInput-root:after': {
      borderBottom: 'none'
    }
  }
}));

export const StyledMonthDatePicker = ({
  selectedDate,
  handleDateChange,
  disabled,
  disableFuture = true,
  shouldShowCheckIcon = false,
  clearable = false
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const options = { year: 'numeric', month: 'long' };
  const monthAndYearToShowOnLabel = new Date(selectedDate).toLocaleDateString(
    'en-US',
    options
  );
  const shouldHideDatePicker = clearable;
  const shouldShowMonthInButton = clearable;
  return (
    <Fragment>
      <Grid container justifyContent="space-between">
        <Grid
          item
          className={
            shouldHideDatePicker
              ? classes.datePickerGridItem
              : classes.datePickerText
          }>
          <MobileDatePicker
            slotProps={{
              actionBar: {
                actions: [
                  'today',
                  'accept',
                  'cancel',
                  ...(clearable ? ['clear'] : [])
                ]
              },
              textField: {
                variant: 'standard'
              },
              // I can't see a way to disable backdropClick for desktop mode, so we are now only disabling it for mobile
              dialog: {
                onClose: (event, reason) => {
                  if (reason !== 'backdropClick') {
                    handleClose(event, reason);
                  }
                }
              }
            }}
            open={isOpen}
            openTo="month"
            value={dateTimeToLuxonDateTime(selectedDate)}
            disableFuture={disableFuture}
            maxDate={disableFuture ? DateTime.now() : undefined}
            views={['year', 'month']}
            onChange={handleDateChange}
            onClose={() => setIsOpen(false)}
            className={classes.datePicker}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between">
        <Grid item className={classes.buttonContainer}>
          <StyledButtonPrimary
            icon={<EventIcon />}
            label={
              shouldShowMonthInButton && selectedDate
                ? monthAndYearToShowOnLabel
                : t('styledDatePicker.changeMonthButton')
            }
            onClick={() => setIsOpen(true)}
            disabled={disabled}
          />
        </Grid>
        {shouldShowCheckIcon && (
          <Grid item>
            <CheckIcon data-testid="check-icon-two" />
          </Grid>
        )}
      </Grid>
    </Fragment>
  );
};

StyledMonthDatePicker.propTypes = {
  selectedDate: PropTypes.any,
  handleDateChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  disableFuture: PropTypes.bool,
  shouldShowCheckIcon: PropTypes.bool,
  clearable: PropTypes.bool
};

export default StyledMonthDatePicker;
