import { atom, useRecoilState } from 'recoil';
import * as effects from 'store/effects';
import {
  LOCAL_STORAGE_TRADE_PARTNER_AHA_LIST_FILTERS_KEY,
  LOCAL_STORAGE_TRADE_PARTNER_AHA_SORT_ORDER_KEY
} from 'constants/localStorage';
import {
  TRADE_PARTNER_SORT_ORDER_OPTIONS,
  TRADE_PARTNER_ISACTIVE_FILTER_OPTIONS,
  SORT_ORDER_DIRECTION,
  FILTER_API_KEYS
} from 'constants/ahas';

const DEFAULT_SORT_ORDER = {
  value: TRADE_PARTNER_SORT_ORDER_OPTIONS.lastModified.value,
  label: TRADE_PARTNER_SORT_ORDER_OPTIONS.lastModified.label,
  direction: SORT_ORDER_DIRECTION.descending
};

const getStoredSortOrder = () => {
  const unserializedSortOrder = localStorage.getItem(
    LOCAL_STORAGE_TRADE_PARTNER_AHA_SORT_ORDER_KEY
  );
  if (unserializedSortOrder) {
    return JSON.parse(unserializedSortOrder);
  } else {
    return null;
  }
};

const order = getStoredSortOrder() ?? DEFAULT_SORT_ORDER;

const DEFAULT_ROWS_PER_PAGE = 30;

const getStoredFilters = () => {
  const unserializedFilters = localStorage.getItem(
    LOCAL_STORAGE_TRADE_PARTNER_AHA_LIST_FILTERS_KEY
  );
  if (unserializedFilters) {
    return JSON.parse(unserializedFilters);
  } else {
    return null;
  }
};

const DEFAULT_FILTERS = {
  [TRADE_PARTNER_ISACTIVE_FILTER_OPTIONS.active.apiKey]:
    TRADE_PARTNER_ISACTIVE_FILTER_OPTIONS.allStatus.value,
  [FILTER_API_KEYS.ahaFiles_projectArea]: null
};

const filter = getStoredFilters() ?? {};

const defaultState = {
  first: DEFAULT_ROWS_PER_PAGE,
  skip: 0,
  filter,
  order,
  search: '',
  hasActiveFilters: hasActiveFilters(filter)
};

function hasActiveFilters(filterState) {
  let hasActiveFilters = false;

  if (typeof filterState === 'object') {
    for (const value of Object.values(filterState)) {
      if (value) {
        hasActiveFilters = true;
        break;
      }
    }
  }

  return hasActiveFilters;
}

const tradePartnerAhaListStateAtom = atom({
  key: 'tradePartnerAhaListState',
  default: defaultState
});

function useTradePartnerAhaListState(projectId) {
  const [
    tradePartnerAhaListState,
    setTradePartnerAhaListState
  ] = useRecoilState(tradePartnerAhaListStateAtom);

  const tradePartnerAhaListStateForProject = {
    ...tradePartnerAhaListState,
    filter: tradePartnerAhaListState.filter?.[projectId] ?? DEFAULT_FILTERS
  };

  function handleTradePartnerAhaListStateChange(newState) {
    if (newState?.filter) {
      newState.filter = {
        ...tradePartnerAhaListState.filter,
        [projectId]: newState.filter
      };
      effects.tradePartnerAhaListFilters.localStorageSave(newState.filter);
    }
    if (hasActiveFilters(newState.filter)) {
      newState.hasActiveFilters = true;
    } else {
      newState.hasActiveFilters = false;
    }

    setTradePartnerAhaListState({
      ...tradePartnerAhaListState,
      ...newState
    });
  }
  return [
    tradePartnerAhaListStateForProject,
    {
      handleTradePartnerAhaListStateChange
    }
  ];
}

export default useTradePartnerAhaListState;
