import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import WhatsNewIcon from '@mui/icons-material/EmojiObjects';
import makeStyles from '@mui/styles/makeStyles';
import { IconButton, Badge, Tooltip } from '@mui/material';

import useSoteriaStore from 'hooks/useSoteriaStore';
import WhatsNewDrawer from './WhatsNewDrawer';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  icon: { color: '#fff' },
  root: {
    borderRadius: '50%',
    padding: theme.spacing(1),
    boxShadow: '2px -2px 36px 4px rgba(255, 255, 255, .75)'
  }
}));

const WhatsNew = ({ iconProps }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    store: { whatsNewIsOpen = false },
    setStore
  } = useSoteriaStore();

  const showNotification = sessionStorage.getItem('showWhatsNewNotification')
    ? true
    : false;

  useEffect(() => {
    if (whatsNewIsOpen) {
      sessionStorage.removeItem('showWhatsNewNotification');
    }
  }, [whatsNewIsOpen]);

  const toggleDrawer = isOpen => event => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setStore({ whatsNewIsOpen: isOpen });
  };

  return (
    <div onKeyDown={toggleDrawer(false)}>
      <Tooltip title={t('whatsNew.tooltip')}>
        <IconButton
          data-testid="whatsNewButton"
          onClick={toggleDrawer(!whatsNewIsOpen)}
          aria-label={'view summaries of the latest app content'}
          size="large"
          {...iconProps}>
          <Badge color="secondary" variant="dot" invisible={!showNotification}>
            <WhatsNewIcon
              classes={{
                root: classnames({ [`${classes.root}`]: whatsNewIsOpen })
              }}
              className={classes.icon}
            />
          </Badge>
        </IconButton>
      </Tooltip>
      <WhatsNewDrawer />
    </div>
  );
};

WhatsNew.propTypes = {
  iconProps: PropTypes.any
};

export default WhatsNew;
