import { useMutation, useQuery } from '@apollo/react-hooks';
import { Button, Card, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AhaStatusCard from 'components/ahas/AhaStatusCard';
import CopyActivityDialog from 'components/ahas/CopyAhaDialog';
import FilesTabContent from 'components/ahas/FilesTabContent';
import NotificationSettingsDialog from 'components/ahas/NotificationSettingsDialog';
import ReviewsTabContent from 'components/ahas/ReviewsTabContent';
import TransferOwnershipDialog from 'components/ahas/TransferOwnershipDialog';
import ErrorNotice from 'components/common/ErrorNotice';
import LoadingSpinner from 'components/common/LoadingSpinner';
import PageNotFoundNotice from 'components/common/PageNotFoundNotice';
import { GET_AHA_TEMPLATE } from 'graphql/aha/ahaTemplate';
import { DELETE_PROJECT_TEMPLATE } from 'graphql/aha/projectTemplate';
import withAuthorization from 'hocs/withAuthorization';
import withOnlineAccessOnly from 'hocs/withOnlineAccessOnly';
import useToast from 'hooks/useToast';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import StyledDeleteConfirmation from 'shared/DeleteConfirmation';
import { formatDate } from 'utils/dateTime';

const useStyles = makeStyles(theme => ({
  mainContainer: { padding: theme.spacing(2) },
  header: {
    backgroundImage: `url(${theme.ahaBackground})`,
    backgroundSize: 'cover',
    height: 125,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    [theme.breakpoints.down('md')]: { paddingLeft: theme.spacing(2) }
  },
  title: {
    textAlign: 'center',
    fontSize: '2rem',
    lineHeight: 1,
    paddingTop: theme.spacing(4.5),
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(5.5),
      fontSize: '1.5rem'
    }
  },
  tradePartner: {
    textAlign: 'center',
    fontSize: '1.125rem',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    lineHeight: 1.5,
    [theme.breakpoints.down('md')]: { fontSize: '1rem' }
  },
  subtitle: {
    textAlign: 'center',
    fontSize: '1.5rem',
    marginBottom: theme.spacing(1)
  },
  metaDataLabel: { fontWeight: 'bold' },
  metaData: {
    color: theme.palette.secondary.contrastText,
    fontWeight: 'normal'
  },
  metaDataCardContainer: { marginBottom: theme.spacing(2) },
  metaDataCard: { padding: theme.spacing(2) },
  deleteCard: { padding: theme.spacing(2) },
  reviewsTableContainer: { marginBottom: theme.spacing(2) },
  actionsCard: { padding: theme.spacing(2) },
  actionsCardContainer: { marginBottom: theme.spacing(2) },
  actionsButton: {
    width: '100%',
    marginBottom: theme.spacing(1),
    borderRadius: 16
  },
  buttonLabel: { fontSize: '0.75rem', fontWeight: 'bold' },
  editButton: {
    width: '100%',
    marginBottom: theme.spacing(1),
    borderRadius: 16,
    marginTop: theme.spacing(2)
  },
  statusCardContainer: {
    marginBottom: theme.spacing(2)
  }
}));

const ActionsButton = ({ handleClick, label }) => {
  const classes = useStyles();

  return (
    <Button
      color="secondary"
      className={classes.actionsButton}
      onClick={handleClick}
      variant="contained">
      <Typography className={classes.buttonLabel}>{label}</Typography>
    </Button>
  );
};

ActionsButton.propTypes = {
  handleClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired
};

const ProjectAhaLandingPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const { displayToast } = useToast();
  const { projectId, ahaId } = useParams();
  const [saveAsDialogIsOpen, toggleSaveAsDialog] = useState(false);
  const [
    isConfirmStepDeleteDialogOpen,
    setIsConfirmStepDeleteDialogOpen
  ] = useState(false);
  const [isDeleteConfirmed, toggleIsDeleteConfirmed] = useState(false);
  const [
    transferOwnershipDialogIsOpen,
    toggleTransferOwnershipDialog
  ] = useState(false);
  const [
    notificationSettingsDialogIsOpen,
    toggleNotificationSettingsDialog
  ] = useState(false);
  const [deleteAhaProjectTemplate] = useMutation(DELETE_PROJECT_TEMPLATE);

  const { data: ahaTemplateData, loading: isLoading, error } = useQuery(
    GET_AHA_TEMPLATE,
    {
      variables: { id: ahaId }
    }
  );

  const projectTemplateId =
    ahaTemplateData?.ahaTemplate?.ahaProjectTemplate?.id;
  const tradePartner =
    ahaTemplateData?.ahaTemplate?.ahaProjectTemplate?.contractor;
  const type = ahaTemplateData?.ahaTemplate?.type;
  const ahaTemplate = ahaTemplateData?.ahaTemplate ?? null;

  const handleDeleteModalClose = () => {
    toggleIsDeleteConfirmed(false);
    setIsConfirmStepDeleteDialogOpen(false);
  };

  const handleDeleteSubmit = () => {
    deleteAhaProjectTemplate({
      variables: { id: ahaTemplate?.ahaProjectTemplate?.id }
    })
      .then(() => {
        displayToast(
          t('ahaActionsMenu.toasts.deleteProjectTemplate.success', {
            name: ahaTemplate?.ahaProjectTemplate?.type
          }),
          'success'
        );
        handleDeleteModalClose();
        history.push(`/projects/${projectId}/ahas`);
      })
      .catch(error => {
        console.error('Delete Project Template Error: ', error);
        displayToast(
          t('ahaActionsMenu.toasts.deleteProjectTemplate.error'),
          'error'
        );
      });
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error) {
    if (error.message?.includes('not found')) {
      return <PageNotFoundNotice />;
    } else {
      return <ErrorNotice />;
    }
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12} className={classes.header}>
          <Typography color="textPrimary" className={classes.title}>
            {type}
          </Typography>
          <Typography color="textPrimary" className={classes.tradePartner}>
            {tradePartner} {t('projectAhaLandingPage.title')}
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.mainContainer}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Grid container>
                <Grid item xs={12} className={classes.metaDataCardContainer}>
                  <Card className={classes.metaDataCard}>
                    <Typography
                      color="textPrimary"
                      className="bold"
                      gutterBottom>
                      Details
                    </Typography>
                    <Typography
                      color="textSecondary"
                      className={classes.metaDataLabel}>
                      Owned By:{' '}
                      <span className={classes.metaData}>
                        {ahaTemplate?.ahaProjectTemplate?.ownedBy?.name}
                      </span>
                    </Typography>
                    <Typography
                      color="textSecondary"
                      className={classes.metaDataLabel}>
                      Created:{' '}
                      <span className={classes.metaData}>
                        {ahaTemplate?.ahaProjectTemplate?.created
                          ? formatDate(ahaTemplate.ahaProjectTemplate.created)
                          : ''}
                      </span>
                    </Typography>
                    <Typography
                      color="textSecondary"
                      className={classes.metaDataLabel}>
                      Created By:{' '}
                      <span className={classes.metaData}>
                        {ahaTemplate?.createdBy?.name}
                      </span>
                    </Typography>
                    <Typography
                      color="textSecondary"
                      className={classes.metaDataLabel}>
                      Last Modified:{' '}
                      <span className={classes.metaData}>
                        {ahaTemplate?.ahaProjectTemplate?.lastModified
                          ? formatDate(
                              ahaTemplate.ahaProjectTemplate.lastModified
                            )
                          : ''}
                      </span>
                    </Typography>
                    <Button
                      onClick={() => {
                        history.push(
                          `/projects/${projectId}/ahas/hensel-phelps-aha/${ahaId}/edit?view=summary`
                        );
                      }}
                      color="primary"
                      className={classes.editButton}
                      variant="contained">
                      <Typography className={classes.buttonLabel}>
                        Edit AHA
                      </Typography>
                    </Button>
                  </Card>
                </Grid>
                <Grid item xs={12} className={classes.statusCardContainer}>
                  <AhaStatusCard ahaTemplate={ahaTemplate} />
                </Grid>
                <Grid item xs={12} className={classes.actionsCardContainer}>
                  <Card className={classes.actionsCard}>
                    <Typography
                      color="textPrimary"
                      className="bold"
                      gutterBottom>
                      Actions
                    </Typography>
                    <Grid container alignItems="center">
                      <Grid item xs={12}>
                        <ActionsButton
                          handleClick={() => {
                            toggleNotificationSettingsDialog(true);
                          }}
                          label={'Manage Notifications'}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <ActionsButton
                          handleClick={() =>
                            toggleTransferOwnershipDialog(true)
                          }
                          label={'Transfer Ownership'}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <ActionsButton
                          handleClick={() => toggleSaveAsDialog(true)}
                          label={'Copy AHA'}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <ActionsButton
                          handleClick={() =>
                            setIsConfirmStepDeleteDialogOpen(true)
                          }
                          label={'Delete'}
                        />
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={9} lg={9}>
              <Grid container>
                <Grid item xs={12} className={classes.reviewsTableContainer}>
                  <ReviewsTabContent
                    projectTemplateId={projectTemplateId}
                    ahaTemplate={ahaTemplate}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FilesTabContent projectTemplateId={projectTemplateId} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <NotificationSettingsDialog
        notificationSettingsDialogIsOpen={notificationSettingsDialogIsOpen}
        toggleNotificationSettingsDialog={toggleNotificationSettingsDialog}
        ahaTemplate={ahaTemplate}
      />
      <CopyActivityDialog
        saveAsDialogIsOpen={saveAsDialogIsOpen}
        toggleSaveAsDialog={toggleSaveAsDialog}
        ahaTemplate={ahaTemplate}
        isGlobal={false}
      />
      <TransferOwnershipDialog
        transferOwnershipDialogIsOpen={transferOwnershipDialogIsOpen}
        toggleTransferOwnershipDialog={toggleTransferOwnershipDialog}
        ahaTemplate={ahaTemplate}
      />
      <StyledDeleteConfirmation
        title={t('ahaActionsMenu.deleteConfirmation.title')}
        dialogIsOpen={isConfirmStepDeleteDialogOpen}
        isLoading={false}
        handleClose={() => setIsConfirmStepDeleteDialogOpen(false)}
        type="notice"
        warningMessage={t('ahaActionsMenu.deleteConfirmation.warningMessage')}
        isConfirmed={isDeleteConfirmed}
        handleChange={event => toggleIsDeleteConfirmed(event.target.checked)}
        handleSubmit={handleDeleteSubmit}
      />
    </>
  );
};

ProjectAhaLandingPage.propTypes = {};

export default withOnlineAccessOnly(
  withAuthorization(ProjectAhaLandingPage, {
    personnelOnProject: true
  })
);
