import { atom, useRecoilState } from 'recoil';
import { LOCAL_STORAGE_PENDING_REVIEW_AHA_SORT_ORDER_KEY } from 'constants/localStorage';

const DEFAULT_SORT_ORDER = {
  name: 'created',
  direction: 'desc'
};

const getStoredSortOrder = () => {
  const unserializedSortOrder = localStorage.getItem(
    LOCAL_STORAGE_PENDING_REVIEW_AHA_SORT_ORDER_KEY
  );
  if (unserializedSortOrder) {
    return JSON.parse(unserializedSortOrder);
  } else {
    return null;
  }
};

const order = getStoredSortOrder() ?? DEFAULT_SORT_ORDER;
const DEFAULT_ROWS_PER_PAGE = 30;

const defaultState = {
  filter: { status: 'Pending' },
  first: DEFAULT_ROWS_PER_PAGE,
  skip: 0,
  order,
  search: ''
};

const pendingReviewAhaListStateAtom = atom({
  key: 'pendingReviewAhaListState',
  default: defaultState
});

function usePendingReviewAhaListState() {
  const [
    pendingReviewAhaListState,
    setPendingReviewAhaListState
  ] = useRecoilState(pendingReviewAhaListStateAtom);

  function handlePendingReviewAhaListStateChange(newState) {
    setPendingReviewAhaListState({
      ...pendingReviewAhaListState,
      ...newState
    });
  }
  return [
    pendingReviewAhaListState,
    {
      handlePendingReviewAhaListStateChange
    }
  ];
}

export default usePendingReviewAhaListState;
