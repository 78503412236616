import makeStyles from '@mui/styles/makeStyles';

const useRiskAssessmentMatrixStyles = () => {
  return makeStyles(theme => ({
    highestScoreRow: {
      borderBottom: '1px solid' + theme.borderGray,
      paddingLeft: theme.spacing(2),
      height: 31,
      [theme.breakpoints.down('lg')]: {
        borderTop: '1px solid' + theme.borderGray,
        height: 'auto',
        paddingLeft: 4
      }
    },
    highestScoreLabel: {
      [theme.breakpoints.down('lg')]: { fontSize: '0.875rem' }
    },
    row: {
      borderBottom: '1px solid' + theme.borderGray,
      paddingLeft: theme.spacing(2),
      minHeight: 30,
      [theme.breakpoints.down('lg')]: { paddingLeft: 4 }
    },
    highestScoreContainer: {
      borderLeft: '1px solid' + theme.borderGray,
      borderBottom: '1px solid' + theme.borderGray,
      height: 30,
      backgroundColor: theme.palette.ahaRacMatrix.extremelyHighRisk,
      [theme.breakpoints.down('lg')]: { height: 48, borderBottom: 'none' }
    },
    extremelyHighRiskBg: {
      backgroundColor: theme.palette.ahaRacMatrix.extremelyHighRisk
    },
    highRiskBg: { backgroundColor: theme.palette.ahaRacMatrix.highRisk },
    moderateRiskBg: {
      backgroundColor: theme.palette.ahaRacMatrix.moderateRisk
    },
    lowRiskBg: { backgroundColor: theme.palette.ahaRacMatrix.lowRisk },
    noStepsBg: { backgroundColor: theme.palette.background.default },
    matrixTitle: {
      fontSize: '0.875rem',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      padding: theme.spacing(0.75, 0, 0, 0),
      textAlign: 'center',
      [theme.breakpoints.down('lg')]: { fontSize: '0.75rem' }
    },
    matrixRow: { height: 30 },
    severityAxisLabel: {
      fontSize: '0.875rem',
      fontWeight: 'bold',
      paddingLeft: theme.spacing(2),
      [theme.breakpoints.down('lg')]: { fontSize: '0.625rem', paddingLeft: 4 }
    },
    probabilityAxisLabel: {
      fontSize: '0.875rem',
      fontWeight: 'bold',
      [theme.breakpoints.down('lg')]: { fontSize: '0.625rem' }
    },
    severityLevelContainer: {
      borderTop: '1px solid' + theme.borderGray
    },
    severityLevel: {
      paddingLeft: theme.spacing(2),
      fontSize: '0.75rem',
      paddingTop: theme.spacing(0.75),
      [theme.breakpoints.down('lg')]: { fontSize: '0.625rem', paddingLeft: 4 }
    },
    probabilityLabel: {
      fontWeight: 'bold',
      fontSize: '0.75rem',
      textAlign: 'center',
      [theme.breakpoints.down('lg')]: { fontSize: '0.625rem' }
    },
    extremelyHighRiskContainer: {
      boxSizing: 'border-box',
      backgroundColor: theme.palette.ahaRacMatrix.extremelyHighRisk,
      borderTop: '1px solid' + theme.borderGray,
      borderLeft: '1px solid' + theme.borderGray
    },
    highRiskContainer: {
      boxSizing: 'border-box',
      backgroundColor: theme.palette.ahaRacMatrix.highRisk,
      borderTop: '1px solid' + theme.borderGray,
      borderLeft: '1px solid' + theme.borderGray
    },
    moderateRiskContainer: {
      boxSizing: 'border-box',
      backgroundColor: theme.palette.ahaRacMatrix.moderateRisk,
      borderTop: '1px solid' + theme.borderGray,
      borderLeft: '1px solid' + theme.borderGray
    },
    lowRiskContainer: {
      boxSizing: 'border-box',
      backgroundColor: theme.palette.ahaRacMatrix.lowRisk,
      borderTop: '1px solid' + theme.borderGray,
      borderLeft: '1px solid' + theme.borderGray
    },
    riskLabel: {
      textAlign: 'center',
      fontSize: '0.75rem',
      fontWeight: 'bold',
      paddingTop: theme.spacing(0.75)
    },
    extremelyHighRiskLabel: {
      color: theme.palette.ahaRacMatrix.extremelyHighRiskLabel
    },
    highRiskLabel: {
      color: theme.palette.ahaRacMatrix.highRiskLabel
    },
    moderateRiskLabel: {
      color: theme.palette.ahaRacMatrix.moderateRiskLabel
    },
    lowRiskLabel: {
      color: theme.palette.ahaRacMatrix.lowRiskLabel
    },
    noStepsLabel: { color: theme.palette.secondary.contrastText },
    racLegendContainer: {
      borderTop: '1px solid' + theme.borderGray,
      borderLeft: '1px solid' + theme.borderGray
    }
  }));
};

export default useRiskAssessmentMatrixStyles;
