import gql from 'graphql-tag';

// Subscriptions
export const ORIENTATION_SUBSCRIPTION = gql`
  subscription orientation($orientationId: ID!) {
    orientation(id: $orientationId) {
      id
      name
      maxAttendees
      language
      facilitators {
        id
        name
        jobTitle
        upn
      }
      isCancelled
      notes
      timeZone
      startDateTime
      endDateTime
      project {
        id
        name
        number
      }
      series {
        id
        type
        interval
        startDateTime
        endDateTime
        endDate
        timeZone
      }
      attendees {
        id
        firstName
        middleName
        lastName
        nickname
        dob
        notes
        contactInformation {
          primaryPhone {
            number
            display
            extension
            type
          }
          emailAddress {
            email
            type
          }
        }
        fullName
        customId
        isSupervisor
        tradePartner {
          id
          name
          project {
            id
            name
            number
            districtNumber
          }
        }
        personnel {
          id
          flags {
            id
            status
            reason
            resolution
          }
        }
        trainings {
          id
          name
          otherName
          notes
          awardedDate
          expirationDate
          files {
            id
            name
            url
            thumbnailUrl
            sizeInBytes
            contentType
            isScanned
            isMalware
            cachedUrl @client
            cachedFile @client
          }
        }
        status
      }
    }
  }
`;

export const GET_ORIENTATIONS_ON_PROJECT_SUBSCRIPTION = gql`
  subscription GetOrientationsOnProject($projectId: ID!) {
    orientationsOnProject(projectId: $projectId) {
      id
      name
      maxAttendees
      language
      facilitators {
        id
        name
        jobTitle
        upn
      }
      isCancelled
      notes
      timeZone
      startDateTime
      endDateTime
      project {
        id
        name
        number
      }
      series {
        id
        type
        interval
        startDateTime
        endDateTime
        endDate
        timeZone
      }
      attendees {
        id
        fullName
        customId
        tradePartner {
          id
          name
          project {
            id
            name
            number
            districtNumber
          }
        }
        trainings {
          id
          name
          otherName
          notes
          awardedDate
          expirationDate
          files {
            id
            name
            url
            thumbnailUrl
            sizeInBytes
            contentType
            isScanned
            isMalware
            cachedUrl @client
            cachedFile @client
          }
        }
        status
      }
    }
  }
`;

export const DELETED_ORIENTATIONS_SUBSCRIPTION = gql`
  subscription DeletedOrientations {
    deletedOrientations {
      id
    }
  }
`;

// Queries
export const GET_ALL_ORIENTATIONS_FOR_PROJECT = gql`
  query GetAllOrientationsForProject($projectId: ID!) {
    orientationsOnProject(projectId: $projectId) {
      id
      name
      maxAttendees
      language
      facilitators {
        id
        name
        jobTitle
        upn
      }
      isCancelled
      notes
      timeZone
      startDateTime
      endDateTime
      project {
        id
        name
        number
      }
      series {
        id
        type
        interval
        startDateTime
        endDateTime
        endDate
        timeZone
      }
      attendees {
        id
        fullName
        customId
        tradePartner {
          id
          name
          project {
            id
            name
            number
            districtNumber
          }
        }
        trainings {
          id
          name
          otherName
          notes
          awardedDate
          expirationDate
          files {
            id
            name
            url
            thumbnailUrl
            sizeInBytes
            contentType
            isScanned
            isMalware
            cachedUrl @client
            cachedFile @client
          }
        }
        status
      }
    }
  }
`;

export const GET_ORIENTATION = gql`
  query GetSingleOrientation($orientationId: ID!) {
    orientation(id: $orientationId) {
      id
      name
      maxAttendees
      language
      facilitators {
        id
        name
        jobTitle
        upn
      }
      isCancelled
      notes
      timeZone
      startDateTime
      endDateTime
      project {
        id
        name
        number
      }
      series {
        id
        type
        interval
        startDateTime
        endDateTime
        endDate
        timeZone
      }
      attendees {
        id
        firstName
        middleName
        lastName
        nickname
        dob
        notes
        contactInformation {
          primaryPhone {
            number
            display
            extension
            type
          }
          emailAddress {
            email
            type
          }
        }
        fullName
        customId
        isSupervisor
        tradePartner {
          id
          name
          project {
            id
            name
            number
            districtNumber
          }
        }
        personnel {
          id
          flags {
            id
            status
            reason
            resolution
          }
        }
        trainings {
          id
          name
          otherName
          notes
          awardedDate
          expirationDate
          files {
            id
            name
            url
            thumbnailUrl
            sizeInBytes
            contentType
            isScanned
            isMalware
            cachedUrl @client
            cachedFile @client
          }
        }
        status
        lastModified
      }
    }
  }
`;

// Mutations
export const CREATE_ORIENTATION = gql`
  mutation CreateOrientation($input: OrientationCreateInput!) {
    createOrientation(input: $input) {
      id
      name
      maxAttendees
      language
      facilitators {
        id
        name
        jobTitle
        upn
      }
      isCancelled
      notes
      timeZone
      startDateTime
      endDateTime
      project {
        id
        name
        number
      }
      series {
        id
        type
        interval
        startDateTime
        endDateTime
        endDate
        timeZone
      }
      attendees {
        id
        fullName
        customId
        tradePartner {
          id
          name
          project {
            id
            name
            number
            districtNumber
          }
        }
        trainings {
          id
          name
          otherName
          notes
          awardedDate
          expirationDate
          files {
            id
            name
            url
            thumbnailUrl
            sizeInBytes
            contentType
            isScanned
            isMalware
            cachedUrl @client
            cachedFile @client
          }
        }
        status
      }
    }
  }
`;

export const UPDATE_ORIENTATION = gql`
  mutation UpdateOrientation($input: OrientationUpdateInput!) {
    updateOrientation(input: $input) {
      id
      name
      maxAttendees
      language
      facilitators {
        id
        name
        jobTitle
        upn
      }
      isCancelled
      notes
      timeZone
      startDateTime
      endDateTime
      project {
        id
        name
        number
      }
      series {
        id
        type
        interval
        startDateTime
        endDateTime
        endDate
        timeZone
      }
      attendees {
        id
        firstName
        middleName
        lastName
        nickname
        dob
        notes
        contactInformation {
          primaryPhone {
            number
            display
            extension
            type
          }
          emailAddress {
            email
            type
          }
        }
        fullName
        customId
        isSupervisor
        tradePartner {
          id
          name
          project {
            id
            name
            number
            districtNumber
          }
        }
        trainings {
          id
          name
          otherName
          notes
          awardedDate
          expirationDate
          files {
            id
            name
            url
            thumbnailUrl
            sizeInBytes
            contentType
            isScanned
            isMalware
            cachedUrl @client
            cachedFile @client
          }
        }
        status
      }
    }
  }
`;

export const DELETE_ORIENTATION = gql`
  mutation DeleteOrientation($orientationId: ID!) {
    deleteOrientation(id: $orientationId) {
      id
    }
  }
`;

// Mutations for Series
export const CREATE_ORIENTATION_SERIES = gql`
  mutation CreateOrientationSeries($input: OrientationSeriesCreateInput!) {
    createOrientationSeries(input: $input) {
      id
      name
      maxAttendees
      language
      facilitators {
        id
        name
        jobTitle
        upn
      }
      isCancelled
      notes
      timeZone
      startDateTime
      endDateTime
      project {
        id
        name
        number
      }
      series {
        id
        type
        interval
        startDateTime
        endDateTime
        endDate
        timeZone
      }
      attendees {
        id
        fullName
        customId
        tradePartner {
          id
          name
          project {
            id
            name
            number
            districtNumber
          }
        }
        trainings {
          id
          name
          otherName
          notes
          awardedDate
          expirationDate
          files {
            id
            name
            url
            thumbnailUrl
            sizeInBytes
            contentType
            isScanned
            isMalware
            cachedUrl @client
            cachedFile @client
          }
        }
        status
      }
    }
  }
`;

export const UPDATE_ORIENTATION_SERIES = gql`
  mutation UpdateOrientationSeries($input: OrientationSeriesUpdateInput!) {
    updateOrientationSeries(input: $input) {
      id
      name
      maxAttendees
      language
      facilitators {
        id
        name
        jobTitle
        upn
      }
      isCancelled
      notes
      timeZone
      startDateTime
      endDateTime
      project {
        id
        name
        number
      }
      series {
        id
        type
        interval
        startDateTime
        endDateTime
        endDate
        timeZone
      }
      attendees {
        id
        firstName
        middleName
        lastName
        nickname
        dob
        notes
        contactInformation {
          primaryPhone {
            number
            display
            extension
            type
          }
          emailAddress {
            email
            type
          }
        }
        fullName
        customId
        isSupervisor
        tradePartner {
          id
          name
          project {
            id
            name
            number
            districtNumber
          }
        }
        trainings {
          id
          name
          otherName
          notes
          awardedDate
          expirationDate
          files {
            id
            name
            url
            thumbnailUrl
            sizeInBytes
            contentType
            isScanned
            isMalware
            cachedUrl @client
            cachedFile @client
          }
        }
        status
      }
    }
  }
`;

export const CANCEL_ORIENTATION_SERIES = gql`
  mutation CancelOrientationSeries($id: ID!, $notes: String) {
    cancelOrientationSeries(id: $id, notes: $notes) {
      id
      isCancelled
    }
  }
`;

export const DELETE_ORIENTATION_SERIES = gql`
  mutation DeleteOrientationSeries($id: ID!) {
    deleteOrientationSeries(id: $id) {
      id
      isCancelled
    }
  }
`;
