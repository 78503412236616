import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useField, useForm } from 'react-final-form-hooks';
import { useMutation } from '@apollo/react-hooks';
import DeleteIcon from '@mui/icons-material/Delete';
import makeStyles from '@mui/styles/makeStyles';
import {
  Grid,
  IconButton,
  Button,
  Typography,
  Tooltip,
  TextField,
  CircularProgress
} from '@mui/material';

import useToast from 'hooks/useToast';
import {
  GET_ALL_GLOBAL_TEMPLATE_CATEGORIES,
  UPDATE_GLOBAL_AHA_TEMPLATE_CATEGORY,
  DELETE_GLOBAL_AHA_TEMPLATE_CATEGORY
} from 'graphql/aha/globalTemplateCategory';

const useStyles = makeStyles(theme => ({
  buttonLabel: { fontSize: '0.75rem', fontWeight: 'bold' },
  buttonContainer: { marginLeft: theme.spacing(1) }
}));

const EditableAhaCategory = ({
  category,
  categoryIdsWithUnsavedChanges,
  setCategoryIdsWithUnsavedChanges,
  setIsCategoryEditLoading
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { displayToast } = useToast();
  const [isEditing, setIsEditing] = useState(false);
  const [, setSelectedCategory] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const ahasExist = category?.ahaTemplates?.length > 0;

  const [
    updateAhaGlobalTemplateCategory,
    { loading: isUpdateLoading }
  ] = useMutation(UPDATE_GLOBAL_AHA_TEMPLATE_CATEGORY, {
    refetchQueries: [{ query: GET_ALL_GLOBAL_TEMPLATE_CATEGORIES }],
    awaitRefetchQueries: true
  });

  const [
    deleteAhaGlobalTemplateCategory,
    { loading: isDeleteLoading }
  ] = useMutation(DELETE_GLOBAL_AHA_TEMPLATE_CATEGORY, {
    refetchQueries: [{ query: GET_ALL_GLOBAL_TEMPLATE_CATEGORIES }],
    awaitRefetchQueries: true
  });

  const isLoading = isUpdateLoading || isDeleteLoading;

  const { form, values, pristine } = useForm({
    /* istanbul ignore next */
    onSubmit: () => {},
    initialValues: {
      categoryName: category.name
    }
  });

  const categoryNameField = useField('categoryName', form);

  useEffect(() => {
    const hasChanges = values.categoryName !== category.name;

    if (hasChanges && !categoryIdsWithUnsavedChanges.includes(category.id)) {
      setCategoryIdsWithUnsavedChanges([
        ...categoryIdsWithUnsavedChanges,
        category.id
      ]);
    } else if (
      !hasChanges &&
      categoryIdsWithUnsavedChanges.includes(category.id)
    ) {
      setCategoryIdsWithUnsavedChanges(
        categoryIdsWithUnsavedChanges.filter(
          unsavedChange => unsavedChange !== category.id
        )
      );
    }
  });

  const canSubmit = () => {
    const { error, invalid } = form.getState();
    return !(invalid || error || pristine);
  };

  const handleUpdateSubmit = category => {
    setIsCategoryEditLoading(true);
    updateAhaGlobalTemplateCategory({
      variables: {
        input: { id: category?.id, name: categoryNameField?.input?.value }
      }
    })
      .then(() => {
        displayToast(
          t('globalStepLibrary.toasts.update.success', {
            name: category.name
          }),
          'success'
        );
        handleClose();
        setIsEditing(false);
      })
      .catch(error => {
        setIsCategoryEditLoading(false);
        console.error('Update Global Step Category Error: ', error);
        displayToast(t('globalStepLibrary.toasts.update.error'), 'error');
      });
  };

  const handleDeleteSubmit = category => {
    setIsCategoryEditLoading(true);
    deleteAhaGlobalTemplateCategory({ variables: { id: category?.id } })
      .then(() => {
        displayToast(
          t('globalStepLibrary.toasts.delete.success', {
            name: category.name
          }),
          'success'
        );
        handleClose();
        setIsDeleting(false);
      })
      .catch(error => {
        setIsCategoryEditLoading(true);
        console.error('Delete Global Step Category Error: ', error);
        displayToast(t('globalStepLibrary.toasts.delete.error'), 'error');
      });
  };

  const handleCancel = () => {
    form.reset();
    setIsEditing(false);
    setIsDeleting(false);
    setIsCategoryEditLoading(false);
  };

  const handleClose = () => {
    setSelectedCategory(null);
    setIsEditing(false);
    setIsDeleting(false);
    setIsCategoryEditLoading(false);
  };

  return (
    <Grid container alignItems="center" direction="row">
      <Grid item>
        <TextField
          value={categoryNameField.input.value}
          input={categoryNameField.input}
          onChange={categoryNameField.input.onChange}
          onClick={() => {
            setSelectedCategory(category);
            setIsEditing(true);
          }}
          meta={categoryNameField.meta}
          color="primary"
          margin="dense"
          variant="outlined"
          fullWidth={true}
        />
      </Grid>
      <Grid item>
        {!isEditing && (
          <>
            {ahasExist && (
              <Tooltip title={t('editableAhaCategory.deleteDisabledMessage')}>
                <span>
                  <IconButton
                    size="small"
                    onClick={() =>
                      console.log(
                        t('editableAhaCategory.deleteDisabledMessage')
                      )
                    }
                    disabled={ahasExist || isLoading}>
                    <DeleteIcon />
                  </IconButton>
                </span>
              </Tooltip>
            )}
            {!ahasExist && !isDeleting && (
              <IconButton
                size="small"
                onClick={() => {
                  setSelectedCategory(category);
                  handleDeleteSubmit(category);
                  setIsDeleting(true);
                }}
                disabled={ahasExist || isLoading}>
                <DeleteIcon />
              </IconButton>
            )}
            {!ahasExist && isDeleting && (
              <CircularProgress color="primary" size={30} />
            )}
          </>
        )}
        {isEditing && (
          <Grid
            container
            direction="row"
            spacing={1}
            className={classes.buttonContainer}>
            <Grid item>
              {!isUpdateLoading && (
                <Button
                  onClick={() => handleUpdateSubmit(category)}
                  disabled={!canSubmit() || isLoading}>
                  <Typography className={classes.buttonLabel}>
                    {t('editableAhaCategory.actions.saveButton')}
                  </Typography>
                </Button>
              )}
              {isUpdateLoading && (
                <CircularProgress color="primary" size={30} />
              )}
            </Grid>
            <Grid item>
              <Button onClick={handleCancel} disabled={isLoading}>
                <Typography className={classes.buttonLabel}>
                  {t('editableAhaCategory.actions.cancelButton')}
                </Typography>
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

EditableAhaCategory.propTypes = {
  category: PropTypes.object.isRequired,
  categoryIdsWithUnsavedChanges: PropTypes.array,
  setCategoryIdsWithUnsavedChanges: PropTypes.func,
  setIsCategoryEditLoading: PropTypes.func
};

export default EditableAhaCategory;
