import React, { useState, Fragment } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import OfflineIcon from '@mui/icons-material/CloudOff';
import OfflineBoltIcon from '@mui/icons-material/OfflineBolt';
import BuildIcon from '@mui/icons-material/Build';
import makeStyles from '@mui/styles/makeStyles';
import {
  AppBar,
  Toolbar,
  Typography,
  Grid,
  Link as MuiLink,
  useTheme,
  Tooltip,
  IconButton,
  Badge
} from '@mui/material';

import useActiveRoute from 'hooks/useActiveRoute';
import useRoles from 'hooks/useRoles';
import HelpMenu from './HelpMenu';
import UserMenu from './UserMenu';
import SideNavigationMenu from './SideNavigationMenu';
import CollapsedUserMenu from './CollapsedUserMenu';
import SettingsDialog from 'components/appbar/SettingsDialog';
import WhatsNew from 'components/appbar/WhatsNew';
import TutorialDialog from 'components/tutorials/TutorialDialog';
import useIsOnline from 'store/onlineDetection';
import useIsManualOfflineModeOn from 'store/manualOfflineMode';
import TestByRoleDialog from 'components/support/TestByRoleDialog';
import { domain, Domain } from 'config/domain';

const useStyles = makeStyles(theme => ({
  appBarTransparent: {
    boxShadow: 'none',
    [theme.breakpoints.down('lg')]: { paddingTop: theme.spacing(0) }
  },
  appBarSolidColor: {
    boxShadow: 'none',
    backgroundColor: theme.primaryAppbarBackgroundColor,
    [theme.breakpoints.down('lg')]: { paddingTop: theme.spacing(0) }
  },
  appName: {
    display: 'inline',
    fontWeight: 'bold',
    color: theme.palette.invariant.white,
    paddingTop: theme.spacing(1)
  },
  leftUserMenu: { flex: 1 },
  hpLogoImg: { width: 200 },
  safetyConnectLogoImg: { height: 34 },
  logosContainer: { flexWrap: 'nowrap' },
  offlineIcon: { color: '#fff' },
  fadedIcon: { color: 'rgba(255, 255, 255, 0.5)' },
  testingIconRoot: {
    borderRadius: '50%',
    padding: theme.spacing(0.5),
    boxShadow: '2px -2px 36px 4px rgba(255, 255, 255, .75)',
    color: 'white',
    width: 28,
    height: 28
  }
}));

const PrimaryAppBar = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const { isActiveRoute } = useActiveRoute();
  const isDashboardPage = isActiveRoute('/projects/:projectId/dashboard');
  const [settingsDialogIsOpen, toggleSettingsDialog] = useState(false);
  const [settingsTab, setSettingsTab] = useState(0);
  const isBeta = process.env.REACT_APP_ENV === 'beta';
  const { isOnline } = useIsOnline();
  const { isOfflineModeEnabled } = useIsManualOfflineModeOn();
  const { isSupportRoleForTesting } = useRoles();
  const [isTestByRoleDialogOpen, setIsTestByRoleDialogOpen] = useState(false);
  const [downgradedRolesValue, handleDowngradedRolesChange] = useState(
    window.sessionStorage.getItem('downgradedRoles') ?? ''
  );
  let downgradedRoles = downgradedRolesValue.split(',').filter(r => r);

  const noDisplaySmXs = { display: { xs: 'none', sm: 'none', md: 'block' } };
  const noPadding = { padding: 0 };
  const spacing = { xs: 1, sm: 3 };

  return (
    <Fragment>
      <AppBar
        className={classNames(classes.appBarSolidColor, {
          [`${classes.appBarTransparent}`]: isDashboardPage
        })}>
        <Toolbar>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Grid
                container
                alignItems="center"
                className={classes.leftUserMenu}>
                <Grid item>
                  <SideNavigationMenu />
                </Grid>
                <Grid item>
                  <Grid
                    container
                    className={classes.logosContainer}
                    direction="row"
                    spacing={spacing}
                    alignItems="center">
                    <Grid
                      item
                      sx={{
                        display: {
                          xs: 'none',
                          md: 'block'
                        }
                      }}>
                      <img
                        data-testid="hpLogoImg"
                        className={classes.hpLogoImg}
                        src={theme.hpLogo}
                        alt={t('images.henselPhelpsLogoAltText')}
                      />
                    </Grid>
                    <Grid item>
                      <MuiLink component={RouterLink} to={'/'} underline="none">
                        <Grid
                          container
                          className={classes.logosContainer}
                          alignItems="center"
                          direction="row"
                          spacing={spacing}>
                          <Grid
                            item
                            sx={{ display: { xs: 'none', sm: 'block' } }}>
                            <img
                              data-testid="safetyConnectLogoImg"
                              className={classes.safetyConnectLogoImg}
                              src={theme.safetyConnectLogo}
                              alt={t('images.henselPhelpsLogoAltText')}
                            />
                          </Grid>
                          <Grid
                            item
                            sx={{ display: { xs: 'block', sm: 'none' } }}>
                            <img
                              data-testid="safetyConnectLogoImg"
                              className={classes.safetyConnectLogoImg}
                              src={theme.safetyConnectLogoMinimal}
                              alt={t('images.henselPhelpsLogoAltText')}
                            />
                          </Grid>
                          {isBeta && (
                            <Grid item>
                              <Typography className={classes.appName}>
                                {t('appNameInBetaEnvironment')}
                              </Typography>
                            </Grid>
                          )}
                        </Grid>
                      </MuiLink>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                alignItems="center"
                spacing={spacing}>
                {isSupportRoleForTesting && (
                  <Grid item>
                    <Badge
                      sx={{ fontWeight: 'bold' }}
                      overlap="circular"
                      color="error"
                      badgeContent={downgradedRoles?.length}>
                      <IconButton
                        size="small"
                        sx={noPadding}
                        onClick={() => setIsTestByRoleDialogOpen(true)}>
                        <BuildIcon
                          classes={{ root: classes.testingIconRoot }}
                        />
                      </IconButton>
                    </Badge>
                  </Grid>
                )}
                {!isOnline && (
                  <Grid item>
                    <OfflineIcon
                      sx={noPadding}
                      className={classes.offlineIcon}
                    />
                  </Grid>
                )}
                {domain === Domain.PRIMARY && (
                  <Grid item>
                    <Tooltip
                      title={
                        isOfflineModeEnabled
                          ? t('offlineModeSwitch.offlineEnabled')
                          : t('offlineModeSwitch.offlineDisabled')
                      }>
                      <IconButton
                        data-testid="offline-mode-icon-button"
                        id={t('offlineModeSwitch.iconButton')}
                        aria-labelledby={t('offlineModeSwitch.iconButton')}
                        size="small"
                        sx={noPadding}
                        onClick={() => {
                          setSettingsTab(1);
                          toggleSettingsDialog(true);
                        }}>
                        <OfflineBoltIcon
                          className={
                            isOfflineModeEnabled
                              ? classes.offlineIcon
                              : classes.fadedIcon
                          }
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                )}
                <Grid
                  item
                  sx={{
                    display: {
                      sm: 'block',
                      xs: 'block',
                      md: domain === Domain.PRIMARY ? 'none' : 'block'
                    }
                  }}>
                  <CollapsedUserMenu sx={noPadding} />
                </Grid>
                {domain === Domain.PRIMARY && (
                  <Fragment>
                    <Grid item sx={noDisplaySmXs}>
                      <HelpMenu iconProps={{ sx: noPadding }} />
                    </Grid>
                    <Grid item sx={noDisplaySmXs}>
                      <WhatsNew iconProps={{ sx: noPadding }} />
                    </Grid>
                  </Fragment>
                )}
                <Grid item>
                  <UserMenu
                    iconProps={{ sx: noPadding }}
                    toggleSettingsDialog={toggleSettingsDialog}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <TutorialDialog />
      <SettingsDialog
        isOpen={settingsDialogIsOpen}
        toggleDialog={toggleSettingsDialog}
        settingsTab={settingsTab}
        setSettingsTab={setSettingsTab}
      />
      <TestByRoleDialog
        isTestByRoleDialogOpen={isTestByRoleDialogOpen}
        setIsTestByRoleDialogOpen={setIsTestByRoleDialogOpen}
        downgradedRolesValue={downgradedRolesValue}
        handleDowngradedRolesChange={handleDowngradedRolesChange}
      />
    </Fragment>
  );
};

export default withRouter(PrimaryAppBar);
