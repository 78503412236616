import { useLocation, matchPath } from 'react-router-dom';

const useActiveRoute = () => {
  let location = useLocation();
  const isActiveRoute = (routeName, exact = true) => {
    return !!matchPath(location.pathname, {
      path: routeName,
      exact: exact
    });
  };

  const projectMatch = matchPath(location.pathname, {
    path: '/projects/:projectId',
    exact: false
  });

  const unlinkedProjectMatch = matchPath(location.pathname, {
    path: '/unlinkedHPEmployees/projects/:projectId',
    exact: false
  });

  const companyMatch = matchPath(location.pathname, {
    path: '/companies/:companyId',
    exact: false
  });

  const tradePartnerMatch = matchPath(location.pathname, {
    path: '/projects/:projectId/trade-partners/:tradePartnerId',
    exact: false
  });

  const tradePartnerPersonnelMatch =
    matchPath(location.pathname, {
      path:
        '/projects/:projectId/trade-partners/:tradePartnerId/personnel/:tradePartnerPersonnelId',
      exact: false
    }) ??
    matchPath(location.pathname, {
      path: '/projects/:projectId/personnel/:tradePartnerPersonnelId',
      exact: false
    });

  const unlinkedPersonnelMatch = matchPath(location.pathname, {
    path:
      '/unlinkedHPEmployees/projects/:projectId/personnel/:tradePartnerPersonnelId',
    exact: false
  });

  const orientationMatch = matchPath(location.pathname, {
    path: '/projects/:projectId/orientations/:orientationId',
    exact: false
  });

  const attendeeMatch = matchPath(location.pathname, {
    path:
      '/projects/:projectId/orientations/:orientationId/attendees/:attendeeId',
    exact: false
  });

  const ahaMatch =
    matchPath(location.pathname, {
      path: '/projects/:projectId/ahas/hensel-phelps-aha/:ahaId',
      exact: false
    }) ??
    matchPath(location.pathname, {
      path: '/ahas/:ahaId/edit',
      exact: false
    });

  const ahaTradePartnerCategoryMatch = matchPath(location.pathname, {
    path:
      '/projects/:projectId/ahas/trade-partner-aha/:ahaTradePartnerCategoryId',
    exact: false
  });

  const ahaReviewMatch = matchPath(location.pathname, {
    path:
      '/projects/:projectId/ahas/hensel-phelps-aha/:ahaId/reviews/:ahaReviewId',
    exact: false
  });

  const params = {
    ...projectMatch?.params,
    ...companyMatch?.params,
    ...tradePartnerMatch?.params,
    ...tradePartnerPersonnelMatch?.params,
    ...orientationMatch?.params,
    ...attendeeMatch?.params,
    ...unlinkedPersonnelMatch?.params,
    ...unlinkedProjectMatch?.params,
    ...ahaMatch?.params,
    ...ahaTradePartnerCategoryMatch?.params,
    ...ahaReviewMatch?.params
  };

  return { isActiveRoute, projectMatch, companyMatch, params };
};

export default useActiveRoute;
