import React, { Suspense } from 'react';
import { render } from 'react-dom';
import { detect } from 'detect-browser';
import { initI18n } from './config/i18n';
import UnsupportedBrowserDisclaimerPage from './pages/UnsupportedBrowserDisclaimerPage';
import LoadingSpinner from './components/common/LoadingSpinner';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { getActions as getSWActions } from 'store/sw';
import 'utils/serviceWorkerErrorHandler.js';

const target = document.getElementById('root');
const browser = detect();

initI18n();
if (browser && browser.name === 'ie') {
  render(
    <Suspense fallback={<LoadingSpinner isFullScreen={true} />}>
      <UnsupportedBrowserDisclaimerPage />
    </Suspense>,
    target
  );
} else {
  require('./indexApp.js');
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register({
  onSuccess: () => getSWActions().handleSuccess(),
  onUpdate: reg => getSWActions().handleUpdate(reg)
});
