import { useState, useEffect } from 'react';
import useIsOnline from 'store/onlineDetection';

const ONE_MIN_IN_MS = 60000;
const INTERVAL = 1 * ONE_MIN_IN_MS;

const useLatestVersion = () => {
  const [state, setState] = useState({
    data: null,
    error: false,
    loading: true
  });
  const { isOnline } = useIsOnline();

  useEffect(() => {
    getLatestVersion();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(getLatestVersion, INTERVAL);
    return () => clearInterval(intervalId);
  });

  function getLatestVersion() {
    if (isOnline) {
      setState(state => ({ data: state.data, error: false, loading: true }));
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker
          .getRegistrations()
          .then(registrations => registrations.forEach(reg => reg.update()))
          .catch(error => {
            console.error(
              'Error checking for new service worker update: ',
              error
            );
          });
      }
      fetch('/meta.json', {
        headers: {
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          Pragma: 'no-cache',
          Expires: 0
        }
      })
        .then(response => response.json())
        .then(meta => {
          setState({
            data: { version: meta.version },
            error: false,
            loading: false
          });
        })
        .catch(function(error) {
          console.error('Error fetching meta.json: ', error);
          setState({ data: null, error: true, loading: false });
        });
    }
  }

  return state;
};

export default useLatestVersion;
