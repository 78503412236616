import gql from 'graphql-tag';

export const GET_ALL_PROJECTS_AS_ADMIN = gql`
  query GetAllProjectsAsAdmin {
    projectsAsAdmin {
      id
      name
      number
      description
      districtNumber
      isActive
      notes
      location {
        display
        streetAndNumber
        streetDetail
        city
        stateOrProvince
        zipOrPostalCode
        country
        latitude
        longitude
        cityAndStateDisplay
      }
      admins {
        id
        upn
        name
        firstName
        lastName
        email
        jobTitle
      }
      accessRequests {
        id
        upn
        name
        firstName
        lastName
        email
        jobTitle
      }
      tradePartners {
        id
        name
        isActive
        notes
        trades
      }
      areas
      availableAhaReferenceOptions
    }
  }
`;

// Subscriptions
export const PROJECT_SUBSCRIPTION = gql`
  subscription project($projectId: ID!) {
    project(id: $projectId) {
      id
      name
      number
      description
      districtNumber
      isActive
      notes
      location {
        display
        streetAndNumber
        streetDetail
        city
        stateOrProvince
        zipOrPostalCode
        country
        latitude
        longitude
        cityAndStateDisplay
      }
      admins {
        id
        upn
        name
        firstName
        lastName
        email
        jobTitle
      }
      accessRequests {
        id
        upn
        name
        firstName
        lastName
        email
        jobTitle
      }
      tradePartners {
        id
        name
        isActive
        notes
        trades
        workerHoursMonthToDate
        workerHoursYearToDate
        workerHoursTotalToDate
      }
      areas
      availableAhaReferenceOptions
      firewatch
      fireDeptPhoneNumber {
        number
        display
        extension
        type
      }
    }
  }
`;

// Queries
export const PAGINATED_PROJECTS = gql`
  query PaginatedProjects(
    $filter: ProjectsFilterInput
    $first: Int!
    $order: [ProjectsOrderInput!]
    $search: String
    $skip: Int
  ) {
    paginatedProjects(
      filter: $filter
      first: $first
      order: $order
      search: $search
      skip: $skip
    ) {
      total
      projects {
        id
        name
        number
        description
        districtNumber
        isActive
        notes
        location {
          display
          streetAndNumber
          streetDetail
          city
          stateOrProvince
          zipOrPostalCode
          country
          latitude
          longitude
          cityAndStateDisplay
        }
        areas
        availableAhaReferenceOptions
        preIncidentAnalysis {
          id
          probability
        }
      }
    }
  }
`;

export const GET_ALL_PROJECTS = gql`
  query GetAllProjects {
    projects {
      id
      name
      number
      description
      districtNumber
      isActive
      notes
      location {
        display
        streetAndNumber
        streetDetail
        city
        stateOrProvince
        zipOrPostalCode
        country
        latitude
        longitude
        cityAndStateDisplay
      }
      areas
      availableAhaReferenceOptions
    }
  }
`;

export const GET_PROJECT = gql`
  query GetSingleProject($projectId: ID!) {
    project(id: $projectId) {
      id
      name
      number
      description
      districtNumber
      isActive
      closed
      notes
      location {
        display
        streetAndNumber
        streetDetail
        city
        stateOrProvince
        zipOrPostalCode
        country
        latitude
        longitude
        cityAndStateDisplay
      }
      admins {
        id
        upn
        name
        firstName
        lastName
        email
        jobTitle
      }
      partners {
        id
        microsoftAdId
        upn
        name
        firstName
        lastName
        email
        jobTitle
      }
      accessRequests {
        id
        upn
        name
        firstName
        lastName
        email
        jobTitle
      }
      tradePartners {
        id
        name
        isActive
        notes
        trades
      }
      areas
      availableAhaReferenceOptions
      firewatch
      fireDeptPhoneNumber {
        number
        display
        extension
        type
      }
      preIncidentAnalysis {
        id
        probability
      }
    }
  }
`;

// Mutations
export const CARBON_COPY_PROJECT = gql`
  mutation CarbonCopyProject($copyFromProjectId: ID!, $copyToProjectId: ID!) {
    carbonCopyProject(
      copyFromProjectId: $copyFromProjectId
      copyToProjectId: $copyToProjectId
    ) {
      id
    }
  }
`;

export const SET_ADMIN_STATUS_ON_PROJECT = gql`
  mutation setAdminStatusOnProject(
    $projectId: ID!
    $adminUpn: String!
    $isAdmin: Boolean!
  ) {
    setAdminStatusOnProject(
      projectId: $projectId
      upn: $adminUpn
      isAdmin: $isAdmin
    ) {
      id
      name
      number
      description
      districtNumber
      isActive
      notes
      location {
        display
        streetAndNumber
        streetDetail
        city
        stateOrProvince
        zipOrPostalCode
        country
        latitude
        longitude
        cityAndStateDisplay
      }
      admins {
        id
        upn
        name
        firstName
        lastName
        email
        jobTitle
      }
      accessRequests {
        id
        upn
        name
        firstName
        lastName
        email
        jobTitle
      }
    }
  }
`;

export const SET_PARTNER_STATUS_ON_PROJECT = gql`
  mutation setPartnerStatusOnProject(
    $projectId: ID!
    $microsoftAdId: String!
    $isPartner: Boolean!
  ) {
    setPartnerStatusOnProject(
      projectId: $projectId
      microsoftAdId: $microsoftAdId
      isPartner: $isPartner
    ) {
      id
      name
      number
      description
      districtNumber
      isActive
      notes
      location {
        display
        streetAndNumber
        streetDetail
        city
        stateOrProvince
        zipOrPostalCode
        country
        latitude
        longitude
        cityAndStateDisplay
      }
      partners {
        id
        microsoftAdId
        upn
        name
        firstName
        lastName
        email
        jobTitle
      }
    }
  }
`;

export const RESOLVE_REQUEST_FOR_ACCESS_TO_PROJECT = gql`
  mutation resolveRequestForAccessToProject(
    $isApproved: Boolean!
    $projectId: ID!
    $requesterUpn: String!
  ) {
    resolveRequestForAccessToProject(
      isApproved: $isApproved
      projectId: $projectId
      requesterUpn: $requesterUpn
    ) {
      id
      name
      number
      description
      districtNumber
      isActive
      notes
      location {
        display
        streetAndNumber
        streetDetail
        city
        stateOrProvince
        zipOrPostalCode
        country
        latitude
        longitude
        cityAndStateDisplay
      }
      admins {
        id
        upn
        name
        firstName
        lastName
        email
        jobTitle
      }
      accessRequests {
        id
        upn
        name
        firstName
        lastName
        email
        jobTitle
      }
    }
  }
`;

export const UPDATE_PROJECT = gql`
  mutation updateProject(
    $id: ID!
    $name: String
    $description: String
    $districtNumber: Int
    $isActive: Boolean
    $notes: String
    $location: LocationUpdateInput
    $areas: [String!]
    $availableAhaReferenceOptions: [AhaReferenceOption!]
    $closed: DateTime
    $firewatch: Int
    $fireDeptPhoneNumber: PhoneNumberUpdateInput
  ) {
    updateProject(
      input: {
        id: $id
        name: $name
        description: $description
        districtNumber: $districtNumber
        isActive: $isActive
        notes: $notes
        location: $location
        areas: $areas
        availableAhaReferenceOptions: $availableAhaReferenceOptions
        closed: $closed
        firewatch: $firewatch
        fireDeptPhoneNumber: $fireDeptPhoneNumber
      }
    ) {
      id
      name
      number
      description
      districtNumber
      isActive
      closed
      notes
      location {
        display
        streetAndNumber
        streetDetail
        city
        stateOrProvince
        zipOrPostalCode
        country
        latitude
        longitude
        cityAndStateDisplay
      }
      admins {
        id
        upn
        name
        firstName
        lastName
        email
        jobTitle
      }
      accessRequests {
        id
        upn
        name
        firstName
        lastName
        email
        jobTitle
      }
      areas
      availableAhaReferenceOptions
      firewatch
      fireDeptPhoneNumber {
        number
        display
        extension
        type
      }
    }
  }
`;
