export const LOCAL_STORAGE_OBSERVATION_LIST_FILTERS_KEY =
  'observation-list-state-filters-2';

export const LOCAL_STORAGE_OFFLINE_MODE_ENABLED_KEY = 'offline-mode-enabled';

export const LOCAL_STORAGE_HENSEL_PHELPS_AHA_LIST_FILTERS_KEY =
  'hensel-phelps-aha-list-state-filters';

export const LOCAL_STORAGE_TRADE_PARTNER_AHA_LIST_FILTERS_KEY =
  'trade-partner-aha-list-state-filters';

export const LOCAL_STORAGE_TRADE_PARTNER_AHA_CATEGORY_FILES_LIST_FILTERS_KEY =
  'trade-partner-aha-category-files-list-state-filters';

export const LOCAL_STORAGE_PENDING_REVIEW_AHA_LIST_FILTERS_KEY =
  'trade-partner-pending-review-list-state-filters';

export const LOCAL_STORAGE_HENSEL_PHELPS_AHA_SORT_ORDER_KEY =
  'hensel-phelps-aha-list-sort-order';

export const LOCAL_STORAGE_TRADE_PARTNER_AHA_SORT_ORDER_KEY =
  'trade-partner-aha-list-sort-order';

export const LOCAL_STORAGE_TRADE_PARTNER_AHA_CATEGORY_FILES_SORT_ORDER_KEY =
  'trade-partner-aha-category-files-list-sort-order';

export const LOCAL_STORAGE_PENDING_REVIEW_AHA_SORT_ORDER_KEY =
  'trade-partner-pending-review-sort-order';

export const LOCAL_STORAGE_AHA_FILES_ON_PROJECT_TEMPLATE_SORT_ORDER_KEY =
  'aha-files-on-project-template-sort-order';

export const LOCAL_STORAGE_AHA_FILES_ON_PROJECT_LIST_FILTERS_KEY =
  'aha-files-on-project-list-filters';

export const LOCAL_STORAGE_AHA_REVIEWS_ON_PROJECT_TEMPLATE_SORT_ORDER_KEY =
  'aha-reviews-on-project-list-filters';

export const LOCAL_STORAGE_PROJECTS_SORT_ORDER_KEY = 'projects-sort-order';

export const LOCAL_STORAGE_PROJECTS_FILTERS_KEY = 'projects-list-filters';

export const LOCAL_STORAGE_COMPANIES_SORT_ORDER_KEY = 'companies-sort-order';

export const LOCAL_STORAGE_COMPANIES_FILTERS_KEY = 'companies-list-filters';
