import React from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import useRoles from 'hooks/useRoles';
import useCurrentUser from 'hooks/useCurrentUser';
import UnauthorizedPageNotice from 'components/common/UnauthorizedPageNotice';
import LoadingSpinner from 'components/common/LoadingSpinner';

const DEFAULT_ALLOWED_ROLES = {
  partnerOnProject: false,
  personnelOnProject: false,
  ahaAdmin: false,
  enterpriseAdmin: true,
  adminOnProject: true
};

function withAuthorization(
  WrappedComponent,
  allowedComponentTypes = DEFAULT_ALLOWED_ROLES
) {
  return props => {
    const { projectId } = useParams();
    const {
      isAdminOnProject,
      isEnterpriseAdmin,
      isPersonnelOnProject,
      isPartnerOnProject,
      isAhaAdmin
    } = useRoles();

    const allowedRoles = {
      ...DEFAULT_ALLOWED_ROLES,
      ...allowedComponentTypes
    };
    const isAuthorized =
      (allowedRoles.enterpriseAdmin && isEnterpriseAdmin) ||
      (allowedRoles.adminOnProject && isAdminOnProject(projectId)) ||
      (allowedRoles.personnelOnProject && isPersonnelOnProject(projectId)) ||
      (allowedRoles.partnerOnProject && isPartnerOnProject(projectId)) ||
      (allowedRoles.ahaAdmin && isAhaAdmin);

    const { currentUser } = useCurrentUser();

    if (isAuthorized) {
      return <WrappedComponent {...props} />;
    } else if (!isAuthorized && !!currentUser?.isLoaded) {
      return <UnauthorizedPageNotice />;
    } else {
      return <LoadingSpinner />;
    }
  };
}

withAuthorization.propTypes = {
  WrappedComponent: PropTypes.node,
  allowedComponentTypes: PropTypes.shape({
    personnelOnProject: PropTypes.bool,
    isAhaAdmin: PropTypes.bool
  })
};

export default withAuthorization;
