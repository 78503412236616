import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'clone-deep';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import RemoveIcon from '@mui/icons-material/RemoveCircleOutline';
import { Grid, Box, Typography, IconButton, Tooltip } from '@mui/material';

import useToast from 'hooks/useToast';
import useConfirmDialog from 'hooks/useConfirmDialog';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import AdUserPermissionCard from 'components/ad/AdUserPermissionCard';
import { SET_ADMIN_STATUS_ON_PROJECT } from 'graphql/project';

let deleteAdminList = [];

const setDeleteAdminList = arr => {
  deleteAdminList = arr;
};

export const ProjectAdminList = ({ admins, projectId }) => {
  const { t } = useTranslation();
  const confirmAction = useConfirmDialog();
  const { displayToast } = useToast();
  const isDeleting = adminId => {
    return deleteAdminList.includes(adminId);
  };

  const handleRemoveAdminClick = admin => {
    confirmAction(
      t('projectAdminList.removeAdminDialog.title'),
      t('projectAdminList.removeAdminDialog.confirmation', {
        firstName: admin.firstName,
        lastName: admin.lastName
      }),
      t('projectAdminList.removeAdminDialog.cancelButton'),
      t('projectAdminList.removeAdminDialog.submitButton')
    ).then(
      result => {
        setDeleteAdminList([...deleteAdminList, admin.id]);
        handleRemoveAdmin(admin);
      },
      () => {
        // cancel was pressed
      }
    );
  };

  const [removeAdmin] = useMutation(SET_ADMIN_STATUS_ON_PROJECT);

  const handleRemoveAdmin = admin => {
    removeAdmin({
      variables: {
        projectId: projectId,
        adminUpn: admin.upn,
        isAdmin: false
      }
    })
      .then(data => {
        displayToast(
          t('projectAdminList.toasts.success', {
            firstName: admin.firstName,
            lastName: admin.lastName
          }),
          'success'
        );
      })
      .catch(error => {
        console.error(error);
        displayToast(t('projectAdminList.toasts.error'), 'error');
      })
      .finally(() => {
        setDeleteAdminList(
          deleteAdminList.filter(deletedAdmin => deletedAdmin !== admin.id)
        );
      });
  };

  function getSortedAdmins(admins) {
    const sortedAdmins = cloneDeep(admins);
    return sortedAdmins.sort((a, b) => {
      var nameA = a.name.toLowerCase();
      var nameB = b.name.toLowerCase();
      return nameA.localeCompare(nameB);
    });
  }
  const sortedAdmins = getSortedAdmins(admins);

  return (
    <Grid container justifyContent="space-between" direction="row" spacing={3}>
      {sortedAdmins.map(admin => (
        <Grid item xs={12} sm={12} md={12} lg={6} key={admin.id}>
          <AdUserPermissionCard
            user={admin}
            isLoading={isDeleting(admin.id)}
            actions={
              <Fragment>
                <Tooltip
                  title="Remove"
                  sx={{
                    display: {
                      xs: 'block',
                      sm: 'none',
                      md: 'none',
                      lg: 'none',
                      xl: 'none'
                    }
                  }}>
                  <IconButton
                    data-testid="remove-admin-iconbutton"
                    disabled={isDeleting(admin.id)}
                    onClick={() => handleRemoveAdminClick(admin)}
                    size="large">
                    <RemoveIcon />
                  </IconButton>
                </Tooltip>
                <Box
                  sx={{
                    display: {
                      xs: 'none',
                      sm: 'block',
                      md: 'block',
                      lg: 'block'
                    }
                  }}>
                  <StyledButtonPrimary
                    data-testid={`remove-admin-button-${admin.upn}`}
                    disabled={isDeleting(admin.id)}
                    label={t('projectAdminList.remove')}
                    onClick={() => handleRemoveAdminClick(admin)}
                  />
                </Box>
              </Fragment>
            }
          />
        </Grid>
      ))}
      {sortedAdmins.length < 1 && (
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Typography color="primary">
            {t('projectAdminList.noAdmins')}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

ProjectAdminList.propTypes = {
  admins: PropTypes.array.isRequired,
  projectId: PropTypes.string.isRequired
};

export default ProjectAdminList;
