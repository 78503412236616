import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Typography } from '@mui/material';

const useStyles = makeStyles(theme => ({
  label: { fontSize: '0.75rem', fontWeight: 'bold' },
  typeButtonLabel: {
    fontSize: '0.875rem',
    textTransform: 'capitalize',
    marginTop: 3
  },
  typeButtonIcon: {
    fontSize: '1rem',
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(0.5)
  },
  buttonGroup: { width: '100%' },
  toggleButton: {
    width: '50%',
    border: '1px solid' + theme.palette.field.border,
    padding: 0,
    minHeight: 36
  }
}));

const ObservationTypeToggle = ({
  setObservationType,
  selectedObservation,
  form,
  typeRecommendation = undefined,
  shouldApplyTypeRecommendation = undefined,
  setShouldApplyTypeRecommendation = undefined
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const [alignment, setAlignment] = useState(null);

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  // theme.overrides.MuiToggleButton = {
  //   root: {
  //     color: 'inherit',
  //     '&.Mui-selected': {
  //       backgroundColor: theme.palette.primary.main + '!important',
  //       color: theme.palette.primary.contrastText + '!important'
  //     }
  //   }
  // };

  const toggleType = type => {
    setObservationType(type);

    // the following clears any lingering value for observed person
    // if the user had chosen behavioral first and selected an observed person
    if (type === 'Environmental') {
      form.change('observedPerson', '');
    }
  };

  useEffect(() => {
    if (shouldApplyTypeRecommendation) {
      if (typeRecommendation === 'Behavioral') {
        toggleType(typeRecommendation);
        setAlignment('right');
      } else if (typeRecommendation === 'Environmental') {
        toggleType(typeRecommendation);
        setAlignment('left');
      }
      setShouldApplyTypeRecommendation(false);
    }
  }, [
    typeRecommendation,
    shouldApplyTypeRecommendation,
    setShouldApplyTypeRecommendation
  ]);

  useEffect(() => {
    if (selectedObservation) {
      if (selectedObservation.type === 'Behavioral') {
        setAlignment('right');
      } else if (selectedObservation.type === 'Environmental') {
        setAlignment('left');
      }
    }
  }, [selectedObservation]);

  return (
    <>
      <Typography color="textSecondary" className={classes.label}>
        {`${t('observationForm.field.type.title')} *`}
      </Typography>
      <ToggleButtonGroup
        value={alignment}
        exclusive
        onChange={handleAlignment}
        aria-label="text alignment"
        className={classes.buttonGroup}>
        <ToggleButton
          color="primary"
          value="left"
          aria-label="left aligned"
          onClick={() => toggleType('Environmental')}
          className={classes.toggleButton}
          disabled={
            alignment === 'left' ||
            (!!selectedObservation && selectedObservation.status !== 'Draft')
          }>
          <Typography className={classes.typeButtonLabel}>
            {t('observationForm.field.type.environmental')}
          </Typography>
          {alignment === 'left' && (
            <CheckCircleOutlineIcon className={classes.typeButtonIcon} />
          )}
        </ToggleButton>
        <ToggleButton
          value="right"
          aria-label="right aligned"
          onClick={() => toggleType('Behavioral')}
          className={classes.toggleButton}
          disabled={
            alignment === 'right' ||
            (!!selectedObservation && selectedObservation.status !== 'Draft')
          }>
          <Typography className={classes.typeButtonLabel}>
            {t('observationForm.field.type.behavioral')}
          </Typography>
          {alignment === 'right' && (
            <CheckCircleOutlineIcon className={classes.typeButtonIcon} />
          )}
        </ToggleButton>
      </ToggleButtonGroup>
    </>
  );
};

ObservationTypeToggle.propTypes = {
  setObservationType: PropTypes.func,
  selectedObservation: PropTypes.object,
  form: PropTypes.any,
  typeRecommendation: PropTypes.string,
  shouldApplyTypeRecommendation: PropTypes.bool,
  setShouldApplyTypeRecommendation: PropTypes.func
};

export default ObservationTypeToggle;
