export const SAFETY_DEPARTMENT_URL =
  'https://hpcc2013.sharepoint.com/sites/SafetyDepartment';

export const SAFETY_AND_HEALTH_MANUAL_URL =
  'https://hpcc2013.sharepoint.com/sites/SafetyManual';

export const SILVERBOOK_URL = 'https://silverbook.henselphelps.com/';

export const DRUG_TEST_URL =
  'https://apps.powerapps.com/play/e/9a257c7c-975d-ef67-88d6-bea682fb3e17/a/db03f5d0-f660-4866-8c9c-c77971d3000f?tenantId=aeeffdfb-cb01-4af6-a78f-fcaba7a6fad8&hint=d9eb05e4-e234-47b7-abd0-5e4f57620dbd&sourcetime=1724860460431&source=portal';

export const HP_EMPLOYEE_INFO_MANAGEMENT_URL = 'https://n35.ultipro.com';

export const SERVICENOW_URL = 'https://henselphelps.service-now.com/';

export const CSSR_URL =
  'https://app.powerbi.com/Redirect?action=OpenReport&appId=6c1f2d8c-7a79-42f2-8de6-b538fa5b69ec&reportObjectId=2627d296-a43b-4ca1-b0ba-1ba27b080930&ctid=aeeffdfb-cb01-4af6-a78f-fcaba7a6fad8';

export const TRADE_PARTNER_TRAININGS_URL =
  'https://app.powerbi.com/groups/me/apps/6c1f2d8c-7a79-42f2-8de6-b538fa5b69ec/reports/fcb409cb-e9e8-4e99-bc24-6318ece0f4b8/ReportSection351b67230b6383b9da2e';

export const SAFETY_OBSERVATIONS_REPORT_URL =
  'https://app.powerbi.com/Redirect?action=OpenReport&appId=6c1f2d8c-7a79-42f2-8de6-b538fa5b69ec&reportObjectId=a089fd21-909c-4a2d-8777-3790b783f968&ctid=aeeffdfb-cb01-4af6-a78f-fcaba7a6fad8';

export const GOLD_REPORT_URL =
  'https://app.powerbi.com/Redirect?action=OpenReport&appId=6c1f2d8c-7a79-42f2-8de6-b538fa5b69ec&reportObjectId=c8833fd6-4b3f-4cc8-9ff7-67ce62a6529d&ctid=aeeffdfb-cb01-4af6-a78f-fcaba7a6fad8';

export const LINK_TRAINING_REPORT_URL =
  'https://app.powerbi.com/groups/me/apps/6c1f2d8c-7a79-42f2-8de6-b538fa5b69ec/reports/1b67b2da-70b4-4d81-9cbc-25a3f2b1ea27/ReportSection';

export const CULTURE_OF_CARE_URL =
  'https://hpcc2013.sharepoint.com/sites/SafetyDepartment/SitePages/CARES.aspx';

export const SAFETY_INCIDENTS_URL =
  'https://apps.powerapps.com/play/980753d1-f05d-4e1c-a4fd-2ee5c4fc1514?tenantId=aeeffdfb-cb01-4af6-a78f-fcaba7a6fad8&screenColor=rgba%28255%2C+255%2C+255%2C+1%29&skipAppMetadata=true';

export const SAFETY_VIOLATIONS_URL =
  'https://apps.powerapps.com/play/1f6b39c0-beca-4c1c-96e3-9f30aecd64cc?tenantId=aeeffdfb-cb01-4af6-a78f-fcaba7a6fad8';

export const SAFETY_DATA_SHEETS_URL =
  'https://chemmanagement.ehs.com/9/86e7f97e-854c-47f9-aa16-f39749545dac';

export const PERMITS_URL = 'https://permits.henselphelps.com';
