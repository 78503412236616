import { GET_AHA_REVIEW } from 'graphql/aha/ahaReview';
import useSoteriaQuery from 'hooks/useSoteriaQuery';

const useAhaTemplate = (ahaReviewId, shouldSkip = false) => {
  const { data: { ahaReview = null } = {}, loading, error } = useSoteriaQuery({
    gql: GET_AHA_REVIEW,
    queryHookOptions: {
      skip: shouldSkip || !ahaReviewId,
      variables: { id: ahaReviewId }
    }
  });

  return { ahaReview, loading, error };
};

export default useAhaTemplate;
