import React from 'react';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import withAuthorization from 'hocs/withAuthorization';
import useIsOnline from 'store/onlineDetection';
import TimeCards from '../../components/safetyHours/TimeCards';

const styles = makeStyles(theme => ({
  notOnlineMessage: {
    backgroundColor: theme.palette.primary.contrastText,
    padding: theme.spacing(2),
    borderRadius: 4,
    color: theme.palette.secondary.contrastText
  }
}));

const SafetyHoursPage = () => {
  const classes = styles();
  const { t } = useTranslation();
  const { isOnline } = useIsOnline();

  return isOnline ? (
    <TimeCards />
  ) : (
    <Typography className={classes.notOnlineMessage}>
      {t('safetyHoursPage.safetyHoursUnavailableOffline')}
    </Typography>
  );
};

export default withAuthorization(SafetyHoursPage);
