import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';

import StyledButtonGroup from 'shared/Buttons/ButtonGroup';

const StyledCancelConfirmation = ({
  onClickGoBack,
  disabledGoBack,
  onClickDiscard,
  disabledDiscard
}) => {
  const { t } = useTranslation();

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography>{t('cancelConfirmation.message')}</Typography>
      </Grid>
      <Grid item>
        <StyledButtonGroup
          onClickAltAction={onClickGoBack}
          labelAltAction={t('cancelConfirmation.actions.goBack')}
          disabledAltAction={disabledGoBack}
          onClickMainAction={onClickDiscard}
          labelMainAction={t('cancelConfirmation.actions.discard')}
          disabledMainAction={disabledDiscard}
        />
      </Grid>
    </Grid>
  );
};

StyledCancelConfirmation.propTypes = {
  onClickGoBack: PropTypes.func.isRequired,
  onClickDiscard: PropTypes.func.isRequired,
  disabledGoBack: PropTypes.bool,
  disabledDiscard: PropTypes.bool
};

export default StyledCancelConfirmation;
