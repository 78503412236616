/* eslint-disable react/display-name */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Typography } from '@mui/material';

import StyledTable from 'shared/Table';
import ActiveOrInactiveStatusContent from 'components/common/ActiveOrInactiveStatusContent';
import withAuthorization from 'hocs/withAuthorization';
import UnauthorizedPageNotice from 'components/common/UnauthorizedPageNotice';
import useRoles from 'hooks/useRoles';
import { GET_ALL_UNLINKED_HP_EMPLOYEES } from '../../../graphql/unlinkedHPEmployees';
import LoadingSpinner from 'components/common/LoadingSpinner';
import ErrorNotice from 'components/common/ErrorNotice';
import PageNotFoundNotice from 'components/common/PageNotFoundNotice';
import withOnlineAccessOnly from 'hocs/withOnlineAccessOnly';
import useSoteriaQuery from 'hooks/useSoteriaQuery';
import StyledTableCellContent from 'components/common/StyledTableCellContent';
import { formatDate } from 'utils/dateTime';

const UnlinkedHpEmployeesPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { isEnterpriseAdmin, isSupportRole } = useRoles();

  const { data, loading, error } = useSoteriaQuery({
    gql: GET_ALL_UNLINKED_HP_EMPLOYEES
  });

  const columns = [
    {
      name: 'id',
      label: 'Personnel Id',
      options: {
        filter: false,
        display: 'excluded'
      }
    },
    {
      name: 'tradePartner.project.id',
      label: 'Project Id',
      options: {
        filter: false,
        display: 'excluded'
      }
    },
    {
      name: 'personnel.fullName',
      label: t('unlinkedHpEmployeesListPage.columns.name'),
      options: {
        filter: false,
        sort: true,
        sortOrder: 'asc',
        searchable: true,
        customBodyRender: value => {
          return <StyledTableCellContent value={value} />;
        }
      }
    },
    {
      name: 'personnel.nickname',
      label: t('unlinkedHpEmployeesListPage.columns.nickName'),
      options: {
        filter: false,
        sort: true,
        searchable: false,
        customBodyRender: value => {
          return <StyledTableCellContent value={value} />;
        }
      }
    },
    {
      name: 'customId',
      label: t('unlinkedHpEmployeesListPage.columns.customId'),
      options: {
        filter: false,
        sort: true,
        searchable: true
      }
    },
    {
      name: 'personnel.contactInformation.emailAddress.email',
      label: t('unlinkedHpEmployeesListPage.columns.email'),
      options: {
        filter: false,
        sort: true,
        searchable: true,
        customBodyRender: value => {
          return <StyledTableCellContent value={value} />;
        }
      }
    },
    {
      name: 'tradePartner.name',
      label: t('unlinkedHpEmployeesListPage.columns.tradePartner'),
      options: {
        filter: false,
        sort: true,
        searchable: true,
        customBodyRender: value => {
          return <StyledTableCellContent value={value} />;
        }
      }
    },

    {
      name: 'isSupervisor',
      label: t('unlinkedHpEmployeesListPage.columns.supervisor'),
      options: {
        filter: true,
        sort: true,
        searchable: true,
        filterOptions: {
          names: [
            t('unlinkedHpEmployeesListPage.columns.supervisor'),
            t('unlinkedHpEmployeesListPage.supervisor.notSupervisor')
          ],
          logic: (isSupervisor, filters) => {
            if (filters.length) {
              const supervisor =
                isSupervisor === true
                  ? t('unlinkedHpEmployeesListPage.columns.supervisor')
                  : t('unlinkedHpEmployeesListPage.supervisor.notSupervisor');
              return !filters.includes(supervisor);
            }
            /* istanbul ignore next */
            return false; // this line can never be tested as filters will always exist
          }
        },
        customBodyRender: isSupervisor => (
          <>
            {isSupervisor && (
              <Typography style={{ fontSize: '0.875rem' }}>
                {t('unlinkedHpEmployeesListPage.supervisor.yes')}
              </Typography>
            )}
          </>
        )
      }
    },
    {
      name: 'isActive',
      label: t('unlinkedHpEmployeesListPage.columns.status'),
      options: {
        sort: true,
        searchable: false,
        filter: true,
        filterOptions: {
          names: [
            t('unlinkedHpEmployeesListPage.status.active'),
            t('unlinkedHpEmployeesListPage.status.inactive')
          ],
          logic: (isActive, filters) => {
            if (filters.length) {
              const status = isActive
                ? t('unlinkedHpEmployeesListPage.status.active')
                : t('unlinkedHpEmployeesListPage.status.inactive');
              return !filters.includes(status);
            }
            /* istanbul ignore next */
            return false; // this line can never be tested as filters will always exist
          }
        },
        customBodyRender: value => (
          <ActiveOrInactiveStatusContent isActive={value} />
        )
      }
    },
    {
      name: 'created',
      label: t('unlinkedHpEmployeesListPage.columns.createdDate'),
      options: {
        filter: false,
        sort: true,
        searchable: true,
        customBodyRender: value => <Typography>{formatDate(value)}</Typography>
      }
    }
  ];

  const options = {
    onRowClick: rowData =>
      history.push(
        `unlinkedHPEmployees/projects/${rowData[1]}/personnel/${rowData[0]}`
      )
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    if (error.message?.includes('not found')) {
      return <PageNotFoundNotice />;
    } else {
      return <ErrorNotice />;
    }
  }

  if (isEnterpriseAdmin || isSupportRole) {
    return (
      <main role="main">
        <StyledTable
          title={t('unlinkedHpEmployeesListPage.title')}
          data={data?.unlinkedHPTradePartnerPersonnels ?? []}
          columns={columns}
          options={options}
        />
      </main>
    );
  } else {
    return <UnauthorizedPageNotice />;
  }
};

export default withOnlineAccessOnly(withAuthorization(UnlinkedHpEmployeesPage));
