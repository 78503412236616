import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import classnames from 'classnames';
import { useMediaQuery } from 'react-responsive';

import 'constants/themes/react-big-calendar/light.scss';
import 'constants/themes/react-big-calendar/dark.scss';
import { DARK_THEME } from 'constants/settings';
import useSettings from 'hooks/useSettings';

const useStyles = makeStyles(theme => ({
  calendarContainer: {
    fontFamily: ['Roboto', 'ui-sans-serif', 'sans-serif'].join(','),
    fontSize: '0.875rem',
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.primary.contrastText,
    padding: theme.spacing(3, 2),
    borderRadius: theme.spacing(1),
    boxShadow: '0 2px 2px 0 rgba(0,0,0,0.14)',
    height: 'calc(100vh - 250px)',
    [theme.breakpoints.down('md')]: { height: 'calc(100vh - 195px)' }
  }
}));

const StyledCalendar = ({ events, onSelectEvent, onSelectSlot }) => {
  const classes = useStyles();
  const { i18n } = useTranslation();
  const { settings } = useSettings();
  const localizer = momentLocalizer(moment);
  const isMobileDevice = useMediaQuery({ maxDeviceWidth: 600 });

  if (i18n.language.includes('en')) {
    moment.locale('en', {
      longDateFormat: { LT: 'ha' }
    });
  }

  let formats = {
    dayFormat: (date, culture, localizer) =>
      localizer.format(date, 'ddd', culture)
  };

  return (
    <div
      className={classnames(
        classes.calendarContainer,
        settings?.theme === DARK_THEME
          ? 'soteria-calendar-dark'
          : 'soteria-calendar-light'
      )}>
      <Calendar
        events={events}
        defaultView={isMobileDevice ? 'week' : 'month'}
        views={['month', 'week', 'day']}
        step={60}
        localizer={localizer}
        selectable={true}
        onSelectSlot={onSelectSlot}
        onSelectEvent={onSelectEvent}
        formats={formats}
      />
    </div>
  );
};

StyledCalendar.propTypes = {
  events: PropTypes.array,
  onSelectSlot: PropTypes.func,
  onSelectEvent: PropTypes.func
};

export default StyledCalendar;
