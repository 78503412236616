import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Avatar, Typography } from '@mui/material';

const useStyles = makeStyles(theme => ({
  container: {
    borderRadius: 4,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    margin: '0 auto'
  },
  avatar: { width: theme.spacing(7), height: theme.spacing(7) },
  title: {
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: { maxWidth: 180 }
  },
  content: { fontSize: '0.875rem' }
}));

const StyledUserCard = ({ src, title, content }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          spacing={1}
          alignItems="center"
          alignContent="flex-start">
          <Grid item>
            <Avatar className={classes.avatar} src={src}></Avatar>
          </Grid>
          <Grid item>
            <Typography className={classes.title}>{title}</Typography>
            <Typography component="div" className={classes.content}>
              {content}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

StyledUserCard.propTypes = {
  src: PropTypes.string,
  title: PropTypes.any.isRequired,
  content: PropTypes.any
};

export default StyledUserCard;
