import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useField, useForm } from 'react-final-form-hooks';
import { useMutation } from '@apollo/react-hooks';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography } from '@mui/material';

import useToast from 'hooks/useToast';
import useFormValidation from 'hooks/useFormValidation';
import StyledDialog from 'shared/Dialog';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import StyledButtonSecondary from 'shared/Buttons/ButtonSecondary';
import StyledNotice from 'shared/Notice';
import StyledInput from 'shared/Input';
import { generateTransactionKey, getMarkdownStringFromDraftJs } from 'utils';
import {
  CREATE_GLOBAL_AHA_EQUIPMENT,
  UPDATE_GLOBAL_AHA_EQUIPMENT,
  GET_PAGINATED_GLOBAL_EQUIPMENTS
} from 'graphql/aha';
import StyledRichTextEditor from 'shared/RichTextEditor';
import AhaScopeIndicator from 'components/ahas/AhaScopeIndicator';
import useAhaGlobalEquipmentListState from 'store/ahaGlobalEquipmentListState';
import MarkdownRenderer from 'shared/MarkdownRenderer';

const useStyles = makeStyles(theme => ({
  fieldContainer: { marginBottom: theme.spacing(2) },
  section: { marginBottom: theme.spacing(2) },
  sectionLabel: { fontSize: '0.875rem' }
}));

const ReadOnlySection = ({ label, value }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} className={classes.section}>
      <Typography color="textSecondary" className={classes.sectionLabel}>
        {label}
      </Typography>
      <Typography component="div">{value}</Typography>
    </Grid>
  );
};

ReadOnlySection.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any
};

const UpsertEquipment = ({
  isOpen,
  setIsOpen,
  isGlobal = false,
  refetchCurrentQueries,
  equipmentToEdit,
  setEquipmentToEdit,
  setAhaTemplate,
  ahaTemplate,
  isReadOnly
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { displayToast } = useToast();
  const { isRequired } = useFormValidation();
  const [transactionKey, setTransactionKey] = useState(
    generateTransactionKey()
  );
  const [ahaGlobalEquipmentListState] = useAhaGlobalEquipmentListState();
  const inspectionRequirementsRef = useRef(null);
  const trainingsRef = useRef(null);
  const isEditing = !!equipmentToEdit;

  const { form, values, pristine } = useForm({
    /* istanbul ignore next */
    onSubmit: () => {},
    initialValues: {
      name: equipmentToEdit?.name ?? '',
      inspectionRequirements: equipmentToEdit?.inspectionRequirements ?? '',
      trainings: equipmentToEdit?.trainings ?? ''
    }
  });
  const nameField = useField('name', form, isRequired);
  const inspectionRequirementsField = useField(
    'inspectionRequirements',
    form,
    isRequired
  );
  const trainingsField = useField('trainings', form, isRequired);

  const [
    createGlobalEquipment,
    { loading: isLoadingCreateGlobalEquipment }
  ] = useMutation(CREATE_GLOBAL_AHA_EQUIPMENT, {
    refetchQueries: [
      {
        query: GET_PAGINATED_GLOBAL_EQUIPMENTS,
        variables: {
          first: ahaGlobalEquipmentListState.first,
          skip: 0,
          search: ahaGlobalEquipmentListState.search
        }
      }
    ]
  });

  const [
    updateGlobalAhaEquipment,
    { loading: isLoadingUpdateGlobalEquipment }
  ] = useMutation(UPDATE_GLOBAL_AHA_EQUIPMENT);

  const isLoading =
    isLoadingCreateGlobalEquipment || isLoadingUpdateGlobalEquipment;

  const canSubmit = () => {
    const { error, invalid } = form.getState();
    return !(invalid || error || isLoading || pristine);
  };

  const handleCreateGlobalEquipment = input => {
    createGlobalEquipment({
      variables: { input }
    })
      .then(
        ({ data: { createGlobalAhaEquipment: createdGlobalEquipment } }) => {
          refetchCurrentQueries();
          handleClose();
          displayToast(
            t('createGlobalEquipmentDialog.toasts.success', {
              name: createdGlobalEquipment.name
            }),
            'success'
          );
        }
      )
      .catch(error => {
        console.error('Create Global Equipment Error: ', error);
        displayToast(t('createGlobalEquipmentDialog.toasts.error'), 'error');
      });
  };

  const handleUpdateGlobalEquipment = input => {
    updateGlobalAhaEquipment({
      variables: { input }
    })
      .then(({ data: { updateGlobalAhaEquipment: globalEquipment } }) => {
        displayToast(
          t('updateGlobalEquipmentDialog.toasts.success', {
            name: globalEquipment.name
          }),
          'success'
        );
        handleClose();
      })
      .catch(error => {
        console.error('Update Global Equipment Error: ', error);
        displayToast(t('updateGlobalEquipmentDialog.toasts.error'), 'error');
      });
  };

  const handleSubmit = () => {
    const input = {
      name: values.name,
      isActive: true,
      inspectionRequirements: values.inspectionRequirements,
      trainings: values.trainings
    };

    if (isGlobal && !ahaTemplate) {
      input.isActive = true;
      input.order = undefined; // Global equipments do not need order
      if (equipmentToEdit) {
        input.id = equipmentToEdit.id;
        handleUpdateGlobalEquipment(input);
      } else {
        input.transactionKey = transactionKey;
        handleCreateGlobalEquipment(input);
      }
    } else {
      const identifier =
        equipmentToEdit?.id ??
        equipmentToEdit?.transactionKey ??
        transactionKey;

      const equipmentToUpsert = {
        ...equipmentToEdit,
        ...input,
        transactionKey: identifier ?? generateTransactionKey()
      };

      let ahaEquipments = [];

      if (isEditing) {
        ahaEquipments = [
          ...ahaTemplate.ahaEquipments.map(equipment => {
            if (
              equipment.id === identifier ||
              equipment.transactionKey === identifier
            ) {
              return equipmentToUpsert;
            } else {
              return equipment;
            }
          })
        ];
      } else {
        const getOrder = arr => {
          if (arr.length > 0) {
            return (
              Math.max.apply(
                Math,
                arr.map(function(item) {
                  return item.order;
                })
              ) + 1
            );
          } else {
            return 0;
          }
        };

        equipmentToUpsert.order = getOrder(ahaTemplate.ahaEquipments);
        ahaEquipments = [...ahaTemplate.ahaEquipments, equipmentToUpsert];
      }

      setAhaTemplate({
        ...ahaTemplate,
        ahaEquipments
      });

      handleClose();
    }
  };

  const handleChange = ref => {
    if (ref.current) {
      ref.current.save();
    }
  };

  const handleSave = (data, onChange) => {
    const markdownString = getMarkdownStringFromDraftJs(data);
    onChange(markdownString);
  };

  const handleClose = () => {
    if (equipmentToEdit) {
      setEquipmentToEdit(null);
    }
    setIsOpen(false);
    setTransactionKey(generateTransactionKey());
    form.resetFieldState('name');
    form.resetFieldState('inspectionRequirements');
    form.resetFieldState('trainings');
    form.reset();
  };

  const getDialogTitle = () => {
    let dialogTitle = '';

    if (isReadOnly) {
      dialogTitle = t('upsertEquipment.viewGlobal.title');
    } else {
      if (equipmentToEdit) {
        if (isGlobal) {
          dialogTitle = t('upsertEquipment.editGlobal.title');
        } else {
          dialogTitle = t('upsertEquipment.edit.title');
        }
      } else {
        if (isGlobal) {
          dialogTitle = t('upsertEquipment.addGlobal.title');
        } else {
          dialogTitle = t('upsertEquipment.add.title');
        }
      }
    }

    return dialogTitle;
  };

  const titleToDisplay = getDialogTitle();

  return (
    <StyledDialog
      maxWidth={isReadOnly ? 'sm' : 'md'}
      isOpen={isOpen}
      handleClose={handleClose}
      isLoading={isLoading}
      title={titleToDisplay}
      content={
        <form>
          <Grid container justifyContent="flex-end" className={'margin-bottom'}>
            <Grid item>
              <AhaScopeIndicator isGlobal={isGlobal} />
            </Grid>
            {isGlobal && !ahaTemplate && !isReadOnly && (
              <Grid item xs={12}>
                <StyledNotice message={t('upsertEquipment.notice.message')} />
              </Grid>
            )}
            {!isReadOnly && (
              <>
                <Grid item xs={12} className={classes.fieldContainer}>
                  <StyledInput
                    label={t('upsertEquipment.field.name')}
                    input={nameField.input}
                    meta={nameField.meta}
                    autoFocus={true}
                    required
                  />
                </Grid>
                <Grid item xs={12} className={classes.fieldContainer}>
                  <StyledRichTextEditor
                    label={t('upsertEquipment.field.trainings')}
                    initialValue={trainingsField.meta?.initial}
                    value={trainingsField.input.value}
                    onSave={data =>
                      handleSave(data, trainingsField.input.onChange)
                    }
                    autoSaveRef={trainingsRef}
                    onChange={() => handleChange(trainingsRef)}
                    required
                    disabled={isLoading}
                  />
                </Grid>
                <Grid item xs={12} className={classes.fieldContainer}>
                  <StyledRichTextEditor
                    label={t('upsertEquipment.field.inspectionRequirements')}
                    initialValue={inspectionRequirementsField?.meta?.initial}
                    value={inspectionRequirementsField.input.value}
                    onSave={data =>
                      handleSave(
                        data,
                        inspectionRequirementsField.input.onChange
                      )
                    }
                    autoSaveRef={inspectionRequirementsRef}
                    onChange={() => handleChange(inspectionRequirementsRef)}
                    required
                    disabled={isLoading}
                  />
                </Grid>
              </>
            )}
            {isReadOnly && (
              <>
                <ReadOnlySection label={'Name'} value={equipmentToEdit?.name} />
                <ReadOnlySection
                  label={'Trainings'}
                  value={
                    <MarkdownRenderer
                      markdownString={equipmentToEdit?.trainings}
                    />
                  }
                />
                <ReadOnlySection
                  label={'Inspection Requirements'}
                  value={
                    <MarkdownRenderer
                      markdownString={equipmentToEdit?.inspectionRequirements}
                    />
                  }
                />
              </>
            )}
          </Grid>
        </form>
      }
      actions={
        <Grid container justifyContent="space-between">
          <Grid item>
            <StyledButtonSecondary
              label={t('upsertEquipment.actions.cancelButton')}
              disabled={isLoading}
              onClick={handleClose}
            />
          </Grid>
          <Grid item>
            {!isReadOnly && (
              <StyledButtonPrimary
                label={t('upsertEquipment.actions.submitButton')}
                disabled={!canSubmit()}
                onClick={handleSubmit}
              />
            )}
          </Grid>
        </Grid>
      }
    />
  );
};

UpsertEquipment.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  isGlobal: PropTypes.bool,
  refetchCurrentQueries: PropTypes.func,
  equipmentToEdit: PropTypes.any,
  setEquipmentToEdit: PropTypes.func,
  setAhaTemplate: PropTypes.func,
  ahaTemplate: PropTypes.object,
  isReadOnly: PropTypes.bool
};

export default UpsertEquipment;
