import React, { Fragment, useState } from 'react';
import { useForm } from 'react-final-form-hooks';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import {
  Grid,
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepContent
} from '@mui/material';

import useSoteriaStore from 'hooks/useSoteriaStore';
import useStringIncludesHenselPhelps from 'hooks/useStringIncludesHenselPhelps';
import StyledFab from 'shared/Buttons/Fab';
import StyledDialog from 'shared/Dialog';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import StyledButtonSecondary from 'shared/Buttons/ButtonSecondary';
import CancelConfirmation from 'shared/CancelConfirmation';
import {
  SelectTradePartnerStep,
  CreatePersonStep,
  VerifyMatchesStep,
  ConfirmPersonStep,
  PersonalInfoFormStep
} from 'components/personnel/CreatePersonnelSteps';
import MatchDetails from 'components/personnel/PersonnelMatchDetails';
import useToast from 'hooks/useToast';
import { generateTransactionKey, getPhoneValues } from 'utils';
import { CREATE_ATTENDEE } from 'graphql/attendee';
import { GET_ORIENTATION } from 'graphql/orientation';

const AddProjectOrientationAttendeeDialog = () => {
  const { t } = useTranslation();
  const {
    store: { whatsNewIsOpen = false }
  } = useSoteriaStore();
  const { orientationId } = useParams();
  const [isOpen, toggleDialog] = useState(false);
  const [activeStepIndex, setActiveStep] = useState(0);
  const [personnelDetails, setPersonnelDetails] = useState(null);
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [
    isCancelConfirmationRendered,
    setIsCancelConfirmationRendered
  ] = useState(false);
  const [updatedProfileImage, setUpdatedProfileImage] = useState(null);
  const { stringIncludesHenselPhelps } = useStringIncludesHenselPhelps();
  const { displayToast } = useToast();
  const [attendeeTxnKey, setAttendeeTxnKey] = useState(
    generateTransactionKey()
  );
  const [selectedMatch, setSelectedMatch] = useState(null);

  const [createAttendee, { loading: isLoading }] = useMutation(
    CREATE_ATTENDEE,
    {
      refetchQueries: [
        { query: GET_ORIENTATION, variables: { orientationId } }
      ],
      awaitRefetchQueries: true
    }
  );

  const { form, values } = useForm({
    /* istanbul ignore next */
    onSubmit: () => {}, // this function required for useForm but is not used
    initialValues: {
      dob: null,
      noMatchFound: false,
      selectedPersonnelId: null,
      isSupervisor: false,
      isPermitApprover: false,
      personnelConfirmation: false
    }
  });

  const henselPhelpsSteps = [
    t('addAttendeeDialog.steps.henselPhelps.selectTradePartner'),
    t('addAttendeeDialog.steps.henselPhelps.addAttendeeToOrientation'),
    t('addAttendeeDialog.steps.nonHenselPhelps.verify'),
    t('addAttendeeDialog.steps.nonHenselPhelps.addAttendeeToOrientation'),
    t('addAttendeeDialog.steps.nonHenselPhelps.confirm')
  ];

  const nonHenselPhelpsSteps = [
    t('addAttendeeDialog.steps.nonHenselPhelps.selectTradePartner'),
    t('addAttendeeDialog.steps.nonHenselPhelps.addAttendeeToOrientation'),
    t('addAttendeeDialog.steps.nonHenselPhelps.verify'),
    t('addAttendeeDialog.steps.nonHenselPhelps.confirm')
  ];

  const isHenselPhelpsEmployee = stringIncludesHenselPhelps(
    values?.selectedTradePartner?.name
  );

  const getSteps = () => {
    if (isHenselPhelpsEmployee) {
      return henselPhelpsSteps;
    }

    return nonHenselPhelpsSteps;
  };

  const isComplete = activeStepIndex === getSteps().length - 1;

  const { errors } = form.getState();
  const isHpOptionalInfoStep = isHenselPhelpsEmployee && activeStepIndex === 3;
  const hasErrors =
    !isHpOptionalInfoStep && Object.values(errors).some(value => !!value);

  const handleShouldClose = () => {
    if (activeStepIndex < 1 && !values.selectedTradePartner) {
      handleClose();
    } else {
      setIsCancelConfirmationRendered(true);
    }
  };

  const handleNext = () => {
    setActiveStep(prevActiveStepIndex => prevActiveStepIndex + 1);
  };

  const handleBack = () => {
    if (personnelDetails) {
      setIsDetailsOpen(false);
      setPersonnelDetails(null);
    } else {
      setActiveStep(prevActiveStepIndex => prevActiveStepIndex - 1);
    }
  };

  const handleDetailsClick = personnel => {
    setIsDetailsOpen(true);
    setPersonnelDetails(personnel);
  };

  const handleSubmit = () => {
    // Important to return undefined instead of null as it is not being omitted by Apollo as it should.
    let attendeeCreateInput;

    if (selectedMatch) {
      attendeeCreateInput = {
        transactionKey: attendeeTxnKey,
        status: 'Enrolled',
        upn: selectedMatch.upn ?? values.upn,
        firstName: selectedMatch.firstName,
        middleName: selectedMatch.middleName ?? undefined,
        lastName: selectedMatch.lastName ?? undefined,
        nickname: selectedMatch.nickname ?? undefined,
        dob: selectedMatch.dob ?? undefined, // A date string, such as 2007-12-03 (year-month-day).
        contactInformation:
          selectedMatch.contactInformation || values.email || values.phoneNumber
            ? {
                emailAddress:
                  selectedMatch.contactInformation.emailAddress.email ||
                  values.email
                    ? {
                        email:
                          selectedMatch.contactInformation.emailAddress.email ??
                          values.email,
                        type: 'Primary'
                      }
                    : undefined,
                primaryPhone: getPhoneValues(
                  selectedMatch.contactInformation.primaryPhone.number ??
                    values.phoneNumber,
                  null,
                  null,
                  'Primary'
                )
              }
            : undefined,
        trainingsToAdd: [],
        emergencyContacts: [],
        customId: values.customId ?? undefined,
        isSupervisor: values.isSupervisor,
        isPermitApprover: values.isPermitApprover,
        tradePartnerId: values.selectedTradePartner.id,
        personnelId:
          selectedMatch.id ??
          (values.selectedPersonnelId !== 'no matches'
            ? values.selectedPersonnelId
            : null) ??
          undefined,
        orientationId: orientationId,
        shouldNotifyAttendee: true
      };
    } else {
      attendeeCreateInput = {
        transactionKey: attendeeTxnKey,
        status: 'Enrolled',
        upn: values.selectedHenselPhelpsEmployee?.upn ?? values.upn,
        firstName:
          values.selectedHenselPhelpsEmployee?.firstName ?? values.firstName,
        middleName: values.middleName ?? undefined,
        lastName:
          values.selectedHenselPhelpsEmployee?.lastName ?? values.lastName,
        nickname: values.nickname ?? undefined,
        dob: values.dob?.toFormat('yyyy-MM-dd') ?? undefined, // A date string, such as 2007-12-03 (year-month-day).
        profileImage: updatedProfileImage,
        contactInformation:
          values.selectedHenselPhelpsEmployee?.email ||
          values.email ||
          values.phoneNumber
            ? {
                emailAddress:
                  values.selectedHenselPhelpsEmployee?.email || values.email
                    ? {
                        email:
                          values.selectedHenselPhelpsEmployee?.email ??
                          values.email,
                        type: 'Primary'
                      }
                    : undefined,
                primaryPhone: getPhoneValues(
                  values.phoneNumber,
                  null,
                  null,
                  'Primary'
                )
              }
            : undefined,
        notes: values.notes ?? undefined,
        trainingsToAdd: [],
        emergencyContacts: [],
        customId: values.customId ?? undefined,
        isSupervisor: values.isSupervisor,
        isPermitApprover: values.isPermitApprover,
        tradePartnerId: values.selectedTradePartner.id,
        personnelId:
          (values.selectedPersonnelId !== 'no matches'
            ? values.selectedPersonnelId.id
            : null) ?? undefined,
        orientationId: orientationId,
        shouldNotifyAttendee: true
      };
    }

    createAttendee({
      variables: { input: attendeeCreateInput }
    }).then(
      ({ data: { createAttendee: attendee } }) => {
        displayToast(
          t('addAttendeeDialog.toasts.success', {
            attendeeName: attendee.fullName
          }),
          'success'
        );
        handleClose();
      },
      error => {
        console.error('Add Attendee Error: ', error);
        displayToast(t('addAttendeeDialog.toasts.error'), 'error');
      }
    );
  };

  const handleClose = () => {
    toggleDialog(false);
    setAttendeeTxnKey(generateTransactionKey());
    setUpdatedProfileImage(null);
    setActiveStep(0);
    setPersonnelDetails(null);
    setIsDetailsOpen(false);
    setIsCancelConfirmationRendered(false);
    form.reset();
  };

  return (
    <Fragment>
      <StyledFab
        data-testid="add-attendee-dialog-fab-button"
        onClick={() => toggleDialog(true)}
        shouldShift={whatsNewIsOpen}
        icon={<AddIcon />}
      />
      <StyledDialog
        data-testid="add-attendee-dialog"
        fullWidth={true}
        maxWidth="md"
        disableBackdropClick
        disableEscapeKeyDown
        isOpen={isOpen}
        shouldShowCloseIconButton={!isCancelConfirmationRendered}
        handleClose={handleShouldClose}
        title={
          !isCancelConfirmationRendered
            ? t('addAttendeeDialog.title')
            : t('addAttendeeDialog.cancelConfirmationTitle')
        }
        isLoading={isLoading}
        content={
          <form>
            {isCancelConfirmationRendered && (
              <CancelConfirmation
                onClickGoBack={() => setIsCancelConfirmationRendered(false)}
                disabledGoBack={isLoading}
                onClickDiscard={() => {
                  handleClose();
                }}
                disabledMainAction={isLoading}
              />
            )}
            {!isCancelConfirmationRendered && (
              <Grid container justifyContent="center">
                {personnelDetails && (
                  <MatchDetails
                    personnelDetails={personnelDetails}
                    handleBack={handleBack}
                    isLoading={isLoading}
                  />
                )}
                {!personnelDetails && (
                  <Grid item xs={12}>
                    <Stepper
                      orientation="vertical"
                      activeStep={activeStepIndex}>
                      {getSteps().map(label => (
                        <Step key={label}>
                          <StepLabel>{label}</StepLabel>
                          <StepContent>
                            <Typography component="div">
                              {activeStepIndex === 0 && (
                                <SelectTradePartnerStep form={form} />
                              )}
                              {activeStepIndex === 1 && (
                                <CreatePersonStep
                                  isHenselPhelpsEmployee={
                                    isHenselPhelpsEmployee
                                  }
                                  form={form}
                                  isLoading={isLoading}
                                  updatedProfileImage={updatedProfileImage}
                                  setUpdatedProfileImage={
                                    setUpdatedProfileImage
                                  }
                                />
                              )}
                              {isHenselPhelpsEmployee && (
                                <Fragment>
                                  {activeStepIndex === 2 && (
                                    <VerifyMatchesStep
                                      form={form}
                                      handleDetailsClick={handleDetailsClick}
                                      updatedProfileImage={updatedProfileImage}
                                      setSelectedMatch={setSelectedMatch}
                                    />
                                  )}
                                  {activeStepIndex === 3 && (
                                    <PersonalInfoFormStep
                                      form={form}
                                      isLoading={isLoading}
                                      updatedProfileImage={updatedProfileImage}
                                      setUpdatedProfileImage={
                                        setUpdatedProfileImage
                                      }
                                      isHenselPhelpsEmployee={
                                        isHenselPhelpsEmployee
                                      }
                                    />
                                  )}
                                  {activeStepIndex === 4 && (
                                    <ConfirmPersonStep
                                      formValues={values}
                                      form={form}
                                      isHenselPhelpsEmployee={
                                        isHenselPhelpsEmployee
                                      }
                                      updatedProfileImage={updatedProfileImage}
                                      selectedMatch={selectedMatch}
                                    />
                                  )}
                                </Fragment>
                              )}
                              {!isHenselPhelpsEmployee && (
                                <Fragment>
                                  {activeStepIndex === 2 && (
                                    <VerifyMatchesStep
                                      form={form}
                                      handleDetailsClick={handleDetailsClick}
                                      updatedProfileImage={updatedProfileImage}
                                      setSelectedMatch={setSelectedMatch}
                                    />
                                  )}

                                  {activeStepIndex === 3 && (
                                    <ConfirmPersonStep
                                      formValues={values}
                                      form={form}
                                      isHenselPhelpsEmployee={
                                        isHenselPhelpsEmployee
                                      }
                                      updatedProfileImage={updatedProfileImage}
                                      setSelectedMatch={setSelectedMatch}
                                    />
                                  )}
                                </Fragment>
                              )}
                            </Typography>
                          </StepContent>
                        </Step>
                      ))}
                    </Stepper>
                  </Grid>
                )}
              </Grid>
            )}
          </form>
        }
        actions={
          !isCancelConfirmationRendered && (
            <Fragment>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <StyledButtonSecondary
                    label={t('addAttendeeDialog.actions.cancelButton')}
                    disabled={isLoading}
                    onClick={handleShouldClose}
                  />
                </Grid>
                <Grid item>
                  <Grid
                    container
                    justifyContent="flex-end"
                    direction="row"
                    spacing={2}>
                    <Grid item>
                      {activeStepIndex !== 0 && (
                        <StyledButtonPrimary
                          disabled={isDetailsOpen || isLoading}
                          label={t('addAttendeeDialog.actions.backButton')}
                          onClick={handleBack}
                        />
                      )}
                    </Grid>
                    <Grid item>
                      {!isComplete && (
                        <StyledButtonPrimary
                          label={
                            !isHenselPhelpsEmployee &&
                            activeStepIndex === 2 &&
                            values.selectedPersonnelId !== null &&
                            values.selectedPersonnelId !== 'no matches'
                              ? t('addAttendeeDialog.actions.selectMatchButton')
                              : t('addAttendeeDialog.actions.nextButton')
                          }
                          disabled={hasErrors || isDetailsOpen || isLoading}
                          onClick={handleNext}
                        />
                      )}
                      {isComplete && (
                        <StyledButtonPrimary
                          label={t('addAttendeeDialog.actions.submitButton')}
                          disabled={hasErrors || isLoading}
                          onClick={handleSubmit}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Fragment>
          )
        }
      />
    </Fragment>
  );
};

export default AddProjectOrientationAttendeeDialog;
