import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useForm, useField } from 'react-final-form-hooks';
import { useMutation } from '@apollo/react-hooks';
import AddIcon from '@mui/icons-material/Add';
import makeStyles from '@mui/styles/makeStyles';
import { Grid } from '@mui/material';

import useToast from 'hooks/useToast';
import useFormValidation from 'hooks/useFormValidation';
import StyledButtonGroup from 'shared/Buttons/ButtonGroup';
import StyledDialog from 'shared/Dialog';
import StyledInput from 'shared/Input';
import StyledSelect from 'shared/Select';
import StyledButtonMuted from 'shared/Buttons/ButtonMuted';
import { FLAG_STATUS_OPTIONS } from 'constants/personnelFlags';
import { generateTransactionKey } from 'utils';
import { UPDATE_PERSONNEL } from 'graphql/personnel';

const useStyles = makeStyles(theme => ({
  container: { marginTop: theme.spacing(1) }
}));

const AddPersonnelFlag = ({ tradePartnerPersonnel }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isOpen, toggleDialog] = useState(false);
  const { isRequired } = useFormValidation();
  const { displayToast } = useToast();
  const [updatePersonnel, { loading: isLoading }] = useMutation(
    UPDATE_PERSONNEL
  );
  const personnel = tradePartnerPersonnel?.personnel;
  const [transactionKey, setTransactionKey] = useState(
    generateTransactionKey()
  );
  const { form, values, pristine } = useForm({
    /* istanbul ignore next */
    onSubmit: () => {}, // this function required for useForm but is not used
    initialValues: {
      transactionKey: transactionKey
    }
  });

  const status = useField('status', form, isRequired);
  const reason = useField('reason', form, isRequired);
  const resolution = useField('resolution', form, isRequired);

  const canSubmit = (() => {
    const { errors } = form.getState();
    return !(errors.reason || errors.status || isLoading || pristine);
  })();

  const canSubmitForResolvedFlag = (() => {
    const { errors } = form.getState();
    return !(
      errors.reason ||
      errors.status ||
      errors.resolution ||
      isLoading ||
      pristine
    );
  })();

  const handleClose = () => {
    toggleDialog(false);
    form.resetFieldState('reason');
    form.resetFieldState('status');
    form.resetFieldState('resolution');
    setTransactionKey(generateTransactionKey());
    form.reset();
  };

  const handleSubmit = () => {
    updatePersonnel({
      variables: {
        input: {
          id: personnel.id,
          flagsToAdd: [
            {
              reason: values.reason,
              resolution: values.resolution,
              status: values.status,
              transactionKey: transactionKey
            }
          ],
          flagsToDelete: [],
          flagsToUpdate: [],
          trainingsToAdd: [],
          trainingsToDelete: [],
          trainingsToUpdate: []
        }
      }
    })
      .then(({ data: { updatePersonnel: personnel } }) => {
        handleClose();
        displayToast(t('addPersonnelFlag.toasts.status.success'), 'success');
      })
      .catch(error => {
        console.error('Add Flag Error: ', error);
        displayToast(t('addPersonnelFlag.toasts.status.error'), 'error');
      });
  };

  return (
    <Fragment>
      <Grid container justifyContent="flex-end" className={classes.container}>
        <Grid item>
          <StyledButtonMuted
            variant="outlined"
            startIcon={<AddIcon color="action" />}
            label={t('addPersonnelFlag.addFlagButton.title')}
            onClick={() => toggleDialog(true)}
          />
        </Grid>
      </Grid>
      <StyledDialog
        data-testid="add-personnel-flag-dialog"
        isOpen={isOpen}
        handleClose={handleClose}
        title={t('addPersonnelFlag.title')}
        isLoading={isLoading}
        content={
          <form>
            <Grid container direction="column" spacing={1}>
              <Grid item xs={12}>
                <StyledSelect
                  input={status.input}
                  meta={status.meta}
                  disabled={isLoading}
                  options={FLAG_STATUS_OPTIONS}
                  placeholder={t('addPersonnelFlag.fields.status.placeholder')}
                  label={t('addPersonnelFlag.fields.status.title')}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <StyledInput
                  multiline={true}
                  input={reason.input}
                  meta={reason.meta}
                  disabled={isLoading}
                  label={t('addPersonnelFlag.fields.reason.title')}
                  required
                />
              </Grid>
              {status.input.value === 'Resolved' && (
                <Grid item xs={12}>
                  <StyledInput
                    multiline={true}
                    input={resolution.input}
                    meta={resolution.meta}
                    disabled={isLoading}
                    label={t('addPersonnelFlag.fields.resolution.title')}
                    required
                  />
                </Grid>
              )}
            </Grid>
          </form>
        }
        actions={
          <StyledButtonGroup
            onClickAltAction={handleClose}
            labelAltAction={t('addPersonnelFlag.actions.cancel')}
            disabledAltAction={isLoading}
            onClickMainAction={handleSubmit}
            labelMainAction={t('addPersonnelFlag.actions.submit')}
            disabledMainAction={
              status.input.value === 'Resolved'
                ? !canSubmitForResolvedFlag
                : !canSubmit
            }
          />
        }
      />
    </Fragment>
  );
};

AddPersonnelFlag.propTypes = {
  tradePartnerPersonnel: PropTypes.object
};

export default AddPersonnelFlag;
