import React from 'react';
import PropTypes from 'prop-types';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Card, Typography, IconButton } from '@mui/material';

import MarkdownRenderer from 'shared/MarkdownRenderer';

const useStyles = makeStyles(theme => ({
  card: { borderRadius: 0, boxShadow: 'none' },
  section: {
    border: '1px solid' + theme.borderGray,
    padding: theme.spacing(0, 1),
    [theme.breakpoints.down('xl')]: { padding: theme.spacing(0.5) }
  },
  name: {
    fontWeight: 'bold',
    display: 'inline',
    verticalAlign: '25%',
    [theme.breakpoints.down('xl')]: { fontSize: '0.875rem' }
  }
}));

const AhaDetailedEquipmentCard = ({
  equipment,
  setIsEditEquipmentDialogOpen,
  setIsConfirmEquipmentDeleteDialogOpen,
  setSelectedEquipment,
  isReadOnlyReview
}) => {
  const classes = useStyles();

  const onEditEquipmentClick = equipment => {
    setSelectedEquipment(equipment);
    setIsEditEquipmentDialogOpen(true);
  };

  const onDeleteEquipmentClick = equipment => {
    setSelectedEquipment(equipment);
    setIsConfirmEquipmentDeleteDialogOpen(true);
  };

  return (
    <Card key={equipment.name} className={classes.card}>
      <Grid container className={classes.container}>
        <Grid item xs={12} sm={4} md={2} lg={2} className={classes.section}>
          {!isReadOnlyReview && <DragIndicatorIcon />}
          <Typography className={classes.name}>{equipment.name}</Typography>
        </Grid>
        <Grid item xs={12} sm={8} md={2} lg={2} className={classes.section}>
          <MarkdownRenderer markdownString={equipment.trainings} />
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={8} className={classes.section}>
          <Grid container direction="row">
            <Grid item xs={12} sm={10} md={10} lg={10}>
              <MarkdownRenderer
                markdownString={equipment.inspectionRequirements}
              />
            </Grid>
            <Grid item xs={12} sm={2} md={2} lg={2}>
              {!isReadOnlyReview && (
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <IconButton
                      size="small"
                      onClick={() => onEditEquipmentClick(equipment)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => onDeleteEquipmentClick(equipment)}>
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

AhaDetailedEquipmentCard.propTypes = {
  equipment: PropTypes.object,
  setIsEditEquipmentDialogOpen: PropTypes.func,
  setIsConfirmEquipmentDeleteDialogOpen: PropTypes.func,
  setSelectedEquipment: PropTypes.func,
  isReadOnlyReview: PropTypes.bool
};

export default AhaDetailedEquipmentCard;
