import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import StyledAutoComplete from 'shared/AutoComplete';
import { getDeterministicId } from 'utils';
import {
  PARENT_TRADE_PARTNER_SEARCH,
  SUB_TRADE_PARTNER_SEARCH
} from 'graphql/tradePartner';

const TradePartnerSelect = ({
  selectedCompany,
  selectedTradePartner,
  handleChange,
  isDisabled,
  type = 'parent',
  shouldShowActiveOnly = false,
  shouldFilterOutHenselPhelps = false,
  ...rest
}) => {
  const { t } = useTranslation();
  const [inputValue, handleInputChange] = useState(null);
  const { projectId, tradePartnerId } = useParams();

  const isParentRelation = type === 'parent';
  const isNoRelation = type === 'none';

  const query =
    isParentRelation || isNoRelation
      ? PARENT_TRADE_PARTNER_SEARCH
      : SUB_TRADE_PARTNER_SEARCH;

  const { data, loading } = useQuery(query, {
    variables: {
      search: inputValue,
      projectId: projectId,
      subcontractorId: isNoRelation
        ? null
        : isParentRelation
        ? tradePartnerId
        : null,
      parentContractorId: isNoRelation
        ? null
        : isParentRelation
        ? null
        : tradePartnerId
    },
    fetchPolicy: 'no-cache'
  });

  const getOptions = () => {
    let options;

    if (isParentRelation || isNoRelation) {
      options =
        data && data.availableParentContractors
          ? data.availableParentContractors
          : [];
    } else {
      options =
        data && data.availableSubcontractors
          ? data.availableSubcontractors
          : [];
    }

    if (shouldShowActiveOnly) {
      options = options.filter(option => option.isActive);
    }

    if (shouldFilterOutHenselPhelps) {
      options = options.filter(
        option => option.name.toLowerCase() !== 'hensel phelps'
      );
    }

    if (
      (selectedCompany && isParentRelation) ||
      (selectedCompany && isNoRelation)
    ) {
      const idToRemove = getDeterministicId(projectId + selectedCompany.id);
      return options.filter(option => option.id !== idToRemove);
    } else {
      return options;
    }
  };

  const options = getOptions();

  return (
    <StyledAutoComplete
      {...rest}
      selectedValue={selectedTradePartner}
      handleChange={handleChange}
      placeholder={t('tradePartnerSelectPlaceholder')}
      isRequired={true}
      isDisabled={isDisabled}
      options={options.map(item => ({
        label: item.name,
        value: item.id,
        ...item
      }))}
      isLoading={loading}
      handleInputChange={handleInputChange}
      shouldShowActiveOnly={shouldShowActiveOnly}
    />
  );
};

TradePartnerSelect.propTypes = {
  selectedCompany: PropTypes.any,
  selectedTradePartner: PropTypes.any,
  handleChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  type: PropTypes.string,
  shouldShowActiveOnly: PropTypes.bool,
  shouldFilterOutHenselPhelps: PropTypes.bool
};

export default TradePartnerSelect;
