import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CheckIcon from '@mui/icons-material/Check';
import makeStyles from '@mui/styles/makeStyles';
import { StyledMonthDatePicker } from 'shared/MonthDatePicker';
import {
  Button,
  Grid,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Typography
} from '@mui/material';

import useObservationListState from 'store/observationListState';
import { ALLOWED_FILTER_KEYS } from 'constants/observationFilters';

const useStyles = makeStyles(theme => ({
  icon: { color: 'white' },
  listItem: {
    width: 300,
    color: theme.palette.background.contrastText,
    [theme.breakpoints.down('lg')]: {
      width: 240
    }
  },
  nested: {
    paddingLeft: theme.spacing(4),
    color: theme.palette.background.contrastText
  },
  listItemText: {
    maxWidth: 240,
    whiteSpace: 'noWrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  filterMenuButton: {
    fontWeight: 'bold',
    textTransform: 'none',
    fontSize: '1rem',
    marginRight: 40,
    color: theme.palette.secondary.contrastText,
    [theme.breakpoints.down('lg')]: {
      marginRight: 10
    }
  },
  closeText: { flex: 'none', fontWeight: 'bold' },
  closeButton: {
    color: theme.palette.background.contrastText,
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 25
  },
  boldText: { fontWeight: 'bold' },
  removeFilters: { fontSize: '0.875rem' }
}));

const ObservationsDashboardFilterMenu = ({ handleChange }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isFilterMenuOpen, toggleIsFilterMenuOpen] = useState(false);
  const [observationListState] = useObservationListState();
  const selectedMonthFromObservationListState =
    observationListState.filter?.observedAfter &&
    observationListState.filter?.observedBefore
      ? new Date(observationListState.filter?.observedAfter)
      : null;
  const [selectedDate, setSelectedDate] = useState(
    selectedMonthFromObservationListState
  );
  const [activeFilters, setActiveFilters] = useState({
    lastWeek: observationListState.filter?.observedAfter === 'lastWeek',
    lastMonth: observationListState.filter?.observedAfter === 'lastMonth',
    open: observationListState.filter?.status === 'Open',
    closed: observationListState.filter?.status === 'Closed',
    environmental: observationListState.filter?.type === 'Environmental',
    behavioral: observationListState.filter?.type === 'Behavioral'
  });

  const filterCount =
    observationListState.filter?.observedAfter &&
    observationListState.filter?.observedBefore
      ? Object.entries(observationListState.filter).filter(
          ([key, value]) => !!value && ALLOWED_FILTER_KEYS.includes(key)
        ).length - 1
      : Object.entries(observationListState.filter).filter(
          ([key, value]) => !!value && ALLOWED_FILTER_KEYS.includes(key)
        ).length;

  const toggleDrawer = isFilterMenuOpen => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    toggleIsFilterMenuOpen(isFilterMenuOpen);
  };

  // HANDLE MOBILE VIEW FOR ORIENTATIONS
  const handleDateChange = dateTime => {
    const startOfMonthDate = dateTime?.startOf('month')?.toJSDate();
    const endOfMonthDate = dateTime?.endOf('month')?.toJSDate();

    setSelectedDate(dateTime);
    handleChange({
      ...observationListState.filter,
      observedAfter: startOfMonthDate,
      observedBefore: endOfMonthDate
    });
    setActiveFilters({
      ...activeFilters,
      lastWeek: false,
      lastMonth: false
    });
  };

  const removeFilters = () => {
    if (observationListState.hasActiveFilters) {
      handleChange({});
      setActiveFilters({});
      setSelectedDate(null);
    }
    return;
  };

  const handleFilterChange = activeFilterKey => {
    switch (activeFilterKey) {
      case t('filterMenu.filters.lastWeek'): {
        const isActive = !activeFilters.lastWeek;
        handleChange({
          ...observationListState.filter,
          observedAfter: isActive ? 'lastWeek' : undefined,
          observedBefore: undefined
        });
        setActiveFilters({
          ...activeFilters,
          lastWeek: !activeFilters.lastWeek,
          lastMonth: false
        });
        return;
      }

      case t('filterMenu.filters.lastMonth'): {
        const isActive = !activeFilters.lastMonth;
        handleChange({
          ...observationListState.filter,
          observedAfter: isActive ? 'lastMonth' : undefined,
          observedBefore: undefined
        });
        setActiveFilters({
          ...activeFilters,
          lastMonth: !activeFilters.lastMonth,
          lastWeek: false
        });
        return;
      }
      case t('filterMenu.filters.open'): {
        const isActive = !activeFilters.open;
        handleChange({
          ...observationListState.filter,
          status: isActive ? 'Open' : undefined
        });
        setActiveFilters({
          ...activeFilters,
          open: !activeFilters.open,
          closed: false,
          draft: false
        });
        return;
      }

      case t('filterMenu.filters.closed'): {
        const isActive = !activeFilters.closed;
        handleChange({
          ...observationListState.filter,
          status: isActive ? 'Closed' : undefined
        });
        setActiveFilters({
          ...activeFilters,
          open: false,
          closed: !activeFilters.closed,
          draft: false
        });
        return;
      }

      case t('filterMenu.filters.draft'): {
        const isActive = !activeFilters.draft;
        handleChange({
          ...observationListState.filter,
          status: isActive ? 'Draft' : undefined
        });
        setActiveFilters({
          ...activeFilters,
          open: false,
          closed: false,
          draft: !activeFilters.draft
        });
        return;
      }
      case t('filterMenu.filters.environmental'): {
        const isActive = !activeFilters.environmental;
        handleChange({
          ...observationListState.filter,
          type: isActive ? 'Environmental' : undefined
        });
        setActiveFilters({
          ...activeFilters,
          environmental: !activeFilters.environmental,
          behavioral: false
        });
        return;
      }
      case t('filterMenu.filters.behavioral'): {
        const isActive = !activeFilters.behavioral;
        handleChange({
          ...observationListState.filter,
          type: isActive ? 'Behavioral' : undefined
        });
        setActiveFilters({
          ...activeFilters,
          environmental: false,
          behavioral: !activeFilters.behavioral
        });
        return;
      }
      default:
        return null;
    }
  };

  // spanish filters do not match english object keys
  const formatOption = option => {
    switch (option) {
      case 'Last 7 Days':
        return 'lastWeek';
      case 'Last 30 Days':
        return 'lastMonth';
      case 'Los últimos 7 días':
        return 'lastWeek';
      case 'Los últimos 30 días':
        return 'lastMonth';
      case 'Abierto':
        return 'Open';
      case 'Cerrado':
        return 'Closed';
      case 'Ambiental':
        return 'environmental';
      case 'Conductual':
        return 'behavioral';
      default:
        return option.toLowerCase();
    }
  };

  const filterGroup = (header, ...options) => (
    <>
      <ListItem className={classes.listItem}>
        <ListItemText>
          <Typography className={classes.listItem}>
            {header.toUpperCase()}
          </Typography>
        </ListItemText>
      </ListItem>
      {options.map((option, index) => {
        const formattedOption = formatOption(option);
        return (
          <ListItem
            key={`${header}-filter-${option}`}
            button
            className={classes.nested}
            onClick={() => handleFilterChange(option)}>
            <ListItemText className={classes.listItem}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography
                    className={
                      activeFilters[formattedOption]
                        ? `${classes.listItemText} ${classes.boldText}`
                        : classes.listItemText
                    }>
                    {option}
                  </Typography>
                </Grid>
                {activeFilters[formattedOption] && (
                  <Grid item>
                    <CheckIcon data-testid={`check-icon-${index}`} />
                  </Grid>
                )}
              </Grid>
            </ListItemText>
          </ListItem>
        );
      })}
    </>
  );

  return (
    <div onKeyDown={toggleDrawer(false)}>
      <Button
        className={classes.filterMenuButton}
        onClick={toggleDrawer(true)}
        aria-label={t('filterMenu.filterAlt')}>
        <Typography variant="overline" className="bold">
          {filterCount > 0
            ? t('filterMenu.filtersCount', { filterCount })
            : t('filterMenu.filter')}
        </Typography>
      </Button>
      <Drawer
        data-testid="filter-menu"
        open={isFilterMenuOpen}
        onClose={toggleDrawer(false)}
        anchor="right">
        <List>
          <ListItem
            button
            className={classes.closeButton}
            onClick={toggleDrawer(false)}>
            <ListItemText className={classes.closeText}>
              <Typography className={classes.listItemText}>
                {t('filterMenu.closeButton')}
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem
            button
            disabled={!observationListState.hasActiveFilters}
            className={classes.closeButton}
            onClick={() => removeFilters()}>
            <ListItemText className={classes.listItem}>
              <Typography
                className={`${classes.listItemText} ${classes.removeFilters}`}>
                {t('filterMenu.removeFilters').toUpperCase()}
              </Typography>
            </ListItemText>
          </ListItem>
          {filterGroup(
            t('filterMenu.headers.timeFrame'),
            t('filterMenu.filters.lastWeek'),
            t('filterMenu.filters.lastMonth')
          )}
          <ListItem className={classes.nested}>
            <ListItemText className={classes.listItem}>
              <StyledMonthDatePicker
                clearable={true}
                disableFuture={false}
                selectedDate={selectedDate}
                handleDateChange={handleDateChange}
                shouldShowCheckIcon={
                  observationListState?.filter?.observedAfter &&
                  observationListState?.filter?.observedBefore
                    ? true
                    : false
                }
              />
            </ListItemText>
          </ListItem>
          {filterGroup(
            t('filterMenu.headers.status'),
            t('filterMenu.filters.open'),
            t('filterMenu.filters.closed'),
            t('filterMenu.filters.draft')
          )}
          {filterGroup(
            t('filterMenu.headers.type'),
            t('filterMenu.filters.environmental'),
            t('filterMenu.filters.behavioral')
          )}
        </List>
      </Drawer>
    </div>
  );
};

ObservationsDashboardFilterMenu.propTypes = {
  handleChange: PropTypes.func
};

export default ObservationsDashboardFilterMenu;
