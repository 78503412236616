import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-final-form-hooks';
import { useMutation } from '@apollo/react-hooks';
import { Grid } from '@mui/material';

import useToast from 'hooks/useToast';
import StyledDialog from 'shared/Dialog';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import StyledButtonSecondary from 'shared/Buttons/ButtonSecondary';
import OrientationFormFields from 'components/orientations/OrientationFormFields';
import { isValidDateTimeRange } from 'utils';
import {
  dateTimeToNaiveLocalTimeString,
  dateTimeToNaiveLocalDateString,
  naiveLocalDateAndTimeToJsDate,
  dateTimeToLuxonDateTime
} from 'utils/dateTime';
import { UPDATE_ORIENTATION_SERIES } from 'graphql/orientation';

const UpdateOrientationSeriesDialog = ({ isOpen, setIsOpen, orientation }) => {
  const { t } = useTranslation();
  const { displayToast } = useToast();

  const [updateOrientationSeries, { loading: isLoading }] = useMutation(
    UPDATE_ORIENTATION_SERIES
  );

  const initialValues = useMemo(() => {
    const startDate = dateTimeToNaiveLocalDateString(
      orientation.startDateTime,
      orientation.timeZone
    );
    const endDate = dateTimeToNaiveLocalDateString(
      orientation.endDateTime,
      orientation.timeZone
    );
    return {
      name: orientation.name ?? 'Safety Orientation',
      maxAttendees: orientation.maxAttendees ?? '',
      language: orientation.language ?? '',
      facilitators:
        orientation.facilitators.map(facilitator => ({
          id: facilitator.upn,
          label: facilitator.name,
          value: facilitator.name,
          upn: facilitator.upn
        })) ?? [],
      notes: orientation.notes ?? '',
      timeZone:
        { label: orientation.timeZone, value: orientation.timeZone } ?? '',
      startDate,
      startTime: naiveLocalDateAndTimeToJsDate(
        startDate,
        dateTimeToNaiveLocalTimeString(
          orientation.startDateTime,
          orientation.timeZone
        )
      ),
      endDate,
      endTime: naiveLocalDateAndTimeToJsDate(
        endDate,
        dateTimeToNaiveLocalTimeString(
          orientation.endDateTime,
          orientation.timeZone
        )
      )
    };
  }, [
    orientation.name,
    orientation.maxAttendees,
    orientation.language,
    orientation.facilitators,
    orientation.notes,
    orientation.timeZone,
    orientation.startDateTime,
    orientation.endDateTime
  ]);

  const { form, values } = useForm({
    /* istanbul ignore next */
    onSubmit: () => {}, // this function required for useForm but is not used
    initialValues
  });

  const isValidDateTimes = isValidDateTimeRange({
    startDate: values.startDate,
    startTime: values.startTime,
    endDate: values.endDate,
    endTime: values.endTime
  });

  const handleClose = () => {
    setIsOpen(false);
    form.reset();
  };

  const handleSubmit = () => {
    const existingFacilitators = orientation.facilitators.map(
      facilitator => facilitator.upn
    );
    const facilitatorsToProcess =
      values.facilitators?.map(facilitator => facilitator.upn) ?? [];

    const oldSeriesStartDateTime = dateTimeToLuxonDateTime(
      orientation.series.startDateTime,
      orientation.series.timeZone
    );
    const oldOccurrenceStartDateTime = dateTimeToLuxonDateTime(
      orientation.startDateTime,
      orientation.timeZone
    );
    const newOccurrenceStartDateTime = dateTimeToLuxonDateTime(
      naiveLocalDateAndTimeToJsDate(
        initialValues.startDate,
        dateTimeToNaiveLocalTimeString(values.startTime),
        values.timeZone.value
      )
    );
    const oldSeriesEndDateTime = dateTimeToLuxonDateTime(
      orientation.series.endDateTime,
      orientation.series.timeZone
    );
    const oldOccurrenceEndDateTime = dateTimeToLuxonDateTime(
      orientation.endDateTime,
      orientation.timeZone
    );
    const newOccurrenceEndDateTime = dateTimeToLuxonDateTime(
      naiveLocalDateAndTimeToJsDate(
        initialValues.endDate,
        dateTimeToNaiveLocalTimeString(values.endTime),
        values.timeZone.value
      )
    );

    updateOrientationSeries({
      variables: {
        input: {
          id: orientation.series.id,
          name: values.name ?? undefined,
          maxAttendees: parseInt(values.maxAttendees) ?? undefined,
          language: values.language ?? undefined,
          facilitatorsToAdd: facilitatorsToProcess.filter(
            facilitator => !existingFacilitators.includes(facilitator)
          ),
          facilitatorsToRemove: existingFacilitators.filter(
            facilitator => !facilitatorsToProcess.includes(facilitator)
          ),
          notes: values.notes ?? undefined,
          timeZone: values.timeZone.value ?? undefined,
          startDateTime: oldSeriesStartDateTime
            .minus(oldOccurrenceStartDateTime.diff(newOccurrenceStartDateTime))
            .setZone('UTC'),
          endDateTime: oldSeriesEndDateTime
            .minus(oldOccurrenceEndDateTime.diff(newOccurrenceEndDateTime))
            .setZone('UTC')
        }
      }
    })
      .then(({ data: { updateOrientationSeries: orientations } }) => {
        displayToast(
          t('updateOrientationDialog.toasts.series.success'),
          'success'
        );
        handleClose();
      })
      .catch(error => {
        console.error('Update Orientation Series Error: ', error);
        displayToast(t('updateOrientationDialog.toasts.series.error'), 'error');
      });
  };

  const canSubmit = () => {
    const { error, invalid, pristine } = form.getState();
    return !(isLoading || error || invalid || pristine || !isValidDateTimes);
  };

  return (
    <StyledDialog
      data-testid="update-project-orientation-dialog"
      isLoading={isLoading}
      isOpen={isOpen}
      handleClose={handleClose}
      title={t('updateProjectOrientationSeriesDialog.title')}
      content={
        <form>
          <OrientationFormFields
            form={form}
            isLoading={isLoading}
            orientation={orientation}
            canEditDateRange={false}
            isValidDateTimes={isValidDateTimes}
            isEditingSeries={true}
          />
        </form>
      }
      actions={
        <Grid container justifyContent="space-between">
          <Grid item>
            <StyledButtonSecondary
              label={t('updateProjectOrientationSeriesDialog.actions.cancel')}
              disabled={isLoading}
              onClick={handleClose}
            />
          </Grid>
          <Grid item>
            <StyledButtonPrimary
              label={t('updateProjectOrientationSeriesDialog.actions.submit')}
              onClick={handleSubmit}
              disabled={!canSubmit()}
            />
          </Grid>
        </Grid>
      }
    />
  );
};

UpdateOrientationSeriesDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  orientation: PropTypes.object.isRequired
};

export default UpdateOrientationSeriesDialog;
