import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import { useField, useForm } from 'react-final-form-hooks';
import AddIcon from '@mui/icons-material/Add';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Button, Typography, CircularProgress } from '@mui/material';

import useToast from 'hooks/useToast';
import useFormValidation from 'hooks/useFormValidation';
import StyledInput from 'shared/Input';
import { generateTransactionKey } from 'utils';
import {
  GET_ALL_GLOBAL_TEMPLATE_CATEGORIES,
  CREATE_GLOBAL_AHA_TEMPLATE_CATEGORY
} from 'graphql/aha/globalTemplateCategory';

const useStyles = makeStyles(theme => ({
  container: {
    paddingLeft: theme.spacing(5),
    [theme.breakpoints.down('lg')]: { paddingLeft: 28 }
  },
  buttonLabel: { fontSize: '0.75rem', fontWeight: 'bold' },
  buttonContainer: {
    margin: theme.spacing(2, 0, 0, 1),
    [theme.breakpoints.down('lg')]: { margin: 0 }
  }
}));

const AddCategory = ({ setUnsavedGlobalTemplateCategory }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isRequired } = useFormValidation();
  const { displayToast } = useToast();
  const [isEditing, setIsEditing] = useState(false);
  const [transactionKey, setTransactionKey] = useState(
    generateTransactionKey()
  );

  const [
    createAhaGlobalTemplateCategory,
    { loading: isCreateLoading }
  ] = useMutation(CREATE_GLOBAL_AHA_TEMPLATE_CATEGORY, {
    refetchQueries: [{ query: GET_ALL_GLOBAL_TEMPLATE_CATEGORIES }],
    awaitRefetchQueries: true
  });

  const { form, pristine } = useForm({
    /* istanbul ignore next */
    onSubmit: () => {} // this function required for useForm but is not used
  });

  const categoryNameField = useField('categoryName', form, isRequired);

  const canSubmit = () => {
    const { error, invalid } = form.getState();
    return !(invalid || error || pristine);
  };

  useEffect(() => {
    if (categoryNameField.input.value.length > 0) {
      setUnsavedGlobalTemplateCategory(true);
    } else {
      setUnsavedGlobalTemplateCategory(false);
    }
  }, [categoryNameField.input.value, setUnsavedGlobalTemplateCategory]);

  const handleCreateGlobalCategorySubmit = () => {
    setIsEditing(false);
    createAhaGlobalTemplateCategory({
      variables: {
        input: {
          name: categoryNameField.input.value,
          transactionKey: transactionKey
        }
      }
    })
      .then(() => {
        displayToast(
          t('globalStepLibrary.toasts.create.success', {
            name: categoryNameField.input.value
          }),
          'success'
        );
        handleClose();
      })
      .catch(error => {
        console.error('Create Global Step Category Error: ', error);
        displayToast(t('globalStepLibrary.toasts.create.error'), 'error');
      });
  };

  const handleCancel = () => {
    form.reset();
    setIsEditing(false);
    setUnsavedGlobalTemplateCategory(false);
  };

  const handleClose = () => {
    setIsEditing(false);
    setTransactionKey(generateTransactionKey());
    form.resetFieldState('categoryName');
    form.reset();
  };

  return (
    <Grid
      container
      alignItems="center"
      direction="row"
      className={classes.container}>
      {!isEditing && !isCreateLoading && (
        <>
          <Button
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => setIsEditing(!isEditing)}>
            <Typography className={classes.buttonLabel}>
              {t('addCategory.actions.addCategoryButton')}
            </Typography>
          </Button>
        </>
      )}
      {(isEditing || isCreateLoading) && (
        <>
          <Grid item>
            <StyledInput
              label={'Category'}
              input={categoryNameField.input}
              meta={categoryNameField.meta}
              disabled={isCreateLoading}
            />
          </Grid>
          <Grid item>
            <Grid
              container
              direction="row"
              spacing={1}
              className={classes.buttonContainer}>
              <Grid item>
                {!isCreateLoading && (
                  <Button
                    onClick={handleCreateGlobalCategorySubmit}
                    disabled={!canSubmit() || isCreateLoading}>
                    <Typography className={classes.buttonLabel}>
                      {t('addCategory.actions.saveButton')}
                    </Typography>
                  </Button>
                )}
                {isCreateLoading && (
                  <CircularProgress color="primary" size={30} />
                )}
              </Grid>
              <Grid item>
                <Button onClick={handleCancel} disabled={isCreateLoading}>
                  <Typography className={classes.buttonLabel}>
                    {t('addCategory.actions.cancelButton')}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

AddCategory.propTypes = {
  setUnsavedGlobalTemplateCategory: PropTypes.func
};

export default AddCategory;
