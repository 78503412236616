import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useForm, useField } from 'react-final-form-hooks';
import EditIcon from '@mui/icons-material/Edit';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Button, Checkbox, FormControlLabel } from '@mui/material';

import useToast from 'hooks/useToast';
import StyledButtonGroup from 'shared/Buttons/ButtonGroup';
import StyledDialog from 'shared/Dialog';
import StyledAutoComplete from 'shared/AutoComplete';
import StyledNotice from 'shared/Notice';
import StyledConfirmationSwitch from 'shared/ConfirmationSwitch';

const useStyles = makeStyles(theme => ({
  warningMessageContent: {
    marginTop: '10px'
  },
  styledButton: {
    backgroundColor: theme.palette.primary.contrastText,
    width: 'auto',
    borderRadius: 16,
    minWidth: theme.spacing(10),
    color: theme.palette.secondary.contrastText,
    fontSize: '0.75rem',
    fontWeight: 'bold'
  }
}));

const EditSafetyHoursSupervisorsDialog = ({
  supervisedBy = [],
  supervisors,
  getSupervisorsLoading,
  setSupervisorsCallback,
  setSupervisorsLoading,
  buttonLabel,
  setDefaultsLabel,
  dialogTitle,
  warningMessage,
  disabled
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { displayToast } = useToast();

  const initialValues = {
    newSafetyHoursSupervisors:
      supervisedBy.map(supervisor => ({
        value: supervisor,
        label: `${supervisor.fullName} - ${supervisor.soteriaAdUser?.employeeId}`
      })) ?? []
  };
  const isLoading = getSupervisorsLoading || setSupervisorsLoading;

  const [isOpen, toggleDialog] = useState(false);
  const [isSetDefaultsChecked, setIsSetDefaultsChecked] = useState(false);
  const [isConfirmed, toggleConfirmSwitch] = useState(false);

  const { form } = useForm({
    /* istanbul ignore next */
    onSubmit: () => {} // this function required for useForm but is not used
  });

  const newSafetyHoursSupervisors = useField('newSafetyHoursSupervisors', form);

  const [value, setValue] = useState(
    supervisedBy.map(supervisor => ({
      value: supervisor,
      label: `${supervisor.fullName} - ${supervisor.soteriaAdUser?.employeeId}`
    }))
  );

  const valueIds = value ? value.map(selection => selection.value.id) : [];

  const filterSelectedSupervisors = supervisors.filter(supervisor => {
    return !valueIds?.includes(supervisor.id);
  });

  const handleClose = () => {
    toggleDialog(false);
    toggleConfirmSwitch(false);
    setIsSetDefaultsChecked(false);
    form.reset(initialValues);
  };

  const handleChange = event => {
    toggleConfirmSwitch(event.target.checked);
  };

  const handleChangeSetDefaults = event => {
    setIsSetDefaultsChecked(event.target.checked);
  };

  const handleSubmit = () => {
    setSupervisorsCallback(value, isSetDefaultsChecked)
      .then(() => {
        handleClose();
        displayToast(
          t('editSafetyHoursSupervisors.toasts.status.success'),
          'success'
        );
        form.reset(initialValues);
      })
      .catch(error => {
        console.error('Add Supervisor Error: ', error);
        displayToast(
          t('editSafetyHoursSupervisors.toasts.status.error'),
          'error'
        );
      });
  };

  return (
    <Fragment>
      <Grid container justifyContent="flex-end">
        <Grid item>
          <Button
            className={classes.styledButton}
            variant="outlined"
            startIcon={<EditIcon />}
            onClick={() => {
              form.reset(initialValues);
              toggleDialog(true);
            }}
            disabled={disabled ? true : null}>
            {buttonLabel}
          </Button>
        </Grid>
      </Grid>
      <StyledDialog
        data-testid="add-safety-hours-supervisors-dialog"
        isOpen={isOpen}
        handleClose={handleClose}
        title={dialogTitle}
        isLoading={isLoading}
        content={
          <>
            <form>
              <Grid container direction="column" spacing={1}>
                <Grid item xs={12}>
                  <StyledAutoComplete
                    selectedValue={value}
                    meta={newSafetyHoursSupervisors.meta}
                    handleChange={setValue}
                    disabled={isLoading}
                    options={filterSelectedSupervisors.map(supervisor => ({
                      value: supervisor,
                      label: `${supervisor.fullName} - ${supervisor.soteriaAdUser?.employeeId}`
                    }))}
                    placeholder={t(
                      'editSafetyHoursSupervisors.fields.placeholder'
                    )}
                    label={t('editSafetyHoursSupervisors.fields.name')}
                    required
                    handleInputChange={() => {}}
                    isMulti={true}
                    key="safety-supervisors-select"
                  />
                  {setDefaultsLabel && (
                    <FormControlLabel
                      label={setDefaultsLabel}
                      control={
                        <Checkbox
                          checked={isSetDefaultsChecked}
                          onChange={handleChangeSetDefaults}
                          color="primary"
                        />
                      }
                    />
                  )}
                  {warningMessage ? (
                    <Grid
                      container
                      direction="column"
                      spacing={1}
                      className={classes.warningMessageContent}>
                      <Grid item>
                        <StyledNotice type="warning" message={warningMessage} />
                      </Grid>
                      <Grid item>
                        <StyledConfirmationSwitch
                          disabled={isLoading}
                          checked={isConfirmed}
                          onChange={handleChange}
                          value={'isConfirmed'}
                        />
                      </Grid>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            </form>
          </>
        }
        actions={
          <>
            <StyledButtonGroup
              onClickAltAction={handleClose}
              labelAltAction={t('editSafetyHoursSupervisors.actions.cancel')}
              disabledAltAction={isLoading}
              disabledMainAction={
                isLoading || (!!warningMessage && !isConfirmed)
              }
              onClickMainAction={handleSubmit}
              labelMainAction={t('editSafetyHoursSupervisors.actions.submit')}
            />
          </>
        }
      />
    </Fragment>
  );
};

EditSafetyHoursSupervisorsDialog.propTypes = {
  supervisedBy: PropTypes.array,
  supervisors: PropTypes.array.isRequired,
  getSupervisorsLoading: PropTypes.bool.isRequired,
  setSupervisorsCallback: PropTypes.func.isRequired,
  setSupervisorsLoading: PropTypes.bool.isRequired,
  dialogTitle: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string,
  setDefaultsLabel: PropTypes.string,
  warningMessage: PropTypes.string
};

export default EditSafetyHoursSupervisorsDialog;
