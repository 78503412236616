import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import InfoIcon from '@mui/icons-material/Info';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import DangerousIcon from '@mui/icons-material/PriorityHigh';
import { Grid, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  notice: {
    borderLeft: '6px solid' + theme.palette.secondary.dark,
    backgroundColor: theme.palette.secondary.main,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  noticeTitle: {
    fontSize: '0.875rem',
    fontWeight: 'bold',
    color: theme.palette.secondary.contrastText
  },
  noticeContent: {
    fontSize: '0.75rem',
    color: theme.palette.secondary.contrastText
  },
  icon: { color: theme.palette.secondary.contrastText },
  warningNotice: {
    borderLeft: '6px solid' + theme.palette.warning.dark,
    backgroundColor: theme.palette.warning.main
  },
  warningNoticeTitle: { color: theme.palette.primary.contrastText },
  warningNoticeContent: {
    color: theme.palette.primary.contrastText,
    fontWeight: 'bold'
  },
  warningIcon: { color: theme.palette.primary.contrastText },
  urgentNotice: {
    borderLeft: '6px solid' + theme.palette.error.dark,
    backgroundColor: theme.palette.error.main
  },
  urgentNoticeTitle: {
    color: theme.palette.primary.contrastText
  },
  urgentNoticeContent: {
    color: theme.palette.primary.contrastText,
    fontWeight: 'bold'
  },
  urgentIcon: { color: theme.palette.primary.contrastText }
}));

// How to use each level of user notice:

// 1. Notice - to convey information
// 2. Warning - to convey possible impacting information
// 3. Urgent/Error - to convey immediately inpactful information

const NOTICE_TITLE = 'Please Note';
const WARNING_TITLE = 'Warning';
const URGENT_TITLE = 'PLEASE ADDRESS THE FOLLOWING';

const StyledNotice = ({ icon, title, message, type }) => {
  const classes = useStyles();

  return (
    <Grid container direction="column" spacing={1} type={type}>
      <Grid item>
        <Grid
          container
          className={classnames(classes.notice, {
            [`${classes.notice}`]: type === 'notice',
            [`${classes.warningNotice}`]: type === 'warning',
            [`${classes.urgentNotice}`]: type === 'urgent'
          })}>
          <Grid item xs={12}>
            <Grid container direction="row" spacing={1} alignItems="center">
              <Grid item>
                {icon ?? icon}
                {!icon && (type === 'notice' || !type) && (
                  <InfoIcon
                    className={classnames(classes.icon, {
                      [`${classes.icon}`]: !type || type === 'notice',
                      [`${classes.warningIcon}`]: type === 'warning'
                    })}
                  />
                )}
                {!icon && type === 'warning' && (
                  <ReportProblemOutlinedIcon
                    className={classnames(classes.icon, {
                      [`${classes.warningIcon}`]: !icon && type === 'warning'
                    })}
                  />
                )}
                {!icon && type === 'urgent' && (
                  <DangerousIcon
                    className={classnames(classes.icon, {
                      [`${classes.urgentIcon}`]: !icon && type === 'urgent'
                    })}
                  />
                )}
              </Grid>
              <Grid item>
                <Typography
                  className={classnames(classes.noticeTitle, {
                    [`${classes.noticeTitle}`]: type === 'notice',
                    [`${classes.warningNoticeTitle}`]: type === 'warning',
                    [`${classes.urgentNoticeTitle}`]: type === 'urgent'
                  })}>
                  {title ?? title}
                  {!title && (type === 'notice' || !type) && NOTICE_TITLE}
                  {!title && type === 'warning' && WARNING_TITLE}
                  {!title && type === 'urgent' && URGENT_TITLE}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography
              component="div"
              className={classnames(classes.noticeContent, {
                [`${classes.noticeContent}`]: !type || type === 'notice',
                [`${classes.warningNoticeContent}`]: type === 'warning',
                [`${classes.urgentNoticeContent}`]: type === 'urgent'
              })}>
              {message}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

StyledNotice.propTypes = {
  icon: PropTypes.node,
  message: PropTypes.any.isRequired,
  title: PropTypes.string,
  type: PropTypes.string
};

export default StyledNotice;
