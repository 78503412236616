import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import withLoginIfOnline from 'hocs/withLoginIfOnline';

const SoteriaAuthentication = ({ children }) => {
  return <Fragment>{children}</Fragment>;
};
SoteriaAuthentication.propTypes = {
  children: PropTypes.node
};

export default withLoginIfOnline(SoteriaAuthentication);
