import { useMutation } from '@apollo/react-hooks';
import { UPDATE_PROJECT_TEMPLATE } from 'graphql/aha/projectTemplate';
import useToast from 'hooks/useToast';
import PropTypes from 'prop-types';
import React from 'react';
import StatusCard from 'shared/StatusCard';

const AhaStatusCard = ({ ahaTemplate }) => {
  const { displayToast } = useToast();
  const [updateAhaProjectTemplate, { loading: isLoading }] = useMutation(
    UPDATE_PROJECT_TEMPLATE
  );

  const handleChange = event => {
    updateAhaProjectTemplate({
      variables: {
        input: {
          id: ahaTemplate?.ahaProjectTemplate?.id,
          status: event.target.checked
        }
      }
    })
      .then(() => {
        displayToast('Success: The AHA status has been updated.', 'success');
      })
      .catch(error => {
        console.error('Update Project Template Error: ', error);
        displayToast(
          'Error: Something went wrong while trying to update the AHA status. Please try again. If the problem persists, please contact support.',
          'error'
        );
      });
  };

  return (
    <StatusCard
      loading={isLoading}
      title={'Current AHA Status: '}
      isActive={ahaTemplate?.ahaProjectTemplate?.status}
      deactivateSubtitle={'Deactivate on Project'}
      activateSubtitle={'Activate on Project'}
      handleChange={handleChange}
    />
  );
};

AhaStatusCard.propTypes = {
  ahaTemplate: PropTypes.any.isRequired
};

export default AhaStatusCard;
