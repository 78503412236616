import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { Typography, Tooltip, Grid } from '@mui/material';

const useStyles = makeStyles(theme => ({
  limitedText: {
    fontSize: '0.875rem',
    maxWidth: 800,
    whiteSpace: 'noWrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    [theme.breakpoints.down('xl')]: { maxWidth: 600 },
    [theme.breakpoints.down('xl')]: { maxWidth: 400 },
    [theme.breakpoints.down('lg')]: { maxWidth: 300 },
    [theme.breakpoints.down('md')]: { maxWidth: 250 }
  }
}));

const StyledTableCellContent = ({ value }) => {
  const classes = useStyles();

  if (value) {
    return (
      <Grid container>
        <Tooltip title={value}>
          <Typography className={classes.limitedText}>{value}</Typography>
        </Tooltip>
      </Grid>
    );
  }
  return null;
};

StyledTableCellContent.propTypes = {
  value: PropTypes.any
};

export default StyledTableCellContent;
