import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import OfflineIcon from '@mui/icons-material/CloudOff';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography, useTheme } from '@mui/material';

import StyledUserNoticeTemplate from 'components/common/UserNoticeTemplate';

const useStyles = makeStyles(theme => ({
  title: {
    textAlign: 'center',
    fontSize: '1.5rem',
    fontWeight: 'bold'
  },
  message: { marginBottom: theme.spacing(5) },
  img: { width: 200, padding: '1.5rem' }
}));

export const NoObservationsNotice = ({ message }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <StyledUserNoticeTemplate
      image={
        <img
          className={classes.img}
          src={theme.offlineNoAccessNotice}
          alt=""
          role="presentation"
        />
      }
      content={
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          direction="column"
          spacing={3}>
          <Grid item>
            <Grid container direction="row" spacing={1} alignItems="center">
              <Grid item>
                <OfflineIcon className={classes.icon} />
              </Grid>
              <Grid item>
                <Typography color="textPrimary" className={classes.title}>
                  {t('offlineNoAccessNotice.title')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Typography className={classes.message}>
              {message ?? t('offlineNoAccessNotice.message')}
            </Typography>
          </Grid>
        </Grid>
      }
    />
  );
};

NoObservationsNotice.propTypes = {
  message: PropTypes.any
};

export default NoObservationsNotice;
