import { LogLevel } from '@azure/msal-browser';
import { domain, Domain } from 'config/domain';

const userAgent = window.navigator.userAgent;
const isEdge = userAgent.indexOf('Edge/') > 0;
const isFirefox = userAgent.indexOf('Firefox') > 0;

export const MSAL_SCOPES =
  domain === Domain.PRIMARY
    ? ['User.Read']
    : [
        'https://HenselPhelpsAzureB2C.onmicrosoft.com/40703d4e-98c4-498c-8794-7d3409a1dc7e/Projects.Read'
      ];

export const redirectUri = process.env.REACT_APP_AUTH_REDIRECT_URI;

const extraAuthConfig =
  domain === Domain.PRIMARY
    ? {}
    : {
        knownAuthorities: [`HenselPhelpsAzureB2C.b2clogin.com`]
      };

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_CLIENT_ID,
    authority: process.env.REACT_APP_AUTH_AUTHORITY_URI,
    redirectUri,
    navigateToLoginRequestUrl: true,
    ...extraAuthConfig
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: isEdge || isFirefox
  },
  system: {
    allowNativeBroker: false, // Disables WAM Broker
    loggerOptions: {
      logLevel: LogLevel.Warning,
      loggerCallback: (level, message, containsPii) => {
        const scrubbedMessage = containsPii
          ? 'Message contents redacted due to PII.'
          : message;
        switch (level) {
          case LogLevel.Error:
            console.error(scrubbedMessage);
            return;
          case LogLevel.Info:
            console.info(scrubbedMessage);
            return;
          case LogLevel.Verbose:
            console.debug(scrubbedMessage);
            return;
          case LogLevel.Warning:
            console.warn(scrubbedMessage);
            return;
        }
      },
      piiLoggingEnabled: false
    }
  }
};
