import React, { useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { Typography, IconButton, Grid } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import EditAhaInformationDialog from 'components/ahas/EditAhaTemplateInformationDialog';

const useStyles = makeStyles(theme => ({
  buttonLabel: { fontSize: '0.75rem', fontWeight: 'bold' },
  buttonContainer: { marginLeft: theme.spacing(1) },
  root: { fontSize: '2rem' },
  title: {
    fontSize: '2rem',
    lineHeight: 1.25,
    fontWeight: 'bold',
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.5rem'
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: 250
    }
  }
}));

const EditableAhaType = ({ ahaTemplate, setAhaTemplate, isDisabled }) => {
  const classes = useStyles();
  const [isEditing, setIsEditing] = useState(false);

  return (
    <>
      <Grid container direction="row" alignItems="center">
        <Grid item>
          <Typography color="textPrimary" className={classes.title}>
            {ahaTemplate?.type}
          </Typography>
        </Grid>
        <Grid item>
          <IconButton
            size="small"
            onClick={() => setIsEditing(true)}
            disabled={isDisabled}>
            <EditIcon />
          </IconButton>
        </Grid>
      </Grid>
      <EditAhaInformationDialog
        isOpen={isEditing}
        setIsOpen={setIsEditing}
        ahaTemplate={ahaTemplate}
        setAhaTemplate={setAhaTemplate}
      />
    </>
  );
};

EditableAhaType.propTypes = {
  ahaTemplate: PropTypes.object,
  setAhaTemplate: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool
};

export default EditableAhaType;
