import React from 'react';
import PropTypes from 'prop-types';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Card, Typography, IconButton } from '@mui/material';

import useRoles from 'hooks/useRoles';

const useStyles = makeStyles(theme => ({
  card: {
    borderRadius: 0,
    boxShadow: 'none'
  },
  section: {
    border: '1px solid' + theme.borderGray,
    padding: theme.spacing(0, 1, 0, 2),
    [theme.breakpoints.down('xl')]: { padding: theme.spacing(0.5) }
  },
  name: {
    fontWeight: 'bold',
    display: 'inline',
    verticalAlign: '25%',
    [theme.breakpoints.down('xl')]: { fontSize: '0.875rem' }
  }
}));

const AhaDetailedActivityCard = ({
  activity,
  setIsEditActivityDialogOpen,
  setIsConfirmActivityDeleteDialogOpen,
  setSelectedActivity,
  isReadOnlyReview
}) => {
  const classes = useStyles();
  const { isAhaAdmin } = useRoles();

  const onEditActivityClick = activity => {
    setSelectedActivity(activity);
    setIsEditActivityDialogOpen(true);
  };

  const onDeleteActivityClick = activity => {
    setSelectedActivity(activity);
    setIsConfirmActivityDeleteDialogOpen(true);
  };

  return (
    <Card key={activity.name} className={classes.card}>
      <Grid container className={classes.container}>
        <Grid item xs={12} className={classes.section}>
          <Grid container direction="row">
            <Grid item xs={12} sm={10} md={10} lg={10}>
              <Typography className={classes.name}>{activity.name}</Typography>
            </Grid>
            <Grid item xs={12} sm={2} md={2} lg={2}>
              {isAhaAdmin && !isReadOnlyReview && (
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <IconButton
                      size="small"
                      onClick={() => onEditActivityClick(activity)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => onDeleteActivityClick(activity)}>
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

AhaDetailedActivityCard.propTypes = {
  activity: PropTypes.object,
  setIsEditActivityDialogOpen: PropTypes.func,
  setIsConfirmActivityDeleteDialogOpen: PropTypes.func,
  setSelectedActivity: PropTypes.func,
  isReadOnlyReview: PropTypes.bool
};

export default AhaDetailedActivityCard;
