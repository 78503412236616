import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSubscription } from '@apollo/react-hooks';
import { NumericFormat } from 'react-number-format';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import FunctionsIcon from '@mui/icons-material/Functions';
import { Grid, Card, Typography, Avatar } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import withAuthorization from 'hocs/withAuthorization';
import useCompany from 'hooks/useCompany';
import LoadingSpinner from 'components/common/LoadingSpinner';
import ErrorNotice from 'components/common/ErrorNotice';
import PageNotFoundNotice from 'components/common/PageNotFoundNotice';
import CompanyProjectList from 'components/company/CompanyProjectList';
import CompanyInfoForm from 'components/company/CompanyInfoForm';
import { COMPANY_SUBSCRIPTION } from 'graphql/company';
import useRoles from 'hooks/useRoles';
import CompanyDeleteCard from 'components/company/CompanyDeleteCard';
import withOnlineAccessOnly from 'hocs/withOnlineAccessOnly';

const useStyles = makeStyles(theme => ({
  icon: { fontSize: '3rem' },
  avatar: {
    borderRadius: 4,
    width: theme.spacing(8),
    height: theme.spacing(8),
    backgroundColor: theme.palette.primary.main
  },
  title: { fontSize: '2rem' },
  subtitle: { fontSize: '1.5rem', marginBottom: theme.spacing(1) },
  textBold: { fontWeight: 'bold' }
}));

const CompanyPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { companyId } = useParams();
  const { company, error, loading } = useCompany(companyId);
  const { isSupportRole } = useRoles();

  useSubscription(COMPANY_SUBSCRIPTION, {
    variables: { companyId: companyId }
  });

  const ttdHours =
    company?.tradePartners.reduce(
      (total, tradePartner) => total + tradePartner.workerHoursTotalToDate,
      0
    ) ?? 0;

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    if (error.message?.includes('not found')) {
      return <PageNotFoundNotice />;
    } else {
      return <ErrorNotice />;
    }
  }

  return (
    <Grid container direction="column">
      <Typography color="textPrimary" className={classes.title}>
        {t('companyPage.title')}
      </Typography>
      <Typography color="textPrimary" className={classes.subtitle}>
        {company.name}
      </Typography>
      <Grid item>
        <Grid container direction="row" spacing={3}>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <Grid container direction="column" spacing={3}>
              <Grid item>
                <CompanyInfoForm company={company} />
              </Grid>
              <Grid item xs={12}>
                <CompanyProjectList tradePartners={company.tradePartners} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} className="margin-bottom">
            <Grid container direction="column" spacing={3}>
              <Grid item xs={12}>
                <Card className="padding">
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    spacing={1}>
                    <Grid item>
                      <Avatar className={classes.avatar}>
                        <LocationCityIcon className={classes.icon} />
                      </Avatar>
                    </Grid>
                    <Grid item>
                      <Typography color="textSecondary">
                        {t('companyPage.data.activeProjects')}
                      </Typography>
                      <Typography className={classes.textBold}>
                        {company.activeProjectCount}
                      </Typography>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card className="padding">
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    spacing={1}>
                    <Grid item>
                      <Avatar className={classes.avatar}>
                        <FunctionsIcon className={classes.icon} />
                      </Avatar>
                    </Grid>
                    <Grid item>
                      <Typography color="textSecondary">
                        {t('companyPage.workerHours.title')}
                      </Typography>
                      <Typography>
                        <NumericFormat
                          displayType={'text'}
                          thousandSeparator={true}
                          value={ttdHours}
                          className={classes.textBold}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              {isSupportRole && (
                <Grid item xs={12}>
                  <CompanyDeleteCard company={company} />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withOnlineAccessOnly(withAuthorization(CompanyPage));
