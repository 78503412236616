/* eslint-disable react/display-name */
import React, { Fragment, useState } from 'react';
import cloneDeep from 'clone-deep';
import { NumericFormat } from 'react-number-format';
import LoadingOverlay from 'react-loading-overlay';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import useDebounce from 'hooks/useDebounce';
import withAuthorization from 'hocs/withAuthorization';
import withOnlineAccessOnly from 'hocs/withOnlineAccessOnly';
import StyledTable from 'shared/Table';
import BackToTopButton from 'shared/BackToTopButton';
import AddTradePartnerDialog from 'components/tradepartners/AddTradePartnerDialog';
import ErrorNotice from 'components/common/ErrorNotice';
import PageNotFoundNotice from 'components/common/PageNotFoundNotice';
import ActiveOrInactiveStatusContent from 'components/common/ActiveOrInactiveStatusContent';
import StyledTableCellContent from 'components/common/StyledTableCellContent';
import useTradePartnerListPageTableState from 'store/tradePartnerListPageTableState';
import { GET_PAGINATED_TRADE_PARTNERS_FOR_PROJECT } from 'graphql/tradePartner';
import useLazyQueryPromise from 'hooks/useLazyQueryPromise';
import { serveCsv, CSV_PAGES } from '../../../utils/csv';
import useToast from 'hooks/useToast';

const useStyles = makeStyles(theme => ({
  limitedText: {
    fontSize: '0.875rem',
    maxWidth: 800,
    whiteSpace: 'noWrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    [theme.breakpoints.down('xl')]: { maxWidth: 600 },
    [theme.breakpoints.down('xl')]: { maxWidth: 400 },
    [theme.breakpoints.down('lg')]: { maxWidth: 300 },
    [theme.breakpoints.down('md')]: { maxWidth: 250 }
  }
}));

export const TradePartnerListPage = () => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const { t } = useTranslation();
  const { projectId } = useParams();
  const { displayToast } = useToast();

  const [
    tradePartnerTableState,
    { handleTableStateChange }
  ] = useTradePartnerListPageTableState();

  const [initialState] = useState(tradePartnerTableState);
  // state needed to have smooth transition to loading while debounce is running for filters
  const [filterLoading, setFilterLoading] = useState(false);
  const [csvDownloadLoading, setCsvDownloadLoading] = useState(false);

  const FILTERS = {
    isActive: t('tradePartnersPage.status.active'),
    inactive: t('tradePartnersPage.status.inactive')
  };

  const getApiSortOrder = order => {
    let apiSortOrderField = null;
    let direction = order.direction;

    switch (order.name) {
      case 'name':
        apiSortOrderField = 'name';
        break;
      case 'isActive':
        apiSortOrderField = 'isActive';
        direction = direction === 'asc' ? 'desc' : 'asc';
        break;
      default:
        console.error(`Sort order, "${order.name}", is not being handled!`);
    }
    return apiSortOrderField && direction
      ? [
          {
            [apiSortOrderField]: direction.toUpperCase()
          }
        ]
      : null;
  };

  const { data, loading, error, fetchMore } = useQuery(
    GET_PAGINATED_TRADE_PARTNERS_FOR_PROJECT,
    {
      variables: {
        projectId,
        first: initialState.first,
        skip: initialState.skip,
        filter: initialState.filter,
        search: initialState.search,
        order: getApiSortOrder(initialState.order)
      },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true
    }
  );

  const [getPaginatedTradePartnersPromise] = useLazyQueryPromise(
    GET_PAGINATED_TRADE_PARTNERS_FOR_PROJECT,
    {
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true
    }
  );

  const handleSearchChange = ({ tableState, tradePartnerTableState }) => {
    const { searchText } = tableState;

    handleTableStateChange({
      ...tradePartnerTableState,
      skip: 0,
      page: 0,
      search: searchText
    });

    loadMore({
      search: searchText,
      skip: 0,
      filter: tradePartnerTableState.filter,
      order: getApiSortOrder(tradePartnerTableState.order)
    });
  };

  const loadMore = variables => {
    return fetchMore({
      variables,
      updateQuery: (_previousResult, { fetchMoreResult, _queryVariables }) => {
        return fetchMoreResult;
      }
    });
  };

  const { debounced: debouncedHandleSearchChange } = useDebounce(
    handleSearchChange
  );

  const handleFilterChange = ({ tableState, tradePartnerTableState }) => {
    const { filterList } = tableState;
    const filters = filterList.flat().map(filter => filter.toLowerCase());
    const tradeFilters = filters.filter(item => !item.includes('active'));

    let selectedFilters = {};

    if (filters.includes(FILTERS.isActive.toLowerCase())) {
      selectedFilters = {
        ...selectedFilters,
        isActive: true,
        trade: tradeFilters.length > 0 ? tradeFilters[0] : null
      };
    }

    if (filters.includes(FILTERS.inactive.toLowerCase())) {
      selectedFilters = {
        ...selectedFilters,
        isActive: false,
        trade: tradeFilters.length > 0 ? tradeFilters[0] : null
      };
    }

    if (tradeFilters.length > 0) {
      selectedFilters = {
        ...selectedFilters,
        trade: tradeFilters[0]
      };
    }

    handleTableStateChange({
      ...tradePartnerTableState,
      filter: selectedFilters,
      filterList,
      skip: 0,
      page: 0
    });

    loadMore({
      search: tradePartnerTableState.search,
      filter: selectedFilters,
      skip: 0,
      order: getApiSortOrder(tradePartnerTableState.order)
    });

    setFilterLoading(false);
  };

  const { debounced: debouncedHandleFilterChange } = useDebounce(
    handleFilterChange
  );

  const handleSortChange = tableState => {
    const { sortOrder } = tableState;

    handleTableStateChange({
      ...tradePartnerTableState,
      order: sortOrder,
      page: 0,
      skip: 0
    });

    loadMore({
      search: tradePartnerTableState.search,
      skip: 0,
      filter: tradePartnerTableState.filter,
      order: getApiSortOrder(sortOrder)
    });
  };

  const handlePageChange = tableState => {
    const { rowsPerPage, page } = tableState;

    let skip =
      page < tradePartnerTableState.page
        ? tradePartnerTableState.skip - rowsPerPage
        : tradePartnerTableState.skip + rowsPerPage;

    handleTableStateChange({
      ...tradePartnerTableState,
      page,
      skip
    });

    loadMore({
      search: tradePartnerTableState.search,
      skip,
      filter: tradePartnerTableState.filter,
      order: getApiSortOrder(tradePartnerTableState.order)
    });
  };

  const tradePartners = data?.paginatedTradePartnersOnProject?.tradePartners
    ? cloneDeep(data.paginatedTradePartnersOnProject.tradePartners)
    : [];

  const getFormattedNumberElement = value => {
    return (
      <NumericFormat
        displayType={'text'}
        thousandSeparator={true}
        value={value === 0 ? '' : value}
        className={classes.limitedText}
      />
    );
  };

  const columns = [
    {
      name: 'id',
      label: 'Trade Partner Id',
      options: {
        filter: false,
        display: 'excluded'
      }
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: false,
        sort: true,
        sortOrder: 'asc',
        searchable: true,
        customBodyRender: value => {
          return <StyledTableCellContent value={value} />;
        }
      }
    },
    {
      name: 'trades',
      label: 'Trades',
      options: {
        filter: true,
        filterType: 'textField',
        customFilterListOptions: { render: value => `Trade: ${value}` },
        filterList: tradePartnerTableState.filterList[2]?.length
          ? tradePartnerTableState.filterList[2]
          : null,
        sort: false,
        customBodyRender: value => {
          return (
            <StyledTableCellContent
              value={value.length > 0 ? value.join(', ').toLowerCase() : null}
            />
          );
        }
      }
    },
    {
      name: 'workerHoursMonthToDate',
      label: 'Worker hours MTD',
      options: {
        filter: false,
        sort: false,
        searchable: false,
        customBodyRender: value => getFormattedNumberElement(value)
      }
    },
    {
      name: 'workerHoursYearToDate',
      label: 'Worker hours YTD',
      options: {
        filter: false,
        sort: false,
        searchable: false,
        customBodyRender: value => getFormattedNumberElement(value)
      }
    },
    {
      name: 'workerHoursTotalToDate',
      label: 'Worker hours TTD',
      options: {
        filter: false,
        searchable: false,
        sort: false,
        customBodyRender: value => getFormattedNumberElement(value)
      }
    },
    {
      name: 'isActive',
      label: 'Status',
      options: {
        sort: true,
        searchable: false,
        filter: true,
        filterList: tradePartnerTableState.filterList[6]?.length
          ? tradePartnerTableState.filterList[6]
          : null,
        filterOptions: {
          names: [
            t('tradePartnersPage.status.active'),
            t('tradePartnersPage.status.inactive')
          ],
          logic: (isActive, filters) => {
            if (filters.length) {
              const status = isActive
                ? t('tradePartnersPage.status.active')
                : t('tradePartnersPage.status.inactive');
              return !filters.includes(status);
            }
          }
        },
        customBodyRender: value => (
          <ActiveOrInactiveStatusContent isActive={value} />
        )
      }
    }
  ];

  const options = {
    onDownload: (buildHead, buildBody, columns) => {
      setCsvDownloadLoading(true);

      const fullDataSet = [];
      let total = 0;
      let csvSkipState = 0;

      getPaginatedTradePartnersPromise({
        projectId,
        first: tradePartnerTableState.first,
        skip: csvSkipState,
        filter: tradePartnerTableState.filter,
        search: tradePartnerTableState.search,
        order: getApiSortOrder(tradePartnerTableState.order)
      })
        .then(async res => {
          total = res.data.paginatedTradePartnersOnProject?.total;
          fullDataSet.push(
            ...res?.data?.paginatedTradePartnersOnProject.tradePartners
          );
          csvSkipState += tradePartnerTableState.first;

          if (fullDataSet.length < total) {
            await getRemainingPaginatedTradePartners();
          }

          serveCsv(
            fullDataSet,
            buildHead,
            buildBody,
            columns,
            t('tradePartnersPage.title'),
            CSV_PAGES.tradePartnerList
          );
          setCsvDownloadLoading(false);
        })
        .catch(err => {
          console.error('Error', err);
          setCsvDownloadLoading(false);
          displayToast(t('tradePartnersPage.csvDownloadError'), 'error');
        });

      let getRemainingPaginatedTradePartners = async () => {
        while (fullDataSet.length < total) {
          await getPaginatedTradePartnersPromise({
            projectId,
            first: tradePartnerTableState.first,
            skip: csvSkipState,
            filter: tradePartnerTableState.filter,
            search: tradePartnerTableState.search,
            order: getApiSortOrder(tradePartnerTableState.order)
          })
            .then(nextRes => {
              fullDataSet.push(
                ...nextRes?.data?.paginatedTradePartnersOnProject.tradePartners
              );
            })
            .catch(err => {
              console.error('Error', err);
              setCsvDownloadLoading(false);
              displayToast(
                t('projectPersonnelListPage.csvDownloadError'),
                'error'
              );
            });
          csvSkipState = csvSkipState += tradePartnerTableState.first;
        }
      };

      // do not download csv on initial button click --> this is handled asynchronously above
      return false;
    },
    download: true,
    count: data?.paginatedTradePartnersOnProject?.total ?? null,
    rowsPerPage: tradePartnerTableState.first,
    searchText: tradePartnerTableState.search,
    page: tradePartnerTableState.page,
    rowsPerPageOptions: [],
    sortOrder: tradePartnerTableState.order,
    onRowClick: rowData => {
      history.push(`/projects/${projectId}/trade-partners/${rowData[0]}`);
    },
    serverSide: true,
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          handlePageChange(tableState);
          break;
        case 'sort':
          handleSortChange(tableState);
          break;
        case 'filterChange':
        case 'resetFilters':
          debouncedHandleFilterChange({ tableState, tradePartnerTableState });
          break;
        case 'search':
        case 'onSearchClose':
          debouncedHandleSearchChange({ tableState, tradePartnerTableState });
          break;
        default:
      }
    }
  };

  if (error) {
    if (error.message?.includes('not found')) {
      return <PageNotFoundNotice />;
    } else {
      return <ErrorNotice />;
    }
  }

  return (
    <Fragment>
      <LoadingOverlay
        text={
          csvDownloadLoading && (
            <p
              style={{
                color: theme.palette.primary.main,
                background: theme.palette.secondary.light
              }}>
              {t('tradePartnersPage.csvLoading')}
            </p>
          )
        }
        active={loading || filterLoading || csvDownloadLoading}
        spinner
        fadeSpeed={50}
        styles={{
          overlay: base => ({
            ...base,
            background: theme.palette.background.overlay
          }),
          spinner: base => ({
            ...base,
            width: '50px',
            '& svg circle': {
              stroke: theme.palette.primary.main
            }
          })
        }}>
        <StyledTable
          title={t('tradePartnersPage.title')}
          data={tradePartners}
          columns={columns}
          options={options}
        />
      </LoadingOverlay>
      <AddTradePartnerDialog
        refetchQuery={() =>
          loadMore({
            first: tradePartnerTableState.first,
            skip: tradePartnerTableState.skip,
            filter: tradePartnerTableState.filter,
            search: tradePartnerTableState.search,
            order: getApiSortOrder(tradePartnerTableState.order)
          })
        }
      />
      <BackToTopButton />
    </Fragment>
  );
};

export default withOnlineAccessOnly(withAuthorization(TradePartnerListPage));
