import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';

import StyledDialog from 'shared/Dialog';
import StyledNotice from 'shared/Notice';
import StyledConfirmationSwitch from 'shared/ConfirmationSwitch';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import StyledButtonSecondary from 'shared/Buttons/ButtonSecondary';

const HighRiskConfirmationDialog = ({
  isOpen,
  setIsOpen,
  handleConfirm,
  isLoading
}) => {
  const { t } = useTranslation();
  const [isConfirmed, setIsConfirmed] = useState(false);

  const handleChange = () => {
    if (!isConfirmed) {
      setIsConfirmed(true);
    } else {
      setIsConfirmed(false);
    }
  };

  const handleSubmit = () => {
    handleConfirm();
    handleClose();
  };

  const handleClose = () => {
    setIsConfirmed(false);
    setIsOpen(false);
  };

  return (
    <StyledDialog
      maxWidth="sm"
      title={t('highRiskConfirmationDialog.title')}
      isOpen={isOpen}
      handleClose={handleClose}
      isLoading={isLoading}
      content={
        <Grid container justifyContent="center">
          <Grid item>
            <StyledNotice
              type="urgent"
              message={t('highRiskConfirmationDialog.noticeMessage')}
            />
          </Grid>
          <Grid item>
            <StyledConfirmationSwitch
              disabled={isLoading}
              checked={isConfirmed}
              onChange={handleChange}
              value={'isConfirmed'}
            />
          </Grid>
        </Grid>
      }
      actions={
        <Grid container justifyContent="space-between">
          <Grid item>
            <StyledButtonSecondary
              label={t('highRiskConfirmationDialog.action.cancelButton')}
              disabled={isLoading}
              onClick={handleClose}
            />
          </Grid>
          <Grid item>
            <StyledButtonPrimary
              label={t('highRiskConfirmationDialog.action.submitButton')}
              disabled={!isConfirmed || isLoading}
              onClick={handleSubmit}
            />
          </Grid>
        </Grid>
      }
    />
  );
};

HighRiskConfirmationDialog.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  handleConfirm: PropTypes.func,
  isLoading: PropTypes.bool
};

export default HighRiskConfirmationDialog;
