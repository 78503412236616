import React, { useState, Fragment } from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import PropTypes from 'prop-types';
import { FormHelperText, useTheme, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  errorFormHelperText: { color: theme.palette.error.main },
  labelText: {
    fontFamily: ['Roboto', 'ui-sans-serif', 'sans-serif'].join(','),
    fontWeight: 'bold',
    lineHeight: 1.5,
    fontSize: '0.75rem'
  }
}));

const StyledAutoComplete = ({
  menuPortalTarget = document.body,
  isCreatable,
  isDisabled = false,
  selectedValue,
  handleChange,
  placeholder,
  isRequired = false,
  options,
  isLoading,
  handleInputChange,
  isMulti,
  meta,
  defaultValue,
  label,
  customError,
  autoFocus = false,
  ...rest
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [selectHasBeenFocused, handleFocusSelectChange] = useState(false);

  const selectIsInvalid = (() => {
    return selectHasBeenFocused && (isNullOrEmptyArray() || meta?.error);
    function isNullOrEmptyArray() {
      return isMulti
        ? !selectedValue || selectedValue.length < 1
        : !selectedValue;
    }
  })();

  const menuPortalStyles = {
    menuPortal: styles => ({ ...styles, zIndex: 9999 }),
    menu: (base, state) => ({
      ...base,
      fontFamily: ['Roboto', 'ui-sans-serif', 'sans-serif'].join(','),
      backgroundColor: theme.selectAutoCompleteMenuBackgroundColor,
      position: 'relative',
      top: '-7px',
      marginBottom: '-6px'
    }),
    control: (base, state) => ({
      ...base,
      boxShadow: 'none',
      backgroundColor: theme.selectAutoCompleteControlBackgroundColor,
      borderColor: selectIsInvalid
        ? theme.palette.error.main
        : theme.palette.field.border,
      '&:hover': {
        borderColor: selectIsInvalid
          ? theme.palette.error.main
          : theme.palette.secondary.contrastText
      },
      '&:focused': {
        boxShadow: 'none'
      }
    }),
    option: (provided, state) => {
      const styles = {
        ...provided,
        backgroundColor: theme.selectAutoCompleteOptionBackgroundColor,
        color: theme.selectAutoCompleteOptionColor
      };
      if (state.isFocused) {
        styles.outline = '2px solid #808080';
      }
      return styles;
    },
    input: (provided, state) => ({
      ...provided,
      color: theme.selectAutoCompleteInputColor
    }),
    placeholder: (provided, state) => ({
      ...provided,
      fontFamily: ['Roboto', 'ui-sans-serif', 'sans-serif'].join(','),
      color: 'inherit',
      fontSize: '0.875rem'
    }),
    loadingIndicator: (provided, state) => ({
      ...provided,
      color: theme.selectAutoCompleteLoadingIndicatorColor
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontFamily: ['Roboto', 'ui-sans-serif', 'sans-serif'].join(','),
      color: theme.selectAutoCompleteSingleValueColor
    }),
    multiValue: (provided, state) => ({
      ...provided,
      fontFamily: ['Roboto', 'ui-sans-serif', 'sans-serif'].join(','),
      color: theme.selectAutoCompleteMultiValueColor
    })
  };

  if (isCreatable) {
    return (
      <Fragment>
        {label && (
          <Fragment>
            {!(selectHasBeenFocused && meta?.error) && (
              <Typography color="textSecondary" className={classes.labelText}>
                {isRequired ? `${label} *` : label}
              </Typography>
            )}
            {selectHasBeenFocused && meta?.error && (
              <Typography color="error" className={classes.labelText}>
                {isRequired ? `${label} *` : label}
              </Typography>
            )}
          </Fragment>
        )}
        <CreatableSelect
          aria-label="creatableselect-input"
          defaultValue={defaultValue}
          menuPortalTarget={menuPortalTarget}
          isMulti={isMulti}
          isClearable
          isDisabled={isDisabled}
          isLoading={isLoading}
          allowCreateWhileLoading={false}
          value={selectedValue ? selectedValue : null}
          onChange={handleChange}
          cacheOptions
          options={options}
          defaultOptions={true}
          placeholder={placeholder}
          onFocus={() => handleFocusSelectChange(true)}
          onInputChange={inputValue => handleInputChange(inputValue)}
          autoFocus={autoFocus}
          styles={
            isRequired ? Object.assign({}, menuPortalStyles) : menuPortalStyles
          }
          {...rest}
        />
        {!isDisabled && selectHasBeenFocused && meta?.error && (
          <FormHelperText className={classes.errorFormHelperText}>
            {meta.error}
          </FormHelperText>
        )}
      </Fragment>
    );
  }

  if (isMulti) {
    return (
      <Fragment>
        {label && (
          <Fragment>
            {!(selectHasBeenFocused && meta?.error) && (
              <Typography color="textSecondary" className={classes.labelText}>
                {isRequired ? `${label} *` : label}
              </Typography>
            )}
            {selectHasBeenFocused && meta?.error && (
              <Typography color="error" className={classes.labelText}>
                {isRequired ? `${label} *` : label}
              </Typography>
            )}
          </Fragment>
        )}
        <Select
          aria-label="multiselect-input"
          defaultValue={defaultValue}
          menuPortalTarget={menuPortalTarget}
          isMulti
          isClearable
          isDisabled={isDisabled}
          isLoading={isLoading}
          value={selectedValue ? selectedValue : null}
          onChange={handleChange}
          cacheOptions
          options={options}
          defaultOptions={true}
          placeholder={placeholder}
          onFocus={() => handleFocusSelectChange(true)}
          onInputChange={inputValue => handleInputChange(inputValue)}
          backspaceRemovesValue
          autoFocus={autoFocus}
          styles={
            isRequired ? Object.assign({}, menuPortalStyles) : menuPortalStyles
          }
          {...rest}
        />
        {!isDisabled && selectHasBeenFocused && meta?.error && (
          <FormHelperText className={classes.errorFormHelperText}>
            {meta.error}
          </FormHelperText>
        )}
      </Fragment>
    );
  }

  return (
    <Fragment>
      {label && (
        <Fragment>
          {!(selectHasBeenFocused && (meta?.error || customError)) && (
            <Typography color="textSecondary" className={classes.labelText}>
              {isRequired ? `${label} *` : label}
            </Typography>
          )}
          {selectHasBeenFocused && (meta?.error || customError) && (
            <Typography color="error" className={classes.labelText}>
              {isRequired ? `${label} *` : label}
            </Typography>
          )}
        </Fragment>
      )}
      <Select
        aria-label="select-input"
        filterOption={() => true}
        menuPortalTarget={menuPortalTarget}
        styles={
          isRequired ? Object.assign({}, menuPortalStyles) : menuPortalStyles
        }
        isDisabled={isDisabled}
        isLoading={isLoading}
        isClearable
        backspaceRemovesValue
        cacheOptions
        options={options}
        defaultOptions={true}
        value={selectedValue ? selectedValue : null}
        placeholder={placeholder}
        onChange={value => handleChange(value)}
        onFocus={() => handleFocusSelectChange(true)}
        onInputChange={inputValue => handleInputChange(inputValue)}
        autoFocus={autoFocus}
        {...rest}
      />
      {!isDisabled && selectHasBeenFocused && (customError || meta?.error) && (
        <FormHelperText className={classes.errorFormHelperText}>
          {customError ?? meta.error}
        </FormHelperText>
      )}
    </Fragment>
  );
};

StyledAutoComplete.propTypes = {
  menuPortalTarget: PropTypes.any,
  isCreatable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  selectedValue: PropTypes.any,
  handleChange: PropTypes.func.isRequired,
  isRequired: PropTypes.bool,
  placeholder: PropTypes.node.isRequired,
  isLoading: PropTypes.bool,
  options: PropTypes.array,
  handleInputChange: PropTypes.func.isRequired,
  isMulti: PropTypes.bool,
  meta: PropTypes.any,
  defaultValue: PropTypes.any,
  label: PropTypes.any,
  customError: PropTypes.string,
  autoFocus: PropTypes.bool
};

export default StyledAutoComplete;
