import React from 'react';

import { useQuery } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';

import useAhaReview from 'hooks/useAhaReview';
import withOnlineAccessOnly from 'hocs/withOnlineAccessOnly';
import withAuthorization from 'hocs/withAuthorization';
import LoadingSpinner from 'components/common/LoadingSpinner';
import ErrorNotice from 'components/common/ErrorNotice';
import AhaDetailedViewEditor from 'components/ahas/AhaDetailedViewEditor';
import { GET_AHA_TEMPLATE } from 'graphql/aha';

const AhaReviewPage = () => {
  const { ahaReviewId } = useParams();
  const { ahaReview, loading: reviewIsLoading } = useAhaReview(ahaReviewId);

  const { data, loading: ahaTemplateIsLoading, error } = useQuery(
    GET_AHA_TEMPLATE,
    {
      variables: { id: ahaReview?.ahaTemplate?.id },
      skip: !ahaReview?.ahaTemplate?.id
    }
  );

  const ahaTemplate = data?.ahaTemplate;
  const isLoading = reviewIsLoading || ahaTemplateIsLoading;

  if (isLoading) {
    return <LoadingSpinner />;
  }
  if (error) {
    return <ErrorNotice />;
  }

  return (
    <AhaDetailedViewEditor
      ahaReview={ahaReview}
      ahaTemplate={ahaTemplate}
      isGlobal={false}
      isReadOnly={true}
      hasChanges={false}
      setAhaTemplate={() => {}}
      handleSaveAhaTemplate={() => {}}
      isLoading={isLoading}
      resetAhaTemplateChanges={() => {}}
      isLoadingUpdate={false}
      hasExternalChanges={false}
    />
  );
};

export default withOnlineAccessOnly(
  withAuthorization(AhaReviewPage, {
    personnelOnProject: true
  })
);
