import ErrorIcon from '@mui/icons-material/Error';
import CircleIcon from '@mui/icons-material/Brightness1';
import React from 'react';

export const scoreRangesOrderedBestToWorst = [
  { name: 'medium', icon: CircleIcon },
  { name: 'high', icon: ErrorIcon },
  { name: 'highest', icon: ErrorIcon }
];

const getScoreRange = score =>
  scoreRangesOrderedBestToWorst[
    Math.min(
      Math.max(Math.floor(score * scoreRangesOrderedBestToWorst.length), 0),
      scoreRangesOrderedBestToWorst.length - 1
    )
  ];

export const getScoreIcon = ({ score, theme }) => {
  if (typeof score !== 'number' || Number.isNaN(score)) {
    return undefined;
  }
  const range = getScoreRange(score);
  if (!range) {
    return undefined;
  }
  return React.createElement(range.icon, {
    opacity: score ? 1 : 0,
    htmlColor: theme?.palette?.scoreIndicator?.[range.name]
  });
};
