import { useSubscription } from '@apollo/react-hooks';

import { PROJECT_SUBSCRIPTION } from 'graphql/project';
import {
  TRADE_PARTNERS_ON_PROJECT_SUBSCRIPTION,
  GET_ALL_TRADE_PARTNERS_FOR_PROJECT
} from 'graphql/tradePartner';
import { PROJECT_WORKER_HOURS_SUBSCRIPTION } from 'graphql/tradePartnerMonthWork';
import {
  GET_ORIENTATIONS_ON_PROJECT_SUBSCRIPTION,
  GET_ALL_ORIENTATIONS_FOR_PROJECT,
  DELETED_ORIENTATIONS_SUBSCRIPTION
} from 'graphql/orientation';
import {
  TRADE_PARTNER_PERSONNELS_ON_PROJECT_SUBSCRIPTION,
  GET_ALL_TRADE_PARTNER_PERSONNEL_FOR_PROJECT
} from 'graphql/tradePartnerPersonnel';
import useRoles from 'hooks/useRoles';
import {
  MY_OBSERVATIONS_ON_PROJECT_SUBSCRIPTION,
  GET_MY_OBSERVATIONS_ON_PROJECT,
  OBSERVATIONS_ON_PROJECT_SUBSCRIPTION,
  GET_ALL_OBSERVATIONS_ON_PROJECT
} from 'graphql/observations';
import {
  GET_SAFE_PROJECT_SUBSCRIPTION,
  GET_SAFE_PROJECT_WITH_ALL_RELATIONS
} from 'graphql/safeProject';

const useProjectSubscriptions = projectId => {
  const { isAdminTypeRole } = useRoles();
  const isAdmin = isAdminTypeRole(projectId);
  const shouldSkip = !isAdmin || !projectId;

  useSubscription(PROJECT_SUBSCRIPTION, {
    skip: shouldSkip,
    variables: { projectId }
  });

  useSubscription(TRADE_PARTNERS_ON_PROJECT_SUBSCRIPTION, {
    skip: shouldSkip,
    variables: { projectId },
    onSubscriptionData: ({ client, subscriptionData }) => {
      const queryExists = !!client?.cache?.data?.data?.ROOT_QUERY?.[
        `tradePartnersOnProject({"projectId":"${projectId}"})`
      ];
      if (queryExists) {
        const { tradePartnersOnProject } = client.readQuery({
          query: GET_ALL_TRADE_PARTNERS_FOR_PROJECT,
          variables: { projectId }
        });

        const { tradePartnersOnProject: tradePartner } = subscriptionData.data;

        client.writeQuery({
          query: GET_ALL_TRADE_PARTNERS_FOR_PROJECT,
          variables: { projectId },
          data: {
            tradePartnersOnProject: [
              ...tradePartnersOnProject.filter(tp => tp.id !== tradePartner.id),
              tradePartner
            ]
          }
        });
      }
    }
  });

  useSubscription(PROJECT_WORKER_HOURS_SUBSCRIPTION, {
    skip: shouldSkip,
    variables: { projectId }
  });

  useSubscription(GET_ORIENTATIONS_ON_PROJECT_SUBSCRIPTION, {
    skip: shouldSkip,
    variables: { projectId },
    onSubscriptionData: ({ client, subscriptionData }) => {
      const queryExists = !!client?.cache?.data?.data?.ROOT_QUERY?.[
        `orientationsOnProject({"projectId":"${projectId}"})`
      ];
      if (queryExists) {
        const { orientationsOnProject } = client.readQuery({
          query: GET_ALL_ORIENTATIONS_FOR_PROJECT,
          variables: { projectId }
        });

        const {
          orientationsOnProject: orientationToUpsert
        } = subscriptionData.data;

        client.writeQuery({
          query: GET_ALL_ORIENTATIONS_FOR_PROJECT,
          variables: { projectId },
          data: {
            orientationsOnProject: [
              ...orientationsOnProject.filter(
                orientation => orientation.id !== orientationToUpsert.id
              ),
              orientationToUpsert
            ]
          }
        });
      }
    }
  });

  useSubscription(DELETED_ORIENTATIONS_SUBSCRIPTION, {
    skip: shouldSkip,
    onSubscriptionData: ({ client, subscriptionData }) => {
      const queryExists = !!client?.cache?.data?.data?.ROOT_QUERY?.[
        `orientationsOnProject({"projectId":"${projectId}"})`
      ];
      if (queryExists) {
        const { orientationsOnProject } = client.readQuery({
          query: GET_ALL_ORIENTATIONS_FOR_PROJECT,
          variables: { projectId }
        });

        const {
          deletedOrientations: orientationToRemove
        } = subscriptionData.data;

        client.writeQuery({
          query: GET_ALL_ORIENTATIONS_FOR_PROJECT,
          variables: { projectId },
          data: {
            orientationsOnProject: orientationsOnProject.filter(
              orientation => orientation.id !== orientationToRemove.id
            )
          }
        });
      }
    }
  });

  useSubscription(TRADE_PARTNER_PERSONNELS_ON_PROJECT_SUBSCRIPTION, {
    skip: shouldSkip,
    variables: { projectId },
    onSubscriptionData: ({ client, subscriptionData }) => {
      const queryExists = !!client?.cache?.data?.data?.ROOT_QUERY?.[
        `tradePartnerPersonnelsOnProject({"projectId":"${projectId}"})`
      ];
      if (queryExists) {
        const { tradePartnerPersonnelsOnProject } = client.readQuery({
          query: GET_ALL_TRADE_PARTNER_PERSONNEL_FOR_PROJECT,
          variables: { projectId }
        });

        const {
          tradePartnerPersonnelsOnProject: tradePartnerPersonnelsOnProjectFromSubscription
        } = subscriptionData.data;

        client.writeQuery({
          query: GET_ALL_TRADE_PARTNER_PERSONNEL_FOR_PROJECT,
          variables: { projectId },
          data: {
            tradePartnerPersonnelsOnProject: [
              ...tradePartnerPersonnelsOnProject.filter(
                tp =>
                  tp.id !== tradePartnerPersonnelsOnProjectFromSubscription.id
              ),
              tradePartnerPersonnelsOnProjectFromSubscription
            ]
          }
        });
      }
    }
  });

  // project personnel available subscriptions - people who aren't admins can access these
  useSubscription(MY_OBSERVATIONS_ON_PROJECT_SUBSCRIPTION, {
    skip: !projectId,
    variables: { projectId },
    onSubscriptionData: ({ client, subscriptionData }) => {
      const queryExists = !!client?.cache?.data?.data?.ROOT_QUERY?.[
        `myObservationsOnProject({"projectId":"${projectId}"})`
      ];

      if (queryExists) {
        const { myObservationsOnProject } = client.readQuery({
          query: GET_MY_OBSERVATIONS_ON_PROJECT,
          variables: { projectId }
        });

        const {
          myObservationsOnProject: myObservationsOnProjectFromSubscription
        } = subscriptionData.data;

        client.writeQuery({
          query: GET_MY_OBSERVATIONS_ON_PROJECT,
          variables: { projectId },
          data: {
            myObservationsOnProject: [
              ...myObservationsOnProject.filter(
                myObservation =>
                  myObservation.id !==
                  myObservationsOnProjectFromSubscription.id
              ),
              myObservationsOnProjectFromSubscription
            ]
          }
        });
      }
    }
  });

  useSubscription(OBSERVATIONS_ON_PROJECT_SUBSCRIPTION, {
    skip: !projectId,
    variables: { projectId },
    onSubscriptionData: ({ client, subscriptionData }) => {
      const queryExists = !!client?.cache?.data?.data?.ROOT_QUERY?.[
        `observationsOnProject({"projectId":"${projectId}"})`
      ];

      if (queryExists) {
        const { observationsOnProject } = client.readQuery({
          query: GET_ALL_OBSERVATIONS_ON_PROJECT,
          variables: { projectId }
        });

        const {
          observationOnProject: observationsOnProjectFromSubscription
        } = subscriptionData.data;

        client.writeQuery({
          query: GET_ALL_OBSERVATIONS_ON_PROJECT,
          variables: { projectId },
          data: {
            observationsOnProject: [
              ...observationsOnProject.filter(
                observation =>
                  observation?.id !== observationsOnProjectFromSubscription?.id
              ),
              observationsOnProjectFromSubscription
            ]
          }
        });
      }
    }
  });

  useSubscription(GET_SAFE_PROJECT_SUBSCRIPTION, {
    skip: !projectId,
    variables: { id: projectId },
    onSubscriptionData: ({ client, subscriptionData }) => {
      const queryExists =
        client?.cache?.data?.data?.ROOT_QUERY?.[
          `safeProject({"id":"${projectId}"})`
        ];

      if (queryExists) {
        const { safeProject } = client.readQuery({
          query: GET_SAFE_PROJECT_WITH_ALL_RELATIONS,
          variables: { id: projectId }
        });

        const {
          safeProject: safeProjectFromSubscription
        } = subscriptionData.data;

        client.writeQuery({
          query: GET_SAFE_PROJECT_WITH_ALL_RELATIONS,
          variables: { id: projectId },
          data: {
            safeProject: {
              ...safeProject,
              ...safeProjectFromSubscription
            }
          }
        });
      }
    }
  });
};

export default useProjectSubscriptions;
