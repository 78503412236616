import React, { useState } from 'react';
import AvatarDetailsContext from '../../context/avatars';
import PropTypes from 'prop-types';

const AvatarProvider = ({ children }) => {
  const [avatars, setAvatars] = useState({});

  const cacheAvatarUrl = avatarToCache => {
    setAvatars(Object.assign({}, avatars, avatarToCache));
  };

  return (
    <AvatarDetailsContext.Provider value={{ avatars, cacheAvatarUrl }}>
      {children}
    </AvatarDetailsContext.Provider>
  );
};

AvatarProvider.propTypes = {
  children: PropTypes.node
};

export default AvatarProvider;
