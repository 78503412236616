import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';

import StyledAutoComplete from 'shared/AutoComplete';
import { COMPANY_SEARCH } from 'graphql/company';

const CompanySelect = ({
  selectedCompanyField,
  handleChange,
  isDisabled,
  isRequired,
  ...rest
}) => {
  const { t } = useTranslation();
  const [inputValue, handleInputChange] = useState(null);
  const { data, loading } = useQuery(COMPANY_SEARCH, {
    variables: { search: inputValue, first: 100 },
    fetchPolicy: 'no-cache'
  });
  const options =
    data?.companySearch?.results?.map(result => result.document) ?? [];

  return (
    <StyledAutoComplete
      selectedValue={selectedCompanyField.input.value}
      meta={selectedCompanyField.meta}
      handleChange={handleChange}
      placeholder={t('companySelect.searchPlaceholder')}
      isRequired={isRequired}
      isDisabled={isDisabled}
      options={options.map(item => ({
        label: item.name,
        value: item.id,
        ...item
      }))}
      isLoading={loading}
      handleInputChange={handleInputChange}
      {...rest}
    />
  );
};

CompanySelect.propTypes = {
  selectedCompanyField: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool
};

export default CompanySelect;
