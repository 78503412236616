import React, { useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import MenuIcon from '@mui/icons-material/Menu';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, List, Drawer, IconButton, Container } from '@mui/material';

import useHelpAndSupport from 'hooks/useHelpAndSupport';
import useTutorials from 'hooks/useTutorials';
import StyledDialog from 'shared/Dialog';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import Introduction from '../Introduction';
import TutorialCategory from './TutorialCategory';

const useStyles = makeStyles(theme => ({
  list: { width: 300, marginTop: 64 },
  tutorialContent: { marginLeft: 300 },
  icon: { color: theme.palette.background.contrastText }
}));

const TutorialMenuList = () => {
  const classes = useStyles();
  const { selectedHelpSection } = useHelpAndSupport();
  const { titles } = useTutorials();

  return (
    <List component="nav" className={classes.list}>
      {titles.map((category, index) => (
        <TutorialCategory
          key={index}
          category={category}
          selectedHelpSection={selectedHelpSection}
        />
      ))}
    </List>
  );
};

const TutorialDialog = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [drawerIsOpen, toggleDrawer] = useState(false);
  const { isOpen, selectedHelpSection, toggleHelp } = useHelpAndSupport();
  const { tutorials, sections } = useTutorials();

  const handleClose = () => {
    toggleHelp(false);
  };

  let Content = selectedHelpSection
    ? sections[selectedHelpSection]
    : Introduction;

  const noDisplayMdUp = {
    display: {
      xs: 'block',
      sm: 'block',
      md: 'none',
      lg: 'none',
      xl: 'none'
    }
  };

  return (
    <Fragment>
      <StyledDialog
        PaperProps={{ 'data-testid': 'tutorialsDialog' }}
        fullScreen={true}
        isOpen={isOpen}
        handleClose={handleClose}
        disableBackdropClick={false}
        disableEscapeKeyDown={false}
        title={t('tutorialDialog.title')}
        titleMenu={
          <Grid item sx={noDisplayMdUp}>
            <IconButton
              onClick={() => toggleDrawer(!drawerIsOpen)}
              size="large">
              <MenuIcon className={classes.icon} />
            </IconButton>
          </Grid>
        }
        content={
          <Fragment>
            <Grid
              container
              direction="row"
              sx={{
                display: { xs: 'none', sm: 'none', md: 'block' }
              }}>
              <Grid item>
                <Drawer variant="permanent">
                  <TutorialMenuList />
                </Drawer>
              </Grid>
              <Grid item className={classes.tutorialContent}>
                <Container maxWidth="md">
                  <Content tutorials={tutorials} />
                </Container>
              </Grid>
            </Grid>
            <Grid container sx={noDisplayMdUp}>
              <Grid item>
                <Content tutorials={tutorials} />
              </Grid>
            </Grid>
          </Fragment>
        }
        actions={
          <StyledButtonPrimary
            onClick={handleClose}
            label={'Close'}
            data-testid="close-tutorials-button"
          />
        }
      />
      <Drawer
        variant="temporary"
        open={drawerIsOpen}
        onClose={() => toggleDrawer(false)}
        sx={noDisplayMdUp}>
        <TutorialMenuList />
      </Drawer>
    </Fragment>
  );
};

export default TutorialDialog;
