import { atom, useRecoilState } from 'recoil';

const DEFAULT_ROWS_PER_PAGE = 100;
const DEFAULT_FILTERS = {};

const defaultState = {
  first: DEFAULT_ROWS_PER_PAGE,
  skip: 0,
  filter: DEFAULT_FILTERS,
  search: '',
  hasActiveFilters: false
};

function hasActiveFilters(filterState) {
  let hasActiveFilters = false;

  if (typeof filterState === 'object') {
    for (const value of Object.values(filterState)) {
      if (value) {
        hasActiveFilters = true;
        break;
      }
    }
  }

  return hasActiveFilters;
}

const ahaGlobalEquipmentListStateAtom = atom({
  key: 'ahaGlobalEquipmentListState',
  default: defaultState
});

function useAhaGlobalEquipmentListState() {
  const [
    ahaGlobalEquipmentListState,
    setAhaGlobalEquipmentListState
  ] = useRecoilState(ahaGlobalEquipmentListStateAtom);

  function handleAhaGlobalEquipmentListStateChange(newState) {
    if (hasActiveFilters(newState.filter)) {
      newState.hasActiveFilters = true;
    } else {
      newState.hasActiveFilters = false;
    }

    setAhaGlobalEquipmentListState({
      ...ahaGlobalEquipmentListState,
      ...newState
    });
  }
  return [
    ahaGlobalEquipmentListState,
    { handleAhaGlobalEquipmentListStateChange }
  ];
}

export default useAhaGlobalEquipmentListState;
