import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import {
  Breadcrumbs,
  Typography,
  Tooltip,
  Link as MuiLink
} from '@mui/material';

import useActiveRoute from 'hooks/useActiveRoute';
import useRoles from 'hooks/useRoles';
import useProject from 'hooks/useProject';
import useCompany from 'hooks/useCompany';
import useTradePartner from 'hooks/useTradePartner';
import useTradePartnerPersonnel from 'hooks/useTradePartnerPersonnel';
import useOrientation from 'hooks/useOrientation';
import useAttendee from 'hooks/useAttendee';
import useIsOnline from 'store/onlineDetection';
import useSafeProject from 'hooks/useSafeProject';
import useAhaTemplate from 'hooks/useAhaTemplate';
import useAhaTemplateCategory from 'hooks/useAhaTemplateCategory';
import { formatDate } from 'utils/dateTime';

const ROOT_PATH = '/';

const useStyles = makeStyles(theme => ({
  root: { justifyContent: 'center', padding: '0 12px' },
  breadcrumbs: { color: theme.palette.secondary.contrastText },
  breadcrumbsOnDashboard: { color: '#fff' },
  breadcrumbsOnDashboardOffline: {
    color: theme.palette.secondary.contrastText
  },
  breadcrumbLinkTextOnDashboard: {
    fontSize: '0.875rem',
    color: '#fff'
  },
  breadcrumbLinkTextOnDashboardOffline: {
    color: theme.palette.secondary.contrastText
  },
  breadcrumbLinkText: {
    fontSize: '0.875rem',
    maxWidth: 200,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}));

const BreadCrumbTitle = ({ title, tooltipTitle }) => {
  const classes = useStyles();
  const { isActiveRoute } = useActiveRoute();
  const isDashboardPage = isActiveRoute('/projects/:projectId/dashboard');
  const { isOnline } = useIsOnline();

  return (
    <Tooltip title={tooltipTitle ?? ''}>
      <Typography
        color="textPrimary"
        className={classNames(
          classes.breadcrumbLinkText,
          {
            [`${classes.breadcrumbLinkTextOnDashboard}`]: isDashboardPage
          },
          {
            [`${classes.breadcrumbLinkTextOnDashboardOffline}`]:
              isDashboardPage && !isOnline
          }
        )}>
        {title}
      </Typography>
    </Tooltip>
  );
};

BreadCrumbTitle.propTypes = {
  tooltipTitle: PropTypes.string,
  title: PropTypes.string.isRequired
};

const Breadcrumb = ({ path, title, tooltipTitle, isReadOnly = false }) => {
  if (isReadOnly) {
    return <BreadCrumbTitle title={title} tooltipTitle={tooltipTitle} />;
  } else {
    return (
      <MuiLink component={RouterLink} to={path} underline="none">
        <BreadCrumbTitle title={title} tooltipTitle={tooltipTitle} />
      </MuiLink>
    );
  }
};

Breadcrumb.propTypes = {
  path: PropTypes.string.isRequired,
  tooltipTitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  isReadOnly: PropTypes.bool
};

const BreadcrumbNavigation = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    isEnterpriseAdmin,
    isAdminTypeRole,
    isSupportRole,
    isAhaAdmin
  } = useRoles();
  const { isActiveRoute, params } = useActiveRoute();
  const {
    projectId,
    companyId,
    tradePartnerId,
    tradePartnerPersonnelId,
    orientationId,
    attendeeId,
    ahaId,
    ahaReviewId,
    ahaTradePartnerCategoryId
  } = params;
  const isAdmin = isAdminTypeRole(projectId);
  const { project: projectAsAdmin } = useProject(projectId, !isAdmin);
  const { safeProject } = useSafeProject(projectId);
  const { company } = useCompany(companyId, !isAdmin);
  const { tradePartner } = useTradePartner(tradePartnerId, !isAdmin);
  const { tradePartnerPersonnel } = useTradePartnerPersonnel({
    tradePartnerPersonnelId,
    shouldSkip: !isAdmin
  });
  const { orientation } = useOrientation(orientationId, !isAdmin);
  const { attendee } = useAttendee(attendeeId, !isAdmin);
  const isDashboardPage = isActiveRoute('/projects/:projectId/dashboard');
  const project = isAdmin ? projectAsAdmin : safeProject;
  const { isOnline } = useIsOnline();
  const { ahaTemplate } = useAhaTemplate(ahaId);
  const { ahaTradePartnerCategory } = useAhaTemplateCategory(
    ahaTradePartnerCategoryId
  );

  return (
    <div className={classes.root}>
      {isAdmin && (
        <Breadcrumbs
          separator="/"
          className={classNames(
            classes.breadcrumbs,
            {
              [`${classes.breadcrumbsOnDashboard}`]: isDashboardPage
            },
            {
              [`${classes.breadcrumbsOnDashboardOffline}`]:
                isDashboardPage && !isOnline
            }
          )}>
          <Breadcrumb
            path={ROOT_PATH}
            title={t('navigation.breadcrumbs.home')}
          />
          {isSupportRole && isActiveRoute('/transfer', false) && (
            <Breadcrumb
              path={'/transfer'}
              title={t('navigation.breadcrumbs.transferPersonnel')}
            />
          )}
          {isEnterpriseAdmin && isActiveRoute('/projects', false) && (
            <Breadcrumb
              path={'/projects'}
              title={t('navigation.breadcrumbs.allProjects')}
            />
          )}
          {isEnterpriseAdmin && isActiveRoute('/companies', false) && (
            <Breadcrumb
              path={'/companies'}
              title={t('navigation.breadcrumbs.companies')}
            />
          )}
          {isEnterpriseAdmin &&
            isActiveRoute('/companies/:companyId', false) && (
              <Breadcrumb
                path={`/companies/${companyId}`}
                title={company?.name ?? t('navigation.breadcrumbs.company')}
              />
            )}
          {isAhaAdmin && isActiveRoute('/ahas', false) && (
            <Breadcrumb
              path={'/ahas'}
              title={t('navigation.breadcrumbs.ahas')}
            />
          )}
          {isAhaAdmin && isActiveRoute('/ahas/:ahaId/edit', false) && (
            <Breadcrumb
              path={`/ahas/${ahaId}/edit`}
              title={ahaTemplate?.type ?? 'AHA'}
            />
          )}
          {isActiveRoute('/projects/:projectId', false) && project && (
            <Breadcrumb
              path={`/projects/${projectId}`}
              title={project?.name ?? t('navigation.breadcrumbs.project')}
              tooltipTitle={
                project?.name ?? t('navigation.breadcrumbs.project')
              }
            />
          )}
          {isActiveRoute('/projects/:projectId/personnel', false) &&
            project && (
              <Breadcrumb
                path={`/projects/${projectId}/personnel`}
                title={t('navigation.breadcrumbs.personnel')}
              />
            )}
          {isActiveRoute(
            '/projects/:projectId/personnel/:tradePartnerPersonnelId',
            false
          ) && (
            <Breadcrumb
              path={`/projects/${projectId}/personnel/${tradePartnerPersonnelId}`}
              title={tradePartnerPersonnel?.personnel?.fullName ?? ''}
              tooltipTitle={tradePartnerPersonnel?.personnel?.fullName ?? ''}
            />
          )}
          {isActiveRoute('/projects/:projectId/dashboard', false) &&
            project && (
              <Breadcrumb
                path={`/projects/${projectId}/dashboard`}
                title={t('navigation.breadcrumbs.dashboard')}
              />
            )}
          {isActiveRoute('/projects/:projectId/projectDetails', false) &&
            project && (
              <Breadcrumb
                path={`/projects/${projectId}/projectDetails`}
                title={t('navigation.breadcrumbs.projectDetails')}
              />
            )}
          {isActiveRoute('/projects/:projectId/permissions', false) && (
            <Breadcrumb
              path={`/projects/${projectId}/permissions`}
              title={t('navigation.breadcrumbs.permissions')}
            />
          )}
          {isActiveRoute('/projects/:projectId/trade-partners', false) && (
            <Breadcrumb
              path={`/projects/${projectId}/trade-partners`}
              title={t('navigation.breadcrumbs.tradePartners')}
            />
          )}
          {isActiveRoute(
            '/projects/:projectId/trade-partners/:tradePartnerId',
            false
          ) && (
            <Breadcrumb
              path={`/projects/${projectId}/trade-partners/${tradePartnerId}`}
              title={
                tradePartner?.name ?? t('navigation.breadcrumbs.tradePartner')
              }
              tooltipTitle={
                tradePartner?.name ?? t('navigation.breadcrumbs.tradePartner')
              }
            />
          )}
          {isActiveRoute(
            '/projects/:projectId/trade-partners/:tradePartnerId/personnel/:tradePartnerPersonnelId',
            false
          ) && (
            <Breadcrumb
              path={`/projects/${projectId}/trade-partners/${tradePartnerId}/personnel/${tradePartnerPersonnelId}`}
              title={tradePartnerPersonnel?.personnel?.fullName ?? ''}
              tooltipTitle={tradePartnerPersonnel?.personnel?.fullName ?? ''}
            />
          )}
          {isActiveRoute('/projects/:projectId/orientations', false) && (
            <Breadcrumb
              path={`/projects/${projectId}/orientations`}
              title={t('navigation.breadcrumbs.orientations')}
            />
          )}
          {isActiveRoute(
            '/projects/:projectId/orientations/:orientationId',
            false
          ) && (
            <Breadcrumb
              path={`/projects/${projectId}/orientations/${orientationId}`}
              title={
                orientation?.startDateTime
                  ? formatDate(orientation.startDateTime)
                  : t('navigation.breadcrumbs.orientation')
              }
              tooltipTitle={
                orientation?.startDateTime
                  ? formatDate(orientation.startDateTime)
                  : t('navigation.breadcrumbs.orientation')
              }
            />
          )}
          {isActiveRoute(
            '/projects/:projectId/orientations/:orientationId/attendees',
            false
          ) && (
            <Breadcrumb
              path={`/projects/${projectId}/orientations/${orientationId}/attendees`}
              title={t('navigation.breadcrumbs.orientationAttendees')}
            />
          )}
          {isActiveRoute(
            '/projects/:projectId/orientations/:orientationId/attendees/:attendeeId',
            false
          ) && (
            <Breadcrumb
              path={`/projects/${projectId}/orientations/${orientationId}/attendees/${attendeeId}`}
              title={
                attendee?.fullName ??
                t('navigation.breadcrumbs.orientationAttendee')
              }
            />
          )}
          {isActiveRoute('/projects/:projectId/worker-hours', false) && (
            <Breadcrumb
              path={`/projects/${projectId}/worker-hours`}
              title={t('navigation.breadcrumbs.tradePartnerWorkerHours')}
            />
          )}
          {isActiveRoute('/unlinkedHPEmployees', false) && (
            <Breadcrumb
              path={`/unlinkedHPEmployees`}
              title={t('navigation.breadcrumbs.unlinkedEmployees')}
            />
          )}
          {isActiveRoute(
            '/unlinkedHPEmployees/projects/:projectId/personnel/:tradePartnerPersonnelId',
            false
          ) && (
            <Breadcrumb
              path={`/unlinkedHPEmployees/projects/${projectId}/personnel/${tradePartnerPersonnelId}`}
              title={tradePartnerPersonnel?.personnel?.fullName ?? ''}
            />
          )}
          {isActiveRoute('/projects/:projectId/observations', false) && (
            <Breadcrumb
              path={`/projects/${projectId}/observations`}
              title={t('navigation.breadcrumbs.observations')}
            />
          )}
          {isActiveRoute('/projects/:projectId/ahas', false) && (
            <Breadcrumb
              path={`/projects/${projectId}/ahas`}
              title={t('navigation.breadcrumbs.ahas')}
            />
          )}
          {isActiveRoute(
            '/projects/:projectId/ahas/hensel-phelps-aha/:ahaId',
            false
          ) &&
            ahaTemplate && (
              <Breadcrumb
                path={`/projects/${projectId}/ahas/hensel-phelps-aha/${ahaId}`}
                title={ahaTemplate.type ?? t('navigation.breadcrumbs.aha')}
                tooltipTitle={
                  ahaTemplate.type ?? t('navigation.breadcrumbs.aha')
                }
              />
            )}
          {isActiveRoute(
            '/projects/:projectId/ahas/hensel-phelps-aha/:ahaId/edit',
            false
          ) &&
            ahaTemplate && (
              <Breadcrumb
                isReadOnly
                path={`/projects/${projectId}/ahas/hensel-phelps-aha/${ahaId}/edit`}
                title={t('navigation.breadcrumbs.editAha')}
              />
            )}
          {isActiveRoute(
            '/projects/:projectId/ahas/trade-partner-aha/:ahaTradePartnerCategoryId/files',
            false
          ) &&
            ahaTradePartnerCategory && (
              <Breadcrumb
                path={`/projects/${projectId}/ahas/trade-partner-aha/${ahaTradePartnerCategory.id}/files`}
                title={
                  ahaTradePartnerCategory.name ??
                  t('navigation.breadcrumbs.tradePartnerAha')
                }
                tooltipTitle={
                  ahaTradePartnerCategory.name ??
                  t('navigation.breadcrumbs.tradePartnerAha')
                }
              />
            )}
          {isActiveRoute(
            '/projects/:projectId/ahas/trade-partner-aha/:ahaTradePartnerCategoryId/files',
            false
          ) &&
            ahaTradePartnerCategory && (
              <Breadcrumb
                path={`/projects/${projectId}/ahas/trade-partner-aha/${ahaTradePartnerCategory.id}/files`}
                isReadOnly
                title={t('navigation.breadcrumbs.ahaFiles')}
              />
            )}
          {isActiveRoute(
            '/projects/:projectId/ahas/hensel-phelps-aha/:ahaId/reviews/:ahaReviewId',
            false
          ) && (
            <Breadcrumb
              path={`/projects/${projectId}/ahas/hensel-phelps-aha/${ahaId}/reviews/${ahaReviewId}`}
              title={t('navigation.breadcrumbs.ahaReview')}
            />
          )}
          {isActiveRoute('/projects/:projectId/safety-hours', false) && (
            <Breadcrumb
              path={`/projects/${projectId}/safety-hours`}
              title={t('navigation.breadcrumbs.safetyHours')}
            />
          )}
          {isActiveRoute(
            '/projects/:projectId/pre-incident-indicators',
            false
          ) && (
            <Breadcrumb
              path={`/projects/${projectId}/pre-incident-indicators`}
              title={t('navigation.breadcrumbs.preIncidentIndicators')}
            />
          )}
        </Breadcrumbs>
      )}
      {!isAdmin && (
        <Breadcrumbs
          separator="/"
          className={classNames(classes.breadcrumbs, {
            [`${classes.breadcrumbsOnDashboard}`]: isDashboardPage
          })}>
          <Breadcrumb
            path={ROOT_PATH}
            title={t('navigation.breadcrumbs.home')}
          />
          {isActiveRoute('/projects/:projectId', false) && project && (
            <Breadcrumb
              path={`/projects/${projectId}`}
              title={project?.name ?? t('navigation.breadcrumbs.project')}
              tooltipTitle={
                project?.name ?? t('navigation.breadcrumbs.project')
              }
            />
          )}
          {isActiveRoute('/projects/:projectId/observations', false) && (
            <Breadcrumb
              path={`/projects/${projectId}/observations`}
              title={t('navigation.breadcrumbs.observations')}
            />
          )}

          {isActiveRoute('/projects/:projectId/ahas', false) && (
            <Breadcrumb
              path={`/projects/${projectId}/ahas`}
              title={t('navigation.breadcrumbs.ahas')}
            />
          )}
          {isActiveRoute(
            '/projects/:projectId/ahas/hensel-phelps-aha/:ahaId',
            false
          ) &&
            ahaTemplate && (
              <Breadcrumb
                path={`/projects/${projectId}/ahas/hensel-phelps-aha/${ahaId}`}
                title={ahaTemplate.type ?? t('navigation.breadcrumbs.aha')}
                tooltipTitle={
                  ahaTemplate.type ?? t('navigation.breadcrumbs.aha')
                }
              />
            )}
          {isActiveRoute(
            '/projects/:projectId/ahas/hensel-phelps-aha/:ahaId/edit',
            false
          ) &&
            ahaTemplate && (
              <Breadcrumb
                isReadOnly
                path={`/projects/${projectId}/ahas/hensel-phelps-aha/${ahaId}/edit`}
                title={t('navigation.breadcrumbs.editAha')}
              />
            )}
          {isActiveRoute(
            '/projects/:projectId/ahas/trade-partner-aha/:ahaTradePartnerCategoryId/files',
            false
          ) &&
            ahaTradePartnerCategory && (
              <Breadcrumb
                path={`/projects/${projectId}/ahas/trade-partner-aha/${ahaTradePartnerCategory.id}/files`}
                title={
                  ahaTradePartnerCategory.name ??
                  t('navigation.breadcrumbs.tradePartnerAha')
                }
                tooltipTitle={
                  ahaTradePartnerCategory.name ??
                  t('navigation.breadcrumbs.tradePartnerAha')
                }
              />
            )}
          {isActiveRoute(
            '/projects/:projectId/ahas/trade-partner-aha/:ahaTradePartnerCategoryId/files',
            false
          ) &&
            ahaTradePartnerCategory && (
              <Breadcrumb
                path={`/projects/${projectId}/ahas/trade-partner-aha/${ahaTradePartnerCategory.id}/files`}
                isReadOnly
                title={t('navigation.breadcrumbs.ahaFiles')}
              />
            )}
          {isActiveRoute(
            '/projects/:projectId/ahas/hensel-phelps-aha/:ahaId/reviews/:ahaReviewId',
            false
          ) && (
            <Breadcrumb
              path={`/projects/${projectId}/ahas/hensel-phelps-aha/${ahaId}/reviews/${ahaReviewId}`}
              title={t('navigation.breadcrumbs.ahaReview')}
            />
          )}
        </Breadcrumbs>
      )}
    </div>
  );
};

BreadcrumbNavigation.propTypes = {
  location: PropTypes.any
};

export default BreadcrumbNavigation;
