import React from 'react';
import { Grid, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import VideoPlayer from '../../VideoPlayer';

const useStyles = makeStyles(theme => ({
  subTitle: { fontSize: '0.875rem' },
  title: { fontSize: '1.5rem' }
}));

const EnterWorkerHours = () => {
  const classes = useStyles();

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography className={classes.subTitle} color="textSecondary">
          Worker Hours Tutorial
        </Typography>
        <Typography className={classes.title} color="textPrimary">
          Enter Worker Hours
        </Typography>
      </Grid>
      <Grid item>
        <Typography color="textPrimary">
          Enter trade partners&apos; worker hours on a per-month basis.
        </Typography>
      </Grid>
      <Grid item>
        <Typography color="textPrimary">Entering worker hours:</Typography>
        <VideoPlayer
          data-testid="enter-worker-hours-video"
          url="/tutorials/workerHours/EnterWorkerHours.mp4"
          tracks={[
            {
              kind: 'subtitles',
              src: '/tutorials/workerHours/EnterWorkerHours.en.vtt',
              srcLang: 'en',
              default: true
            }
          ]}
        />
      </Grid>
      <Grid item>
        <Typography color="textPrimary">
          To select a different month:
        </Typography>
        <VideoPlayer
          data-testid="enter-past-worker-hours-video"
          url="/tutorials/workerHours/EnterWorkerHoursPast.mp4"
          tracks={[
            {
              kind: 'subtitles',
              src: '/tutorials/workerHours/EnterWorkerHoursPast.en.vtt',
              srcLang: 'en',
              default: true
            }
          ]}
        />
      </Grid>
    </Grid>
  );
};

export default EnterWorkerHours;
