import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CheckIcon from '@mui/icons-material/CheckCircle';
import ClearIcon from '@mui/icons-material/Clear';
import { Grid, Typography, Tooltip } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  active: { color: theme.palette.success.main },
  icon: { fontSize: '1rem', marginTop: 4 },
  label: { fontSize: '0.875rem' }
}));

const ActiveOrInactiveStatusContent = ({ isActive }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Tooltip title={isActive ? t('status.active') : t('status.inactive')}>
      <Grid container alignItems="center" direction="row" spacing={1}>
        <Grid item className={isActive ? classes.active : ''}>
          {isActive && (
            <CheckIcon
              classes={{ root: classes.icon }}
              data-testid="active-icon"
            />
          )}
          {!isActive && (
            <ClearIcon
              classes={{ root: classes.icon }}
              data-testid="inactive-icon"
            />
          )}
        </Grid>
        <Grid
          item
          sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' } }}>
          <Typography className={classes.label}>
            {isActive ? t('status.active') : t('status.inactive')}
          </Typography>
        </Grid>
      </Grid>
    </Tooltip>
  );
};

ActiveOrInactiveStatusContent.propTypes = {
  isActive: PropTypes.any
};

export default ActiveOrInactiveStatusContent;
