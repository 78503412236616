import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useForm, useField } from 'react-final-form-hooks';
import { useMutation } from '@apollo/react-hooks';
import makeStyles from '@mui/styles/makeStyles';
import { Grid } from '@mui/material';

import useToast from 'hooks/useToast';
import StyledInput from 'shared/Input';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import StyledButtonSecondary from 'shared/Buttons/ButtonSecondary';
import { UPDATE_PROJECT } from 'graphql/project';

const useStyles = makeStyles(theme => ({
  form: { width: '100%' },
  button: {
    padding: 0,
    borderRadius: 16,
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1.5),
    [theme.breakpoints.down('md')]: { marginTop: 0.5 }
  },
  buttonText: {
    fontSize: '0.75rem',
    fontWeight: 'bold',
    padding: '5px 15px'
  },
  buttonContainer: {
    justifyContent: 'flex-end'
  }
}));

const UpdateProjectForm = ({ project, toggleEditing }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [updateProject, { loading }] = useMutation(UPDATE_PROJECT);
  const { displayToast } = useToast();

  const { form, values, pristine } = useForm({
    /* istanbul ignore next */
    onSubmit: () => {}, // this function required for useForm but is not used
    initialValues: {
      name: project.name || '',
      streetAndNumber: project.location.streetAndNumber ?? '',
      city: project.location.city ?? '',
      stateOrProvince: project.location.stateOrProvince ?? '',
      zipOrPostalCode: project.location.zipOrPostalCode ?? ''
    }
  });

  const onSubmit = () => {
    updateProject({
      variables: {
        id: project.id,
        name: values.name,
        location: {
          display: `${values.streetAndNumber} ${values.city}, ${values.stateOrProvince} ${values.zipOrPostalCode}`,
          streetAndNumber: values.streetAndNumber,
          city: values.city,
          stateOrProvince: values.stateOrProvince,
          zipOrPostalCode: values.zipOrPostalCode
        }
      }
    })
      .then(({ data: { updateProject: updatedProject } }) => {
        displayToast(
          t('projectDashboardPage.toasts.update.success', {
            projectName: updatedProject.name
          }),
          'success'
        );
        handleClose();
      })
      .catch(error => {
        console.error('Request access Error: ', error);
        displayToast(t('projectDashboardPage.toasts.update.error'), 'error');
      });
  };

  const validate = value => {
    return value ? undefined : 'This field is required.';
  };

  const name = useField('name', form, validate);
  const streetAndNumber = useField('streetAndNumber', form, validate);
  const city = useField('city', form, validate);
  const stateOrProvince = useField('stateOrProvince', form, validate);
  const zipOrPostalCode = useField('zipOrPostalCode', form, validate);

  function handleClose() {
    form.reset();
    toggleEditing(false);
  }

  const canSubmit = () => {
    const { error, invalid } = form.getState();
    return !(pristine || invalid || loading || error);
  };

  return (
    <div>
      <form className={classes.form} data-testid="edit-project-info-form">
        <Grid container spacing={2} direction="column">
          <Grid item>
            <Grid container direction="row" spacing={1}>
              <Grid item xs={12}>
                <StyledInput
                  required
                  disabled={loading}
                  fullWidth
                  label={t(
                    'projectDashboardPage.updateProjectForm.fieldLabels.name'
                  )}
                  input={name.input}
                  meta={name.meta}
                  multiline={true}
                />
              </Grid>
              <Grid item xs={12}>
                <StyledInput
                  required
                  disabled={loading}
                  fullWidth
                  label={t(
                    'projectDashboardPage.updateProjectForm.fieldLabels.address'
                  )}
                  input={streetAndNumber.input}
                  meta={streetAndNumber.meta}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <StyledInput
                  required
                  disabled={loading}
                  fullWidth
                  label={t(
                    'projectDashboardPage.updateProjectForm.fieldLabels.city'
                  )}
                  input={city.input}
                  meta={city.meta}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <StyledInput
                  required
                  disabled={loading}
                  fullWidth
                  label={t(
                    'projectDashboardPage.updateProjectForm.fieldLabels.state'
                  )}
                  input={stateOrProvince.input}
                  meta={stateOrProvince.meta}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <StyledInput
                  required
                  disabled={loading}
                  fullWidth
                  label={t(
                    'projectDashboardPage.updateProjectForm.fieldLabels.zip'
                  )}
                  input={zipOrPostalCode.input}
                  meta={zipOrPostalCode.meta}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container className={classes.buttonContainer}>
              <Grid item>
                <StyledButtonSecondary
                  onClick={handleClose}
                  disabled={loading}
                  label={t(
                    'projectDashboardPage.updateProjectForm.cancelButton'
                  )}
                />
              </Grid>

              <Grid item>
                <StyledButtonPrimary
                  data-testid="update-project-submit"
                  disabled={!canSubmit()}
                  onClick={onSubmit}
                  label={t(
                    'projectDashboardPage.updateProjectForm.submitButton'
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

UpdateProjectForm.propTypes = {
  project: PropTypes.object.isRequired,
  toggleEditing: PropTypes.func
};

export default UpdateProjectForm;
