import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useField, useForm } from 'react-final-form-hooks';
import { useMutation } from '@apollo/react-hooks';
import { useParams, useHistory } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography } from '@mui/material';

import useFormValidation from 'hooks/useFormValidation';
import useSafeProject from 'hooks/useSafeProject';
import useToast from 'hooks/useToast';
import StyledDialog from 'shared/Dialog';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import StyledButtonSecondary from 'shared/Buttons/ButtonSecondary';
import StyledInput from 'shared/Input';
import ScopeIndicator from 'components/ahas/AhaScopeIndicator';
import { generateTransactionKey } from 'utils';
import { CREATE_PROJECT_TEMPLATE } from 'graphql/aha/projectTemplate';
import { CREATE_GLOBAL_AHA_TEMPLATE } from 'graphql/aha/globalTemplate';

const useStyles = makeStyles(theme => ({
  instructions: { marginBottom: theme.spacing(3) }
}));

const SaveAsDialog = ({
  saveAsDialogIsOpen,
  toggleSaveAsDialog,
  ahaTemplate,
  isGlobal
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { projectId } = useParams();
  const { safeProject } = useSafeProject(projectId);
  const { isRequired } = useFormValidation();
  const { displayToast } = useToast();
  const [transactionKey, setTransactionKey] = useState(
    generateTransactionKey()
  );

  const [
    createAhaProjectTemplate,
    { loading: isCreateProjectTemplateLoading }
  ] = useMutation(CREATE_PROJECT_TEMPLATE);

  const [
    createGlobalAhaTemplate,
    { loading: isCreateGlobalTemplateLoading }
  ] = useMutation(CREATE_GLOBAL_AHA_TEMPLATE);

  const isLoading =
    isCreateProjectTemplateLoading || isCreateGlobalTemplateLoading;

  const { form, pristine, values } = useForm({
    /* istanbul ignore next */
    onSubmit: () => {}, // this function required for useForm but is not used
    initialValues: {
      name: ahaTemplate?.type
    }
  });

  const nameField = useField('name', form, isRequired);

  const canSubmit = () => {
    const { error, invalid } = form.getState();
    return !(invalid || error || pristine);
  };

  const handleCreateProjectAhaTemplate = () => {
    return createAhaProjectTemplate({
      variables: {
        input: {
          contractor: 'HENSEL PHELPS',
          location: safeProject.name,
          notes: ahaTemplate?.ahaProjectTemplate?.notes ?? '',
          projectId: projectId,
          templateIds: ahaTemplate?.id,
          transactionKey: transactionKey,
          templateType: values.name ?? ahaTemplate?.type
        }
      }
    });
  };

  const handleCreateGlobalAhaTemplate = () => {
    return createGlobalAhaTemplate({
      variables: {
        input: {
          ahaGlobalTemplateCategoryId:
            ahaTemplate?.ahaGlobalTemplateCategory?.id,
          templateIds: ahaTemplate?.id,
          transactionKey: transactionKey,
          type: values.name ?? ahaTemplate?.type
        }
      }
    });
  };

  const handleSubmit = () => {
    const createTemplate = isGlobal
      ? handleCreateGlobalAhaTemplate
      : handleCreateProjectAhaTemplate;

    createTemplate()
      .then(response => {
        displayToast(t('saveAsDialog.copyTemplate.toasts.success'), 'success');
        handleClose();
        history.push(
          isGlobal
            ? `/ahas/${response.data.createGlobalAhaTemplate?.id}?view=summary`
            : `/projects/${projectId}/ahas/hensel-phelps-aha/${response.data.createAhaProjectTemplate?.ahaTemplate?.id}?view=summary`
        );
      })
      .catch(error => {
        console.error('Copy AHA Error: ', error);
        displayToast(t('saveAsDialog.copyAha.toasts.error'), 'error');
      });
  };

  const handleClose = () => {
    form.reset();
    setTransactionKey(generateTransactionKey());
    toggleSaveAsDialog(false);
  };

  return (
    <StyledDialog
      maxWidth="sm"
      title={t('copyAha.title')}
      isOpen={saveAsDialogIsOpen}
      handleClose={handleClose}
      isLoading={isLoading}
      content={
        <Grid container>
          <Grid item xs={12}>
            <ScopeIndicator isGlobal={isGlobal} />
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.instructions}>
              {t('copyAha.instructions')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <StyledInput
              label={t('copyAha.field.name')}
              input={nameField.input}
              meta={nameField.meta}
              disabled={isLoading}
              autoFocus={true}
              required
            />
          </Grid>
        </Grid>
      }
      actions={
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <StyledButtonSecondary
                  label={t('copyAha.actions.cancelButton')}
                  disabled={isLoading}
                  onClick={handleClose}
                />
              </Grid>
              <Grid item>
                <StyledButtonPrimary
                  label={t('copyAha.actions.submitButton')}
                  disabled={isLoading || !canSubmit()}
                  onClick={handleSubmit}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
    />
  );
};

SaveAsDialog.propTypes = {
  saveAsDialogIsOpen: PropTypes.bool,
  toggleSaveAsDialog: PropTypes.func,
  ahaTemplate: PropTypes.object,
  isGlobal: PropTypes.bool
};

export default SaveAsDialog;
