import React from 'react';
import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

import VideoPlayer from '../../VideoPlayer';

const useStyles = makeStyles(theme => ({
  subTitle: { fontSize: '0.875rem' },
  title: { fontSize: '1.5rem' },
  notice: {
    borderLeft: '6px solid' + theme.palette.secondary.dark,
    backgroundColor: theme.palette.secondary.main,
    padding: theme.spacing(2)
  },
  accessNoticeTitle: { fontSize: '0.875rem', fontWeight: 'bold' },
  accessNoticeContent: { fontSize: '0.75rem' }
}));

const AddFlag = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography
          className={classes.subTitle}
          color="textSecondary"
          component="h3">
          {t('tutorials.personnel.pageTitle')}
        </Typography>
        <Typography
          className={classes.title}
          color="textPrimary"
          component="h4">
          {t('tutorials.personnel.addFlag.menuTitle')}
        </Typography>
      </Grid>
      <Grid item>
        <Typography color="textPrimary">
          {t('tutorials.personnel.addFlag.description')}
        </Typography>
      </Grid>
      <Grid item>
        <VideoPlayer
          data-testid="add-flag-video"
          url="/tutorials/personnel/AddFlag.mp4"
          tracks={[
            {
              kind: 'subtitles',
              src: '/tutorials/personnel/AddFlag.en.vtt',
              srcLang: 'en',
              default: true
            }
          ]}
        />
      </Grid>
    </Grid>
  );
};

export default AddFlag;
