import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import withQueryParams from 'react-router-query-params';
import { useTranslation } from 'react-i18next';
import { AgGridReact } from 'ag-grid-react';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import makeStyles from '@mui/styles/makeStyles';
import {
  Typography,
  Grid,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Button
} from '@mui/material';

import useSettings from 'hooks/useSettings';
import StyledButtonMuted from 'shared/Buttons/ButtonMuted';
import NumericEditor from './NumericEditor';
import 'ag-grid-community/styles/ag-grid.css';
import 'constants/themes/ag-grid-balham/light.scss';
import 'constants/themes/ag-grid-balham/dark.scss';
import { DARK_THEME } from 'constants/settings';

const DEFAULT_STATUS_VALUE = 'active';
const ALLOWED_STATUS_VALUES = [DEFAULT_STATUS_VALUE, 'inactive', 'all'];

const useStyles = makeStyles(theme => ({
  gridContainer: {
    height: 'calc(100vh - 330px)',
    width: '100%',
    borderRadius: 4,
    [theme.breakpoints.down('md')]: { height: 'calc(100vh - 370px)' }
  },
  filterText: { marginRight: theme.spacing(2), fontSize: '0.875rem' },
  labelText: {
    fontSize: '0.875rem',
    color: theme.palette.secondary.contrastText,
    marginBottom: -4
  },
  exportButton: {
    [theme.breakpoints.down('lg')]: { marginBottom: theme.spacing(1) }
  },
  clearButton: {
    height: '100%',
    textTransform: 'none',
    backgroundColor: 'buttonface'
  }
}));

let gridApi;
let statusType = 'active';
const WorkerHoursTable = ({
  project,
  workerHourRows,
  editableRows,
  setEditableRows,
  isEditing,
  toggleEditing,
  isLoading,
  queryParams,
  setQueryParams,
  updateTotalHours
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { status: statusParam } = queryParams;
  const [status, setStatus] = useState(statusParam);
  const { settings } = useSettings();

  const columnDefs = [
    {
      headerName: 'Trade Partner Name',
      field: 'name',
      sortable: true,
      sort: 'asc',
      filter: true,
      resizable: true
    },
    {
      headerName: 'Worker Hours (MTD)',
      field: 'hours',
      sortable: true,
      filter: 'agNumberColumnFilter',
      resizable: true,
      editable: true,
      cellEditor: 'numericEditor',
      singleClickEdit: true
    },
    {
      headerName: 'Status',
      field: 'isActive',
      sortable: true,
      filter: false,
      editable: false,
      resizable: true,
      cellRenderer: function(params) {
        return (
          <Fragment>
            <Typography>
              {params.value === true ? 'Active' : 'Inactive'}
            </Typography>
          </Fragment>
        );
      }
    },
    {
      headerName: 'Clear',
      field: 'hours',
      sortable: false,
      filter: false,
      editable: false,
      resizable: true,
      cellRenderer: function(params) {
        return (
          <Fragment>
            {params.value && (
              <Button
                className={classes.clearButton}
                onClick={() => params.setValue('')}>
                Clear
              </Button>
            )}
          </Fragment>
        );
      }
    }
  ];

  const handleStatusChange = event => {
    setStatus(event.target.value);
    externalFilterChanged(event.target.value);
    setQueryParams({ status: event.target.value });
  };

  const onGridReady = params => {
    gridApi = params.api;
    gridApi.sizeColumnsToFit();
    if (statusParam) {
      externalFilterChanged(statusParam);
    }
  };

  const externalFilterChanged = newValue => {
    statusType = newValue;
    gridApi.onFilterChanged();
  };

  const isExternalFilterPresent = () => {
    return statusType !== 'all';
  };
  const doesExternalFilterPass = node => {
    switch (statusType) {
      case 'inactive':
        return node.data.isActive === false;
      case 'active':
        return node.data.isActive === true;
      default:
        return true;
    }
  };

  const handleChange = event => {
    const rowChanged = event.newValue !== event.oldValue;
    if (rowChanged) {
      if (!isEditing) {
        toggleEditing(true);
      }
      const editedRow = event.data;
      updateTotalHours({
        hours:
          editedRow.hours && editedRow.hours !== ''
            ? parseFloat(editedRow.hours)
            : 0,
        id: editedRow.id
      });

      const exists = editableRows.some(row => row.id === editedRow.id);
      if (exists) {
        setEditableRows(
          editableRows.map(row => {
            if (row.id !== editedRow.id) {
              return row;
            }
            return {
              ...row,
              ...editedRow
            };
          })
        );
      } else {
        setEditableRows(editableRows.concat([editedRow]));
      }
    }
  };

  const onExport = () => {
    gridApi.exportDataAsCsv({
      fileName: `${project?.name}_${project.number}_worker_hours`,
      columnKeys: ['name', 'hours', 'isActive']
    });
  };

  const labels = [
    { value: 'active', label: t('projectWorkerHoursTable.status.active') },
    { value: 'inactive', label: t('projectWorkerHoursTable.status.inactive') },
    { value: 'all', label: t('projectWorkerHoursTable.status.all') }
  ];

  return (
    <Grid container direction="column">
      <Grid item>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <FormControl
              variant="standard"
              component="fieldset"
              className={classes.formControl}>
              <Grid container direction="row" alignItems="center">
                <Grid item>
                  <Typography
                    color="textPrimary"
                    className={classes.filterText}>
                    {t('projectWorkerHoursTable.status.title')}
                  </Typography>
                </Grid>
                <Grid item>
                  <RadioGroup
                    color="textPrimary"
                    aria-label="Status"
                    name="Status"
                    value={status}
                    onChange={handleStatusChange}>
                    <Grid container direction="row" spacing={2}>
                      {labels.map(label => {
                        return (
                          <Grid item key={label.value}>
                            <FormControlLabel
                              value={label.value}
                              labelPlacement="end"
                              control={<Radio color="primary" size="small" />}
                              label={
                                <Typography className={classes.labelText}>
                                  {label.label}
                                </Typography>
                              }
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </RadioGroup>
                </Grid>
                <Grid item className={classes.exportButton}>
                  <StyledButtonMuted
                    startIcon={<ImportExportIcon color="action" />}
                    label={t('projectWorkerHoursTable.exportButton')}
                    onClick={onExport}
                    disabled={isLoading}
                  />
                </Grid>
              </Grid>
            </FormControl>
          </Grid>
          {isEditing && (
            <Grid item>
              <Typography color="primary">
                {t('projectWorkerHoursTable.editing')}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item>
        <div
          className={classnames(
            classes.gridContainer,
            settings?.theme === DARK_THEME
              ? 'ag-theme-balham-dark'
              : 'ag-theme-balham'
          )}>
          <AgGridReact
            stopEditingWhenGridLosesFocus={true}
            suppressMenuHide={true}
            columnDefs={columnDefs}
            frameworkComponents={{ numericEditor: NumericEditor }}
            rowData={workerHourRows}
            isExternalFilterPresent={isExternalFilterPresent}
            doesExternalFilterPass={doesExternalFilterPass}
            onGridReady={onGridReady}
            undoRedoCellEditing={true}
            undoRedoCellEditingLimit={20} // default is 10
            onCellValueChanged={event => handleChange(event)}
          />
        </div>
      </Grid>
    </Grid>
  );
};

WorkerHoursTable.propTypes = {
  project: PropTypes.object,
  workerHourRows: PropTypes.array.isRequired,
  editableRows: PropTypes.array.isRequired,
  setEditableRows: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  toggleEditing: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  queryParams: PropTypes.object.isRequired,
  setQueryParams: PropTypes.func.isRequired,
  updateTotalHours: PropTypes.func.isRequired
};

export default withQueryParams({
  stripUnknownKeys: false,
  keys: {
    status: {
      default: DEFAULT_STATUS_VALUE,
      validate: value => ALLOWED_STATUS_VALUES.includes(value)
    }
  }
})(WorkerHoursTable);
