import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography } from '@mui/material';

const useStyles = makeStyles(theme => ({
  instructionsRow: { borderTop: '1px solid' + theme.borderGray },
  instructions: {
    fontSize: '0.625rem',
    paddingLeft: theme.spacing(2),
    padding: theme.spacing(0.5, 0),
    [theme.breakpoints.down('lg')]: { paddingLeft: 4 }
  }
}));

const RiskAssessmentInstructions = () => {
  const classes = useStyles();
  // TODO: TRANSLATES
  // const { t } = useTranslation();

  return (
    <>
      <Grid item xs={12} className={classes.instructionsRow}>
        <Typography color="textPrimary" className={classes.instructions}>
          <span className="bold">Step 1:</span> Review each "Hazard" with
          identified safety "Controls". Determined RAC (see matrix).
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.instructionsRow}>
        <Typography color="textPrimary" className={classes.instructions}>
          Probability: Likelihood the activity will cause a Mishap (Near Miss,
          Incident, or Accident). Identify as Frequent, Likely, Occasional,
          Seldom, or Unlikely.
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.instructionsRow}>
        <Typography color="textPrimary" className={classes.instructions}>
          Severity: The outcome if a mishap occurred. Identify as Catastrophic,
          Critical, Marginal, or Negligible.
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.instructionsRow}>
        <Typography color="textPrimary" className={classes.instructions}>
          <span className="bold">Step 2:</span> Identify the RAC (probability
          vs. severity) as E, H, M, L for each "Hazard" on AHA.
        </Typography>
      </Grid>
    </>
  );
};

export default RiskAssessmentInstructions;
