import React from 'react';
import classNames from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import { AppBar, Toolbar } from '@mui/material';

import useActiveRoute from 'hooks/useActiveRoute';
import BreadcrumbNavigation from './BreadcrumbNavigation';
import useIsOnline from 'store/onlineDetection';

const useStyles = makeStyles(theme => ({
  appBarTransparent: {
    boxShadow: 'none',
    marginTop: theme.spacing(8),
    backgroundColor: theme.transparent + '!important'
  },
  appBarSolidColor: {
    boxShadow: 'none',
    marginTop: theme.spacing(8),
    backgroundColor: theme.secondaryAppbarBackgroundColor
  },
  appBarHighContrast: { backgroundColor: theme.palette.primary.contrastText },
  offlineContainer: {
    backgroundColor: theme.palette.success.main,
    paddingTop: theme.spacing(1)
  },
  offlineMessage: {
    fontWeight: 'bold',
    color: theme.palette.primary.contrastText
  },
  toolbar: { backgroundColor: theme.palette.secondary.main }
}));

const SecondaryAppBar = () => {
  const classes = useStyles();
  const { isActiveRoute } = useActiveRoute();
  const isDashboardPage = isActiveRoute('/projects/:projectId/dashboard');
  const isSafeDashboardPage = isActiveRoute(
    '/projects/:projectId/observations'
  );
  const isProjectAhaEditorPage = isActiveRoute(
    '/projects/:projectId/ahas/hensel-phelps-aha/:ahaId/edit'
  );
  const isGlobalAhaEditorPage = isActiveRoute('/ahas/:ahaId/edit');
  const { isOnline } = useIsOnline();

  return (
    <AppBar
      id="breadcrumb-appbar"
      style={{ zIndex: 1000, marginBottom: 200 }}
      className={classNames(classes.appBarSolidColor, {
        [`${classes.appBarTransparent}`]:
          isDashboardPage || isSafeDashboardPage,
        [`${classes.appBarHighContrast}`]:
          isProjectAhaEditorPage || isGlobalAhaEditorPage
      })}>
      <Toolbar
        variant="dense"
        className={classNames({
          [`${classes.toolbar}`]:
            (isDashboardPage && !isOnline) || isSafeDashboardPage
        })}>
        <BreadcrumbNavigation />
      </Toolbar>
    </AppBar>
  );
};

export default SecondaryAppBar;
