import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSubscription } from '@apollo/react-hooks';
import { Typography, Grid } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import withAuthorization from 'hocs/withAuthorization';
import useOrientation from 'hooks/useOrientation';
import OrientationDetailsCard from 'components/orientations/OrientationDetailsCard';
import OrientationRegistrationOptionsCard from 'components/orientations/OrientationRegistrationOptionsCard';
import LoadingSpinner from 'components/common/LoadingSpinner';
import ErrorNotice from 'components/common/ErrorNotice';
import PageNotFoundNotice from 'components/common/PageNotFoundNotice';
import { ORIENTATION_SUBSCRIPTION } from 'graphql/orientation';
import withOnlineAccessOnly from 'hocs/withOnlineAccessOnly';

const useStyles = makeStyles(theme => ({
  title: { fontSize: '2rem' }
}));

const OrientationPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { orientationId } = useParams();
  const { orientation, error, loading } = useOrientation(orientationId);

  useSubscription(ORIENTATION_SUBSCRIPTION, {
    variables: { orientationId }
  });

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    if (error.message?.includes('not found')) {
      return <PageNotFoundNotice />;
    } else {
      return <ErrorNotice />;
    }
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography
            color="textPrimary"
            className={classes.title}
            component="h1">
            {t('projectOrientationFacilitationPage.pageTitle')}
          </Typography>
        </Grid>
        <Grid container direction="row" spacing={3}>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <OrientationDetailsCard orientation={orientation} />
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <OrientationRegistrationOptionsCard orientation={orientation} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default withOnlineAccessOnly(withAuthorization(OrientationPage));
