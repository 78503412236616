import React, { Fragment } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography } from '@mui/material';

const useStyles = makeStyles(theme => ({
  hrDivider: { padding: 0, margin: 0, width: '100%' },
  centeredText: { textAlign: 'center' },
  mediumFontSize: { fontSize: '0.75rem' },
  border: { border: '1px solid black' },
  borderRightAndLeft: {
    borderRight: '1px solid black',
    borderLeft: '1px solid black'
  },
  rightBorder: { boxSize: 'border-box', borderRight: '1px solid black' },
  leftBorder: { boxSize: 'border-box', borderLeft: '1px solid black' },
  fortyPixelHeight: { height: 40 }
}));

const ModifiedAndReviewedSignatureSection = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const userRowCount = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

  return (
    <Grid container className={classes.border}>
      <Grid item xs={12}>
        <Typography
          className={classnames(
            'bold',
            classes.centeredText,
            classes.mediumFontSize
          )}>
          {t('modifiedAndReviewedSignatureSection.signatureTable.title')}
        </Typography>
      </Grid>
      <hr className={classes.hrDivider} />
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={5} className={classnames(classes.rightBorder)}>
            <Typography
              className={classnames(
                'bold',
                classes.centeredText,
                classes.mediumFontSize
              )}>
              {t(
                'modifiedAndReviewedSignatureSection.signatureTable.nameColumn'
              )}
            </Typography>
          </Grid>
          <Grid item xs={5} className={classnames(classes.rightBorder)}>
            <Typography
              className={classnames(
                'bold',
                classes.centeredText,
                classes.mediumFontSize
              )}>
              {t(
                'modifiedAndReviewedSignatureSection.signatureTable.signatureColumn'
              )}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              className={classnames(
                'bold',
                classes.centeredText,
                classes.mediumFontSize
              )}>
              {t(
                'modifiedAndReviewedSignatureSection.signatureTable.dateColumn'
              )}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <hr className={classes.hrDivider} />
      {userRowCount.map((count, index) => (
        <Fragment key={index}>
          <Grid item xs={12}>
            <Grid container className={classes.fortyPixelHeight}>
              <Grid
                item
                xs={5}
                className={classnames(classes.rightBorder)}></Grid>
              <Grid
                item
                xs={5}
                className={classnames(classes.rightBorder)}></Grid>
              <Grid item xs={2}></Grid>
            </Grid>
          </Grid>
          <hr className={classes.hrDivider} />
        </Fragment>
      ))}
    </Grid>
  );
};

export default ModifiedAndReviewedSignatureSection;
