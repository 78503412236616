import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
const englishTranslation = require('../../constants/locales/en-US/translation.json');

export const initI18n = customInitParameters => {
  i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      supportedLngs: ['en-US'],
      fallbackLng: 'en-US',
      react: {
        useSuspense: true
      },
      interpolation: {
        escapeValue: false // not needed for react as it escapes by default
      },
      // Preloading is faster than relying on the backend loader as long as
      // we're dealing with a small set of translations.
      resources: {
        'en-US': { translation: englishTranslation }
      },
      ...customInitParameters
    });
  return i18n;
};

export default i18n;
