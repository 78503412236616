/* eslint-disable react/prop-types */
import { Grid, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React from 'react';
import CompetentPersonSignatureSection from './CompetentPersonSignatureSection';
import EquipmentSection from './EquipmentSection';
import JobStepsSection from './JobStepsSection';
import ModifiedAndReviewedSignatureSection from './ModifiedAndReviewedSignatureSection';
import ProjectInformationSection from './ProjectInformationSection';
import ReviewerSignatureSection from './ReviewerSignatureSection';
import RiskMatrixSection from './RiskMatrixSection';

const styles = theme => ({
  main: { color: theme.palette.invariant.black },
  title: {
    fontWeight: 'bold',
    fontSize: 32,
    textAlign: 'center'
  },
  projectInformationContainer: {
    boxSizing: 'border-box',
    width: '50%',
    display: 'float',
    float: 'left',
    border: '1px solid black'
  },
  racMatrixContainer: {
    boxSizing: 'border-box',
    width: '50%',
    display: 'float',
    float: 'right',
    border: '1px solid black'
  },
  thead: { display: 'tableHeaderGroup' },
  tfoot: { display: 'tableFooterGroup' },
  pageBreak: {
    display: 'block',
    pageBreakBefore: 'always',
    pageBreakAfter: 'always',
    pageBreakInside: 'avoid'
  },
  disclaimerText: { fontSize: '0.635rem' },
  disclaimerContainer: {
    marginTop: theme.spacing(21)
  }
});

// This component is passed to react-to-print package which requires the use of a class component
class AhaPrintableDocument extends React.PureComponent {
  render() {
    const { classes, ahaTemplate } = this.props;

    return (
      <table className={classes.main}>
        <thead>
          <tr>
            <td></td>
          </tr>
        </thead>
        <tbody>
          <tr className={classes.pageBreak}>
            <td>
              <Typography className={classes.title}>
                Activity Hazard Analysis (AHA)
              </Typography>
              <div className={classes.projectInformationContainer}>
                <ProjectInformationSection ahaTemplate={ahaTemplate} />
              </div>
              <div className={classes.racMatrixContainer}>
                <RiskMatrixSection ahaTemplate={ahaTemplate} />
              </div>
              <JobStepsSection ahaTemplate={ahaTemplate} />
              <EquipmentSection ahaTemplate={ahaTemplate} />
            </td>
          </tr>
          <tr className={classes.pageBreak} style={{ display: 'flex' }}>
            <td style={{ flex: 1 }}>
              <CompetentPersonSignatureSection ahaTemplate={ahaTemplate} />
            </td>
          </tr>
          <tr className={classes.pageBreak} style={{ display: 'flex' }}>
            <td style={{ flex: 1 }}>
              <ReviewerSignatureSection />
            </td>
          </tr>
          <tr className={classes.pageBreak} style={{ display: 'flex' }}>
            <td style={{ flex: 1 }}>
              <ModifiedAndReviewedSignatureSection />
              <Grid container className={classes.disclaimerContainer}>
                <Typography className={classes.disclaimerText}>
                  This AHA has been reviewed by Hensel Phelps for general
                  compliance with the job site safety requirements. The Hensel
                  Phelps review, however, does not relieve Subcontractor of the
                  responsibility for compliance with all applicable safety laws,
                  regulations, ordinances, and contractual requirements.
                  Subcontractor is responsible for reviewing this AHA with all
                  personnel involved with the Definable Feature of Work (DFOW)
                  on a regular basis and must notify Hensel Phelps and adjust
                  the AHA as necessary whenever the plan for performing the DFOW
                  is modified or following an unplanned event.
                </Typography>
              </Grid>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

export default withStyles(styles)(AhaPrintableDocument);
