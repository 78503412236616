import React from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import SafetyConnectEmblem from 'assets/images/logos/SafetyConnectLogoCircleRed.png';
import SiteMaintenance from 'assets/images/siteMaintenance.svg';

const useStyles = makeStyles(theme => ({
  safetyConnectEmblemImg: {
    width: 120,
    margin: theme.spacing(8, 0),
    [theme.breakpoints.down('lg')]: { margin: theme.spacing(4, 0) }
  },
  siteMaintenanceImg: {
    width: 200,
    height: 'auto',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('lg')]: { width: 150 },
    [theme.breakpoints.up('lg')]: { width: 300 }
  },
  title: {
    fontSize: '1.75rem',
    fontWeight: 'bold',
    [theme.breakpoints.down('lg')]: { fontSize: '1.25rem' }
  },
  content: {
    fontSize: '1.25rem',
    [theme.breakpoints.down('lg')]: { fontSize: '1rem' }
  }
}));

const MaintenanceNoticePage = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item>
        <img
          src={SafetyConnectEmblem}
          alt={t('maintenanceNoticePage.safetyConnectEmblemImgAlt')}
          className={classes.safetyConnectEmblemImg}
        />
      </Grid>
      <Grid item xs={10} sm={10} md={8} lg={8}>
        <Grid container direction="row">
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            lg={4}
            sx={{ display: { xs: 'none', sm: 'block' } }}>
            <img
              className={classes.siteMaintenanceImg}
              src={SiteMaintenance}
              alt=""
              role="presentation"
            />
          </Grid>
          <Grid item xs={12} sm={8} md={8} lg={8}>
            <Typography component="h2" className={classes.title} gutterBottom>
              {t('maintenanceNoticePage.title')}
            </Typography>
            <Typography component="h3" className={classes.content}>
              {t('maintenanceNoticePage.content')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MaintenanceNoticePage;
