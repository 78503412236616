import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { useForm, useField } from 'react-final-form-hooks';
import { Grid, Typography } from '@mui/material';

import useToast from 'hooks/useToast';
import StyledDialog from 'shared/Dialog';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import StyledButtonSecondary from 'shared/Buttons/ButtonSecondary';
import StyledInput from 'shared/Input';
import StyledNotice from 'shared/Notice';
import StyledConfirmationSwitch from 'shared/ConfirmationSwitch';
import { UPDATE_ORIENTATION } from 'graphql/orientation';

const CancelSingleOrientationDialog = ({
  cancelSingleOrientationDialogIsOpen,
  toggleCancelSingleOrientationDialog,
  orientation
}) => {
  const { t } = useTranslation();
  const { displayToast } = useToast();
  const { projectId } = useParams();
  const history = useHistory();
  const [isConfirmed, toggleConfirmSwitch] = useState(false);

  const [updateOrientation, { loading: isLoading }] = useMutation(
    UPDATE_ORIENTATION
  );

  const { form, values } = useForm({
    /* istanbul ignore next */
    onSubmit: () => {}, // this function required for useForm but is not used
    initialValues: {
      notes: orientation.notes ?? ''
    }
  });

  const notes = useField('notes', form);

  const { errors } = form.getState();

  const canSubmit = () => {
    return !errors.notes;
  };

  const handleClose = () => {
    toggleCancelSingleOrientationDialog(false);
    toggleConfirmSwitch(false);
  };

  const handleSubmit = () => {
    updateOrientation({
      variables: {
        input: {
          id: orientation.id,
          isCancelled: true,
          notes: values.notes ?? undefined
        }
      }
    })
      .then(({ data: { updateOrientation: orientation } }) => {
        displayToast(
          t('cancelOrientationDialog.toasts.single.success'),
          'success'
        );
        handleClose();
        history.push(`/projects/${projectId}/orientations`);
      })
      .catch(error => {
        console.error('Cancel Orientation Error: ', error);
        displayToast(t('cancelOrientationDialog.toasts.single.error'), 'error');
      });
  };

  const handleChange = event => {
    toggleConfirmSwitch(event.target.checked);
  };

  return (
    <StyledDialog
      data-testid="cancel-project-orientation-dialog"
      isLoading={isLoading}
      maxWidth="sm"
      isOpen={cancelSingleOrientationDialogIsOpen}
      handleClose={handleClose}
      title={t('cancelProjectOrientationDialog.confirmation.title')}
      content={
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <StyledNotice
              type="warning"
              message={t(
                'cancelProjectOrientationDialog.confirmation.subTitle'
              )}
            />
          </Grid>
          <Grid item>
            <StyledConfirmationSwitch
              disabled={isLoading}
              checked={isConfirmed}
              onChange={handleChange}
              value={'isConfirmed'}
            />
          </Grid>
          <Grid item className="margin-top">
            <Typography>
              {t('cancelSingleOrientationDialog.field.notes.instructions')}
            </Typography>
          </Grid>
          <Grid item>
            <StyledInput
              input={notes.input}
              meta={notes.meta}
              disabled={isLoading}
              label={t('cancelSingleOrientationDialog.field.notes.name')}
              multiline={true}
            />
          </Grid>
        </Grid>
      }
      actions={
        <Fragment>
          <StyledButtonSecondary
            label={t(
              'cancelProjectOrientationDialog.confirmation.actions.cancelButton'
            )}
            disabled={isLoading}
            onClick={handleClose}
          />
          <StyledButtonPrimary
            label={t(
              'cancelProjectOrientationDialog.confirmation.actions.submitButton'
            )}
            disabled={isLoading || !isConfirmed || !canSubmit}
            onClick={handleSubmit}
          />
        </Fragment>
      }
    />
  );
};
CancelSingleOrientationDialog.propTypes = {
  cancelSingleOrientationDialogIsOpen: PropTypes.any,
  toggleCancelSingleOrientationDialog: PropTypes.func,
  orientation: PropTypes.object.isRequired
};
export default CancelSingleOrientationDialog;
