import React from 'react';
import { RecoilRoot } from 'recoil';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import * as Sentry from '@sentry/browser';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { msalConfig } from 'config/msalConfig';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication, EventType } from '@azure/msal-browser';

import './index.scss';
import App from 'components/App';
import AppThemeProvider from 'providers/AppThemeProvider';
import CacheBuster from 'components/CacheBuster';
import MaintenanceModeDetector from 'components/MaintenanceModeDetector';
import SoteriaSubscriptions from 'components/common/SoteriaSubscriptions';
import SettingsProvider from 'providers/settings';
import ToastProvider from 'providers/toasts';
import AvatarProvider from 'providers/avatars';
import SoteriaStoreProvider from 'providers/soteriaStore';
import EnabledFeaturesProvider from 'providers/enabledFeatures/enabledFeatures';
import CanaryFeaturesProvider from 'providers/canaryFeatures/canaryFeatures';
import OnlineSync from 'components/offline/OnlineSync';
import SoteriaApolloProvider from 'providers/SoteriaApolloProvider';
import OfflinePrefetch from 'components/offline/OfflinePrefetch';
import NetworkStatusWrapper from 'components/offline/NetworkStatusWrapper';
import SoteriaAuthentication from 'components/common/SoteriaAuthentication';

const target = document.getElementById('root');

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://52b6e82d3def48e2afc6d91a42f64918@sentry.io/1885811',
    environment: process.env.REACT_APP_ENV
  });
}

const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

msalInstance.addEventCallback(event => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
});

render(
  <RecoilRoot>
    <NetworkStatusWrapper>
      <SettingsProvider>
        <AppThemeProvider>
          <BrowserRouter>
            <MsalProvider instance={msalInstance}>
              <HelmetProvider>
                <EnabledFeaturesProvider>
                  <CanaryFeaturesProvider>
                    <SoteriaStoreProvider>
                      <AvatarProvider>
                        <SoteriaApolloProvider>
                          <ToastProvider>
                            <SoteriaAuthentication>
                              <CacheBuster>
                                <OfflinePrefetch>
                                  <OnlineSync>
                                    <SoteriaSubscriptions>
                                      <MaintenanceModeDetector>
                                        <LocalizationProvider
                                          dateAdapter={AdapterLuxon}>
                                          <App />
                                        </LocalizationProvider>
                                      </MaintenanceModeDetector>
                                    </SoteriaSubscriptions>
                                  </OnlineSync>
                                </OfflinePrefetch>
                              </CacheBuster>
                            </SoteriaAuthentication>
                          </ToastProvider>
                        </SoteriaApolloProvider>
                      </AvatarProvider>
                    </SoteriaStoreProvider>
                  </CanaryFeaturesProvider>
                </EnabledFeaturesProvider>
              </HelmetProvider>
            </MsalProvider>
          </BrowserRouter>
        </AppThemeProvider>
      </SettingsProvider>
    </NetworkStatusWrapper>
  </RecoilRoot>,
  target
);
