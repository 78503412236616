import React, { useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  Grid,
  Typography,
  Badge,
  LinearProgress,
  Card,
  Divider
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import withAuthorization from 'hocs/withAuthorization';
import useProject from 'hooks/useProject';
import useToast from 'hooks/useToast';
import LoadingSpinner from 'components/common/LoadingSpinner';
import ErrorNotice from 'components/common/ErrorNotice';
import PageNotFoundNotice from 'components/common/PageNotFoundNotice';
import Notice from 'shared/Notice';
import StyledTabs from 'shared/Tabs';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import AdUserSelect from 'components/ad/AdUserSelect';
import AdUserPartnerSelect from 'components/ad/AdUserPartnerSelect';
import ProjectAdminList from 'components/project/ProjectAdminList';
import ProjectPartnerList from 'components/project/ProjectPartnerList';
import ProjectAdminRequestsList from 'components/project/ProjectAdminRequestsList';
import { SET_ADMIN_STATUS_ON_PROJECT } from 'graphql/project';
import { SET_PARTNER_STATUS_ON_PROJECT } from 'graphql/project';
import withOnlineAccessOnly from 'hocs/withOnlineAccessOnly';
import { TP_OBSERVATION_PILOT_PROJECTS } from 'constants/canaryFeatures';

const useStyles = makeStyles(theme => ({
  container: { height: 'auto' },
  title: { paddingBottom: theme.spacing(1), fontSize: '2rem' },
  manageRequestsTabText: { margin: theme.spacing(1) },
  outerContentContainer: { padding: theme.spacing(3) },
  contentContainer: {
    padding: theme.spacing(3),
    [theme.breakpoints.down('lg')]: { padding: 0 }
  },
  contentTitle: { fontSize: '1.25rem' },
  badge: { margin: theme.spacing(1) },
  img: { width: 240, padding: theme.spacing(3) },
  popover: { marginLeft: theme.spacing(1) },
  divider: { [theme.breakpoints.down('lg')]: { margin: theme.spacing(2, 0) } },
  placeholderText: { [theme.breakpoints.down('md')]: { fontSize: '0.75rem' } }
}));

export const ProjectPermissionsPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  let { search } = useLocation();
  const params = new URLSearchParams(search);
  const { projectId } = useParams();
  const { project, error, loading } = useProject(projectId);
  const [selectedAdUser, setSelectedAdUser] = useState(null);
  const [selectedAdPartnerUser, setSelectedAdPartnerUser] = useState(null);
  const { displayToast } = useToast();
  const [addAdmin, { loading: isAddingAdmin }] = useMutation(
    SET_ADMIN_STATUS_ON_PROJECT
  );
  const [addPartner, { loading: isAddingPartner }] = useMutation(
    SET_PARTNER_STATUS_ON_PROJECT
  );
  const isAdding = isAddingAdmin || isAddingPartner;
  const allowedTabs = ['admins', 'partners', 'requests'];
  const [activeTabIndex, setActiveTab] = useState(getTab());

  function getTab() {
    let param = params.get('manage');
    if (param) {
      param = param.toLowerCase();
      if (allowedTabs.includes(param)) {
        return allowedTabs.indexOf(param);
      } else {
        history.push({ search: `?manage=${allowedTabs[0]}` });
      }
    }
    return 0;
  }

  const handleTabChange = (_, newValue) => {
    setActiveTab(newValue);
    history.push({ search: `?manage=${allowedTabs[newValue]}` });
  };

  const handleSubmit = () => {
    addAdmin({
      variables: {
        projectId: project.id,
        adminUpn: selectedAdUser.upn,
        isAdmin: true
      }
    })
      .then(data => {
        displayToast(
          t('projectPermissionsPage.toasts.add.success', {
            firstName: selectedAdUser.firstName,
            lastName: selectedAdUser.lastName
          }),
          'success'
        );
        setSelectedAdUser(null);
      })
      .catch(error => {
        console.error(error);
        displayToast(t('projectPermissionsPage.toasts.add.error'), 'error');
      });
  };

  const handleSubmitPartner = () => {
    addPartner({
      variables: {
        projectId: project.id,
        microsoftAdId: selectedAdPartnerUser.microsoftAdId,
        isPartner: true
      }
    })
      .then(data => {
        displayToast(
          t('projectPermissionsPage.toasts.addPartner.success', {
            email: selectedAdPartnerUser.email
          }),
          'success'
        );
        setSelectedAdPartnerUser(null);
      })
      .catch(error => {
        console.error(error);
        displayToast(
          t('projectPermissionsPage.toasts.addPartner.error'),
          'error'
        );
      });
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    if (error.message?.includes('not found')) {
      return <PageNotFoundNotice />;
    } else {
      return <ErrorNotice />;
    }
  }

  const tabs = [
    {
      label: (
        <Typography>{t('projectPermissionsPage.adminsTab.label')}</Typography>
      ),
      content: (
        <Fragment>
          {isAdding && <LinearProgress color="primary" />}
          <Card className={classes.outerContentContainer}>
            <Grid container className={classes.contentContainer}>
              <Grid item xs={12}>
                <Grid
                  container
                  direction="column"
                  justifyContent="flex-start"
                  spacing={2}>
                  <Grid item>
                    <Typography
                      color="textPrimary"
                      className={classes.contentTitle}>
                      {t('projectPermissionsPage.addAdmin')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <AdUserSelect
                      selectedUser={selectedAdUser}
                      handleChange={setSelectedAdUser}
                      placeholder={
                        <Typography className={classes.placeholderText}>
                          {t('projectPermissionsPage.addAdminPlaceHolder')}
                        </Typography>
                      }
                    />
                  </Grid>
                  <Grid item>
                    <StyledButtonPrimary
                      data-testid="add-admin-button"
                      label={t('projectPermissionsPage.addButton')}
                      onClick={handleSubmit}
                      disabled={!selectedAdUser || isAdding}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Divider className={classes.divider}></Divider>
            <Grid container className={classes.contentContainer}>
              <Grid item xs={12}>
                <Typography
                  color="textPrimary"
                  className={classes.contentTitle}>
                  {t('projectPermissionsPage.manageCurrentAdmins')}
                </Typography>
              </Grid>
              <ProjectAdminList
                admins={project.admins}
                projectId={project.id}
              />
            </Grid>
          </Card>
        </Fragment>
      )
    },
    ...(TP_OBSERVATION_PILOT_PROJECTS.includes(projectId)
      ? [
          {
            label: (
              <Typography>
                {t('projectPermissionsPage.partnersTab.label')}
              </Typography>
            ),
            content: (
              <Fragment>
                {isAdding && <LinearProgress color="primary" />}
                <Card className={classes.outerContentContainer}>
                  <Grid container className={classes.contentContainer}>
                    <Grid item xs={12}>
                      <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        spacing={2}>
                        <Grid item>
                          <Typography
                            color="textPrimary"
                            className={classes.contentTitle}>
                            {t('projectPermissionsPage.addPartner')}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Notice
                            type="notice"
                            title={t(
                              'projectPermissionsPage.addPartnerSearchTipsTitle'
                            )}
                            message={t(
                              'projectPermissionsPage.addPartnerSearchTipsContent'
                            )}
                          />
                          <AdUserPartnerSelect
                            selectedUser={selectedAdPartnerUser}
                            handleChange={setSelectedAdPartnerUser}
                            placeholder={
                              <Typography className={classes.placeholderText}>
                                {t(
                                  'projectPermissionsPage.addPartnerPlaceHolder'
                                )}
                              </Typography>
                            }
                          />
                        </Grid>
                        <Grid item>
                          <StyledButtonPrimary
                            data-testid="add-partner-button"
                            label={t('projectPermissionsPage.addButton')}
                            onClick={handleSubmitPartner}
                            disabled={!selectedAdPartnerUser || isAdding}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider className={classes.divider}></Divider>
                  <Grid container className={classes.contentContainer}>
                    <Grid item xs={12}>
                      <Typography
                        color="textPrimary"
                        className={classes.contentTitle}>
                        {t('projectPermissionsPage.manageCurrentPartners')}
                      </Typography>
                    </Grid>
                    <ProjectPartnerList
                      partners={project.partners}
                      projectId={project.id}
                    />
                  </Grid>
                </Card>
              </Fragment>
            )
          }
        ]
      : []),
    {
      label: (
        <Badge
          badgeContent={
            project.accessRequests.length > 0
              ? project.accessRequests.length
              : null
          }
          color="secondary"
          className={classes.badge}>
          <Typography className={classes.manageRequestsTabText}>
            {t('projectPermissionsPage.requestsTab.label')}
          </Typography>
        </Badge>
      ),
      content: (
        <Card className={classes.outerContentContainer}>
          <Grid container className={classes.contentContainer}>
            <Grid item xs={12}>
              <Typography color="textPrimary" className={classes.contentTitle}>
                {t('projectPermissionsPage.manageAdminRequests')}
              </Typography>
            </Grid>
            <ProjectAdminRequestsList
              requests={project.accessRequests}
              projectId={project.id}
            />
          </Grid>
        </Card>
      )
    }
  ];

  return (
    <Grid container justifyContent="center" className={classes.container}>
      <Grid item xs={12}>
        <Typography
          component="h1"
          color="textPrimary"
          className={classes.title}>
          {t('projectPermissionsPage.heading')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <StyledTabs
          tabs={tabs}
          activeTab={activeTabIndex}
          handleChange={handleTabChange}
        />
      </Grid>
    </Grid>
  );
};

export default withOnlineAccessOnly(withAuthorization(ProjectPermissionsPage));
