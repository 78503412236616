import React from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import chrome from '../../assets/images/unsupportedBrowserImages/chromeBrowserLogoColor.png';
import edge from '../../assets/images/unsupportedBrowserImages/microsoftEdgeBrowserLogoColor.png';
import safari from '../../assets/images/unsupportedBrowserImages/safariBrowserLogoColor.png';
import firefox from '../../assets/images/unsupportedBrowserImages/firefoxBrowserLogoColor.png';
import mWindows from '../../assets/images/unsupportedBrowserImages/windowsOSLogo.png';
import linux from '../../assets/images/unsupportedBrowserImages/linuxOSLogo.png';
import apple from '../../assets/images/unsupportedBrowserImages/appleOSLogo.png';
import safetyConnect from '../../assets/images/logos/SafetyConnectLogoLandscapeBlack.png';
import { Grid, Typography, Button } from '@mui/material';
import { SERVICENOW_URL } from 'constants/externalLinks';

const PREFIX = 'UnsupportedBrowserDisclaimerPage';

const classes = {
  root: `${PREFIX}-root`,
  safetyConnectImg: `${PREFIX}-safetyConnectImg`,
  unsupportedBrowserText: `${PREFIX}-unsupportedBrowserText`,
  browserItemContainer: `${PREFIX}-browserItemContainer`,
  browserText: `${PREFIX}-browserText`,
  mainTextContainer: `${PREFIX}-mainTextContainer`,
  mainTextEmphasized: `${PREFIX}-mainTextEmphasized`,
  mainText: `${PREFIX}-mainText`,
  browserImg: `${PREFIX}-browserImg`,
  osText: `${PREFIX}-osText`,
  osImg: `${PREFIX}-osImg`,
  helpBtn: `${PREFIX}-helpBtn`,
  helpBtnText: `${PREFIX}-helpBtnText`
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    flex: 1,
    textAlign: 'center',
    backgroundColor: '#F2F2F2',
    minHeight: '100vh',
    height: '100%'
  },

  [`& .${classes.safetyConnectImg}`]: {
    maxWidth: 200,
    margin: '80px 0 32px 0'
  },
  [`& .${classes.unsupportedBrowserText}`]: {
    fontWeight: 'bold',
    fontSize: '2rem'
  },

  [`& .${classes.browserItemContainer}`]: {
    boxShadow: '2px 2px 10px gray',
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    backgroundColor: '#FFFFFF',
    border: '8px solid #A6192E',
    borderRadius: 4
  },

  [`& .${classes.browserText}`]: {
    textTransform: 'uppercase',
    fontSize: '0.75rem',
    fontWeight: 'bold',
    paddingBottom: 24
  },

  [`& .${classes.mainTextContainer}`]: { padding: 24, color: '#5C5C5C' },
  [`& .${classes.mainTextEmphasized}`]: { fontWeight: 'bold' },
  [`& .${classes.mainText}`]: { fontWeight: 'normal' },
  [`& .${classes.browserImg}`]: {
    maxWidth: theme.spacing(8),
    marginBottom: '2rem'
  },
  [`& .${classes.osText}`]: { fontSize: '0.625rem' },
  [`& .${classes.osImg}`]: { height: theme.spacing(2), width: 'auto' },

  [`& .${classes.helpBtn}`]: {
    margin: '32px 0',
    backgroundColor: '#A6192E',
    color: '#FFFFFF',
    '&:hover': { backgroundColor: '#700006' }
  },

  [`& .${classes.helpBtnText}`]: {
    textTransform: 'capitalize',
    fontSize: '0.75rem',
    fontWeight: 'bold',
    '&:first-letter': { textTransform: 'uppercase' }
  }
}));

export const UnsupportedBrowserDisclaimerPage = props => {
  const { t } = useTranslation();

  return (
    <Root className={classes.root}>
      <Grid
        container
        alignItems="center"
        alignContent="center"
        justifyContent="center">
        <Grid item>
          <img
            src={safetyConnect}
            alt={t('images.safetyConnectBannerAltText')}
            className={classes.safetyConnectImg}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.unsupportedBrowserText}>
            {t('unsupportedBrowserPage.heading')}
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.mainTextContainer}>
          <Typography gutterBottom className={classes.mainTextEmphasized}>
            {t('unsupportedBrowserPage.apology')}
            <span className={classes.mainText}>
              {t('unsupportedBrowserPage.explanation')}
            </span>
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item xs={5} lg={1} className={classes.browserItemContainer}>
          <Typography className={classes.browserText}>
            {t('unsupportedBrowserPage.browsers.chrome')}
          </Typography>
          <a
            href="https://www.google.com/chrome/"
            target="_blank"
            rel="noopener noreferrer">
            <img
              src={chrome}
              alt={t('images.chromeLogoAltText')}
              className={classes.browserImg}
            />
          </a>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12}>
              <Typography className={classes.osText}>
                {t('unsupportedBrowserPage.operatingSystem')}
              </Typography>
            </Grid>
            <Grid item md={1} lg={4}>
              <img
                src={mWindows}
                alt={t('images.windowsLogoAltText')}
                className={classes.osImg}
              />
            </Grid>
            <Grid item md={1} lg={4}>
              <img
                src={linux}
                alt={t('images.linuxLogoAltText')}
                className={classes.osImg}></img>
            </Grid>
            <Grid item md={1} lg={4}>
              <img
                src={apple}
                alt={t('images.appleLogoAltText')}
                className={classes.osImg}></img>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={5} lg={1} className={classes.browserItemContainer}>
          <Typography className={classes.browserText}>
            {t('unsupportedBrowserPage.browsers.edge')}
          </Typography>
          <a
            href="https://www.microsoft.com/en-us/windows/microsoft-edge"
            target="_blank"
            rel="noopener noreferrer">
            <img
              src={edge}
              alt={t('images.edgeLogoAltText')}
              className={classes.browserImg}
            />
          </a>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12}>
              <Typography className={classes.osText}>
                {t('unsupportedBrowserPage.operatingSystem')}
              </Typography>
            </Grid>
            <Grid item md={1} lg={4}>
              <img
                src={mWindows}
                alt={t('images.windowsLogoAltText')}
                className={classes.osImg}></img>
            </Grid>
            <Grid item md={1} lg={4}>
              <img
                src={apple}
                alt={t('images.appleLogoAltText')}
                className={classes.osImg}></img>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={5} lg={1} className={classes.browserItemContainer}>
          <Typography className={classes.browserText}>
            {t('unsupportedBrowserPage.browsers.safari')}
          </Typography>
          <a
            href="https://support.apple.com/downloads/safari"
            target="_blank"
            rel="noopener noreferrer">
            <img
              src={safari}
              alt={t('images.safariLogoAltText')}
              className={classes.browserImg}
            />
          </a>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12}>
              <Typography className={classes.osText}>
                {t('unsupportedBrowserPage.operatingSystem')}
              </Typography>
            </Grid>
            <Grid item>
              <img
                src={apple}
                alt={t('images.appleLogoAltText')}
                className={classes.osImg}></img>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={5} lg={1} className={classes.browserItemContainer}>
          <Typography className={classes.browserText}>
            {t('unsupportedBrowserPage.browsers.firefox')}
          </Typography>
          <a
            href="https://www.mozilla.org/en-US/firefox/new/"
            target="_blank"
            rel="noopener noreferrer">
            <img
              src={firefox}
              alt={t('images.firefoxLogoAltText')}
              className={classes.browserImg}
            />
          </a>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12}>
              <Typography className={classes.osText}>
                {t('unsupportedBrowserPage.operatingSystem')}
              </Typography>
            </Grid>
            <Grid item md={1} lg={4}>
              <img
                src={mWindows}
                alt={t('images.windowsLogoAltText')}
                className={classes.osImg}></img>
            </Grid>
            <Grid item md={1} lg={4}>
              <img
                src={linux}
                alt={t('images.linuxLogoAltText')}
                className={classes.osImg}></img>
            </Grid>
            <Grid item md={1} lg={4}>
              <img
                src={apple}
                alt={t('images.appleLogoAltText')}
                className={classes.osImg}></img>
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          <Grid item>
            <Button
              size="small"
              variant="contained"
              className={classes.helpBtn}
              onClick={() => window.open(SERVICENOW_URL, '_blank')}>
              <Typography className={classes.helpBtnText}>
                {t('unsupportedBrowserPage.helpButton')}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Root>
  );
};

export default UnsupportedBrowserDisclaimerPage;
