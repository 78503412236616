import React from 'react';
import { Box, Chip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useField } from 'react-final-form-hooks';
import CancelIcon from '@mui/icons-material/Cancel';

import useSoteriaQuery from 'hooks/useSoteriaQuery';
import { GET_OBSERVATION_CAUSES } from 'graphql/observations';
import StyledSelect from 'shared/Select';

const ObservationCauseSelect = ({ form, isLoading, isAllSafe }) => {
  const { t } = useTranslation();

  const { data } = useSoteriaQuery({
    gql: GET_OBSERVATION_CAUSES
  });

  const options = data?.observationCauses ?? [];

  const validateCauses = value => {
    if (isAllSafe) {
      return undefined;
    } else {
      return value?.length ? undefined : t('formValidation.required');
    }
  };

  const causes = useField('causes', form, validateCauses);

  return (
    <StyledSelect
      value={causes.input?.value?.map?.(item => item.value ?? item) || []}
      label={t('observationForm.field.cause.title')}
      placeholder={t('observationForm.field.cause.placeholder')}
      renderValue={selected => {
        if (selected.length === 0) {
          return t('observationForm.field.cause.placeholder');
        }

        return (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map(causeId => (
              <Chip
                key={causeId}
                label={
                  <Typography>
                    {options.find(cause => cause.id === causeId)?.name}
                  </Typography>
                }
                deleteIcon={
                  <CancelIcon onMouseDown={event => event.stopPropagation()} />
                }
                onDelete={() =>
                  causes.input.onChange(
                    causes.input.value.filter(
                      item => (item.value ?? item) !== causeId
                    )
                  )
                }
              />
            ))}
          </Box>
        );
      }}
      meta={causes.meta}
      options={options.map(item => ({
        label: item.name,
        value: item.id
      }))}
      disabled={isLoading}
      required={!isAllSafe}
      multiple
      input={causes.input}
      inputProps={{ 'data-testid': 'cause-select' }}
      onChange={event => causes.input.onChange(event.target.value)}
    />
  );
};

ObservationCauseSelect.propTypes = {
  form: PropTypes.any,
  isLoading: PropTypes.bool,
  isAllSafe: PropTypes.bool,
  observationWillBeClosed: PropTypes.bool
};

export default ObservationCauseSelect;
