import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSubscription } from '@apollo/react-hooks';
import { Grid, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import useTradePartner from 'hooks/useTradePartner';
import withAuthorization from 'hocs/withAuthorization';
import { TRADE_PARTNER_SUBSCRIPTION } from 'graphql/tradePartner';
import LoadingSpinner from 'components/common/LoadingSpinner';
import ErrorNotice from 'components/common/ErrorNotice';
import PageNotFoundNotice from 'components/common/PageNotFoundNotice';
import TradePartnerInfoForm from 'components/tradepartners/TradePartnerInfoForm';
import TradePartnerStatusCard from 'components/tradepartners/TradePartnerStatusCard';
import TradePartnerWorkerHoursCard from 'components/tradepartners/TradePartnerWorkerHoursCard';
import ManageContractorsCard from 'components/tradepartners/ManageContractorsCard';
import TradePartnerPersonnelList from 'components/tradepartners/TradePartnerPersonnelList';
import EnabledFeature from 'components/common/EnabledFeature';
import { features } from 'constants/enabledFeatures';
import TradePartnerDeleteCard from 'components/tradepartners/TradePartnerDeleteCard';
import useRoles from 'hooks/useRoles';
import withOnlineAccessOnly from 'hocs/withOnlineAccessOnly';

const useStyles = makeStyles(theme => ({
  title: { fontSize: '2rem' },
  subtitle: { fontSize: '1.5rem', marginBottom: theme.spacing(1) },
  sectionContainer: { borderRadius: 4 },
  tradePartnerInfo: { padding: theme.spacing(1) },
  icon: {
    fontSize: '2rem',
    marginLeft: theme.spacing(0.5),
    marginTop: theme.spacing(0.5)
  },
  mtdEditContainer: { borderRadius: 4, padding: theme.spacing(3) },
  hoursText: { fontSize: '2rem', fontWeight: 'bold' }
}));

const TradePartnerPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { tradePartnerId } = useParams();
  const { tradePartner, error, loading } = useTradePartner(tradePartnerId);
  const { isSupportRole } = useRoles();

  useSubscription(TRADE_PARTNER_SUBSCRIPTION, {
    variables: { tradePartnerId: tradePartnerId }
  });

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    if (error.message?.includes('not found')) {
      return <PageNotFoundNotice />;
    } else {
      return <ErrorNotice />;
    }
  }

  if (tradePartner && !tradePartner.company) {
    return <ErrorNotice message={t('tradePartnerPage.companyErrorNotice')} />;
  }

  return (
    <Grid container direction="column">
      <Typography color="textPrimary" className={classes.title}>
        {t('tradePartnerPage.title')}
      </Typography>
      <Typography color="textPrimary" className={classes.subtitle}>
        {tradePartner.name}
      </Typography>
      <Grid item>
        <Grid container direction="row" spacing={3}>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <Grid container direction="column" spacing={3}>
              <Grid item>
                <TradePartnerWorkerHoursCard tradePartner={tradePartner} />
              </Grid>
              <Grid item>
                <TradePartnerInfoForm tradePartner={tradePartner} />
              </Grid>
              <Grid item xs={12}>
                <TradePartnerPersonnelList />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Grid container direction="column" spacing={3}>
              <Grid item>
                <TradePartnerStatusCard tradePartner={tradePartner} />
              </Grid>
              <Grid item>
                <ManageContractorsCard
                  isEditable={tradePartner.isActive}
                  relationType={'parent'}
                  contractors={tradePartner.parentContractors}
                  title={t('tradePartnerPage.manageParentContractors.title')}
                  subtitle={t(
                    'tradePartnerPage.manageParentContractors.subtitle'
                  )}
                />
              </Grid>
              <Grid item>
                <ManageContractorsCard
                  isEditable={tradePartner.isActive}
                  relationType={'child'}
                  contractors={tradePartner.subcontractors}
                  title={t('tradePartnerPage.manageSubcontractors.title')}
                  subtitle={t('tradePartnerPage.manageSubcontractors.subtitle')}
                />
              </Grid>
              {isSupportRole && (
                <Grid item>
                  <TradePartnerDeleteCard tradePartner={tradePartner} />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withOnlineAccessOnly(withAuthorization(TradePartnerPage));
