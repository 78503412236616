import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import useProject from 'hooks/useProject';
import StyledAutoComplete from 'shared/AutoComplete';

const FacilitatorSelect = ({
  label,
  selectedFacilitators,
  handleChange,
  isDisabled,
  isRequired,
  isLoading,
  ...rest
}) => {
  const { t } = useTranslation();
  // eslint-disable-next-line no-unused-vars
  const [inputValue, handleInputChange] = useState(null);
  const { projectId } = useParams();
  const { project } = useProject(projectId);

  return (
    <StyledAutoComplete
      label={label}
      selectedValue={selectedFacilitators.input.value}
      meta={selectedFacilitators.meta}
      handleChange={handleChange}
      placeholder={t('companySelect.searchPlaceholder')}
      isRequired={true}
      isDisabled={isDisabled}
      options={
        project?.admins.map(admin => ({
          id: admin.upn,
          label: admin.name,
          value: admin.name,
          upn: admin.upn
        })) ?? []
      }
      isLoading={isLoading}
      handleInputChange={handleInputChange}
      isMulti
      {...rest}
    />
  );
};

FacilitatorSelect.propTypes = {
  label: PropTypes.string.isRequired,
  selectedFacilitators: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  isLoading: PropTypes.bool
};

export default FacilitatorSelect;
