import { atom, useRecoilState } from 'recoil';
import { LOCAL_STORAGE_AHA_FILES_ON_PROJECT_TEMPLATE_SORT_ORDER_KEY } from 'constants/localStorage';
import {
  HENSEL_PHELPS_AHA_FILES_SORT_ORDER_OPTIONS,
  SORT_ORDER_DIRECTION
} from 'constants/ahas';

const DEFAULT_SORT_ORDER = {
  name: HENSEL_PHELPS_AHA_FILES_SORT_ORDER_OPTIONS.uploaded.value,
  direction: SORT_ORDER_DIRECTION.ascending.toLowerCase()
};

const getStoredSortOrder = () => {
  const unserializedSortOrder = localStorage.getItem(
    LOCAL_STORAGE_AHA_FILES_ON_PROJECT_TEMPLATE_SORT_ORDER_KEY
  );
  if (unserializedSortOrder) {
    return JSON.parse(unserializedSortOrder);
  } else {
    return null;
  }
};

const DEFAULT_ROWS_PER_PAGE = 10;
const order = getStoredSortOrder() ?? DEFAULT_SORT_ORDER;

const defaultState = {
  first: DEFAULT_ROWS_PER_PAGE,
  skip: 0,
  order,
  search: ''
};

const ahaFilesOnProjectTemplateListStateAtom = atom({
  key: 'ahaFilesOnProjectTemplateListState',
  default: defaultState
});

function useAhaProjectTemplateFilesListState() {
  const [
    ahaFilesOnProjectTemplateListState,
    setAhaFilesOnProjectListState
  ] = useRecoilState(ahaFilesOnProjectTemplateListStateAtom);

  function handleAhaFilesOnProjectListStateChange(newState) {
    setAhaFilesOnProjectListState({
      ...ahaFilesOnProjectTemplateListState,
      ...newState
    });
  }
  return [
    ahaFilesOnProjectTemplateListState,
    { handleAhaFilesOnProjectListStateChange }
  ];
}

export default useAhaProjectTemplateFilesListState;
