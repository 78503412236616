import React, { useReducer } from 'react';
import SoteriaStoreContext from '../../context/soteriaStore';
import PropTypes from 'prop-types';

const initialState = {
  whatsNewIsOpen: false,
  helpIsOpen: false,
  selectedHelpSection: null
};

let reducer = (store, newStore) => {
  return { ...store, ...newStore };
};

const SoteriaStoreProvider = ({ children }) => {
  const [store, setStore] = useReducer(reducer, initialState);

  return (
    <SoteriaStoreContext.Provider value={{ store, setStore }}>
      {children}
    </SoteriaStoreContext.Provider>
  );
};

SoteriaStoreProvider.propTypes = {
  children: PropTypes.node
};

export default SoteriaStoreProvider;
