import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import { useField } from 'react-final-form-hooks';
import ErrorIcon from '@mui/icons-material/Error';
import FlagIcon from '@mui/icons-material/Flag';
import makeStyles from '@mui/styles/makeStyles';
import {
  Grid,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Avatar,
  Radio,
  Typography,
  Link,
  CircularProgress
} from '@mui/material';

import useFormValidation from 'hooks/useFormValidation';
import PersonnelMatchDetails from 'components/personnel/PersonnelMatchDetails';
import { formatDateOfBirth } from 'utils';
import { PERSONNEL_SEARCH } from 'graphql/personnel';

const useStyles = makeStyles(theme => ({
  title: { fontSize: '1.25rem' },
  radioGroupContainer: { width: '100%' },
  toImprove: { color: theme.palette.flag.toImprove },
  resolved: { color: theme.palette.flag.resolved },
  achievement: { color: theme.palette.flag.achievement },
  attentionRequired: { color: theme.palette.flag.attentionRequired },
  flagContainer: {
    paddingRight: 8,
    [theme.breakpoints.down('lg')]: {
      paddingRight: 0
    }
  },
  noMatchesContainer: { marginTop: theme.spacing(1) },
  container: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  nameText: {
    fontWeight: 'bold',
    maxWidth: 370,
    [theme.breakpoints.down('xl')]: { maxWidth: 280 },
    [theme.breakpoints.down('lg')]: { maxWidth: 250 },
    [theme.breakpoints.down('md')]: { maxWidth: 200 }
  },
  dobText: { fontSize: '0.875rem' },
  link: {
    color: theme.palette.background.callToAction,
    fontSize: '0.875rem',
    textDecoration: 'underline',
    '&:hover': { cursor: 'pointer' }
  },
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10)
  }
}));

const VerifyMatches = ({ form, selfRegisteredAttendee }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [personnelDetails, setPersonnelDetails] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [isDetailsPanelOpen, setIsDetailsPanelOpen] = useState(false);
  const { isRequired } = useFormValidation();

  const getSearchString = () => {
    const SEARCHABLE_FIELDS = [
      'firstName',
      'lastName',
      'middleName',
      'nickname'
    ];
    const searchParts = [];

    SEARCHABLE_FIELDS.forEach(field => {
      if (selfRegisteredAttendee && selfRegisteredAttendee[field]) {
        searchParts.push(selfRegisteredAttendee[field]);
      }
    });
    return searchParts.join('|');
  };

  const getFilter = () => {
    if (selfRegisteredAttendee?.dob) {
      return { dob: selfRegisteredAttendee.dob };
    } else if (selfRegisteredAttendee?.upn) {
      return { upn: selfRegisteredAttendee.upn };
    }

    return null;
  };

  const { data, loading: isLoading } = useQuery(PERSONNEL_SEARCH, {
    variables: {
      search: getSearchString(),
      filter: getFilter()
    },
    fetchPolicy: 'no-cache'
  });

  const selectedPersonnelId = useField('selectedPersonnelId', form, isRequired);

  const renderIndividualFlag = (classname, count) => {
    if (count === 0) {
      return;
    }

    return (
      <>
        <Grid item className={classes.flagContainer}>
          <Typography>
            <FlagIcon className={classes[classname]} />({count})
          </Typography>
        </Grid>
      </>
    );
  };

  const renderFlagGroup = flags => {
    let toImproveCount = 0;
    let resolvedCount = 0;
    let achievementCount = 0;
    let attentionRequiredCount = 0;

    flags.forEach(flag => {
      if (flag.status === 'ToImprove') {
        toImproveCount++;
      } else if (flag.status === 'Resolved') {
        resolvedCount++;
      } else if (flag.status === 'Achievement') {
        achievementCount++;
      } else if (flag.status === 'High') {
        attentionRequiredCount++;
      }
      return null;
    });

    return (
      <Grid container alignItems="center">
        {renderIndividualFlag('toImprove', toImproveCount)}
        {renderIndividualFlag('resolved', resolvedCount)}
        {renderIndividualFlag('achievement', achievementCount)}
        {renderIndividualFlag('attentionRequired', attentionRequiredCount)}
      </Grid>
    );
  };

  const handleDetailsClick = personnel => {
    setIsDetailsPanelOpen(true);
    setPersonnelDetails(personnel);
  };

  const handleBack = () => {
    setIsDetailsPanelOpen(false);
    setPersonnelDetails(null);
  };

  return (
    <Grid container direction="column" spacing={1}>
      <Typography component="h2" className={classes.title}>
        {t('verifyMatches.title')}
      </Typography>
      {isLoading && <CircularProgress color="primary" />}
      {!isLoading && (
        <>
          {personnelDetails && (
            <PersonnelMatchDetails
              personnelDetails={personnelDetails}
              handleBack={handleBack}
              isLoading={isLoading}
            />
          )}
          {!personnelDetails && (
            <Grid item>
              <FormControl
                variant="standard"
                component="fieldset"
                className={classes.radioGroupContainer}>
                <RadioGroup
                  name="personnelMatches"
                  value={selectedPersonnelId.input.value}
                  onChange={selectedPersonnelId.input.onChange}>
                  {data?.personnels?.map(personnel => (
                    <Grid container key={personnel.id}>
                      <Grid item xs={12}>
                        <FormControlLabel
                          value={personnel.id}
                          control={<Radio color="primary" />}
                          label={
                            <Grid
                              container
                              direction="row"
                              alignItems="center"
                              spacing={1}
                              className={classes.container}>
                              <Grid item>
                                {personnel.profileImage?.cachedUrl &&
                                  !personnel.profileImage?.isMalware && (
                                    <Avatar
                                      alt={t('verifyMatches.profileImage', {
                                        personnelName: personnel.name
                                      })}
                                      className={classes.avatar}
                                      src={
                                        personnel.profileImage.cachedUrl
                                      }></Avatar>
                                  )}
                                {!personnel.profileImage?.cachedUrl &&
                                  !personnel.profileImage?.isMalware && (
                                    <Avatar
                                      alt={t(
                                        'verifyMatches.profileImagePlaceholder'
                                      )}
                                      className={classes.avatar}></Avatar>
                                  )}
                                {!personnel.profileImage?.cachedUrl &&
                                  personnel.profileImage?.isMalware && (
                                    <Avatar
                                      className={classes.avatar}
                                      alt={t('verifyMatches.malware')}>
                                      <ErrorIcon
                                        className={classes.avatar}
                                        data-testid="malware-icon"
                                      />
                                    </Avatar>
                                  )}
                              </Grid>
                              <Grid item>
                                <Typography className={classes.nameText}>
                                  {personnel.fullName}
                                </Typography>
                                <Typography className={classes.dobText}>
                                  {t('verifyMatches.dob.label')}{' '}
                                  {formatDateOfBirth(personnel.dob)}
                                </Typography>
                                <Link
                                  className={classes.link}
                                  aria-label={`view match details on ${personnel.fullname}`}
                                  tabIndex="0"
                                  onKeyDown={() => {
                                    handleDetailsClick(personnel);
                                  }}
                                  onClick={() => {
                                    handleDetailsClick(personnel);
                                  }}
                                  underline="hover">
                                  <Typography variant="overline">
                                    {t('verifyMatches.detailsButton.title')}
                                  </Typography>
                                </Link>
                              </Grid>
                              <Grid item>
                                {renderFlagGroup(personnel.flags)}
                              </Grid>
                            </Grid>
                          }
                        />
                      </Grid>
                    </Grid>
                  ))}
                  <FormControlLabel
                    value={'no matches'}
                    control={<Radio color="primary" />}
                    label={
                      <>
                        {selfRegisteredAttendee?.profileImage && (
                          <Grid
                            container
                            direction="row"
                            alignItems="center"
                            spacing={1}
                            className={classes.container}>
                            <Grid item>
                              <Avatar
                                className={classes.avatar}
                                src={
                                  selfRegisteredAttendee?.profileImage?.url
                                }></Avatar>
                            </Grid>
                            <Grid item>
                              <Typography className={classes.nameText}>
                                {t('verifyMatches.noMatchesMessage')}
                              </Typography>
                            </Grid>
                          </Grid>
                        )}
                        {!selfRegisteredAttendee?.profileImage && (
                          <Typography className={classes.nameText}>
                            {t('verifyMatches.noMatchesMessage')}
                          </Typography>
                        )}
                      </>
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};

VerifyMatches.propTypes = {
  form: PropTypes.any,
  selfRegisteredAttendee: PropTypes.any
};

export default VerifyMatches;
