import makeStyles from '@mui/styles/makeStyles';
import { tabsClasses } from '@mui/material/Tabs';
import { tabClasses } from '@mui/material/Tab';
const useAhaStyles = () => {
  return makeStyles(theme => ({
    '@keyframes spin3d': {
      '0%': { transform: 'rotate3d(.03, 1, -.9, 0deg)' },
      '100%': { transform: 'rotate3d(.03, 1, -.9, -360deg)' }
    },
    '@keyframes depth': {
      '0%': { textShadow: '0 0 white' },
      '25%': {
        textShadow:
          '2px 0 white, 4px 0 white, 6px 0 white, 8px 0 white, 10px 0 white, 12px 0 white, 14px 0 white, 16px 0 white'
      },
      '50%': { textShadow: '0 0 white' },
      '75%': {
        textShadow:
          '-2px 0 white, -4px 0 white, -6px 0 white, -8px 0 white, -10px 0 white, -12px 0 white, -14px 0 white, -16px 0 white'
      },
      '100%': { textShadow: '0 0 white' }
    },
    root: {
      height: 'calc(100vh - 237px)',
      paddingTop: 64,
      [theme.breakpoints.down('lg')]: {
        paddingTop: 56,
        height: 100,
        marginTop: 56
      },
      [theme.breakpoints.down('md')]: { paddingTop: 0 }
    },
    spinRoot: { perspective: '500px' },
    headerContainer: {
      marginTop: 48,
      backgroundImage: `url(${theme.ahaBackground})`,
      backgroundSize: 'cover',
      height: 125,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      [theme.breakpoints.down('lg')]: { height: 60, marginTop: 0 }
    },
    title: {
      fontSize: '2rem',
      color: theme.palette.secondary.contrastText,
      fontFamily: 'Roboto, ui-sans-serif, sans-serif',
      fontWeight: 400,
      textAlign: 'center',
      margin: 0,
      [theme.breakpoints.down('md')]: {
        fontSize: '1.25rem',
        fontWeight: 'bold'
      }
    },
    spinTitle: {
      animationName: 'depth, spin3d',
      animationTimingFunction: 'linear',
      animationIterationCount: 'infinite',
      animationDuration: '3s',
      transformOrigin: 'center'
    },
    titleOnPendingReviewTab: {
      marginBottom: 32,
      paddingTop: 38
    },
    titleForSafariBrowser: {
      marginTop: theme.spacing(5.5),
      [theme.breakpoints.down('lg')]: { marginTop: theme.spacing(2) },
      [theme.breakpoints.down('md')]: { marginTop: theme.spacing(2.5) }
    },
    container: {
      height: 'calc(100vh - 237px)',
      padding: theme.spacing(0, 2),
      backgroundColor: theme.palette.background.default,
      [theme.breakpoints.down('lg')]: { height: 'calc(100vh - 172px)' },
      [theme.breakpoints.down('md')]: { height: 'calc(100vh - 120px)' }
    },
    searchInput: {
      backgroundColor: theme.palette.background.paper,
      maxWidth: 450,
      '& .MuiInput-underline:before': {
        borderBottom: '2px solid' + theme.palette.primary.main
      },
      '& .MuiInputBase-input': {
        color: theme.palette.secondary.contrastText + '!important',
        backgroundColor: theme.palette.background.paper
      },
      '&:hover': {
        borderBottomColor: theme.palette.primary.main
      }
    },
    searchFieldIcon: { fontSize: 16, color: theme.palette.icon.main },
    filterIcon: { fontSize: 16, color: theme.palette.icon.main },
    icon: {
      color: theme.palette.secondary.contrastText
    },
    tabs: {
      minHeight: theme.spacing(3),
      marginTop: theme.spacing(0.5),
      height: 38,
      [`& .${tabsClasses.flexContainer}`]: { height: '38px' },
      [`& .${tabClasses.root}`]: { height: '28px' }
    },
    redTab: {
      backgroundColor: `${theme.palette.primary.main} !important`,
      border: `1px solid ${theme.palette.primary.main} !important`,
      color: `${theme.palette.primary.contrastText} !important`
    },
    whiteTab: {
      backgroundColor: `${theme.palette.primary.contrastText} !important`,
      border: `1px solid ${theme.palette.primary.contrastText} !important`,
      color: `${theme.palette.primary.main} !important`
    },
    tab: {
      marginTop: 4,
      marginLeft: 4,
      border: '1px solid' + theme.palette.secondary.dark,
      boxShadow: '0px 10px 10px rgba(0, 0, 0, .4)',
      minHeight: '24px !important',
      padding: theme.spacing(0.5, 0, 0, 0),
      borderRadius: '8px 8px 0 0',
      paddingRight: 35
    },
    tabLabel: {
      textTransform: 'capitalize',
      fontWeight: 'bolder',
      padding: theme.spacing(0, 1),
      paddingTop: 4,
      [theme.breakpoints.down('md')]: {
        fontSize: '0.875rem'
      }
    },
    whiteBadge: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      top: '14px !important',
      right: '-15px !important',
      [theme.breakpoints.down('md')]: { right: '-12px !important' }
    },
    redBadge: {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.contrastText,
      top: '14px !important',
      right: '-15px !important',
      [theme.breakpoints.down('md')]: { right: '-12px !important' }
    },
    chipsContainer: { marginTop: 8 },
    sortContainer: { marginTop: 8 },
    select: {
      backgroundColor: theme.palette.primary.contrastText
    },
    filterContainer: { marginTop: 8 },
    filterButton: {
      backgroundColor: theme.palette.primary.contrastText
    },
    filterButtonLabel: {
      textTransform: 'capitalize',
      fontSize: '0.875rem',
      paddingTop: 4,
      paddingBottom: 0
    },
    filterGroupContainer: { padding: 16 },
    listCustomizationLabel: {
      fontWeight: 'bold',
      fontSize: '0.75rem',
      marginTop: 8,
      marginBottom: -10,
      [theme.breakpoints.down('md')]: { marginTop: 4 }
    },
    activeFiltersLabel: {
      fontWeight: 'bold',
      fontSize: '0.75rem'
    },
    toggleSortDirectionButton: {
      minWidth: 0,
      marginLeft: theme.spacing(0.5),
      padding: theme.spacing(1.125)
    },
    toggleSortDirectionButtonForSafariBrowser: {
      padding: theme.spacing(0.75)
    },
    sortDirectionButtonIcon: { fontSize: 16, color: theme.palette.icon.main },
    filterDropDownIcon: { fontSize: 16, color: theme.palette.icon.main },
    chipLabel: { fontWeight: 'bold', fontSize: 12 },
    chip: { marginRight: theme.spacing(1) },
    searchContainer: { [theme.breakpoints.down('md')]: { width: '100%' } },
    filterOptionLabel: { fontSize: '0.75rem' },
    clickableFilterOption: {
      height: '20px',
      width: '20px',
      marginRight: '10px',
      boxSizing: 'border-box',
      '&:hover': { backgroundColor: 'none' }
    },
    nested: { marginLeft: theme.spacing(2) },
    filterGroupSubTitle: { fontSize: '0.625rem' },
    filterGroup: { marginBottom: theme.spacing(2) }
  }));
};

export default useAhaStyles;
