const PhoneNumber = require('awesome-phonenumber');

//functions to format phone number.

export const formatPhoneNumber = phoneNumberToFormat => {
  if (!phoneNumberToFormat) {
    return null;
  }
  let filterOnlyNumbersFromPhoneNumber = ('' + phoneNumberToFormat).replace(
    /[^0-9]+/g,
    ''
  );
  let phoneNumberMatches = filterOnlyNumbersFromPhoneNumber.match(
    /^(1|)?(\d{3})(\d{3})(\d{4})$/
  );
  if (phoneNumberMatches) {
    let internationalCode = phoneNumberMatches[1] ? '+1 ' : '';
    return [
      internationalCode,
      '(',
      phoneNumberMatches[2],
      ') ',
      phoneNumberMatches[3],
      '-',
      phoneNumberMatches[4]
    ].join('');
  }
  return null;
};

export const formatInputAndInternationalPhoneNumber = phoneNumber => {
  return phoneNumber ? PhoneNumber(phoneNumber).a.number : null;
};
