import { resetApp } from 'utils';
import {
  LOCAL_STORAGE_OBSERVATION_LIST_FILTERS_KEY,
  LOCAL_STORAGE_OFFLINE_MODE_ENABLED_KEY,
  LOCAL_STORAGE_HENSEL_PHELPS_AHA_LIST_FILTERS_KEY,
  LOCAL_STORAGE_TRADE_PARTNER_AHA_LIST_FILTERS_KEY,
  LOCAL_STORAGE_HENSEL_PHELPS_AHA_SORT_ORDER_KEY,
  LOCAL_STORAGE_TRADE_PARTNER_AHA_CATEGORY_FILES_LIST_FILTERS_KEY,
  LOCAL_STORAGE_TRADE_PARTNER_AHA_CATEGORY_FILES_SORT_ORDER_KEY,
  LOCAL_STORAGE_PENDING_REVIEW_AHA_LIST_FILTERS_KEY,
  LOCAL_STORAGE_PENDING_REVIEW_AHA_SORT_ORDER_KEY,
  LOCAL_STORAGE_AHA_FILES_ON_PROJECT_TEMPLATE_SORT_ORDER_KEY,
  LOCAL_STORAGE_PROJECTS_SORT_ORDER_KEY,
  LOCAL_STORAGE_PROJECTS_FILTERS_KEY,
  LOCAL_STORAGE_COMPANIES_SORT_ORDER_KEY,
  LOCAL_STORAGE_COMPANIES_FILTERS_KEY
} from 'constants/localStorage';

const SW = {}; // don't keep it in the store

const sw = {
  update() {
    const registrationWaiting = SW.registration && SW.registration.waiting;

    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
      registrationWaiting.onstatechange = function(e) {
        if (e.target.state === 'activated') {
          resetApp();
        }
      };
    }
  },
  saveRegistration(registration) {
    SW.registration = registration;
  }
};

const observationListFilters = {
  localStorageSave(filters) {
    const serializedFilters = JSON.stringify(filters);
    localStorage.setItem(
      LOCAL_STORAGE_OBSERVATION_LIST_FILTERS_KEY,
      serializedFilters
    );
  }
};

const offlineMode = {
  localStorageSave(isEnabled) {
    const serializedValue = JSON.stringify(isEnabled);
    localStorage.setItem(
      LOCAL_STORAGE_OFFLINE_MODE_ENABLED_KEY,
      serializedValue
    );
  }
};

const henselPhelpsAhaListFilters = {
  localStorageSave(filters) {
    const serializedFilters = JSON.stringify(filters);
    localStorage.setItem(
      LOCAL_STORAGE_HENSEL_PHELPS_AHA_LIST_FILTERS_KEY,
      serializedFilters
    );
  }
};

const tradePartnerAhaListFilters = {
  localStorageSave(filters) {
    const serializedFilters = JSON.stringify(filters);
    localStorage.setItem(
      LOCAL_STORAGE_TRADE_PARTNER_AHA_LIST_FILTERS_KEY,
      serializedFilters
    );
  }
};

const henselPhelpsAhaSortOrder = {
  localStorageSave(order) {
    const serializedFilters = JSON.stringify(order);
    localStorage.setItem(
      LOCAL_STORAGE_HENSEL_PHELPS_AHA_SORT_ORDER_KEY,
      serializedFilters
    );
  }
};

const tradePartnerAhaCategoryFilesListFilters = {
  localStorageSave(filters) {
    const serializedFilters = JSON.stringify(filters);
    localStorage.setItem(
      LOCAL_STORAGE_TRADE_PARTNER_AHA_CATEGORY_FILES_LIST_FILTERS_KEY,
      serializedFilters
    );
  }
};

const tradePartnerAhaCategoryFilesListSortOrder = {
  localStorageSave(order) {
    const serializedFilters = JSON.stringify(order);
    localStorage.setItem(
      LOCAL_STORAGE_TRADE_PARTNER_AHA_CATEGORY_FILES_SORT_ORDER_KEY,
      serializedFilters
    );
  }
};

const pendingReviewAhaListFilters = {
  localStorageSave(filters) {
    const serializedFilters = JSON.stringify(filters);
    localStorage.setItem(
      LOCAL_STORAGE_PENDING_REVIEW_AHA_LIST_FILTERS_KEY,
      serializedFilters
    );
  }
};

const pendingReviewAhaSortOrder = {
  localStorageSave(order) {
    const serializedFilters = JSON.stringify(order);
    localStorage.setItem(
      LOCAL_STORAGE_PENDING_REVIEW_AHA_SORT_ORDER_KEY,
      serializedFilters
    );
  }
};

const ahaFilesOnProjectSortOrder = {
  localStorageSave(order) {
    const serializedFilters = JSON.stringify(order);
    localStorage.setItem(
      LOCAL_STORAGE_AHA_FILES_ON_PROJECT_TEMPLATE_SORT_ORDER_KEY,
      serializedFilters
    );
  }
};

const projectsSortOrder = {
  localStorageSave(order) {
    const serializedFilters = JSON.stringify(order);
    localStorage.setItem(
      LOCAL_STORAGE_PROJECTS_SORT_ORDER_KEY,
      serializedFilters
    );
  }
};

const projectsListFilters = {
  localStorageSave(filters) {
    const serializedFilters = JSON.stringify(filters);
    localStorage.setItem(LOCAL_STORAGE_PROJECTS_FILTERS_KEY, serializedFilters);
  }
};

const companiesSortOrder = {
  localStorageSave(order) {
    const serializedFilters = JSON.stringify(order);
    localStorage.setItem(
      LOCAL_STORAGE_COMPANIES_SORT_ORDER_KEY,
      serializedFilters
    );
  }
};

const companiesListFilters = {
  localStorageSave(filters) {
    const serializedFilters = JSON.stringify(filters);
    localStorage.setItem(
      LOCAL_STORAGE_COMPANIES_FILTERS_KEY,
      serializedFilters
    );
  }
};

export {
  sw,
  observationListFilters,
  offlineMode,
  henselPhelpsAhaListFilters,
  tradePartnerAhaListFilters,
  henselPhelpsAhaSortOrder,
  tradePartnerAhaCategoryFilesListFilters,
  tradePartnerAhaCategoryFilesListSortOrder,
  pendingReviewAhaListFilters,
  pendingReviewAhaSortOrder,
  ahaFilesOnProjectSortOrder,
  projectsSortOrder,
  projectsListFilters,
  companiesSortOrder,
  companiesListFilters
};
