import { atom, useRecoilState } from 'recoil';
import * as effects from 'store/effects';
import { LOCAL_STORAGE_OBSERVATION_LIST_FILTERS_KEY } from 'constants/localStorage';

const DEFAULT_SORT_ORDER = {
  name: 'created',
  direction: 'desc'
};

const DEFAULT_ROWS_PER_PAGE = 30;

// Remove the previous list filter local storage key
localStorage.removeItem('observation-list-state-filters');

const getStoredFilters = () => {
  const unserializedFilters = localStorage.getItem(
    LOCAL_STORAGE_OBSERVATION_LIST_FILTERS_KEY
  );
  if (unserializedFilters) {
    return JSON.parse(unserializedFilters);
  } else {
    return null;
  }
};

const DEFAULT_FILTERS = {};

const filter = getStoredFilters() ?? DEFAULT_FILTERS;

const defaultState = {
  first: DEFAULT_ROWS_PER_PAGE,
  myObservationsSkip: 0,
  allHpObservationsSkip: 0,
  allTpObservationsSkip: 0,
  filter,
  order: DEFAULT_SORT_ORDER,
  search: '',
  hasActiveFilters: hasActiveFilters(filter)
};

function hasActiveFilters(filterState) {
  let hasActiveFilters = false;

  if (typeof filterState === 'object') {
    for (const value of Object.values(filterState)) {
      if (value) {
        hasActiveFilters = true;
        break;
      }
    }
  }

  return hasActiveFilters;
}

const observationListStateAtom = atom({
  key: 'observationListState',
  default: defaultState
});

function useObservationListState() {
  const [observationListState, setObservationListState] = useRecoilState(
    observationListStateAtom
  );

  function handleObservationListStateChange(newState) {
    if (newState?.filter) {
      effects.observationListFilters.localStorageSave(newState.filter);
    }
    if (hasActiveFilters(newState.filter)) {
      newState.hasActiveFilters = true;
    } else {
      newState.hasActiveFilters = false;
    }

    setObservationListState({
      ...observationListState,
      ...newState
    });
  }
  return [observationListState, { handleObservationListStateChange }];
}

export default useObservationListState;
