import React, { useState, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useField, useForm } from 'react-final-form-hooks';
import { useMutation } from '@apollo/react-hooks';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography } from '@mui/material';

import {
  CREATE_GLOBAL_AHA_STEP,
  UPDATE_GLOBAL_AHA_STEP,
  GET_ALL_GLOBAL_STEP_CATEGORIES,
  GET_ALL_GLOBAL_STEPS_FOR_CATEGORY,
  GLOBAL_STEP_SEARCH
} from 'graphql/aha';
import { RISK_ASSESSMENT_CODES } from 'constants/ahas';
import StyledDialog from 'shared/Dialog';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import StyledButtonSecondary from 'shared/Buttons/ButtonSecondary';
import StyledNotice from 'shared/Notice';
import StyledInput from 'shared/Input';
import StyledSelect from 'shared/Select';
import CategorySelect from 'components/ahas/AhaStepCategorySelect';
import StepReferences from 'components/ahas/StepReferences';
import { generateTransactionKey, getMarkdownStringFromDraftJs } from 'utils';
import useFormValidation from 'hooks/useFormValidation';
import useToast from 'hooks/useToast';
import StyledRichTextEditor from 'shared/RichTextEditor';
import AhaScopeIndicator from 'components/ahas/AhaScopeIndicator';
import useAhaGlobalStepListState from 'store/ahaGlobalStepListState';
import MarkdownRenderer from 'shared/MarkdownRenderer';

const useStyles = makeStyles(theme => ({
  fieldContainer: { marginBottom: theme.spacing(1) },
  wysiwygContainer: { border: '1px solid black' },
  section: { marginBottom: theme.spacing(2) },
  sectionLabel: { fontSize: '0.875rem' }
}));

const ReadOnlySection = ({ label, value }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} className={classes.section}>
      <Typography color="textSecondary" className={classes.sectionLabel}>
        {label}
      </Typography>
      <Typography component="div">{value}</Typography>
    </Grid>
  );
};

ReadOnlySection.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any
};

const UpsertStep = ({
  isOpen,
  setIsOpen,
  isGlobal = false,
  refetchQueries = [],
  stepToEdit,
  setStepToEdit,
  setAhaTemplate,
  ahaTemplate,
  isReadOnly
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { displayToast } = useToast();
  const { isRequired } = useFormValidation();
  const [stepTransactionKey, setStepTransactionKey] = useState(
    generateTransactionKey()
  );
  const [
    globalStepCategoryTransactionKey,
    setGlobalStepCategoryTransactionKey
  ] = useState(generateTransactionKey());
  const [ahaGlobalStepListState] = useAhaGlobalStepListState();
  const hazardsRef = useRef(null);
  const controlsRef = useRef(null);

  const initialValues = useMemo(() => {
    return {
      category: stepToEdit
        ? {
            label: stepToEdit.ahaGlobalStepCategory?.name,
            value: stepToEdit.ahaGlobalStepCategory
          }
        : null,
      name: stepToEdit?.name ?? '',
      rac: stepToEdit?.riskAssessmentCode ?? '',
      hazards: stepToEdit?.hazards ?? '',
      controls: stepToEdit?.controls ?? '',
      references:
        stepToEdit?.ahaStepReferences?.map(ahaStepReference => ({
          label: ahaStepReference.ahaReferenceOption,
          ahaReferenceOption: ahaStepReference.ahaReferenceOption,
          value: ahaStepReference.value
        })) ?? []
    };
  }, [stepToEdit]);

  const { form, values, pristine } = useForm({
    /* istanbul ignore next */
    onSubmit: () => {},
    initialValues
  });

  const categoryField = useField('category', form);
  const nameField = useField('name', form, isRequired);
  const racField = useField('rac', form, isRequired);
  const hazardsField = useField('hazards', form, isRequired);
  const controlsField = useField('controls', form, isRequired);
  const referencesField = useField('references', form);

  const defaultRefetchQueries = [
    { query: GET_ALL_GLOBAL_STEP_CATEGORIES },
    ...(values?.category?.value?.id
      ? [
          {
            query: GET_ALL_GLOBAL_STEPS_FOR_CATEGORY,
            variables: { id: values.category.value.id }
          }
        ]
      : []),
    ...(ahaGlobalStepListState?.search
      ? [
          {
            query: GLOBAL_STEP_SEARCH,
            variables: { search: ahaGlobalStepListState?.search }
          }
        ]
      : [])
  ];

  const [
    createGlobalAhaStep,
    { loading: isLoadingCreateGlobalStep }
  ] = useMutation(CREATE_GLOBAL_AHA_STEP, {
    refetchQueries: [...defaultRefetchQueries, ...refetchQueries],
    awaitRefetchQueries: true
  });

  const [
    updateGlobalAhaStep,
    { loading: isLoadingUpdateGlobalStep }
  ] = useMutation(UPDATE_GLOBAL_AHA_STEP, {
    refetchQueries: [...defaultRefetchQueries, ...refetchQueries],
    awaitRefetchQueries: true
  });

  const isLoading = isLoadingCreateGlobalStep || isLoadingUpdateGlobalStep;
  const isEditing = !!stepToEdit;

  const handleChange = ref => {
    if (ref.current) {
      ref.current.save();
    }
  };

  const handleSave = (data, onChange) => {
    const markdownString = getMarkdownStringFromDraftJs(data);
    onChange(markdownString);
  };

  const canSubmit = () => {
    const { error, invalid } = form.getState();
    const emptyReferencesValue = values?.references
      ?.map(reference => reference.value === '')
      .includes(true);
    const paramsToSubmit = !(
      invalid ||
      error ||
      isLoading ||
      pristine ||
      emptyReferencesValue
    );

    if (isGlobal && !ahaTemplate) {
      return paramsToSubmit && values.category;
    } else {
      return paramsToSubmit;
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    setStepTransactionKey(generateTransactionKey());
    setGlobalStepCategoryTransactionKey(generateTransactionKey());

    form.resetFieldState('category');
    form.resetFieldState('name');
    form.resetFieldState('rac');
    form.resetFieldState('hazards');
    form.resetFieldState('controls');
    form.resetFieldState('references');
    form.reset();
    if (stepToEdit && setStepToEdit) {
      setStepToEdit(null);
    }
  };

  const handleCreateGlobalAhaStep = input => {
    createGlobalAhaStep({
      variables: { input }
    })
      .then(({ data: { createGlobalAhaStep: globalStep } }) => {
        displayToast(
          t('createGlobalStepDialog.toasts.success', {
            stepName: globalStep.name
          }),
          'success'
        );
        handleClose();
      })
      .catch(error => {
        console.error('Create Global Step Error: ', error);
        displayToast(t('createGlobalStepDialog.toasts.error'), 'error');
      });
  };

  const handleUpdateGlobalAhaStep = input => {
    updateGlobalAhaStep({
      variables: { input }
    })
      .then(({ data: { updateGlobalAhaStep: globalStep } }) => {
        displayToast(
          t('updateGlobalStepDialog.toasts.success', {
            stepName: globalStep.name
          }),
          'success'
        );
        handleClose();
      })
      .catch(error => {
        console.error('Update Global Step Error: ', error);
        displayToast(t('updateGlobalStepDialog.toasts.error'), 'error');
      });
  };

  const handleSubmit = () => {
    let input = {
      name: values.name,
      isActive: true,
      controls: values.controls,
      hazards: values.hazards,
      ahaStepReferences: values.references.map(reference => ({
        ahaReferenceOption: reference.ahaReferenceOption,
        value: reference.value
      })),
      riskAssessmentCode: values.rac
    };

    if (isGlobal && !ahaTemplate) {
      input = {
        ...input,
        ahaGlobalStepCategory: values.category?.__isNew__
          ? {
              name: values.category?.value,
              transactionKey: globalStepCategoryTransactionKey
            }
          : {
              id: values.category?.value?.id,
              name: values.category?.value?.name
            },
        order: undefined, // Global steps do not need order,
        templateId: undefined // Global steps do not need templateId
      };

      if (isEditing) {
        input.id = stepToEdit.id;
        handleUpdateGlobalAhaStep(input);
      } else {
        input.transactionKey = stepTransactionKey;
        handleCreateGlobalAhaStep(input);
      }
    } else {
      const identifier =
        stepToEdit?.id ?? stepToEdit?.transactionKey ?? stepTransactionKey;

      const stepToUpsert = {
        ...stepToEdit,
        ...input,
        transactionKey: identifier ?? generateTransactionKey()
      };

      let ahaSteps = [];

      if (isEditing) {
        ahaSteps = [
          ...ahaTemplate.ahaSteps.map(step => {
            if (step.id === identifier || step.transactionKey === identifier) {
              return stepToUpsert;
            } else {
              return step;
            }
          })
        ];
      } else {
        const getOrder = arr => {
          if (arr.length > 0) {
            return (
              Math.max.apply(
                Math,
                arr.map(function(item) {
                  return item.order;
                })
              ) + 1
            );
          } else {
            return 0;
          }
        };

        stepToUpsert.order = getOrder(ahaTemplate.ahaSteps);
        ahaSteps = [...ahaTemplate.ahaSteps, stepToUpsert];
      }

      setAhaTemplate({
        ...ahaTemplate,
        ahaSteps
      });

      handleClose();
    }
  };

  const getDialogTitle = () => {
    let dialogTitle = '';

    if (isReadOnly) {
      dialogTitle = t('upsertStep.viewGlobal.title');
    } else {
      if (stepToEdit) {
        if (isGlobal) {
          dialogTitle = t('upsertStep.editGlobal.title');
        } else {
          dialogTitle = t('upsertStep.edit.title');
        }
      } else {
        if (isGlobal) {
          dialogTitle = t('upsertStep.addGlobal.title');
        } else {
          dialogTitle = t('upsertStep.add.title');
        }
      }
    }

    return dialogTitle;
  };

  const titleToDisplay = getDialogTitle();

  return (
    <StyledDialog
      maxWidth={isReadOnly ? 'sm' : 'md'}
      isOpen={isOpen}
      handleClose={handleClose}
      isLoading={isLoading}
      disabled={isLoading}
      title={titleToDisplay}
      content={
        <form>
          <Grid container justifyContent="flex-end" className={'margin-bottom'}>
            <Grid item>
              <AhaScopeIndicator isGlobal={isGlobal} />
            </Grid>
            {isGlobal && !ahaTemplate && !isReadOnly && (
              <>
                <Grid item xs={12}>
                  <StyledNotice message={t('upsertStep.notice.message')} />
                </Grid>
                <Grid item xs={12} className={classes.fieldContainer}>
                  <CategorySelect
                    isDisabled={isLoading}
                    selectedCategory={categoryField.input.value}
                    meta={categoryField.meta}
                    handleChange={categoryField.input.onChange}
                  />
                </Grid>
              </>
            )}
            {!isReadOnly && (
              <>
                <Grid item xs={12} className={classes.fieldContainer}>
                  <StyledInput
                    label={t('upsertStep.field.stepName')}
                    input={nameField.input}
                    meta={nameField.meta}
                    autoFocus={true}
                    required
                  />
                </Grid>
                <Grid item xs={12} className={classes.fieldContainer}>
                  <StyledRichTextEditor
                    label={t('upsertStep.field.hazards')}
                    initialValue={hazardsField?.meta?.initial}
                    value={hazardsField.input.value}
                    onSave={data =>
                      handleSave(data, hazardsField.input.onChange)
                    }
                    autoSaveRef={hazardsRef}
                    onChange={() => handleChange(hazardsRef)}
                    required
                    disabled={isLoading}
                  />
                </Grid>
                <Grid item xs={12} className={classes.fieldContainer}>
                  <StyledRichTextEditor
                    label={t('upsertStep.field.controls')}
                    initialValue={controlsField?.meta?.initial}
                    value={controlsField.input.value}
                    onSave={data =>
                      handleSave(data, controlsField.input.onChange)
                    }
                    autoSaveRef={controlsRef}
                    onChange={() => handleChange(controlsRef)}
                    required
                    disabled={isLoading}
                  />
                </Grid>
                <Grid item xs={12} className={classes.fieldContainer}>
                  <StyledSelect
                    placeholder={t('upsertStep.field.racPlaceholder')}
                    margin="dense"
                    label={t('upsertStep.field.rac')}
                    required
                    input={racField.input}
                    meta={racField.meta}
                    disabled={isLoading}
                    options={Object.keys(RISK_ASSESSMENT_CODES).map(option => ({
                      label: RISK_ASSESSMENT_CODES[option].label,
                      value: RISK_ASSESSMENT_CODES[option].value
                    }))}
                  />
                </Grid>
                <Grid item xs={12} className={classes.fieldContainer}>
                  <StepReferences
                    referencesToUpsert={referencesField.input.value}
                    setReferencesToUpsert={referencesField.input.onChange}
                    isGlobal={isGlobal}
                  />
                </Grid>
              </>
            )}
            {isReadOnly && (
              <>
                <ReadOnlySection label={'Name'} value={stepToEdit?.name} />
                <ReadOnlySection
                  label={'Hazards'}
                  value={
                    <MarkdownRenderer markdownString={stepToEdit?.hazards} />
                  }
                />
                <ReadOnlySection
                  label={'Controls'}
                  value={
                    <MarkdownRenderer markdownString={stepToEdit?.controls} />
                  }
                />
                <ReadOnlySection
                  label={'RAC'}
                  value={stepToEdit?.riskAssessmentCode}
                />
                {stepToEdit?.ahaStepReferences?.length > 0 && (
                  <ReadOnlySection
                    label={'References'}
                    value={stepToEdit?.ahaStepReferences?.map(
                      (reference, index) => {
                        const label = reference.ahaReferenceOption;
                        const value = reference.value;
                        return (
                          <Typography
                            style={{ display: 'block' }}
                            component="span"
                            key={index}>
                            {label}: {value}
                          </Typography>
                        );
                      }
                    )}
                  />
                )}
              </>
            )}
          </Grid>
        </form>
      }
      actions={
        <Grid container justifyContent="space-between">
          <Grid item>
            <StyledButtonSecondary
              label={t('upsertStep.actions.cancelButton')}
              disabled={isLoading}
              onClick={handleClose}
            />
          </Grid>
          <Grid item>
            {!isReadOnly && (
              <StyledButtonPrimary
                label={t('upsertStep.actions.submitButton')}
                disabled={!canSubmit() || isLoading}
                onClick={handleSubmit}
              />
            )}
          </Grid>
        </Grid>
      }
    />
  );
};

UpsertStep.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  isGlobal: PropTypes.bool,
  refetchQueries: PropTypes.array,
  stepToEdit: PropTypes.any,
  setStepToEdit: PropTypes.func,
  setAhaTemplate: PropTypes.func,
  ahaTemplate: PropTypes.object,
  isReadOnly: PropTypes.bool
};

export default UpsertStep;
