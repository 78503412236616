import gql from 'graphql-tag';

// Queries

export const GET_PAGINATED_AHA_REVIEWS_ON_PROJECT = gql`
  query GetPaginatedAhaReviewsOnProject(
    $filter: AhaReviewFiltersInput
    $first: Int!
    $order: [AhaReviewOrderInput!]
    $projectId: ID!
    $search: String
    $skip: Int
  ) {
    paginatedAhaReviewsOnProject(
      filter: $filter
      first: $first
      order: $order
      projectId: $projectId
      search: $search
      skip: $skip
    ) {
      total
      ahaReviews {
        id
        status
        created
        completed
        preparedBy {
          id
          name
        }
        ahaTemplate {
          id
        }
        requestedReviewers {
          id
          name
          jobTitle
          upn
        }
        approvers {
          id
          name
          jobTitle
        }
        rejecters {
          id
          name
          jobTitle
        }
        ahaProjectTemplate {
          id
          ahaTemplate {
            id
            type
          }
        }
      }
    }
  }
`;

export const GET_PAGINATED_AHA_REVIEWS_ON_PROJECT_TOTAL_PENDING_COUNT = gql`
  query GetPaginatedAhaReviewsOnProject(
    $filter: AhaReviewFiltersInput
    $first: Int!
    $order: [AhaReviewOrderInput!]
    $projectId: ID!
    $search: String
    $skip: Int
  ) {
    paginatedAhaReviewsOnProject(
      filter: $filter
      first: $first
      order: $order
      projectId: $projectId
      search: $search
      skip: $skip
    ) {
      total
    }
  }
`;

export const GET_PAGINATED_AHA_REVIEWS_ON_PROJECT_TEMPLATE = gql`
  query GetPaginatedAhaReviewsOnProjectTemplate(
    $filter: AhaReviewFiltersInput
    $first: Int!
    $order: [AhaReviewOrderInput!]
    $ahaProjectTemplateId: ID!
    $search: String
    $skip: Int
  ) {
    paginatedAhaReviewsOnProjectTemplate(
      filter: $filter
      first: $first
      order: $order
      ahaProjectTemplateId: $ahaProjectTemplateId
      search: $search
      skip: $skip
    ) {
      total
      ahaReviews {
        id
        status
        created
        completed
        lastModified
        preparedBy {
          id
          name
        }
        requestedReviewers {
          id
          name
          jobTitle
          upn
        }
        approvers {
          id
          name
          jobTitle
        }
        rejecters {
          id
          name
          jobTitle
        }
        ahaTemplate {
          id
        }
        ahaProjectTemplate {
          id
          ahaTemplate {
            id
            type
          }
        }
      }
    }
  }
`;

export const GET_AHA_REVIEW = gql`
  query GetAhaReview($id: ID!) {
    ahaReview(id: $id) {
      id
      created
      status
      project {
        id
        name
      }
      preparedBy {
        id
        name
        jobTitle
        upn
      }
      ahaTemplate {
        id
        ahaActivitys {
          id
          name
          isActive
          created
          lastModified
          order
        }
        ahaEquipments {
          id
          inspectionRequirements
          isActive
          name
          order
          trainings
        }
        ahaSteps {
          ahaGlobalStepCategory {
            id
            name
          }
          controls
          hazards
          id
          isActive
          name
          order
          riskAssessmentCode
          ahaStepReferences {
            id
            value
            ahaReferenceOption
            ahaStep {
              id
            }
          }
        }
      }
      ahaProjectTemplate {
        id
        contractor
        notes
        ahaTemplate {
          id
          type
        }
      }
      requestedReviewers {
        id
        name
        jobTitle
        upn
      }
      approvers {
        id
        name
        jobTitle
      }
      rejecters {
        id
        name
        jobTitle
      }
    }
  }
`;

// Mutations

export const CREATE_AHA_REVIEW = gql`
  mutation CreateAhaReview($input: AhaReviewCreateInput!) {
    createAhaReview(input: $input) {
      id
      created
      completed
      status
      preparedBy {
        id
        name
      }
      requestedReviewers {
        id
        name
      }
      approvers {
        id
        name
      }
    }
  }
`;

export const UPDATE_AHA_REVIEW = gql`
  mutation UpdateAhaReview($input: AhaReviewUpdateInput!) {
    updateAhaReview(input: $input) {
      id
    }
  }
`;

export const DELETE_AHA_REVIEW = gql`
  mutation DeleteAhaReview($id: ID!) {
    deleteAhaReview(id: $id) {
      id
    }
  }
`;

export const APPROVE_AHA_REVIEW = gql`
  mutation ApproveAhaReview($id: ID!) {
    approveAhaReview(id: $id) {
      id
    }
  }
`;

export const REJECT_AHA_REVIEW = gql`
  mutation RejectAhaReview($id: ID!) {
    rejectAhaReview(id: $id) {
      id
    }
  }
`;

export const COMPLETE_AHA_REVIEW = gql`
  mutation CompleteAhaReview($id: ID!) {
    completeAhaReview(id: $id) {
      id
    }
  }
`;
