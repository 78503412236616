import React, {
  forwardRef,
  useState,
  useRef,
  useEffect,
  useImperativeHandle
} from 'react';
import StyledAutoComplete from 'shared/AutoComplete';
import { useTranslation } from 'react-i18next';
import ReactDOM from 'react-dom';

const TimeCardSupervisorsEditor = forwardRef((props, ref) => {
  const [value, setValue] = useState(
    props.value.map(supervisor => ({
      value: supervisor,
      label: `${supervisor.fullName} - ${supervisor.soteriaAdUser?.employeeId}`
    }))
  );
  const refContainer = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    ReactDOM.findDOMNode(refContainer.current).focus();
  }, []);

  /* Component Editor Lifecycle methods */
  useImperativeHandle(ref, () => {
    return {
      // the final value to send to the grid, on completion of editing
      getValue() {
        return value ? value.map(selection => selection.value) : [];
      },

      // Gets called once before editing starts, to give editor a chance to
      // cancel the editing before it even starts.
      isCancelBeforeStart() {
        return false;
      },

      // Gets called once when editing is finished (eg if Enter is pressed).
      // If you return true, then the result of the edit will be ignored.
      isCancelAfterEnd() {
        return false;
      },

      isPopup() {
        return true;
      }
    };
  });

  const valueIds = value ? value.map(selection => selection.value.id) : [];
  const filterSelectedSupervisors = props.supervisors
    .filter(supervisor => {
      return !valueIds.includes(supervisor.id);
    })
    .map(supervisor => ({
      value: supervisor,
      label: `${supervisor.fullName} - ${supervisor.soteriaAdUser?.employeeId}`
    }));

  const key = `safety-supervisors-select${
    typeof props.rowIndex === 'number' ? '-' + props.rowIndex : ''
  }`;

  return (
    <div
      ref={refContainer}
      style={{
        width: '300px'
      }}>
      <StyledAutoComplete
        // There is probably a better value for this, but the default behavior was making the options unclickable.
        menuPortalTarget={null}
        forwardRef={refContainer}
        selectedValue={value}
        handleChange={setValue}
        options={filterSelectedSupervisors}
        placeholder={t('editSafetyHoursSupervisors.fields.placeholder')}
        handleInputChange={() => {}}
        isMulti={true}
        key={key}
        autoFocus={true}
      />
    </div>
  );
});

export default TimeCardSupervisorsEditor;
