import React, { useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-final-form-hooks';
import { useMutation } from '@apollo/react-hooks';
import AddIcon from '@mui/icons-material/Add';
import { Grid } from '@mui/material';

import useToast from 'hooks/useToast';
import useSoteriaStore from 'hooks/useSoteriaStore';
import StyledDialog from 'shared/Dialog';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import StyledButtonSecondary from 'shared/Buttons/ButtonSecondary';
import StyledFab from 'shared/Buttons/Fab';
import CompanyFormFields from '../CompanyFormFields';
import { generateTransactionKey, getPhoneValues } from 'utils';
import { updateCompaniesCache } from './utils';
import { CREATE_COMPANY } from 'graphql/company';

const AddCompanyDialog = () => {
  const { t } = useTranslation();
  const {
    store: { whatsNewIsOpen = false }
  } = useSoteriaStore();
  const [isOpen, toggleDialog] = useState(false);
  const [transactionKey, setTransactionKey] = useState(
    generateTransactionKey()
  );

  const [createCompany, { loading }] = useMutation(CREATE_COMPANY, {
    update: updateCompaniesCache
  });

  const { displayToast } = useToast();
  const { form, values } = useForm({
    /* istanbul ignore next */
    onSubmit: () => {}, // this function required for useForm but is not used
    initialValues: {
      country: 'United States',
      transactionKey: transactionKey
    }
  });

  const handleClose = () => {
    toggleDialog(false);
    setTransactionKey(generateTransactionKey());
    form.reset();
  };

  const handleSubmit = () => {
    let companyToCreate = {
      transactionKey: transactionKey,
      name: values.name,
      notes: values.notes,
      location: {
        display: `${values.streetAndNumber}${
          values.streetDetails ? ' ' + values.streetDetails : ''
        }, ${values.city}, ${values.stateOrProvince} ${
          values.zipOrPostalCode
        }, ${values.country}`,
        streetAndNumber: values.streetAndNumber,
        streetDetail: values.streetDetails,
        city: values.city,
        stateOrProvince: values.stateOrProvince,
        zipOrPostalCode: values.zipOrPostalCode,
        country: values.country
      },
      contactInformation: {
        website: values.website,
        primaryPhone: getPhoneValues(
          values.primaryPhoneNumber,
          values.primaryPhoneExtension,
          null,
          'Primary'
        ),
        secondaryPhone: getPhoneValues(
          values.secondaryPhoneNumber,
          values.secondaryPhoneExtension,
          null,
          'Secondary'
        ),
        faxPhone: getPhoneValues(values.faxNumber, null, null, 'Fax')
      },
      contacts: []
    };
    createCompany({
      variables: companyToCreate
    })
      .then(({ data: { createCompany: company } }) => {
        displayToast(
          t('createCompanyDialog.toasts.success', {
            companyName: company.name
          }),
          'success'
        );
        handleClose();
      })
      .catch(error => {
        console.error('Create Company Error: ', error);
        displayToast(t('createCompanyDialog.toasts.error'), 'error');
      });
  };

  const canSubmit = () => {
    const { error, invalid } = form.getState();
    return !(invalid || loading || error);
  };

  return (
    <Fragment>
      <StyledFab
        data-testid="add-company-dialog-fab-button"
        onClick={() => toggleDialog(true)}
        shouldShift={whatsNewIsOpen}
        icon={<AddIcon />}
      />
      <StyledDialog
        data-testid="add-company-dialog"
        isLoading={loading}
        isOpen={isOpen}
        handleClose={handleClose}
        title={t('createCompanyDialog.title')}
        content={
          <form>
            <CompanyFormFields form={form} isLoading={loading} />
          </form>
        }
        actions={
          <Grid container justifyContent="space-between">
            <Grid item>
              <StyledButtonSecondary
                label={t('createCompanyDialog.actions.cancelButton')}
                disabled={loading}
                onClick={handleClose}
              />
            </Grid>
            <Grid item>
              <StyledButtonPrimary
                data-testid="add-company-submit"
                label={t('createCompanyDialog.actions.submitButton')}
                onClick={handleSubmit}
                disabled={!canSubmit()}
              />
            </Grid>
          </Grid>
        }
      />
    </Fragment>
  );
};

export default AddCompanyDialog;
