import React, { useState } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import { useMutation } from '@apollo/react-hooks';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import ErrorIcon from '@mui/icons-material/Error';
import makeStyles from '@mui/styles/makeStyles';
import { Typography, Grid, Avatar, IconButton, Link } from '@mui/material';

import useToast from 'hooks/useToast';
import StyledDialog from 'shared/Dialog';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import StyledButtonSecondary from 'shared/Buttons/ButtonSecondary';
import { MAX_FILE_SIZE_IN_BYTES } from 'constants/maxFileSize';
import { UPDATE_PERSONNEL, GET_PERSONNEL } from 'graphql/personnel';

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(4, 8),
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(2)
    }
  },
  avatar: {
    minWidth: theme.spacing(24),
    minHeight: theme.spacing(24),
    maxWidth: theme.spacing(24),
    maxHeight: theme.spacing(24),
    backgroundColor: theme.palette.background.contrastText,
    [theme.breakpoints.down('md')]: {
      objectFit: 'cover',
      height: theme.spacing(24),
      width: 'auto'
    }
  },
  errorText: {
    color: theme.palette.error.main
  },
  icon: { width: 24, height: 24, color: theme.palette.background.contrastText },
  iconButton: { marginBottom: theme.spacing(1) },
  text: { display: 'inline' },
  link: {
    color: theme.palette.secondary.contrastText,
    '&:hover': { textDecoration: 'none', cursor: 'pointer' }
  }
}));

const UpdatePersonnelPictureDialog = ({
  tradePartnerPersonnel,
  isChangePersonnelPictureOpen,
  setIsChangePersonnelPictureOpen
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { displayToast } = useToast();
  const [updatedProfileImage, setUpdatedProfileImage] = useState();
  const [imageUploadError, setImageUploadError] = useState(null);
  const [updatePersonnel, { loading: isLoading }] = useMutation(
    UPDATE_PERSONNEL
  );
  const [
    deletePersonnelProfileImage,
    { loading: deleteIsLoading }
  ] = useMutation(UPDATE_PERSONNEL, {
    update(cache, { data: { updatePersonnel: personnelToUpsert } }) {
      const personnelExists = !!cache?.data?.data?.ROOT_QUERY?.[
        `personnel({"id":"${personnelToUpsert.id}"})`
      ];
      let existingPersonnel = {};
      if (personnelExists) {
        const { personnel } = cache.readQuery({
          query: GET_PERSONNEL,
          variables: { id: personnelToUpsert.id }
        });
        existingPersonnel = personnel;
      }
      cache.writeQuery({
        query: GET_PERSONNEL,
        variables: { id: personnelToUpsert.id },
        data: {
          personnel: {
            ...existingPersonnel,
            ...personnelToUpsert
          }
        }
      });
    }
  });

  const { getInputProps, open } = useDropzone({
    accept: 'image/*',
    multiple: false,
    maxSize: MAX_FILE_SIZE_IN_BYTES,
    maxFiles: 1,
    onDropAccepted: acceptedFile => {
      setImageUploadError(null);
      validateFiles(acceptedFile[0]);
    },
    onDropRejected: rejectedFile => {
      const errorMessage = rejectedFile[0].errors[0].code;
      if (errorMessage === 'file-too-large') {
        setImageUploadError(
          t('addFile.largeFileError', {
            fileSize: Intl.NumberFormat(i18next.language, {
              style: 'unit',
              unit: 'megabyte'
            }).format(MAX_FILE_SIZE_IN_BYTES / 1000000)
          })
        );
        return;
      }
      setImageUploadError(t('addFile.generalFileUploadError'));
    }
  });

  const validateFiles = fileToCheck => {
    const formattedFile = formatFile(fileToCheck);
    setUpdatedProfileImage(formattedFile);
    return;
  };

  const formatFile = file => {
    const fileWithPreview = generateFilePreviews([file]);
    if (!file.path) {
      Object.assign(fileWithPreview[0], {
        path: file.name
      });
    }
    return fileWithPreview[0];
  };

  const generateFilePreviews = files => {
    const filesWithPreviews = files.map(file => {
      if (file) {
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        });
        return file;
      }
      return files;
    });
    return filesWithPreviews;
  };

  const handleSubmit = () => {
    updatePersonnel({
      variables: {
        input: {
          id: tradePartnerPersonnel.personnel.id,
          profileImage: updatedProfileImage,
          flagsToAdd: [],
          flagsToDelete: [],
          flagsToUpdate: [],
          trainingsToAdd: [],
          trainingsToDelete: [],
          trainingsToUpdate: []
        }
      }
    })
      .then(({ data: { updatePersonnel: personnel } }) => {
        displayToast(
          t('updatePersonnelPictureDialog.toasts.update.success', {
            imageName: tradePartnerPersonnel.personnel?.profileImage?.name
          }),
          'success'
        );
        handleClose();
      })
      .catch(error => {
        displayToast(
          t('updatePersonnelPictureDialog.toasts.update.error', {
            imageName: tradePartnerPersonnel.personnel?.profileImage?.name
          }),
          'error'
        );
      });
  };

  const handleClose = () => {
    setIsChangePersonnelPictureOpen(false);
    deleteLocalImage(updatedProfileImage);
  };

  const handleDelete = () => {
    setImageUploadError(null);
    deletePersonnelProfileImage({
      variables: {
        input: {
          id: tradePartnerPersonnel.personnel.id,
          shouldDeleteProfileImage: true,
          flagsToAdd: [],
          flagsToDelete: [],
          flagsToUpdate: [],
          trainingsToAdd: [],
          trainingsToDelete: [],
          trainingsToUpdate: []
        }
      }
    })
      .then(({ data: { updatePersonnel: personnel } }) => {
        displayToast(
          t('updatePersonnelPictureDialog.toasts.delete.success', {
            imageName: tradePartnerPersonnel.personnel?.profileImage?.name
          }),
          'success'
        );
        handleClose();
      })
      .catch(error => {
        displayToast(
          t('updatePersonnelPictureDialog.toasts.delete.error', {
            imageName: tradePartnerPersonnel.personnel?.profileImage?.name
          }),
          'error'
        );
      });
  };

  const deleteLocalImage = () => {
    if (updatedProfileImage?.preview) {
      URL.revokeObjectURL(updatedProfileImage.preview);
    }
    setUpdatedProfileImage(null);
  };

  return (
    <StyledDialog
      title={t('updatePersonnelPictureDialog.title')}
      isOpen={isChangePersonnelPictureOpen}
      handleClose={handleClose}
      disableBackdropClick={false}
      disableEscapeKeyDown={false}
      maxWidth="sm"
      isLoading={isLoading || deleteIsLoading}
      content={
        <Grid
          container
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="center"
          className={classes.container}>
          <Grid item>
            {tradePartnerPersonnel?.personnel?.profileImage?.cachedUrl &&
              !updatedProfileImage &&
              !tradePartnerPersonnel?.personnel?.profileImage?.isMalware && (
                <Avatar
                  className={classes.avatar}
                  src={
                    tradePartnerPersonnel.personnel.profileImage.cachedUrl
                  }></Avatar>
              )}
            {!tradePartnerPersonnel?.personnel?.profileImage?.cachedUrl &&
              !updatedProfileImage &&
              !tradePartnerPersonnel?.personnel?.profileImage?.isMalware && (
                <Avatar className={classes.avatar}></Avatar>
              )}
            {!tradePartnerPersonnel?.personnel?.profileImage?.cachedUrl &&
              !updatedProfileImage &&
              tradePartnerPersonnel?.personnel?.profileImage?.isMalware && (
                <Avatar className={classes.avatar}>
                  <ErrorIcon
                    className={classes.avatar}
                    data-testid="malware-icon"
                  />
                </Avatar>
              )}
            {updatedProfileImage && (
              <Avatar
                className={classes.avatar}
                src={updatedProfileImage.preview}></Avatar>
            )}
          </Grid>
          <Grid item>
            <Grid container direction="column">
              <Grid item>
                <Grid container direction="row" alignItems="center">
                  <Grid item>
                    <input {...getInputProps()} />
                    <IconButton className={classes.iconButton} onClick={open} size="large">
                      <AddAPhotoIcon className={classes.icon} />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <Link
                      className={classes.link}
                      aria-label={'capture or upload user image'}
                      tabIndex="0"
                      onKeyDown={open}
                      onClick={open}
                      underline="hover">
                      <Typography className={classes.text}>
                        {t('updatePersonnelPictureDialog.actions.addPicture')}
                      </Typography>
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
              {tradePartnerPersonnel.personnel.profileImage &&
                !updatedProfileImage && (
                  <Grid item>
                    <Grid container direction="row" alignItems="center">
                      <Grid item>
                        <input {...getInputProps()} />
                        <IconButton
                          className={classes.iconButton}
                          onClick={() =>
                            updatedProfileImage
                              ? deleteLocalImage(updatedProfileImage)
                              : handleDelete()
                          }
                          size="large">
                          <DeleteForeverOutlinedIcon className={classes.icon} />
                        </IconButton>
                      </Grid>
                      <Grid item>
                        <Link
                          className={classes.link}
                          aria-label={'remove uploaded image'}
                          tabIndex="0"
                          onKeyDown={() =>
                            updatedProfileImage
                              ? deleteLocalImage(updatedProfileImage)
                              : handleDelete()
                          }
                          onClick={() =>
                            updatedProfileImage
                              ? deleteLocalImage(updatedProfileImage)
                              : handleDelete()
                          }
                          underline="hover">
                          <Typography className={classes.text}>
                            {t(
                              'updatePersonnelPictureDialog.actions.removePicture'
                            )}
                          </Typography>
                        </Link>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              {imageUploadError && (
                <Grid item>
                  <p className={classes.errorText}>{imageUploadError}</p>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      }
      actions={
        <Grid container justifyContent="space-between">
          <Grid item>
            <StyledButtonSecondary
              label={t('updatePersonnelPictureDialog.actions.cancel')}
              onClick={handleClose}
              disabled={isLoading || deleteIsLoading}
            />
          </Grid>
          <Grid item>
            <StyledButtonPrimary
              label={t('updatePersonnelPictureDialog.actions.save')}
              onClick={handleSubmit}
              disabled={!updatedProfileImage || isLoading || deleteIsLoading}
            />
          </Grid>
        </Grid>
      }
    />
  );
};

UpdatePersonnelPictureDialog.propTypes = {
  tradePartnerPersonnel: PropTypes.object.isRequired,
  isChangePersonnelPictureOpen: PropTypes.bool.isRequired,
  setIsChangePersonnelPictureOpen: PropTypes.func.isRequired
};

export default UpdatePersonnelPictureDialog;
