import { useQuery } from '@apollo/react-hooks';
import { Button, InputAdornment } from '@mui/material';
import Badge from '@mui/material/Badge';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import SortIcon from '@mui/icons-material/Sort';
import { default as classnames, default as classNames } from 'classnames';
import LoadingSpinner from 'components/common/LoadingSpinner';
import TabPanel from 'components/common/TabPanel';
import AddObservationPartnerDialog from 'components/observations/AddObservationPartnerDialog';
import ObservationCardsList from 'components/observations/ObservationCardsList';
import ObservationsDashboardFilterMenu from 'components/observations/ObservationsDashboardFilterMenu';
import { OBSERVATION_SEARCH } from 'graphql/observations';
import withAuthorization from 'hocs/withAuthorization';
import useDebounce from 'hooks/useDebounce';
import useObservationCounts from 'hooks/useObservationCounts';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import withQueryParams from 'react-router-query-params';
import StyledFab from 'shared/Buttons/Fab';
import useObservationListState from 'store/observationListState';
import useObservationStyles from './useObservationsStyles';
import withOnlineAccessOnly from 'hocs/withOnlineAccessOnly';
import { ALLOWED_FILTER_KEYS } from 'constants/observationFilters';

const MY_OBSERVATIONS_TAB = 'myobservations';

const allowedTabValues = [MY_OBSERVATIONS_TAB];

const validateTabParam = search => {
  const params = new URLSearchParams(search);
  const tab = params.get('tab');
  return allowedTabValues.includes(tab?.toLowerCase());
};

// Helpers
const resetUpdateQuery = (
  _previousResult,
  { fetchMoreResult, _queryVariables }
) => {
  return fetchMoreResult;
};

const a11yProps = index => {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`
  };
};

const getApiFilter = filterState => {
  const filter = {};

  for (const [key, value] of Object.entries(filterState)) {
    switch (key) {
      case 'observedAfter':
        if (value === 'lastWeek') {
          const oneWeekAgo = moment().subtract(7, 'days');
          filter.observedAfter = oneWeekAgo.startOf('day').toISOString();
        } else if (value === 'lastMonth') {
          const thirtyDaysAgo = moment().subtract(30, 'days');
          filter.observedAfter = thirtyDaysAgo.startOf('day').toISOString();
        } else if (value) {
          filter.observedAfter = value;
        }
        break;
      case 'observedBefore':
        if (value) {
          filter.observedBefore = value;
        }
        break;
      default:
        if (ALLOWED_FILTER_KEYS.includes(key) && value) {
          filter[key] = value;
        }
        break;
    }
  }

  return filter;
};

const getApiSortOrder = sortState => {
  return sortState.name && sortState.direction
    ? [
        {
          [sortState.name]: sortState.direction.toUpperCase()
        }
      ]
    : null;
};

const ObservationsPartnerDashboardPage = ({ setQueryParams }) => {
  const styles = useObservationStyles();
  const classes = styles();
  const { t } = useTranslation();
  const theme = useTheme();
  const history = useHistory();
  let { search: locationSearch } = useLocation();
  const { projectId } = useParams();
  const { myOpenObservationsCount } = useObservationCounts(projectId);
  const params = new URLSearchParams(locationSearch);
  const [
    observationListState,
    { handleObservationListStateChange }
  ] = useObservationListState();

  const [initialObservationListState] = useState(observationListState);
  const [search, setSearch] = useState(observationListState.search);
  const [shouldShowLoader, setShouldShowLoader] = useState(true);
  const [
    initialQueriesHaveBeenCalled,
    setInitialQueriesHaveBeenCalled
  ] = useState(false);

  const allowedTabs = [MY_OBSERVATIONS_TAB];
  const [activeTabName, setActiveTabName] = useState(null);
  const [activeTab, setActiveTab] = useState(getTab());

  const [addObservationDialogIsOpen, toggleAddObservationDialog] = useState(
    false
  );

  // Get the initial data
  const {
    data: myObservationsData,
    loading: isMyObsLoading,
    fetchMore: fetchMoreMyObservations
  } = useQuery(OBSERVATION_SEARCH, {
    variables: {
      projectId,
      ownObservations: true,
      first: initialObservationListState.first,
      skip: 0,
      search: initialObservationListState.search,
      filter: {
        ...getApiFilter(initialObservationListState.filter),
        createdByAzureClientId: process.env.REACT_APP_AZURE_B2C_CLIENT_ID
      },
      order: getApiSortOrder(initialObservationListState.order)
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  });

  useEffect(() => {
    const shouldDisableFullscreenLoader =
      !initialQueriesHaveBeenCalled && myObservationsData;

    if (shouldDisableFullscreenLoader) {
      setShouldShowLoader(false);
      setInitialQueriesHaveBeenCalled(true);
    }
  }, [initialQueriesHaveBeenCalled, myObservationsData]);

  useEffect(() => {
    if (
      !initialQueriesHaveBeenCalled &&
      observationListState.myObservationsSkip > 0
    ) {
      handleObservationListStateChange({
        ...observationListState,
        myObservationsSkip: 0
      });
    }
  }, [
    handleObservationListStateChange,
    initialQueriesHaveBeenCalled,
    observationListState
  ]);

  // Observations
  const myObservations =
    myObservationsData?.observationSearch?.observations ?? [];

  // Counts

  const myObservationsTotalCount =
    myObservationsData?.observationSearch?.total ?? 0;

  // Handlers
  const loadMoreMyObservations = (variables, shouldReset = false) => {
    if (shouldReset) {
      setShouldShowLoader(true);
    }

    return fetchMoreMyObservations({
      variables,
      updateQuery: shouldReset
        ? resetUpdateQuery
        : (previousResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return previousResult;
            return Object.assign({}, previousResult, {
              observationSearch: {
                ...previousResult.observationSearch,
                ...fetchMoreResult.observationSearch,
                total: fetchMoreResult.observationSearch.total,
                observations: [
                  ...previousResult.observationSearch.observations,
                  ...fetchMoreResult.observationSearch.observations
                ]
              }
            });
          }
    }).then(() => {
      setShouldShowLoader(false);
    });
  };

  const loadMore = (type, variables, shouldReset = false) => {
    if (type === 'myObservations') {
      loadMoreMyObservations(variables, shouldReset);
    } else {
      console.error(`Cannot handle loadMore of type: "${type}"`);
    }
  };

  const handleLoadNextPage = type => {
    const newState = {};
    let skip = observationListState.first;

    switch (type) {
      case 'myObservations':
        skip = skip + observationListState.myObservationsSkip;
        newState.myObservationsSkip = skip;
        break;
      default:
        console.error(`Cannot handle loadMore for type: "${type}"`);
    }

    handleObservationListStateChange({
      ...observationListState,
      ...newState
    });

    const variables = {
      first: observationListState.first,
      search: observationListState.search,
      filter: getApiFilter(observationListState.filter),
      skip
    };

    loadMore(type, variables);
  };

  const resetAndRefetch = () => {
    handleObservationListStateChange({
      ...observationListState,
      myObservationsSkip: 0
    });

    const variables = {
      first: observationListState.first,
      search: observationListState.search,
      skip: 0,
      filter: getApiFilter(observationListState.filter),
      order: getApiSortOrder(observationListState.order)
    };

    loadMore('myObservations', variables, true);
  };

  const handleDebouncedSearchChange = ({ search, state }) => {
    handleObservationListStateChange({
      ...state,
      search,
      myObservationsSkip: 0
    });

    const variables = {
      first: state.first,
      filter: getApiFilter(state.filter),
      skip: 0,
      search,
      order: getApiSortOrder(state.order)
    };

    loadMore('myObservations', variables, true);
  };

  const { debounced: debouncedHandleSearchChange } = useDebounce(
    handleDebouncedSearchChange
  );

  const handleSearchChange = event => {
    const search = event ? event.target.value : '';

    setSearch(search);
    debouncedHandleSearchChange({
      search,
      state: observationListState
    });
  };

  const handleSortChange = () => {
    const newDirection =
      observationListState.order.direction === 'asc' ? 'desc' : 'asc';

    const newOrder = {
      ...observationListState.order,
      direction: newDirection
    };
    handleObservationListStateChange({
      ...observationListState,
      order: newOrder
    });

    const variables = {
      first: observationListState.first,
      skip: 0,
      search: observationListState.search,
      filter: getApiFilter(observationListState.filter),
      order: getApiSortOrder(newOrder)
    };

    loadMore('myObservations', variables, true);
  };

  const handleFilterChange = filterState => {
    handleObservationListStateChange({
      ...observationListState,
      filter: filterState
    });

    const variables = {
      first: observationListState.first,
      skip: 0,
      search: observationListState.search,
      filter: getApiFilter(filterState),
      order: getApiSortOrder(observationListState.order)
    };

    loadMore('myObservations', variables, true);
  };

  function getTab() {
    let param = params.get('tab');
    if (param) {
      param = param.toLowerCase();
      if (allowedTabs.includes(param)) {
        if (activeTabName !== param) {
          setActiveTabName(allowedTabs[allowedTabs.indexOf(param)]);
        }
        return allowedTabs.indexOf(param);
      } else {
        history.push({ search: `?tab=${allowedTabs[0]}` });
      }
    } else {
      if (activeTabName !== MY_OBSERVATIONS_TAB) {
        setActiveTabName(MY_OBSERVATIONS_TAB);
      }
    }

    return 0;
  }

  const myObservationsLabel = () => {
    if (myOpenObservationsCount > 0) {
      return (
        <Tooltip
          title={t('myObservations.tab.openObservationsCount', {
            count: myOpenObservationsCount
          })}>
          <Badge
            badgeContent={myOpenObservationsCount}
            data-testid="my-open-observations-badge"
            value={myOpenObservationsCount}
            classes={{
              badge:
                activeTab === allowedTabs.indexOf(MY_OBSERVATIONS_TAB)
                  ? classes.redBadge
                  : classes.whiteBadge
            }}>
            <Typography className={classes.tabLabel}>
              {t('myObservations.tab.myObservations')}
            </Typography>
          </Badge>
        </Tooltip>
      );
    } else {
      return (
        <Typography className={classes.tabLabel}>
          {t('myObservations.tab.myObservations')}
        </Typography>
      );
    }
  };

  const tabs = [
    {
      isActiveTab: activeTab === allowedTabs.indexOf(MY_OBSERVATIONS_TAB),
      label: myObservationsLabel(),
      content: (() => {
        if (myObservations?.length < 1) {
          if (
            observationListState.hasActiveFilters ||
            observationListState.search
          ) {
            return (
              <div className={classes.noObservationsMessage}>
                <Typography>{t('dashboardPage.noFiltersMatch')}</Typography>
              </div>
            );
          } else {
            return (
              <div className={classes.noObservationsMessage}>
                <Typography>
                  {t('dashboardPage.noPersonalObservations')}
                </Typography>
              </div>
            );
          }
        } else {
          return (
            <ObservationCardsList
              observations={myObservations}
              loadMore={() => handleLoadNextPage('myObservations')}
              type={'myObservations'}
              total={myObservationsTotalCount}
              isLoading={shouldShowLoader}
              refetchCurrentQueries={resetAndRefetch}
            />
          );
        }
      })()
    }
  ];

  const getTotal = () => {
    switch (activeTabName) {
      case MY_OBSERVATIONS_TAB:
        return myObservationsTotalCount;
      default:
        return null;
    }
  };

  const handleTabChange = (_, newValue) => {
    const tab = allowedTabs[newValue];
    setActiveTabName(tab);
    setActiveTab(newValue);
    setQueryParams({ tab });
  };

  if (isMyObsLoading && !initialQueriesHaveBeenCalled && shouldShowLoader) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <main
        role="main"
        className={classNames(classes.root, {
          [`${classes.rootAsOnline}`]: true
        })}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          className={classes.filterMenuContainer}>
          <Grid item>
            <Typography
              color="textPrimary"
              variant="overline"
              className={classes.totalResults}>
              {t('dashboardPage.totalResults.label')} {getTotal()}
            </Typography>
          </Grid>
          <Grid item>
            <ObservationsDashboardFilterMenu
              handleChange={handleFilterChange}
            />
          </Grid>
        </Grid>

        <StyledFab
          aria-label={t('dashboardPage.addObservation')}
          onClick={() => toggleAddObservationDialog(true)}
          icon={<AddIcon />}
        />

        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center">
          <Grid item>
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
              <img src={theme.safeLogo} alt={t('dashboardPage.safeLogo')} />
            </Box>
            <Box
              sx={{
                display: {
                  xs: 'block',
                  sm: 'none',
                  md: 'none',
                  lg: 'none',
                  xl: 'none'
                }
              }}>
              <img
                src={theme.safeLogoMobile}
                alt={t('dashboardPage.safeLogo')}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignItems="flex-start"
          className={classes.container}>
          <Grid
            item
            className={classes.contentContainer}
            xs={12}
            sm={12}
            md={10}
            lg={10}
            style={{ width: '100%' }}>
            <Tabs
              data-testid="observation-tabs"
              value={activeTab}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="tabs"
              className={classes.tabs}>
              {tabs.map((tab, index) => (
                <Tab
                  key={index}
                  label={tab.label}
                  className={classNames(
                    classes.tab,
                    {
                      [`${classes.redTab}`]: tab.isActiveTab
                    },
                    {
                      [`${classes.whiteTab}`]: !tab.isActiveTab
                    }
                  )}
                  {...a11yProps(index)}
                />
              ))}
            </Tabs>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              className={classes.searchContainer}>
              <Grid item>
                <TextField
                  className={classes.searchInput}
                  value={search}
                  onChange={handleSearchChange}
                  color="primary"
                  placeholder={t('dashboardPage.searchObservations')}
                  margin="dense"
                  variant="standard"
                  inputProps={{
                    'aria-label': t('dashboardPage.searchFieldLabel'),
                    'aria-required': false
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon
                          aria-label={t('dashboardPage.searchButton')}
                        />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        {observationListState.search && (
                          <IconButton
                            aria-label={t('dashboardPage.clearAlt')}
                            size="small"
                            onClick={() => handleSearchChange('')}
                            className={classes.icon}>
                            <ClearIcon />
                          </IconButton>
                        )}
                      </InputAdornment>
                    )
                  }}
                  fullWidth={true}
                />
              </Grid>
              <Grid item>
                <Grid container alignItems="center">
                  <Grid item>
                    <SortIcon
                      role="presentation"
                      className={classes.sortIcon}
                    />
                  </Grid>
                  <Grid item>
                    <Typography className={classes.sortLabel}>
                      {t('dashboardPage.sortLabel')}{' '}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      onClick={handleSortChange}
                      variant="contained"
                      color="secondary"
                      className={classes.createdDateSortButton}
                      endIcon={
                        observationListState.order.direction === 'asc' ? (
                          <ArrowDownwardIcon />
                        ) : (
                          <ArrowUpwardIcon />
                        )
                      }>
                      {observationListState.order.direction === 'asc'
                        ? t('dashboardPage.sortByONewest')
                        : t('dashboardPage.sortByONewest')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              direction="column"
              spacing={1}
              className={classnames({
                [`${classes.paddingTop}`]: false
              })}>
              {tabs.map((tab, index) => (
                <TabPanel
                  key={index}
                  value={activeTab}
                  index={index}
                  role="tabpanel"
                  hidden={activeTab !== index}
                  id={`scrollable-auto-tabpanel-${index}`}
                  aria-labelledby={`scrollable-auto-tab-${index}`}>
                  {tab.content}
                </TabPanel>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <AddObservationPartnerDialog
          addObservationDialogIsOpen={addObservationDialogIsOpen}
          toggleAddObservationDialog={toggleAddObservationDialog}
          refetchQuery={resetAndRefetch}
        />
      </main>
    </>
  );
};

ObservationsPartnerDashboardPage.propTypes = {
  queryParams: PropTypes.object.isRequired,
  setQueryParams: PropTypes.func.isRequired
};

export default withOnlineAccessOnly(
  withAuthorization(
    withQueryParams({
      stripUnknownKeys: false,
      keys: {
        tab: {
          default: undefined,
          validate: (_, props) => validateTabParam(props.location.search)
        }
      }
    })(ObservationsPartnerDashboardPage),
    {
      partnerOnProject: true
    }
  )
);
