import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useApolloClient } from '@apollo/react-hooks';
import CachedIcon from '@mui/icons-material/Cached';
import OfflineIcon from '@mui/icons-material/CloudOff';
import makeStyles from '@mui/styles/makeStyles';
import {
  Grid,
  Typography,
  FormGroup,
  FormControlLabel,
  Switch
} from '@mui/material';

import StyledDialog from 'shared/Dialog';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import StyledButtonSecondary from 'shared/Buttons/ButtonSecondary';
import StyledNotice from 'shared/Notice';
import packageJson from '../../../../../package.json';
import useSoteriaApolloClient from 'config/apolloConfig';
import useIsOnline from 'store/onlineDetection';

const useStyles = makeStyles(theme => ({
  usingLatestVersionNotice: { fontStyle: 'italic' },
  usingOutdatedVersionNotice: {
    fontStyle: 'italic',
    color: theme.palette.error.main
  },
  offlineContainer: {
    backgroundColor: theme.palette.secondary.light,
    padding: theme.spacing(0.5, 1, 0, 1),
    boxShadow: '2px 2px 10px gray',
    marginBottom: theme.spacing(4),
    borderRadius: 4,
    [theme.breakpoints.down('md')]: { padding: theme.spacing(2) }
  },
  icon: {
    color: theme.palette.secondary.contrastText,
    marginLeft: 24,
    [theme.breakpoints.down('md')]: { marginLeft: 0 }
  },
  label: {
    fontSize: '0.75rem',
    fontWeight: 'bold',
    display: 'inline',
    color: theme.palette.secondary.contrastText
  },
  offlineMessage: {
    fontWeight: 'bold',
    fontSize: '0.75rem',
    color: theme.palette.secondary.contrastText
  }
}));

const VersionDialog = ({ isOpen, setIsOpen }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const client = useApolloClient();
  const { purgeOfflineCache } = useSoteriaApolloClient();
  const { isOnline } = useIsOnline();
  const [shouldUpdateServiceWorker, setShouldUpdateServiceWorker] = useState(
    false
  );
  const [versionWasChecked, setVersionWasChecked] = useState(false);
  const [isCheckingVersion, setIsCheckingVersion] = useState(false);
  const [latestVersion, setLatestVersion] = useState({
    data: null,
    error: false,
    loading: true
  });
  const [isAcknowledged, toggleAcknowledgeSwitch] = useState(false);

  const handleVersionCheck = () => {
    if (!isCheckingVersion) {
      setIsCheckingVersion(true);
    }

    fetch('/meta.json', {
      headers: {
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        Pragma: 'no-cache',
        Expires: 0
      }
    })
      .then(response => response.json())
      .then(meta => {
        setLatestVersion({
          data: { version: meta.version },
          error: false,
          loading: false
        });
      })
      .catch(function(error) {
        console.error('Error fetching meta.json: ', error);
        setLatestVersion({ data: null, error: true, loading: false });
      })
      .finally(function() {
        setTimeout(function() {
          setIsCheckingVersion(false);
          setVersionWasChecked(true);
        }, 2 * 1000);
      });
  };

  const semverGreaterThan = (latestVersion, currentVersion) => {
    if (latestVersion?.data?.version && currentVersion) {
      const versionsA = latestVersion.data.version.split(/\./g);

      const versionsB = currentVersion.split(/\./g);
      while (versionsA.length || versionsB.length) {
        const a = Number(versionsA.shift());

        const b = Number(versionsB.shift());

        if (a === b) continue;

        return a > b || isNaN(b);
      }
    }

    return false;
  };

  useEffect(() => {
    const currentVersion = packageJson.version;

    const shouldReload =
      !isCheckingVersion &&
      latestVersion &&
      currentVersion &&
      semverGreaterThan(latestVersion, currentVersion);

    if (shouldReload) {
      setShouldUpdateServiceWorker(true);
    }
  }, [t, latestVersion, isCheckingVersion]);

  const handleVersionBtnClick = async () => {
    await client.clearStore();
    await purgeOfflineCache();

    navigator.serviceWorker.ready.then(registration => {
      registration.unregister().then(() => {
        window.location.reload();
      });
    });

    window.location.reload();
  };

  const handleChange = event => {
    toggleAcknowledgeSwitch(event.target.checked);
  };

  const handleClose = () => {
    toggleAcknowledgeSwitch(false);
    setIsOpen(false);
  };

  return (
    <StyledDialog
      isOpen={isOpen}
      handleClose={handleClose}
      title={t('versionDialog.title')}
      isLoading={isCheckingVersion}
      content={
        <Grid container>
          <Grid item xs={12}>
            {!isOnline && (
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                className={classes.offlineContainer}>
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    spacing={1}
                    alignItems="center">
                    <Grid item>
                      <OfflineIcon className={classes.icon} />
                    </Grid>
                    <Grid item>
                      <Typography className={classes.label}>
                        {t('versionDialog.offlineBanner.label')}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Typography className={classes.offlineMessage}>
                    {t('versionDialog.offlineBanner.message')}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} className="margin-bottom">
            <Typography>{t('versionDialog.instructions')}</Typography>
          </Grid>
          <Grid item xs={12} className="margin-bottom">
            <StyledButtonPrimary
              label={t('versionDialog.actions.checkForUpdatesButton')}
              onClick={handleVersionCheck}
              disabled={isCheckingVersion || !isOnline}
            />
          </Grid>
          {versionWasChecked && (
            <Grid item xs={12}>
              <Typography>
                {t('versionDialog.currentVersionLabel')} {packageJson.version}
              </Typography>
              <Typography>
                {t('versionDialog.latestVersionLabel')}{' '}
                {latestVersion?.data?.version}
              </Typography>
            </Grid>
          )}
          {shouldUpdateServiceWorker && versionWasChecked && (
            <Grid item xs={12} className="margin-bottom">
              <Typography className={classes.usingOutdatedVersionNotice}>
                {t('versionDialog.outdatedVersion')}
              </Typography>
            </Grid>
          )}
          {!shouldUpdateServiceWorker && versionWasChecked && (
            <Grid item xs={12} className="margin-bottom">
              <Typography className={classes.usingLatestVersionNotice}>
                {t('versionDialog.latestVersion')}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} className="margin-bottom">
            <StyledNotice
              title={t('versionDialog.noticeTitle')}
              message={t('versionDialog.message')}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container alignItems="center" direction="column">
              <Grid item xs={12}>
                <Typography>
                  {t('versionDialog.statementOfAcknowledgement')}
                </Typography>
              </Grid>
              <Grid item>
                <FormGroup row>
                  <FormControlLabel
                    labelPlacement="end"
                    control={
                      <Switch
                        color="primary"
                        disabled={isCheckingVersion || !isOnline}
                        inputProps={{
                          role: 'button',
                          'aria-pressed': isAcknowledged,
                          'aria-label': `toggle acknowledgement switch to ${isAcknowledged}`
                        }}
                        checked={isAcknowledged}
                        onChange={handleChange}
                        value={'isAcknowledged'}
                      />
                    }
                    label={t('versionDialog.switchLabel')}
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <StyledButtonPrimary
                  icon={<CachedIcon />}
                  label={t('versionDialog.actions.forceReloadApp')}
                  disabled={isCheckingVersion || !isAcknowledged || !isOnline}
                  onClick={handleVersionBtnClick}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
      actions={
        <Grid container justifyContent="space-between">
          <Grid item>
            <StyledButtonSecondary
              label={t('versionDialog.actions.closeButton')}
              disabled={isCheckingVersion}
              onClick={handleClose}
            />
          </Grid>
          <Grid item>
            <StyledButtonPrimary
              label={t('versionDialog.actions.cancelButton')}
              disabled={isCheckingVersion}
              onClick={handleClose}
            />
          </Grid>
        </Grid>
      }
    />
  );
};

VersionDialog.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func
};

export default VersionDialog;
