import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import { Typography, Grid, Link as MuiLink, useTheme } from '@mui/material';

import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import StyledUserNoticeTemplate from 'components/common/UserNoticeTemplate';

const useStyles = makeStyles(theme => ({
  heading: { fontSize: '2rem' },
  img: { width: 200, padding: theme.spacing(3) }
}));

export const PageNotFoundNotice = ({ message }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <StyledUserNoticeTemplate
      image={
        <img
          className={classes.img}
          src={theme.pageNotFound404}
          alt=""
          role="presentation"
        />
      }
      content={
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography color="textPrimary" className={classes.heading}>
              {t('pageNotFoundNotice.heading')}
            </Typography>
          </Grid>
          {message && (
            <Grid item>
              <Typography color="textPrimary">{message}</Typography>
            </Grid>
          )}
          <Grid item>
            <Grid container justifyContent="center">
              <Grid item>
                <MuiLink component={RouterLink} to={'/'} underline="none">
                  <StyledButtonPrimary
                    label={t('pageNotFoundNotice.homeButton')}
                  />
                </MuiLink>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
    />
  );
};

PageNotFoundNotice.propTypes = {
  message: PropTypes.string
};

export default PageNotFoundNotice;
