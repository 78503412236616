import React, { Fragment, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '@mui/x-date-pickers';
import { Grid, Tooltip, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import EventIcon from '@mui/icons-material/Event';
import CheckIcon from '@mui/icons-material/Check';
import ReactDOM from 'react-dom';

const ISO_8601_WEEKDAY_SUNDAY = 7;

const useStyles = makeStyles(theme => ({
  input: {
    fontSize: '1.5rem',
    paddingBottom: theme.spacing(2),
    marginTop: '-8px !important',
    fontFamily: ['Roboto', 'ui-sans-serif', 'sans-serif'].join(',')
  },
  datePickerGridItem: {
    display: 'none'
  }
}));

export const StyledWeekDatePicker = ({
  selectedDate,
  handleDateChange,
  disabled,
  disabledTooltipText = '',
  disableFuture = false,
  shouldShowCheckIcon = false,
  shouldShowWeekInButton = false,
  clearable = false
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const weekMonthAndYearToShowOnLabel = `week of ${selectedDate.toFormat(
    'yyyy-MM-dd'
  )}`;
  const shouldHideDatePicker = shouldShowWeekInButton;
  return (
    <Fragment>
      <Grid container justifyContent="space-between">
        <Grid
          item
          className={shouldHideDatePicker ? classes.datePickerGridItem : ''}>
          <DatePicker
            shouldDisableDate={date => {
              const isSunday = date.weekday === ISO_8601_WEEKDAY_SUNDAY;
              return !isSunday;
            }}
            clearable={clearable}
            open={isOpen}
            openTo="day"
            value={selectedDate}
            disableFuture={disableFuture}
            showTodayButton={true}
            views={['year', 'month', 'day']}
            onChange={date => handleDateChange(date)}
            onClose={() => setIsOpen(false)}
            slotProps={{
              // I can't see a way to disable backdropClick for desktop mode, so we are now only disabling it for mobile
              dialog: {
                onClose: (event, reason) => {
                  if (reason !== 'backdropClick') {
                    handleClose(event, reason);
                  }
                }
              },
              popper: { anchorEl: anchorEl }
            }}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between">
        <Tooltip title={disabled ? disabledTooltipText : ''}>
          <Grid item>
            <StyledButtonPrimary
              icon={<EventIcon />}
              label={
                shouldShowWeekInButton && selectedDate
                  ? weekMonthAndYearToShowOnLabel
                  : t('styledDatePicker.changeWeekButton')
              }
              onClick={event => {
                setIsOpen(true);
                setAnchorEl(event.currentTarget);
              }}
              disabled={disabled}
            />
          </Grid>
        </Tooltip>
        {shouldShowCheckIcon && (
          <Grid item>
            <CheckIcon data-testid="check-icon-two" />
          </Grid>
        )}
      </Grid>
    </Fragment>
  );
};

StyledWeekDatePicker.propTypes = {
  selectedDate: PropTypes.any,
  handleDateChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  disabledTooltipText: PropTypes.string,
  disableFuture: PropTypes.bool,
  shouldShowCheckIcon: PropTypes.bool,
  shouldShowWeekInButton: PropTypes.bool,
  clearable: PropTypes.bool
};

export default StyledWeekDatePicker;
