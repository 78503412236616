/* eslint-disable react/prop-types */
/* eslint-disable react/no-string-refs */
// Copied from ag-grid documentation
import React, {
  forwardRef,
  useRef,
  useState,
  useEffect,
  useImperativeHandle
} from 'react';
import ReactDOM from 'react-dom';

const KEY_BACKSPACE = 8;
const KEY_DELETE = 46;
const KEY_F2 = 113;
const KEY_ENTER = 13;

const createInitialState = props => {
  let startValue;
  let highlightAllOnFocus = true;

  if (props.keyPress === KEY_BACKSPACE || props.keyPress === KEY_DELETE) {
    // if backspace or delete pressed, we clear the cell
    startValue = '';
  } else if (props.charPress) {
    // if a letter was pressed, we start with the letter
    startValue = props.charPress;
    highlightAllOnFocus = false;
  } else {
    // otherwise we start with the current value
    startValue = props.value ?? props.data.hours ?? 0;
    if (props.keyPress === KEY_F2) {
      highlightAllOnFocus = false;
    }
  }

  return {
    value: startValue,
    highlightAllOnFocus
  };
};

const isLeftOrRight = event => {
  return [37, 39].indexOf(event.keyCode) > -1;
};

const getCharCodeFromEvent = event => {
  event = event || window.event;
  return typeof event.which === 'undefined' ? event.keyCode : event.which;
};

const isCharNumeric = charStr => {
  return !!/\d/.test(charStr);
};

const isKeyPressedNumeric = event => {
  const charCode = getCharCodeFromEvent(event);
  const charStr = event.key ? event.key : String.fromCharCode(charCode);
  return isCharNumeric(charStr);
};

const deleteOrBackspace = event => {
  return [KEY_DELETE, KEY_BACKSPACE].indexOf(event.keyCode) > -1;
};

const NumericEditor = forwardRef((props, ref) => {
  const [state, setState] = useState(createInitialState(props));
  const refContainer = useRef(null);
  const refInput = useRef(null);

  const cancelBeforeStart =
    props.charPress && '1234567890'.indexOf(props.charPress) < 0;

  const inputElement = refInput.current;
  const inputDOMNode = ReactDOM.findDOMNode(inputElement);

  useEffect(() => {
    if (inputDOMNode) {
      ReactDOM.findDOMNode(inputElement).focus();
    }
  }, [inputDOMNode, inputElement]);

  useEffect(() => {
    if (inputElement) {
      inputElement.addEventListener('keydown', onKeyDown);
      return () => inputElement.removeEventListener('keydown', onKeyDown);
    }
  }, [inputElement]);

  const handleChange = event => {
    setState({ value: event.target.value });
  };

  const onKeyDown = event => {
    if (
      (isLeftOrRight(event) || deleteOrBackspace(event)) &&
      event.keyCode !== KEY_ENTER
    ) {
      event.stopPropagation();
      return;
    }

    if (!isKeyPressedNumeric(event) && event.keyCode !== KEY_ENTER) {
      if (event.preventDefault) event.preventDefault();
    }
  };

  /* Component Editor Lifecycle methods */
  useImperativeHandle(ref, () => {
    return {
      isPopup() {
        return false;
      },

      afterGuiAttached() {
        // get ref from React component
        const eInput = refInput.current;
        eInput.focus();
        if (state.highlightAllOnFocus) {
          eInput.select();

          setState({
            highlightAllOnFocus: false
          });
        } else {
          // when we started editing, we want the carot at the end, not the start.
          // this comes into play in two scenarios: a) when user hits F2 and b)
          // when user hits a printable character, then on IE (and only IE) the carot
          // was placed after the first character, thus 'apply' would end up as 'pplea'
          const length = eInput.value ? eInput.value.length : 0;
          if (length > 0) {
            eInput.setSelectionRange(length, length);
          }
        }
      },

      getValue() {
        return state.value;
      },

      isCancelBeforeStart() {
        return cancelBeforeStart;
      }
    };
  });

  return (
    <div
      ref={refContainer}
      style={{
        width: '300px'
      }}>
      <input
        ref={refInput}
        value={state.value}
        onChange={handleChange}
        style={{ width: '100%' }}
      />
    </div>
  );
});

export default NumericEditor;
