import React from 'react';
import { Grid, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import VideoPlayer from '../../VideoPlayer';

const useStyles = makeStyles(theme => ({
  subTitle: { fontSize: '0.875rem' },
  title: { fontSize: '1.5rem' },
  emphasized: { fontStyle: 'italic' },
  bold: { fontWeight: 'bold' }
}));

const AddToProject = () => {
  const classes = useStyles();

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography className={classes.subTitle} color="textSecondary">
          Trade Partners Tutorial
        </Typography>
        <Typography className={classes.title} color="textPrimary">
          Add Trade Partner to Project
        </Typography>
      </Grid>
      <Grid item>
        <Typography color="textPrimary">
          Before a company can be added to a project as a trade partner, its
          basic information must be added. There are two different workflows,
          depending on whether or not the company already exists in Safety
          Connect.
        </Typography>
      </Grid>
      <Grid item>
        <Typography color="textPrimary">
          <span className={classes.bold}>Workflow 1:</span> Company already
          exists
        </Typography>
        <VideoPlayer
          data-testid="add-to-project-first-video"
          url="/tutorials/tradePartners/AddToProject.mp4"
          tracks={[
            {
              kind: 'subtitles',
              src: '/tutorials/tradePartners/AddToProject.en.vtt',
              srcLang: 'en',
              default: true
            }
          ]}
        />
      </Grid>
      <Grid item>
        <Typography color="textPrimary">
          <span className={classes.bold}>Workflow 2:</span> Company does not
          exist
        </Typography>
        <VideoPlayer
          data-testid="add-to-project-second-video"
          url="/tutorials/tradePartners/AddToProjectNew.mp4"
          tracks={[
            {
              kind: 'subtitles',
              src: '/tutorials/tradePartners/AddToProjectNew.en.vtt',
              srcLang: 'en',
              default: true
            }
          ]}
        />
      </Grid>
    </Grid>
  );
};

export default AddToProject;
