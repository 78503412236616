import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import deepEqual from 'deep-equal';
import { useTranslation } from 'react-i18next';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import makeStyles from '@mui/styles/makeStyles';
import {
  Typography,
  FormControl,
  FormControlLabel,
  FormGroup,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  ClickAwayListener,
  FormHelperText
} from '@mui/material';

import useSoteriaQuery from 'hooks/useSoteriaQuery';
import { GET_OBSERVATION_CATEGORIES } from 'graphql/observations';

const useStyles = makeStyles(theme => ({
  label: { fontSize: '0.75rem', fontWeight: 'bold' },
  checkbox: { paddingTop: 0, paddingBottom: 0 },
  accordion: {
    boxShadow: 'none',
    border: '1px solid' + theme.palette.field.border,
    borderRadius: 4
  },
  accordionSummary: { height: 36, minHeight: 36 },
  formControlLabel: { marginBottom: theme.spacing(1) },
  error: { border: '1px solid' + theme.palette.error.main },
  errorLabel: { color: theme.palette.error.main },
  errorMessage: { fontSize: '0.75rem', color: theme.palette.error.main }
}));

const ObservationCategorySelect = ({
  observationType,
  selectedCategories,
  setSelectedCategories,
  selectedObservation
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const [wasTouched, setWasTouched] = useState(false);

  const { data } = useSoteriaQuery({
    gql: GET_OBSERVATION_CATEGORIES
  });

  const handleChange = (event, category) => {
    if (event.target.checked) {
      setSelectedCategories([...selectedCategories, category]);
    } else {
      setSelectedCategories(
        selectedCategories.filter(
          selectedCategory => selectedCategory.name !== category.name
        )
      );
    }
  };

  const toggleAccordion = () => {
    setIsExpanded(!isExpanded);
    setWasTouched(true);
  };

  const handleClickAway = () => {
    setIsExpanded(false);
  };

  const behaviorialCategories =
    data?.observationCategories?.filter(
      observationCategory => observationCategory.type === 'Behavioral'
    ) ?? [];

  const environmentalCategories =
    data?.observationCategories?.filter(
      observationCategory => observationCategory.type === 'Environmental'
    ) ?? [];

  const categories =
    observationType === 'Environmental'
      ? environmentalCategories
      : behaviorialCategories;

  const categoryError = selectedCategories?.length < 1 && wasTouched;

  useEffect(() => {
    if (selectedObservation && !wasTouched) {
      const categoriesToSelect = selectedObservation.items?.map(item =>
        item?.subCategory?.category?.name.toLowerCase()
      );

      const categoriesToSet = categories.filter(category =>
        categoriesToSelect?.includes(category?.name?.toLowerCase())
      );

      if (!deepEqual(categoriesToSet, selectedCategories)) {
        setSelectedCategories(
          categoriesToSet.filter(category =>
            categoriesToSelect?.includes(category?.name?.toLowerCase())
          )
        );
      }
    }
  }, [
    categories,
    selectedObservation,
    setSelectedCategories,
    selectedCategories,
    wasTouched
  ]);

  return <>
    <Typography
      color="textSecondary"
      className={classNames(classes.label, {
        [`${classes.errorLabel}`]: categoryError
      })}>
      {t('observationForm.field.category.title')}
    </Typography>
    <ClickAwayListener onClickAway={handleClickAway}>
      <Accordion
        expanded={isExpanded}
        onChange={toggleAccordion}
        className={classNames(classes.accordion, {
          [`${classes.error}`]: categoryError
        })}>
        <AccordionSummary
          data-testid="observation-categories-accordion"
          expandIcon={<KeyboardArrowDownIcon />}
          className={classes.accordionSummary}>
          <Typography variant="subtitle2">
            {selectedCategories?.length < 1 &&
              t('observationForm.field.category.placeholder')}
            {selectedCategories?.length === 1 &&
              t('observationForm.field.category.placeholderOneSelected')}
            {selectedCategories?.length > 1 &&
              t('observationForm.field.category.placeholderManySelected', {
                numSelected: selectedCategories?.length
              })}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormControl variant="standard" component="fieldset">
            <FormGroup>
              <>
                {categories?.map((category, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      value={category.name}
                      label={
                        <Typography className={classes.label}>
                          {category.name}
                        </Typography>
                      }
                      control={
                        <Checkbox
                          checked={selectedCategories.some(
                            selectedCategory =>
                              selectedCategory.name === category.name
                          )}
                          color="primary"
                          className={classes.checkbox}
                        />
                      }
                      onChange={event => handleChange(event, category)}
                      className={classes.formControlLabel}
                    />
                  );
                })}
              </>
            </FormGroup>
          </FormControl>
        </AccordionDetails>
      </Accordion>
    </ClickAwayListener>
    {categoryError && (
      <FormHelperText className={classes.errorMessage}>
        {t('observationCategories.errorMessage')}
      </FormHelperText>
    )}
  </>;
};

ObservationCategorySelect.propTypes = {
  observationType: PropTypes.string,
  selectedCategories: PropTypes.any,
  setSelectedCategories: PropTypes.func,
  selectedObservation: PropTypes.object
};

export default ObservationCategorySelect;
