import React from 'react';
import PropTypes from 'prop-types';
// import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Grid, Typography, Hidden } from '@mui/material';

import useRiskAssessmentMatrixStyles from './useRiskAssessmentMatrixStyles';
import RiskAssessmentLegend from './RiskAssessmentLegend';
import RiskAssessmentInstructions from './RiskAssessmentInstructions';

const RiskAssessmentMatrixRow = ({
  severityLevel,
  severityColumnOne,
  severityColumnTwo,
  severityColumnThree,
  severityColumnFour,
  severityColumnFive
}) => {
  const styles = useRiskAssessmentMatrixStyles();
  const classes = styles();

  return (
    <Grid container className={classes.matrixRow}>
      <Grid item xs={2} className={classes.severityLevelContainer}>
        <Typography color="textPrimary" className={classes.severityLevel}>
          {severityLevel}
        </Typography>
      </Grid>
      <Grid
        item
        xs={2}
        className={classnames({
          [`${classes.extremelyHighRiskContainer}`]: severityColumnOne === 'E',
          [`${classes.highRiskContainer}`]: severityColumnOne === 'H',
          [`${classes.moderateRiskContainer}`]: severityColumnOne === 'M',
          [`${classes.lowRiskContainer}`]: severityColumnOne === 'L'
        })}>
        <Typography
          className={classnames(classes.riskLabel, {
            [`${classes.extremelyHighRiskLabel}`]: severityColumnOne === 'E',
            [`${classes.highRiskLabel}`]: severityColumnOne === 'H',
            [`${classes.moderateRiskLabel}`]: severityColumnOne === 'M',
            [`${classes.lowRiskLabel}`]: severityColumnOne === 'L'
          })}>
          {severityColumnOne}
        </Typography>
      </Grid>
      <Grid
        item
        xs={2}
        className={classnames({
          [`${classes.extremelyHighRiskContainer}`]: severityColumnTwo === 'E',
          [`${classes.highRiskContainer}`]: severityColumnTwo === 'H',
          [`${classes.moderateRiskContainer}`]: severityColumnTwo === 'M',
          [`${classes.lowRiskContainer}`]: severityColumnTwo === 'L'
        })}>
        <Typography
          className={classnames(classes.riskLabel, {
            [`${classes.extremelyHighRiskLabel}`]: severityColumnTwo === 'E',
            [`${classes.highRiskLabel}`]: severityColumnTwo === 'H',
            [`${classes.moderateRiskLabel}`]: severityColumnTwo === 'M',
            [`${classes.lowRiskLabel}`]: severityColumnTwo === 'L'
          })}>
          {severityColumnTwo}
        </Typography>
      </Grid>
      <Grid
        item
        xs={2}
        className={classnames({
          [`${classes.extremelyHighRiskContainer}`]:
            severityColumnThree === 'E',
          [`${classes.highRiskContainer}`]: severityColumnThree === 'H',
          [`${classes.moderateRiskContainer}`]: severityColumnThree === 'M',
          [`${classes.lowRiskContainer}`]: severityColumnThree === 'L'
        })}>
        <Typography
          className={classnames(classes.riskLabel, {
            [`${classes.extremelyHighRiskLabel}`]: severityColumnThree === 'E',
            [`${classes.highRiskLabel}`]: severityColumnThree === 'H',
            [`${classes.moderateRiskLabel}`]: severityColumnThree === 'M',
            [`${classes.lowRiskLabel}`]: severityColumnThree === 'L'
          })}>
          {severityColumnThree}
        </Typography>
      </Grid>
      <Grid
        item
        xs={2}
        className={classnames({
          [`${classes.extremelyHighRiskContainer}`]: severityColumnFour === 'E',
          [`${classes.highRiskContainer}`]: severityColumnFour === 'H',
          [`${classes.moderateRiskContainer}`]: severityColumnFour === 'M',
          [`${classes.lowRiskContainer}`]: severityColumnFour === 'L'
        })}>
        <Typography
          className={classnames(classes.riskLabel, {
            [`${classes.extremelyHighRiskLabel}`]: severityColumnFour === 'E',
            [`${classes.highRiskLabel}`]: severityColumnFour === 'H',
            [`${classes.moderateRiskLabel}`]: severityColumnFour === 'M',
            [`${classes.lowRiskLabel}`]: severityColumnFour === 'L'
          })}>
          {severityColumnFour}
        </Typography>
      </Grid>
      <Grid
        item
        xs={2}
        className={classnames({
          [`${classes.extremelyHighRiskContainer}`]: severityColumnFive === 'E',
          [`${classes.highRiskContainer}`]: severityColumnFive === 'H',
          [`${classes.moderateRiskContainer}`]: severityColumnFive === 'M',
          [`${classes.lowRiskContainer}`]: severityColumnFive === 'L'
        })}>
        <Typography
          className={classnames(classes.riskLabel, {
            [`${classes.extremelyHighRiskLabel}`]: severityColumnFive === 'E',
            [`${classes.highRiskLabel}`]: severityColumnFive === 'H',
            [`${classes.moderateRiskLabel}`]: severityColumnFive === 'M',
            [`${classes.lowRiskLabel}`]: severityColumnFive === 'L'
          })}>
          {severityColumnFive}
        </Typography>
      </Grid>
    </Grid>
  );
};

RiskAssessmentMatrixRow.propTypes = {
  severityLevel: PropTypes.string.isRequired,
  severityColumnOne: PropTypes.string.isRequired,
  severityColumnTwo: PropTypes.string.isRequired,
  severityColumnThree: PropTypes.string.isRequired,
  severityColumnFour: PropTypes.string.isRequired,
  severityColumnFive: PropTypes.string.isRequired
};

const RiskAssessmentMatrix = ({ ahaTemplate }) => {
  const styles = useRiskAssessmentMatrixStyles();
  const classes = styles();
  //TODO: TRANSLATES
  //const { t } = useTranslation();

  const frequencyLevels = [
    'Frequent',
    'Likely',
    'Occasional',
    'Seldom',
    'Unlikely'
  ];

  const getHighestRiskAssessmentScore = () => {
    const codes = ahaTemplate?.ahaSteps
      ?.filter(step => step.isActive === true)
      .map(step => step.riskAssessmentCode);

    if (codes) {
      if (codes.includes('ExtremelyHigh')) {
        return 'E';
      }

      if (codes.includes('High')) {
        return 'H';
      }

      if (codes.includes('Moderate')) {
        return 'M';
      }

      if (codes.includes('Low')) {
        return 'L';
      }

      if (codes.length < 1) {
        return 'No Steps';
      }
    }
  };

  const score = getHighestRiskAssessmentScore();

  return (
    <Grid container>
      <Grid item xs={12} className={classes.highestScoreRow}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={9}>
            <Typography
              color="textPrimary"
              className={classes.highestScoreLabel}>
              Overall Risk Assessment Code (RAC - highest score)
            </Typography>
          </Grid>
          <Grid
            item
            xs={3}
            className={classnames(classes.highestScoreContainer, {
              [`${classes.extremelyHighRiskBg}`]: score === 'E',
              [`${classes.highRiskBg}`]: score === 'H',
              [`${classes.moderateRiskBg}`]: score === 'M',
              [`${classes.lowRiskBg}`]: score === 'L',
              [`${classes.noStepsBg}`]: score === 'No Steps'
            })}>
            <Grid
              container
              justifyContent="center"
              alignContent="center"
              style={{ height: '100%' }}>
              <Grid item>
                <Typography
                  className={classnames(classes.riskLabel, {
                    [`${classes.extremelyHighRiskLabel}`]: score === 'E',
                    [`${classes.highRiskLabel}`]: score === 'H',
                    [`${classes.moderateRiskLabel}`]: score === 'M',
                    [`${classes.lowRiskLabel}`]: score === 'L',
                    [`${classes.noStepsLabel}`]: score === 'No Steps'
                  })}>
                  {score}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.row}>
        <Typography color="textPrimary" className={classes.matrixTitle}>
          Risk Assessment Code (RAC) Matrix
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12} sm={10} md={12} lg={10}>
            <Grid container>
              <Grid item xs={2} implementation="css" component={Hidden} />
              <Grid item xs={10}>
                <Typography
                  color="textPrimary"
                  className={classes.probabilityAxisLabel}>
                  Probability
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography
                  color="textPrimary"
                  className={classes.severityAxisLabel}>
                  Severity
                </Typography>
              </Grid>
              {frequencyLevels.map((level, index) => (
                <Grid item xs={2} key={index}>
                  <Typography
                    className={classes.probabilityLabel}
                    color="textSecondary">
                    {level}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12} sm={10} md={12} lg={10}>
            <RiskAssessmentMatrixRow
              severityLevel={'Catastrophic'}
              severityColumnOne={'E'}
              severityColumnTwo={'E'}
              severityColumnThree={'H'}
              severityColumnFour={'H'}
              severityColumnFive={'M'}
            />
            <Grid item xs={12}>
              <RiskAssessmentMatrixRow
                severityLevel={'Critical'}
                severityColumnOne={'E'}
                severityColumnTwo={'H'}
                severityColumnThree={'H'}
                severityColumnFour={'M'}
                severityColumnFive={'L'}
              />
            </Grid>
            <Grid item xs={12}>
              <RiskAssessmentMatrixRow
                severityLevel={'Marginal'}
                severityColumnOne={'H'}
                severityColumnTwo={'M'}
                severityColumnThree={'M'}
                severityColumnFour={'L'}
                severityColumnFive={'L'}
              />
            </Grid>
            <Grid item xs={12}>
              <RiskAssessmentMatrixRow
                severityLevel={'Negligible'}
                severityColumnOne={'M'}
                severityColumnTwo={'L'}
                severityColumnThree={'L'}
                severityColumnFour={'L'}
                severityColumnFive={'L'}
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={2}
            md={12}
            lg={2}
            className={classes.racLegendContainer}>
            <RiskAssessmentLegend />
          </Grid>
        </Grid>
      </Grid>
      <RiskAssessmentInstructions />
    </Grid>
  );
};

RiskAssessmentMatrix.propTypes = {
  ahaTemplate: PropTypes.object
};

export default RiskAssessmentMatrix;
