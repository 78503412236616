import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import uniqBy from 'lodash.uniqby';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useRouteMatch } from 'react-router-dom';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import makeStyles from '@mui/styles/makeStyles';
import {
  List,
  ListItem,
  ListItemText,
  Collapse,
  Typography,
  Link as MuiLink,
  Grid,
  Tooltip
} from '@mui/material';

import useCurrentUser from 'hooks/useCurrentUser';
import useRoles from 'hooks/useRoles';
import useProject from 'hooks/useProject';
import PrimaryAppBarNavigationProjectMenuListItem from '../ProjectMenuListItem';
import useIsOnline from 'store/onlineDetection';
import {
  CSSR_URL,
  TRADE_PARTNER_TRAININGS_URL,
  SAFETY_DEPARTMENT_URL,
  SAFETY_AND_HEALTH_MANUAL_URL,
  SAFETY_OBSERVATIONS_REPORT_URL,
  SILVERBOOK_URL,
  GOLD_REPORT_URL,
  LINK_TRAINING_REPORT_URL,
  CULTURE_OF_CARE_URL
} from 'constants/externalLinks';
import useSafeProject from 'hooks/useSafeProject';
import { domain, Domain } from 'config/domain';

const homePageLink = '/';
const masterProjectListPageRouteLink = '/projects';
const masterCompanyListPageRouteLink = '/companies';
const masterAHAListPageRouteLink = '/ahas';
const unlinkedEmployeesPageRouteLink = '/unlinkedHPEmployees';
const transferPersonnelPageRouteLink = '/transfer';

const useStyles = makeStyles(theme => ({
  container: { width: 300 },
  listItemText: {
    fontSize: '0.875rem',
    maxWidth: 240,
    whiteSpace: 'noWrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  nested: { paddingLeft: theme.spacing(4) },
  adUserInfoWrapper: { padding: theme.spacing(3) },
  adUserJobTitleText: { fontSize: '0.875rem' },
  muiLinkTextColor: { color: theme.palette.background.contrastText }
}));

const SoteriaMuiLink = ({ onClick, to, label }) => {
  const classes = useStyles();
  return (
    <MuiLink
      className={classes.muiLinkTextColor}
      component={RouterLink}
      onClick={onClick}
      to={to}
      underline="none">
      <ListItem button className={classes.nested}>
        <ListItemText>
          <Typography className={classes.listItemText}>{label}</Typography>
        </ListItemText>
      </ListItem>
    </MuiLink>
  );
};

SoteriaMuiLink.propTypes = {
  onClick: PropTypes.func.isRequired,
  to: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

const SoteriaListItem = ({ onClick, label }) => {
  const classes = useStyles();
  return (
    <ListItem button className={classes.nested} onClick={onClick}>
      <ListItemText>
        <Typography className={classes.listItemText}>{label}</Typography>
      </ListItemText>
    </ListItem>
  );
};

SoteriaListItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired
};

const SideNavigationMenuList = ({ closeDrawer }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const match = useRouteMatch('/projects/:projectId');
  const projectId =
    match && match.params && match.params.projectId
      ? match.params.projectId
      : null;
  const { currentUser } = useCurrentUser();
  const {
    isEnterpriseAdmin,
    isSupportRole,
    isAdminTypeRole,
    isAhaAdmin
  } = useRoles();
  const isAdmin = isAdminTypeRole(projectId);
  const { project: projectAsAdmin } = useProject(projectId, !isAdmin);
  const { safeProject } = useSafeProject(projectId);
  const [isMyProjectsOpen, setIsMyProjectsOpen] = useState(false);
  const [isReportsOpen, setIsReportsOpen] = useState(false);
  const [isResourcesOpen, setIsResourcesOpen] = useState(false);
  const [isToolsOpen, setIsToolsOpen] = useState(false);
  const [isAdminOpen, setIsAdminOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const project = isAdmin ? projectAsAdmin : safeProject;
  const myProjects = uniqBy(
    [
      ...(currentUser?.projects ?? []),
      ...(currentUser?.safeProjects ?? []),
      ...(currentUser?.safeProjectsAsPartner ?? [])
    ],
    'id'
  );
  const { isOnline } = useIsOnline();

  return (
    <Grid container justifyContent="center" className={classes.container}>
      <Grid item>
        <List component="nav">
          <MuiLink
            className={classes.muiLinkTextColor}
            component={RouterLink}
            to={homePageLink}
            onClick={closeDrawer}
            underline="none">
            <ListItem button style={{ width: 300 }}>
              <ListItemText>
                <Typography className={classes.listItemText}>
                  {t('navigation.home')}
                </Typography>
              </ListItemText>
            </ListItem>
          </MuiLink>
          {project && (
            <PrimaryAppBarNavigationProjectMenuListItem
              project={project}
              isNested={false}
              setSelectedItem={setSelectedItem}
              selectedItem={selectedItem}
              closeDrawer={closeDrawer}
              isAdmin={isAdmin}
            />
          )}
          {isOnline && myProjects.length > 0 && (
            <Fragment>
              <ListItem
                button
                onClick={() => setIsMyProjectsOpen(!isMyProjectsOpen)}>
                <ListItemText>
                  <Typography className={classes.listItemText}>
                    {t('navigation.myProjects.heading')}
                  </Typography>
                </ListItemText>
                {isMyProjectsOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={isMyProjectsOpen} timeout="auto" unmountOnExit>
                {myProjects.map(project => (
                  <MuiLink
                    className={classes.muiLinkTextColor}
                    key={project.id}
                    component={RouterLink}
                    to={`/projects/${project.id}/dashboard`}
                    onClick={() => {
                      localStorage.setItem(
                        'lastProjectVisited',
                        JSON.stringify({ projectId: project.id })
                      );
                      closeDrawer();
                    }}
                    underline="none">
                    <ListItem button className={classes.nested}>
                      <ListItemText data-testid="individual-project">
                        <Tooltip title={project.name}>
                          <Typography className={classes.listItemText}>
                            {project.name}
                          </Typography>
                        </Tooltip>
                      </ListItemText>
                    </ListItem>
                  </MuiLink>
                ))}
              </Collapse>
            </Fragment>
          )}
          {isOnline && (isEnterpriseAdmin || isAhaAdmin) && (
            <Fragment>
              <ListItem button onClick={() => setIsAdminOpen(!isAdminOpen)}>
                <ListItemText>
                  <Typography className={classes.listItemText}>
                    {t('navigation.admin.heading')}
                  </Typography>
                </ListItemText>
                {isAdminOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={isAdminOpen} timeout="auto" unmountOnExit>
                {isEnterpriseAdmin && (
                  <SoteriaMuiLink
                    onClick={closeDrawer}
                    to={masterProjectListPageRouteLink}
                    label={t('navigation.admin.masterProjectList')}
                  />
                )}
                {isEnterpriseAdmin && (
                  <SoteriaMuiLink
                    onClick={closeDrawer}
                    to={masterCompanyListPageRouteLink}
                    label={t('navigation.admin.masterCompanyList')}
                  />
                )}
                {isAhaAdmin && (
                  <SoteriaMuiLink
                    onClick={closeDrawer}
                    to={masterAHAListPageRouteLink}
                    label={t('navigation.admin.masterAHAList')}
                  />
                )}
              </Collapse>
            </Fragment>
          )}
          {isOnline && domain === Domain.PRIMARY && (
            <Fragment>
              <ListItem button onClick={() => setIsReportsOpen(!isReportsOpen)}>
                <ListItemText>
                  <Typography className={classes.listItemText}>
                    {t('navigation.reports.heading')}
                  </Typography>
                </ListItemText>
                {isReportsOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={isReportsOpen} timeout="auto" unmountOnExit>
                <SoteriaListItem
                  onClick={() => {
                    window.open(CSSR_URL, '_blank', 'noopener');
                    closeDrawer();
                  }}
                  label={t(
                    'navigation.resources.links.corporateSafetySummaryReport'
                  )}
                />
                <SoteriaListItem
                  onClick={() => {
                    window.open(GOLD_REPORT_URL, '_blank', 'noopener');
                    closeDrawer();
                  }}
                  label={t('navigation.resources.links.goldReport')}
                />
                <SoteriaListItem
                  onClick={() => {
                    window.open(LINK_TRAINING_REPORT_URL, '_blank', 'noopener');
                    closeDrawer();
                  }}
                  label={t('navigation.resources.links.linkTraining')}
                />
                <SoteriaListItem
                  onClick={() => {
                    window.open(
                      SAFETY_OBSERVATIONS_REPORT_URL,
                      '_blank',
                      'noopener'
                    );
                    closeDrawer();
                  }}
                  label={t('navigation.resources.links.safetyObservations')}
                />
                <SoteriaListItem
                  onClick={() => {
                    window.open(
                      TRADE_PARTNER_TRAININGS_URL,
                      '_blank',
                      'noopener'
                    );
                    closeDrawer();
                  }}
                  label={t('navigation.resources.links.tradePartnerTraining')}
                />
              </Collapse>
              <ListItem
                button
                onClick={() => setIsResourcesOpen(!isResourcesOpen)}>
                <ListItemText>
                  <Typography className={classes.listItemText}>
                    {t('navigation.resources.heading')}
                  </Typography>
                </ListItemText>
                {isResourcesOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={isResourcesOpen} timeout="auto" unmountOnExit>
                <SoteriaListItem
                  onClick={() => {
                    window.open(CULTURE_OF_CARE_URL, '_blank', 'noopener');
                    closeDrawer();
                  }}
                  label={t('navigation.resources.links.cultureOfCarePage')}
                />
                <SoteriaListItem
                  onClick={() => {
                    window.open(SAFETY_DEPARTMENT_URL, '_blank', 'noopener');
                    closeDrawer();
                  }}
                  label={t('navigation.resources.links.safetyDepartmentPage')}
                />
                <SoteriaListItem
                  onClick={() => {
                    window.open(
                      SAFETY_AND_HEALTH_MANUAL_URL,
                      '_blank',
                      'noopener'
                    );
                    closeDrawer();
                  }}
                  label={t('navigation.resources.links.safetyAndHealthManual')}
                />
                <SoteriaListItem
                  onClick={() => {
                    window.open(SILVERBOOK_URL, '_blank', 'noopener');
                    closeDrawer();
                  }}
                  label={t('navigation.resources.links.silverbook')}
                />
              </Collapse>
              <ListItem button onClick={() => setIsToolsOpen(!isToolsOpen)}>
                <ListItemText>
                  <Typography className={classes.listItemText}>
                    {t('navigation.tools.heading')}
                  </Typography>
                </ListItemText>
                {isToolsOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={isToolsOpen} timeout="auto" unmountOnExit>
                {isSupportRole && (
                  <SoteriaMuiLink
                    onClick={closeDrawer}
                    to={transferPersonnelPageRouteLink}
                    label={t('navigation.transferPersonnel')}
                  />
                )}
                {(isEnterpriseAdmin || isSupportRole) && (
                  <SoteriaMuiLink
                    onClick={closeDrawer}
                    to={unlinkedEmployeesPageRouteLink}
                    label={t('navigation.unlinkedEmployees')}
                  />
                )}
              </Collapse>
            </Fragment>
          )}
        </List>
      </Grid>
    </Grid>
  );
};

SideNavigationMenuList.propTypes = {
  closeDrawer: PropTypes.func.isRequired
};

export default SideNavigationMenuList;
