import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

const StyledConfirmationSwitch = ({ disabled, checked, onChange, value }) => {
  const { t } = useTranslation();

  return (
    <Grid container alignItems="center" direction="column">
      <Grid item xs={12}>
        <Typography>{t('confirmationSwitch.continuePrompt')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <FormGroup row>
          <FormControlLabel
            labelPlacement="end"
            control={
              <Switch
                color="primary"
                disabled={disabled}
                inputProps={{
                  'data-testid': 'confirm-input-switch',
                  role: 'button',
                  'aria-pressed': checked,
                  'aria-label': `toggle confirmation switch to ${checked}`
                }}
                checked={checked}
                onChange={onChange}
                value={value}
              />
            }
            label={t('confirmationSwitch.continueConfirmed')}
          />
        </FormGroup>
      </Grid>
    </Grid>
  );
};

StyledConfirmationSwitch.propTypes = {
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string
};

export default StyledConfirmationSwitch;
