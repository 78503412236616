import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-final-form-hooks';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { useField } from 'react-final-form-hooks';
import {
  Grid,
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepContent
} from '@mui/material';

import useToast from 'hooks/useToast';
import useFormValidation from 'hooks/useFormValidation';
import StyledDialog from 'shared/Dialog';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import StyledButtonSecondary from 'shared/Buttons/ButtonSecondary';
import {
  ConfirmHpEmployeeStep,
  SelectAdUserStep
} from 'components/personnel/LinkHpPersonnelSteps';
import { UPDATE_PERSONNEL } from 'graphql/personnel';
import { PERSONNEL_SEARCH } from 'graphql/personnel';

const LinkHpPersonnelDialog = ({ isOpen, toggleDialog, personnel }) => {
  const { t } = useTranslation();
  const [activeStepIndex, setActiveStep] = useState(0);
  const { displayToast } = useToast();
  const [areMatchesFound, setAreMatchesFound] = useState(false);
  const { isRequired } = useFormValidation();

  const [updatePersonnel, { loading: isLoading }] = useMutation(
    UPDATE_PERSONNEL
  );

  const { form, values } = useForm({
    /* istanbul ignore next */
    onSubmit: () => {}, // this function required for useForm but is not used
    initialValues: {
      personnelConfirmation: false
    }
  });

  const { errors } = form.getState();

  const selectedHenselPhelpsEmployee = useField(
    'selectedHenselPhelpsEmployee',
    form,
    isRequired
  );

  const [
    checkApiForDupes,
    { data, loading: isLoadingSearchResults }
  ] = useLazyQuery(PERSONNEL_SEARCH, {
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    if (
      !areMatchesFound &&
      data?.personnels.length > 1 &&
      selectedHenselPhelpsEmployee.input?.value !== null
    ) {
      setAreMatchesFound(true);
    }

    if (
      !areMatchesFound &&
      data?.personnels.length > 1 &&
      selectedHenselPhelpsEmployee.input?.value === null
    ) {
      setAreMatchesFound(false);
    }
  }, [areMatchesFound, data, selectedHenselPhelpsEmployee, setAreMatchesFound]);

  const steps = ['Select Personnel', 'Confirmation'];

  const isComplete = activeStepIndex === steps.length - 1;

  const handleClose = () => {
    setActiveStep(0);
    form.reset();
    toggleDialog(false);
  };

  const handleNext = () => {
    setActiveStep(prevActiveStepIndex => prevActiveStepIndex + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStepIndex => prevActiveStepIndex - 1);
  };

  const handleSubmit = () => {
    updatePersonnel({
      variables: {
        input: {
          id: personnel.id,
          upn: values.selectedHenselPhelpsEmployee.upn
        }
      }
    })
      .then(({ data: { updatePersonnel: personnel } }) => {
        displayToast(
          t('linkHpPersonnelDialog.update.success', {
            personnelName: personnel.fullName
          }),
          'success'
        );
        handleClose();
      })
      .catch(error => {
        console.error('Update Personnel Error: ', error);
        displayToast(t('linkHpPersonnelDialog.toasts.update.error'), 'error');
      });
  };

  return (
    <Fragment>
      <StyledDialog
        data-testid="link-hp-personnel-dialog"
        isOpen={isOpen}
        handleClose={handleClose}
        title={t('linkHpPersonnelDialog.title')}
        isLoading={isLoading}
        content={
          <form>
            <Grid container justifyContent="center">
              <Grid item xs={12}>
                <Stepper orientation="vertical" activeStep={activeStepIndex}>
                  {steps.map(label => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                      <StepContent>
                        <Typography component="div">
                          {activeStepIndex === 0 && (
                            <SelectAdUserStep
                              form={form}
                              isLoading={isLoading}
                              isLoadingSearchResults={isLoadingSearchResults}
                              areMatchesFound={areMatchesFound}
                              setAreMatchesFound={setAreMatchesFound}
                              checkApiForDupes={checkApiForDupes}
                              selectedHenselPhelpsEmployee={
                                selectedHenselPhelpsEmployee
                              }
                            />
                          )}
                          {activeStepIndex === 1 && (
                            <ConfirmHpEmployeeStep
                              selectedHenselPhelpsEmployee={
                                values.selectedHenselPhelpsEmployee
                              }
                              form={form}
                            />
                          )}
                        </Typography>
                      </StepContent>
                    </Step>
                  ))}
                </Stepper>
              </Grid>
            </Grid>
          </form>
        }
        actions={
          <Fragment>
            <Grid container justifyContent="space-between">
              <Grid item>
                <StyledButtonSecondary
                  label={t('linkHpPersonnelDialog.actions.cancelButton')}
                  disabled={isLoading}
                  onClick={handleClose}
                />
              </Grid>
              <Grid item>
                <Grid container justifyContent="flex-end" direction="row" spacing={2}>
                  <Grid item>
                    {activeStepIndex !== 0 && (
                      <StyledButtonPrimary
                        disabled={isLoading}
                        label={t('linkHpPersonnelDialog.actions.backButton')}
                        onClick={handleBack}
                      />
                    )}
                  </Grid>
                  <Grid item>
                    {!isComplete && (
                      <StyledButtonPrimary
                        label={t('linkHpPersonnelDialog.actions.nextButton')}
                        disabled={
                          !!errors.selectedHenselPhelpsEmployee ||
                          areMatchesFound ||
                          isLoading ||
                          isLoadingSearchResults
                        }
                        onClick={handleNext}
                      />
                    )}
                    {isComplete && (
                      <StyledButtonPrimary
                        label={t('linkHpPersonnelDialog.actions.submitButton')}
                        disabled={
                          !!(
                            errors.selectedHenselPhelpsEmployee ||
                            errors.personnelConfirmation
                          )
                        }
                        onClick={handleSubmit}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Fragment>
        }
      />
    </Fragment>
  );
};

LinkHpPersonnelDialog.propTypes = {
  isOpen: PropTypes.bool,
  toggleDialog: PropTypes.func,
  personnel: PropTypes.object
};

export default LinkHpPersonnelDialog;
