import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography, Divider, Hidden } from '@mui/material';

import useAhaReview from 'hooks/useAhaReview';

const useStyles = makeStyles(theme => ({
  dividerRoot: { width: '100%', backgroundColor: 'black' },
  highestScoreLabel: {
    fontSize: '0.875rem',
    padding: theme.spacing(0, 1)
  },
  smallFontSize: { fontSize: '0.625rem' },
  mediumFontSize: { fontSize: '0.75rem' },
  regularFontSize: { fontSize: '0.875rem' },
  centeredText: { textAlign: 'center' },
  rightBorder: { boxSize: 'border-box', borderRight: '1px solid black' },
  leftBorder: { boxSize: 'border-box', borderLeft: '1px solid black' },
  paddingLeft: { paddingLeft: theme.spacing(0.5) },
  extremelyHighRiskColor: { backgroundColor: '#FF0909' },
  highRiskColor: { backgroundColor: '#F1B700' },
  moderateRiskColor: { backgroundColor: '#F1EA00' },
  lowRiskColor: { backgroundColor: '#3FDC00' },
  fullHeight: { height: '100%' },
  instructionsText: {
    lineHeight: 1,
    paddingTop: '.25rem',
    fontSize: '0.5625rem',
    paddingLeft: theme.spacing(0.5)
  },
  divider: { padding: 0, margin: 0, width: '100%' },
  matrixLabel: { fontWeight: 'bold', fontSize: '0.75rem' },
  highestScore: {
    textAlign: 'center',
    fontSize: '0.625rem',
    marginTop: theme.spacing(0.5)
  }
}));

const RiskMatrixSection = ({ ahaTemplate }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { ahaReviewId } = useParams();
  const { ahaReview } = useAhaReview(ahaReviewId);

  const getHighestRiskAssessmentScore = () => {
    const codes = ahaReview
      ? ahaReview?.ahaTemplate?.ahaSteps?.map(step => step.riskAssessmentCode)
      : ahaTemplate?.ahaSteps?.map(step => step.riskAssessmentCode);

    if (codes) {
      if (codes.includes('ExtremelyHigh')) {
        return 'E';
      }

      if (codes.includes('High')) {
        return 'H';
      }

      if (codes.includes('Moderate')) {
        return 'M';
      }

      if (codes.includes('Low')) {
        return 'L';
      }
    }
  };

  const score = getHighestRiskAssessmentScore();

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container justifyContent="space-between">
          <Grid item xs={11}>
            <Typography className={classes.highestScoreLabel}>
              {t('riskMatrixSection.highestScore.label')}
            </Typography>
          </Grid>
          <Grid
            item
            xs={1}
            className={classnames(classes.leftBorder, {
              [`${classes.extremelyHighRiskColor}`]: score === 'E',
              [`${classes.highRiskColor}`]: score === 'H',
              [`${classes.moderateRiskColor}`]: score === 'M',
              [`${classes.lowRiskColor}`]: score === 'L'
            })}>
            <Grid
              container
              justifyContent="center"
              alignContent="center"
              className={classes.fullHeight}>
              <Grid item>
                <Typography className={classes.highestScore}>
                  {score}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <hr className={classes.divider} />
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={2} implementation="css" component={Hidden} />
          <Grid item xs={10}>
            <Typography className={classes.matrixLabel}>
              {t('riskMatrixSection.matrix.title')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <hr className={classes.divider} />
      <Grid item xs={12}>
        <Grid container alignItems="center">
          <Grid item xs={2}>
            <Typography
              className={classnames(
                'bold',
                classes.centeredText,
                classes.mediumFontSize
              )}>
              {t('riskMatrixSection.severityAxis.name')}
            </Typography>
          </Grid>
          <Grid item xs={10} className={classes.leftBorder}>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  className={classnames(
                    'bold',
                    classes.centeredText,
                    classes.mediumFontSize
                  )}>
                  {t('riskMatrixSection.probabilityAxis.name')}
                </Typography>
              </Grid>
              <hr className={classes.divider} />
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs>
                    <Typography
                      className={classnames(
                        classes.smallFontSize,
                        classes.centeredText,
                        classes.rightBorder
                      )}>
                      {t('riskMatrixSection.probabilityLevel.frequent')}
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography
                      className={classnames(
                        classes.smallFontSize,
                        classes.centeredText,
                        classes.rightBorder
                      )}>
                      {t('riskMatrixSection.probabilityLevel.likely')}
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography
                      className={classnames(
                        classes.smallFontSize,
                        classes.centeredText,
                        classes.rightBorder
                      )}>
                      {t('riskMatrixSection.probabilityLevel.occasional')}
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography
                      className={classnames(
                        classes.smallFontSize,
                        classes.centeredText,
                        classes.rightBorder
                      )}>
                      {t('riskMatrixSection.probabilityLevel.seldom')}
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography
                      className={classnames(
                        classes.smallFontSize,
                        classes.centeredText
                      )}>
                      {t('riskMatrixSection.probabilityLevel.unlikely')}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <hr className={classes.divider} />
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={2}>
            <Typography
              className={classnames(
                classes.smallFontSize,
                classes.centeredText,
                classes.rightBorder
              )}>
              {t('riskMatrixSection.severityLevel.catastrophic')}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              className={classnames(
                classes.smallFontSize,
                classes.centeredText,
                classes.rightBorder,
                classes.extremelyHighRiskColor
              )}>
              E
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              className={classnames(
                classes.smallFontSize,
                classes.centeredText,
                classes.rightBorder,
                classes.extremelyHighRiskColor
              )}>
              E
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              className={classnames(
                classes.smallFontSize,
                classes.centeredText,
                classes.rightBorder,
                classes.highRiskColor
              )}>
              H
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              className={classnames(
                classes.smallFontSize,
                classes.centeredText,
                classes.rightBorder,
                classes.highRiskColor
              )}>
              H
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              className={classnames(
                classes.smallFontSize,
                classes.centeredText,
                classes.moderateRiskColor
              )}>
              M
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <hr className={classes.divider} />
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={2}>
            <Typography
              className={classnames(
                classes.smallFontSize,
                classes.centeredText,
                classes.rightBorder
              )}>
              {t('riskMatrixSection.severityLevel.critical')}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              className={classnames(
                classes.smallFontSize,
                classes.centeredText,
                classes.rightBorder,
                classes.extremelyHighRiskColor
              )}>
              E
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              className={classnames(
                classes.smallFontSize,
                classes.centeredText,
                classes.rightBorder,
                classes.highRiskColor
              )}>
              H
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              className={classnames(
                classes.smallFontSize,
                classes.centeredText,
                classes.rightBorder,
                classes.highRiskColor
              )}>
              H
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              className={classnames(
                classes.smallFontSize,
                classes.centeredText,
                classes.rightBorder,
                classes.moderateRiskColor
              )}>
              M
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              className={classnames(
                classes.smallFontSize,
                classes.centeredText,
                classes.lowRiskColor
              )}>
              L
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <hr className={classes.divider} />
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={2}>
            <Typography
              className={classnames(
                classes.smallFontSize,
                classes.centeredText,
                classes.rightBorder
              )}>
              {t('riskMatrixSection.severityLevel.marginal')}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              className={classnames(
                classes.smallFontSize,
                classes.centeredText,
                classes.rightBorder,
                classes.highRiskColor
              )}>
              H
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              className={classnames(
                classes.smallFontSize,
                classes.centeredText,
                classes.rightBorder,
                classes.moderateRiskColor
              )}>
              M
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              className={classnames(
                classes.smallFontSize,
                classes.centeredText,
                classes.rightBorder,
                classes.moderateRiskColor
              )}>
              M
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              className={classnames(
                classes.smallFontSize,
                classes.centeredText,
                classes.rightBorder,
                classes.lowRiskColor
              )}>
              L
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              className={classnames(
                classes.smallFontSize,
                classes.centeredText,
                classes.lowRiskColor
              )}>
              L
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <hr className={classes.divider} />
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={2}>
            <Typography
              className={classnames(
                classes.smallFontSize,
                classes.centeredText,
                classes.rightBorder
              )}>
              {t('riskMatrixSection.severityLevel.negligible')}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              className={classnames(
                classes.smallFontSize,
                classes.centeredText,
                classes.rightBorder,
                classes.moderateRiskColor
              )}>
              M
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              className={classnames(
                classes.smallFontSize,
                classes.centeredText,
                classes.rightBorder,
                classes.lowRiskColor
              )}>
              L
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              className={classnames(
                classes.smallFontSize,
                classes.centeredText,
                classes.rightBorder,
                classes.lowRiskColor
              )}>
              L
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              className={classnames(
                classes.smallFontSize,
                classes.centeredText,
                classes.rightBorder,
                classes.lowRiskColor
              )}>
              L
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              className={classnames(
                classes.smallFontSize,
                classes.centeredText,
                classes.lowRiskColor
              )}>
              L
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <hr className={classes.divider} />
      <Grid item xs={12}>
        <Typography className={classes.instructionsText}>
          {t('riskMatrixSection.matrixInstructions.stepOne')}
        </Typography>
      </Grid>
      <hr className={classes.divider} />
      <Grid item xs={12}>
        <Grid container alignItems="center">
          <Grid item xs={8} className={classes.rightBorder}>
            <Typography className={classes.instructionsText}>
              {t('riskMatrixSection.matrixInstructions.probabilityDefinition')}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              className={classnames(
                classes.mediumFontSize,
                classes.centeredText
              )}>
              {t('riskMatrixSection.racChartLabel')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <hr className={classes.divider} />
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={8} className={classes.rightBorder}>
            <Typography className={classes.instructionsText}>
              {t('riskMatrixSection.matrixInstructions.severityDefinition')}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  className={classnames(
                    classes.smallFontSize,
                    classes.paddingLeft
                  )}>
                  {t('riskMatrixSection.racChartLegend.extremelyHighRisk')}
                </Typography>
              </Grid>
              <Divider classes={{ root: classes.dividerRoot }} />
              <Grid item xs={12}>
                <Typography
                  className={classnames(
                    classes.smallFontSize,
                    classes.paddingLeft
                  )}>
                  {t('riskMatrixSection.racChartLegend.highRisk')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <hr className={classes.divider} />
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={8} className={classes.rightBorder}>
            <Typography className={classes.instructionsText}>
              {t('riskMatrixSection.matrixInstructions.stepTwo')}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  className={classnames(
                    classes.smallFontSize,
                    classes.paddingLeft
                  )}>
                  {t('riskMatrixSection.racChartLegend.moderateRisk')}
                </Typography>
              </Grid>
              <hr className={classes.divider} />
              <Grid item xs={12}>
                <Typography
                  className={classnames(
                    classes.smallFontSize,
                    classes.paddingLeft
                  )}>
                  {t('riskMatrixSection.racChartLegend.lowRisk')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

RiskMatrixSection.propTypes = {
  ahaTemplate: PropTypes.object
};

export default RiskMatrixSection;
