import React from 'react';
import { Grid, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import VideoPlayer from '../../VideoPlayer';

const useStyles = makeStyles(theme => ({
  subTitle: { fontSize: '0.875rem' },
  title: { fontSize: '1.5rem' }
}));

const MyProjects = () => {
  const classes = useStyles();

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography className={classes.subTitle} color="textSecondary">
          Projects Tutorial
        </Typography>
        <Typography className={classes.title} color="textPrimary">
          My Projects
        </Typography>
      </Grid>
      <Grid item>
        <Typography color="textPrimary">
          Quickly navigate to any project that you have administrative access
          to.
        </Typography>
      </Grid>
      <Grid item>
        <VideoPlayer
          data-testid="my-projects-video"
          url="/tutorials/projects/MyProjects.mp4"
          tracks={[
            {
              kind: 'subtitles',
              src: '/tutorials/projects/MyProjects.en.vtt',
              srcLang: 'en',
              default: true
            }
          ]}
        />
      </Grid>
    </Grid>
  );
};

export default MyProjects;
