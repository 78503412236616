import { useQuery } from '@apollo/react-hooks';
import useIsOnline from 'store/onlineDetection';
import useOfflineModeCapabilities from 'store/manualOfflineMode';
import { SOTERIA_FETCH_POLICIES } from 'constants/soteriaFetchPolicies';

const IS_TEST_ENV = process.env.NODE_ENV === 'test';

const getApolloFetchPolicy = soteriaFetchPolicy => {
  // Apollo Mocked Provider does not work with any fetch policy other than no-cache
  if (IS_TEST_ENV) {
    return 'no-cache';
  }

  switch (soteriaFetchPolicy) {
    case SOTERIA_FETCH_POLICIES.cacheOnly:
      return 'cache-only';
    case SOTERIA_FETCH_POLICIES.onlineOnly:
      return 'no-cache';
    default:
      return 'cache-first';
  }
};

const useSoteriaQuery = ({
  gql,
  queryHookOptions,
  soteriaQueryOptions: { soteriaFetchPolicy } = {}
}) => {
  const { isOnline } = useIsOnline();
  const { isOfflineModeEnabled } = useOfflineModeCapabilities();

  // getting page what it needs
  // TODO: catch errors if not found in cache for cache-only
  const { data, loading, error, refetch } = useQuery(gql, {
    ...queryHookOptions,
    fetchPolicy: getApolloFetchPolicy(soteriaFetchPolicy)
  });

  // making sure that cache is always up to date if offline mode is enabled since we persist the cache
  // runs if online and the current page supports the cache
  useQuery(gql, {
    ...queryHookOptions,
    fetchPolicy: 'network-only',
    skip:
      IS_TEST_ENV ||
      soteriaFetchPolicy === SOTERIA_FETCH_POLICIES.onlineOnly ||
      soteriaFetchPolicy === SOTERIA_FETCH_POLICIES.cacheOnly ||
      queryHookOptions?.skip ||
      !isOnline ||
      !isOfflineModeEnabled
  });

  return { data, loading, error, refetch };
};

export default useSoteriaQuery;
