import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import {
  Grid,
  Typography,
  Button,
  TextField,
  IconButton,
  Menu,
  MenuItem,
  ListItemText,
  FormHelperText,
  Link
} from '@mui/material';

import useSafeProject from 'hooks/useSafeProject';
import { STEP_REFERENCE_OPTIONS } from 'constants/ahas';

const useStyles = makeStyles(theme => ({
  referencesLabel: { fontSize: '0.875rem', fontWeight: 'bold' },
  referencesMenuButton: {
    boxShadow: 'none',
    border: '1px solid gray',
    padding: theme.spacing(0, 1),
    backgroundColor: theme.palette.secondary.main
  },
  referencesMenuButtonLabel: {
    fontSize: '0.75rem',
    fontWeight: 'bold',
    margin: theme.spacing(0, 2, 0, 1),
    verticalAlign: 'top'
  },
  selectedReferenceLabel: {
    display: 'inline',
    fontSize: '0.875rem',
    fontWeight: 'bold',
    marginRight: theme.spacing(2)
  },
  fieldContainer: {
    width: 800,
    [theme.breakpoints.down('xl')]: { width: 500 },
    [theme.breakpoints.down('lg')]: { width: 350 },
    [theme.breakpoints.down('md')]: { width: 150 }
  },
  field: { display: 'inline-block' },
  errorText: { fontSize: '0.75rem' },
  icon: { marginTop: 6 },
  link: { '&:hover': { cursor: 'pointer' } },
  noReferencesMessage: { fontSize: '0.75rem' }
}));

const StyledMenu = withStyles({
  paper: { border: '1px solid #d3d4d5' }
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    width: 210,
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white
      }
    }
  }
}))(MenuItem);

const StepReferences = ({
  referencesToUpsert,
  setReferencesToUpsert,
  isGlobal
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { projectId } = useParams();
  const { safeProject } = useSafeProject(projectId);
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();

  const availableOptions = isGlobal
    ? STEP_REFERENCE_OPTIONS
    : safeProject?.availableAhaReferenceOptions;

  const options = isGlobal
    ? availableOptions.filter(
        availableOption =>
          !referencesToUpsert.some(
            referenceToUpsert =>
              referenceToUpsert.ahaReferenceOption === availableOption.value
          )
      )
    : safeProject?.availableAhaReferenceOptions.filter(
        availableOption =>
          !referencesToUpsert.some(
            referenceToUpsert =>
              referenceToUpsert.ahaReferenceOption === availableOption
          )
      );

  const handleMenuButtonClick = event => {
    setAnchorEl(event?.currentTarget);
  };

  const handleMenuItemClick = option => {
    setReferencesToUpsert([
      ...referencesToUpsert,
      {
        label: isGlobal ? option.label : option,
        ahaReferenceOption: isGlobal ? option.value : option,
        value: ''
      }
    ]);
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleReferenceDelete = reference => {
    setReferencesToUpsert(
      referencesToUpsert.filter(
        referenceToUpsert =>
          referenceToUpsert.ahaReferenceOption !== reference.ahaReferenceOption
      )
    );
  };

  const shouldShowAsError = reference => {
    return !reference.value;
  };

  return <>
    <Grid container alignItems="flex-end" spacing={1}>
      <Grid item>
        <Typography className={classes.referencesLabel} color="textSecondary">
          {t('stepReferences.title')}
        </Typography>
      </Grid>
      {options?.length > 0 && (
        <Grid item>
          <Button
            disabled={options.length < 1}
            onClick={handleMenuButtonClick}
            size="small"
            variant="contained"
            className={classes.referencesMenuButton}>
            <Grid container alignItems="center">
              <Grid item>
                <MenuBookIcon color="disabled" className={classes.icon} />
              </Grid>
              <Grid item>
                <Typography
                  className={classes.referencesMenuButtonLabel}
                  color="textSecondary">
                  {t('stepReferences.menuButtonLabel')}
                </Typography>
              </Grid>
              <Grid item>
                <ExpandMoreIcon color="disabled" className={classes.icon} />
              </Grid>
            </Grid>
          </Button>
          {options?.length > 0 && (
            <StyledMenu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}>
              {options.map((option, index) => (
                <StyledMenuItem
                  key={index}
                  onClick={() => handleMenuItemClick(option)}>
                  <ListItemText primary={isGlobal ? option.label : option} />
                </StyledMenuItem>
              ))}
            </StyledMenu>
          )}
        </Grid>
      )}

      {!isGlobal && availableOptions?.length < 1 && (
        <Grid item xs={12}>
          <Typography className={classes.noReferencesMessage}>
            {t('stepReferences.noReferenceOptionsMessagePartOne')}{' '}
            <Link
              className={classes.link}
              onClick={() =>
                history.push(`/projects/${projectId}/projectDetails`)
              }
              underline="hover">
              {t('stepReferences.noReferenceOptionsMessagePartTwo')}
            </Link>
          </Typography>
        </Grid>
      )}
    </Grid>
    {referencesToUpsert.map((reference, index) => (
      <Grid
        container
        key={index}
        direction="row"
        alignItems="center"
        justifyContent="flex-start">
        <Grid item xs={12}>
          <Typography
            className={classes.selectedReferenceLabel}
            color="textSecondary">
            {reference.label}
          </Typography>
        </Grid>
        <Grid item xs={9} className={classes.fieldContainer}>
          <TextField
            value={reference.value}
            onChange={event => {
              reference.value = event.currentTarget.value;
              setReferencesToUpsert(
                referencesToUpsert.map(referenceToUpsert => {
                  if (
                    referenceToUpsert.ahaReferenceOption ===
                    reference.ahaReferenceOption
                  ) {
                    return {
                      ...referenceToUpsert,
                      value: event.currentTarget.value
                    };
                  }

                  return referenceToUpsert;
                })
              );
            }}
            error={shouldShowAsError(reference)}
            className={classes.field}
            fullWidth={true}
            size="small"
            variant="outlined"
            multiline={true}
          />
          {shouldShowAsError(reference) && (
            <FormHelperText>
              <Typography
                color="error"
                component="span"
                className={classes.errorText}>
                {t('stepReferences.errorFieldRequired')}
              </Typography>
            </FormHelperText>
          )}
        </Grid>
        <Grid item xs={3}>
          <IconButton
            size="small"
            onClick={() => handleReferenceDelete(reference)}>
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
    ))}
  </>;
};

StepReferences.propTypes = {
  referencesToUpsert: PropTypes.any.isRequired,
  setReferencesToUpsert: PropTypes.func.isRequired,
  isGlobal: PropTypes.bool
};

export default StepReferences;
