window.addEventListener('error', async err => {
  // On development builds of React, error boundaries don't stop
  // errors from bubbling up to the window error handler
  // https://github.com/facebook/react/issues/12897#issuecomment-410036991
  if (process.env.NODE_ENV !== 'development') {
    console.error(err);

    // Check if serviceWorker exists
    if (!navigator.serviceWorker) {
      return;
    }

    // Only run if serviceWorker is installing or waiting to update to avoid reloading on unrelated errors
    const registration = await navigator.serviceWorker.ready;
    if (registration.installing || registration.waiting) {
      navigator.serviceWorker.ready.then(async registration => {
        console.log(
          'ServiceWorkerErrorHandler: The service worker will now be unregistered!'
        );
        await registration.unregister();
        window.location.reload();
      });
    }
  }
});
