import React, { useState } from 'react';
import PropTypes from 'prop-types';
import copy from 'copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import makeStyles from '@mui/styles/makeStyles';
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ClickAwayListener,
  Grid,
  Link
} from '@mui/material';

import useToast from 'hooks/useToast';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import { SERVICENOW_URL } from 'constants/externalLinks';

const useStyles = makeStyles(theme => ({
  accordion: {
    boxShadow: 'none',
    border: '1px solid ' + theme.palette.field.border,
    borderRadius: 4
  },
  accordionSummary: { height: 36, minHeight: 36 },
  serviceIdContainer: {
    border: '1px solid' + theme.palette.field.border,
    padding: theme.spacing(0.5, 1),
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(1),
    wordBreak: 'break-all'
  },
  serviceNowLink: { cursor: 'pointer' },
  instructions: {
    fontSize: '0.875rem',
    fontWeight: 'bold',
    marginBottom: 16
  },
  serviceId: { fontSize: '0.875rem' },
  buttonContainer: { alignSelf: 'flex-end' }
}));

const ObservationDetails = ({ selectedObservation }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const { displayToast } = useToast();

  const toggleAccordion = () => {
    setIsExpanded(!isExpanded);
  };

  const handleClickAway = () => {
    setIsExpanded(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Accordion
        expanded={isExpanded}
        onChange={toggleAccordion}
        className={classes.accordion}>
        <AccordionSummary
          expandIcon={<KeyboardArrowDownIcon />}
          className={classes.accordionSummary}>
          <Typography variant="subtitle2">
            {t('observationDetails.title')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container direction="column">
            <Grid item>
              <Typography className={classes.instructions}>
                {t('observationDetails.instructions.partOne')}
                <Link
                  className={classes.serviceNowLink}
                  aria-label={'navigate to ServiceNow in a new window'}
                  tabIndex="0"
                  onKeyDown={() =>
                    window.open(SERVICENOW_URL, '_blank', 'noopener')
                  }
                  onClick={() =>
                    window.open(SERVICENOW_URL, '_blank', 'noopener')
                  }
                  underline="hover">
                  {' '}
                  {t('observationDetails.serviceNowLink')}
                </Link>{' '}
                {t('observationDetails.instructions.partTwo')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.serviceId}>
                {t('observationDetails.serviceId.label')}{' '}
              </Typography>
            </Grid>
            <Grid item className={classes.serviceIdContainer}>
              <Typography variant="subtitle2">
                {selectedObservation.id}
              </Typography>
            </Grid>
            <Grid item className={classes.buttonContainer}>
              <Typography>
                <StyledButtonPrimary
                  label={'Copy'}
                  onClick={() =>
                    copy(
                      selectedObservation.id,
                      displayToast(
                        t('observationDetails.toasts.copy.success'),
                        'success'
                      )
                    )
                  }
                  variant="outlined"
                />
              </Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </ClickAwayListener>
  );
};

ObservationDetails.propTypes = {
  selectedObservation: PropTypes.object
};

export default ObservationDetails;
