import { atom, useRecoilState } from 'recoil';
import * as effects from 'store/effects';
import {
  LOCAL_STORAGE_TRADE_PARTNER_AHA_CATEGORY_FILES_LIST_FILTERS_KEY,
  LOCAL_STORAGE_TRADE_PARTNER_AHA_CATEGORY_FILES_SORT_ORDER_KEY
} from 'constants/localStorage';
import {
  TRADE_PARTNER_CATEGORY_FILES_SORT_ORDER_OPTIONS,
  SORT_ORDER_DIRECTION
} from 'constants/ahas';

const DEFAULT_SORT_ORDER = {
  name: TRADE_PARTNER_CATEGORY_FILES_SORT_ORDER_OPTIONS.uploaded.value,
  direction: SORT_ORDER_DIRECTION.ascending.toLowerCase()
};

const getStoredSortOrder = () => {
  const unserializedSortOrder = localStorage.getItem(
    LOCAL_STORAGE_TRADE_PARTNER_AHA_CATEGORY_FILES_SORT_ORDER_KEY
  );
  if (unserializedSortOrder) {
    return JSON.parse(unserializedSortOrder);
  } else {
    return null;
  }
};

const order = getStoredSortOrder() ?? DEFAULT_SORT_ORDER;

const DEFAULT_ROWS_PER_PAGE = 100;

const getStoredFilters = () => {
  const unserializedFilters = localStorage.getItem(
    LOCAL_STORAGE_TRADE_PARTNER_AHA_CATEGORY_FILES_LIST_FILTERS_KEY
  );
  if (unserializedFilters) {
    return JSON.parse(unserializedFilters);
  } else {
    return null;
  }
};

const DEFAULT_FILTERS = {};

const filter = getStoredFilters() ?? DEFAULT_FILTERS;

const defaultState = {
  first: DEFAULT_ROWS_PER_PAGE,
  skip: 0,
  filter,
  order,
  search: '',
  hasActiveFilters: hasActiveFilters(filter)
};

function hasActiveFilters(filterState) {
  let hasActiveFilters = false;

  if (typeof filterState === 'object') {
    for (const value of Object.values(filterState)) {
      if (value) {
        hasActiveFilters = true;
        break;
      }
    }
  }

  return hasActiveFilters;
}

const tradePartnerAhaCategoryFilesListStateAtom = atom({
  key: 'tradePartnerAhaCategoryFilesListState',
  default: defaultState
});

function useTradePartnerAhaCategoryFilesListState() {
  const [
    tradePartnerAhaCategoryFilesListState,
    setTradePartnerAhaCategoryFilesListState
  ] = useRecoilState(tradePartnerAhaCategoryFilesListStateAtom);

  function handleTradePartnerAhaCategoryFilesListStateChange(newState) {
    if (newState?.filter) {
      effects.tradePartnerAhaCategoryFilesListFilters.localStorageSave(
        newState.filter
      );
    }
    if (hasActiveFilters(newState.filter)) {
      newState.hasActiveFilters = true;
    } else {
      newState.hasActiveFilters = false;
    }

    setTradePartnerAhaCategoryFilesListState({
      ...tradePartnerAhaCategoryFilesListState,
      ...newState
    });
  }
  return [
    tradePartnerAhaCategoryFilesListState,
    {
      handleTradePartnerAhaCategoryFilesListStateChange: handleTradePartnerAhaCategoryFilesListStateChange
    }
  ];
}

export default useTradePartnerAhaCategoryFilesListState;
