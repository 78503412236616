import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography } from '@mui/material';

const useStyles = makeStyles(() => ({
  label: { fontWeight: 'bold', fontSize: '0.875rem', display: 'inline' }
}));

const DetailsRow = ({ label, value }) => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container direction="row" spacing={1}>
          <Grid item>
            <Typography color="textSecondary" className={classes.label}>
              {label}:
            </Typography>
          </Grid>
          <Grid item>
            <Typography>{value}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

DetailsRow.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired
};

export default DetailsRow;
