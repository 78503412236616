import gql from 'graphql-tag';

// Subscriptions
export const TRADE_PARTNER_PERSONNEL_SUBSCRIPTION = gql`
  subscription tradePartnerPersonnel($tradePartnerPersonnelId: ID!) {
    tradePartnerPersonnel(id: $tradePartnerPersonnelId) {
      id
      customId
      isActive
      isSupervisor
      isPermitApprover
      shouldBeAdLinked
      orientationStatus
      notes
      supervisedBy {
        id
        fullName
        nickname
        soteriaAdUser {
          id
          employeeId
        }
      }
      orientationHistory {
        id
        name
        language
        facilitators {
          id
          name
          jobTitle
          upn
        }
        isCancelled
        notes
        timeZone
        startDateTime
        endDateTime
      }
      tradePartner {
        id
        name
        isActive
        trades
      }
      personnel {
        id
        isImported
        firstName
        middleName
        lastName
        nickname
        fullName
        dob
        notes
        upn
        tradePartnerHistory {
          id
          orientationHistory {
            id
            name
            startDateTime
            isCancelled
          }
        }
        contactInformation {
          emailAddress {
            email
            type
          }
          phoneNumbers {
            number
            display
            extension
            type
          }
          primaryPhone {
            number
            display
            extension
            type
          }
        }
        flags {
          id
          status
          reason
          resolution
        }
        trainings {
          id
          name
          otherName
          notes
          awardedDate
          expirationDate
          files {
            id
            name
            url
            thumbnailUrl
            sizeInBytes
            contentType
            isScanned
            isMalware
            cachedUrl @client
            cachedFile @client
          }
        }
        tradePartnerHistory {
          id
          customId
          isActive
          isSupervisor
          isPermitApprover
          shouldBeAdLinked
          orientationStatus
          notes
          tradePartner {
            id
            name
            project {
              id
              name
              number
              districtNumber
            }
          }
        }
        profileImage {
          id
          name
          url
          thumbnailUrl
          sizeInBytes
          contentType
          isScanned
          isMalware
          cachedUrl @client
          cachedFile @client
        }
      }
    }
  }
`;

export const TRADE_PARTNER_PERSONNELS_ON_PROJECT_SUBSCRIPTION = gql`
  subscription tradePartnerPersonnelsOnProject($projectId: ID!) {
    tradePartnerPersonnelsOnProject(projectId: $projectId) {
      id
      customId
      isActive
      isSupervisor
      isPermitApprover
      shouldBeAdLinked
      orientationStatus
      notes
      tradePartner {
        id
        name
        isActive
        trades
        company {
          id
        }
      }
      personnel {
        id
        isImported
        firstName
        middleName
        lastName
        nickname
        fullName
        dob
        notes
        upn
        soteriaAdUser {
          id
          upn
          employeeId
        }
        contactInformation {
          emailAddress {
            email
            type
          }
          phoneNumbers {
            number
            display
            extension
            type
          }
          primaryPhone {
            number
            display
            extension
            type
          }
        }
        flags {
          id
          status
          reason
          resolution
        }
        profileImage {
          id
          name
          url
          thumbnailUrl
          sizeInBytes
          contentType
          isScanned
          isMalware
          cachedUrl @client
          cachedFile @client
        }
      }
    }
  }
`;

// Queries
export const GET_TRADE_PARTNER_PERSONNEL_FOR_GENERAL_RELATIONS = gql`
  query GetSingleTradePartnerPersonnel($id: ID!) {
    tradePartnerPersonnel(id: $id) {
      id
      supervisedBy {
        id
        fullName
        nickname
        soteriaAdUser {
          id
          employeeId
        }
      }
      orientationHistory {
        id
        name
        language
        facilitators {
          id
          name
          jobTitle
          upn
        }
        isCancelled
        notes
        timeZone
        startDateTime
        endDateTime
      }
      personnel {
        id
        flags {
          id
          status
          reason
          resolution
        }
        trainings {
          id
          name
          otherName
          notes
          awardedDate
          expirationDate
          files {
            id
            name
            url
            thumbnailUrl
            sizeInBytes
            contentType
            isScanned
            isMalware
            cachedUrl @client
            cachedFile @client
          }
        }
      }
    }
  }
`;

export const GET_TRADE_PARTNER_PERSONNEL_FOR_WORK_HISTORY = gql`
  query GetSingleTradePartnerPersonnelForWorkHistory($id: ID!) {
    tradePartnerPersonnel(id: $id) {
      id
      personnel {
        id
        tradePartnerHistory {
          id
          customId
          isActive
          isSupervisor
          isPermitApprover
          shouldBeAdLinked
          orientationStatus
          notes
          tradePartner {
            id
            name
            project {
              id
              name
              number
              districtNumber
            }
          }
          orientationHistory {
            id
            name
            startDateTime
            isCancelled
          }
        }
      }
    }
  }
`;

export const GET_TRADE_PARTNER_PERSONNEL_FOR_BASIC_IDENTITY = gql`
  query GetSingleTradePartnerPersonnelForBasicIdentity($id: ID!) {
    tradePartnerPersonnel(id: $id) {
      id
      customId
      isActive
      isSupervisor
      isPermitApprover
      shouldBeAdLinked
      isHenselPhelpsEmployee
      orientationStatus
      notes
      tradePartner {
        id
        name
        isActive
        trades
      }
      personnel {
        id
        isImported
        firstName
        middleName
        lastName
        nickname
        fullName
        dob
        notes
        upn
        mergedTo {
          id
        }
        soteriaAdUser {
          id
          employeeId
          microsoftAdId
        }
        contactInformation {
          emailAddress {
            email
            type
          }
          phoneNumbers {
            number
            display
            extension
            type
          }
          primaryPhone {
            number
            display
            extension
            type
          }
        }
        profileImage {
          id
          name
          url
          thumbnailUrl
          sizeInBytes
          contentType
          isScanned
          isMalware
          cachedUrl @client
          cachedFile @client
        }
      }
    }
  }
`;

export const GET_PAGINATED_TRADE_PARTNER_PERSONNEL_FOR_PROJECT = gql`
  query GetPaginatedTradePartnerPersonnelForProject(
    $projectId: ID!
    $first: Int!
    $skip: Int
    $filter: TradePartnerPersonnelsFilterInput
    $order: [TradePartnerPersonnelsOrderInput!]
    $search: String
  ) {
    paginatedTradePartnerPersonnelsOnProject(
      projectId: $projectId
      first: $first
      skip: $skip
      filter: $filter
      order: $order
      search: $search
    ) {
      total
      tradePartnerPersonnels {
        id
        customId
        isActive
        isSupervisor
        isPermitApprover
        shouldBeAdLinked
        orientationStatus
        orientationHistory {
          id
          endDateTime
        }
        notes
        tradePartner {
          id
          name
          isActive
          trades
          company {
            id
          }
        }
        personnel {
          id
          isImported
          firstName
          middleName
          lastName
          nickname
          fullName
          dob
          notes
          upn
          soteriaAdUser {
            id
            upn
          }
          contactInformation {
            emailAddress {
              email
              type
            }
            phoneNumbers {
              number
              display
              extension
              type
            }
            primaryPhone {
              number
              display
              extension
              type
            }
          }
          flags {
            id
            status
            reason
            resolution
          }
          profileImage {
            id
            name
            url
            thumbnailUrl
            sizeInBytes
            contentType
            isScanned
            isMalware
            cachedUrl @client
            cachedFile @client
          }
        }
      }
    }
  }
`;

export const GET_ALL_TRADE_PARTNER_PERSONNEL_FOR_PROJECT = gql`
  query GetAllTradePartnerPersonnelForProject(
    $projectId: ID!
    $first: Int!
    $skip: Int
  ) {
    tradePartnerPersonnelsOnProject(
      projectId: $projectId
      first: $first
      skip: $skip
    ) {
      id
      customId
      isActive
      isSupervisor
      isPermitApprover
      shouldBeAdLinked
      orientationStatus
      notes
      tradePartner {
        id
        name
        isActive
        trades
        company {
          id
        }
      }
      personnel {
        id
        isImported
        firstName
        middleName
        lastName
        nickname
        fullName
        dob
        notes
        upn
        soteriaAdUser {
          id
          upn
        }
        contactInformation {
          emailAddress {
            email
            type
          }
          phoneNumbers {
            number
            display
            extension
            type
          }
          primaryPhone {
            number
            display
            extension
            type
          }
        }
        flags {
          id
          status
          reason
          resolution
        }
        profileImage {
          id
          name
          url
          thumbnailUrl
          sizeInBytes
          contentType
          isScanned
          isMalware
          cachedUrl @client
          cachedFile @client
        }
      }
    }
  }
`;

export const TRADE_PARTNER_PERSONNELS_ON_PROJECT_SEARCH = gql`
  query TradePartnerPersonnelsOnProjectSearch(
    $projectNumber: String
    $search: String
  ) {
    paginatedTradePartnerPersonnelsOnProject(
      projectNumber: $projectNumber
      search: $search
      first: 100
    ) {
      total
      tradePartnerPersonnels {
        id
        isActive
        personnel {
          id
          firstName
          middleName
          lastName
          nickname
          fullName
          dob
          soteriaAdUser {
            id
            employeeId
          }
          contactInformation {
            emailAddress {
              email
            }
            primaryPhone {
              number
            }
          }
          flags {
            id
          }
          profileImage {
            id
            name
            url
            thumbnailUrl
            sizeInBytes
            contentType
            isScanned
            isMalware
            cachedUrl @client
            cachedFile @client
          }
          tradePartnerHistory {
            id
            tradePartner {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const GET_ALL_TRADE_PARTNER_PERSONNEL_FOR_TRADE_PARTNER = gql`
  query GetAllTradePartnerPersonnelForTradePartner($tradePartnerId: ID!) {
    tradePartnerPersonnelsOnTradePartner(tradePartnerId: $tradePartnerId) {
      id
      customId
      isActive
      isSupervisor
      isPermitApprover
      shouldBeAdLinked
      orientationStatus
      notes
      tradePartner {
        id
        name
        isActive
        trades
        company {
          id
        }
      }
      personnel {
        id
        isImported
        firstName
        middleName
        lastName
        nickname
        fullName
        dob
        notes
        upn
        soteriaAdUser {
          id
          upn
        }
        contactInformation {
          emailAddress {
            email
            type
          }
          phoneNumbers {
            number
            display
            extension
            type
          }
          primaryPhone {
            number
            display
            extension
            type
          }
        }
        flags {
          id
          status
          reason
          resolution
        }
        profileImage {
          id
          name
          url
          thumbnailUrl
          sizeInBytes
          contentType
          isScanned
          isMalware
          cachedUrl @client
          cachedFile @client
        }
      }
    }
  }
`;

export const GET_RELATION_COUNTS_FOR_TPP = gql`
  query RelationCountsForTradePartnerPersonnel($id: ID!) {
    relationCountsForTradePartnerPersonnel(id: $id) {
      observationAsObservedPerson
      observationAsSupervisor
      observationAsOriginator
      safetyHours
    }
  }
`;

// Mutations
export const UPSERT_PERSONNEL_TO_PROJECT = gql`
  mutation UpsertPersonnelToProject($input: PersonnelRecordUpsertInput!) {
    upsertPersonnelToProject(input: $input) {
      id
      customId
      isActive
      isSupervisor
      isPermitApprover
      shouldBeAdLinked
      orientationStatus
      orientationHistory {
        id
        endDateTime
      }
      notes
      tradePartner {
        id
        name
        isActive
        trades
        company {
          id
        }
      }
      personnel {
        id
        isImported
        firstName
        middleName
        lastName
        nickname
        fullName
        dob
        notes
        upn
        soteriaAdUser {
          id
          upn
        }
        contactInformation {
          emailAddress {
            email
            type
          }
          phoneNumbers {
            number
            display
            extension
            type
          }
          primaryPhone {
            number
            display
            extension
            type
          }
        }
        flags {
          id
          status
          reason
          resolution
        }
        profileImage {
          id
          name
          url
          thumbnailUrl
          sizeInBytes
          contentType
          isScanned
          isMalware
          cachedUrl @client
          cachedFile @client
        }
      }
    }
  }
`;

export const UPDATE_TRADE_PARTNER_PERSONNEL = gql`
  mutation UpdateTradePartnerPersonnel(
    $input: TradePartnerPersonnelUpdateInput!
  ) {
    updateTradePartnerPersonnel(input: $input) {
      id
      customId
      isActive
      isSupervisor
      isPermitApprover
      shouldBeAdLinked
      orientationStatus
      notes
      tradePartner {
        id
        name
        isActive
        trades
      }
      personnel {
        id
        isImported
        firstName
        middleName
        lastName
        nickname
        fullName
        dob
        notes
        upn
        contactInformation {
          emailAddress {
            email
            type
          }
          phoneNumbers {
            number
            display
            extension
            type
          }
          primaryPhone {
            number
            display
            extension
            type
          }
        }
        profileImage {
          id
          name
          url
          thumbnailUrl
          sizeInBytes
          contentType
          isScanned
          isMalware
          cachedUrl @client
          cachedFile @client
        }
      }
    }
  }
`;

export const DELETE_TRADE_PARTNER_PERSONNEL = gql`
  mutation DeleteTradePartnerPersonnel($id: ID!) {
    deleteTradePartnerPersonnel(id: $id) {
      id
    }
  }
`;

export const RELINK_TRADE_PARTNER_PERSONNELS = gql`
  mutation RelinkTradePartnerPersonnels(
    $tradePartnerPersonnelIds: [ID!]!
    $toMicrosoftAdId: ID!
  ) {
    relinkTradePartnerPersonnels(
      toMicrosoftAdId: $toMicrosoftAdId
      tradePartnerPersonnelIds: $tradePartnerPersonnelIds
    ) {
      id
      customId
      isActive
      isSupervisor
      isPermitApprover
      shouldBeAdLinked
      orientationStatus
      notes
    }
  }
`;

export const SET_SAFETY_HOURS_SUPERVISORS_ON_TRADE_PARTNER_PERSONNEL = gql`
  mutation SetSafetyHoursSupervisorsOnTradePartnerPersonnel(
    $tradePartnerPersonnelIds: [ID!]!
    $supervisorIds: [ID!]!
  ) {
    setSafetyHoursSupervisorsOnTradePartnerPersonnel(
      tradePartnerPersonnelIds: $tradePartnerPersonnelIds
      supervisorIds: $supervisorIds
    ) {
      id
      supervisedBy {
        id
        fullName
        soteriaAdUser {
          id
          employeeId
        }
      }
    }
  }
`;
