import React from 'react';
import { Grid, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  subTitle: { fontSize: '0.875rem' },
  title: { fontSize: '1.5rem' },
  color: { color: theme.palette.primary.main }
}));

const Introduction = () => {
  const classes = useStyles();

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography className={classes.subTitle} color="textSecondary">
          Introduction
        </Typography>
        <Typography className={classes.title} color="textPrimary">
          Safety Connect Tutorials
        </Typography>
      </Grid>
      <Grid item>
        <Typography color="textPrimary">
          Welcome to Safety Connect Tutorials!
        </Typography>
      </Grid>
      <Grid item>
        <Typography color="textPrimary">
          Each category of Safety Connect is comprised of individual tutorials.
          These tutorials include written and visual instructions that have been
          crafted to teach you how to complete each major action within the
          application.
        </Typography>
      </Grid>
      <Grid item>
        <Typography color="textPrimary">
          If you have already reviewed the tutorials and are still unable to
          perform the action you need, please{' '}
          <a
            href="https://henselphelpsdev.service-now.com/"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.color}>
            contact support
          </a>
          . If you have an idea for a tutorial that doesn&apos;t already exist,
          please{' '}
          <a
            href="https://henselphelpsdev.service-now.com/"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.color}>
            request it as a new feature
          </a>{' '}
          for the dev team to build in future releases.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Introduction;
