import { useEffect, useCallback, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { pendingObservationsDb as db } from 'pouchDB';
import { useTranslation } from 'react-i18next';
import deepEqual from 'deep-equal';

import {
  CREATE_OBSERVATION,
  OPEN_OBSERVATION_COUNT
} from 'graphql/observations';
import useToast from 'hooks/useToast';
import useIsOnline from 'store/onlineDetection';
import useOfflineSync from 'store/offlineSync';
import useCurrentUser from 'hooks/useCurrentUser';
import useRoles from 'hooks/useRoles';
import { useMsal } from '@azure/msal-react';

const ONE_MIN_IN_MS = 60000;
const INTERVAL = 1 * ONE_MIN_IN_MS;

const getAllPendingObservationsFromDbForUpn = async upn => {
  const results = await db.allDocs({
    include_docs: true,
    attachments: true
  });

  return (
    results?.rows
      ?.filter(result => result.doc.observation.creator?.upn === upn)
      ?.map(result => ({
        ...result.doc.observation,
        rev: result.doc._rev
      })) ?? []
  );
};

const usePendingObservationSync = () => {
  const { displayToast } = useToast();
  const { t } = useTranslation();
  const { currentUser } = useCurrentUser();
  const { isOnline } = useIsOnline();
  const { isAdminTypeRole } = useRoles();
  const {
    offlineSyncState: {
      observations: observationsState,
      errorObservationIdToRetry,
      isRetryingErrorSync
    },
    offlineSyncActions: { updateObservations, updateErrorObservationSyncState }
  } = useOfflineSync();
  const [createObservation] = useMutation(CREATE_OBSERVATION);
  const [isProcessing, setIsProcessing] = useState(false);
  const { instance: msalInstance } = useMsal();

  const processSinglePendingObservation = useCallback(
    async pendingObservation => {
      const createObservationInput = getCreateObservationInput(
        pendingObservation
      );

      try {
        const {
          // eslint-disable-next-line no-unused-vars
          data: { createObservation: observation }
        } = await createObservation({
          variables: { input: createObservationInput },
          refetchQueries: [
            {
              query: OPEN_OBSERVATION_COUNT,
              variables: {
                projectId: createObservationInput.projectId,
                ownObservations: true
              }
            },
            ...(isAdminTypeRole(createObservationInput.projectId)
              ? [
                  {
                    query: OPEN_OBSERVATION_COUNT,
                    variables: { projectId: createObservationInput.projectId }
                  }
                ]
              : [])
          ],
          awaitRefetchQueries: true
        });

        // TODO: Reset skip and Refetch paginated queries with current state

        try {
          return db.remove(
            createObservationInput.transactionKey,
            pendingObservation.rev
          );
        } catch (err) {
          console.error(
            'Did not delete pending observation from indexed-db',
            err
          );
          return err;
        }
      } catch (error) {
        return await db
          .get(pendingObservation.transactionKey)
          .then(function(doc) {
            doc.observation.error = {
              isError: true,
              errorType: 'create',
              error: error
            };
            return db.put(doc);
          })
          .catch(function(err_1) {
            console.log('did not update doc', err_1);
          })
          .finally(() => {
            return Promise.reject(error);
          });
      }
    },
    [createObservation, isAdminTypeRole]
  );

  const setPendingObservations = useCallback(
    async (shouldStopSyncing = false) => {
      const upn = msalInstance?.getActiveAccount()?.username?.toLowerCase();
      const observations = await getAllPendingObservationsFromDbForUpn(
        currentUser?.upn ?? upn
      );
      if (!deepEqual(observations, observationsState.pending)) {
        updateObservations({
          pending: observations,
          ...(shouldStopSyncing ? { isSyncing: false } : {})
        });
      }
    },
    [currentUser, observationsState.pending, updateObservations]
  );

  // Init pending observations: We should only run this once to prevent infinite loops
  useEffect(() => {
    setPendingObservations();
  }, []);

  const processErrorObservation = useCallback(
    async id => {
      const allPendingObservations = await getAllPendingObservationsFromDbForUpn(
        currentUser?.upn
      );

      const errorObservationToRetry = allPendingObservations.find(
        observation => observation.transactionKey === id
      );

      let processErrorOccurred = false;

      if (errorObservationToRetry && isOnline && !isProcessing) {
        setIsProcessing(true);
        await processSinglePendingObservation(errorObservationToRetry).catch(
          // eslint-disable-next-line no-loop-func
          async () => {
            processErrorOccurred = true;
          }
        );
        if (processErrorOccurred) {
          updateErrorObservationSyncState(null, false);
          displayToast(t('dashboardPage.toasts.retryError'), 'error');
        } else {
          updateErrorObservationSyncState(null, false);
          displayToast(t('dashboardPage.toasts.retrySuccess'), 'success');
          await setPendingObservations(true);
        }

        setIsProcessing(false);
      }
    },
    [
      currentUser,
      displayToast,
      updateErrorObservationSyncState,
      processSinglePendingObservation,
      isOnline,
      isProcessing,
      setPendingObservations,
      t
    ]
  );

  const processPendingObservations = useCallback(async () => {
    if (errorObservationIdToRetry || isRetryingErrorSync) {
      return;
    } else if (isOnline && !isProcessing) {
      const allPendingObservations = await getAllPendingObservationsFromDbForUpn(
        currentUser?.upn
      );
      const pendingObservationsWithoutErrors =
        allPendingObservations.filter(observation => !observation.error) ?? [];

      let processErrorOccurred = false;

      if (pendingObservationsWithoutErrors.length > 0) {
        setIsProcessing(true);
        updateObservations({ isSyncing: true });
        for (const pendingObservation of pendingObservationsWithoutErrors) {
          await processSinglePendingObservation(pendingObservation).catch(
            // eslint-disable-next-line no-loop-func
            async () => {
              processErrorOccurred = true;
            }
          );
        }

        if (processErrorOccurred) {
          displayToast(t('dashboardPage.toasts.batchAddError'), 'error');
          console.error('Error processing pending observations');
        } else {
          displayToast(t('dashboardPage.toasts.batchAddSuccess'), 'success');
        }

        await setPendingObservations(true);
      }
      setIsProcessing(false);
    }
  }, [
    currentUser,
    displayToast,
    isOnline,
    isProcessing,
    processSinglePendingObservation,
    setPendingObservations,
    t,
    updateObservations,
    isRetryingErrorSync,
    errorObservationIdToRetry
  ]);

  useEffect(() => {
    // deepcode ignore CodeInjection: It looks like the only information actually crossing this boundary is a Promise<void> so it should be safe.
    const intervalId = setInterval(processPendingObservations, INTERVAL);
    return () => clearInterval(intervalId);
  });

  useEffect(() => {
    if (isRetryingErrorSync) {
      // manually resubmit pending observation with previous error
      processErrorObservation(errorObservationIdToRetry);
    }
  });
};

function getCreateObservationInput(pendingObservation) {
  return {
    isDraft: pendingObservation.isDraft,
    transactionKey: pendingObservation.transactionKey,
    projectId: pendingObservation.projectId,
    tradePartnerId: pendingObservation.tradePartnerId,
    supervisorId: pendingObservation.supervisorId,
    projectArea: pendingObservation.projectArea,
    observedDateTime: pendingObservation.observedDateTime,
    type: pendingObservation.type,
    notes: pendingObservation.notes,
    observedPersonId: pendingObservation.observedPersonId ?? undefined,
    originatorId: pendingObservation.originatorId,
    isAllSafe: pendingObservation.isAllSafe,
    causeIds: pendingObservation.causeIds,
    items: pendingObservation.items
  };
}

export default usePendingObservationSync;
