import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useField } from 'react-final-form-hooks';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography, Select, MenuItem } from '@mui/material';

import useFormValidation from 'hooks/useFormValidation';
import StyledInput from 'shared/Input';
import AddFile from 'components/common/AddFile';
import SafeTradePartnerSelect from 'components/observations/SafeTradePartnerSelect';
import { GET_SAFE_PROJECT_FOR_AREAS } from 'graphql/safeProject';
import useSoteriaQuery from 'hooks/useSoteriaQuery';
import { useParams } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const useStyles = makeStyles(theme => ({
  fieldLabel: { fontSize: '0.75rem', fontWeight: 'bold' },
  formField: { marginBottom: theme.spacing(1) }
}));

const TradePartnerAhaForm = ({
  allTradePartners,
  form,
  addedFiles,
  setAddedFiles,
  filesToDelete,
  setFilesToDelete,
  isLoading
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { isRequired } = useFormValidation();

  const { projectId } = useParams();
  const {
    data: safeProjectForAreasData,
    loading: isLoadingProjectForAreas
  } = useSoteriaQuery({
    gql: GET_SAFE_PROJECT_FOR_AREAS,
    queryHookOptions: {
      skip: !projectId,
      variables: { projectId }
    }
  });

  const selectedTradePartner = useField(
    'selectedTradePartner',
    form,
    isRequired
  );

  const tradePartnerAhaNameField = useField(
    'tradePartnerAhaName',
    form,
    isRequired
  );

  const tradePartnerAhaDescriptionField = useField(
    'tradePartnerAhaDescription',
    form,
    isRequired
  );

  const projectArea = useField('projectArea', form);

  return (
    <form>
      <Grid container justifyContent="center">
        <Grid item xs={12} className={classes.formField}>
          <Typography color="textSecondary" className={classes.fieldLabel}>
            {t('tradePartnerAhaForm.fieldLabel.tradePartner')}
          </Typography>
          <SafeTradePartnerSelect
            selectedTradePartner={selectedTradePartner.input.value}
            meta={selectedTradePartner.meta}
            handleChange={selectedTradePartner.input.onChange}
            tradePartnerOptions={allTradePartners ?? []}
            isLoading={isLoading}
            isDisabled={isLoading}
            isRequired={true}
            shouldFilterOutHenselPhelps={true}
          />
        </Grid>
        <Grid item xs={12} className={classes.formField}>
          <StyledInput
            input={tradePartnerAhaNameField.input}
            meta={tradePartnerAhaNameField.meta}
            disabled={isLoading}
            label={t('tradePartnerAhaForm.fieldLabel.name')}
            required
          />
        </Grid>
        <Grid item xs={12} className={classes.formField}>
          <StyledInput
            input={tradePartnerAhaDescriptionField.input}
            meta={tradePartnerAhaDescriptionField.meta}
            disabled={isLoading}
            label={t('tradePartnerAhaForm.fieldLabel.description')}
            multiline={true}
            rows={3}
            required
          />
        </Grid>
        <Grid item xs={12} className={classes.formField}>
          <Typography color="textSecondary" className={classes.fieldLabel}>
            {t('tradePartnerAhaForm.fieldLabel.projectArea')}
          </Typography>
          <Select
            value={projectArea.input.value || null}
            onChange={projectArea.input.onChange}
            meta={projectArea.meta}
            disabled={isLoadingProjectForAreas}
            displayEmpty={true}
            renderValue={value =>
              value || t('tradePartnerAhaForm.projectAreaNoneSpecified')
            }
            variant="outlined"
            margin="dense"
            autoWidth={true}
            IconComponent={KeyboardArrowDownIcon}>
            <MenuItem
              data-testid={`option-none-specified`}
              key={-1}
              value={null}>
              {t('tradePartnerAhaForm.projectAreaNoneSpecified')}
            </MenuItem>
            {(safeProjectForAreasData?.safeProject?.areas ?? []).map(
              (area, index) => (
                <MenuItem
                  data-testid={`option-${area}`}
                  key={index}
                  value={area}>
                  {area}
                </MenuItem>
              )
            )}
          </Select>
        </Grid>
        <Grid item xs={12} className={classes.formField}>
          <Typography color="textSecondary" className={classes.fieldLabel}>
            {t('tradePartnerAhaForm.fieldLabel.uploadFile')}
          </Typography>
          <AddFile
            fileDataFromDb={[]}
            setAddedFiles={setAddedFiles}
            addedFiles={addedFiles}
            existingFiles={[]}
            setFilesToDelete={setFilesToDelete}
            filesToDelete={filesToDelete}
            maxNumFiles={1}
          />
        </Grid>
      </Grid>
    </form>
  );
};

TradePartnerAhaForm.propTypes = {
  allTradePartners: PropTypes.array,
  form: PropTypes.any,
  addedFiles: PropTypes.array,
  setAddedFiles: PropTypes.func,
  filesToDelete: PropTypes.array,
  setFilesToDelete: PropTypes.func,
  isLoading: PropTypes.bool
};

export default TradePartnerAhaForm;
