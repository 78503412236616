import { useMutation, useQuery } from '@apollo/react-hooks';
import { Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import classnames from 'classnames';
import AhaReviewersDialog from 'components/ahas/AhaReviewersDialog';
import {
  DELETE_AHA_REVIEW,
  GET_PAGINATED_AHA_REVIEWS_ON_PROJECT_TEMPLATE
} from 'graphql/aha/ahaReview';
import useDebounce from 'hooks/useDebounce';
import useToast from 'hooks/useToast';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import { useHistory, useParams } from 'react-router-dom';
import StyledButtonMuted from 'shared/Buttons/ButtonMuted';
import StyledDeleteConfirmation from 'shared/DeleteConfirmation';
import StyledTable from 'shared/Table';
import useAhaProjectTemplateReviewsListState from 'store/ahaProjectTemplateReviewsListState';
import { formatDate } from 'utils/dateTime';

const useStyles = makeStyles(theme => ({
  actionsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('lg')]: { justifyContent: 'flex-start' }
  },
  rowText: { fontSize: '0.875rem' },
  approvedOrCompleted: { color: theme.palette.success.main },
  rejected: { color: theme.palette.error.main },
  status: { fontSize: '0.75rem' },
  addButton: {
    '@media (max-width: 960px)': {
      marginBottom: 7
    }
  }
}));

const ReviewsTabContent = ({ projectTemplateId, ahaTemplate }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const history = useHistory();
  const { projectId, ahaId } = useParams();
  const { displayToast } = useToast();
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(
    false
  );
  const [requestReviewDialogIsOpen, toggleRequestReviewDialog] = useState(
    false
  );
  const [isDeleteConfirmed, toggleIsDeleteConfirmed] = useState(false);
  const [reviewIdToDelete, setReviewIdToDelete] = useState();
  const [
    ahaReviewsOnProjectTemplateListState,
    { handleAhaReviewsOnProjectTemplateListStateChange }
  ] = useAhaProjectTemplateReviewsListState();
  const [initialAhaReviewsOnProjectTemplateListState] = useState(
    ahaReviewsOnProjectTemplateListState
  );

  const getApiSortOrder = order => {
    let apiSortOrderField = null;
    let direction = order.direction;

    switch (order.name) {
      case 'completed':
        apiSortOrderField = 'completed';
        break;
      case 'created':
        apiSortOrderField = 'created';
        break;
      case 'preparedBy.name':
        apiSortOrderField = 'preparedBy';
        break;
      case 'status':
        apiSortOrderField = 'status';
        break;
      default:
        console.error(`Sort order, "${order.name}", is not being handled!`);
    }
    return apiSortOrderField && direction
      ? [
          {
            [apiSortOrderField]: direction.toUpperCase()
          }
        ]
      : null;
  };

  const { data, loading: isLoadingPaginatedReviews, fetchMore } = useQuery(
    GET_PAGINATED_AHA_REVIEWS_ON_PROJECT_TEMPLATE,
    {
      skip: !projectTemplateId,
      variables: {
        first: initialAhaReviewsOnProjectTemplateListState.first,
        order: getApiSortOrder(
          initialAhaReviewsOnProjectTemplateListState.order
        ),
        ahaProjectTemplateId: projectTemplateId,
        search: initialAhaReviewsOnProjectTemplateListState.search,
        skip: 0
      },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true
    }
  );

  const pendingReviews = data?.paginatedAhaReviewsOnProjectTemplate?.ahaReviews;

  const [deleteAhaReview, { loading: isLoadingForDelete }] = useMutation(
    DELETE_AHA_REVIEW
  );

  const isLoading = isLoadingPaginatedReviews || isLoadingForDelete;

  const loadMore = variables => {
    return fetchMore({
      variables,
      updateQuery: (_previousResult, { fetchMoreResult, _queryVariables }) => {
        return fetchMoreResult;
      }
    });
  };

  const handlePageChange = tableState => {
    const { rowsPerPage, page } = tableState;

    let skip =
      page < ahaReviewsOnProjectTemplateListState.page
        ? ahaReviewsOnProjectTemplateListState.skip - rowsPerPage
        : ahaReviewsOnProjectTemplateListState.skip + rowsPerPage;

    handleAhaReviewsOnProjectTemplateListStateChange({
      ...ahaReviewsOnProjectTemplateListState,
      page,
      skip
    });

    loadMore({
      search: ahaReviewsOnProjectTemplateListState.search,
      skip,
      order: getApiSortOrder(ahaReviewsOnProjectTemplateListState.order)
    });
  };

  const handleSortChange = tableState => {
    const { sortOrder } = tableState;

    handleAhaReviewsOnProjectTemplateListStateChange({
      ...ahaReviewsOnProjectTemplateListState,
      order: sortOrder,
      page: 0,
      skip: 0
    });

    loadMore({
      search: ahaReviewsOnProjectTemplateListState.search,
      skip: 0,
      order: getApiSortOrder(sortOrder)
    });
  };

  const handleSearchChange = ({ tableState, debouncedHandleSearchChange }) => {
    const { searchText } = tableState;

    handleAhaReviewsOnProjectTemplateListStateChange({
      ...ahaReviewsOnProjectTemplateListState,
      skip: 0,
      page: 0,
      search: searchText
    });

    loadMore({
      search: searchText,
      skip: 0,
      order: getApiSortOrder(ahaReviewsOnProjectTemplateListState.order)
    });
  };

  const { debounced: debouncedHandleSearchChange } = useDebounce(
    handleSearchChange
  );

  const openAhaReview = rowData => {
    const ahaReviewId = rowData && rowData[0];
    history.push(
      `/projects/${projectId}/ahas/hensel-phelps-aha/${ahaId}/reviews/${ahaReviewId}`
    );
  };

  const handleChange = event => {
    toggleIsDeleteConfirmed(event.target.checked);
  };

  const handleConfirmDelete = (event, rowData) => {
    event.stopPropagation();
    event.preventDefault();
    setReviewIdToDelete(rowData && rowData?.rowData[0]);
    setIsDeleteConfirmationOpen(true);
  };

  const refetchQuery = () => {
    return loadMore({
      first: ahaReviewsOnProjectTemplateListState.first,
      skip: ahaReviewsOnProjectTemplateListState.skip,
      search: ahaReviewsOnProjectTemplateListState.search,
      order: getApiSortOrder(ahaReviewsOnProjectTemplateListState.order)
    });
  };

  const handleDeleteSubmit = () => {
    deleteAhaReview({ variables: { id: reviewIdToDelete } })
      .then(async () => {
        if (refetchQuery) {
          await refetchQuery();
        }
        displayToast(t('deleteAhaReview.toasts.success'), 'success');
        handleClose();
      })
      .catch(error => {
        console.error('Delete AHA Review Error: ', error);
        displayToast(t('deleteAhaReview.toasts.error'), 'error');
      });
  };

  const handleClose = () => {
    setIsDeleteConfirmationOpen(false);
    toggleIsDeleteConfirmed(false);
  };

  const columns = [
    {
      name: 'id',
      label: 'Id',
      options: {
        filter: false,
        display: 'excluded'
      }
    },
    {
      name: 'preparedBy.name',
      label: 'Prepared By',
      options: {
        filter: false,
        searchable: true,
        sort: true
      }
    },
    {
      name: 'requestedReviewers',
      label: 'Requested Reviewers',
      options: {
        filter: false,
        searchable: true,
        sort: false,
        // eslint-disable-next-line react/display-name
        customBodyRender: value => {
          if (value?.length > 1) {
            const reviewers = value.map(value => {
              return value.name;
            });
            return reviewers.join(', ');
          }
          return value[0].name;
        }
      }
    },
    {
      name: 'approvers',
      label: 'Reviewed By',
      options: {
        filter: false,
        searchable: true,
        sort: false,
        // eslint-disable-next-line react/display-name
        customBodyRender: value => {
          if (value?.length > 0) {
            const approvers = value.map(value => {
              return value.name;
            });
            return approvers.join(', ');
          }
          return '';
        }
      }
    },
    {
      name: 'created',
      label: 'Request Date',
      options: {
        filter: false,
        searchable: true,
        sort: true,
        // eslint-disable-next-line react/display-name
        customBodyRender: value => {
          return (
            <Typography className={classes.rowText}>
              {formatDate(value)}
            </Typography>
          );
        }
      }
    },
    {
      name: 'lastModified',
      label: 'Reviewed Date',
      options: {
        filter: false,
        searchable: true,
        sort: true,
        // eslint-disable-next-line react/display-name
        customBodyRender: value => {
          if (value) {
            return (
              <Typography className={classes.rowText}>
                {formatDate(value)}
              </Typography>
            );
          }
        }
      }
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: false,
        searchable: true,
        sort: true,
        // eslint-disable-next-line react/display-name
        customBodyRender: value => {
          return (
            <>
              <Typography
                className={classnames(classes.status, {
                  [`${classes.approvedOrCompleted}`]:
                    value === 'Completed' || value === 'Reviewed',
                  [`${classes.rejected}`]: value === 'Rejected'
                })}>
                {value === 'Reviewed' ? 'Accepted' : value}
              </Typography>
            </>
          );
        }
      }
    },
    {
      name: 'status',
      label: ' ',
      options: {
        filter: false,
        searchable: false,
        sort: false,
        // eslint-disable-next-line react/display-name
        customBodyRender: (status, rowData) => {
          return (
            <>
              {(status === 'Pending' || status === 'Reviewed') && (
                <Grid container className={classes.actionsContainer}>
                  <Grid item>
                    <Tooltip
                      title={
                        status !== 'Pending'
                          ? 'Can only delete a pending review'
                          : t(
                              'reviewsTabContent.actions.deleteReviewIconButton'
                            )
                      }>
                      <span>
                        <IconButton
                          size="small"
                          disabled={status !== 'Pending'}
                          onClick={event =>
                            handleConfirmDelete(event, rowData)
                          }>
                          <DeleteIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Grid>
                </Grid>
              )}
            </>
          );
        }
      }
    },
    {
      name: 'ahaProjectTemplate.id',
      label: 'ahaProjectTemplateId',
      options: {
        filter: false,
        display: 'excluded'
      }
    },
    {
      name: 'ahaTemplate.id',
      label: 'ahaTemplateId',
      options: {
        filter: false,
        display: 'excluded'
      }
    }
  ];

  const options = {
    filter: false,
    download: false,
    count: data?.paginatedAhaReviewsOnProjectTemplate?.total ?? null,
    rowsPerPage: ahaReviewsOnProjectTemplateListState.first,
    searchText: ahaReviewsOnProjectTemplateListState.search,
    searchPlaceholder: t('reviewsTabContent.searchPlaceholder'),
    rowsPerPageOptions: [],
    page: ahaReviewsOnProjectTemplateListState.page,
    sortOrder: ahaReviewsOnProjectTemplateListState.order,
    onRowClick: rowData => openAhaReview(rowData),
    serverSide: true,
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          handlePageChange(tableState);
          break;
        case 'sort':
          handleSortChange(tableState);
          break;
        case 'search':
        case 'onSearchClose':
          debouncedHandleSearchChange({
            tableState,
            ahaReviewsOnProjectTemplateListState
          });
          break;
        default:
      }
    },
    // eslint-disable-next-line react/display-name
    customToolbar: () => {
      return (
        <StyledButtonMuted
          className={classes.addButton}
          startIcon={<AddIcon color="action" />}
          label={t('reviewsTabContent.requestReviewButton')}
          onClick={() => toggleRequestReviewDialog(true)}
        />
      );
    }
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <LoadingOverlay
            active={isLoading}
            spinner
            fadeSpeed={50}
            styles={{
              overlay: base => ({
                ...base,
                background: theme.palette.background.overlay
              }),
              spinner: base => ({
                ...base,
                width: '50px',
                '& svg circle': {
                  stroke: theme.palette.primary.main
                }
              })
            }}>
            <StyledTable
              title={t('reviewsTabContent.title')}
              data={pendingReviews ?? []}
              columns={columns}
              options={options}
            />
          </LoadingOverlay>
        </Grid>
      </Grid>
      <StyledDeleteConfirmation
        title={t('reviewsTabContent.deleteConfirmationTitle')}
        dialogIsOpen={isDeleteConfirmationOpen}
        isLoading={isLoading}
        handleClose={handleClose}
        warningMessage={t('reviewsTabContent.deleteConfirmationMessage')}
        isConfirmed={isDeleteConfirmed}
        handleChange={handleChange}
        handleSubmit={handleDeleteSubmit}
      />
      <AhaReviewersDialog
        requestReviewDialogIsOpen={requestReviewDialogIsOpen}
        toggleRequestReviewDialog={toggleRequestReviewDialog}
        ahaTemplate={ahaTemplate}
        isGlobal={false}
        isManagingReviewers={false}
        refetchQuery={refetchQuery}
      />
    </>
  );
};

ReviewsTabContent.propTypes = {
  projectTemplateId: PropTypes.string,
  ahaTemplate: PropTypes.object
};

export default ReviewsTabContent;
