import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useField } from 'react-final-form-hooks';
import { useParams } from 'react-router-dom';
import OfflineIcon from '@mui/icons-material/CloudOff';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography } from '@mui/material';

import useFormValidation from 'hooks/useFormValidation';
import useSafeProject from 'hooks/useSafeProject';
import StyledSelect from 'shared/Select';
import StyledKeyboardDatePickerField from 'shared/KeyboardDatePickerField';
import StyledKeyboardTimePickerField from 'shared/KeyboardTimePickerField';
import ObservationTypeToggle from 'components/observations/ObservationTypeToggle';
import ObservationCategories from 'components/observations/ObservationCategories';
import ObservationSubcategories from 'components/observations/ObservationSubcategories';
import ObservationAllSafe from 'components/observations/ObservationAllSafe';
import ObservationCauseSelect from 'components/observations/ObservationCauseSelect';
import TradePartnerPersonnelSelect from 'components/tradepartners/TradePartnerPersonnelSelect';
import SafeTradePartnerSelect from 'components/observations/SafeTradePartnerSelect';
import useIsOnline from 'store/onlineDetection';
import { getTradePartnerPersonnelLabel } from 'utils';
import ObservationDetails from './ObservationDetails';
import { DateTime } from 'luxon';
import { isDateTimeInstance } from 'utils/dateTime';
import StyledNotice from 'shared/Notice';

const useStyles = makeStyles(theme => ({
  errorMessage: { fontSize: '0.75rem' },
  allSafeContainer: { margin: theme.spacing(1, 0, 2, 0) },
  offlineContainer: {
    backgroundColor: theme.palette.secondary.light,
    padding: theme.spacing(0.5, 1, 0, 1),
    boxShadow: '2px 2px 10px gray',
    marginBottom: theme.spacing(4),
    borderRadius: 4,
    [theme.breakpoints.down('md')]: { padding: theme.spacing(2) }
  },
  icon: {
    color: theme.palette.secondary.contrastText,
    marginLeft: 24,
    [theme.breakpoints.down('md')]: { marginLeft: 0 }
  },
  label: {
    fontSize: '0.75rem',
    fontWeight: 'bold',
    display: 'inline',
    color: theme.palette.secondary.contrastText
  },
  offlineMessage: {
    fontWeight: 'bold',
    fontSize: '0.75rem',
    color: theme.palette.secondary.contrastText
  },
  notesContainer: { marginBottom: 120 }
}));

const ConfirmationField = ({ label, content }) => {
  return (
    <Typography>
      <span className="bold">{label}</span>
      {content}
    </Typography>
  );
};

ConfirmationField.propTypes = {
  label: PropTypes.string.isRequired,
  content: PropTypes.any
};

const ObservationPartnerForm = ({
  form,
  isLoading,
  observationType,
  setObservationType,
  isAllSafe,
  setIsAllSafe,
  selectedCategories,
  setSelectedCategories,
  selectedSubCategories,
  setSelectedSubCategories,
  selectedObservation,
  deletable
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isRequired, isValidPastDate, isValidTime } = useFormValidation();
  const { isOnline } = useIsOnline();
  const { projectId } = useParams();
  const { safeProject, allHenselPhelpsTradePartnerPersonnels } = useSafeProject(
    projectId
  );
  const allTradePartners = safeProject?.tradePartners ?? [];
  const observationIsClosed = selectedObservation?.status === 'Closed';

  const validateObservedTradePartner = value => {
    if (safeProject?.tradePartners?.length < 1) {
      return t('observationForm.validation.configureObservedTradePartner');
    }
    return isRequired(value);
  };

  const observedTradePartner = useField(
    'observedTradePartner',
    form,
    validateObservedTradePartner
  );

  const getObservablePeopleForSelectedTradePartner = () => {
    return (
      allTradePartners.find(
        tradePartner =>
          tradePartner.id === observedTradePartner?.input?.value?.id
      )?.personnels ?? []
    );
  };

  const observablePeopleForSelectedTradePartner = observedTradePartner?.input
    ?.value
    ? getObservablePeopleForSelectedTradePartner()
    : [];

  const validateProjectAreas = value => {
    if (safeProject?.areas?.length < 1) {
      return t('observationForm.validation.configureProjectAreas');
    }
    return isRequired(value);
  };

  const twoMonthWarningDate = DateTime.now()
    .minus({ month: 2 })
    .startOf('month');
  const minObservedDate = DateTime.now()
    .minus({ days: 10 })
    .startOf('day');
  const maxObservedDateAndTime = DateTime.now().toFormat("yyyy-MM-dd'T'TT");
  const currentDate = DateTime.now().toFormat('MM-dd-yyyy');

  const validateObservedDate = value => {
    if (!value) {
      return isRequired(value);
    } else if (observedDate.input.value <= minObservedDate) {
      return t('observationForm.validation.configureObservationDate', {
        minObservedDate: minObservedDate.toFormat('MM-dd-yyyy')
      });
    } else {
      return isValidPastDate(value);
    }
  };

  const validateObservedTime = value => {
    const formattedSelectedObservedDate = isDateTimeInstance(
      observedDate.input.value
    )
      ? observedDate.input.value.toFormat('MM-dd-yyyy')
      : DateTime.fromJSDate(observedDate.input.value).toFormat('MM-dd-yyyy');
    const formattedSelectedObservedTime = DateTime.fromISO(
      observedTime.input.value
    ).toFormat("yyyy-MM-dd'T'TT");

    if (!value) {
      return isRequired(value);
    } else if (
      currentDate === formattedSelectedObservedDate &&
      formattedSelectedObservedTime > maxObservedDateAndTime
    ) {
      return t('observationForm.validation.configureObservationTime', {
        maxObservedTime: DateTime.fromFormat(
          maxObservedDateAndTime,
          "yyyy-MM-dd'T'TT"
        ).toFormat('hh:mm a')
      });
    } else {
      return isValidTime(value);
    }
  };

  const projectArea = useField('projectArea', form, validateProjectAreas);
  const observedDate = useField('observedDate', form, validateObservedDate);
  const observedTime = useField('observedTime', form, validateObservedTime);

  const validateObservedPerson = value => {
    if (observablePeopleForSelectedTradePartner?.length < 1) {
      return t('observationForm.validation.configureObservedPerson');
    }
    return isRequired(value);
  };

  const observedPerson = useField(
    'observedPerson',
    form,
    validateObservedPerson
  );

  const shouldShowRemainingFields =
    observationType || selectedObservation?.type;

  const typeIsBehavioral =
    observationType === 'Behavioral' ||
    selectedObservation?.type === 'Behavioral';
  const typeIsEnvironmental =
    observationType === 'Environmental' ||
    selectedObservation?.type === 'Environmental';

  const getOfflineMessage = () => {
    if (selectedObservation && !deletable) {
      return t('observationForm.offlineMessageForUpdate');
    }

    if (selectedObservation && deletable) {
      return t('observationForm.offlineMessageForDelete');
    }

    if (!selectedObservation) {
      return t('observationForm.offlineMessage');
    }
  };

  return (
    <>
      {!isOnline && (
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className={classes.offlineContainer}>
          <Grid item>
            <Grid container direction="row" spacing={1} alignItems="center">
              <Grid item>
                <OfflineIcon className={classes.icon} />
              </Grid>
              <Grid item>
                <Typography className={classes.label}>
                  {t('observationForm.offlineStatus.title')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Typography className={classes.offlineMessage}>
              {getOfflineMessage()}
            </Typography>
          </Grid>
        </Grid>
      )}

      <Grid container direction="row" spacing={2}>
        {!observationIsClosed && !deletable && (
          <>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <SafeTradePartnerSelect
                selectedTradePartner={observedTradePartner.input.value}
                meta={observedTradePartner.meta}
                handleChange={value => {
                  form.change('observedPerson', '');
                  observedTradePartner.input.onChange(value);
                }}
                tradePartnerOptions={allTradePartners ?? []}
                isLoading={isLoading}
                isDisabled={isLoading}
                isRequired={true}
                label={t('observationForm.field.observedTradePartner.title')}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <StyledSelect
                inputProps={{ 'data-testid': 'area-select' }}
                input={projectArea.input}
                meta={projectArea.meta}
                disabled={isLoading}
                options={
                  safeProject?.areas?.map(item => ({
                    label: item,
                    value: item
                  })) ?? []
                }
                placeholder={t('observationForm.field.area.placeholder')}
                label={t('observationForm.field.area.title')}
                required
              />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
              <StyledKeyboardDatePickerField
                label={t('observationForm.field.observedDate.title')}
                value={observedDate.input.value || null}
                meta={observedDate.meta}
                handleDateChange={observedDate.input.onChange}
                disabled={isLoading}
                minDate={minObservedDate}
                inputProps={{
                  onFocus: observedDate.input.onFocus,
                  onBlur: observedDate.input.onBlur,
                  'data-testid': t('observationForm.field.observedDate.title')
                }}
              />
              {observedDate.input.value < twoMonthWarningDate &&
                observedDate.input.value > minObservedDate && (
                  <Typography color="primary" className={classes.errorMessage}>
                    {t('observationForm.field.observedDate.warningMessage')}
                  </Typography>
                )}
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
              <StyledKeyboardTimePickerField
                label={t('observationForm.field.observedTime.title')}
                value={observedTime.input.value || null}
                meta={observedTime.meta}
                handleDateChange={observedTime.input.onChange}
                disabled={isLoading}
                inputProps={{
                  onFocus: observedTime.input.onFocus,
                  onBlur: observedTime.input.onBlur,
                  'data-testid': t('observationForm.field.observedTime.title')
                }}
                required={true}
                shouldDisplayAsError={
                  currentDate ===
                    DateTime.fromJSDate(observedDate.input.value) &&
                  DateTime.fromISO(observedTime.input.value).toFormat(
                    "yyyy-MM-dd'T'TT"
                  ) > maxObservedDateAndTime
                }
              />
              {observedTime.meta.error && (
                <Typography color="primary" className={classes.errorMessage}>
                  {t('observationForm.validation.configureObservationTime', {
                    maxObservedTime: DateTime.fromFormat(
                      maxObservedDateAndTime,
                      "yyyy-MM-dd'T'TT"
                    ).toFormat('hh:mm a')
                  })}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <ObservationTypeToggle
                setObservationType={setObservationType}
                selectedObservation={selectedObservation}
                form={form}
              />
            </Grid>
            <Grid item xs={12} className={classes.allSafeContainer}>
              <ObservationAllSafe
                isAllSafe={isAllSafe}
                setIsAllSafe={setIsAllSafe}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledNotice
                type="warning"
                title={t('upsertObservationDialog.userAgreement.title')}
                message={t('upsertObservationDialog.userAgreement.message')}
              />
            </Grid>
            {shouldShowRemainingFields && (
              <>
                {typeIsBehavioral && (
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <TradePartnerPersonnelSelect
                      selectedTradePartnerPersonnel={observedPerson.input.value}
                      meta={observedPerson.meta}
                      handleChange={observedPerson.input.onChange}
                      tradePartnerPersonnelOptions={
                        observablePeopleForSelectedTradePartner ?? []
                      }
                      isLoading={isLoading}
                      isDisabled={
                        isLoading || !observedTradePartner.input.value
                      }
                      isRequired={true}
                      placeholder={t(
                        'observationForm.field.observedPerson.placeholder'
                      )}
                      label={t('observationForm.field.observedPerson.title')}
                    />
                  </Grid>
                )}
                {shouldShowRemainingFields && (
                  <>
                    {typeIsBehavioral && (
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <ObservationCategories
                          observationType={observationType}
                          selectedCategories={selectedCategories}
                          setSelectedCategories={setSelectedCategories}
                          selectedObservation={selectedObservation}
                        />
                      </Grid>
                    )}
                    {typeIsEnvironmental && (
                      <Grid item xs={12}>
                        <ObservationCategories
                          observationType={observationType}
                          selectedCategories={selectedCategories}
                          setSelectedCategories={setSelectedCategories}
                          selectedObservation={selectedObservation}
                        />
                      </Grid>
                    )}
                  </>
                )}
                {selectedCategories.length > 0 && (
                  <Grid item xs={12}>
                    <ObservationSubcategories
                      isAllSafe={isAllSafe}
                      selectedCategories={selectedCategories}
                      selectedSubCategories={selectedSubCategories}
                      setSelectedSubCategories={setSelectedSubCategories}
                      shouldShowFlag={false}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <ObservationCauseSelect
                    form={form}
                    isLoading={isLoading}
                    isAllSafe={isAllSafe}
                  />
                </Grid>
                {selectedObservation && (
                  <Grid item xs={12} className="margin-bottom">
                    <ObservationDetails
                      selectedObservation={selectedObservation}
                    />
                  </Grid>
                )}
              </>
            )}
          </>
        )}
        {(observationIsClosed || deletable) && (
          <>
            <Grid item>
              <ConfirmationField
                label={t(
                  'observationForm.confirmationField.observedTradePartner.title'
                )}
                content={selectedObservation?.tradePartner?.name}
              />
              <ConfirmationField
                label={t('observationForm.confirmationField.area.title')}
                content={selectedObservation?.projectArea}
              />
              <ConfirmationField
                label={t(
                  'observationForm.confirmationField.observedDate.title'
                )}
                content={moment(selectedObservation?.observedDateTime).format(
                  'MM/DD/YYYY'
                )}
              />
              <ConfirmationField
                label={t(
                  'observationForm.confirmationField.observedTime.title'
                )}
                content={moment(selectedObservation?.observedDateTime).format(
                  'h:mm a'
                )}
              />
              <ConfirmationField
                label={t('observationForm.confirmationField.type.title')}
                content={selectedObservation?.type}
              />
              <ConfirmationField
                label={t('observationForm.confirmationField.allSafe.title')}
                content={
                  selectedObservation?.isAllSafe
                    ? t('observationForm.confirmationField.allSafe.yes')
                    : t('observationForm.confirmationField.allSafe.no')
                }
              />
              {selectedObservation?.type === 'Behavioral' && (
                <ConfirmationField
                  label={t(
                    'observationForm.confirmationField.observedPerson.title'
                  )}
                  content={
                    getTradePartnerPersonnelLabel(
                      selectedObservation?.observedPerson
                    ) ?? ''
                  }
                />
              )}
              <ConfirmationField
                label={t('observationForm.confirmationField.categories.title')}
                content={
                  deletable
                    ? selectedObservation.selectedSubCategories
                        .map(
                          subCategory =>
                            `${subCategory.category.name} (${subCategory.name})`
                        )
                        .join(', ')
                    : selectedObservation?.items
                        ?.map(
                          item =>
                            `${item.subCategory.category.name} (${item.subCategory.name})`
                        )
                        .join(', ')
                }
              />
              <ConfirmationField
                label={t('observationForm.confirmationField.causes.title')}
                content={
                  deletable
                    ? selectedObservation?.causes
                        ?.map(cause => cause.label)
                        .join(', ')
                    : selectedObservation?.causes
                        ?.map(cause => cause.name)
                        .join(', ')
                }
              />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

ObservationPartnerForm.propTypes = {
  form: PropTypes.any,
  isLoading: PropTypes.bool,
  observationType: PropTypes.string,
  setObservationType: PropTypes.func,
  isAllSafe: PropTypes.bool,
  setIsAllSafe: PropTypes.func,
  selectedCategories: PropTypes.array,
  setSelectedCategories: PropTypes.func,
  selectedSubCategories: PropTypes.array,
  setSelectedSubCategories: PropTypes.func,
  selectedObservation: PropTypes.object,
  isOnline: PropTypes.bool,
  deletable: PropTypes.bool
};

export default ObservationPartnerForm;
