import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ApolloProvider } from '@apollo/react-hooks';

import useSoteriaApolloClient from 'config/apolloConfig';
import LoadingSpinner from 'components/common/LoadingSpinner';
import useOfflineModeCapabilities from 'store/manualOfflineMode';

const SoteriaApolloProvider = ({ children }) => {
  const {
    offlineCapableClient,
    onlineModeOnlyClient,
    purgeOfflineCache
  } = useSoteriaApolloClient();
  const { isOfflineModeEnabled } = useOfflineModeCapabilities();

  const client = isOfflineModeEnabled
    ? offlineCapableClient
    : onlineModeOnlyClient;

  useEffect(() => {
    if (!isOfflineModeEnabled && offlineCapableClient) {
      purgeOfflineCache();
    }
  }, [isOfflineModeEnabled, offlineCapableClient, purgeOfflineCache]);

  if (!client) {
    return <LoadingSpinner isFullScreen={true} />;
  }
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
SoteriaApolloProvider.propTypes = {
  children: PropTypes.node
};

export default SoteriaApolloProvider;
