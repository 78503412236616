import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { useField } from 'react-final-form-hooks';
import { useDropzone } from 'react-dropzone';
import DeleteIcon from '@mui/icons-material/Delete';
import PictureAsPdf from '@mui/icons-material/PictureAsPdf';
import makeStyles from '@mui/styles/makeStyles';
import {
  Grid,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tooltip
} from '@mui/material';

import useFormValidation from 'hooks/useFormValidation';
import StyledInput from 'shared/Input';
import StyledStep from 'shared/Step';
import StyledKeyboardDatePickerField from 'shared/KeyboardDatePickerField';
import StyledPhoneNumberField from 'shared/PhoneNumberField';
import ButtonPrimary from 'shared/Buttons/ButtonPrimary';
import TrainingDisplayImage from 'components/common/SoteriaFileDisplayImage';
import { fileIsImage, fileIsPdf } from 'utils';
import { MAX_FILE_SIZE_IN_BYTES } from 'constants/maxFileSize';
import useStringIncludesHenselPhelps from '../../../../hooks/useStringIncludesHenselPhelps';

const useStyles = makeStyles(theme => ({
  labelText: { fontSize: '0.75rem', fontWeight: 'bold' },
  desktopInstructions: {
    '&:hover': { cursor: 'pointer' },
    [theme.breakpoints.down('lg')]: { display: 'none' }
  },
  mobileInstructions: {
    [theme.breakpoints.up('md')]: { display: 'none' }
  },
  dropzone: {
    width: '100%',
    height: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    outline: 'none',
    borderColor: theme.palette.secondary.dark,
    borderStyle: 'dashed',
    borderWidth: 2,
    borderRadius: 4
  },
  imageUploadErrorDropzone: {
    width: '100%',
    height: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.error.main,
    outline: 'none',
    borderColor: theme.palette.error.main,
    borderStyle: 'dashed',
    borderWidth: 2,
    borderRadius: 4
  },
  container: {
    width: '100%',
    height: 100,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.dark,
    outline: 'none',
    transition: 'border .24s ease-in-out',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  imageUploadErrorContainer: {
    width: '100%',
    height: 100,
    margin: '8px 8px 16px 8px',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.error.main,
    outline: 'none',
    transition: 'border .24s ease-in-out',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: 40
  },
  icon: {
    color: theme.palette.secondary.light,
    fontSize: '1.125rem',
    paddingRight: 6
  },
  thumbsContainer: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
    borderRadius: 4,
    justifyContent: 'space-between',
    height: 125,
    [theme.breakpoints.down('lg')]: {
      height: 175,
      justifyContent: 'space-around'
    }
  },
  thumb: {
    display: 'inline-flex',
    marginBottom: 8,
    marginRight: 8,
    marginLeft: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
  },
  thumbInner: {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
    flexDirection: 'row'
  },
  img: {
    display: 'block',
    width: 'auto',
    height: '100%'
  },
  imgText: {
    display: 'flex',
    alignItems: 'center'
  },
  fileName: {
    whiteSpace: 'nowrap',
    alignSelf: 'center',
    marginLeft: 40,
    maxWidth: 300,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontFamily: 'Roboto, ui-sans-serif, sans-serif',
    [theme.breakpoints.down('lg')]: {
      marginLeft: 0,
      width: 150,
      maxWidth: 150
    }
  },
  instructions: {
    textAlign: 'center',
    color: theme.palette.secondary.contrastText
  },
  removeButtonContainer: { display: 'flex', alignItems: 'center' },
  pdfIcon: { width: '100%', height: '100%' },
  uploadedFilesContainer: {
    marginTop: theme.spacing(1)
  },
  supervisorSection: {
    marginTop: 10
  }
}));

const PersonalInfoFormStep = ({
  form,
  isLoading,
  updatedProfileImage,
  setUpdatedProfileImage,
  isHenselPhelpsEmployee,
  selectedPersonnelId
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isRequired, isValidPastDate, isPhoneNumber } = useFormValidation();
  const [imageUploadError, setImageUploadError] = useState(null);
  const { stringIncludesHenselPhelps } = useStringIncludesHenselPhelps();
  const { values } = form.getState();
  const isHP = stringIncludesHenselPhelps(values.selectedTradePartner?.name);

  const validatePhoneNumber = value => {
    if (!value) {
      return isRequired(value);
    }
    return isPhoneNumber(value);
  };

  const firstName = useField('firstName', form, isRequired);
  const middleName = useField('middleName', form);
  const lastName = useField('lastName', form, isRequired);
  const nickname = useField('nickname', form);
  const dob = useField('dob', form, value =>
    isHP ? undefined : isValidPastDate(value)
  );
  const phoneNumber = useField('phoneNumber', form, validatePhoneNumber);
  const email = useField('email', form, (value, fields) =>
    fields.isSupervisor && !value
      ? t('formValidation.requiredForSupervisors')
      : undefined
  );
  const customId = useField('customId', form);
  const isSupervisor = useField('isSupervisor', form);
  const isPermitApprover = useField('isPermitApprover', form);

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    multiple: false,
    maxSize: MAX_FILE_SIZE_IN_BYTES,
    maxFiles: 1,
    onDropAccepted: acceptedFile => {
      setImageUploadError(null);
      validateFiles(acceptedFile[0]);
    },
    onDropRejected: rejectedFile => {
      const errorMessage = rejectedFile[0].errors[0].code;
      if (errorMessage === 'file-too-large') {
        setImageUploadError(
          t('addFile.largeFileError', {
            fileSize: Intl.NumberFormat(i18next.language, {
              style: 'unit',
              unit: 'megabyte'
            }).format(MAX_FILE_SIZE_IN_BYTES / 1000000)
          })
        );
        return;
      }
      setImageUploadError(t('addFile.generalFileUploadError'));
    }
  });

  const validateFiles = fileToCheck => {
    const formattedFile = formatFile(fileToCheck);
    setUpdatedProfileImage(formattedFile);
    return;
  };

  const formatFile = file => {
    const fileWithPreview = generateFilePreviews([file]);
    if (!file.path) {
      Object.assign(fileWithPreview[0], {
        path: file.name
      });
    }
    return fileWithPreview[0];
  };

  const generateFilePreviews = files => {
    const filesWithPreviews = files.map(file => {
      if (file) {
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        });
        return file;
      }
      return files;
    });
    return filesWithPreviews;
  };

  const deleteLocalImage = () => {
    if (updatedProfileImage?.preview) {
      URL.revokeObjectURL(updatedProfileImage.preview);
    }
    setUpdatedProfileImage(null);
  };

  return (
    <Fragment>
      <StyledStep title="">
        <Grid
          container
          direction="row"
          spacing={1}
          data-testid="personal-info-form"
          role="form">
          {!isHenselPhelpsEmployee && (
            <>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <StyledInput
                  input={firstName.input}
                  meta={firstName.meta}
                  disabled={isLoading}
                  label={t('createProjectPersonnelDialog.fields.firstName')}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <StyledInput
                  input={middleName.input}
                  meta={middleName.meta}
                  disabled={isLoading}
                  label={t('createProjectPersonnelDialog.fields.middleName')}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <StyledInput
                  input={lastName.input}
                  meta={lastName.meta}
                  disabled={isLoading}
                  label={t('createProjectPersonnelDialog.fields.lastName')}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <StyledInput
                  input={nickname.input}
                  meta={nickname.meta}
                  disabled={isLoading}
                  label={t('createProjectPersonnelDialog.fields.nickname')}
                />
              </Grid>
            </>
          )}
          {isHenselPhelpsEmployee && selectedPersonnelId === 'no matches' && (
            <Grid item xs={12}>
              <StyledInput
                input={nickname.input}
                meta={nickname.meta}
                disabled={isLoading}
                label={t('createProjectPersonnelDialog.fields.nickname')}
              />
            </Grid>
          )}
          {!isHenselPhelpsEmployee && (
            <>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <StyledKeyboardDatePickerField
                  label={t('createProjectPersonnelDialog.fields.dob')}
                  value={dob.input.value || null}
                  handleDateChange={dob.input.onChange}
                  meta={dob.meta}
                  inputProps={{
                    onFocus: dob.input.onFocus,
                    onBlur: dob.input.onBlur,
                    'data-testid': 'dobPicker'
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <StyledPhoneNumberField
                  label={t('createProjectPersonnelDialog.fields.phoneNumber')}
                  value={phoneNumber.input.value}
                  meta={phoneNumber.meta}
                  onChange={phoneNumber.input.onChange}
                  disabled={isLoading}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <StyledInput
                  input={email.input}
                  meta={email.meta}
                  disabled={isLoading}
                  label={t('createProjectPersonnelDialog.fields.email')}
                  errorBeforeTouched
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <StyledInput
              input={customId.input}
              meta={customId.meta}
              disabled={isLoading}
              label={t('createProjectPersonnelDialog.fields.customId')}
              placeholder={t(
                'createProjectPersonnelDialog.fields.customIdPlaceholder'
              )}
            />
          </Grid>
          {!isHenselPhelpsEmployee && (
            <>
              <Grid item xs={12}>
                <Typography color="textSecondary" className={classes.labelText}>
                  {t('createProjectPersonnelDialog.fields.image')}
                </Typography>
                <Grid
                  container
                  className={
                    !imageUploadError
                      ? classes.container
                      : classes.imageUploadErrorContainer
                  }>
                  <Grid
                    item
                    className={
                      !imageUploadError
                        ? classes.dropzone
                        : classes.imageUploadErrorDropzone
                    }
                    {...getRootProps()}>
                    <input {...getInputProps()} />
                    {imageUploadError && <p>{imageUploadError}</p>}
                    {!imageUploadError && (
                      <>
                        <Typography
                          color="textSecondary"
                          className={classes.desktopInstructions}>
                          {t('addFile.uploadImage')}
                        </Typography>
                        <Typography
                          color="textSecondary"
                          className={classes.mobileInstructions}>
                          {t('addFile.takePhoto')}
                        </Typography>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}

          {updatedProfileImage && (
            <Grid container className={classes.uploadedFilesContainer}>
              <Grid item>
                <Typography className={classes.labelText}>
                  {t('addFile.uploadedFiles')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  direction="row"
                  className={classes.thumbsContainer}>
                  <Grid item className={classes.imgText}>
                    <div className={classes.thumb}>
                      <div className={classes.thumbInner}>
                        {fileIsImage(updatedProfileImage) && (
                          <TrainingDisplayImage file={updatedProfileImage} />
                        )}
                        {fileIsPdf(updatedProfileImage) && (
                          <PictureAsPdf className={classes.pdfIcon} />
                        )}
                      </div>
                    </div>
                    <Tooltip title={updatedProfileImage.name}>
                      <div className={classes.fileName}>
                        {updatedProfileImage.name}
                      </div>
                    </Tooltip>
                  </Grid>
                  <Grid item className={classes.removeButtonContainer}>
                    <ButtonPrimary
                      className={classes.removeButton}
                      label={t('addFile.removeFileButton')}
                      onClick={() => deleteLocalImage(updatedProfileImage)}
                      icon={<DeleteIcon className={classes.icon} />}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} className={classes.supervisorSection}>
          <Typography color="textSecondary" className={classes.labelText}>
            {t('createPersonnelDialog.checkbox.supervisor')}
          </Typography>
          <FormControl variant="standard" component="fieldset">
            <RadioGroup
              name="personnelMatches"
              value={isSupervisor.input.value}
              onChange={() =>
                isSupervisor.input.onChange(!isSupervisor.input.value)
              }>
              <FormControlLabel
                value={true}
                control={<Radio color="primary" />}
                label={t('createPersonnelDialog.checkbox.supervisorStatus')}
              />
              <FormControlLabel
                value={false}
                control={<Radio color="primary" />}
                label={t('createPersonnelDialog.checkbox.noSupervisorStatus')}
                data-testid="notSupervisor"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        {isHenselPhelpsEmployee && (
          <Grid item xs={12} className={classes.supervisorSection}>
            <Typography color="textSecondary" className={classes.labelText}>
              {t('createPersonnelDialog.checkbox.isPermitApprover')}
            </Typography>
            <FormControl variant="standard" component="fieldset">
              <RadioGroup
                name="personnelMatches"
                value={isPermitApprover.input.value}
                onChange={() =>
                  isPermitApprover.input.onChange(!isPermitApprover.input.value)
                }>
                <FormControlLabel
                  value={true}
                  control={<Radio color="primary" />}
                  label={t(
                    'createPersonnelDialog.checkbox.isPermitApproverStatus'
                  )}
                />
                <FormControlLabel
                  value={false}
                  control={<Radio color="primary" />}
                  label={t(
                    'createPersonnelDialog.checkbox.noIsPermitApproverStatus'
                  )}
                  data-testid="notIsPermitApprover"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        )}
      </StyledStep>
    </Fragment>
  );
};

PersonalInfoFormStep.propTypes = {
  form: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  updatedProfileImage: PropTypes.object,
  setUpdatedProfileImage: PropTypes.func,
  isHenselPhelpsEmployee: PropTypes.bool,
  selectedPersonnelId: PropTypes.string
};

export default PersonalInfoFormStep;
