import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ImageIcon from '@mui/icons-material/Image';
import ErrorIcon from '@mui/icons-material/Error';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  img: { display: 'block', maxWidth: '90%' },
  fullSizeScale: {
    width: '50%',
    height: '50%',
    alignSelf: 'center'
  }
}));

const SoteriaFileDisplayImage = ({ file, isFullSizePreview = false }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  if (file) {
    // catches newly uploaded files not sent to API yet
    if (file.preview && !file.cachedUrl) {
      return (
        <img
          data-testid="image-preview"
          src={file.preview}
          className={classes.img}
          alt={`${t('addFile.training')}-${file.name}`}
        />
      );
    }

    if (file.cachedUrl && !file.isMalWare) {
      return (
        <img
          data-testid="image-cachedUrl"
          src={file.cachedUrl}
          className={classes.img}
          alt={`${t('addFile.training')}-${file.name}`}
        />
      );
    }

    if (file.isMalware) {
      return (
        <ErrorIcon
          data-testid="malware-icon"
          className={isFullSizePreview ? classes.fullSizeScale : ''}
        />
      );
    }
  }

  return (
    <>
      <ImageIcon
        data-testid="image-icon"
        className={
          isFullSizePreview ? classes.fullSizeScale : classes.imagePlaceholder
        }
      />
    </>
  );
};

SoteriaFileDisplayImage.propTypes = {
  file: PropTypes.object,
  isFullSizePreview: PropTypes.bool
};

export default SoteriaFileDisplayImage;
