import React from 'react';
import PropTypes from 'prop-types';
import { SnackbarProvider, MaterialDesignContent } from 'notistack';
import { styled, useTheme } from '@mui/styles';

const ToastProvider = ({ children }) => {
  const theme = useTheme();

  const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
    '&.notistack-MuiContent': {
      padding: theme.spacing(1, 2),
      color: theme.palette.primary.contrastText,
      fontSize: '0.875rem'
    },
    '&.notistack-MuiContent-success': {
      backgroundColor: theme.palette.success.main
    },
    '&.notistack-MuiContent-error': {
      backgroundColor: theme.palette.error.main
    },
    '&.notistack-MuiContent-warning': {
      backgroundColor: theme.palette.warning.main
    },
    '&.notistack-MuiContent-info': {
      backgroundColor: theme.palette.info.main
    }
  }));
  return (
    <SnackbarProvider
      maxSnack={2}
      Components={{
        success: StyledMaterialDesignContent,
        error: StyledMaterialDesignContent,
        warning: StyledMaterialDesignContent,
        info: StyledMaterialDesignContent
      }}>
      {children}
    </SnackbarProvider>
  );
};

ToastProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default ToastProvider;
