import { atom, useRecoilState } from 'recoil';

const defaultState = { isLoading: true };

const currentUserStateAtom = atom({
  key: 'currentUserState',
  default: defaultState
});

export const useCurrentUserState = () => {
  const [currentUserState, setCurrentUserState] = useRecoilState(
    currentUserStateAtom
  );

  return [
    currentUserState,
    {
      setCurrentUserState
    }
  ];
};

export default currentUserStateAtom;
