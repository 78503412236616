/* eslint-disable react/prop-types */
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import { useForm } from 'react-final-form-hooks';
import AddIcon from '@mui/icons-material/Add';
import {
  Grid,
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepContent
} from '@mui/material';

import useSoteriaStore from 'hooks/useSoteriaStore';
import useStringIncludesHenselPhelps from 'hooks/useStringIncludesHenselPhelps';
import useToast from 'hooks/useToast';
import StyledFab from 'shared/Buttons/Fab';
import StyledDialog from 'shared/Dialog';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import StyledButtonSecondary from 'shared/Buttons/ButtonSecondary';
import StyledButtonGroup from 'shared/Buttons/ButtonGroup';
import PersonnelMatchDetails from 'components/personnel/PersonnelMatchDetails';
import {
  SelectTradePartnerStep,
  CreatePersonStep,
  VerifyMatchesStep,
  ConfirmPersonStep,
  PersonalInfoFormStep
} from 'components/personnel/CreatePersonnelSteps';
import { generateTransactionKey, getPhoneValues } from 'utils';
import { UPSERT_PERSONNEL_TO_PROJECT } from 'graphql/tradePartnerPersonnel';

const AddProjectPersonnelDialog = ({ refetchQuery }) => {
  const { t } = useTranslation();
  const {
    store: { whatsNewIsOpen = false }
  } = useSoteriaStore();
  const [personnelTransactionKey, setPersonnelTransactionKey] = useState(
    generateTransactionKey()
  );
  const { displayToast } = useToast();
  const { stringIncludesHenselPhelps } = useStringIncludesHenselPhelps();
  const [isOpen, toggleDialog] = useState(false);
  const [activeStepIndex, setActiveStep] = useState(0);
  const [personnelDetails, setPersonnelDetails] = useState(null);
  const [isDetailsPanelOpen, setIsDetailsPanelOpen] = useState(false);
  const [
    isCancelConfirmationRendered,
    setIsCancelConfirmationRendered
  ] = useState(false);
  const [updatedProfileImage, setUpdatedProfileImage] = useState(null);
  const [selectedMatch, setSelectedMatch] = useState(null);

  const { form, values } = useForm({
    /* istanbul ignore next */
    onSubmit: () => {}, // this function required for useForm but is not used
    initialValues: {
      dob: null,
      noMatchFound: false,
      selectedPersonnelId: null,
      isSupervisor: false,
      isPermitApprover: false,
      personnelConfirmation: false
    }
  });

  const [upsertTradePartnerToProject, { loading: isLoading }] = useMutation(
    UPSERT_PERSONNEL_TO_PROJECT
  );

  const { errors } = form.getState();

  const isHenselPhelpsEmployee = stringIncludesHenselPhelps(
    values?.selectedTradePartner?.name
  );

  const henselPhelpsSteps = [
    t('addProjectPersonnelDialog.steps.henselPhelps.selectTradePartner'),
    t('addProjectPersonnelDialog.steps.henselPhelps.addPersonnelToProject'),
    t('addProjectPersonnelDialog.steps.nonHenselPhelps.verify'),
    t('addProjectPersonnelDialog.steps.nonHenselPhelps.addPersonnelToProject'),
    t('addProjectPersonnelDialog.steps.nonHenselPhelps.confirm')
  ];

  const nonHenselPhelpsSteps = [
    t('addProjectPersonnelDialog.steps.nonHenselPhelps.selectTradePartner'),
    t('addProjectPersonnelDialog.steps.nonHenselPhelps.addPersonnelToProject'),
    t('addProjectPersonnelDialog.steps.nonHenselPhelps.verify'),
    t('addProjectPersonnelDialog.steps.nonHenselPhelps.confirm')
  ];

  const getSteps = () => {
    if (isHenselPhelpsEmployee) {
      return henselPhelpsSteps;
    }

    return nonHenselPhelpsSteps;
  };

  const isComplete = activeStepIndex === getSteps().length - 1;

  const handleDetailsClick = personnel => {
    setIsDetailsPanelOpen(true);
    setPersonnelDetails(personnel);
  };

  const handleClose = () => {
    toggleDialog(false);
    setPersonnelTransactionKey(generateTransactionKey());
    setActiveStep(0);
    setPersonnelDetails(null);
    setIsDetailsPanelOpen(false);
    setIsCancelConfirmationRendered(false);
    setUpdatedProfileImage(null);
    form.reset();
  };

  const handleShouldClose = () => {
    if (activeStepIndex < 1 && !values.selectedTradePartner) {
      handleClose();
    } else {
      setIsCancelConfirmationRendered(true);
    }
  };

  const handleNext = () => {
    setActiveStep(prevActiveStepIndex => prevActiveStepIndex + 1);
  };

  const handleBack = () => {
    if (personnelDetails) {
      setIsDetailsPanelOpen(false);
      setPersonnelDetails(null);
    } else {
      setActiveStep(prevActiveStepIndex => prevActiveStepIndex - 1);
    }
  };

  const handleSubmit = () => {
    // Important to return undefined instead of null as it is not being omitted by Apollo as it should.

    const isUpdateMutation =
      values.selectedPersonnelId && values.selectedPersonnelId !== 'no matches';

    const personnelToUpsert = {
      dob:
        values.selectedHenselPhelpsEmployee?.dob ??
        values.dob?.toFormat('yyyy-MM-dd') ??
        undefined, // A date string, such as 2007-12-03 (year-month-day).
      upn: isUpdateMutation
        ? undefined
        : values.selectedHenselPhelpsEmployee?.upn,
      firstName:
        values.selectedHenselPhelpsEmployee?.firstName ?? values.firstName,
      lastName:
        values.selectedHenselPhelpsEmployee?.lastName ?? values.lastName,
      middleName: values.middleName ?? undefined,
      nickname: values.nickname ?? undefined,
      contactInformation:
        values.selectedHenselPhelpsEmployee?.email ||
        values.email ||
        values.phoneNumber ||
        values.phone
          ? {
              emailAddress:
                values.selectedHenselPhelpsEmployee?.email || values.email
                  ? {
                      email:
                        values.selectedHenselPhelpsEmployee?.email ??
                        values.email,
                      type: 'Primary'
                    }
                  : undefined,
              primaryPhone: getPhoneValues(
                values.phoneNumber ?? values.phone,
                null,
                null,
                'Primary'
              )
            }
          : undefined,
      profileImage: updatedProfileImage,
      trainingsToAdd: [],
      emergencyContacts: [],
      onboardingApplicantId:
        values.selectedHenselPhelpsEmployee?.__typename ===
        'OnboardingApplicant'
          ? values.selectedHenselPhelpsEmployee.id
          : undefined
    };

    const personnelRecordInput = {
      personnelToCreate: !isUpdateMutation
        ? { ...personnelToUpsert, transactionKey: personnelTransactionKey }
        : undefined,
      personnelToUpdate: isUpdateMutation
        ? {
            ...personnelToUpsert,
            id: selectedMatch
              ? selectedMatch.id
              : values.selectedPersonnelId.id,
            flagsToAdd: [],
            flagsToDelete: [],
            flagsToUpdate: [],
            trainingsToDelete: [],
            trainingsToUpdate: []
          }
        : undefined,
      tradePartnerPersonnel: {
        isActive: true,
        isSupervisor: values.isSupervisor,
        isPermitApprover: values.isPermitApprover,
        tradePartnerId: values.selectedTradePartner.id,
        customId: values.customId
      }
    };

    upsertTradePartnerToProject({
      variables: { input: personnelRecordInput }
    }).then(
      ({ data: { upsertPersonnelToProject: tradePartnerPersonnel } }) => {
        refetchQuery();
        handleSuccess(tradePartnerPersonnel);
      },
      error => {
        console.error('Add TradePartnerPersonnel Error: ', error);
        displayToast(
          t('addProjectPersonnelDialog.toasts.tradePartnerPersonnel.error'),

          'error'
        );
      }
    );

    function handleSuccess(tradePartnerPersonnel) {
      displayToast(
        t('addProjectPersonnelDialog.toasts.success', {
          tradePartnerPersonnelName: tradePartnerPersonnel.personnel.fullName
        }),
        'success'
      );
      handleClose();
    }
  };

  const canContinueForHenselPhelpsEmployee = () => {
    switch (activeStepIndex) {
      case 0:
        return !errors.selectedTradePartner;
      case 1:
        return !errors.selectedHenselPhelpsEmployee;
      case 2:
        return !errors.selectedPersonnelId;
      case 3:
        return true;
      default:
        return false;
    }
  };

  const canContinueForTradePartnerEmployee = () => {
    switch (activeStepIndex) {
      case 0:
        return !errors.selectedTradePartner;
      case 1:
        return !(
          errors.selectedTradePartner ||
          errors.firstName ||
          errors.lastName ||
          errors.dob ||
          errors.phoneNumber
        );

      case 2:
        // TODO: check for selected employee or create new
        return !errors.selectedPersonnelId;

      case 3:
        return true;
      default:
        return false;
    }
  };

  const canContinue = (() => {
    return isHenselPhelpsEmployee
      ? canContinueForHenselPhelpsEmployee()
      : canContinueForTradePartnerEmployee();
  })();

  const canSubmit = (() => {
    if (isHenselPhelpsEmployee) {
      return !(
        errors.selectedTradePartner ||
        errors.selectedHenselPhelpsEmployee ||
        errors.personnelConfirmation ||
        isLoading
      );
    } else {
      return !(
        errors.selectedTradePartner ||
        errors.firstName ||
        errors.lastName ||
        errors.dob ||
        errors.personnelConfirmation ||
        isLoading
      );
    }
  })();

  return (
    <Fragment>
      <StyledFab
        data-testid="add-project-personnel-dialog-fab-button"
        onClick={() => toggleDialog(true)}
        shouldShift={whatsNewIsOpen}
        icon={<AddIcon />}
      />
      <StyledDialog
        data-testid="add-project-personnel-dialog"
        isOpen={isOpen}
        shouldShowCloseIconButton={!isCancelConfirmationRendered}
        handleClose={handleShouldClose}
        title={
          !isCancelConfirmationRendered
            ? t('addProjectPersonnelDialog.title')
            : t('addProjectPersonnelDialog.cancelConfirmationTitle')
        }
        isLoading={isLoading}
        content={
          <form>
            {isCancelConfirmationRendered && (
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Typography>
                    {t('addProjectPersonnelDialog.cancelConfirmation.message')}
                  </Typography>
                </Grid>
                <Grid item>
                  <StyledButtonGroup
                    onClickAltAction={() =>
                      setIsCancelConfirmationRendered(false)
                    }
                    labelAltAction={t(
                      'addProjectPersonnelDialog.actions.goBackButton'
                    )}
                    disabledAltAction={isLoading}
                    onClickMainAction={() => {
                      handleClose();
                    }}
                    labelMainAction={t(
                      'addProjectPersonnelDialog.actions.discardButton'
                    )}
                    disabledMainAction={isLoading}
                  />
                </Grid>
              </Grid>
            )}
            {!isCancelConfirmationRendered && (
              <Grid container justifyContent="center">
                {personnelDetails && (
                  <PersonnelMatchDetails
                    personnelDetails={personnelDetails}
                    handleBack={handleBack}
                    isLoading={isLoading}
                  />
                )}
                {!personnelDetails && (
                  <Grid item xs={12}>
                    <Stepper
                      orientation="vertical"
                      activeStep={activeStepIndex}>
                      {getSteps().map(label => (
                        <Step key={label}>
                          <StepLabel>{label}</StepLabel>
                          <StepContent>
                            <Typography component="div">
                              {activeStepIndex === 0 && (
                                <SelectTradePartnerStep form={form} />
                              )}
                              {activeStepIndex === 1 && (
                                <CreatePersonStep
                                  isHenselPhelpsEmployee={
                                    isHenselPhelpsEmployee
                                  }
                                  form={form}
                                  isLoading={isLoading}
                                  updatedProfileImage={updatedProfileImage}
                                  setUpdatedProfileImage={
                                    setUpdatedProfileImage
                                  }
                                />
                              )}
                              {isHenselPhelpsEmployee && (
                                <Fragment>
                                  {activeStepIndex === 2 && (
                                    <VerifyMatchesStep
                                      form={form}
                                      handleDetailsClick={handleDetailsClick}
                                      updatedProfileImage={updatedProfileImage}
                                      setSelectedMatch={setSelectedMatch}
                                    />
                                  )}
                                  {activeStepIndex === 3 && (
                                    <PersonalInfoFormStep
                                      form={form}
                                      isLoading={isLoading}
                                      updatedProfileImage={updatedProfileImage}
                                      setUpdatedProfileImage={
                                        setUpdatedProfileImage
                                      }
                                      isHenselPhelpsEmployee={
                                        isHenselPhelpsEmployee
                                      }
                                      selectedPersonnelId={
                                        values.selectedPersonnelId
                                      }
                                    />
                                  )}
                                  {activeStepIndex === 4 && (
                                    <ConfirmPersonStep
                                      formValues={values}
                                      form={form}
                                      isHenselPhelpsEmployee={
                                        isHenselPhelpsEmployee
                                      }
                                      updatedProfileImage={updatedProfileImage}
                                      selectedMatch={selectedMatch}
                                    />
                                  )}
                                </Fragment>
                              )}
                              {!isHenselPhelpsEmployee && (
                                <Fragment>
                                  {activeStepIndex === 2 && (
                                    <VerifyMatchesStep
                                      form={form}
                                      handleDetailsClick={handleDetailsClick}
                                      updatedProfileImage={updatedProfileImage}
                                      setSelectedMatch={setSelectedMatch}
                                    />
                                  )}

                                  {activeStepIndex === 3 && (
                                    <ConfirmPersonStep
                                      formValues={values}
                                      form={form}
                                      isHenselPhelpsEmployee={
                                        isHenselPhelpsEmployee
                                      }
                                      updatedProfileImage={updatedProfileImage}
                                      selectedMatch={selectedMatch}
                                    />
                                  )}
                                </Fragment>
                              )}
                            </Typography>
                          </StepContent>
                        </Step>
                      ))}
                    </Stepper>
                  </Grid>
                )}
              </Grid>
            )}
          </form>
        }
        actions={
          !isCancelConfirmationRendered && (
            <Fragment>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <StyledButtonSecondary
                    label={t('addProjectPersonnelDialog.actions.cancelButton')}
                    disabled={isLoading}
                    onClick={handleShouldClose}
                  />
                </Grid>
                <Grid item>
                  <Grid
                    container
                    justifyContent="flex-end"
                    direction="row"
                    spacing={2}>
                    <Grid item>
                      {activeStepIndex !== 0 && (
                        <StyledButtonPrimary
                          disabled={isLoading || isDetailsPanelOpen}
                          label={t(
                            'addProjectPersonnelDialog.actions.backButton'
                          )}
                          onClick={handleBack}
                        />
                      )}
                    </Grid>
                    <Grid item>
                      {!isComplete && (
                        <StyledButtonPrimary
                          label={
                            !isHenselPhelpsEmployee &&
                            activeStepIndex === 2 &&
                            values.selectedPersonnelId !== null &&
                            values.selectedPersonnelId !== 'no matches'
                              ? t(
                                  'addProjectPersonnelDialog.actions.selectMatchButton'
                                )
                              : t(
                                  'addProjectPersonnelDialog.actions.nextButton'
                                )
                          }
                          disabled={!canContinue || isDetailsPanelOpen}
                          onClick={handleNext}
                        />
                      )}
                      {isComplete && (
                        <StyledButtonPrimary
                          label={t(
                            'addProjectPersonnelDialog.actions.submitButton'
                          )}
                          disabled={!canSubmit}
                          onClick={handleSubmit}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Fragment>
          )
        }
      />
    </Fragment>
  );
};

export default AddProjectPersonnelDialog;
