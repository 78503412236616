import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Paper } from '@mui/material';

import useActiveRoute from 'hooks/useActiveRoute';

const useStyles = makeStyles(theme => ({
  container: {
    height: 'calc(100vh - 176px)',
    [theme.breakpoints.down('lg')]: {
      height: 'calc(100vh - 152px)'
    },
    [theme.breakpoints.down('md')]: {
      height: 'calc(100vh - 104px)'
    }
  },
  containerOnDashboard: { paddingTop: 128, height: '100vh' },
  paper: { borderTop: '12px solid' + theme.palette.primary.main },
  content: { padding: theme.spacing(3), borderRadius: 4 }
}));

export const StyledUserNoticeTemplate = ({ image, content }) => {
  const classes = useStyles();
  const { isActiveRoute } = useActiveRoute();
  const isDashboardPage = isActiveRoute('/projects/:projectId/dashboard');

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      alignContent="center"
      className={classNames(classes.container, {
        [`${classes.containerOnDashboard}`]: isDashboardPage
      })}>
      <Grid item>
        <Paper className={classes.paper}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            className={classes.content}>
            <Grid item xs={12}>
              {image}
            </Grid>
            <Grid item xs={12}>
              {content}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

StyledUserNoticeTemplate.propTypes = {
  image: PropTypes.any.isRequired,
  content: PropTypes.any.isRequired
};

export default StyledUserNoticeTemplate;
