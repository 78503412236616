import React, { forwardRef } from 'react';
import { Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
  button: {
    minWidth: theme.spacing(10),
    width: 'auto',
    fontSize: '0.75rem',
    fontWeight: 'bold',
    borderRadius: 16
  }
}));

const StyledButtonPrimary = forwardRef(
  (
    {
      label,
      color = 'primary',
      variant = 'contained',
      onClick,
      className,
      icon,
      ...rest
    },
    ref
  ) => {
    const classes = useStyles();
    if (icon) {
      return (
        <Button
          data-testid="button-primary"
          color={color}
          variant={variant}
          onClick={onClick}
          startIcon={icon}
          className={classNames(classes.button, className)}
          ref={ref}
          {...rest}>
          {label}
        </Button>
      );
    } else {
      return (
        <Button
          data-testid="button-primary"
          color={color}
          variant={variant}
          onClick={onClick}
          className={classNames(classes.button, className)}
          ref={ref}
          {...rest}>
          {label}
        </Button>
      );
    }
  }
);

StyledButtonPrimary.propTypes = {
  label: PropTypes.string.isRequired,
  color: PropTypes.string,
  variant: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  icon: PropTypes.node
};

export default StyledButtonPrimary;
