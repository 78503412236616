import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useField } from 'react-final-form-hooks';
import { Grid, Typography } from '@mui/material';

import useFormValidation from 'hooks/useFormValidation';
import StyledAutoComplete from 'shared/AutoComplete';
import StyledInput from 'shared/Input';
import StyledCheckboxList from 'shared/CheckList';

const TrainingSelect = ({
  selectedCompetentPersonOptions,
  setSelectedCompetentPersonOptions,
  form
}) => {
  const { t } = useTranslation();
  const { isRequired } = useFormValidation();
  // eslint-disable-next-line no-unused-vars
  const [inputValue, handleInputChange] = useState(null);
  const { values } = form.getState();

  const isCustomTraining =
    values?.selectedTrainingName?.value === 'Other' ||
    values?.selectedTrainingName?.value ===
      t('personnelTrainingDialog.trainingNamesAndCompetencies.other');

  const isCompetentPersonTraining =
    values?.selectedTrainingName?.value ===
    t('personnelTrainingDialog.trainingNamesAndCompetencies.competentPerson');

  const selectedTrainingNameField = useField(
    'selectedTrainingName',
    form,
    isRequired
  );

  const OtherTrainingNameField = useField(
    'customTrainingName',
    form,
    isRequired
  );

  const trainingNameOptions = [
    t('personnelTrainingDialog.trainingNamesAndCompetencies.boomLift'),
    t('personnelTrainingDialog.trainingNamesAndCompetencies.competentPerson'),
    t('personnelTrainingDialog.trainingNamesAndCompetencies.confinedSpaces'),
    t('personnelTrainingDialog.trainingNamesAndCompetencies.cranes'),
    t('personnelTrainingDialog.trainingNamesAndCompetencies.excavations'),
    t('personnelTrainingDialog.trainingNamesAndCompetencies.fallProtection'),
    t('personnelTrainingDialog.trainingNamesAndCompetencies.forkLift'),
    t('personnelTrainingDialog.trainingNamesAndCompetencies.osha10'),
    t('personnelTrainingDialog.trainingNamesAndCompetencies.osha30'),
    t('personnelTrainingDialog.trainingNamesAndCompetencies.rigging'),
    t('personnelTrainingDialog.trainingNamesAndCompetencies.scaffolds'),
    t('personnelTrainingDialog.trainingNamesAndCompetencies.scissorLift'),
    t('personnelTrainingDialog.trainingNamesAndCompetencies.signal'),
    t('personnelTrainingDialog.trainingNamesAndCompetencies.silica'),
    t('personnelTrainingDialog.trainingNamesAndCompetencies.other')
  ];

  const competentPersonOptions = [
    t('personnelTrainingDialog.trainingNamesAndCompetencies.blasting'),
    t('personnelTrainingDialog.trainingNamesAndCompetencies.concrete'),
    t('personnelTrainingDialog.trainingNamesAndCompetencies.confinedSpaces'),
    t('personnelTrainingDialog.trainingNamesAndCompetencies.miscCranes'),
    t('personnelTrainingDialog.trainingNamesAndCompetencies.demolition'),
    t('personnelTrainingDialog.trainingNamesAndCompetencies.electrical'),
    t(
      'personnelTrainingDialog.trainingNamesAndCompetencies.environmentalControls'
    ),
    t('personnelTrainingDialog.trainingNamesAndCompetencies.excavations'),
    t('personnelTrainingDialog.trainingNamesAndCompetencies.fallProtection'),
    t('personnelTrainingDialog.trainingNamesAndCompetencies.hearingProtection'),
    t('personnelTrainingDialog.trainingNamesAndCompetencies.ladders'),
    t('personnelTrainingDialog.trainingNamesAndCompetencies.rigging'),
    t('personnelTrainingDialog.trainingNamesAndCompetencies.scaffolds'),
    t('personnelTrainingDialog.trainingNamesAndCompetencies.steel'),
    t('personnelTrainingDialog.trainingNamesAndCompetencies.toxicSubstances'),
    t('personnelTrainingDialog.trainingNamesAndCompetencies.underground'),
    t('personnelTrainingDialog.trainingNamesAndCompetencies.welding')
  ];

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <StyledAutoComplete
          selectedValue={selectedTrainingNameField.input.value}
          handleChange={selectedTrainingNameField.input.onChange}
          placeholder={t(
            'personnelTrainingDialog.trainingForm.searchTrainingNames'
          )}
          options={trainingNameOptions.map(item => {
            if (item === 'Other: Create a custom training name') {
              return {
                label: item,
                value: 'Other'
              };
            } else {
              return {
                label: item,
                value: item
              };
            }
          })}
          handleInputChange={handleInputChange}
          label={t('personnelTrainingDialog.trainingForm.name')}
          isRequired={true}
          meta={selectedTrainingNameField.meta}
        />
      </Grid>
      {isCustomTraining && (
        <Grid item>
          <StyledInput
            input={{
              ...OtherTrainingNameField.input,
              ...{ 'data-testid': 'custom-training-name-input' }
            }}
            meta={OtherTrainingNameField.meta}
            label={t('personnelTrainingDialog.trainingForm.addNewTrainingName')}
            onChange={OtherTrainingNameField.input.onChange}
            value={OtherTrainingNameField.input.value || ''}
            required
          />
        </Grid>
      )}
      {isCompetentPersonTraining && (
        <Grid item>
          <Typography>
            {t('personnelTrainingDialog.trainingForm.selectCompetencies')}
          </Typography>
          <StyledCheckboxList
            listItems={competentPersonOptions}
            selectedListItems={selectedCompetentPersonOptions}
            setSelectedListItems={setSelectedCompetentPersonOptions}
          />
        </Grid>
      )}
    </Grid>
  );
};

TrainingSelect.propTypes = {
  selectedCompetentPersonOptions: PropTypes.array,
  setSelectedCompetentPersonOptions: PropTypes.func,
  form: PropTypes.any,
  existingTrainingData: PropTypes.any
};

export default TrainingSelect;
