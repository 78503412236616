import gql from 'graphql-tag';

// Queries

export const GET_PAGINATED_AHA_ACTIVITIES = gql`
  query GetPaginatedAhaActivities($first: Int!, $search: String, $skip: Int) {
    paginatedGlobalAhaActivitys(first: $first, search: $search, skip: $skip) {
      total
      ahaActivitys {
        id
        name
        isActive
        created
        lastModified
        order
        ahaTemplate {
          id
          type
        }
      }
    }
  }
`;

export const GET_AHA_ACTIVITY = gql`
  query GetAhaActivity($id: ID!) {
    ahaActivity(id: $id) {
      id
      name
      isActive
      created
      lastModified
      order
      ahaTemplate {
        id
        type
      }
    }
  }
`;

// Mutations

export const CREATE_GLOBAL_AHA_ACTIVITY = gql`
  mutation CreateGlobalAhaActivity($input: AhaGlobalActivityCreateInput!) {
    createGlobalAhaActivity(input: $input) {
      id
      name
      isActive
      created
      lastModified
      order
      ahaTemplate {
        id
        type
      }
    }
  }
`;

export const UPDATE_GLOBAL_AHA_ACTIVITY = gql`
  mutation UpdateGlobalAhaActivity($input: AhaActivityUpdateInput!) {
    updateGlobalAhaActivity(input: $input) {
      id
      name
      isActive
      created
      lastModified
      order
      ahaTemplate {
        id
        type
      }
    }
  }
`;

export const DELETE_GLOBAL_AHA_ACTIVITY = gql`
  mutation DeleteGlobalAhaActivity($id: ID!) {
    deleteGlobalAhaActivity(id: $id) {
      id
    }
  }
`;
