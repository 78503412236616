import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useField } from 'react-final-form-hooks';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography } from '@mui/material';

import useFormValidation from 'hooks/useFormValidation';
import StyledInput from 'shared/Input';
import StyledSelect from 'shared/Select';
import StyledTimezoneSelect from 'shared/TimezoneSelect';
import StyledKeyboardDatePickerField from 'shared/KeyboardDatePickerField';
import StyledKeyboardTimePickerField from 'shared/KeyboardTimePickerField';
import StyledNotice from 'shared/Notice';
import FacilitatorSelect from 'components/orientations/FacilitatorSelect';
import { LANGUAGES_OPTIONS } from 'constants/languages';
import {
  ALLOWED_ORIENTATION_TYPES,
  ORIENTATION_TYPE_OPTIONS,
  ORIENTATION_SERIES_END_DATE_OPTIONS
} from 'constants/orientationRecurrence';

const useStyles = makeStyles(theme => ({
  iconContainer: { marginTop: theme.spacing(2.5), width: 30 },
  notice: {
    borderLeft: '6px solid' + theme.palette.secondary.dark,
    backgroundColor: theme.palette.secondary.main,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  noticeContent: { fontSize: '0.75rem' },
  dateTimeContainer: {
    width: 200,
    [theme.breakpoints.down('lg')]: { width: '50%' },
    [theme.breakpoints.down('md')]: { width: '100%' }
  }
}));

const OrientationFormFields = ({
  form,
  isLoading,
  orientation,
  canEditDateRange = false,
  isValidDateTimes,
  isEditingSeries
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isRequired, isValidDate, isValidTime } = useFormValidation();

  const datesAreEditable = !isEditingSeries || canEditDateRange;

  const timeZone = useField('timeZone', form, isRequired);
  const startDate = useField(
    'startDate',
    form,
    datesAreEditable ? isValidDate : undefined
  );
  const startTime = useField('startTime', form, isValidTime);
  const endDate = useField(
    'endDate',
    form,
    datesAreEditable ? isValidDate : undefined
  );

  const { values, dirtyFields } = form.getState();

  useEffect(() => {
    if (dirtyFields?.startDate && !dirtyFields?.endDate) {
      // eslint-disable-next-line react/prop-types
      form.change('endDate', values.startDate);
    }
  }, [dirtyFields, form, values.startDate]);

  const endTime = useField('endTime', form, isValidTime);
  const maxAttendees = useField('maxAttendees', form, isRequired);
  const facilitators = useField('facilitators', form, isRequired);
  const language = useField('language', form);
  const notes = useField('notes', form);

  // Series fields
  const type = useField('type', form);
  const isCreatingSeries = ALLOWED_ORIENTATION_TYPES.includes(
    type?.input?.value
  );

  const recurringEndDate = useField(
    'recurringEndDate',
    form,
    isCreatingSeries ? isRequired : null
  );

  return (
    <Grid container direction="column" spacing={2} alignContent="center">
      {isEditingSeries && (
        <Grid item xs={12}>
          <StyledNotice
            message={t('orientationFormFields.seriesUpdateNotice')}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <StyledTimezoneSelect
          label={t('orientationFormFields.field.timeZone.name')}
          placeholder={t('orientationFormFields.field.timeZone.placeholder')}
          selectedValue={timeZone.input.value}
          meta={timeZone.meta}
          handleChange={timeZone.input.onChange}
          handleInputChange={() => {}}
          isRequired={true}
          isDisabled={isLoading || isEditingSeries}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container direction="row" spacing={1} alignItems="center">
          <Grid item className={classes.dateTimeContainer}>
            <StyledKeyboardDatePickerField
              label={t('orientationFormFields.field.startDate.name')}
              value={startDate.input.value || null}
              handleDateChange={startDate.input.onChange}
              meta={startDate.meta}
              disabled={isLoading || !datesAreEditable || isEditingSeries}
              inputProps={{
                onFocus: startDate.input.onFocus,
                onBlur: startDate.input.onBlur
              }}
              shouldDisplayAsError={!isValidDateTimes}
              disableFuture={false}
            />
          </Grid>
          <Grid item className={classes.dateTimeContainer}>
            <StyledKeyboardTimePickerField
              label={t('orientationFormFields.field.startTime.name')}
              value={startTime.input.value || null}
              meta={startTime.meta}
              handleDateChange={startTime.input.onChange}
              disabled={isLoading || isEditingSeries}
              inputProps={{
                onFocus: startTime.input.onFocus,
                onBlur: startTime.input.onBlur
              }}
              shouldDisplayAsError={!isValidDateTimes}
            />
          </Grid>
          <Grid
            item
            className={classes.iconContainer}
            sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
            <ArrowRightAltIcon />
          </Grid>
          <Grid item className={classes.dateTimeContainer}>
            <StyledKeyboardDatePickerField
              label={t('orientationFormFields.field.endDate.name')}
              value={endDate.input.value || null}
              handleDateChange={endDate.input.onChange}
              meta={endDate.meta}
              disabled={isLoading || !datesAreEditable || isEditingSeries}
              inputProps={{
                onFocus: endDate.input.onFocus,
                onBlur: endDate.input.onBlur
              }}
              shouldDisplayAsError={!isValidDateTimes}
              disableFuture={false}
            />
          </Grid>
          <Grid item className={classes.dateTimeContainer}>
            <StyledKeyboardTimePickerField
              label={t('orientationFormFields.field.endTime.name')}
              value={endTime.input.value || null}
              meta={endTime.meta}
              handleDateChange={endTime.input.onChange}
              disabled={isLoading || isEditingSeries}
              inputProps={{
                onFocus: endTime.input.onFocus,
                onBlur: endTime.input.onBlur
              }}
              shouldDisplayAsError={!isValidDateTimes}
            />
          </Grid>
          {!isValidDateTimes && (
            <Grid item xs={12}>
              <Typography color="error" style={{ fontSize: '0.75rem' }}>
                {t('orientationFormFields.field.endTimeErrorMessage.name')}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      {!orientation && (
        <Grid item xs={12}>
          <StyledSelect
            inputProps={{ 'data-testid': 'recurring-select' }}
            placeholder={t(
              'orientationFormFields.field.recurring.selectPlaceholder'
            )}
            margin="dense"
            label={t('orientationFormFields.field.recurring.name')}
            input={type.input}
            meta={type.meta}
            disabled={isLoading}
            options={ORIENTATION_TYPE_OPTIONS}
          />
        </Grid>
      )}
      {type.input.value && type.input.value !== 'Does not repeat' && (
        <Grid item xs={12}>
          <StyledSelect
            inputProps={{ 'data-testid': 'recurring-end-date-select' }}
            placeholder={t(
              'orientationFormFields.field.recurringUntil.selectPlaceholder'
            )}
            margin="dense"
            label={t('orientationFormFields.field.recurringUntil.name')}
            input={recurringEndDate.input}
            meta={recurringEndDate.meta}
            disabled={isLoading}
            options={ORIENTATION_SERIES_END_DATE_OPTIONS}
            required={true}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <StyledInput
          type="number"
          input={maxAttendees.input}
          meta={maxAttendees.meta}
          disabled={isLoading}
          label={t('orientationFormFields.field.maxAttendees.name')}
          required={true}
        />
      </Grid>
      <Grid item xs={12}>
        <FacilitatorSelect
          label={t('facilitatorSelect.label')}
          selectedFacilitators={facilitators}
          handleChange={facilitators.input.onChange}
          isLoading={isLoading}
        />
      </Grid>
      <Grid item xs={12}>
        <StyledSelect
          inputProps={{ 'data-testid': 'language-select' }}
          placeholder={t(
            'orientationFormFields.field.language.selectPlaceholder'
          )}
          margin="dense"
          label={t('orientationFormFields.field.language.name')}
          input={language.input}
          meta={language.meta}
          disabled={isLoading}
          options={LANGUAGES_OPTIONS}
        />
      </Grid>
      <Grid item>
        <StyledInput
          input={notes.input}
          meta={notes.meta}
          disabled={isLoading}
          label={t('orientationFormFields.field.notes.name')}
          multiline={true}
        />
      </Grid>
    </Grid>
  );
};

OrientationFormFields.defaultProps = {
  isEditingSeries: false
};

OrientationFormFields.propTypes = {
  form: PropTypes.shape({
    getState: PropTypes.func
  }).isRequired,
  isLoading: PropTypes.bool,
  orientation: PropTypes.object,
  canEditDateRange: PropTypes.bool,
  isValidDateTimes: PropTypes.bool,
  isEditingSeries: PropTypes.bool
};

export default OrientationFormFields;
