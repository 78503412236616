import { useState, useEffect, useContext } from 'react';
import AvatarDetailsContext from 'context/avatars';
import useGraphApi from 'hooks/useGraphApi';
import { domain, Domain } from 'config/domain';

const useFetchUserPhoto = (
  upnOrId,
  azureClientId = process.env.REACT_APP_AZURE_CLIENT_ID
) => {
  const [thumbnailUrl, updateUrl] = useState(null);
  const { avatars, cacheAvatarUrl } = useContext(AvatarDetailsContext);
  const cachedAvatar = avatars[upnOrId];
  const shouldCallApi =
    !!upnOrId &&
    !cachedAvatar &&
    domain !== Domain.PARTNER &&
    azureClientId === process.env.REACT_APP_AZURE_CLIENT_ID;
  // If no photo can be fetched due to lack of information, treat it as loading.
  // This makes upstream behavior smoother when upn is lazy loaded.
  const [blobIsLoading, setBlobIsLoading] = useState(shouldCallApi);
  const { isLoading: graphApiIsLoading, response } = useGraphApi({
    path: `users/${upnOrId}/photo/$value`,
    skip: !shouldCallApi,
    azureClientId
  });

  useEffect(() => {
    if (shouldCallApi && !response) {
      setBlobIsLoading(true);
    }
  }, [shouldCallApi, response, graphApiIsLoading, setBlobIsLoading]);

  useEffect(() => {
    if (!graphApiIsLoading && response) {
      response
        .blob()
        .then(blob => {
          const url = URL.createObjectURL(blob);
          updateUrl(url);
          cacheAvatarUrl({ [upnOrId]: url });
        })
        .then(_ => {
          setBlobIsLoading(false);
        })
        .catch(error => {
          // We don't care
        });
    }
  }, [cacheAvatarUrl, cachedAvatar, upnOrId, graphApiIsLoading, response]);

  return {
    thumbnailUrl: cachedAvatar || thumbnailUrl,
    loading: !cachedAvatar && (graphApiIsLoading || blobIsLoading)
  };
};

export default useFetchUserPhoto;
