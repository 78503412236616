import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import LoadingOverlay from 'react-loading-overlay';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography, CircularProgress } from '@mui/material';

const useStyles = makeStyles(theme => ({
  infiniteScroll: { overflowX: 'hidden' },
  padding: { padding: theme.spacing(2) },
  endMessage: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(4),
    color: theme.palette.secondary.contrastText
  },
  scrollContainer: props => ({
    maxHeight: props.height ?? '100%',
    width: '100%',
    overflowX: 'auto'
    // TODO: HANDLE BREAKPOINTS
    //[theme.breakpoints.down('xs')]: { maxHeight: 'calc(100vh - 360px)' }
  }),
  lightText: { color: '#fff' },
  darkText: { color: '#000' }
}));

const StyledInfiniteScrollWithOverlay = ({
  children,
  height,
  isLoading,
  dataLength,
  next,
  hasMore,
  containerName,
  endMessageTextColor
}) => {
  const classes = useStyles({ height });
  const theme = useTheme();
  const { t } = useTranslation();

  const containerId = containerName
    ? `${containerName}-scroll-container`
    : 'scroll-container';

  return (
    <LoadingOverlay
      active={isLoading}
      spinner
      fadeSpeed={50}
      styles={{
        overlay: base => ({
          ...base,
          background: theme.palette.background.overlay
        }),
        spinner: base => ({
          ...base,
          width: '50px',
          '& svg circle': {
            stroke: theme.palette.primary.main
          }
        })
      }}>
      <div id={containerId} className={classes.scrollContainer}>
        <InfiniteScroll
          scrollThreshold={0.9}
          className={classes.infiniteScroll}
          scrollableTarget={containerId}
          dataLength={dataLength}
          next={next}
          hasMore={hasMore}
          loader={
            <Grid container justifyContent="center" className={classes.padding}>
              <Grid item>
                <CircularProgress color="primary" />
              </Grid>
            </Grid>
          }
          endMessage={
            <Grid container justifyContent="center" className={classes.padding}>
              <Grid item>
                <Typography
                  variant="overline"
                  className={classnames(classes.endMessage, {
                    [`${classes.lightText}`]: endMessageTextColor === 'light',
                    [`${classes.darkText}`]: endMessageTextColor === 'dark'
                  })}>
                  {t('infiniteScroll.endOfListMessage')}
                </Typography>
              </Grid>
            </Grid>
          }>
          {children}
        </InfiniteScroll>
      </div>
    </LoadingOverlay>
  );
};

StyledInfiniteScrollWithOverlay.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  dataLength: PropTypes.number,
  next: PropTypes.any,
  hasMore: PropTypes.bool,
  containerName: PropTypes.string,
  height: PropTypes.string,
  endMessageTextColor: PropTypes.string
};

export default StyledInfiniteScrollWithOverlay;
