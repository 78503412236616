import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import { Grid, Typography } from '@mui/material';

import useToast from 'hooks/useToast';
import StyledDialog from 'shared/Dialog';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import StyledButtonSecondary from 'shared/Buttons/ButtonSecondary';
import StyledNotice from 'shared/Notice';
import { UPDATE_ATTENDEE } from 'graphql/attendee';

const DenyConfirmationDialog = ({
  denyConfirmationDialogIsOpen,
  toggleDenyConfirmationDialog,
  selectedAttendee
}) => {
  const { t } = useTranslation();
  const { displayToast } = useToast();

  const [updateAttendee, { loading: isLoading }] = useMutation(UPDATE_ATTENDEE);

  const attendeeToDeny = {
    input: {
      id: selectedAttendee?.id,
      personnelId: selectedAttendee?.personnel?.id,
      status: 'Denied'
    }
  };

  const handleSubmit = () => {
    updateAttendee({
      variables: attendeeToDeny
    })
      .then(({ data: { updateAttendee: attendee } }) => {
        displayToast(t('denyConfirmationDialog.toasts.success'), 'success');
        toggleDenyConfirmationDialog(false);
      })
      .catch(error => {
        console.error('Deny Attendee Error: ', error);
        displayToast(t('denyConfirmationDialog.toasts.error'), 'error');
      });
  };

  return (
    <StyledDialog
      isLoading={isLoading}
      maxWidth="sm"
      isOpen={denyConfirmationDialogIsOpen}
      handleClose={() => toggleDenyConfirmationDialog(false)}
      title={t('denyConfirmationDialog.title')}
      content={
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <StyledNotice
              type="Notice"
              message={t('denyConfirmationDialog.noticeMessage', {
                attendeeName: selectedAttendee?.fullName
              })}
            />
          </Grid>
          <Grid item>
            <Typography>
              {t('denyConfirmationDialog.confirmation.continue')}
            </Typography>
          </Grid>
        </Grid>
      }
      actions={
        <Fragment>
          <StyledButtonSecondary
            label={t(
              'denyConfirmationDialog.confirmation.actions.cancelButton'
            )}
            disabled={isLoading}
            onClick={() => toggleDenyConfirmationDialog(false)}
          />
          <StyledButtonPrimary
            label={t('denyConfirmationDialog.confirmation.actions.denyButton')}
            disabled={isLoading}
            onClick={handleSubmit}
          />
        </Fragment>
      }
    />
  );
};

DenyConfirmationDialog.propTypes = {
  selectedAttendee: PropTypes.object,
  denyConfirmationDialogIsOpen: PropTypes.bool,
  toggleDenyConfirmationDialog: PropTypes.func
};

export default DenyConfirmationDialog;
