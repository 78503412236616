import { useMsal } from '@azure/msal-react';
import {
  InteractionStatus,
  InteractionRequiredAuthError
} from '@azure/msal-browser';
import { useState, useEffect } from 'react';
import { MSAL_SCOPES, redirectUri } from 'config/msalConfig';
import { domain, Domain } from 'config/domain';

const useGraphApi = ({
  path,
  skip = false,
  azureClientId = process.env.REACT_APP_AZURE_CLIENT_ID
}) => {
  const { instance: msalInstance, inProgress: oauthProgress } = useMsal();
  // Graph API is not supported for B2C users, and would require a guest user to use cross-tenant
  const shouldCallApi =
    !skip &&
    domain !== Domain.PARTNER &&
    azureClientId === process.env.REACT_APP_AZURE_CLIENT_ID;
  const [isLoading, setIsLoading] = useState(shouldCallApi);
  const [response, setResponse] = useState(undefined);
  const [accessToken, setAccessToken] = useState(undefined);
  const [error, setError] = useState(undefined);

  useEffect(() => {
    if (shouldCallApi) {
      const account = msalInstance.getActiveAccount();
      if (!account) {
        throw Error(
          'No active account! Verify a user has been signed in and setActiveAccount has been called.'
        );
      }

      if (!accessToken) {
        console.log('Silently acquiring token for graph API call.');
        msalInstance
          .acquireTokenSilent({
            scopes: MSAL_SCOPES,
            account: account,
            redirectUri
          })
          .then(response => setAccessToken(response.accessToken));
      }

      if (accessToken && !(response || error)) {
        const bearer = `Bearer ${accessToken}`;

        const headers = new Headers();
        headers.append('Authorization', bearer);

        const options = {
          method: 'GET',
          headers: headers
        };

        fetch(`https://graph.microsoft.com/v1.0/${path}`, options)
          .then(response => {
            setResponse(response);
            setIsLoading(false);
          })
          .catch(error => {
            setError(error);
            setIsLoading(false);
            if (error instanceof InteractionRequiredAuthError) {
              console.log(
                `Graph API returned an InteractionRequiredAuthError: ${JSON.stringify(
                  error
                )}`
              );
              if (oauthProgress === InteractionStatus.None) {
                msalInstance.acquireTokenRedirect({
                  scopes: MSAL_SCOPES,
                  account: account,
                  redirectUri
                });
              } else {
                console.log('Login interaction already in progress');
              }
            }
          });
      }
    }
  }, [
    shouldCallApi,
    accessToken,
    setAccessToken,
    response,
    setResponse,
    error,
    setError,
    setIsLoading
  ]);

  return { isLoading, response, error };
};

export default useGraphApi;
