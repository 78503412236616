import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography } from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';

import useToast from 'hooks/useToast';
import StyledDialog from 'shared/Dialog';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import StyledButtonSecondary from 'shared/Buttons/ButtonSecondary';
import { RELINK_TRADE_PARTNER_PERSONNELS } from 'graphql/tradePartnerPersonnel';
import AdUserSelect from 'components/ad/AdUserSelect';

const useStyles = makeStyles(theme => ({
  optionsContainer: {
    borderRadius: 4,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2)
  },
  instructions: { marginTop: theme.spacing(1) },
  radioGroupContainer: { marginBottom: theme.spacing(2) },
  radioLabel: { fontSize: '0.875rem' },
  mergeFromNotSpecified: { fontStyle: 'italic' },
  accordionContainer: { marginTop: theme.spacing(2) },
  error: { color: theme.palette.error.main, fontSize: '0.75rem' }
}));

const TradePartnerPersonnelRelinkDialog = ({
  dialogIsOpen,
  setDialogIsOpen,
  tradePartnerPersonnel
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { displayToast } = useToast();
  const history = useHistory();
  const { projectId } = useParams();
  const [selectedAdUser, setSelectedAdUser] = useState(null);

  const [relinkPersonnel, { loading: isLoadingForRelink }] = useMutation(
    RELINK_TRADE_PARTNER_PERSONNELS
  );

  const handleClose = () => {
    setDialogIsOpen(false);
    setSelectedAdUser(null);
  };

  const canSubmit = () => {
    return selectedAdUser && !isLoadingForRelink;
  };

  const handleSubmit = () => {
    relinkPersonnel({
      variables: {
        toMicrosoftAdId: selectedAdUser.microsoftAdId,
        tradePartnerPersonnelIds: [tradePartnerPersonnel.id]
      }
    }).then(
      () => {
        handleClose();
        displayToast(t('personnelMergeDialog.toasts.success'), 'success');
        history.push(`/projects/${projectId}/personnel`);
      },
      error => {
        console.error('Personnel Merging Error: ', error);
        displayToast(t('personnelMergeDialog.toasts.error'), 'error');
      }
    );
  };

  return (
    <StyledDialog
      isOpen={dialogIsOpen}
      handleClose={handleClose}
      isLoading={isLoadingForRelink}
      title={t('tradePartnerPersonnelRelinkDialog.title')}
      content={
        <Grid container justifyContent="center" spacing={3} alignContent="center">
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <AdUserSelect
              selectedUser={selectedAdUser}
              handleChange={setSelectedAdUser}
              placeholder={
                <Typography className={classes.placeholderText}>
                  {t('tradePartnerPersonnelRelinkDialog.userSelectPlaceholder')}
                </Typography>
              }
              optionFilter={option =>
                option.microsoftAdId !==
                tradePartnerPersonnel.personnel.soteriaAdUser.microsoftAdId
              }
            />
          </Grid>
        </Grid>
      }
      actions={
        <Grid container justifyContent="space-between">
          <Grid item>
            <StyledButtonSecondary
              label={t(
                'tradePartnerPersonnelRelinkDialog.actions.cancelButton'
              )}
              disabled={isLoadingForRelink}
              onClick={handleClose}
            />
          </Grid>
          <Grid item>
            <StyledButtonPrimary
              label={t(
                'tradePartnerPersonnelRelinkDialog.actions.submitButton'
              )}
              disabled={!canSubmit() || isLoadingForRelink}
              onClick={handleSubmit}
            />
          </Grid>
        </Grid>
      }
    />
  );
};

TradePartnerPersonnelRelinkDialog.propTypes = {
  dialogIsOpen: PropTypes.bool.isRequired,
  setDialogIsOpen: PropTypes.func.isRequired,
  tradePartnerPersonnel: PropTypes.object.isRequired
};

export default TradePartnerPersonnelRelinkDialog;
