import {
  Button,
  Container,
  Fade,
  Grid,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import bg from 'assets/images/bg.jpg';
import classNames from 'classnames';
import ErrorNotice from 'components/common/ErrorNotice';
import LoadingSpinner from 'components/common/LoadingSpinner';
import PageNotFoundNotice from 'components/common/PageNotFoundNotice';
import ProjectDashboardTile from 'components/common/ProjectDashboardTile';
import OfflineNoAccessNotice from 'components/offline/OfflineNoAccessNotice';
import withAuthorization from 'hocs/withAuthorization';
import useObservationCounts from 'hooks/useObservationCounts';
import useSafeProject from 'hooks/useSafeProject';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import StyledOfflineBanner from 'shared/OfflineBanner';
import useIsManualOfflineModeOn from 'store/manualOfflineMode';
import useIsOnline from 'store/onlineDetection';

const useStyles = makeStyles(theme => ({
  background: {
    backgroundImage: `url(${bg})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    height: 320,
    width: '100%',
    paddingTop: 0,
    position: 'fixed',
    zIndex: 1,
    [theme.breakpoints.down('lg')]: { height: 260 }
  },
  backgroundOffline: { height: 188 },
  backgroundOverlay: {
    height: 320,
    paddingTop: 0,
    background: `linear-gradient(to bottom,${theme.backgroundLinearGradientTop},${theme.backgroundLinearGradientBottom})`,
    [theme.breakpoints.down('lg')]: { height: 260 }
  },
  backgroundOverlayOffline: { height: 188 },
  button: {
    backgroundColor: theme.palette.invariant.lightGray,
    padding: 0,
    borderRadius: 16,
    marginTop: '1rem',
    marginLeft: theme.spacing(1.5),
    [theme.breakpoints.down('lg')]: { marginTop: 0.5 }
  },
  buttonText: {
    fontSize: '0.75rem',
    fontWeight: 'bold',
    padding: '5px 15px',
    color: theme.palette.invariant.black,
    '&:hover': { color: theme.palette.primary.contrastText }
  },
  projectTitleText: {
    color: '#fff',
    fontSize: '2.5rem',
    marginTop: 112,
    paddingLeft: theme.spacing(1.5),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.up('xl')]: { maxWidth: `calc(1280px - 48px)` },
    [theme.breakpoints.down('xl')]: {
      maxWidth: `calc(1280px - 48px)`,
      fontSize: '2rem'
    },
    [theme.breakpoints.down('xl')]: { maxWidth: `calc(960px - 48px)` },
    [theme.breakpoints.down('lg')]: {
      maxWidth: `calc(600px - 48px)`,
      fontSize: '1.5rem'
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: `calc(300px - 48px)`,
      marginTop: theme.spacing(6)
    }
  },
  projectTitleTextOffline: { marginTop: '1rem' },
  projectInfoText: {
    display: 'inline',
    color: '#fff',
    fontSize: '1.5rem',
    [theme.breakpoints.down('lg')]: { fontSize: '1rem' }
  },
  tileContainer: {
    paddingTop: 340,
    minHeight: 'calc(100vh - 300px)',
    height: 'auto',
    [theme.breakpoints.down('md')]: {
      minHeight: 'calc(100vh - 250px)'
    },
    [theme.breakpoints.down('lg')]: {
      paddingTop: 300
    },
    [theme.breakpoints.down('xl')]: {
      maxWidth: '1600px',
      margin: '0 auto'
    }
  },
  addressTextContainer: { margin: theme.spacing(0, 0, 1, 1.5) },
  headerContainer: {
    [theme.breakpoints.down('md')]: {
      marginTop: 64
    }
  }
}));

export const ProjectPartnerDashboardPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const history = useHistory();
  const { projectId } = useParams();
  const { isOnline } = useIsOnline();
  const { myOpenObservationsCount } = useObservationCounts(projectId, false);

  const { isOfflineModeEnabled } = useIsManualOfflineModeOn();

  const {
    safeProject: project,
    error: projectError,
    loading: projectIsLoading
  } = useSafeProject(projectId);

  if (projectIsLoading) {
    return <LoadingSpinner />;
  }

  if (!isOnline && !project) {
    return (
      <OfflineNoAccessNotice
        message={t('withOnlineAccessOnly.projectUnavailable.message')}
      />
    );
  }

  if (isOnline && projectError) {
    if (projectError?.message?.includes('not found')) {
      return <PageNotFoundNotice />;
    } else {
      return <ErrorNotice />;
    }
  }

  const numSafeObservationNotifications = myOpenObservationsCount;

  const tiles = [];
  tiles.push(
    <ProjectDashboardTile
      onClick={() => history.push(`/projects/${project?.id}/observations`)}
      tileImg={theme.safeTile}
      tileName={t('projectDashboardPage.tiles.safe.title')}
      tileDescription={t('projectDashboardPage.tiles.safe.description')}
      badgeContent={numSafeObservationNotifications}
      isDisabled={!isOnline && !isOfflineModeEnabled}
    />
  );

  const dashboardGridSizing = {
    xs: 4,
    sm: 4,
    md: 3,
    lg: tiles.length <= 12 ? 3 : 2
  };

  return (
    <Fragment>
      {!isOnline && (
        <StyledOfflineBanner
          content={t('projectDashboardPage.offlineMessage')}
        />
      )}
      <Fade in={true} timeout={500}>
        <div
          className={classNames(classes.background, {
            [`${classes.backgroundOffline}`]: !isOnline
          })}>
          <div
            className={classNames(classes.backgroundOverlay, {
              [`${classes.backgroundOverlayOffline}`]: !isOnline
            })}>
            <Grid container direction="column">
              <Container
                sx={{
                  overflow: 'hidden',
                  '&.MuiContainer-maxWidthXl': { maxWidth: 1920 }
                }}
                maxWidth="xl"
                className={classes.headerContainer}>
                <Grid item>
                  <Fade in={true} timeout={500}>
                    <Grid container>
                      <Grid item xs={12}>
                        {project && (
                          <Tooltip title={project.name}>
                            <Typography
                              component="h1"
                              className={classNames(classes.projectTitleText, {
                                [`${classes.projectTitleTextOffline}`]: !isOnline
                              })}>
                              {project.name}
                            </Typography>
                          </Tooltip>
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        className={classes.addressTextContainer}>
                        <Grid container direction="row" spacing={1}>
                          <Grid item>
                            <Typography
                              noWrap={true}
                              className={classes.projectInfoText}>
                              {project?.displayAddress}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              noWrap={true}
                              className={classes.projectInfoText}>
                              | #{project?.number} | Region{' '}
                              {project?.districtNumber}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Fade>
                </Grid>
              </Container>
            </Grid>
          </div>
        </div>
      </Fade>
      <Grid item className={classes.tileContainer}>
        <Container maxWidth={tiles.length <= 12 ? 'lg' : 'xl'}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}>
            {tiles.map((tile, index) => (
              <Grid key={`tileGridItem${index}`} item {...dashboardGridSizing}>
                {tile}
              </Grid>
            ))}
          </Grid>
        </Container>
      </Grid>
    </Fragment>
  );
};

export default withAuthorization(ProjectPartnerDashboardPage, {
  partnerOnProject: true
});
