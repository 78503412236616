import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import FlagIcon from '@mui/icons-material/Flag';
import OutlinedFlagIcon from '@mui/icons-material/OutlinedFlag';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import makeStyles from '@mui/styles/makeStyles';
import {
  Grid,
  Typography,
  FormGroup,
  Checkbox,
  FormHelperText
} from '@mui/material';

const useStyles = makeStyles(theme => ({
  categoryName: {
    fontSize: '0.875rem',
    [theme.breakpoints.up('md')]: { marginLeft: theme.spacing(3) }
  },
  categoryNameAllSafe: { fontSize: '0.875rem', display: 'inline' },
  subcategoryName: {
    fontSize: '0.875rem',
    maxWidth: 220,
    whiteSpace: 'noWrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    paddingLeft: theme.spacing(1)
  },
  isSufficientChecked: { color: theme.palette.success.main },
  checkbox: { padding: 0 },
  isAllSafeIcon: { color: theme.palette.success.main },
  error: { color: theme.palette.error.main }
}));

const ObservationSubcategories = ({
  isAllSafe,
  selectedCategories,
  selectedSubCategories,
  setSelectedSubCategories,
  shouldShowFlag = true
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleChange = (event, subCategory, type) => {
    const existingSelectedSubcategory = selectedSubCategories.find(
      selectedSubCategory => selectedSubCategory.id === subCategory.id
    );

    const modifiedSubCategory = {
      isSafe: false,
      wasDeficient: false,
      ...subCategory,
      ...existingSelectedSubcategory,
      [type]: event.target.checked,
      ...(type === 'isSafe' && event.target.checked
        ? { wasDeficient: true }
        : {})
    };

    const shouldRemoveSubCategory =
      !event.target.checked &&
      modifiedSubCategory.isSafe === false &&
      (modifiedSubCategory.wasDeficient === false || !shouldShowFlag);

    if (shouldRemoveSubCategory) {
      setSelectedSubCategories([
        ...selectedSubCategories.filter(
          selectedSubCategory => selectedSubCategory.id !== subCategory.id
        )
      ]);
    } else {
      setSelectedSubCategories([
        ...selectedSubCategories.filter(
          selectedSubCategory => selectedSubCategory.id !== subCategory.id
        ),
        modifiedSubCategory
      ]);
    }
  };

  const subCategoryError = () => {
    if (selectedCategories.length > 0 && selectedSubCategories.length > 0) {
      return selectedCategories
        .map(category => {
          return selectedSubCategories.some(
            subCategory => subCategory.category.id === category.id
          );
        })
        .includes(false);
    } else {
      return true;
    }
  };

  return (
    <>
      <Grid container direction="row" spacing={2}>
        {isAllSafe && (
          <Grid item>
            {selectedCategories?.map((category, index) => (
              <Grid
                container
                key={index}
                direction="row"
                spacing={1}
                alignItems="center">
                <Grid>
                  <CheckCircleIcon className={classes.isAllSafeIcon} />
                </Grid>
                <Grid item>
                  <Typography className={classes.categoryNameAllSafe}>
                    {category.name}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
        )}
        {!isAllSafe && (
          <>
            {selectedCategories?.map((category, index) => (
              <Grid item xs={12} sm={6} md={6} lg={6} xl={4} key={index}>
                <Typography className={classes.categoryName}>
                  {category.name}
                </Typography>
                {category.subCategories.map((subcategory, index) => {
                  return (
                    <FormGroup key={index}>
                      <Grid container direction="row" justifyContent="center">
                        <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                          <Grid
                            container
                            alignItems="center"
                            justifyContent="space-between">
                            <Grid item>
                              <Typography className={classes.subcategoryName}>
                                {subcategory.name}
                              </Typography>
                            </Grid>
                            <Grid item>
                              {shouldShowFlag && (
                                <Checkbox
                                  inputProps={{
                                    'data-testid': 'was-deficient-checkbox'
                                  }}
                                  icon={<OutlinedFlagIcon />}
                                  checked={
                                    selectedSubCategories?.find(
                                      selectedSubCategory =>
                                        selectedSubCategory.id ===
                                        subcategory.id
                                    )?.wasDeficient ?? false
                                  }
                                  disabled={
                                    selectedSubCategories?.find(
                                      selectedSubCategory =>
                                        selectedSubCategory.id ===
                                        subcategory.id
                                    )?.isSafe ?? false
                                  }
                                  checkedIcon={<FlagIcon color="error" />}
                                  name={t(
                                    'observationSubcategories.checkbox.deficient'
                                  )}
                                  onChange={event =>
                                    handleChange(
                                      event,
                                      subcategory,
                                      'wasDeficient'
                                    )
                                  }
                                  className={classes.checkbox}
                                  color="secondary"
                                />
                              )}

                              <Checkbox
                                inputProps={{
                                  'data-testid': 'is-safe-checkbox'
                                }}
                                icon={<RadioButtonUncheckedIcon />}
                                checked={
                                  selectedSubCategories?.find(
                                    selectedSubCategory =>
                                      selectedSubCategory.id === subcategory.id
                                  )?.isSafe ?? false
                                }
                                checkedIcon={
                                  <CheckCircleIcon
                                    className={classes.isSufficientChecked}
                                  />
                                }
                                name={t(
                                  'observationSubcategories.checkbox.isSafe'
                                )}
                                onChange={event =>
                                  handleChange(event, subcategory, 'isSafe')
                                }
                                className={classes.checkbox}
                                color="secondary"
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </FormGroup>
                  );
                })}
              </Grid>
            ))}
          </>
        )}
      </Grid>
      {subCategoryError() && !isAllSafe && (
        <FormHelperText className={classes.error}>
          {t('observationSubcategories.errorMessage')}
        </FormHelperText>
      )}
    </>
  );
};

ObservationSubcategories.propTypes = {
  isAllSafe: PropTypes.bool,
  selectedCategories: PropTypes.array,
  selectedSubCategories: PropTypes.array,
  setSelectedSubCategories: PropTypes.func,
  shouldShowFlag: PropTypes.bool
};

export default ObservationSubcategories;
