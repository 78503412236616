import { tutorials as rawTutorials } from '../../components/tutorials';

export const tutorials = getConstants();

function getConstants() {
  let constants = {};
  Object.keys(rawTutorials).forEach(categoryKey => {
    constants[categoryKey] = {};
    Object.keys(rawTutorials[categoryKey].sections).forEach(sectionKey => {
      constants[categoryKey][sectionKey] = sectionKey;
    });
  });
  return constants;
}
