import React from 'react';
import PropTypes from 'prop-types';
import copy from 'copy-to-clipboard';
import QRCode from 'qrcode.react';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import makeStyles from '@mui/styles/makeStyles';
import {
  Typography,
  Grid,
  Dialog,
  DialogTitle,
  Tooltip,
  IconButton,
  Divider,
  DialogContent,
  DialogActions,
  useTheme
} from '@mui/material';

import useToast from 'hooks/useToast';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';

const useStyles = makeStyles(theme => ({
  logoContainer: { marginBottom: '2rem' },
  hpLogo: { width: 250 },
  scLogo: { width: 130, marginLeft: theme.spacing(2) },
  title: { textAlign: 'center', fontSize: '2rem' },
  subtitle: { textAlign: 'center', fontSize: '1.5rem' },
  qrCodeContainer: { textAlign: 'center', margin: theme.spacing(6, 2) },
  instructionsTitle: { fontSize: '1.5rem' },
  closeIcon: { fontSize: '1.25rem' },
  dialogTitle: {
    zIndex: 1500,
    [theme.breakpoints.down('lg')]: { padding: theme.spacing(2) }
  },
  dialogContent: {
    padding: '1.5rem',
    [theme.breakpoints.down('lg')]: { padding: theme.spacing(3, 2) }
  },
  urlContainer: {
    border: '1px solid' + theme.palette.secondary.contrastText,
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(1),
    wordBreak: 'break-all'
  }
}));

const ShareCalendarDialog = ({
  shareCalendarDialogIsOpen,
  toggleShareCalendarDialog,
  projectId
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const { displayToast } = useToast();

  const URL = `${process.env.REACT_APP_SELF_REGISTRATION_URL}/projects/${projectId}/orientations`;

  const handleClose = () => {
    toggleShareCalendarDialog(false);
  };

  return (
    <Dialog
      data-testid="orientation-qr-code-dialog"
      open={shareCalendarDialogIsOpen}
      fullWidth={true}
      maxWidth="md"
      onClose={handleClose}>
      <DialogTitle className={classes.dialogTitle} id="non-printable">
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Grid container direction="row" alignItems="center">
              <Grid item>
                <Tooltip title={t('shareCalendarDialog.title')}>
                  <Typography color="textPrimary" className={classes.title}>
                    {t('shareCalendarDialog.title')}
                  </Typography>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <IconButton data-testid="iconButton" onClick={handleClose} size="large">
              <CloseIcon className={classes.closeIcon} />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider></Divider>
      <DialogContent className={classes.dialogContent} id="printable">
        <Grid container direction="column" spacing={1} alignContent="center">
          <Grid item className={classes.logoContainer}>
            <img
              data-testid="hp-logo-img"
              className={classes.hpLogo}
              src={theme.hpLogoContrast}
              alt={t('images.henselPhelpsLogoAltText')}
            />
            <img
              data-testid="safetyconnect-logo-img"
              className={classes.scLogo}
              src={theme.safetyConnectLogoContrast}
              alt={t('images.safetyConnectLogoAltText')}
            />
          </Grid>
          <Grid item>
            <Typography component="h1" className={classes.title}>
              {t('shareCalendarDialog.printablePage.title')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography component="h2" className={classes.subtitle}>
              {t('shareCalendarDialog.printablePage.subtitle')}
            </Typography>
          </Grid>
          <Grid item>
            <Grid container direction="column" spacing={4}>
              <Grid item>
                <Typography component="h3">
                  {t('shareCalendarDialog.shareLinkInstructions')}
                </Typography>
                <div className={classes.urlContainer}>
                  <Typography>{URL}</Typography>
                </div>
                <Grid container justifyContent="flex-end">
                  <StyledButtonPrimary
                    label={t('orientationRegistrationOptionsCard.actions.copy')}
                    onClick={() =>
                      copy(
                        URL,
                        displayToast(
                          t(
                            'orientationRegistrationOptionsCard.toasts.copy.success'
                          ),
                          'success'
                        )
                      )
                    }
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={classes.qrCodeContainer}>
            <QRCode value={URL} />
          </Grid>
          <Grid item>
            <Typography
              component="h2"
              className={classes.instructionsTitle}
              gutterBottom>
              {t(
                'orientationQrCodeDialog.printablePage.printInstructions.title'
              )}
            </Typography>
            <Typography component="h3" gutterBottom>
              {t(
                'orientationQrCodeDialog.printablePage.printInstructions.stepOne'
              )}
            </Typography>
            <Typography component="h3" gutterBottom>
              {t(
                'orientationQrCodeDialog.printablePage.printInstructions.stepTwo'
              )}
            </Typography>
            <Typography component="h3" gutterBottom>
              {t(
                'orientationQrCodeDialog.printablePage.printInstructions.stepThree'
              )}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions id="non-printable">
        <Grid container justifyContent="space-between">
          <Grid item>
            <StyledButtonPrimary
              label={t('shareCalendarDialog.actions.closeButton')}
              onClick={handleClose}
            />
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

ShareCalendarDialog.propTypes = {
  shareCalendarDialogIsOpen: PropTypes.any,
  toggleShareCalendarDialog: PropTypes.func,
  projectId: PropTypes.string.isRequired
};

export default ShareCalendarDialog;
