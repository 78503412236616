import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import { useField } from 'react-final-form-hooks';
import FlagIcon from '@mui/icons-material/Flag';
import ErrorIcon from '@mui/icons-material/Error';
import makeStyles from '@mui/styles/makeStyles';
import {
  Grid,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Link,
  Typography,
  CircularProgress,
  Avatar
} from '@mui/material';

import useFormValidation from 'hooks/useFormValidation';
import StyledStep from 'shared/Step';
import { formatDateOfBirth } from '../../../../utils';
import { PERSONNEL_SEARCH } from 'graphql/personnel';

const useStyles = makeStyles(theme => ({
  radioGroupContainer: { width: '100%' },
  toImprove: { color: theme.palette.flag.toImprove },
  resolved: { color: theme.palette.flag.resolved },
  achievement: { color: theme.palette.flag.achievement },
  attentionRequired: { color: theme.palette.flag.attentionRequired },
  flagContainer: {
    paddingRight: 8,
    [theme.breakpoints.down('lg')]: {
      paddingRight: 0
    }
  },
  noMatchesContainer: { marginTop: theme.spacing(1) },
  container: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  nameText: {
    fontWeight: 'bold',
    maxWidth: 370,
    [theme.breakpoints.down('xl')]: { maxWidth: 280 },
    [theme.breakpoints.down('lg')]: { maxWidth: 250 },
    [theme.breakpoints.down('md')]: { maxWidth: 200 }
  },
  fieldText: { fontSize: '0.875rem' },
  link: {
    color: theme.palette.background.callToAction,
    fontSize: '0.875rem',
    textDecoration: 'underline',
    '&:hover': { cursor: 'pointer' }
  },
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10)
  }
}));

const VerifyMatchesStep = ({
  form,
  handleDetailsClick,
  updatedProfileImage,
  setSelectedMatch
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isRequired } = useFormValidation();

  const { values } = form.getState();

  const getSearchString = () => {
    const SEARCHABLE_FIELDS = [
      'firstName',
      'lastName',
      'middleName',
      'nickname'
    ];
    const searchParts = [];

    SEARCHABLE_FIELDS.forEach(field => {
      if (values[field]) {
        searchParts.push(values[field]);
      }
    });
    return searchParts.join('|');
  };

  const getFilter = () => {
    if (values?.selectedHenselPhelpsEmployee?.employeeId) {
      return { employeeId: values.selectedHenselPhelpsEmployee.employeeId };
    } else if (values?.selectedHenselPhelpsEmployee?.dob) {
      return {
        dob: values.selectedHenselPhelpsEmployee.dob
      };
    } else if (values?.dob) {
      return { dob: values.dob.toFormat('yyyy-MM-dd') };
    } else if (values?.selectedHenselPhelpsEmployee?.upn) {
      return { upn: values.selectedHenselPhelpsEmployee.upn };
    } else if (values?.selectedHenselPhelpsEmployee?.email) {
      return { email: values.selectedHenselPhelpsEmployee.email };
    }
    return null;
  };

  const { data, loading: isLoading } = useQuery(PERSONNEL_SEARCH, {
    variables: {
      search: getSearchString(),
      filter: getFilter()
    },
    fetchPolicy: 'no-cache'
  });

  const selectedPersonnelId = useField('selectedPersonnelId', form, isRequired);

  useEffect(() => {
    const selectedMatch = data?.personnels.filter(
      personnel => personnel.id === selectedPersonnelId.input.value
    );
    setSelectedMatch(selectedMatch?.length > 0 ? selectedMatch[0] : null);
  }, [selectedPersonnelId.input.value, data, setSelectedMatch]);

  const renderIndividualFlag = (classname, count) => {
    if (count === 0) {
      return;
    }

    return (
      <>
        <Grid item className={classes.flagContainer}>
          <Typography>
            <FlagIcon className={classes[classname]} />({count})
          </Typography>
        </Grid>
      </>
    );
  };

  const renderFlagGroup = flags => {
    let toImproveCount = 0;
    let resolvedCount = 0;
    let achievementCount = 0;
    let attentionRequiredCount = 0;

    flags.forEach(flag => {
      if (flag.status === 'ToImprove') {
        toImproveCount++;
      } else if (flag.status === 'Resolved') {
        resolvedCount++;
      } else if (flag.status === 'Achievement') {
        achievementCount++;
      } else if (flag.status === 'High') {
        attentionRequiredCount++;
      }
      return null;
    });

    return (
      <Grid container alignItems="center">
        {renderIndividualFlag('toImprove', toImproveCount)}
        {renderIndividualFlag('resolved', resolvedCount)}
        {renderIndividualFlag('achievement', achievementCount)}
        {renderIndividualFlag('attentionRequired', attentionRequiredCount)}
      </Grid>
    );
  };

  return (
    <StyledStep title={t('verifyMatchesStep.title')}>
      {isLoading && <CircularProgress color="primary" />}
      {!isLoading && (
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <FormControl
              variant="standard"
              component="fieldset"
              className={classes.radioGroupContainer}>
              <RadioGroup
                name="personnelMatches"
                value={selectedPersonnelId.input.value}
                onChange={selectedPersonnelId.input.onChange}>
                {data?.personnels?.map(personnel => {
                  return (
                    <Grid container key={personnel.id}>
                      <Grid item xs={12}>
                        <FormControlLabel
                          value={personnel.id}
                          control={<Radio color="primary" />}
                          label={
                            <Grid
                              container
                              direction="row"
                              alignItems="center"
                              spacing={1}
                              className={classes.container}>
                              <Grid item>
                                {personnel.profileImage?.cachedUrl &&
                                  !personnel.profileImage?.isMalware && (
                                    <Avatar
                                      className={classes.avatar}
                                      src={
                                        personnel.profileImage.cachedUrl
                                      }></Avatar>
                                  )}
                                {!personnel.profileImage?.cachedUrl &&
                                  !personnel.profileImage?.isMalware && (
                                    <Avatar className={classes.avatar}></Avatar>
                                  )}
                                {!personnel.profileImage?.cachedUrl &&
                                  personnel.profileImage?.isMalware && (
                                    <Avatar className={classes.avatar}>
                                      <ErrorIcon
                                        className={classes.avatar}
                                        data-testid="malware-icon"
                                      />
                                    </Avatar>
                                  )}
                              </Grid>
                              <Grid item>
                                <Typography className={classes.nameText}>
                                  {personnel.fullName}
                                </Typography>
                                {personnel?.soteriaAdUser?.employeeId && (
                                  <Typography className={classes.fieldText}>
                                    HP Employee ID:{' '}
                                    {personnel.soteriaAdUser.employeeId}
                                  </Typography>
                                )}
                                <Typography className={classes.fieldText}>
                                  {t('verifyMatchesStep.dob.label')}{' '}
                                  {formatDateOfBirth(personnel.dob)}
                                </Typography>
                                <Link
                                  className={classes.link}
                                  aria-label={`view details on ${personnel.fullname}`}
                                  tabIndex="0"
                                  onKeyDown={() => {
                                    handleDetailsClick(personnel);
                                  }}
                                  onClick={() => {
                                    handleDetailsClick(personnel);
                                  }}
                                  underline="hover">
                                  <Typography variant="overline">
                                    {t('verifyMatchesStep.detailsButton.title')}
                                  </Typography>
                                </Link>
                              </Grid>
                              <Grid item>
                                {renderFlagGroup(personnel.flags)}
                              </Grid>
                            </Grid>
                          }
                        />
                      </Grid>
                    </Grid>
                  );
                })}
                {(!values?.selectedHenselPhelpsEmployee?.employeeId ||
                  !data?.personnels?.length) && (
                  <FormControlLabel
                    value={'no matches'}
                    control={<Radio color="primary" />}
                    label={
                      <>
                        {updatedProfileImage && (
                          <Grid
                            container
                            direction="row"
                            alignItems="center"
                            spacing={1}
                            className={classes.container}>
                            <Grid item>
                              <Avatar
                                className={classes.avatar}
                                src={updatedProfileImage.preview}></Avatar>
                            </Grid>
                            <Grid item>
                              <Typography className={classes.nameText}>
                                {t('verifyMatchesStep.noMatchesMessage')}
                              </Typography>
                            </Grid>
                          </Grid>
                        )}
                        {!updatedProfileImage && (
                          <Typography className={classes.nameText}>
                            {t('verifyMatchesStep.noMatchesMessage')}
                          </Typography>
                        )}
                      </>
                    }
                  />
                )}
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      )}
    </StyledStep>
  );
};

VerifyMatchesStep.propTypes = {
  form: PropTypes.object.isRequired,
  handleDetailsClick: PropTypes.func.isRequired,
  updatedProfileImage: PropTypes.object,
  setSelectedMatch: PropTypes.func.isRequired
};

export default VerifyMatchesStep;
