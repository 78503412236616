import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-final-form-hooks';
import { useMutation } from '@apollo/react-hooks';
import EditIcon from '@mui/icons-material/Edit';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Card, Typography, LinearProgress } from '@mui/material';

import useToast from 'hooks/useToast';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import StyledButtonSecondary from 'shared/Buttons/ButtonSecondary';
import StyledIconButton from 'shared/Buttons/IconButton';
import StyledNotice from 'shared/Notice';
import CompanyFormFields from 'components/company/CompanyFormFields';
import { getPhoneNumber, getPhoneValues } from 'utils';
import { UPDATE_COMPANY } from 'graphql/company';
import {
  formatPhoneNumber,
  formatInputAndInternationalPhoneNumber
} from 'utils/formatPhoneNumber';

const useStyles = makeStyles(theme => ({
  title: { fontWeight: 'bold', marginBottom: theme.spacing(1) },
  infoHeaderText: { fontWeight: 'bold' },
  buttonPrimary: { marginLeft: theme.spacing(1) },
  websiteLinkText: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'underline',
      color: theme.palette.primary.main
    }
  },
  notesContainer: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
    borderRadius: 4
  }
}));

const CompanyInfoForm = ({ company }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isEditMode, toggleEditMode] = useState(false);
  const [updateCompany, { loading }] = useMutation(UPDATE_COMPANY);
  const { displayToast } = useToast();

  const primaryPhone = getPhone('primary');
  const secondaryPhone = getPhone('secondary');
  const faxPhone = getPhone('fax');

  function getPhone(type) {
    let existingPhone = company.contactInformation?.phoneNumbers?.find(
      phone => phone.type.toLowerCase() === type.toLowerCase()
    );
    if (existingPhone) {
      const phoneNumber = getPhoneNumber(existingPhone.number);
      return { ...existingPhone, number: phoneNumber?.getNumber() ?? null };
    } else {
      return {
        number: null,
        display: null,
        extension: null,
        type: null
      };
    }
  }

  const formatPhoneNumbers = companyPhoneNumber => {
    const companyInputPhoneNumber = formatPhoneNumber(companyPhoneNumber);
    const companyInternationalPhoneNumber = formatInputAndInternationalPhoneNumber(
      companyPhoneNumber
    )?.international;
    return companyInternationalPhoneNumber
      ? companyInternationalPhoneNumber
      : companyInputPhoneNumber;
  };

  const { form, values, pristine } = useForm({
    onSubmit: () => {},
    initialValues: {
      name: company.name ?? '',
      streetAndNumber: company.location?.streetAndNumber ?? '',
      streetDetails: company.location?.streetDetail ?? '',
      city: company.location?.city ?? '',
      stateOrProvince: company.location?.stateOrProvince ?? '',
      zipOrPostalCode: company.location?.zipOrPostalCode ?? '',
      country: company.location?.country ?? 'United States',
      primaryPhoneNumber: primaryPhone.number ?? '',
      primaryPhoneExtension: primaryPhone.extension ?? '',
      secondaryPhoneNumber: secondaryPhone.number ?? '',
      secondaryPhoneExtension: secondaryPhone.extension ?? '',
      faxNumber: faxPhone.number ?? '',
      website: company.contactInformation?.website ?? '',
      notes: company.notes ?? ''
    }
  });

  const canSubmit = () => {
    const { error, invalid } = form.getState();
    return !(pristine || error || loading || invalid);
  };

  const onSubmit = () => {
    let companyToUpdate = {
      id: company.id,
      name: values.name,
      notes: values.notes,
      location: {
        display: `${values.streetAndNumber}${
          values.streetDetails ? '' + values.streetDetails : ''
        }, ${values.city}, ${values.stateOrProvince} ${
          values.zipOrPostalCode
        }, ${values.country}`,
        streetAndNumber: values.streetAndNumber,
        streetDetail: values.streetDetails,
        city: values.city,
        stateOrProvince: values.stateOrProvince,
        zipOrPostalCode: values.zipOrPostalCode,
        country: values.country
      },
      contactInformation: {
        website: values.website,
        primaryPhone: getPhoneValues(
          values.primaryPhoneNumber,
          values.primaryPhoneExtension,
          primaryPhone.number,
          'Primary'
        ),
        secondaryPhone: getPhoneValues(
          values.secondaryPhoneNumber,
          values.secondaryPhoneExtension,
          secondaryPhone.number,
          'Secondary'
        ),
        faxPhone: getPhoneValues(values.faxNumber, null, faxPhone.number, 'Fax')
      },
      contacts: []
    };

    updateCompany({
      variables: companyToUpdate
    })
      .then(({ data: { updateCompany: company } }) => {
        handleClose();
        displayToast(t('companyInfoForm.toasts.success'), 'success');
      })
      .catch(error => {
        console.error('Update Company Error: ', error);
        displayToast(t('companyInfoForm.toasts.error'), 'error');
      });
  };

  const handleClose = () => {
    form.reset();
    toggleEditMode(false);
  };

  return (
    <Fragment>
      {!isEditMode && (
        <Grid container>
          <Grid item xs={12}>
            <Card className="padding">
              <Grid container direction="row" justifyContent="space-between">
                <Grid item>
                  <Typography className={classes.title}>
                    {t('companyInfoForm.sections.generalInformation.title')}
                  </Typography>
                </Grid>
                <Grid item>
                  <StyledIconButton
                    data-testid="edit-iconbutton"
                    onClick={() => toggleEditMode(!isEditMode)}
                    disabled={loading}
                    className="noPadding">
                    <EditIcon />
                  </StyledIconButton>
                </Grid>
              </Grid>
              <Grid container direction="column">
                <Grid item>
                  <Grid
                    container
                    direction="column"
                    spacing={1}
                    justifyContent="space-between">
                    <Grid item xs={12}>
                      <Grid container direction="column">
                        <Grid item xs={12}>
                          <Typography className={classes.nameText}>
                            {company.name}
                          </Typography>
                          <Typography>{company.location?.display}</Typography>
                          {company.contactInformation?.website && (
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={company.contactInformation?.website}
                              alt="company website"
                              className={classes.websiteLinkText}>
                              <Typography>
                                {company.contactInformation?.website}
                              </Typography>
                            </a>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    {company.contactInformation?.phoneNumbers?.length > 0 && (
                      <Grid item xs={12}>
                        {company.contactInformation.phoneNumbers.map(
                          (phoneNumber, index) => (
                            <Fragment key={index}>
                              {phoneNumber.number && (
                                <Grid
                                  container
                                  spacing={1}
                                  direction="row"
                                  alignItems="center">
                                  <Grid item>
                                    <Typography
                                      color="textSecondary"
                                      className={classes.phoneTypeText}>
                                      {phoneNumber.type}
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <Typography>
                                      {formatPhoneNumbers(phoneNumber.number)}
                                    </Typography>
                                  </Grid>
                                  {phoneNumber.extension && (
                                    <Grid item>
                                      <Typography>
                                        {t(
                                          'companyInfoForm.sections.contactInformation.phoneExtension'
                                        )}
                                        {phoneNumber.extension}
                                      </Typography>
                                    </Grid>
                                  )}
                                </Grid>
                              )}
                            </Fragment>
                          )
                        )}
                      </Grid>
                    )}
                    <Grid item>
                      <Grid container>
                        <Grid item xs={12}>
                          <Grid container direction="column">
                            <Grid item>
                              <Typography
                                gutterBottom
                                className={classes.infoHeaderText}>
                                {t('companyInfoForm.sections.notes')}
                              </Typography>
                            </Grid>
                            <Grid item className={classes.notesContainer}>
                              <Typography>
                                {company.notes
                                  ? company.notes
                                  : t(
                                      'companyInfoForm.sections.noNotesDisclaimer'
                                    )}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      )}
      {isEditMode && (
        <Grid container justifyContent="space-evenly" direction="column">
          <Grid item xs={12}>
            {loading && <LinearProgress color="primary" />}
            <Card className="padding">
              <Grid container direction="row" justifyContent="space-between">
                <Grid item>
                  <Typography className="margin-bottom">
                    {t('companyInfoForm.sections.generalInformation.title')}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography color="primary">
                    {t('companyInfoForm.isEditing')}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container direction="column" spacing={2}>
                <Grid item xs={12}>
                  <StyledNotice
                    type="warning"
                    message={t(
                      'companyInfoForm.sections.generalInformation.warning'
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <form>
                    <CompanyFormFields
                      form={form}
                      isLoading={loading}
                      company={company}
                    />
                  </form>
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent="flex-end">
                    <Grid item>
                      <StyledButtonSecondary
                        className={classes.button}
                        onClick={handleClose}
                        label={t('companyInfoForm.actions.cancel')}
                        disabled={loading}
                      />
                      <StyledButtonPrimary
                        className={classes.buttonPrimary}
                        onClick={onSubmit}
                        label={t('companyInfoForm.actions.submit')}
                        disabled={!canSubmit()}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
};

CompanyInfoForm.propTypes = {
  company: PropTypes.object.isRequired
};

export default CompanyInfoForm;
