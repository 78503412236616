import { useSubscription } from '@apollo/react-hooks';
import { SOTERIA_USER_SUBSCRIPTION } from '../../graphql/soteriaAdUser';
import { useMsal } from '@azure/msal-react';

const useSoteriaAdUserSubscription = () => {
  const { instance: msalInstance } = useMsal();
  const microsoftAdId = msalInstance?.getActiveAccount()?.localAccountId;

  useSubscription(SOTERIA_USER_SUBSCRIPTION, {
    skip: !microsoftAdId,
    variables: { microsoftAdId }
    /* TODO: Handle cache update ourselves.
     * Detect if user is in a form. Display toast?
     * fetchPolicy: 'no-cache',
     * onSubscriptionData: ({ client, subscriptionData }) => {
         console.log('SUB IS HERE: ', subscriptionData);
       }
     */
  });
};

export default useSoteriaAdUserSubscription;
