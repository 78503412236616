import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { detect } from 'detect-browser';
import { useReactToPrint } from 'react-to-print';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import PublicIcon from '@mui/icons-material/Public';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import InboxIcon from '@mui/icons-material/Inbox';
import RateReviewIcon from '@mui/icons-material/RateReview';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import DeleteIcon from '@mui/icons-material/Delete';
import PrintIcon from '@mui/icons-material/Print';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import BlockIcon from '@mui/icons-material/Block';
import GroupIcon from '@mui/icons-material/Group';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import {
  Grid,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText
} from '@mui/material';

import useActiveRoute from 'hooks/useActiveRoute';
import useToast from 'hooks/useToast';
import StyledDeleteConfirmation from 'shared/DeleteConfirmation';
import NotificationSettingsDialog from 'components/ahas/NotificationSettingsDialog';
import AhaReviewersDialog from 'components/ahas/AhaReviewersDialog';
import TransferOwnershipDialog from 'components/ahas/TransferOwnershipDialog';
import AhaPrintableDocument from 'components/ahas/AhaPrintableDocument';
import CopyAhaDialog from 'components/ahas/CopyAhaDialog';
import AhaReviewApprovalConfirmationDialog from 'components/ahas/AhaReviewApprovalConfirmationDialog';
import AhaReviewRejectionConfirmationDialog from 'components/ahas/AhaReviewRejectionConfirmationDialog';
import AhaPrintDisclaimerDialog from 'components/ahas/AhaPrintDisclaimerDialog';
import useAhaGlobalTemplatesListState from 'store/ahaGlobalTemplatesListState';
import { DELETE_PROJECT_TEMPLATE } from 'graphql/aha/projectTemplate';
import {
  DELETE_GLOBAL_TEMPLATE,
  PAGINATED_GLOBAL_TEMPLATES
} from 'graphql/aha/globalTemplate';

const useStyles = makeStyles(theme => ({
  scopeLabel: { fontSize: '0.75rem', display: 'inline' },
  scopeIcon: {
    color: theme.palette.secondary.contrastText,
    marginRight: theme.spacing(0.5)
  },
  listItemLabel: {
    fontSize: '0.75rem',
    color: theme.palette.secondary.contrastText
  },
  listItemIcon: { minWidth: 35 },
  icon: { width: 16 }
}));

const StyledMenu = withStyles(theme => ({}))(props => (
  <Menu
    elevation={4}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: { color: theme.palette.primary.contrastText }
}))(MenuItem);

const AhaActionsMenu = ({
  ahaTemplate,
  isGlobal,
  ahaReview,
  isReadOnlyReview,
  hasChanges
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { projectId } = useParams();
  const { displayToast } = useToast();
  const { isActiveRoute } = useActiveRoute();
  const isAhaDetailedEditorPage = isActiveRoute(
    '/projects/:projectId/ahas/hensel-phelps-aha/:ahaId/edit'
  );
  const componentRef = useRef();
  const printReact = useReactToPrint({
    content: () => componentRef.current
  });
  const [printDisclaimerIsOpen, setPrintDisclaimerIsOpen] = useState(false);
  const browser = detect();
  const handlePrint = () => {
    if (browser && browser.name === 'safari') {
      setPrintDisclaimerIsOpen(true);
    } else {
      printReact();
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [
    isConfirmStepDeleteDialogOpen,
    setIsConfirmStepDeleteDialogOpen
  ] = useState(false);
  const [isDeleteConfirmed, toggleIsDeleteConfirmed] = useState(false);
  const [requestReviewDialogIsOpen, toggleRequestReviewDialog] = useState(
    false
  );
  const [saveAsDialogIsOpen, toggleSaveAsDialog] = useState(false);
  const [
    transferOwnershipDialogIsOpen,
    toggleTransferOwnershipDialog
  ] = useState(false);
  const [
    notificationSettingsDialogIsOpen,
    toggleNotificationSettingsDialog
  ] = useState(false);
  const [
    isReviewApprovalConfirmationDialogOpen,
    setIsReviewApprovalConfirmationDialogOpen
  ] = useState(false);
  const [
    isReviewRejectionConfirmationDialogOpen,
    setIsReviewRejectionConfirmationDialogOpen
  ] = useState(false);
  const [ahaGlobalTemplatesListState] = useAhaGlobalTemplatesListState();

  const [
    deleteAhaProjectTemplate,
    { loading: isDeleteProjectTemplateLoading }
  ] = useMutation(DELETE_PROJECT_TEMPLATE);

  const getApiSortOrder = order => {
    let apiSortOrderField = null;
    let direction = order.direction;

    switch (order.name) {
      case 'type':
        apiSortOrderField = 'type';
        break;
      case 'createdBy.name':
        apiSortOrderField = 'createdBy';
        break;
      case 'ahaGlobalTemplateCategory.name':
        apiSortOrderField = 'ahaGlobalTemplateCategory_name';
        break;
      case 'created':
        apiSortOrderField = 'created';
        break;
      case 'lastModified':
        apiSortOrderField = 'lastModified';
        break;
      default:
        console.error(`Sort order, "${order.name}", is not being handled!`);
    }
    return apiSortOrderField && direction
      ? [
          {
            [apiSortOrderField]: direction.toUpperCase()
          }
        ]
      : null;
  };

  const [
    deleteGlobalAhaTemplate,
    { loading: isDeleteGlobalTemplateLoading }
  ] = useMutation(DELETE_GLOBAL_TEMPLATE, {
    refetchQueries: [
      {
        query: PAGINATED_GLOBAL_TEMPLATES,
        variables: {
          first: ahaGlobalTemplatesListState.first,
          order: getApiSortOrder(ahaGlobalTemplatesListState.order),
          search: ahaGlobalTemplatesListState.search,
          skip: ahaGlobalTemplatesListState.skip
        }
      }
    ],
    awaitRefetchQueries: true
  });

  const isLoading =
    isDeleteProjectTemplateLoading || isDeleteGlobalTemplateLoading;

  const isRejectedReview = ahaReview?.status === 'Rejected';
  const isCompletedReview = ahaReview?.status === 'Completed';

  const handleMenuButtonClick = event => {
    setAnchorEl(event?.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    toggleIsDeleteConfirmed(false);
    setIsConfirmStepDeleteDialogOpen(false);
  };

  const handleCloseConfirmStepDelete = () => {
    setIsConfirmStepDeleteDialogOpen(false);
  };

  const handleChange = event => {
    toggleIsDeleteConfirmed(event.target.checked);
  };

  const handleDeleteSubmit = () => {
    if (isGlobal) {
      deleteGlobalAhaTemplate({ variables: { id: ahaTemplate?.id } })
        .then(() => {
          displayToast(
            t('ahaActionsMenu.toasts.deleteGlobalTemplate.success', {
              name: ahaTemplate?.type
            }),
            'success'
          );
          handleClose();
          history.push(`/ahas`);
        })
        .catch(error => {
          console.error('Delete Global Template Error: ', error);
          displayToast(
            t('ahaActionsMenu.toasts.deleteGlobalTemplate.error'),
            'error'
          );
        });
    } else {
      deleteAhaProjectTemplate({
        variables: { id: ahaTemplate?.ahaProjectTemplate?.id }
      })
        .then(() => {
          displayToast(
            t('ahaActionsMenu.toasts.deleteProjectTemplate.success', {
              name: ahaTemplate?.ahaProjectTemplate?.type
            }),
            'success'
          );
          handleClose();
          history.push(`/projects/${projectId}/ahas`);
        })
        .catch(error => {
          console.error('Delete Project Template Error: ', error);
          displayToast(
            t('ahaActionsMenu.toasts.deleteProjectTemplate.error'),
            'error'
          );
        });
    }
  };

  const handleConfirmApproval = () => {
    setIsReviewApprovalConfirmationDialogOpen(true);
  };

  const handleConfirmRejection = () => {
    setIsReviewRejectionConfirmationDialogOpen(true);
  };

  const handleManageReviewers = () => {
    toggleRequestReviewDialog(true);
  };

  return (
    <>
      <Grid container alignItems="flex-end" spacing={1}>
        <Grid item>
          <Grid container direction="row" alignItems="center">
            <Grid item>
              {!isReadOnlyReview && (
                <>
                  {isGlobal ? (
                    <PublicIcon className={classes.scopeIcon} />
                  ) : (
                    <LocationCityIcon className={classes.scopeIcon} />
                  )}
                </>
              )}
              {isReadOnlyReview && (
                <RateReviewIcon className={classes.scopeIcon} />
              )}
            </Grid>
            <Grid item>
              {!isReadOnlyReview && (
                <Typography color="textPrimary" className={classes.scopeLabel}>
                  {isGlobal
                    ? t('ahaActionsMenu.scope.global')
                    : t('ahaActionsMenu.scope.project')}
                </Typography>
              )}
              {isReadOnlyReview && (
                <Typography color="textPrimary" className={classes.scopeLabel}>
                  {t('ahaActionsMenu.scope.review')}
                </Typography>
              )}
            </Grid>
            <Grid item>
              <IconButton
                size="small"
                onClick={handleMenuButtonClick}
                disabled={isLoading}>
                <KeyboardArrowDownIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <StyledMenu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}>
            {!isReadOnlyReview && (
              <div>
                {!isGlobal && (
                  <StyledMenuItem
                    onClick={() => {
                      toggleNotificationSettingsDialog(true);
                      setAnchorEl(null);
                    }}>
                    <ListItemIcon className={classes.listItemIcon}>
                      <InboxIcon className={classes.icon} />
                    </ListItemIcon>
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography className={classes.listItemLabel}>
                          {t('ahaActionsMenu.menuItem.manageNotifications')}
                        </Typography>
                      }
                    />
                  </StyledMenuItem>
                )}
                {!isGlobal && (
                  <StyledMenuItem
                    onClick={() => {
                      toggleRequestReviewDialog(true);
                      setAnchorEl(null);
                    }}>
                    <ListItemIcon className={classes.listItemIcon}>
                      <RateReviewIcon className={classes.icon} />
                    </ListItemIcon>
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography className={classes.listItemLabel}>
                          {t('ahaActionsMenu.menuItem.requestReview')}
                        </Typography>
                      }
                    />
                  </StyledMenuItem>
                )}
                {!isGlobal && (
                  <StyledMenuItem
                    onClick={() => {
                      toggleTransferOwnershipDialog(true);
                      setAnchorEl(null);
                    }}>
                    <ListItemIcon className={classes.listItemIcon}>
                      <CompareArrowsIcon className={classes.icon} />
                    </ListItemIcon>
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography className={classes.listItemLabel}>
                          {t('ahaActionsMenu.menuItem.transferOwnership')}
                        </Typography>
                      }
                    />
                  </StyledMenuItem>
                )}
                <StyledMenuItem
                  onClick={() => {
                    toggleSaveAsDialog(true);
                    setAnchorEl(null);
                  }}
                  disabled={hasChanges}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <SaveAltIcon className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography className={classes.listItemLabel}>
                        {t('ahaActionsMenu.menuItem.copyAha')}
                      </Typography>
                    }
                  />
                </StyledMenuItem>
                <StyledMenuItem
                  onClick={() => {
                    setIsConfirmStepDeleteDialogOpen(true);
                    setAnchorEl(null);
                  }}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <DeleteIcon className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography className={classes.listItemLabel}>
                        {t('ahaActionsMenu.menuItem.delete')}
                      </Typography>
                    }
                  />
                </StyledMenuItem>
                {isAhaDetailedEditorPage && (
                  <StyledMenuItem
                    onClick={() => {
                      handlePrint();
                      setAnchorEl(null);
                    }}>
                    <ListItemIcon className={classes.listItemIcon}>
                      <PrintIcon className={classes.icon} />
                    </ListItemIcon>
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography className={classes.listItemLabel}>
                          {t('ahaActionsMenu.menuItem.print')}
                        </Typography>
                      }
                    />
                  </StyledMenuItem>
                )}
              </div>
            )}
            {isReadOnlyReview && (
              <div>
                <StyledMenuItem
                  disabled={isRejectedReview || isCompletedReview}
                  onClick={() => {
                    handleConfirmApproval();
                    setAnchorEl(null);
                  }}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <ThumbUpAltIcon className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography className={classes.listItemLabel}>
                        {t('ahaActionsMenu.menuItem.approve')}
                      </Typography>
                    }
                  />
                </StyledMenuItem>
                <StyledMenuItem
                  disabled={isRejectedReview || isCompletedReview}
                  onClick={() => {
                    handleConfirmRejection();
                    setAnchorEl(null);
                  }}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <BlockIcon className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography className={classes.listItemLabel}>
                        {t('ahaActionsMenu.menuItem.reject')}
                      </Typography>
                    }
                  />
                </StyledMenuItem>
                <StyledMenuItem
                  disabled={isRejectedReview || isCompletedReview}
                  onClick={() => {
                    handleManageReviewers();
                    setAnchorEl(null);
                  }}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <GroupIcon className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography className={classes.listItemLabel}>
                        {t('ahaActionsMenu.menuItem.manageReviewers')}
                      </Typography>
                    }
                  />
                </StyledMenuItem>
                <StyledMenuItem
                  onClick={() => {
                    handlePrint();
                    setAnchorEl(null);
                  }}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <PrintIcon className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography className={classes.listItemLabel}>
                        {t('ahaActionsMenu.menuItem.print')}
                      </Typography>
                    }
                  />
                </StyledMenuItem>
              </div>
            )}
          </StyledMenu>
        </Grid>
      </Grid>
      <StyledDeleteConfirmation
        title={t('ahaActionsMenu.deleteConfirmation.title')}
        dialogIsOpen={isConfirmStepDeleteDialogOpen}
        isLoading={false}
        handleClose={handleCloseConfirmStepDelete}
        type="notice"
        warningMessage={t('ahaActionsMenu.deleteConfirmation.warningMessage')}
        isConfirmed={isDeleteConfirmed}
        handleChange={handleChange}
        handleSubmit={handleDeleteSubmit}
      />
      <NotificationSettingsDialog
        notificationSettingsDialogIsOpen={notificationSettingsDialogIsOpen}
        toggleNotificationSettingsDialog={toggleNotificationSettingsDialog}
        ahaTemplate={ahaTemplate}
      />
      <AhaReviewersDialog
        requestReviewDialogIsOpen={requestReviewDialogIsOpen}
        toggleRequestReviewDialog={toggleRequestReviewDialog}
        ahaTemplate={ahaTemplate}
        isGlobal={isGlobal}
        isManagingReviewers={isReadOnlyReview}
        ahaReview={ahaReview}
        isReadOnlyReview={isReadOnlyReview}
      />
      <TransferOwnershipDialog
        transferOwnershipDialogIsOpen={transferOwnershipDialogIsOpen}
        toggleTransferOwnershipDialog={toggleTransferOwnershipDialog}
        ahaTemplate={ahaTemplate}
      />
      <CopyAhaDialog
        saveAsDialogIsOpen={saveAsDialogIsOpen}
        toggleSaveAsDialog={toggleSaveAsDialog}
        ahaTemplate={ahaTemplate}
        isGlobal={isGlobal}
      />
      <AhaReviewApprovalConfirmationDialog
        isOpen={isReviewApprovalConfirmationDialogOpen}
        setIsOpen={setIsReviewApprovalConfirmationDialogOpen}
      />
      <AhaReviewRejectionConfirmationDialog
        isOpen={isReviewRejectionConfirmationDialogOpen}
        setIsOpen={setIsReviewRejectionConfirmationDialogOpen}
      />
      <AhaPrintDisclaimerDialog
        isOpen={printDisclaimerIsOpen}
        setIsOpen={setPrintDisclaimerIsOpen}
        ahaTemplate={ahaTemplate}
        isGlobal={isGlobal}
      />
      <div style={{ display: 'none' }}>
        {!isGlobal && (
          <AhaPrintableDocument
            ref={componentRef}
            ahaTemplate={ahaTemplate}
            isGlobal={isGlobal}
          />
        )}
      </div>
    </>
  );
};

AhaActionsMenu.propTypes = {
  ahaTemplate: PropTypes.object,
  isGlobal: PropTypes.bool,
  ahaReview: PropTypes.object,
  isReadOnlyReview: PropTypes.bool,
  hasChanges: PropTypes.bool
};

export default AhaActionsMenu;
