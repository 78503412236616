import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@apollo/react-hooks';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Grid,
  Card,
  Typography,
  IconButton,
  TextField,
  InputAdornment,
  Button
} from '@mui/material';

import {
  GET_PAGINATED_GLOBAL_EQUIPMENTS,
  DELETE_GLOBAL_AHA_EQUIPMENT
} from 'graphql/aha';
import useAhaGlobalEquipmentListState from 'store/ahaGlobalEquipmentListState';
import StyledInfiniteScrollWithOverlay from 'shared/InfiniteScrollWithOverlay';
import useDebounce from 'hooks/useDebounce';
import StyledDeleteConfirmation from 'shared/DeleteConfirmation';
import useToast from 'hooks/useToast';
import UpsertEquipment from 'components/ahas/UpsertEquipment';
import useRoles from 'hooks/useRoles';
import useGlobalEquipmentTabContentStyles from './useGlobalEquipmentTabContentStyles';

const resetUpdateQuery = (
  _previousResult,
  { fetchMoreResult, _queryVariables }
) => {
  return fetchMoreResult;
};

const GlobalEquipmentTabContent = ({
  isEditing,
  handleAddGlobalEquipmentToTemplate
}) => {
  const styles = useGlobalEquipmentTabContentStyles();
  const classes = styles();
  const { t } = useTranslation();
  const { isAhaAdmin } = useRoles();
  const { displayToast } = useToast();
  const [shouldShowLoader, setShouldShowLoader] = useState(true);
  const [initialQueryHasBeenCalled, setInitialQueryHasBeenCalled] = useState(
    false
  );
  const [
    ahaGlobalEquipmentListState,
    { handleAhaGlobalEquipmentListStateChange }
  ] = useAhaGlobalEquipmentListState();
  const [search, setSearch] = useState(ahaGlobalEquipmentListState.search);
  const [initialEquipmentListState] = useState(ahaGlobalEquipmentListState);
  const [
    isUpsertEquipmentDialogOpen,
    setIsUpsertEquipmentDialogOpen
  ] = useState(false);
  const [selectedEquipment, setSelectedEquipment] = useState(null);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(
    false
  );
  const [isDeleteConfirmed, toggleIsDeleteConfirmed] = useState(false);
  const [isReadOnlyEquipment, setIsReadOnlyEquipment] = useState(false);

  // Get the initial data
  const { data, fetchMore: fetchMoreGlobalEquipments } = useQuery(
    GET_PAGINATED_GLOBAL_EQUIPMENTS,
    {
      variables: {
        first: initialEquipmentListState.first,
        skip: 0,
        search: initialEquipmentListState.search
      },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true
    }
  );

  useEffect(() => {
    const shouldDisableFullscreenLoader = !initialQueryHasBeenCalled && data;
    if (shouldDisableFullscreenLoader) {
      setShouldShowLoader(false);
      setInitialQueryHasBeenCalled(true);
    }
  }, [data, initialQueryHasBeenCalled]);

  useEffect(() => {
    if (!initialQueryHasBeenCalled && ahaGlobalEquipmentListState.skip > 0) {
      handleAhaGlobalEquipmentListStateChange({
        ...ahaGlobalEquipmentListState,
        skip: 0
      });
    }
  }, [
    ahaGlobalEquipmentListState,
    handleAhaGlobalEquipmentListStateChange,
    initialQueryHasBeenCalled
  ]);

  const globalEquipmentsList =
    data?.paginatedGlobalAhaEquipments?.ahaEquipments ?? [];

  const globalEquipmentsTotalCount =
    data?.paginatedGlobalAhaEquipments?.total ?? 0;

  const [deleteGlobalAhaEquipment, { loading: isDeleting }] = useMutation(
    DELETE_GLOBAL_AHA_EQUIPMENT
  );

  const loadMore = (variables, shouldReset = false) => {
    if (shouldReset) {
      setShouldShowLoader(true);
    }

    return fetchMoreGlobalEquipments({
      variables,
      updateQuery: shouldReset
        ? resetUpdateQuery
        : (previousResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return previousResult;
            return Object.assign({}, previousResult, {
              paginatedGlobalAhaEquipments: {
                ...previousResult.paginatedGlobalAhaEquipments,
                ...fetchMoreResult.paginatedGlobalAhaEquipments,
                total: fetchMoreResult.paginatedGlobalAhaEquipments.total,
                ahaEquipments: [
                  ...previousResult.paginatedGlobalAhaEquipments.ahaEquipments,
                  ...fetchMoreResult.paginatedGlobalAhaEquipments.ahaEquipments
                ]
              }
            });
          }
    }).then(() => {
      setShouldShowLoader(false);
    });
  };

  const handleLoadNextPage = () => {
    const skip =
      ahaGlobalEquipmentListState.skip + ahaGlobalEquipmentListState.first;
    handleAhaGlobalEquipmentListStateChange({
      ...ahaGlobalEquipmentListState,
      skip
    });

    const variables = {
      first: ahaGlobalEquipmentListState.first,
      search: ahaGlobalEquipmentListState.search,
      skip
    };

    loadMore(variables);
  };

  const resetAndRefetch = () => {
    handleAhaGlobalEquipmentListStateChange({
      ...ahaGlobalEquipmentListState,
      skip: 0
    });

    const variables = {
      first: ahaGlobalEquipmentListState.first,
      search: ahaGlobalEquipmentListState.search,
      skip: 0
    };

    loadMore(variables, true);
  };

  const handleDebouncedSearchChange = ({ search, state }) => {
    handleAhaGlobalEquipmentListStateChange({
      ...state,
      search,
      skip: 0
    });

    const variables = {
      first: state.first,
      skip: 0,
      search
    };
    loadMore(variables, true);
  };

  const { debounced: debouncedHandleSearchChange } = useDebounce(
    handleDebouncedSearchChange
  );

  const handleSearchChange = event => {
    const search = event ? event.target.value : '';

    setSearch(search);
    debouncedHandleSearchChange({
      search,
      state: ahaGlobalEquipmentListState
    });
  };

  const handleAddGlobalEquipmentButtonClick = () => {
    setIsUpsertEquipmentDialogOpen(!isUpsertEquipmentDialogOpen);
  };

  const handleDeleteSubmit = () => {
    deleteGlobalAhaEquipment({ variables: { id: selectedEquipment.id } })
      .then(() => {
        resetAndRefetch();
        displayToast(
          t('globalEquipmentLibrary.toasts.delete.success', {
            name: selectedEquipment.name
          }),
          'success'
        );
        handleClose();
      })
      .catch(error => {
        console.error('Delete Global Equipment Error: ', error);
        displayToast(t('globalEquipmentLibrary.toasts.delete.error'), 'error');
      });
  };

  const handleViewEquipment = equipment => {
    setIsReadOnlyEquipment(true);
    setSelectedEquipment(equipment);
    setIsUpsertEquipmentDialogOpen(true);
  };

  const handleClose = () => {
    setIsReadOnlyEquipment(false);
    setSelectedEquipment(null);
    setIsDeleteConfirmationOpen(false);
    toggleIsDeleteConfirmed(false);
    setIsUpsertEquipmentDialogOpen(false);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <TextField
            className={classes.searchInput}
            value={search}
            onChange={handleSearchChange}
            color="secondary"
            placeholder={t('dashboardPage.searchObservations')}
            margin="dense"
            variant="standard"
            inputProps={{
              'aria-label': t('dashboardPage.searchFieldLabel'),
              'aria-required': false
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    className={classes.icon}
                    aria-label={t('AHADashboardPage.Search')}
                  />
                </InputAdornment>
              ),
              endAdornment: search ? (
                <InputAdornment position="end">
                  <IconButton
                    aria-label={t('AHADashboardPage.clearSearch')}
                    size="small"
                    onClick={() => handleSearchChange('')}
                    className={classes.icon}>
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ) : null
            }}
            fullWidth={true}
          />
        </Grid>
        <Grid item xs={12}>
          <StyledInfiniteScrollWithOverlay
            containerName={'aha-global-equipment'}
            height={'calc(100vh - 348px)'}
            isLoading={shouldShowLoader}
            dataLength={globalEquipmentsList.length}
            next={handleLoadNextPage}
            hasMore={globalEquipmentsList.length < globalEquipmentsTotalCount}
            endMessageTextColor={'light'}>
            {globalEquipmentsList.map(equipment => {
              return (
                <Card
                  key={equipment.id}
                  className={classes.globalEquipmentCard}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center">
                    <Grid item>
                      <Typography className={classes.globalEquipmentCardName}>
                        {equipment.name}
                      </Typography>
                    </Grid>
                    <Grid item>
                      {!isEditing && (
                        <>
                          <IconButton
                            size="small"
                            onClick={() => {
                              handleViewEquipment(equipment);
                            }}>
                            <VisibilityIcon />
                          </IconButton>
                          <IconButton
                            size="small"
                            onClick={() => {
                              handleAddGlobalEquipmentToTemplate(equipment);
                            }}>
                            <AddIcon />
                          </IconButton>
                        </>
                      )}

                      {isEditing && (
                        <>
                          <IconButton
                            size="small"
                            onClick={() => {
                              setIsReadOnlyEquipment(false);
                              setSelectedEquipment(equipment);
                              setIsUpsertEquipmentDialogOpen(true);
                            }}>
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            size="small"
                            onClick={() => {
                              setIsReadOnlyEquipment(false);
                              setSelectedEquipment(equipment);
                              setIsDeleteConfirmationOpen(true);
                            }}>
                            <DeleteIcon />
                          </IconButton>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Card>
              );
            })}
          </StyledInfiniteScrollWithOverlay>
        </Grid>
        {isAhaAdmin && (
          <Grid item className={classes.addButtonContainer}>
            <Button
              className={classes.addButton}
              size="small"
              variant="contained"
              color="secondary"
              startIcon={<AddIcon />}
              onClick={handleAddGlobalEquipmentButtonClick}>
              <Typography className={classes.addButtonLabel}>
                {t('globalEquipmentLibrary.actions.addEquipmentButton')}
              </Typography>
            </Button>
          </Grid>
        )}
      </Grid>
      <UpsertEquipment
        isOpen={isUpsertEquipmentDialogOpen}
        setIsOpen={setIsUpsertEquipmentDialogOpen}
        isGlobal={true}
        refetchCurrentQueries={() => {}}
        equipmentToEdit={selectedEquipment}
        setEquipmentToEdit={setSelectedEquipment}
        isReadOnly={isReadOnlyEquipment}
      />
      <StyledDeleteConfirmation
        title={t(
          'globalEquipmentLibrary.deleteGlobalEquipmentConfirmationTitle'
        )}
        dialogIsOpen={isDeleteConfirmationOpen}
        isLoading={isDeleting}
        handleClose={handleClose}
        warningMessage={t('globalEquipmentLibrary.deleteWarning', {
          equipmentName: selectedEquipment?.name
        })}
        isConfirmed={isDeleteConfirmed}
        handleChange={event => {
          toggleIsDeleteConfirmed(event.target.checked);
        }}
        handleSubmit={handleDeleteSubmit}
      />
    </>
  );
};

GlobalEquipmentTabContent.propTypes = {
  isEditing: PropTypes.bool,
  handleAddGlobalEquipmentToTemplate: PropTypes.func
};

export default GlobalEquipmentTabContent;
