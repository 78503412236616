import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  IconButton,
  Divider,
  LinearProgress,
  Tooltip,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  closeIcon: {
    fontSize: '1.25rem',
    color: theme.palette.background.contrastText
  },
  dialogTitle: {
    zIndex: 1500,
    [theme.breakpoints.down('lg')]: { padding: theme.spacing(2) }
  },
  title: {
    fontSize: '1.5rem',
    whiteSpace: 'nowrap',
    alignSelf: 'center',
    maxWidth: 500,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('lg')]: { fontSize: '1.25rem' }
  },
  dialogContent: {
    [theme.breakpoints.down('lg')]: { padding: theme.spacing(1, 2) }
  }
}));

const StyledDialog = ({
  title,
  titleMenu,
  content,
  contentGroupHeader,
  actions,
  isOpen,
  handleClose,
  fullWidth = true,
  maxWidth = 'md',
  fullScreen = false,
  isLoading,
  shouldShowCloseIconButton = true,
  shouldCenterDialogTitle = false,
  disableBackdropClick = true,
  disableEscapeKeyDown = true,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={isOpen}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      fullScreen={fullScreen}
      onClose={handleClose}
      disableEscapeKeyDown={disableEscapeKeyDown}
      {...rest}>
      {isLoading && <LinearProgress color="primary" />}
      <DialogTitle className={classes.dialogTitle} id="non-printable">
        {!shouldCenterDialogTitle && (
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Grid container direction="row" alignItems="center">
                {titleMenu && <Grid item>{titleMenu}</Grid>}
                <Grid item>
                  <Tooltip title={title}>
                    <Typography color="textPrimary" className={classes.title}>
                      {title}
                    </Typography>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
            {shouldShowCloseIconButton && (
              <Grid item>
                <IconButton
                  data-testid="iconButton"
                  disabled={isLoading}
                  onClick={handleClose}
                  size="large">
                  <CloseIcon className={classes.closeIcon} />
                </IconButton>
              </Grid>
            )}
          </Grid>
        )}
        {shouldCenterDialogTitle && (
          <Grid container justifyContent="center" alignItems="center">
            <Grid item>
              <Grid container direction="row" alignItems="center">
                {titleMenu && <Grid item>{titleMenu}</Grid>}
                <Grid item>
                  <Tooltip title={title}>
                    <Typography color="textPrimary" className={classes.title}>
                      {title}
                    </Typography>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </DialogTitle>
      <Divider></Divider>
      <DialogContent className={classes.dialogContent} id="printable">
        {contentGroupHeader && (
          <Fragment>
            <Typography color="textPrimary">{contentGroupHeader}</Typography>
          </Fragment>
        )}
        {content}
      </DialogContent>
      <DialogActions id="non-printable">{actions}</DialogActions>
    </Dialog>
  );
};

StyledDialog.propTypes = {
  title: PropTypes.any.isRequired,
  titleMenu: PropTypes.node,
  content: PropTypes.node,
  contentGroupHeader: PropTypes.string,
  actions: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
  maxWidth: PropTypes.string,
  fullScreen: PropTypes.bool,
  isLoading: PropTypes.bool,
  shouldShowCloseIconButton: PropTypes.bool,
  shouldCenterDialogTitle: PropTypes.bool,
  disableBackdropClick: PropTypes.bool,
  disableEscapeKeyDown: PropTypes.bool
};

export default StyledDialog;
