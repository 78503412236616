export const Domain = Object.freeze({
  PRIMARY: Symbol('PRIMARY'),
  PARTNER: Symbol('PARTNER')
});

const domainByHostname = {
  ['localhost']: Domain.PRIMARY,
  ['partner.localhost']: Domain.PARTNER,
  ['qa-safetyconnect.henselphelps.io']: Domain.PRIMARY,
  ['partner.qa-safetyconnect.henselphelps.io']: Domain.PARTNER,
  ['beta-safetyconnect.henselphelps.com']: Domain.PRIMARY,
  ['partner.beta-safetyconnect.henselphelps.com']: Domain.PARTNER,
  ['safetyconnect.henselphelps.com']: Domain.PRIMARY,
  ['partner.safetyconnect.henselphelps.com']: Domain.PARTNER
};
const hostname =
  window.location.hostname !== 'localhost'
    ? window.location.hostname
    : process.env.REACT_APP_LOCALHOST_SIMULATED_DOMAIN;

export const domain = domainByHostname[hostname];

if (!domain) {
  throw new Error(`Unexpected hostname: ${window.location.hostname}`);
}
