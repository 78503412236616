import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';

import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';

const ButtonGroup = ({
  iconAltAction,
  onClickAltAction,
  labelAltAction,
  disabledAltAction,
  iconMainAction,
  onClickMainAction,
  labelMainAction,
  disabledMainAction
}) => {
  if (iconAltAction && iconMainAction) {
    return (
      <Grid container justifyContent="flex-end" direction="row" spacing={1}>
        <Grid item>
          <StyledButtonPrimary
            variant="outlined"
            color="primary"
            icon={iconAltAction}
            onClick={onClickAltAction}
            label={labelAltAction}
            disabled={disabledAltAction}
          />
        </Grid>
        <Grid item>
          <StyledButtonPrimary
            variant="contained"
            color="primary"
            icon={iconMainAction}
            onClick={onClickMainAction}
            label={labelMainAction}
            disabled={disabledMainAction}
          />
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid container justifyContent="flex-end" direction="row" spacing={1}>
        <Grid item>
          <StyledButtonPrimary
            variant="outlined"
            color="primary"
            onClick={onClickAltAction}
            label={labelAltAction}
            disabled={disabledAltAction}
          />
        </Grid>
        <Grid item>
          <StyledButtonPrimary
            variant="contained"
            color="primary"
            onClick={onClickMainAction}
            label={labelMainAction}
            disabled={disabledMainAction}
          />
        </Grid>
      </Grid>
    );
  }
};

ButtonGroup.propTypes = {
  iconAltAction: PropTypes.node,
  onClickAltAction: PropTypes.func.isRequired,
  labelAltAction: PropTypes.string,
  disabledAltAction: PropTypes.any,
  iconMainAction: PropTypes.node,
  onClickMainAction: PropTypes.func.isRequired,
  labelMainAction: PropTypes.string,
  disabledMainAction: PropTypes.any,
  className: PropTypes.string
};

export default ButtonGroup;
