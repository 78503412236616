import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import ShareIcon from '@mui/icons-material/Share';
import { Typography, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CardActionArea from '@mui/material/CardActionArea';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import AddIcon from '@mui/icons-material/Add';
import { DateTime } from 'luxon';

import withAuthorization from 'hocs/withAuthorization';
import withOnlineAccessOnly from 'hocs/withOnlineAccessOnly';
import useSoteriaStore from 'hooks/useSoteriaStore';
import StyledCalendar from 'shared/Calendar';
import StyledFab from 'shared/Buttons/Fab';
import StyledMonthDatePicker from 'shared/MonthDatePicker';
import BackToTopButton from 'shared/BackToTopButton';
import AddOrientationDialog from 'components/orientations/AddOrientationDialog';
import ShareCalendarDialog from 'components/orientations/ShareCalendarDialog';
import LoadingSpinner from 'components/common/LoadingSpinner';
import ErrorNotice from 'components/common/ErrorNotice';
import PageNotFoundNotice from 'components/common/PageNotFoundNotice';
import { GET_ALL_ORIENTATIONS_FOR_PROJECT } from 'graphql/orientation';
import {
  getDateTimeStringFromMoment,
  getDateFromDateTimeString,
  formatDate
} from 'utils/dateTime';

const useStyles = makeStyles(theme => ({
  title: { fontSize: '2rem' },
  card: { borderLeft: '6px solid' + theme.palette.primary.main },
  cardActionArea: {
    padding: theme.spacing(1),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  noOrientationsMessage: { padding: theme.spacing(1) }
}));

const OrientationsPage = () => {
  const {
    store: { whatsNewIsOpen = false }
  } = useSoteriaStore();
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { projectId } = useParams();
  const [slotInfo, setSlotInfo] = useState(null);
  const [shareCalendarDialogIsOpen, toggleShareCalendarDialog] = useState(
    false
  );
  const [addDialogIsOpen, toggleAddDialog] = useState(false);
  const today = DateTime.now();
  const [selectedDate, setSelectedDate] = useState(today);

  const { data, loading: isLoading, error } = useQuery(
    GET_ALL_ORIENTATIONS_FOR_PROJECT,
    {
      variables: { projectId: projectId },
      pollInterval: 5000,
      fetchPolicy: 'network-only'
    }
  );

  const orientations =
    data?.orientationsOnProject
      ?.filter(orientation => !orientation.isCancelled)
      .map(orientation => {
        const onlyHours =
          moment(orientation.startDateTime).format('mm') === '00';
        return {
          id: orientation.id,
          title: onlyHours
            ? `${moment(orientation.startDateTime).format(
                'ha'
              )} ${orientation.facilitators
                .map(facilitator => facilitator.name)
                .join(', ')}`
            : `${moment(orientation.startDateTime).format(
                'h:mm a'
              )} ${orientation.facilitators
                .map(facilitator => facilitator.name)
                .join(', ')}`,
          start: moment(orientation.startDateTime).toDate(),
          end: moment(orientation.endDateTime).toDate()
        };
      }) ?? [];

  const handleSelectSlot = slotInfo => {
    toggleAddDialog(true);
    setSlotInfo(slotInfo);
  };

  const handleShareButtonClick = () => {
    toggleShareCalendarDialog(true);
  };

  const handleSelectEvent = slotInfo => {
    history.push(`/projects/${projectId}/orientations/${slotInfo.id}`);
  };

  // HANDLE MOBILE VIEW FOR ORIENTATIONS
  const handleDateChange = date => {
    setSelectedDate(date);
  };

  const getOrientationsForSelectedMonth = (orientations, selectedDate) => {
    const filteredOrientations = orientations?.filter(
      orientation =>
        moment(orientation.start).month() === moment(selectedDate).month() &&
        moment(orientation.start).year() === moment(selectedDate).year()
    );

    filteredOrientations.sort((a, b) => {
      return moment(a.start).diff(b.start);
    });

    return filteredOrientations;
  };

  const orientationsToDisplay = getOrientationsForSelectedMonth(
    orientations,
    selectedDate
  );

  const getStartDateToDisplay = orientation => {
    const dateTimeString = getDateTimeStringFromMoment(orientation.start);
    const dateFromDateString = getDateFromDateTimeString(dateTimeString);
    return formatDate(dateFromDateString);
  };

  const handleSelectEventCard = orientation => {
    history.push(`/projects/${projectId}/orientations/${orientation.id}`);
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error) {
    if (error.message?.includes('not found')) {
      return <PageNotFoundNotice />;
    } else {
      return <ErrorNotice />;
    }
  }

  return (
    <>
      <Box
        sx={{
          display: {
            xs: 'block',
            sm: 'none',
            md: 'none',
            lg: 'none',
            xl: 'none'
          }
        }}>
        <StyledFab
          onClick={handleSelectSlot}
          shouldShift={whatsNewIsOpen}
          icon={<AddIcon />}
        />
      </Box>
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <Grid container direction="row" spacing={1} alignItems="center">
            <Grid item>
              <Typography color="textPrimary" className={classes.title}>
                {t('projectOrientationsPage.pageTitle')}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                size="small"
                onClick={handleShareButtonClick}
                aria-label={'share orientations for this project'}>
                <ShareIcon color="primary" />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {/* Orientations for Desktop */}
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            <StyledCalendar
              events={orientations}
              onSelectSlot={handleSelectSlot}
              onSelectEvent={handleSelectEvent}
            />
          </Box>
          {/* Orientations for Mobile */}
          <Grid
            container
            direction="column"
            spacing={2}
            sx={{
              display: {
                xs: 'block',
                sm: 'none',
                md: 'none',
                lg: 'none',
                xl: 'none'
              }
            }}>
            <Grid item>
              <StyledMonthDatePicker
                disableFuture={false}
                selectedDate={selectedDate}
                handleDateChange={handleDateChange}
              />
            </Grid>
            {orientationsToDisplay.map(orientation => (
              <Grid item key={orientation.id}>
                <Card className={classes.card}>
                  <Tooltip
                    title={`${getStartDateToDisplay(orientation)} - ${
                      orientation.title
                    }`}>
                    <CardActionArea
                      onClick={() => handleSelectEventCard(orientation)}
                      className={classes.cardActionArea}>
                      <Typography
                        variant="caption"
                        className={classes.orientationDetails}>
                        <span className="bold">
                          {getStartDateToDisplay(orientation)} -
                        </span>{' '}
                        {orientation.title}
                      </Typography>
                    </CardActionArea>
                  </Tooltip>
                </Card>
              </Grid>
            ))}
            {orientationsToDisplay.length === 0 && (
              <Grid item className={classes.noOrientationsMessage}>
                <Typography>
                  {t('projectOrientationsPage.mobile.noOrientationsMessage')}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <AddOrientationDialog
        addDialogIsOpen={addDialogIsOpen}
        toggleAddDialog={toggleAddDialog}
        slotInfo={slotInfo}
      />
      {projectId && (
        <ShareCalendarDialog
          shareCalendarDialogIsOpen={shareCalendarDialogIsOpen}
          toggleShareCalendarDialog={toggleShareCalendarDialog}
          projectId={projectId}
        />
      )}
      <BackToTopButton />
    </>
  );
};

export default withOnlineAccessOnly(withAuthorization(OrientationsPage));
