import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import makeStyles from '@mui/styles/makeStyles';
import {
  Typography,
  FormControlLabel,
  Checkbox,
  FormGroup
} from '@mui/material';

const useStyles = makeStyles(theme => ({
  allSafeFormControlLabel: { margin: '0 auto' },
  allSafeLabel: {
    fontSize: '0.875rem',
    fontWeight: 'bold',
    marginTop: theme.spacing(0.75)
  },
  allSafeMessage: { fontSize: '0.75rem', textAlign: 'center' },
  allSafeChecked: { color: theme.palette.success.main }
}));

const ObservationAllSafe = ({ isAllSafe, setIsAllSafe }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <FormGroup>
        <FormControlLabel
          className={classes.allSafeFormControlLabel}
          control={
            <Checkbox
              icon={<RadioButtonUncheckedIcon />}
              checkedIcon={
                <CheckCircleIcon className={classes.allSafeChecked} />
              }
              name={t('observationForm.field.allSafe.title')}
              checked={isAllSafe}
              onChange={() => setIsAllSafe(!isAllSafe)}
              color="secondary"
            />
          }
          label={
            <Typography
              color="textSecondary"
              className={classNames(classes.allSafeLabel, {
                [`${classes.allSafeChecked}`]: isAllSafe === true
              })}>
              {t('observationForm.field.allSafe.title')}
            </Typography>
          }
        />
      </FormGroup>
      <Typography className={classes.allSafeMessage}>
        {t('observationForm.field.allSafe.message')}
      </Typography>
    </>
  );
};

ObservationAllSafe.propTypes = {
  isAllSafe: PropTypes.bool,
  setIsAllSafe: PropTypes.func
};

export default ObservationAllSafe;
