import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import PictureAsPdf from '@mui/icons-material/PictureAsPdf';
import ErrorIcon from '@mui/icons-material/Error';
import makeStyles from '@mui/styles/makeStyles';
import { CircularProgress, Typography } from '@mui/material';

const useStyles = makeStyles(theme => ({
  fullSizePreview: {
    display: 'flex',
    justifyContent: 'center'
  },
  pdfPaginationButtons: {
    display: 'flex',
    justifyContent: 'center'
  },
  pdfIcon: {
    width: '50%',
    height: '50%'
  },
  placeholderContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%'
  },
  imagePlaceholderText: {
    alignSelf: 'center',
    fontWeight: 'bold',
    marginTop: 2,
    [theme.breakpoints.down('lg')]: {
      textAlign: 'center'
    }
  }
}));

const PdfViewer = ({ file }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <>
      <div className={classes.fullSizePreview}>
        {!file.cachedUrl && !file.isMalware && (
          <div className={classes.placeholderContainer}>
            <PictureAsPdf
              className={classes.pdfIcon}
              data-testid="pdf-placeholder-image"
            />{' '}
            <Typography className={classes.imagePlaceholderText}>
              {t('personnelTrainingDialog.readOnly.imagePlaceholderText')}
            </Typography>
          </div>
        )}

        {file.isMalware && (
          <div className={classes.placeholderContainer}>
            <ErrorIcon
              className={classes.pdfIcon}
              data-testid="malware-icon-pdf-view"
            />{' '}
            <Typography className={classes.imagePlaceholderText}>
              {t('personnelTrainingDialog.readOnly.isMalware')}
            </Typography>
          </div>
        )}

        {file.cachedUrl && !file.isMalware && (
          <div data-testid="pdf-viewer">
            <Document
              file={{ url: file.cachedUrl }}
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={error => {
                console.log('ERROR', error);
              }}
              loading={<CircularProgress color="primary" />}>
              {Array.from(new Array(numPages), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
              ))}
            </Document>
          </div>
        )}
      </div>
    </>
  );
};

PdfViewer.propTypes = {
  file: PropTypes.any
};

export default PdfViewer;
