import { createTheme } from '@mui/material';
import pageNotFoundDark from 'assets/images/404pageNotFoundDark.svg';
import pageNotFoundLight from 'assets/images/404pageNotFoundLight.svg';
import addProjectAdminDark from 'assets/images/addProjectAdminDark.svg';
import addProjectAdminLight from 'assets/images/addProjectAdminLight.svg';
import ahaBackgroundDark from 'assets/images/AhaDashboardImageDark.jpg';
import ahaBackgroundLight from 'assets/images/AhaDashboardImageLight.jpg';
import ahaBuilderDark from 'assets/images/dashboardTileImages/dark/ahaBuilderDark.png';
import incidentsDark from 'assets/images/dashboardTileImages/dark/nearMissDark.png';
import orientationsDark from 'assets/images/dashboardTileImages/dark/orientationsDark.png';
import permissionsDark from 'assets/images/dashboardTileImages/dark/permissionsDark.png';
import personnelDark from 'assets/images/dashboardTileImages/dark/personnelDark.png';
import safeDark from 'assets/images/dashboardTileImages/dark/safeDark.png';
// Dashboard Tiles
import tradePartnersDark from 'assets/images/dashboardTileImages/dark/tradePartnersDark.png';
import violationsDark from 'assets/images/dashboardTileImages/dark/violationsDark.png';
import dataSheetsDark from 'assets/images/dashboardTileImages/dark/dataSheetsDark.png';
import workerHoursDark from 'assets/images/dashboardTileImages/dark/workerHoursDark.png';
import ahaBuilderLight from 'assets/images/dashboardTileImages/light/ahaBuilderLight.png';
import incidentsLight from 'assets/images/dashboardTileImages/light/nearMissLight.png';
import orientationsLight from 'assets/images/dashboardTileImages/light/orientationsLight.png';
import permissionsLight from 'assets/images/dashboardTileImages/light/permissionsLight.png';
import personnelLight from 'assets/images/dashboardTileImages/light/personnelLight.png';
import safeLight from 'assets/images/dashboardTileImages/light/safeLight.png';
import tradePartnersLight from 'assets/images/dashboardTileImages/light/tradePartnersLight.png';
import violationsLight from 'assets/images/dashboardTileImages/light/violationsLight.png';
import dataSheetsLight from 'assets/images/dashboardTileImages/light/dataSheetsLight.png';
import workerHoursLight from 'assets/images/dashboardTileImages/light/workerHoursLight.png';
import safetyHoursLight from 'assets/images/dashboardTileImages/light/safetyHoursLight.png';
import safetyHoursDark from 'assets/images/dashboardTileImages/dark/safetyHoursDark.png';
import preIncidentIndicatorsLight from 'assets/images/dashboardTileImages/light/preIncidentIndicatorsLight.png';
import preIncidentIndicatorsDark from 'assets/images/dashboardTileImages/dark/preIncidentIndicatorsDark.png';
import permitsLight from 'assets/images/dashboardTileImages/light/permitsLight.png';
import permitsDark from 'assets/images/dashboardTileImages/dark/permitsDark.png';
import deleteTradePartnerDark from 'assets/images/deleteTradePartnerDark.svg';
import deleteTradePartnerLight from 'assets/images/deleteTradePartnerLight.svg';
import errorNotice from 'assets/images/errorNotice.svg';
import errorNoticeDark from 'assets/images/errorNoticeDark.svg';
import safariPrintDisclaimerDark from 'assets/images/landingPageDark.svg';
import safariPrintDisclaimerLight from 'assets/images/landingPageLight.svg';
import safePracticesLight from 'assets/images/dashboardTileImages/light/safePracticesLight.png';
import safePracticesDark from 'assets/images/dashboardTileImages/dark/safePracticesDark.png';
import drugTestLight from 'assets/images/dashboardTileImages/light/drugTestLight.png';
import drugTestDark from 'assets/images/dashboardTileImages/dark/drugTestDark.png';
// Logos
import hpLogoDark from 'assets/images/logos/hpLogoDark.png';
import hpLogoLight from 'assets/images/logos/hpLogoLight.png';
import safeLogoDark from 'assets/images/logos/safeLogoDark.png';
import safeLogoDarkMobile from 'assets/images/logos/safeLogoDarkMobile.png';
import safeLogoLight from 'assets/images/logos/safeLogoLight.png';
import safeLogoLightMobile from 'assets/images/logos/safeLogoLightMobile.png';
import safetyConnectLogoDark from 'assets/images/logos/SafetyConnectLogoLandscapeBlack.png';
import safetyConnectLogoLight from 'assets/images/logos/SafetyConnectLogoLandscapeWhite.png';
import safetyConnectLogoMinimalLight from 'assets/images/logos/SafetyConnectLogoMinimalWhite.png';
import noObservationsNoticeDark from 'assets/images/noObservationsDark.svg';
import noObservationsNoticeLight from 'assets/images/noObservationsLight.svg';
import noProjectsDark from 'assets/images/noProjectsDark.svg';
import noProjectsLight from 'assets/images/noProjectsLight.svg';
import offlineNoAccessNoticeDark from 'assets/images/offlineNoAccessDark.svg';
import offlineNoAccessNoticeLight from 'assets/images/offlineNoAccessLight.svg';
import safeBackgroundDark from 'assets/images/safeBackgroundDark.jpg';
// Background Images
import safeBackgroundLight from 'assets/images/safeBackgroundLight.jpg';
import transferPersonnelDark from 'assets/images/transferPersonnelDark.svg';
import transferPersonnelLight from 'assets/images/transferPersonnelLight.svg';
import unauthorizedAccessDark from 'assets/images/unauthorizedAccessDark.svg';
import unauthorizedAccessLight from 'assets/images/unauthorizedAccessLight.svg';
import underConstructionDark from 'assets/images/underConstructionDark.svg';
import underConstructionLight from 'assets/images/underConstructionLight.svg';
import { tabsClasses } from '@mui/material/Tabs';
import { switchClasses } from '@mui/material/Switch';

const SOTERIA_TYPOGRAPHY = {
  fontFamily: ['Roboto', 'ui-sans-serif', 'sans-serif'].join(','),
  useNextVariants: true
};

const sharedOverrides = {
  MuiButtonGroup: { styleOverrides: { grouped: { minWidth: 30 } } },
  // AHA Feature text editor
  MUIRichTextEditor: {
    styleOverrides: {
      root: { width: '100%' },
      placeHolder: { position: 'relative' },
      error: { borderBottom: 'none' },
      inheritFontSize: { fontSize: '0.875rem' }
    }
  },
  MuiTableRow: {
    styleOverrides: { hover: { '&:hover': { cursor: 'pointer' } } }
  },
  MUIDataTable: {
    styleOverrides: { responsiveBase: { padding: 16 } }
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        paddingTop: 0 + '!important',
        paddingBottom: 0 + '!important',
        '@media (min-width: 960px)': {
          height: 30
        }
      }
    }
  },
  MUIDataTableBodyCell: {
    styleOverrides: {
      stackedCommon: {
        '@media (max-width: 960px)': {
          fontSize: '0.875rem'
        }
      }
    }
  },
  MUIDataTablePagination: {
    styleOverrides: {
      navContainer: {
        justifyContent: 'center !important'
      }
    }
  },
  MuiTablePagination: {
    styleOverrides: {
      spacer: {
        flex: 0
      },
      toolbar: {
        justifyContent: 'start',
        '@media (max-width: 960px)': {
          flexWrap: 'wrap',
          justifyContent: 'center'
        }
      },
      actions: {
        '@media (max-width: 960px)': {
          marginLeft: 0
        }
      }
    }
  },

  // Active Input Styles
  MuiOutlinedInput: {
    styleOverrides: {
      inputMarginDense: { paddingTop: 9, paddingBottom: 6 },
      // Active Input Styles
      root: {
        padding: 0,
        lineHeight: '1.1876em',
        minHeight: '36px',
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderWidth: 2
        }
      },
      input: {
        paddingLeft: 14,
        paddingRight: 14,
        paddingTop: 9,
        paddingBottom: 9
      }
    }
  },
  MuiTableSortLabel: {
    styleOverrides: {
      icon: {
        fontSize: '1rem',
        marginTop: 16,
        fontWeight: 'bold'
      }
    }
  },
  // this sets table filter options to 100% width
  MuiGridListTile: { styleOverrides: { root: { width: '100% !important' } } },
  MuiFormControl: { styleOverrides: { root: { width: '100%' } } },
  MuiInputBase: {
    styleOverrides: { root: { minHeight: 36 }, input: { fontSize: '0.875rem' } }
  },
  MuiSelect: {
    styleOverrides: { icon: { color: 'hsl(0,0%,80%) !important' } }
  },
  MuiPickersToolbarText: {
    styleOverrides: { toolbarTxt: { fontSize: '2rem' } }
  },
  MuiIconButton: {
    styleOverrides: { root: { marginRight: 0 } }
  }
};

// hpRed & Variants = '#a6192e';
const hpRedLightened = '#dd5157';
const hpRedLightest = '#f0c9d0';
const hpRedDarkened = '#700006';

// hpYellow & Variants = '#f2a900';
const hpYellowLightened = '#ffda4a';
const hpYellowLightest = '#f5d898';
const hpYellowDarkened = '#ba7a00';

// Appwide
const brightWhite = '#ffffff';
const trueBlack = '#000000';
const transparent = 'rgba(0,0,0,0)';

// RGBAs
const overlayLight = 'rgba(219,219,219,.7)';
const overlayThinLight = 'rgba(255, 255, 255,.23)';
const overlayDark = 'rgba(0, 0, 0,.9)';
const overlayThinDark = 'rgba(0, 0, 0,.23)';
const overlayGray = 'rgba(33, 33, 33,.7)';
const overlayHpRed = 'rgba(112, 0, 6,0.9)';
//const overlayHpYellow = 'rgba(242, 169, 0, 0.95)';

// THEME COLORS: LIGHT
const hpRed = '#A6192E';
const featherLightGray = '#f2f2f2';
const mediumLightGray = '#d9d9d9';
const lightAppleGreen = '#749116';
const peach = '#DF6C2C';
const darkPeach = '#B25623';
const blueSea = '#1975A6';
const raspberry = '#cd0000';
const darkRaspberry = '#A40000';
const whiteGray = '#eaeaea';
const disabledTextLight = 'rgba(0, 0, 0, 0.26)';

// THEME COLORS: DARK
const hpYellow = '#f2a900';
const deepestGray = '#121212';
const mediumDeepGray = '#212121';
const seafoamGreen = '#009E63';
const pumpkin = '#F26D00';
const darkPumpkin = '#C15700';
const blueSky = '#0086f2';
const mediumDarkGray = '#564e4e';
const blackGray = '#443c3c';
const disabledTextDark = 'rgba(255, 255, 255, 0.3)';

const paletteInvariant = {
  white: brightWhite,
  lightGray: mediumLightGray,
  black: trueBlack
};

const paletteScoreIndicator = {
  medium: hpYellow,
  high: peach,
  highest: hpRed
};

const paletteRankChangeIndicator = {
  same: '#808080',
  down: '#7e911d',
  up: hpRed,
  new: peach,
  extra: '#808080'
};

export const HP_LIGHT_THEME = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: hpRed,
      light: hpRedLightened,
      lightest: hpRedLightest,
      dark: hpRedDarkened,
      contrastText: brightWhite
    },
    secondary: {
      main: featherLightGray,
      light: brightWhite,
      dark: mediumLightGray,
      contrastText: trueBlack
    },
    error: {
      main: raspberry,
      dark: darkRaspberry
    },
    warning: {
      main: peach,
      dark: darkPeach
    },
    info: {
      main: blueSea
    },
    success: {
      main: lightAppleGreen
    },
    background: {
      default: featherLightGray,
      paper: brightWhite,
      contrastText: trueBlack,
      alternateText: brightWhite,
      callToAction: hpRed,
      confirmation: whiteGray,
      overlay: overlayLight,
      disabledText: disabledTextLight
    },
    flag: {
      toImprove: peach,
      resolved: lightAppleGreen,
      achievement: blueSea,
      attentionRequired: raspberry
    },
    training: {
      expired: 'rgba(232, 85, 85)',
      expiringSoon: 'rgba(255, 160, 109)'
    },
    icon: {
      main: 'rgba(0, 0, 0, 0.54)',
      select: 'hsl(0,0%,80%)'
    },
    contrastText: {
      red: raspberry,
      green: lightAppleGreen
    },
    observation: {
      status: mediumDarkGray
    },
    aha: {
      inactiveStatus: mediumDarkGray
    },
    field: {
      border: '#c6c6c6',
      placeholder: '#000000de'
    },
    ahaRacMatrix: {
      extremelyHighRisk: '#ffcdcb',
      highRisk: '#ffe7cb',
      moderateRisk: '#fffed5',
      lowRisk: '#c7dec9',
      extremelyHighRiskLabel: '#564e4e',
      highRiskLabel: '#564e4e',
      moderateRiskLabel: '#564e4e',
      lowRiskLabel: '#564e4e'
    },
    richTextEditor: {
      highlight: 'yellow'
    },
    scoreIndicator: paletteScoreIndicator,
    rankChangeIndicator: paletteRankChangeIndicator,
    invariant: paletteInvariant
  },

  // Appwide
  white: brightWhite,
  typography: SOTERIA_TYPOGRAPHY,
  hpLogo: hpLogoLight,
  hpLogoContrast: hpLogoDark,
  safetyConnectLogo: safetyConnectLogoLight,
  safetyConnectLogoContrast: safetyConnectLogoDark,
  safetyConnectLogoMinimal: safetyConnectLogoMinimalLight,
  transparent: transparent,
  stepperBackgroundColor: brightWhite,
  borderGray: mediumDarkGray,

  // Tiles
  tradePartnersTile: tradePartnersLight,
  workerHoursTile: workerHoursLight,
  permissionsTile: permissionsLight,
  personnelTile: personnelLight,
  orientationsTile: orientationsLight,
  safeTile: safeLight,
  ahaTile: ahaBuilderLight,
  incidentsTile: incidentsLight,
  violationsTile: violationsLight,
  dataSheetsTile: dataSheetsLight,
  safetyHoursTile: safetyHoursLight,
  preIncidentIndicatorsTile: preIncidentIndicatorsLight,
  permitsTile: permitsLight,
  safePracticesTile: safePracticesLight,
  drugTestTile: drugTestLight,

  // Select AutoComplete Styles
  selectAutoCompleteMenuBackgroundColor: brightWhite,
  selectAutoCompleteControlBackgroundColor: brightWhite,
  selectAutoCompleteOptionBackgroundColor: brightWhite,
  selectAutoCompleteOptionColor: trueBlack,
  selectAutoCompleteInputColor: trueBlack,
  selectAutoCompletePlaceholderColor: trueBlack,
  selectAutoCompleteLoadingIndicatorColor: trueBlack,
  selectAutoCompleteSingleValueColor: trueBlack,
  selectAutoCompleteMultiValueColor: trueBlack,

  // AppBar Styles
  primaryAppbarBackgroundColor: hpRed,
  secondaryAppbarBackgroundColor: featherLightGray,

  // LinearGradient Styles
  backgroundLinearGradientTop: overlayHpRed,
  backgroundLinearGradientBottom: overlayDark,

  // SVG Images
  noProjectsNotice: noProjectsLight,
  addProjectAdmin: addProjectAdminLight,
  deleteTradePartner: deleteTradePartnerLight,
  errorNotice: errorNotice,
  unauthorizedAccess: unauthorizedAccessLight,
  pageNotFound404: pageNotFoundLight,
  underConstruction: underConstructionLight,
  safeLogo: safeLogoLight,
  safeLogoMobile: safeLogoLightMobile,
  noObservationsNotice: noObservationsNoticeLight,
  offlineNoAccessNotice: offlineNoAccessNoticeLight,
  transferEmployees: transferPersonnelLight,
  safariPrintDisclaimer: safariPrintDisclaimerLight,

  // Background Images
  safeBackground: safeBackgroundLight,
  ahaBackground: ahaBackgroundLight,

  // AHA Summary View Global Library Bg Colors
  libraryBackgroundColor: hpRedDarkened,
  libraryTabBackgroundColor: hpRed,

  // Overrides
  components: Object.assign({}, sharedOverrides, {
    MuiButton: {
      styleOverrides: {
        outlined: { border: `1px solid ${overlayThinDark}` },
        root: { color: trueBlack },
        containedPrimary: { color: brightWhite }
      }
    },
    MuiChip: {
      styleOverrides: {
        deleteIcon: {
          color: trueBlack
        }
      }
    },
    MuiStepIcon: {
      styleOverrides: {
        '&.Mui-active': { color: hpRed + '!important' },
        '&.Mui-completed': { color: lightAppleGreen + '!important' }
      }
    },
    MUIDataTableToolbar: {
      styleOverrides: {
        icon: {
          '&:hover': {
            color: trueBlack
          }
        },
        iconActive: {
          color: hpRed
        }
      }
    },
    MUIDataTableHeadCell: {
      styleOverrides: {
        root: {
          color: trueBlack
        }
      }
    },
    MUIDataTableFilter: {
      styleOverrides: {
        gridListTile: {
          maxWidth: '100%'
        }
      }
    },
    MUITableCell: {
      styleOverrides: {
        root: {
          color: trueBlack
        }
      }
    },
    MuiTableSortLabel: {
      styleOverrides: {
        icon: {
          color: trueBlack
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          backgroundColor: hpRed,
          color: mediumLightGray,
          '&.Mui-selected': {
            color: brightWhite
          }
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          height: '68px',
          [`& .${tabsClasses.flexContainer}`]: { height: '68px' },
          [`& .${tabsClasses.indicator}`]: { backgroundColor: brightWhite }
        }
      }
    },
    MuiSwitch: {
      root: {
        [`&.${switchClasses.checked} .${switchClasses.track}`]: {
          backgroundColor: 'rgb(116,145,22)'
        }
      }
    }
  })
});

export const HP_DARK_THEME = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: hpYellow,
      light: hpYellowLightened,
      lightest: hpYellowLightest,
      dark: hpYellowDarkened,
      contrastText: trueBlack
    },
    secondary: {
      main: deepestGray,
      light: mediumDeepGray,
      dark: trueBlack,
      contrastText: brightWhite
    },
    error: {
      main: '#d9213c',
      dark: '#AD1A30'
    },
    warning: {
      main: pumpkin,
      dark: darkPumpkin
    },
    info: {
      main: blueSky
    },
    success: {
      main: seafoamGreen
    },
    background: {
      default: deepestGray,
      paper: mediumDeepGray,
      contrastText: brightWhite,
      alternateText: trueBlack,
      callToAction: hpYellow,
      confirmation: blackGray,
      overlay: overlayDark,
      disabledText: disabledTextDark
    },
    flag: {
      toImprove: pumpkin,
      resolved: '#00d988',
      achievement: '#41aaff',
      attentionRequired: '#d9213c'
    },
    training: {
      expired: 'rgba(232, 85, 85)',
      expiringSoon: 'rgba(255, 160, 109)'
    },
    icon: {
      main: brightWhite,
      select: brightWhite
    },
    contrastText: {
      red: '#ff647b',
      green: '#00d988'
    },
    observation: {
      status: featherLightGray
    },
    aha: {
      inactiveStatus: featherLightGray
    },
    field: {
      border: '#515151',
      placeholder: '#ffffff'
    },
    ahaRacMatrix: {
      extremelyHighRisk: '#313131',
      highRisk: '#313131',
      moderateRisk: '#313131',
      lowRisk: '#313131',
      extremelyHighRiskLabel: 'red',
      highRiskLabel: 'orange',
      moderateRiskLabel: 'yellow',
      lowRiskLabel: 'green'
    },
    richTextEditor: {
      highlight: '#BBBB00'
    },
    scoreIndicator: paletteScoreIndicator,
    rankChangeIndicator: paletteRankChangeIndicator,
    invariant: paletteInvariant
  },

  // Appwide
  white: brightWhite,
  typography: SOTERIA_TYPOGRAPHY,
  hpLogo: hpLogoLight,
  hpLogoContrast: hpLogoLight,
  safetyConnectLogo: safetyConnectLogoLight,
  safetyConnectLogoContrast: safetyConnectLogoLight,
  safetyConnectLogoMinimal: safetyConnectLogoMinimalLight,
  transparent: transparent,
  stepperBackgroundColor: trueBlack,
  borderGray: '#191715',

  // Tiles
  tradePartnersTile: tradePartnersDark,
  workerHoursTile: workerHoursDark,
  permissionsTile: permissionsDark,
  personnelTile: personnelDark,
  orientationsTile: orientationsDark,
  safeTile: safeDark,
  ahaTile: ahaBuilderDark,
  incidentsTile: incidentsDark,
  violationsTile: violationsDark,
  dataSheetsTile: dataSheetsDark,
  safetyHoursTile: safetyHoursDark,
  preIncidentIndicatorsTile: preIncidentIndicatorsDark,
  permitsTile: permitsDark,
  safePracticesTile: safePracticesDark,
  drugTestTile: drugTestDark,

  //   Select AutoComplete Styles
  selectAutoCompleteMenuBackgroundColor: deepestGray,
  selectAutoCompleteControlBackgroundColor: mediumDeepGray,
  selectAutoCompleteOptionBackgroundColor: deepestGray,
  selectAutoCompleteOptionColor: brightWhite,
  selectAutoCompleteInputColor: brightWhite,
  selectAutoCompletePlaceholderColor: brightWhite,
  selectAutoCompleteLoadingIndicatorColor: brightWhite,
  selectAutoCompleteSingleValueColor: brightWhite,
  selectAutoCompleteMultiValueColor: trueBlack,

  //   AppBar Styles
  primaryAppbarBackgroundColor: trueBlack,
  secondaryAppbarBackgroundColor: deepestGray,

  //   LinearGradiant Styles
  backgroundLinearGradientTop: overlayDark,
  backgroundLinearGradientBottom: overlayGray,

  // SVG Images
  noProjectsNotice: noProjectsDark,
  addProjectAdmin: addProjectAdminDark,
  deleteTradePartner: deleteTradePartnerDark,
  errorNotice: errorNoticeDark,
  unauthorizedAccess: unauthorizedAccessDark,
  pageNotFound404: pageNotFoundDark,
  underConstruction: underConstructionDark,
  safeLogo: safeLogoDark,
  safeLogoMobile: safeLogoDarkMobile,
  noObservationsNotice: noObservationsNoticeDark,
  offlineNoAccessNotice: offlineNoAccessNoticeDark,
  transferEmployees: transferPersonnelDark,
  safariPrintDisclaimer: safariPrintDisclaimerDark,

  // Background Images
  safeBackground: safeBackgroundDark,
  ahaBackground: ahaBackgroundDark,

  // AHA Summary View Global Library Bg Colors
  libraryBackgroundColor: trueBlack,
  libraryTabBackgroundColor: deepestGray,

  // Overrides
  components: Object.assign({}, sharedOverrides, {
    MuiButton: {
      styleOverrides: {
        outlined: {
          border: `1px solid ${overlayThinLight}`
        },
        root: {
          color: brightWhite
        },
        containedPrimary: { color: trueBlack }
      }
    },
    MuiChip: {
      styleOverrides: {
        deleteIcon: {
          color: brightWhite
        }
      }
    },
    MuiStepIcon: {
      styleOverrides: {
        '&.Mui-active': { color: hpYellow + '!important' },
        '&.Mui-completed': { color: seafoamGreen + '!important' }
      }
    },
    MUIDataTableToolbar: {
      styleOverrides: {
        icon: {
          '&:hover': {
            color: brightWhite
          }
        },
        iconActive: {
          color: hpYellow
        }
      }
    },
    MUIDataTableHeadCell: {
      styleOverrides: {
        root: {
          color: brightWhite
        }
      }
    },
    MUIDataTableFilter: {
      styleOverrides: {
        gridListTile: {
          maxWidth: '100%'
        }
      }
    },
    MUITableCell: {
      styleOverrides: {
        root: {
          color: brightWhite
        }
      }
    },
    MuiTableSortLabel: {
      styleOverrides: {
        icon: {
          color: brightWhite
        }
      }
    },
    MuiPaper: {
      styleOverrides: { root: { backgroundImage: 'unset' } }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: blackGray,
          '&.Mui-selected': {
            color: trueBlack
          }
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          height: '68px',
          [`& .${tabsClasses.flexContainer}`]: { height: '68px' },
          [`& .${tabsClasses.indicator}`]: { backgroundColor: trueBlack }
        }
      }
    }
  })
});
