import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  container: { marginBottom: theme.spacing(1) }
}));

const StyledStep = ({ title, children }) => {
  const classes = useStyles();

  return (
    <Grid container direction="column">
      <Grid item className={classes.container}>
        <Typography variant="h6">{title}</Typography>
      </Grid>
      {children}
    </Grid>
  );
};

StyledStep.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired
};

export default StyledStep;
