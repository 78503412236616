import React from 'react';
import VideoPlayer from '../../VideoPlayer';
import { Grid, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  subTitle: { fontSize: '0.875rem' },
  title: { fontSize: '1.5rem' },
  bold: { fontWeight: 'bold' }
}));

const CancelRecurringOrientation = () => {
  const classes = useStyles();

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography className={classes.subTitle} color="textSecondary">
          Orientations Tutorial
        </Typography>
        <Typography className={classes.title} color="textPrimary">
          Cancel Recurring Orientation
        </Typography>
      </Grid>
      <Grid item>
        <VideoPlayer
          data-testid="cancel-recurring-orientation-video"
          url="/tutorials/orientations/CancelOrientationSeries.mp4"
          tracks={[
            {
              kind: 'subtitles',
              src: '/tutorials/orientations/CancelOrientationSeries.en.vtt',
              srcLang: 'en',
              default: true
            }
          ]}
        />
      </Grid>
    </Grid>
  );
};

export default CancelRecurringOrientation;
