import { OPEN_OBSERVATION_COUNT } from 'graphql/observations';
import useSoteriaQuery from 'hooks/useSoteriaQuery';

const useObservationCounts = (projectId, isAdminTypeRole) => {
  const { data: myObservationsCountData } = useSoteriaQuery({
    gql: OPEN_OBSERVATION_COUNT,
    queryHookOptions: {
      variables: { projectId, ownObservations: true }
    }
  });

  const { data: allHpObservationsCountData } = useSoteriaQuery({
    gql: OPEN_OBSERVATION_COUNT,
    queryHookOptions: {
      variables: {
        projectId,
        createdByAzureClientId: process.env.REACT_APP_AZURE_CLIENT_ID
      },
      skip: !isAdminTypeRole
    }
  });

  const { data: allTpObservationsCountData } = useSoteriaQuery({
    gql: OPEN_OBSERVATION_COUNT,
    queryHookOptions: {
      variables: {
        projectId,
        createdByAzureClientId: process.env.REACT_APP_AZURE_B2C_CLIENT_ID
      },
      skip: !isAdminTypeRole
    }
  });

  const allOpenHpObservationsCount =
    allHpObservationsCountData?.observationSearch?.total ?? null;
  const allOpenTpObservationsCount =
    allTpObservationsCountData?.observationSearch?.total ?? null;
  const myOpenObservationsCount =
    myObservationsCountData?.observationSearch?.total ?? null;

  return {
    allOpenHpObservationsCount,
    allOpenTpObservationsCount,
    allOpenObservationsCount:
      allOpenHpObservationsCount === null && allOpenTpObservationsCount === null
        ? null
        : (allOpenHpObservationsCount ?? 0) + (allOpenTpObservationsCount ?? 0),
    myOpenObservationsCount
  };
};

export default useObservationCounts;
