import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import PublicIcon from '@mui/icons-material/Public';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography } from '@mui/material';

const useStyles = makeStyles(theme => ({
  container: { marginBottom: theme.spacing(1) },
  scopeLabel: { fontSize: '0.75rem', display: 'inline' },
  scopeIcon: {
    color: theme.palette.secondary.contrastText,
    marginRight: theme.spacing(0.5)
  }
}));

const AhaScopeIndicator = ({ isGlobal }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="flex-end"
      className={classes.container}>
      <Grid item>
        {isGlobal ? (
          <PublicIcon className={classes.scopeIcon} />
        ) : (
          <LocationCityIcon className={classes.scopeIcon} />
        )}
      </Grid>
      <Grid item>
        <Typography color="textPrimary" className={classes.scopeLabel}>
          {isGlobal
            ? t('scopeIndicator.label.global')
            : t('scopeIndicator.label.project')}
        </Typography>
      </Grid>
    </Grid>
  );
};

AhaScopeIndicator.propTypes = {
  isGlobal: PropTypes.bool.isRequired
};

export default AhaScopeIndicator;
