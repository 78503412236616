import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'clone-deep';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import EditIcon from '@mui/icons-material/Edit';
import {
  Grid,
  Card,
  Typography,
  Chip,
  LinearProgress
} from '@mui/material';

import useToast from 'hooks/useToast';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import StyledIconButton from 'shared/Buttons/IconButton';
import StyledChip from 'shared/Chip';
import TradePartnerSelect from 'components/tradepartners/TradePartnerSelect';
import { stringCompare } from 'utils';
import {
  SET_SUBCONTRACTOR_STATUS,
  SET_PARENT_CONTRACTOR_STATUS
} from 'graphql/tradePartner';

const ManageContractorsCard = ({
  contractors,
  title,
  relationType,
  subtitle,
  isEditable = true
}) => {
  const history = useHistory();
  const { projectId, tradePartnerId } = useParams();
  const { t } = useTranslation();
  const { displayToast } = useToast();
  const [isEditing, toggleEditing] = useState(false);
  const [selectedContractor, setSelectedContractor] = useState(null);

  const sortedContractors = cloneDeep(contractors);
  sortedContractors.sort((a, b) => stringCompare(a.name, b.name));

  const isParentRelationType = relationType === 'parent';

  const [setContractorStatus, { loading }] = useMutation(
    isParentRelationType
      ? SET_SUBCONTRACTOR_STATUS
      : SET_PARENT_CONTRACTOR_STATUS
  );

  const setStatus = (contractor, status) => {
    return setContractorStatus({
      variables: {
        parentId: isParentRelationType ? contractor.id : tradePartnerId,
        subcontractorId: isParentRelationType ? tradePartnerId : contractor.id,
        isConnected: status
      }
    });
  };

  const handleRemove = contractor => {
    setStatus(contractor, false)
      .then(data => {
        displayToast(
          t('contractorStatus.toasts.remove.success', {
            contractorName: contractor.name
          }),
          'success'
        );
      })
      .catch(error => {
        console.error('Set Subcontractor Status Error: ', error);
        displayToast(t('contractorStatus.toasts.error'), 'error');
      });
  };

  const handleAdd = () => {
    setStatus(selectedContractor, true)
      .then(data => {
        displayToast(
          t('contractorStatus.toasts.add.success', {
            contractorName: selectedContractor.name
          }),
          'success'
        );
        setSelectedContractor(null);
      })
      .catch(error => {
        console.error('Set Subcontractor Status Error: ', error);
        displayToast(t('contractorStatus.toasts.error'), 'error');
      });
  };

  return <>
    {loading && <LinearProgress color="primary" />}
    <Card className="padding">
      <Grid container direction="row" justifyContent="space-between" spacing={1}>
        <Grid item>
          <Typography gutterBottom color="textPrimary" className="bold">
            {title}
          </Typography>
        </Grid>
        {isEditable && (
          <Grid item>
            {!isEditing && (
              <StyledIconButton
                data-testid="edit-iconbutton"
                disabled={loading}
                onClick={() => toggleEditing(!isEditing)}
                className="noPadding">
                <EditIcon />
              </StyledIconButton>
            )}
            {isEditing && (
              <Typography color="primary">
                {t(
                  'tradePartnerPage.tradePartnerInfo.relatedDetails.isEditing'
                )}
              </Typography>
            )}
          </Grid>
        )}
      </Grid>
      <Grid container>
        {!isEditing && (
          <Grid item>
            {sortedContractors.length > 0 && (
              <>
                <Grid container>
                  {sortedContractors.length >= 1 && (
                    <Grid item xs={12}>
                      <Typography color="textSecondary" gutterBottom>
                        {subtitle}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
                <Grid container direction="row" spacing={1}>
                  {sortedContractors.map(contractor => (
                    <Grid item key={contractor.id}>
                      <Chip
                        size="small"
                        color="primary"
                        variant="outlined"
                        clickable
                        label={contractor.name}
                        onClick={() =>
                          history.push(
                            `/projects/${projectId}/trade-partners/${contractor.id}`
                          )
                        }
                      />
                    </Grid>
                  ))}
                </Grid>
              </>
            )}
          </Grid>
        )}
        {sortedContractors.length < 1 && (
          <Grid item xs={12}>
            <Typography color="textSecondary">
              {t(
                'tradePartnerPage.tradePartnerInfo.relatedDetails.noRelatedContractors'
              )}
            </Typography>
          </Grid>
        )}
        {isEditing && (
          <Grid item xs={12}>
            <Grid container direction="column" spacing={1}>
              <Grid item xs={12}>
                <TradePartnerSelect
                  isDisabled={loading}
                  type={relationType}
                  selectedTradePartner={selectedContractor}
                  handleChange={setSelectedContractor}
                />
              </Grid>
              <Grid item>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <StyledButtonPrimary
                      disabled={!selectedContractor || loading}
                      onClick={handleAdd}
                      label={t(
                        'tradePartnerPage.tradePartnerInfo.relatedDetails.actions.add'
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container direction="row" spacing={1}>
                  {sortedContractors.map(contractor => (
                    <Grid item key={contractor.id}>
                      <StyledChip
                        data-testid="contractor-chip"
                        disabled={loading}
                        label={contractor.name}
                        onDelete={() => handleRemove(contractor)}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid item className="margin-top">
                <StyledButtonPrimary
                  disabled={loading}
                  onClick={() => toggleEditing(!isEditing)}
                  label={t(
                    'tradePartnerPage.tradePartnerInfo.relatedDetails.actions.close'
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Card>
  </>;
};

ManageContractorsCard.propTypes = {
  contractors: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  relationType: PropTypes.string.isRequired,
  isEditable: PropTypes.bool
};

export default ManageContractorsCard;
