import gql from 'graphql-tag';

// Queries
export const GET_SAFE_PROJECT = gql`
  query GetSafeProject($id: ID!) {
    safeProject(id: $id) {
      id
      name
      number
      districtNumber
    }
  }
`;

export const GET_SAFE_PROJECT_FOR_AREAS = gql`
  query GetSingleSafeProjectForAreas($projectId: ID!) {
    safeProject(id: $projectId) {
      id
      areas
    }
  }
`;

export const GET_SAFE_PROJECT_WITH_ALL_RELATIONS = gql`
  query GetSafeProjectWithAllRelations($id: ID!) {
    safeProject(id: $id) {
      areas
      id
      isActive
      name
      displayAddress
      number
      districtNumber
      availableAhaReferenceOptions
      tradePartners {
        id
        isActive
        name
        personnels {
          id
          customId
          isActive
          isSupervisor
          personnel {
            id
            firstName
            lastName
            middleName
            nickname
            upn
          }
        }
      }
    }
  }
`;

export const GET_SAFE_PROJECTS = gql`
  query GetSafeProjects {
    safeProjects {
      areas
      id
      isActive
      name
      displayAddress
      number
      districtNumber
      availableAhaReferenceOptions
      tradePartners {
        id
        isActive
        name
        personnels {
          id
          customId
          isActive
          isSupervisor
          personnel {
            id
            firstName
            lastName
            middleName
            nickname
            upn
          }
        }
      }
    }
  }
`;

export const GET_SAFE_PROJECT_SUBSCRIPTION = gql`
  subscription SafeProjectSubscription($id: ID!) {
    safeProject(id: $id) {
      areas
      id
      isActive
      name
      displayAddress
      number
      districtNumber
      availableAhaReferenceOptions
      tradePartners {
        id
        isActive
        name
        personnels {
          id
          customId
          isActive
          isSupervisor
          personnel {
            id
            firstName
            lastName
            middleName
            nickname
            upn
          }
        }
      }
    }
  }
`;
