import React from 'react';
import PropTypes from 'prop-types';
import { PulseLoader } from 'react-spinners';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography, Link } from '@mui/material';

import useHelpAndSupport from 'hooks/useHelpAndSupport';
import useTutorials from 'hooks/useTutorials';
import StyledStep from 'shared/Step';
import StyledNotice from 'shared/Notice';
import AdUserSelect from 'components/ad/AdUserSelect';
import { SERVICENOW_URL } from 'constants/externalLinks';

const useStyles = makeStyles(theme => ({
  container: { flexDirection: 'column' },
  loadingText: { display: 'inline' },
  noticeMessage: { fontSize: '0.75rem', display: 'inline' },
  link: { '&:hover': { cursor: 'pointer' } }
}));

const SelectAdUserStep = ({
  isLoading,
  isLoadingSearchResults,
  areMatchesFound,
  setAreMatchesFound,
  checkApiForDupes,
  selectedHenselPhelpsEmployee
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const { toggleHelp } = useHelpAndSupport();
  const { tutorials } = useTutorials();

  return (
    <StyledStep title={t('linkHpPersonnelDialog.selectHpEmployeeStep.title')}>
      <Grid container className={classes.container} spacing={1}>
        <Grid item>
          <Typography variant="subtitle2">
            ({t('linkHpPersonnelDialog.selectHpEmployeeStep.adMessage')})
          </Typography>
        </Grid>
        <Grid item>
          <AdUserSelect
            selectedUser={selectedHenselPhelpsEmployee.input.value || null}
            meta={selectedHenselPhelpsEmployee.meta}
            handleChange={selectedAdUser => {
              selectedHenselPhelpsEmployee.input.onChange(selectedAdUser);

              if (selectedAdUser === null) {
                setAreMatchesFound(false);
              }

              if (selectedAdUser !== null) {
                checkApiForDupes({
                  variables: {
                    filter: { upn: selectedAdUser.upn }
                  }
                });
              }
            }}
            isDisabled={isLoading || isLoadingSearchResults}
            placeholder={
              <Typography>
                {t('linkHpPersonnelDialog.selectHpEmployeeStep.search')}
              </Typography>
            }
            isRequired={true}
            customError={
              areMatchesFound
                ? t('selectAdUserStep.customError.duplicateFound')
                : undefined
            }
          />
        </Grid>
        {isLoadingSearchResults && (
          <Grid item>
            <Grid container direction="row" spacing={1} alignItems="flex-end">
              <Grid item>
                <Typography color="primary" className={classes.loadingText}>
                  {t('selectAdUserStep.loadingSearchResults')}
                </Typography>
              </Grid>
              <Grid item>
                <PulseLoader
                  color={theme.palette.primary.main}
                  loading={true}
                  size={6}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
        {areMatchesFound && !isLoadingSearchResults && (
          <Grid item>
            <StyledNotice
              title={'Manage Duplicate Record(s)'}
              message={
                <>
                  <Typography
                    component="span"
                    className={classes.noticeMessage}>
                    {t('selectAdUserStep.duplicateRecordsNotice.messagePart1')}
                  </Typography>{' '}
                  <Link
                    className={classes.link}
                    aria-label={
                      'view the tutorial on how to merge duplicate personnel records'
                    }
                    underline="always"
                    tabIndex="0"
                    onKeyDown={() =>
                      toggleHelp(
                        true,
                        tutorials?.personnel?.mergeDuplicateRecords
                      )
                    }
                    onClick={() =>
                      toggleHelp(
                        true,
                        tutorials?.personnel?.mergeDuplicateRecords
                      )
                    }>
                    {t(
                      'selectAdUserStep.duplicateRecordsNotice.link.mergeTutorial'
                    )}
                  </Link>{' '}
                  <Typography
                    component="span"
                    className={classes.noticeMessage}>
                    {t('selectAdUserStep.duplicateRecordsNotice.messagePart2')}
                  </Typography>{' '}
                  <Link
                    className={classes.link}
                    aria-label={'navigate to ServiceNow in a new window'}
                    underline="always"
                    tabIndex="0"
                    onKeyDown={() =>
                      window.open(SERVICENOW_URL, '_blank', 'noopener')
                    }
                    onClick={() =>
                      window.open(SERVICENOW_URL, '_blank', 'noopener')
                    }>
                    {t(
                      'selectAdUserStep.duplicateRecordsNotice.link.contactSupport'
                    )}
                  </Link>{' '}
                  <Typography
                    component="span"
                    className={classes.noticeMessage}>
                    {t('selectAdUserStep.duplicateRecordsNotice.messagePart3')}
                  </Typography>
                </>
              }
            />
          </Grid>
        )}
      </Grid>
    </StyledStep>
  );
};

SelectAdUserStep.propTypes = {
  isLoading: PropTypes.bool,
  isLoadingSearchResults: PropTypes.bool,
  areMatchesFound: PropTypes.bool,
  setAreMatchesFound: PropTypes.func,
  checkApiForDupes: PropTypes.func,
  selectedHenselPhelpsEmployee: PropTypes.any
};

export default SelectAdUserStep;
