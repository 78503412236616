import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { useDropzone } from 'react-dropzone';
import { useMutation } from '@apollo/react-hooks';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import ErrorIcon from '@mui/icons-material/Error';
import { Typography, Grid, Avatar, IconButton, Link } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import StyledDialog from 'shared/Dialog';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import StyledButtonSecondary from 'shared/Buttons/ButtonSecondary';
import useToast from 'hooks/useToast';
import { UPDATE_ATTENDEE, GET_ATTENDEE } from 'graphql/attendee';
import { MAX_FILE_SIZE_IN_BYTES } from 'constants/maxFileSize';

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(4, 8),
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(2)
    }
  },
  avatar: {
    minWidth: theme.spacing(24),
    minHeight: theme.spacing(24),
    maxWidth: theme.spacing(24),
    maxHeight: theme.spacing(24),
    backgroundColor: theme.palette.background.contrastText,
    [theme.breakpoints.down('md')]: {
      objectFit: 'cover',
      height: theme.spacing(24),
      width: 'auto'
    }
  },
  errorText: {
    color: theme.palette.error.main
  },
  icon: { width: 24, height: 24, color: theme.palette.background.contrastText },
  iconButton: { marginBottom: theme.spacing(1) },
  text: { display: 'inline' },
  link: {
    color: theme.palette.secondary.contrastText,
    '&:hover': { textDecoration: 'none', cursor: 'pointer' }
  }
}));

const UpdateAttendeePictureDialog = ({
  attendee,
  isChangeAttendeePictureOpen,
  setIsChangeAttendeePictureOpen
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { displayToast } = useToast();
  const [updatedProfileImage, setUpdatedProfileImage] = useState();
  const [imageUploadError, setImageUploadError] = useState(null);
  const [updateAttendee, { loading: isLoading }] = useMutation(UPDATE_ATTENDEE);

  const [
    deleteAttendeeProfileImage,
    { loading: deleteIsLoading }
  ] = useMutation(UPDATE_ATTENDEE, {
    update(cache, { data: { updateAttendee: attendeeToUpsert } }) {
      const attendeeExists = !!cache?.data?.data?.ROOT_QUERY?.[
        `attendee({"id":"${attendeeToUpsert.id}"})`
      ];
      let existingAttendee = {};
      if (attendeeExists) {
        const { attendee } = cache.readQuery({
          query: GET_ATTENDEE,
          variables: { id: attendeeToUpsert.id }
        });
        existingAttendee = attendee;
      }
      cache.writeQuery({
        query: GET_ATTENDEE,
        variables: { id: attendeeToUpsert.id },
        data: {
          attendee: {
            ...existingAttendee,
            ...attendeeToUpsert
          }
        }
      });
    }
  });

  const { getInputProps, open } = useDropzone({
    accept: 'image/*',
    multiple: false,
    maxSize: MAX_FILE_SIZE_IN_BYTES,
    maxFiles: 1,
    onDropAccepted: acceptedFile => {
      setImageUploadError(null);
      validateFiles(acceptedFile[0]);
    },
    onDropRejected: rejectedFile => {
      const errorMessage = rejectedFile[0].errors[0].code;
      if (errorMessage === 'file-too-large') {
        setImageUploadError(
          t('addFile.largeFileError', {
            fileSize: Intl.NumberFormat(i18next.language, {
              style: 'unit',
              unit: 'megabyte'
            }).format(MAX_FILE_SIZE_IN_BYTES / 1000000)
          })
        );
        return;
      }
      setImageUploadError(t('addFile.generalFileUploadError'));
    }
  });

  const validateFiles = fileToCheck => {
    const formattedFile = formatFile(fileToCheck);
    setUpdatedProfileImage(formattedFile);
    return;
  };

  const formatFile = file => {
    const fileWithPreview = generateFilePreviews([file]);
    if (!file.path) {
      Object.assign(fileWithPreview[0], {
        path: file.name
      });
    }
    return fileWithPreview[0];
  };

  const generateFilePreviews = files => {
    const filesWithPreviews = files.map(file => {
      if (file) {
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        });
        return file;
      }
      return files;
    });
    return filesWithPreviews;
  };

  const handleSubmit = () => {
    updateAttendee({
      variables: {
        input: {
          id: attendee.id,
          personnelId: attendee.personnel?.id,
          profileImage: updatedProfileImage
        }
      }
    })
      .then(({ data: { updateAttendee: attendee } }) => {
        displayToast(
          t('updateAttendeePictureDialog.toasts.update.success'),
          'success'
        );
        handleClose();
      })
      .catch(error => {
        displayToast(
          t('updateAttendeePictureDialog.toasts.update.error'),
          'error'
        );
      });
  };

  const handleClose = () => {
    setIsChangeAttendeePictureOpen(false);
    deleteLocalImage(updatedProfileImage);
  };

  const handleDelete = () => {
    setImageUploadError(null);
    deleteAttendeeProfileImage({
      variables: {
        input: {
          id: attendee.id,
          shouldDeleteProfileImage: true
        }
      }
    })
      .then(({ data: { updateAttendee: attendee } }) => {
        displayToast(
          t('updateAttendeePictureDialog.toasts.delete.success'),
          'success'
        );
        handleClose();
      })
      .catch(error => {
        displayToast(
          t('updateAttendeePictureDialog.toasts.delete.error'),
          'error'
        );
      });
  };

  const deleteLocalImage = () => {
    if (updatedProfileImage?.preview) {
      URL.revokeObjectURL(updatedProfileImage.preview);
    }
    setUpdatedProfileImage(null);
  };

  return (
    <StyledDialog
      title={t('updateAttendeePictureDialog.title')}
      isOpen={isChangeAttendeePictureOpen}
      handleClose={handleClose}
      disableBackdropClick={false}
      disableEscapeKeyDown={false}
      maxWidth="sm"
      isLoading={isLoading || deleteIsLoading}
      content={
        <Grid
          container
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="center"
          className={classes.container}>
          <Grid item>
            {attendee?.profileImage?.cachedUrl &&
              !updatedProfileImage &&
              !attendee?.profileImage?.isMalware && (
                <Avatar
                  className={classes.avatar}
                  src={attendee.profileImage.cachedUrl}></Avatar>
              )}
            {!attendee?.profileImage?.cachedUrl &&
              !updatedProfileImage &&
              !attendee?.profileImage?.isMalware && (
                <Avatar className={classes.avatar}></Avatar>
              )}
            {!attendee?.profileImage?.cachedUrl &&
              !updatedProfileImage &&
              attendee?.profileImage?.isMalware && (
                <Avatar className={classes.avatar}>
                  <ErrorIcon
                    className={classes.avatar}
                    data-testid="malware-icon"
                  />
                </Avatar>
              )}
            {updatedProfileImage && (
              <Avatar
                className={classes.avatar}
                src={updatedProfileImage.preview}></Avatar>
            )}
          </Grid>
          <Grid item>
            <Grid container direction="column">
              <Grid item>
                <Grid container direction="row" alignItems="center">
                  <Grid item>
                    <input {...getInputProps()} />
                    <IconButton className={classes.iconButton} onClick={open} size="large">
                      <AddAPhotoIcon className={classes.icon} />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <Link
                      className={classes.link}
                      aria-label={'capture or upload user image'}
                      tabIndex="0"
                      onKeyDown={open}
                      onClick={open}
                      underline="hover">
                      <Typography className={classes.text}>
                        {t('updateAttendeePictureDialog.actions.addPicture')}
                      </Typography>
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
              {attendee.profileImage && !updatedProfileImage && (
                <Grid item>
                  <Grid container direction="row" alignItems="center">
                    <Grid item>
                      <input {...getInputProps()} />
                      <IconButton
                        className={classes.iconButton}
                        onClick={() =>
                          updatedProfileImage
                            ? deleteLocalImage(updatedProfileImage)
                            : handleDelete()
                        }
                        size="large">
                        <DeleteForeverOutlinedIcon className={classes.icon} />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <Link
                        className={classes.link}
                        aria-label={'remove uploaded image'}
                        tabIndex="0"
                        onKeyDown={() =>
                          updatedProfileImage
                            ? deleteLocalImage(updatedProfileImage)
                            : handleDelete()
                        }
                        onClick={() =>
                          updatedProfileImage
                            ? deleteLocalImage(updatedProfileImage)
                            : handleDelete()
                        }
                        underline="hover">
                        <Typography className={classes.text}>
                          {t(
                            'updateAttendeePictureDialog.actions.removePicture'
                          )}
                        </Typography>
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {imageUploadError && (
                <Grid item>
                  <p className={classes.errorText}>{imageUploadError}</p>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      }
      actions={
        <Grid container justifyContent="space-between">
          <Grid item>
            <StyledButtonSecondary
              label={t('updateAttendeePictureDialog.actions.cancel')}
              onClick={handleClose}
              disabled={isLoading || deleteIsLoading}
            />
          </Grid>
          <Grid item>
            <StyledButtonPrimary
              label={t('updateAttendeePictureDialog.actions.save')}
              onClick={handleSubmit}
              disabled={!updatedProfileImage || isLoading || deleteIsLoading}
            />
          </Grid>
        </Grid>
      }
    />
  );
};

UpdateAttendeePictureDialog.propTypes = {
  attendee: PropTypes.object.isRequired,
  isChangeAttendeePictureOpen: PropTypes.bool.isRequired,
  setIsChangeAttendeePictureOpen: PropTypes.func.isRequired
};

export default UpdateAttendeePictureDialog;
