export const ENTERPRISE_ADMIN = 'enterprise-admin';
export const DEFAULT = 'default';
export const SUPPORT = 'support';
export const ROAMING_ADMIN = 'roaming-admin';
export const AHA_ADMIN = 'aha-admin';
export const CANARY = 'canary';
export const SAFETY_DIRECTOR = 'safety-director';

const roles = [
  ENTERPRISE_ADMIN,
  DEFAULT,
  SUPPORT,
  ROAMING_ADMIN,
  AHA_ADMIN,
  CANARY,
  SAFETY_DIRECTOR
];

export default roles;
