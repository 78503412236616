import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import {
  Grid,
  Typography,
  Select,
  MenuItem,
  InputLabel
} from '@mui/material';

import StyledDialog from 'shared/Dialog';
import StyledButtonSecondary from 'shared/Buttons/ButtonSecondary';
import FlagCard from './FlagCard';

const useStyles = makeStyles(theme => ({
  select: { minWidth: 200 }
}));

const PersonnelFlagDetailsDialog = ({
  tradePartnerPersonnel,
  isFlagDetailsOpen,
  setIsFlagDetailsOpen,
  selectedFilter = 'all',
  setSelectedFilter
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const getFilteredFlags = () => {
    return (
      tradePartnerPersonnel?.personnel?.flags?.filter(
        flag => flag?.status?.toLowerCase() === selectedFilter
      ) ?? []
    );
  };

  const flags =
    selectedFilter === 'all'
      ? tradePartnerPersonnel?.personnel?.flags ?? []
      : getFilteredFlags();

  const handleClose = () => {
    setIsFlagDetailsOpen(false);
    setSelectedFilter('');
  };

  return <>
    <StyledDialog
      data-testid="personnel-flag-details-dialog"
      isOpen={isFlagDetailsOpen}
      handleClose={handleClose}
      title={t('personnelFlagDetailsDialog.title')}
      content={
        <>
          <Grid container>
            <Grid item>
              <InputLabel>
                {t('personnelFlagDetailsDialog.statusSelect.title')}
              </InputLabel>
              <Select
                inputProps={{ 'data-testid': 'filter-select' }}
                className={classes.select}
                margin="dense"
                variant="outlined"
                value={selectedFilter}
                onChange={({ target: { value } }) =>
                  setSelectedFilter(value)
                }>
                <MenuItem value={'high'}>
                  {t(
                    'personnelFlagDetailsDialog.statusSelect.options.attentionRequired'
                  )}
                </MenuItem>
                <MenuItem value={'toimprove'}>
                  {t(
                    'personnelFlagDetailsDialog.statusSelect.options.toImprove'
                  )}
                </MenuItem>
                <MenuItem value={'resolved'}>
                  {t(
                    'personnelFlagDetailsDialog.statusSelect.options.resolved'
                  )}
                </MenuItem>
                <MenuItem value={'achievement'}>
                  {t(
                    'personnelFlagDetailsDialog.statusSelect.options.achievement'
                  )}
                </MenuItem>
                <MenuItem value={'all'}>
                  {t('personnelFlagDetailsDialog.statusSelect.options.all')}
                </MenuItem>
              </Select>
            </Grid>
          </Grid>
          {flags.length >= 1 && (
            <>
              {flags.map(flag => (
                <FlagCard
                  key={flag.id}
                  flag={flag}
                  tradePartnerPersonnel={tradePartnerPersonnel}
                />
              ))}
            </>
          )}
          {flags.length < 1 && (
            <Grid item>
              <Typography className="margin-top">
                {t('personnelFlagDetailsDialog.noFlagsNotice')}
              </Typography>
            </Grid>
          )}
        </>
      }
      actions={
        <Grid container justifyContent="flex-start">
          <Grid item>
            <StyledButtonSecondary
              label={t('personnelFlagDetailsDialog.actions.close')}
              onClick={handleClose}
            />
          </Grid>
        </Grid>
      }
    />
  </>;
};

PersonnelFlagDetailsDialog.propTypes = {
  tradePartnerPersonnel: PropTypes.object.isRequired,
  isFlagDetailsOpen: PropTypes.bool.isRequired,
  setIsFlagDetailsOpen: PropTypes.func.isRequired,
  selectedFilter: PropTypes.string,
  setSelectedFilter: PropTypes.func.isRequired
};

export default PersonnelFlagDetailsDialog;
