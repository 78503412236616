import useRoles from 'hooks/useRoles';
import { useEffect, useState } from 'react';
import { useCurrentUserState } from 'recoil/currentUser';
import { useMsal } from '@azure/msal-react';
import useGraphApi from 'hooks/useGraphApi';
import useFetchUserPhoto from 'hooks/useFetchUserPhoto';
import useSoteriaQuery from 'hooks/useSoteriaQuery';
import { GET_SOTERIA_USER } from '../../graphql/soteriaAdUser';
import { domain, Domain } from 'config/domain';

const HOME_DISTRICT_FIELD =
  'extension_08cfd51d0e4a41128e563e87bd341c37_sPDistrictNumber';

const useSetCurrentUser = () => {
  const [, { setCurrentUserState }] = useCurrentUserState();
  const { roles } = useRoles();
  const [adUser, setAdUser] = useState(undefined);
  const { instance: msalInstance } = useMsal();
  const activeAccount = msalInstance?.getActiveAccount();
  const localAccountId = activeAccount?.localAccountId;
  const { isLoading: graphApiIsLoading, response, error } = useGraphApi({
    path: `users/${localAccountId}?$select=id,displayName,${HOME_DISTRICT_FIELD}`,
    skip: !localAccountId
  });
  const { thumbnailUrl } = useFetchUserPhoto(localAccountId);
  const { data: sadUserData, error: sadUserError } = useSoteriaQuery({
    gql: GET_SOTERIA_USER
  });
  const sadUser = sadUserData?.currentSoteriaAdUser;

  if (sadUserError) {
    console.error('Error retrieving user from the API: ', error);
  }

  useEffect(() => {
    if (error) {
      console.error(
        'Error trying to retrieve AD user info from microsoft graph api: ',
        error
      );
    } else if (!graphApiIsLoading && response) {
      response.json().then(responseJson => setAdUser(responseJson));
    }
  }, [graphApiIsLoading, response, error, setAdUser]);

  useEffect(() => {
    if (adUser || (domain === Domain.PARTNER && sadUser)) {
      const user = {
        isLoading: false,
        profilePicture: thumbnailUrl,
        displayName: adUser?.displayName ?? sadUser.name,
        homeDistrict: Number.parseInt(adUser?.[HOME_DISTRICT_FIELD]),
        id: adUser?.id ?? sadUser.microsoftAdId,
        roles: roles,
        upn: sadUser?.upn,
        email: sadUser?.email,
        isLoaded: !!sadUser,
        projects: sadUser?.projects ?? [],
        safeProjects: sadUser?.safeProjects ?? [],
        safeProjectsAsPartner: sadUser?.safeProjectsAsPartner ?? [],
        jobTitle: sadUser?.jobTitle ?? null
      };
      setCurrentUserState(user);
    }
  }, [adUser, sadUser, roles, thumbnailUrl, setCurrentUserState]);
};

export default useSetCurrentUser;
