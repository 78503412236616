import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classnames from 'classnames';
import EditIcon from '@mui/icons-material/Edit';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, IconButton, Typography } from '@mui/material';

import EditAhaTemplateInformationDialog from 'components/ahas/EditAhaTemplateInformationDialog';

const useStyles = makeStyles(theme => ({
  row: {
    borderTop: '1px solid' + theme.borderGray,
    minHeight: 30,
    height: 'auto'
  },
  rowRemoved: { borderTop: 'none' },
  notesRow: {
    borderTop: '1px solid' + theme.borderGray,
    minHeight: 89,
    height: 'auto',
    [theme.breakpoints.down('lg')]: { minHeight: 0 }
  },
  rowText: {
    paddingLeft: theme.spacing(2),
    fontSize: '0.875rem',
    marginTop: theme.spacing(0.5),
    [theme.breakpoints.down('lg')]: { paddingLeft: 4 }
  },
  rowTextDisabled: {
    color: theme.palette.background.disabledText
  },
  reviewApprovers: {
    fontWeight: 'bold'
  },
  rejectedReview: {
    color: theme.palette.error.main,
    fontWeight: 'bold'
  }
}));

const ProjectInformation = ({
  ahaTemplate,
  setAhaTemplate,
  isGlobal,
  isReadOnlyReview,
  ahaReview
}) => {
  const classes = useStyles();
  const [
    isEditProjectInfoDialogOpen,
    setIsEditProjectInfoDialogOpen
  ] = useState(false);

  // TODO: Troubleshoot ahaTemplate not coming back with ownedBy -> propbably stripped from hasChanges logic

  return <>
    <Grid container>
      {!isReadOnlyReview && (
        <Grid item xs={12}>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <IconButton
                size="small"
                onClick={() => setIsEditProjectInfoDialogOpen(true)}>
                <EditIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid
        item
        xs={12}
        className={classnames(classes.row, {
          [`${classes.rowRemoved}`]: isReadOnlyReview
        })}>
        <Typography color="textPrimary" className={classes.rowText}>
          Activity/Work Task:{' '}
          {ahaReview
            ? ahaReview.ahaProjectTemplate?.ahaTemplate?.type.toUpperCase()
            : ahaTemplate?.type.toUpperCase()}
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.row}>
        <Typography
          color="textPrimary"
          className={classnames(classes.rowText, {
            [`${classes.rowTextDisabled}`]: isGlobal
          })}>
          Project Location:{' '}
          {isGlobal
            ? 'N/A'
            : ahaReview
            ? ahaReview.project?.name
            : ahaTemplate?.ahaProjectTemplate?.location}
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.row}>
        <Typography
          color="textPrimary"
          className={classnames(classes.rowText, {
            [`${classes.rowTextDisabled}`]: isGlobal
          })}>
          Contractor:{' '}
          {isGlobal
            ? 'N/A'
            : ahaReview
            ? ahaReview.ahaProjectTemplate?.contractor
            : ahaTemplate?.ahaProjectTemplate?.contractor}
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.row}>
        <Typography
          color="textPrimary"
          className={classnames(classes.rowText, {
            [`${classes.rowTextDisabled}`]: isGlobal
          })}>
          Date Prepared:{' '}
          {isGlobal
            ? 'N/A'
            : ahaReview
            ? moment(ahaReview.created).format('MM/DD/YYYY')
            : moment(ahaTemplate?.created).format('MM/DD/YYYY')}
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.row}>
        <Typography
          color="textPrimary"
          className={classnames(classes.rowText, {
            [`${classes.rowTextDisabled}`]: isGlobal
          })}>
          Prepared By:{' '}
          {ahaReview
            ? `${ahaReview.preparedBy?.name} (${ahaReview.preparedBy?.jobTitle})`
            : ''}
        </Typography>
      </Grid>
      {isReadOnlyReview && ahaReview && (
        <Grid item xs={12} className={classes.row}>
          <Typography color="textPrimary" className={classes.rowText}>
            Reviewed By:{' '}
            {ahaReview?.status !== 'Rejected' && (
              <span className={classes.reviewApprovers}>
                {ahaReview.approvers
                  .map(approver => `${approver.name} (${approver.jobTitle})`)
                  .join('; ')}
              </span>
            )}
            {ahaReview?.status === 'Rejected' && (
              <span className={classes.rejectedReview}>Rejected</span>
            )}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12} className={classes.notesRow}>
        <Typography
          color="textPrimary"
          className={classnames(classes.rowText, {
            [`${classes.rowTextDisabled}`]: isGlobal
          })}>
          Notes:{' '}
          {isGlobal
            ? 'N/A'
            : ahaReview
            ? ahaReview.ahaProjectTemplate?.notes
            : ahaTemplate?.ahaProjectTemplate?.notes}
        </Typography>
      </Grid>
    </Grid>
    <EditAhaTemplateInformationDialog
      isGlobal={isGlobal}
      ahaTemplate={ahaTemplate}
      setAhaTemplate={setAhaTemplate}
      isOpen={isEditProjectInfoDialogOpen}
      setIsOpen={setIsEditProjectInfoDialogOpen}
    />
  </>;
};

ProjectInformation.propTypes = {
  ahaTemplate: PropTypes.object,
  setAhaTemplate: PropTypes.func,
  isGlobal: PropTypes.bool,
  isReadOnlyReview: PropTypes.bool,
  ahaReview: PropTypes.object
};

export default ProjectInformation;
