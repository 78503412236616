import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import ErrorIcon from '@mui/icons-material/Error';
import makeStyles from '@mui/styles/makeStyles';
import { Typography, Grid, IconButton, Avatar } from '@mui/material';

import UpdatePersonnelPictureDialog from 'components/personnel/UpdatePersonnelPictureDialog';

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: '2rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.5rem'
    }
  },
  avatarContainer: {
    marginLeft: theme.spacing(1.5),
    [theme.breakpoints.down('md')]: {
      marginLeft: 0
    }
  },
  avatar: {
    position: 'relative',
    width: theme.spacing(18),
    height: theme.spacing(18),
    [theme.breakpoints.down('md')]: {
      width: theme.spacing(12),
      height: theme.spacing(12)
    }
  },
  placeholderAvatar: {
    position: 'relative',
    width: theme.spacing(18),
    height: theme.spacing(18),
    backgroundColor: theme.palette.background.contrastText,
    [theme.breakpoints.down('md')]: {
      width: theme.spacing(12),
      height: theme.spacing(12)
    }
  },
  iconButton: {
    position: 'absolute',
    top: 270,
    left: 160,
    zIndex: 2,
    backgroundColor: 'white',
    color: 'black',
    [theme.breakpoints.up('sm')]: {
      top: 270,
      left: 160
    },
    [theme.breakpoints.down('md')]: { top: 190, left: 85 },
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText
    },
    boxShadow: ' 0 2px 2px 0 rgba(0,0,0,0.14)'
  },
  container: { marginBottom: theme.spacing(1) },
  personnelHeadingTextContainer: {
    marginTop: '2rem',
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(1),
      maxWidth: 230,
      overflowWrap: 'break-word'
    }
  },
  personnelHeadingText: { fontSize: '1.25rem' }
}));

const PersonnelHeader = ({ tradePartnerPersonnel }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [
    isChangePersonnelPictureOpen,
    setIsChangePersonnelPictureOpen
  ] = useState(false);

  const handleChangePictureClick = () => {
    setIsChangePersonnelPictureOpen(true);
  };
  return (
    <>
      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          spacing={3}
          alignItems="center"
          className={classes.container}>
          <Grid item className={classes.avatarContainer}>
            {tradePartnerPersonnel?.personnel?.profileImage?.cachedUrl &&
              !tradePartnerPersonnel?.personnel?.profileImage?.isMalware && (
                <>
                  <Avatar
                    alt={t('personnelHeader.profileImage', {
                      personnelName: tradePartnerPersonnel.personnel.name
                    })}
                    className={classes.avatar}
                    src={
                      tradePartnerPersonnel.personnel.profileImage.cachedUrl
                    }></Avatar>
                  <IconButton
                    id={t('personnelHeader.actions.editButton')}
                    title={t('personnelHeader.actions.editButtonTitle')}
                    aria-labelledby={t('personnelHeader.actions.editButton')}
                    size="small"
                    classes={{ root: classes.iconButton }}
                    onClick={handleChangePictureClick}>
                    <EditIcon />
                  </IconButton>
                </>
              )}
            {!tradePartnerPersonnel?.personnel?.profileImage?.cachedUrl &&
              !tradePartnerPersonnel?.personnel?.profileImage?.isMalware && (
                <>
                  <Avatar
                    alt={t('personnelHeader.profileImagePlaceholder')}
                    className={classes.placeholderAvatar}></Avatar>
                  <IconButton
                    size="small"
                    classes={{ root: classes.iconButton }}
                    onClick={handleChangePictureClick}>
                    <AddIcon />
                  </IconButton>
                </>
              )}
            {!tradePartnerPersonnel?.personnel?.profileImage?.cachedUrl &&
              tradePartnerPersonnel?.personnel?.profileImage?.isMalware && (
                <>
                  <Avatar
                    alt={t('personnelHeader.profileImagePlaceholder')}
                    className={classes.placeholderAvatar}>
                    <ErrorIcon
                      data-testid="malware-icon"
                      className={classes.placeholderAvatar}
                    />
                  </Avatar>
                  <IconButton
                    aria-labelledby={t('personnelHeader.actions.addButton')}
                    size="small"
                    classes={{ root: classes.iconButton }}
                    onClick={handleChangePictureClick}>
                    <AddIcon />
                  </IconButton>
                </>
              )}
          </Grid>
          <Grid item className={classes.personnelHeadingTextContainer}>
            <Typography
              color="textPrimary"
              className={classes.personnelHeadingText}>
              {`${tradePartnerPersonnel?.personnel.fullName}`}
              {tradePartnerPersonnel?.personnel.nickname
                ? ` (${tradePartnerPersonnel?.personnel.nickname})`
                : ''}
            </Typography>
            {tradePartnerPersonnel.customId && (
              <Typography
                color="textPrimary"
                className={classes.personnelHeadingText}>
                {tradePartnerPersonnel.customId}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
      <UpdatePersonnelPictureDialog
        tradePartnerPersonnel={tradePartnerPersonnel}
        isChangePersonnelPictureOpen={isChangePersonnelPictureOpen}
        setIsChangePersonnelPictureOpen={setIsChangePersonnelPictureOpen}
      />
    </>
  );
};

PersonnelHeader.propTypes = {
  tradePartnerPersonnel: PropTypes.object.isRequired
};

export default PersonnelHeader;
