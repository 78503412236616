import gql from 'graphql-tag';

// TODO: add back in when BE subscription is done
// export const MAINTENANCE_MODE_SUBSCRIPTION = gql`
//   subscription MaintenanceStatusSubscription {
//     MaintenanceStatusSubscription {
//       isMaintenanceModeOn
//     }
//   }
// `;

export const GET_MAINTENANCE_MODE_STATUS = gql`
  query MaintenanceStatusQuery {
    MaintenanceStatusQuery {
      isMaintenanceModeOn
    }
  }
`;
