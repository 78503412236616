import { useQuery } from '@apollo/react-hooks';
import {
  FILTER_API_KEYS,
  HENSEL_PHELPS_FILTER_OPTIONS,
  HENSEL_PHELPS_SORT_ORDER_OPTIONS,
  SORT_ORDER_DIRECTION
} from 'constants/ahas';
import { GET_PAGINATED_PROJECT_TEMPLATES } from 'graphql/aha/projectTemplate';
import useDebounce from 'hooks/useDebounce';
import moment from 'moment';
import { useState } from 'react';
import useHenselPhelpsAhaListState from 'store/henselPhelpsAhaListState';

const useAhaProjectTemplates = (projectId, shouldSkip = false) => {
  const [
    henselPhelpsAhaListState,
    { handleHenselPhelpsAhaListStateChange }
  ] = useHenselPhelpsAhaListState();
  const [initialHenselPhelpsAhaListState] = useState(henselPhelpsAhaListState);

  const [activeFilters, setActiveFilters] = useState({
    hpModifiedLastThirtyDays:
      henselPhelpsAhaListState.filter?.lastModifiedAfter ===
      HENSEL_PHELPS_FILTER_OPTIONS.hpModifiedLastThirtyDays.value,
    hpModifiedLastOverThirtyDays:
      henselPhelpsAhaListState.filter?.lastModifiedBefore ===
      HENSEL_PHELPS_FILTER_OPTIONS.hpModifiedLastOverThirtyDays.value,
    hpModifiedLastOverFortyFiveDays:
      henselPhelpsAhaListState.filter?.lastModifiedBefore ===
      HENSEL_PHELPS_FILTER_OPTIONS.hpModifiedLastOverFortyFiveDays.value,
    hpModifiedLastOverSixtyDays:
      henselPhelpsAhaListState.filter?.lastModifiedBefore ===
      HENSEL_PHELPS_FILTER_OPTIONS.hpModifiedLastOverSixtyDays.value,
    hpModifiedLastAllTime:
      henselPhelpsAhaListState.filter?.lastModifiedBefore ===
      HENSEL_PHELPS_FILTER_OPTIONS.hpModifiedLastAllTime.value,
    recordStatusPending:
      // refers to latest review status
      henselPhelpsAhaListState.filter?.ahaStatus?.includes(
        HENSEL_PHELPS_FILTER_OPTIONS.recordStatusPending.value
      ) ?? false,
    recordStatusAccepted:
      // refers to latest review status
      henselPhelpsAhaListState.filter?.ahaStatus?.includes(
        HENSEL_PHELPS_FILTER_OPTIONS.recordStatusAccepted.value
      ) ?? false,
    recordStatusRejected:
      // refers to latest review status
      henselPhelpsAhaListState.filter?.ahaStatus?.includes(
        HENSEL_PHELPS_FILTER_OPTIONS.recordStatusRejected.value
      ) ?? false,
    recordStatusAll:
      // refers to latest review status
      henselPhelpsAhaListState.filter?.ahaStatus?.includes(
        HENSEL_PHELPS_FILTER_OPTIONS.recordStatusAll.value
      ) ?? false,
    ahaStatusActive:
      henselPhelpsAhaListState.filter?.recordStatus === // refers to ahaStatus
      HENSEL_PHELPS_FILTER_OPTIONS.ahaStatusActive.value,
    ahaStatusInactive:
      henselPhelpsAhaListState.filter?.recordStatus === // refers to ahaStatus
      HENSEL_PHELPS_FILTER_OPTIONS.ahaStatusInactive.value,
    ahaStatusAll:
      henselPhelpsAhaListState.filter?.recordStatus === // refers to ahaStatus
      HENSEL_PHELPS_FILTER_OPTIONS.ahaStatusAll.value,
    hasAttachment:
      henselPhelpsAhaListState.filter?.hasAttachment ===
      HENSEL_PHELPS_FILTER_OPTIONS.hasAttachment?.value,
    hasNoAttachments:
      henselPhelpsAhaListState.filter?.hasAttachment ===
      HENSEL_PHELPS_FILTER_OPTIONS.hasNoAttachments.value,
    hasOrHasNoAttachments:
      henselPhelpsAhaListState.filter?.hasAttachment ===
      HENSEL_PHELPS_FILTER_OPTIONS.hasOrHasNoAttachments.value
  });

  const {
    data: { paginatedAhaProjectTemplates = null } = {},
    loading,
    fetchMore
  } = useQuery(GET_PAGINATED_PROJECT_TEMPLATES, {
    skip: shouldSkip,
    variables: {
      filter: getApiFilter(initialHenselPhelpsAhaListState.filter),
      first: initialHenselPhelpsAhaListState.first,
      order: getApiSortOrder(initialHenselPhelpsAhaListState.order),
      projectId,
      search: initialHenselPhelpsAhaListState.search,
      skip: 0
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  });

  const total = paginatedAhaProjectTemplates?.total ?? 0;

  const resetUpdateQuery = (
    _previousResult,
    { fetchMoreResult, _queryVariables }
  ) => {
    return fetchMoreResult;
  };

  const loadMore = (variables, shouldReset = false) => {
    return fetchMore({
      variables,
      updateQuery: shouldReset
        ? resetUpdateQuery
        : (previousResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return previousResult;
            return Object.assign({}, previousResult, {
              paginatedAhaProjectTemplates: {
                ...previousResult.paginatedAhaProjectTemplates,
                ...fetchMoreResult.paginatedAhaProjectTemplates,
                total: fetchMoreResult.paginatedAhaProjectTemplates.total,
                ahaProjectTemplates: [
                  ...previousResult.paginatedAhaProjectTemplates
                    .ahaProjectTemplates,
                  ...fetchMoreResult.paginatedAhaProjectTemplates
                    .ahaProjectTemplates
                ]
              }
            });
          }
    });
  };

  const handleDebouncedSearchChange = ({ search, state, handleChange }) => {
    handleChange({
      ...state,
      search,
      skip: 0
    });

    const variables = {
      first: state.first,
      skip: 0,
      filter: getApiFilter(state.filter),
      order: getApiSortOrder(state.order),
      search
    };

    loadMore(variables, true);
  };

  const { debounced: debouncedHandleSearchChange } = useDebounce(
    handleDebouncedSearchChange
  );

  const toggleSortDirection = () => {
    let newDirection;

    if (
      henselPhelpsAhaListState?.order?.direction ===
      SORT_ORDER_DIRECTION.ascending
    ) {
      newDirection = SORT_ORDER_DIRECTION.descending;
    } else {
      newDirection = SORT_ORDER_DIRECTION.ascending;
    }

    const newOrder = {
      ...henselPhelpsAhaListState.order,
      direction: newDirection
    };

    handleHenselPhelpsAhaListStateChange({
      ...henselPhelpsAhaListState,
      order: newOrder
    });

    const variables = {
      first: henselPhelpsAhaListState.first,
      skip: 0,
      search: henselPhelpsAhaListState.search,
      filter: getApiFilter(henselPhelpsAhaListState.filter),
      order: getApiSortOrder(newOrder)
    };

    loadMore(variables, true);
  };

  const handleSortChange = event => {
    const sortByValue = event.target.value;

    const newOrder = {
      ...henselPhelpsAhaListState.order,
      label: getSortByLabel(sortByValue),
      value: sortByValue
    };

    handleHenselPhelpsAhaListStateChange({
      ...henselPhelpsAhaListState,
      order: newOrder
    });

    const variables = {
      first: henselPhelpsAhaListState.first,
      skip: 0,
      search: henselPhelpsAhaListState.search,
      filter: getApiFilter(henselPhelpsAhaListState.filter),
      order: getApiSortOrder(newOrder)
    };

    loadMore(variables, true);
  };

  const handleFilterChange = filter => {
    let newFilter = {};

    switch (filter) {
      case HENSEL_PHELPS_FILTER_OPTIONS.hpModifiedLastThirtyDays.value: {
        const isActive = !activeFilters.hpModifiedLastThirtyDays;
        newFilter = {
          [HENSEL_PHELPS_FILTER_OPTIONS.hpModifiedLastThirtyDays
            .apiKey]: isActive
            ? HENSEL_PHELPS_FILTER_OPTIONS.hpModifiedLastThirtyDays.value
            : undefined,
          lastModifiedBefore: undefined
        };
        setActiveFilters({
          ...activeFilters,
          hpModifiedLastThirtyDays: isActive,
          hpModifiedLastOverThirtyDays: false,
          hpModifiedLastOverFortyFiveDays: false,
          hpModifiedLastOverSixtyDays: false,
          hpModifiedLastAllTime: false
        });

        break;
      }

      case HENSEL_PHELPS_FILTER_OPTIONS.hpModifiedLastOverThirtyDays.value: {
        const isActive = !activeFilters.hpModifiedLastOverThirtyDays;
        newFilter = {
          [HENSEL_PHELPS_FILTER_OPTIONS.hpModifiedLastOverThirtyDays
            .apiKey]: isActive
            ? HENSEL_PHELPS_FILTER_OPTIONS.hpModifiedLastOverThirtyDays.value
            : undefined,
          lastModifiedAfter: undefined
        };
        setActiveFilters({
          ...activeFilters,
          hpModifiedLastOverThirtyDays: isActive,
          hpModifiedLastThirtyDays: false,
          hpModifiedLastOverFortyFiveDays: false,
          hpModifiedLastOverSixtyDays: false,
          hpModifiedLastAllTime: false
        });

        break;
      }

      case HENSEL_PHELPS_FILTER_OPTIONS.hpModifiedLastOverFortyFiveDays.value: {
        const isActive = !activeFilters.hpModifiedLastOverFortyFiveDays;
        newFilter = {
          [HENSEL_PHELPS_FILTER_OPTIONS.hpModifiedLastOverFortyFiveDays
            .apiKey]: isActive
            ? HENSEL_PHELPS_FILTER_OPTIONS.hpModifiedLastOverFortyFiveDays.value
            : undefined,
          lastModifiedAfter: undefined
        };
        setActiveFilters({
          ...activeFilters,
          hpModifiedLastThirtyDays: false,
          hpModifiedLastOverThirtyDays: false,
          hpModifiedLastOverFortyFiveDays: isActive,
          hpModifiedLastOverSixtyDays: false,
          hpModifiedLastAllTime: false
        });
        break;
      }

      case HENSEL_PHELPS_FILTER_OPTIONS.hpModifiedLastOverSixtyDays.value: {
        const isActive = !activeFilters.hpModifiedLastOverSixtyDays;
        newFilter = {
          [HENSEL_PHELPS_FILTER_OPTIONS.hpModifiedLastOverSixtyDays
            .apiKey]: isActive
            ? HENSEL_PHELPS_FILTER_OPTIONS.hpModifiedLastOverSixtyDays.value
            : undefined,
          lastModifiedAfter: undefined
        };
        setActiveFilters({
          ...activeFilters,
          hpModifiedLastThirtyDays: false,
          hpModifiedLastOverThirtyDays: false,
          hpModifiedLastOverFortyFiveDays: false,
          hpModifiedLastOverSixtyDays: isActive,
          hpModifiedLastAllTime: false
        });
        break;
      }

      case HENSEL_PHELPS_FILTER_OPTIONS.hpModifiedLastAllTime.value: {
        const isActive = !activeFilters.hpModifiedLastAllTime;
        newFilter = {
          [HENSEL_PHELPS_FILTER_OPTIONS.hpModifiedLastAllTime.apiKey]: isActive
            ? HENSEL_PHELPS_FILTER_OPTIONS.hpModifiedLastAllTime.value
            : undefined,
          lastModifiedAfter: undefined
        };
        setActiveFilters({
          ...activeFilters,
          hpModifiedLastThirtyDays: false,
          hpModifiedLastOverThirtyDays: false,
          hpModifiedLastOverFortyFiveDays: false,
          hpModifiedLastOverSixtyDays: false,
          hpModifiedLastAllTime: isActive
        });
        break;
      }

      case HENSEL_PHELPS_FILTER_OPTIONS.recordStatusPending.value: {
        const isActive = !activeFilters.recordStatusPending;
        const currentStatuses =
          henselPhelpsAhaListState?.filter[
            HENSEL_PHELPS_FILTER_OPTIONS.recordStatusPending.apiKey
          ] ?? [];

        newFilter = {
          [HENSEL_PHELPS_FILTER_OPTIONS.recordStatusPending.apiKey]: isActive
            ? [
                ...currentStatuses,
                HENSEL_PHELPS_FILTER_OPTIONS.recordStatusPending.value
              ]
            : currentStatuses.filter(
                statusOption =>
                  statusOption !==
                  HENSEL_PHELPS_FILTER_OPTIONS.recordStatusPending.value
              )
        };
        setActiveFilters({
          ...activeFilters,
          recordStatusPending: isActive
        });
        break;
      }

      case HENSEL_PHELPS_FILTER_OPTIONS.recordStatusAccepted.value: {
        const isActive = !activeFilters.recordStatusAccepted;
        const currentStatuses =
          henselPhelpsAhaListState?.filter[
            HENSEL_PHELPS_FILTER_OPTIONS.recordStatusAccepted.apiKey
          ] ?? [];

        newFilter = {
          [HENSEL_PHELPS_FILTER_OPTIONS.recordStatusAccepted.apiKey]: isActive
            ? [
                ...currentStatuses,
                HENSEL_PHELPS_FILTER_OPTIONS.recordStatusAccepted.value
              ]
            : currentStatuses.filter(
                statusOption =>
                  statusOption !==
                  HENSEL_PHELPS_FILTER_OPTIONS.recordStatusAccepted.value
              )
        };
        setActiveFilters({
          ...activeFilters,
          recordStatusAccepted: isActive
        });
        break;
      }

      case HENSEL_PHELPS_FILTER_OPTIONS.recordStatusRejected.value: {
        const isActive = !activeFilters.recordStatusRejected;
        const currentStatuses =
          henselPhelpsAhaListState?.filter[
            HENSEL_PHELPS_FILTER_OPTIONS.recordStatusRejected.apiKey
          ] ?? [];

        newFilter = {
          [HENSEL_PHELPS_FILTER_OPTIONS.recordStatusRejected.apiKey]: isActive
            ? [
                ...currentStatuses,
                HENSEL_PHELPS_FILTER_OPTIONS.recordStatusRejected.value
              ]
            : currentStatuses.filter(
                statusOption =>
                  statusOption !==
                  HENSEL_PHELPS_FILTER_OPTIONS.recordStatusRejected.value
              )
        };
        setActiveFilters({
          ...activeFilters,
          recordStatusRejected: isActive
        });
        break;
      }

      case HENSEL_PHELPS_FILTER_OPTIONS.recordStatusAll.value: {
        const isActive = !activeFilters.recordStatusAll;
        const currentStatuses =
          henselPhelpsAhaListState?.filter[
            HENSEL_PHELPS_FILTER_OPTIONS.recordStatusAll.apiKey
          ] ?? [];

        newFilter = {
          [HENSEL_PHELPS_FILTER_OPTIONS.recordStatusAll.apiKey]: isActive
            ? [
                ...currentStatuses,
                HENSEL_PHELPS_FILTER_OPTIONS.recordStatusAll.value
              ]
            : currentStatuses.filter(
                statusOption =>
                  statusOption !==
                  HENSEL_PHELPS_FILTER_OPTIONS.recordStatusAll.value
              )
        };
        setActiveFilters({
          ...activeFilters,
          recordStatusPending: false,
          recordStatusAccepted: false,
          recordStatusRejected: false,
          recordStatusAll: isActive
        });
        break;
      }

      case HENSEL_PHELPS_FILTER_OPTIONS.ahaStatusActive.value: {
        const isActive = !activeFilters.ahaStatusActive;
        newFilter = {
          [HENSEL_PHELPS_FILTER_OPTIONS.ahaStatusActive.apiKey]: isActive
            ? HENSEL_PHELPS_FILTER_OPTIONS.ahaStatusActive.value
            : undefined
        };
        setActiveFilters({
          ...activeFilters,
          ahaStatusActive: isActive,
          ahaStatusInactive: false,
          ahaStatusAll: false
        });
        break;
      }

      case HENSEL_PHELPS_FILTER_OPTIONS.ahaStatusInactive.value: {
        const isActive = !activeFilters.ahaStatusInactive;
        newFilter = {
          [HENSEL_PHELPS_FILTER_OPTIONS.ahaStatusInactive.apiKey]: isActive
            ? HENSEL_PHELPS_FILTER_OPTIONS.ahaStatusInactive.value
            : undefined
        };
        setActiveFilters({
          ...activeFilters,
          ahaStatusActive: false,
          ahaStatusInactive: isActive,
          ahaStatusAll: false
        });
        break;
      }

      case HENSEL_PHELPS_FILTER_OPTIONS.ahaStatusAll.value: {
        const isActive = !activeFilters.ahaStatusAll;
        newFilter = {
          [HENSEL_PHELPS_FILTER_OPTIONS.ahaStatusAll.apiKey]: isActive
            ? HENSEL_PHELPS_FILTER_OPTIONS.ahaStatusAll.value
            : undefined
        };
        setActiveFilters({
          ...activeFilters,
          ahaStatusActive: false,
          ahaStatusInactive: false,
          ahaStatusAll: isActive
        });
        break;
      }

      case HENSEL_PHELPS_FILTER_OPTIONS.hasAttachment.value: {
        const isActive = !activeFilters.hasAttachment;
        newFilter = {
          [HENSEL_PHELPS_FILTER_OPTIONS.hasAttachment.apiKey]: isActive
            ? HENSEL_PHELPS_FILTER_OPTIONS.hasAttachment.value
            : undefined
        };
        setActiveFilters({
          ...activeFilters,
          hasAttachment: isActive,
          hasNoAttachments: false,
          hasOrHasNoAttachments: false
        });
        break;
      }

      case HENSEL_PHELPS_FILTER_OPTIONS.hasNoAttachments.value: {
        const isActive = !activeFilters.hasNoAttachments;
        newFilter = {
          [HENSEL_PHELPS_FILTER_OPTIONS.hasNoAttachments.apiKey]: isActive
            ? HENSEL_PHELPS_FILTER_OPTIONS.hasNoAttachments.value
            : undefined
        };
        setActiveFilters({
          ...activeFilters,
          hasAttachment: false,
          hasNoAttachments: isActive,
          hasOrHasNoAttachments: false
        });
        break;
      }

      case HENSEL_PHELPS_FILTER_OPTIONS.hasOrHasNoAttachments.value: {
        const isActive = !activeFilters.hasOrHasNoAttachments;
        newFilter = {
          [HENSEL_PHELPS_FILTER_OPTIONS.hasOrHasNoAttachments.apiKey]: isActive
            ? HENSEL_PHELPS_FILTER_OPTIONS.hasOrHasNoAttachments.value
            : undefined
        };
        setActiveFilters({
          ...activeFilters,
          hasAttachment: false,
          hasNoAttachments: false,
          hasOrHasNoAttachments: isActive
        });
        break;
      }

      default:
        break;
    }

    if (newFilter.latestReviewStatus?.length < 1) {
      newFilter.latestReviewStatus = undefined;
    }

    const newFilterState = {
      ...henselPhelpsAhaListState.filter,
      ...newFilter
    };

    handleHenselPhelpsAhaListStateChange({
      ...henselPhelpsAhaListState,
      filter: newFilterState
    });

    const variables = {
      first: henselPhelpsAhaListState.first,
      skip: 0,
      search: henselPhelpsAhaListState.search,
      filter: getApiFilter(newFilterState),
      order: getApiSortOrder(henselPhelpsAhaListState.order)
    };

    loadMore(variables, true);
  };

  return {
    projectTemplates: paginatedAhaProjectTemplates?.ahaProjectTemplates,
    total,
    loading,
    loadMore,
    handleSearch: debouncedHandleSearchChange,
    handleSortChange,
    toggleSortDirection,
    handleFilterChange
  };
};

function getApiFilter(filterState) {
  const filter = {};

  for (const [key, value] of Object.entries(filterState)) {
    switch (key) {
      case FILTER_API_KEYS.recordStatus:
        if (value === HENSEL_PHELPS_FILTER_OPTIONS.ahaStatusActive.value) {
          filter[HENSEL_PHELPS_FILTER_OPTIONS.ahaStatusActive.apiKey] = true;
        }
        if (value === HENSEL_PHELPS_FILTER_OPTIONS.ahaStatusInactive.value) {
          filter[HENSEL_PHELPS_FILTER_OPTIONS.ahaStatusInactive.apiKey] = false;
        }
        if (value === HENSEL_PHELPS_FILTER_OPTIONS.ahaStatusAll.value) {
          filter[HENSEL_PHELPS_FILTER_OPTIONS.ahaStatusAll.apiKey] = undefined;
        }
        break;

      case FILTER_API_KEYS.hasAttachment:
        if (value === HENSEL_PHELPS_FILTER_OPTIONS.hasAttachment.value) {
          filter[HENSEL_PHELPS_FILTER_OPTIONS.hasAttachment.apiKey] = true;
        }
        if (value === HENSEL_PHELPS_FILTER_OPTIONS.hasNoAttachments.value) {
          filter[HENSEL_PHELPS_FILTER_OPTIONS.hasNoAttachments.apiKey] = false;
        }
        if (
          value === HENSEL_PHELPS_FILTER_OPTIONS.hasOrHasNoAttachments.value
        ) {
          filter[
            HENSEL_PHELPS_FILTER_OPTIONS.hasOrHasNoAttachments.apiKey
          ] = undefined;
        }
        break;

      case FILTER_API_KEYS.ahaStatus:
        if (Array.isArray(value) && value.length > 0) {
          const selectedStatuses = [];
          if (
            value.includes(
              HENSEL_PHELPS_FILTER_OPTIONS.recordStatusPending.value
            )
          ) {
            selectedStatuses.push('Pending');
          }
          if (
            value.includes(
              HENSEL_PHELPS_FILTER_OPTIONS.recordStatusRejected.value
            )
          ) {
            selectedStatuses.push('Rejected');
          }
          if (
            value.includes(
              HENSEL_PHELPS_FILTER_OPTIONS.recordStatusAccepted.value
            )
          ) {
            selectedStatuses.push('Reviewed');
          }

          filter[FILTER_API_KEYS.ahaStatus] = selectedStatuses;
        } else {
          filter[FILTER_API_KEYS.ahaStatus] = undefined;
        }
        break;

      case FILTER_API_KEYS.lastModifiedAfter:
        if (
          value === HENSEL_PHELPS_FILTER_OPTIONS.hpModifiedLastThirtyDays.value
        ) {
          const thirtyDaysAgo = moment().subtract(30, 'days');
          filter[
            HENSEL_PHELPS_FILTER_OPTIONS.hpModifiedLastThirtyDays.apiKey
          ] = thirtyDaysAgo.startOf('day').toISOString();
        }
        break;

      case FILTER_API_KEYS.lastModifiedBefore:
        if (
          value ===
          HENSEL_PHELPS_FILTER_OPTIONS.hpModifiedLastOverThirtyDays.value
        ) {
          const thirtyDaysAgo = moment().subtract(30, 'days');
          filter[
            HENSEL_PHELPS_FILTER_OPTIONS.hpModifiedLastOverThirtyDays.apiKey
          ] = thirtyDaysAgo.startOf('day').toISOString();
        } else if (
          value ===
          HENSEL_PHELPS_FILTER_OPTIONS.hpModifiedLastOverFortyFiveDays.value
        ) {
          const fortyFiveDaysAgo = moment().subtract(45, 'days');
          filter[
            HENSEL_PHELPS_FILTER_OPTIONS.hpModifiedLastOverFortyFiveDays.apiKey
          ] = fortyFiveDaysAgo.startOf('day').toISOString();
        } else if (
          value ===
          HENSEL_PHELPS_FILTER_OPTIONS.hpModifiedLastOverSixtyDays.value
        ) {
          const sixtyDaysAgo = moment().subtract(60, 'days');
          filter[
            HENSEL_PHELPS_FILTER_OPTIONS.hpModifiedLastOverSixtyDays.apiKey
          ] = sixtyDaysAgo.startOf('day').toISOString();
        } else if (
          value === HENSEL_PHELPS_FILTER_OPTIONS.hpModifiedLastAllTime.value
        ) {
          filter.lastModifiedBefore = undefined;
        }
        break;
      case FILTER_API_KEYS.createdBefore:
        break;
      default:
        break;
    }
  }
  return filter;
}

function getApiSortOrder(sortState) {
  return sortState.value && sortState.direction
    ? [
        {
          [sortState.value]: sortState.direction.toUpperCase()
        }
      ]
    : null;
}

function getSortByLabel(value) {
  const sortBy = Object.values(HENSEL_PHELPS_SORT_ORDER_OPTIONS).find(
    option => option.value === value
  );

  return sortBy?.label ?? '';
}

export default useAhaProjectTemplates;
