import MyProjects from './projects/MyProjects';
import ViewAllProjects from './projects/ViewAllProjects';
import EditProjectDetails from './projects/EditProjectDetails';
import Roles from './permissions/Roles';
import AddOrRemoveAdmin from './permissions/AddOrRemoveAdmin';
import ApproveOrDenyAccessRequests from './permissions/ApproveOrDenyAccessRequests';
import AddToProject from './tradePartners/AddToProject';
import AddNewCompany from './companies/AddNewCompany';
import DeleteCompany from './companies/DeleteCompany';
import DeleteTradePartner from './tradePartners/DeleteTradePartner';
import SetTradePartnerStatus from './tradePartners/SetTradePartnerStatus';
import EnterWorkerHours from './workerHours/EnterWorkerHours';
import ViewProjectPersonnel from './personnel/ViewProjectPersonnel';
import ViewTradePartnerPersonnel from './personnel/ViewTradePartnerPersonnel';
import AddNewPersonnel from './personnel/AddPersonnel';
import AddFlag from './personnel/AddFlag';
import MergeDuplicateRecords from './personnel/MergeDuplicateRecords';
import ScheduleOrientation from './orientations/ScheduleOrientation';
import EditRecurringOrientation from './orientations/EditRecurringOrientation';
import CancelRecurringOrientation from './orientations/CancelRecurringOrientation';
import ManageAttendees from './orientations/ManageAttendees';
import EnableOfflineMode from './settings/EnableOfflineMode';
import { features } from 'constants/enabledFeatures';

export const tutorials = {
  companies: {
    menuTitle: 'tutorials.companies.menuTitle',
    sections: {
      addNewCompany: {
        menuTitle: 'tutorials.companies.addNewCompany.menuTitle',
        content: AddNewCompany
      },
      deleteCompany: {
        menuTitle: 'tutorials.companies.deleteCompany.menuTitle',
        content: DeleteCompany
      }
    }
  },
  orientations: {
    enabledFeature: features.orientations,
    menuTitle: 'tutorials.orientations.menuTitle',
    sections: {
      scheduleOrientations: {
        menuTitle: 'tutorials.orientations.scheduleOrientation.menuTitle',
        content: ScheduleOrientation
      },
      editRecurringOrientations: {
        menuTitle: 'tutorials.orientations.editRecurringOrientation.menuTitle',
        content: EditRecurringOrientation
      },
      cancelRecurringOrientations: {
        menuTitle:
          'tutorials.orientations.cancelRecurringOrientation.menuTitle',
        content: CancelRecurringOrientation
      },
      manageAttendees: {
        menuTitle: 'tutorials.orientations.manageAttendees.menuTitle',
        content: ManageAttendees
      }
    }
  },
  permissions: {
    menuTitle: 'tutorials.permissions.menuTitle',
    sections: {
      roles: {
        menuTitle: 'tutorials.permissions.roles.menuTitle',
        content: Roles
      },
      addOrRemoveAdmin: {
        menuTitle: 'tutorials.permissions.addOrRemoveAdmin.menuTitle',
        content: AddOrRemoveAdmin
      },
      approveOrDenyAccessRequests: {
        menuTitle:
          'tutorials.permissions.approveOrDenyAccessRequests.menuTitle',
        content: ApproveOrDenyAccessRequests
      }
    }
  },
  personnel: {
    enabledFeature: features.personnel,
    menuTitle: 'tutorials.personnel.menuTitle',
    sections: {
      viewProjectPersonnel: {
        menuTitle: 'tutorials.personnel.viewProjectPersonnel.menuTitle',
        content: ViewProjectPersonnel
      },
      viewTradePartnerPersonnel: {
        menuTitle: 'tutorials.personnel.viewTradePartnerPersonnel.menuTitle',
        content: ViewTradePartnerPersonnel
      },
      addNewPersonnel: {
        menuTitle: 'tutorials.personnel.addNewPersonnel.menuTitle',
        content: AddNewPersonnel
      },
      addFlag: {
        menuTitle: 'tutorials.personnel.addFlag.menuTitle',
        content: AddFlag
      },
      mergeDuplicateRecords: {
        menuTitle: 'tutorials.personnel.mergeDuplicateRecords.menuTitle',
        content: MergeDuplicateRecords
      }
    }
  },
  projects: {
    menuTitle: 'tutorials.projects.menuTitle',
    sections: {
      viewAllProjects: {
        menuTitle: 'tutorials.projects.viewAllProjects.menuTitle',
        content: ViewAllProjects
      },
      myProjects: {
        menuTitle: 'tutorials.projects.myProjects.menuTitle',
        content: MyProjects
      },
      editProjectDetails: {
        menuTitle: 'tutorials.projects.editProjectDetails.menuTitle',
        content: EditProjectDetails
      }
    }
  },
  settings: {
    menuTitle: 'tutorials.settings.menuTitle',
    sections: {
      enableOfflineMode: {
        menuTitle: 'tutorials.settings.enableOfflineMode.menuTitle',
        content: EnableOfflineMode
      }
    }
  },
  tradePartners: {
    menuTitle: 'tutorials.tradePartners.menuTitle',
    sections: {
      addToProject: {
        menuTitle: 'tutorials.tradePartners.addToProject.menuTitle',
        content: AddToProject
      },
      activateOrDeactivateTradePartner: {
        menuTitle:
          'tutorials.tradePartners.activateOrDeactivateTradePartner.menuTitle',
        content: SetTradePartnerStatus
      },
      removeFromProject: {
        menuTitle: 'tutorials.tradePartners.removeFromProject.menuTitle',
        content: DeleteTradePartner
      }
    }
  },
  workerHours: {
    menuTitle: 'tutorials.workerHours.menuTitle',
    sections: {
      enterWorkerHours: {
        menuTitle: 'tutorials.workerHours.enterWorkerHours.menuTitle',
        content: EnterWorkerHours
      }
    }
  }
};

export function getTitles(tutorials) {
  let titles = [];
  Object.keys(tutorials).forEach(categoryKey => {
    let category = {};
    category.menuTitle = tutorials[categoryKey].menuTitle;
    category.sections = [];
    Object.keys(tutorials[categoryKey].sections).forEach(sectionKey => {
      category.sections.push({
        name: sectionKey,
        menuTitle: tutorials[categoryKey].sections[sectionKey].menuTitle
      });
    });
    titles.push(category);
  });
  return titles;
}

export function getSections(tutorials) {
  let sections = {};
  Object.keys(tutorials).forEach(categoryKey => {
    Object.keys(tutorials[categoryKey].sections).forEach(sectionKey => {
      sections[sectionKey] =
        tutorials[categoryKey].sections[sectionKey].content;
    });
  });

  return sections;
}
