import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';

import StyledAutoComplete from 'shared/AutoComplete';
import { TRADE_SEARCH } from 'graphql/tradePartner';

const TradeSelect = ({
  selectedTrades,
  meta,
  handleChange,
  initialValues,
  isDisabled,
  ...rest
}) => {
  const { t } = useTranslation();
  const [inputValue, handleInputChange] = useState(null);

  const { data, loading } = useQuery(TRADE_SEARCH, {
    variables: { search: inputValue },
    fetchPolicy: 'no-cache'
  });
  const options = data?.trades ?? [];

  return (
    <StyledAutoComplete
      label={t('addTradePartnerDialog.steps.tradePartnerInfo.subTitle')}
      defaultValue={initialValues}
      selectedValue={selectedTrades}
      meta={meta}
      handleChange={handleChange}
      placeholder={t(
        'addTradePartnerDialog.steps.tradePartnerInfo.fields.trades'
      )}
      isRequired={true}
      isDisabled={isDisabled}
      options={options.map(item => ({ label: item, value: item }))}
      isLoading={loading}
      handleInputChange={handleInputChange}
      isCreatable
      isMulti={true}
      {...rest}
    />
  );
};

TradeSelect.propTypes = {
  selectedTrades: PropTypes.any,
  meta: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  initialValues: PropTypes.array,
  isDisabled: PropTypes.bool
};

export default TradeSelect;
