import React, { useState } from 'react';
import PropTypes from 'prop-types';
import LoadingOverlay from 'react-loading-overlay';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Card, Typography, IconButton } from '@mui/material';

import useSoteriaQuery from 'hooks/useSoteriaQuery';
import StyledDeleteConfirmation from 'shared/DeleteConfirmation';
import useToast from 'hooks/useToast';
import UpsertStep from 'components/ahas/UpsertStep';
import {
  GET_ALL_GLOBAL_STEPS_FOR_CATEGORY,
  DELETE_GLOBAL_AHA_STEP,
  GET_ALL_GLOBAL_STEP_CATEGORIES,
  GLOBAL_STEP_SEARCH
} from 'graphql/aha';
import useAhaGlobalStepListState from 'store/ahaGlobalStepListState';

const useStyles = makeStyles(theme => ({
  globalJobStepCard: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(0.3, 1),
    boxShadow: 'none',
    margin: theme.spacing(1, 0)
  },
  globalJobStepCardName: {
    fontSize: '0.875rem',
    display: 'inline',
    verticalAlign: '35%'
  },
  noStepsMessage: { fontSize: '0.875rem' }
}));

const CategoryStepList = ({
  category,
  isGlobalEditing,
  categorySearchAhaSteps,
  handleAddGlobalStepToTemplate
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const { displayToast } = useToast();
  const [selectedStep, setSelectedStep] = useState(null);
  const [isUpsertStepDialogOpen, setIsUpsertStepDialogOpen] = useState(false);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(
    false
  );
  const [isDeleteConfirmed, toggleIsDeleteConfirmed] = useState(false);
  const [queryWasCalled, setQueryWasCalled] = useState(false);
  const [ahaGlobalStepListState] = useAhaGlobalStepListState();
  const [isReadOnlyStep, setIsReadOnlyStep] = useState(false);

  const { data, loading: isLoadingGlobalStepsOnCategory } = useSoteriaQuery({
    gql: GET_ALL_GLOBAL_STEPS_FOR_CATEGORY,
    queryHookOptions: {
      variables: { id: category.id },
      skip: !category?.id || categorySearchAhaSteps?.length > 0,
      onCompleted: data => {
        setQueryWasCalled(true);
      }
    }
  });

  const steps = categorySearchAhaSteps ?? data?.ahaStepsForGlobalCategory ?? [];
  const hasSteps = steps.length > 0;

  const refetchQueries = [
    {
      query: GET_ALL_GLOBAL_STEPS_FOR_CATEGORY,
      variables: { id: category.id }
    },
    { query: GET_ALL_GLOBAL_STEP_CATEGORIES },
    ...(ahaGlobalStepListState?.search
      ? [
          {
            query: GLOBAL_STEP_SEARCH,
            variables: { search: ahaGlobalStepListState?.search }
          }
        ]
      : [])
  ];

  const [deleteGlobalAhaStep, { loading: isDeletingGlobalStep }] = useMutation(
    DELETE_GLOBAL_AHA_STEP,
    {
      refetchQueries,
      awaitRefetchQueries: true
    }
  );

  const isLoading = isLoadingGlobalStepsOnCategory || isDeletingGlobalStep;

  const handleViewStep = step => {
    setIsReadOnlyStep(true);
    setSelectedStep(step);
    setIsUpsertStepDialogOpen(true);
  };

  const onAddStepClick = step => {
    handleAddGlobalStepToTemplate(step);
  };

  const onEditStepClick = step => {
    setIsReadOnlyStep(false);
    setSelectedStep(step);
    setIsUpsertStepDialogOpen(true);
  };

  const onDeleteStepClick = step => {
    setIsReadOnlyStep(false);
    setSelectedStep(step);
    setIsDeleteConfirmationOpen(true);
  };

  const handleDeleteSubmit = () => {
    deleteGlobalAhaStep({ variables: { id: selectedStep.id } })
      .then(() => {
        displayToast(
          t('categoryStepList.toasts.delete.success', {
            name: selectedStep.name
          }),
          'success'
        );
        handleDeleteModalClose();
      })
      .catch(error => {
        console.error('Delete Global Step Error: ', error);
        displayToast(t('categoryStepList.toasts.delete.error'), 'error');
      });
  };

  const handleDeleteModalClose = () => {
    setSelectedStep(null);
    setIsDeleteConfirmationOpen(false);
    toggleIsDeleteConfirmed(false);
  };

  const getSkeletonSteps = numSteps => {
    let content = [];
    for (let i = 0; i < numSteps; i++) {
      content.push(
        <Card key={i} className={classes.globalJobStepCard}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center">
            <Grid item></Grid>
            <Grid item>
              {!isGlobalEditing && (
                <IconButton size="small" disabled>
                  <AddIcon />
                </IconButton>
              )}
              {isGlobalEditing && (
                <>
                  <IconButton disabled size="small">
                    <EditIcon />
                  </IconButton>
                  <IconButton disabled size="small">
                    <DeleteIcon />
                  </IconButton>
                </>
              )}
            </Grid>
          </Grid>
        </Card>
      );
    }
    return content;
  };

  return <>
    <Grid container>
      <Grid item xs={12}>
        <LoadingOverlay
          active={isLoadingGlobalStepsOnCategory || isDeletingGlobalStep}
          spinner
          fadeSpeed={50}
          styles={{
            overlay: base => ({
              ...base,
              background: theme.palette.background.overlay
            }),
            spinner: base => ({
              ...base,
              width: '50px',
              '& svg circle': {
                stroke: theme.palette.primary.main
              }
            })
          }}>
          {!categorySearchAhaSteps &&
            (!queryWasCalled || !data) &&
            getSkeletonSteps(3)}
          {hasSteps && (
            <>
              {steps.map(step => (
                <Card key={step.id} className={classes.globalJobStepCard}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center">
                    <Grid item>
                      <Typography className={classes.globalJobStepCardName}>
                        {step.name}
                      </Typography>
                    </Grid>
                    <Grid item>
                      {!isGlobalEditing && (
                        <>
                          <IconButton
                            size="small"
                            onClick={() => handleViewStep(step)}
                            disabled={isLoading}>
                            <VisibilityIcon />
                          </IconButton>
                          <IconButton
                            size="small"
                            onClick={() => onAddStepClick(step)}
                            disabled={isLoading}>
                            <AddIcon />
                          </IconButton>
                        </>
                      )}
                      {isGlobalEditing && (
                        <>
                          <IconButton
                            size="small"
                            onClick={() => onEditStepClick(step)}
                            disabled={isLoading}>
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            size="small"
                            onClick={() => onDeleteStepClick(step)}
                            disabled={isLoading}>
                            <DeleteIcon />
                          </IconButton>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Card>
              ))}
            </>
          )}
        </LoadingOverlay>
      </Grid>
      {queryWasCalled && data?.ahaStepsForGlobalCategory?.length < 1 && (
        <Typography className={classes.noStepsMessage}>
          {t('categoryStepList.noStepsExistUnderExpandedCategoryMessage')}
        </Typography>
      )}
    </Grid>
    <UpsertStep
      isOpen={isUpsertStepDialogOpen}
      setIsOpen={setIsUpsertStepDialogOpen}
      stepToEdit={selectedStep}
      isGlobal={true}
      isReadOnly={isReadOnlyStep}
    />
    <StyledDeleteConfirmation
      title={t('categoryStepList.deleteStepConfirmation.title')}
      dialogIsOpen={isDeleteConfirmationOpen}
      isLoading={isDeletingGlobalStep}
      handleClose={handleDeleteModalClose}
      warningMessage={t('categoryStepList.deleteWarning', {
        name: selectedStep?.name
      })}
      isConfirmed={isDeleteConfirmed}
      handleChange={event => toggleIsDeleteConfirmed(event.target.checked)}
      handleSubmit={handleDeleteSubmit}
    />
  </>;
};

CategoryStepList.propTypes = {
  category: PropTypes.any.isRequired,
  isGlobalEditing: PropTypes.bool,
  categorySearchAhaSteps: PropTypes.array,
  handleAddGlobalStepToTemplate: PropTypes.func
};

export default CategoryStepList;
