import gql from 'graphql-tag';

// Subscriptions
export const ATTENDEE_SUBSCRIPTION = gql`
  subscription AttendeeSubscription($attendeeId: ID!) {
    attendee(id: $attendeeId) {
      id
      status
      upn
      firstName
      fullName
      middleName
      lastName
      nickname
      dob
      profileImage {
        id
        name
        url
        thumbnailUrl
        sizeInBytes
        contentType
        isScanned
        isMalware
        cachedUrl @client
        cachedFile @client
      }
      contactInformation {
        emailAddress {
          email
          type
        }
        phoneNumbers {
          number
          display
          extension
          type
        }
        primaryPhone {
          number
          display
          extension
          type
        }
      }
      notes
      trainings {
        id
        name
        otherName
        notes
        awardedDate
        expirationDate
        files {
          id
          name
          url
          thumbnailUrl
          sizeInBytes
          contentType
          isScanned
          isMalware
          cachedUrl @client
          cachedFile @client
        }
      }
      emergencyContacts {
        id
        name
        title
        relationship
        contactInformation {
          emailAddress {
            email
            type
          }
          phoneNumbers {
            number
            display
            extension
            type
          }
          primaryPhone {
            number
            display
            extension
            type
          }
        }
        location {
          display
          streetAndNumber
          streetDetail
          city
          stateOrProvince
          zipOrPostalCode
          country
          latitude
          longitude
          cityAndStateDisplay
        }
      }
      customId
      isSupervisor
      isPermitApprover
      tradePartner {
        id
        name
        project {
          id
          name
          number
          districtNumber
        }
      }
      personnel {
        id
        firstName
        middleName
        lastName
        nickname
        fullName
        dob
        notes
        upn
        contactInformation {
          emailAddress {
            email
            type
          }
          phoneNumbers {
            number
            display
            extension
            type
          }
          primaryPhone {
            number
            display
            extension
            type
          }
        }
        flags {
          id
          status
          reason
          resolution
        }
        trainings {
          id
          name
          otherName
          notes
          awardedDate
          expirationDate
          files {
            id
            name
            url
            thumbnailUrl
            sizeInBytes
            contentType
            isScanned
            isMalware
            cachedUrl @client
            cachedFile @client
          }
        }
        tradePartnerHistory {
          id
          isSupervisor
          isPermitApprover
          shouldBeAdLinked
          tradePartner {
            id
            name
            project {
              id
              name
              number
              districtNumber
            }
          }
        }
        profileImage {
          id
          name
          url
          thumbnailUrl
          sizeInBytes
          contentType
          isScanned
          isMalware
          cachedUrl @client
          cachedFile @client
        }
      }
      orientation {
        id
      }
    }
  }
`;

// Queries
export const GET_ATTENDEE = gql`
  query GetSingleAttendee($attendeeId: ID!) {
    attendee(id: $attendeeId) {
      id
      status
      upn
      firstName
      fullName
      middleName
      lastName
      nickname
      dob
      profileImage {
        id
        name
        url
        thumbnailUrl
        sizeInBytes
        contentType
        isScanned
        isMalware
        cachedUrl @client
        cachedFile @client
      }
      contactInformation {
        emailAddress {
          email
          type
        }
        phoneNumbers {
          number
          display
          extension
          type
        }
        primaryPhone {
          number
          display
          extension
          type
        }
      }
      notes
      trainings {
        id
        name
        otherName
        notes
        awardedDate
        expirationDate
        files {
          id
          name
          url
          thumbnailUrl
          sizeInBytes
          contentType
          isScanned
          isMalware
          cachedUrl @client
          cachedFile @client
        }
      }
      emergencyContacts {
        id
        name
        title
        relationship
        contactInformation {
          emailAddress {
            email
            type
          }
          phoneNumbers {
            number
            display
            extension
            type
          }
          primaryPhone {
            number
            display
            extension
            type
          }
        }
        location {
          display
          streetAndNumber
          streetDetail
          city
          stateOrProvince
          zipOrPostalCode
          country
          latitude
          longitude
          cityAndStateDisplay
        }
      }
      customId
      isSupervisor
      isPermitApprover
      tradePartner {
        id
        name
        project {
          id
          name
          number
          districtNumber
        }
      }
      personnel {
        id
        firstName
        middleName
        lastName
        nickname
        fullName
        dob
        notes
        upn
        contactInformation {
          emailAddress {
            email
            type
          }
          phoneNumbers {
            number
            display
            extension
            type
          }
          primaryPhone {
            number
            display
            extension
            type
          }
        }
        flags {
          id
          status
          reason
          resolution
        }
        trainings {
          id
          name
          otherName
          notes
          awardedDate
          expirationDate
          files {
            id
            name
            url
            thumbnailUrl
            sizeInBytes
            contentType
            isScanned
            isMalware
            cachedUrl @client
            cachedFile @client
          }
        }
        tradePartnerHistory {
          id
          isSupervisor
          isPermitApprover
          shouldBeAdLinked
          tradePartner {
            id
            name
            project {
              id
              name
              number
              districtNumber
            }
          }
        }
        profileImage {
          id
          name
          url
          thumbnailUrl
          sizeInBytes
          contentType
          isScanned
          isMalware
          cachedUrl @client
          cachedFile @client
        }
      }
      orientation {
        id
      }
    }
  }
`;

export const GET_ENROLLED_ATTENDEES_FOR_PROJECT = gql`
  query GetEnrolledAttendeesForProject($projectId: ID!) {
    enrolledAttendeesOnProject(projectId: $projectId) {
      id
    }
  }
`;

// Mutations
export const CREATE_ATTENDEE = gql`
  mutation CreateAttendee($input: AttendeeCreateInput!) {
    createAttendee(input: $input) {
      id
      status
      upn
      firstName
      fullName
      middleName
      lastName
      nickname
      dob
      profileImage {
        id
        name
        url
        thumbnailUrl
        sizeInBytes
        contentType
        isScanned
        isMalware
        cachedUrl @client
        cachedFile @client
      }
      contactInformation {
        emailAddress {
          email
          type
        }
        phoneNumbers {
          number
          display
          extension
          type
        }
        primaryPhone {
          number
          display
          extension
          type
        }
      }
      notes
      trainings {
        id
        name
        otherName
        notes
        awardedDate
        expirationDate
        files {
          id
          name
          url
          thumbnailUrl
          sizeInBytes
          contentType
          isScanned
          isMalware
          cachedUrl @client
          cachedFile @client
        }
      }
      emergencyContacts {
        id
        name
        title
        relationship
        contactInformation {
          emailAddress {
            email
            type
          }
          phoneNumbers {
            number
            display
            extension
            type
          }
          primaryPhone {
            number
            display
            extension
            type
          }
        }
        location {
          display
          streetAndNumber
          streetDetail
          city
          stateOrProvince
          zipOrPostalCode
          country
          latitude
          longitude
          cityAndStateDisplay
        }
      }
      customId
      isSupervisor
      isPermitApprover
      tradePartner {
        id
        name
        project {
          id
          name
          number
          districtNumber
        }
      }
      personnel {
        id
        firstName
        middleName
        lastName
        nickname
        fullName
        dob
        notes
        upn
        contactInformation {
          emailAddress {
            email
            type
          }
          phoneNumbers {
            number
            display
            extension
            type
          }
          primaryPhone {
            number
            display
            extension
            type
          }
        }
        flags {
          id
          status
          reason
          resolution
        }
        trainings {
          id
          name
          otherName
          notes
          awardedDate
          expirationDate
          files {
            id
            name
            url
            thumbnailUrl
            sizeInBytes
            contentType
            isScanned
            isMalware
            cachedUrl @client
            cachedFile @client
          }
        }
        tradePartnerHistory {
          id
          isSupervisor
          isPermitApprover
          shouldBeAdLinked
          tradePartner {
            id
            name
            project {
              id
              name
              number
              districtNumber
            }
          }
        }
        profileImage {
          id
          name
          url
          thumbnailUrl
          sizeInBytes
          contentType
          isScanned
          isMalware
          cachedUrl @client
          cachedFile @client
        }
      }
      orientation {
        id
      }
    }
  }
`;

export const UPDATE_ATTENDEE = gql`
  mutation UpdateAttendee($input: AttendeeUpdateInput!) {
    updateAttendee(input: $input) {
      id
      status
      upn
      firstName
      fullName
      middleName
      lastName
      nickname
      dob
      profileImage {
        id
        name
        url
        thumbnailUrl
        sizeInBytes
        contentType
        isScanned
        isMalware
        cachedUrl @client
        cachedFile @client
      }
      contactInformation {
        emailAddress {
          email
          type
        }
        phoneNumbers {
          number
          display
          extension
          type
        }
        primaryPhone {
          number
          display
          extension
          type
        }
      }
      notes
      trainings {
        id
        name
        otherName
        notes
        awardedDate
        expirationDate
        files {
          id
          name
          url
          thumbnailUrl
          sizeInBytes
          contentType
          isScanned
          isMalware
          cachedUrl @client
          cachedFile @client
        }
      }
      emergencyContacts {
        id
        name
        title
        relationship
        contactInformation {
          emailAddress {
            email
            type
          }
          phoneNumbers {
            number
            display
            extension
            type
          }
          primaryPhone {
            number
            display
            extension
            type
          }
        }
        location {
          display
          streetAndNumber
          streetDetail
          city
          stateOrProvince
          zipOrPostalCode
          country
          latitude
          longitude
          cityAndStateDisplay
        }
      }
      customId
      isSupervisor
      isPermitApprover
      tradePartner {
        id
        name
        project {
          id
          name
          number
          districtNumber
        }
      }
      personnel {
        id
        firstName
        middleName
        lastName
        nickname
        fullName
        dob
        notes
        upn
        contactInformation {
          emailAddress {
            email
            type
          }
          phoneNumbers {
            number
            display
            extension
            type
          }
          primaryPhone {
            number
            display
            extension
            type
          }
        }
        flags {
          id
          status
          reason
          resolution
        }
        trainings {
          id
          name
          otherName
          notes
          awardedDate
          expirationDate
          files {
            id
            name
            url
            thumbnailUrl
            sizeInBytes
            contentType
            isScanned
            isMalware
            cachedUrl @client
            cachedFile @client
          }
        }
        tradePartnerHistory {
          id
          isSupervisor
          isPermitApprover
          shouldBeAdLinked
          tradePartner {
            id
            name
            project {
              id
              name
              number
              districtNumber
            }
          }
        }
        profileImage {
          id
          name
          url
          thumbnailUrl
          sizeInBytes
          contentType
          isScanned
          isMalware
          cachedUrl @client
          cachedFile @client
        }
      }
      orientation {
        id
      }
    }
  }
`;

export const APPROVE_ATTENDEE = gql`
  mutation approveAttendee($attendeeId: String!, $personnelId: String) {
    approveAttendee(attendeeId: $attendeeId, personnelId: $personnelId) {
      id
      status
      upn
      firstName
      fullName
      middleName
      lastName
      nickname
      dob
      profileImage {
        id
        name
        url
        thumbnailUrl
        sizeInBytes
        contentType
        isScanned
        isMalware
        cachedUrl @client
        cachedFile @client
      }
      contactInformation {
        emailAddress {
          email
          type
        }
        phoneNumbers {
          number
          display
          extension
          type
        }
        primaryPhone {
          number
          display
          extension
          type
        }
      }
      notes
      trainings {
        id
        name
        otherName
        notes
        awardedDate
        expirationDate
        files {
          id
          name
          url
          thumbnailUrl
          sizeInBytes
          contentType
          isScanned
          isMalware
          cachedUrl @client
          cachedFile @client
        }
      }
      emergencyContacts {
        id
        name
        title
        relationship
        contactInformation {
          emailAddress {
            email
            type
          }
          phoneNumbers {
            number
            display
            extension
            type
          }
          primaryPhone {
            number
            display
            extension
            type
          }
        }
        location {
          display
          streetAndNumber
          streetDetail
          city
          stateOrProvince
          zipOrPostalCode
          country
          latitude
          longitude
          cityAndStateDisplay
        }
      }
      customId
      isSupervisor
      isPermitApprover
      tradePartner {
        id
        name
        project {
          id
          name
          number
          districtNumber
        }
      }
      personnel {
        id
        firstName
        middleName
        lastName
        nickname
        fullName
        dob
        notes
        upn
        contactInformation {
          emailAddress {
            email
            type
          }
          phoneNumbers {
            number
            display
            extension
            type
          }
          primaryPhone {
            number
            display
            extension
            type
          }
        }
        flags {
          id
          status
          reason
          resolution
        }
        trainings {
          id
          name
          otherName
          notes
          awardedDate
          expirationDate
          files {
            id
            name
            url
            thumbnailUrl
            sizeInBytes
            contentType
            isScanned
            isMalware
            cachedUrl @client
            cachedFile @client
          }
        }
        tradePartnerHistory {
          id
          isSupervisor
          isPermitApprover
          shouldBeAdLinked
          tradePartner {
            id
            name
            project {
              id
              name
              number
              districtNumber
            }
          }
        }
        profileImage {
          id
          name
          url
          thumbnailUrl
          sizeInBytes
          contentType
          isScanned
          isMalware
          cachedUrl @client
          cachedFile @client
        }
      }
      orientation {
        id
      }
    }
  }
`;

export const DELETE_ATTENDEE = gql`
  mutation DeleteAttendee($attendeeId: ID!) {
    deleteAttendee(id: $attendeeId) {
      id
    }
  }
`;
