import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import { useField, useForm } from 'react-final-form-hooks';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography } from '@mui/material';

import useFormValidation from 'hooks/useFormValidation';
import useToast from 'hooks/useToast';
import StyledDialog from 'shared/Dialog';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import StyledButtonSecondary from 'shared/Buttons/ButtonSecondary';
import StyledSelect from 'shared/Select';
import ScopeIndicator from 'components/ahas/AhaScopeIndicator';
import { NOTIFICATION_FREQUENCY_OPTIONS } from 'constants/ahas';
import { UPDATE_PROJECT_TEMPLATE } from 'graphql/aha/projectTemplate';

const useStyles = makeStyles(theme => ({
  instructions: { marginBottom: theme.spacing(3) }
}));

const NotificationSettingsDialog = ({
  notificationSettingsDialogIsOpen,
  toggleNotificationSettingsDialog,
  ahaTemplate
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { displayToast } = useToast();
  const { isPositiveNumber } = useFormValidation();

  const [updateAhaProjectTemplate, { loading: isLoading }] = useMutation(
    UPDATE_PROJECT_TEMPLATE
  );

  const { form, values, pristine } = useForm({
    /* istanbul ignore next */
    onSubmit: () => {},
    initialValues: {
      frequency:
        ahaTemplate?.ahaProjectTemplate?.reviewReminderCadenceDays ?? 30
    }
  });

  const frequencyField = useField('frequency', form, isPositiveNumber);

  const canSubmit = () => {
    const { error, invalid } = form.getState();
    return !(invalid || error || pristine);
  };

  const handleSubmit = () => {
    updateAhaProjectTemplate({
      variables: {
        input: {
          id: ahaTemplate?.ahaProjectTemplate?.id,
          reviewReminderCadenceDays: values.frequency
        }
      }
    })
      .then(() => {
        displayToast(t('notificationSettingsDialog.toasts.success'), 'success');
        handleClose();
      })
      .catch(error => {
        console.error('Update Project Template Error', 'error');
        displayToast(t('notificationSettingsDialog.toasts.error'), 'error');
      });
  };

  const handleClose = () => {
    toggleNotificationSettingsDialog(false);
    form.reset();
  };

  return (
    <StyledDialog
      maxWidth="sm"
      title={t('notificationSettingsDialog.title')}
      isOpen={notificationSettingsDialogIsOpen}
      handleClose={handleClose}
      isLoading={isLoading}
      content={
        <Grid container>
          <Grid item xs={12}>
            <ScopeIndicator isGlobal={false} />
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.instructions}>
              {t('notificationSettingsDialog.instructions')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <StyledSelect
              margin="dense"
              label={t('notificationSettingsDialog.selectLabel')}
              required
              input={frequencyField.input}
              meta={frequencyField.meta}
              disabled={isLoading}
              options={NOTIFICATION_FREQUENCY_OPTIONS.map(option => ({
                label: option.label,
                value: option.value
              }))}
            />
          </Grid>
        </Grid>
      }
      actions={
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <StyledButtonSecondary
                  label={t('notificationSettingsDialog.actions.cancelButton')}
                  disabled={isLoading}
                  onClick={handleClose}
                />
              </Grid>
              <Grid item>
                <StyledButtonPrimary
                  label={t('notificationSettingsDialog.actions.submitButton')}
                  disabled={!canSubmit() || isLoading}
                  onClick={handleSubmit}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
    />
  );
};

NotificationSettingsDialog.propTypes = {
  notificationSettingsDialogIsOpen: PropTypes.bool,
  toggleNotificationSettingsDialog: PropTypes.func,
  ahaTemplate: PropTypes.object
};

export default NotificationSettingsDialog;
