import React from 'react';
import PropTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';
import { useTranslation } from 'react-i18next';
import FunctionsIcon from '@mui/icons-material/Functions';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography, Avatar, Card, useTheme } from '@mui/material';

import { getMonthFromDate, getYearFromDate } from 'utils';

const useStyles = makeStyles(theme => ({
  title: { fontWeight: 'bold' },
  icon: { fontSize: '2rem' },
  avatar: {
    borderRadius: 4,
    width: theme.spacing(8),
    height: theme.spacing(8),
    backgroundColor: theme.palette.background.callToAction
  },
  avatarText: { fontSize: '1.5rem', fontWeight: 'bold' },
  hoursText: { fontWeight: 'bold' }
}));

const TradePartnerWorkerHoursCard = ({ tradePartner }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const theme = useTheme();

  const today = new Date();
  const currentYear = getYearFromDate(today);

  return (
    <Card className="margin-bottom">
      <Grid container>
        <Grid item xs={12} className="padding">
          <Grid container direction="row" spacing={1}>
            <Grid item xs={12}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography className={classes.title}>
                    {t('tradePartnerPage.workerHours.title')}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <Grid
                container
                justifyContent="flex-start"
                alignContent="center"
                alignItems="center">
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    spacing={1}
                    alignItems="center">
                    <Grid item>
                      <Avatar className={classes.avatar}>
                        <Typography className={classes.avatarText}>
                          {getMonthFromDate(today, 'short')}
                        </Typography>
                      </Avatar>
                    </Grid>
                    <Grid item xs>
                      <Grid container direction="column">
                        <Grid item>
                          <Typography color="textSecondary">
                            {t('tradePartnerPage.workerHours.monthToDate')}
                          </Typography>
                        </Grid>
                        <Grid item xs>
                          <Typography>
                            <NumericFormat
                              displayType={'text'}
                              thousandSeparator={true}
                              value={tradePartner.workerHoursMonthToDate}
                              className={classes.hoursText}
                            />
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <Grid
                container
                justifyContent="flex-start"
                alignContent="center"
                alignItems="center">
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    spacing={1}
                    alignItems="center">
                    <Grid item>
                      <Avatar
                        className={classes.avatar}
                        style={{
                          backgroundColor: theme.palette.secondary.secondary
                        }}>
                        <Typography className={classes.avatarText}>
                          {currentYear}
                        </Typography>
                      </Avatar>
                    </Grid>
                    <Grid item xs>
                      <Grid container direction="column">
                        <Grid item>
                          <Typography color="textSecondary">
                            {t('tradePartnerPage.workerHours.yearToDate')}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography>
                            <NumericFormat
                              displayType={'text'}
                              thousandSeparator={true}
                              value={tradePartner.workerHoursYearToDate}
                              className={classes.hoursText}
                            />
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <Grid
                container
                justifyContent="flex-start"
                alignContent="center"
                alignItems="center">
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    spacing={1}
                    alignItems="center">
                    <Grid item>
                      <Avatar className={classes.avatar}>
                        <FunctionsIcon className={classes.icon} />
                      </Avatar>
                    </Grid>
                    <Grid item xs>
                      <Grid container direction="column">
                        <Grid item>
                          <Typography color="textSecondary">
                            {t('tradePartnerPage.workerHours.totalToDate')}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography>
                            <NumericFormat
                              displayType={'text'}
                              thousandSeparator={true}
                              value={tradePartner.workerHoursTotalToDate}
                              className={classes.hoursText}
                            />
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

TradePartnerWorkerHoursCard.propTypes = {
  tradePartner: PropTypes.object.isRequired
};

export default TradePartnerWorkerHoursCard;
