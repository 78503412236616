import React, { useState, Fragment } from 'react';
import TimezoneSelect from 'react-timezone-select';
import PropTypes from 'prop-types';
import { FormHelperText, useTheme, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  errorFormHelperText: { color: theme.palette.error.main },
  labelText: {
    fontFamily: ['Roboto', 'ui-sans-serif', 'sans-serif'].join(','),
    fontWeight: 'bold',
    lineHeight: 1.5,
    fontSize: '0.75rem'
  }
}));

const StyledTimezoneSelect = ({
  isDisabled = false,
  selectedValue,
  handleChange,
  placeholder,
  isRequired = false,
  isLoading,
  handleInputChange,
  meta,
  defaultValue,
  label,
  ...rest
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [selectHasBeenFocused, handleFocusSelectChange] = useState(false);
  const selectIsInvalid = (() => {
    return selectHasBeenFocused && (isNullOrEmptyArray() || meta?.error);
    function isNullOrEmptyArray() {
      return !selectedValue;
    }
  })();

  const requiredFieldStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: state.isFocused
        ? '#ddd'
        : selectIsInvalid
        ? theme.palette.error.main
        : '#ddd',
      '&:hover': {
        borderColor: state.isFocused
          ? '#ddd'
          : selectIsInvalid
          ? theme.palette.error.main
          : '#ddd'
      }
    })
  };

  const menuPortalStyles = {
    menuPortal: styles => ({ ...styles, zIndex: 9999 }),
    menu: (base, state) => ({
      ...base,
      fontFamily: ['Roboto', 'ui-sans-serif', 'sans-serif'].join(','),
      backgroundColor: theme.selectAutoCompleteMenuBackgroundColor
    }),
    control: (base, state) => ({
      ...base,
      backgroundColor: theme.selectAutoCompleteControlBackgroundColor
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: theme.selectAutoCompleteOptionBackgroundColor,
      color: theme.selectAutoCompleteOptionColor
    }),
    input: (provided, state) => ({
      ...provided,
      color: theme.selectAutoCompleteInputColor
    }),
    placeholder: (provided, state) => ({
      ...provided,
      fontFamily: ['Roboto', 'ui-sans-serif', 'sans-serif'].join(','),
      color: theme.palette.secondary.contrastText
    }),
    loadingIndicator: (provided, state) => ({
      ...provided,
      color: theme.selectAutoCompleteLoadingIndicatorColor
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontFamily: ['Roboto', 'ui-sans-serif', 'sans-serif'].join(','),
      color: theme.selectAutoCompleteSingleValueColor,
      fontSize: '0.875rem'
    }),
    multiValue: (provided, state) => ({
      ...provided,
      fontFamily: ['Roboto', 'ui-sans-serif', 'sans-serif'].join(','),
      color: theme.selectAutoCompleteMultiValueColor
    })
  };

  return (
    <Fragment>
      {label && (
        <Fragment>
          {!selectHasBeenFocused && !isDisabled && (
            <Typography color="textSecondary" className={classes.labelText}>
              {isRequired ? `${label} *` : label}
            </Typography>
          )}
          {selectHasBeenFocused && !meta.error && !isDisabled && (
            <Typography color="textSecondary" className={classes.labelText}>
              {isRequired ? `${label} *` : label}
            </Typography>
          )}
          {selectHasBeenFocused && meta?.error && !isDisabled && (
            <Typography color="error" className={classes.labelText}>
              {isRequired ? `${label} *` : label}
            </Typography>
          )}
          {isDisabled && (
            <Typography color="textSecondary" className={classes.labelText}>
              {label}
            </Typography>
          )}
        </Fragment>
      )}
      <TimezoneSelect
        aria-label="timezone-select"
        value={selectedValue ? selectedValue : null}
        onChange={handleChange}
        isDisabled={isDisabled}
        isLoading={isLoading}
        defaultValue={defaultValue}
        menuPortalTarget={document.body}
        defaultOptions={true}
        placeholder={placeholder}
        onFocus={() => handleFocusSelectChange(true)}
        onInputChange={inputValue => handleInputChange(inputValue)}
        styles={
          isRequired
            ? Object.assign({}, requiredFieldStyles, menuPortalStyles)
            : menuPortalStyles
        }
        isClearable
        cacheOptions
        {...rest}
      />
      {!isDisabled && selectHasBeenFocused && meta?.error && (
        <FormHelperText className={classes.errorFormHelperText}>
          {meta.error}
        </FormHelperText>
      )}
    </Fragment>
  );
};

StyledTimezoneSelect.propTypes = {
  isDisabled: PropTypes.bool,
  selectedValue: PropTypes.any,
  handleChange: PropTypes.func.isRequired,
  isRequired: PropTypes.bool,
  placeholder: PropTypes.node.isRequired,
  isLoading: PropTypes.bool,
  handleInputChange: PropTypes.func.isRequired,
  meta: PropTypes.any,
  defaultValue: PropTypes.any,
  label: PropTypes.string
};

export default StyledTimezoneSelect;
