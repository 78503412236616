import gql from 'graphql-tag';

// Subscriptions
export const TRADE_PARTNER_SUBSCRIPTION = gql`
  subscription tradePartner($tradePartnerId: ID!) {
    tradePartner(id: $tradePartnerId) {
      id
      name
      notes
      trades
      isActive
      workerHoursMonthToDate
      workerHoursYearToDate
      workerHoursTotalToDate
      project {
        id
        name
        number
        description
        districtNumber
        isActive
        notes
        location {
          display
          streetAndNumber
          streetDetail
          city
          stateOrProvince
          zipOrPostalCode
          country
          latitude
          longitude
        }
      }
      parentContractors {
        id
        name
      }
      subcontractors {
        id
        name
      }
      company {
        id
        name
        notes
        location {
          display
          streetAndNumber
          streetDetail
          city
          stateOrProvince
          zipOrPostalCode
          country
        }
        contactInformation {
          website
          phoneNumbers {
            number
            display
            extension
            type
          }
          primaryPhone {
            number
            display
            extension
            type
          }
          secondaryPhone {
            number
            display
            extension
            type
          }
          faxPhone {
            number
            display
            extension
            type
          }
          emailAddress {
            email
            type
          }
        }
      }
      workerHours {
        id
        hours
        year
        month
      }
    }
  }
`;

export const TRADE_PARTNERS_ON_PROJECT_SUBSCRIPTION = gql`
  subscription tradePartnersOnProject($projectId: ID!) {
    tradePartnersOnProject(projectId: $projectId) {
      id
      name
      notes
      trades
      isActive
      workerHoursMonthToDate
      workerHoursYearToDate
      workerHoursTotalToDate
      project {
        id
        name
      }
      parentContractors {
        id
        name
      }
      subcontractors {
        id
        name
      }
      company {
        id
        name
        notes
      }
      workerHours {
        id
        hours
        year
        month
      }
    }
  }
`;

// Queries
export const GET_ALL_TRADE_PARTNERS_FOR_PROJECT = gql`
  query GetAllTradePartners($projectId: ID!) {
    tradePartnersOnProject(projectId: $projectId) {
      id
      name
      isActive
      trades
      notes
      workerHoursMonthToDate
      workerHoursYearToDate
      workerHoursTotalToDate
      company {
        id
        name
      }
    }
  }
`;

export const GET_PAGINATED_TRADE_PARTNERS_FOR_PROJECT = gql`
  query getPaginatedTradePartnersForProject(
    $projectId: ID!
    $first: Int!
    $skip: Int
    $filter: TradePartnersFilterInput
    $order: [TradePartnersOrderInput!]
    $search: String
  ) {
    paginatedTradePartnersOnProject(
      projectId: $projectId
      first: $first
      skip: $skip
      filter: $filter
      order: $order
      search: $search
    ) {
      total
      tradePartners {
        id
        name
        isActive
        trades
        notes
        workerHoursMonthToDate
        workerHoursYearToDate
        workerHoursTotalToDate
        company {
          id
          name
        }
      }
    }
  }
`;

export const GET_TRADE_PARTNER = gql`
  query GetSingleTradePartner($id: ID!) {
    tradePartner(id: $id) {
      id
      name
      notes
      trades
      isActive
      workerHoursMonthToDate
      workerHoursYearToDate
      workerHoursTotalToDate
      project {
        id
        name
      }
      parentContractors {
        id
        name
      }
      subcontractors {
        id
        name
      }
      company {
        id
        name
        notes
        location {
          display
          streetAndNumber
          streetDetail
          city
          stateOrProvince
          zipOrPostalCode
          country
          latitude
          longitude
          cityAndStateDisplay
        }
        contactInformation {
          website
          phoneNumbers {
            number
            display
            extension
            type
          }
          primaryPhone {
            number
            display
            extension
            type
          }
          secondaryPhone {
            number
            display
            extension
            type
          }
          faxPhone {
            number
            display
            extension
            type
          }
          emailAddress {
            email
            type
          }
        }
      }
      workerHours {
        id
        hours
        year
        month
      }
    }
  }
`;

export const PARENT_TRADE_PARTNER_SEARCH = gql`
  query SearchForParentTradePartners(
    $search: String
    $projectId: ID!
    $subcontractorId: ID
  ) {
    availableParentContractors(
      search: $search
      first: 100
      projectId: $projectId
      subcontractorId: $subcontractorId
    ) {
      id
      name
      isActive
    }
  }
`;

export const SUB_TRADE_PARTNER_SEARCH = gql`
  query SearchForSubTradePartners(
    $search: String
    $projectId: ID!
    $parentContractorId: ID
  ) {
    availableSubcontractors(
      search: $search
      first: 100
      projectId: $projectId
      parentContractorId: $parentContractorId
    ) {
      id
      name
      isActive
    }
  }
`;

export const TRADE_SEARCH = gql`
  query Trades($search: String) {
    trades(search: $search, first: 100)
  }
`;

// Mutations
export const CREATE_TRADE_PARTNER = gql`
  mutation CreateTradePartner(
    $projectId: ID!
    $companyId: ID!
    $name: String!
    $isActive: Boolean!
    $notes: String
    $trades: [String!]!
    $contacts: [ContactCreateInput!]!
  ) {
    createTradePartner(
      input: {
        projectId: $projectId
        companyId: $companyId
        name: $name
        isActive: $isActive
        notes: $notes
        trades: $trades
        contacts: $contacts
      }
    ) {
      id
      name
      isActive
      trades
      notes
      workerHoursMonthToDate
      workerHoursYearToDate
      workerHoursTotalToDate
    }
  }
`;

export const ADD_TRADE_PARTNER_TO_PROJECT = gql`
  mutation AddTradePartnerToProject(
    $name: String!
    $projectId: ID!
    $isActive: Boolean!
    $contacts: [ContactCreateInput!]!
    $trades: [String!]!
    $company: CompanyCreateInput
    $companyId: ID
    $notes: String
    $parentContractorId: ID
  ) {
    addTradePartnerToProject(
      input: {
        name: $name
        projectId: $projectId
        isActive: $isActive
        contacts: $contacts
        trades: $trades
        company: $company
        companyId: $companyId
        notes: $notes
        parentContractorId: $parentContractorId
      }
    ) {
      id
      name
      notes
      trades
      isActive
      workerHoursMonthToDate
      workerHoursYearToDate
      workerHoursTotalToDate
      project {
        id
        name
      }
      parentContractors {
        id
        name
      }
      subcontractors {
        id
        name
      }
      company {
        id
        name
        notes
        location {
          display
          streetAndNumber
          streetDetail
          city
          stateOrProvince
          zipOrPostalCode
          country
          latitude
          longitude
          cityAndStateDisplay
        }
        contactInformation {
          website
          phoneNumbers {
            number
            display
            extension
            type
          }
          primaryPhone {
            number
            display
            extension
            type
          }
          secondaryPhone {
            number
            display
            extension
            type
          }
          faxPhone {
            number
            display
            extension
            type
          }
          emailAddress {
            email
            type
          }
        }
      }
      workerHours {
        id
        hours
        year
        month
      }
    }
  }
`;

export const UPDATE_TRADE_PARTNER = gql`
  mutation UpdateTradePartner(
    $id: ID!
    $name: String
    $isActive: Boolean
    $notes: String
    $trades: [String!]
    $contacts: [ContactCreateInput!]
  ) {
    updateTradePartner(
      input: {
        id: $id
        name: $name
        isActive: $isActive
        notes: $notes
        trades: $trades
        contacts: $contacts
      }
    ) {
      id
      name
      notes
      trades
      isActive
      workerHoursMonthToDate
      workerHoursYearToDate
      workerHoursTotalToDate
      project {
        id
        name
      }
      parentContractors {
        id
        name
      }
      subcontractors {
        id
        name
      }
      company {
        id
        name
        notes
        location {
          display
          streetAndNumber
          streetDetail
          city
          stateOrProvince
          zipOrPostalCode
          country
          latitude
          longitude
          cityAndStateDisplay
        }
        contactInformation {
          website
          phoneNumbers {
            number
            display
            extension
            type
          }
          primaryPhone {
            number
            display
            extension
            type
          }
          secondaryPhone {
            number
            display
            extension
            type
          }
          faxPhone {
            number
            display
            extension
            type
          }
          emailAddress {
            email
            type
          }
        }
      }
      workerHours {
        id
        hours
        year
        month
      }
    }
  }
`;

export const SET_SUBCONTRACTOR_STATUS = gql`
  mutation SetSubcontractorStatus(
    $parentId: ID!
    $subcontractorId: ID!
    $isConnected: Boolean!
  ) {
    setSubcontractorStatus(
      parentId: $parentId
      subcontractorId: $subcontractorId
      isConnected: $isConnected
    ) {
      id
      name
      notes
      trades
      isActive
      workerHoursMonthToDate
      workerHoursYearToDate
      workerHoursTotalToDate
      project {
        id
        name
        number
        description
        districtNumber
        isActive
        notes
        location {
          display
          streetAndNumber
          streetDetail
          city
          stateOrProvince
          zipOrPostalCode
          country
          latitude
          longitude
        }
      }
      parentContractors {
        id
        name
      }
      subcontractors {
        id
        name
      }
      company {
        id
        name
        notes
        location {
          display
          streetAndNumber
          streetDetail
          city
          stateOrProvince
          zipOrPostalCode
          country
        }
        contactInformation {
          website
          phoneNumbers {
            number
            display
            extension
            type
          }
          primaryPhone {
            number
            display
            extension
            type
          }
          secondaryPhone {
            number
            display
            extension
            type
          }
          faxPhone {
            number
            display
            extension
            type
          }
          emailAddress {
            email
            type
          }
        }
      }
      workerHours {
        id
        hours
        year
        month
      }
    }
  }
`;

export const SET_PARENT_CONTRACTOR_STATUS = gql`
  mutation SetParentContractorStatus(
    $parentId: ID!
    $subcontractorId: ID!
    $isConnected: Boolean!
  ) {
    setParentContractorStatus(
      parentId: $parentId
      subcontractorId: $subcontractorId
      isConnected: $isConnected
    ) {
      id
      name
      notes
      trades
      isActive
      workerHoursMonthToDate
      workerHoursYearToDate
      workerHoursTotalToDate
      project {
        id
        name
        number
        description
        districtNumber
        isActive
        notes
        location {
          display
          streetAndNumber
          streetDetail
          city
          stateOrProvince
          zipOrPostalCode
          country
          latitude
          longitude
        }
      }
      parentContractors {
        id
        name
      }
      subcontractors {
        id
        name
      }
      company {
        id
        name
        notes
        location {
          display
          streetAndNumber
          streetDetail
          city
          stateOrProvince
          zipOrPostalCode
          country
        }
        contactInformation {
          website
          phoneNumbers {
            number
            display
            extension
            type
          }
          primaryPhone {
            number
            display
            extension
            type
          }
          secondaryPhone {
            number
            display
            extension
            type
          }
          faxPhone {
            number
            display
            extension
            type
          }
          emailAddress {
            email
            type
          }
        }
      }
      workerHours {
        id
        hours
        year
        month
      }
    }
  }
`;

export const DELETE_TRADE_PARTNER = gql`
  mutation DeleteTradePartner($id: ID!) {
    deleteTradePartner(id: $id) {
      id
    }
  }
`;

export const SET_WORKER_HOURS = gql`
  mutation SetWorkerHours(
    $tradePartnerId: ID!
    $hours: Float!
    $month: Month!
    $year: Int!
  ) {
    setWorkerHours(
      tradePartnerId: $tradePartnerId
      hours: $hours
      month: $month
      year: $year
    ) {
      id
      name
      notes
      trades
      isActive
      workerHoursMonthToDate
      workerHoursYearToDate
      workerHoursTotalToDate
      project {
        id
        name
      }
      parentContractors {
        id
        name
      }
      subcontractors {
        id
        name
      }
      company {
        id
        name
      }
      workerHours {
        id
        hours
        year
        month
      }
    }
  }
`;

export const SET_MANY_WORKER_HOURS = gql`
  mutation SetManyWorkerHours(
    $projectId: ID!
    $workerHours: [TradePartnerMonthWorkUpsertInput!]!
  ) {
    setManyWorkerHours(projectId: $projectId, workerHours: $workerHours) {
      id
      name
      workerHours {
        id
        hours
        month
        year
      }
      company {
        id
        name
      }
    }
  }
`;
