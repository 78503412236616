import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import cloneDeep from 'clone-deep';
import LoadingOverlay from 'react-loading-overlay';
import { useTheme } from '@mui/material/styles';

import useDebounce from 'hooks/useDebounce';
import withAuthorization from 'hocs/withAuthorization';
import StyledTable from 'shared/Table';
import ErrorNotice from 'components/common/ErrorNotice';
import BackToTopButton from 'shared/BackToTopButton';
import ActiveOrInactiveStatusContent from 'components/common/ActiveOrInactiveStatusContent';
import withOnlineAccessOnly from 'hocs/withOnlineAccessOnly';
import StyledTableCellContent from 'components/common/StyledTableCellContent';
import useProjectsListState from 'store/projectsListState';
import { PAGINATED_PROJECTS } from 'graphql/project';
import { DISTRICT_NUMBERS } from 'constants/districts';
import OverallScoreGauge from 'components/preIncidentIndicators/OverallScoreGauge';
import useEnabledFeature from 'hooks/useIsFeatureEnabledForCurrentUser';
import { features } from 'constants/enabledFeatures';

const MasterProjectListPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const theme = useTheme();
  const [
    projectsListState,
    { handleProjectsListStateChange }
  ] = useProjectsListState();
  const [initialProjectsListState] = useState(projectsListState);

  const isPreIncidentIndicatorsFeatureEnabled = useEnabledFeature(
    features.preIncidentIndicators
  );

  const FILTERS = {
    districts: 'Regions',
    isActive: 'Active',
    inactive: 'Inactive'
  };

  const getApiSortOrder = order => {
    let apiSortOrderField = null;
    let direction = order.direction;

    switch (order.name) {
      case 'name':
        apiSortOrderField = 'name';
        break;
      case 'number':
        apiSortOrderField = 'number';
        break;
      case 'district':
        apiSortOrderField = 'districtNumber';
        break;
      case 'location.cityAndStateDisplay':
        apiSortOrderField = 'location_city';
        break;
      case 'isActive':
        apiSortOrderField = 'isActive';
        break;
      case 'preIncidentAnalysis.probability':
        apiSortOrderField = 'preIncidentAnalysis_probability';
        break;
      default:
        console.error(`Sort order, "${order.name}", is not being handled!`);
    }
    return apiSortOrderField && direction
      ? [
          {
            [apiSortOrderField]: direction.toUpperCase()
          }
        ]
      : null;
  };

  const { data, loading, error, fetchMore } = useQuery(PAGINATED_PROJECTS, {
    variables: {
      first: initialProjectsListState.first,
      filter: initialProjectsListState.filter,
      order: getApiSortOrder(initialProjectsListState.order),
      search: initialProjectsListState.search,
      skip: 0
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  });

  const projects = data?.paginatedProjects?.projects
    ? cloneDeep(data.paginatedProjects.projects)
    : [];

  const columns = [
    {
      name: 'id',
      label: 'Id',
      options: {
        filter: false,
        display: 'excluded'
      }
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: false,
        sort: true,
        sortOrder: 'asc',
        searchable: true,
        // eslint-disable-next-line react/display-name
        customBodyRender: value => {
          return <StyledTableCellContent value={value} />;
        }
      }
    },
    {
      name: 'number',
      label: 'Number',
      options: {
        filter: false,
        sort: true,
        searchable: true
      }
    },
    {
      name: 'districtNumber',
      label: 'Region',
      options: {
        filter: true,
        sort: false,
        filterList: projectsListState.filterList[3]?.length
          ? projectsListState.filterList[3]
          : null,
        filterOptions: {
          names: DISTRICT_NUMBERS,
          logic: (districtNumber, filters) => {
            if (filters.length) {
              if (districtNumber.length > 0) {
                return !filters.includes(districtNumber.toString());
              } else {
                return false;
              }
            }
          }
        }
      }
    },
    {
      name: 'location.cityAndStateDisplay',
      label: 'Location',
      options: {
        filter: false,
        sort: true,
        searchable: true,
        // eslint-disable-next-line react/display-name
        customBodyRender: value => {
          return <StyledTableCellContent value={value} />;
        }
      }
    },
    {
      name: 'isActive',
      label: 'Status',
      options: {
        sort: true,
        searchable: false,
        filter: true,
        filterList: projectsListState.filterList[9]?.length
          ? projectsListState.filterList[9]
          : null,
        filterOptions: {
          names: [FILTERS.isActive, FILTERS.inactive],
          logic: (isActive, filters) => {
            if (filters.length) {
              const status = isActive ? FILTERS.isActive : FILTERS.inactive;
              return !filters.includes(status);
            }
            /* istanbul ignore next */
            return false; // this line can never be tested as filters will always exist
          }
        },
        // eslint-disable-next-line react/display-name
        customBodyRender: value => (
          <ActiveOrInactiveStatusContent isActive={value} />
        )
      }
    }
  ];

  if (isPreIncidentIndicatorsFeatureEnabled) {
    columns.push({
      name: 'preIncidentAnalysis.probability',
      label: 'PI Score',
      options: {
        sort: true,
        searchable: false,
        filter: false,
        // eslint-disable-next-line react/display-name
        customBodyRender: (value, tableMeta) => (
          <OverallScoreGauge
            score={value}
            projectId={projects?.[tableMeta?.rowIndex]?.id}
            style={{ width: '45px', marginBottom: '-10px' }}
            animate={false}
            arcWidth={0.3}
          />
        )
      }
    });
  }

  const loadMore = variables => {
    return fetchMore({
      variables,
      updateQuery: (_previousResult, { fetchMoreResult, _queryVariables }) => {
        return fetchMoreResult;
      }
    });
  };

  const handleFilterChange = tableState => {
    const { filterList } = tableState;
    const filters = filterList.flat().map(filter => filter.toLowerCase());
    let selectedFilters = {};
    let district = parseInt(filterList[3]);

    if (filters.some(filter => DISTRICT_NUMBERS.includes(filter))) {
      selectedFilters = {
        ...selectedFilters,
        districtNumber: district
      };
    }

    if (filters.includes(FILTERS.isActive.toLowerCase())) {
      selectedFilters = {
        ...selectedFilters,
        isActive: true
      };
    }

    if (filters.includes(FILTERS.inactive.toLowerCase())) {
      selectedFilters = {
        ...selectedFilters,
        isActive: false
      };
    }

    handleProjectsListStateChange({
      ...projectsListState,
      filter: selectedFilters,
      filterList,
      skip: 0,
      page: 0
    });

    loadMore({
      search: projectsListState.search,
      filter: selectedFilters,
      skip: 0,
      order: getApiSortOrder(projectsListState.order)
    });
  };

  const handlePageChange = tableState => {
    const { rowsPerPage, page } = tableState;

    let skip =
      page < projectsListState.page
        ? projectsListState.skip - rowsPerPage
        : projectsListState.skip + rowsPerPage;

    handleProjectsListStateChange({
      ...projectsListState,
      page,
      skip
    });

    loadMore({
      search: projectsListState.search,
      skip,
      order: getApiSortOrder(projectsListState.order)
    });
  };

  const handleSortChange = tableState => {
    const { sortOrder } = tableState;

    handleProjectsListStateChange({
      ...projectsListState,
      order: sortOrder,
      page: 0,
      skip: 0
    });

    loadMore({
      search: projectsListState.search,
      skip: 0,
      order: getApiSortOrder(sortOrder)
    });
  };

  const handleSearchChange = ({ tableState, debouncedHandleSearchChange }) => {
    const { searchText } = tableState;

    handleProjectsListStateChange({
      ...projectsListState,
      skip: 0,
      page: 0,
      search: searchText
    });

    loadMore({
      search: searchText,
      skip: 0,
      order: getApiSortOrder(projectsListState.order)
    });
  };

  const { debounced: debouncedHandleSearchChange } = useDebounce(
    handleSearchChange
  );

  if (error) {
    console.error(error);
    return <ErrorNotice />;
  }

  const options = {
    download: false,
    searchPlaceholder: 'Search...',
    count: data?.paginatedProjects?.total ?? null,
    rowsPerPage: projectsListState.first,
    searchText: projectsListState.search,
    page: projectsListState.page,
    rowsPerPageOptions: [],
    sortOrder: projectsListState.order,
    serverSide: true,
    onRowClick: rowData => history.push(`/projects/${rowData[0]}`),
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          handlePageChange(tableState);
          break;
        case 'sort':
          handleSortChange(tableState);
          break;
        case 'filterChange':
        case 'resetFilters':
          handleFilterChange(tableState);
          break;
        case 'search':
        case 'onSearchClose':
          debouncedHandleSearchChange({
            tableState,
            projectsListState
          });
          break;
        default:
      }
    }
  };

  return (
    <Fragment>
      <LoadingOverlay
        active={loading}
        spinner
        fadeSpeed={50}
        styles={{
          overlay: base => ({
            ...base,
            background: theme.palette.background.overlay
          }),
          spinner: base => ({
            ...base,
            width: '50px',
            '& svg circle': {
              stroke: theme.palette.primary.main
            }
          })
        }}>
        <StyledTable
          title={t('masterProjectListPage.title')}
          data={projects}
          columns={columns}
          options={options}
        />
      </LoadingOverlay>
      <BackToTopButton />
    </Fragment>
  );
};

export default withOnlineAccessOnly(withAuthorization(MasterProjectListPage));
