/* eslint-disable react/display-name */
import React, { Fragment } from 'react';
import cloneDeep from 'clone-deep';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import { Typography } from '@mui/material';

import useSoteriaQuery from 'hooks/useSoteriaQuery';
import StyledTable from 'shared/Table';
import LoadingSpinner from 'components/common/LoadingSpinner';
import ErrorNotice from 'components/common/ErrorNotice';
import PageNotFoundNotice from 'components/common/PageNotFoundNotice';
import ActiveOrInactiveStatusContent from 'components/common/ActiveOrInactiveStatusContent';
import StyledTableCellContent from 'components/common/StyledTableCellContent';
import { ORIENTATION_STATUSES } from 'constants/orientationStatuses';
import { GET_ALL_TRADE_PARTNER_PERSONNEL_FOR_TRADE_PARTNER } from 'graphql/tradePartnerPersonnel';

const useStyles = makeStyles(theme => ({
  greenText: {
    color: theme.palette.contrastText.green,
    fontSize: '0.875rem'
  },
  redText: {
    color: theme.palette.contrastText.red,
    fontSize: '0.875rem'
  }
}));

const TradePartnerPersonnelList = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { projectId, tradePartnerId } = useParams();

  const { data, loading, error } = useSoteriaQuery({
    gql: GET_ALL_TRADE_PARTNER_PERSONNEL_FOR_TRADE_PARTNER,
    queryHookOptions: {
      variables: { tradePartnerId }
    }
  });

  const getOrientationStatus = value => {
    switch (value) {
      case ORIENTATION_STATUSES.completed:
        return t('tradePartnerPersonnelList.orientationStatus.complete');
      case ORIENTATION_STATUSES.incomplete:
        return t('tradePartnerPersonnelList.orientationStatus.incomplete');
      case ORIENTATION_STATUSES.imported:
        return t('tradePartnerPersonnelList.orientationStatus.imported');
      default:
        return null;
    }
  };

  const formattedTradePartnerPersonnels = () => {
    const tradePartnerPersonnelsCopy = cloneDeep(
      data.tradePartnerPersonnelsOnTradePartner
    );
    return tradePartnerPersonnelsCopy.map(tradePartnerPersonnel => {
      return {
        customId: tradePartnerPersonnel.customId,
        id: tradePartnerPersonnel.id,
        isActive: tradePartnerPersonnel.isActive,
        isSupervisor: tradePartnerPersonnel.isSupervisor,
        notes: tradePartnerPersonnel.notes,
        orientationStatus: tradePartnerPersonnel.orientationStatus,
        shouldBeAdLinked: tradePartnerPersonnel.shouldBeAdLinked,
        tradePartner: tradePartnerPersonnel.tradePartner,
        personnel: tradePartnerPersonnel.personnel,
        personnelFlagCount: tradePartnerPersonnel.personnel.flags.length
      };
    });
  };
  const tradePartnerPersonnels = data?.tradePartnerPersonnelsOnTradePartner
    ? formattedTradePartnerPersonnels(data.tradePartnerPersonnelsOnTradePartner)
    : [];

  const columns = [
    {
      name: 'id',
      label: 'Trade Partner Personnel Id',
      options: {
        filter: false,
        display: 'excluded'
      }
    },
    {
      name: 'personnel.fullName',
      label: t('tradePartnerPersonnelList.columns.name'),
      options: {
        filter: false,
        sort: true,
        searchable: true,
        customBodyRender: value => {
          return <StyledTableCellContent value={value} />;
        }
      }
    },
    {
      name: 'personnel.nickname',
      label: t('tradePartnerPersonnelList.columns.nickName'),
      options: {
        filter: false,
        sort: true,
        searchable: false,
        customBodyRender: value => {
          return <StyledTableCellContent value={value} />;
        }
      }
    },
    {
      name: 'customId',
      label: t('tradePartnerPersonnelList.columns.customId'),
      options: {
        filter: false,
        sort: true,
        searchable: true
      }
    },
    {
      name: 'personnel.contactInformation.emailAddress.email',
      label: t('tradePartnerPersonnelList.columns.email'),
      options: {
        filter: false,
        sort: true,
        searchable: true,
        customBodyRender: value => {
          return <StyledTableCellContent value={value} />;
        }
      }
    },
    {
      name: 'orientationStatus',
      label: t('tradePartnerPersonnelList.columns.orientation'),
      options: {
        filter: true,
        sort: true,
        searchable: false,
        filterOptions: {
          names: [
            t('tradePartnerPersonnelList.orientationStatus.complete'),
            t('tradePartnerPersonnelList.orientationStatus.incomplete'),
            t('tradePartnerPersonnelList.orientationStatus.imported')
          ],
          logic: (value, filters) => {
            if (filters.length) {
              const status = getOrientationStatus(value);
              return !filters.includes(status);
            }
            /* istanbul ignore next */
            return false; // this line can never be tested as filters will always exist
          }
        },
        customBodyRender: value => {
          const status = getOrientationStatus(value);
          return (
            <>
              <Typography
                className={
                  value === ORIENTATION_STATUSES.completed
                    ? classes.greenText
                    : classes.redText
                }>
                {status}
              </Typography>
            </>
          );
        }
      }
    },
    {
      name: 'isSupervisor',
      label: t('tradePartnerPersonnelList.columns.supervisor'),
      options: {
        filter: true,
        sort: true,
        searchable: true,
        filterOptions: {
          names: [
            t('tradePartnerPersonnelList.isSupervisorCheck.supervisor'),
            t('tradePartnerPersonnelList.isSupervisorCheck.notSupervisor')
          ],
          logic: (isSupervisor, filters) => {
            if (filters.length) {
              const supervisor =
                isSupervisor === true
                  ? t('tradePartnerPersonnelList.isSupervisorCheck.supervisor')
                  : t(
                      'tradePartnerPersonnelList.isSupervisorCheck.notSupervisor'
                    );
              return !filters.includes(supervisor);
            }
            /* istanbul ignore next */
            return false; // this line can never be tested as filters will always exist
          }
        },
        customBodyRender: isSupervisor => (
          <Fragment>
            {isSupervisor && (
              <Typography style={{ fontSize: '0.875rem' }}>
                {t('personnelJobHistoryList.status.supervisor')}
              </Typography>
            )}
          </Fragment>
        )
      }
    },
    {
      name: 'personnelFlagCount',
      label: t('tradePartnerPersonnelList.columns.flags'),
      options: {
        filter: false,
        sort: true,
        searchable: true,
        customBodyRender: personnelFlagCount => (
          <Fragment>
            {personnelFlagCount > 0 && (
              <Typography>{personnelFlagCount}</Typography>
            )}
          </Fragment>
        )
      }
    },
    {
      name: 'isActive',
      label: t('tradePartnerPersonnelList.columns.status'),
      options: {
        sort: true,
        searchable: false,
        filter: true,
        filterOptions: {
          names: [
            t('tradePartnerPersonnelList.isActiveCheck.active'),
            t('tradePartnerPersonnelList.isActiveCheck.inactive')
          ],
          logic: (isActive, filters) => {
            if (filters.length) {
              const status = isActive
                ? t('tradePartnerPersonnelList.isActiveCheck.active')
                : t('tradePartnerPersonnelList.isActiveCheck.inactive');
              return !filters.includes(status);
            }
            /* istanbul ignore next */
            return false; // this line can never be tested as filters will always exist
          }
        },
        customBodyRender: value => (
          <ActiveOrInactiveStatusContent isActive={value} />
        )
      }
    }
  ];

  const options = {
    sortOrder: {
      name: 'personnel.fullName',
      direction: 'asc'
    },
    onRowClick: rowData =>
      history.push(
        `/projects/${projectId}/trade-partners/${tradePartnerId}/personnel/${rowData[0]}`
      )
  };

  if (loading) {
    return <LoadingSpinner />;
  }
  if (error) {
    if (error.message?.includes('not found')) {
      return <PageNotFoundNotice />;
    } else {
      return <ErrorNotice />;
    }
  }

  return (
    <StyledTable
      title={t('tradePartnerPersonnelList.tableTitle')}
      data={tradePartnerPersonnels}
      columns={columns}
      options={options}
    />
  );
};

export default TradePartnerPersonnelList;
