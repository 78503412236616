import React, { useMemo } from 'react';
import { useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import { scoreRangesOrderedBestToWorst } from 'utils/indicatorScoreRange';
import GaugeChart from 'react-gauge-chart';

const OverallScoreGauge = ({ score, projectId, style, animate, arcWidth }) => {
  const theme = useTheme();
  return useMemo(
    () =>
      !!score && (
        <GaugeChart
          id={`gauge-project-${projectId}`}
          needleColor={theme?.palette?.secondary?.contrastText}
          needleBaseColor={theme?.palette?.secondary?.contrastText}
          colors={scoreRangesOrderedBestToWorst.map(
            scoreRange => theme?.palette?.scoreIndicator?.[scoreRange.name]
          )}
          percent={score}
          hideText={true}
          marginInPercent={0.01}
          style={style}
          animate={animate}
          arcWidth={arcWidth}
        />
      ),
    [score, projectId, style, animate, arcWidth]
  );
};

OverallScoreGauge.propTypes = {
  projectId: PropTypes.string,
  score: PropTypes.number,
  style: PropTypes.any,
  animate: PropTypes.bool,
  arcWidth: PropTypes.number
};

export default OverallScoreGauge;
