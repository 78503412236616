import { GET_COMPANY } from '../../graphql/company';
import useSoteriaQuery from 'hooks/useSoteriaQuery';

const useCompany = (companyId, shouldSkip = false) => {
  const { data: { company = null } = {}, loading, error } = useSoteriaQuery({
    gql: GET_COMPANY,
    queryHookOptions: {
      skip: shouldSkip || !companyId,
      variables: { id: companyId }
    }
  });

  return { company, loading, error };
};

export default useCompany;
