import makeStyles from '@mui/styles/makeStyles';
const useGlobalStepTabContentStyles = () => {
  return makeStyles(theme => ({
    cardsContainer: {
      height: 'calc(100vh - 348px)',
      overflowY: 'auto'
    },
    accordionRoot: {
      marginTop: theme.spacing(1),
      borderRadius: 4,
      '& .MuiTypography-root': { color: theme.palette.secondary.contrastText }
    },
    accordionSummaryRoot: { minHeight: 30, maxHeight: 30 },
    accordionSummaryRootEditing: { minHeight: 30 },
    accordionSummaryRootExpanded: {},
    accordionDetailsRoot: {
      marginLeft: theme.spacing(2),
      paddingTop: 0,
      paddingBottom: 8
    },
    globalJobStepCard: {
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(0.3, 1),
      boxShadow: 'none',
      margin: theme.spacing(1, 0)
    },
    globalJobStepCardName: {
      fontSize: '0.875rem',
      display: 'inline',
      verticalAlign: '35%'
    },
    icon: { color: 'white' },
    searchInput: {
      '& .MuiInput-underline:before': {
        borderBottom: '2px solid' + theme.palette.secondary.main
      },
      '& .MuiInputBase-input': {
        fontSize: '1rem !important',
        fontWeight: 'bold !important',
        color: theme.palette.secondary.contrastText + '!important',
        padding: theme.spacing(1, 0, 0, 2),
        backgroundColor: theme.palette.background.paper,
        borderRadius: 4,
        height: 19
      },
      '&:hover': {
        borderBottomColor: theme.palette.primary.main
      }
    },
    accordionExpanded: {
      paddingTop: theme.spacing(0.5)
    },
    accordionSummaryExpanded: {
      minHeight: '0px !important',
      backgroundColor: theme.palette.background.default
    },
    expanded: {
      margin: '0px !important',
      minHeight: '0px !important'
    },
    accordionSummaryContent: {
      margin: '4px !important',
      '&:hover': { cursor: 'auto' }
    },
    accordionSummaryContentExpanded: {
      margin: '0px !important',
      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.spacing(1, 1, 0, 0)
    },
    headingExpanded: { fontWeight: 'bold', '&:hover': { cursor: 'auto' } },
    expandIcon: { color: 'gray', '&:hover': { cursor: 'pointer' } },
    categoryName: { fontSize: '0.875rem', '&:hover': { cursor: 'pointer' } },
    addButtonContainer: { position: 'absolute', bottom: 16 },
    addButton: {
      position: 'relative',
      bottom: 0,
      left: 0,
      borderRadius: 16
    },
    addButtonLabel: { fontSize: '0.75rem', fontWeight: 'bold' },
    endOfListMessage: {
      color: '#fff',
      textTransform: 'uppercase',
      textAlign: 'center',
      paddingTop: theme.spacing(3.5),
      fontSize: '0.75rem',
      fontWeight: 'bold'
    }
  }));
};

export default useGlobalStepTabContentStyles;
