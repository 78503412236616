import { useEffect, useCallback } from 'react';
import {
  GET_OBSERVATION_CATEGORIES,
  GET_OBSERVATION_CAUSES
} from 'graphql/observations';
import { GET_SAFE_PROJECTS } from 'graphql/safeProject';
import { GET_ALL_PROJECTS_AS_ADMIN } from 'graphql/project';
import useSoteriaQuery from 'hooks/useSoteriaQuery';
import useOfflineModeCapabilities from 'store/manualOfflineMode';

const usePrefetchOfflineModeData = () => {
  const {
    isOfflineModeEnabled,
    refetchOfflineModeQueriesToCache,
    setRefetchOfflineModeQueriesToCache
  } = useOfflineModeCapabilities();

  const { refetch: refetchObservationCategories } = useSoteriaQuery({
    gql: GET_OBSERVATION_CATEGORIES,
    queryHookOptions: {
      skip: !isOfflineModeEnabled
    }
  });
  const { refetch: refetchObservationCauses } = useSoteriaQuery({
    gql: GET_OBSERVATION_CAUSES,
    queryHookOptions: {
      skip: !isOfflineModeEnabled
    }
  });

  const { refetch: refetchSafeProjects } = useSoteriaQuery({
    gql: GET_SAFE_PROJECTS,
    queryHookOptions: {
      skip: !isOfflineModeEnabled
    }
  });
  const { refetch: refetchProjectsAsAdmins } = useSoteriaQuery({
    gql: GET_ALL_PROJECTS_AS_ADMIN,
    queryHookOptions: {
      skip: !isOfflineModeEnabled
    }
  });

  const refetchAllQueries = useCallback(() => {
    return () => {
      refetchObservationCategories();
      refetchObservationCauses();
      refetchSafeProjects();
      refetchProjectsAsAdmins();
    };
  }, [
    refetchProjectsAsAdmins,
    refetchObservationCategories,
    refetchObservationCauses,
    refetchSafeProjects
  ]);

  useEffect(() => {
    if (!refetchOfflineModeQueriesToCache) {
      setRefetchOfflineModeQueriesToCache(refetchAllQueries);
    }
  });
};

export default usePrefetchOfflineModeData;
