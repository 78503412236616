import { atom, useRecoilState } from 'recoil';

const defaultIsOnlineState = atom({
  key: 'isOnline',
  default: true
});

function useIsOnline() {
  const [isOnline, setIsOnlineState] = useRecoilState(defaultIsOnlineState);

  return { isOnline, setIsOnline: setIsOnlineState };
}

export default useIsOnline;
