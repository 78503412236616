import React from 'react';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import { useMediaQuery } from 'react-responsive';

const StyledTable = ({ title, data, columns, options, ...rest }) => {
  const isMobileDevice = useMediaQuery({ maxDeviceWidth: 960 });

  const getTableStackType = () => {
    if (data.length > 0) {
      return isMobileDevice ? 'vertical' : 'standard';
    } else {
      return 'simple';
    }
  };
  const defaultOptions = {
    enableNestedDataAccess: '.',
    fixedHeader: true,
    filterType: 'dropdown',
    filter: true,
    print: false,
    selectToolbarPlacement: 'none',
    selectableRowsHeader: false,
    selectableRows: 'none',
    selectableRowsOnClick: false,
    caseSensitive: false,
    viewColumns: false,
    responsive: getTableStackType(),
    rowsPerPage: isMobileDevice ? 10 : 50,
    rowsPerPageOptions: [10, 20, 50, 100],
    downloadOptions: {
      filename: `${title.split(' ').join('_')}.csv`
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      // https://github.com/gregnb/mui-datatables/pull/722#issuecomment-526346440
      return '\uFEFF' + buildHead(columns) + buildBody(data);
    }
  };
  return (
    <MUIDataTable
      title={title}
      data={data}
      columns={columns}
      options={{ ...defaultOptions, ...options }}
      {...rest}
    />
  );
};

StyledTable.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  options: PropTypes.any
};

export default StyledTable;
