import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import WhatsNewIcon from '@mui/icons-material/EmojiObjects';

import useToast from 'hooks/useToast';
import useSoteriaStore from 'hooks/useSoteriaStore';
import useLatestVersion from 'hooks/useLatestVersion';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import packageJson from '../../../package.json';
import useSW from 'store/sw';

global.appVersion = packageJson.version;

const Reloader = ({ toastKey }) => {
  const { t } = useTranslation();
  const { swActions } = useSW();
  const refreshCacheAndReload = () => {
    sessionStorage.setItem('recentlyUpdated', true);
    sessionStorage.setItem('showWhatsNewNotification', true);
    swActions.update();
  };
  return (
    <StyledButtonPrimary
      label={t('updateToast.reloadButton')}
      onClick={refreshCacheAndReload}
    />
  );
};

Reloader.propTypes = {
  toastKey: PropTypes.any
};

const WhatsNew = ({ toastKey }) => {
  const { t } = useTranslation();
  const { setStore } = useSoteriaStore();
  const { closeSnackbar } = useSnackbar();

  const toggleWhatsNew = () => {
    setStore({ whatsNewIsOpen: true });
    closeSnackbar(toastKey);
  };

  return (
    <StyledButtonPrimary
      label={t('updateToast.whatsNewButton')}
      onClick={toggleWhatsNew}
      startIcon={<WhatsNewIcon />}
    />
  );
};

WhatsNew.propTypes = {
  toastKey: PropTypes.any
};

const semverGreaterThan = (latestVersion, currentVersion) => {
  if (latestVersion && currentVersion) {
    const versionsA = latestVersion.split(/\./g);

    const versionsB = currentVersion.split(/\./g);
    while (versionsA.length || versionsB.length) {
      const a = Number(versionsA.shift());

      const b = Number(versionsB.shift());

      if (a === b) continue;

      return a > b || isNaN(b);
    }
  }

  return false;
};

const CacheBuster = ({ children }) => {
  const { t } = useTranslation();
  const { data, loading: versionCheckLoading } = useLatestVersion();
  const { displayToast } = useToast();
  const { swState } = useSW();

  const latestVersion = data?.version ?? null;

  useEffect(() => {
    const currentVersion = global.appVersion;

    const shouldReload =
      !versionCheckLoading &&
      latestVersion &&
      currentVersion &&
      semverGreaterThan(latestVersion, currentVersion);

    if (shouldReload && swState.isUpdated) {
      displayToast(t('updateToast.message'), 'info', Reloader, false);
    }

    // Check if recently updated
    const recentlyUpdated = sessionStorage.getItem('recentlyUpdated');
    if (recentlyUpdated && !swState.isUpdated) {
      sessionStorage.removeItem('recentlyUpdated');
      displayToast(t('updateToast.whatsNew'), 'info', WhatsNew);
    }
  }, [
    data,
    displayToast,
    versionCheckLoading,
    t,
    latestVersion,
    swState.isUpdated,
    swState
  ]);
  return <Fragment>{children}</Fragment>;
};

CacheBuster.propTypes = {
  children: PropTypes.node
};

export default CacheBuster;
