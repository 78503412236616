import React from 'react';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import PrintIcon from '@mui/icons-material/Print';
import CloseIcon from '@mui/icons-material/Close';
import makeStyles from '@mui/styles/makeStyles';
import {
  Typography,
  Grid,
  Dialog,
  DialogTitle,
  Tooltip,
  IconButton,
  Divider,
  DialogContent,
  DialogActions
} from '@mui/material';

import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import StyledButtonSecondary from 'shared/Buttons/ButtonSecondary';
import hpLogo from 'assets/images/logos/hpLogoDark.png';
import safetyConnectLogo from 'assets/images/logos/SafetyConnectLogoLandscapeBlack.png';

const useStyles = makeStyles(theme => ({
  logoContainer: { marginBottom: '2rem' },
  hpLogo: { width: 250 },
  scLogo: { width: 130, marginLeft: theme.spacing(2) },
  title: { textAlign: 'center', fontSize: '2rem', color: 'black' },
  subtitle: { textAlign: 'center', fontSize: '1.5rem', color: 'black' },
  qrCodeContainer: { textAlign: 'center', margin: theme.spacing(10, 2) },
  instructionsTitle: { fontSize: '1.5rem', color: 'black' },
  closeIcon: { fontSize: '1.25rem', color: 'black' },
  dialogTitle: {
    backgroundColor: 'white',
    zIndex: 1500,
    [theme.breakpoints.down('lg')]: { padding: theme.spacing(2) }
  },
  dialogContent: {
    color: 'black',
    backgroundColor: 'white',
    padding: '1.5rem',
    [theme.breakpoints.down('lg')]: { padding: theme.spacing(3, 2) }
  },
  dialogActions: { backgroundColor: 'white' },
  cancelButton: { color: 'black' }
}));

const OrientationQrCodeDialog = ({
  orientationQrCodeDialogIsOpen,
  toggleOrientationQrCodeDialogIsOpen
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { projectId, orientationId } = useParams();

  const handleClose = () => {
    toggleOrientationQrCodeDialogIsOpen(false);
  };

  return (
    <Dialog
      data-testid="orientation-qr-code-dialog"
      className={classes.dialog}
      open={orientationQrCodeDialogIsOpen}
      fullWidth={true}
      maxWidth="md"
      onClose={handleClose}>
      <DialogTitle className={classes.dialogTitle} id="non-printable">
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Grid container direction="row" alignItems="center">
              <Grid item>
                <Tooltip title={t('orientationQrCodeDialog.title')}>
                  <Typography color="textPrimary" className={classes.title}>
                    {t('orientationQrCodeDialog.title')}
                  </Typography>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <IconButton
              alt={t('orientationQrCodeDialog.actions.closeButton')}
              data-testid="iconButton"
              onClick={handleClose}
              size="large">
              <CloseIcon className={classes.closeIcon} />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider></Divider>
      <DialogContent className={classes.dialogContent} id="printable">
        <Grid container direction="column" spacing={1} alignContent="center">
          <Grid item className={classes.logoContainer}>
            <img
              data-testid="hp-logo-img"
              className={classes.hpLogo}
              src={hpLogo}
              alt={t('images.henselPhelpsLogoAltText')}
            />
            <img
              data-testid="safetyconnect-logo-img"
              className={classes.scLogo}
              src={safetyConnectLogo}
              alt={t('images.safetyConnectLogoAltText')}
            />
          </Grid>
          <Grid item>
            <Typography component="h1" className={classes.title}>
              {t('orientationQrCodeDialog.printablePage.title')}
            </Typography>
            <Typography component="h2" className={classes.subtitle}>
              {t('orientationQrCodeDialog.printablePage.subtitle')}
            </Typography>
          </Grid>
          <Grid item className={classes.qrCodeContainer}>
            <QRCode
              value={`${process.env.REACT_APP_SELF_REGISTRATION_URL}/projects/${projectId}/orientations/${orientationId}`}
            />
          </Grid>
          <Grid item>
            <Typography
              component="h2"
              className={classes.instructionsTitle}
              gutterBottom>
              {t(
                'orientationQrCodeDialog.printablePage.printInstructions.title'
              )}
            </Typography>
            <Typography component="h3" gutterBottom>
              {t(
                'orientationQrCodeDialog.printablePage.printInstructions.stepOne'
              )}
            </Typography>
            <Typography component="h3" gutterBottom>
              {t(
                'orientationQrCodeDialog.printablePage.printInstructions.stepTwo'
              )}
            </Typography>
            <Typography component="h3" gutterBottom>
              {t(
                'orientationQrCodeDialog.printablePage.printInstructions.stepThree'
              )}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions id="non-printable" className={classes.dialogActions}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <StyledButtonSecondary
              aria-label={t('orientationQrCodeDialog.actions.cancelButton')}
              className={classes.cancelButton}
              label={t('orientationQrCodeDialog.actions.cancelButton')}
              onClick={handleClose}
            />
          </Grid>
          <Grid item>
            <StyledButtonPrimary
              aria-label={t('orientationQrCodeDialog.actions.printButton')}
              label={t('orientationQrCodeDialog.actions.printButton')}
              icon={<PrintIcon />}
              onClick={() => window.print()}
            />
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

OrientationQrCodeDialog.propTypes = {
  orientationQrCodeDialogIsOpen: PropTypes.bool.isRequired,
  toggleOrientationQrCodeDialogIsOpen: PropTypes.func.isRequired
};

export default OrientationQrCodeDialog;
