import { atom, useRecoilState } from 'recoil';
import { LOCAL_STORAGE_AHA_REVIEWS_ON_PROJECT_TEMPLATE_SORT_ORDER_KEY } from 'constants/localStorage';

const DEFAULT_SORT_ORDER = {
  name: 'completed',
  direction: 'asc'
};

const getStoredSortOrder = () => {
  const unserializedSortOrder = localStorage.getItem(
    LOCAL_STORAGE_AHA_REVIEWS_ON_PROJECT_TEMPLATE_SORT_ORDER_KEY
  );
  if (unserializedSortOrder) {
    return JSON.parse(unserializedSortOrder);
  } else {
    return null;
  }
};

const DEFAULT_ROWS_PER_PAGE = 10;
const order = getStoredSortOrder() ?? DEFAULT_SORT_ORDER;

const defaultState = {
  first: DEFAULT_ROWS_PER_PAGE,
  skip: 0,
  order,
  search: ''
};

const ahaReviewsOnProjectTemplateListStateAtom = atom({
  key: 'ahaReviewsOnProjectTemplateListState',
  default: defaultState
});

function useAhaProjectTemplateReviewsListState() {
  const [
    ahaReviewsOnProjectTemplateListState,
    setAhaReviewsOnProjectTemplateListState
  ] = useRecoilState(ahaReviewsOnProjectTemplateListStateAtom);

  function handleAhaReviewsOnProjectTemplateListStateChange(newState) {
    setAhaReviewsOnProjectTemplateListState({
      ...ahaReviewsOnProjectTemplateListState,
      ...newState
    });
  }
  return [
    ahaReviewsOnProjectTemplateListState,
    { handleAhaReviewsOnProjectTemplateListStateChange }
  ];
}

export default useAhaProjectTemplateReviewsListState;
