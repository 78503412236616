import React from 'react';
import PropTypes from 'prop-types';
import { TimePicker } from '@mui/x-date-pickers';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { Typography } from '@mui/material';
import { dateTimeToLuxonDateTime } from 'utils/dateTime';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  label: { fontSize: '0.75rem', fontWeight: 'bold', marginBottom: -8 },
  timePicker: {
    marginTop: 8,
    marginBottom: 4,
    marginLeft: 0,
    marginRight: 0,
    '& .MuiFormHelperText-root': {
      marginLeft: 0,
      color: theme.palette.error.main
    }
  }
}));

const StyledKeyboardTimePickerField = ({
  label,
  meta,
  required = true,
  inputProps,
  value,
  handleDateChange,
  disabled,
  shouldDisplayAsError = false,
  ...rest
}) => {
  const classes = useStyles();
  const showError = meta.error && meta.touched && meta.invalid;

  return (
    <>
      {label && (
        <>
          {!meta.touched && !disabled && !shouldDisplayAsError && (
            <Typography color="textSecondary" className={classes.label}>
              {required ? `${label} *` : label}
            </Typography>
          )}
          {meta.touched && !meta.error && !disabled && !shouldDisplayAsError && (
            <Typography color="textSecondary" className={classes.label}>
              {required ? `${label} *` : label}
            </Typography>
          )}
          {meta.touched && meta.error && !disabled && !shouldDisplayAsError && (
            <Typography color="error" className={classes.label}>
              {required ? `${label} *` : label}
            </Typography>
          )}
          {shouldDisplayAsError && (
            <Typography color="error" className={classes.label}>
              {required ? `${label} *` : label}
            </Typography>
          )}
          {disabled && !shouldDisplayAsError && (
            <Typography color="textSecondary" className={classes.label}>
              {label}
            </Typography>
          )}
        </>
      )}
      <TimePicker
        mask="__:__ _M"
        value={dateTimeToLuxonDateTime(value) || null}
        onChange={handleDateChange}
        clearable
        slotProps={{
          actionBar: {
            actions: ['clear', 'accept', 'cancel']
          },
          textField: {
            ...inputProps,
            required: true,
            helperText:
              showError && required
                ? meta.error || meta.submitError || meta.invalid
                : undefined,
            error: shouldDisplayAsError || (meta.error && meta.touched),
            variant: 'outlined'
          },
          // I can't see a way to disable backdropClick for desktop mode, so we are now only disabling it for mobile
          dialog: {
            onClose: (event, reason) => {
              if (reason !== 'backdropClick') {
                handleClose(event, reason);
              }
            }
          }
        }}
        keyboardIcon={<ScheduleIcon />}
        disabled={disabled}
        className={classes.timePicker}
        {...rest}
      />
    </>
  );
};

StyledKeyboardTimePickerField.propTypes = {
  label: PropTypes.string.isRequired,
  meta: PropTypes.any,
  required: PropTypes.bool,
  inputProps: PropTypes.any,
  value: PropTypes.any,
  handleDateChange: PropTypes.func.isRequired,
  shouldDisplayAsError: PropTypes.bool,
  disabled: PropTypes.bool
};

export default StyledKeyboardTimePickerField;
