import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';

import StyledDialog from 'shared/Dialog';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import StyledButtonSecondary from 'shared/Buttons/ButtonSecondary';
import StyledNotice from 'shared/Notice';
import StyledConfirmationSwitch from 'shared/ConfirmationSwitch';

const StyledDeleteConfirmation = ({
  title,
  dialogIsOpen,
  shouldShowConfirmToggle = true,
  isLoading,
  handleClose,
  type,
  warningMessage,
  isConfirmed,
  handleChange,
  handleSubmit
}) => {
  const { t } = useTranslation();

  const handleConfirm = () => {
    handleChange(true);
    handleSubmit();
  };

  return (
    <StyledDialog
      isOpen={dialogIsOpen}
      isLoading={isLoading}
      handleClose={handleClose}
      disableBackdropClick={false}
      disableEscapeKeyDown={false}
      maxWidth="sm"
      title={title}
      content={
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <StyledNotice type={type} message={warningMessage} />
          </Grid>
          {shouldShowConfirmToggle && (
            <Grid item>
              <StyledConfirmationSwitch
                disabled={isLoading}
                checked={isConfirmed}
                onChange={handleChange}
                value={'isConfirmed'}
              />
            </Grid>
          )}
        </Grid>
      }
      actions={
        <Fragment>
          <StyledButtonSecondary
            label={t('attendeeDeleteCard.confirmation.actions.cancelButton')}
            disabled={isLoading}
            onClick={handleClose}
          />
          {shouldShowConfirmToggle && (
            <StyledButtonPrimary
              label={t('attendeeDeleteCard.confirmation.actions.submitButton')}
              disabled={isLoading || !isConfirmed}
              onClick={handleSubmit}
            />
          )}

          {!shouldShowConfirmToggle && (
            <StyledButtonPrimary
              label={t('attendeeDeleteCard.confirmation.actions.confirmButton')}
              disabled={isLoading}
              onClick={handleConfirm}
            />
          )}
        </Fragment>
      }
    />
  );
};

StyledDeleteConfirmation.propTypes = {
  title: PropTypes.any,
  shouldShowConfirmToggle: PropTypes.bool,
  dialogIsOpen: PropTypes.bool,
  isLoading: PropTypes.bool,
  handleClose: PropTypes.func,
  type: PropTypes.string,
  warningMessage: PropTypes.any,
  isConfirmed: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

export default StyledDeleteConfirmation;
