import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';
import useAhaReview from 'hooks/useAhaReview';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import MarkdownRenderer from 'shared/MarkdownRenderer';

const useStyles = makeStyles(theme => ({
  fullWidth: { width: '100%' },
  hrDivider: { padding: 0, margin: 0, width: '100%' },
  jobStepColumnHeader: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '0.75rem',
    paddingTop: '.125rem'
  },
  borderRightAndLeft: {
    borderRight: '1px solid black',
    borderLeft: '1px solid black'
  },
  borderTop: { borderTop: '1px solid black' },
  mediumFontSize: { fontSize: '0.75rem' },
  padding: { padding: theme.spacing(0.5) },
  column: {
    width: '29%',
    borderLeft: '1px solid black'
  },
  columnSmall: { width: '13%' }
}));

const JobStepsSection = ({ ahaTemplate }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { ahaReviewId } = useParams();
  const { ahaReview } = useAhaReview(ahaReviewId);

  const sortByOrder = (a, b) => a.order - b.order;

  const jobSteps = ahaReview
    ? ahaReview?.ahaTemplate?.ahaSteps?.filter(step => step.isActive === true)
      ? [...ahaReview.ahaTemplate.ahaSteps]
          .sort(sortByOrder)
          .filter(step => step.isActive === true)
      : []
    : ahaTemplate?.ahaSteps.filter(step => step.isActive === true)
    ? [...ahaTemplate.ahaSteps]
        .sort(sortByOrder)
        .filter(step => step.isActive === true)
    : [];

  const getRiskAssessmentCode = step => {
    if (step) {
      if (step.riskAssessmentCode === 'ExtremelyHigh') {
        return 'E';
      }

      if (step.riskAssessmentCode === 'High') {
        return 'H';
      }

      if (step.riskAssessmentCode === 'Moderate') {
        return 'M';
      }

      if (step.riskAssessmentCode === 'Low') {
        return 'L';
      }
    }
  };

  return (
    <div className={classes.fullWidth}>
      <div className="page-break-auto">
        <Grid container className={classes.borderTop}>
          <Grid item className={classes.column}>
            <Typography className={classes.jobStepColumnHeader}>
              {t('jobStepsSection.title')}
            </Typography>
          </Grid>
          <Grid item className={classes.column}>
            <Typography className={classes.jobStepColumnHeader}>
              {t('jobStepsSection.column.hazards')}
            </Typography>
          </Grid>
          <Grid item className={classes.column}>
            <Typography className={classes.jobStepColumnHeader}>
              {t('jobStepsSection.column.controls')}
            </Typography>
          </Grid>
          <Grid
            item
            className={classnames(
              classes.borderRightAndLeft,
              classes.columnSmall
            )}>
            <Typography className={classes.jobStepColumnHeader}>
              {t('jobStepsSection.column.rac')}
            </Typography>
          </Grid>
        </Grid>
        <hr className={classes.hrDivider} />
      </div>
      {jobSteps?.map(step => (
        <div key={step.id ?? step.transactionKey}>
          <hr className={classes.hrDivider} />
          <Grid container>
            <Grid item className={classes.column}>
              <Typography
                className={classnames(classes.mediumFontSize, classes.padding)}>
                {step.name}
              </Typography>
            </Grid>
            <Grid item className={classes.column}>
              <Typography
                component={'div'}
                className={classnames(classes.mediumFontSize, classes.padding)}>
                <MarkdownRenderer markdownString={step.hazards} />
              </Typography>
            </Grid>
            <Grid item className={classes.column}>
              <Typography
                component={'div'}
                className={classnames(classes.mediumFontSize, classes.padding)}>
                <MarkdownRenderer markdownString={step.controls} />
              </Typography>
              <Typography
                className={classnames(classes.mediumFontSize, classes.padding)}>
                {step.ahaStepReferences
                  .map(reference => {
                    return `${reference.ahaReferenceOption}: ${reference.value}`;
                  })
                  .join(', ')}
              </Typography>
            </Grid>
            <Grid
              item
              className={classnames(
                classes.borderRightAndLeft,
                classes.columnSmall
              )}>
              <Grid container justifyContent="center" alignItems="center">
                <Grid item>
                  <Typography
                    className={classnames(
                      classes.mediumFontSize,
                      classes.padding
                    )}>
                    {getRiskAssessmentCode(step)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <hr className={classes.hrDivider} />
        </div>
      ))}
    </div>
  );
};

JobStepsSection.propTypes = {
  ahaTemplate: PropTypes.object
};

export default JobStepsSection;
