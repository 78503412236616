import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useField } from 'react-final-form-hooks';
import { Grid, Typography } from '@mui/material';

import useFormValidation from 'hooks/useFormValidation';
import StyledStep from 'shared/Step';
import AdUserSelect from 'components/ad/AdUserSelect';
import PersonalInfoFormStep from '../PersonalInfoFormStep';

const CreatePersonStep = ({
  form,
  isLoading,
  isHenselPhelpsEmployee,
  updatedProfileImage,
  setUpdatedProfileImage
}) => {
  const { t } = useTranslation();
  const { isRequired } = useFormValidation();

  const selectedHenselPhelpsEmployee = useField(
    'selectedHenselPhelpsEmployee',
    form,
    (...args) => (isHenselPhelpsEmployee ? isRequired(...args) : undefined)
  );

  return (
    <Fragment>
      {isHenselPhelpsEmployee && (
        <StyledStep title={t('selectAdUserStep.title')}>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <AdUserSelect
                includeOnboardingApplicantsWithNoAdUser
                selectedUser={selectedHenselPhelpsEmployee.input.value || null}
                meta={selectedHenselPhelpsEmployee.meta}
                handleChange={selectedHenselPhelpsEmployee.input.onChange}
                isDisabled={isLoading}
                placeholder={
                  <Typography>{t('selectAdUserStep.search')}</Typography>
                }
                isRequired={true}
              />
            </Grid>
          </Grid>
        </StyledStep>
      )}
      {!isHenselPhelpsEmployee && (
        <PersonalInfoFormStep
          form={form}
          isLoading={isLoading}
          updatedProfileImage={updatedProfileImage}
          setUpdatedProfileImage={setUpdatedProfileImage}
          isHenselPhelpsEmployee={isHenselPhelpsEmployee}
        />
      )}
    </Fragment>
  );
};

CreatePersonStep.propTypes = {
  form: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  isHenselPhelpsEmployee: PropTypes.bool,
  updatedProfileImage: PropTypes.object,
  setUpdatedProfileImage: PropTypes.func
};

export default CreatePersonStep;
