import moment from 'moment';
import { DateTime } from 'luxon';

const NAIVE_DATE_FORMAT_LUXON = 'yyyy-MM-dd';
const NAIVE_TIME_FORMAT_LUXON = 'HH:mm';

export const getMomentDateTime = (date, time) => {
  const momentDate = moment(date, 'YYYY-MM-DD');
  const timeParts = moment(time)
    .format('HH:mm')
    .split(':');
  let dateTime = momentDate._d;
  dateTime.setHours(timeParts[0], timeParts[1]);
  return dateTime;
};

export const getDateTimeStringFromMoment = (date, time) => {
  const isoFormattedDateTimeString = getMomentDateTime(
    date,
    time
  ).toISOString();
  return isoFormattedDateTimeString;
};

export function getDateFromDateTimeString(str) {
  var date = new Date(str),
    mnth = ('0' + (date.getMonth() + 1)).slice(-2),
    day = ('0' + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join('-');
}

export function formatDate(date, shouldShowTime = false) {
  if (shouldShowTime) {
    return moment(date).format('MM/DD/YYYY h:mm a');
  } else {
    return moment(date).format('MM/DD/YYYY');
  }
}

// display time as h:mm a
// single 'h' omits 0's for single digit hours
// 'a' sets the am/pm display to lowercase
export function formatTime(time) {
  moment(time).format('h:mm a');
}

/**
 * Supports dateTime as falsy, string, number of milliseconds, luxon.DateTime, moment.Moment, or Date object.
 */
export function dateTimeToLuxonDateTime(
  dateTime,
  timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
) {
  let luxonDateTime;
  if (!dateTime) {
    luxonDateTime = null;
  } else if (typeof dateTime === 'string') {
    luxonDateTime = DateTime.fromISO(dateTime);
  } else if (typeof dateTime === 'number') {
    luxonDateTime = DateTime.fromMillis(dateTime);
  } else if (DateTime.isDateTime(dateTime)) {
    luxonDateTime = dateTime;
  } else if (dateTime._isAMomentObject) {
    luxonDateTime = DateTime.fromJSDate(dateTime._d);
  } else if (dateTime instanceof Date) {
    luxonDateTime = DateTime.fromJSDate(dateTime);
  } else {
    throw new Error('Unsupported type of dateTime');
  }

  if (luxonDateTime?.invalidReason) {
    console.error(
      'Error parsing time: ' +
        luxonDateTime.invalidReason +
        ' - ' +
        luxonDateTime.invalidExplanation
    );
  }
  return luxonDateTime?.setZone(timeZone) ?? null;
}

/**
 * Supports dateTime as falsy, string, number of milliseconds, luxon.DateTime, moment.Moment, or Date object. (Returns '' for falsy.)
 */
export function dateTimeToNaiveLocalTimeString(
  dateTime,
  timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
) {
  if (!dateTime) {
    return '';
  }

  return dateTimeToLuxonDateTime(dateTime, timeZone).toFormat(
    NAIVE_TIME_FORMAT_LUXON
  );
}

/**
 * Supports dateTime as falsy, string, number of milliseconds, luxon.DateTime, moment.Moment, or Date object. (Returns '' for falsy.)
 */
export function dateTimeToNaiveLocalDateString(
  dateTime,
  timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
) {
  if (!dateTime) {
    return '';
  }

  return dateTimeToLuxonDateTime(dateTime, timeZone).toFormat(
    NAIVE_DATE_FORMAT_LUXON
  );
}

export function naiveLocalDateAndTimeToJsDate(
  naiveLocalDateString,
  naiveLocalTimeString,
  timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
) {
  return DateTime.fromFormat(
    `${naiveLocalDateString} ${naiveLocalTimeString}`,
    `${NAIVE_DATE_FORMAT_LUXON} ${NAIVE_TIME_FORMAT_LUXON}`,
    { zone: timeZone }
  ).toJSDate();
}

export const isDateTimeInstance = obj => {
  return DateTime.isDateTime(obj);
};
