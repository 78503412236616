// https://github.com/Rosey/markdown-draft-js
export const draftToMarkdownOptions = {
  styleItems: {
    'SOTERIA-HIGHLIGHT': {
      open: function() {
        return '==';
      },
      close: function() {
        return '==';
      }
    }
  }
};
export const markdownToDraftOptions = {
  remarkablePreset: 'commonmark',
  remarkableOptions: {
    enable: {
      inline: ['mark']
    }
  },
  blockStyles: {
    mark_open: 'SOTERIA-HIGHLIGHT'
  }
};
