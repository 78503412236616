import {
  Box,
  Card,
  CardActionArea,
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import classNames from 'classnames';
import UpdateObservationDialog from 'components/observations/UpdateObservationDialog';
import useRoles from 'hooks/useRoles';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import LoadingOverlay from 'react-loading-overlay';
import useIsOnline from 'store/onlineDetection';
import { formatDate } from 'utils/dateTime';
import { domain, Domain } from 'config/domain';

const getScrollContainerId = type => {
  return type.replace(/([a-zA-Z])(?=[A-Z])/g, '$1-').toLowerCase();
};

const useStyles = makeStyles(theme => ({
  card: {
    borderLeft: '8px solid' + theme.palette.primary.main,
    borderRadius: 0,
    [theme.breakpoints.up('sm')]: { padding: theme.spacing(0.5, 1.5, 1.5, 2) },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1.5, 1.5, 1.5, 2)
    }
  },
  cardClosed: { borderLeft: '8px solid' + theme.palette.observation.status },
  daysOpen: { color: theme.palette.primary.main, fontWeight: 'bold' },
  tradePartnerName: {
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      maxWidth: 250,
      whiteSpace: 'noWrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    }
  },
  label: { fontWeight: 'bold' },
  value: {
    maxWidth: 120,
    whiteSpace: 'noWrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: { maxWidth: 250 }
  },
  originator: {
    maxWidth: 260,
    whiteSpace: 'noWrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  originatorContainer: {
    marginBottom: 0
  },
  disabledCard: { cursor: 'not-allowed', pointerEvents: 'none' },
  cardContent: { paddingTop: theme.spacing(4) },
  scrollContainer: {
    maxHeight: 505,
    overflowX: 'auto',
    [theme.breakpoints.down('md')]: { maxHeight: 'calc(100vh - 360px)' }
  },
  padding: { padding: theme.spacing(2) },
  infiniteScroll: { overflow: 'hidden !important' },
  endMessage: { textAlign: 'center' }
}));

const ObservationsCardsList = ({
  observations,
  type,
  deletable = false,
  loadMore,
  total,
  isLoading,
  refetchCurrentQueries,
  compact = false
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const [
    updateObservationDialogIsOpen,
    setUpdateObservationDialogIsOpen
  ] = useState(false);
  const [scrollContainerId] = useState(getScrollContainerId(type));
  const [selectedObservation, setSelectedObservation] = useState(null);
  const { isSupportRole } = useRoles();
  const { isOnline } = useIsOnline();

  const getDayCount = createdDate => {
    const daysSinceCreated = moment().diff(createdDate, 'days');
    return daysSinceCreated;
  };

  const handleObservationCardClick = observation => {
    if (isOnline || deletable) {
      setUpdateObservationDialogIsOpen(true);
      setSelectedObservation(observation);
    }
  };

  return (
    <>
      <LoadingOverlay
        active={isLoading}
        spinner
        fadeSpeed={50}
        styles={{
          overlay: base => ({
            ...base,
            background: theme.palette.background.overlay
          }),
          spinner: base => ({
            ...base,
            width: '50px',
            '& svg circle': {
              stroke: theme.palette.primary.main
            }
          })
        }}>
        <Box
          sx={theme =>
            compact
              ? {
                  marginLeft: '8px !important',
                  [theme.breakpoints.down('md')]: {
                    maxHeight: 'calc(100vh - 270px) !important'
                  },
                  // For some reason, the md breakpoint doesn't seem to be getting the right height.
                  [theme.breakpoints.up('md')]: {
                    maxHeight: 'calc(100vh - 320px) !important'
                  }
                }
              : {}
          }
          id={`${scrollContainerId}-scroll-container`}
          className={classes.scrollContainer}>
          <InfiniteScroll
            scrollThreshold={0.9}
            className={classes.infiniteScroll}
            scrollableTarget={`${scrollContainerId}-scroll-container`}
            dataLength={observations?.length}
            next={loadMore}
            hasMore={observations.length < total}
            loader={
              <Grid
                container
                justifyContent="center"
                className={classes.padding}>
                <Grid item>
                  <CircularProgress color="primary" />
                </Grid>
              </Grid>
            }
            endMessage={
              <Grid
                container
                justifyContent="center"
                className={classes.padding}>
                <Grid item>
                  <Typography
                    variant="overline"
                    color="textPrimary"
                    className={classes.endMessage}>
                    {t('observationCardsList.endOfListMessage')}
                  </Typography>
                </Grid>
              </Grid>
            }>
            <Grid container direction="column" spacing={compact ? 0.5 : 2}>
              {observations?.map((observation, index) => {
                const originatorOrCreatorDisplayName = observation.originator
                  ? `${observation.originator.personnel.lastName}, ${observation.originator.personnel.firstName}`
                  : `${observation.createdBy.lastName}, ${observation.createdBy.firstName} (${observation.createdBy.email})`;
                return (
                  <Fragment key={observation.id ?? index}>
                    <Grid item>
                      <CardActionArea
                        className={classNames({
                          [`${classes.disabledCard}`]: !isOnline && !deletable
                        })}
                        onClick={() => handleObservationCardClick(observation)}>
                        <Card
                          data-testid="observation-card"
                          className={classNames(classes.card, {
                            [`${classes.cardClosed}`]:
                              observation.status === 'Closed'
                          })}>
                          <Grid container>
                            <Grid item xs={12}>
                              <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                style={{
                                  marginBottom: 10
                                }}>
                                <Grid item>
                                  <Typography
                                    className={classes.tradePartnerName}>
                                    {observation.tradePartner?.name ??
                                      'Trade Partner TBD'}
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Grid container alignItems="center">
                                    <Grid item>
                                      {!deletable && isSupportRole && (
                                        <Typography
                                          color="textSecondary"
                                          variant="overline"
                                          style={{
                                            textAlign: 'right',
                                            lineHeight: 1,
                                            marginRight: 35
                                          }}>
                                          {t('observationCard.id.label')}{' '}
                                          {observation.id}
                                        </Typography>
                                      )}
                                    </Grid>
                                    <Grid item>
                                      {(isOnline || deletable) &&
                                        domain === Domain.PRIMARY && (
                                          <IconButton
                                            aria-label={t(
                                              'observationCard.editIconButton'
                                            )}
                                            size="small"
                                            component="span"
                                            onClick={() =>
                                              handleObservationCardClick(
                                                observation
                                              )
                                            }>
                                            {deletable && <DeleteIcon />}
                                            {!deletable && <EditIcon />}
                                          </IconButton>
                                        )}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid
                                container
                                direction="row"
                                alignItems="flex-end"
                                justifyContent="space-between">
                                <Grid item xs={12} sm={4} md={3} lg={2}>
                                  <Grid container direction="row" spacing={1}>
                                    <Grid item>
                                      <Typography
                                        color="textSecondary"
                                        variant="subtitle2"
                                        className={classes.label}>
                                        {observation.originator
                                          ? t('observationCard.originator')
                                          : t('observationCard.createdBy')}
                                      </Typography>
                                    </Grid>
                                    <Grid item>
                                      <Tooltip
                                        title={originatorOrCreatorDisplayName}>
                                        <Typography
                                          variant="subtitle2"
                                          className={classes.value}>
                                          {originatorOrCreatorDisplayName}
                                        </Typography>
                                      </Tooltip>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={12} sm={4} md={3} lg={2}>
                                  <Grid container direction="row" spacing={1}>
                                    <Grid item>
                                      <Typography
                                        color="textSecondary"
                                        variant="subtitle2"
                                        className={classes.label}>
                                        Observed
                                      </Typography>
                                    </Grid>
                                    <Grid item>
                                      <Typography
                                        variant="subtitle2"
                                        className={classes.value}>
                                        {formatDate(
                                          observation.observedDateTime
                                        )}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={12} sm={4} md={3} lg={2}>
                                  <Grid container direction="row" spacing={1}>
                                    <Grid item>
                                      <Typography
                                        color="textSecondary"
                                        variant="subtitle2"
                                        className={classes.label}>
                                        Created
                                      </Typography>
                                    </Grid>
                                    <Grid item>
                                      <Tooltip
                                        title={originatorOrCreatorDisplayName}>
                                        <Typography
                                          variant="subtitle2"
                                          className={classes.value}>
                                          {formatDate(observation.createdDate)}
                                        </Typography>
                                      </Tooltip>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={12} sm={4} md={3} lg={2}>
                                  <Grid container direction="row" spacing={1}>
                                    <Grid item>
                                      <Typography
                                        color="textSecondary"
                                        variant="subtitle2"
                                        className={classes.label}>
                                        {t('observationCard.area')}
                                      </Typography>
                                    </Grid>
                                    <Grid item>
                                      <Typography
                                        variant="subtitle2"
                                        className={classes.value}>
                                        {observation.projectArea ?? 'TBD'}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={12} sm={4} md={3} lg={2}>
                                  <Grid container direction="row" spacing={1}>
                                    <Grid item>
                                      <Typography
                                        color="textSecondary"
                                        variant="subtitle2"
                                        className={classes.label}>
                                        {t('observationCard.type')}
                                      </Typography>
                                    </Grid>
                                    <Grid item>
                                      <Typography
                                        variant="subtitle2"
                                        className={classes.value}>
                                        {observation.type === 'Behavioral'
                                          ? t(
                                              'observationCardsList.type.behavioral'
                                            )
                                          : observation.type === 'Environmental'
                                          ? t(
                                              'observationCardsList.type.environmental'
                                            )
                                          : 'TBD'}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} md={3} lg={2}>
                                  <Grid container justifyContent="flex-end">
                                    {type === 'pending' && observation.error && (
                                      <Typography
                                        color="textSecondary"
                                        variant="subtitle2">
                                        {t('observationCard.error')}
                                      </Typography>
                                    )}
                                    {observation.status === 'Draft' &&
                                      !observation.error && (
                                        <Typography
                                          color="textSecondary"
                                          variant="subtitle2">
                                          {t('observationCard.draft')}
                                        </Typography>
                                      )}
                                    {observation.status === 'Closed' &&
                                      !observation.error && (
                                        <Typography
                                          color="textSecondary"
                                          variant="subtitle2">
                                          {t('observationCard.closed')}
                                        </Typography>
                                      )}
                                    {observation.status === 'Open' &&
                                      type === 'pending' &&
                                      !observation.error && (
                                        <Typography
                                          className={classes.daysOpen}
                                          variant="subtitle2">
                                          {t('observationCard.open')}
                                        </Typography>
                                      )}
                                    {observation.status === 'Open' &&
                                      type !== 'pending' && (
                                        <Typography
                                          className={classes.daysOpen}
                                          variant="subtitle2">
                                          {getDayCount(observation.createdDate)}{' '}
                                          {t('observationCard.daysOpen')}
                                        </Typography>
                                      )}
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Card>
                      </CardActionArea>
                    </Grid>
                  </Fragment>
                );
              })}
            </Grid>
          </InfiniteScroll>
        </Box>
      </LoadingOverlay>

      {updateObservationDialogIsOpen && (
        <UpdateObservationDialog
          updateObservationDialogIsOpen={updateObservationDialogIsOpen}
          setUpdateObservationDialogIsOpen={setUpdateObservationDialogIsOpen}
          selectedObservation={selectedObservation}
          deletable={deletable}
          refetchCurrentQueries={refetchCurrentQueries}
        />
      )}
    </>
  );
};

ObservationsCardsList.propTypes = {
  observations: PropTypes.array,
  type: PropTypes.string,
  deletable: PropTypes.bool,
  loadMore: PropTypes.func,
  total: PropTypes.number,
  isLoading: PropTypes.bool,
  refetchCurrentQueries: PropTypes.func
};

export default ObservationsCardsList;
