import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useField } from 'react-final-form-hooks';
import {
  Grid,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Typography
} from '@mui/material';

import useFormValidation from 'hooks/useFormValidation';
import StyledStep from 'shared/Step';
import StyledInput from 'shared/Input';
import TradePartnerSelect from 'components/tradepartners/TradePartnerSelect';
import TradeSelect from 'components/tradepartners/TradeSelect';

const TradePartnerInfoStep = ({
  form,
  isSubcontractor,
  toggleIsSubcontractor,
  isLoading
}) => {
  const { t } = useTranslation();
  const { isRequired } = useFormValidation();
  const displayName = useField('displayName', form);

  const validate = trades => {
    if (!trades || trades?.length < 1) {
      return 'Required';
    }
  };

  const trades = useField('trades', form, validate);
  const parentTradePartner = useField('parentTradePartner', form, isRequired);
  const notes = useField('tradePartnerNotes', form);

  const { values } = form.getState();

  return (
    <StyledStep>
      <Grid container direction="column" spacing={1}>
        <Grid item xs={12}>
          <TradeSelect
            isDisabled={isLoading}
            selectedTrades={trades.input.value}
            meta={trades.meta}
            handleChange={trades.input.onChange}
          />
        </Grid>
        <Grid item xs={12}>
          <StyledInput
            fullWidth={true}
            disabled={isLoading}
            input={displayName.input}
            meta={displayName.meta}
            placeholder={values?.selectedCompany?.name ?? values?.name}
            label={t(
              'addTradePartnerDialog.steps.tradePartnerInfo.fields.displayName'
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <StyledInput
            fullWidth={true}
            multiline={true}
            disabled={isLoading}
            rows={5}
            input={notes.input}
            meta={notes.meta}
            label={t(
              'addTradePartnerDialog.steps.tradePartnerInfo.fields.notes'
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  data-testid="trade-partner-relationship-checkbox"
                  color="primary"
                  variant="outlined"
                  disabled={isLoading}
                  checked={isSubcontractor}
                  onChange={() => toggleIsSubcontractor(!isSubcontractor)}
                />
              }
              label={
                <Typography>
                  {t(
                    'addTradePartnerDialog.steps.tradePartnerInfo.isSubCheckbox'
                  )}
                </Typography>
              }
            />
          </FormGroup>
        </Grid>
        {isSubcontractor && (
          <Grid item xs={12}>
            <TradePartnerSelect
              selectedCompany={values.selectedCompany}
              selectedTradePartner={parentTradePartner.input.value || null}
              isDisabled={isLoading}
              handleChange={parentTradePartner.input.onChange}
            />
          </Grid>
        )}
      </Grid>
    </StyledStep>
  );
};

TradePartnerInfoStep.propTypes = {
  form: PropTypes.object.isRequired,
  isSubcontractor: PropTypes.bool.isRequired,
  toggleIsSubcontractor: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
};

export default TradePartnerInfoStep;
