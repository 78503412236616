/* eslint-disable no-unused-vars */
import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';

// Notice Assets
import addProjectAdminDark from 'assets/images/addProjectAdminDark.svg';
import addProjectAdminLight from 'assets/images/addProjectAdminLight.svg';
import deleteTradePartnerDark from 'assets/images/deleteTradePartnerDark.svg';
import deleteTradePartnerLight from 'assets/images/deleteTradePartnerLight.svg';
import noProjectsDark from 'assets/images/noProjectsDark.svg';
import noProjectsLight from 'assets/images/noProjectsLight.svg';
import errorNotice from 'assets/images/errorNotice.svg';
import errorNoticeDark from 'assets/images/errorNoticeDark.svg';
import unauthorizedAccessDark from 'assets/images/unauthorizedAccessDark.svg';
import unauthorizedAccessLight from 'assets/images/unauthorizedAccessLight.svg';
import underConstructionDark from 'assets/images/underConstructionDark.svg';
import underConstructionLight from 'assets/images/underConstructionLight.svg';
import pageNotFoundDark from 'assets/images/404pageNotFoundDark.svg';
import pageNotFoundLight from 'assets/images/404pageNotFoundLight.svg';
import noObservationsNoticeDark from 'assets/images/noObservationsDark.svg';
import noObservationsNoticeLight from 'assets/images/noObservationsLight.svg';
import offlineNoAccessNoticeDark from 'assets/images/offlineNoAccessDark.svg';
import offlineNoAccessNoticeLight from 'assets/images/offlineNoAccessLight.svg';

// Dashboard Tiles
import tradePartnersDark from 'assets/images/dashboardTileImages/dark/tradePartnersDark.png';
import tradePartnersLight from 'assets/images/dashboardTileImages/light/tradePartnersLight.png';
import workerHoursDark from 'assets/images/dashboardTileImages/dark/workerHoursDark.png';
import workerHoursLight from 'assets/images/dashboardTileImages/light/workerHoursLight.png';
import permissionsDark from 'assets/images/dashboardTileImages/dark/permissionsDark.png';
import permissionsLight from 'assets/images/dashboardTileImages/light/permissionsLight.png';
import personnelDark from 'assets/images/dashboardTileImages/dark/personnelDark.png';
import personnelLight from 'assets/images/dashboardTileImages/light/personnelLight.png';
import orientationsDark from 'assets/images/dashboardTileImages/dark/orientationsDark.png';
import orientationsLight from 'assets/images/dashboardTileImages/light/orientationsLight.png';
import safeDark from 'assets/images/dashboardTileImages/dark/safeDark.png';
import safeLight from 'assets/images/dashboardTileImages/light/safeLight.png';
import ahaBuilderDark from 'assets/images/dashboardTileImages/dark/ahaBuilderDark.png';
import ahaBuilderLight from 'assets/images/dashboardTileImages/light/ahaBuilderLight.png';

// Logos
import hpLogoDark from 'assets/images/logos/hpLogoDark.png';
import hpLogoLight from 'assets/images/logos/hpLogoLight.png';
import safetyConnectLogoDark from 'assets/images/logos/SafetyConnectLogoLandscapeBlack.png';
import safetyConnectLogoLight from 'assets/images/logos/SafetyConnectLogoLandscapeWhite.png';
import safetyConnectLogoMinimalDark from 'assets/images/logos/SafetyConnectLogoMinimalBlack.png';
import safetyConnectLogoMinimalLight from 'assets/images/logos/SafetyConnectLogoMinimalWhite.png';
import safeLogoDark from 'assets/images/logos/safeLogoDark.png';
import safeLogoLight from 'assets/images/logos/safeLogoLight.png';
import safeLogoDarkMobile from 'assets/images/logos/safeLogoDarkMobile.png';
import safeLogoLightMobile from 'assets/images/logos/safeLogoLightMobile.png';

// Background Images
import safeBackgroundLight from 'assets/images/safeBackgroundLight.jpg';
import safeBackgroundDark from 'assets/images/safeBackgroundDark.jpg';
import bg from 'assets/images/bg.jpg';

// Pages
import ObservationDashboardPage from 'pages/ObservationsDashboardPage';

// App imports
import LoadingSpinner from 'components/common/LoadingSpinner';

// Prefetch API data
import usePrefetchOfflineModeData from 'hooks/usePrefetchOfflineModeData';

const OfflinePrefetch = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  usePrefetchOfflineModeData();

  useEffect(() => {
    const imgs = [
      addProjectAdminDark,
      addProjectAdminLight,
      deleteTradePartnerDark,
      deleteTradePartnerLight,
      noProjectsDark,
      noProjectsLight,
      errorNotice,
      errorNoticeDark,
      unauthorizedAccessDark,
      unauthorizedAccessLight,
      underConstructionDark,
      underConstructionLight,
      pageNotFoundDark,
      pageNotFoundLight,
      noObservationsNoticeDark,
      noObservationsNoticeLight,
      offlineNoAccessNoticeDark,
      offlineNoAccessNoticeLight,
      tradePartnersDark,
      tradePartnersLight,
      workerHoursDark,
      workerHoursLight,
      permissionsDark,
      permissionsLight,
      personnelDark,
      personnelLight,
      orientationsDark,
      orientationsLight,
      safeDark,
      safeLight,
      safeBackgroundDark,
      safeBackgroundLight,
      bg,
      hpLogoDark,
      hpLogoLight,
      safetyConnectLogoDark,
      safetyConnectLogoLight,
      safetyConnectLogoMinimalDark,
      safetyConnectLogoMinimalLight,
      safeLogoDark,
      safeLogoLight,
      safeLogoDarkMobile,
      safeLogoLightMobile,
      ahaBuilderDark,
      ahaBuilderLight
    ];

    const cacheImages = async srcArray => {
      const promises = await srcArray.map(src => {
        return new Promise(function(resolve, reject) {
          const img = new Image();

          img.src = src;
          img.onload = resolve();
          img.onerror = reject();
        });
      });

      await Promise.all(promises);

      setIsLoading(false);
    };

    cacheImages(imgs);
  }, []);

  return (
    <Fragment>
      {isLoading ? (
        <LoadingSpinner isFullScreen={true} />
      ) : (
        <Fragment>{children}</Fragment>
      )}
    </Fragment>
  );
};

OfflinePrefetch.propTypes = {
  children: PropTypes.node.isRequired
};

export default OfflinePrefetch;
