import { atom, useRecoilState } from 'recoil';

import { DEFAULT_ROWS_PER_PAGE } from 'constants/personnelListPageTable';

const DEFAULT_SORT_ORDER = {
  name: 'personnel.fullName',
  direction: 'asc'
};

const defaultState = {
  first: DEFAULT_ROWS_PER_PAGE,
  skip: 0,
  page: 0,
  filter: {},
  filterList: [],
  order: DEFAULT_SORT_ORDER,
  search: null
};

const personnelListPageTableState = atom({
  key: 'personnelListPageTableState',
  default: defaultState
});

function usePersonnelListPageTableState() {
  const [tableState, setTableState] = useRecoilState(
    personnelListPageTableState
  );

  function handleTableStateChange(newTableState) {
    setTableState({ ...tableState, ...newTableState });
  }
  return [tableState, { handleTableStateChange }];
}

export default usePersonnelListPageTableState;
