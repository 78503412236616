import { GET_ATTENDEE } from 'graphql/attendee';
import useSoteriaQuery from 'hooks/useSoteriaQuery';

const useAttendee = (attendeeId, shouldSkip = false) => {
  const { data: { attendee = null } = {}, loading, error } = useSoteriaQuery({
    gql: GET_ATTENDEE,
    queryHookOptions: {
      skip: shouldSkip || !attendeeId,
      variables: { attendeeId }
    }
  });

  return { attendee, loading, error };
};

export default useAttendee;
