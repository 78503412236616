import React from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import {
  FormGroup,
  FormControlLabel,
  Switch,
  Typography,
  Tooltip
} from '@mui/material';

import useIsManualOfflineModeOn from 'store/manualOfflineMode';
import useIsOnline from 'store/onlineDetection';

const useStyles = makeStyles(theme => ({
  labelText: { fontSize: '0.875rem' }
}));

const OfflineModeSwitch = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    isOfflineModeEnabled,
    setIsManualOfflineModeEnabled
  } = useIsManualOfflineModeOn();
  const { isOnline } = useIsOnline();

  const offlineModeLabel = isOfflineModeEnabled
    ? t('offlineModeSwitch.offlineEnabled')
    : t('offlineModeSwitch.offlineDisabled');

  const handleToggle = event => {
    const isOfflineEnabled = event.target.checked;
    setIsManualOfflineModeEnabled(isOfflineEnabled);
  };

  return (
    <FormGroup row>
      <Tooltip title={isOnline ? '' : t('offlineModeSwitch.disabled')}>
        <FormControlLabel
          labelPlacement="start"
          control={
            <Switch
              disabled={!isOnline}
              inputProps={{
                'data-testid': 'offline-mode-switch',
                role: 'button',
                'aria-pressed': isOfflineModeEnabled,
                'aria-label': `toggle confirmation switch to ${offlineModeLabel}`
              }}
              checked={isOfflineModeEnabled}
              onChange={handleToggle}
              value={
                isOfflineModeEnabled
                  ? 'offlineModeEnabled'
                  : 'offlineModeDisabled'
              }
              color="primary"
              size="small"
            />
          }
          label={
            <Typography color="textPrimary" className={classes.labelText}>
              {offlineModeLabel}
            </Typography>
          }
        />
      </Tooltip>
    </FormGroup>
  );
};

export default OfflineModeSwitch;
