import React, { useState } from 'react';
import copy from 'copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Card, Typography, Link } from '@mui/material';

import useToast from 'hooks/useToast';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import OrientationQrCodeDialog from 'components/orientations/OrientationQrCodeDialog';

const useStyles = makeStyles(theme => ({
  urlContainer: {
    border: '1px solid' + theme.palette.secondary.contrastText,
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(1),
    wordBreak: 'break-all'
  },
  urlLink: { wordBreak: 'break-all', '&:hover': { cursor: 'pointer' } }
}));

const OrientationRegistrationOptionsCard = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { projectId, orientationId } = useParams();
  const { displayToast } = useToast();
  const [qrCodeDialogIsOpen, toggleQrCodeDialogIsOpen] = useState(false);

  const URL = `${process.env.REACT_APP_SELF_REGISTRATION_URL}/projects/${projectId}/orientations/${orientationId}`;

  const handleQrCodeClick = () => {
    toggleQrCodeDialogIsOpen(true);
  };

  return (
    <>
      <Card className="padding">
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography color="textPrimary" className="bold" component="h2">
              {t('orientationRegistrationOptionsCard.title')}
            </Typography>
          </Grid>
          <Grid item>
            <Grid container direction="column" spacing={4}>
              <Grid item>
                <Typography component="h3">
                  {t(
                    'orientationRegistrationOptionsCard.shareLinkInstructions'
                  )}
                </Typography>
                <div className={classes.urlContainer}>
                  <Typography>{URL}</Typography>
                </div>
                <Grid container justifyContent="flex-end">
                  <StyledButtonPrimary
                    aria-label={t(
                      'orientationRegistrationOptionsCard.actions.copy'
                    )}
                    label={t('orientationRegistrationOptionsCard.actions.copy')}
                    onClick={() =>
                      copy(
                        URL,
                        displayToast(
                          t(
                            'orientationRegistrationOptionsCard.toasts.copy.success'
                          ),
                          'success'
                        )
                      )
                    }
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Grid item>
                <Typography gutterBottom component="h3">
                  {t(
                    'orientationRegistrationOptionsCard.useQrCodeInstructionsPartOne'
                  )}
                  <Link
                    className={classes.urlLink}
                    aria-label={
                      'open the self registration page in a new window'
                    }
                    tabIndex="0"
                    onKeyDown={() => window.open(URL, '_blank', 'noopener')}
                    onClick={() => window.open(URL, '_blank', 'noopener')}
                    underline="hover">
                    {URL}
                  </Link>
                  {t(
                    'orientationRegistrationOptionsCard.useQrCodeInstructionsPartTwo'
                  )}
                  <Link
                    className={classes.urlLink}
                    aria-label={
                      'display the QR code for the selected orientation'
                    }
                    tabIndex="0"
                    onKeyDown={handleQrCodeClick}
                    onClick={handleQrCodeClick}
                    underline="hover">
                    {t(
                      'orientationRegistrationOptionsCard.useQrCodeInstructionsPartThree'
                    )}
                  </Link>
                </Typography>
                <StyledButtonPrimary
                  aria-label={t(
                    'orientationRegistrationOptionsCard.actions.print'
                  )}
                  label={t('orientationRegistrationOptionsCard.actions.print')}
                  onClick={handleQrCodeClick}
                  variant="outlined"
                />
              </Grid>
              <Grid item>
                <Typography gutterBottom component="h3">
                  {t(
                    'orientationRegistrationOptionsCard.selfManageInstructions'
                  )}
                </Typography>
                <StyledButtonPrimary
                  aria-label={t(
                    'orientationRegistrationOptionsCard.actions.manageAttendees'
                  )}
                  label={t(
                    'orientationRegistrationOptionsCard.actions.manageAttendees'
                  )}
                  onClick={() =>
                    history.push(
                      `/projects/${projectId}/orientations/${orientationId}/attendees`
                    )
                  }
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
      <OrientationQrCodeDialog
        orientationQrCodeDialogIsOpen={qrCodeDialogIsOpen}
        toggleOrientationQrCodeDialogIsOpen={toggleQrCodeDialogIsOpen}
      />
    </>
  );
};

export default OrientationRegistrationOptionsCard;
