import { atom, useRecoilState } from 'recoil';

const defaultOfflineSyncState = atom({
  key: 'offlineSyncState',
  default: {
    observations: {
      isSyncing: false,
      pending: []
    },
    errorObservationIdToRetry: null,
    isRetryingErrorSync: false
  }
});

function useOfflineSync() {
  const [offlineSyncState, setOnlineSyncState] = useRecoilState(
    defaultOfflineSyncState
  );

  function updateObservations(newObservationsState) {
    setOnlineSyncState({
      ...offlineSyncState,
      observations: {
        ...offlineSyncState.observations,
        ...newObservationsState
      }
    });
  }

  function updateErrorObservationSyncState(id, syncValue) {
    setOnlineSyncState({
      ...offlineSyncState,
      errorObservationIdToRetry: id,
      isRetryingErrorSync: syncValue
    });
  }

  return {
    offlineSyncState,
    offlineSyncActions: { updateObservations, updateErrorObservationSyncState }
  };
}

export default useOfflineSync;
