import gql from 'graphql-tag';

// Queries
export const GET_AD_CONTACTS = gql`
  query GetAdContacts(
    $search: String!
    $first: Int
    $filter: AdContactFilter
    $clientId: String
  ) {
    adContacts(
      search: $search
      first: $first
      filter: $filter
      clientId: $clientId
    ) {
      upn
      name
      firstName
      lastName
      email
      jobTitle
      officeLocation
      thumbnailUrl
      microsoftAdId
      employeeId
      dob
    }
  }
`;
