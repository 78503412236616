import React, { useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, useField } from 'react-final-form-hooks';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, useTheme, Typography } from '@mui/material';

import useFormValidation from 'hooks/useFormValidation';
import useToast from 'hooks/useToast';
import useCurrentUser from 'hooks/useCurrentUser';
import StyledDialog from 'shared/Dialog';
import StyledInput from 'shared/Input';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import StyledButtonSecondary from 'shared/Buttons/ButtonSecondary';
import AdUserSelect from 'components/ad/AdUserSelect';
import { REQUEST_ACCESS_TO_PROJECT } from 'graphql/soteriaAdUser';

const useStyles = makeStyles(theme => ({
  form: { paddingTop: theme.spacing(2) }
}));

export const RequestProjectAccess = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { projectId } = useParams();
  const { currentUser } = useCurrentUser();
  const [isOpen, toggleDialog] = useState(false);
  const [requestAccess, { loading }] = useMutation(REQUEST_ACCESS_TO_PROJECT);
  const { displayToast } = useToast();
  const [isSuccess, setIsSuccess] = useState(false);
  const theme = useTheme();

  const { form, values, pristine } = useForm({
    onSubmit: () => {}
  });

  const { isRequired } = useFormValidation();
  const selectedAdUser = useField('selectedAdUser', form);
  const projectNumber = useField('projectNumber', form, isRequired);

  const onSubmit = () => {
    const requestToSend = {
      requesterUpn: currentUser.upn,
      approverUpn: values.selectedAdUser.upn,
      projectName: null,
      projectNumber: values?.projectNumber?.trim(),
      projectId
    };
    requestAccess({ variables: requestToSend })
      .then(() => {
        setIsSuccess(true);
        displayToast(t('requestProjectAccessDialog.toasts.success'), 'success');
        handleClose();
      })
      .catch(error => {
        if (error?.message?.includes('Project not found')) {
          displayToast(
            t('requestProjectAccessDialog.toasts.notFound.error', {
              projectNumber: values.projectNumber
            }),
            'error'
          );
        } else {
          console.error('Request access Error: ', error);
          displayToast(t('requestProjectAccessDialog.toasts.error'), 'error');
        }
      });
  };

  function handleClose() {
    form.reset();
    toggleDialog(false);
    setIsSuccess(false);
  }

  const canSubmit = () => {
    if (projectId) {
      return !(!values.selectedAdUser || loading);
    } else {
      return !(
        pristine ||
        !values.projectNumber ||
        !values.selectedAdUser ||
        loading
      );
    }
  };

  return (
    <div>
      <Grid container justifyContent="center">
        <Grid item>
          <StyledButtonPrimary
            data-testid="request-admin-access-button"
            style={{ marginTop: theme.spacing(2) }}
            label={t('requestProjectAccessDialog.requestButton')}
            onClick={() => toggleDialog(true)}
          />
        </Grid>
      </Grid>
      <StyledDialog
        data-testid="request-project-access-dialog"
        isLoading={loading}
        fullWidth={true}
        title={t('requestProjectAccessDialog.title')}
        contentGroupHeader={t(
          'requestProjectAccessDialog.requestInstructionsGeneral'
        )}
        content={
          <form className={classes.form}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <AdUserSelect
                  selectedUser={selectedAdUser.input.value || null}
                  meta={selectedAdUser.meta}
                  handleChange={selectedAdUser.input.onChange}
                  isDisabled={isSuccess || loading}
                  placeholder={
                    <Typography>
                      {t(
                        'requestProjectAccessDialog.sectionHeading.selectProjectAdmin'
                      )}
                    </Typography>
                  }
                  required={true}
                />
              </Grid>
              {!projectId && (
                <Grid item xs={12}>
                  <Grid container direction="column">
                    <Grid item>
                      <StyledInput
                        required
                        fullWidth={true}
                        meta={projectNumber.meta}
                        input={{
                          ...projectNumber.input,
                          ...{ 'data-testid': 'project-number-input' }
                        }}
                        disabled={isSuccess || loading}
                        label={t(
                          'requestProjectAccessDialog.fieldLabels.projectNumber'
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </form>
        }
        actions={
          <Fragment>
            {isSuccess && (
              <StyledButtonPrimary
                label={t('requestProjectAccessDialog.closeButton')}
                onClick={handleClose}
              />
            )}
            {!isSuccess && (
              <Fragment>
                <StyledButtonSecondary
                  onClick={handleClose}
                  label={t('requestProjectAccessDialog.cancelButton')}
                  disabled={loading}
                />
                <StyledButtonPrimary
                  data-testid="request-access-submit-button"
                  onClick={onSubmit}
                  label={t('requestProjectAccessDialog.submitButton')}
                  disabled={!canSubmit()}
                />
              </Fragment>
            )}
          </Fragment>
        }
        isOpen={isOpen}
        handleClose={handleClose}
      />
    </div>
  );
};

export default RequestProjectAccess;
