import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-final-form-hooks';
import { useMutation } from '@apollo/react-hooks';
import { useHistory, useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';

import useToast from 'hooks/useToast';
import StyledDialog from 'shared/Dialog';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import StyledButtonSecondary from 'shared/Buttons/ButtonSecondary';
import TradePartnerAhaForm from '../TradePartnerAhaForm';
import { generateTransactionKey } from 'utils';
import useSafeProject from 'hooks/useSafeProject';
import { CREATE_AHA_TRADE_PARTNER_CATEGORY } from 'graphql/aha/tradePartnerCategory';

const AddTradePartnerAhaDialog = ({
  addTradePartnerAHADialogIsOpen,
  toggleAddTradePartnerAHADialog,
  refetchQuery
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { projectId } = useParams();
  const { displayToast } = useToast();
  const [addedFile, setAddedFile] = useState([]);
  const [fileToDelete, setFileToDelete] = useState([]);
  const [transactionKey, setTransactionKey] = useState(
    generateTransactionKey()
  );
  const [isLoadingForRefetch, setIsLoadingForRefetch] = useState(false);
  const { safeProject } = useSafeProject(projectId);
  const allTradePartners = safeProject?.tradePartners ?? [];

  const [
    createAhaTradePartnerCategory,
    { loading: isLoadingForCreate }
  ] = useMutation(CREATE_AHA_TRADE_PARTNER_CATEGORY);

  const isLoading = isLoadingForCreate || isLoadingForRefetch;

  const { form, values, pristine } = useForm({
    /* istanbul ignore next */
    onSubmit: () => {}
  });

  const canSubmit = (() => {
    const { error, invalid } = form.getState();
    return !(
      invalid ||
      error ||
      pristine ||
      isLoading ||
      addedFile?.length < 1
    );
  })();

  const handleSubmit = () => {
    setIsLoadingForRefetch(true);
    createAhaTradePartnerCategory({
      variables: {
        input: {
          tradePartnerId: values.selectedTradePartner.id,
          name: values.tradePartnerAhaName,
          ahaFile: {
            description: values.tradePartnerAhaDescription,
            file: addedFile[0],
            projectArea: values.projectArea
          },
          transactionKey: transactionKey
        }
      }
    })
      .then(async response => {
        await refetchQuery();
        setIsLoadingForRefetch(false);
        handleClose();
        displayToast(t('addTradePartnerAhaDialog.toasts.success'), 'success');
        history.push(
          `/projects/${projectId}/ahas/trade-partner-aha/${response?.data?.createAhaTradePartnerCategory?.id}/files`
        );
      })
      .catch(error => {
        console.error('Add Trade Partner AHA File Error: ', error);
        displayToast(t('addTradePartnerAhaDialog.toasts.error'), 'error');
      });
  };

  const handleClose = () => {
    form.reset();
    form.resetFieldState('selectedTradePartner');
    form.resetFieldState('tradePartnerAhaName');
    form.resetFieldState('tradePartnerAhaDescription');
    setAddedFile([]);
    setFileToDelete([]);
    toggleAddTradePartnerAHADialog(false);
    setTransactionKey(generateTransactionKey());
  };

  return (
    <StyledDialog
      title={t('addTradePartnerAhaDialog.title')}
      isOpen={addTradePartnerAHADialogIsOpen}
      handleClose={handleClose}
      isLoading={isLoading}
      content={
        <TradePartnerAhaForm
          allTradePartners={allTradePartners}
          form={form}
          addedFiles={addedFile}
          setAddedFiles={setAddedFile}
          filesToDelete={fileToDelete}
          setFilesToDelete={setFileToDelete}
          isLoading={isLoading}
        />
      }
      actions={
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <StyledButtonSecondary
                  label={t('addTradePartnerAhaDialog.actions.cancelButton')}
                  disabled={isLoading}
                  onClick={handleClose}
                />
              </Grid>
              <Grid item>
                <StyledButtonPrimary
                  label={t('addTradePartnerAhaDialog.actions.submitButton')}
                  disabled={!canSubmit}
                  onClick={handleSubmit}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
    />
  );
};

AddTradePartnerAhaDialog.propTypes = {
  addTradePartnerAHADialogIsOpen: PropTypes.bool,
  toggleAddTradePartnerAHADialog: PropTypes.func,
  refetchQuery: PropTypes.func
};

export default AddTradePartnerAhaDialog;
