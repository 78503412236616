import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Fuse from 'fuse.js';
import { useTranslation } from 'react-i18next';

import StyledAutoComplete from 'shared/AutoComplete';
import { getTradePartnerPersonnelLabel } from 'utils';

const getFilteredOptions = (options, inputValue) => {
  if (inputValue) {
    const fuse = new Fuse(options, fuseOptions);
    return fuse.search(inputValue);
  } else {
    return options
      .map((option, index) => ({
        item: option,
        score: 1,
        refIndex: index
      }))
      .sort((a, b) =>
        a.item.personnel.lastName.toLowerCase() >
        b.item.personnel.lastName.toLowerCase()
          ? 1
          : -1
      );
  }
};

const fuseOptions = {
  isCaseSensitive: false,
  includeScore: true,
  shouldSort: true,
  keys: [
    'customId',
    'personnel.firstName',
    'personnel.middleName',
    'personnel.lastName',
    'personnel.nickname',
    'personnel.upn'
  ]
};

const TradePartnerPersonnelSelect = ({
  selectedTradePartnerPersonnel,
  handleChange,
  tradePartnerPersonnelOptions,
  isLoading,
  isRequired = false,
  isDisabled,
  placeholder,
  label,
  ...rest
}) => {
  const { t } = useTranslation();
  const [inputValue, handleInputChange] = useState('');

  const filteredOptions = tradePartnerPersonnelOptions
    ? getFilteredOptions(tradePartnerPersonnelOptions, inputValue)
    : [];

  return (
    <StyledAutoComplete
      label={label}
      placeholder={t('tradePartnerSelectPlaceholder')}
      selectedValue={selectedTradePartnerPersonnel}
      handleInputChange={handleInputChange}
      handleChange={handleChange}
      options={filteredOptions.map(option => ({
        label: getTradePartnerPersonnelLabel(option.item),
        value: option.item.id,
        ...option.item
      }))}
      isRequired={isRequired}
      isDisabled={isDisabled}
      isLoading={isLoading}
      {...rest}
    />
  );
};

TradePartnerPersonnelSelect.propTypes = {
  selectedTradePartnerPersonnel: PropTypes.any,
  handleChange: PropTypes.func.isRequired,
  tradePartnerPersonnelOptions: PropTypes.array,
  isLoading: PropTypes.bool,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  placeholder: PropTypes.string,
  label: PropTypes.string.isRequired
};

export default TradePartnerPersonnelSelect;
