import { atom, useRecoilState } from 'recoil';

const DEFAULT_ROWS_PER_PAGE = 100;
const DEFAULT_FILTERS = {};

const defaultState = {
  first: DEFAULT_ROWS_PER_PAGE,
  skip: 0,
  filter: DEFAULT_FILTERS,
  search: '',
  hasActiveFilters: false
};

function hasActiveFilters(filterState) {
  let hasActiveFilters = false;

  if (typeof filterState === 'object') {
    for (const value of Object.values(filterState)) {
      if (value) {
        hasActiveFilters = true;
        break;
      }
    }
  }

  return hasActiveFilters;
}

const ahaGlobalStepListStateAtom = atom({
  key: 'ahaGlobalStepListState',
  default: defaultState
});

function useAhaGlobalStepListState() {
  const [ahaGlobalStepListState, setAhaGlobalStepListState] = useRecoilState(
    ahaGlobalStepListStateAtom
  );

  function handleAhaGlobalStepListStateChange(newState) {
    if (hasActiveFilters(newState.filter)) {
      newState.hasActiveFilters = true;
    } else {
      newState.hasActiveFilters = false;
    }

    setAhaGlobalStepListState({
      ...ahaGlobalStepListState,
      ...newState
    });
  }
  return [
    ahaGlobalStepListState,
    {
      handleAhaGlobalStepListStateChange
    }
  ];
}

export default useAhaGlobalStepListState;
