import React from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import { FormGroup, FormControlLabel, Switch, Typography } from '@mui/material';

import useSettings from 'hooks/useSettings';
import { LIGHT_THEME, DARK_THEME } from 'constants/settings';

const useStyles = makeStyles(theme => ({
  labelText: { fontSize: '0.875rem' }
}));

const ThemeSwitch = () => {
  const classes = useStyles();
  const { settings, setSettings } = useSettings();
  const { t } = useTranslation();
  const themeLabel =
    settings.theme === LIGHT_THEME
      ? t('settings.themeSelector.options.light')
      : t('settings.themeSelector.options.dark');

  const handleThemeChange = event => {
    const isLightTheme = event.target.checked;
    const selectedTheme = isLightTheme ? LIGHT_THEME : DARK_THEME;
    setSettings({ theme: selectedTheme });
  };

  return (
    <FormGroup row>
      <FormControlLabel
        labelPlacement="start"
        control={
          <Switch
            inputProps={{
              'data-testid': 'theme-input-switch',
              role: 'button',
              'aria-pressed': settings && settings.theme,
              'aria-label': `toggle confirmation switch to ${settings &&
                settings.theme}`
            }}
            checked={settings && settings.theme === LIGHT_THEME}
            onChange={handleThemeChange}
            value={
              settings && settings.theme === LIGHT_THEME
                ? 'isLightTheme'
                : 'isDarkTheme'
            }
            color="primary"
            size="small"
          />
        }
        label={
          <Typography color="textPrimary" className={classes.labelText}>
            {themeLabel}
          </Typography>
        }
      />
    </FormGroup>
  );
};

export default ThemeSwitch;
