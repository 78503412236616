import gql from 'graphql-tag';

// Queries

export const GLOBAL_TEMPLATE_SEARCH = gql`
  query GlobalAhaTemplatesAsAhaGlobalTemplateCategorySubsets($search: String!) {
    globalAhaTemplatesAsAhaGlobalTemplateCategorySubsets(search: $search) {
      id
      name
      ahaTemplates {
        id
        ahaGlobalTemplateCategory {
          id
          ahaTemplates {
            id
          }
        }
        created
        createdBy {
          id
          name
          jobTitle
        }

        lastModified
        type
      }
      categoryTemplateCount
    }
  }
`;

export const GET_GLOBAL_TEMPLATE = gql`
  query GetGlobalTemplate($id: ID!) {
    ahaTemplate(id: $id) {
      id
      ahaGlobalTemplateCategory {
        id
        ahaTemplates {
          id
          ahaActivitys {
            id
            name
            isActive
            created
            lastModified
            order
          }
          ahaEquipments {
            ahaTemplate {
              id
            }

            inspectionRequirements
            isActive
            name
            order
            trainings
          }
          ahaGlobalTemplateCategory {
            id
          }
          ahaProjectTemplate {
            id
          }
          ahaSteps {
            id
          }
          created
          createdBy {
            id
            name
            jobTitle
          }
          id
          lastModified
          type
        }
      }
      created
      createdBy {
        id
        name
        jobTitle
      }
      id
      lastModified
      type
    }
  }
`;

// TODO: FIX THE FOLLOWING QUERY AFTER PAULS FIX
export const GET_ALL_GLOBAL_TEMPLATES = gql`
  query globalAhaTemplatesAsAhaGlobalTemplateCategorySubsets($search: String!) {
    globalAhaTemplatesAsAhaGlobalTemplateCategorySubsets(search: $search) {
      id
      # name
      # ahaTemplates {
      #   id
      #   created
      #   createdBy {
      #     id
      #     name
      #     jobTitle
      #   }
      #   lastModified
      #   type
      # }
      # categoryTemplateCount
    }
  }
`;

export const PAGINATED_GLOBAL_TEMPLATES = gql`
  query PaginatedGlobalTemplates(
    $first: Int!
    $order: [AhaTemplateOrderInput!]
    $search: String
    $skip: Int
  ) {
    paginatedGlobalAhaTemplates(
      first: $first
      order: $order
      search: $search
      skip: $skip
    ) {
      total
      ahaTemplates {
        id
        lastModified
        type
        created
        createdBy {
          id
          name
          jobTitle
        }
        ahaGlobalTemplateCategory {
          id
          name
          ahaTemplates {
            id
          }
        }
      }
    }
  }
`;

// Mutations

export const CREATE_GLOBAL_AHA_TEMPLATE = gql`
  mutation CreateGlobalAhaTemplate($input: GlobalAhaTemplateCreateInput!) {
    createGlobalAhaTemplate(input: $input) {
      id
      type
      ahaGlobalTemplateCategory {
        ahaTemplates {
          id
          type
        }
        id
        name
      }
    }
  }
`;

export const UPDATE_GLOBAL_AHA_TEMPLATE = gql`
  mutation UpdateGlobalAhaTemplate($input: AhaTemplateUpdateInput!) {
    updateGlobalAhaTemplate(input: $input) {
      ahaEquipments {
        ahaTemplate {
          id
        }
        id
        inspectionRequirements
        isActive
        name
        order
        trainings
      }
      ahaGlobalTemplateCategory {
        ahaTemplates {
          id
        }
        id
        name
      }
      ahaProjectTemplate {
        id
      }
      ahaSteps {
        id
      }
      created
      createdBy {
        id
        name
        jobTitle
      }
      id
      lastModified
      type
    }
  }
`;

export const DELETE_GLOBAL_TEMPLATE = gql`
  mutation DeleteGlobalTemplate($id: ID!) {
    deleteGlobalAhaTemplate(id: $id) {
      id
    }
  }
`;
