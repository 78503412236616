import { useState, useEffect } from 'react';
import { ApolloClient } from 'apollo-client';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { MSAL_SCOPES, redirectUri } from './msalConfig';
import { useMsal } from '@azure/msal-react';
import {
  InteractionStatus,
  InteractionRequiredAuthError
} from '@azure/msal-browser';
import { resolvers, typeDefs } from '../graphql';
import { ApolloLink, split } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import {
  persistCache,
  LocalStorageWrapper,
  CachePersistor
} from 'apollo3-cache-persist';

const url = new URL(process.env.REACT_APP_SOTERIA_API_URL);
const isDevelopment = process.env.NODE_ENV === 'development';
const webSocketUrl = `${isDevelopment ? 'ws' : 'wss'}://${url.hostname}:${
  isDevelopment ? url.port : ''
}/ws`;

const httpLink = createUploadLink({
  uri: url.origin
});

const downgradedRolesLink = setContext(async (_, { headers }) => {
  const downgradedRoles = window.sessionStorage.getItem('downgradedRoles');
  if (!downgradedRoles) {
    return headers;
  }
  return {
    headers: {
      ...headers,
      'X-Downgraded-Roles': downgradedRoles
    }
  };
});

const onlineOnlyModeCache = new InMemoryCache({
  freezeResults: true
});

const useSoteriaApolloClient = () => {
  const [offlineCapableClient, setOfflineCapableClient] = useState(undefined);
  const [onlineModeOnlyClient, setOnlineModeOnlyClient] = useState(undefined);
  const [persistor, setPersistor] = useState(undefined);
  const { instance: msalInstance, inProgress: oauthProgress } = useMsal();
  useEffect(() => {
    if (oauthProgress !== InteractionStatus.None) {
      return;
    }
    const wsLink = new WebSocketLink({
      uri: webSocketUrl,
      options: {
        reconnect: true,
        connectionParams: async () => {
          console.log('Silently acquiring token for Apollo websocket.');
          const token = await msalInstance.acquireTokenSilent({
            scopes: MSAL_SCOPES,
            account: msalInstance.getActiveAccount(),
            redirectUri
          });
          return {
            authorization: token ? `Bearer ${token.idToken}` : ''
          };
        },
        connectionCallback: error => {
          if (
            error instanceof InteractionRequiredAuthError ||
            error?.message === 'token expired'
          ) {
            console.log(
              `Apollo websocket encountered an InteractionRequiredAuthError: ${JSON.stringify(
                error
              )}`
            );
            if (oauthProgress === InteractionStatus.None) {
              msalInstance.acquireTokenRedirect({
                scopes: MSAL_SCOPES,
                account: msalInstance.getActiveAccount(),
                redirectUri
              });
            } else {
              console.log('Login interaction already in progress');
            }
          } else if (error) {
            console.error(
              `Apollo websocket encountered an unhandled error: ${JSON.stringify(
                error
              )}`
            );
          }
        }
      }
    });

    const errorLink = onError(({ networkError }) => {
      if (
        networkError instanceof InteractionRequiredAuthError ||
        networkError?.bodyText === 'token expired'
      ) {
        console.log(
          `Apollo encountered an InteractionRequiredAuthError: ${JSON.stringify(
            networkError
          )}`
        );
        if (oauthProgress === InteractionStatus.None) {
          msalInstance.acquireTokenRedirect({
            scopes: MSAL_SCOPES,
            account: msalInstance.getActiveAccount(),
            redirectUri
          });
        } else {
          console.log('Login interaction already in progress');
        }
      } else if (networkError) {
        console.error(
          `Apollo encountered an unhandled error: ${JSON.stringify(
            networkError
          )}`
        );
      }
    });

    const authLink = setContext(async (_, { headers }) => {
      console.log('Silently acquiring token for Apollo.');
      const token = await msalInstance.acquireTokenSilent({
        scopes: MSAL_SCOPES,
        account: msalInstance.getActiveAccount(),
        redirectUri
      });
      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token.idToken}` : ''
        }
      };
    });

    const link = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      wsLink,
      errorLink
        .concat(authLink)
        .concat(downgradedRolesLink)
        .concat(httpLink)
    );

    setOnlineModeOnlyClient(
      new ApolloClient({
        link: ApolloLink.from([link]),
        cache: onlineOnlyModeCache,
        resolvers: resolvers,
        typeDefs: typeDefs,
        assumeImmutableResults: true
      })
    );

    const cache = new InMemoryCache({
      freezeResults: true
    });

    const client = new ApolloClient({
      link: ApolloLink.from([link]),
      cache: cache,
      resolvers: resolvers,
      typeDefs: typeDefs,
      assumeImmutableResults: true,
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'cache-and-network'
        }
      }
    });

    persistCache({
      cache,
      storage: new LocalStorageWrapper(window.localStorage)
    }).then(() => {
      client.onResetStore(async () =>
        cache.writeData({
          data: {}
        })
      );
      const persistor = new CachePersistor({
        cache: client.cache,
        storage: window.localStorage
      });
      setPersistor(persistor);
      setOfflineCapableClient(client);
    });
    return () => {};
  }, [msalInstance, oauthProgress, InteractionStatus]);

  const purgeOfflineCache = () => {
    if (offlineCapableClient) {
      return offlineCapableClient.clearStore().then(() => {
        console.log('Cache cleared successfully');
        return persistor.purge().then(() => {
          console.log('Persistor purged successfully');
        });
      });
    } else {
      return Promise.resolve();
    }
  };

  return {
    offlineCapableClient,
    onlineModeOnlyClient,
    persistor,
    purgeOfflineCache
  };
};

export default useSoteriaApolloClient;
