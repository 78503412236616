import makeStyles from '@mui/styles/makeStyles';
const useObservationStyles = () => {
  return makeStyles(theme => ({
    root: {
      backgroundImage: `url(${theme.safeBackground})`,
      backgroundSize: 'cover',
      position: 'fixed',
      width: '100%',
      minHeight: 'calc(100vh - 112px)',
      height: 'auto',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      [theme.breakpoints.down('md')]: {
        paddingTop: 0
      },
      '& .MuiTabs-scroller': {
        paddingTop: 12
      },
      '& .PrivateTabIndicator-root': {
        backgroundColor: 'transparent !important'
      }
    },
    rootAsOnline: {
      paddingTop: 112,
      [theme.breakpoints.down('md')]: {
        paddingTop: 56
      }
    },
    redTab: {
      backgroundColor: `${theme.palette.primary.main} !important`,
      border: `1px solid ${theme.palette.primary.main} !important`,
      color: `${theme.palette.primary.contrastText} !important`
    },
    whiteTab: {
      backgroundColor: `${theme.palette.primary.contrastText} !important`,
      border: `1px solid ${theme.palette.primary.contrastText} !important`,
      color: `${theme.palette.primary.main} !important`
    },
    searchInput: {
      width: 500,
      [theme.breakpoints.down('lg')]: { width: 'calc(100vw - 16px)' },
      '& .MuiInput-underline:before': {
        borderBottom: '2px solid' + theme.palette.primary.main
      },
      '& .MuiInputBase-input': {
        fontSize: '1rem !important',
        fontWeight: 'bold !important',
        color: theme.palette.secondary.contrastText + '!important',
        padding: theme.spacing(1, 0, 0, 2),
        backgroundColor: theme.palette.background.paper,
        borderRadius: 4
      },
      '&:hover': {
        borderBottomColor: theme.palette.primary.main
      }
    },
    container: {
      height: 'calc(100vh - 64px)',
      [theme.breakpoints.down('md')]: {
        height: 'calc(100vh - 56px)'
      }
    },
    noObservationsContainer: {
      height: 'calc(100vh - 64px)',
      marginTop: theme.spacing(6),
      [theme.breakpoints.down('md')]: {
        height: 'calc(100vh - 56px)',
        marginTop: theme.spacing(2)
      }
    },
    noObservationsMessage: {
      backgroundColor: theme.palette.primary.contrastText,
      padding: theme.spacing(2),
      borderRadius: 4,
      color: theme.palette.secondary.contrastText
    },
    tab: {
      marginTop: 4,
      marginLeft: 4,
      border: '1px solid' + theme.palette.secondary.dark,
      boxShadow: '0px 10px 10px rgba(0, 0, 0, .4)',
      minHeight: '24px !important',
      padding: theme.spacing(0.5, 0, 0, 0),
      borderRadius: '8px 8px 0 0',
      paddingRight: 35
    },
    tabs: {
      minHeight: theme.spacing(3),
      marginTop: theme.spacing(0.5)
    },
    tabLabel: {
      textTransform: 'capitalize',
      fontWeight: 'bolder',
      padding: theme.spacing(0, 1),
      paddingTop: 4,
      [theme.breakpoints.down('md')]: {
        fontSize: '0.875rem'
      }
    },
    card: {
      borderLeft: '8px solid' + theme.palette.primary.main,
      borderRadius: 0,
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(1.5, 1.5, 1.5, 2)
      },
      [theme.breakpoints.up('xs')]: { padding: theme.spacing(0.5, 1.5, 3.5, 2) }
    },
    tradePartnerName: { fontWeight: 'bold' },
    daysOpen: { color: theme.palette.primary.main },
    filterMenuContainer: {
      padding: theme.spacing(1, 0, 0, 4),
      [theme.breakpoints.down('md')]: { padding: theme.spacing(0, 0, 0, 1) }
    },
    searchContainer: { padding: '12px 0px 20px 0px' },
    icon: {
      color: theme.palette.secondary.contrastText
    },
    whiteBadge: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      top: '14px !important',
      right: '-15px !important',
      [theme.breakpoints.down('md')]: { right: '-12px !important' }
    },
    redBadge: {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.contrastText,
      top: '14px !important',
      right: '-15px !important',
      [theme.breakpoints.down('md')]: { right: '-12px !important' }
    },
    contentContainer: {
      [theme.breakpoints.down('lg')]: { padding: 10 }
    },
    loadingSpinner: {
      backgroundColor: 'rgba(0,0,0,0)',
      height: 505,
      overflowX: 'hidden',
      [theme.breakpoints.down('md')]: { height: 'calc(100vh - 360px)' }
    },
    sortIcon: { color: theme.palette.secondary.contrastText },
    sortLabel: {
      fontWeight: 'bold',
      display: 'inline',
      color: theme.palette.secondary.contrastText,
      marginRight: theme.spacing(1)
    },
    createdDateSortButton: {
      fontSize: '0.75rem',
      fontWeight: 'bold',
      borderRadius: 16
    },
    paddingTop: { paddingTop: theme.spacing(3) },
    totalResults: { marginTop: theme.spacing(2), fontWeight: 'bold' },
    title: {
      fontSize: '2rem',
      color: theme.palette.secondary.contrastText,
      fontFamily: 'Roboto, ui-sans-serif, sans-serif',
      fontWeight: 400
    }
  }));
};

export default useObservationStyles;
