import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography, Card, Chip } from '@mui/material';
import { useMutation, useQuery } from '@apollo/react-hooks';
import EditSafetyHoursSupervisorsDialog from 'components/personnel/EditSafetyHoursSupervisorsDialog';
import { GET_ALL_SAFETY_HOURS_SUPERVISORS } from 'graphql/personnel';
import { SET_SAFETY_HOURS_SUPERVISORS_ON_TRADE_PARTNER_PERSONNEL } from 'graphql/tradePartnerPersonnel';
const useStyles = makeStyles(theme => ({
  titleContainer: { paddingBottom: theme.spacing(1) },
  titleText: { display: 'inline', fontWeight: 'bold' },
  link: {
    display: 'inline',
    textDecoration: 'underline',
    color: theme.palette.secondary.contrastText,
    '&:hover': { cursor: 'pointer' }
  },
  card: { padding: theme.spacing(3) },
  chip: {
    margin: 5,
    overflow: 'visible',
    textOverflow: 'inherit',
    whiteSpace: 'break-spaces'
  },
  chipContent: {
    border: '1px solid' + theme.palette.primary.main,
    color: theme.palette.primary.main,
    [theme.breakpoints.down('md')]: { height: 'fit-content', maxWidth: 245 },
    overflow: 'visible',
    textOverflow: 'inherit',
    whiteSpace: 'break-spaces'
  },
  label: {
    paddingTop: 1,
    [theme.breakpoints.down('md')]: {
      overflow: 'visible',
      textOverflow: 'inherit',
      whiteSpace: 'break-spaces'
    }
  }
}));
const SafetyHoursSupervisorsCard = ({ tradePartnerPersonnel }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { data: supervisorsData, loading: isSupervisorsLoading } = useQuery(
    GET_ALL_SAFETY_HOURS_SUPERVISORS
  );
  const [
    setSafetyHoursSupervisorsOnTradePartnerPersonnel,
    { loading: isSetSupervisorsOnPersonnelLoading }
  ] = useMutation(SET_SAFETY_HOURS_SUPERVISORS_ON_TRADE_PARTNER_PERSONNEL);
  const handleSubmit = formValue => {
    return setSafetyHoursSupervisorsOnTradePartnerPersonnel({
      variables: {
        tradePartnerPersonnelIds: [tradePartnerPersonnel.id],
        supervisorIds: formValue?.map(selection => selection.value.id) ?? []
      }
    });
  };
  return (
    <Card className={classes.card}>
      <Grid container>
        <Grid item xs={12}>
          <Grid container direction="column">
            <Grid item>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                className={classes.titleContainer}>
                <Grid item>
                  <Typography className={classes.titleText}>
                    {t('personnelSupervisors.title')}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={3}>
                <Grid item>
                  <Grid container direction="column">
                    {!!tradePartnerPersonnel?.supervisedBy?.length &&
                      tradePartnerPersonnel.supervisedBy.map(
                        (safetyHoursSupervisor, index) => {
                          return (
                            <div key={index}>
                              <Grid item className={classes.chip}>
                                <Chip
                                  className={classes.chipContent}
                                  data-testid="safety-hours-supervisor-chip"
                                  color="primary"
                                  label={
                                    <Typography className={classes.label}>
                                      {`${safetyHoursSupervisor.fullName} - ${safetyHoursSupervisor.soteriaAdUser?.employeeId}`}
                                    </Typography>
                                  }
                                  variant="outlined"
                                  size="small"
                                />
                              </Grid>
                            </div>
                          );
                        }
                      )}
                    {!tradePartnerPersonnel?.supervisedBy?.length && (
                      <Typography color="textSecondary" gutterBottom>
                        {t(
                          'editSafetyHoursSupervisors.fields.noSupervisorsDisclaimer'
                        )}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <EditSafetyHoursSupervisorsDialog
                supervisedBy={tradePartnerPersonnel.supervisedBy}
                buttonLabel={t(
                  'editSafetyHoursSupervisors.supervisorsButton.edit'
                )}
                dialogTitle={t('editSafetyHoursSupervisors.title')}
                supervisors={supervisorsData?.safetyHoursSupervisors ?? []}
                setSupervisorsCallback={handleSubmit}
                getSupervisorsLoading={isSupervisorsLoading}
                setSupervisorsLoading={isSetSupervisorsOnPersonnelLoading}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};
SafetyHoursSupervisorsCard.propTypes = {
  tradePartnerPersonnel: PropTypes.object.isRequired
};
export default SafetyHoursSupervisorsCard;
