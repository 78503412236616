/* eslint-disable react/no-unescaped-entities */
import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import { useField, useForm } from 'react-final-form-hooks';
import PictureAsPdf from '@mui/icons-material/PictureAsPdf';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorIcon from '@mui/icons-material/Error';
import makeStyles from '@mui/styles/makeStyles';
import {
  Grid,
  Typography,
  Tooltip,
  IconButton,
  Checkbox,
  FormControlLabel,
  FormGroup
} from '@mui/material';

import useFormValidation from 'hooks/useFormValidation';
import useToast from 'hooks/useToast';
import StyledInput from 'shared/Input';
import StyledButtonGroup from 'shared/Buttons/ButtonGroup';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import StyledButtonSecondary from 'shared/Buttons/ButtonSecondary';
import StyledDialog from 'shared/Dialog';
import StyledKeyboardDatePickerField from 'shared/KeyboardDatePickerField';
import TrainingSelect from 'components/common/TrainingSelect';
import PdfViewer from 'components/common/PdfViewer';
import TrainingDisplayImage from 'components/common/SoteriaFileDisplayImage';
import WordDocViewer from 'components/common/WordDocViewer';
import ExcelViewer from 'components/common/ExcelViewer';
import AddFile from 'components/common/AddFile';
import TrainingDialogViews from 'constants/trainings';
import {
  generateTransactionKey,
  fileIsImage,
  fileIsPdf,
  fileIsDoc,
  fileIsXls
} from 'utils';
import { UPDATE_PERSONNEL } from 'graphql/personnel';
import { isDateTimeInstance } from 'utils/dateTime';

const useStyles = makeStyles(theme => ({
  titleText: { fontWeight: 'bold' },
  formHelperText: { marginLeft: 0 },
  trainingDateLabelText: { fontSize: '0.875rem', marginBottom: -8 },
  trainingDateLabelTextError: { color: theme.palette.primary.light },
  notesContainer: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
    borderRadius: 4,
    marginBottom: 15,
    width: '100%',
    whiteSpace: 'pre-wrap'
  },
  fileContainer: {
    margin: 0,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
    borderRadius: 4,
    justifyContent: 'space-between',
    height: 125,
    [theme.breakpoints.down('lg')]: {
      height: 175,
      justifyContent: 'space-around'
    }
  },
  file: {
    display: 'inline-flex',
    marginBottom: 8,
    marginRight: 8,
    marginLeft: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
  },
  fileInner: {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
    flexDirection: 'row'
  },
  fileSection: { marginBottom: 15 },
  img: {
    display: 'block',
    maxWidth: '90%'
  },
  fileText: {
    display: 'flex',
    alignItems: 'center'
  },
  fileName: {
    whiteSpace: 'nowrap',
    alignSelf: 'center',
    marginLeft: 40,
    maxWidth: 500,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('lg')]: {
      marginLeft: 0,
      width: 150,
      maxWidth: 150
    }
  },
  pdfIcon: {
    width: '100%',
    height: '100%'
  },
  editContainer: {
    justifyContent: 'space-between'
  },
  viewButtonContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  fullSizePreview: {
    display: 'flex',
    justifyContent: 'center'
    // flexDirection: 'column'
  },
  fullSizePlaceholder: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  fullScreenPlaceholderImage: {
    width: '50%',
    height: '50%'
  },
  imagePlaceholderText: {
    alignSelf: 'center',
    fontWeight: 'bold',
    [theme.breakpoints.down('lg')]: {
      textAlign: 'center'
    }
  },
  fullSizeImage: {
    maxWidth: '100%'
  },
  expirationFields: {
    display: 'flex',
    flexDirection: 'column'
  },
  checkboxHelperText: {
    alignSelf: 'center',
    fontSize: '1rem',
    marginTop: 6
  },
  filesSectionLabel: {
    fontSize: '0.75rem',
    fontWeight: 'bold',
    marginBottom: theme.spacing(1)
  }
}));

const TrainingDialog = ({
  isOpen,
  handleClose,
  existingTrainingData,
  trainingDialogView,
  setTrainingDialogView,
  tradePartnerPersonnel,
  isHenselPhelpsEmployee
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isRequired } = useFormValidation();
  const { displayToast } = useToast();
  const [updatePersonnel, { loading }] = useMutation(UPDATE_PERSONNEL);
  const {
    READ_ONLY,
    ADD_TRAINING,
    EDIT_TRAINING,
    VIEW_PDF,
    VIEW_IMAGE,
    CONFIRM_DELETE,
    CONFIRM_CANCEL,
    NO_DOC_PREVIEW,
    NO_EXCEL_PREVIEW
  } = TrainingDialogViews;

  const trainingHasFilesUploaded =
    existingTrainingData?.files?.length > 0 ||
    existingTrainingData?.updatedFiles?.length > 0;

  const [shouldNeverExpire, setShouldNeverExpire] = useState(
    existingTrainingData?.expirationDate === null ||
      existingTrainingData?.expirationDate ===
        'personnelTrainingDialog.trainingForm.neverExpires'
  );

  const formatTrainingName = name => {
    if (name === 'BoomLift') {
      return t('personnelTrainingDialog.formattedTrainingNames.boomLift');
    } else if (name === 'ConfinedSpace') {
      return t('personnelTrainingDialog.formattedTrainingNames.confinedSpace');
    } else if (name === 'FallProtection') {
      return t('personnelTrainingDialog.formattedTrainingNames.fallProtection');
    } else if (name === 'ScissorLift') {
      return t('personnelTrainingDialog.formattedTrainingNames.scissorLift');
    } else if (name === 'ForkLift') {
      return t('personnelTrainingDialog.formattedTrainingNames.forkLift');
    }

    return name;
  };

  let parsedAwardDate;
  let parsedExprDate;
  let formattedTrainingName;

  if (existingTrainingData) {
    parsedAwardDate = Date.parse(existingTrainingData.awardedDate);
    parsedExprDate =
      existingTrainingData.expirationDate === null
        ? null
        : Date.parse(existingTrainingData.expirationDate);
    formattedTrainingName = formatTrainingName(existingTrainingData.name);
  }

  const initialValues = useMemo(
    () => ({
      selectedTrainingName: existingTrainingData
        ? {
            value: formattedTrainingName,
            label: formattedTrainingName
          }
        : '',
      customTrainingName: existingTrainingData?.otherName ?? null,
      selectedTrainingAwardedDate: existingTrainingData
        ? parsedAwardDate
        : null,
      selectedTrainingExpirationDate: existingTrainingData
        ? parsedExprDate
        : null,
      selectedTrainingNotes: existingTrainingData?.notes ?? ''
    }),
    [
      existingTrainingData,
      parsedAwardDate,
      parsedExprDate,
      formattedTrainingName
    ]
  );

  const { form: trainingForm, values, pristine } = useForm({
    onSubmit: () => {},
    initialValues
  });

  // local state
  const { errors: trainingFormErrors } = trainingForm.getState();

  const [addedFiles, setAddedFiles] = useState([]);

  const [filesToDelete, setFilesToDelete] = useState([]);

  const existingFiles =
    existingTrainingData?.updatedFiles?.length > 0
      ? existingTrainingData.updatedFiles
      : [];

  const [
    selectedCompetentPersonOptions,
    setSelectedCompetentPersonOptions
  ] = useState([]);

  const [fileAttachmentToView, setFileAttachmentToView] = useState();

  const validate = expirationDate => {
    if (!shouldNeverExpire && !expirationDate) {
      return t('personnelTrainingDialog.trainingForm.expirationError');
    }

    if (expirationDate) {
      setShouldNeverExpire(false);
      return undefined;
    }

    return undefined;
  };

  const selectedTrainingAwardedDate = useField(
    'selectedTrainingAwardedDate',
    trainingForm,
    isRequired
  );

  const selectedTrainingExpirationDate = useField(
    'selectedTrainingExpirationDate',
    trainingForm,
    validate
  );

  const selectedTrainingNotes = useField('selectedTrainingNotes', trainingForm);

  const isCompetentPersonError = () => {
    if (values?.selectedTrainingName?.value === 'Competent Person') {
      return selectedCompetentPersonOptions.length === 0;
    }

    return false;
  };

  const isCustomNameError = () =>
    values.selectedTrainingName?.label === 'Other' &&
    trainingFormErrors.customTrainingName;

  const isExpirationDateError = () =>
    !shouldNeverExpire && !selectedTrainingExpirationDate.input?.value;

  const canSubmit = () => {
    return !(
      trainingFormErrors.selectedTrainingName ||
      trainingFormErrors.selectedTrainingAwardedDate ||
      isExpirationDateError() ||
      isCompetentPersonError() ||
      isCustomNameError() ||
      loading
    );
  };

  const formatFiles = files => {
    // if this files array contains an id that is also in the filesToDelete array, remove it
    // const finalFiles = files.filter(file => )

    const formattedFilesArray = files.map(file => {
      const filesToBeDeleted = filesToDelete.map(file => file.id);
      const isFileToBeDeleted = filesToBeDeleted.includes(file.id);

      if (isFileToBeDeleted) {
        return null;
      }
      if (file.cachedFile) {
        return file.cachedFile;
      }
      if (file.preview) {
        URL.revokeObjectURL(file.preview);
        Object.assign({}, delete file.preview, file);
      }

      return file;
    });

    return formattedFilesArray.filter(file => file !== null);
  };

  const createFinalTrainingObject = (
    trainingId,
    name,
    customName,
    awardDate,
    exprDate,
    files,
    notes
  ) => {
    const formatDisplayDate = momentObj => {
      if (momentObj === null) {
        return null;
      }
      return moment(momentObj).format('YYYY-MM-DD');
    };
    const filesCopy = [...files];
    const formattedFiles = formatFiles(filesCopy);
    const transactionKey = generateTransactionKey();
    const formattedName = name.replace(/\s/g, '');
    const chooseCustomName = () => {
      if (name === 'Other' && customName) {
        return customName;
      }
      return null;
    };

    if (trainingDialogView === ADD_TRAINING) {
      return {
        transactionKey,
        name: formattedName,
        otherName: chooseCustomName(),
        awardedDate: formatDisplayDate(awardDate),
        expirationDate: formatDisplayDate(exprDate),
        filesToAdd: formattedFiles,
        notes
      };
    } else if (trainingDialogView === EDIT_TRAINING) {
      if (isTrainingCompetentPerson) {
        return {
          transactionKey,
          name: formattedName,
          otherName: chooseCustomName(),
          awardedDate: formatDisplayDate(awardDate),
          expirationDate: formatDisplayDate(exprDate),
          shouldDeleteExpirationDate: shouldNeverExpire,
          filesToAdd: formattedFiles,
          notes
        };
      }
      return {
        id: trainingId,
        name: formattedName,
        otherName: chooseCustomName(),
        awardedDate: formatDisplayDate(awardDate),
        expirationDate: formatDisplayDate(exprDate),
        shouldDeleteExpirationDate: shouldNeverExpire,
        filesToAdd: formattedFiles,
        filesToDelete,
        notes
      };
    }
  };

  const isTrainingOtherCategory =
    values.selectedTrainingName?.value === 'Other';

  const isTrainingCompetentPerson =
    values.selectedTrainingName?.value === 'Competent Person' ||
    values.selectedTrainingName?.value ===
      'personnelTrainingDialog.trainingNamesAndCompetencies.competentPerson';

  const getFinalizedTrainings = () => {
    if (!isTrainingCompetentPerson) {
      setSelectedCompetentPersonOptions([]);
    }

    const trainingName = values.selectedTrainingName?.value;

    const customTrainingName = isTrainingOtherCategory
      ? values.customTrainingName
      : null;

    const trainingId = existingTrainingData?.id ?? null;

    if (isTrainingCompetentPerson) {
      let competencyTrainings = [];
      // create a new training for each competency
      selectedCompetentPersonOptions.forEach(competency => {
        const competencyAsIndividualTraining = createFinalTrainingObject(
          null,
          'Other',
          `${trainingName} - ${competency}`,
          isDateTimeInstance(selectedTrainingAwardedDate.input.value)
            ? selectedTrainingAwardedDate.input.value.toFormat('MM-dd-yyyy')
            : selectedTrainingAwardedDate.input.value,
          isDateTimeInstance(selectedTrainingExpirationDate.input.value)
            ? selectedTrainingExpirationDate.input.value.toFormat('MM-dd-yyyy')
            : selectedTrainingExpirationDate.input.value,
          [...addedFiles, ...existingFiles],
          selectedTrainingNotes.input.value
        );
        competencyTrainings.push(competencyAsIndividualTraining);
      });
      return competencyTrainings;
    } else {
      const singleNewTraining = createFinalTrainingObject(
        trainingId,
        trainingName,
        customTrainingName,
        isDateTimeInstance(selectedTrainingAwardedDate.input.value)
          ? selectedTrainingAwardedDate.input.value.toFormat('MM-dd-yyyy')
          : selectedTrainingAwardedDate.input.value,
        isDateTimeInstance(selectedTrainingExpirationDate.input.value)
          ? selectedTrainingExpirationDate.input.value.toFormat('MM-dd-yyyy')
          : selectedTrainingExpirationDate.input.value,
        addedFiles,
        selectedTrainingNotes.input.value
      );
      return [singleNewTraining];
    }
  };

  const getToastTrainingName = trainings => {
    if (trainings.length > 1) {
      const allNames = trainings.map(training => {
        return training.otherName;
      });
      return allNames.join(', ');
    } else {
      return trainings[0].otherName === null
        ? formatTrainingName(trainings[0].name)
        : trainings[0].otherName;
    }
  };

  const deleteTraining = () => {
    updatePersonnel({
      variables: {
        input: {
          id: tradePartnerPersonnel.personnel.id,
          trainingsToAdd: [],
          trainingsToDelete: [{ id: existingTrainingData.id }],
          trainingsToUpdate: [],
          flagsToAdd: [],
          flagsToDelete: [],
          flagsToUpdate: []
        }
      }
    })
      .then(
        ({ data: { updateTradePartnerPersonnel: tradePartnerPersonnel } }) => {
          displayToast(
            t('personnelTrainingDialog.toasts.delete.success', {
              trainingName: getToastTrainingName([existingTrainingData])
            }),
            'success'
          );
          handleClose();
        }
      )
      .catch(error => {
        displayToast(
          t('personnelTrainingDialog.toasts.delete.error', {
            trainingName: getToastTrainingName([existingTrainingData])
          }),
          'error'
        );
      });
  };

  // fn to handle updating UI & calling api for adding or editing a training
  const upsertTraining = () => {
    const trainingsToUpsert = getFinalizedTrainings();
    if (trainingDialogView === ADD_TRAINING) {
      return updatePersonnel({
        variables: {
          input: {
            id: tradePartnerPersonnel.personnel.id,
            flagsToAdd: [],
            flagsToDelete: [],
            flagsToUpdate: [],
            trainingsToAdd: trainingsToUpsert,
            trainingsToDelete: [],
            trainingsToUpdate: []
          }
        }
      })
        .then(
          ({
            data: { updateTradePartnerPersonnel: tradePartnerPersonnel }
          }) => {
            displayToast(
              isTrainingCompetentPerson && trainingsToUpsert.length > 1
                ? t('personnelTrainingDialog.toasts.add.multipleSuccess', {
                    trainingName: getToastTrainingName(trainingsToUpsert)
                  })
                : t('personnelTrainingDialog.toasts.add.success', {
                    trainingName: getToastTrainingName(trainingsToUpsert)
                  }),

              'success'
            );
            handleClose();
          }
        )
        .catch(error => {
          console.error('EERRRORRR', error);
          displayToast(
            t('personnelTrainingDialog.toasts.add.error', {
              trainingName: getToastTrainingName(trainingsToUpsert)
            }),
            'error'
          );
        });
    } else {
      // if original training is changed to a competent person training, then delete the OG training and create all new competent person trainings
      if (isTrainingCompetentPerson) {
        updatePersonnel({
          variables: {
            input: {
              id: tradePartnerPersonnel.personnel.id,
              trainingsToAdd: trainingsToUpsert,
              trainingsToDelete: [{ id: existingTrainingData.id }],
              trainingsToUpdate: [],
              flagsToAdd: [],
              flagsToDelete: [],
              flagsToUpdate: []
            }
          }
        })
          .then(
            ({
              data: { updateTradePartnerPersonnel: tradePartnerPersonnel }
            }) => {
              displayToast(
                trainingsToUpsert.length > 1
                  ? t('personnelTrainingDialog.toasts.update.multipleSuccess', {
                      trainingName: getToastTrainingName(trainingsToUpsert)
                    })
                  : t('personnelTrainingDialog.toasts.update.success', {
                      trainingName: getToastTrainingName(trainingsToUpsert)
                    }),

                'success'
              );
              handleClose();
            }
          )
          .catch(error => {
            displayToast(
              t('personnelTrainingDialog.toasts.update.error', {
                trainingName: getToastTrainingName(trainingsToUpsert)
              }),
              'error'
            );
          });
      } else {
        updatePersonnel({
          variables: {
            input: {
              id: tradePartnerPersonnel.personnel.id,
              trainingsToAdd: [],
              trainingsToDelete: [],
              trainingsToUpdate: trainingsToUpsert,
              flagsToAdd: [],
              flagsToDelete: [],
              flagsToUpdate: [],
              emergencyContacts: []
            }
          }
        })
          .then(
            ({
              data: { updateTradePartnerPersonnel: tradePartnerPersonnel }
            }) => {
              displayToast(
                t('personnelTrainingDialog.toasts.update.success', {
                  trainingName: getToastTrainingName(trainingsToUpsert)
                }),
                'success'
              );
              handleClose();
            }
          )
          .catch(error => {
            console.error('EERRRORRR', error);
            displayToast(
              t('personnelTrainingDialog.toasts.update.error', {
                trainingName: getToastTrainingName(trainingsToUpsert)
              }),
              'error'
            );
          });
      }
    }
  };

  const generateDialogTitle = () => {
    if (trainingDialogView === ADD_TRAINING) {
      return t('personnelTrainingDialog.trainingForm.addNewTraining');
    }

    if (trainingDialogView === EDIT_TRAINING) {
      return `${t(
        'personnelTrainingDialog.edit.title'
      )} ${existingTrainingData.otherName ??
        formatTrainingName(existingTrainingData.name)}`;
    }

    if (trainingDialogView === READ_ONLY) {
      return `${existingTrainingData.otherName ??
        formatTrainingName(existingTrainingData.name)}`;
    }

    if (
      trainingDialogView === VIEW_PDF ||
      trainingDialogView === VIEW_IMAGE ||
      trainingDialogView === NO_DOC_PREVIEW ||
      trainingDialogView === NO_EXCEL_PREVIEW
    ) {
      return fileAttachmentToView.name;
    }

    if (trainingDialogView === CONFIRM_DELETE) {
      return t('personnelTrainingDialog.delete.title', {
        trainingName:
          existingTrainingData.otherName ??
          formatTrainingName(existingTrainingData.name)
      });
    }

    if (trainingDialogView === CONFIRM_CANCEL) {
      return t('personnelTrainingDialog.cancel.title');
    }
  };

  const generateDialogContent = () => {
    if (trainingDialogView === CONFIRM_CANCEL) {
      return (
        <>
          <Grid container>
            <Grid item>
              <Typography className="margin-bottom">
                {t('personnelTrainingDialog.cancel.dialogContent')}
              </Typography>
            </Grid>
          </Grid>
        </>
      );
    }

    if (trainingDialogView === CONFIRM_DELETE) {
      return (
        <>
          <Grid container>
            <Grid item>
              <Typography className="margin-bottom">
                {t('personnelTrainingDialog.delete.confirmation', {
                  trainingName:
                    existingTrainingData.otherName ??
                    formatTrainingName(existingTrainingData.name)
                })}
              </Typography>
            </Grid>
          </Grid>
        </>
      );
    }

    const openFilePreview = async file => {
      setFileAttachmentToView(file);

      fileIsPdf(file)
        ? setTrainingDialogView(VIEW_PDF)
        : fileIsImage(file)
        ? setTrainingDialogView(VIEW_IMAGE)
        : fileIsDoc(file)
        ? setTrainingDialogView(NO_DOC_PREVIEW)
        : setTrainingDialogView(NO_EXCEL_PREVIEW);
    };

    if (trainingDialogView === VIEW_PDF) {
      return <PdfViewer file={fileAttachmentToView} />;
    }

    if (trainingDialogView === NO_DOC_PREVIEW) {
      return <WordDocViewer file={fileAttachmentToView} />;
    }

    if (trainingDialogView === NO_EXCEL_PREVIEW) {
      return <ExcelViewer file={fileAttachmentToView} />;
    }

    if (trainingDialogView === VIEW_IMAGE) {
      return (
        <div
          className={
            fileAttachmentToView.cachedUrl === null
              ? classes.fullSizePlaceholder
              : classes.fullSizePreview
          }>
          <TrainingDisplayImage
            file={fileAttachmentToView}
            isFullSizePreview={true}
          />
          {!fileAttachmentToView.cachedUrl &&
            !fileAttachmentToView.isMalware && (
              <Typography className={classes.imagePlaceholderText}>
                {t('personnelTrainingDialog.readOnly.imagePlaceholderText')}
              </Typography>
            )}
          {fileAttachmentToView.isMalware && (
            <Typography className={classes.imagePlaceholderText}>
              {t('personnelTrainingDialog.readOnly.isMalware')}
            </Typography>
          )}
        </div>
      );
    }

    if (
      trainingDialogView === ADD_TRAINING ||
      trainingDialogView === EDIT_TRAINING
    ) {
      return (
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12}>
            <TrainingSelect
              selectedCompetentPersonOptions={selectedCompetentPersonOptions}
              setSelectedCompetentPersonOptions={
                setSelectedCompetentPersonOptions
              }
              form={trainingForm}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <StyledKeyboardDatePickerField
              label={t('personnelTrainingDialog.trainingForm.awardedDate')}
              value={selectedTrainingAwardedDate.input.value || null}
              handleDateChange={selectedTrainingAwardedDate.input.onChange}
              meta={selectedTrainingAwardedDate.meta}
              inputProps={{
                onFocus: selectedTrainingAwardedDate.input.onFocus,
                onBlur: selectedTrainingAwardedDate.input.onBlur,
                'data-testid': 'awarded-datepicker'
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Grid container className={classes.expirationFields}>
              <Grid item>
                <StyledKeyboardDatePickerField
                  label={t(
                    'personnelTrainingDialog.trainingForm.expirationDate'
                  )}
                  value={selectedTrainingExpirationDate.input.value || null}
                  handleDateChange={
                    selectedTrainingExpirationDate.input.onChange
                  }
                  meta={selectedTrainingExpirationDate.meta}
                  inputProps={{
                    onFocus: selectedTrainingExpirationDate.input.onFocus,
                    onBlur: selectedTrainingExpirationDate.input.onBlur,
                    'data-testid': 'expiration-datepicker'
                  }}
                  disableFuture={false}
                  required={!shouldNeverExpire}
                />
              </Grid>
              <Grid item>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        data-testid="training-never-expires-checkBox"
                        checked={shouldNeverExpire}
                        onChange={() => {
                          selectedTrainingExpirationDate.input.onChange(null);
                          setShouldNeverExpire(!shouldNeverExpire);
                        }}
                        name="noExpirationDateCheck"
                        color="primary"
                        variant="outlined"
                      />
                    }
                    label={
                      <Typography className={classes.checkboxHelperText}>
                        {t('personnelTrainingDialog.trainingForm.neverExpires')}
                      </Typography>
                    }
                    labelPlacement="end"
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <StyledInput
              input={selectedTrainingNotes.input}
              meta={selectedTrainingNotes.meta}
              label={t('personnelTrainingDialog.trainingForm.notes')}
              multiline={true}
              placeholder={t(
                'personnelTrainingDialog.trainingForm.notesPlaceHolder'
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              color="textSecondary"
              className={classes.filesSectionLabel}>
              {t('personnelTrainingDialog.trainingForm.files')}
            </Typography>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <AddFile
                  fileDataFromDb={
                    existingTrainingData
                      ? existingTrainingData.updatedFiles
                      : []
                  }
                  setAddedFiles={setAddedFiles}
                  addedFiles={addedFiles}
                  existingFiles={existingFiles}
                  setFilesToDelete={setFilesToDelete}
                  filesToDelete={filesToDelete}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    }

    if (trainingDialogView === READ_ONLY) {
      return (
        <>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Grid container direction="column">
                <Grid item>
                  <Grid container className={classes.editContainer}>
                    <Grid item>
                      <Grid container direction="column">
                        <Grid item>
                          <Typography className={classes.titleText}>
                            {t('personnelTrainingDialog.readOnly.awardedDate')}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography>
                            {existingTrainingData.awardedDate}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    {!isHenselPhelpsEmployee && (
                      <Grid item>
                        <Tooltip
                          title={t(
                            'personnelTrainingDialog.readOnly.editTrainingTooltip'
                          )}>
                          <IconButton
                            data-testid="edit-training-button"
                            onClick={() => setTrainingDialogView(EDIT_TRAINING)}
                            size="large">
                            {/* TODO: add visibility condition for Link trainings */}
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="column">
                <Grid item>
                  <Typography className={classes.titleText}>
                    {t('personnelTrainingDialog.readOnly.expirationDate')}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography data-testid="expiration-date-text">
                    {existingTrainingData.expirationDate === null
                      ? t('personnelTrainingDialog.trainingForm.neverExpires')
                      : existingTrainingData.expirationDate}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="column">
                <Grid item>
                  <Typography className={classes.titleText}>
                    {t('personnelTrainingDialog.readOnly.notes')}
                  </Typography>
                </Grid>
                <Grid item className={classes.notesContainer}>
                  <Typography>
                    {existingTrainingData.notes === ''
                      ? t('personnelTrainingDialog.readOnly.noNotesDisclaimer')
                      : existingTrainingData.notes}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item className={classes.filesSection}>
                <Grid container direction="column">
                  <Grid item>
                    <Typography className={classes.titleText}>
                      {t('personnelTrainingDialog.readOnly.uploadedFiles')}
                    </Typography>
                  </Grid>
                  <Grid container>
                    {!trainingHasFilesUploaded && (
                      <>
                        <Grid item className={classes.notesContainer}>
                          <Typography>
                            {t(
                              'personnelTrainingDialog.readOnly.noFilesDisclaimer'
                            )}
                          </Typography>
                        </Grid>
                      </>
                    )}
                    {existingTrainingData.updatedFiles.length > 0 &&
                      existingTrainingData.updatedFiles.map((file, index) => {
                        return (
                          <Grid
                            item
                            key={index}
                            xs={12}
                            className={classes.fileSection}>
                            <Grid
                              container
                              direction="row"
                              className={classes.fileContainer}>
                              <Grid item className={classes.fileText}>
                                <div className={classes.file}>
                                  <div className={classes.fileInner}>
                                    {fileIsImage(file) && (
                                      <TrainingDisplayImage file={file} />
                                    )}
                                    {fileIsPdf(file) && !file.isMalware && (
                                      <PictureAsPdf
                                        className={classes.pdfIcon}
                                        data-testid="pdf-placeholder-image"
                                      />
                                    )}
                                    {(fileIsPdf(file) ||
                                      fileIsDoc(file) ||
                                      fileIsXls(file)) &&
                                      file.isMalware && (
                                        <ErrorIcon
                                          className={classes.pdfIcon}
                                          data-testid="malware-icon-pdf"
                                        />
                                      )}
                                  </div>
                                </div>
                                <Tooltip title={file.name}>
                                  <div className={classes.fileName}>
                                    {file.name}
                                  </div>
                                </Tooltip>
                              </Grid>
                              <Grid
                                item
                                className={classes.viewButtonContainer}>
                                <StyledButtonPrimary
                                  label={t(
                                    'personnelTrainingDialog.actions.viewButton'
                                  )}
                                  onClick={() => openFilePreview(file)}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        );
                      })}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      );
    }
  };

  const generateActions = () => {
    if (trainingDialogView === CONFIRM_DELETE) {
      return (
        <StyledButtonGroup
          onClickAltAction={() => setTrainingDialogView(READ_ONLY)}
          labelAltAction={t('personnelTrainingDialog.actions.backButton')}
          disabledAltAction={loading}
          onClickMainAction={() => {
            deleteTraining();
          }}
          labelMainAction={t('personnelTrainingDialog.actions.deleteButton')}
          disabledMainAction={loading}
        />
      );
    }

    if (trainingDialogView === CONFIRM_CANCEL) {
      return (
        <StyledButtonGroup
          onClickAltAction={() =>
            existingTrainingData
              ? setTrainingDialogView(EDIT_TRAINING)
              : setTrainingDialogView(ADD_TRAINING)
          }
          labelAltAction={t('personnelTrainingDialog.actions.backButton')}
          disabledAltAction={loading}
          onClickMainAction={() => {
            handleClose();
          }}
          labelMainAction={t('personnelTrainingDialog.actions.discardButton')}
          disabledMainAction={loading}
        />
      );
    }

    if (trainingDialogView === ADD_TRAINING) {
      return (
        <>
          <Grid container justifyContent="space-between">
            <Grid item>
              <StyledButtonSecondary
                label={t('personnelTrainingDialog.actions.cancelButton')}
                disabled={loading}
                onClick={() =>
                  !pristine
                    ? setTrainingDialogView(CONFIRM_CANCEL)
                    : handleClose()
                }
              />
            </Grid>
            <Grid item>
              <StyledButtonPrimary
                label={t('personnelTrainingDialog.actions.submitButton')}
                disabled={!canSubmit()}
                onClick={() => upsertTraining()}
              />
            </Grid>
          </Grid>
        </>
      );
    }

    if (trainingDialogView === EDIT_TRAINING) {
      return (
        <>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <StyledButtonSecondary
                label={t('personnelTrainingDialog.actions.cancelButton')}
                disabled={loading}
                onClick={() =>
                  !pristine
                    ? setTrainingDialogView(CONFIRM_CANCEL)
                    : handleClose()
                }
              />
            </Grid>
            <Grid item>
              <StyledButtonGroup
                onClickAltAction={() => setTrainingDialogView(READ_ONLY)}
                labelAltAction={t('personnelTrainingDialog.actions.backButton')}
                disabledAltAction={null}
                onClickMainAction={() => upsertTraining()}
                labelMainAction={t(
                  'personnelTrainingDialog.actions.saveButton'
                )}
                disabledMainAction={!canSubmit()}
              />
            </Grid>
          </Grid>
        </>
      );
    }

    /* TODO: add visibility condition for Link trainings */

    if (trainingDialogView === READ_ONLY) {
      return (
        <>
          <Grid container justifyContent="space-between">
            <Grid item>
              <StyledButtonSecondary
                label={t('personnelTrainingDialog.actions.cancelButton')}
                disabled={loading}
                onClick={handleClose}
              />
            </Grid>
            {!isHenselPhelpsEmployee && (
              <Grid item>
                <StyledButtonPrimary
                  startIcon={<DeleteIcon className={classes.icon} />}
                  label={t(
                    'personnelTrainingDialog.actions.deleteTrainingButton'
                  )}
                  onClick={() => setTrainingDialogView(CONFIRM_DELETE)}
                />
              </Grid>
            )}
          </Grid>
        </>
      );
    }

    if (
      trainingDialogView === VIEW_PDF ||
      trainingDialogView === VIEW_IMAGE ||
      trainingDialogView === NO_DOC_PREVIEW ||
      trainingDialogView === NO_EXCEL_PREVIEW
    ) {
      return (
        <>
          <Grid item>
            <StyledButtonPrimary
              label={t('personnelTrainingDialog.actions.backButton')}
              disabled={loading}
              onClick={() => setTrainingDialogView(READ_ONLY)}
            />
          </Grid>
        </>
      );
    }
  };

  return (
    <StyledDialog
      data-testid="training-dialog"
      isOpen={isOpen}
      shouldShowCloseIconButton={trainingDialogView !== CONFIRM_CANCEL}
      handleClose={handleClose}
      title={generateDialogTitle()}
      isLoading={loading}
      content={generateDialogContent()}
      actions={generateActions()}
    />
  );
};

TrainingDialog.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  existingTrainingData: PropTypes.any,
  trainingDialogView: PropTypes.string,
  setTrainingDialogView: PropTypes.func,
  tradePartnerPersonnel: PropTypes.object,
  isHenselPhelpsEmployee: PropTypes.bool
};

export default TrainingDialog;
