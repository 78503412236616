import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ClimbingBoxLoader } from 'react-spinners';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme, Grid, Typography } from '@mui/material';

import useActiveRoute from 'hooks/useActiveRoute';

const useStyles = makeStyles(theme => ({
  loadingSpinner: {
    backgroundColor: theme.palette.background.default,
    height: 'calc(100vh - 160px)',
    [theme.breakpoints.down('lg')]: {
      height: 'calc(100vh - 152px)'
    },
    [theme.breakpoints.down('md')]: {
      height: 'calc(100vh - 104px)'
    }
  },
  loadingSpinnerOnDashboard: {
    height: '100vh',
    backgroundColor: theme.palette.background.default
  },
  fullScreenLoadingSpinner: {
    width: '100vw',
    height: '100vh',
    backgroundColor: theme.palette.background.default
  },
  minimalLoadingSpinner: {
    height: 'auto',
    backgroundColor: theme.palette.background.default
  }
}));

function LoadingSpinner({ isFullScreen = false, isMinimal = false }) {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const { isActiveRoute } = useActiveRoute();
  const isDashboardPage = isActiveRoute('/projects/:projectId/dashboard');
  const isSafeDashboardPage = isActiveRoute(
    '/projects/:projectId/observations'
  );

  return (
    <Grid
      container
      justifyContent="center"
      alignContent="center"
      alignItems="center"
      data-testid="loading-spinner"
      className={classNames({
        [`${classes.loadingSpinner}`]: !isFullScreen,
        [`${classes.loadingSpinnerOnDashboard}`]:
          isDashboardPage || isSafeDashboardPage,
        [`${classes.fullScreenLoadingSpinner}`]: isFullScreen,
        [`${classes.minimalLoadingSpinner}`]: isMinimal
      })}>
      <Grid item>
        <ClimbingBoxLoader
          color={theme.palette.primary.main}
          loading={true}
          size={10}
        />
      </Grid>
      <Grid item>
        <Typography color="primary">{t('loadingSpinner.loading')}</Typography>
      </Grid>
    </Grid>
  );
}

LoadingSpinner.propTypes = {
  styles: PropTypes.object,
  size: PropTypes.number,
  isFullScreen: PropTypes.bool,
  isMinimal: PropTypes.bool
};

export default LoadingSpinner;
