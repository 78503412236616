import { useEffect } from 'react';
import moment from 'moment';

import { GET_SAFE_PROJECT_WITH_ALL_RELATIONS } from 'graphql/safeProject';
import useSoteriaQuery from 'hooks/useSoteriaQuery';
import { HP_TRADE_PARTNER_NAMES } from 'constants/hpTradePartnerNames';
import useSafeProjectQueryTimestamps from 'store/safeProjectQueryTimestamps';
import { SOTERIA_FETCH_POLICIES } from 'constants/soteriaFetchPolicies';

const EXPIRATION_DURATION = moment.duration(1, 'hours');

const reducer = (allTradePartnerPersonnels, tradePartner) => {
  const activeTradePartnerPersonnels = tradePartner.personnels.filter(
    tpp => tpp.isActive
  );
  allTradePartnerPersonnels.push(...activeTradePartnerPersonnels);
  return allTradePartnerPersonnels;
};

const getHPTradePartnerPersonnels = allTradePartners => {
  const allHenselPhelpsTradePartnerPersonnels = [];

  const henselPhelpsTradePartners =
    allTradePartners.filter(
      tp =>
        HP_TRADE_PARTNER_NAMES.includes(tp.name.toLowerCase().trim()) &&
        tp.isActive
    ) ?? [];

  henselPhelpsTradePartners.forEach(tp =>
    allHenselPhelpsTradePartnerPersonnels.push(tp.personnels)
  );

  return allHenselPhelpsTradePartnerPersonnels.flat();
};

const useSafeProject = safeProjectId => {
  const {
    safeProjectQueryTimeStamps,
    setTimeStampForProject
  } = useSafeProjectQueryTimestamps();

  const shouldCall = safeProjectQueryTimeStamps[safeProjectId]
    ? moment().diff(moment(safeProjectQueryTimeStamps[safeProjectId])) >
      EXPIRATION_DURATION.asMilliseconds()
    : true;

  useEffect(() => {
    if (shouldCall) {
      setTimeStampForProject(safeProjectId);
    }
  }, [
    shouldCall,
    safeProjectQueryTimeStamps,
    setTimeStampForProject,
    safeProjectId
  ]);

  const { data: { safeProject = null } = {}, loading, error } = useSoteriaQuery(
    {
      gql: GET_SAFE_PROJECT_WITH_ALL_RELATIONS,
      queryHookOptions: {
        skip: !safeProjectId,
        variables: { id: safeProjectId }
      },
      soteriaQueryOptions: !shouldCall
        ? { soteriaFetchPolicy: SOTERIA_FETCH_POLICIES.cacheOnly }
        : {}
    }
  );

  const allTradePartnerPersonnels =
    safeProject?.tradePartners?.reduce(reducer, []) ?? [];

  const allHenselPhelpsTradePartnerPersonnels = getHPTradePartnerPersonnels(
    safeProject?.tradePartners ?? []
  );

  return {
    safeProject,
    allTradePartnerPersonnels,
    allHenselPhelpsTradePartnerPersonnels,
    loading,
    error
  };
};

export default useSafeProject;
