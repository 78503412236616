import React, { useState } from 'react';
import PropTypes from 'prop-types';
import EnabledFeaturesContext from 'context/enabledFeatures';
import { enabledFeatures } from 'constants/enabledFeatures';

const features = enabledFeatures[process.env.REACT_APP_ENV];

const EnabledFeaturesProvider = ({ children }) => {
  const [enabledFeatures] = useState(features);

  return (
    <EnabledFeaturesContext.Provider value={{ enabledFeatures }}>
      {children}
    </EnabledFeaturesContext.Provider>
  );
};

EnabledFeaturesProvider.propTypes = {
  children: PropTypes.node
};

export default EnabledFeaturesProvider;
