import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useField } from 'react-final-form-hooks';
import {
  Grid,
  Link,
  Typography,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Radio
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import useFormValidation from 'hooks/useFormValidation';
import useStringIncludesHenselPhelps from 'hooks/useStringIncludesHenselPhelps';
import StyledInput from 'shared/Input';
import StyledKeyboardDatePickerField from 'shared/KeyboardDatePickerField';
import StyledPhoneNumberField from 'shared/PhoneNumberField';
import StyledNotice from 'shared/Notice';
import { HP_EMPLOYEE_INFO_MANAGEMENT_URL } from 'constants/externalLinks';

const useStyles = makeStyles(theme => ({
  inputContainer: { marginBottom: theme.spacing(1) },
  labelText: { fontSize: '0.75rem', fontWeight: 'bold' }
}));

const AttendeeFormFields = ({ form, isLoading, attendee }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isRequired, isValidPastDate, isPhoneNumber } = useFormValidation();
  const { stringIncludesHenselPhelps } = useStringIncludesHenselPhelps();
  const isHenselPhelps = stringIncludesHenselPhelps(attendee.tradePartner.name);

  const firstName = useField('firstName', form, isRequired);
  const middleName = useField('middleName', form);
  const lastName = useField('lastName', form, isRequired);
  const nickname = useField('nickname', form);
  const dob = useField('dob', form, isValidPastDate);
  const phoneNumber = useField('phoneNumber', form, isPhoneNumber);
  const email = useField('email', form);
  const customId = useField('customId', form);
  const notes = useField('notes', form);
  const isSupervisor = useField('isSupervisor', form);

  return (
    <Grid container direction="row" spacing={1}>
      {isHenselPhelps && (
        <Grid item xs={12}>
          <StyledNotice
            title={'Attention Hensel Phelps Attendees'}
            message={
              <>
                {t('attendeeFormFields.henselPhelpsPartOne')}
                <Link
                  aria-label={'open n35.ultipro.com in a new window'}
                  tabIndex="0"
                  onKeyDown={() => {
                    window.open(
                      HP_EMPLOYEE_INFO_MANAGEMENT_URL,
                      '_blank',
                      'noopener'
                    );
                  }}
                  onClick={() => {
                    window.open(
                      HP_EMPLOYEE_INFO_MANAGEMENT_URL,
                      '_blank',
                      'noopener'
                    );
                  }}
                  underline="hover">
                  {t('attendeeFormFields.henselPhelpsPartTwo')}
                </Link>{' '}
                {t('attendeeFormFields.henselPhelpsPartThree')}
              </>
            }
          />
        </Grid>
      )}
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <StyledInput
          input={firstName.input}
          meta={firstName.meta}
          disabled={isLoading || isHenselPhelps}
          label={t('attendeeFormFields.firstName')}
          required
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <StyledInput
          input={middleName.input}
          meta={middleName.meta}
          disabled={isLoading || isHenselPhelps}
          label={t('attendeeFormFields.middleName')}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <StyledInput
          input={lastName.input}
          meta={lastName.meta}
          disabled={isLoading || isHenselPhelps}
          label={t('attendeeFormFields.lastName')}
          required
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <StyledInput
          input={nickname.input}
          meta={nickname.meta}
          disabled={isLoading}
          label={t('attendeeFormFields.nickname')}
        />
      </Grid>
      {!isHenselPhelps && (
        <>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <StyledKeyboardDatePickerField
              label={t('attendeeFormFields.dob')}
              value={dob.input.value || null}
              handleDateChange={dob.input.onChange}
              meta={dob.meta}
              inputProps={{
                onFocus: dob.input.onFocus,
                onBlur: dob.input.onBlur,
                'data-testid': 'dobPicker'
              }}
              disabled={isLoading || isHenselPhelps}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <StyledPhoneNumberField
              label={t('attendeeFormFields.phoneNumber')}
              value={phoneNumber.input.value}
              meta={phoneNumber.meta}
              onChange={phoneNumber.input.onChange}
              disabled={isLoading}
            />
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <StyledInput
          input={email.input}
          meta={email.meta}
          disabled={isLoading || isHenselPhelps}
          label={t('attendeeFormFields.email')}
        />
      </Grid>
      <Grid item xs={12}>
        <StyledInput
          multiline={true}
          disabled={isLoading}
          input={customId.input}
          meta={customId.meta}
          label={t('attendeeFormFields.customId')}
        />
      </Grid>
      <Grid item xs={12} className={classes.inputContainer}>
        <Typography color="textSecondary" className={classes.labelText}>
          {t('attendeeFormFields.supervisorLabel')}
        </Typography>
        <FormControl variant="standard" component="fieldset">
          <RadioGroup
            name="isSupervisor"
            value={isSupervisor.input.value}
            onChange={() =>
              isSupervisor.input.onChange(!isSupervisor.input.value)
            }>
            <FormControlLabel
              value={true}
              control={<Radio color="primary" />}
              label={t('attendeeFormFields.isSupervisor')}
            />
            <FormControlLabel
              data-testid="not-supervisor-radio-button"
              value={false}
              control={<Radio color="primary" />}
              label={t('attendeeFormFields.notIsSupervisor')}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <StyledInput
          multiline={true}
          disabled={isLoading}
          input={notes.input}
          meta={notes.meta}
          label={t('attendeeFormFields.notes')}
        />
      </Grid>
    </Grid>
  );
};

AttendeeFormFields.propTypes = {
  form: PropTypes.any.isRequired,
  isLoading: PropTypes.bool,
  attendee: PropTypes.object.isRequired
};

export default AttendeeFormFields;
