import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useIsOnline from 'store/onlineDetection';
import OfflineNoAccessNotice from 'components/offline/OfflineNoAccessNotice';
import useToast from 'hooks/useToast';

function withOnlineAccessOnly(WrappedComponent) {
  return props => {
    const { t } = useTranslation();
    const { listen } = useHistory();
    const { isOnline } = useIsOnline();
    const { displayToast } = useToast();

    const [shouldShowOfflineMessage, setShouldShowOfflineMessage] = useState(
      !isOnline
    );
    const [alreadyDisplayedToast, setAlreadyDisplayedToast] = useState(false);

    useEffect(() => {
      if (shouldShowOfflineMessage && isOnline) {
        setShouldShowOfflineMessage(false);
        setAlreadyDisplayedToast(false);
      }

      if (isOnline && !shouldShowOfflineMessage && alreadyDisplayedToast) {
        setAlreadyDisplayedToast(false);
      }

      if (!isOnline && !shouldShowOfflineMessage && !alreadyDisplayedToast) {
        displayToast(t('offlineAccessNotice'), 'info');
        setAlreadyDisplayedToast(!alreadyDisplayedToast);
      }
      const deregisterListener = listen(location => {
        if (!isOnline && !shouldShowOfflineMessage) {
          setShouldShowOfflineMessage(true);
        }
      });

      return deregisterListener;
    }, [
      alreadyDisplayedToast,
      displayToast,
      isOnline,
      listen,
      shouldShowOfflineMessage,
      t
    ]);

    if (shouldShowOfflineMessage) {
      return <OfflineNoAccessNotice />;
    } else {
      return <WrappedComponent {...props} />;
    }
  };
}

export default withOnlineAccessOnly;
