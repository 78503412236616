import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import useActiveRoute from 'hooks/useActiveRoute';
import useProjectSubscriptions from 'hooks/useProjectSubscriptions';

const SoteriaSubscriptions = ({ children }) => {
  const { params } = useActiveRoute();
  const { projectId } = params;
  useProjectSubscriptions(projectId);
  return <Fragment>{children}</Fragment>;
};
SoteriaSubscriptions.propTypes = {
  children: PropTypes.node
};

export default SoteriaSubscriptions;
