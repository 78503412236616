import React from 'react';
import PropTypes from 'prop-types';
import LoadingOverlay from 'react-loading-overlay';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Grid } from '@mui/material';

import AhaGlobalLibrary from 'components/ahas/AhaGlobalLibrary';
import SummaryWorkspace from 'components/ahas/SummaryWorkspace';

const useStyles = makeStyles(theme => ({
  container: { maxheight: 'calc(100vh -64px)' },
  libraryContainer: {
    backgroundColor: theme.libraryBackgroundColor,
    height: 'calc(100vh - 112px)',
    padding: theme.spacing(1, 2),
    [theme.breakpoints.down('md')]: { height: 'calc(100vh - 56px)' }
  },
  workspaceContainer: {
    backgroundColor: theme.palette.background.default,
    height: 'calc(100vh - 140px)',
    [theme.breakpoints.down('md')]: { height: 'calc(100vh - 56px)' }
  },
  innerWorkspaceContainer: {
    padding: theme.spacing(1, 1.5)
  }
}));

const AhaSummaryViewEditor = ({
  isGlobal = false,
  hasChanges,
  ahaTemplate,
  originalAhaTemplate,
  setAhaTemplate,
  handleSaveAhaTemplate,
  resetAhaTemplateChanges,
  isLoading
}) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Grid container direction="row" className={classes.container}>
      <Grid
        item
        sm={4}
        className={classes.libraryContainer}
        sx={{ display: { xs: 'none', sm: 'block' } }}>
        <AhaGlobalLibrary
          ahaTemplate={ahaTemplate}
          setAhaTemplate={setAhaTemplate}
        />
      </Grid>
      <Grid item xs={12} sm={8} className={classes.workspaceContainer}>
        <LoadingOverlay
          active={isLoading}
          spinner
          fadeSpeed={50}
          styles={{
            overlay: base => ({
              ...base,
              backgroundColor: theme.palette.background.default,
              height: 'calc(100vh - 112px)'
            }),
            spinner: base => ({
              ...base,
              width: '50px',
              '& svg circle': {
                stroke: theme.palette.primary.main
              }
            })
          }}>
          <div className={classes.innerWorkspaceContainer}>
            <SummaryWorkspace
              isGlobal={isGlobal}
              hasChanges={hasChanges}
              ahaTemplate={ahaTemplate}
              originalAhaTemplate={originalAhaTemplate}
              setAhaTemplate={setAhaTemplate}
              handleSaveAhaTemplate={handleSaveAhaTemplate}
              resetAhaTemplateChanges={resetAhaTemplateChanges}
              isLoading={isLoading}
            />
          </div>
        </LoadingOverlay>
      </Grid>
    </Grid>
  );
};

AhaSummaryViewEditor.propTypes = {
  isGlobal: PropTypes.bool,
  hasChanges: PropTypes.bool.isRequired,
  ahaTemplate: PropTypes.object,
  originalAhaTemplate: PropTypes.object,
  setAhaTemplate: PropTypes.func.isRequired,
  handleSaveAhaTemplate: PropTypes.func.isRequired,
  resetAhaTemplateChanges: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
};

export default AhaSummaryViewEditor;
