export const HP_TRADE_PARTNER_NAMES = [
  'hensel phelps',
  'hensel phelps ce markup',
  'hensel phelps concrete self work',
  'hensel phelps turnkey concrete',
  'hensel phelps services, llc',
  'name of hensel phe',
  'hensel phelps development',
  'hensel phelps services',
  'hensel phelps concrete',
  'hpcc craft',
  'hp concrete',
  'name of hp craft',
  'hensel phelps construction co.',
  'hensel phelps - votum',
  'hensel phelps - ace',
  'hensel phelps - praise',
  'hensel phelps - rl burns',
  'hensel phelps - jcb',
  'hensel phelps construction',
  'hp - day finishers',
  'hpcc'
];
