import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import { useHistory, useParams } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Tooltip,
  CircularProgress,
  CardActionArea
} from '@mui/material';

import { formatDate } from 'utils/dateTime';
import usePendingReviewAhaListState from 'store/pendingReviewAhaListState';
import { GET_PAGINATED_AHA_REVIEWS_ON_PROJECT } from 'graphql/aha/ahaReview';

const useStyles = makeStyles(theme => ({
  scrollContainer: {
    maxHeight: 'calc(100vh - 360px)',
    overflowX: 'auto',
    [theme.breakpoints.down('lg')]: {
      maxHeight: 'calc(100vh - 330px)'
    },
    [theme.breakpoints.down('md')]: {
      maxHeight: 'calc(100vh - 200px)'
    }
  },
  container: { marginBottom: theme.spacing(2) },
  card: {
    borderLeft: '8px solid' + theme.palette.primary.main,
    borderRadius: 0
  },
  bold: { fontWeight: 'bold' },
  limitedTextDesktop: {
    maxWidth: 160,
    whiteSpace: 'noWrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.675rem'
    }
  },
  type: {
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.875rem'
    }
  },
  noAhasFoundContainer: {
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(2),
    borderRadius: 4
  },
  noAhasFoundMessage: {
    padding: theme.spacing(2),
    color: theme.palette.text.primary
  },
  infiniteScroll: { overflow: 'hidden !important' }
}));

const RowItem = ({ label, content, tooltipTitle }) => {
  const classes = useStyles();

  return (
    <Grid container direction="row" spacing={1}>
      <Grid item>
        <Typography
          color="textSecondary"
          variant="subtitle2"
          className={classNames(classes.bold, classes.limitedTextDesktop)}>
          {label}
        </Typography>
      </Grid>
      <Grid item>
        <Tooltip title={tooltipTitle}>
          <Typography
            variant="subtitle2"
            className={classes.limitedTextDesktop}>
            {content}
          </Typography>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

RowItem.propTypes = {
  label: PropTypes.any,
  content: PropTypes.any,
  tooltipTitle: PropTypes.string
};
const PendingReviewTabContent = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const history = useHistory();
  const { projectId } = useParams();
  const [pendingReviewAhaListState] = usePendingReviewAhaListState();
  const [initialPendingReviewAhaListState] = useState(
    pendingReviewAhaListState
  );

  function getApiSortOrder(sortState) {
    return sortState.name && sortState.direction
      ? [
          {
            [sortState.name]: sortState.direction.toUpperCase()
          }
        ]
      : null;
  }

  const { data, loading: isLoading, fetchMore } = useQuery(
    GET_PAGINATED_AHA_REVIEWS_ON_PROJECT,
    {
      variables: {
        filter: initialPendingReviewAhaListState.filter,
        first: initialPendingReviewAhaListState.first,
        order: getApiSortOrder(initialPendingReviewAhaListState.order),
        projectId,
        search: initialPendingReviewAhaListState.search,
        skip: 0
      },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true
    }
  );

  const pendingReviews = data?.paginatedAhaReviewsOnProject?.ahaReviews;
  const total = data?.paginatedAhaReviewsOnProject?.total;
  const length = pendingReviews?.length ?? [];

  const loadMore = variables => {
    return fetchMore({
      variables,
      updateQuery: (_previousResult, { fetchMoreResult, _queryVariables }) => {
        return fetchMoreResult;
      }
    });
  };

  const handlePendingReviewCardClick = review => {
    history.push(
      `/projects/${projectId}/ahas/hensel-phelps-aha/${review?.ahaProjectTemplate?.ahaTemplate?.id}/reviews/${review.id}`
    );
  };

  return (
    <LoadingOverlay
      active={isLoading}
      spinner
      fadeSpeed={50}
      styles={{
        overlay: base => ({
          ...base,
          background: theme.palette.background.overlay
        }),
        spinner: base => ({
          ...base,
          width: '50px',
          '& svg circle': {
            stroke: theme.palette.primary.main
          }
        })
      }}>
      <div
        id={'pending-review-ahas-tab-scroll-container'}
        className={classes.scrollContainer}>
        <InfiniteScroll
          scrollThreshold={0.9}
          className={classes.infiniteScroll}
          scrollableTarget={'pending-review-ahas-tab-scroll-container'}
          dataLength={pendingReviews?.length ?? 0}
          next={loadMore}
          hasMore={length < total}
          loader={
            <Grid container justifyContent="center" className={classes.padding}>
              <Grid item>
                <CircularProgress color="primary" />
              </Grid>
            </Grid>
          }
          endMessage={
            <Grid container justifyContent="center" className={classes.padding}>
              <Grid item>
                <Typography
                  variant="overline"
                  color="textPrimary"
                  className={classes.endMessage}>
                  {t('pendingReviewTabContent.endOfListMessage')}
                </Typography>
              </Grid>
            </Grid>
          }>
          <Grid container>
            {pendingReviews?.map(review => {
              return (
                <Grid
                  item
                  xs={12}
                  key={review.id}
                  className={classes.container}>
                  <Card className={classes.card}>
                    <CardActionArea
                      onClick={() => handlePendingReviewCardClick(review)}>
                      <CardContent style={{ padding: 8 }}>
                        <Grid container>
                          <Grid item xs={12}>
                            <Typography className={classes.type}>
                              {review.ahaProjectTemplate?.ahaTemplate?.type}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={12} md={4} lg={4}>
                            <RowItem
                              label={t('pendingReviewTabContent.preparedBy')}
                              content={review.preparedBy.name}
                              tooltipTitle={review.preparedBy.name}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={4} lg={4}>
                            <RowItem
                              label={t('pendingReviewTabContent.createdOn')}
                              content={formatDate(review.created)}
                              tooltipTitle={formatDate(review.created)}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={4} lg={4}>
                            <RowItem
                              label={t('pendingReviewTabContent.status')}
                              content={review.status}
                              tooltipTitle={review.status}
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              );
            })}
            {pendingReviews?.length < 1 && (
              <Grid container className={classes.noAhasFoundContainer}>
                <Grid item>
                  <Typography className={classes.noAhasFoundMessage}>
                    {t('pendingReviewTabContent.noPendingReviewsMessage')}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
        </InfiniteScroll>
      </div>
    </LoadingOverlay>
  );
};

PendingReviewTabContent.propTypes = {
  aha: PropTypes.any
};

export default PendingReviewTabContent;
