import {
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  Grid,
  Tooltip,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import LoadingOverlay from 'react-loading-overlay';
import { useHistory, useParams } from 'react-router-dom';
import useTradePartnerAhaListState from 'store/tradePartnerAhaListState';
import { formatDate } from 'utils/dateTime';

const useStyles = makeStyles(theme => ({
  scrollContainer: {
    maxHeight: 'calc(100vh - 360px)',
    overflowX: 'auto',
    [theme.breakpoints.down('xl')]: {
      maxHeight: 'calc(100vh - 450px)'
    },
    [theme.breakpoints.down('lg')]: {
      maxHeight: 'calc(100vh - 400px)'
    }
  },
  noAhasFoundContainer: {
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(2),
    borderRadius: 4
  },
  noAhasFoundMessage: {
    padding: theme.spacing(2),
    color: theme.palette.text.primary
  },
  card: {
    borderLeft: '8px solid' + theme.palette.primary.main,
    borderRadius: 0,
    marginBottom: theme.spacing(2)
  },
  bold: { fontWeight: 'bold' },
  limitedTextDesktop: {
    maxWidth: 160,
    whiteSpace: 'noWrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.675rem'
    }
  },
  tpFilesCount: {
    fontWeight: 'bold',
    fontSize: '0.75rem',
    color: theme.palette.primary.main,
    textAlign: 'right',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.675rem'
    }
  },
  tradePartnerName: {
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.875rem'
    }
  },
  infiniteScroll: { overflow: 'hidden !important' }
}));

const RowItem = ({ label, content, tooltipTitle }) => {
  const classes = useStyles();

  return (
    <Grid container direction="row" spacing={1}>
      {label && (
        <Grid item>
          <Typography
            color="textSecondary"
            variant="subtitle2"
            className={classnames(classes.bold, classes.limitedTextDesktop)}>
            {label}
          </Typography>
        </Grid>
      )}

      <Grid item>
        <Tooltip title={tooltipTitle}>
          <Typography
            variant="subtitle2"
            className={classes.limitedTextDesktop}>
            {content}
          </Typography>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

RowItem.propTypes = {
  label: PropTypes.any,
  content: PropTypes.any,
  tooltipTitle: PropTypes.any
};

const TradePartnerAhaTabContent = ({
  ahas,
  fetchMoreTradePartnerAhas,
  isLoadingPaginatedProjectTemplates,
  tradePartnerAhasTotalCount,
  projectArea
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const { projectId } = useParams();
  const history = useHistory();

  const [
    tradePartnerAhaListState,
    { handleTradePartnerAhaListStateChange }
  ] = useTradePartnerAhaListState(projectId);

  const handleLoadNextPage = () => {
    const skip = tradePartnerAhaListState.skip + tradePartnerAhaListState.first;
    handleTradePartnerAhaListStateChange({
      ...tradePartnerAhaListState,
      skip
    });

    const variables = {
      first: tradePartnerAhaListState.first,
      search: tradePartnerAhaListState.search,
      skip
    };

    fetchMoreTradePartnerAhas(variables);
  };

  const ahasList = ahas?.length ?? [];

  const cardClickParams = projectArea ? `?projectArea=${projectArea}` : '';

  const handleCardClick = aha => {
    history.push(
      `/projects/${projectId}/ahas/trade-partner-aha/${aha.id}/files${cardClickParams}`
    );
  };

  const getLastUploadedBy = aha => {
    const latestAhaFile =
      aha?.ahaFiles?.length > 0
        ? aha?.ahaFiles?.reduce((a, b) => {
            return new Date(a.uploaded) > new Date(b.uploaded) ? a : b;
          })
        : '';

    return latestAhaFile?.uploadedBy?.name ?? 'NA';
  };

  const getLatestUploadedDate = aha => {
    const latestAhaFile =
      aha?.ahaFiles?.length > 0
        ? aha?.ahaFiles?.reduce((a, b) => {
            return new Date(a.uploaded) > new Date(b.uploaded) ? a : b;
          })
        : '';

    return formatDate(latestAhaFile?.uploaded) ?? 'NA';
  };

  const getFilesCount = aha => {
    const count =
      aha?.ahaFiles?.length === 1
        ? aha.ahaFiles.length + ' File'
        : aha?.ahaFiles?.length + ' Files';

    return count;
  };

  return (
    <LoadingOverlay
      active={isLoadingPaginatedProjectTemplates}
      spinner
      fadeSpeed={50}
      styles={{
        overlay: base => ({
          ...base,
          background: theme.palette.background.overlay
        }),
        spinner: base => ({
          ...base,
          width: '50px',
          '& svg circle': {
            stroke: theme.palette.primary.main
          }
        })
      }}>
      <div
        id={'trade-partner-ahas-tab-scroll-container'}
        className={classes.scrollContainer}>
        <InfiniteScroll
          scrollThreshold={0.9}
          className={classes.infiniteScroll}
          scrollableTarget={'trade-partner-ahas-tab-scroll-container'}
          dataLength={ahasList.length}
          next={handleLoadNextPage}
          hasMore={ahas?.length < tradePartnerAhasTotalCount}
          loader={
            <Grid container justifyContent="center" className={classes.padding}>
              <Grid item>
                <CircularProgress color="primary" />
              </Grid>
            </Grid>
          }
          endMessage={
            <Grid container justifyContent="center" className={classes.padding}>
              <Grid item>
                <Typography
                  variant="overline"
                  color="textPrimary"
                  className={classes.endMessage}>
                  {t('observationCardsList.endOfListMessage')}
                </Typography>
              </Grid>
            </Grid>
          }>
          <Grid container>
            {ahas?.length > 0 && (
              <>
                {ahas?.map((aha, index) => (
                  <Fragment key={aha.id ?? index}>
                    <Grid item xs={12}>
                      <Card className={classes.card}>
                        <CardActionArea onClick={() => handleCardClick(aha)}>
                          <CardContent style={{ padding: 8 }}>
                            <Grid container justifyContent="space-between">
                              <Grid item xs={12}>
                                <Grid container justifyContent="space-between">
                                  <Grid item>
                                    <Typography
                                      className={classes.tradePartnerName}>
                                      {aha.name}
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <Typography
                                      className={classes.tpFilesCount}>
                                      {getFilesCount(aha)}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={12} sm={12} md={3} lg={3}>
                                <RowItem
                                  content={aha.tradePartner?.name}
                                  tooltipTitle={aha.tradePartner?.name}
                                />
                              </Grid>
                              <Grid item xs={12} sm={12} md={3} lg={3}>
                                <RowItem
                                  label={t(
                                    'tradePartnerAhaTabContent.createdDate'
                                  )}
                                  content={formatDate(aha.created)}
                                  tooltipTitle={formatDate(aha.created)}
                                />
                              </Grid>
                              <Grid item xs={12} sm={12} md={3} lg={3}>
                                <RowItem
                                  label={t(
                                    'tradePartnerAhaTabContent.uploadedBy'
                                  )}
                                  content={getLastUploadedBy(aha)}
                                  tooltipTitle={getLastUploadedBy(aha)}
                                />
                              </Grid>
                              <Grid item xs={12} sm={12} md={3} lg={3}>
                                <RowItem
                                  label={t(
                                    'tradePartnerAhaTabContent.lastUploaded'
                                  )}
                                  content={getLatestUploadedDate(aha)}
                                  tooltipTitle={getLatestUploadedDate(aha)}
                                />
                              </Grid>
                            </Grid>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  </Fragment>
                ))}
              </>
            )}
            {ahas?.length < 1 && (
              <Grid container className={classes.noAhasFoundContainer}>
                <Grid item>
                  <Typography className={classes.noAhasFoundMessage}>
                    No Trade Partner AHAs found.
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
        </InfiniteScroll>
      </div>
    </LoadingOverlay>
  );
};

TradePartnerAhaTabContent.propTypes = {
  ahas: PropTypes.any,
  fetchMoreTradePartnerAhas: PropTypes.func,
  isLoadingPaginatedProjectTemplates: PropTypes.bool,
  tradePartnerAhasTotalCount: PropTypes.number,
  projectArea: PropTypes.string
};

export default TradePartnerAhaTabContent;
