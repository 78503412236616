import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import { Link, Typography } from '@mui/material';

const useStyles = makeStyles(theme => ({
  link: {
    display: 'inline',
    '&:hover': { cursor: 'pointer' }
  },
  simpleSmallLink: {
    textDecorationColor: theme.palette.secondary.contrastText
  },
  simpleLink: { textDecorationColor: theme.palette.secondary.contrastText },
  simpleSmallLinkText: { fontSize: '0.875rem' },
  simpleLinkText: {},
  emphasizedLinkText: { fontWeight: 'bold' },
  boldLinkText: { fontSize: '1.25rem', fontWeight: 'bold' }
}));

// Types

// simple: should apply to links that remain the body font size and use the standard font color
// simpleSmall
// emphasized: should apply to links that remain the body font size and use the secondary color font
// emphasizedSmall
// bold: should apply to links that should be called out and made larger to draw attention

const StyledLink = ({
  type,
  ariaLabel,
  onKeyDown,
  onClick,
  linkText,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Link
      type={type}
      aria-label={ariaLabel}
      className={classNames(classes.link, {
        [`${classes.simpleSmallLink}`]: type === 'simpleSmall',
        [`${classes.simpleLink}`]: type === 'simple',
        [`${classes.emphasizedLink}`]: type === 'emphasized',
        [`${classes.boldLink}`]: type === 'bold'
      })}
      tabIndex="0"
      onKeyDown={onKeyDown}
      onClick={onClick}
      underline="always"
      {...rest}>
      {type === 'simpleSmall' && (
        <Typography className={classes.simpleSmallLinkText} color="textPrimary">
          {linkText}
        </Typography>
      )}
      {type === 'simple' && (
        <Typography className={classes.simpleLinkText} color="textPrimary">
          {linkText}
        </Typography>
      )}
      {type === 'emphasized' && (
        <Typography className={classes.emphasizedLinkText} color="primary">
          {linkText}
        </Typography>
      )}
      {type === 'bold' && (
        <Typography className={classes.boldLinkText} color="primary">
          {linkText}
        </Typography>
      )}
    </Link>
  );
};

StyledLink.propTypes = {
  type: PropTypes.string,
  ariaLabel: PropTypes.string.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  linkText: PropTypes.string.isRequired
};

export default StyledLink;
