import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-final-form-hooks';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import AddIcon from '@mui/icons-material/Add';
import makeStyles from '@mui/styles/makeStyles';
import {
  Grid,
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepContent
} from '@mui/material';

import useToast from 'hooks/useToast';
import useSoteriaStore from 'hooks/useSoteriaStore';
import StyledDialog from 'shared/Dialog';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import StyledButtonSecondary from 'shared/Buttons/ButtonSecondary';
import StyledFab from 'shared/Buttons/Fab';
import {
  SelectCompanyStep,
  NewCompanyStep,
  TradepartnerInfoStep
} from '../CreateTradePartnerSteps';
import { generateTransactionKey, getPhoneValues } from 'utils';
import { ADD_TRADE_PARTNER_TO_PROJECT } from 'graphql/tradePartner';

const useStyles = makeStyles(theme => ({
  icon: { fontSize: '2rem' }
}));

const existingCompanySteps = ['Select Company', 'Connect Project'];

const newCompanySteps = [
  'Select Company',
  'Create New Company',
  'Connect Project'
];

const AddTradePartnerDialog = ({ refetchQuery }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    store: { whatsNewIsOpen = false }
  } = useSoteriaStore();
  const [isOpen, toggleDialog] = useState(false);
  const { displayToast } = useToast();
  const { projectId } = useParams();
  const [activeStepIndex, setActiveStep] = useState(0);
  const [shouldCreateCompany, setShouldCreateCompany] = useState(false);
  const [isSubcontractor, toggleIsSubcontractor] = useState(false);
  const [addTradePartnerToProject, { loading: isLoading }] = useMutation(
    ADD_TRADE_PARTNER_TO_PROJECT
  );

  const [transactionKey, setTransactionKey] = useState(
    generateTransactionKey()
  );

  const steps = shouldCreateCompany ? newCompanySteps : existingCompanySteps;
  const isComplete = activeStepIndex === steps.length - 1;

  const { form, values } = useForm({
    /* istanbul ignore next */
    onSubmit: () => {}, // this function required for useForm but is not used
    initialValues: {
      country: 'United States',
      transactionKey: transactionKey
    }
  });

  const { errors } = form.getState();

  const handleClose = () => {
    toggleDialog(false);
    setTransactionKey(generateTransactionKey());
    setActiveStep(0);
    setShouldCreateCompany(false);
    toggleIsSubcontractor(false);
    form.reset();
  };

  const validateTpDisplayName = tpDisplayName => {
    if (
      tpDisplayName === null ||
      typeof tpDisplayName === 'undefined' ||
      tpDisplayName.trim() === '' ||
      tpDisplayName.length <= 0
    ) {
      tpDisplayName = values?.selectedCompany?.name;
    }
    return tpDisplayName;
  };

  const handleSubmit = () => {
    const input = {
      // company
      company: values?.selectedCompany?.id
        ? null
        : {
            transactionKey: transactionKey,
            name: values.name,
            notes: values.notes,
            location: {
              display: `${values.streetAndNumber} ${
                values.streetDetails ? values.streetDetails : ''
              }, ${values.city}, ${values.stateOrProvince} ${
                values.zipOrPostalCode
              }, ${values.country}`,
              streetAndNumber: values.streetAndNumber,
              streetDetail: values.streetDetails,
              city: values.city,
              stateOrProvince: values.stateOrProvince,
              zipOrPostalCode: values.zipOrPostalCode,
              country: values.country
            },
            contactInformation: {
              website: values.website,
              primaryPhone: getPhoneValues(
                values.primaryPhoneNumber,
                values.primaryPhoneExtension,
                null,
                'Primary'
              ),
              secondaryPhone: getPhoneValues(
                values.secondaryPhoneNumber,
                values.secondaryPhoneExtension,
                null,
                'Secondary'
              ),
              faxPhone: getPhoneValues(values.faxNumber, null, null, 'Fax')
            },
            contacts: []
          },

      // trade partner
      name: validateTpDisplayName(values?.displayName) ?? values?.name,
      projectId: projectId,
      companyId: values?.selectedCompany?.id,
      isActive: true,
      notes: values?.tradePartnerNotes,
      trades: values?.trades?.map(trade => trade.value) ?? [],
      contacts: [],

      // parentContractorId
      parentContractorId: values?.parentTradePartner?.id
    };

    addTradePartnerToProject({
      variables: input
    }).then(
      ({ data: { addTradePartnerToProject: tradePartner } }) => {
        refetchQuery();
        handleSuccess(tradePartner);
      },
      error => {
        console.error('Create TradePartner Error: ', error);
        displayToast(
          t('addTradePartnerDialog.toasts.tradePartner.error'),
          'error'
        );
      }
    );

    function handleSuccess(tradePartner) {
      displayToast(
        t('addTradePartnerDialog.toasts.success', {
          tradePartnerName: tradePartner.name
        }),
        'success'
      );
      handleClose();
    }
  };

  const handleNext = () => {
    setActiveStep(prevActiveStepIndex => prevActiveStepIndex + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStepIndex => prevActiveStepIndex - 1);
  };

  const canContinue = (() => {
    return shouldCreateCompany
      ? canContinueForNewCompanySteps()
      : canContinueForExistingCompanySteps();
  })();

  function canContinueForExistingCompanySteps() {
    switch (activeStepIndex) {
      case 0:
        return !errors.selectedCompany;
      default:
        return false;
    }
  }

  function canContinueForNewCompanySteps() {
    switch (activeStepIndex) {
      case 0:
        return true;
      case 1:
        return !(
          errors.name ||
          errors.streetAndNumber ||
          errors.city ||
          errors.stateOrProvince ||
          errors.zipOrPostalCode ||
          errors.country
        );
      default:
        return false;
    }
  }

  const canSubmit = () => {
    if (isSubcontractor) {
      return !(errors.trades || errors.parentTradePartner || isLoading);
    }
    return !(errors.trades || isLoading);
  };

  function getStepContent() {
    return shouldCreateCompany
      ? getNewCompanySteps()
      : getExistingCompanySteps();
  }

  function getExistingCompanySteps() {
    switch (activeStepIndex) {
      case 0:
        return (
          <SelectCompanyStep
            form={form}
            shouldCreateCompany={shouldCreateCompany}
            setShouldCreateCompany={setShouldCreateCompany}
          />
        );
      case 1:
        return (
          <TradepartnerInfoStep
            form={form}
            isSubcontractor={isSubcontractor}
            toggleIsSubcontractor={toggleIsSubcontractor}
            isLoading={isLoading}
          />
        );
      default:
        return 'Unknown step';
    }
  }

  function getNewCompanySteps() {
    switch (activeStepIndex) {
      case 0:
        return (
          <SelectCompanyStep
            form={form}
            shouldCreateCompany={shouldCreateCompany}
            setShouldCreateCompany={setShouldCreateCompany}
          />
        );
      case 1:
        return <NewCompanyStep form={form} />;
      case 2:
        return (
          <TradepartnerInfoStep
            form={form}
            isSubcontractor={isSubcontractor}
            toggleIsSubcontractor={toggleIsSubcontractor}
            isLoading={isLoading}
          />
        );
      default:
        return 'Unknown step';
    }
  }

  return (
    <Fragment>
      <StyledFab
        data-testid="add-trade-partner-dialog-fab-button"
        onClick={() => toggleDialog(true)}
        shouldShift={whatsNewIsOpen}
        icon={<AddIcon className={classes.icon} />}
      />
      <StyledDialog
        data-testid="add-trade-partner-dialog"
        isOpen={isOpen}
        handleClose={handleClose}
        title={t('addTradePartnerDialog.title')}
        isLoading={isLoading}
        content={
          <form>
            <Grid container justifyContent="center">
              <Grid item xs={12}>
                <Stepper orientation="vertical" activeStep={activeStepIndex}>
                  {steps.map(label => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                      <StepContent>
                        <Typography component="div">
                          {getStepContent(activeStepIndex, form)}
                        </Typography>
                      </StepContent>
                    </Step>
                  ))}
                </Stepper>
              </Grid>
            </Grid>
          </form>
        }
        actions={
          <Grid container justifyContent="space-between">
            <Grid item>
              <StyledButtonSecondary
                label={t('addTradePartnerDialog.actions.cancelButton')}
                disabled={isLoading}
                onClick={handleClose}
              />
            </Grid>
            <Grid item>
              <Grid container spacing={1}>
                <Grid item>
                  {activeStepIndex !== 0 && (
                    <StyledButtonPrimary
                      disabled={isLoading}
                      label={t('addTradePartnerDialog.actions.backButton')}
                      onClick={handleBack}
                    />
                  )}
                </Grid>
                <Grid item>
                  {!isComplete && (
                    <StyledButtonPrimary
                      label={t('addTradePartnerDialog.actions.nextButton')}
                      disabled={!canContinue}
                      onClick={handleNext}
                    />
                  )}
                  {isComplete && (
                    <StyledButtonPrimary
                      label={t('addTradePartnerDialog.actions.submitButton')}
                      disabled={!canSubmit()}
                      onClick={handleSubmit}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      />
    </Fragment>
  );
};

AddTradePartnerDialog.propTypes = {
  refetchQuery: PropTypes.func
};

export default AddTradePartnerDialog;
