import { GET_TRADE_PARTNER } from '../../graphql/tradePartner';
import useSoteriaQuery from 'hooks/useSoteriaQuery';

const useTradePartner = (tradePartnerId, shouldSkip = false) => {
  const {
    data: { tradePartner = null } = {},
    loading,
    error
  } = useSoteriaQuery({
    gql: GET_TRADE_PARTNER,
    queryHookOptions: {
      skip: shouldSkip || !tradePartnerId,
      variables: { id: tradePartnerId }
    }
  });

  return { tradePartner, loading, error };
};

export default useTradePartner;
