import gql from 'graphql-tag';

// Queries
export const PERSONNEL_SEARCH = gql`
  query SearchForPersonnel($search: String, $filter: PersonnelsFilterInput) {
    personnels(search: $search, filter: $filter, first: 100) {
      id
      firstName
      middleName
      lastName
      nickname
      fullName
      dob
      notes
      upn
      soteriaAdUser {
        id
        hpEmployeeNumber
        employeeId
        email
      }
      contactInformation {
        emailAddress {
          email
          type
        }
        primaryPhone {
          number
          display
          extension
          type
        }
      }
      flags {
        id
        status
        reason
        resolution
      }
      tradePartnerHistory {
        id
        isSupervisor
        shouldBeAdLinked
        tradePartner {
          id
          name
          project {
            id
            name
            number
            districtNumber
          }
        }
      }
      profileImage {
        url
        isMalware
        cachedUrl @client
      }
    }
  }
`;

export const GET_PERSONNEL = gql`
  query GetSinglePersonnel($id: ID!) {
    personnel(id: $id) {
      id
      profileImage {
        id
        name
        url
        thumbnailUrl
        sizeInBytes
        contentType
        isScanned
        isMalware
        cachedUrl @client
        cachedFile @client
      }
    }
  }
`;

export const GET_SIMILAR_PERSONNELS = gql`
  query GetSimilarPersonnels($similarToPersonnelId: ID!) {
    similarPersonnels(similarToPersonnelId: $similarToPersonnelId) {
      id
      firstName
      middleName
      lastName
      nickname
      dob
      profileImage {
        id
        cachedUrl @client
      }
      contactInformation {
        emailAddress {
          email
        }
        primaryPhone {
          number
        }
      }
      soteriaAdUser {
        id
        hpEmployeeNumber
        employeeId
        email
      }
      tradePartnerHistory {
        id
        tradePartner {
          id
          project {
            id
          }
        }
      }
    }
  }
`;

// Mutations
export const MERGE_PERSONNEL = gql`
  mutation MergePersonnel(
    $mergeToPersonnelId: ID!
    $mergeFromPersonnelId: ID!
  ) {
    mergePersonnel(
      mergeToPersonnelId: $mergeToPersonnelId
      mergeFromPersonnelId: $mergeFromPersonnelId
    ) {
      id
    }
  }
`;

export const UPDATE_PERSONNEL = gql`
  mutation UpdatePersonnel($input: PersonnelUpdateInput!) {
    updatePersonnel(input: $input) {
      id
      firstName
      middleName
      lastName
      nickname
      fullName
      dob
      notes
      upn
      contactInformation {
        emailAddress {
          email
          type
        }
        phoneNumbers {
          number
          display
          extension
          type
        }
        primaryPhone {
          number
          display
          extension
          type
        }
      }
      flags {
        id
        status
        reason
        resolution
      }
      trainings {
        id
        name
        otherName
        notes
        awardedDate
        expirationDate
        files {
          id
          name
          url
          thumbnailUrl
          sizeInBytes
          contentType
          isScanned
          isMalware
          cachedUrl @client
          cachedFile @client
        }
      }
      tradePartnerHistory {
        id
        isSupervisor
        shouldBeAdLinked
        tradePartner {
          id
          name
          project {
            id
            name
            number
            districtNumber
          }
        }
      }
      profileImage {
        id
        name
        url
        thumbnailUrl
        sizeInBytes
        contentType
        isScanned
        isMalware
        cachedUrl @client
        cachedFile @client
      }
    }
  }
`;

export const DELETE_PERSONNEL = gql`
  mutation DeletePersonnel($id: ID!) {
    deletePersonnel(id: $id) {
      id
    }
  }
`;

export const GET_ALL_SAFETY_HOURS_SUPERVISORS = gql`
  query GetAllSafetyHoursSupervisors {
    safetyHoursSupervisors {
      id
      fullName
      soteriaAdUser {
        id
        employeeId
      }
    }
  }
`;
