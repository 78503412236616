import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import { Grid, Card, Typography } from '@mui/material';

import useToast from 'hooks/useToast';
import StyledDialog from 'shared/Dialog';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import StyledButtonSecondary from 'shared/Buttons/ButtonSecondary';
import StyledNotice from 'shared/Notice';
import StyledConfirmationSwitch from 'shared/ConfirmationSwitch';
import {
  DELETE_TRADE_PARTNER,
  GET_ALL_TRADE_PARTNERS_FOR_PROJECT
} from 'graphql/tradePartner';

const TradePartnerDeleteCard = ({ tradePartner }) => {
  const { t } = useTranslation();
  const [dialogIsOpen, toggleDialog] = useState(false);
  const [isConfirmed, toggleConfirmSwitch] = useState(false);
  const history = useHistory();
  const { displayToast } = useToast();

  const [deleteTradePartner, { loading }] = useMutation(DELETE_TRADE_PARTNER, {
    update(cache, { data: { deleteTradePartner } }) {
      let result = null;
      try {
        result = cache.readQuery({
          query: GET_ALL_TRADE_PARTNERS_FOR_PROJECT,
          variables: { projectId: tradePartner.project.id }
        });
      } catch (err) {
        // https://github.com/apollographql/apollo-feature-requests/issues/1
      }
      if (result?.tradePartnersOnProject) {
        cache.writeQuery({
          query: GET_ALL_TRADE_PARTNERS_FOR_PROJECT,
          variables: { projectId: tradePartner.project.id },
          data: {
            tradePartnersOnProject: result.tradePartnersOnProject.filter(
              tradePartner => tradePartner.id !== deleteTradePartner.id
            )
          }
        });
      }
    }
  });

  const handleClose = () => {
    toggleDialog(false);
    toggleConfirmSwitch(false);
  };

  const handleSubmit = () => {
    deleteTradePartner({
      variables: { id: tradePartner.id }
    })
      .then(() => {
        displayToast(
          t('tradePartnerPage.toasts.delete.success', {
            tradePartnerName: tradePartner.name
          }),
          'success'
        );
        handleClose();
        history.push(`/projects/${tradePartner.project.id}/trade-partners`);
      })
      .catch(error => {
        console.error('Unlink Trade Partner Error: ', error);
        displayToast(t('tradePartnerPage.toasts.delete.error'), 'error');
      });
  };

  const handleChange = event => {
    toggleConfirmSwitch(event.target.checked);
  };

  return (
    <Fragment>
      <Card className="padding">
        <Grid container direction="row" alignItems="center">
          <Grid item xs={12}>
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <Typography color="textPrimary" className="bold">
                  {t('tradePartnerPage.removeCard.title')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Typography gutterBottom color="textSecondary">
                      {t('tradePartnerPage.removeCard.subTitle')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <StyledButtonPrimary
                      disabled={loading}
                      onClick={() => toggleDialog(true)}
                      label={t('tradePartnerPage.removeCard.removeButton')}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
      <StyledDialog
        isOpen={dialogIsOpen}
        isLoading={loading}
        handleClose={handleClose}
        disableBackdropClick={false}
        disableEscapeKeyDown={false}
        maxWidth="sm"
        title={t('tradePartnerPage.removeCard.confirmation.title')}
        content={
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <StyledNotice
                type="warning"
                message={t('tradePartnerPage.removeCard.confirmation.subTitle')}
              />
            </Grid>
            <Grid item>
              <StyledConfirmationSwitch
                disabled={loading}
                checked={isConfirmed}
                onChange={handleChange}
                value={'isConfirmed'}
              />
            </Grid>
          </Grid>
        }
        actions={
          <Fragment>
            <StyledButtonSecondary
              label={t(
                'tradePartnerPage.removeCard.confirmation.actions.cancelButton'
              )}
              disabled={loading}
              onClick={handleClose}
            />
            <StyledButtonPrimary
              label={t(
                'tradePartnerPage.removeCard.confirmation.actions.submitButton'
              )}
              disabled={loading || !isConfirmed}
              onClick={handleSubmit}
            />
          </Fragment>
        }
      />
    </Fragment>
  );
};
TradePartnerDeleteCard.propTypes = {
  tradePartner: PropTypes.any
};
export default TradePartnerDeleteCard;
