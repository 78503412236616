import gql from 'graphql-tag';

// Queries
export const GET_OBSERVATION_CAUSES = gql`
  query GetObservationCauses {
    observationCauses {
      id
      name
    }
  }
`;

export const GET_OBSERVATION_CATEGORIES = gql`
  query GetObservationCategories {
    observationCategories {
      id
      name
      type
      subCategories {
        id
        name
        category {
          id
          name
          type
        }
      }
    }
  }
`;

export const GET_OBSERVATION = gql`
  query GetSingleObservation($observationId: ID!) {
    observation(id: $observationId) {
      id
      createdDate
      project {
        id
        name
      }
      tradePartner {
        id
        name
      }
      projectArea
      observedDateTime
      type
      notes
      observedPerson {
        id
        personnel {
          id
          firstName
          middleName
          lastName
          nickname
        }
      }
      supervisor {
        id
        personnel {
          id
          firstName
          middleName
          lastName
          nickname
        }
      }
      originator {
        id
        personnel {
          id
          firstName
          middleName
          lastName
          nickname
        }
      }
      status
      isAllSafe
      causes {
        id
        name
      }
      items {
        id
        isSafe
        subCategory {
          id
          name
          type
          category {
            id
            name
          }
        }
        wasDeficient
      }
      createdBy {
        id
        firstName
        lastName
        name
        jobTitle
        upn
        email
        azureClientId
      }
    }
  }
`;

export const GET_PAGINATED_OBSERVATIONS_ON_PROJECT = gql`
  query GetPaginatedObservationsOnProject(
    $projectId: ID!
    $first: Int!
    $skip: Int
    $filter: ObservationFiltersInput
    $order: [ObservationOrderInput!]
    $search: String
  ) {
    paginatedObservationsOnProject(
      projectId: $projectId
      first: $first
      skip: $skip
      filter: $filter
      order: $order
      search: $search
    ) {
      total
      observations {
        id
        createdDate
        project {
          id
          name
        }
        tradePartner {
          id
          name
        }
        projectArea
        observedDateTime
        type
        notes
        observedPerson {
          id
          personnel {
            id
            firstName
            middleName
            lastName
            nickname
          }
        }
        supervisor {
          id
          personnel {
            id
            firstName
            middleName
            lastName
            nickname
          }
        }
        originator {
          id
          personnel {
            id
            firstName
            middleName
            lastName
            nickname
          }
        }
        status
        isAllSafe
        causes {
          id
          name
        }
        items {
          id
          isSafe
          subCategory {
            id
            name
            type
            category {
              id
              name
            }
          }
          wasDeficient
        }
        createdBy {
          id
          firstName
          lastName
          name
          jobTitle
          upn
          email
          azureClientId
        }
      }
    }
  }
`;

export const GET_PAGINATED_MY_OBSERVATIONS_ON_PROJECT = gql`
  query GetPaginatedMyObservationsOnProject(
    $projectId: ID!
    $first: Int!
    $skip: Int
    $filter: ObservationFiltersInput
    $order: [ObservationOrderInput!]
    $search: String
  ) {
    paginatedMyObservationsOnProject(
      projectId: $projectId
      first: $first
      skip: $skip
      filter: $filter
      order: $order
      search: $search
    ) {
      total
      observations {
        id
        createdDate
        project {
          id
          name
        }
        tradePartner {
          id
          name
        }
        projectArea
        observedDateTime
        type
        notes
        observedPerson {
          id
          personnel {
            id
            firstName
            middleName
            lastName
            nickname
          }
        }
        supervisor {
          id
          personnel {
            id
            firstName
            middleName
            lastName
            nickname
          }
        }
        originator {
          id
          personnel {
            id
            firstName
            middleName
            lastName
            nickname
          }
        }
        status
        isAllSafe
        causes {
          id
          name
        }
        items {
          id
          isSafe
          subCategory {
            id
            name
            type
            category {
              id
              name
            }
          }
          wasDeficient
        }
        createdBy {
          id
          firstName
          lastName
          name
          jobTitle
          upn
          email
          azureClientId
        }
      }
    }
  }
`;

export const GET_OPEN_OBSERVATIONS_COUNT = gql`
  query GetOpenObservationsCount(
    $projectId: ID!
    $createdByAzureClientId: String
  ) {
    paginatedObservationsOnProject(
      projectId: $projectId
      first: 0
      filter: {
        status: "Open"
        createdByAzureClientId: $createdByAzureClientId
      }
    ) {
      total
    }
  }
`;

export const GET_OPEN_MY_OBSERVATIONS_COUNT = gql`
  query GetOpenMyObservationsCount($projectId: ID!) {
    paginatedMyObservationsOnProject(
      projectId: $projectId
      first: 0
      filter: { status: "Open" }
    ) {
      total
    }
  }
`;

export const GET_MY_OBSERVATIONS_ON_PROJECT = gql`
  query GetMyObservationsOnProject($projectId: ID!) {
    myObservationsOnProject(projectId: $projectId) {
      id
      createdDate
      project {
        id
        name
      }
      tradePartner {
        id
        name
      }
      projectArea
      observedDateTime
      type
      notes
      observedPerson {
        id
        personnel {
          id
          firstName
          middleName
          lastName
          nickname
        }
      }
      supervisor {
        id
        personnel {
          id
          firstName
          middleName
          lastName
          nickname
        }
      }
      originator {
        id
        personnel {
          id
          firstName
          middleName
          lastName
          nickname
        }
      }
      status
      isAllSafe
      causes {
        id
        name
      }
      items {
        id
        isSafe
        subCategory {
          id
          name
          type
          category {
            id
            name
          }
        }
        wasDeficient
      }
      createdBy {
        id
        firstName
        lastName
        name
        jobTitle
        upn
        email
        azureClientId
      }
    }
  }
`;

export const GET_ALL_OBSERVATIONS_ON_PROJECT = gql`
  query ObservationsOnProject($projectId: ID!) {
    observationsOnProject(projectId: $projectId) {
      id
      createdDate
      project {
        id
        name
      }
      tradePartner {
        id
        name
      }
      projectArea
      observedDateTime
      type
      notes
      observedPerson {
        id
        personnel {
          id
          firstName
          middleName
          lastName
          nickname
        }
      }
      supervisor {
        id
        personnel {
          id
          firstName
          middleName
          lastName
          nickname
        }
      }
      originator {
        id
        personnel {
          id
          firstName
          middleName
          lastName
          nickname
        }
      }
      status
      isAllSafe
      causes {
        id
        name
      }
      items {
        id
        isSafe
        subCategory {
          id
          name
          type
          category {
            id
            name
          }
        }
        wasDeficient
      }
      createdBy {
        id
        firstName
        lastName
        name
        jobTitle
        upn
        email
        azureClientId
      }
    }
  }
`;

export const OBSERVATION_SEARCH = gql`
  query ObservationSearch(
    $projectId: ID!
    $ownObservations: Boolean
    $first: Int!
    $skip: Int
    $filter: ObservationFiltersInput
    $order: [ObservationOrderInput!]
    $search: String
  ) {
    observationSearch(
      projectId: $projectId
      ownObservations: $ownObservations
      first: $first
      skip: $skip
      filter: $filter
      order: $order
      search: $search
    ) {
      total
      observations {
        id
        createdDate
        project {
          id
          name
        }
        tradePartner {
          id
          name
        }
        projectArea
        observedDateTime
        type
        notes
        observedPerson {
          id
          personnel {
            id
            firstName
            middleName
            lastName
            nickname
          }
        }
        supervisor {
          id
          personnel {
            id
            firstName
            middleName
            lastName
            nickname
          }
        }
        originator {
          id
          personnel {
            id
            firstName
            middleName
            lastName
            nickname
          }
        }
        status
        isAllSafe
        causes {
          id
          name
        }
        items {
          id
          isSafe
          subCategory {
            id
            name
            type
            category {
              id
              name
            }
          }
          wasDeficient
        }
        createdBy {
          id
          firstName
          lastName
          name
          jobTitle
          upn
          email
          azureClientId
        }
      }
    }
  }
`;

export const OPEN_OBSERVATION_COUNT = gql`
  query ObservationSearch(
    $projectId: ID!
    $ownObservations: Boolean
    $createdByAzureClientId: String
  ) {
    observationSearch(
      projectId: $projectId
      ownObservations: $ownObservations
      first: 0
      filter: {
        status: "Open"
        createdByAzureClientId: $createdByAzureClientId
      }
    ) {
      total
    }
  }
`;

export const GET_OBSERVATION_RECOMMENDATION = gql`
  query ObservationRecommendation($input: String!, $projectId: ID!) {
    observationRecommendation(input: $input, projectId: $projectId) {
      relevantProjectArea
      relevantSubCategories {
        subCategoryId
        relevance
        isSafe
      }
      rawResponse
      responseParsingError
    }
  }
`;

// Mutations
export const CREATE_OBSERVATION = gql`
  mutation CreateObservation($input: ObservationCreateInput!) {
    createObservation(input: $input) {
      id
      createdDate
      project {
        id
        name
      }
      tradePartner {
        id
        name
      }
      projectArea
      observedDateTime
      type
      notes
      observedPerson {
        id
        personnel {
          id
          firstName
          middleName
          lastName
          nickname
        }
      }
      supervisor {
        id
        personnel {
          id
          firstName
          middleName
          lastName
          nickname
        }
      }
      originator {
        id
        personnel {
          id
          firstName
          middleName
          lastName
          nickname
        }
      }
      status
      isAllSafe
      causes {
        id
        name
      }
      items {
        id
        isSafe
        subCategory {
          id
          name
          type
          category {
            id
            name
          }
        }
        wasDeficient
      }
      createdBy {
        id
        firstName
        lastName
        name
        jobTitle
        upn
        email
        azureClientId
      }
    }
  }
`;

export const UPDATE_OBSERVATION = gql`
  mutation UpdateObservation($input: ObservationUpdateInput!) {
    updateObservation(input: $input) {
      id
      createdDate
      project {
        id
        name
      }
      tradePartner {
        id
        name
      }
      projectArea
      observedDateTime
      type
      notes
      observedPerson {
        id
        personnel {
          id
          firstName
          middleName
          lastName
          nickname
        }
      }
      supervisor {
        id
        personnel {
          id
          firstName
          middleName
          lastName
          nickname
        }
      }
      originator {
        id
        personnel {
          id
          firstName
          middleName
          lastName
          nickname
        }
      }
      status
      isAllSafe
      causes {
        id
        name
      }
      items {
        id
        isSafe
        subCategory {
          id
          name
          type
          category {
            id
            name
          }
        }
        wasDeficient
      }
      createdBy {
        id
        firstName
        lastName
        name
        jobTitle
        upn
        email
        azureClientId
      }
    }
  }
`;

export const DELETE_OBSERVATION = gql`
  mutation DeleteObservation($id: ID!) {
    deleteObservation(id: $id) {
      id
    }
  }
`;

// Subscriptions
export const MY_OBSERVATIONS_ON_PROJECT_SUBSCRIPTION = gql`
  subscription MyObservationsOnProject($projectId: ID!) {
    myObservationsOnProject(projectId: $projectId) {
      id
      createdDate
      project {
        id
        name
      }
      tradePartner {
        id
        name
      }
      projectArea
      observedDateTime
      type
      notes
      observedPerson {
        id
        personnel {
          id
          firstName
          middleName
          lastName
          nickname
        }
      }
      supervisor {
        id
        personnel {
          id
          firstName
          middleName
          lastName
          nickname
        }
      }
      originator {
        id
        personnel {
          id
          firstName
          middleName
          lastName
          nickname
        }
      }
      status
      isAllSafe
      causes {
        id
        name
      }
      items {
        id
        isSafe
        subCategory {
          id
          name
          type
          category {
            id
            name
          }
        }
        wasDeficient
      }
      createdBy {
        id
        firstName
        lastName
        name
        jobTitle
        upn
        email
        azureClientId
      }
    }
  }
`;

export const OBSERVATIONS_ON_PROJECT_SUBSCRIPTION = gql`
  subscription ObservationOnProject($projectId: ID!) {
    observationOnProject(projectId: $projectId) {
      id
      createdDate
      project {
        id
        name
      }
      tradePartner {
        id
        name
      }
      projectArea
      observedDateTime
      type
      notes
      observedPerson {
        id
        personnel {
          id
          firstName
          middleName
          lastName
          nickname
        }
      }
      supervisor {
        id
        personnel {
          id
          firstName
          middleName
          lastName
          nickname
        }
      }
      originator {
        id
        personnel {
          id
          firstName
          middleName
          lastName
          nickname
        }
      }
      status
      isAllSafe
      causes {
        id
        name
      }
      items {
        id
        isSafe
        subCategory {
          id
          name
          type
          category {
            id
            name
          }
        }
        wasDeficient
      }
      createdBy {
        id
        firstName
        lastName
        name
        jobTitle
        upn
        email
        azureClientId
      }
    }
  }
`;
