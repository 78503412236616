import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import EditIcon from '@mui/icons-material/Edit';
import makeStyles from '@mui/styles/makeStyles';
import { Menu, MenuItem, IconButton } from '@mui/material';

const useStyles = makeStyles(theme => ({
  editIcon: { color: theme.palette.icon.main }
}));

const EditMenu = ({ handleEditChoice }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleToggle = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = ({ shouldEditSeries }) => {
    handleEditChoice({ shouldEditSeries });
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <EditIcon className={classes.editIcon} />
      <IconButton
        data-testid="orientation-details-card-edit-button"
        aria-label={t('editMenu.editButtonAriaLabel')}
        size="small"
        onClick={handleToggle}>
        <KeyboardArrowDownIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        keepMounted
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}>
        <MenuItem
          onClick={() => handleMenuItemClick({ shouldEditSeries: false })}>
          {t('editMenu.thisEvent')}
        </MenuItem>
        <MenuItem
          onClick={() => handleMenuItemClick({ shouldEditSeries: true })}>
          {t('editMenu.thisAndFollowingEvents')}
        </MenuItem>
      </Menu>
    </>
  );
};

EditMenu.propTypes = {
  handleEditChoice: PropTypes.func.isRequired
};

export default EditMenu;
