import {
  HENSEL_PHELPS_FILTER_OPTIONS,
  HENSEL_PHELPS_SORT_ORDER_OPTIONS,
  SORT_ORDER_DIRECTION
} from 'constants/ahas';
import {
  LOCAL_STORAGE_HENSEL_PHELPS_AHA_LIST_FILTERS_KEY,
  LOCAL_STORAGE_HENSEL_PHELPS_AHA_SORT_ORDER_KEY
} from 'constants/localStorage';
import { atom, useRecoilState } from 'recoil';
import * as effects from 'store/effects';

const DEFAULT_SORT_ORDER = {
  value: HENSEL_PHELPS_SORT_ORDER_OPTIONS.lastModified.value,
  label: HENSEL_PHELPS_SORT_ORDER_OPTIONS.lastModified.label,
  direction: SORT_ORDER_DIRECTION.descending
};

const getStoredSortOrder = () => {
  const unserializedSortOrder = localStorage.getItem(
    LOCAL_STORAGE_HENSEL_PHELPS_AHA_SORT_ORDER_KEY
  );
  if (unserializedSortOrder) {
    return JSON.parse(unserializedSortOrder);
  } else {
    return null;
  }
};

const order = getStoredSortOrder() ?? DEFAULT_SORT_ORDER;

const DEFAULT_ROWS_PER_PAGE = 30;

const getStoredFilters = () => {
  const unserializedFilters = localStorage.getItem(
    LOCAL_STORAGE_HENSEL_PHELPS_AHA_LIST_FILTERS_KEY
  );
  if (unserializedFilters) {
    return JSON.parse(unserializedFilters);
  } else {
    return null;
  }
};

const DEFAULT_FILTERS = {
  [HENSEL_PHELPS_FILTER_OPTIONS.hpModifiedLastAllTime.apiKey]:
    HENSEL_PHELPS_FILTER_OPTIONS.hpModifiedLastAllTime.value,
  [HENSEL_PHELPS_FILTER_OPTIONS.ahaStatusAll.apiKey]:
    HENSEL_PHELPS_FILTER_OPTIONS.ahaStatusAll.value,
  [HENSEL_PHELPS_FILTER_OPTIONS.hasOrHasNoAttachments.apiKey]:
    HENSEL_PHELPS_FILTER_OPTIONS.hasOrHasNoAttachments.value
};

const filter = getStoredFilters() ?? DEFAULT_FILTERS;

const defaultState = {
  first: DEFAULT_ROWS_PER_PAGE,
  skip: 0,
  filter,
  order,
  search: '',
  hasActiveFilters: hasActiveFilters(filter)
};

function hasActiveFilters(filterState) {
  let hasActiveFilters = false;

  if (typeof filterState === 'object') {
    for (const value of Object.values(filterState)) {
      if (value) {
        hasActiveFilters = true;
        break;
      }
    }
  }

  return hasActiveFilters;
}

const henselPhelpsAhaListStateAtom = atom({
  key: 'henselPhelpsAhaListState',
  default: defaultState
});

function useHenselPhelpsAhaListState() {
  const [
    henselPhelpsAhaListState,
    setHenselPhelpsAhaListState
  ] = useRecoilState(henselPhelpsAhaListStateAtom);

  function handleHenselPhelpsAhaListStateChange(newState) {
    if (newState?.filter) {
      effects.henselPhelpsAhaListFilters.localStorageSave(newState.filter);
    }
    if (hasActiveFilters(newState.filter)) {
      newState.hasActiveFilters = true;
    } else {
      newState.hasActiveFilters = false;
    }

    if (newState?.order) {
      effects.henselPhelpsAhaSortOrder.localStorageSave(newState.order);
    }

    setHenselPhelpsAhaListState({
      ...henselPhelpsAhaListState,
      ...newState
    });
  }

  return [
    henselPhelpsAhaListState,
    {
      handleHenselPhelpsAhaListStateChange
    }
  ];
}

export default useHenselPhelpsAhaListState;
