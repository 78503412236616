import React from 'react';
import VideoPlayer from '../../VideoPlayer';
import { Grid, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  subTitle: { fontSize: '0.875rem' },
  title: { fontSize: '1.5rem' },
  bold: { fontWeight: 'bold' }
}));

const ManageAttendees = () => {
  const classes = useStyles();

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography className={classes.subTitle} color="textSecondary">
          Manage Attendees Tutorials
        </Typography>
        <Typography className={classes.title} color="textPrimary">
          Approve Attendee
        </Typography>
      </Grid>
      <Grid item>
        <VideoPlayer
          data-testid="approve-attendee-video"
          url="/tutorials/orientations/ApproveAttendee.mp4"
          tracks={[
            {
              kind: 'subtitles',
              src: '/tutorials/orientations/ApproveAttendee.en.vtt',
              srcLang: 'en',
              default: true
            }
          ]}
        />
      </Grid>

      <Grid item>
        <Typography className={classes.title} color="textPrimary">
          Deny Attendee
        </Typography>
      </Grid>
      <Grid item>
        <VideoPlayer
          data-testid="deny-attendee-video"
          url="/tutorials/orientations/DenyAttendee.mp4"
          tracks={[
            {
              kind: 'subtitles',
              src: '/tutorials/orientations/DenyAttendee.en.vtt',
              srcLang: 'en',
              default: true
            }
          ]}
        />
      </Grid>

      <Grid item>
        <Typography className={classes.title} color="textPrimary">
          Verify Attendee
        </Typography>
      </Grid>
      <Grid item>
        <VideoPlayer
          data-testid="verify-attendee-video"
          url="/tutorials/orientations/VerifyAttendee.mp4"
          tracks={[
            {
              kind: 'subtitles',
              src: '/tutorials/orientations/VerifyAttendee.en.vtt',
              srcLang: 'en',
              default: true
            }
          ]}
        />
      </Grid>
    </Grid>
  );
};

export default ManageAttendees;
