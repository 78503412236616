import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useMutation, useLazyQuery, useQuery } from '@apollo/react-hooks';
import { useForm, useField } from 'react-final-form-hooks';
import makeStyles from '@mui/styles/makeStyles';
import {
  Grid,
  Typography,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  Divider
} from '@mui/material';

import useFormValidation from 'hooks/useFormValidation';
import useToast from 'hooks/useToast';
import StyledDialog from 'shared/Dialog';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import StyledButtonSecondary from 'shared/Buttons/ButtonSecondary';
import StyledNotice from 'shared/Notice';
import StyledDetailsRow from 'shared/DetailsRow';
import StyledUserCard from 'shared/UserCard';
import StyledAccordion from 'shared/Accordion';
import StyledInput from 'shared/Input';
import TradePartnerPersonnelSearchSelect from 'components/tradepartners/TradePartnerPersonnelSearchSelect';
import { getPersonnelLabel } from 'utils';
import { formatDateOfBirth } from 'utils';
import { isNullish } from 'utils/isNullish';
import { MERGE_PERSONNEL } from 'graphql/personnel';
import { GET_TRADE_PARTNER_PERSONNEL_FOR_BASIC_IDENTITY } from 'graphql/tradePartnerPersonnel';
import {
  formatPhoneNumber,
  formatInputAndInternationalPhoneNumber
} from 'utils/formatPhoneNumber';

const useStyles = makeStyles(theme => ({
  optionsContainer: {
    borderRadius: 4,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2)
  },
  instructions: { marginTop: theme.spacing(1) },
  radioGroupContainer: { marginBottom: theme.spacing(2) },
  radioLabel: { fontSize: '0.875rem' },
  mergeFromNotSpecified: { fontStyle: 'italic' },
  accordionContainer: { marginTop: theme.spacing(2) },
  error: { color: theme.palette.error.main, fontSize: '0.75rem' }
}));

const PersonnelMergeDialog = ({
  mergeDialogIsOpen,
  setMergeDialogIsOpen,
  tradePartnerPersonnel
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isRequired } = useFormValidation();
  const { displayToast } = useToast();
  const [selectedOption, setSelectedOption] = useState('searchByProject');
  const [singlePersonnel, setSinglePersonnel] = useState(undefined);
  const [singlePersonnelError, setSinglePersonnelError] = useState(false);

  const { form } = useForm({
    /* istanbul ignore next */
    onSubmit: () => {} // this function required for useForm but is not used
  });

  const { errors } = form.getState();

  const mergeToPersonnelId = tradePartnerPersonnel.id;

  // Option 1: Search by project number
  // Step 1
  const isValidProjectNumber = value => {
    if (selectedOption === 'searchByPersonnelId') {
      return undefined;
    }

    if (selectedOption === 'searchByProject') {
      if (value) {
        if (value.length < 7) {
          return t('personnelMergeDialog.projectNumberField.customError');
        }
      }
      return isRequired(value);
    }

    return undefined;
  };

  // Step 2
  const isValidMergeFromPersonnel = value => {
    if (selectedOption === 'searchByPersonnelId') {
      return undefined;
    }

    if (selectedOption === 'searchByProject') {
      if (!value) {
        return isRequired(value);
      }
      if (value?.personnel?.soteriaAdUser?.employeeId) {
        return t('personnelMergeDialog.linkedError');
      }
      return undefined;
    }

    return undefined;
  };

  ////////////////////////////////////////////////////////////////////////////

  // Option 2: Search by personnel ID
  const isValidTradepartnerPersonnelId = value => {
    if (selectedOption === 'searchByProject') {
      return undefined;
    }

    if (selectedOption === 'searchByPersonnelId') {
      if (value) {
        if (value === mergeToPersonnelId) {
          return t('personnelMergeDialog.personnelSelect.customError');
        }
      }

      return isRequired(value);
    }
  };

  const [mergePersonnel, { loading: isLoadingForPersonnelMerge }] = useMutation(
    MERGE_PERSONNEL
  );

  const projectNumberField = useField(
    'projectNumber',
    form,
    isValidProjectNumber
  );

  const mergeFromPersonnelSelectField = useField(
    'mergeFromPersonnel',
    form,
    isValidMergeFromPersonnel
  );

  const personnelIdField = useField(
    'personnelId',
    form,
    isValidTradepartnerPersonnelId
  );

  const [
    getSinglePersonnel,
    // eslint-disable-next-line no-unused-vars
    { data, loading: isLoadingForGetSinglePersonnel }
  ] = useLazyQuery(GET_TRADE_PARTNER_PERSONNEL_FOR_BASIC_IDENTITY, {
    onCompleted: ({ tradePartnerPersonnel }) => {
      setSinglePersonnel(tradePartnerPersonnel?.personnel ?? undefined);
    },
    onError: e => {
      setSinglePersonnelError(true);
    },
    fetchPolicy: 'no-cache'
  });

  const isSinglePersonnelLinked =
    data?.tradePartnerPersonnel?.personnel?.soteriaAdUser?.employeeId;

  const { data: tradePartnerPersonnelData, loading } = useQuery(
    GET_TRADE_PARTNER_PERSONNEL_FOR_BASIC_IDENTITY,
    {
      variables: { id: tradePartnerPersonnel.id }
    }
  );

  const personnelToMergeFrom =
    selectedOption === 'searchByProject'
      ? mergeFromPersonnelSelectField.input.value
      : singlePersonnel;

  const personnelToMergeFromDisplay =
    selectedOption === 'searchByProject'
      ? mergeFromPersonnelSelectField?.input?.value?.personnel
      : singlePersonnel;

  const hasProjectNumber =
    !isNullish(projectNumberField.input.value) &&
    projectNumberField?.input?.value.length >= 7;

  const mergeFromPersonnelWasSelected = !isNullish(
    mergeFromPersonnelSelectField.input.value
  );

  const isSamePersonnel =
    selectedOption === 'searchByProject'
      ? mergeFromPersonnelSelectField?.input?.value?.personnel?.id ===
        tradePartnerPersonnel.personnel.id
      : singlePersonnel?.id === tradePartnerPersonnel.personnel.id;

  const handleSubmitPersonnelId = () => {
    if (singlePersonnelError) {
      setSinglePersonnelError(false);
    }
    getSinglePersonnel({ variables: { id: personnelIdField.input.value } });
  };

  const handleClose = () => {
    if (singlePersonnelError) {
      setSinglePersonnelError(false);
    }

    setMergeDialogIsOpen(false);
    form.resetFieldState('mergeFromPersonnel');
    form.resetFieldState('personnelId');
    form.resetFieldState('projectNumber');
    setSinglePersonnel(undefined);
    form.reset();
  };

  const canSubmit = () => {
    const { error, invalid } = form.getState();

    if (selectedOption === 'searchByPersonnelId') {
      return !(
        loading ||
        !personnelToMergeFrom ||
        invalid ||
        error ||
        isSamePersonnel ||
        isLoadingForPersonnelMerge ||
        isLoadingForGetSinglePersonnel
      );
    } else {
      return !(
        loading ||
        invalid ||
        error ||
        isSamePersonnel ||
        isLoadingForPersonnelMerge ||
        isLoadingForGetSinglePersonnel
      );
    }
  };

  const handleSubmit = () => {
    mergePersonnel({
      variables: {
        mergeToPersonnelId: tradePartnerPersonnel?.personnel?.id,
        mergeFromPersonnelId:
          selectedOption === 'searchByPersonnelId'
            ? personnelToMergeFrom?.id
            : personnelToMergeFrom?.personnel?.id
      }
    }).then(
      ({ data: { mergePersonnel: personnel } }) => {
        handleClose();
        displayToast(t('personnelMergeDialog.toasts.success'), 'success');
      },
      error => {
        console.error('Personnel Merging Error: ', error);
        displayToast(t('personnelMergeDialog.toasts.error'), 'error');
      }
    );
  };

  const getTradePartnerHistory = personnel => {
    const tpHistories = personnel.tradePartnerHistory;
    if (tpHistories.length > 0) {
      const tpNames = tpHistories.map(tpHistory => {
        return tpHistory.tradePartner.name;
      });
      return [...new Set(tpNames)].join(', ');
    }
  };

  const handleRadioOptionChange = event => {
    setSelectedOption(event.target.value);
  };

  const formatPersonnelPhoneNumber = phoneNumber => {
    const personnelInputPhoneNumber = formatPhoneNumber(phoneNumber);
    const personnelInternationalPhoneNumber = formatInputAndInternationalPhoneNumber(
      phoneNumber
    )?.international;
    return personnelInternationalPhoneNumber
      ? personnelInternationalPhoneNumber
      : personnelInputPhoneNumber;
  };

  const getPersonnelDetailsAccordian = personnel => (
    <StyledAccordion
      title={t('personnelMergeDialog.details.title')}
      content={
        <Grid container direction="column">
          <Grid item>
            {personnel?.soteriaAdUser?.employeeId && (
              <StyledDetailsRow
                label="HP Employee ID"
                value={personnel.soteriaAdUser.employeeId}
              />
            )}
            <StyledDetailsRow
              label={t('personnelMergeDialog.details.name')}
              value={`${personnel?.lastName}, 
      ${personnel?.firstName} ${
                personnel?.nickname ? `"${personnel.nickname}"` : ''
              }
      ${personnel?.middleName ?? ''}`}
            />
            {personnel?.dob && (
              <StyledDetailsRow
                label={t('personnelMergeDialog.details.dob')}
                value={formatDateOfBirth(personnel.dob)}
              />
            )}
            {personnel?.contactInformation?.primaryPhone?.number && (
              <StyledDetailsRow
                label={t('personnelMergeDialog.details.phoneNumber')}
                value={formatPersonnelPhoneNumber(
                  personnel?.contactInformation?.primaryPhone?.number
                )}
              />
            )}
            {personnel?.contactInformation?.emailAddress?.email && (
              <StyledDetailsRow
                label={t('personnelMergeDialog.details.email')}
                value={personnel.contactInformation?.emailAddress.email}
              />
            )}
            {personnel?.tradePartnerHistory?.length > 0 && (
              <StyledDetailsRow
                label={t('personnelMergeDialog.details.tradePartners')}
                value={getTradePartnerHistory(personnel)}
              />
            )}
            {personnel?.flags?.length > 0 && (
              <StyledDetailsRow
                label={t('personnelMergeDialog.details.flags')}
                value={personnel.flags.length}
              />
            )}
          </Grid>
        </Grid>
      }
    />
  );

  return (
    <StyledDialog
      isOpen={mergeDialogIsOpen}
      handleClose={handleClose}
      isLoading={
        loading || isLoadingForPersonnelMerge || isLoadingForGetSinglePersonnel
      }
      title={t('personnelMergeDialog.title')}
      content={
        <Grid
          container
          justifyContent="center"
          spacing={3}
          alignContent="center">
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <StyledNotice
              type="warning"
              message={t('personnelMergeDialog.notice.message')}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <Grid container className={classes.optionsContainer}>
              <Grid item xs={12}>
                <Typography className="section-title">
                  {t('personnelMergeDialog.options.title')}
                </Typography>
                <Divider />
                <Typography className={classes.instructions}>
                  {t('personnelMergeDialog.options.instructions')}
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.radioGroupContainer}>
                <FormControl variant="standard" component="fieldset">
                  <RadioGroup
                    name="personnelSearchOptions"
                    value={selectedOption}
                    onChange={handleRadioOptionChange}>
                    <FormControlLabel
                      value={'searchByProject'}
                      control={<Radio color="primary" />}
                      label={
                        <Typography className={classes.radioLabel}>
                          {t('personnelMergeDialog.options.optionOneLabel')}
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      value={'searchByPersonnelId'}
                      control={<Radio color="primary" />}
                      label={
                        <Typography className={classes.radioLabel}>
                          {t('personnelMergeDialog.options.optionTwoLabel')}
                        </Typography>
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {selectedOption === 'searchByProject' && (
                <Grid item xs={12}>
                  <StyledInput
                    input={projectNumberField.input}
                    meta={projectNumberField.meta}
                    disabled={
                      isLoadingForPersonnelMerge ||
                      isLoadingForGetSinglePersonnel
                    }
                    label={t('personnelMergeDialog.projectNumberInput.label')}
                    placeholder={t(
                      'personnelMergeDialog.projectNumberInput.placeholder'
                    )}
                  />
                  <TradePartnerPersonnelSearchSelect
                    projectNumber={projectNumberField.input.value}
                    selectedTradePartnerPersonnel={
                      mergeFromPersonnelSelectField.input.value
                    }
                    meta={mergeFromPersonnelSelectField.meta}
                    handleChange={mergeFromPersonnelSelectField.input.onChange}
                    isLoading={isLoadingForPersonnelMerge}
                    isDisabled={
                      !hasProjectNumber ||
                      isLoadingForPersonnelMerge ||
                      isLoadingForGetSinglePersonnel
                    }
                    isRequired={
                      hasProjectNumber || !mergeFromPersonnelWasSelected
                    }
                    label={t('personnelMergeDialog.personnelSelect.label')}
                    customError={
                      isSamePersonnel
                        ? t('personnelMergeDialog.personnelSelect.customError')
                        : undefined
                    }
                  />
                </Grid>
              )}
              {selectedOption === 'searchByPersonnelId' && (
                <Grid item xs={12}>
                  <StyledInput
                    input={personnelIdField.input}
                    meta={personnelIdField.meta}
                    disabled={
                      isLoadingForPersonnelMerge ||
                      isLoadingForGetSinglePersonnel
                    }
                    label={t('personnelMergeDialog.personnelIdInput.label')}
                    placeholder={t(
                      'personnelMergeDialog.personnelIdInput.placeholder'
                    )}
                    shouldDisplayAsError={singlePersonnelError}
                  />
                  {singlePersonnelError && (
                    <Typography className={classes.error}>
                      {t('personnelMergeDialog.personnelIdInput.error')}
                    </Typography>
                  )}
                  {isSinglePersonnelLinked && (
                    <Typography className={classes.error}>
                      {t('personnelMergeDialog.linkedError')}
                    </Typography>
                  )}
                  <Grid
                    container
                    justifyContent="flex-end"
                    className="margin-bottom">
                    <Grid item>
                      <StyledButtonPrimary
                        label={t('personnelMergeDialog.actions.searchButton')}
                        disabled={
                          !!errors.personnelIdField ||
                          !personnelIdField.input.value ||
                          isLoadingForPersonnelMerge ||
                          isLoadingForGetSinglePersonnel
                        }
                        onClick={handleSubmitPersonnelId}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <Grid container>
              <Grid item xs={12}>
                <Typography className="section-title">
                  {t('personnelMergeDialog.mergeSelect.label')}
                </Typography>
                {!personnelToMergeFrom && (
                  <Typography className={classes.mergeFromNotSpecified}>
                    {t('personnelMergeDialog.mergeSelect.noneSelected')}
                  </Typography>
                )}
                {personnelToMergeFrom && !isLoadingForGetSinglePersonnel && (
                  <StyledUserCard
                    src={
                      personnelToMergeFromDisplay?.profileImage?.cachedUrl ?? ''
                    }
                    title={getPersonnelLabel(personnelToMergeFromDisplay)}
                    content={
                      <Typography>
                        {t('personnelMergeDialog.userCardDobLabel')}{' '}
                        {personnelToMergeFromDisplay?.dob
                          ? formatDateOfBirth(personnelToMergeFromDisplay.dob)
                          : ''}
                      </Typography>
                    }
                  />
                )}
              </Grid>
              <Grid item xs={12} className={classes.accordionContainer}>
                {personnelToMergeFrom &&
                  !isLoadingForGetSinglePersonnel &&
                  getPersonnelDetailsAccordian(personnelToMergeFromDisplay)}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <Typography className="section-title">
              {t('personnelMergeDialog.mergeDisplay.label')}
            </Typography>
            <StyledUserCard
              src={
                tradePartnerPersonnel.personnel?.profileImage?.cachedUrl ?? ''
              }
              title={getPersonnelLabel(tradePartnerPersonnel.personnel)}
              content={
                <>
                  {tradePartnerPersonnel.personnel.dob ? (
                    <Typography>
                      {t('personnelMergeDialog.userCardDobLabel')}{' '}
                      {formatDateOfBirth(tradePartnerPersonnel.personnel.dob)}
                    </Typography>
                  ) : (
                    ''
                  )}
                  {tradePartnerPersonnel.customId ? (
                    <Typography component="div">
                      {t('personnelMergeDialog.userCardCustomIdLabel')}{' '}
                      {tradePartnerPersonnel.customId}
                    </Typography>
                  ) : (
                    ''
                  )}
                </>
              }
            />
            <Grid item xs={12} className={classes.accordionContainer}>
              {!loading &&
                getPersonnelDetailsAccordian(
                  tradePartnerPersonnelData.tradePartnerPersonnel?.personnel
                )}
            </Grid>
          </Grid>
        </Grid>
      }
      actions={
        <Grid container justifyContent="space-between">
          <Grid item>
            <StyledButtonSecondary
              label={t('personnelMergeDialog.actions.cancelButton')}
              disabled={
                isLoadingForPersonnelMerge || isLoadingForGetSinglePersonnel
              }
              onClick={handleClose}
            />
          </Grid>
          <Grid item>
            <StyledButtonPrimary
              label={t('personnelMergeDialog.actions.submitButton')}
              disabled={
                !canSubmit() ||
                isLoadingForPersonnelMerge ||
                isLoadingForGetSinglePersonnel ||
                isSinglePersonnelLinked
              }
              onClick={handleSubmit}
            />
          </Grid>
        </Grid>
      }
    />
  );
};

PersonnelMergeDialog.propTypes = {
  mergeDialogIsOpen: PropTypes.bool.isRequired,
  setMergeDialogIsOpen: PropTypes.func.isRequired,
  tradePartnerPersonnel: PropTypes.object.isRequired
};

export default PersonnelMergeDialog;
