import gql from 'graphql-tag';

// Queries

export const GET_ALL_GLOBAL_TEMPLATE_CATEGORIES = gql`
  query GetAllGlobalTemplateCategories {
    ahaGlobalTemplateCategories {
      id
      name
      ahaTemplates {
        id
        type
      }
    }
  }
`;

export const GET_ALL_TEMPLATES_FOR_GLOBAL_CATEGORY = gql`
  query GetAllTemplatesForGlobalCategory($ahaGlobalTemplateCategoryId: ID!) {
    ahaTemplatesForGlobalCategory(
      ahaGlobalTemplateCategoryId: $ahaGlobalTemplateCategoryId
    ) {
      id
      type
      ahaGlobalTemplateCategory {
        ahaTemplates {
          id
          type
        }
        id
        name
      }
    }
  }
`;

// Mutations

export const CREATE_GLOBAL_AHA_TEMPLATE_CATEGORY = gql`
  mutation CreateGlobalAhaTemplateCategory(
    $input: AhaGlobalTemplateCategoryCreateInput!
  ) {
    createAhaGlobalTemplateCategory(input: $input) {
      id
      name
      ahaTemplates {
        id
        type
        ahaEquipments {
          id
        }
        ahaSteps {
          id
        }
      }
    }
  }
`;

export const UPDATE_GLOBAL_AHA_TEMPLATE_CATEGORY = gql`
  mutation UpdateGlobalAhaTemplateCategory(
    $input: AhaGlobalTemplateCategoryUpdateInput!
  ) {
    updateAhaGlobalTemplateCategory(input: $input) {
      id
      name
      ahaTemplates {
        id
        type
        ahaEquipments {
          id
        }
        ahaSteps {
          id
        }
      }
    }
  }
`;

export const DELETE_GLOBAL_AHA_TEMPLATE_CATEGORY = gql`
  mutation DeleteAhaGlobalTemplateCategory($id: ID!) {
    deleteAhaGlobalTemplateCategory(id: $id) {
      id
    }
  }
`;
