import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography, Link as MuiLink, useTheme } from '@mui/material';

import useRoles from 'hooks/useRoles';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import RequestProjectAccess from 'components/project/RequestProjectAccess';
import StyledUserNoticeTemplate from 'components/common/UserNoticeTemplate';

const masterProjectPageRouteLink = '/projects';

const useStyles = makeStyles(theme => ({
  heading: { fontSize: '2rem', fontWeight: 'bold' },
  img: { width: 200, padding: theme.spacing(3) }
}));

export const NoProjectsNotice = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const { isEnterpriseAdmin, isRoamingAdmin } = useRoles();

  return (
    <StyledUserNoticeTemplate
      image={
        <img
          className={classes.img}
          src={theme.noProjectsNotice}
          alt=""
          role="presentation"
        />
      }
      content={
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          direction="column"
          spacing={3}>
          <Grid item>
            <Typography color="textPrimary" className={classes.heading}>
              {t('noProjectsNotice.heading')}
            </Typography>
          </Grid>
          {!isEnterpriseAdmin && isRoamingAdmin && (
            <Grid item>
              <Typography color="textPrimary">
                {t('noProjectsNotice.subHeading.isDefaultRole')}
              </Typography>
              <RequestProjectAccess />
            </Grid>
          )}
          {isEnterpriseAdmin && (
            <Fragment>
              <Grid item>
                <Typography color="textPrimary">
                  {t(
                    'noProjectsNotice.subHeading.isEnterpriseAdmin.viewProjects'
                  )}
                </Typography>
                <Typography color="textPrimary">
                  {t(
                    'noProjectsNotice.subHeading.isEnterpriseAdmin.grantPermissions'
                  )}
                </Typography>
              </Grid>
              <Grid item>
                <MuiLink
                  component={RouterLink}
                  to={masterProjectPageRouteLink}
                  underline="none">
                  <StyledButtonPrimary
                    label={t('noProjectsNotice.masterProjectListButton')}
                  />
                </MuiLink>
              </Grid>
            </Fragment>
          )}
        </Grid>
      }
    />
  );
};

export default NoProjectsNotice;
