import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography, Card, LinearProgress } from '@mui/material';

import StyledButtonMuted from 'shared/Buttons/ButtonMuted';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import StyledButtonSecondary from 'shared/Buttons/ButtonSecondary';

const useStyles = makeStyles(theme => ({
  titleText: { fontWeight: 'bold' },
  card: { padding: theme.spacing(3) },
  buttonContainer: { justifyContent: 'flex-end', marginTop: theme.spacing(2) }
}));

const StyledProjectDetailsCard = ({
  isLoading,
  title,
  editButtonLabel,
  readContent,
  writeContent,
  canSubmit,
  handleCancel,
  handleSubmit,
  isEditing,
  setIsEditing
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleEditButtonClick = () => {
    setIsEditing(true);
  };

  return <>
    {isLoading && <LinearProgress color="primary" />}
    <Card className={classes.card} data-testid="projec-details-card">
      <Grid container direction="column">
        <Grid item>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography
                gutterBottom
                className={classes.titleText}
                component="h2">
                {title}
              </Typography>
            </Grid>
            <Grid item>
              {isEditing && (
                <Typography color="primary">
                  {t('styledProjectDetailsCard.isEditing')}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
        {!isEditing && (
          <>
            {readContent}
            <Grid container justifyContent="flex-end">
              <Grid item>
                <StyledButtonMuted
                  label={editButtonLabel}
                  onClick={handleEditButtonClick}
                  disabled={isLoading}
                  startIcon={<EditIcon color="action" />}
                />
              </Grid>
            </Grid>
          </>
        )}
        {isEditing && (
          <>
            {writeContent}
            <Grid container className={classes.buttonContainer}>
              <Grid item>
                <StyledButtonSecondary
                  label={t('styledProjectDetailsCard.actions.cancelButton')}
                  onClick={handleCancel}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item>
                <StyledButtonPrimary
                  label={t('styledProjectDetailsCard.actions.submitButton')}
                  onClick={handleSubmit}
                  disabled={!canSubmit() || isLoading}
                />
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Card>
  </>;
};

StyledProjectDetailsCard.propTypes = {
  canSubmit: PropTypes.func,
  handleSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
  title: PropTypes.string.isRequired,
  readContent: PropTypes.any,
  writeContent: PropTypes.any,
  editButtonLabel: PropTypes.string,
  handleCancel: PropTypes.func,
  isEditing: PropTypes.bool,
  setIsEditing: PropTypes.func
};

export default StyledProjectDetailsCard;
