import React, { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import NavigationPrompt from 'react-router-navigation-prompt';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme, Grid, Typography } from '@mui/material';
import { useQuery, useMutation } from '@apollo/react-hooks';
import {
  GET_SAFETY_HOURS_ON_PROJECT,
  UPDATE_MANY_SAFETY_HOURS
} from 'graphql/safetyHours';
import { GET_ALL_SAFETY_HOURS_SUPERVISORS } from 'graphql/personnel';
import { DateTime } from 'luxon';

import useProject from 'hooks/useProject';
import useToast from 'hooks/useToast';
import ConfirmationDialog from 'components/common/ConfirmDialog';
import StyledWeekDatePicker from 'shared/WeekDatePicker';
import TimeCardsTable from './TimeCardsTable';
import withOnlineAccessOnly from 'hocs/withOnlineAccessOnly';
import withAuthorization from 'hocs/withAuthorization';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import StyledButtonSecondary from 'shared/Buttons/ButtonSecondary';
import LoadingOverlay from 'react-loading-overlay';

const DAY_OF_MONTH_SAFETY_HOURS_DUE = 10;

const useStyles = makeStyles(theme => ({
  title: { fontSize: '2rem' },
  content: { width: 'calc(100vw - 40px)' },
  archiveMessage: {
    fontSize: '0.875rem',
    fontWeight: 'bold',
    [theme.breakpoints.down('lg')]: { marginBottom: theme.spacing(1) }
  }
}));

const TimeCards = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { projectId } = useParams();
  const { project } = useProject(projectId);
  const { displayToast } = useToast();
  const theme = useTheme();

  const initialDate = () => {
    const now = DateTime.now();
    // Initially select the previous Sunday (ISO 8601 weekday 7).
    return now
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .minus({ days: now.weekday });
  };

  const [
    isCancelConfirmationRendered,
    setIsCancelConfirmationRendered
  ] = useState(false);
  const [selectedDate, setSelectedDate] = useState(initialDate());
  const startJsDate = selectedDate.minus({ days: 6 }).toJSDate();
  const endJsDate = selectedDate.toJSDate();
  const [isEditing, toggleEditing] = useState(false);

  const [editedRows, setEditedRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);

  const editedRowsById = editedRows.reduce((accumulator, row) => {
    accumulator[row.id] = row;
    return accumulator;
  }, {});

  const { data: safetyHoursData, loading: safetyHoursLoading } = useQuery(
    GET_SAFETY_HOURS_ON_PROJECT,
    {
      skip: !projectId,
      variables: {
        projectId,
        startDate: startJsDate,
        endDate: endJsDate
      }
    }
  );
  const rows = useMemo(
    () =>
      safetyHoursData?.getSafetyHoursOnProject?.filter(
        row => row.tradePartnerPersonnel
      ) ?? [],
    [safetyHoursData]
  );

  const { data: supervisorsData, loading: supervisorsLoading } = useQuery(
    GET_ALL_SAFETY_HOURS_SUPERVISORS
  );

  const refetchQueries = [
    {
      query: GET_SAFETY_HOURS_ON_PROJECT,
      variables: {
        projectId,
        startDate: startJsDate,
        endDate: endJsDate
      }
    }
  ];

  const [
    updateManySafetyHours,
    { loading: updateManySafetyHoursLoading }
  ] = useMutation(UPDATE_MANY_SAFETY_HOURS, {
    refetchQueries,
    awaitRefetchQueries: true
  });

  const handleDateChange = date => {
    setSelectedDate(date);
  };

  const isLoading =
    safetyHoursLoading || supervisorsLoading || updateManySafetyHoursLoading;

  const handleCancel = () => {
    setEditedRows([]);
    toggleEditing(false);
    setIsCancelConfirmationRendered(false);
  };

  // Clear edit state if the underlying rows change
  useEffect(() => {
    if (editedRows.length) {
      console.error('row data unexpectedly changed while edits were pending');
    }
    handleCancel();
  }, [rows]);

  const onSubmit = () => {
    updateManySafetyHours({
      variables: {
        inputs: editedRows.map(row => ({
          id: row.id,
          supervisedByIds: row.supervisedBy.map(supervisor => supervisor.id),
          tradePartnerPersonnel: {
            supervisedByIds: row.tradePartnerPersonnel.supervisedBy.map(
              supervisor => supervisor.id
            )
          }
        }))
      }
    })
      .then(data => {
        setEditedRows([]);
        toggleEditing(false);
        displayToast(t('timeCardsPage.toasts.updateMany.success'), 'success');
      })
      .catch(error => {
        console.error('Update Safety Hours Error: ', error);
        displayToast(t('timeCardsPage.toasts.updateMany.error'), 'error');
      });
  };

  const handleOnFilterChange = filteredTableData => {
    if (
      filteredRows.length !== filteredTableData.length ||
      filteredRows.some(
        row => !filteredTableData.some(newRow => newRow.id === row.id)
      )
    ) {
      setFilteredRows(filteredTableData);
    }
  };

  const handleUpdateManySupervisors = async (formValue, alsoUpdateDefaults) => {
    if (!filteredRows?.length) {
      return;
    }
    const supervisedByIds =
      formValue?.map(selection => selection.value.id) ?? [];

    return updateManySafetyHours({
      variables: {
        inputs: filteredRows.map(row => ({
          id: row.id,
          supervisedByIds,
          ...(alsoUpdateDefaults && {
            tradePartnerPersonnel: { supervisedByIds }
          })
        }))
      }
    });
  };

  const renderUnsavedChangesConfirmationModal = () => {
    //  this component does not accept the useConfirmDialog hook as a child
    return (
      <NavigationPrompt
        when={(crntLocation, nextLocation) =>
          !nextLocation ||
          !nextLocation.pathname.startsWith(crntLocation.pathname)
        }>
        {({ onConfirm, onCancel }) => (
          <ConfirmationDialog
            show={true}
            cancel={onCancel}
            confirmation={t('timeCardsPage.discardMessage')}
            proceed={onConfirm}
            options={{
              theme,
              title: t('timeCardsPage.unsavedChanges'),
              rejectLabel: t('timeCardsPage.actions.backButton'),
              confirmLabel: t('timeCardsPage.actions.discardButton')
            }}
          />
        )}
      </NavigationPrompt>
    );
  };

  const startOfDay = { hour: 0, minute: 0, second: 0, millisecond: 0 };
  const dueDate = selectedDate
    .set({ day: DAY_OF_MONTH_SAFETY_HOURS_DUE, ...startOfDay })
    .plus({ month: 1 });
  const isEditable = dueDate >= DateTime.now().set(startOfDay);

  return (
    <Grid container direction="column" alignContent="center">
      <Grid item>
        <Grid container direction="column" className={classes.content}>
          <Grid item>
            <Typography color="textPrimary" className={classes.title}>
              {t('timeCardsPage.timeCard')}
            </Typography>
          </Grid>
          <Grid container direction="row" alignItems="center" spacing={1}>
            <Grid item>
              <StyledWeekDatePicker
                disabled={isEditing}
                disabledTooltipText={t(
                  'timeCardsPage.tooltips.notAvailableWhileEditing'
                )}
                selectedDate={selectedDate}
                handleDateChange={handleDateChange}
                shouldShowWeekInButton={true}
              />
            </Grid>
            {isEditable && (
              <Grid item>
                <Typography className={classes.archiveMessage} color="primary">
                  {t('timeCardsPage.archiveDateNotice', {
                    date: dueDate.toFormat('yyyy-MM-dd')
                  })}
                </Typography>
              </Grid>
            )}
            {!isEditable && (
              <Grid item>
                <Typography className={classes.archiveMessage} color="primary">
                  {t('timeCardsPage.archivedMessage')}
                </Typography>
              </Grid>
            )}
          </Grid>

          <Grid item>
            <LoadingOverlay
              active={safetyHoursLoading || supervisorsLoading}
              spinner
              fadeSpeed={50}
              styles={{
                overlay: base => ({
                  ...base,
                  background: theme.palette.background.overlay
                }),
                spinner: base => ({
                  ...base,
                  width: '50px',
                  '& svg circle': {
                    stroke: theme.palette.primary.main
                  }
                })
              }}>
              <TimeCardsTable
                project={project}
                supervisors={supervisorsData?.safetyHoursSupervisors ?? []}
                workerHourRows={
                  editedRows.length
                    ? rows.map(row => ({
                        ...row,
                        supervisedBy:
                          editedRowsById?.[row.id]?.supervisedBy ??
                          row.supervisedBy
                      }))
                    : rows
                }
                editableRows={editedRows}
                setEditableRows={setEditedRows}
                isEditing={isEditing}
                toggleEditing={toggleEditing}
                isLoading={isLoading}
                updateManySupervisors={handleUpdateManySupervisors}
                handleFilterChange={handleOnFilterChange}
                isEditable={isEditable}
              />
            </LoadingOverlay>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container justifyContent="flex-end" direction="row" spacing={1}>
            <Grid item>
              <StyledButtonSecondary
                className={classes.button}
                onClick={() => setIsCancelConfirmationRendered(true)}
                label={t('timeCardsPage.actions.cancelButton')}
                disabled={isLoading || !isEditing}
              />
            </Grid>
            <Grid item>
              <StyledButtonPrimary
                className={classes.buttonPrimary}
                onClick={onSubmit}
                label={t('timeCardsPage.actions.submitButton')}
                disabled={isLoading || !isEditing}
              />
              {/* )}  */}
            </Grid>
          </Grid>
        </Grid>
        {isCancelConfirmationRendered && (
          <ConfirmationDialog
            show={true}
            cancel={() => setIsCancelConfirmationRendered(false)}
            confirmation={t('timeCardsPage.discardMessage')}
            proceed={handleCancel}
            options={{
              theme,
              title: t('timeCardsPage.unsavedChanges'),
              rejectLabel: t('timeCardsPage.actions.backButton'),
              confirmLabel: t('timeCardsPage.actions.discardButton')
            }}
          />
        )}
      </Grid>
      {editedRows.length > 0 && renderUnsavedChangesConfirmationModal()}
    </Grid>
  );
};

export default withOnlineAccessOnly(withAuthorization(TimeCards));
