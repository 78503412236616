import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';

import StyledDialog from 'shared/Dialog';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import StyledButtonSecondary from 'shared/Buttons/ButtonSecondary';

const StyledDiscardChangesDialog = ({
  discardChangesDialogIsOpen,
  setDiscardChangesDialogIsOpen,
  handleDiscard,
  content,
  shouldBeDisabled = false
}) => {
  const { t } = useTranslation();

  const handleClose = () => {
    setDiscardChangesDialogIsOpen(false);
  };

  return (
    <StyledDialog
      maxWidth="sm"
      isOpen={discardChangesDialogIsOpen}
      handleClose={handleClose}
      title={t('styledDiscardChangesDialog.title')}
      content={
        <Grid container>
          <Grid item xs={12}>
            {content}
          </Grid>
        </Grid>
      }
      actions={
        <Grid container justifyContent="flex-end">
          <Grid item>
            <StyledButtonSecondary label={'Back'} onClick={handleClose} />
          </Grid>
          <Grid item>
            <StyledButtonPrimary
              label={'Discard'}
              disabled={shouldBeDisabled}
              onClick={handleDiscard}
            />
          </Grid>
        </Grid>
      }
    />
  );
};

StyledDiscardChangesDialog.propTypes = {
  discardChangesDialogIsOpen: PropTypes.bool,
  setDiscardChangesDialogIsOpen: PropTypes.func,
  discardWasClicked: PropTypes.bool,
  handleDiscard: PropTypes.func,
  content: PropTypes.any,
  shouldBeDisabled: PropTypes.bool
};

export default StyledDiscardChangesDialog;
