import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import makeStyles from '@mui/styles/makeStyles';
import { Menu, MenuItem } from '@mui/material';

import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';

const useStyles = makeStyles(theme => ({
  root: {
    '&:hover': {
      backgroundColor: theme.palette.primary.lightest,
      color: 'black'
    }
  }
}));

const CancelMenu = ({ handleCancelChoice }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleToggle = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = ({ shouldCancelSeries }) => {
    handleCancelChoice({ shouldCancelSeries });
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <StyledButtonPrimary
        aria-label={t('cancelMenu.actions.cancel')}
        label={t('cancelMenu.actions.cancel')}
        onClick={handleToggle}
        endIcon={<KeyboardArrowDownIcon className={classes.editIcon} />}
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        keepMounted
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}>
        <MenuItem
          onClick={() => handleMenuItemClick({ shouldCancelSeries: false })}
          className={classes.root}>
          {t('cancelMenu.thisEvent')}
        </MenuItem>
        <MenuItem
          onClick={() => handleMenuItemClick({ shouldCancelSeries: true })}
          className={classes.root}>
          {t('cancelMenu.thisAndFollowingEvents')}
        </MenuItem>
      </Menu>
    </>
  );
};

CancelMenu.propTypes = {
  handleCancelChoice: PropTypes.func.isRequired
};

export default CancelMenu;
