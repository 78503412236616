import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import EventIcon from '@mui/icons-material/Event';
import ScheduleIcon from '@mui/icons-material/Schedule';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import LanguageIcon from '@mui/icons-material/Language';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import NotesIcon from '@mui/icons-material/Notes';
import moment from 'moment';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Card, Typography, IconButton } from '@mui/material';

import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import StyledNotice from 'shared/Notice';
import UpdateSingleOrientationDialog from 'components/orientations/UpdateSingleOrientationDialog';
import CancelSingleOrientationDialog from 'components/orientations/CancelSingleOrientationDialog';
import UpdateOrientationSeriesDialog from 'components/orientations/UpdateOrientationSeriesDialog';
import CancelOrientationSeriesDialog from 'components/orientations/CancelOrientationSeriesDialog';
import { formatDate } from 'utils/dateTime';
import EditMenu from './EditMenu';
import CancelMenu from './CancelMenu';

const useStyles = makeStyles(theme => ({
  label: {
    [theme.breakpoints.down('xl')]: { maxWidth: 300 },
    [theme.breakpoints.down('xl')]: { maxWidth: 190 },
    [theme.breakpoints.down('lg')]: { maxWidth: 300 },
    [theme.breakpoints.down('md')]: { maxWidth: 190 }
  },
  editIcon: { color: theme.palette.icon.main }
}));

const DetailsRow = ({ icon, label }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} style={{ marginBottom: 8 }}>
      <Grid container direction="row" spacing={2} alignItems="flex-start">
        <Grid item>{icon}</Grid>
        <Grid item>
          <Typography className={classes.label}>{label}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

DetailsRow.propTypes = {
  icon: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired
};

const OrientationDetailsCard = ({ orientation }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const isRecurring = !!orientation.series;
  const startDateTime = new Date(orientation.startDateTime);
  const formattedStartDate = formatDate(startDateTime);
  const formattedStartTime = moment(orientation.startDateTime).format('h:mm a');
  const formattedEndTime = moment(orientation.endDateTime).format('h:mm a');

  const [
    updateSingleOrientationDialogIsOpen,
    toggleUpdateSingleOrientationDialog
  ] = useState(false);

  const [
    updateOrientationSeriesDialogIsOpen,
    toggleUpdateOrientationSeriesDialog
  ] = useState(false);

  const [
    cancelSingleOrientationDialogIsOpen,
    toggleCancelSingleOrientationDialog
  ] = useState(false);

  const [
    cancelOrientationSeriesDialogIsOpen,
    toggleCancelOrientationSeriesDialog
  ] = useState(false);

  const handleEditChoice = ({ shouldEditSeries }) => {
    if (shouldEditSeries) {
      toggleUpdateOrientationSeriesDialog(true);
    } else {
      toggleUpdateSingleOrientationDialog(true);
    }
  };

  const handleCancelChoice = ({ shouldCancelSeries }) => {
    if (shouldCancelSeries) {
      toggleCancelOrientationSeriesDialog(true);
    } else {
      toggleCancelSingleOrientationDialog(true);
    }
  };

  return <>
    <Card className="padding">
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start">
            <Grid item>
              <Typography color="textPrimary" className="bold" component="h2">
                {t('orientationDetailsCard.title')}
              </Typography>
            </Grid>
            <Grid item>
              {!orientation.isCancelled && (
                <>
                  {!isRecurring && (
                    <IconButton
                      aria-label={t(
                        'orientationDetailsCard.editButtonAriaLabel'
                      )}
                      data-testid="orientation-details-card-edit-button"
                      onClick={() =>
                        toggleUpdateSingleOrientationDialog(true)
                      }
                      size="small">
                      <EditIcon className={classes.editIcon} />
                    </IconButton>
                  )}
                  {isRecurring && (
                    <EditMenu handleEditChoice={handleEditChoice} />
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
        {orientation.isCancelled && (
          <Grid item>
            <StyledNotice
              type="Notice"
              message={t('orientationDetailsCard.cancelledNotice.message')}
            />
          </Grid>
        )}
        <Grid item>
          <Grid container>
            <DetailsRow
              icon={
                <EventIcon
                  color="primary"
                  title={t('orientationDetailsCard.startDateIcon.title')}
                  aria-hidden={false}
                />
              }
              label={formattedStartDate}
            />
            <DetailsRow
              icon={
                <ScheduleIcon
                  color="primary"
                  title={t('orientationDetailsCard.scheduleIcon.title')}
                  aria-hidden={false}
                />
              }
              label={`${formattedStartTime} - ${formattedEndTime}`}
            />
            {isRecurring && (
              <DetailsRow
                icon={
                  <AutorenewIcon
                    color="primary"
                    title={t('orientationDetailsCard.seriesTypeIcon.title')}
                    aria-hidden={false}
                  />
                }
                label={orientation.series?.type}
              />
            )}
            <DetailsRow
              icon={
                <LanguageIcon
                  color="primary"
                  title={t('orientationDetailsCard.languageIcon.title')}
                  aria-hidden={false}
                />
              }
              label={
                orientation.language === 'EnglishSpanish'
                  ? t('orientationDetailsCard.details.languageLabel')
                  : orientation.language
              }
            />
            <DetailsRow
              icon={
                <ThumbUpAltIcon
                  color="primary"
                  title={t('orientationDetailsCard.attendeesIcon.title')}
                  aria-hidden={false}
                />
              }
              label={`${orientation.attendees.length} / ${orientation.maxAttendees}`}
            />
            <DetailsRow
              icon={
                <SupervisedUserCircleIcon
                  color="primary"
                  title={t('orientationDetailsCard.facilitatorsIcon.title')}
                  aria-hidden={false}
                />
              }
              label={`${orientation.facilitators
                .map(facilitator => {
                  return facilitator.name;
                })
                .join(', ')}`}
            />
            {orientation.notes && (
              <DetailsRow
                icon={
                  <NotesIcon
                    color="primary"
                    title={t('orientationDetailsCard.notesIcon.title')}
                    aria-hidden={false}
                  />
                }
                label={orientation.notes}
              />
            )}
          </Grid>
        </Grid>
        {!orientation.isCancelled && (
          <Grid item>
            <Grid container justifyContent="flex-end">
              {!isRecurring && (
                <StyledButtonPrimary
                  label={t('orientationDetailsCard.actions.cancel')}
                  onClick={() => toggleCancelSingleOrientationDialog(true)}
                />
              )}
              {isRecurring && (
                <CancelMenu handleCancelChoice={handleCancelChoice} />
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Card>
    <UpdateSingleOrientationDialog
      updateSingleOrientationDialogIsOpen={
        updateSingleOrientationDialogIsOpen
      }
      toggleUpdateSingleOrientationDialog={
        toggleUpdateSingleOrientationDialog
      }
      orientation={orientation}
    />
    <UpdateOrientationSeriesDialog
      isOpen={updateOrientationSeriesDialogIsOpen}
      setIsOpen={toggleUpdateOrientationSeriesDialog}
      orientation={orientation}
    />
    <CancelSingleOrientationDialog
      cancelSingleOrientationDialogIsOpen={
        cancelSingleOrientationDialogIsOpen
      }
      toggleCancelSingleOrientationDialog={
        toggleCancelSingleOrientationDialog
      }
      orientation={orientation}
    />
    <CancelOrientationSeriesDialog
      cancelOrientationSeriesDialogIsOpen={
        cancelOrientationSeriesDialogIsOpen
      }
      toggleCancelOrientationSeriesDialog={
        toggleCancelOrientationSeriesDialog
      }
      orientation={orientation}
    />
  </>;
};

OrientationDetailsCard.propTypes = {
  orientation: PropTypes.object.isRequired
};

export default OrientationDetailsCard;
