import React from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography } from '@mui/material';

const useStyles = makeStyles(theme => ({
  subTitle: { fontSize: '0.875rem' },
  title: { fontSize: '1.5rem' }
}));

const MergeDuplicateRecords = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography
          className={classes.subTitle}
          color="textSecondary"
          component="h3">
          {t('tutorials.personnel.pageTitle')}
        </Typography>
        <Typography
          className={classes.title}
          color="textPrimary"
          component="h4">
          {t('tutorials.personnel.mergeDuplicateRecords.menuTitle')}
        </Typography>
      </Grid>
      <Grid item>
        <Typography color="textPrimary">
          {t('tutorials.personnel.mergeDuplicateRecords.chooseMasterRecord')}
        </Typography>
        <br />
        <Typography color="textPrimary">
          {t('tutorials.personnel.mergeDuplicateRecords.openDialog')}
        </Typography>
        <br />
        <Typography color="textPrimary">
          {t('tutorials.personnel.mergeDuplicateRecords.mergeInstructions')}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default MergeDuplicateRecords;
