const TRAINING_DIALOG_VIEWS = {
  READ_ONLY: 'readOnly',
  ADD_TRAINING: 'addTraining',
  EDIT_TRAINING: 'editTraining',
  VIEW_PDF: 'viewPdfAttachment',
  VIEW_IMAGE: 'viewImageAttachment',
  CONFIRM_DELETE: 'confirmDelete',
  CONFIRM_CANCEL: 'confirmCancel',
  NO_DOC_PREVIEW: 'noDocPreview',
  NO_EXCEL_PREVIEW: 'noExcelPreview'
};

export default TRAINING_DIALOG_VIEWS;
