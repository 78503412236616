import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useField } from 'react-final-form-hooks';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography, Checkbox, Avatar } from '@mui/material';

import useFormValidation from 'hooks/useFormValidation';
import StyledStep from 'shared/Step';
import { dateTimeToLuxonDateTime } from 'utils/dateTime';

import {
  formatPhoneNumber,
  formatInputAndInternationalPhoneNumber
} from 'utils/formatPhoneNumber';

const useStyles = makeStyles(theme => ({
  labelText: { fontWeight: 'bold', paddingRight: 5 },
  container: {
    backgroundColor: theme.palette.background.default,
    borderRadius: 4,
    padding: 15,
    marginBottom: 15
  },
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    marginBottom: theme.spacing(1)
  }
}));

const ConfirmPersonStep = ({
  formValues,
  form,
  isHenselPhelpsEmployee,
  updatedProfileImage,
  selectedMatch
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { isRequired } = useFormValidation();

  const personnelConfirmation = useField(
    'personnelConfirmation',
    form,
    isRequired
  );

  const personnelPhoneNumber =
    selectedMatch?.contactInformation.primaryPhone?.number ||
    formValues.phoneNumber;
  const personnelInputPhoneNumber = formatPhoneNumber(personnelPhoneNumber);
  const personnelInternationalPhoneNumber = formatInputAndInternationalPhoneNumber(
    personnelPhoneNumber
  )?.international;
  const validPersonnelPhoneNumber = personnelInternationalPhoneNumber
    ? personnelInternationalPhoneNumber
    : personnelInputPhoneNumber;

  const renderRow = (label, value) => {
    return (
      <Grid item>
        <Grid container direction="row">
          <Grid item>
            <Typography className={classes.labelText}>{label}</Typography>
          </Grid>
          {label !== t('confirmPersonStep.phoneNumber') && (
            <Grid item>
              <Typography>{value}</Typography>
            </Grid>
          )}
          {label === t('confirmPersonStep.phoneNumber') && (
            <Typography>{validPersonnelPhoneNumber}</Typography>
          )}
        </Grid>
      </Grid>
    );
  };

  const renderConfirmationFields = () => {
    if (isHenselPhelpsEmployee) {
      return (
        <>
          {renderRow(
            t('confirmPersonStep.tradePartner'),
            formValues.selectedTradePartner.name
          )}
          {renderRow(
            t('confirmPersonStep.fullName'),
            formValues.selectedHenselPhelpsEmployee.name
          )}
          {renderRow(
            t('confirmPersonStep.nickname'),
            selectedMatch?.nickname ?? ''
          )}
          {renderRow(
            t('confirmPersonStep.dob'),
            dateTimeToLuxonDateTime(selectedMatch?.dob)?.toFormat('MM/dd/yyyy')
          )}
          {renderRow(
            t('confirmPersonStep.phoneNumber'),
            selectedMatch?.contactInformation.primaryPhone?.number ??
              selectedMatch?.email ??
              ''
          )}
          {renderRow(
            t('confirmPersonStep.jobTitle'),
            formValues.selectedHenselPhelpsEmployee.jobTitle
          )}
          {renderRow(
            t('confirmPersonStep.email'),
            formValues.selectedHenselPhelpsEmployee.email
          )}
          {renderRow(
            t('confirmPersonStep.customId'),
            formValues.customId ?? ''
          )}
          {renderRow(
            t('confirmPersonStep.supervisor.title'),
            formValues.isSupervisor
              ? t('confirmPersonStep.supervisor.yes')
              : t('confirmPersonStep.supervisor.no')
          )}
          {renderRow(
            t('confirmPersonStep.isPermitApprover.title'),
            formValues.isPermitApprover
              ? t('confirmPersonStep.isPermitApprover.yes')
              : t('confirmPersonStep.isPermitApprover.no')
          )}
        </>
      );
    }

    return (
      <>
        {renderRow(
          t('confirmPersonStep.tradePartner'),
          formValues.selectedTradePartner.name
        )}
        {renderRow(t('confirmPersonStep.firstName'), formValues.firstName)}
        {renderRow(
          t('confirmPersonStep.middleName'),
          formValues.middleName ?? ''
        )}
        {renderRow(t('confirmPersonStep.lastName'), formValues.lastName)}
        {renderRow(t('confirmPersonStep.nickname'), formValues.nickname ?? '')}
        {renderRow(
          t('confirmPersonStep.dob'),
          formValues.dob.toFormat('MM/dd/yyyy')
        )}
        {renderRow(
          t('confirmPersonStep.phoneNumber'),
          formValues.phoneNumber ?? ''
        )}
        {renderRow(t('confirmPersonStep.email'), formValues.email ?? '')}
        {renderRow(t('confirmPersonStep.customId'), formValues.customId ?? '')}
        {renderRow(
          t('confirmPersonStep.supervisor.title'),
          formValues.isSupervisor
            ? t('confirmPersonStep.supervisor.yes')
            : t('confirmPersonStep.supervisor.no')
        )}
      </>
    );
  };

  return (
    <StyledStep title={t('confirmPersonStep.title')}>
      <Grid container direction="row" alignItems="center">
        <Typography>{t('confirmPersonStep.confirmationMessage')}</Typography>
        <Checkbox
          checked={
            personnelConfirmation.input.value === ''
              ? false
              : personnelConfirmation.input.value
          }
          onChange={personnelConfirmation.input.onChange}
          name="confirmationCheck"
          color="primary"
          inputProps={{ 'data-testid': 'confirmationCheckBox' }}
        />
      </Grid>
      <Grid
        className={classes.container}
        container
        direction="column"
        spacing={1}>
        <Grid item>
          {updatedProfileImage && (
            <Avatar
              className={classes.avatar}
              src={updatedProfileImage.preview}></Avatar>
          )}
          {renderConfirmationFields()}
        </Grid>
      </Grid>
    </StyledStep>
  );
};

ConfirmPersonStep.propTypes = {
  formValues: PropTypes.any,
  form: PropTypes.any,
  isHenselPhelpsEmployee: PropTypes.bool,
  updatedProfileImage: PropTypes.object,
  selectedMatch: PropTypes.object
};

export default ConfirmPersonStep;
