import gql from 'graphql-tag';

const SAFETY_HOURS_SUPERVISION_FRAGMENT = gql`
  fragment SafetyHoursSupervision on SafetyHours {
    id
    supervisedBy {
      id
      fullName
      soteriaAdUser {
        id
        employeeId
      }
    }
  }
`;

export const GET_SAFETY_HOURS_ON_PROJECT = gql`
  query GetSafetyHoursOnProject(
    $projectId: ID!
    $startDate: String
    $endDate: String
  ) {
    getSafetyHoursOnProject(
      projectId: $projectId
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      hours
      postDate
      payrollEndDate
      workCraft
      workClass
      paySequence
      postSequence
      earnCode
      jobCostCompany
      phase {
        id
        phaseGroup
        phaseCode
        phaseDescription
      }
      tradePartnerPersonnel {
        id
        personnel {
          id
          fullName
          nickname
          soteriaAdUser {
            id
            employeeId
          }
        }
        supervisedBy {
          id
          fullName
          nickname
          soteriaAdUser {
            id
            employeeId
          }
        }
      }
      personnel {
        id
        fullName
        nickname
        soteriaAdUser {
          id
          employeeId
        }
      }
      supervisedBy {
        id
        fullName
        nickname
        soteriaAdUser {
          id
          employeeId
        }
      }
    }
  }
`;

export const UPDATE_MANY_SAFETY_HOURS = gql`
  mutation UpdateManySafetyHours($inputs: [SafetyHoursUpdateInput!]!) {
    updateManySafetyHours(inputs: $inputs) {
      ...SafetyHoursSupervision
    }
  }
  ${SAFETY_HOURS_SUPERVISION_FRAGMENT}
`;
