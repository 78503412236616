import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import FlagIcon from '@mui/icons-material/Flag';
import { Grid, Typography, Card, Avatar } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import StyledLink from 'shared/Link';
import AttendeeFlagDetailsDialog from '../AttendeeFlagDetailsDialog';

const useStyles = makeStyles(theme => ({
  titleContainer: { paddingBottom: theme.spacing(1) },
  titleText: { display: 'inline', fontWeight: 'bold' },
  card: { padding: theme.spacing(3) },
  flagIcon: {
    color: theme.palette.background.alternateText
  },
  toImproveAvatar: {
    backgroundColor: theme.palette.flag.toImprove
  },
  resolvedAvatar: {
    backgroundColor: theme.palette.flag.resolved
  },
  achievementAvatar: {
    backgroundColor: theme.palette.flag.achievement
  },
  attentionRequiredAvatar: {
    backgroundColor: theme.palette.flag.attentionRequired
  }
}));

const AttendeeFlags = ({ attendee }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isFlagDetailsOpen, setIsFlagDetailsOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState('');

  const getFilteredFlags = status => {
    return (
      attendee?.personnel?.flags?.filter(
        flag => flag?.status?.toLowerCase() === status
      ) ?? []
    );
  };

  const toImproveFlags = getFilteredFlags('toimprove');
  const resolvedFlags = getFilteredFlags('resolved');
  const achievementFlags = getFilteredFlags('achievement');
  const attentionRequiredFlags = getFilteredFlags('high');

  const handleDetailsClick = status => {
    setSelectedFilter(status);
    setIsFlagDetailsOpen(true);
  };

  const renderSingleFlag = (label, flagTypeLength, type) => {
    const getClassName = () => {
      if (type === 'toimprove') {
        return `${classes.toImproveAvatar}`;
      } else if (type === 'resolved') {
        return `${classes.resolvedAvatar}`;
      } else if (type === 'achievement') {
        return `${classes.achievementAvatar}`;
      } else if (type === 'high') {
        return `${classes.attentionRequiredAvatar}`;
      }

      return;
    };

    return (
      <Grid item>
        <Grid container direction="row" spacing={1}>
          <Grid item>
            <Avatar className={getClassName()}>
              <FlagIcon className={classes.flagIcon} />
            </Avatar>
          </Grid>
          <Grid item>
            <Typography>
              {label} ({flagTypeLength})
            </Typography>
            <StyledLink
              data-testid={`${type}-status-view-link`}
              type="simpleSmall"
              ariaLabel={`view details about ${type} flag`}
              onKeyDown={() => handleDetailsClick(type)}
              onClick={() => handleDetailsClick(type)}
              linkText={t('personnelFlags.status.viewLink')}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return <>
    <>
      <Card className={classes.card}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container direction="column">
              <Grid item>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  className={classes.titleContainer}>
                  <Grid item>
                    <Typography className={classes.titleText}>
                      {t('attendeeFlags.title')}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="row" spacing={3}>
                  {attentionRequiredFlags.length > 0 &&
                    renderSingleFlag(
                      t('attendeeFlags.status.attentionRequired'),
                      attentionRequiredFlags.length,
                      'high'
                    )}
                  {toImproveFlags.length > 0 &&
                    renderSingleFlag(
                      t('attendeeFlags.status.toImprove'),
                      toImproveFlags.length,
                      'toimprove'
                    )}
                  {resolvedFlags.length > 0 &&
                    renderSingleFlag(
                      t('attendeeFlags.status.resolved'),
                      resolvedFlags.length,
                      'resolved'
                    )}
                  {achievementFlags.length > 0 &&
                    renderSingleFlag(
                      t('attendeeFlags.status.achievement'),
                      achievementFlags.length,
                      'achievement'
                    )}
                  {toImproveFlags.length <= 0 &&
                    resolvedFlags.length <= 0 &&
                    achievementFlags.length <= 0 &&
                    attentionRequiredFlags.length <= 0 && (
                      <Grid item>
                        <Typography color="textSecondary">
                          {t('attendeeFlags.noFlagsMessage')}
                        </Typography>
                      </Grid>
                    )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </>
    <AttendeeFlagDetailsDialog
      attendee={attendee}
      isFlagDetailsOpen={isFlagDetailsOpen}
      setIsFlagDetailsOpen={setIsFlagDetailsOpen}
      selectedFilter={selectedFilter}
      setSelectedFilter={setSelectedFilter}
    />
  </>;
};

AttendeeFlags.propTypes = {
  attendee: PropTypes.object.isRequired
};

export default AttendeeFlags;
