import gql from 'graphql-tag';

export const GET_MINIMAL_AHA_TEMPLATE = gql`
  query GetMinimalAhaTemplate($id: ID!) {
    ahaTemplate(id: $id) {
      id
      type
      ahaProjectTemplate {
        id
        contractor
      }
    }
  }
`;

export const GET_AHA_TEMPLATE = gql`
  query GetAhaTemplate($id: ID!) {
    ahaTemplate(id: $id) {
      id
      created
      lastModified
      type
      ahaGlobalTemplateCategory {
        id
        name
      }
      ahaProjectTemplate {
        id
        status
        contractor
        location
        notes
        reviewReminderCadenceDays
        created
        lastModified
        ahaReviews {
          id
          created
          completed
          status
          requestedReviewers {
            id
            upn
            name
          }
        }
        ownedBy {
          id
          upn
          name
          jobTitle
        }
        project {
          id
          name
        }
      }
      ahaActivitys {
        id
        name
        isActive
        created
        lastModified
        order
      }
      ahaEquipments {
        id
        inspectionRequirements
        isActive
        name
        order
        trainings
      }
      ahaSteps {
        ahaGlobalStepCategory {
          id
          name
        }
        controls
        hazards
        id
        isActive
        name
        order
        riskAssessmentCode
        ahaStepReferences {
          id
          value
          ahaReferenceOption
          ahaStep {
            id
          }
        }
      }
      createdBy {
        id
        jobTitle
        name
      }
    }
  }
`;

export const AHA_TEMPLATE_SUBSCRIPTION = gql`
  subscription AhaTemplateSubscription($id: ID!) {
    ahaTemplate(id: $id) {
      id
      created
      lastModified
      type
      ahaGlobalTemplateCategory {
        id
        name
      }
      ahaProjectTemplate {
        id
        status
        contractor
        location
        notes
        reviewReminderCadenceDays
        ahaReviews {
          id
          created
          completed
          status
          requestedReviewers {
            id
            upn
            name
          }
        }
        ownedBy {
          id
          upn
          name
          jobTitle
        }
        project {
          id
          name
        }
      }
      ahaActivitys {
        id
        name
        isActive
        created
        lastModified
        order
      }
      ahaEquipments {
        id
        inspectionRequirements
        isActive
        name
        order
        trainings
      }
      ahaSteps {
        ahaGlobalStepCategory {
          id
          name
        }
        controls
        hazards
        id
        isActive
        name
        order
        riskAssessmentCode
        ahaStepReferences {
          id
          value
          ahaReferenceOption
          ahaStep {
            id
          }
        }
      }
      createdBy {
        id
        jobTitle
        name
      }
    }
  }
`;
