import { GET_ALL_COMPANIES } from 'graphql/company';

export const updateCompaniesCache = (cache, { data: { createCompany } }) => {
  const { companies } = cache.readQuery({
    query: GET_ALL_COMPANIES
  });
  cache.writeQuery({
    query: GET_ALL_COMPANIES,
    data: { companies: companies.concat([createCompany]) }
  });
};
