import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import EditIcon from '@mui/icons-material/Edit';
import makeStyles from '@mui/styles/makeStyles';
import {
  Grid,
  Card,
  Typography,
  FormControl,
  LinearProgress,
  Select,
  InputLabel,
  MenuItem
} from '@mui/material';

import useToast from 'hooks/useToast';
import { useForm, useField } from 'react-final-form-hooks';
import useFormValidation from 'hooks/useFormValidation';
import StyledButtonMuted from 'shared/Buttons/ButtonMuted';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import StyledButtonSecondary from 'shared/Buttons/ButtonSecondary';
import StyledPhoneNumberField from 'shared/PhoneNumberField';
import { UPDATE_PROJECT } from 'graphql/project';

const useStyles = makeStyles(theme => ({
  card: { padding: theme.spacing(3) },
  label: {
    fontWeight: 'bold',
    fontSize: '0.875rem',
    display: 'inline',
    marginRight: 4
  },
  value: { display: 'inline' },
  form: { padding: 4 },
  labelText: { fontWeight: 'bold' }
}));

const ProjectPermitCard = ({ project, isLoading }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { displayToast } = useToast();
  const [isEditing, toggleEditing] = useState(false);
  const { isPhoneNumber } = useFormValidation();

  const [updateProject, { loading: isUpdateLoading }] = useMutation(
    UPDATE_PROJECT
  );

  const { form, values } = useForm({
    /* istanbul ignore next */
    onSubmit: () => {}, // this function required for useForm but is not used
    initialValues: {
      firewatch: project.firewatch,
      fireDeptPhoneNumber: project.fireDeptPhoneNumber.number ?? ''
    }
  });

  const canSubmit = () => {
    const { error, invalid, pristine } = form.getState();
    return !(invalid || error || pristine || isUpdateLoading || isLoading);
  };

  /**
   * Plus sign was being submitted if phone number was deleted.
   * Submits fireDeptPhoneNumber if phone number is valid and doesn't only contain a + sign.
   */
  const handleSubmit = () => {
    updateProject({
      variables: {
        id: project?.id,
        firewatch: values.firewatch,
        fireDeptPhoneNumber: {
          number:
            values.fireDeptPhoneNumber === '+' ? '' : values.fireDeptPhoneNumber
        }
      }
    })
      .then(() => {
        displayToast(
          t('projectDetailsPage.projectPermitCard.toasts.update.success'),
          'success'
        );
        handleClose();
      })
      .catch(error => {
        console.error('Project Permit Error: ', error);
        displayToast(
          t('projectDetailsPage.projectPermitCard.toasts.update.error'),
          'error'
        );
      });
  };

  const firewatch = useField('firewatch', form);
  const fireDeptPhoneNumber = useField(
    'fireDeptPhoneNumber',
    form,
    isPhoneNumber
  );

  const handleClose = () => {
    form.reset();
    toggleEditing(false);
  };

  return (
    <>
      <Grid container direction="column" spacing={3}>
        <Grid item xs={12}>
          {isUpdateLoading && <LinearProgress color="primary" />}
          <Card className={classes.card}>
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Typography className="bold" component="h2">
                      {t('projectDetailsPage.projectPermitCard.title')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    {isEditing && (
                      <Typography color="primary">
                        {t('projectDetailsPage.projectPermitCard.editing')}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              {!isEditing && (
                <>
                  <Grid item>
                    <Typography component="span">
                      <Grid item>
                        <Typography
                          color="textSecondary"
                          className={classes.label}>
                          {t('projectDetailsPage.projectPermitCard.firewatch')}
                        </Typography>
                        <Typography className={classes.value}>
                          {project.firewatch + ' min.'}
                        </Typography>
                      </Grid>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography component="span">
                      <Grid item>
                        <Typography
                          color="textSecondary"
                          className={classes.label}>
                          {t(
                            'projectDetailsPage.projectPermitCard.fireDeptPhoneNumber'
                          )}
                        </Typography>
                        <Typography className={classes.value}>
                          {project.fireDeptPhoneNumber.number}
                        </Typography>
                      </Grid>
                    </Typography>
                  </Grid>
                  <Grid item style={{ marginTop: 8 }}>
                    <Grid
                      container
                      justifyContent="flex-end"
                      alignItems="flex-end">
                      <Grid item>
                        <StyledButtonMuted
                          startIcon={<EditIcon color="action" />}
                          label={t(
                            'projectDetailsPage.projectPermitCard.actions.editButton'
                          )}
                          onClick={() => toggleEditing(true)}
                          disabled={isLoading}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
              {isEditing && (
                <form onSubmit={handleSubmit} className={classes.form}>
                  <Grid container spacing={2} direction="column">
                    <Grid item xs={12}>
                      <FormControl variant="standard">
                        <InputLabel className={classes.labelText}>
                          Firewatch:
                        </InputLabel>
                        <Select
                          variant="standard"
                          id="firewatch-select"
                          value={firewatch.input.value}
                          label="Firewatch"
                          onChange={firewatch.input.onChange}>
                          <MenuItem value={30}>30 min.</MenuItem>
                          <MenuItem value={60}>60 min.</MenuItem>
                          <MenuItem value={90}>90 min.</MenuItem>
                          <MenuItem value={120}>120 min.</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <StyledPhoneNumberField
                        label={t(
                          'projectDetailsPage.projectPermitCard.fireDeptPhoneNumber'
                        )}
                        value={fireDeptPhoneNumber.input.value}
                        meta={fireDeptPhoneNumber.meta}
                        onChange={fireDeptPhoneNumber.input.onChange}
                        disabled={isLoading}
                      />
                    </Grid>
                    <Grid item>
                      <Grid container justifyContent="flex-end">
                        <Grid item>
                          <StyledButtonSecondary
                            onClick={handleClose}
                            disabled={isLoading || isUpdateLoading}
                            label={t(
                              'projectDetailsPage.projectPermitCard.actions.cancelButton'
                            )}
                          />
                        </Grid>
                        <Grid item>
                          <StyledButtonPrimary
                            onClick={handleSubmit}
                            disabled={!canSubmit()}
                            label={t(
                              'projectDetailsPage.projectPermitCard.actions.submitButton'
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

ProjectPermitCard.propTypes = {
  project: PropTypes.object,
  isLoading: PropTypes.bool
};

export default ProjectPermitCard;
