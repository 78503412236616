import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useField, useForm } from 'react-final-form-hooks';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import makeStyles from '@mui/styles/makeStyles';
import {
  Grid,
  IconButton,
  Button,
  Typography,
  Link,
  TextField
} from '@mui/material';
import { useMutation } from '@apollo/react-hooks';

import {
  UPDATE_GLOBAL_AHA_STEP_CATEGORY,
  GET_ALL_GLOBAL_STEP_CATEGORIES
} from 'graphql/aha';
import useToast from 'hooks/useToast';
import useFormValidation from 'hooks/useFormValidation';

const useStyles = makeStyles(theme => ({
  expandIcon: { color: 'gray', '&:hover': { cursor: 'pointer' } },
  categoryName: { fontSize: '0.875rem', '&:hover': { cursor: 'pointer' } },
  headingExpanded: { fontWeight: 'bold', '&:hover': { cursor: 'auto' } },
  buttonLabel: { fontSize: '0.75rem', fontWeight: 'bold' },
  buttonContainer: { marginLeft: theme.spacing(1) }
}));

const EditableGlobalStepCategory = ({
  category,
  isGlobalEditing,
  setIsEditing,
  isExpanded,
  setIsExpanded,
  onDeleteClick,
  categoriesBeingEdited,
  setCategoriesBeingEdited,
  categoryIdsWithUnsavedChanges,
  setCategoryIdsWithUnsavedChanges,
  discardWasClicked,
  setDiscardWasClicked,
  unsavedChangesDialogIsOpen,
  setUnsavedChangesDialogIsOpen
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { displayToast } = useToast();
  const { isRequired } = useFormValidation();
  const [isEditingStepCategoryName, setIsEditingStepCategoryName] = useState(
    false
  );

  const { form, values, pristine } = useForm({
    /* istanbul ignore next */
    onSubmit: () => {}, // this function required for useForm but is not used
    initialValues: {
      categoryName: category.name
    }
  });

  const categoryNameField = useField('categoryName', form, isRequired);

  useEffect(() => {
    if (discardWasClicked) {
      form.resetFieldState('categoryName');
      form.reset();
      setCategoriesBeingEdited([]);
      setIsEditingStepCategoryName(false);
      setIsEditing(false);
      setUnsavedChangesDialogIsOpen(false);
      setDiscardWasClicked(false);
    }
  }, [
    discardWasClicked,
    form,
    setCategoriesBeingEdited,
    setDiscardWasClicked,
    setIsEditing,
    setUnsavedChangesDialogIsOpen,
    unsavedChangesDialogIsOpen
  ]);

  const [updateGlobalAhaCategory, { loading: isLoading }] = useMutation(
    UPDATE_GLOBAL_AHA_STEP_CATEGORY,
    {
      update(
        cache,
        { data: { updateAhaGlobalStepCategory: updatedCategory } }
      ) {
        let result = null;
        try {
          result = cache.readQuery({
            query: GET_ALL_GLOBAL_STEP_CATEGORIES
          });
        } catch (err) {
          // https://github.com/apollographql/apollo-feature-requests/issues/1
        }
        if (result?.ahaGlobalStepCategories) {
          cache.writeQuery({
            query: GET_ALL_GLOBAL_STEP_CATEGORIES,
            data: {
              ahaGlobalStepCategories: result.ahaGlobalStepCategories.map(
                category => {
                  if (category.id === updatedCategory.id) {
                    return {
                      ...category,
                      ...updatedCategory
                    };
                  }
                  return category;
                }
              )
            }
          });
        }
      }
    }
  );

  const canSubmit = () => {
    const { error, invalid } = form.getState();
    return !(invalid || error || pristine);
  };

  const handleClose = () => {
    form.reset();
    setIsEditingStepCategoryName(false);
    setCategoriesBeingEdited(
      categoriesBeingEdited.filter(
        categoryBeingEdited => categoryBeingEdited.id !== category.id
      )
    );
  };

  const handleSubmit = () => {
    updateGlobalAhaCategory({
      variables: {
        input: {
          id: category.id,
          name: values.categoryName
        }
      }
    })
      .then(() => {
        displayToast(
          t('updateGlobalAhaStepCategory.toasts.update.success'),
          'success'
        );
        handleClose();
      })
      .catch(error => {
        console.error('Update Global Step Category Error: ', error);
        displayToast(
          t('updateGlobalAhaStepCategory.toasts.update.error'),
          'error'
        );
      });
  };

  useEffect(() => {
    const hasChanges = values.categoryName !== category.name;

    if (hasChanges && !categoryIdsWithUnsavedChanges.includes(category.id)) {
      setCategoryIdsWithUnsavedChanges([
        ...categoryIdsWithUnsavedChanges,
        category.id
      ]);
    } else if (
      !hasChanges &&
      categoryIdsWithUnsavedChanges.includes(category.id)
    ) {
      setCategoryIdsWithUnsavedChanges(
        categoryIdsWithUnsavedChanges.filter(
          unsavedChange => unsavedChange !== category.id
        )
      );
    }
  });

  return (
    <Grid container alignItems="center">
      <Grid item>
        {isExpanded ? (
          <ExpandMoreIcon
            className={classes.expandIcon}
            onClick={setIsExpanded}
          />
        ) : (
          <KeyboardArrowRightIcon
            className={classes.expandIcon}
            onClick={setIsExpanded}
          />
        )}
      </Grid>
      {!isEditingStepCategoryName && (
        <Grid item>
          <Link
            color="initial"
            className={classes.categoryName}
            onClick={setIsExpanded}
            underline="hover">
            <Typography
              className={
                isExpanded ? classes.headingExpanded : classes.heading
              }>
              {category.name}
            </Typography>
          </Link>
        </Grid>
      )}

      {isEditingStepCategoryName && (
        <>
          <Grid item>
            <TextField
              variant="standard"
              value={categoryNameField.input.value}
              input={categoryNameField.input}
              onChange={categoryNameField.input.onChange}
              meta={categoryNameField.meta}
              disabled={isLoading}
              autoFocus={true}
              required />
          </Grid>
          <Grid item>
            <Grid
              container
              direction="row"
              spacing={1}
              className={classes.buttonContainer}>
              <Grid item>
                <Button onClick={handleSubmit} disabled={!canSubmit()}>
                  <Typography className={classes.buttonLabel}>Save</Typography>
                </Button>
              </Grid>
              <Grid item>
                <Button onClick={handleClose} disabled={isLoading}>
                  <Typography className={classes.buttonLabel}>
                    Cancel
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}

      {isGlobalEditing && !isEditingStepCategoryName && (
        <Grid item>
          <IconButton
            size="small"
            onClick={() => {
              setIsEditingStepCategoryName(true);
              setCategoriesBeingEdited([...categoriesBeingEdited, category]);
            }}>
            <EditIcon />
          </IconButton>

          <IconButton
            size="small"
            onClick={onDeleteClick}
            disabled={category.stepCount > 0 || category.categoryStepCount > 0}>
            <DeleteIcon />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
};

EditableGlobalStepCategory.propTypes = {
  category: PropTypes.object,
  isGlobalEditing: PropTypes.bool,
  setIsEditing: PropTypes.func,
  isExpanded: PropTypes.bool,
  setIsExpanded: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  categoriesBeingEdited: PropTypes.array,
  setCategoriesBeingEdited: PropTypes.func,
  categoryIdsWithUnsavedChanges: PropTypes.array,
  setCategoryIdsWithUnsavedChanges: PropTypes.func,
  discardWasClicked: PropTypes.bool,
  setDiscardWasClicked: PropTypes.func,
  unsavedChangesDialogIsOpen: PropTypes.bool,
  setUnsavedChangesDialogIsOpen: PropTypes.func
};

export default EditableGlobalStepCategory;
