import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import deepEqual from 'deep-equal';
import { useParams, useLocation } from 'react-router-dom';
import NavigationPrompt from 'react-router-navigation-prompt';
import cloneDeep from 'clone-deep';
import { useMutation } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import withQueryParams from 'react-router-query-params';
import { Typography, Grid, CircularProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DateTime } from 'luxon';
import { NumericFormat } from 'react-number-format';

import withAuthorization from 'hocs/withAuthorization';
import useProjectWorkerHourRows from 'hooks/useProjectWorkerHourRows';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import StyledButtonSecondary from 'shared/Buttons/ButtonSecondary';
import { SET_MANY_WORKER_HOURS } from 'graphql/tradePartner';
import { GET_TRADE_PARTNER_MONTH_WORKS } from 'graphql/tradePartnerMonthWork';
import useProject from 'hooks/useProject';
import useToast from 'hooks/useToast';
import LoadingSpinner from 'components/common/LoadingSpinner';
import ErrorNotice from 'components/common/ErrorNotice';
import StyledMonthDatePicker from 'shared/MonthDatePicker';
import WorkerHoursTable from 'components/WorkerHoursTable';
import PageNotFoundNotice from 'components/common/PageNotFoundNotice';
import ConfirmationDialog from 'components/common/ConfirmDialog';
import withOnlineAccessOnly from 'hocs/withOnlineAccessOnly';

const useStyles = makeStyles(theme => ({
  title: { fontSize: '2rem' },
  dataGridContainer: { paddingBottom: theme.spacing(1) },
  totalHoursContainer: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 4,
    width: 250,
    border: `1px solid ${theme.palette.observation.status}`,
    padding: '6px 2px 2px 12px',
    display: 'flex'
  },
  totalHoursText: {
    color: theme.palette.secondary.contrastText,
    marginRight: 15,
    fontSize: '0.875rem',
    fontFamily: 'Roboto, ui-sans-serif, sans-serif',
    fontWeight: 'bold'
  }
}));

const isValidYear = year => {
  if (!year) {
    return false;
  }
  const yearInteger = parseInt(year);
  return yearInteger <= new Date().getFullYear();
};

const isValidMonth = month => {
  if (!month) {
    return false;
  }
  const monthInteger = parseInt(month);
  return monthInteger >= 1 && monthInteger <= 12;
};

const validateSelectedDate = search => {
  const params = new URLSearchParams(search);
  const year = params.get('year');
  const month = params.get('month');
  const today = DateTime.now();

  return (
    isValidYear(year) &&
    isValidMonth(month) &&
    DateTime.fromObject({ year, month }) < today
  );
};

const ProjectWorkerHoursPage = ({ setQueryParams }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const { projectId } = useParams();
  const { project } = useProject(projectId);
  const { displayToast } = useToast();
  const theme = useTheme();

  // if month is selected, do nothing
  // if month is not selected, subtract one
  const initialDate = () => {
    const params = new URLSearchParams(location.search);
    const year = params.get('year');
    const month = params.get('month');

    if (isValidYear(year) && isValidMonth(month)) {
      return DateTime.fromObject({ year, month });
    }

    const now = DateTime.now();
    return DateTime.fromObject({ year: now.year, month: now.month - 1 });
  };

  const [
    isCancelConfirmationRendered,
    setIsCancelConfirmationRendered
  ] = useState(false);
  const [selectedDate, setSelectedDate] = useState(initialDate());

  const [isEditing, toggleEditing] = useState(false);
  //state to keep track of rows that should be counted in total hours
  const [totalWorkerHourRows, setTotalWorkerHourRows] = useState();
  const [totalHours, setTotalHours] = useState(null);
  const submitButtonRef = useRef(null);

  const handleDateChange = date => {
    const year = date.year;
    const month = date.month;
    setQueryParams({ year, month });
    setSelectedDate(date);
  };
  const selectedYear = selectedDate.year;
  const selectedMonth = selectedDate.monthLong;
  const [currentMonth, setCurrentMonth] = useState(null);

  const { rows: workerHourRows, loading, error } = useProjectWorkerHourRows(
    projectId,
    selectedYear,
    selectedMonth
  );

  const [clonedRows, setClonedRows] = useState(cloneDeep(workerHourRows));

  const [setManyWorkerHours, { loading: setManyLoading }] = useMutation(
    SET_MANY_WORKER_HOURS,
    {
      update(cache, { data: { setManyWorkerHours: tradePartnersToUpdate } }) {
        // the following check allows the test file to run without requiring queries to the cache
        if (cache.data.data.ROOT_QUERY) {
          const allMonthWorksFromResponse = tradePartnersToUpdate.reduce(
            (monthWorks, tradePartner) => {
              const monthWorksToAdd = tradePartner.workerHours.map(
                monthWork => ({
                  ...monthWork,
                  tradePartner: {
                    id: tradePartner.id,
                    name: tradePartner.name,
                    __typename: 'TradePartner'
                  }
                })
              );
              return [...monthWorks, ...monthWorksToAdd];
            },
            []
          );
          const { monthlyWorkerHours: cachedMonthWorks } = cache.readQuery({
            query: GET_TRADE_PARTNER_MONTH_WORKS,
            variables: {
              projectId: projectId,
              year: selectedYear,
              month: selectedMonth
            }
          });
          const monthWorks = allMonthWorksFromResponse
            .filter(
              monthWork =>
                monthWork.year === selectedYear &&
                monthWork.month === selectedMonth
            )
            .reduce((monthWorks, monthWorkToUpdate) => {
              const existingMonthWork = cachedMonthWorks.find(
                monthWork => monthWork.id === monthWorkToUpdate.id
              );
              if (existingMonthWork) {
                return monthWorks.concat({
                  ...existingMonthWork,
                  ...monthWorkToUpdate
                });
              } else {
                return monthWorks.concat(monthWorkToUpdate);
              }
            }, []);
          const updatedMonthWorks = cachedMonthWorks.map(cachedMonthWork => {
            const updatedMonthWork = monthWorks.find(
              monthWork => monthWork.id === cachedMonthWork.id
            );
            return updatedMonthWork ?? cachedMonthWork;
          });
          const newMonthWorks = monthWorks.filter(monthWork => {
            return !updatedMonthWorks.some(
              updatedMonthWork => updatedMonthWork.id === monthWork.id
            );
          });

          cache.writeQuery({
            query: GET_TRADE_PARTNER_MONTH_WORKS,
            variables: {
              projectId: projectId,
              year: selectedYear,
              month: selectedMonth
            },
            data: {
              monthlyWorkerHours: [...updatedMonthWorks, ...newMonthWorks]
            }
          });
        }
      }
    }
  );

  const [editedRows, setEditedRows] = useState([]);
  const [tableRows, setTableRows] = useState([]);

  useEffect(() => {
    if (!isEditing && !deepEqual(tableRows, workerHourRows)) {
      setTableRows(cloneDeep(workerHourRows));
    }
  }, [tableRows, isEditing, workerHourRows]);

  useEffect(() => {
    if (totalHours === null || !deepEqual(clonedRows, workerHourRows)) {
      setClonedRows(workerHourRows);
      setTotalWorkerHourRows(workerHourRows);
      const allHours = workerHourRows
        .map(row => {
          return row.hours;
        })
        .filter(hour => hour !== '');

      const hoursSum = allHours.length
        ? allHours.reduce(
            (total, currentValue) =>
              parseFloat(total) + parseFloat(currentValue)
          )
        : 0;

      setTotalHours(hoursSum);
      setCurrentMonth(selectedMonth);
    }
  }, [
    workerHourRows,
    totalHours,
    selectedMonth,
    currentMonth,
    loading,
    clonedRows
  ]);

  useEffect(() => {
    if (isEditing && submitButtonRef.current) {
      submitButtonRef.current.focus();
    }
  }, [isEditing]);

  const updateTotalHours = editedRow => {
    const allWorkerHours = totalWorkerHourRows.length
      ? totalWorkerHourRows
          .map(workerHour => {
            if (parseFloat(workerHour.hours) > 0) {
              return {
                hours: workerHour.hours ? parseFloat(workerHour.hours) : 0,
                id: workerHour.id
              };
            } else {
              return null;
            }
          })
          .filter(Boolean)
      : [];

    let existingHoursNotEdited = allWorkerHours.filter(
      currentRow => currentRow.id !== editedRow.id
    );

    const updatedRows = [...existingHoursNotEdited, editedRow];

    setTotalWorkerHourRows(updatedRows ? [...updatedRows] : []);

    const allHours = updatedRows.map(row => {
      return row.hours;
    });

    if (allHours.length > 0) {
      const workerHoursSum = allHours.reduce(
        (total, currentValue) => parseFloat(total) + parseFloat(currentValue)
      );
      setTotalHours(workerHoursSum);
    }
  };

  const handleCancel = () => {
    setEditedRows([]);
    toggleEditing(false);
    setIsCancelConfirmationRendered(false);
    setTotalHours(null);
  };

  const onSubmit = () => {
    let workerHours = editedRows.map(workerHour => ({
      tradePartnerId: workerHour.id,
      hours: workerHour.hours ? parseFloat(workerHour.hours) : 0,
      month: selectedMonth,
      year: selectedYear
    }));
    setManyWorkerHours({
      variables: { projectId: projectId, workerHours: workerHours }
    })
      .then(data => {
        toggleEditing(false);
        setEditedRows([]);
        displayToast(
          t('tradePartnerWorkerHoursPage.toasts.updateMany.success'),
          'success'
        );
      })
      .catch(error => {
        console.error('Update Worker Hours Error: ', error);
        displayToast(
          t('tradePartnerWorkerHoursPage.toasts.updateMany.error'),
          'error'
        );
      });
  };

  const renderUnsavedChangesConfirmationModal = () => {
    //  this component does not accept the useConfirmDialog hook as a child
    return (
      <NavigationPrompt
        when={(crntLocation, nextLocation) =>
          !nextLocation ||
          !nextLocation.pathname.startsWith(crntLocation.pathname)
        }>
        {({ onConfirm, onCancel }) => (
          <ConfirmationDialog
            show={true}
            cancel={onCancel}
            confirmation={t('tradePartnerWorkerHoursPage.discardMessage')}
            proceed={onConfirm}
            options={{
              theme,
              title: t('tradePartnerWorkerHoursPage.unsavedChanges'),
              rejectLabel: t('tradePartnerWorkerHoursPage.backButton'),
              confirmLabel: t('tradePartnerWorkerHoursPage.discardButton')
            }}
          />
        )}
      </NavigationPrompt>
    );
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    if (error.message?.includes('not found')) {
      return <PageNotFoundNotice />;
    } else {
      return <ErrorNotice />;
    }
  }

  return (
    <div>
      <Typography color="textPrimary" className={classes.title}>
        {t('tradePartnerWorkerHoursPage.title')}
      </Typography>
      <StyledMonthDatePicker
        disabled={isEditing}
        selectedDate={selectedDate}
        handleDateChange={handleDateChange}
      />
      <Grid container>
        <Grid item xs={12} className={classes.dataGridContainer}>
          <WorkerHoursTable
            project={project}
            workerHourRows={tableRows}
            editableRows={editedRows}
            setEditableRows={setEditedRows}
            isEditing={isEditing}
            toggleEditing={toggleEditing}
            isLoading={setManyLoading}
            updateTotalHours={updateTotalHours}
          />
        </Grid>
        <Grid item>
          <Grid container>
            <Grid item className={classes.totalHoursContainer}>
              <Typography className={classes.totalHoursText}>
                {t('tradePartnerWorkerHoursPage.totalHours')}
              </Typography>
              <NumericFormat
                style={{ marginTop: 3 }}
                className={classes.totalHoursText}
                data-testid="total-hours-count"
                displayType={'text'}
                thousandSeparator={true}
                value={totalHours ?? 0}
                // attribute added for testing
                total={totalHours ?? 0}
              />
            </Grid>
          </Grid>
        </Grid>

        {isEditing && (
          <Grid item xs={12}>
            <Grid
              container
              justifyContent="flex-end"
              direction="row"
              spacing={1}>
              <Grid item>
                <StyledButtonSecondary
                  className={classes.button}
                  onClick={() => setIsCancelConfirmationRendered(true)}
                  label={t('tradePartnerWorkerHoursPage.cancelButton')}
                  disabled={loading || setManyLoading}
                />
              </Grid>
              <Grid item>
                {setManyLoading && <CircularProgress color="primary" />}
                {!setManyLoading && (
                  <StyledButtonPrimary
                    className={classes.buttonPrimary}
                    onClick={onSubmit}
                    label={t('tradePartnerWorkerHoursPage.submitButton')}
                    disabled={loading || setManyLoading}
                    ref={submitButtonRef}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        )}
        {isCancelConfirmationRendered && (
          <ConfirmationDialog
            show={true}
            cancel={() => setIsCancelConfirmationRendered(false)}
            confirmation={t('tradePartnerWorkerHoursPage.discardMessage')}
            proceed={handleCancel}
            options={{
              theme,
              title: t('tradePartnerWorkerHoursPage.unsavedChanges'),
              rejectLabel: t('tradePartnerWorkerHoursPage.backButton'),
              confirmLabel: t('tradePartnerWorkerHoursPage.discardButton')
            }}
          />
        )}
      </Grid>
      {editedRows.length > 0 && renderUnsavedChangesConfirmationModal()}
    </div>
  );
};

ProjectWorkerHoursPage.propTypes = {
  queryParams: PropTypes.object.isRequired,
  setQueryParams: PropTypes.func.isRequired
};

export default withOnlineAccessOnly(
  withAuthorization(
    withQueryParams({
      stripUnknownKeys: false,
      keys: {
        month: {
          default: undefined,
          validate: (_, props) => validateSelectedDate(props.location.search)
        },
        year: {
          default: undefined,
          validate: (_, props) => validateSelectedDate(props.location.search)
        }
      }
    })(ProjectWorkerHoursPage)
  )
);
