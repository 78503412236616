import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-final-form-hooks';
import { useMutation } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';

import useToast from 'hooks/useToast';
import StyledDialog from 'shared/Dialog';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import StyledButtonSecondary from 'shared/Buttons/ButtonSecondary';
import OrientationFormFields from 'components/orientations/OrientationFormFields';
import { generateTransactionKey, isValidDateTimeRange } from 'utils';
import {
  dateTimeToNaiveLocalTimeString,
  dateTimeToNaiveLocalDateString,
  naiveLocalDateAndTimeToJsDate,
  dateTimeToLuxonDateTime
} from 'utils/dateTime';
import {
  CREATE_ORIENTATION,
  CREATE_ORIENTATION_SERIES,
  GET_ALL_ORIENTATIONS_FOR_PROJECT
} from 'graphql/orientation';
import { Duration } from 'luxon';

const AddOrientationDialog = ({
  addDialogIsOpen,
  toggleAddDialog,
  slotInfo
}) => {
  const { t } = useTranslation();
  const { projectId } = useParams();
  const { displayToast } = useToast();
  const [txnKey, setTxnKey] = useState(generateTransactionKey());
  const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const [createOrientation, { loading: isLoading }] = useMutation(
    CREATE_ORIENTATION
  );

  const [
    createOrientationSeries,
    { loading: isLoadingForSeries }
  ] = useMutation(CREATE_ORIENTATION_SERIES, {
    refetchQueries: [
      { query: GET_ALL_ORIENTATIONS_FOR_PROJECT, variables: { projectId } }
    ],
    awaitRefetchQueries: true
  });

  const initialValues = useMemo(() => {
    const startDate = dateTimeToNaiveLocalDateString(slotInfo?.start);
    const endDate = dateTimeToNaiveLocalDateString(slotInfo?.end);
    return {
      transactionKey: txnKey,
      timeZone:
        {
          label: localTimezone,
          value: localTimezone
        } ?? '',
      startDate,
      startTime: naiveLocalDateAndTimeToJsDate(
        startDate,
        dateTimeToNaiveLocalTimeString(slotInfo?.start)
      ).setHours(8),
      endDate,
      endTime: naiveLocalDateAndTimeToJsDate(
        endDate,
        dateTimeToNaiveLocalTimeString(slotInfo?.end)
      ).setHours(9),
      recurringEndDate: 3,
      type: 'Does not repeat' ?? '',
      language: 'English'
    };
  }, [txnKey, slotInfo, localTimezone]);

  const { form, values, pristine } = useForm({
    /* istanbul ignore next */
    onSubmit: () => {}, // this function required for useForm but is not used
    initialValues
  });

  const isValidDateTimes = isValidDateTimeRange({
    startDate: values.startDate,
    startTime: values.startTime,
    endDate: values.endDate,
    endTime: values.endTime
  });

  const handleClose = () => {
    toggleAddDialog(false);
    setTxnKey(generateTransactionKey());
    form.reset();
  };

  const handleCreateSingleOrientation = orientationToCreate => {
    createOrientation({ variables: { input: orientationToCreate } })
      .then(({ data: { createOrientation: orientation } }) => {
        displayToast(
          t('addOrientationDialog.toasts.single.success'),
          'success'
        );
        handleClose();
      })
      .catch(error => {
        console.error('Create Orientation Error: ', error);
        displayToast(t('addOrientationDialog.toasts.single.error'), 'error');
      });
  };

  const handleCreateOrientationSeries = orientationToCreate => {
    createOrientationSeries({ variables: { input: orientationToCreate } })
      .then(({ data: { createOrientationSeries: orientations } }) => {
        displayToast(
          t('addOrientationDialog.toasts.series.success'),
          'success'
        );
        handleClose();
      })
      .catch(error => {
        console.error('Create Orientation Series Error: ', error);
        displayToast(t('addOrientationDialog.toasts.series.error'), 'error');
      });
  };

  const isSeries = values.type && values.type !== 'Does not repeat';

  const handleSubmit = () => {
    const newOccurrenceStartDateTime = dateTimeToLuxonDateTime(
      naiveLocalDateAndTimeToJsDate(
        dateTimeToNaiveLocalDateString(values.startDate),
        dateTimeToNaiveLocalTimeString(values.startTime),
        values.timeZone.value
      )
    );
    const newOccurrenceEndDateTime = dateTimeToLuxonDateTime(
      naiveLocalDateAndTimeToJsDate(
        dateTimeToNaiveLocalDateString(values.endDate),
        dateTimeToNaiveLocalTimeString(values.endTime),
        values.timeZone.value
      )
    );

    const orientationToCreate = {
      transactionKey: txnKey,
      projectId: projectId,
      name: 'Safety Orientation',
      maxAttendees: parseInt(values.maxAttendees),
      language: values.language,
      facilitatorsToAdd:
        values.facilitators?.map(facilitator => facilitator.upn) ?? [],
      notes: values.notes ?? null,
      timeZone: values.timeZone.value,
      startDateTime: newOccurrenceStartDateTime,
      endDateTime: newOccurrenceEndDateTime
    };

    if (isSeries) {
      const calculatedEndDate = newOccurrenceStartDateTime.plus(
        Duration.fromObject({ months: values.recurringEndDate })
      );

      orientationToCreate.type = values.type;
      orientationToCreate.endDate = dateTimeToNaiveLocalDateString(
        calculatedEndDate,
        'UTC'
      );
      handleCreateOrientationSeries(orientationToCreate);
    } else {
      handleCreateSingleOrientation(orientationToCreate);
    }
  };

  const canSubmit = () => {
    const { error, invalid } = form.getState();
    return !(isLoading || invalid || error || !isValidDateTimes);
  };

  const canSubmitForSeries = () => {
    const { errors } = form.getState();

    return !(
      isLoadingForSeries ||
      pristine ||
      errors.type ||
      errors.recurringEndDate ||
      errors.maxAttendees ||
      errors.timeZone ||
      errors.startDate ||
      errors.startTime ||
      errors.endDate ||
      errors.endTime ||
      errors.facilitators ||
      !isValidDateTimes
    );
  };

  return (
    <StyledDialog
      isLoading={isLoading || isLoadingForSeries}
      isOpen={addDialogIsOpen}
      handleClose={handleClose}
      title={t('addOrientationDialog.title')}
      content={
        <form>
          <OrientationFormFields
            form={form}
            isLoading={isLoading || isLoadingForSeries}
            isValidDateTimes={isValidDateTimes}
          />
        </form>
      }
      actions={
        <Grid container justifyContent="space-between">
          <Grid item>
            <StyledButtonSecondary
              aria-label={t('addOrientationDialog.actions.cancel')}
              label={t('addOrientationDialog.actions.cancel')}
              disabled={isLoading || isLoadingForSeries}
              onClick={handleClose}
            />
          </Grid>
          <Grid item>
            <StyledButtonPrimary
              label={t('addOrientationDialog.actions.submit')}
              onClick={handleSubmit}
              disabled={
                values.type === 'Does not repeat'
                  ? !canSubmit()
                  : !canSubmitForSeries()
              }
            />
          </Grid>
        </Grid>
      }
    />
  );
};

AddOrientationDialog.propTypes = {
  addDialogIsOpen: PropTypes.bool,
  slotInfo: PropTypes.shape({
    end: PropTypes.any,
    start: PropTypes.any
  }),
  toggleAddDialog: PropTypes.func,
  isValidDateTimeRange: PropTypes.func
};

export default AddOrientationDialog;
