import React from 'react';
import VideoPlayer from '../../VideoPlayer';
import { Grid, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  subTitle: { fontSize: '0.875rem' },
  title: { fontSize: '1.5rem' },
  bold: { fontWeight: 'bold' }
}));

const EditRecurringOrientation = () => {
  const classes = useStyles();

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography className={classes.subTitle} color="textSecondary">
          Orientations Tutorial
        </Typography>
        <Typography className={classes.title} color="textPrimary">
          Edit Recurring Orientation
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          When adjusting the frequency of an orientation you will need to cancel
          the existing calendar item and recreate with the changes. Be sure to
          select the first orientation you would like to change from the
          calendar view and then take the steps in the video to cancel and
          recreate the orientation schedule.
        </Typography>
      </Grid>
      <Grid item>
        <VideoPlayer
          data-testid="edit-recurring-orientation-video"
          url="/tutorials/orientations/EditRecurringOrientation.mp4"
          tracks={[
            {
              kind: 'subtitles',
              src: '/tutorials/orientations/EditRecurringOrientation.en.vtt',
              srcLang: 'en',
              default: true
            }
          ]}
        />
      </Grid>
    </Grid>
  );
};

export default EditRecurringOrientation;
