import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';

import useToast from 'hooks/useToast';
import StatusCard from 'shared/StatusCard';
import { UPDATE_TRADE_PARTNER } from 'graphql/tradePartner';

const TradePartnerStatusCard = ({ tradePartner }) => {
  const { t } = useTranslation();
  const { displayToast } = useToast();
  const [updateTradePartner, { loading }] = useMutation(UPDATE_TRADE_PARTNER);

  const handleChange = event => {
    updateTradePartner({
      variables: {
        id: tradePartner.id,
        isActive: event.target.checked
      }
    })
      .then(({ data: { updateTradePartner: tradePartner } }) => {
        displayToast(
          t('tradePartnerPage.toasts.status.success', {
            tradePartnerName: tradePartner.name,
            action: tradePartner.isActive
              ? t('tradePartnerPage.manageStatus.activated')
              : t('tradePartnerPage.manageStatus.deactivated')
          }),
          'success'
        );
      })
      .catch(error => {
        console.error('Set Status Error: ', error);
        displayToast(t('tradePartnerPage.toasts.status.error'), 'error');
      });
  };

  return (
    <StatusCard
      loading={loading}
      title={t('tradePartnerPage.manageStatus.title')}
      isActive={tradePartner.isActive}
      deactivateSubtitle={t(
        'tradePartnerPage.manageStatus.subtitle.deactivate'
      )}
      activateSubtitle={t('tradePartnerPage.manageStatus.subtitle.activate')}
      handleChange={handleChange}
    />
  );
};

TradePartnerStatusCard.propTypes = {
  tradePartner: PropTypes.any.isRequired
};

export default TradePartnerStatusCard;
