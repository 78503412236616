import React, { Suspense } from 'react';
import { Route, Redirect, Switch, withRouter } from 'react-router-dom';
import { Box } from '@mui/material';
import { enabledRoutes } from 'routes';
import withDocumentTitle from 'hocs/withDocumentTitle';
import useSetCurrentUser from 'hooks/useSetCurrentUser';
import useSoteriaAdUserSubscription from 'hooks/useSoteriaAdUserSubscription';
import PrimaryAppBar from 'components/appbar/PrimaryAppBar';
import LoadingSpinner from 'components/common/LoadingSpinner';
import RootContainer from 'components/common/RootContainer';
import SecondaryAppBar from 'components/appbar/SecondaryAppBar';
import MaintenanceNoticePage from 'pages/MaintenanceNoticePage';
import useIsMaintenanceMode from 'store/maintenanceModeDetection';

const App = () => {
  useSetCurrentUser();
  useSoteriaAdUserSubscription();
  const { isMaintenanceMode } = useIsMaintenanceMode();

  if (isMaintenanceMode) {
    return <MaintenanceNoticePage />;
  } else {
    return (
      <main>
        <PrimaryAppBar />
        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
          <SecondaryAppBar />
        </Box>
        <Suspense fallback={<LoadingSpinner isFullScreen={true} />}>
          <RootContainer>
            <Switch>
              {enabledRoutes.map(route => (
                <Route {...route} key={route.path || '#'} />
              ))}
              <Redirect
                exact
                from="/projects/:projectId"
                to="/projects/:projectId/dashboard"
              />
              <Redirect from="*" to="/" />
            </Switch>
          </RootContainer>
        </Suspense>
      </main>
    );
  }
};

export default withDocumentTitle(withRouter(App));
