import React from 'react';
import { SwipeableDrawer } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import useSoteriaStore from 'hooks/useSoteriaStore';
import AppWhatsNewList from '../WhatsNewList';

const useStyles = makeStyles(theme => ({
  paper: { backgroundColor: theme.palette.background.paper }
}));

const WhatsNewDrawer = () => {
  const classes = useStyles();
  const {
    store: { whatsNewIsOpen = false },
    setStore
  } = useSoteriaStore();

  const toggleDrawer = isOpen => event => {
    if (
      // if the drawer is open and there is an event
      event &&
      // and that type of event is a press down on a key
      event.type === 'keydown' &&
      // and either of those keys is tab of shift
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      // don't toggle
      return;
    } // for any other key, toggle the drawer
    setStore({ whatsNewIsOpen: isOpen });
  };

  return (
    <SwipeableDrawer
      // conditional test id needed for testing because this is always rendered, but the CSS toggling visibility
      data-testid={
        whatsNewIsOpen ? 'whatsNewDrawerIsOpen' : 'whatsNewDrawerIsClosed'
      }
      classes={{ paper: classes.paper }}
      variant="persistent"
      anchor="right"
      open={whatsNewIsOpen}
      onOpen={toggleDrawer(true)}
      onClose={toggleDrawer(false)}>
      <AppWhatsNewList />
    </SwipeableDrawer>
  );
};

export default WhatsNewDrawer;
