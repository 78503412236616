import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import sanitizeHtml from 'sanitize-html';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  markdown: { fontFamily: 'Roboto, ui-sans-serif, sans-serif' }
}));

const MarkdownRenderer = ({ markdownString }) => {
  const classes = useStyles();

  return (
    <ReactMarkdown allowDangerousHtml={false} className={classes.markdown}>
      {sanitizeHtml(markdownString)}
    </ReactMarkdown>
  );
};

MarkdownRenderer.propTypes = {
  markdownString: PropTypes.string
};

export default MarkdownRenderer;
