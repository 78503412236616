import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography, Avatar, Link } from '@mui/material';

import StyledDialog from 'shared/Dialog';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import { GET_SIMILAR_PERSONNELS } from 'graphql/personnel';
import { useQuery } from '@apollo/react-hooks';

const useStyles = makeStyles(theme => ({
  personnelContainer: {
    borderRadius: 4,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    margin: '0 auto'
  },
  avatar: { width: theme.spacing(7), height: theme.spacing(7) },
  personnelField: { fontSize: '0.875rem', fontWeight: 'normal' },
  personnelFieldLabel: { fontWeight: 'bold' },
  personnelFieldValueMatching: { color: theme.palette.primary.light },
  personnelFieldValueNotMatching: { color: theme.palette.primary.dark },
  urlLink: { wordBreak: 'break-all', '&:hover': { cursor: 'pointer' } }
}));

const addFieldToComparison = ({
  comparison,
  fieldName,
  referenceValue,
  value,
  weight = 1
}) => {
  const field = { name: fieldName, value };
  const isMatch =
    (typeof value === 'string' &&
      value.toLowerCase() === referenceValue?.toLowerCase()) ||
    value === referenceValue;
  field.isMatch = isMatch;
  if (isMatch) {
    comparison.similarityScore += weight;
  }
  comparison.fields.push(field);
  return comparison;
};

const getPersonnelComparison = (referencePersonnel, personnel) => {
  const sampleTradePartnerPersonnel = personnel.tradePartnerHistory[0];
  const comparison = {
    similarityScore: 0,
    fields: [],
    avatarUrl: personnel.profileImage?.cachedUrl ?? '',
    sampleTradePartnerPersonnelUrl:
      sampleTradePartnerPersonnel &&
      `/projects/${sampleTradePartnerPersonnel.tradePartner.project.id}/personnel/${sampleTradePartnerPersonnel.id}`
  };
  addFieldToComparison({
    comparison,
    fieldName: 'ID',
    referenceValue: referencePersonnel.id,
    value: personnel.id
  });
  addFieldToComparison({
    comparison,
    fieldName: 'email',
    referenceValue: referencePersonnel.contactInformation?.emailAddress?.email,
    value: personnel.contactInformation?.emailAddress?.email
  });
  addFieldToComparison({
    comparison,
    fieldName: 'First Name',
    referenceValue: referencePersonnel.firstName,
    value: personnel.firstName
  });
  addFieldToComparison({
    comparison,
    fieldName: 'Middle Name',
    referenceValue: referencePersonnel.middleName,
    value: personnel.middleName
  });
  addFieldToComparison({
    comparison,
    fieldName: 'Last Name',
    referenceValue: referencePersonnel.lastName,
    value: personnel.lastName
  });
  addFieldToComparison({
    comparison,
    fieldName: 'Nickname',
    referenceValue: referencePersonnel.nickname,
    value: personnel.nickname
  });
  addFieldToComparison({
    comparison,
    fieldName: 'DOB',
    referenceValue: referencePersonnel.dob,
    value: personnel.dob
  });
  addFieldToComparison({
    comparison,
    fieldName: 'phone',
    referenceValue: referencePersonnel.contactInformation?.primaryPhone?.number,
    value: personnel.contactInformation?.primaryPhone?.number
  });
  addFieldToComparison({
    comparison,
    fieldName: 'Linked AD Employee ID',
    referenceValue: referencePersonnel.soteriaAdUser?.employeeId,
    value: personnel.soteriaAdUser?.employeeId
  });

  return comparison;
};

const SimilarPersonnelDialog = ({
  dialogIsOpen,
  setDialogIsOpen,
  personnelId
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { data, loading: isLoading } = useQuery(GET_SIMILAR_PERSONNELS, {
    variables: { similarToPersonnelId: personnelId }
  });
  const similarPersonnels = data?.similarPersonnels;

  const referencePersonnel = similarPersonnels?.find(
    personnel => personnel.id === personnelId
  );
  const comparisons = similarPersonnels
    ?.filter(personnel => personnel.id !== personnelId)
    .map(personnel => getPersonnelComparison(referencePersonnel, personnel));
  comparisons?.sort((a, b) => b.similarityScore - a.similarityScore);

  const handleClose = () => setDialogIsOpen(false);

  const getComparisonCard = comparison => {
    return (
      <Grid container className={classes.personnelContainer}>
        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            spacing={1}
            alignItems="center"
            alignContent="flex-start"
            wrap="nowrap">
            <Grid item>
              <Avatar
                className={classes.avatar}
                src={comparison.avatarUrl}></Avatar>
            </Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                alignContent="flex-start"
                spacing={2}>
                {comparison.fields.map(field => {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      className={classes.personnelField}
                      key={field.name}>
                      <Grid container direction="row" spacing={1}>
                        <Grid item>
                          <Typography
                            component="div"
                            className={classes.personnelFieldLabel}>
                            {`${field.name}: `}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography
                            component="div"
                            className={
                              field.isMatch
                                ? classes.personnelFieldValueMatching
                                : classes.personnelFieldValueNotMatching
                            }>
                            {field.value}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}
                {comparison.sampleTradePartnerPersonnelUrl && (
                  <Grid item xs={12} sm={12} md={6}>
                    <Link
                      className={classes.urlLink}
                      aria-label={t(
                        'similarPersonnelDialog.sampleTradePartnerPersonnelLink.text'
                      )}
                      tabIndex="0"
                      href={comparison.sampleTradePartnerPersonnelUrl}
                      underline="hover">
                      {t(
                        'similarPersonnelDialog.sampleTradePartnerPersonnelLink.text'
                      )}
                    </Link>
                  </Grid>
                )}
                {!comparison.sampleTradePartnerPersonnelUrl && (
                  <Grid item xs={12} sm={12} md={6}>
                    <Typography>No projects found</Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };
  return (
    <StyledDialog
      isOpen={dialogIsOpen}
      handleClose={handleClose}
      isLoading={isLoading}
      title={t('similarPersonnelDialog.title')}
      content={
        !isLoading && (
          <Grid container justifyContent="center" spacing={3} alignContent="center">
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography className="section-title">
                    {t('similarPersonnelDialog.referencePersonnel.label')}
                  </Typography>
                  {referencePersonnel &&
                    getComparisonCard(
                      getPersonnelComparison(
                        referencePersonnel,
                        referencePersonnel
                      )
                    )}
                </Grid>
                <Grid item xs={12}>
                  <Typography className="section-title">
                    {t('similarPersonnelDialog.similarPersonnel.label')}
                  </Typography>
                  {!comparisons?.length && (
                    <Typography>
                      {t('similarPersonnelDialog.similarPersonnel.noneFound')}
                    </Typography>
                  )}
                  {!!comparisons?.length && (
                    <Grid container direction="column" spacing={1}>
                      {comparisons.map(comparison => (
                        <Grid item>{getComparisonCard(comparison)}</Grid>
                      ))}
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )
      }
      actions={
        <Grid container justifyContent="flex-end">
          <Grid item>
            <StyledButtonPrimary
              label={t('similarPersonnelDialog.actions.closeButton')}
              onClick={handleClose}
            />
          </Grid>
        </Grid>
      }
    />
  );
};

SimilarPersonnelDialog.propTypes = {
  dialogIsOpen: PropTypes.bool.isRequired,
  setDialogIsOpen: PropTypes.func.isRequired,
  personnelId: PropTypes.string.isRequired
};

export default SimilarPersonnelDialog;
