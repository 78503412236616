import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import { Grid, Typography } from '@mui/material';

import useToast from 'hooks/useToast';
import StyledDialog from 'shared/Dialog';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import StyledButtonSecondary from 'shared/Buttons/ButtonSecondary';
import StyledNotice from 'shared/Notice';
import { APPROVE_ATTENDEE } from 'graphql/attendee';

const ApproveConfirmationDialog = ({
  approveConfirmationDialogIsOpen,
  toggleApproveConfirmationDialog,
  selectedAttendee
}) => {
  const { t } = useTranslation();
  const { displayToast } = useToast();

  const [approveAttendee, { loading: isLoading }] = useMutation(
    APPROVE_ATTENDEE
  );

  const handleSubmit = () => {
    approveAttendee({
      variables: {
        attendeeId: selectedAttendee?.id,
        personnelId: selectedAttendee?.personnel?.id ?? undefined
      }
    })
      .then(({ data: { updateAttendee: attendee } }) => {
        displayToast(
          t('approveConfirmationDialog.toasts.success', {
            attendeeName: selectedAttendee?.fullName
          }),
          'success'
        );
        toggleApproveConfirmationDialog(false);
      })
      .catch(error => {
        console.error('Approve Attendee Error: ', error);
        displayToast(
          t('approveConfirmationDialog.toasts.error', {
            attendeeName: selectedAttendee?.fullName
          }),
          'error'
        );
      });
  };

  return (
    <StyledDialog
      isLoading={isLoading}
      maxWidth="sm"
      isOpen={approveConfirmationDialogIsOpen}
      handleClose={() => toggleApproveConfirmationDialog(false)}
      title={t('approveConfirmationDialog.title')}
      content={
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <StyledNotice
              message={t('approveConfirmationDialog.noticeMessage', {
                attendeeName: selectedAttendee?.fullName
              })}
            />
          </Grid>
          <Grid item>
            <Typography>
              {t('approveConfirmationDialog.confirmation.continue')}
            </Typography>
          </Grid>
        </Grid>
      }
      actions={
        <Fragment>
          <StyledButtonSecondary
            label={t(
              'approveConfirmationDialog.confirmation.actions.cancelButton'
            )}
            disabled={isLoading}
            onClick={() => toggleApproveConfirmationDialog(false)}
          />
          <StyledButtonPrimary
            label={t(
              'approveConfirmationDialog.confirmation.actions.approveButton'
            )}
            disabled={isLoading}
            onClick={handleSubmit}
          />
        </Fragment>
      }
    />
  );
};

ApproveConfirmationDialog.propTypes = {
  approveConfirmationDialogIsOpen: PropTypes.bool.isRequired,
  toggleApproveConfirmationDialog: PropTypes.func.isRequired,
  selectedAttendee: PropTypes.object
};

export default ApproveConfirmationDialog;
