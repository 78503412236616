import { useEffect, useState } from 'react';
import useCurrentUser from 'hooks/useCurrentUser';
import {
  ENTERPRISE_ADMIN,
  DEFAULT,
  SUPPORT,
  ROAMING_ADMIN,
  AHA_ADMIN,
  CANARY,
  SAFETY_DIRECTOR
} from '../../constants/appRoles';
import { useMsal } from '@azure/msal-react';

const hasRole = (roles, role) => {
  const downgradedRolesValue =
    window.sessionStorage.getItem('downgradedRoles') ?? '';
  const downgradedRoles = downgradedRolesValue.split(',');
  return roles?.includes(role) && !downgradedRoles.includes(role);
};

const useRoles = () => {
  const { instance } = useMsal();
  const [roles, setRoles] = useState([]);
  const { currentUser } = useCurrentUser();

  useEffect(() => {
    const idTokenClaims = instance.getActiveAccount()?.idTokenClaims;
    const roles = idTokenClaims?.roles ?? [];
    setRoles(roles);
  }, [instance]);

  const isEnterpriseAdmin = hasRole(roles, ENTERPRISE_ADMIN);
  const isDefaultRole = hasRole(roles, DEFAULT);
  const isSupportRole = hasRole(roles, SUPPORT);
  const isRoamingAdmin = hasRole(roles, ROAMING_ADMIN);
  const isAhaAdmin = hasRole(roles, AHA_ADMIN);
  const isCanary = hasRole(roles, CANARY);
  const isSupportRoleForTesting = roles.includes(SUPPORT);
  const isSafetyDirector = hasRole(roles, SAFETY_DIRECTOR);

  const isAdminOnProject = projectId => {
    if (projectId) {
      return currentUser?.projects?.some(project => project.id === projectId);
    } else {
      return false;
    }
  };

  const isPersonnelOnProject = projectId => {
    if (projectId) {
      return currentUser?.safeProjects?.some(
        project => project.id === projectId
      );
    } else {
      return false;
    }
  };

  const isPartnerOnProject = projectId => {
    if (projectId) {
      return currentUser?.safeProjectsAsPartner?.some(
        project => project.id === projectId
      );
    } else {
      return false;
    }
  };

  const isAdminTypeRole = projectId => {
    return isEnterpriseAdmin || isAdminOnProject(projectId);
  };

  return {
    isLoading: currentUser?.isLoading ?? true,
    roles,
    isEnterpriseAdmin,
    isDefaultRole,
    isSupportRole,
    isRoamingAdmin,
    isAhaAdmin,
    isCanary,
    isAdminOnProject,
    isPersonnelOnProject,
    isPartnerOnProject,
    isAdminTypeRole,
    isSupportRoleForTesting,
    isSafetyDirector
  };
};

export default useRoles;
