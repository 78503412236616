import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import makeStyles from '@mui/styles/makeStyles';

import useActiveRoute from 'hooks/useActiveRoute';
import useSoteriaStore from 'hooks/useSoteriaStore';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: 'calc(100vh - 112px)',
    height: 'auto',
    paddingTop: 112,
    [theme.breakpoints.down('md')]: {
      minHeight: 'calc(100vh - 56px)',
      paddingTop: 56
    }
  },
  rootAsDashboard: { paddingTop: 0 },
  padding: {
    padding: theme.spacing(1, 4.5, 3, 4.5),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(3, 2)
    }
  },
  paddingAsDashboard: { padding: 0 },
  pageWidthWithDrawer: { paddingRight: 300 }
}));

export const RootContainer = ({ children }) => {
  const classes = useStyles();
  const { isActiveRoute } = useActiveRoute();
  const isDashboardPage = isActiveRoute('/projects/:projectId/dashboard');
  const isSafeDashboardPage = isActiveRoute(
    '/projects/:projectId/observations'
  );
  const isAhaDashboardPage = isActiveRoute('/projects/:projectId/ahas');
  const isTradePartnerAhaFilesPage = isActiveRoute(
    '/projects/:projectId/ahas/trade-partner-aha/:ahaTradePartnerCategoryId/files'
  );
  const isPageWithNoPadding =
    isActiveRoute('/projects/:projectId/ahas/hensel-phelps-aha/:ahaId/edit') ||
    isActiveRoute('/ahas/:ahaId/edit') ||
    isActiveRoute(
      '/projects/:projectId/ahas/hensel-phelps-aha/:ahaId/reviews/:ahaReviewId'
    ) ||
    isActiveRoute('/projects/:projectId/ahas/hensel-phelps-aha/:ahaId') ||
    isActiveRoute('/projects/:projectId/safety-hours');

  const { store } = useSoteriaStore();
  const whatsNewDrawerIsOpen = store?.whatsNewIsOpen;

  return (
    <div
      className={
        !isSafeDashboardPage && !isAhaDashboardPage
          ? classNames(
              classes.root,
              {
                [`${classes.rootAsDashboard}`]: isDashboardPage
              },
              { [`${classes.pageWidthWithDrawer}`]: whatsNewDrawerIsOpen }
            )
          : undefined
      }>
      <div
        className={
          !isSafeDashboardPage && !isAhaDashboardPage
            ? classNames(classes.padding, {
                [`${classes.paddingAsDashboard}`]:
                  isDashboardPage ||
                  isTradePartnerAhaFilesPage ||
                  isPageWithNoPadding
              })
            : undefined
        }>
        {children}
      </div>
    </div>
  );
};

RootContainer.propTypes = {
  children: PropTypes.node.isRequired
};

export default RootContainer;
