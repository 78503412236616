//import asyncComponentLoader from 'utils/asyncComponentLoader';
import { features, enabledFeatures } from 'constants/enabledFeatures';
import { features as feature, canaryFeatures } from 'constants/canaryFeatures';
import { isNullish } from 'utils/isNullish';
import LandingPage from 'pages/LandingPage';
import ProjectDashboardPage from 'pages/project/ProjectDashboardPage';
import ProjectPartnerDashboardPage from 'pages/project/ProjectDashboardPartnerPage';
import ObservationsDashboardPage from 'pages/ObservationsDashboardPage';
import ObservationsPartnerDashboardPage from 'pages/ObservationsDashboardPage/ObservationsDashboardPartnerPage';
import ObservationsDashboardAiPocPage from 'pages/ObservationsDashboardAiPocPage';
import ProjectPermissionsPage from 'pages/project/ProjectPermissionsPage';
import MasterProjectListPage from 'pages/masterListPages/MasterProjectListPage';
import MasterCompanyListPage from 'pages/masterListPages/MasterCompanyListPage';
import CompanyPage from 'pages/CompanyPage';
import TradePartnerListPage from 'pages/tradePartners/TradePartnerListPage/TradePartnerListPage';
import TradePartnerPage from 'pages/tradePartners/TradePartnerPage';
import ProjectWorkerHoursPage from 'pages/ProjectWorkerHoursPage';
import ProjectDetailsPage from 'pages/project/ProjectDetailsPage';
import ProjectPersonnelListPage from 'pages/personnel/ProjectPersonnelListPage';
import ProjectPersonnelPage from 'pages/personnel/ProjectPersonnelPage';
import OrientationsPage from 'pages/orientations/OrientationsPage';
import OrientationPage from 'pages/orientations/OrientationPage';
import AttendeeListPage from 'pages/attendees/AttendeeListPage';
import AttendeePage from 'pages/attendees/AttendeePage';
import UnlinkedHpEmployeesListPage from 'pages/personnel/UnlinkedHpEmployeesListPage';
import TransferPersonnelPage from 'pages/personnel/TransferPersonnelPage';
import AhaDashboardPage from 'pages/ahas/AhaDashboardPage';
import MasterAhaListPage from 'pages/masterListPages/MasterAhaListPage';
import TradePartnerAhaFilesPage from 'pages/ahas/TradePartnerAhaFilesPage';
import AhaEditorPage from 'pages/ahas/AhaEditorPage';
import AhaReviewPage from 'pages/ahas/AhaReviewPage';
import ProjectAhaLandingPage from 'pages/ahas/ProjectAhaLandingPage';
import SafetyHoursPage from 'pages/SafetyHours/SafetyHoursPage';
import PreIncidentIndicatorsPage from 'pages/PreIncidentIndicators/PreIncidentIndicatorsPage';
import { domain, Domain } from 'config/domain';

const allRoutes = [
  {
    exact: true,
    component: LandingPage,
    path: '/',
    domains: [Domain.PRIMARY, Domain.PARTNER]
  },
  {
    exact: true,
    component: LandingPage,
    path: '/observations-ai-poc',
    domains: [Domain.PRIMARY]
  },
  {
    exact: true,
    component: LandingPage,
    path: '/index.html',
    domains: [Domain.PRIMARY, Domain.PARTNER]
  },
  {
    exact: true,
    component:
      domain === Domain.PRIMARY
        ? ProjectDashboardPage
        : ProjectPartnerDashboardPage,
    path: '/projects/:projectId/dashboard',
    domains: [Domain.PRIMARY, Domain.PARTNER]
  },
  {
    exact: true,
    component: ProjectPermissionsPage,
    path: '/projects/:projectId/permissions',
    domains: [Domain.PRIMARY]
  },
  {
    exact: true,
    component: MasterProjectListPage,
    path: '/projects',
    domains: [Domain.PRIMARY]
  },
  {
    exact: true,
    component: MasterCompanyListPage,
    path: '/companies',
    domains: [Domain.PRIMARY]
  },
  {
    exact: true,
    component: CompanyPage,
    path: '/companies/:companyId',
    domains: [Domain.PRIMARY]
  },
  {
    exact: true,
    component: TradePartnerListPage,
    path: '/projects/:projectId/trade-partners',
    domains: [Domain.PRIMARY]
  },
  {
    exact: true,
    component: TradePartnerPage,
    path: '/projects/:projectId/trade-partners/:tradePartnerId',
    domains: [Domain.PRIMARY]
  },
  {
    exact: true,
    component: ProjectWorkerHoursPage,
    path: '/projects/:projectId/worker-hours',
    domains: [Domain.PRIMARY]
  },
  {
    exact: true,
    component: ProjectDetailsPage,
    path: '/projects/:projectId/projectDetails',
    domains: [Domain.PRIMARY]
  },
  {
    exact: true,
    component: ProjectPersonnelListPage,
    path: '/projects/:projectId/personnel',
    domains: [Domain.PRIMARY]
  },
  {
    exact: true,
    component: ProjectPersonnelPage,
    path: '/projects/:projectId/personnel/:tradePartnerPersonnelId',
    domains: [Domain.PRIMARY]
  },
  {
    exact: true,
    component: ProjectPersonnelPage,
    path:
      '/projects/:projectId/trade-partners/:tradePartnerId/personnel/:tradePartnerPersonnelId',
    domains: [Domain.PRIMARY]
  },
  {
    exact: true,
    component: ProjectPersonnelPage,
    path:
      '/unlinkedHPEmployees/projects/:projectId/personnel/:tradePartnerPersonnelId',
    domains: [Domain.PRIMARY]
  },
  {
    exact: true,
    component: OrientationsPage,
    path: '/projects/:projectId/orientations',
    domains: [Domain.PRIMARY]
  },
  {
    exact: true,
    component: OrientationPage,
    path: '/projects/:projectId/orientations/:orientationId',
    domains: [Domain.PRIMARY]
  },
  {
    exact: true,
    component: AttendeeListPage,
    path: '/projects/:projectId/orientations/:orientationId/attendees',
    domains: [Domain.PRIMARY]
  },
  {
    exact: true,
    component: AttendeePage,
    path:
      '/projects/:projectId/orientations/:orientationId/attendees/:attendeeId',
    domains: [Domain.PRIMARY]
  },
  {
    exact: true,
    component: UnlinkedHpEmployeesListPage,
    path: '/unlinkedHPEmployees',
    domains: [Domain.PRIMARY]
  },
  {
    exact: true,
    component:
      domain === Domain.PRIMARY
        ? ObservationsDashboardPage
        : ObservationsPartnerDashboardPage,
    path: '/projects/:projectId/observations',
    domains: [Domain.PRIMARY, Domain.PARTNER]
  },
  {
    exact: true,
    component: ObservationsDashboardAiPocPage,
    path: '/projects/:projectId/observations-ai-poc',
    domains: [Domain.PRIMARY]
  },
  {
    exact: true,
    component: TransferPersonnelPage,
    path: '/transfer',
    domains: [Domain.PRIMARY]
  },
  {
    exact: true,
    component: AhaDashboardPage,
    path: '/projects/:projectId/ahas',
    domains: [Domain.PRIMARY]
  },
  {
    exact: true,
    component: AhaReviewPage,
    path:
      '/projects/:projectId/ahas/hensel-phelps-aha/:ahaId/reviews/:ahaReviewId',
    domains: [Domain.PRIMARY]
  },
  {
    exact: true,
    component: TradePartnerAhaFilesPage,
    path:
      '/projects/:projectId/ahas/trade-partner-aha/:ahaTradePartnerCategoryId/files',
    domains: [Domain.PRIMARY]
  },
  {
    exact: true,
    component: MasterAhaListPage,
    path: '/ahas',
    domains: [Domain.PRIMARY]
  },
  {
    exact: true,
    component: ProjectAhaLandingPage,
    path: '/projects/:projectId/ahas/hensel-phelps-aha/:ahaId',
    domains: [Domain.PRIMARY]
  },
  {
    exact: true,
    component: AhaEditorPage,
    path: '/projects/:projectId/ahas/hensel-phelps-aha/:ahaId/edit',
    domains: [Domain.PRIMARY]
  },
  {
    exact: true,
    component: AhaEditorPage,
    path: '/ahas/:ahaId/edit',
    domains: [Domain.PRIMARY]
  },
  {
    exact: true,
    component: SafetyHoursPage,
    path: '/projects/:projectId/safety-hours',
    domains: [Domain.PRIMARY]
  },
  {
    exact: true,
    component: PreIncidentIndicatorsPage,
    path: '/projects/:projectId/pre-incident-indicators',
    domains: [Domain.PRIMARY],
    enabledFeature: features.preIncidentIndicators
  }
];

export const enabledRoutes = allRoutes.filter(
  route =>
    route.domains.includes(domain) &&
    (isNullish(route.enabledFeature)
      ? true
      : enabledFeatures[process.env.REACT_APP_ENV][route.enabledFeature] ||
        canaryFeatures[process.env.REACT_APP_ENV][route.enabledFeature])
);
