import React from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import { Paper, Grid, Typography, useTheme, Link } from '@mui/material';
import { useMsal } from '@azure/msal-react';
import { MSAL_SCOPES, redirectUri } from 'config/msalConfig';
import { InteractionStatus } from '@azure/msal-browser';

import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import { SERVICENOW_URL } from 'constants/externalLinks';

const useStyles = makeStyles(theme => ({
  container: {
    height: '100vh',
    width: '100vw',
    backgroundColor: theme.palette.background.default
  },
  paper: { padding: theme.spacing(3), borderRadius: 4 },
  heading: { fontSize: '2rem', textAlign: 'center' },
  logoContainer: { marginBottom: theme.spacing(3) },
  hpLogo: { width: 250 },
  scLogo: { width: 130, marginLeft: theme.spacing(2) },
  buttonContainer: { marginBottom: '2rem' },
  link: { '&:hover': { cursor: 'pointer' } },
  unauthorizedAccessImg: { width: 200, marginBottom: theme.spacing(6) }
}));
export const LoginErrorNotice = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const { instance: msalInstance, inProgress: oauthProgress } = useMsal();

  const login = () => {
    if (oauthProgress === InteractionStatus.None) {
      console.log('attempting login from error notice');
      msalInstance.loginRedirect({
        scopes: MSAL_SCOPES,
        redirectUri
      });
    } else {
      console.log('Login interaction already in progress');
    }
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      alignContent="center"
      className={classes.container}>
      <Grid item xs={10} sm={10} md={8} lg={6}>
        <Paper className={classes.paper}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center">
            <Grid item xs={12}>
              <Grid container className={classes.logoContainer}>
                <Grid item>
                  <img
                    className={classes.hpLogo}
                    src={theme.hpLogoContrast}
                    alt={t('images.henselPhelpsLogoAltText')}
                  />
                </Grid>
                <Grid item>
                  <img
                    className={classes.scLogo}
                    src={theme.safetyConnectLogoContrast}
                    alt={t('images.safetyConnectLogoAltText')}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <img
                src={theme.unauthorizedAccess}
                alt=""
                role="presentation"
                className={classes.unauthorizedAccessImg}
              />
            </Grid>
            <Grid item xs={12} className={classes.buttonContainer}>
              <Typography
                color="textPrimary"
                className={classes.heading}
                gutterBottom>
                {t('loginErrorNotice.heading')}
              </Typography>
              <Typography>
                {t('loginErrorNotice.message')}{' '}
                <Link
                  className={classes.link}
                  aria-label={'navigate to ServiceNow in a new window'}
                  tabIndex="0"
                  onKeyDown={() =>
                    window.open(SERVICENOW_URL, '_blank', 'noopener')
                  }
                  onClick={() =>
                    window.open(SERVICENOW_URL, '_blank', 'noopener')
                  }
                  underline="hover">
                  {t('loginErrorNotice.contactSupport')}
                </Link>
                .
              </Typography>
            </Grid>
            <Grid item>
              <StyledButtonPrimary
                label={t('loginErrorNotice.loginButton')}
                onClick={login}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};
export default LoginErrorNotice;
