import React, { useEffect, useState } from 'react';
import useIsOnline from 'store/onlineDetection';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import useCurrentUser from 'hooks/useCurrentUser';
import OfflineNoAccessNotice from 'components/offline/OfflineNoAccessNotice';
import LoginErrorNotice from 'components/common/LoginErrorNotice';
import LoadingSpinner from 'components/common/LoadingSpinner';
import { MsalAuthenticationTemplate, useMsal } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { MSAL_SCOPES, redirectUri } from 'config/msalConfig';

function withOnlineOnlyLogin(WrappedComponent) {
  return props => {
    const { isOnline } = useIsOnline();
    const { listen } = useHistory();
    const { currentUser } = useCurrentUser();
    const { t } = useTranslation();
    const [
      isLoadingPageFreshInOfflineMode,
      setIsLoadingPageFreshInOfflineMode
    ] = useState(!isOnline);
    const [previousOnlineState, setPreviousOnlineState] = useState(isOnline);
    const { instance: msalInstance } = useMsal();

    useEffect(() => {
      if (!previousOnlineState && isOnline) {
        console.log(
          'Silently refreshing token after regaining internet connection.'
        );
        // Refresh token status
        msalInstance.acquireTokenSilent({
          scopes: MSAL_SCOPES,
          account: msalInstance.getActiveAccount(),
          redirectUri
        });
      }
    }, [isOnline, previousOnlineState, msalInstance]);

    useEffect(() => {
      if (previousOnlineState !== isOnline) {
        setPreviousOnlineState(isOnline);
      }
    }, [isOnline, previousOnlineState]);

    useEffect(() => {
      if (isLoadingPageFreshInOfflineMode && isOnline) {
        setIsLoadingPageFreshInOfflineMode(false);
      }

      const deregisterListener = listen(location => {
        if (!isOnline && !isLoadingPageFreshInOfflineMode) {
          setIsLoadingPageFreshInOfflineMode(true);
        }
      });

      return deregisterListener;
    }, [isOnline, listen, isLoadingPageFreshInOfflineMode]);

    if (isLoadingPageFreshInOfflineMode) {
      if (currentUser) {
        return <WrappedComponent {...props} />;
      } else {
        return (
          <OfflineNoAccessNotice
            message={t('withOnlineOnlyAdalLogin.noUserMessage')}
          />
        );
      }
    } else {
      return (
        <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          authenticationRequest={{
            scopes: MSAL_SCOPES,
            redirectUri
          }}
          loadingComponent={() => <LoadingSpinner isFullScreen={true} />}
          errorComponent={() => <LoginErrorNotice />}>
          <WrappedComponent {...props} />
        </MsalAuthenticationTemplate>
      );
    }
  };
}

export default withOnlineOnlyLogin;
