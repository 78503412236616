import useFetchUserPhoto from 'hooks/useFetchUserPhoto';

const getInitials = adContact => {
  if (adContact?.firstName && adContact?.lastName) {
    return `${adContact.firstName[0]}${adContact.lastName[0]}`;
  } else if (adContact?.displayName) {
    return adContact.displayName
      .split(' ')
      .slice(0, 2)
      .map(word => word[0])
      .reverse()
      .join('');
  }
  return '';
};

const useAdAvatar = (
  adContact,
  azureClientId = process.env.REACT_APP_AZURE_CLIENT_ID
) => {
  const { thumbnailUrl, loading } = useFetchUserPhoto(
    adContact.upn,
    azureClientId
  );
  const initials = getInitials(adContact);

  return {
    thumbnailUrl: thumbnailUrl ?? adContact.profilePicture,
    loading,
    initials
  };
};

export default useAdAvatar;
