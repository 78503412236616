import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ExpandMore from '@mui/icons-material/ExpandMore';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import makeStyles from '@mui/styles/makeStyles';
import {
  ListItem,
  ListItemText,
  Collapse,
  Typography
} from '@mui/material';

import useHelpAndSupport from 'hooks/useHelpAndSupport';

const useStyles = makeStyles(theme => ({
  listItemText: { fontSize: '0.875rem' },
  nested: { paddingLeft: theme.spacing(4) }
}));

const TutorialCategory = ({ category, selectedHelpSection }) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const { setSelectedHelpSection } = useHelpAndSupport();
  const isSelectedCategory = category.sections?.some(
    section => section.name === selectedHelpSection
  );

  if (!isOpen && isSelectedCategory) {
    setIsOpen(true);
  }

  if (isOpen && !isSelectedCategory) {
    setIsOpen(false);
  }
  const toggleCategory = () => {
    setIsOpen(!isOpen);
    if (isOpen) {
      setSelectedHelpSection(null);
    } else {
      setSelectedHelpSection(category.sections[0].name);
    }
  };
  return (
    <Fragment>
      <ListItem
        disableTouchRipple
        disableRipple
        button
        onClick={toggleCategory}>
        {isOpen ? <ExpandMore /> : <KeyboardArrowRight />}
        <ListItemText>
          <Typography className={classes.listItemText}>
            {t(category.menuTitle)}
          </Typography>
        </ListItemText>
      </ListItem>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        {category.sections.map((section, index) => (
          <ListItem
            selected={section.name === selectedHelpSection}
            key={index}
            button
            className={classes.nested}
            onClick={() => setSelectedHelpSection(section.name)}>
            <ListItemText>
              <Typography className={classes.listItemText}>
                {t(section.menuTitle)}
              </Typography>
            </ListItemText>
          </ListItem>
        ))}
      </Collapse>
    </Fragment>
  );
};

TutorialCategory.propTypes = {
  category: PropTypes.object.isRequired,
  selectedHelpSection: PropTypes.string
};

export default TutorialCategory;
