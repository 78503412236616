import { GET_PROJECT } from '../../graphql/project';
import useSoteriaQuery from 'hooks/useSoteriaQuery';

const useProject = (projectId, shouldSkip = false) => {
  const { data: { project = null } = {}, loading, error } = useSoteriaQuery({
    gql: GET_PROJECT,
    queryHookOptions: {
      skip: shouldSkip || !projectId,
      variables: { projectId }
    }
  });

  return { project, loading, error };
};

export default useProject;
