import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import ErrorIcon from '@mui/icons-material/Error';
import { Typography, Grid, IconButton, Avatar } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Link from '@mui/material/Link';

import UpdateAttendeePictureDialog from '../UpdateAttendeePictureDialog';

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: '2rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.5rem'
    }
  },
  avatarContainer: {
    marginLeft: theme.spacing(1.5),
    [theme.breakpoints.down('md')]: {
      marginLeft: 0
    }
  },
  avatar: {
    position: 'relative',
    width: theme.spacing(18),
    height: theme.spacing(18),
    [theme.breakpoints.down('md')]: {
      width: theme.spacing(12),
      height: theme.spacing(12)
    }
  },
  placeholderAvatar: {
    position: 'relative',
    width: theme.spacing(18),
    height: theme.spacing(18),
    backgroundColor: theme.palette.background.contrastText,
    [theme.breakpoints.down('md')]: {
      width: theme.spacing(12),
      height: theme.spacing(12)
    }
  },
  iconButton: {
    position: 'absolute',
    top: 270,
    left: 160,
    zIndex: 2,
    backgroundColor: 'white',
    color: 'black',
    [theme.breakpoints.up('sm')]: {
      top: 270,
      left: 160
    },
    [theme.breakpoints.down('md')]: { top: 190, left: 85 },
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText
    },
    boxShadow: ' 0 2px 2px 0 rgba(0,0,0,0.14)'
  },
  container: { marginBottom: theme.spacing(1) },
  personnelHeadingTextContainer: {
    marginTop: '2rem',
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(1),
      maxWidth: 230,
      overflowWrap: 'break-word'
    }
  },
  personnelHeadingText: { fontSize: '1.25rem' },
  link: { textDecoration: 'underline', '&:hover': { cursor: 'pointer' } },
  linkText: { display: 'inline' }
}));

const AttendeeHeader = ({ attendee }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const attendeeIsApproved = attendee?.status === 'Approved';
  const history = useHistory();
  const { projectId } = useParams();

  const [
    isChangeAttendeePictureOpen,
    setIsChangeAttendeePictureOpen
  ] = useState(false);

  const handleChangePictureClick = () => {
    setIsChangeAttendeePictureOpen(true);
  };

  const attendeeTradePartnerPersonnelId = attendee.personnel?.tradePartnerHistory?.find(
    history => history.tradePartner.id === attendee.tradePartner.id
  )?.id;

  return <>
    <Grid item xs={12}>
      <Grid
        container
        direction="row"
        spacing={3}
        alignItems="center"
        className={classes.container}>
        <Grid item className={classes.avatarContainer}>
          {/* User added an image during their attendee enrollment; 
           The image is NOT malware */}
          {attendee?.profileImage?.cachedUrl &&
            !attendee?.profileImage?.isMalware && (
              <>
                <Avatar
                  alt={t('attendeeHeader.profileImage', {
                    attendeeName: attendee.fullName
                  })}
                  className={classes.avatar}
                  src={attendee.profileImage.cachedUrl}></Avatar>
                {!attendeeIsApproved && (
                  <IconButton
                    data-testid="edit-attendee-profile-image"
                    id={t('attendeeHeader.actions.editButton')}
                    title={t('attendeeHeader.actions.editButtonTitle')}
                    aria-labelledby={t('attendeeHeader.actions.editButton')}
                    size="small"
                    classes={{ root: classes.iconButton }}
                    onClick={handleChangePictureClick}>
                    <EditIcon />
                  </IconButton>
                )}
              </>
            )}
          {/* User did NOT add an image during their attendee enrollment; 
           The image is NOT malware; 
           Their existing personnel file does NOT contain an image */}
          {!attendee?.profileImage?.cachedUrl &&
            !attendee?.profileImage?.isMalware &&
            !attendee?.personnel?.profileImage && (
              <>
                <Avatar
                  alt={t('attendeeHeader.profileImagePlaceholder')}
                  className={classes.placeholderAvatar}></Avatar>
                {!attendeeIsApproved && (
                  <IconButton
                    size="small"
                    classes={{ root: classes.iconButton }}
                    onClick={handleChangePictureClick}>
                    <AddIcon />
                  </IconButton>
                )}
              </>
            )}
          {/* User did NOT add an image during their attendee enrollment; 
           The image IS malware */}
          {!attendee?.profileImage?.cachedUrl &&
            attendee?.profileImage?.isMalware && (
              <>
                <Avatar
                  alt={t('attendeeHeader.profileImagePlaceholder')}
                  className={classes.placeholderAvatar}>
                  <ErrorIcon
                    data-testid="malware-icon"
                    className={classes.placeholderAvatar}
                  />
                </Avatar>
                {!attendeeIsApproved && (
                  <IconButton
                    aria-labelledby={t('attendeeHeader.actions.addButton')}
                    size="small"
                    classes={{ root: classes.iconButton }}
                    onClick={handleChangePictureClick}>
                    <AddIcon />
                  </IconButton>
                )}
              </>
            )}
          {/* User did NOT add an image during their attendee enrollment; 
           The image is NOT malware; 
           Their existing personnel file DOES contain an image */}
          {!attendee?.profileImage?.cachedUrl &&
            !attendee?.profileImage?.isMalware &&
            attendee?.personnel?.profileImage && (
              <>
                <Avatar
                  alt={t('attendeeHeader.profileImage', {
                    attendeeName: attendee.personnel.fullName
                  })}
                  className={classes.avatar}
                  src={attendee.personnel.profileImage.url}></Avatar>
                {!attendeeIsApproved && (
                  <IconButton
                    id={t('attendeeHeader.actions.editButton')}
                    title={t('attendeeHeader.actions.editButtonTitle')}
                    aria-labelledby={t('attendeeHeader.actions.editButton')}
                    size="small"
                    classes={{ root: classes.iconButton }}
                    onClick={handleChangePictureClick}>
                    <EditIcon />
                  </IconButton>
                )}
              </>
            )}
        </Grid>
        <Grid item className={classes.personnelHeadingTextContainer}>
          <Typography
            color="textPrimary"
            className={classes.personnelHeadingText}>
            {attendee.personnel && attendee.personnel.fullName}
            {!attendee.personnel && attendee?.fullName}{' '}
            {attendee.personnel?.nickname
              ? `(${attendee.personnel.nickname})`
              : ''}
            {!attendee.personnel && attendee?.nickname
              ? `(${attendee.nickname})`
              : ''}
          </Typography>
          {attendee?.customId && (
            <Typography
              color="textPrimary"
              className={classes.personnelHeadingText}>
              {attendee.customId}
            </Typography>
          )}
          {attendeeIsApproved && attendeeTradePartnerPersonnelId && (
            <Link
              className={classes.link}
              aria-label={`navigate to the personnel page for ${attendee.personnel.fullName}`}
              tabIndex="0"
              onKeyDown={() => {
                history.push(
                  `/projects/${projectId}/personnel/${attendeeTradePartnerPersonnelId}`
                );
              }}
              onClick={() => {
                history.push(
                  `/projects/${projectId}/personnel/${attendeeTradePartnerPersonnelId}`
                );
              }}
              underline="hover">
              <Typography className={classes.linkText}>
                {t('attendeeHeader.link.viewPersonnelPage')}
              </Typography>
            </Link>
          )}
        </Grid>
      </Grid>
    </Grid>
    <UpdateAttendeePictureDialog
      attendee={attendee}
      isChangeAttendeePictureOpen={isChangeAttendeePictureOpen}
      setIsChangeAttendeePictureOpen={setIsChangeAttendeePictureOpen}
    />
  </>;
};

AttendeeHeader.propTypes = {
  attendee: PropTypes.object
};

export default AttendeeHeader;
