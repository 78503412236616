import { useQuery } from '@apollo/react-hooks';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AddIcon from '@mui/icons-material/Add';
import AddAhaDialog from 'components/ahas/AddAhaDialog';
import ErrorNotice from 'components/common/ErrorNotice';
import LoadingSpinner from 'components/common/LoadingSpinner';
import { PAGINATED_GLOBAL_TEMPLATES } from 'graphql/aha/globalTemplate';
import withAuthorization from 'hocs/withAuthorization';
import withOnlineAccessOnly from 'hocs/withOnlineAccessOnly';
import useDebounce from 'hooks/useDebounce';
import useSoteriaStore from 'hooks/useSoteriaStore';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import StyledFab from 'shared/Buttons/Fab';
import StyledTable from 'shared/Table';
import useAhaGlobalTemplatesListState from 'store/ahaGlobalTemplatesListState';
import { formatDate } from 'utils/dateTime';

const useStyles = makeStyles(theme => ({
  rowText: { fontSize: '0.875rem' }
}));

const MasterAhaListPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const {
    store: { whatsNewIsOpen = false }
  } = useSoteriaStore();
  const [
    addHenselPhelpsAhaDialogIsOpen,
    setAddHenselPhelpsAhaDialogIsOpen
  ] = useState(false);
  const [
    ahaGlobalTemplatesListState,
    { handleAhaGlobalTemplatesListStateChange }
  ] = useAhaGlobalTemplatesListState();
  const [initialAhaGlobalTemplatesListState] = useState(
    ahaGlobalTemplatesListState
  );

  const getApiSortOrder = order => {
    let apiSortOrderField = null;
    let direction = order.direction;

    switch (order.name) {
      case 'type':
        apiSortOrderField = 'type';
        break;
      case 'createdBy.name':
        apiSortOrderField = 'createdBy';
        break;
      case 'ahaGlobalTemplateCategory.name':
        apiSortOrderField = 'ahaGlobalTemplateCategory_name';
        break;
      case 'created':
        apiSortOrderField = 'created';
        break;
      case 'lastModified':
        apiSortOrderField = 'lastModified';
        break;
      default:
        console.error(`Sort order, "${order.name}", is not being handled!`);
    }
    return apiSortOrderField && direction
      ? [
          {
            [apiSortOrderField]: direction.toUpperCase()
          }
        ]
      : null;
  };

  const { data, loading: isLoading, error, fetchMore } = useQuery(
    PAGINATED_GLOBAL_TEMPLATES,
    {
      variables: {
        first: initialAhaGlobalTemplatesListState.first,
        order: getApiSortOrder(initialAhaGlobalTemplatesListState.order),
        search: initialAhaGlobalTemplatesListState.search,
        skip: 0
      }
    }
  );

  const globalTemplatesList = data?.paginatedGlobalAhaTemplates?.ahaTemplates;

  const columns = [
    {
      name: 'id',
      label: 'AHA Template Id',
      options: {
        filter: false,
        display: 'excluded'
      }
    },
    {
      name: 'type',
      label: 'Type',
      options: {
        filter: false,
        sort: true,
        sortOrder: 'asc',
        searchable: true
      }
    },
    {
      name: 'ahaGlobalTemplateCategory.name',
      label: 'Category',
      options: {
        filter: false,
        sort: true,
        sortOrder: 'asc',
        searchable: true
      }
    },
    {
      name: 'createdBy.name',
      label: 'Created By',
      options: {
        filter: false,
        sort: false,
        sortOrder: 'asc',
        searchable: true
      }
    },
    {
      name: 'created',
      label: 'Created',
      options: {
        filter: false,
        sort: true,
        sortOrder: 'asc',
        searchable: true,
        // eslint-disable-next-line react/display-name
        customBodyRender: value => {
          return (
            <Typography className={classes.rowText}>
              {formatDate(value)}
            </Typography>
          );
        }
      }
    },
    {
      name: 'lastModified',
      label: 'Last Modified',
      options: {
        filter: false,
        sort: true,
        sortOrder: 'asc',
        searchable: true,
        // eslint-disable-next-line react/display-name
        customBodyRender: value => {
          return (
            <Typography className={classes.rowText}>
              {formatDate(value)}
            </Typography>
          );
        }
      }
    }
  ];

  const loadMore = variables => {
    return fetchMore({
      variables,
      updateQuery: (_previousResult, { fetchMoreResult, _queryVariables }) => {
        return fetchMoreResult;
      }
    });
  };

  const handlePageChange = tableState => {
    const { rowsPerPage, page } = tableState;

    let skip =
      page < ahaGlobalTemplatesListState.page
        ? ahaGlobalTemplatesListState.skip - rowsPerPage
        : ahaGlobalTemplatesListState.skip + rowsPerPage;

    handleAhaGlobalTemplatesListStateChange({
      ...ahaGlobalTemplatesListState,
      page,
      skip
    });

    loadMore({
      search: ahaGlobalTemplatesListState.search,
      skip,
      order: getApiSortOrder(ahaGlobalTemplatesListState.order)
    });
  };

  const handleSortChange = tableState => {
    const { sortOrder } = tableState;

    handleAhaGlobalTemplatesListStateChange({
      ...ahaGlobalTemplatesListState,
      order: sortOrder,
      page: 0,
      skip: 0
    });

    loadMore({
      search: ahaGlobalTemplatesListState.search,
      skip: 0,
      order: getApiSortOrder(sortOrder)
    });
  };

  const handleSearchChange = ({ tableState, debouncedHandleSearchChange }) => {
    const { searchText } = tableState;

    handleAhaGlobalTemplatesListStateChange({
      ...ahaGlobalTemplatesListState,
      skip: 0,
      page: 0,
      search: searchText
    });

    loadMore({
      search: searchText,
      skip: 0,
      order: getApiSortOrder(ahaGlobalTemplatesListState.order)
    });
  };

  const { debounced: debouncedHandleSearchChange } = useDebounce(
    handleSearchChange
  );

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error) {
    console.error(error);
    return <ErrorNotice />;
  }

  const options = {
    filter: false,
    download: false,
    count: data?.paginatedGlobalAhaTemplates?.total ?? null,
    rowsPerPage: ahaGlobalTemplatesListState.first,
    searchText: ahaGlobalTemplatesListState.search,
    page: ahaGlobalTemplatesListState.page,
    rowsPerPageOptions: [],
    sortOrder: {
      ...ahaGlobalTemplatesListState.order,
      direction: ahaGlobalTemplatesListState.order?.direction?.toLowerCase()
    },
    serverSide: true,
    searchPlaceholder: 'Search by Type or Category',
    onRowClick: rowData => {
      const ahaId = rowData[0];
      return history.push(`ahas/${ahaId}/edit?view=summary`);
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          handlePageChange(tableState);
          break;
        case 'sort':
          handleSortChange(tableState);
          break;
        case 'search':
        case 'onSearchClose':
          debouncedHandleSearchChange({
            tableState,
            ahaGlobalTemplatesListState
          });
          break;
        default:
      }
    }
  };

  return (
    <>
      <StyledFab
        onClick={() => setAddHenselPhelpsAhaDialogIsOpen(true)}
        shouldShift={whatsNewIsOpen}
        icon={<AddIcon />}
      />
      <StyledTable
        title={t('masterAhaList.title')}
        data={globalTemplatesList}
        columns={columns}
        options={options}
      />
      <AddAhaDialog
        isGlobal={true}
        addHenselPhelpsAhaDialogIsOpen={addHenselPhelpsAhaDialogIsOpen}
        setAddHenselPhelpsAhaDialogIsOpen={setAddHenselPhelpsAhaDialogIsOpen}
        refetchQueries={() =>
          loadMore({
            first: ahaGlobalTemplatesListState.first,
            skip: ahaGlobalTemplatesListState.skip,
            search: ahaGlobalTemplatesListState.search,
            order: getApiSortOrder(ahaGlobalTemplatesListState.order)
          })
        }
      />
    </>
  );
};

export default withOnlineAccessOnly(
  withAuthorization(MasterAhaListPage, {
    ahaAdmin: true,
    enterpriseAdmin: false
  })
);
