import { atom, useRecoilState } from 'recoil';

const DEFAULT_ROWS_PER_PAGE = 100;

const defaultState = {
  first: DEFAULT_ROWS_PER_PAGE,
  skip: 0,
  search: ''
};

const ahaGlobalActivitiesListStateAtom = atom({
  key: 'ahaGlobalActivitiesListState',
  default: defaultState
});

function useAhaGlobalActivitiesListState() {
  const [
    ahaGlobalActivitiesListState,
    setAhaGlobalActivitiesListState
  ] = useRecoilState(ahaGlobalActivitiesListStateAtom);

  function handleAhaGlobalActivitiesListStateChange(newState) {
    setAhaGlobalActivitiesListState({
      ...ahaGlobalActivitiesListState,
      ...newState
    });
  }
  return [
    ahaGlobalActivitiesListState,
    { handleAhaGlobalActivitiesListStateChange }
  ];
}

export default useAhaGlobalActivitiesListState;
