import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  FormHelperText,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  useTheme
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  select: { padding: 0, marginTop: 0 },
  selectIcon: { color: theme.palette.icon.select, padding: theme.spacing(1) },
  labelText: { fontSize: '0.75rem', fontWeight: 'bold' }
}));

const StyledSelect = ({
  input: {
    name,
    label: inputLabel,
    onChange: inputOnChange,
    value,
    ...restInput
  },
  meta,
  label,
  required = false,
  disabled,
  options,
  placeholder,
  onChange,
  ...rest
}) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Fragment>
      <FormControl
        variant="standard"
        error={meta.error && (meta.touched || meta.visited)}
        disabled={disabled || false}
        required={required || false}>
        {!label && (
          <InputLabel shrink id={`${inputLabel}-select-label-label`}>
            {inputLabel}
          </InputLabel>
        )}
        {label && (
          <Fragment>
            {!meta.touched && !meta.visited && !disabled && (
              <Typography color="textSecondary" className={classes.labelText}>
                {required ? `${label} *` : label}
              </Typography>
            )}
            {(meta.touched || meta.visited) && !meta.error && !disabled && (
              <Typography color="textSecondary" className={classes.labelText}>
                {required ? `${label} *` : label}
              </Typography>
            )}
            {(meta.touched || meta.visited) && meta.error && !disabled && (
              <Typography color="error" className={classes.labelText}>
                {required ? `${label} *` : label}
              </Typography>
            )}
            {disabled && (
              <Typography color="textSecondary" className={classes.labelText}>
                {label}
              </Typography>
            )}
          </Fragment>
        )}
        <Select
          labelId={`${label}-select-label-label`}
          id={`${label}-select-label`}
          value={value}
          required={required}
          onChange={onChange || inputOnChange}
          inputProps={{
            ...restInput,
            ...{ 'aria-label': label ?? inputLabel },
            style: { color: theme.palette.secondary.contrastText }
          }}
          {...rest}
          displayEmpty
          margin="dense"
          variant="outlined"
          fullWidth={true}
          className={classes.select}
          IconComponent={KeyboardArrowDownIcon}>
          {placeholder && (
            <MenuItem disabled value="">
              {placeholder}
            </MenuItem>
          )}
          {options?.map((option, index) => (
            <MenuItem
              data-testid={`option-${option.label}`}
              key={option.id ?? index}
              value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {(meta.touched || meta.visited) && meta.error && (
        // TODO: SHOULD NOT USE DEFAULT RED - IMPLEMENT THEME
        <FormHelperText style={{ color: 'red' }}>{meta.error}</FormHelperText>
      )}
    </Fragment>
  );
};

StyledSelect.propTypes = {
  input: PropTypes.any,
  meta: PropTypes.any,
  label: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  options: PropTypes.any,
  placeholder: PropTypes.string,
  onChange: PropTypes.func
};

export default StyledSelect;
