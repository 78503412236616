import {
  Collapse,
  Link as MuiLink,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import classNames from 'classnames';
import {
  SAFETY_INCIDENTS_URL,
  SAFETY_VIOLATIONS_URL
} from 'constants/externalLinks';
import useActiveRoute from 'hooks/useActiveRoute';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { domain, Domain } from 'config/domain';

const useStyles = makeStyles(theme => ({
  nested: { paddingLeft: theme.spacing(4) },
  doubleNested: { paddingLeft: theme.spacing(8) },
  listItemText: {
    fontSize: '0.875rem',
    maxWidth: 240,
    whiteSpace: 'noWrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  muiLinkTextColor: { color: theme.palette.background.contrastText }
}));

const SoteriaMuiLink = ({
  to,
  closeDrawer,
  selected,
  isNested = true,
  label
}) => {
  const classes = useStyles();

  return (
    <MuiLink
      className={classes.muiLinkTextColor}
      component={RouterLink}
      to={to}
      onClick={closeDrawer}
      underline="none">
      <ListItem
        button
        selected={selected}
        className={classNames({
          [`${classes.nested}`]: !isNested,
          [`${classes.doubleNested}`]: isNested
        })}>
        <ListItemText>
          <Typography className={classes.listItemText}>{label}</Typography>
        </ListItemText>
      </ListItem>
    </MuiLink>
  );
};

SoteriaMuiLink.propTypes = {
  to: PropTypes.string.isRequired,
  closeDrawer: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  isNested: PropTypes.bool,
  label: PropTypes.string.isRequired
};

const ProjectMenuListItem = ({
  project,
  isNested = true,
  setSelectedItem,
  selectedItem,
  closeDrawer,
  isAdmin = false
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isActiveRoute } = useActiveRoute();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (selectedItem) {
      const selectedItemIsOpen =
        selectedItem.projectId === project.id ? selectedItem.isOpen : false;

      if (selectedItemIsOpen !== isOpen) {
        setIsOpen(selectedItemIsOpen);
      }
    } else if (!isOpen && isActiveRoute(`/projects/${project.id}`, false)) {
      setSelectedItem({ projectId: project.id, isOpen: true });
      setIsOpen(true);
    }
  }, [selectedItem, isActiveRoute, project.id, setSelectedItem, isOpen]);

  const getProjectLink = (projectId, page) => {
    return `/projects/${projectId}/${page}`;
  };

  const handleClick = () => {
    const isOpen =
      selectedItem && selectedItem.projectId === project.id
        ? !selectedItem.isOpen
        : true;

    setSelectedItem({ projectId: project.id, isOpen: isOpen });
  };

  return (
    <List component="div" disablePadding>
      <ListItem
        data-testid="project-menu-list-item"
        button
        className={classNames({ [`${classes.nested}`]: isNested })}
        onClick={handleClick}>
        <ListItemText>
          <Tooltip title={project.name}>
            <Typography className={classes.listItemText}>
              {project.name}
            </Typography>
          </Tooltip>
        </ListItemText>
        {isOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {domain === Domain.PRIMARY && (
            <SoteriaMuiLink
              to={getProjectLink(project.id, 'ahas')}
              closeDrawer={closeDrawer}
              selected={isActiveRoute(`/projects/${project.id}/ahas`)}
              isNested={false}
              label={t('navigation.myProjects.links.aha')}
            />
          )}
          <SoteriaMuiLink
            to={getProjectLink(project.id, 'dashboard')}
            closeDrawer={closeDrawer}
            selected={isActiveRoute(`/projects/${project.id}/dashboard`)}
            isNested={false}
            label={t('navigation.myProjects.links.dashboard')}
          />
          {domain === Domain.PRIMARY && (
            <ListItem
              button
              className={classes.nested}
              onClick={() =>
                window.open(SAFETY_INCIDENTS_URL, '_blank', 'noopener')
              }>
              <ListItemText>
                <Typography className={classes.listItemText}>
                  Incidents
                </Typography>
              </ListItemText>
            </ListItem>
          )}
          <SoteriaMuiLink
            to={getProjectLink(project.id, 'observations')}
            closeDrawer={closeDrawer}
            selected={isActiveRoute(`/projects/${project.id}/observations`)}
            isNested={false}
            label={t('navigation.myProjects.links.observations')}
          />
          {isAdmin && (
            <>
              <SoteriaMuiLink
                to={getProjectLink(project.id, 'orientations')}
                closeDrawer={closeDrawer}
                selected={isActiveRoute(`/projects/${project.id}/orientations`)}
                isNested={false}
                label={t('navigation.myProjects.links.orientations')}
              />
              <SoteriaMuiLink
                to={getProjectLink(project.id, 'permissions')}
                closeDrawer={closeDrawer}
                selected={isActiveRoute(`/projects/${project.id}/permissions`)}
                isNested={false}
                label={t('navigation.myProjects.links.permissions')}
              />
              <SoteriaMuiLink
                to={getProjectLink(project.id, 'personnel')}
                closeDrawer={closeDrawer}
                selected={isActiveRoute(`/projects/${project.id}/personnel`)}
                isNested={false}
                label={t('navigation.myProjects.links.personnel')}
              />
              <SoteriaMuiLink
                to={getProjectLink(project.id, 'trade-partners')}
                closeDrawer={closeDrawer}
                selected={isActiveRoute(
                  `/projects/${project.id}/trade-partners`
                )}
                isNested={false}
                label={t('navigation.tradePartners')}
              />
              <SoteriaMuiLink
                to={getProjectLink(project.id, 'worker-hours')}
                closeDrawer={closeDrawer}
                selected={isActiveRoute(`/projects/${project.id}/worker-hours`)}
                isNested={false}
                label={t('navigation.tradePartnerWorkerHours')}
              />
              <ListItem
                button
                className={classes.nested}
                onClick={() =>
                  window.open(SAFETY_VIOLATIONS_URL, '_blank', 'noopener')
                }>
                <ListItemText>
                  <Typography className={classes.listItemText}>
                    Violations
                  </Typography>
                </ListItemText>
              </ListItem>
            </>
          )}
        </List>
      </Collapse>
    </List>
  );
};

ProjectMenuListItem.propTypes = {
  project: PropTypes.object.isRequired,
  isNested: PropTypes.bool,
  setSelectedItem: PropTypes.func.isRequired,
  selectedItem: PropTypes.object,
  closeDrawer: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool
};

export default withRouter(ProjectMenuListItem);
