import { atom, useRecoilState } from 'recoil';
import moment from 'moment';

const defaultSafeProjectQueryTimestamps = atom({
  key: 'safeProjectQueryTimeStamps',
  default: {}
});

function useSafeProjectQueryTimestamps() {
  const [
    safeProjectQueryTimeStamps,
    setSafeProjectQueryTimeStamps
  ] = useRecoilState(defaultSafeProjectQueryTimestamps);

  const setTimeStampForProject = projectId => {
    setSafeProjectQueryTimeStamps({
      ...safeProjectQueryTimeStamps,
      [projectId]: moment().toISOString()
    });
  };

  return {
    safeProjectQueryTimeStamps,
    setTimeStampForProject
  };
}

export default useSafeProjectQueryTimestamps;
