import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useField } from 'react-final-form-hooks';
import { Grid, Link } from '@mui/material';

import useFormValidation from 'hooks/useFormValidation';
import useStringIncludesHenselPhelps from 'hooks/useStringIncludesHenselPhelps';
import StyledInput from 'shared/Input';
import StyledKeyboardDatePickerField from 'shared/KeyboardDatePickerField';
import StyledPhoneNumberField from 'shared/PhoneNumberField';
import StyledNotice from 'shared/Notice';
import { HP_EMPLOYEE_INFO_MANAGEMENT_URL } from 'constants/externalLinks';

const PersonnelFormFields = ({ form, isLoading, tradePartnerPersonnel }) => {
  const { t } = useTranslation();
  const { isRequired, isValidPastDate, isPhoneNumber } = useFormValidation();
  const { stringIncludesHenselPhelps } = useStringIncludesHenselPhelps();
  const isHenselPhelps = stringIncludesHenselPhelps(
    tradePartnerPersonnel.tradePartner.name
  );

  const firstName = useField('firstName', form, isRequired);
  const middleName = useField('middleName', form);
  const lastName = useField('lastName', form, isRequired);
  const nickname = useField('nickname', form);
  const dob = useField('dob', form, isValidPastDate);
  const phoneNumber = useField('phoneNumber', form, isPhoneNumber);
  const email = useField('email', form);
  const notes = useField('notes', form);

  return (
    <Grid container direction="row" spacing={1}>
      {isHenselPhelps && (
        <Grid item xs={12}>
          <StyledNotice
            title={'Attention Hensel Phelps Personnel'}
            message={
              <>
                {t('personnelFormFields.henselPhelpsPartOne')}
                <Link
                  aria-label={'navigate n35.ultipro.com in a new window'}
                  tabIndex="0"
                  onKeyDown={() => {
                    window.open(
                      HP_EMPLOYEE_INFO_MANAGEMENT_URL,
                      '_blank',
                      'noopener'
                    );
                  }}
                  onClick={() => {
                    window.open(
                      HP_EMPLOYEE_INFO_MANAGEMENT_URL,
                      '_blank',
                      'noopener'
                    );
                  }}
                  underline="hover">
                  {t('personnelFormFields.henselPhelpsPartTwo')}
                </Link>{' '}
                {t('personnelFormFields.henselPhelpsPartThree')}
              </>
            }
          />
        </Grid>
      )}
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <StyledInput
          input={firstName.input}
          meta={firstName.meta}
          disabled={isLoading || isHenselPhelps}
          label={t('personnelFormFields.firstName')}
          required
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <StyledInput
          input={middleName.input}
          meta={middleName.meta}
          disabled={isLoading || isHenselPhelps}
          label={t('personnelFormFields.middleName')}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <StyledInput
          input={lastName.input}
          meta={lastName.meta}
          disabled={isLoading || isHenselPhelps}
          label={t('personnelFormFields.lastName')}
          required
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <StyledInput
          input={nickname.input}
          meta={nickname.meta}
          disabled={isLoading}
          label={t('personnelFormFields.nickname')}
        />
      </Grid>
      {!isHenselPhelps && (
        <>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <StyledKeyboardDatePickerField
              label={t('personnelFormFields.dob')}
              value={dob.input.value || null}
              handleDateChange={dob.input.onChange}
              meta={dob.meta}
              inputProps={{
                onFocus: dob.input.onFocus,
                onBlur: dob.input.onBlur,
                'data-testid': 'dobPicker'
              }}
              disabled={isLoading || isHenselPhelps}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <StyledPhoneNumberField
              label={t(
                'createCompanyDialog.steps.contact.fields.primaryContact.phoneNumber'
              )}
              value={phoneNumber.input.value}
              meta={phoneNumber.meta}
              onChange={phoneNumber.input.onChange}
              disabled={isLoading}
            />
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <StyledInput
          input={email.input}
          meta={email.meta}
          disabled={isLoading || isHenselPhelps}
          label={t('personnelFormFields.email')}
        />
      </Grid>
      <Grid item xs={12}>
        <StyledInput
          multiline={true}
          disabled={isLoading}
          input={notes.input}
          meta={notes.meta}
          label={t('personnelFormFields.notes')}
        />
      </Grid>
    </Grid>
  );
};

PersonnelFormFields.propTypes = {
  form: PropTypes.any.isRequired,
  isLoading: PropTypes.bool,
  tradePartnerPersonnel: PropTypes.object.isRequired
};

export default PersonnelFormFields;
