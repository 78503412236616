import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useForm, useField } from 'react-final-form-hooks';
import { useMutation } from '@apollo/react-hooks';
import DeleteIcon from '@mui/icons-material/Delete';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography, Card, IconButton } from '@mui/material';
import deepEqual from 'deep-equal';

import useToast from 'hooks/useToast';
import StyledDialog from 'shared/Dialog';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import StyledButtonSecondary from 'shared/Buttons/ButtonSecondary';
import AdUserSelect from 'components/ad/AdUserSelect';
import ScopeIndicator from 'components/ahas/AhaScopeIndicator';
import { generateTransactionKey } from 'utils';
import {
  GET_AHA_REVIEW,
  CREATE_AHA_REVIEW,
  UPDATE_AHA_REVIEW
} from 'graphql/aha/ahaReview';

const useStyles = makeStyles(theme => ({
  instructions: { marginBottom: theme.spacing(3) },
  addedReviewersLabel: {
    fontSize: '0.75rem',
    fontWeight: 'bold',
    marginTop: theme.spacing(2)
  },
  card: {
    backgroundColor: theme.palette.background.default,
    paddingLeft: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  reviewersLabel: { fontSize: '0.75rem', fontWeight: 'bold' },
  managingReviewersContainer: { marginBottom: theme.spacing(4) },
  currentReviewerAddedError: { fontSize: '0.75rem', marginTop: -8 }
}));

const AhaReviewersDialog = ({
  requestReviewDialogIsOpen,
  toggleRequestReviewDialog,
  ahaTemplate,
  isGlobal,
  isManagingReviewers = false,
  ahaReview,
  isReadOnlyReview,
  refetchQuery
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { displayToast } = useToast();
  const [existingReviewers, setExistingReviewers] = useState([]);
  const [reviewers, setReviewers] = useState([]);

  useEffect(() => {
    if (
      ahaReview?.requestedReviewers &&
      !deepEqual(ahaReview?.requestedReviewers, existingReviewers)
    ) {
      setExistingReviewers(ahaReview?.requestedReviewers);
      setReviewers([...ahaReview?.requestedReviewers]);
    }
  }, [ahaReview?.requestedReviewers, existingReviewers]);

  const [transactionKey, setTransactionKey] = useState(
    generateTransactionKey()
  );

  const [createAhaReview, { loading: isLoadingForCreate }] = useMutation(
    CREATE_AHA_REVIEW
  );

  const [updateAhaReview, { loading: isLoadingForUpdate }] = useMutation(
    UPDATE_AHA_REVIEW,
    {
      refetchQueries: [
        {
          query: GET_AHA_REVIEW,
          variables: { id: ahaReview?.id }
        }
      ],
      awaitRefetchQueries: true
    }
  );

  const isLoading = isLoadingForCreate || isLoadingForUpdate;

  const { form } = useForm({
    /* istanbul ignore next */
    onSubmit: () => {} // this function required for useForm but is not used
  });

  const selectedHenselPhelpsEmployee = useField(
    'selectedHenselPhelpsEmployee',
    form
  );

  const handleRemoveReviewer = reviewer => {
    const filteredReviewers =
      reviewers.filter(addedReviewer => addedReviewer.upn !== reviewer.upn) ??
      [];
    return setReviewers(filteredReviewers);
  };

  const existingReviewerUpns = existingReviewers?.map(existing => existing.upn);
  const reviewerUpns = reviewers?.map(reviewer => reviewer.upn);

  function checkForChangesToReviewers(existingReviewerUpns, reviewerUpns) {
    return (
      Array.isArray(existingReviewerUpns) &&
      Array.isArray(reviewerUpns) &&
      existingReviewerUpns.length === reviewerUpns.length &&
      existingReviewerUpns.every((val, index) => val === reviewerUpns[index])
    );
  }

  const noReviewersSelected = checkForChangesToReviewers(
    existingReviewerUpns,
    reviewerUpns
  );

  const canSubmit = () => {
    return (
      reviewers.length > 0 &&
      !duplicateReviewerIsSelected &&
      !noReviewersSelected
    );
  };

  const upnsToSend = reviewers?.map(reviewer => reviewer.upn);

  const handleSubmit = () => {
    isManagingReviewers
      ? updateAhaReview({
          variables: {
            input: {
              id: ahaReview?.id,
              requestedReviewerUpns: upnsToSend
            }
          }
        })
          .then(async () => {
            if (refetchQuery) {
              await refetchQuery();
            }
            displayToast(t('requestReviewDialog.toasts.success'), 'success');
            handleClose();
          })
          .catch(error => {
            console.error('AHA Review Request Error: ', error);
            displayToast(t('requestReviewDialog.toasts.error'), 'error');
          })
      : createAhaReview({
          variables: {
            input: {
              ahaProjectTemplateId: ahaTemplate?.ahaProjectTemplate?.id,
              requestedReviewerUpns: upnsToSend,
              transactionKey: transactionKey
            }
          }
        })
          .then(async () => {
            if (refetchQuery) {
              await refetchQuery();
            }
            displayToast(t('requestReviewDialog.toasts.success'), 'success');
            handleClose();
          })
          .catch(error => {
            console.error('AHA Review Request Error: ', error);
            displayToast(t('requestReviewDialog.toasts.error'), 'error');
          });
  };

  const handleClose = () => {
    form.reset();
    form.resetFieldState('selectedHenselPhelpsEmployee');
    setReviewers(isReadOnlyReview ? reviewers : []);
    toggleRequestReviewDialog(false);
    setTransactionKey(generateTransactionKey());
  };

  const duplicateReviewerIsSelected = reviewers.some(
    reviewer => reviewer.upn === selectedHenselPhelpsEmployee?.input?.value?.upn
  );

  return (
    <StyledDialog
      maxWidth="sm"
      title={
        isManagingReviewers
          ? 'Manage Reviewers'
          : t('requestReviewDialog.title')
      }
      isOpen={requestReviewDialogIsOpen}
      handleClose={handleClose}
      isLoading={isLoading}
      disabled={isLoading}
      content={
        <Grid container>
          <Grid item xs={12}>
            {!isReadOnlyReview && <ScopeIndicator isGlobal={isGlobal} />}
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.instructions}>
              {t('requestReviewDialog.instructions')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <AdUserSelect
              selectedUser={selectedHenselPhelpsEmployee.input.value || null}
              meta={selectedHenselPhelpsEmployee.meta}
              handleChange={selectedReviewer => {
                const reviewerAlreadySelected = reviewers.some(
                  reviewer => reviewer?.upn === selectedReviewer?.upn
                );
                if (selectedReviewer) {
                  if (reviewerAlreadySelected) {
                    selectedHenselPhelpsEmployee.input.onChange(
                      selectedReviewer
                    );
                  } else {
                    setReviewers([...reviewers, selectedReviewer]);
                  }
                } else {
                  selectedHenselPhelpsEmployee.input.onChange(null);
                }
              }}
              isDisabled={
                isLoading ||
                ahaReview?.status === 'Rejected' ||
                ahaReview?.status === 'Completed'
              }
              label={
                isManagingReviewers
                  ? 'Add Reviewer(s)'
                  : t('requestReviewDialog.adSelectLabel')
              }
              placeholder={
                <Typography>
                  {t('requestReviewDialog.adSelectPlaceholder')}
                </Typography>
              }
              isRequired={true}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null
              }}
              noOptionsMessage={() => null}
              loadingMessage={() => null}
              customError={
                duplicateReviewerIsSelected
                  ? t('requestReviewDialog.adSelectCustomError')
                  : undefined
              }
              autoFocus={true}
            />
          </Grid>
          {reviewers.length > 0 && (
            <>
              <Grid item xs={12}>
                <Typography
                  color="textSecondary"
                  className={classes.addedReviewersLabel}>
                  {t('requestReviewDialog.addedReviewers')}
                </Typography>
                {reviewers.map((reviewer, index) => {
                  return (
                    <Card key={index} className={classes.card}>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center">
                        <Grid item>
                          <Typography>
                            {reviewer?.name} ({reviewer?.jobTitle})
                          </Typography>
                        </Grid>
                        <Grid item>
                          <IconButton onClick={() => handleRemoveReviewer(reviewer)} size="large">
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Card>
                  );
                })}
              </Grid>
            </>
          )}
        </Grid>
      }
      actions={
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <StyledButtonSecondary
                  label={t('requestReviewDialog.actions.cancelButton')}
                  disabled={isLoading}
                  onClick={handleClose}
                />
              </Grid>
              <Grid item>
                <StyledButtonPrimary
                  label={t('requestReviewDialog.actions.submitButton')}
                  disabled={!canSubmit() || isLoading}
                  onClick={handleSubmit}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
    />
  );
};

AhaReviewersDialog.propTypes = {
  requestReviewDialogIsOpen: PropTypes.bool,
  toggleRequestReviewDialog: PropTypes.func,
  ahaTemplate: PropTypes.object,
  isGlobal: PropTypes.bool,
  isManagingReviewers: PropTypes.bool,
  ahaReview: PropTypes.object,
  isReadOnlyReview: PropTypes.bool,
  refetchQuery: PropTypes.func
};

export default AhaReviewersDialog;
