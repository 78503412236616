import useSoteriaStore from '../useSoteriaStore';

const useHelpAndSupport = () => {
  const { store, setStore } = useSoteriaStore();

  const toggleHelp = (isOpen, selectedSection) => {
    setStore({
      helpIsOpen: isOpen,
      selectedHelpSection: isOpen ? selectedSection : null
    });
  };

  const setSelectedHelpSection = selectedSection => {
    setStore({
      selectedHelpSection: selectedSection
    });
  };

  return {
    toggleHelp,
    isOpen: store?.helpIsOpen,
    selectedHelpSection: store?.selectedHelpSection,
    setSelectedHelpSection
  };
};

export default useHelpAndSupport;
