import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';

import { GET_MAINTENANCE_MODE_STATUS } from 'graphql/maintenanceMode/index.js';
import { client } from 'config/maintenanceClientConfig';
import useLazyQueryPromise from 'hooks/useLazyQueryPromise';
import useIsMaintenanceMode from 'store/maintenanceModeDetection';
import useIsOnline from 'store/onlineDetection';

const MaintenanceModeDetector = ({ children }) => {
  const { isOnline } = useIsOnline();

  const { isMaintenanceMode, setIsMaintenanceMode } = useIsMaintenanceMode();

  const [checkMaintenanceMode] = useLazyQueryPromise(
    GET_MAINTENANCE_MODE_STATUS,
    {
      client: client,
      fetchPolicy: 'no-cache'
    }
  );

  const checkMaintenanceModeStatus = () => {
    checkMaintenanceMode()
      .then(res => {
        const maintenanceMode =
          res?.data?.MaintenanceStatusQuery?.isMaintenanceModeOn ?? false;

        if (maintenanceMode !== isMaintenanceMode) {
          setIsMaintenanceMode(maintenanceMode);
        }
      })
      .catch(err => console.error('ERROR', err));
  };

  useEffect(() => {
    if (isOnline) {
      checkMaintenanceModeStatus();
    }
  }, []);

  useEffect(() => {
    if (isOnline) {
      const intervalId = setInterval(checkMaintenanceModeStatus, 60000);
      return () => clearInterval(intervalId);
    }
  });

  return <Fragment>{children}</Fragment>;
};

MaintenanceModeDetector.propTypes = {
  children: PropTypes.node
};

export default MaintenanceModeDetector;
