import { GET_ORIENTATION } from 'graphql/orientation';
import useSoteriaQuery from 'hooks/useSoteriaQuery';

const useOrientation = (orientationId, shouldSkip = false) => {
  const { data: { orientation = null } = {}, loading, error } = useSoteriaQuery(
    {
      gql: GET_ORIENTATION,
      queryHookOptions: {
        skip: shouldSkip || !orientationId,
        variables: { orientationId }
      }
    }
  );

  return { orientation, loading, error };
};

export default useOrientation;
