import ColorLensIcon from '@mui/icons-material/ColorLens';
import OfflineBoltIcon from '@mui/icons-material/OfflineBolt';

import { tutorials } from 'constants/tutorials';
import { features } from 'constants/enabledFeatures';

export const whatsNew = {
  version: '1.0.0 - 4.0.0',
  items: [
    {
      title: 'Updated Observation Search',
      content:
        'The search algorithm for observations has been upgraded. The notes are now searchable. Matches and sorting will differ from the old algorithm. A new "Draft" status has been added for a future enhancement.'
    },
    {
      title: 'Updated Company Search',
      content:
        'When searching for existing companies, the search algorithm has been upgraded. Matches and sorting will differ from the old algorithm.'
    },
    {
      title: 'Required Fields',
      content:
        'Both in Safety Connect and in the self-registration app, phone number is now required for all orientation attendees, and email is required for supervisor attendees.'
    },
    {
      title: 'Offline Mode',
      icon: OfflineBoltIcon,
      content:
        'Enable offline mode for projects with little to no internet connectivity.',
      tutorial: tutorials.settings.enableOfflineMode
    },
    {
      title: 'SAFE',
      content: `Manage observations from your device while on the field. Observations can be created for the projects you have accessed even when you lose service.`,
      enabledFeature: features.safe
    },
    {
      title: 'Orientations',
      content: `Schedule a single orientation or recurring series and manage attendees.
      Send your trade partners a link to the schedule to allow them to self-register
      ahead of time.`,
      tutorial: tutorials.orientations.scheduleOrientations,
      enabledFeature: features.orientations
    },
    {
      title: 'Personnel',
      content: `Accurately track personnel lists for your projects.`,
      tutorial: tutorials.personnel.viewProjectPersonnel,
      enabledFeature: features.personnel
    },
    {
      title: 'Dark theme',
      icon: ColorLensIcon,
      content: `A darker screen can reduce eye strain in some environments.
        You can enable dark theme in the settings menu under your avatar image in the upper right.`
    },
    {
      title: 'Permissions',
      content: `Manage admins on projects with ease.`,
      tutorial: tutorials.permissions.roles
    },
    {
      title: 'Tutorials',
      content: `Learn about Safety Connect with step-by-step tutorials.`,
      tutorial: ''
    }
  ]
};
