import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@apollo/react-hooks';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Card, Typography, IconButton, Button } from '@mui/material';

import useToast from 'hooks/useToast';
import StyledDeleteConfirmation from 'shared/DeleteConfirmation';
import UpsertActivity from 'components/ahas/UpsertActivity';
import {
  GET_PAGINATED_AHA_ACTIVITIES,
  DELETE_GLOBAL_AHA_ACTIVITY
} from 'graphql/aha/globalActivity';
import useAhaGlobalActivitiesListState from 'store/ahaGlobalActivitiesListState';
import StyledInfiniteScrollWithOverlay from 'shared/InfiniteScrollWithOverlay';

const useStyles = makeStyles(theme => ({
  globalCompetencyCard: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(0.3, 1),
    boxShadow: 'none',
    margin: theme.spacing(1, 0),
    height: 34.8,
    '& .MuiIconButton-root': {
      padding: 3
    }
  },
  globalCompetencyCardName: {
    fontSize: '0.875rem',
    display: 'inline',
    verticalAlign: '-10%'
  },
  addButtonContainer: { position: 'absolute', bottom: 16 },
  addButton: {
    position: 'relative',
    bottom: 0,
    left: 0,
    borderRadius: 16
  },
  addButtonLabel: { fontSize: '0.75rem', fontWeight: 'bold' }
}));

const resetUpdateQuery = (
  _previousResult,
  { fetchMoreResult, _queryVariables }
) => {
  return fetchMoreResult;
};

const GlobalActivitiesTabContent = ({
  isEditing,
  handleAddGlobalActivityToTemplate
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { displayToast } = useToast();
  const [isReadOnlyActivity, setIsReadOnlyActivity] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [isUpsertActivityDialogOpen, setIsUpsertActivityDialogOpen] = useState(
    false
  );
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(
    false
  );
  const [isDeleteConfirmed, toggleIsDeleteConfirmed] = useState(false);
  const [
    ahaGlobalActivitiesListState,
    { handleAhaGlobalActivitiesListStateChange }
  ] = useAhaGlobalActivitiesListState();
  const [initialActivitiesListState] = useState(ahaGlobalActivitiesListState);
  const [shouldShowLoader, setShouldShowLoader] = useState(true);
  const [initialQueryHasBeenCalled, setInitialQueryHasBeenCalled] = useState(
    false
  );

  // Get the initial data
  const { data, fetchMore: fetchMoreGlobalActivities } = useQuery(
    GET_PAGINATED_AHA_ACTIVITIES,
    {
      variables: {
        first: initialActivitiesListState.first,
        skip: 0,
        search: initialActivitiesListState.search
      },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true
    }
  );

  const [deleteGlobalAhaActivity, { loading: isDeleting }] = useMutation(
    DELETE_GLOBAL_AHA_ACTIVITY
  );

  useEffect(() => {
    const shouldDisableFullscreenLoader = !initialQueryHasBeenCalled && data;
    if (shouldDisableFullscreenLoader) {
      setShouldShowLoader(false);
      setInitialQueryHasBeenCalled(true);
    }
  }, [data, initialQueryHasBeenCalled]);

  useEffect(() => {
    if (!initialQueryHasBeenCalled && ahaGlobalActivitiesListState.skip > 0) {
      handleAhaGlobalActivitiesListStateChange({
        ...ahaGlobalActivitiesListState,
        skip: 0
      });
    }
  }, [
    ahaGlobalActivitiesListState,
    handleAhaGlobalActivitiesListStateChange,
    initialQueryHasBeenCalled
  ]);

  const handleClose = () => {
    setIsReadOnlyActivity(false);
    setSelectedActivity(null);
    setIsDeleteConfirmationOpen(false);
    toggleIsDeleteConfirmed(false);
    setIsUpsertActivityDialogOpen(false);
  };

  const handleViewActivity = activity => {
    setIsReadOnlyActivity(true);
    setSelectedActivity(activity);
    setIsUpsertActivityDialogOpen(true);
  };

  const handleAddGlobalActivityButtonClick = () => {
    setIsUpsertActivityDialogOpen(!isUpsertActivityDialogOpen);
  };

  const resetAndRefetch = () => {
    handleAhaGlobalActivitiesListStateChange({
      ...ahaGlobalActivitiesListState,
      skip: 0
    });

    const variables = {
      first: ahaGlobalActivitiesListState.first,
      search: ahaGlobalActivitiesListState.search,
      skip: 0
    };

    loadMore(variables, true);
  };

  const handleDeleteSubmit = () => {
    deleteGlobalAhaActivity({ variables: { id: selectedActivity.id } })
      .then(() => {
        resetAndRefetch();
        displayToast(
          t('globalActivitiesTabContent.toasts.success', {
            name: selectedActivity.name
          }),
          'success'
        );
        handleClose();
      })
      .catch(error => {
        console.error('Delete Global Activity Error: ', error);
        displayToast(t('globalActivitiesTabContent.toasts.error'), 'error');
      });
  };

  const loadMore = (variables, shouldReset = false) => {
    if (shouldReset) {
      setShouldShowLoader(true);
    }

    return fetchMoreGlobalActivities({
      variables,
      updateQuery: shouldReset
        ? resetUpdateQuery
        : (previousResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return previousResult;
            return Object.assign({}, previousResult, {
              paginatedGlobalAhaActivitys: {
                ...previousResult.paginatedGlobalAhaActivitys,
                ...fetchMoreResult.paginatedGlobalAhaActivitys,
                total: fetchMoreResult.paginatedGlobalAhaActivitys.total,
                ahaActivitys: [
                  ...previousResult.paginatedGlobalAhaActivitys.ahaActivitys,
                  ...fetchMoreResult.paginatedGlobalAhaActivitys.ahaActivitys
                ]
              }
            });
          }
    }).then(() => {
      setShouldShowLoader(false);
    });
  };

  const handleLoadNextPage = () => {
    const skip =
      ahaGlobalActivitiesListState.skip + ahaGlobalActivitiesListState.first;
    handleAhaGlobalActivitiesListStateChange({
      ...ahaGlobalActivitiesListState,
      skip
    });

    const variables = {
      first: ahaGlobalActivitiesListState.first,
      search: ahaGlobalActivitiesListState.search,
      skip
    };

    loadMore(variables);
  };

  const globalActivitiesList =
    data?.paginatedGlobalAhaActivitys?.ahaActivitys ?? [];

  const globalActivitiesTotalCount =
    data?.paginatedGlobalAhaActivitys?.total ?? 0;

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <StyledInfiniteScrollWithOverlay
            containerName={'aha-global-activities'}
            height={'calc(100vh - 348px)'}
            isLoading={shouldShowLoader}
            dataLength={globalActivitiesList.length}
            next={handleLoadNextPage}
            hasMore={globalActivitiesList.length < globalActivitiesTotalCount}
            endMessageTextColor={'light'}>
            {globalActivitiesList.map(activity => (
              <Card key={activity.id} className={classes.globalCompetencyCard}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center">
                  <Grid item>
                    <Typography className={classes.globalCompetencyCardName}>
                      {activity.name}
                    </Typography>
                  </Grid>
                  <Grid item>
                    {!isEditing && (
                      <>
                        <IconButton
                          size="small"
                          onClick={() => {
                            handleViewActivity(activity);
                          }}>
                          <VisibilityIcon />
                        </IconButton>
                        <IconButton
                          size="small"
                          onClick={() => {
                            handleAddGlobalActivityToTemplate(activity);
                          }}>
                          <AddIcon />
                        </IconButton>
                      </>
                    )}
                    {isEditing && (
                      <>
                        <IconButton
                          size="small"
                          onClick={() => {
                            setIsReadOnlyActivity(false);
                            setSelectedActivity(activity);
                            setIsUpsertActivityDialogOpen(true);
                          }}>
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          size="small"
                          onClick={() => {
                            setIsReadOnlyActivity(false);
                            setSelectedActivity(activity);
                            setIsDeleteConfirmationOpen(true);
                          }}>
                          <DeleteIcon />
                        </IconButton>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Card>
            ))}
          </StyledInfiniteScrollWithOverlay>
        </Grid>
        <Grid item className={classes.addButtonContainer}>
          <Button
            className={classes.addButton}
            size="small"
            variant="contained"
            color="secondary"
            startIcon={<AddIcon />}
            onClick={handleAddGlobalActivityButtonClick}>
            <Typography className={classes.addButtonLabel}>
              Add Activity to Library
            </Typography>
          </Button>
        </Grid>
      </Grid>
      <UpsertActivity
        isOpen={isUpsertActivityDialogOpen}
        setIsOpen={setIsUpsertActivityDialogOpen}
        isGlobal={true}
        refetchCurrentQueries={() => {}}
        activityToEdit={selectedActivity}
        setActivityToEdit={setSelectedActivity}
        isReadOnly={isReadOnlyActivity}
      />
      <StyledDeleteConfirmation
        title={'Delete Global Activity'}
        dialogIsOpen={isDeleteConfirmationOpen}
        isLoading={isDeleting}
        handleClose={handleClose}
        warningMessage={t('globalActivitiesTabContent.deleteWarning', {
          name: selectedActivity?.name
        })}
        isConfirmed={isDeleteConfirmed}
        handleChange={event => {
          toggleIsDeleteConfirmed(event.target.checked);
        }}
        handleSubmit={handleDeleteSubmit}
      />
    </>
  );
};

GlobalActivitiesTabContent.propTypes = {
  isEditing: PropTypes.bool,
  handleAddGlobalActivityToTemplate: PropTypes.func
};

export default GlobalActivitiesTabContent;
