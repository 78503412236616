import gql from 'graphql-tag';

// Queries

export const GET_PAGINATED_PROJECT_TEMPLATES = gql`
  query GetPaginatedProjectTemplates(
    $filter: AhaProjectTemplateFiltersInput
    $first: Int!
    $order: [AhaProjectTemplateOrderInput!]
    $projectId: ID!
    $search: String
    $skip: Int
  ) {
    paginatedAhaProjectTemplates(
      filter: $filter
      first: $first
      order: $order
      projectId: $projectId
      search: $search
      skip: $skip
    ) {
      total
      ahaProjectTemplates {
        id
        status
        created
        lastModified
        ahaTemplate {
          created
          createdBy {
            id
            jobTitle
            name
          }
          id
          lastModified
          type
        }
        contractor
        location
        notes
        ownedBy {
          id
          upn
          name
          jobTitle
        }
        project {
          id
          name
        }
        ahaReviews {
          id
          status
          lastModified
          completed
        }
        ahaFiles {
          id
          lastModified
        }
      }
    }
  }
`;

// Mutations

export const CREATE_PROJECT_TEMPLATE = gql`
  mutation CreateProjectTemplate($input: AhaProjectTemplateCreateInput!) {
    createAhaProjectTemplate(input: $input) {
      id
      ahaTemplate {
        id
        type
      }
    }
  }
`;

export const UPDATE_PROJECT_TEMPLATE = gql`
  mutation UpdateProjectTemplate($input: AhaProjectTemplateUpdateInput!) {
    updateAhaProjectTemplate(input: $input) {
      id
      status
      reviewReminderCadenceDays
      ownedBy {
        id
        name
        upn
        jobTitle
      }
      ahaTemplate {
        id
        created
        lastModified
        ahaActivitys {
          id
          name
          isActive
          created
          lastModified
          order
        }
        ahaEquipments {
          id
          inspectionRequirements
          isActive
          name
          order
          trainings
        }
        ahaGlobalTemplateCategory {
          ahaTemplates {
            id
          }
          id
          name
        }
        ahaSteps {
          ahaStepReferences {
            ahaReferenceOption
            ahaStep {
              id
            }
            id
            value
          }
          controls
          hazards
          id
          isActive
          name
          order
          riskAssessmentCode
        }
        id
        type
      }
      contractor
      location
      notes
      project {
        id
        name
      }
    }
  }
`;

export const DELETE_PROJECT_TEMPLATE = gql`
  mutation DeleteProjectTemplate($id: ID!) {
    deleteAhaProjectTemplate(id: $id) {
      id
    }
  }
`;
