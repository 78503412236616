import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@mui/material';

const StyledChip = ({ disabled, label, ...rest }) => {
  return (
    <Chip
      size="small"
      color="default"
      variant="outlined"
      disabled={disabled}
      label={label}
      {...rest}
    />
  );
};

StyledChip.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.any.isRequired
};

export default StyledChip;
