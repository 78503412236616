import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useField, useForm } from 'react-final-form-hooks';
import { useMutation } from '@apollo/react-hooks';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography } from '@mui/material';

import useToast from 'hooks/useToast';
import useFormValidation from 'hooks/useFormValidation';
import StyledDialog from 'shared/Dialog';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import StyledButtonSecondary from 'shared/Buttons/ButtonSecondary';
import StyledNotice from 'shared/Notice';
import StyledInput from 'shared/Input';
import { generateTransactionKey } from 'utils';
import {
  CREATE_GLOBAL_AHA_ACTIVITY,
  UPDATE_GLOBAL_AHA_ACTIVITY
} from 'graphql/aha/globalActivity';
import AhaScopeIndicator from 'components/ahas/AhaScopeIndicator';
import { GET_PAGINATED_AHA_ACTIVITIES } from 'graphql/aha/globalActivity';
import useAhaGlobalActivitiesListState from 'store/ahaGlobalActivitiesListState';

const useStyles = makeStyles(theme => ({
  fieldContainer: { marginBottom: theme.spacing(2) },
  section: { marginBottom: theme.spacing(2) },
  sectionLabel: { fontSize: '0.875rem' }
}));

const ReadOnlySection = ({ label, value }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} className={classes.section}>
      <Typography color="textSecondary" className={classes.sectionLabel}>
        {label}
      </Typography>
      <Typography component="div">{value}</Typography>
    </Grid>
  );
};

ReadOnlySection.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any
};

const UpsertActivity = ({
  isOpen,
  setIsOpen,
  isGlobal = false,
  refetchCurrentQueries,
  activityToEdit,
  setActivityToEdit,
  setAhaTemplate,
  ahaTemplate,
  isReadOnly
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { displayToast } = useToast();
  const { isRequired } = useFormValidation();
  const [transactionKey, setTransactionKey] = useState(
    generateTransactionKey()
  );
  const isEditing = !!activityToEdit;
  const [ahaGlobalActivitiesListState] = useAhaGlobalActivitiesListState();

  const { form, values, pristine } = useForm({
    /* istanbul ignore next */
    onSubmit: () => {},
    initialValues: {
      name: activityToEdit?.name ?? ''
    }
  });
  const nameField = useField('name', form, isRequired);

  const [
    createGlobalActivity,
    { loading: isLoadingCreateGlobalActivity }
  ] = useMutation(CREATE_GLOBAL_AHA_ACTIVITY, {
    refetchQueries: [
      {
        query: GET_PAGINATED_AHA_ACTIVITIES,
        variables: {
          first: ahaGlobalActivitiesListState.first,
          skip: 0,
          search: ahaGlobalActivitiesListState.search
        }
      }
    ]
  });

  const [
    updateGlobalAhaActivity,
    { loading: isLoadingUpdateGlobalActivity }
  ] = useMutation(UPDATE_GLOBAL_AHA_ACTIVITY);

  const isLoading =
    isLoadingCreateGlobalActivity || isLoadingUpdateGlobalActivity;

  const canSubmit = () => {
    const { error, invalid } = form.getState();
    return !(invalid || error || isLoading || pristine);
  };

  const handleCreateGlobalActivity = input => {
    createGlobalActivity({
      variables: { input }
    })
      .then(({ data: { createGlobalAhaActivity: createdGlobalActivity } }) => {
        refetchCurrentQueries();
        handleClose();
        displayToast(
          t('createGlobalEquipmentDialog.toasts.success', {
            name: createdGlobalActivity.name
          }),
          'success'
        );
      })
      .catch(error => {
        console.error('Create Global Activity Error: ', error);
        displayToast(t('createGlobalEquipmentDialog.toasts.error'), 'error');
      });
  };

  const handleUpdateGlobalActivity = input => {
    updateGlobalAhaActivity({
      variables: { input }
    })
      .then(({ data: { updateGlobalAhaActivity: globalActivity } }) => {
        displayToast(
          t('upsertActivity.toasts.success', {
            name: globalActivity.name
          }),
          'success'
        );
        handleClose();
      })
      .catch(error => {
        console.error('Update Global Activity Error: ', error);
        displayToast(t('upsertActivity.toasts.error'), 'error');
      });
  };

  const handleSubmit = () => {
    const input = {
      name: values.name,
      isActive: true
    };

    if (isGlobal && !ahaTemplate) {
      input.isActive = true;
      input.order = undefined; // Global activities do not need order
      if (activityToEdit) {
        input.id = activityToEdit.id;
        handleUpdateGlobalActivity(input);
      } else {
        input.transactionKey = transactionKey;
        handleCreateGlobalActivity(input);
      }
    } else {
      const identifier =
        activityToEdit?.id ?? activityToEdit?.transactionKey ?? transactionKey;

      const activityToUpsert = {
        ...activityToEdit,
        ...input,
        transactionKey: identifier ?? generateTransactionKey()
      };

      let ahaActivities = [];

      if (isEditing) {
        ahaActivities = [
          ...ahaTemplate?.ahaActivitys.map(activity => {
            if (
              activity.id === identifier ||
              activity.transactionKey === identifier
            ) {
              return activityToUpsert;
            } else {
              return activity;
            }
          })
        ];
      } else {
        const getOrder = arr => {
          if (arr.length > 0) {
            return (
              Math.max.apply(
                Math,
                arr.map(function(item) {
                  return item.order;
                })
              ) + 1
            );
          } else {
            return 0;
          }
        };

        activityToUpsert.order = getOrder(ahaTemplate.ahaActivitys);
        ahaActivities = [...ahaTemplate.ahaActivitys, activityToUpsert];
      }

      setAhaTemplate({
        ...ahaTemplate,
        ahaActivitys: ahaActivities
      });

      handleClose();
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    setTransactionKey(generateTransactionKey());
    form.resetFieldState('name');
    form.reset();
    if (activityToEdit && setActivityToEdit) {
      setActivityToEdit(null);
    }
  };

  const getDialogTitle = () => {
    let dialogTitle = '';

    if (isReadOnly) {
      dialogTitle = 'Global Activity';
    } else {
      if (activityToEdit) {
        dialogTitle = 'Edit Activity';
      } else {
        dialogTitle = 'Add Activity';
      }
    }

    return dialogTitle;
  };

  const titleToDisplay = getDialogTitle();

  return (
    <StyledDialog
      maxWidth={isReadOnly ? 'sm' : 'md'}
      isOpen={isOpen}
      handleClose={handleClose}
      isLoading={isLoading}
      title={titleToDisplay}
      content={
        <form>
          <Grid container justifyContent="flex-end" className={'margin-bottom'}>
            <Grid item>
              <AhaScopeIndicator isGlobal={isGlobal} />
            </Grid>
            {isGlobal && !ahaTemplate && !isReadOnly && (
              <Grid item xs={12}>
                <StyledNotice message={t('upsertCompetency.notice.message')} />
              </Grid>
            )}
            {!isReadOnly && (
              <>
                <Grid item xs={12} className={classes.fieldContainer}>
                  <StyledInput
                    label={t('upsertCompetency.field.name')}
                    input={nameField.input}
                    meta={nameField.meta}
                    autoFocus={true}
                    required
                    disabled={isLoading}
                  />
                </Grid>
              </>
            )}
            {isReadOnly && (
              <>
                <ReadOnlySection
                  label={t('upsertCompetency.field.name')}
                  value={activityToEdit?.name}
                />
              </>
            )}
          </Grid>
        </form>
      }
      actions={
        <Grid container justifyContent="space-between">
          <Grid item>
            <StyledButtonSecondary
              label={t('upsertEquipment.actions.cancelButton')}
              disabled={isLoading}
              onClick={handleClose}
            />
          </Grid>
          <Grid item>
            {!isReadOnly && (
              <StyledButtonPrimary
                label={t('upsertEquipment.actions.submitButton')}
                disabled={!canSubmit() || isLoading}
                onClick={handleSubmit}
              />
            )}
          </Grid>
        </Grid>
      }
    />
  );
};

UpsertActivity.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  isGlobal: PropTypes.bool,
  refetchCurrentQueries: PropTypes.func,
  activityToEdit: PropTypes.any,
  setActivityToEdit: PropTypes.func,
  setAhaTemplate: PropTypes.func,
  ahaTemplate: PropTypes.object,
  isReadOnly: PropTypes.bool
};

export default UpsertActivity;
