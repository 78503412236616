import React from 'react';
import PropTypes from 'prop-types';

import StyledStep from 'shared/Step';
import CompanyFormFields from 'components/company/CompanyFormFields';

const NewCompanyStep = ({ form, isLoading }) => {
  return (
    <StyledStep>
      <CompanyFormFields
        form={form}
        isLoading={isLoading}
        isNestedForm={true}
      />
    </StyledStep>
  );
};

NewCompanyStep.propTypes = {
  form: PropTypes.any.isRequired,
  isLoading: PropTypes.bool
};

export default NewCompanyStep;
