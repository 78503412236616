import { atom, useRecoilState } from 'recoil';
import * as effects from 'store/effects';
import {
  LOCAL_STORAGE_COMPANIES_SORT_ORDER_KEY,
  LOCAL_STORAGE_COMPANIES_FILTERS_KEY
} from 'constants/localStorage';

const DEFAULT_ROWS_PER_PAGE = 100;
const DEFAULT_SORT_ORDER = {
  name: 'name',
  direction: 'asc'
};
const DEFAULT_FILTERS = {};

const getStoredSortOrder = () => {
  const unserializedSortOrder = localStorage.getItem(
    LOCAL_STORAGE_COMPANIES_SORT_ORDER_KEY
  );
  if (unserializedSortOrder) {
    return JSON.parse(unserializedSortOrder);
  } else {
    return null;
  }
};

const getStoredFilters = () => {
  const unserializedFilters = localStorage.getItem(
    LOCAL_STORAGE_COMPANIES_FILTERS_KEY
  );
  if (unserializedFilters) {
    return JSON.parse(unserializedFilters);
  } else {
    return null;
  }
};

const order = getStoredSortOrder() ?? DEFAULT_SORT_ORDER;
const filter = getStoredFilters() ?? DEFAULT_FILTERS;

const defaultState = {
  first: DEFAULT_ROWS_PER_PAGE,
  order,
  filter,
  filterList: [],
  search: '',
  skip: 0,
  page: 0
};

function hasActiveFilters(filterState) {
  let hasActiveFilters = false;

  if (typeof filterState === 'object') {
    for (const value of Object.values(filterState)) {
      if (value) {
        hasActiveFilters = true;
        break;
      }
    }
  }

  return hasActiveFilters;
}

const companiesListStateAtom = atom({
  key: 'companiesListState',
  default: defaultState
});

function useCompaniesListState() {
  const [companiesListState, setCompaniesListState] = useRecoilState(
    companiesListStateAtom
  );

  function handleCompaniesListStateChange(newState) {
    if (newState?.filter) {
      effects.companiesListFilters.localStorageSave(newState.filter);
    }
    if (hasActiveFilters(newState.filter)) {
      newState.hasActiveFilters = true;
    } else {
      newState.hasActiveFilters = false;
    }

    setCompaniesListState({
      ...companiesListState,
      ...newState
    });
  }
  return [companiesListState, { handleCompaniesListStateChange }];
}

export default useCompaniesListState;
