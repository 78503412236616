import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import NavigationPrompt from 'react-router-navigation-prompt';
import classnames from 'classnames';
import { detect } from 'detect-browser';
import { useReactToPrint } from 'react-to-print';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import UndoIcon from '@mui/icons-material/ArrowBackIos';
import RedoIcon from '@mui/icons-material/ArrowForwardIos';
import EditIcon from '@mui/icons-material/Edit';
import PrintIcon from '@mui/icons-material/Print';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import {
  Grid,
  Typography,
  Card,
  IconButton,
  FormGroup,
  Checkbox,
  Button,
  Tooltip
} from '@mui/material';

import StyledDeleteConfirmation from 'shared/DeleteConfirmation';
import StyledDiscardChangesDialog from 'shared/DiscardChangesDialog';
import StyledNotice from 'shared/Notice';
import StyledConfirmationSwitch from 'shared/ConfirmationSwitch';
import EditStep from 'components/ahas/UpsertStep';
import EditEquipment from 'components/ahas/UpsertEquipment';
import EditableAhaType from './EditableAhaType';
import AhaActionsMenu from '../AhaActionsMenu';
import ConfirmationDialog from 'components/common/ConfirmDialog';
import HighRiskConfirmationDialog from 'components/ahas/HighRiskConfirmationDialog';
import AhaPrintableDocument from 'components/ahas/AhaPrintableDocument';
import AhaPrintDisclaimerDialog from 'components/ahas/AhaPrintDisclaimerDialog';
import UpsertActivity from 'components/ahas/UpsertActivity';

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: '2rem',
    lineHeight: 1.25,
    fontWeight: 'bold',
    [theme.breakpoints.down('xl')]: {
      margin: theme.spacing(1, 0)
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.5rem'
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: 250
    }
  },
  subTitle: {
    fontSize: '0.875rem',
    marginBottom: theme.spacing(1),
    lineHeight: 1,
    textTransform: 'uppercase'
  },
  card: {
    padding: theme.spacing(2),
    borderRadius: 0
  },
  cardLabel: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: '0.875rem'
  },
  workspaceCard: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(0.3, 1),
    boxShadow: 'none',
    marginBottom: theme.spacing(1)
  },
  workspaceCardName: {
    fontSize: '0.875rem',
    display: 'inline',
    verticalAlign: '35%'
  },
  scrollingContainer: {
    height: 'calc(100vh - 260px)',
    overflowY: 'scroll',
    [theme.breakpoints.down('md')]: {
      height: 'calc(100vh - 220px)'
    }
  },
  formGroup: { display: 'inline' },
  discardChangesButton: { borderRadius: 16, marginRight: 16 },
  saveButton: { borderRadius: 16 },
  printButton: { borderRadius: 16 },
  continueButton: { borderRadius: 16 },
  buttonLabel: {
    fontSize: '0.75rem',
    fontWeight: 'bold',
    marginTop: 2,
    [theme.breakpoints.down('md')]: { fontSize: '0.625rem' }
  },
  noItemsMessage: { fontSize: '0.75rem', paddingTop: theme.spacing(1) },
  link: { '&:hover': { cursor: 'pointer' } },
  riskIndication: {
    display: 'inline',
    fontSize: '0.625rem',
    marginRight: theme.spacing(2),
    fontWeight: 'bold'
  },
  icon: {
    fontSize: '1.25rem',
    [theme.breakpoints.down('md')]: { fontSize: '1rem' }
  },
  activeChanges: { color: theme.palette.primary.main },
  leftSideActionButtons: {
    width: 250,
    [theme.breakpoints.down('md')]: { width: 100 }
  },
  competencyName: { padding: theme.spacing(1), fontSize: '0.875rem' }
}));

const SummaryWorkspace = ({
  ahaTemplate,
  originalAhaTemplate,
  isGlobal,
  hasChanges,
  setAhaTemplate,
  isLoading,
  handleSaveAhaTemplate,
  resetAhaTemplateChanges
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const { projectId, ahaId } = useParams();
  const history = useHistory();
  const [isEditStepDialogOpen, setIsEditStepDialogOpen] = useState(false);
  const [isEditEquipmentDialogOpen, setIsEditEquipmentDialogOpen] = useState(
    false
  );
  const [isUpsertActivityDialogOpen, setIsUpsertActivityDialogOpen] = useState(
    false
  );
  const [
    isConfirmStepDeleteDialogOpen,
    setIsConfirmStepDeleteDialogOpen
  ] = useState(false);
  const [
    isConfirmEquipmentDeleteDialogOpen,
    setIsConfirmEquipmentDeleteDialogOpen
  ] = useState(false);
  const [
    isConfirmActivityDeleteDialogOpen,
    setIsConfirmActivityDeleteDialogOpen
  ] = useState(false);
  const [isDeleteConfirmed, setIsDeleteConfirmed] = useState(false);
  const [selectedStep, setSelectedStep] = useState(null);
  const [selectedSaveType, setSelectedSaveType] = useState(null);
  const [selectedEquipment, setSelectedEquipment] = useState(null);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [discardChangesDialogIsOpen, setDiscardChangesDialogIsOpen] = useState(
    false
  );
  const [isDiscardChangesConfirmed, setIsDiscardChangesConfirmed] = useState(
    false
  );
  const [
    isHighRiskConfirmationDialogRendered,
    renderHighRiskConfirmationDialog
  ] = useState(false);
  const sortByOrder = (a, b) => a.order - b.order;
  const componentRef = useRef();
  const browser = detect();
  const printReact = useReactToPrint({
    content: () => componentRef.current
  });
  const [printDisclaimerIsOpen, setPrintDisclaimerIsOpen] = useState(false);
  const handlePrint = () => {
    if (browser && browser.name === 'safari') {
      setPrintDisclaimerIsOpen(true);
    } else {
      printReact();
    }
  };

  const workSpaceJobSteps = ahaTemplate?.ahaSteps
    ? [...ahaTemplate.ahaSteps].sort(sortByOrder)
    : [];
  const workSpaceEquipment = ahaTemplate?.ahaEquipments
    ? [...ahaTemplate.ahaEquipments].sort(sortByOrder)
    : [];
  const workSpaceActivities = ahaTemplate?.ahaActivitys
    ? [...ahaTemplate.ahaActivitys].sort(sortByOrder)
    : [];

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    result.forEach((item, index) => {
      item.order = index;
    });

    return result;
  };

  const onDragEnd = (result, type) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const isStepType = type === 'steps';
    const isEquipmentType = type === 'equipment';

    const newResults = reorder(
      isStepType
        ? workSpaceJobSteps
        : isEquipmentType
        ? workSpaceEquipment
        : workSpaceActivities,
      result.source.index,
      result.destination.index
    );

    setAhaTemplate({
      ...ahaTemplate,
      ...(isStepType
        ? { ahaSteps: newResults }
        : isEquipmentType
        ? { ahaEquipments: newResults }
        : { ahaActivitys: newResults })
    });
  };

  const handleCloseConfirmStepDelete = () => {
    setIsConfirmStepDeleteDialogOpen(false);
  };

  const handleCloseConfirmEquipmentDelete = () => {
    setIsConfirmEquipmentDeleteDialogOpen(false);
  };

  const handleCloseConfirmActivityDelete = () => {
    setIsConfirmActivityDeleteDialogOpen(false);
  };

  const handleChange = checked => {
    setIsDeleteConfirmed(checked);
  };

  const handleDeleteSubmit = type => {
    const isStepType = type === 'step';
    const isEquipmentType = type === 'equipment';

    const identifier = isStepType
      ? selectedStep.id ?? selectedStep.transactionKey
      : isEquipmentType
      ? selectedEquipment.id ?? selectedEquipment.transactionKey
      : selectedActivity.id ?? selectedActivity.transactionKey;

    setAhaTemplate({
      ...ahaTemplate,
      ...(isStepType
        ? {
            ahaSteps: workSpaceJobSteps.filter(
              step =>
                step.id !== identifier && step.transactionKey !== identifier
            )
          }
        : isEquipmentType
        ? {
            ahaEquipments: workSpaceEquipment.filter(
              equipment =>
                equipment.id !== identifier &&
                equipment.transactionKey !== identifier
            )
          }
        : {
            ahaActivitys: workSpaceActivities.filter(
              activity =>
                activity.id !== identifier &&
                activity.transactionKey !== identifier
            )
          })
    });

    setIsConfirmStepDeleteDialogOpen(false);
    setIsConfirmEquipmentDeleteDialogOpen(false);
    setIsConfirmActivityDeleteDialogOpen(false);
    setIsDeleteConfirmed(false);
    setSelectedEquipment(null);
    setSelectedStep(null);
    setSelectedActivity(null);
  };

  const handleSaveButtonClick = () => {
    checkForHighRiskSteps('save');
  };

  const handleContinueButtonClick = () => {
    checkForHighRiskSteps('saveAndContinue');
  };

  const handleSaveForType = type => {
    if (type === 'saveAndContinue') {
      handleSaveAndContinue();
    } else {
      handleSave();
    }
  };

  const checkForHighRiskSteps = type => {
    const hasQuestionableRisk =
      ahaTemplate?.ahaSteps
        ?.filter(step => step.isActive === true)
        .some(
          step =>
            step.riskAssessmentCode?.includes('ExtremelyHigh') ||
            step.riskAssessmentCode?.includes('High')
        ) ?? false;

    if (hasQuestionableRisk) {
      setSelectedSaveType(type);
      renderHighRiskConfirmationDialog(true);
    } else {
      handleSaveForType(type);
    }
  };

  const handleSave = () => {
    let variables = null;

    if (isGlobal) {
      variables = {
        input: {
          id: ahaTemplate.id,
          type: ahaTemplate.type,
          ahaEquipments:
            ahaTemplate?.ahaEquipments?.map(
              ({ __typename, ...restEquipment }) => ({ ...restEquipment })
            ) ?? [],
          ahaSteps:
            ahaTemplate?.ahaSteps?.map(
              ({
                __typename,
                ahaGlobalStepCategory,
                ahaStepReferences,
                ...restStep
              }) => {
                return {
                  ...restStep,
                  ahaStepReferences: ahaStepReferences.map(
                    ({ id, ahaStep, __typename, ...restReference }) => ({
                      ...restReference
                    })
                  )
                };
              }
            ) ?? [],
          ahaActivitys:
            ahaTemplate?.ahaActivitys?.map(
              ({
                __typename,
                created,
                lastModified,
                ahaTemplate,
                ...restActivities
              }) => ({ ...restActivities })
            ) ?? []
        }
      };
    } else {
      variables = {
        input: {
          contractor: ahaTemplate?.ahaProjectTemplate?.contractor,
          id: ahaTemplate?.ahaProjectTemplate?.id,
          location: ahaTemplate?.ahaProjectTemplate?.location,
          notes: ahaTemplate?.ahaProjectTemplate?.notes,
          template: {
            ahaEquipments:
              ahaTemplate?.ahaEquipments?.map(
                ({ __typename, ...restEquipment }) => ({ ...restEquipment })
              ) ?? [],
            ahaSteps:
              ahaTemplate?.ahaSteps?.map(
                ({
                  __typename,
                  ahaGlobalStepCategory,
                  ahaStepReferences,
                  ...restStep
                }) => {
                  return {
                    ...restStep,
                    ahaStepReferences: ahaStepReferences.map(
                      ({ id, __typename, ahaStep, ...restReference }) => ({
                        ...restReference
                      })
                    )
                  };
                }
              ) ?? [],
            ahaActivitys:
              ahaTemplate?.ahaActivitys?.map(
                ({
                  __typename,
                  created,
                  lastModified,
                  ahaTemplate,
                  ...restActivities
                }) => ({
                  ...restActivities
                })
              ) ?? [],
            id: ahaTemplate?.id,
            type: ahaTemplate?.type
          }
        }
      };
    }

    return handleSaveAhaTemplate(variables);
  };

  const handleSaveAndContinue = () => {
    const detailedViewRoute = isGlobal
      ? `/ahas/${ahaId}/edit?view=detailed`
      : `/projects/${projectId}/ahas/hensel-phelps-aha/${ahaId}/edit?view=detailed`;

    if (hasChanges) {
      handleSave().then(() => {
        history.push(detailedViewRoute);
      });
    } else {
      history.push(detailedViewRoute);
    }
  };

  const handleItemStatusToggle = (type, item) => {
    const itemToToggle = {
      ...item,
      isActive: !item.isActive
    };

    const identifier = item.transactionKey ?? item.id;

    if (type === 'step') {
      setAhaTemplate({
        ...ahaTemplate,
        ahaSteps: ahaTemplate?.ahaSteps?.map(item => {
          return item.id === identifier || item.transactionKey === identifier
            ? itemToToggle
            : item;
        })
      });
    }

    if (type === 'equipment') {
      setAhaTemplate({
        ...ahaTemplate,
        ahaEquipments: ahaTemplate?.ahaEquipments?.map(item => {
          return item.id === identifier || item.transactionKey === identifier
            ? itemToToggle
            : item;
        })
      });
    }
  };

  const onEditStepClick = step => {
    setSelectedStep(step);
    setIsEditStepDialogOpen(true);
  };

  const onEditActivityClick = activity => {
    setSelectedActivity(activity);
    setIsUpsertActivityDialogOpen(true);
  };

  const handleDiscard = () => {
    resetAhaTemplateChanges();
    setIsDiscardChangesConfirmed(false);
    setDiscardChangesDialogIsOpen(false);
  };

  const handleDiscardChanges = event => {
    setIsDiscardChangesConfirmed(event.target.checked);
  };

  const renderUnsavedChangesConfirmationDialog = () => {
    return (
      <NavigationPrompt
        when={(currentLocation, nextLocation) =>
          !nextLocation ||
          !nextLocation.pathname.startsWith(currentLocation.pathname)
        }>
        {({ onConfirm, onCancel }) => (
          <ConfirmationDialog
            show={true}
            cancel={onCancel}
            confirmation="You have unsaved changes. To submit changes to the AHA, go back and select the save button in the bottom right corner of the screen."
            proceed={onConfirm}
            options={{
              theme,
              title: 'Unsaved AHA Changes',
              rejectLabel: 'Back',
              confirmLabel: 'Discard'
            }}
          />
        )}
      </NavigationPrompt>
    );
  };

  const handleBackButtonClick = () => {
    if (isGlobal) {
      history.push(`/ahas`);
    } else {
      history.push(`/projects/${projectId}/ahas/hensel-phelps-aha/${ahaId}`);
    }
  };

  return (
    <>
      <Grid container justifyContent="space-between" style={{ marginTop: 6 }}>
        <Grid item>
          <Grid container spacing={1} className={classes.leftSideActionButtons}>
            <Grid item>
              <Tooltip title="Back">
                <span>
                  <IconButton
                    size="small"
                    onClick={handleBackButtonClick}
                    disabled={isLoading}>
                    <UndoIcon color="action" className={classes.icon} />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
            {!isGlobal && (
              <Grid item>
                <Tooltip title="Print">
                  <span>
                    <IconButton
                      size="small"
                      onClick={handlePrint}
                      disabled={isLoading}>
                      <PrintIcon color="action" className={classes.icon} />
                    </IconButton>
                  </span>
                </Tooltip>
              </Grid>
            )}
            <Grid item>
              <Tooltip
                title={!hasChanges ? 'There are no changes to save' : 'Save'}>
                <span>
                  <IconButton
                    size="small"
                    onClick={handleSaveButtonClick}
                    disabled={!hasChanges || isLoading}>
                    <SaveIcon
                      color="action"
                      className={classnames(classes.icon, {
                        [`${classes.activeChanges}`]: hasChanges
                      })}
                    />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          {!hasChanges && (
            <Tooltip title="Continue">
              <span>
                <IconButton
                  size="small"
                  onClick={handleContinueButtonClick}
                  disabled={isLoading}>
                  <RedoIcon color="action" className={classes.icon} />
                </IconButton>
              </span>
            </Tooltip>
          )}
          {hasChanges && (
            <>
              <Button
                onClick={() => setDiscardChangesDialogIsOpen(true)}
                disabled={isLoading}
                className={classes.discardChangesButton}
                variant="outlined"
                size="small">
                <Typography className={classes.buttonLabel}>
                  Discard Changes
                </Typography>
              </Button>
              <Button
                onClick={handleContinueButtonClick}
                disabled={isLoading}
                className={classes.continueButton}
                variant="contained"
                color="primary"
                size="small">
                <Typography className={classes.buttonLabel}>
                  {hasChanges ? 'Save & Continue' : 'Continue'}
                </Typography>
              </Button>
            </>
          )}
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{ marginTop: 24 }}>
        <Grid item>
          <EditableAhaType
            ahaTemplate={ahaTemplate}
            setAhaTemplate={setAhaTemplate}
            isDisabled={isLoading}
          />
        </Grid>
        <Grid item>
          <AhaActionsMenu
            ahaTemplate={originalAhaTemplate}
            isGlobal={isGlobal}
            hasChanges={hasChanges}
          />
        </Grid>
      </Grid>
      <Typography color="textPrimary" className={classes.subTitle}>
        {isGlobal
          ? `Category: ${ahaTemplate?.ahaGlobalTemplateCategory?.name}`
          : 'Project AHA Management'}
      </Typography>
      <div className={classes.scrollingContainer}>
        <Card className={classes.card}>
          <Typography className={classes.cardLabel}>Steps</Typography>
          {workSpaceJobSteps.length < 1 && (
            <Typography className={classes.noItemsMessage}>
              No job steps have been added.
            </Typography>
          )}
          <DragDropContext onDragEnd={results => onDragEnd(results, 'steps')}>
            <Droppable droppableId="steps">
              {(provided, snapshot) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {workSpaceJobSteps?.map((step, index) => (
                    <Draggable
                      key={step.transactionKey ?? step.id}
                      draggableId={step.transactionKey ?? step.id}
                      index={index}>
                      {(provided, snapshot) => (
                        <div
                          style={provided.draggableProps.style}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}>
                          <Card className={classes.workspaceCard}>
                            <Grid
                              container
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center">
                              <Grid item>
                                <DragIndicatorIcon />
                                <Typography
                                  className={classes.workspaceCardName}>
                                  {step.name}
                                </Typography>
                              </Grid>
                              <Grid item>
                                {step.riskAssessmentCode ===
                                  'ExtremelyHigh' && (
                                  <Tooltip title={'Extremely high risk step'}>
                                    <Typography
                                      className={classes.riskIndication}>
                                      * Extremely High Risk
                                    </Typography>
                                  </Tooltip>
                                )}
                                {step.riskAssessmentCode === 'High' && (
                                  <Tooltip title={'High risk step'}>
                                    <Typography
                                      className={classes.riskIndication}>
                                      * High-Risk
                                    </Typography>
                                  </Tooltip>
                                )}
                                <FormGroup className={classes.formGroup}>
                                  <Tooltip
                                    title={
                                      step.isActive === true
                                        ? 'Uncheck to make inactive'
                                        : 'Check to make active'
                                    }>
                                    <Checkbox
                                      checked={step.isActive === true}
                                      onChange={() =>
                                        handleItemStatusToggle('step', step)
                                      }
                                      color="primary"
                                    />
                                  </Tooltip>
                                </FormGroup>
                                <IconButton
                                  size="small"
                                  onClick={() => onEditStepClick(step)}>
                                  <EditIcon />
                                </IconButton>
                                <IconButton
                                  size="small"
                                  onClick={() => {
                                    setSelectedStep(step);
                                    setIsConfirmStepDeleteDialogOpen(true);
                                  }}>
                                  <DeleteIcon />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </Card>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Card>
        <br />
        <Card className={classes.card}>
          <Typography className={classes.cardLabel}>Equipment</Typography>
          {workSpaceEquipment.length < 1 && (
            <Typography className={classes.noItemsMessage}>
              No equipment items have been added.
            </Typography>
          )}
          <DragDropContext
            onDragEnd={results => onDragEnd(results, 'equipments')}>
            <Droppable droppableId="steps">
              {(provided, snapshot) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {workSpaceEquipment?.map((equipment, index) => (
                    <Draggable
                      key={equipment.transactionKey ?? equipment.id}
                      draggableId={equipment.transactionKey ?? equipment.id}
                      index={index}>
                      {(provided, snapshot) => (
                        <div
                          style={provided.draggableProps.style}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}>
                          <Card className={classes.workspaceCard}>
                            <Grid
                              container
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center">
                              <Grid item>
                                <DragIndicatorIcon />
                                <Typography
                                  className={classes.workspaceCardName}>
                                  {equipment.name}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <FormGroup className={classes.formGroup}>
                                  <Tooltip
                                    title={
                                      equipment.isActive === true
                                        ? 'Uncheck to make inactive'
                                        : 'Check to make active'
                                    }>
                                    <Checkbox
                                      checked={equipment.isActive === true}
                                      onChange={() =>
                                        handleItemStatusToggle(
                                          'equipment',
                                          equipment
                                        )
                                      }
                                      color="primary"
                                    />
                                  </Tooltip>
                                </FormGroup>
                                <IconButton
                                  size="small"
                                  onClick={() => {
                                    setSelectedEquipment(equipment);
                                    setIsEditEquipmentDialogOpen(true);
                                  }}>
                                  <EditIcon />
                                </IconButton>
                                <IconButton
                                  size="small"
                                  onClick={() => {
                                    setSelectedEquipment(equipment);
                                    setIsConfirmEquipmentDeleteDialogOpen(true);
                                  }}>
                                  <DeleteIcon />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </Card>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Card>
        <br />
        {workSpaceActivities.length > 0 && (
          <Card className={classes.card}>
            <Typography className={classes.cardLabel}>Activities</Typography>
            <StyledNotice
              type="warning"
              message={
                'This AHA Contains Activities Requiring a Competent or Qualified Person. Proof of Competency is Required.'
              }
            />
            {workSpaceActivities.map((activity, index) => (
              <Card className={classes.workspaceCard} key={index}>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Typography className={classes.competencyName}>
                      {activity.name}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <>
                      <IconButton
                        size="small"
                        onClick={() => onEditActivityClick(activity)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        size="small"
                        onClick={() => {
                          setSelectedActivity(activity);
                          setIsConfirmActivityDeleteDialogOpen(true);
                        }}>
                        <DeleteIcon />
                      </IconButton>
                    </>
                  </Grid>
                </Grid>
              </Card>
            ))}
          </Card>
        )}
      </div>
      <EditStep
        isOpen={isEditStepDialogOpen}
        setIsOpen={setIsEditStepDialogOpen}
        stepToEdit={selectedStep}
        isGlobal={ahaTemplate?.ahaProjectTemplate ? false : true}
        setAhaTemplate={setAhaTemplate}
        ahaTemplate={ahaTemplate}
      />
      <EditEquipment
        isOpen={isEditEquipmentDialogOpen}
        setIsOpen={setIsEditEquipmentDialogOpen}
        equipmentToEdit={selectedEquipment}
        setEquipmentToEdit={setSelectedEquipment}
        isGlobal={ahaTemplate?.ahaProjectTemplate ? false : true}
        setAhaTemplate={setAhaTemplate}
        ahaTemplate={ahaTemplate}
      />
      <UpsertActivity
        isOpen={isUpsertActivityDialogOpen}
        setIsOpen={setIsUpsertActivityDialogOpen}
        isGlobal={ahaTemplate?.ahaProjectTemplate ? false : true}
        refetchCurrentQueries={() => {}}
        activityToEdit={selectedActivity}
        setActivityToEdit={setSelectedActivity}
        setAhaTemplate={setAhaTemplate}
        ahaTemplate={ahaTemplate}
      />
      <StyledDeleteConfirmation
        title={'Delete Step'}
        shouldShowConfirmToggle={false}
        dialogIsOpen={isConfirmStepDeleteDialogOpen}
        isLoading={false}
        handleClose={handleCloseConfirmStepDelete}
        type="notice"
        warningMessage={t('ahaSummaryWorkspace.delete.step', {
          name: selectedStep?.name
        })}
        isConfirmed={isDeleteConfirmed}
        handleChange={handleChange}
        handleSubmit={() => handleDeleteSubmit('step')}
      />
      <StyledDeleteConfirmation
        title={'Delete Equipment'}
        shouldShowConfirmToggle={false}
        dialogIsOpen={isConfirmEquipmentDeleteDialogOpen}
        isLoading={false}
        handleClose={handleCloseConfirmEquipmentDelete}
        type="notice"
        warningMessage={t('ahaSummaryWorkspace.delete.equipment', {
          name: selectedEquipment?.name
        })}
        isConfirmed={isDeleteConfirmed}
        handleChange={handleChange}
        handleSubmit={() => handleDeleteSubmit('equipment')}
      />
      <StyledDeleteConfirmation
        title={'Delete Activity'}
        shouldShowConfirmToggle={false}
        dialogIsOpen={isConfirmActivityDeleteDialogOpen}
        isLoading={false}
        handleClose={handleCloseConfirmActivityDelete}
        type="notice"
        warningMessage={t('ahaSummaryWorkspace.delete.activity', {
          name: selectedActivity?.name
        })}
        isConfirmed={isDeleteConfirmed}
        handleChange={handleChange}
        handleSubmit={() => handleDeleteSubmit('activity')}
      />
      <StyledDiscardChangesDialog
        discardChangesDialogIsOpen={discardChangesDialogIsOpen}
        setDiscardChangesDialogIsOpen={setDiscardChangesDialogIsOpen}
        handleDiscard={handleDiscard}
        shouldBeDisabled={!isDiscardChangesConfirmed}
        content={
          <Grid container>
            <Grid item xs={12}>
              <StyledNotice
                message={
                  'You are discarding unsaved changes. Once discarded, this action cannot be undone.'
                }
              />
            </Grid>
            <Grid item xs={12}>
              <StyledConfirmationSwitch
                disabled={isLoading}
                checked={isDiscardChangesConfirmed}
                onChange={handleDiscardChanges}
                value={'isDiscardChangesConfirmed'}
              />
            </Grid>
          </Grid>
        }
      />
      <HighRiskConfirmationDialog
        isOpen={isHighRiskConfirmationDialogRendered}
        setIsOpen={renderHighRiskConfirmationDialog}
        handleConfirm={() => {
          handleSaveForType(selectedSaveType);
        }}
        isLoading={isLoading}
      />
      <AhaPrintDisclaimerDialog
        isOpen={printDisclaimerIsOpen}
        setIsOpen={setPrintDisclaimerIsOpen}
        ahaTemplate={ahaTemplate}
        isGlobal={isGlobal}
      />
      <div style={{ display: 'none' }}>
        {!isGlobal && (
          <AhaPrintableDocument
            ref={componentRef}
            ahaTemplate={ahaTemplate}
            isGlobal={isGlobal}
          />
        )}
      </div>
      {hasChanges && renderUnsavedChangesConfirmationDialog()}
    </>
  );
};

SummaryWorkspace.propTypes = {
  isGlobal: PropTypes.bool,
  hasChanges: PropTypes.bool.isRequired,
  ahaTemplate: PropTypes.object,
  originalAhaTemplate: PropTypes.object,
  setAhaTemplate: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  handleSaveAhaTemplate: PropTypes.func.isRequired,
  resetAhaTemplateChanges: PropTypes.func.isRequired
};

export default SummaryWorkspace;
