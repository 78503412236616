import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import FlagIcon from '@mui/icons-material/Flag';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CallIcon from '@mui/icons-material/Call';
import MailIcon from '@mui/icons-material/Mail';
import ErrorIcon from '@mui/icons-material/Error';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography, Avatar } from '@mui/material';

import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import { formatDateOfBirth } from 'utils';
import {
  formatPhoneNumber,
  formatInputAndInternationalPhoneNumber
} from 'utils/formatPhoneNumber';

const useStyles = makeStyles(theme => ({
  toImprove: { color: theme.palette.flag.toImprove },
  resolved: { color: theme.palette.flag.resolved },
  achievement: { color: theme.palette.flag.achievement },
  attentionRequired: { color: theme.palette.flag.attentionRequired },
  container: {
    borderRadius: 4,
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.background.default
  },
  nameText: {
    fontWeight: 'bold',
    maxWidth: 370,
    [theme.breakpoints.down('xl')]: { maxWidth: 280 },
    [theme.breakpoints.down('lg')]: { maxWidth: 250 },
    [theme.breakpoints.down('md')]: { maxWidth: 200 }
  },
  dobText: { fontSize: '0.875rem' },
  notesContainer: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
    borderRadius: 4
  },
  headingText: {
    fontSize: '1.25rem',
    margin: theme.spacing(2, 0, 1, 0),
    fontWeight: 'bold'
  }
}));

const PersonnelMatchDetails = ({ personnelDetails, handleBack, isLoading }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const getFlagStatus = status => {
    if (status === 'ToImprove') {
      return t('flagCard.status.toImprove');
    } else if (status === 'High') {
      return t('flagCard.status.attentionRequired');
    }
    return status;
  };

  const personnelPhoneNumber =
    personnelDetails.contactInformation?.primaryPhone?.number;
  const personnelInputPhoneNumber = formatPhoneNumber(personnelPhoneNumber);
  const personnelInternationalPhoneNumber = formatInputAndInternationalPhoneNumber(
    personnelPhoneNumber
  )?.international;
  const validPersonnelPhoneNumber = personnelInternationalPhoneNumber
    ? personnelInternationalPhoneNumber
    : personnelInputPhoneNumber;

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <StyledButtonPrimary
          variant="outlined"
          startIcon={<ArrowBackIcon />}
          label={t('addProjectPersonnelDialog.actions.backToMatchesButton')}
          onClick={handleBack}
          disabled={isLoading}
        />
      </Grid>
      <Grid item>
        <Typography className={classes.headingText}>
          {t('addProjectPersonnelDialog.detailsView.selectedMatch')}
        </Typography>
        <Grid
          container
          direction="row"
          alignItems="center"
          spacing={1}
          className={classes.container}>
          <Grid item>
            {personnelDetails.profileImage?.cachedUrl && (
              <Avatar
                className={classes.avatar}
                src={personnelDetails.profileImage.cachedUrl}></Avatar>
            )}
            {!personnelDetails.profileImage?.cachedUrl &&
              !personnelDetails.profileImage?.isMalware && (
                <Avatar className={classes.avatar}></Avatar>
              )}
            {!personnelDetails.profileImage?.cachedUrl &&
              personnelDetails.profileImage?.isMalware && (
                <Avatar className={classes.avatar}>
                  <ErrorIcon
                    className={classes.avatar}
                    data-testid="malware-icon"
                  />
                </Avatar>
              )}
          </Grid>
          <Grid item>
            {personnelDetails.nickname && (
              <Typography className={classes.nameText}>
                {personnelDetails.fullName} ({personnelDetails.nickname})
              </Typography>
            )}
            {!personnelDetails.nickname && (
              <Typography className={classes.nameText}>
                {personnelDetails.fullName}
              </Typography>
            )}
            <Typography className={classes.dobText}>
              {t('verifyMatchesStep.dob.label')}{' '}
              {formatDateOfBirth(personnelDetails.dob)}
            </Typography>
          </Grid>
        </Grid>
        <Typography className={classes.headingText}>
          {t('addProjectPersonnelDialog.detailsView.details')}
        </Typography>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Typography>
              {t('personnelInfoCard.contactInformation.title')}
            </Typography>
          </Grid>
          {personnelDetails.contactInformation?.emailAddress?.email && (
            <Grid item>
              <Grid container direction="row" spacing={1} alignItems="center">
                <Grid item>
                  <MailIcon color="action" />
                </Grid>
                <Grid item>
                  <Typography color="textSecondary" className="inline">
                    {personnelDetails.contactInformation.emailAddress.email}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
          {personnelDetails.contactInformation?.primaryPhone?.number && (
            <Grid item>
              <Grid container direction="row" spacing={1} alignItems="center">
                <Grid item>
                  <CallIcon color="action" />
                </Grid>
                <Grid item>
                  <Typography
                    component="span"
                    color="textSecondary"
                    className="inline">
                    {validPersonnelPhoneNumber}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item>
            <Grid container direction="column">
              <Grid item>
                <Typography>{t('personnelInfoCard.notes.title')}</Typography>
              </Grid>
              <Grid item className={classes.notesContainer}>
                <Typography>
                  {personnelDetails?.notes
                    ? personnelDetails.notes
                    : t('personnelInfoCard.notes.noNotesDisclaimer')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {personnelDetails.tradePartnerHistory?.length > 0 && (
        <Grid item>
          <Typography className={classes.headingText}>
            {t('addProjectPersonnelDialog.detailsView.projects')}
          </Typography>
          <Grid container direction="row">
            {personnelDetails.tradePartnerHistory.map(
              ({ tradePartner: { id, project } }) => (
                <Grid
                  container
                  key={`${project.id} - ${id}`}
                  direction="column"
                  spacing={1}
                  className={classes.container}>
                  <Grid item>
                    <Typography>
                      {project.name} | #{project.number} |{' '}
                      {t('addProjectPersonnelDialog.detailsView.district')}{' '}
                      {project.districtNumber}
                    </Typography>
                  </Grid>
                </Grid>
              )
            )}
          </Grid>
        </Grid>
      )}
      {personnelDetails.flags?.length > 0 && (
        <Grid item>
          <Typography className={classes.headingText}>
            {t('addProjectPersonnelDialog.detailsView.flags')}
          </Typography>
          <>
            {personnelDetails.flags.map(flag => (
              <Grid container key={flag.id} direction="column" spacing={1}>
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    className={classes.container}>
                    <Grid item>
                      <FlagIcon
                        className={classnames(
                          {
                            [`${classes.toImprove}`]:
                              flag.status === 'ToImprove'
                          },
                          {
                            [`${classes.resolved}`]: flag.status === 'Resolved'
                          },
                          {
                            [`${classes.achievement}`]:
                              flag.status === 'Achievement'
                          },
                          {
                            [`${classes.attentionRequired}`]:
                              flag.status === 'High'
                          }
                        )}
                      />
                    </Grid>
                    <Grid item>
                      <Typography
                        className={classnames(
                          {
                            [`${classes.toImprove}`]:
                              flag.status === 'ToImprove'
                          },
                          {
                            [`${classes.resolved}`]: flag.status === 'Resolved'
                          },
                          {
                            [`${classes.achievement}`]:
                              flag.status === 'Achievement'
                          },
                          {
                            [`${classes.attentionRequired}`]:
                              flag.status === 'High'
                          }
                        )}>
                        {getFlagStatus(flag.status)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>{t('flagCard.reason.title')}</Typography>
                      <Typography variant="subtitle2">{flag.reason}</Typography>
                    </Grid>
                    {flag.resolution && (
                      <Grid item xs={12}>
                        <Typography>
                          {t('flagCard.resolution.title')}
                        </Typography>
                        <Typography variant="subtitle2">
                          {flag.resolution}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </>
        </Grid>
      )}
    </Grid>
  );
};

PersonnelMatchDetails.propTypes = {
  personnelDetails: PropTypes.object.isRequired,
  handleBack: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default PersonnelMatchDetails;
