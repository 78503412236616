import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useForm, useField } from 'react-final-form-hooks';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/CheckCircle';
import ClearIcon from '@mui/icons-material/Clear';
import makeStyles from '@mui/styles/makeStyles';
import {
  Grid,
  Card,
  Typography,
  Link,
  LinearProgress,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@mui/material';

import useToast from 'hooks/useToast';
import StyledInput from 'shared/Input';
import StyledButtonGroup from 'shared/Buttons/ButtonGroup';
import StyledButtonMuted from 'shared/Buttons/ButtonMuted';
import { formatDate } from 'utils/dateTime';
import { UPDATE_TRADE_PARTNER_PERSONNEL } from 'graphql/tradePartnerPersonnel';
import TradePartnerSelect from 'components/tradepartners/TradePartnerSelect';

const useStyles = makeStyles(theme => ({
  titleText: { fontWeight: 'bold' },
  card: { padding: theme.spacing(3) },
  notesContainer: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
    borderRadius: 4
  },
  link: { '&:hover': { cursor: 'pointer' } },
  labelText: { fontSize: '0.75rem', fontWeight: 'bold' },
  icon: { fontSize: '1rem', paddingTop: 2 },
  active: { color: theme.palette.success.main },
  inputContainer: { marginBottom: theme.spacing(1) }
}));

const TradePartnerPersonnelProjectInfoCard = ({
  tradePartnerPersonnel,
  isHenselPhelpsEmployee
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const { projectId } = useParams();
  const { displayToast } = useToast();
  const [isEditing, toggleEditing] = useState(false);
  const isImported = tradePartnerPersonnel?.personnel?.isImported;

  const [updateTradePartnerPersonnel, { loading: isLoading }] = useMutation(
    UPDATE_TRADE_PARTNER_PERSONNEL
  );

  const { form, values, pristine } = useForm({
    /* istanbul ignore next */
    onSubmit: () => {}, // this function required for useForm but is not used
    initialValues: {
      customId: tradePartnerPersonnel.customId ?? '',
      isSupervisor: tradePartnerPersonnel.isSupervisor,
      isPermitApprover: tradePartnerPersonnel.isPermitApprover,
      notes: tradePartnerPersonnel.notes ?? '',
      tradePartner: tradePartnerPersonnel.tradePartner
    }
  });

  const getMostRecentOrientation = () => {
    if (tradePartnerPersonnel?.orientationHistory?.length >= 1) {
      // filter out any future orientations
      const pastOrientations = tradePartnerPersonnel.orientationHistory.filter(
        orientation =>
          Date.parse(orientation.startDateTime) < new Date() &&
          !orientation.isCancelled
      );

      // get most recent orientation
      if (pastOrientations.length > 1) {
        const sortedOrientationDates = pastOrientations
          .slice()
          .sort(
            (a, b) => Date.parse(b.startDateTime) - Date.parse(a.startDateTime)
          );
        return sortedOrientationDates[0];
      } else if (pastOrientations.length === 1) {
        return pastOrientations[0];
      } else {
        return null;
      }
    }
    return null;
  };

  const orientationToDisplay = getMostRecentOrientation();

  const orientationDateToDisplay = () => {
    const startDateTime = orientationToDisplay?.startDateTime
      ? new Date(orientationToDisplay.startDateTime)
      : null;
    const formattedStartDate = startDateTime ? formatDate(startDateTime) : null;
    const formattedStartTime = startDateTime
      ? moment(orientationToDisplay.startDateTime).format('h:mm a')
      : null;
    const formattedEndDate = orientationToDisplay?.endDateTime
      ? formatDate(new Date(orientationToDisplay.endDateTime))
      : null;

    const formattedEndTime = orientationToDisplay?.endDateTime
      ? moment(orientationToDisplay.endDateTime).format('h:mm a')
      : null;

    const isMultiDay = formattedStartDate !== formattedEndDate;

    if (isMultiDay) {
      return `${formattedStartDate} - ${formattedEndDate} ${formattedStartTime}`;
    } else {
      return `${formattedStartDate} ${formattedStartTime} - ${formattedEndTime}`;
    }
  };

  const customId = useField('customId', form);
  const isSupervisor = useField('isSupervisor', form);
  const isPermitApprover = useField('isPermitApprover', form);
  const notes = useField('notes', form);
  const tradePartnerId = useField('tradePartner', form);

  const canSubmit = (() => {
    const { errors, invalid } = form.getState();
    return !(
      pristine ||
      errors.customId ||
      errors.isSupervisor ||
      errors.isPermitApprover ||
      errors.tradePartnerId ||
      errors.notes ||
      invalid ||
      isLoading
    );
  })();

  const handleClose = () => {
    form.reset();
    toggleEditing(false);
  };

  const onSubmit = () => {
    updateTradePartnerPersonnel({
      variables: {
        input: {
          id: tradePartnerPersonnel.id,
          customId:
            values.customId && values.customId !== '' ? values.customId : null,
          isSupervisor: values.isSupervisor,
          isPermitApprover: values.isPermitApprover,
          notes: values.notes,
          tradePartnerId: values.tradePartner.id
        }
      }
    })
      .then(
        ({ data: { updateTradePartnerPersonnel: tradePartnerPersonnel } }) => {
          displayToast(
            t(
              'tradePartnerPersonnelProjectInfoCard.toasts.updateNotes.success',
              {
                tradePartnerProject: tradePartnerPersonnel.tradePartner.name
              }
            ),
            'success'
          );
          handleClose();
        }
      )
      .catch(error => {
        console.error('Update Project Info Error: ', error);
        displayToast(
          t('tradePartnerPersonnelProjectInfoCard.toasts.updateNotes.error'),
          'error'
        );
      });
  };

  return (
    <form>
      <Grid container direction="column" spacing={3}>
        <Grid item xs={12}>
          {isLoading && <LinearProgress color="primary" />}
          <Card className={classes.card}>
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Typography gutterBottom className={classes.titleText}>
                      {t('tradePartnerPersonnelProjectInfoCard.title')}
                    </Typography>
                  </Grid>
                  {tradePartnerPersonnel.isActive && (
                    <Grid item>
                      {isEditing && (
                        <Typography color="primary">
                          {t(
                            'tradePartnerPersonnelProjectInfoCard.editMode.title'
                          )}
                        </Typography>
                      )}
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="column">
                  <Grid item>
                    <Typography>
                      {t(
                        'tradePartnerPersonnelProjectInfoCard.field.tradePartner'
                      )}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Link
                      data-testid="trade-partner-link"
                      className={classes.link}
                      underline="always"
                      href=""
                      aria-label={`view trade partner ${tradePartnerPersonnel.tradePartner.name}`}
                      tabIndex="0"
                      onKeyDown={() =>
                        history.push(
                          `/projects/${projectId}/trade-partners/${tradePartnerPersonnel.tradePartner.id}`
                        )
                      }
                      onClick={() =>
                        history.push(
                          `/projects/${projectId}/trade-partners/${tradePartnerPersonnel.tradePartner.id}`
                        )
                      }>
                      <Typography gutterBottom>
                        {tradePartnerPersonnel.tradePartner.name}
                      </Typography>
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
              {tradePartnerPersonnel.customId && (
                <Grid item>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography>
                        {t(
                          'tradePartnerPersonnelProjectInfoCard.field.customId.title'
                        )}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography color="textSecondary" gutterBottom>
                        {tradePartnerPersonnel.customId}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              <Grid item>
                <Grid container direction="column">
                  <Grid item>
                    <Typography>
                      {t(
                        'tradePartnerPersonnelProjectInfoCard.field.orientation.title'
                      )}
                    </Typography>
                  </Grid>
                  {orientationToDisplay && (
                    <Grid item>
                      <Grid container direction="column">
                        <Grid item>
                          <Typography color="textSecondary" gutterBottom>
                            {t(
                              'tradePartnerPersonnelProjectInfoCard.field.orientation.attendeeDate',
                              {
                                date: orientationDateToDisplay()
                              }
                            )}
                          </Typography>
                        </Grid>
                        <Grid item>
                          {orientationToDisplay.facilitators?.length > 1 && (
                            <Typography color="textSecondary" gutterBottom>
                              {t(
                                'tradePartnerPersonnelProjectInfoCard.field.orientation.facilitators',
                                {
                                  names: orientationToDisplay?.facilitators
                                    ?.map(facilitator => {
                                      return facilitator.name;
                                    })
                                    .join('; ')
                                }
                              )}
                            </Typography>
                          )}
                          {orientationToDisplay.facilitators?.length === 1 && (
                            <Typography color="textSecondary" gutterBottom>
                              {t(
                                'tradePartnerPersonnelProjectInfoCard.field.orientation.facilitator',
                                {
                                  names:
                                    orientationToDisplay.facilitators[0].name
                                }
                              )}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item>
                          <Link
                            data-testid="orientation-link"
                            className={classes.link}
                            underline="always"
                            href=""
                            aria-label={'view attended orientation details'}
                            tabIndex="0"
                            onKeyDown={() =>
                              history.push(
                                `/projects/${projectId}/orientations/${orientationToDisplay.id}`
                              )
                            }
                            onClick={() =>
                              history.push(
                                `/projects/${projectId}/orientations/${orientationToDisplay.id}`
                              )
                            }>
                            <Typography gutterBottom>
                              {t(
                                'tradePartnerPersonnelProjectInfoCard.field.orientation.viewOrientation'
                              )}
                            </Typography>
                          </Link>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  {!orientationToDisplay && !isImported && (
                    <Grid item>
                      <Typography color="textSecondary" gutterBottom>
                        {t(
                          'tradePartnerPersonnelProjectInfoCard.field.orientation.notAttended'
                        )}
                      </Typography>
                    </Grid>
                  )}
                  {!orientationToDisplay && isImported && (
                    <>
                      <Typography color="textSecondary">
                        {t(
                          'tradePartnerPersonnelProjectInfoCard.field.orientation.importedRecord.title'
                        )}
                      </Typography>
                      <Typography color="textSecondary">
                        {t(
                          'tradePartnerPersonnelProjectInfoCard.field.orientation.notAttended'
                        )}
                      </Typography>
                      <Typography color="textSecondary">
                        {t(
                          'tradePartnerPersonnelProjectInfoCard.field.orientation.importedRecord.status'
                        )}
                      </Typography>
                      <Typography color="textSecondary">
                        {t(
                          'tradePartnerPersonnelProjectInfoCard.field.orientation.unknownAttendedDate'
                        )}
                      </Typography>
                    </>
                  )}
                </Grid>
              </Grid>
              <Grid item>
                {!isEditing && (
                  <>
                    <Grid
                      container
                      direction="column"
                      className="margin-bottom">
                      <Grid item>
                        <Typography>
                          {t(
                            'tradePartnerPersonnelProjectInfoCard.field.isSupervisor.title'
                          )}
                        </Typography>
                      </Grid>
                      <Grid item>
                        {tradePartnerPersonnel.isSupervisor && (
                          <Grid
                            container
                            direction="row"
                            spacing={1}
                            className={classes.active}>
                            <Grid item>
                              <CheckIcon classes={{ root: classes.icon }} />
                            </Grid>
                            <Grid item>
                              <Typography color="textSecondary">
                                {t(
                                  'tradePartnerPersonnelProjectInfoCard.field.isSupervisor.yes'
                                )}
                              </Typography>
                            </Grid>
                          </Grid>
                        )}
                        {!tradePartnerPersonnel.isSupervisor && (
                          <Grid container direction="row" spacing={1}>
                            <Grid item>
                              <ClearIcon classes={{ root: classes.icon }} />
                            </Grid>
                            <Grid item>
                              <Typography color="textSecondary">
                                {t(
                                  'tradePartnerPersonnelProjectInfoCard.field.isSupervisor.no'
                                )}
                              </Typography>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    {isHenselPhelpsEmployee && (
                      <Grid
                        container
                        direction="column"
                        className="margin-bottom">
                        <Grid item>
                          <Typography>
                            {t(
                              'tradePartnerPersonnelProjectInfoCard.field.isPermitApprover.title'
                            )}
                          </Typography>
                        </Grid>
                        <Grid item>
                          {tradePartnerPersonnel.isPermitApprover && (
                            <Grid
                              container
                              direction="row"
                              spacing={1}
                              className={classes.active}>
                              <Grid item>
                                <CheckIcon classes={{ root: classes.icon }} />
                              </Grid>
                              <Grid item>
                                <Typography color="textSecondary">
                                  {t(
                                    'tradePartnerPersonnelProjectInfoCard.field.isPermitApprover.yes'
                                  )}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                          {!tradePartnerPersonnel.isPermitApprover && (
                            <Grid container direction="row" spacing={1}>
                              <Grid item>
                                <ClearIcon classes={{ root: classes.icon }} />
                              </Grid>
                              <Grid item>
                                <Typography color="textSecondary">
                                  {t(
                                    'tradePartnerPersonnelProjectInfoCard.field.isPermitApprover.no'
                                  )}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Grid container direction="column">
                        <Grid item>
                          <Typography>
                            {t(
                              'tradePartnerPersonnelProjectInfoCard.field.notes.title'
                            )}
                          </Typography>
                        </Grid>
                        <Grid item className={classes.notesContainer}>
                          <Typography>
                            {tradePartnerPersonnel?.notes
                              ? tradePartnerPersonnel.notes
                              : t(
                                  'tradePartnerPersonnelProjectInfoCard.field.notes.noNotesDisclaimer'
                                )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item style={{ marginTop: 8 }}>
                      <Grid container justifyContent="flex-end">
                        <Grid item>
                          <StyledButtonMuted
                            startIcon={<EditIcon color="action" />}
                            label={t(
                              'tradePartnerPersonnelProjectInfoCard.field.notes.editButton.title'
                            )}
                            onClick={() => toggleEditing(true)}
                            disabled={isLoading}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}
                {isEditing && (
                  <>
                    <Grid item xs={12} className={classes.inputContainer}>
                      <TradePartnerSelect
                        selectedTradePartner={tradePartnerId.input.value}
                        meta={tradePartnerId.meta}
                        handleChange={tradePartnerId.input.onChange}
                        label={t(
                          'tradePartnerPersonnelProjectInfoCard.field.tradePartner'
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} className={classes.inputContainer}>
                      <StyledInput
                        multiline={true}
                        disabled={isLoading}
                        input={customId.input}
                        meta={customId.meta}
                        label={t(
                          'tradePartnerPersonnelProjectInfoCard.field.customId.title'
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} className={classes.inputContainer}>
                      <StyledInput
                        multiline={true}
                        disabled={isLoading}
                        input={notes.input}
                        meta={notes.meta}
                        label={t(
                          'tradePartnerPersonnelProjectInfoCard.field.notes.title'
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} className={classes.inputContainer}>
                      <Typography
                        color="textSecondary"
                        className={classes.labelText}>
                        {t('personnelFormFields.supervisorLabel')}
                      </Typography>
                      <FormControl variant="standard" component="fieldset">
                        <RadioGroup
                          name="isSupervisor"
                          value={isSupervisor.input.value}
                          onChange={() =>
                            isSupervisor.input.onChange(
                              !isSupervisor.input.value
                            )
                          }>
                          <FormControlLabel
                            value={true}
                            control={<Radio color="primary" />}
                            label={t('personnelFormFields.isSupervisor')}
                          />
                          <FormControlLabel
                            value={false}
                            control={<Radio color="primary" />}
                            label={t('personnelFormFields.notIsSupervisor')}
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    {isHenselPhelpsEmployee && (
                      <Grid item xs={12} className={classes.inputContainer}>
                        <Typography
                          color="textSecondary"
                          className={classes.labelText}>
                          {t('personnelFormFields.permitApproverLabel')}
                        </Typography>
                        <FormControl variant="standard" component="fieldset">
                          <RadioGroup
                            name="isPermitApprover"
                            value={isPermitApprover.input.value}
                            onChange={() =>
                              isPermitApprover.input.onChange(
                                !isPermitApprover.input.value
                              )
                            }>
                            <FormControlLabel
                              value={true}
                              control={<Radio color="primary" />}
                              label={t('personnelFormFields.isPermitApprover')}
                            />
                            <FormControlLabel
                              value={false}
                              control={<Radio color="primary" />}
                              label={t(
                                'personnelFormFields.notIsPermitApprover'
                              )}
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    )}
                  </>
                )}
              </Grid>
              {isEditing && (
                <StyledButtonGroup
                  onClickAltAction={handleClose}
                  labelAltAction={t(
                    'tradePartnerPersonnelProjectInfoCard.actions.cancel'
                  )}
                  disabledAltAction={isLoading}
                  onClickMainAction={onSubmit}
                  labelMainAction={t(
                    'tradePartnerPersonnelProjectInfoCard.actions.submit'
                  )}
                  disabledMainAction={!canSubmit}
                />
              )}
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </form>
  );
};

TradePartnerPersonnelProjectInfoCard.propTypes = {
  tradePartnerPersonnel: PropTypes.object
};

export default TradePartnerPersonnelProjectInfoCard;
