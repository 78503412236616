import { HP_LIGHT_THEME, HP_DARK_THEME } from 'constants/themes';
import { DARK_THEME } from 'constants/settings';
import React from 'react';
import PropTypes from 'prop-types';

import useSettings from 'hooks/useSettings';
import { StyledEngineProvider } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

const AppThemeProvider = ({ children }) => {
  const { settings } = useSettings();
  const currentTheme =
    settings?.theme === DARK_THEME ? HP_DARK_THEME : HP_LIGHT_THEME;
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={currentTheme}>
        <CssBaseline />
        <div>{children}</div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

AppThemeProvider.propTypes = {
  children: PropTypes.node
};

export default AppThemeProvider;
