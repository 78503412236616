import { GET_AHA_TEMPLATE } from 'graphql/aha';
import useSoteriaQuery from 'hooks/useSoteriaQuery';

const useAhaTemplate = (ahaId, shouldSkip = false) => {
  const { data: { ahaTemplate = null } = {}, loading, error } = useSoteriaQuery(
    {
      gql: GET_AHA_TEMPLATE,
      queryHookOptions: {
        skip: shouldSkip || !ahaId,
        variables: { id: ahaId }
      }
    }
  );

  return { ahaTemplate, loading, error };
};

export default useAhaTemplate;
