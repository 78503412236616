import gql from 'graphql-tag';

// Queries
export const GET_SOTERIA_USER = gql`
  query GetSoteriaUser {
    currentSoteriaAdUser {
      id
      upn
      name
      firstName
      lastName
      email
      jobTitle
      projectAccessRequests {
        projectId
        projectName
      }
      projects {
        id
        name
        number
        description
        districtNumber
        isActive
        notes
      }
      safeProjects {
        id
        name
      }
      safeProjectsAsPartner {
        id
        name
      }
    }
  }
`;

// Mutations
export const REQUEST_ACCESS_TO_PROJECT = gql`
  mutation requestAccessToProject(
    $projectId: ID
    $projectNumber: String
    $projectName: String
    $approverUpn: String!
    $requesterUpn: String!
  ) {
    requestAccessToProject(
      projectId: $projectId
      projectNumber: $projectNumber
      projectName: $projectName
      approverUpn: $approverUpn
      requesterUpn: $requesterUpn
    ) {
      id
      upn
      name
      firstName
      lastName
      email
      jobTitle
      projectAccessRequests {
        projectId
        projectName
      }
      projects {
        id
        name
        number
        description
        districtNumber
        isActive
        notes
      }
      safeProjects {
        id
        name
      }
    }
  }
`;

// Subscriptions
export const SOTERIA_USER_SUBSCRIPTION = gql`
  subscription OnSoteriaAdUserUpdated($microsoftAdId: String!) {
    soteriaAdUser(microsoftAdId: $microsoftAdId) {
      id
      upn
      name
      firstName
      lastName
      email
      jobTitle
      projectAccessRequests {
        projectId
        projectName
      }
      projects {
        id
        name
        number
        description
        districtNumber
        isActive
        notes
      }
      safeProjects {
        id
        name
      }
      safeProjectsAsPartner {
        id
        name
      }
    }
  }
`;
