import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import { Typography, Fade, Badge, Grid, Link } from '@mui/material';

const useStyles = makeStyles(theme => ({
  tileImg: {
    height: 150,
    width: 150,
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      height: 100,
      width: 100
    },
    cursor: 'pointer'
  },
  tileImgDisabled: { opacity: 0.3 },
  tileNameText: {
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: { fontSize: '0.875rem' }
  },
  tileNameTextDisabled: { opacity: 0.5 },
  link: { cursor: 'pointer' },
  tileDescriptionText: {
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  textLink: {
    '&:hover': { textDecoration: 'none', cursor: 'pointer' }
  },
  badge: {
    backgroundColor: theme.palette.secondary.main,
    border: '1px solid' + theme.palette.primary.main,
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    zIndex: 0
  },
  customBadge: {}
}));

export const ProjectDashboardTile = ({
  onClick,
  tileImg,
  tileName,
  tileDescription,
  fadeTimeout = 500,
  badgeContent,
  isDisabled = false
}) => {
  const classes = useStyles();

  return (
    <Fade in={true} timeout={fadeTimeout}>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="center">
        <Grid item>
          <Badge
            invisible={!badgeContent}
            badgeContent={badgeContent}
            classes={{
              badge:
                typeof badgeContent === 'object'
                  ? classes.customBadge
                  : classes.badge
            }}>
            <Link
              aria-label={`navigate to ${tileName}`}
              tabIndex="0"
              onKeyDown={isDisabled ? e => e.preventDefault() : onClick}
              onClick={isDisabled ? e => e.preventDefault() : onClick}
              className={classNames(classes.link, {
                [`${classes.linkDisabled}`]: isDisabled
              })}
              underline="hover">
              <img
                className={classNames(classes.tileImg, {
                  [`${classes.tileImgDisabled}`]: isDisabled
                })}
                src={tileImg}
                alt={tileName}
              />
            </Link>
          </Badge>
        </Grid>
        <Grid item>
          <Link
            aria-label={`navigate to ${tileName}`}
            tabIndex="0"
            onKeyDown={isDisabled ? e => e.preventDefault() : onClick}
            onClick={isDisabled ? e => e.preventDefault() : onClick}
            className={classNames(classes.textlink, {
              [`${classes.linkDisabled}`]: isDisabled
            })}
            underline="hover">
            <Typography
              variant="h5"
              component="h2"
              align="center"
              color="textPrimary"
              className={classNames(classes.tileNameText, {
                [`${classes.tileNameTextDisabled}`]: isDisabled
              })}>
              {tileName}
            </Typography>
          </Link>
          <Link
            aria-label={`click to ${tileDescription}`}
            tabIndex="0"
            onKeyDown={isDisabled ? e => e.preventDefault() : onClick}
            onClick={isDisabled ? e => e.preventDefault() : onClick}
            className={classNames(classes.textlink, {
              [`${classes.linkDisabled}`]: isDisabled
            })}
            underline="hover"
            sx={{ display: { xs: 'none', sm: 'block' } }}>
            <Typography
              variant="body2"
              color="textPrimary"
              component="p"
              align="center"
              className={classes.tileDescriptionText}>
              {tileDescription}
            </Typography>
          </Link>
        </Grid>
      </Grid>
    </Fade>
  );
};

ProjectDashboardTile.propTypes = {
  onClick: PropTypes.any.isRequired,
  tileImg: PropTypes.any.isRequired,
  tileName: PropTypes.any.isRequired,
  tileDescription: PropTypes.any.isRequired,
  fadeTimeout: PropTypes.number,
  badgeContent: PropTypes.any,
  isDisabled: PropTypes.bool
};

export default ProjectDashboardTile;
