import {
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  Grid,
  Tooltip,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import LoadingOverlay from 'react-loading-overlay';
import { useHistory, useParams } from 'react-router-dom';
import useHenselPhelpsAhaListState from 'store/henselPhelpsAhaListState';
import { formatDate } from 'utils/dateTime';

const useStyles = makeStyles(theme => ({
  scrollContainer: {
    maxHeight: 'calc(100vh - 360px)',
    overflowX: 'auto',
    [theme.breakpoints.down('xl')]: {
      maxHeight: 'calc(100vh - 450px)'
    },
    [theme.breakpoints.down('lg')]: {
      maxHeight: 'calc(100vh - 400px)'
    }
  },
  noAhasFoundContainer: {
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(2),
    borderRadius: 4
  },
  noAhasFoundMessage: {
    padding: theme.spacing(2),
    color: theme.palette.text.primary
  },
  card: {
    borderLeft: '8px solid' + theme.palette.primary.main,
    borderRadius: 0,
    marginBottom: theme.spacing(2)
  },
  inactiveCard: {
    borderLeft: '8px solid' + theme.palette.aha.inactiveStatus
  },
  bold: { fontWeight: 'bold' },
  limitedTextDesktop: {
    maxWidth: 160,
    whiteSpace: 'noWrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.675rem'
    }
  },
  tpFilesCount: {
    fontWeight: 'bold',
    fontSize: '0.75rem',
    color: theme.palette.primary.main
  },
  type: {
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.875rem'
    }
  },
  fileCount: { fontSize: '0.75rem', fontWeight: 'bold' },
  infiniteScroll: { overflow: 'hidden !important' }
}));

const RowItem = ({ label, content, tooltipTitle }) => {
  const classes = useStyles();

  return (
    <Grid container direction="row" spacing={1}>
      <Grid item>
        <Typography
          color="textSecondary"
          variant="subtitle2"
          className={classnames(classes.bold, classes.limitedTextDesktop)}>
          {label}
        </Typography>
      </Grid>
      <Grid item>
        <Tooltip title={tooltipTitle}>
          <Typography
            variant="subtitle2"
            className={classes.limitedTextDesktop}>
            {content}
          </Typography>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

RowItem.propTypes = {
  label: PropTypes.any,
  content: PropTypes.any,
  tooltipTitle: PropTypes.any
};

const HenselPhelpsAhaTabContent = ({
  projectTemplates,
  fetchMoreHenselPhelpsAhas,
  isLoadingPaginatedProjectTemplates,
  henselPhelpsAhasTotalCount
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const { projectId } = useParams();
  const history = useHistory();

  const [
    henselPhelpsAhaListState,
    { handleHenselPhelpsAhaListStateChange }
  ] = useHenselPhelpsAhaListState();

  const handleLoadNextPage = () => {
    const skip = henselPhelpsAhaListState.skip + henselPhelpsAhaListState.first;
    handleHenselPhelpsAhaListStateChange({
      ...henselPhelpsAhaListState,
      skip
    });

    const variables = {
      first: henselPhelpsAhaListState.first,
      search: henselPhelpsAhaListState.search,
      skip
    };

    fetchMoreHenselPhelpsAhas(variables);
  };

  const ahasList = projectTemplates?.length ?? [];

  const handleCardClick = projectTemplate => {
    history.push(
      `/projects/${projectId}/ahas/hensel-phelps-aha/${projectTemplate?.ahaTemplate?.id}`
    );
  };

  const getLatestReviewStatus = projectTemplate => {
    let statusOfLatestReview;
    const reviewList = projectTemplate?.ahaReviews;

    if (reviewList?.length === 1) {
      statusOfLatestReview = reviewList.map(review => review.status);
    }

    if (reviewList?.length > 1) {
      // eslint-disable-next-line array-callback-return
      reviewList?.reduce((a, b) => {
        const latestModifiedReviewObject =
          new Date(a?.lastModified) > new Date(b?.lastModified) ? a : b;
        statusOfLatestReview = latestModifiedReviewObject?.status;
      });
    }

    if (statusOfLatestReview.toString() === 'Reviewed') {
      return 'Accepted';
    } else {
      return statusOfLatestReview.toString();
    }
  };

  const getLastModifiedDateOfLatestUploadedFile = projectTemplate => {
    let lastModifiedDate;
    const filesList = projectTemplate?.ahaFiles;

    if (filesList?.length > 0) {
      // eslint-disable-next-line array-callback-return
      filesList?.reduce((a, b) => {
        const latestModifiedFileObject =
          new Date(a?.lastModified) > new Date(b?.lastModified) ? a : b;
        lastModifiedDate = latestModifiedFileObject?.lastModified ?? null;
      });
    }
    return lastModifiedDate;
  };

  const getFileCount = projectTemplate => {
    const count = projectTemplate?.ahaFiles?.length;
    let countLabel;

    if (count === 1) {
      countLabel = 'File';
    }

    if (count === 0 || count > 1) {
      countLabel = 'Files';
    }

    return count + ' ' + countLabel;
  };

  return <>
    <LoadingOverlay
      active={isLoadingPaginatedProjectTemplates}
      spinner
      fadeSpeed={50}
      styles={{
        overlay: base => ({
          ...base,
          background: theme.palette.background.overlay
        }),
        spinner: base => ({
          ...base,
          width: '50px',
          '& svg circle': {
            stroke: theme.palette.primary.main
          }
        })
      }}>
      <div
        id={'hensel-phelps-ahas-tab-scroll-container'}
        className={classes.scrollContainer}>
        <InfiniteScroll
          scrollThreshold={0.9}
          className={classes.infiniteScroll}
          scrollableTarget={'hensel-phelps-ahas-tab-scroll-container'}
          dataLength={ahasList.length}
          next={handleLoadNextPage}
          hasMore={projectTemplates?.length < henselPhelpsAhasTotalCount}
          loader={
            <Grid container justifyContent="center" className={classes.padding}>
              <Grid item>
                <CircularProgress color="primary" />
              </Grid>
            </Grid>
          }
          endMessage={
            <Grid container justifyContent="center" className={classes.padding}>
              <Grid item>
                <Typography
                  variant="overline"
                  color="textPrimary"
                  className={classes.endMessage}>
                  {t('observationCardsList.endOfListMessage')}
                </Typography>
              </Grid>
            </Grid>
          }>
          <Grid container>
            {projectTemplates?.length > 0 && (
              <>
                {projectTemplates?.map((projectTemplate, index) => (
                  <Fragment key={projectTemplate.id ?? index}>
                    <Grid item xs={12}>
                      <Card
                        className={classnames(classes.card, {
                          [`${classes.inactiveCard}`]:
                            projectTemplate?.status === false
                        })}>
                        <CardActionArea
                          onClick={() => handleCardClick(projectTemplate)}>
                          <CardContent style={{ padding: 8 }}>
                            <Grid container justifyContent="space-between">
                              <Grid item xs={12}>
                                <Grid
                                  container
                                  justifyContent="space-between"
                                  alignItems="center">
                                  <Grid item>
                                    <Typography className={classes.type}>
                                      {projectTemplate?.ahaTemplate?.type}
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    {projectTemplate?.ahaFiles?.length >
                                      0 && (
                                      <Tooltip
                                        title={
                                          'Last file uploaded on' +
                                          ' ' +
                                          formatDate(
                                            getLastModifiedDateOfLatestUploadedFile(
                                              projectTemplate
                                            )
                                          )
                                        }>
                                        <Typography
                                          className={classes.fileCount}
                                          color="primary">
                                          {getFileCount(projectTemplate)}
                                        </Typography>
                                      </Tooltip>
                                    )}
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={12} sm={12} md={3} lg={3}>
                                <RowItem
                                  label={t(
                                    'henselPhelpsAhaTabContent.ownedBy'
                                  )}
                                  content={projectTemplate?.ownedBy?.name}
                                  tooltipTitle={
                                    projectTemplate?.ownedBy?.name
                                  }
                                />
                              </Grid>
                              <Grid item xs={12} sm={12} md={3} lg={3}>
                                <RowItem
                                  label={t(
                                    'henselPhelpsAhaTabContent.createdOn'
                                  )}
                                  content={formatDate(
                                    projectTemplate?.created
                                  )}
                                  tooltipTitle={formatDate(
                                    projectTemplate?.created,
                                    true
                                  )}
                                />
                              </Grid>
                              <Grid item xs={12} sm={12} md={3} lg={3}>
                                <RowItem
                                  label={t(
                                    'henselPhelpsAhaTabContent.modifiedOn'
                                  )}
                                  content={formatDate(
                                    projectTemplate?.lastModified
                                  )}
                                  tooltipTitle={formatDate(
                                    projectTemplate?.lastModified,
                                    true
                                  )}
                                />
                              </Grid>
                              <Grid item xs={12} sm={12} md={3} lg={3}>
                                {projectTemplate?.ahaReviews?.length > 0 && (
                                  <RowItem
                                    label={'Status'}
                                    content={getLatestReviewStatus(
                                      projectTemplate
                                    )}
                                    tooltipTitle={
                                      'Status of the most recent review'
                                    }
                                  />
                                )}
                              </Grid>
                            </Grid>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  </Fragment>
                ))}
              </>
            )}
            {projectTemplates?.length < 1 && (
              <Grid container className={classes.noAhasFoundContainer}>
                <Grid item>
                  <Typography className={classes.noAhasFoundMessage}>
                    {t('henselPhelpsAhaTabContent.noAhasFound')}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
        </InfiniteScroll>
      </div>
    </LoadingOverlay>
  </>;
};

HenselPhelpsAhaTabContent.propTypes = {
  projectTemplates: PropTypes.any,
  fetchMoreHenselPhelpsAhas: PropTypes.func,
  isLoadingPaginatedProjectTemplates: PropTypes.bool,
  henselPhelpsAhasTotalCount: PropTypes.number
};

export default HenselPhelpsAhaTabContent;
