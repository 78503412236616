import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import {
  Grid,
  FormGroup,
  FormControlLabel,
  Switch,
  Typography
} from '@mui/material';

import useCurrentUser from 'hooks/useCurrentUser';
import StyledDialog from 'shared/Dialog';
import StyledNotice from 'shared/Notice';
import sherlock from 'assets/images/gifs/sherlockholmesparrot.gif';
import StyledButtonSecondary from 'shared/Buttons/ButtonSecondary';

const useStyles = makeStyles(theme => ({
  labelText: { fontSize: '0.875rem' },
  container: {
    backgroundColor: theme.palette.background.default,
    marginTop: theme.spacing(2),
    padding: theme.spacing(1, 2),
    borderRadius: 4
  }
}));

const TestByRoleDialog = ({
  isTestByRoleDialogOpen,
  setIsTestByRoleDialogOpen,
  downgradedRolesValue,
  handleDowngradedRolesChange
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { currentUser } = useCurrentUser();
  const roles = currentUser?.roles ?? [];

  let downgradedRoles = downgradedRolesValue.split(',').filter(r => r);

  const handleChange = event => {
    const isDowngraded = event.target.checked;
    const role = event.target.value;
    if (isDowngraded && !downgradedRoles.includes(role)) {
      downgradedRoles.push(role);
    }

    if (!isDowngraded && downgradedRoles.includes(role)) {
      downgradedRoles = downgradedRoles.filter(r => r !== role);
    }
    const newDowngradedRolesValue = downgradedRoles.join(',');
    window.sessionStorage.setItem('downgradedRoles', newDowngradedRolesValue);
    handleDowngradedRolesChange(newDowngradedRolesValue);
    // trackEvent('Theme', 'Changed theme', selectedTheme);
  };

  const handleClose = () => {
    setIsTestByRoleDialogOpen(false);
  };

  return (
    <StyledDialog
      isOpen={isTestByRoleDialogOpen}
      handleClose={handleClose}
      disableBackdropClick={false}
      disableEscapeKeyDown={false}
      maxWidth="sm"
      title={'Test by Role(s)'}
      content={
        <Grid container>
          <Grid item xs={12}>
            <img src={sherlock} alt={'Cult of the parrot sherlock version'} />
          </Grid>
          <Grid item xs={12}>
            <StyledNotice
              message={t('downgradedRolesBanner.message', {
                downgradedRoles: downgradedRolesValue
              })}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography>
              The following SafetyConnect user roles have been designated with
              permissioned access rights to the application. Toggle to
              selectively test by role(s).
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {roles.map((role, index) => {
              const isDowngraded = downgradedRoles.includes(role);
              return (
                <Grid
                  container
                  key={`disabled-role-${index}`}
                  className={classes.container}
                  justifyContent="space-between">
                  <Grid item>
                    <Typography>{role}</Typography>
                  </Grid>
                  <Grid item>
                    <FormGroup row>
                      <FormControlLabel
                        labelPlacement="start"
                        control={
                          <Switch
                            checked={isDowngraded}
                            onChange={handleChange}
                            value={role}
                            color="primary"
                            size="small"
                          />
                        }
                        label={
                          <Typography
                            color="textPrimary"
                            className={classes.labelText}>
                            {isDowngraded ? 'Downgraded' : 'Active'}
                          </Typography>
                        }
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      }
      actions={<StyledButtonSecondary label={'Close'} onClick={handleClose} />}
    />
  );
};

TestByRoleDialog.propTypes = {
  isTestByRoleDialogOpen: PropTypes.bool,
  setIsTestByRoleDialogOpen: PropTypes.func,
  downgradedRolesValue: PropTypes.any,
  handleDowngradedRolesChange: PropTypes.func
};

export default TestByRoleDialog;
