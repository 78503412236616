import {
  GET_TRADE_PARTNER_PERSONNEL_FOR_BASIC_IDENTITY,
  GET_TRADE_PARTNER_PERSONNEL_FOR_GENERAL_RELATIONS,
  GET_TRADE_PARTNER_PERSONNEL_FOR_WORK_HISTORY
} from '../../graphql/tradePartnerPersonnel';
import useSoteriaQuery from 'hooks/useSoteriaQuery';
import { merge } from 'merge-anything';

const useTradePartnerPersonnel = ({
  tradePartnerPersonnelId,
  shouldSkip = false,
  shouldIncludeGeneralRelations = false,
  shouldIncludeWorkHistory = false
}) => {
  const {
    data: { tradePartnerPersonnel: tradePartnerPersonnelBasic = null } = {},
    loading: loadingBasic,
    error: errorBasic
  } = useSoteriaQuery({
    gql: GET_TRADE_PARTNER_PERSONNEL_FOR_BASIC_IDENTITY,
    queryHookOptions: {
      skip: shouldSkip || !tradePartnerPersonnelId,
      variables: { id: tradePartnerPersonnelId }
    }
  });

  const {
    data: {
      tradePartnerPersonnel: tradePartnerPersonnelGeneralRelations = null
    } = {},
    loading: loadingGeneralRelations,
    error: errorGeneralRelations
  } = useSoteriaQuery({
    gql: GET_TRADE_PARTNER_PERSONNEL_FOR_GENERAL_RELATIONS,
    queryHookOptions: {
      skip:
        shouldSkip ||
        !tradePartnerPersonnelId ||
        !shouldIncludeGeneralRelations,
      variables: { id: tradePartnerPersonnelId }
    }
  });

  const {
    data: {
      tradePartnerPersonnel: tradePartnerPersonnelWorkHistory = null
    } = {},
    loading: loadingWorkHistory,
    error: errorWorkHistory
  } = useSoteriaQuery({
    gql: GET_TRADE_PARTNER_PERSONNEL_FOR_WORK_HISTORY,
    queryHookOptions: {
      skip: shouldSkip || !tradePartnerPersonnelId || !shouldIncludeWorkHistory,
      variables: { id: tradePartnerPersonnelId }
    }
  });

  const loading = loadingBasic || loadingGeneralRelations || loadingWorkHistory;
  const error = errorBasic || errorGeneralRelations || errorWorkHistory;

  let tradePartnerPersonnel = null;

  if (!loading) {
    tradePartnerPersonnel = tradePartnerPersonnelBasic;
    if (shouldIncludeGeneralRelations) {
      tradePartnerPersonnel =
        tradePartnerPersonnel && tradePartnerPersonnelGeneralRelations
          ? merge(tradePartnerPersonnel, tradePartnerPersonnelGeneralRelations)
          : null;
    }
    if (shouldIncludeWorkHistory) {
      tradePartnerPersonnel =
        tradePartnerPersonnel && tradePartnerPersonnelWorkHistory
          ? merge(tradePartnerPersonnel, tradePartnerPersonnelWorkHistory)
          : null;
    }
  }

  return {
    tradePartnerPersonnel,
    loading,
    error
  };
};

export default useTradePartnerPersonnel;
