import gql from 'graphql-tag';

export const GET_PRE_INCIDENT_ANALYSIS_FOR_PROJECT = gql`
  query GetPreIncidentAnalysisForProject($projectId: ID!) {
    preIncidentAnalysisForProject(projectId: $projectId) {
      id
      prediction
      probability
      lastAnalysisDate
      preIncidentIndicators {
        id
        preIncidentIndicatorCategory {
          id
          feature
          description
          shortDescription
          focusOn
          isActionable
        }
        value
        score
        changeDescriptor
      }
    }
  }
`;
