/* eslint-disable react/display-name */
import React from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'clone-deep';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import StyledTable from 'shared/Table';
import ActiveOrInactiveStatusContent from 'components/common/ActiveOrInactiveStatusContent';
import StyledTableCellContent from 'components/common/StyledTableCellContent';

export const CompanyProjectList = ({ tradePartners }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const tradePartnersData = cloneDeep(tradePartners);

  const columns = [
    {
      name: 'id',
      label: 'Trade Partner Id',
      options: {
        filter: false,
        display: 'excluded'
      }
    },
    {
      name: 'project.name',
      label: 'Name',
      options: {
        filter: false,
        sort: true,
        sortOrder: 'asc',
        searchable: true,
        customBodyRender: value => {
          return <StyledTableCellContent value={value} />;
        }
      }
    },
    {
      name: 'trades',
      label: 'Trades on Project',
      options: {
        filter: true,
        filterType: 'textField',
        customFilterListOptions: { render: value => `Trade: ${value}` },
        sort: true,
        searchable: false,
        customBodyRender: value => {
          return (
            <StyledTableCellContent
              value={value.length > 0 ? value.join(', ').toLowerCase() : null}
            />
          );
        }
      }
    },
    {
      name: 'project.location.cityAndStateDisplay',
      label: 'Location',
      options: {
        filter: false,
        sort: true,
        searchable: true,
        customBodyRender: value => {
          return <StyledTableCellContent value={value} />;
        }
      }
    },
    {
      name: 'project.number',
      label: 'Number',
      options: {
        filter: false,
        sort: true,
        searchable: true
      }
    },
    {
      name: 'project.districtNumber',
      label: 'Region',
      options: {
        filter: true,
        customFilterListOptions: { render: value => `Region: ${value}` },
        filterType: 'multiselect',
        searchable: false,
        sort: true
      }
    },
    {
      name: 'isActive',
      label: 'Company Status',
      options: {
        sort: true,
        searchable: false,
        filter: true,
        filterOptions: {
          names: [
            t('companyProjectList.status.active'),
            t('companyProjectList.status.inactive')
          ],
          logic: (isActive, filters) => {
            if (filters.length) {
              const status = isActive
                ? t('companyProjectList.status.active')
                : t('companyProjectList.status.inactive');
              return !filters.includes(status);
            }
            /* istanbul ignore next */
            return false; // this return is required but is ignored for tests as filters will never be null
          }
        },
        customBodyRender: value => (
          <ActiveOrInactiveStatusContent isActive={value} />
        )
      }
    },
    {
      name: 'project.id',
      label: 'projectId',
      options: {
        filter: false,
        sort: false,
        searchable: false,
        display: 'excluded'
      }
    }
  ];

  const options = {
    onRowClick: rowData => {
      history.push(`/projects/${rowData[7]}`);
    }
  };

  return (
    <StyledTable
      title={t('companyProjectList.tableTitle')}
      data={tradePartnersData}
      columns={columns}
      options={options}
    />
  );
};

CompanyProjectList.propTypes = {
  tradePartners: PropTypes.array.isRequired
};

export default CompanyProjectList;
