import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';
import useAhaReview from 'hooks/useAhaReview';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import MarkdownRenderer from 'shared/MarkdownRenderer';

const useStyles = makeStyles(theme => ({
  fullWidth: { width: '100%' },
  hrDivider: { padding: 0, margin: 0, width: '100%' },
  centeredText: { textAlign: 'center' },
  mediumFontSize: { fontSize: '0.75rem' },
  jobStepColumnHeader: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '0.75rem',
    paddingTop: '.125rem'
  },
  borderRightAndLeft: {
    borderRight: '1px solid black',
    borderLeft: '1px solid black'
  },
  borderLeft: {
    borderLeft: '1px solid black'
  },
  padding: { padding: theme.spacing(0.5) }
}));

const EquipmentSection = ({ ahaTemplate }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { ahaReviewId } = useParams();
  const { ahaReview } = useAhaReview(ahaReviewId);

  const sortByOrder = (a, b) => a.order - b.order;

  const equipments = ahaReview
    ? ahaReview?.ahaTemplate?.ahaEquipments.filter(
        equipment => equipment.isActive === true
      )
      ? [...ahaReview.ahaTemplate.ahaEquipments]
          .sort(sortByOrder)
          .filter(equipment => equipment.isActive === true)
      : []
    : ahaTemplate?.ahaEquipments.filter(
        equipment => equipment.isActive === true
      )
    ? [...ahaTemplate.ahaEquipments]
        .sort(sortByOrder)
        .filter(equipment => equipment.isActive === true)
    : [];

  return (
    <div className={classes.fullWidth}>
      <div className="page-break-auto">
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={4} className={classes.borderLeft}>
              <Typography className={classes.jobStepColumnHeader}>
                {t('equipmentSection.title')}
              </Typography>
            </Grid>
            <Grid item xs={4} className={classes.borderLeft}>
              <Typography className={classes.jobStepColumnHeader}>
                {t('equipmentSection.column.training')}
              </Typography>
            </Grid>
            <Grid
              item
              xs={4}
              className={classnames(classes.borderRightAndLeft)}>
              <Typography className={classnames(classes.jobStepColumnHeader)}>
                {t('equipmentSection.column.inspectionRequirements')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <hr className={classes.hrDivider} />
      </div>
      {equipments?.map(equipment => (
        <div
          key={equipment.id ?? equipment.transactionKey}
          className="page-break-auto">
          <hr className={classes.hrDivider} />
          <Grid container>
            <Grid item xs={4} className={classes.borderLeft}>
              <Typography
                className={classnames(classes.mediumFontSize, classes.padding)}>
                {equipment.name}
              </Typography>
            </Grid>
            <Grid item xs={4} className={classes.borderLeft}>
              <Typography
                component={'div'}
                className={classnames(classes.mediumFontSize, classes.padding)}>
                <MarkdownRenderer markdownString={equipment.trainings} />
              </Typography>
            </Grid>
            <Grid
              item
              xs={4}
              className={classnames(classes.borderRightAndLeft)}>
              <Typography
                component={'div'}
                className={classnames(classes.mediumFontSize, classes.padding)}>
                <MarkdownRenderer
                  markdownString={equipment.inspectionRequirements}
                />
              </Typography>
            </Grid>
          </Grid>
          <hr className={classes.hrDivider} />
        </div>
      ))}
    </div>
  );
};

EquipmentSection.propTypes = {
  ahaTemplate: PropTypes.object
};

export default EquipmentSection;
