import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

const ObservationsTabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}>
      <Box p={1}>{children}</Box>
    </div>
  );
};

ObservationsTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

export default ObservationsTabPanel;
