import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import {
  LinearProgress,
  Card,
  Grid,
  Typography,
  FormGroup,
  Switch
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';

const useStyles = makeStyles(theme => ({
  titleText: { display: 'inline', fontWeight: 'bold' },
  card: {
    borderLeft: '8px solid' + theme.palette.success.main,
    padding: theme.spacing(3)
  },
  cardInactive: {
    borderLeft: 'none',
    padding: theme.spacing(3),
    [theme.breakpoints.down('md')]: { padding: theme.spacing(2) }
  }
}));

const IsActiveSwitch = withStyles(theme => ({
  switchBase: {
    color: theme.palette.background.default,
    '&.Mui-checked': {
      color: theme.palette.success.main
    },
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: theme.palette.success.main
    }
  },
  checked: {},
  track: {}
}))(Switch);

const StyledStatusCard = ({
  loading,
  title,
  isActive,
  deactivateSubtitle,
  activateSubtitle,
  handleChange
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      {loading && <LinearProgress color="primary" />}
      <Card
        className={classnames(
          {
            [`${classes.card}`]: isActive === true
          },
          {
            [`${classes.cardInactive}`]: isActive === false
          }
        )}>
        <Grid container direction="row" alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <Grid container direction="column" spacing={1}>
              <Grid item xs={12}>
                <Typography component="h1" className="inline dialog-title-bold">
                  {title}
                </Typography>
                <Typography className="inline">
                  {isActive ? t('statusCard.active') : t('statusCard.inactive')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Typography color="textSecondary">
                      {isActive ? deactivateSubtitle : activateSubtitle}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <FormGroup row>
                      <IsActiveSwitch
                        classes={{
                          root: classes.switchBase,
                          checked: classes.checked
                        }}
                        inputProps={{ 'data-testid': 'status-switch' }}
                        checked={isActive}
                        onChange={handleChange}
                        value="isActive"
                        size="small"
                        disabled={loading}
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

StyledStatusCard.propTypes = {
  loading: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  deactivateSubtitle: PropTypes.string.isRequired,
  activateSubtitle: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired
};

export default StyledStatusCard;
