import React from 'react';

import usePendingObservationSync from 'hooks/usePendingObservationSync';

// eslint-disable-next-line react/prop-types
const OnlineSync = ({ children }) => {
  usePendingObservationSync();

  return <>{children}</>;
};

export default OnlineSync;
