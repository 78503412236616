import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Typography } from '@mui/material';

const useStyles = makeStyles(theme => ({
  racLegendTitle: {
    fontSize: '0.875rem',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    padding: theme.spacing(0.5, 0, 0, 1),
    [theme.breakpoints.down('lg')]: {
      fontSize: '0.75rem',
      padding: theme.spacing(1, 0, 0, 0)
    }
  },
  racLegendLevels: {
    fontSize: '0.75rem',
    paddingLeft: theme.spacing(1),
    [theme.breakpoints.down('lg')]: { fontSize: '0.625rem', paddingLeft: 4 }
  }
}));

const noDisplayXsMd = {
  display: {
    xs: 'none',
    sm: 'block',
    md: 'none',
    sm: 'block',
    lg: 'block'
  }
};

const RiskAssessmentLegend = () => {
  const classes = useStyles();
  // TODO: TRANSLATES
  // const { t } = useTranslation();

  return (
    <>
      <Typography
        color="textPrimary"
        className={classes.racLegendTitle}
        sx={noDisplayXsMd}>
        RAC CHART
      </Typography>
      <Typography
        color="textPrimary"
        className={classes.racLegendLevels}
        sx={noDisplayXsMd}>
        E = Extremely High-Risk
      </Typography>
      <Typography
        color="textPrimary"
        className={classes.racLegendLevels}
        sx={noDisplayXsMd}>
        H = High-Risk
      </Typography>
      <Typography
        color="textPrimary"
        className={classes.racLegendLevels}
        sx={noDisplayXsMd}>
        M = Moderate Risk
      </Typography>
      <Typography
        color="textPrimary"
        className={classes.racLegendLevels}
        sx={noDisplayXsMd}>
        L = Low Risk
      </Typography>
      <Typography
        color="textPrimary"
        className={classes.racLegendLevels}
        sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'none' } }}>
        <span className={classes.racLegendTitle}>RAC CHART:</span> E = Extremely
        High-Risk, H = High-Risk, M = Moderate Risk, L = Low Risk
      </Typography>
    </>
  );
};

export default RiskAssessmentLegend;
