import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'clone-deep';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import { useField, useForm } from 'react-final-form-hooks';
import { useHistory } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';

import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Grid,
  Link,
  Typography,
  Button,
  CircularProgress
} from '@mui/material';

import useToast from 'hooks/useToast';
import useFormValidation from 'hooks/useFormValidation';
import StyledInput from 'shared/Input';
import { generateTransactionKey, stringCompare } from 'utils';
import useAhaGlobalTemplatesListState from 'store/ahaGlobalTemplatesListState';
import {
  CREATE_GLOBAL_AHA_TEMPLATE,
  PAGINATED_GLOBAL_TEMPLATES
} from 'graphql/aha/globalTemplate';
import { GET_ALL_GLOBAL_TEMPLATE_CATEGORIES } from 'graphql/aha/globalTemplateCategory';

const useStyles = makeStyles(theme => ({
  formControlRoot: {
    margin: theme.spacing(0, 0, 0, 9),
    backgroundColor: theme.palette.background.paper
  },
  formControlRootEditing: {
    margin: theme.spacing(0, 2),
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down('lg')]: { margin: 0 }
  },
  label: { fontSize: '0.75rem' },
  formControlLabelRoot: { height: 30 },
  ahaLinksContainer: {
    padding: theme.spacing(0, 0, 1, 9),
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.spacing(0, 0, 1, 1),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('lg')]: { paddingLeft: theme.spacing(4) }
  },
  link: { '&:hover': { cursor: 'pointer' } },
  linkText: { fontSize: '0.875rem', '&:hover': { fontWeight: 'bold' } },
  addAhaContainer: {
    borderRadius: 8,

    margin: theme.spacing(1, 0)
  },
  addAhaButtonLabel: { fontSize: '0.75rem', fontWeight: 'bold' },
  cancelButton: { margin: theme.spacing(0, 0, 0.75, 0) },
  saveButton: { margin: theme.spacing(0, 1, 0.75, 1) },
  buttonLabel: { fontSize: '0.75rem', fontWeight: 'bold' },
  noAhasMessage: { fontSize: '0.75rem' },
  disabledLink: { color: theme.palette.background.disabledText }
}));

const GlobalAhas = ({
  category,
  isEditing,
  selectedAha,
  setSelectedAha,
  isLoading
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { isRequired } = useFormValidation();
  const { displayToast } = useToast();
  const [isAddingAha, setIsAddingAha] = useState(false);
  const [transactionKey, setTransactionKey] = useState(
    generateTransactionKey()
  );
  const [ahaGlobalTemplatesListState] = useAhaGlobalTemplatesListState();

  const sortedAhas = cloneDeep(category?.ahaTemplates).map(template => {
    const { ahaTemplates, ...restCategory } = category;

    return {
      ...template,
      category: { ...restCategory }
    };
  });
  sortedAhas.sort((a, b) => stringCompare(a.type, b.type));

  const getApiSortOrder = order => {
    let apiSortOrderField = null;
    let direction = order.direction;

    switch (order.name) {
      case 'type':
        apiSortOrderField = 'type';
        break;
      case 'createdBy.name':
        apiSortOrderField = 'createdBy';
        break;
      case 'ahaGlobalTemplateCategory.name':
        apiSortOrderField = 'ahaGlobalTemplateCategory_name';
        break;
      case 'created':
        apiSortOrderField = 'created';
        break;
      case 'lastModified':
        apiSortOrderField = 'lastModified';
        break;
      default:
        console.error(`Sort order, "${order.name}", is not being handled!`);
    }
    return apiSortOrderField && direction
      ? [
          {
            [apiSortOrderField]: direction.toUpperCase()
          }
        ]
      : null;
  };

  const [
    createGlobalAhaTemplate,
    { loading: isLoadingCreateGlobalAhaTemplate }
  ] = useMutation(CREATE_GLOBAL_AHA_TEMPLATE, {
    refetchQueries: [
      {
        query: GET_ALL_GLOBAL_TEMPLATE_CATEGORIES
      },
      {
        query: PAGINATED_GLOBAL_TEMPLATES,
        variables: {
          first: ahaGlobalTemplatesListState.first,
          order: getApiSortOrder(ahaGlobalTemplatesListState.order),
          search: ahaGlobalTemplatesListState.search,
          skip: ahaGlobalTemplatesListState.skip
        }
      }
    ],
    awaitRefetchQueries: true
  });

  const isSaving = isLoading || isLoadingCreateGlobalAhaTemplate;

  const { form, pristine } = useForm({
    /* istanbul ignore next */
    onSubmit: () => {}
  });

  const ahaNameField = useField('ahaName', form, isRequired);

  const handleRadioOptionChange = event => {
    const selectedAha = JSON.parse(event.target.value);
    setSelectedAha(selectedAha);
  };

  const canSubmit = () => {
    const { error, invalid } = form.getState();
    return !(invalid || error || pristine);
  };

  const handleSubmit = () => {
    createGlobalAhaTemplate({
      variables: {
        input: {
          ahaGlobalTemplateCategoryId: category?.id,
          transactionKey: transactionKey,
          type: ahaNameField?.input?.value
        }
      }
    })
      .then(({ data }) => {
        const createdGlobalTemplate = data.createGlobalAhaTemplate;
        displayToast(
          t('globalAhas.toasts.create.success', {
            name: createdGlobalTemplate.type
          }),
          'success'
        );
        handleClose();
        history.push(`/ahas/${createdGlobalTemplate.id}/edit?view=summary`);
      })
      .catch(error => {
        console.error('Create Global AHA Error: ', error);
        displayToast(t('globalAhas.toasts.create.error'), 'error');
      });
  };

  const handleClose = () => {
    setIsAddingAha(false);
    setTransactionKey(generateTransactionKey());
    setSelectedAha();
  };

  return (
    <>
      <FormControl
        variant="standard"
        classes={{
          root: isEditing
            ? classes.formControlRootEditing
            : classes.formControlRoot
        }}
        component="fieldset">
        {!isEditing && sortedAhas.length > 0 && (
          <>
            <RadioGroup
              value={selectedAha ?? ''}
              onChange={handleRadioOptionChange}>
              {sortedAhas?.map(template => {
                return (
                  <FormControlLabel
                    key={template.id}
                    classes={{
                      label: classes.label,
                      root: classes.formControlLabelRoot
                    }}
                    value={JSON.stringify(template, 2, null)}
                    control={
                      <Radio
                        color="primary"
                        size="small"
                        checked={selectedAha?.id === template.id}
                        disabled={isSaving}
                      />
                    }
                    label={template.type}
                  />
                );
              })}
            </RadioGroup>
          </>
        )}
        {!isEditing && sortedAhas.length < 1 && (
          <>
            <Typography className={classes.noAhasMessage}>
              {t('ahas.noAhasMessage')}
            </Typography>
          </>
        )}
        {isEditing && (
          <Grid container className={classes.ahaLinksContainer}>
            {sortedAhas?.map(template => (
              <Grid item xs={12} key={template.id}>
                <Link
                  component="button"
                  disabled={isSaving}
                  className={classnames(classes.link, {
                    [`${classes.disabledLink}`]: isSaving
                  })}
                  aria-label={'navigate to the summary view of the AHA'}
                  tabIndex="0"
                  underline="always"
                  onClick={() =>
                    history.push(`/ahas/${template.id}/edit?view=summary`)
                  }
                  onKeyDown={() =>
                    history.push(`/ahas/${template.id}/edit?view=summary`)
                  }>
                  <Typography className={classes.linkText}>
                    {template.type}
                  </Typography>
                </Link>
              </Grid>
            ))}
            <Grid item>
              {!isAddingAha && (
                <Button
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={() => setIsAddingAha(!isAddingAha)}
                  disabled={isSaving}>
                  <Typography className={classes.addAhaButtonLabel}>
                    {t('ahas.actions.addAhaButton')}
                  </Typography>
                </Button>
              )}
              {isAddingAha && (
                <div className={classes.addAhaContainer}>
                  <Grid container direction="row" alignItems="flex-end">
                    <Grid item>
                      <StyledInput
                        label={t('addCategoryAhas.nameField.label')}
                        input={ahaNameField.input}
                        meta={ahaNameField.meta}
                        disabled={isSaving}
                        required
                      />
                    </Grid>
                    <Grid item>
                      {!isSaving && (
                        <Button
                          className={classes.saveButton}
                          disabled={!canSubmit() || isSaving}
                          onClick={handleSubmit}>
                          <Typography className={classes.buttonLabel}>
                            {t('ahas.actions.saveButton')}
                          </Typography>
                        </Button>
                      )}
                      {isSaving && (
                        <CircularProgress color="primary" size={30} />
                      )}
                      <Button
                        className={classes.cancelButton}
                        onClick={() => setIsAddingAha(false)}
                        disabled={isSaving}>
                        <Typography className={classes.buttonLabel}>
                          {t('ahas.actions.cancelButton')}
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              )}
            </Grid>
          </Grid>
        )}
      </FormControl>
    </>
  );
};

GlobalAhas.propTypes = {
  category: PropTypes.object.isRequired,
  isEditing: PropTypes.bool,
  selectedAha: PropTypes.object,
  setSelectedAha: PropTypes.func,
  isLoading: PropTypes.bool
};

export default GlobalAhas;
