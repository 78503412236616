import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';

const VideoPlayer = ({ url, tracks = [], ...rest }) => {
  return (
    <ReactPlayer
      url={url}
      controls
      width="100%"
      height="100%"
      config={{ file: { tracks } }}
      {...rest}
    />
  );
};

VideoPlayer.propTypes = {
  url: PropTypes.string.isRequired,
  tracks: PropTypes.array
};

export default VideoPlayer;
