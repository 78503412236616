import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';

import useToast from 'hooks/useToast';
import StatusCard from 'shared/StatusCard';
import { UPDATE_TRADE_PARTNER_PERSONNEL } from 'graphql/tradePartnerPersonnel';

const TradePartnerPersonnelStatusCard = ({ tradePartnerPersonnel }) => {
  const { t } = useTranslation();
  const { displayToast } = useToast();
  const [updateTradePartnerPersonnel, { loading }] = useMutation(
    UPDATE_TRADE_PARTNER_PERSONNEL
  );

  const handleChange = event => {
    let input = {
      id: tradePartnerPersonnel.id,
      isActive: event.target.checked
    };
    updateTradePartnerPersonnel({
      variables: {
        input
      }
    })
      .then(
        ({ data: { updateTradePartnerPersonnel: tradePartnerPersonnel } }) => {
          displayToast(
            t('tradePartnerPersonnelStatusCard.toasts.status.success', {
              tradePartnerPersonnelName:
                tradePartnerPersonnel.personnel.fullName,
              action: tradePartnerPersonnel.isActive
                ? t('tradePartnerPersonnelStatusCard.manageStatus.activated')
                : t('tradePartnerPersonnelStatusCard.manageStatus.deactivated')
            }),
            'success'
          );
        }
      )
      .catch(error => {
        console.error('Set Status Error: ', error);
        displayToast(
          t('tradePartnerPersonnelStatusCard.toasts.status.error'),
          'error'
        );
      });
  };

  return (
    <StatusCard
      loading={loading}
      title={t('projectPersonnelStatusCard.title')}
      isActive={tradePartnerPersonnel.isActive}
      deactivateSubtitle={t(
        'projectPersonnelStatusCard.manageStatus.subtitle.deactivate'
      )}
      activateSubtitle={t(
        'projectPersonnelStatusCard.manageStatus.subtitle.activate'
      )}
      handleChange={handleChange}
    />
  );
};

TradePartnerPersonnelStatusCard.propTypes = {
  tradePartnerPersonnel: PropTypes.object.isRequired
};

export default TradePartnerPersonnelStatusCard;
