import React from 'react';
import PropTypes from 'prop-types';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import makeStyles from '@mui/styles/makeStyles';
import { TextField, InputAdornment, IconButton } from '@mui/material';

const useStyles = makeStyles(theme => ({
  searchInput: {
    maxWidth: 450,
    '& .MuiInput-underline:before': {
      borderBottom: '2px solid' + theme.palette.primary.main
    },
    '& .MuiInputBase-input': {
      fontSize: '1rem !important',
      fontWeight: 'bold !important',
      color: theme.palette.secondary.contrastText + '!important',
      padding: theme.spacing(1, 0, 0, 2),
      backgroundColor: theme.palette.background.default,
      borderRadius: 4
    },
    '&:hover': {
      borderBottomColor: theme.palette.primary.main
    }
  },
  icon: { color: theme.palette.secondary.contrastText },
  inputLabel: { fontWeight: 'bold' }
}));

const StyledSearch = ({ value, onChange, placeholder, onClick }) => {
  const classes = useStyles();

  return (
    <TextField
      className={classes.searchInput}
      value={value}
      onChange={onChange}
      color="primary"
      placeholder={placeholder}
      label={'Search'}
      margin="dense"
      variant="standard"
      inputProps={{
        'aria-required': false
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton size="small" onClick={onClick} className={classes.icon}>
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        )
      }}
      InputLabelProps={{ classes: { root: classes.inputLabel } }}
      fullWidth={true}
    />
  );
};

StyledSearch.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  placeholder: PropTypes.any,
  onClick: PropTypes.func
};

export default StyledSearch;
