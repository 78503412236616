/* eslint-disable react/display-name */
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import { useApolloClient, useSubscription } from '@apollo/react-hooks';
import cloneDeep from 'clone-deep';
import { Typography, Grid, Link, Tooltip } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import withAuthorization from 'hocs/withAuthorization';
import StyledTable from 'shared/Table';
import BackToTopButton from 'shared/BackToTopButton';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import AddProjectOrientationAttendeeDialog from 'components/attendees/AddAttendeeDialog';
import VerifySelfRegisteredAttendeeDialog from 'components/attendees/VerifySelfRegisteredAttendeeDialog';
import ApproveConfirmationDialog from './ApproveConfirmationDialog';
import DenyConfirmationDialog from './DenyConfirmationDialog';
import LoadingSpinner from 'components/common/LoadingSpinner';
import ErrorNotice from 'components/common/ErrorNotice';
import PageNotFoundNotice from 'components/common/PageNotFoundNotice';
import { GET_ORIENTATION } from 'graphql/orientation';
import {
  GET_ATTENDEE,
  ATTENDEE_SUBSCRIPTION,
  DELETE_ATTENDEE
} from 'graphql/attendee';
import { ORIENTATION_SUBSCRIPTION } from 'graphql/orientation';
import { formatDateOfBirth, serializeDate } from 'utils';
import withOnlineAccessOnly from 'hocs/withOnlineAccessOnly';
import useSoteriaQuery from 'hooks/useSoteriaQuery';
import StyledTableCellContent from 'components/common/StyledTableCellContent';
import useToast from 'hooks/useToast';
import { useMutation } from '@apollo/react-hooks';

const useStyles = makeStyles(theme => ({
  title: { fontSize: '2rem' },
  verifyButton: { width: 86 },
  attentionRequired: { color: theme.palette.flag.attentionRequired },
  flagContainer: {
    paddingRight: 8,
    [theme.breakpoints.down('lg')]: {
      paddingRight: 0
    }
  },
  detailsLink: {
    fontSize: '0.875rem',
    '&:hover': { cursor: 'pointer' },
    textDecoration: 'underline'
  },
  limitedText: {
    fontSize: '0.875rem',
    maxWidth: 800,
    whiteSpace: 'noWrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    [theme.breakpoints.down('xl')]: { maxWidth: 600 },
    [theme.breakpoints.down('xl')]: { maxWidth: 400 },
    [theme.breakpoints.down('lg')]: { maxWidth: 300 },
    [theme.breakpoints.down('md')]: { maxWidth: 250 }
  },
  buttonContainer: {
    justifyContent: 'flex-start',
    '@media (max-width: 960px)': {
      justifyContent: 'space-between',
      margin: '-15px !important'
    }
  }
}));

const AttendeeListPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { projectId, orientationId } = useParams();
  const history = useHistory();
  const apolloClient = useApolloClient();
  const [selectedAttendee, setSelectedAttendee] = useState(null);
  const { displayToast } = useToast();

  const [
    isSelfRegistrationVerificationOpen,
    toggleIsSelfRegistrationVerificationOpen
  ] = useState(false);

  const [
    approveConfirmationDialogIsOpen,
    toggleApproveConfirmationDialog
  ] = useState(false);

  const [denyConfirmationDialogIsOpen, toggleDenyConfirmationDialog] = useState(
    false
  );

  const setSelectedAttendeeFromCache = attendeeId => {
    return apolloClient
      .query({
        query: GET_ATTENDEE,
        variables: { attendeeId }
      })
      .then(({ data: { attendee } }) => {
        setSelectedAttendee(attendee);
      });
  };

  const handleVerify = rowData => {
    setSelectedAttendeeFromCache(rowData[0]).then(() => {
      toggleIsSelfRegistrationVerificationOpen(true);
    });
  };

  const handleApprove = rowData => {
    setSelectedAttendeeFromCache(rowData[0]).then(() => {
      toggleApproveConfirmationDialog(true);
    });
  };

  const handleDeny = rowData => {
    setSelectedAttendeeFromCache(rowData[0]).then(() => {
      toggleDenyConfirmationDialog(true);
    });
  };

  const handleDetailsView = rowData => {
    setSelectedAttendee(rowData);
    history.push(
      `/projects/${projectId}/orientations/${orientationId}/attendees/${rowData[0]}`
    );
  };

  const [deleteAttendee] = useMutation(DELETE_ATTENDEE, {
    refetchQueries: [{ query: GET_ORIENTATION, variables: { orientationId } }],
    awaitRefetchQueries: true
  });

  const handleRemove = attendee => {
    const [attendeeId, , , , fullName] = attendee;
    deleteAttendee({
      variables: { attendeeId }
    })
      .then(() => {
        displayToast(
          t('attendeeDeleteCard.toasts.success', {
            attendeeName: fullName
          }),
          'success'
        );
        history.push(
          `/projects/${projectId}/orientations/${orientationId}/attendees`
        );
      })
      .catch(error => {
        console.error('Delete Attendee Error: ', error);
        displayToast(t('attendeeDeleteCard.toasts.error'), 'error');
      });
  };

  const { data, loading: isLoading, error } = useSoteriaQuery({
    gql: GET_ORIENTATION,
    queryHookOptions: {
      variables: { orientationId }
    }
  });

  useSubscription(ORIENTATION_SUBSCRIPTION, {
    variables: { orientationId }
  });

  useSubscription(ATTENDEE_SUBSCRIPTION, {
    variables: {}
  });

  const attendees = data?.orientation?.attendees
    ? cloneDeep(data?.orientation?.attendees).map(attendee => ({
        ...attendee,
        formattedDob: attendee.dob ? formatDateOfBirth(attendee.dob) : null,
        formattedPhone: attendee.contactInformation?.primaryPhone?.number?.replace(
          '+',
          ''
        ),
        personnelFlagCount: attendee.personnel?.flags?.length || 0
      }))
    : [];

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error) {
    if (error.message?.includes('not found')) {
      return <PageNotFoundNotice />;
    } else {
      return <ErrorNotice />;
    }
  }

  const columns = [
    {
      name: 'id',
      label: 'SafetyConnectId',
      options: {
        filter: false,
        display: 'excluded'
      }
    },
    {
      name: 'firstName',
      label: 'firstName',
      options: {
        filter: false,
        sort: false,
        searchable: false,
        display: 'excluded'
      }
    },
    {
      name: 'middleName',
      label: 'middleName',
      options: {
        filter: false,
        sort: false,
        searchable: false,
        display: 'excluded'
      }
    },
    {
      name: 'lastName',
      label: 'lastName',
      options: {
        filter: false,
        sort: false,
        searchable: false,
        display: 'excluded'
      }
    },
    {
      name: 'fullName',
      label: 'Name',
      options: {
        filter: false,
        sort: true,
        download: false,
        sortOrder: 'asc',
        searchable: true,
        customBodyRender: (fullName, tableMeta) => (
          <Link
            aria-label={`navigate to the attendee page for ${fullName}`}
            tabIndex="0"
            onKeyDown={() => handleDetailsView(tableMeta.rowData)}
            onClick={() => handleDetailsView(tableMeta.rowData)}
            underline="hover">
            <Tooltip title={fullName}>
              <Typography
                className={`${classes.detailsLink} ${classes.limitedText}`}>
                {fullName}
              </Typography>
            </Tooltip>
          </Link>
        )
      }
    },
    {
      name: 'formattedDob',
      label: 'dob',
      options: {
        filter: false,
        sort: false,
        searchable: false,
        display: 'excluded'
      }
    },
    {
      name: 'nickname',
      label: 'nickname',
      options: {
        filter: false,
        sort: false,
        searchable: false,
        display: 'excluded'
      }
    },
    {
      name: 'customId',
      label: 'Custom ID',
      options: {
        filter: false,
        sort: true,
        searchable: true
      }
    },
    {
      name: 'formattedPhone',
      label: 'phone',
      options: {
        filter: false,
        sort: false,
        searchable: false,
        display: 'excluded'
      }
    },
    {
      name: 'contactInformation.emailAddress.email',
      label: 'email',
      options: {
        filter: false,
        sort: false,
        searchable: false,
        display: 'excluded'
      }
    },
    {
      name: 'notes',
      label: 'notes',
      options: {
        filter: false,
        sort: false,
        searchable: false,
        display: 'excluded'
      }
    },
    {
      name: 'tradePartner.name',
      label: 'Trade Partner',
      options: {
        filter: false,
        sort: true,
        searchable: true,
        customBodyRender: value => {
          return <StyledTableCellContent value={value} />;
        }
      }
    },
    {
      name: 'isSupervisor',
      label: 'isSupervisor',
      options: {
        filter: false,
        sort: false,
        searchable: false,
        display: 'excluded'
      }
    },
    {
      name: 'trainings',
      label: 'Trainings to Add',
      options: {
        filter: false,
        sort: true,
        download: false,
        searchable: true,
        customBodyRender: trainings => (
          <Fragment>
            {trainings?.length > 0 && (
              <Typography>{trainings?.length}</Typography>
            )}
          </Fragment>
        )
      }
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: true,
        sort: true,
        searchable: true,
        customFilterListOptions: { render: value => `Status: ${value}` },
        filterOptions: {
          names: [
            t('projectOrientationListPage.columns.status.enrolled'),
            t('projectOrientationListPage.columns.status.approved'),
            t('projectOrientationListPage.columns.status.denied'),
            t('projectOrientationListPage.columns.status.selfEnrolled')
          ],
          logic: (status, filters) => {
            if (filters.length && status === 'Enrolled') {
              const attendeeStatus = t(
                'projectOrientationListPage.columns.status.enrolled'
              );
              return !filters.includes(attendeeStatus);
            } else if (filters.length && status === 'SelfEnrolled') {
              const attendeeStatus = t(
                'projectOrientationListPage.columns.status.selfEnrolled'
              );
              return !filters.includes(attendeeStatus);
            } else if (filters.length && status === 'Approved') {
              const attendeeStatus = t(
                'projectOrientationListPage.columns.status.approved'
              );
              return !filters.includes(attendeeStatus);
            } else if (filters.length && status === 'Denied') {
              const attendeeStatus = t(
                'projectOrientationListPage.columns.status.denied'
              );
              return !filters.includes(attendeeStatus);
            }
            /* istanbul ignore next */
            return false; // this line can never be tested as filters will always exist
          }
        },
        customBodyRender: status => {
          if (status === 'SelfEnrolled') {
            return (
              <Typography style={{ fontSize: '0.875rem' }}>
                {t('projectOrientationListPage.columns.status.selfEnrolled')}
              </Typography>
            );
          } else if (status === 'Denied') {
            return (
              <Typography style={{ fontSize: '0.875rem' }}>
                {t('projectOrientationListPage.columns.status.denied')}
              </Typography>
            );
          } else if (status === 'Approved') {
            return (
              <Typography style={{ fontSize: '0.875rem' }}>
                {t('projectOrientationListPage.columns.status.approved')}
              </Typography>
            );
          } else if (status === 'Enrolled') {
            return (
              <Typography style={{ fontSize: '0.875rem' }}>
                {t('projectOrientationListPage.columns.status.enrolled')}
              </Typography>
            );
          }
        }
      }
    },
    {
      name: 'personnelFlagCount',
      label: t('projectOrientationListPage.columns.flags'),
      options: {
        filter: false,
        sort: true,
        searchable: true,
        customBodyRender: personnelFlagCount => (
          <Fragment>
            {personnelFlagCount > 0 && (
              <Typography>{personnelFlagCount}</Typography>
            )}
          </Fragment>
        )
      }
    },
    {
      name: 'lastModified',
      label: 'Date Added',
      options: {
        filter: false,
        sort: true,
        download: false,
        searchable: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <Fragment>
              {tableMeta.rowData[14] === 'Approved'
                ? serializeDate(new Date(value))
                : null}
            </Fragment>
          );
        }
      }
    },
    {
      name: 'status',
      label: ' ',
      options: {
        filter: false,
        download: false,
        sort: true,
        searchable: true,
        customBodyRender: (status, tableMeta, index) => {
          const isApproved = status === 'Approved';
          const isDenied = status === 'Denied';
          const isEnrolled = status === 'Enrolled';
          const isSelfEnrolled = status === 'SelfEnrolled';

          if (isSelfEnrolled) {
            return (
              <Grid container key={index} className={classes.buttonContainer}>
                <StyledButtonPrimary
                  className={classes.verifyButton}
                  onClick={() => handleVerify(tableMeta.rowData)}
                  label={t('projectOrientationListPage.actions.verify')}
                />
              </Grid>
            );
          }

          if (isApproved) {
            return (
              <Grid item>
                <StyledButtonPrimary
                  variant="contained"
                  color="primary"
                  onClick={() => handleRemove(tableMeta.rowData)}
                  label={t('projectOrientationListPage.actions.delete')}
                />
              </Grid>
            );
          }

          if (isEnrolled) {
            return (
              <Grid
                container
                className={classes.buttonContainer}
                direction="row"
                spacing={1}>
                <Grid item>
                  <StyledButtonPrimary
                    variant="outlined"
                    color="primary"
                    onClick={() => handleDeny(tableMeta.rowData)}
                    label={t('projectOrientationListPage.actions.deny')}
                    disabled={
                      tableMeta.rowData[5] === 'Approved' ||
                      tableMeta.rowData[5] === 'Denied'
                    }
                  />
                </Grid>
                <Grid item>
                  <StyledButtonPrimary
                    variant="contained"
                    color="primary"
                    onClick={() => handleApprove(tableMeta.rowData)}
                    label={t('projectOrientationListPage.actions.approve')}
                    disabled={
                      tableMeta.rowData[5] === 'Approved' ||
                      tableMeta.rowData[5] === 'Denied'
                    }
                  />
                </Grid>
                <Grid item>
                  <StyledButtonPrimary
                    variant="contained"
                    color="primary"
                    onClick={() => handleRemove(tableMeta.rowData)}
                    label={t('projectOrientationListPage.actions.delete')}
                  />
                </Grid>
              </Grid>
            );
          }

          if (isDenied) {
            return (
              <Grid
                container
                className={classes.buttonContainer}
                direction="row">
                <Grid item>
                  <StyledButtonPrimary
                    variant="contained"
                    color="primary"
                    onClick={() => handleApprove(tableMeta.rowData)}
                    label={t('projectOrientationListPage.actions.approve')}
                    disabled={
                      tableMeta.rowData[5] === 'Approved' ||
                      tableMeta.rowData[5] === 'Denied'
                    }
                  />
                </Grid>
                <Grid item>
                  <StyledButtonPrimary
                    variant="contained"
                    color="primary"
                    onClick={() => handleRemove(tableMeta.rowData)}
                    label={t('projectOrientationListPage.actions.delete')}
                  />
                </Grid>
              </Grid>
            );
          }
        }
      }
    }
  ];

  const options = {
    rowHover: false,
    sortOrder: {
      name: 'lastName',
      direction: 'asc'
    }
  };

  return (
    <>
      <Grid container direction="column">
        <Grid item>
          <Typography
            component="h1"
            color="textPrimary"
            className={classes.title}>
            {t('projectOrientationAttendeeListPage.pageTitle')}
          </Typography>
        </Grid>
        <Grid item>
          <StyledTable
            title={t('projectOrientationAttendeeListPage.title')}
            data={attendees}
            columns={columns}
            options={options}
          />
          <AddProjectOrientationAttendeeDialog />
          <BackToTopButton />
        </Grid>
      </Grid>
      <VerifySelfRegisteredAttendeeDialog
        isOpen={isSelfRegistrationVerificationOpen}
        toggleIsOpen={toggleIsSelfRegistrationVerificationOpen}
        selectedAttendee={selectedAttendee}
      />
      <ApproveConfirmationDialog
        approveConfirmationDialogIsOpen={approveConfirmationDialogIsOpen}
        toggleApproveConfirmationDialog={toggleApproveConfirmationDialog}
        selectedAttendee={selectedAttendee}
      />
      <DenyConfirmationDialog
        denyConfirmationDialogIsOpen={denyConfirmationDialogIsOpen}
        toggleDenyConfirmationDialog={toggleDenyConfirmationDialog}
        selectedAttendee={selectedAttendee}
      />
    </>
  );
};
export default withOnlineAccessOnly(withAuthorization(AttendeeListPage));
