import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'clone-deep';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import RemoveIcon from '@mui/icons-material/RemoveCircleOutline';
import { Grid, Box, Typography, IconButton, Tooltip } from '@mui/material';

import useToast from 'hooks/useToast';
import useConfirmDialog from 'hooks/useConfirmDialog';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import AdUserPermissionCard from 'components/ad/AdUserPermissionCard';
import { SET_PARTNER_STATUS_ON_PROJECT } from 'graphql/project';

let deletePartnerList = [];

const setDeletePartnerList = arr => {
  deletePartnerList = arr;
};

export const ProjectPartnerList = ({ partners, projectId }) => {
  const { t } = useTranslation();
  const confirmAction = useConfirmDialog();
  const { displayToast } = useToast();
  const isDeleting = partnerId => {
    return deletePartnerList.includes(partnerId);
  };

  const handleRemovePartnerClick = partner => {
    confirmAction(
      t('projectPartnerList.removePartnerDialog.title'),
      t('projectPartnerList.removePartnerDialog.confirmation', {
        email: partner.email
      }),
      t('projectPartnerList.removePartnerDialog.cancelButton'),
      t('projectPartnerList.removePartnerDialog.submitButton')
    ).then(
      result => {
        setDeletePartnerList([...deletePartnerList, partner.id]);
        handleRemovePartner(partner);
      },
      () => {
        // cancel was pressed
      }
    );
  };

  const [removePartner] = useMutation(SET_PARTNER_STATUS_ON_PROJECT);

  const handleRemovePartner = partner => {
    removePartner({
      variables: {
        projectId: projectId,
        microsoftAdId: partner.microsoftAdId,
        isPartner: false
      }
    })
      .then(data => {
        displayToast(
          t('projectPartnerList.toasts.success', {
            email: partner.email
          }),
          'success'
        );
      })
      .catch(error => {
        console.error(error);
        displayToast(t('projectPartnerList.toasts.error'), 'error');
      })
      .finally(() => {
        setDeletePartnerList(
          deletePartnerList.filter(
            deletedPartner => deletedPartner !== partner.id
          )
        );
      });
  };

  function getSortedPartners(partners) {
    const sortedPartners = cloneDeep(partners);
    return sortedPartners.sort((a, b) => {
      var emailA = a.email.toLowerCase();
      var emailB = b.email.toLowerCase();
      return emailA.localeCompare(emailB);
    });
  }
  const sortedPartners = getSortedPartners(partners);

  return (
    <Grid container justifyContent="space-between" direction="row" spacing={3}>
      {sortedPartners.map(partner => (
        <Grid item xs={12} sm={12} md={12} lg={6} key={partner.id}>
          <AdUserPermissionCard
            user={partner}
            azureClientId={process.env.REACT_APP_AZURE_B2C_CLIENT_ID}
            isLoading={isDeleting(partner.id)}
            actions={
              <Fragment>
                <Tooltip
                  title="Remove"
                  sx={{
                    display: {
                      xs: 'block',
                      sm: 'none',
                      md: 'none',
                      lg: 'none',
                      xl: 'none'
                    }
                  }}>
                  <IconButton
                    data-testid="remove-partner-iconbutton"
                    disabled={isDeleting(partner.id)}
                    onClick={() => handleRemovePartnerClick(partner)}
                    size="large">
                    <RemoveIcon />
                  </IconButton>
                </Tooltip>
                <Box
                  sx={{
                    display: {
                      xs: 'none',
                      sm: 'block',
                      md: 'block',
                      lg: 'block'
                    }
                  }}>
                  <StyledButtonPrimary
                    data-testid={`remove-partner-button-${partner.upn}`}
                    disabled={isDeleting(partner.id)}
                    label={t('projectPartnerList.remove')}
                    onClick={() => handleRemovePartnerClick(partner)}
                  />
                </Box>
              </Fragment>
            }
          />
        </Grid>
      ))}
      {sortedPartners.length < 1 && (
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Typography color="primary">
            {t('projectPartnerList.noPartners')}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

ProjectPartnerList.propTypes = {
  partners: PropTypes.array.isRequired,
  projectId: PropTypes.string.isRequired
};

export default ProjectPartnerList;
