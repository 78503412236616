import React from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import withAuthorization from 'hocs/withAuthorization';
import useProject from 'hooks/useProject';
import LoadingSpinner from 'components/common/LoadingSpinner';
import ErrorNotice from 'components/common/ErrorNotice';
import PageNotFoundNotice from 'components/common/PageNotFoundNotice';
import ProjectInfoCard from 'components/project/ProjectInfoCard';
import ProjectJobSiteCard from 'components/project/ProjectJobSiteCard';
import AhaOptionsCard from 'components/ahas/AhaOptionsCard';
import ProjectPermitCard from 'components/project/ProjectPermitCard';
import withOnlineAccessOnly from 'hocs/withOnlineAccessOnly';
import CompleteProjectCard from 'components/project/CompleteProjectCard';

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: '2rem',
    color: theme.palette.secondary.contrastText,
    fontFamily: 'Roboto, ui-sans-serif, sans-serif',
    fontWeight: 400
  }
}));

const ProjectDetailsPage = () => {
  const classes = useStyles();
  const { projectId } = useParams();
  const { project, error, loading } = useProject(projectId);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    if (error.message?.includes('not found')) {
      return <PageNotFoundNotice />;
    } else {
      return <ErrorNotice />;
    }
  }

  return (
    <>
      <h1 className={classes.title}>{project.name}</h1>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12}>
              <ProjectInfoCard isLoading={loading} project={project} />
            </Grid>
            <Grid item xs={12}>
              <AhaOptionsCard isLoading={loading} project={project} />
            </Grid>
            <Grid item xs={12}>
              <CompleteProjectCard isLoading={loading} project={project} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <ProjectJobSiteCard isLoading={loading} project={project} />
            </Grid>
            <Grid item>
              <ProjectPermitCard isLoading={loading} project={project} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default withOnlineAccessOnly(withAuthorization(ProjectDetailsPage));
