import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CanaryFeaturesContext from 'context/canaryFeatures';
import { canaryFeatures } from 'constants/canaryFeatures';

const features = canaryFeatures[process.env.REACT_APP_ENV];

const CanaryFeaturesProvider = ({ children }) => {
  const [canaryFeatures] = useState(features);

  return (
    <CanaryFeaturesContext.Provider value={{ canaryFeatures }}>
      {children}
    </CanaryFeaturesContext.Provider>
  );
};

CanaryFeaturesProvider.propTypes = {
  children: PropTypes.node
};

export default CanaryFeaturesProvider;
