import React from 'react';
import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import VideoPlayer from '../../VideoPlayer';

const useStyles = makeStyles(theme => ({
  subTitle: { fontSize: '0.875rem' },
  title: { fontSize: '1.5rem' },
  bold: { fontWeight: 'bold' }
}));

const AddOrRemoveAdmin = () => {
  const classes = useStyles();

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography className={classes.subTitle} color="textSecondary">
          Permissions Tutorial
        </Typography>
        <Typography className={classes.title} color="textPrimary">
          Manage Project Admins
        </Typography>
      </Grid>
      <Grid item>
        <Typography color="textPrimary">
          Adding a user as project admin status allows them to:
        </Typography>
        <ul>
          <li>
            <Typography color="textPrimary">
              view and edit project and trade partner information
            </Typography>
          </li>
          <li>
            <Typography color="textPrimary">
              add other admins to the project
            </Typography>
          </li>
        </ul>
      </Grid>
      <Grid item>
        <VideoPlayer
          data-testid="add-or-remove-admin-video"
          url="/tutorials/permissions/AddOrRemoveAdmin.mp4"
          tracks={[
            {
              kind: 'subtitles',
              src: '/tutorials/permissions/AddOrRemoveAdmin.en.vtt',
              srcLang: 'en',
              default: true
            }
          ]}
        />
      </Grid>
    </Grid>
  );
};

export default AddOrRemoveAdmin;
