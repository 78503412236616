import React from 'react';
import { useTheme, useMediaQuery, Stack, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { scoreRangesOrderedBestToWorst } from 'utils/indicatorScoreRange';

const IndicatorScoreGauge = ({ indicator }) => {
  const theme = useTheme();
  const isBreakpointSmall = useMediaQuery(theme.breakpoints.only('sm'));
  const isBreakpointExtraSmall = useMediaQuery(theme.breakpoints.only('xs'));
  const gaugeColorRangeCount = scoreRangesOrderedBestToWorst.length;
  const gaugeValueLowerBound = 0;
  const gaugeValueUpperBound = 0.2;
  const adjustedGaugeValue = Math.max(
    0,
    Math.min(
      (indicator.score - gaugeValueLowerBound) /
        (gaugeValueUpperBound - gaugeValueLowerBound),
      1
    )
  );
  const gaugeScalePx = isBreakpointExtraSmall ? 4 : isBreakpointSmall ? 5 : 10;
  const gaugePointerHeightPx = gaugeScalePx * 2;
  const gaugePointerWidthPx = gaugeScalePx * 3;
  const gaugeRisePerColorRangePx = gaugeScalePx;
  const gaugeTotalRisePx = gaugeColorRangeCount * gaugeRisePerColorRangePx;
  const gaugeStartHeightPx = gaugeScalePx;
  const gaugeHeightPx =
    gaugeStartHeightPx + gaugeTotalRisePx + gaugePointerHeightPx;
  const gaugeRunPerColorRangePx = gaugeScalePx * 10;
  const gaugeWidthPx =
    gaugePointerWidthPx + gaugeRunPerColorRangePx * gaugeColorRangeCount;
  const gaugePointerOverhangX = Math.floor(gaugePointerWidthPx / 2);
  const gaugePointerOriginX =
    gaugePointerOverhangX +
    gaugeRunPerColorRangePx * gaugeColorRangeCount * adjustedGaugeValue;
  const gaugePointerOriginY =
    gaugeStartHeightPx + gaugeTotalRisePx * adjustedGaugeValue;
  const gaugePointerCorners = [
    [gaugePointerOriginX, gaugePointerOriginY - gaugePointerHeightPx / 2],
    [
      gaugePointerOriginX - gaugePointerWidthPx / 2,
      gaugePointerOriginY + gaugePointerHeightPx / 2
    ],
    [
      gaugePointerOriginX + gaugePointerWidthPx / 2,
      gaugePointerOriginY + gaugePointerHeightPx / 2
    ]
  ];

  const cartesianCoordinatesToPolygonPoints = corners =>
    corners
      .map(([x, y]) => [x, gaugeHeightPx - y])
      .map(coordinates => coordinates.join(','))
      .join(' ');

  return (
    <Stack>
      <Tooltip
        title={
          indicator.preIncidentIndicatorCategory.focusOn &&
          `Focus on: ${indicator.preIncidentIndicatorCategory.focusOn}`
        }>
        <svg width={gaugeWidthPx} height={gaugeHeightPx}>
          {scoreRangesOrderedBestToWorst.map((scoreRange, index) => {
            // Draw a polygon for the colored gauge range
            const xLeft =
              gaugePointerOverhangX + gaugeRunPerColorRangePx * index;
            const xRight = xLeft + gaugeRunPerColorRangePx;
            const yBottom = 0;
            const yTopLeft =
              gaugeStartHeightPx + gaugeRisePerColorRangePx * index;
            const yTopRight = yTopLeft + gaugeRisePerColorRangePx;
            const corners = [
              [xLeft, yBottom],
              [xLeft, yTopLeft],
              [xRight, yTopRight],
              [xRight, yBottom]
            ];
            return (
              <polygon
                key={`gauge_${indicator.id}_range_${index}`}
                fill={theme?.palette?.scoreIndicator?.[scoreRange.name]}
                points={cartesianCoordinatesToPolygonPoints(corners)}
              />
            );
          })}
          <polygon
            key={`gauge_${indicator.id}_pointer`}
            strokeWidth={2}
            stroke={theme?.palette?.invariant?.black}
            fill={theme?.palette?.invariant?.lightGray}
            points={cartesianCoordinatesToPolygonPoints(gaugePointerCorners)}
          />
        </svg>
      </Tooltip>
    </Stack>
  );
};

IndicatorScoreGauge.propTypes = {
  indicator: PropTypes.object
};

export default IndicatorScoreGauge;
