import { GET_AHA_TRADE_PARTNER_CATEGORY } from 'graphql/aha/tradePartnerCategory';
import useSoteriaQuery from 'hooks/useSoteriaQuery';

const useAhaTemplateCategory = (
  ahaTradePartnerCategoryId,
  shouldSkip = false
) => {
  const {
    data: { ahaTradePartnerCategory = null } = {},
    loading,
    error
  } = useSoteriaQuery({
    gql: GET_AHA_TRADE_PARTNER_CATEGORY,
    queryHookOptions: {
      skip: shouldSkip || !ahaTradePartnerCategoryId,
      variables: { id: ahaTradePartnerCategoryId }
    }
  });

  return { ahaTradePartnerCategory, loading, error };
};

export default useAhaTemplateCategory;
