import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import MuiPhoneNumber from 'material-ui-phone-number';
import { FormHelperText, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  labelText: { fontSize: '0.75rem', fontWeight: 'bold' },
  errorText: { color: theme.palette.error.main },
  phoneNumberInput: {
    marginTop: 0,
    '& .MuiInputBase-root': {
      paddingLeft: 14
    },
    '& .MuiInputBase-input': {
      paddingLeft: 0
    },
    '& svg': { width: 16, margin: '7px !important' }
  }
}));

const StyledPhoneNumberField = ({
  label,
  meta,
  value,
  onChange,
  disabled,
  required = false,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Fragment>
      {label && (
        <Fragment>
          {!(meta?.dirty && meta?.error) && (
            <Typography color="textSecondary" className={classes.labelText}>
              {required ? `${label} *` : label}
            </Typography>
          )}
          {meta?.dirty && meta?.error && (
            <Typography color="error" className={classes.labelText}>
              {required ? `${label} *` : label}
            </Typography>
          )}
        </Fragment>
      )}
      <MuiPhoneNumber
        inputProps={{ 'data-testid': 'phone-input' }}
        defaultCountry={'us'}
        value={value}
        onChange={onChange}
        // margin="dense"
        disableAreaCodes={true}
        variant="outlined"
        disabled={disabled}
        fullWidth={true}
        className={classes.phoneNumberInput}
        {...rest}
      />
      {meta?.dirty && meta?.error && (
        <FormHelperText className={classes.errorText}>
          {meta.error}
        </FormHelperText>
      )}
    </Fragment>
  );
};

StyledPhoneNumberField.propTypes = {
  label: PropTypes.string,
  meta: PropTypes.any,
  value: PropTypes.any,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool
};

export default StyledPhoneNumberField;
