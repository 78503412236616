import { Button, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import AddIcon from '@mui/icons-material/Add';
import UndoIcon from '@mui/icons-material/ArrowBackIos';
import PrintIcon from '@mui/icons-material/Print';
import SaveIcon from '@mui/icons-material/Save';
import classnames from 'classnames';
import AhaActionsMenu from 'components/ahas/AhaActionsMenu';
import AhaDetailedActivityCard from 'components/ahas/AhaDetailedActivityCard';
import AhaDetailedEquipmentCard from 'components/ahas/AhaDetailedEquipmentCard';
import AhaDetailedStepCard from 'components/ahas/AhaDetailedStepCard';
import AhaPrintableDocument from 'components/ahas/AhaPrintableDocument';
import AhaPrintDisclaimerDialog from 'components/ahas/AhaPrintDisclaimerDialog';
import HighRiskConfirmationDialog from 'components/ahas/HighRiskConfirmationDialog';
import ProjectInformation from 'components/ahas/ProjectInformation';
import RiskAssessmentMatrix from 'components/ahas/RiskAssessmentMatrix';
import UpsertActivity from 'components/ahas/UpsertActivity';
import EditEquipment from 'components/ahas/UpsertEquipment';
import EditStep from 'components/ahas/UpsertStep';
import ConfirmationDialog from 'components/common/ConfirmDialog';
import { detect } from 'detect-browser';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import { useHistory, useParams } from 'react-router-dom';
import NavigationPrompt from 'react-router-navigation-prompt';
import { useReactToPrint } from 'react-to-print';
import StyledConfirmationSwitch from 'shared/ConfirmationSwitch';
import StyledDeleteConfirmation from 'shared/DeleteConfirmation';
import StyledDiscardChangesDialog from 'shared/DiscardChangesDialog';
import StyledNotice from 'shared/Notice';

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2, 4),
    [theme.breakpoints.down('lg')]: { padding: theme.spacing(1) }
  },
  title: {
    fontSize: '2rem',
    textAlign: 'center',
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.5rem',
      marginTop: theme.spacing(1)
    }
  },
  button: {
    borderRadius: 16,
    backgroundColor: theme.palette.primary.contrastText,
    border: '1px solid' + theme.palette.secondary.dark
  },
  buttonLabel: {
    fontSize: '0.75rem',
    fontWeight: 'bold',
    marginTop: 2,
    [theme.breakpoints.down('md')]: { fontSize: '0.625rem' }
  },
  ahaContainer: { border: '1px solid' + theme.borderGray },
  row: { border: '1px solid' + theme.borderGray },
  headerRow: {
    border: '1px solid' + theme.borderGray,
    borderBottom: '2px solid' + theme.borderGray
  },
  equipmentHeaderRow: {
    borderTop: '3px solid' + theme.borderGray,
    border: '1px solid' + theme.borderGray
  },
  columnLabel: {
    textAlign: 'center',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    paddingTop: theme.spacing(0.5)
  },
  projectInformationContainer: {
    backgroundColor: theme.palette.background.paper,
    borderRight: '1px solid' + theme.borderGray,
    [theme.breakpoints.down('lg')]: { borderRight: 'none' }
  },
  racContainer: { backgroundColor: theme.palette.background.paper },
  jobStepsHeaderContainer: {
    backgroundColor: theme.palette.background.paper
  },
  equipmentHeaderContainer: {
    backgroundColor: theme.palette.background.paper
  },
  addStepButtonContainer: { margin: theme.spacing(1, 0, 3, 0) },
  addEquipmentButtonContainer: { margin: theme.spacing(1, 0, 3, 0) },
  discardChangesButton: { borderRadius: 16, marginLeft: theme.spacing(2) },
  noContentMessage: { marginTop: theme.spacing(1) },
  saveButton: { borderRadius: 16 },
  printButton: { borderRadius: 16, marginRight: theme.spacing(1) },
  buttonIcon: { color: theme.palette.icon.main },
  link: { '&:hover': { cursor: 'pointer' } },
  statusLabel: { fontSize: '0.75rem' },
  rejected: { color: theme.palette.error.main, fontWeight: 'bold' },
  approvedOrCompleted: {
    color: theme.palette.success.main,
    fontWeight: 'bold'
  },
  icon: {
    [theme.breakpoints.down('md')]: { fontSize: '1rem' }
  }
}));

const AhaDetailedViewEditor = ({
  isGlobal = false,
  hasChanges,
  hasExternalChanges,
  ahaTemplate,
  originalAhaTemplate,
  setAhaTemplate,
  handleSaveAhaTemplate,
  resetAhaTemplateChanges,
  isLoadingUpdate,
  isReadOnly = false,
  ahaReview
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const history = useHistory();
  const { projectId, ahaId } = useParams();
  const [selectedStep, setSelectedStep] = useState(null);
  const [selectedEquipment, setSelectedEquipment] = useState(null);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [isUpsertStepDialogOpen, setIsUpsertStepDialogOpen] = useState(false);
  const [
    isUpsertEquipmentDialogOpen,
    setIsUpsertEquipmentDialogOpen
  ] = useState(false);
  const [isUpsertActivityDialogOpen, setIsUpsertActivityDialogOpen] = useState(
    false
  );
  const [discardChangesDialogIsOpen, setDiscardChangesDialogIsOpen] = useState(
    false
  );
  const [isDiscardChangesConfirmed, setIsDiscardChangesConfirmed] = useState(
    false
  );
  const [isDeleteConfirmed, setIsDeleteConfirmed] = useState(false);
  const [
    isConfirmStepDeleteDialogOpen,
    setIsConfirmStepDeleteDialogOpen
  ] = useState(false);
  const [
    isConfirmEquipmentDeleteDialogOpen,
    setIsConfirmEquipmentDeleteDialogOpen
  ] = useState(false);
  const [
    isConfirmActivityDeleteDialogOpen,
    setIsConfirmActivityDeleteDialogOpen
  ] = useState(false);
  const [
    isHighRiskConfirmationDialogRendered,
    renderHighRiskConfirmationDialog
  ] = useState(false);
  const componentRef = useRef();
  const browser = detect();
  const printReact = useReactToPrint({
    content: () => componentRef.current
  });
  const [printDisclaimerIsOpen, setPrintDisclaimerIsOpen] = useState(false);
  const handlePrint = () => {
    if (browser && browser.name === 'safari') {
      setPrintDisclaimerIsOpen(true);
    } else {
      printReact();
    }
  };
  const [isCheckingForUpdates, setIsCheckingForUpdates] = useState(false);

  const checkForHighRiskSteps = () => {
    setIsCheckingForUpdates(true);
    const summaryViewRoute = isGlobal
      ? `/ahas/${ahaId}/edit?view=detailed`
      : `/projects/${projectId}/ahas/hensel-phelps-aha/${ahaId}/edit?view=summary`;

    const activeSteps = ahaTemplate?.ahaSteps?.filter(
      step => step.isActive === true
    );
    const extremelyHighRiskStep = activeSteps?.map(step =>
      step.riskAssessmentCode?.includes('ExtremelyHigh')
    );
    const highRiskStep = activeSteps?.map(step =>
      step.riskAssessmentCode?.includes('High')
    );

    if (hasChanges) {
      if (extremelyHighRiskStep.includes(true) || highRiskStep.includes(true)) {
        renderHighRiskConfirmationDialog(true);
      } else {
        handleSave();
      }
    } else {
      history.push(summaryViewRoute);
    }
    setIsCheckingForUpdates(false);
  };

  const handleSave = () => {
    let variables = null;

    if (isGlobal) {
      variables = {
        input: {
          id: ahaTemplate.id,
          type: ahaTemplate.type,
          ahaEquipments:
            ahaTemplate?.ahaEquipments?.map(
              ({ __typename, ...restEquipment }) => ({ ...restEquipment })
            ) ?? [],
          ahaSteps:
            ahaTemplate?.ahaSteps?.map(
              ({
                __typename,
                ahaGlobalStepCategory,
                ahaStepReferences,
                ...restStep
              }) => {
                return {
                  ...restStep,
                  ahaStepReferences: ahaStepReferences.map(
                    ({ id, ahaStep, __typename, ...restReference }) => ({
                      ...restReference
                    })
                  )
                };
              }
            ) ?? [],
          ahaActivitys:
            ahaTemplate?.ahaActivitys?.map(
              ({
                __typename,
                created,
                lastModified,
                ahaTemplate,
                ...restActivities
              }) => ({ ...restActivities })
            ) ?? []
        }
      };
    } else {
      variables = {
        input: {
          contractor: ahaTemplate?.ahaProjectTemplate?.contractor,
          id: ahaTemplate?.ahaProjectTemplate?.id,
          location: ahaTemplate?.ahaProjectTemplate?.location,
          notes: ahaTemplate?.ahaProjectTemplate?.notes,
          template: {
            ahaEquipments:
              ahaTemplate?.ahaEquipments?.map(
                ({ __typename, ...restEquipment }) => ({ ...restEquipment })
              ) ?? [],
            ahaSteps:
              ahaTemplate?.ahaSteps?.map(
                ({
                  __typename,
                  ahaGlobalStepCategory,
                  ahaStepReferences,
                  ...restStep
                }) => {
                  return {
                    ...restStep,
                    ahaStepReferences: ahaStepReferences.map(
                      ({ id, __typename, ahaStep, ...restReference }) => ({
                        ...restReference
                      })
                    )
                  };
                }
              ) ?? [],
            ahaActivitys:
              ahaTemplate?.ahaActivitys?.map(
                ({
                  __typename,
                  created,
                  lastModified,
                  ahaTemplate,
                  ...restActivities
                }) => ({ ...restActivities })
              ) ?? [],
            id: ahaTemplate?.id,
            type: ahaTemplate?.type
          }
        }
      };
    }

    return handleSaveAhaTemplate(variables);
  };

  const sortByOrder = (a, b) => a.order - b.order;

  const detailedJobSteps = ahaTemplate?.ahaSteps.filter(
    step => step.isActive === true
  )
    ? [...ahaTemplate.ahaSteps]
        .sort(sortByOrder)
        .filter(step => step.isActive === true)
    : [];
  const detailedEquipments = ahaTemplate?.ahaEquipments.filter(
    equipment => equipment.isActive === true
  )
    ? [...ahaTemplate.ahaEquipments]
        .sort(sortByOrder)
        .filter(equipment => equipment.isActive === true)
    : [];
  const detailedActivities = ahaTemplate?.ahaActivitys;

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    result.forEach((item, index) => {
      item.order = index;
    });

    return result;
  };

  const onDragEnd = (result, type) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const isStepType = type === 'steps';

    const newResults = reorder(
      isStepType ? detailedJobSteps : detailedEquipments,
      result.source.index,
      result.destination.index
    );

    setAhaTemplate({
      ...ahaTemplate,
      ...(isStepType ? { ahaSteps: newResults } : { ahaEquipments: newResults })
    });
  };

  const handleDiscardChanges = event => {
    setIsDiscardChangesConfirmed(event.target.checked);
  };

  const handleDiscard = () => {
    resetAhaTemplateChanges();
    setIsDiscardChangesConfirmed(false);
    setDiscardChangesDialogIsOpen(false);
  };

  const handleCloseConfirmStepDelete = () => {
    setIsConfirmStepDeleteDialogOpen(false);
  };

  const handleCloseConfirmEquipmentDelete = () => {
    setIsConfirmEquipmentDeleteDialogOpen(false);
  };

  const handleCloseConfirmActivityDelete = () => {
    setIsConfirmActivityDeleteDialogOpen(false);
  };

  const handleChange = event => {
    setIsDeleteConfirmed(event.target.checked);
  };

  const handleDeleteSubmit = type => {
    const isStepType = type === 'step';
    const isEquipmentType = type === 'equipment';

    const identifier = isStepType
      ? selectedStep.id ?? selectedStep.transactionKey
      : isEquipmentType
      ? selectedEquipment.id ?? selectedEquipment.transactionKey
      : selectedActivity.id ?? selectedActivity.transactionKey;

    setAhaTemplate({
      ...ahaTemplate,
      ...(isStepType
        ? {
            ahaSteps: detailedJobSteps.filter(
              step =>
                step.id !== identifier && step.transactionKey !== identifier
            )
          }
        : isEquipmentType
        ? {
            ahaEquipments: detailedEquipments.filter(
              equipment =>
                equipment.id !== identifier &&
                equipment.transactionKey !== identifier
            )
          }
        : {
            ahaActivitys: detailedActivities.filter(
              activity =>
                activity.id !== identifier &&
                activity.transactionKey !== identifier
            )
          })
    });

    setIsConfirmStepDeleteDialogOpen(false);
    setIsConfirmEquipmentDeleteDialogOpen(false);
    setIsConfirmActivityDeleteDialogOpen(false);
    setIsDeleteConfirmed(false);
    setSelectedEquipment(null);
    setSelectedStep(null);
    setSelectedActivity(null);
  };

  const handleAddJobStepButtonClick = () => {
    setIsUpsertStepDialogOpen(true);
  };

  const handleAddEquipmentButtonClick = () => {
    setIsUpsertEquipmentDialogOpen(true);
  };

  const handleAddActivityButtonClick = () => {
    setIsUpsertActivityDialogOpen(true);
  };

  const renderUnsavedChangesConfirmationDialog = () => {
    return (
      <NavigationPrompt
        when={(currentLocation, nextLocation) => {
          return (
            !nextLocation ||
            !nextLocation.pathname.startsWith(currentLocation.pathname) ||
            currentLocation.search !== nextLocation.search
          );
        }}>
        {({ onConfirm, onCancel }) => (
          <ConfirmationDialog
            show={true}
            cancel={onCancel}
            confirmation={t('ahaDetailedViewEditor.discardChangesConfirmation')}
            proceed={() => {
              resetAhaTemplateChanges();
              onConfirm();
            }}
            options={{
              theme,
              title: 'Unsaved AHA Changes',
              rejectLabel: 'Back',
              confirmLabel: 'Discard'
            }}
          />
        )}
      </NavigationPrompt>
    );
  };

  const isRejectedReview = ahaReview?.status === 'Rejected';

  const isApprovedOrCompletedReview =
    ahaReview?.status === 'Reviewed' || ahaReview?.status === 'Completed';

  return (
    <>
      <LoadingOverlay
        active={isLoadingUpdate || isCheckingForUpdates}
        spinner
        fadeSpeed={50}
        styles={{
          overlay: base => ({
            ...base,
            background: theme.palette.background.overlay
          }),
          spinner: base => ({
            ...base,
            width: '50px',
            '& svg circle': {
              stroke: theme.palette.primary.main
            }
          })
        }}>
        <Grid container className={classes.container}>
          <Grid item xs={12}>
            <Grid container justifyContent="space-between">
              <Grid item>
                {!isReadOnly && (
                  <Grid container direction="row" spacing={1}>
                    <Grid item>
                      <Tooltip title="Back">
                        <IconButton
                          size="small"
                          onClick={() =>
                            isGlobal
                              ? history.push(`/ahas/${ahaId}/edit?view=summary`)
                              : history.push(
                                  `/projects/${projectId}/ahas/hensel-phelps-aha/${ahaId}/edit?view=summary`
                                )
                          }>
                          <UndoIcon color="action" className={classes.icon} />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    {!isGlobal && (
                      <Grid item>
                        <Tooltip title="Print">
                          <IconButton
                            size="small"
                            onClick={handlePrint}
                            disabled={isLoadingUpdate}>
                            <PrintIcon
                              color="action"
                              className={classes.icon}
                            />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    )}
                    {!hasChanges && (
                      <Grid item>
                        <Tooltip
                          title={
                            !hasChanges
                              ? 'There are no changes to save'
                              : 'Save'
                          }>
                          <span>
                            <IconButton
                              size="small"
                              onClick={checkForHighRiskSteps}
                              disabled={!hasChanges}>
                              <SaveIcon
                                color="action"
                                className={classes.icon}
                              />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </Grid>
                    )}
                    {hasChanges && (
                      <Grid item>
                        <Button
                          onClick={checkForHighRiskSteps}
                          disabled={!hasChanges}
                          className={classes.saveButton}
                          variant="contained"
                          color="primary"
                          size="small">
                          <Typography className={classes.buttonLabel}>
                            Save
                          </Typography>
                        </Button>
                        <Button
                          onClick={() => setDiscardChangesDialogIsOpen(true)}
                          disabled={isLoadingUpdate}
                          className={classes.discardChangesButton}
                          variant="outlined"
                          color="primary"
                          size="small">
                          <Typography className={classes.buttonLabel}>
                            Discard Changes
                          </Typography>
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                )}
              </Grid>
              <Grid item>
                <AhaActionsMenu
                  ahaTemplate={originalAhaTemplate}
                  isGlobal={isGlobal}
                  ahaReview={ahaReview}
                  isReadOnlyReview={isReadOnly}
                  hasChanges={hasChanges}
                />
                {isReadOnly && ahaReview?.status && (
                  <Typography
                    color="textPrimary"
                    className={classes.statusLabel}>
                    Status:{' '}
                    <span
                      className={classnames({
                        [`${classes.rejected}`]: isRejectedReview,
                        [`${classes.approvedOrCompleted}`]: isApprovedOrCompletedReview
                      })}>
                      {ahaReview.status}
                    </span>
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography color="textPrimary" className={classes.title}>
              Activity Hazard Analysis (AHA)
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} className={classes.ahaContainer}>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={5}
                    lg={5}
                    className={classes.projectInformationContainer}>
                    <ProjectInformation
                      ahaTemplate={ahaTemplate}
                      setAhaTemplate={setAhaTemplate}
                      isGlobal={isGlobal}
                      isReadOnlyReview={isReadOnly}
                      ahaReview={ahaReview}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={7}
                    lg={7}
                    className={classes.racContainer}>
                    <RiskAssessmentMatrix ahaTemplate={ahaTemplate} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    className={classes.jobStepsHeaderContainer}>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={2}
                        lg={2}
                        className={classes.headerRow}>
                        <Typography
                          color="textSecondary"
                          className={classes.columnLabel}>
                          Job Steps
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        className={classes.headerRow}
                        sx={{
                          display: { xs: 'none', sm: 'none', md: 'block' }
                        }}>
                        <Typography
                          color="textSecondary"
                          className={classes.columnLabel}>
                          Hazards
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={7}
                        className={classes.headerRow}
                        sx={{
                          display: { xs: 'none', sm: 'none', md: 'block' }
                        }}>
                        <Typography
                          color="textSecondary"
                          className={classes.columnLabel}>
                          Controls
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        className={classes.headerRow}
                        sx={{
                          display: { xs: 'none', sm: 'none', md: 'block' }
                        }}>
                        <Typography
                          color="textSecondary"
                          className={classes.columnLabel}>
                          RAC
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    {detailedJobSteps?.length > 0 && (
                      <>
                        <DragDropContext
                          onDragEnd={results => onDragEnd(results, 'steps')}>
                          <Droppable droppableId="steps">
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}>
                                {detailedJobSteps?.map((step, index) => (
                                  <Draggable
                                    isDragDisabled={isReadOnly}
                                    key={
                                      step.transactionKey ??
                                      step.id ??
                                      step.name
                                    }
                                    draggableId={
                                      step.transactionKey ??
                                      step.id ??
                                      step.name
                                    }
                                    index={index}>
                                    {(provided, snapshot) => (
                                      <div
                                        style={provided.draggableProps.style}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}>
                                        <AhaDetailedStepCard
                                          key={step.id}
                                          step={step}
                                          setIsEditStepDialogOpen={
                                            setIsUpsertStepDialogOpen
                                          }
                                          setIsConfirmStepDeleteDialogOpen={
                                            setIsConfirmStepDeleteDialogOpen
                                          }
                                          setSelectedStep={setSelectedStep}
                                          isReadOnlyReview={isReadOnly}
                                        />
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                      </>
                    )}
                    {detailedJobSteps?.length < 1 && (
                      <Typography
                        color="textPrimary"
                        className={classes.noContentMessage}>
                        There are no job steps.
                      </Typography>
                    )}
                  </Grid>
                  {!isReadOnly && (
                    <Grid
                      item
                      xs={12}
                      className={classes.addStepButtonContainer}>
                      <Grid container justifyContent="flex-end">
                        <Grid item>
                          <Button
                            className={classes.button}
                            size="small"
                            variant="contained"
                            color="secondary"
                            startIcon={
                              <AddIcon className={classes.buttonIcon} />
                            }
                            onClick={handleAddJobStepButtonClick}>
                            <Typography
                              color="textSecondary"
                              className={classes.buttonLabel}>
                              Add Step
                            </Typography>
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    className={classes.equipmentHeaderContainer}>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={2}
                        lg={2}
                        className={classes.equipmentHeaderRow}>
                        <Typography
                          color="textSecondary"
                          className={classes.columnLabel}>
                          Equipment
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        className={classes.equipmentHeaderRow}
                        sx={{
                          display: { xs: 'none', sm: 'none', md: 'block' }
                        }}>
                        <Typography
                          color="textSecondary"
                          className={classes.columnLabel}>
                          Training
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={8}
                        className={classes.equipmentHeaderRow}
                        sx={{
                          display: { xs: 'none', sm: 'none', md: 'block' }
                        }}>
                        <Typography
                          color="textSecondary"
                          className={classes.columnLabel}>
                          Inspection Requirements
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    {detailedEquipments?.length > 0 && (
                      <>
                        <DragDropContext
                          onDragEnd={results =>
                            onDragEnd(results, 'equipment')
                          }>
                          <Droppable droppableId="equipment">
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}>
                                {detailedEquipments?.map((equipment, index) => (
                                  <Draggable
                                    isDragDisabled={isReadOnly}
                                    key={
                                      equipment.transactionKey ??
                                      equipment.id ??
                                      equipment.name
                                    }
                                    draggableId={
                                      equipment.transactionKey ??
                                      equipment.id ??
                                      equipment.name
                                    }
                                    index={index}>
                                    {(provided, snapshot) => (
                                      <div
                                        style={provided.draggableProps.style}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}>
                                        <AhaDetailedEquipmentCard
                                          key={equipment.id}
                                          equipment={equipment}
                                          setIsEditEquipmentDialogOpen={
                                            setIsUpsertEquipmentDialogOpen
                                          }
                                          setIsConfirmEquipmentDeleteDialogOpen={
                                            setIsConfirmEquipmentDeleteDialogOpen
                                          }
                                          setSelectedEquipment={
                                            setSelectedEquipment
                                          }
                                          isReadOnlyReview={isReadOnly}
                                        />
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                      </>
                    )}
                    {detailedEquipments?.length < 1 && (
                      <Typography
                        color="textPrimary"
                        className={classes.noContentMessage}>
                        There is no equipment.
                      </Typography>
                    )}
                  </Grid>
                  {!isReadOnly && (
                    <Grid
                      item
                      xs={12}
                      className={classes.addEquipmentButtonContainer}>
                      <Grid container justifyContent="flex-end">
                        <Grid item>
                          <Button
                            className={classes.button}
                            size="small"
                            variant="contained"
                            color="secondary"
                            startIcon={
                              <AddIcon className={classes.buttonIcon} />
                            }
                            onClick={handleAddEquipmentButtonClick}>
                            <Typography
                              color="textSecondary"
                              className={classes.buttonLabel}>
                              Add Equipment
                            </Typography>
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    className={classes.equipmentHeaderContainer}>
                    <Grid container>
                      <Grid item xs={12} className={classes.equipmentHeaderRow}>
                        <Typography
                          color="textSecondary"
                          className={classes.columnLabel}>
                          Activities
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  {detailedActivities?.length > 0 && (
                    <Grid item xs={12}>
                      {detailedActivities.map((activity, index) => (
                        <AhaDetailedActivityCard
                          key={index}
                          activity={activity}
                          setIsEditActivityDialogOpen={
                            setIsUpsertActivityDialogOpen
                          }
                          setIsConfirmActivityDeleteDialogOpen={
                            setIsConfirmActivityDeleteDialogOpen
                          }
                          setSelectedActivity={setSelectedActivity}
                          isReadOnlyReview={isReadOnly}
                          isGlobal={isGlobal}
                        />
                      ))}
                    </Grid>
                  )}
                  <>
                    {detailedActivities?.length < 1 && (
                      <Typography
                        color="textPrimary"
                        className={classes.noContentMessage}>
                        There are no activities.
                      </Typography>
                    )}
                    {!isReadOnly && (
                      <Grid
                        item
                        xs={12}
                        className={classes.addEquipmentButtonContainer}>
                        <Grid container justifyContent="flex-end">
                          <Grid item>
                            <Button
                              className={classes.button}
                              size="small"
                              variant="contained"
                              color="secondary"
                              startIcon={
                                <AddIcon className={classes.buttonIcon} />
                              }
                              onClick={handleAddActivityButtonClick}>
                              <Typography
                                color="textSecondary"
                                className={classes.buttonLabel}>
                                Add Activity
                              </Typography>
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <EditStep
          isOpen={isUpsertStepDialogOpen}
          setIsOpen={setIsUpsertStepDialogOpen}
          stepToEdit={selectedStep}
          setStepToEdit={setSelectedStep}
          isGlobal={ahaTemplate?.ahaProjectTemplate ? false : true}
          setAhaTemplate={setAhaTemplate}
          ahaTemplate={ahaTemplate}
        />
        <EditEquipment
          isOpen={isUpsertEquipmentDialogOpen}
          setIsOpen={setIsUpsertEquipmentDialogOpen}
          equipmentToEdit={selectedEquipment}
          setEquipmentToEdit={setSelectedEquipment}
          isGlobal={ahaTemplate?.ahaProjectTemplate ? false : true}
          setAhaTemplate={setAhaTemplate}
          ahaTemplate={ahaTemplate}
        />
        <UpsertActivity
          isOpen={isUpsertActivityDialogOpen}
          setIsOpen={setIsUpsertActivityDialogOpen}
          isGlobal={ahaTemplate?.ahaProjectTemplate ? false : true}
          refetchCurrentQueries={() => {}}
          activityToEdit={selectedActivity}
          setActivityToEdit={setSelectedActivity}
          setAhaTemplate={setAhaTemplate}
          ahaTemplate={ahaTemplate}
        />
        <StyledDeleteConfirmation
          title={'Delete Step'}
          dialogIsOpen={isConfirmStepDeleteDialogOpen}
          isLoading={false}
          handleClose={handleCloseConfirmStepDelete}
          type="notice"
          warningMessage={t('ahaSummaryWorkspace.delete.step', {
            name: selectedStep?.name
          })}
          isConfirmed={isDeleteConfirmed}
          handleChange={handleChange}
          handleSubmit={() => handleDeleteSubmit('step')}
        />
        <StyledDeleteConfirmation
          title={'Delete Equipment'}
          dialogIsOpen={isConfirmEquipmentDeleteDialogOpen}
          isLoading={false}
          handleClose={handleCloseConfirmEquipmentDelete}
          type="notice"
          warningMessage={t('ahaSummaryWorkspace.delete.equipment', {
            name: selectedEquipment?.name
          })}
          isConfirmed={isDeleteConfirmed}
          handleChange={handleChange}
          handleSubmit={() => handleDeleteSubmit('equipment')}
        />
        <StyledDeleteConfirmation
          title={'Delete Activity'}
          dialogIsOpen={isConfirmActivityDeleteDialogOpen}
          isLoading={false}
          handleClose={handleCloseConfirmActivityDelete}
          type="notice"
          warningMessage={t('ahaSummaryWorkspace.delete.competency', {
            name: selectedActivity?.name
          })}
          isConfirmed={isDeleteConfirmed}
          handleChange={handleChange}
          handleSubmit={() => handleDeleteSubmit('activity')}
        />
        <StyledDiscardChangesDialog
          discardChangesDialogIsOpen={discardChangesDialogIsOpen}
          setDiscardChangesDialogIsOpen={setDiscardChangesDialogIsOpen}
          handleDiscard={handleDiscard}
          shouldBeDisabled={!isDiscardChangesConfirmed}
          content={
            <Grid container>
              <Grid item xs={12}>
                <StyledNotice
                  message={
                    'You are discarding unsaved changes. Once discarded, this action cannot be undone.'
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <StyledConfirmationSwitch
                  disabled={isLoadingUpdate}
                  checked={isDiscardChangesConfirmed}
                  onChange={handleDiscardChanges}
                  value={'isDiscardChangesConfirmed'}
                />
              </Grid>
            </Grid>
          }
        />
      </LoadingOverlay>
      <HighRiskConfirmationDialog
        isOpen={isHighRiskConfirmationDialogRendered}
        setIsOpen={renderHighRiskConfirmationDialog}
        handleConfirm={handleSave}
        isLoading={isLoadingUpdate}
      />
      <AhaPrintDisclaimerDialog
        isOpen={printDisclaimerIsOpen}
        setIsOpen={setPrintDisclaimerIsOpen}
        ahaTemplate={ahaTemplate}
        isGlobal={isGlobal}
      />
      <div style={{ display: 'none' }}>
        {!isGlobal && (
          <AhaPrintableDocument
            ref={componentRef}
            ahaTemplate={ahaTemplate}
            isGlobal={isGlobal}
          />
        )}
      </div>
      {hasChanges && renderUnsavedChangesConfirmationDialog()}
    </>
  );
};

AhaDetailedViewEditor.propTypes = {
  isGlobal: PropTypes.bool,
  hasChanges: PropTypes.bool.isRequired,
  hasExternalChanges: PropTypes.bool.isRequired,
  ahaTemplate: PropTypes.object,
  originalAhaTemplate: PropTypes.object,
  setAhaTemplate: PropTypes.func.isRequired,
  handleSaveAhaTemplate: PropTypes.func.isRequired,
  resetAhaTemplateChanges: PropTypes.func.isRequired,
  isLoadingUpdate: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  ahaReview: PropTypes.object
};

export default AhaDetailedViewEditor;
