import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { TextField, FormHelperText, Typography, useTheme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  labelText: { fontSize: '0.75rem', fontWeight: 'bold' },
  errorText: { color: theme.palette.error.main }
}));

const StyledInput = ({
  input: { name, label: inputLabel, onChange, value, ...restInput },
  meta,
  label,
  placeholder,
  required = false,
  disabled,
  shouldDisplayAsError = false,
  autoFocus = false,
  errorBeforeTouched = false,
  ...rest
}) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Fragment>
      {label && (
        <Fragment>
          {!(errorBeforeTouched || meta.touched) &&
            !disabled &&
            !shouldDisplayAsError && (
              <Typography color="textSecondary" className={classes.labelText}>
                {required ? `${label} *` : label}
              </Typography>
            )}
          {(errorBeforeTouched || meta.touched) &&
            !meta.error &&
            !disabled &&
            !shouldDisplayAsError && (
              <Typography color="textSecondary" className={classes.labelText}>
                {required ? `${label} *` : label}
              </Typography>
            )}
          {(errorBeforeTouched || meta.touched) &&
            meta.error &&
            !disabled &&
            !shouldDisplayAsError && (
              <Typography color="error" className={classes.labelText}>
                {required ? `${label} *` : label}
              </Typography>
            )}
          {shouldDisplayAsError && (
            <Typography color="error" className={classes.labelText}>
              {required ? `${label} *` : label}
            </Typography>
          )}
          {disabled && !shouldDisplayAsError && (
            <Typography color="textSecondary" className={classes.labelText}>
              {label}
            </Typography>
          )}
        </Fragment>
      )}
      <TextField
        color="primary"
        name={name}
        label={label ? null : inputLabel}
        value={value}
        margin="dense"
        variant="outlined"
        onChange={onChange}
        placeholder={placeholder}
        inputProps={{
          ...restInput,
          ...{ 'aria-label': label ?? inputLabel },
          'aria-required': required
        }}
        error={
          shouldDisplayAsError ||
          (meta.error && (errorBeforeTouched || meta.touched))
        }
        fullWidth={true}
        required={required}
        disabled={disabled}
        autoFocus={autoFocus}
        {...rest}
        style={{
          marginTop: 0,
          backgroundColor: theme.selectAutoCompleteControlBackgroundColor
        }}
      />
      {(errorBeforeTouched || meta.touched) && meta.error && (
        <FormHelperText className={classes.errorText}>
          {meta.error}
        </FormHelperText>
      )}
    </Fragment>
  );
};

StyledInput.propTypes = {
  input: PropTypes.any,
  meta: PropTypes.any,
  inputOnChange: PropTypes.func,
  isValid: PropTypes.func,
  label: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  shouldDisplayAsError: PropTypes.bool,
  autoFocus: PropTypes.bool
};

export default StyledInput;
