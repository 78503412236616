import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useField } from 'react-final-form-hooks';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography, Checkbox } from '@mui/material';

import useFormValidation from 'hooks/useFormValidation';
import StyledStep from 'shared/Step';

const useStyles = makeStyles(theme => ({
  labelText: { fontWeight: 'bold', paddingRight: 5 },
  container: {
    backgroundColor: theme.palette.background.default,
    borderRadius: 4,
    padding: 15,
    marginBottom: 15
  }
}));

const ConfirmHpEmployeeStep = ({ selectedHenselPhelpsEmployee, form }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isRequired } = useFormValidation();

  const personnelConfirmation = useField(
    'personnelConfirmation',
    form,
    isRequired
  );

  const renderRow = (label, value) => {
    return (
      <Grid item>
        <Grid container direction="row">
          <Grid item>
            <Typography className={classes.labelText}>{label}</Typography>
          </Grid>
          <Grid item>
            <Typography>{value}</Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  };
  const renderConfirmationFields = () => {
    return (
      <>
        {renderRow(
          t('linkHpPersonnelDialog.confirmHpEmployeeStep.tradePartner'),
          t('linkHpPersonnelDialog.confirmHpEmployeeStep.henselPhelps')
        )}
        {renderRow(
          t('linkHpPersonnelDialog.confirmHpEmployeeStep.name'),
          selectedHenselPhelpsEmployee.name
        )}

        {renderRow(
          t('linkHpPersonnelDialog.confirmHpEmployeeStep.jobTitle'),
          selectedHenselPhelpsEmployee.jobTitle
        )}
        {renderRow(
          t('linkHpPersonnelDialog.confirmHpEmployeeStep.email'),
          selectedHenselPhelpsEmployee.email
        )}
      </>
    );
  };

  return (
    <StyledStep title={t('linkHpPersonnelDialog.confirmHpEmployeeStep.title')}>
      <Grid container direction="row" alignItems="center">
        <Typography>
          {t('linkHpPersonnelDialog.confirmHpEmployeeStep.confirmation')}
        </Typography>
        <Checkbox
          checked={
            personnelConfirmation.input.value === ''
              ? false
              : personnelConfirmation.input.value
          }
          onChange={personnelConfirmation.input.onChange}
          name="confirmationCheck"
          color="primary"
          inputProps={{
            'data-testid': 'confirmation-checkBox'
          }}
        />
      </Grid>
      <Grid
        className={classes.container}
        container
        direction="column"
        spacing={1}>
        <Grid item>{renderConfirmationFields()}</Grid>
      </Grid>
    </StyledStep>
  );
};

ConfirmHpEmployeeStep.propTypes = {
  selectedHenselPhelpsEmployee: PropTypes.object,
  form: PropTypes.object
};

export default ConfirmHpEmployeeStep;
