import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import FlagIcon from '@mui/icons-material/Flag';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import CancelIcon from '@mui/icons-material/Cancel';
import { useForm, useField } from 'react-final-form-hooks';
import { useMutation } from '@apollo/react-hooks';
import makeStyles from '@mui/styles/makeStyles';
import {
  Grid,
  Typography,
  Card,
  CardContent,
  LinearProgress
} from '@mui/material';

import useFormValidation from 'hooks/useFormValidation';
import useToast from 'hooks/useToast';
import StyledInput from 'shared/Input';
import StyledSelect from 'shared/Select';
import StyledButtonGroup from 'shared/Buttons/ButtonGroup';
import { FLAG_STATUS_OPTIONS, ACHEIVEMENT } from 'constants/personnelFlags';
import { UPDATE_PERSONNEL } from 'graphql/personnel';

const useStyles = makeStyles(theme => ({
  toImprove: { color: theme.palette.flag.toImprove },
  resolved: { color: theme.palette.flag.resolved },
  achievement: { color: theme.palette.flag.achievement },
  attentionRequired: { color: theme.palette.flag.attentionRequired },
  card: {
    margin: theme.spacing(2, 0),
    backgroundColor: theme.palette.background.default,
    boxShadow: 'none'
  }
}));

const FlagCard = ({ flag, tradePartnerPersonnel }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);
  const [shouldDelete, setShouldDelete] = useState(false);
  const { isRequired } = useFormValidation();
  const { displayToast } = useToast();
  const [updatePersonnel, { loading: isLoading }] = useMutation(
    UPDATE_PERSONNEL
  );

  const { form, values, pristine } = useForm({
    /* istanbul ignore next */
    onSubmit: () => {}, // this function required for useForm but is not used
    initialValues: {
      reason: flag?.reason ?? '',
      status: flag?.status ?? '',
      resolution: flag?.resolution ?? ''
    }
  });

  const status = useField('status', form, isRequired);
  const reason = useField('reason', form, isRequired);
  const resolution = useField('resolution', form, isRequired);

  const canSubmit = (() => {
    const { errors } = form.getState();
    return !(pristine || errors.reason || errors.status || isLoading);
  })();

  const canSubmitForResolvedFlag = (() => {
    const { errors } = form.getState();
    return !(
      pristine ||
      errors.reason ||
      errors.status ||
      errors.resolution ||
      isLoading
    );
  })();

  const handleDeleteFlag = () => {
    updatePersonnel({
      variables: {
        input: {
          id: tradePartnerPersonnel.personnel.id,
          flagsToAdd: [],
          flagsToDelete: [{ id: flag.id }],
          flagsToUpdate: [],
          trainingsToAdd: [],
          trainingsToDelete: [],
          trainingsToUpdate: []
        }
      }
    })
      .then(({ data: { updatePersonnel: personnel } }) => {
        handleClose();
        displayToast(t('flagCard.toasts.remove.success'), 'success');
      })
      .catch(error => {
        console.error('Delete Flag Error: ', error);
        displayToast(t('flagCard.toasts.remove.error'), 'error');
      });
  };

  const handleSubmit = () => {
    updatePersonnel({
      variables: {
        input: {
          id: tradePartnerPersonnel.personnel.id,
          flagsToAdd: [],
          flagsToDelete: [],
          flagsToUpdate: [
            {
              id: flag.id,
              reason: values.reason,
              status: values.status,
              resolution: values.resolution
            }
          ],
          trainingsToAdd: [],
          trainingsToDelete: [],
          trainingsToUpdate: []
        }
      }
    })
      .then(({ data: { updatePersonnel: personnel } }) => {
        handleClose();
        displayToast(t('flagCard.toasts.status.success'), 'success');
      })
      .catch(error => {
        console.error('Update Flag Error: ', error);
        displayToast(t('flagCard.toasts.status.error'), 'error');
      });
  };

  const handleClose = () => {
    setIsEditing(false);
    form.reset();
  };

  const getFlagStatus = status => {
    if (status === 'ToImprove') {
      return t('flagCard.status.toImprove');
    } else if (status === 'High') {
      return t('flagCard.status.attentionRequired');
    }

    return status;
  };

  return (
    <Card className={classes.card}>
      {isLoading && <LinearProgress color="primary" />}
      <CardContent>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <Grid
                  container
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  className={classnames(
                    {
                      [`${classes.toImprove}`]: flag.status === 'ToImprove'
                    },
                    {
                      [`${classes.resolved}`]: flag.status === 'Resolved'
                    },
                    {
                      [`${classes.achievement}`]: flag.status === 'Achievement'
                    },
                    {
                      [`${classes.attentionRequired}`]: flag.status === 'High'
                    }
                  )}>
                  <Grid item>
                    <FlagIcon />
                  </Grid>
                  <Grid item>
                    <Typography>{getFlagStatus(flag.status)}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              {!isEditing && (
                <>
                  <Grid item>
                    <Typography>{t('flagCard.reason.title')}</Typography>
                    <Typography variant="subtitle2">{flag.reason}</Typography>
                  </Grid>
                  {flag.status === 'Resolved' && flag.resolution && (
                    <Grid item>
                      <Typography>{t('flagCard.resolution.title')}</Typography>
                      <Typography variant="subtitle2">
                        {flag.resolution}
                      </Typography>
                    </Grid>
                  )}
                </>
              )}
              {isEditing && (
                <form>
                  <Grid container direction="column" spacing={1}>
                    {flag.status !== 'Achievement' && (
                      <Grid item>
                        <StyledSelect
                          placeholder={t(
                            'flagCard.editMode.statusSelect.placeholder'
                          )}
                          label={t('flagCard.editMode.statusSelect.title')}
                          required
                          input={status.input}
                          meta={status.meta}
                          disabled={isLoading}
                          options={FLAG_STATUS_OPTIONS.filter(
                            option => option.label !== ACHEIVEMENT
                          )}
                        />
                      </Grid>
                    )}
                    <Grid item>
                      <StyledInput
                        multiline={true}
                        input={reason.input}
                        meta={reason.meta}
                        disabled={isLoading}
                        label={t('flagCard.editMode.reason.title')}
                        required
                      />
                    </Grid>
                    {status.input.value === 'Resolved' && (
                      <Grid item>
                        <StyledInput
                          multiline={true}
                          input={resolution.input}
                          meta={resolution.meta}
                          disabled={isLoading}
                          label={t('flagCard.editMode.resolution.title')}
                          required
                        />
                      </Grid>
                    )}
                  </Grid>
                </form>
              )}
            </Grid>
          </Grid>
          <Grid item>
            <Grid container justifyContent="flex-end" spacing={1} alignItems="center">
              {!isEditing && shouldDelete && (
                <>
                  <Grid item>
                    <Typography className="bold">
                      {t('flagCard.deleteFlag.disclaimer')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <StyledButtonGroup
                      iconAltAction={<CancelIcon />}
                      onClickAltAction={() => setShouldDelete(false)}
                      labelAltAction={t(
                        'flagCard.deleteFlag.actions.confirmCancel'
                      )}
                      disabledAltAction={isLoading}
                      iconMainAction={<SendIcon />}
                      onClickMainAction={handleDeleteFlag}
                      labelMainAction={t(
                        'flagCard.deleteFlag.actions.continue'
                      )}
                    />
                  </Grid>
                </>
              )}
              {!isEditing && !shouldDelete && (
                <Grid item>
                  <StyledButtonGroup
                    iconAltAction={<DeleteIcon />}
                    onClickAltAction={() => setShouldDelete(true)}
                    labelAltAction={t('flagCard.deleteFlag.actions.delete')}
                    disabledAltAction={isLoading}
                    iconMainAction={<EditIcon />}
                    onClickMainAction={() => setIsEditing(true)}
                    labelMainAction={t('flagCard.deleteFlag.actions.edit')}
                    disabledMainAction={isLoading}
                  />
                </Grid>
              )}
              {isEditing && (
                <Grid item>
                  <StyledButtonGroup
                    onClickAltAction={handleClose}
                    labelAltAction={t('flagCard.deleteFlag.actions.cancel')}
                    disabledAltAction={isLoading}
                    onClickMainAction={handleSubmit}
                    labelMainAction={t('flagCard.deleteFlag.actions.submit')}
                    disabledMainAction={
                      status.input.value === 'Resolved'
                        ? !canSubmitForResolvedFlag
                        : !canSubmit
                    }
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

FlagCard.propTypes = {
  flag: PropTypes.object.isRequired,
  tradePartnerPersonnel: PropTypes.object
};

export default FlagCard;
