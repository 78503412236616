import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useField } from 'react-final-form-hooks';
import { useParams } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import {
  Grid,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Typography
} from '@mui/material';

import useSoteriaQuery from 'hooks/useSoteriaQuery';
import StyledStep from 'shared/Step';
import CompanySelect from 'components/company/CompanySelect';
import { getDeterministicId } from 'utils';
import { GET_ALL_TRADE_PARTNERS_FOR_PROJECT } from 'graphql/tradePartner';
import { SOTERIA_FETCH_POLICIES } from 'constants/soteriaFetchPolicies';

const useStyles = makeStyles(theme => ({
  errorText: { color: theme.errorText }
}));

const SelectCompanyStep = ({
  form,
  shouldCreateCompany,
  setShouldCreateCompany
}) => {
  const classes = useStyles();
  const { projectId } = useParams();
  const { t } = useTranslation();

  const { data: { tradePartnersOnProject = [] } = {} } = useSoteriaQuery({
    gql: GET_ALL_TRADE_PARTNERS_FOR_PROJECT,
    queryHookOptions: {
      variables: { projectId }
    },
    soteriaQueryOptions: {
      soteriaFetchPolicy: SOTERIA_FETCH_POLICIES.onlineOnly
    }
  });

  const validate = selectedCompany => {
    if (!selectedCompany) {
      return 'Required';
    }
    const id = getDeterministicId(projectId + selectedCompany.id);
    const alreadyExists =
      tradePartnersOnProject.length > 0
        ? tradePartnersOnProject.some(tradePartner => tradePartner.id === id)
        : false;
    return alreadyExists
      ? t('addTradePartnerDialog.steps.selectCompany.alreadyExists')
      : undefined;
  };

  const selectedCompany = useField('selectedCompany', form, validate);

  return (
    <StyledStep title={t('addTradePartnerDialog.steps.selectCompany.title')}>
      <Grid container direction="column" spacing={1}>
        <Grid item xs={12}>
          <CompanySelect
            isDisabled={shouldCreateCompany}
            selectedCompanyField={selectedCompany}
            handleChange={selectedCompany.input.onChange}
            isRequired={!shouldCreateCompany}
          />
        </Grid>
        <Grid item xs={12}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  data-testid="trade-partner-not-found-checkbox"
                  color="primary"
                  variant="outlined"
                  checked={shouldCreateCompany}
                  onChange={() => {
                    selectedCompany.input.onChange(null);
                    setShouldCreateCompany(!shouldCreateCompany);
                  }}
                />
              }
              label={
                <Typography>
                  {t(
                    'addTradePartnerDialog.steps.selectCompany.notFoundCheckBox'
                  )}
                </Typography>
              }
            />
          </FormGroup>
          {shouldCreateCompany && (
            <Typography className={classes.errorText}>
              {t(
                'addTradePartnerDialog.steps.selectCompany.instructions.notFoundInfo.clickNext'
              )}
            </Typography>
          )}
        </Grid>
      </Grid>
    </StyledStep>
  );
};

SelectCompanyStep.propTypes = {
  form: PropTypes.object.isRequired,
  shouldCreateCompany: PropTypes.bool.isRequired,
  setShouldCreateCompany: PropTypes.func.isRequired
};

export default SelectCompanyStep;
