import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useField } from 'react-final-form-hooks';
import { Grid } from '@mui/material';

import useFormValidation from 'hooks/useFormValidation';
import StyledStep from 'shared/Step';
import TradePartnerSelect from 'components/tradepartners/TradePartnerSelect';

const SelectTradePartnerStep = ({ form, isLoading }) => {
  const { t } = useTranslation();

  const { isRequired } = useFormValidation();

  const selectedTradePartner = useField(
    'selectedTradePartner',
    form,
    isRequired
  );

  return (
    <StyledStep title={t('selectTradePartnerStep.stepTitle')}>
      <Grid container direction="column" spacing={1}>
        <Grid item xs={12}>
          <TradePartnerSelect
            selectedTradePartner={selectedTradePartner.input.value}
            meta={selectedTradePartner.meta}
            handleChange={selectedTradePartner.input.onChange}
            isDisabled={isLoading}
            shouldShowActiveOnly={true}
          />
        </Grid>
      </Grid>
    </StyledStep>
  );
};

SelectTradePartnerStep.propTypes = {
  form: PropTypes.object.isRequired,
  isLoading: PropTypes.bool
};

export default SelectTradePartnerStep;
