import React, { useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { Grid, Card, Typography } from '@mui/material';

import StyledDialog from 'shared/Dialog';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import StyledButtonSecondary from 'shared/Buttons/ButtonSecondary';
import StyledNotice from 'shared/Notice';
import StyledConfirmationSwitch from 'shared/ConfirmationSwitch';
import useToast from 'hooks/useToast';
import { DELETE_ATTENDEE } from 'graphql/attendee';
import { GET_ORIENTATION } from 'graphql/orientation';

const AttendeeDeleteCard = ({ attendee }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { projectId, orientationId, attendeeId } = useParams();
  const [dialogIsOpen, toggleDialog] = useState(false);
  const [isConfirmed, toggleConfirmSwitch] = useState(false);
  const { displayToast } = useToast();

  const [deleteAttendee, { loading: isLoading }] = useMutation(
    DELETE_ATTENDEE,
    {
      refetchQueries: [
        { query: GET_ORIENTATION, variables: { orientationId } }
      ],
      awaitRefetchQueries: true
    }
  );

  const handleSubmit = () => {
    deleteAttendee({
      variables: { attendeeId }
    })
      .then(() => {
        displayToast(
          t('attendeeDeleteCard.toasts.success', {
            attendeeName: attendee.fullName
          }),
          'success'
        );
        handleClose();
        history.push(
          `/projects/${projectId}/orientations/${orientationId}/attendees`
        );
      })
      .catch(error => {
        console.error('Delete Attendee Error: ', error);
        displayToast(t('attendeeDeleteCard.toasts.error'), 'error');
      });
  };

  const handleClose = () => {
    toggleDialog(false);
    toggleConfirmSwitch(false);
  };

  const handleChange = event => {
    toggleConfirmSwitch(event.target.checked);
  };

  return (
    <Fragment>
      <Card className="padding">
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Typography color="textPrimary" className="bold">
              {t('attendeeDeleteCard.title')}
            </Typography>
          </Grid>
          <Grid item>
            <Grid container direction="row" justifyContent="space-between">
              <Grid item>
                <Typography gutterBottom color="textSecondary">
                  {t('attendeeDeleteCard.remove')}
                </Typography>
              </Grid>
              <Grid item>
                <StyledButtonPrimary
                  disabled={isLoading}
                  label={t('attendeeDeleteCard.button.delete')}
                  onClick={() => toggleDialog(true)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
      <StyledDialog
        isOpen={dialogIsOpen}
        isLoading={isLoading}
        handleClose={handleClose}
        disableBackdropClick={false}
        disableEscapeKeyDown={false}
        maxWidth="sm"
        title={t('attendeeDeleteCard.confirmation.title')}
        content={
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <StyledNotice
                type="warning"
                message={t('attendeeDeleteCard.confirmation.subTitle')}
              />
            </Grid>
            <Grid item>
              <StyledConfirmationSwitch
                disabled={isLoading}
                checked={isConfirmed}
                onChange={handleChange}
                value={'isConfirmed'}
              />
            </Grid>
          </Grid>
        }
        actions={
          <Fragment>
            <StyledButtonSecondary
              label={t('attendeeDeleteCard.confirmation.actions.cancelButton')}
              disabled={isLoading}
              onClick={handleClose}
            />
            <StyledButtonPrimary
              label={t('attendeeDeleteCard.confirmation.actions.submitButton')}
              disabled={isLoading || !isConfirmed}
              onClick={handleSubmit}
            />
          </Fragment>
        }
      />
    </Fragment>
  );
};
AttendeeDeleteCard.propTypes = {
  attendee: PropTypes.object.isRequired
};
export default AttendeeDeleteCard;
