import React from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { Grid, Typography, Link } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import VideoPlayer from '../../VideoPlayer';
import useHelpAndSupport from 'hooks/useHelpAndSupport';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  subTitle: { fontSize: '0.875rem' },
  title: { fontSize: '1.5rem' },
  notice: {
    borderLeft: '6px solid' + theme.palette.secondary.dark,
    backgroundColor: theme.palette.secondary.main,
    padding: theme.spacing(2)
  },
  accessNoticeTitle: { fontSize: '0.875rem', fontWeight: 'bold' },
  accessNoticeContent: { fontSize: '0.75rem' }
}));

const AddNewCompany = ({ tutorials }) => {
  const classes = useStyles();
  const { setSelectedHelpSection } = useHelpAndSupport();

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography className={classes.subTitle} color="textSecondary">
          Companies Tutorial
        </Typography>
        <Typography className={classes.title} color="textPrimary">
          Add New Company
        </Typography>
      </Grid>
      <Grid item>
        <Grid container className={classes.notice} direction="column">
          <Grid item>
            <Grid container direction="row" spacing={1} alignItems="center">
              <Grid item>
                <InfoIcon />
              </Grid>
              <Grid item>
                <Typography className={classes.accessNoticeTitle}>
                  Access notice
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Typography className={classes.accessNoticeContent}>
              This tutorial describes a workflow which is limited to Enterprise
              Admins.
            </Typography>
            <Typography className={classes.accessNoticeContent}>
              For the project admin workflow, see{' '}
              <Link
                data-testid="tradePartnerLink"
                aria-label={
                  'view the project admin workflow to add trade partners to projects'
                }
                tabIndex="0"
                onKeyDown={() =>
                  setSelectedHelpSection(tutorials.tradePartners.addToProject)
                }
                onClick={() =>
                  setSelectedHelpSection(tutorials.tradePartners.addToProject)
                }
                underline="hover">
                Add Trade Partner to Project.
              </Link>
            </Typography>
            <Typography className={classes.accessNoticeContent}>
              For a complete description of permissioned roles in Safety
              Connect, see{' '}
              <Link
                data-testid="rolesLink"
                aria-label={'view list of user roles'}
                tabIndex="0"
                onKeyDown={() =>
                  setSelectedHelpSection(tutorials.permissions.roles)
                }
                onClick={() =>
                  setSelectedHelpSection(tutorials.permissions.roles)
                }
                underline="hover">
                Roles.
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Typography color="textPrimary">
          Before a company can be added to a project as a trade partner, its
          basic information must be added.
        </Typography>
      </Grid>
      <Grid item>
        <VideoPlayer
          data-testid="add-new-company-video"
          url="/tutorials/companies/AddNewCompany.mp4"
          tracks={[
            {
              kind: 'subtitles',
              src: '/tutorials/companies/AddNewCompany.en.vtt',
              srcLang: 'en',
              default: true
            }
          ]}
        />
      </Grid>
    </Grid>
  );
};

AddNewCompany.propTypes = {
  tutorials: PropTypes.object.isRequired
};

export default AddNewCompany;
