import React from 'react';
import PropTypes from 'prop-types';
import { Fab, Tooltip } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  fab: {
    top: 'auto',
    right: 24,
    bottom: 24,
    left: 'auto',
    position: 'fixed',
    zIndex: 1300,
    '@media (max-width: 600px)': {
      right: 32,
      bottom: 56
    }
  }
}));

const StyledFab = ({
  onClick,
  icon,
  shouldShift,
  tooltipMessage = '',
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Tooltip title={tooltipMessage} className={classes.fab}>
      <div>
        <Fab
          data-testid="fab"
          onClick={onClick}
          style={shouldShift ? { right: 324 } : {}}
          color="primary"
          {...rest}>
          {icon}
        </Fab>
      </div>
    </Tooltip>
  );
};

StyledFab.propTypes = {
  shouldShift: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.node.isRequired,
  tooltipMessage: PropTypes.string
};

export default StyledFab;
