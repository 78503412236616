import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from '@mui/x-date-pickers';
import { Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { dateTimeToLuxonDateTime } from 'utils/dateTime';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  label: { fontSize: '0.75rem', fontWeight: 'bold', marginBottom: -8 },
  datePicker: {
    marginTop: 8,
    marginBottom: 4,
    marginLeft: 0,
    marginRight: 0,
    '& .MuiFormHelperText-root': {
      marginLeft: 0,
      color: theme.palette.error.main
    }
  }
}));

const StyledKeyboardDatePickerField = ({
  label,
  value,
  handleDateChange,
  openTo = 'day',
  helperText,
  meta,
  required = true,
  inputProps,
  disableFuture = true,
  disabled,
  shouldDisplayAsError = false,
  ...rest
}) => {
  const classes = useStyles();
  const showError = meta.error && meta.touched && meta.invalid;

  return (
    <>
      {label && (
        <>
          {!meta.touched && !disabled && !shouldDisplayAsError && (
            <Typography color="textSecondary" className={classes.label}>
              {required ? `${label} *` : label}
            </Typography>
          )}
          {meta.touched && !meta.error && !disabled && !shouldDisplayAsError && (
            <Typography color="textSecondary" className={classes.label}>
              {required ? `${label} *` : label}
            </Typography>
          )}
          {meta.touched && meta.error && !disabled && !shouldDisplayAsError && (
            <Typography color="error" className={classes.label}>
              {required ? `${label} *` : label}
            </Typography>
          )}
          {shouldDisplayAsError && (
            <Typography color="error" className={classes.label}>
              {required ? `${label} *` : label}
            </Typography>
          )}
          {disabled && !shouldDisplayAsError && (
            <Typography color="textSecondary" className={classes.label}>
              {label}
            </Typography>
          )}
        </>
      )}
      <DatePicker
        value={dateTimeToLuxonDateTime(value) || null}
        onChange={handleDateChange}
        openTo={openTo}
        disableFuture={disableFuture}
        maxDate={disableFuture ? DateTime.now() : undefined}
        slotProps={{
          actionBar: {
            actions: ['clear', 'accept', 'cancel']
          },
          textField: {
            ...inputProps,
            required: true,
            helperText:
              showError && required
                ? meta.error || meta.submitError || meta.invalid
                : undefined,
            error: shouldDisplayAsError || (meta.error && meta.touched),
            variant: 'outlined'
          },
          // I can't see a way to disable backdropClick for desktop mode, so we are now only disabling it for mobile
          dialog: {
            onClose: (event, reason) => {
              if (reason !== 'backdropClick') {
                handleClose(event, reason);
              }
            }
          }
        }}
        style={{ width: '100%' }}
        disabled={disabled}
        className={classes.datePicker}
        {...rest}
      />
    </>
  );
};

StyledKeyboardDatePickerField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  handleDateChange: PropTypes.func.isRequired,
  openTo: PropTypes.string,
  helperText: PropTypes.any,
  meta: PropTypes.any,
  required: PropTypes.bool,
  inputProps: PropTypes.any,
  disableFuture: PropTypes.bool,
  shouldDisplayAsError: PropTypes.bool,
  disabled: PropTypes.bool
};

export default StyledKeyboardDatePickerField;
