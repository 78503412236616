import React from 'react';
import WarningIcon from '@mui/icons-material/Warning';
import { Grid, Typography, Link } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { SERVICENOW_URL } from 'constants/externalLinks';

const useStyles = makeStyles(theme => ({
  subTitle: { fontSize: '0.875rem' },
  title: { fontSize: '1.5rem' },
  danger: {
    borderLeft: '6px solid' + theme.palette.secondary.dark,
    backgroundColor: theme.palette.secondary.main,
    padding: theme.spacing(2)
  },
  dangerTitle: {
    fontSize: '0.875rem',
    fontWeight: 'bold',
    textTransform: 'uppercase'
  },
  dangerContent: { fontSize: '0.75rem' }
}));

const DeleteTradePartner = () => {
  const classes = useStyles();

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography className={classes.subTitle} color="textSecondary">
          Trade Partners Tutorial
        </Typography>
        <Typography className={classes.title} color="textPrimary">
          Delete Trade Partner
        </Typography>
      </Grid>
      <Grid item>
        <Grid container className={classes.danger} direction="column">
          <Grid item>
            <Grid container direction="row" spacing={1} alignItems="center">
              <Grid item>
                <WarningIcon />
              </Grid>
              <Grid item>
                <Typography className={classes.dangerTitle}>Danger</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Typography className={classes.dangerContent}>
              This action permanently deletes a trade partner from the database,
              including its entire work history on the project. A trade partner
              should normally only be deleted if it was created in error, and
              does not have work history.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Typography>
          If you have added a trade partner to a project by accident,
          <Link
            data-testid="supportLink"
            aria-label={'navigate to ServiceNow in a new window'}
            tabIndex="0"
            onKeyDown={() => {
              window.open(SERVICENOW_URL, '_blank', 'noopener');
            }}
            onClick={() => {
              window.open(SERVICENOW_URL, '_blank', 'noopener');
            }}
            underline="hover">
            {' '}
            contact support{' '}
          </Link>
          to request that it be removed.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default DeleteTradePartner;
