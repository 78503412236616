import React from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import { FormGroup, FormControlLabel, Switch, Typography } from '@mui/material';

const useStyles = makeStyles(theme => ({
  labelText: { fontSize: '0.875rem' }
}));

const LanguageSwitch = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const handleLanguageChange = event => {
    i18n.changeLanguage(event.target.checked ? 'en-US' : 'es-MX');
  };
  const isSpanish = i18n.language.startsWith('es');
  return (
    <div>
      <FormGroup row>
        <FormControlLabel
          labelPlacement="start"
          control={
            <Switch
              disabled
              inputProps={{
                'data-testid': 'language-input-switch',
                role: 'button',
                'aria-pressed': !isSpanish,
                'aria-label': `toggle switch to ${!isSpanish}`
              }}
              checked={!isSpanish}
              onChange={handleLanguageChange}
              value="isEnglishLanguage"
              color="primary"
              size="small"
            />
          }
          label={
            isSpanish ? (
              <Typography color="textPrimary" className={classes.labelText}>
                {t('settings.languageSelector.options.spanish')}
              </Typography>
            ) : (
              <Typography color="textPrimary" className={classes.labelText}>
                {t('settings.languageSelector.options.english')}
              </Typography>
            )
          }
        />
      </FormGroup>
    </div>
  );
};

export default LanguageSwitch;
