import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import FlagIcon from '@mui/icons-material/Flag';
import classnames from 'classnames';
import { Grid, Typography, Card, CardContent } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  toImprove: { color: theme.palette.flag.toImprove },
  resolved: { color: theme.palette.flag.resolved },
  achievement: { color: theme.palette.flag.achievement },
  attentionRequired: { color: theme.palette.flag.attentionRequired },
  card: {
    margin: theme.spacing(2, 0),
    backgroundColor: theme.palette.background.default,
    boxShadow: 'none'
  }
}));

const FlagCard = ({ flag }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const getFlagStatus = status => {
    if (status === 'ToImprove') {
      return t('flagCard.status.toImprove');
    } else if (status === 'High') {
      return t('flagCard.status.attentionRequired');
    }

    return status;
  };

  return (
    <Card className={classes.card}>
      <CardContent>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <Grid
                  container
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  className={classnames({
                    [`${classes.toImprove}`]: flag.status === 'ToImprove',
                    [`${classes.resolved}`]: flag.status === 'Resolved',
                    [`${classes.achievement}`]: flag.status === 'Achievement',
                    [`${classes.attentionRequired}`]: flag.status === 'High'
                  })}>
                  <Grid item>
                    <FlagIcon />
                  </Grid>
                  <Grid item>
                    <Typography>{getFlagStatus(flag.status)}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Typography>{t('flagCard.reason.title')}</Typography>
                <Typography variant="subtitle2">{flag.reason}</Typography>
              </Grid>
              {flag.status === 'Resolved' && flag.resolution && (
                <Grid item>
                  <Typography>{t('flagCard.resolution.title')}</Typography>
                  <Typography variant="subtitle2">{flag.resolution}</Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

FlagCard.propTypes = {
  flag: PropTypes.object.isRequired
};

export default FlagCard;
