import gql from 'graphql-tag';

// Queries

export const GET_PAGINATED_GLOBAL_EQUIPMENTS = gql`
  query GetPaginatedGlobalEquipments(
    $first: Int!
    $search: String
    $skip: Int
  ) {
    paginatedGlobalAhaEquipments(first: $first, search: $search, skip: $skip) {
      total
      ahaEquipments {
        id
        inspectionRequirements
        isActive
        name
        trainings
      }
    }
  }
`;

// Mutations

export const CREATE_GLOBAL_AHA_EQUIPMENT = gql`
  mutation CreateGlobalAhaEquipment($input: AhaGlobalEquipmentCreateInput!) {
    createGlobalAhaEquipment(input: $input) {
      id
      inspectionRequirements
      name
      trainings
    }
  }
`;

export const UPDATE_GLOBAL_AHA_EQUIPMENT = gql`
  mutation UpdateGlobalAhaEquipment($input: AhaEquipmentUpdateInput!) {
    updateGlobalAhaEquipment(input: $input) {
      id
      inspectionRequirements
      name
      trainings
    }
  }
`;

export const DELETE_GLOBAL_AHA_EQUIPMENT = gql`
  mutation DeleteGlobalAhaEquipment($id: ID!) {
    deleteGlobalAhaEquipment(id: $id) {
      id
    }
  }
`;
