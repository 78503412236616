import { atom, useRecoilState } from 'recoil';

const defaultMaintenanceModeState = atom({
  key: 'isMaintenanceMode',
  default: false
});

function useIsMaintenanceMode() {
  const [isMaintenanceMode, setIsMaintenanceMode] = useRecoilState(
    defaultMaintenanceModeState
  );

  return { isMaintenanceMode, setIsMaintenanceMode };
}

export default useIsMaintenanceMode;
