export const CSV_PAGES = {
  personnelList: 'personnelList',
  tradePartnerList: 'tradePartnerList'
};

// helper function to return latest orientation completion date
function getLatestOrientationCompletionDate(orientationHistory) {
  if (!orientationHistory || !orientationHistory.length) {
    return null;
  }

  return new Date(
    orientationHistory.reduce((previous, current) => {
      return new Date(previous.endDateTime) > new Date(current.endDateTime)
        ? previous
        : current;
    }).endDateTime
  ).toDateString();
}

// helper function to format data in mui-datatable columns for trade partner personnel list page
function getColumnFormattedTppCsvData(dataSet) {
  let formattedData = dataSet.map((obj, indexNum) => {
    const dataArray = [
      obj.id,
      obj.personnel.fullName ?? null,
      obj.personnel.nickname ?? null,
      obj.customId ?? null,
      obj.personnel.contactInformation.emailAddress.email ?? null,
      obj.tradePartner.name,
      obj.orientationStatus ?? null,
      getLatestOrientationCompletionDate(obj.orientationHistory),
      obj.isSupervisor,
      obj.personnel.flags.length,
      obj.isActive ? 'Active' : 'Inactive'
    ];

    return { index: indexNum, data: dataArray };
  });

  return formattedData;
}

// helper function to format data in mui-datatable columns for trade partner list page
function getColumnFormattedTpCsvData(dataSet) {
  let formattedData = dataSet.map((obj, indexNum) => {
    const dataArray = [
      obj.id,
      obj.name,
      obj.trades,
      obj.workerHoursMonthToDate,
      obj.workerHoursYearToDate,
      obj.workerHoursTotalToDate,
      obj.isActive ? 'Active' : 'Inactive'
    ];

    return { index: indexNum, data: dataArray };
  });

  return formattedData;
}

// helper function to choose which data formatting function to run based on the page
function getColumnFormattedData(dataSet, pageType) {
  switch (pageType) {
    case CSV_PAGES.personnelList:
      return getColumnFormattedTppCsvData(dataSet);
    case CSV_PAGES.tradePartnerList:
      return getColumnFormattedTpCsvData(dataSet);
    default:
  }
}

//  helper function to download a csv taken from MUI source code
// https://github.com/gregnb/mui-datatables/blob/62c53a6e31bac445a882c5e3470c63f84ac18a77/src/utils.js#L8
function downloadCSV(csv, filename) {
  const blob = new Blob([csv], { type: 'text/csv' });

  /* taken from react-csv */
  if (navigator && navigator.msSaveOrOpenBlob) {
    navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    const dataURI = `data:text/csv;charset=utf-8,${csv}`;

    const URL = window.URL || window.webkitURL;
    const downloadURI =
      typeof URL.createObjectURL === 'undefined'
        ? dataURI
        : URL.createObjectURL(blob);

    let link = document.createElement('a');
    link.setAttribute('href', downloadURI);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

// create and download the csv
export function serveCsv(
  data,
  buildHead,
  buildBody,
  columns,
  csvTitle,
  pageType
) {
  const columnFormattedCsvData = getColumnFormattedData(data, pageType);
  const csv = '\uFEFF' + buildHead(columns) + buildBody(columnFormattedCsvData);
  downloadCSV(csv, `${csvTitle.split(' ').join('_')}.csv`);
}
