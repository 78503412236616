import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import withQueryParams from 'react-router-query-params';
import { useTranslation } from 'react-i18next';
import { AgGridReact } from 'ag-grid-react';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, FormControl, Button, Tooltip } from '@mui/material';

import useSettings from 'hooks/useSettings';
import 'ag-grid-community/styles/ag-grid.css';
import 'constants/themes/ag-grid-balham/light.scss';
import 'constants/themes/ag-grid-balham/dark.scss';
import { DARK_THEME } from 'constants/settings';
import { DateTime } from 'luxon';
import cloneDeep from 'clone-deep';
import TimeCardSupervisorsEditor from './TimeCardSupervisorsEditor';
import EditSafetyHoursSupervisorsDialog from 'components/personnel/EditSafetyHoursSupervisorsDialog';

const APPROXIMATE_EXPECTED_GRID_WIDTH_OVERHEAD = 5;

const useStyles = makeStyles(theme => ({
  gridContainer: {
    height: 'calc(100vh - 300px)',
    borderRadius: 4
  },
  tableActionButton: {
    marginTop: '0.875rem',
    [theme.breakpoints.down('lg')]: { marginBottom: theme.spacing(1) }
  },
  formControl: {
    marginBottom: 4
  },
  bulkActionDialog: {
    marginTop: '0.875rem',
    [theme.breakpoints.down('lg')]: { marginBottom: theme.spacing(1) }
  },
  buttonContainer: {
    width: '100%'
  },
  exportAndColumnsActionContainer: {
    display: 'flex'
  },
  styledButton: {
    backgroundColor: theme.palette.primary.contrastText,
    width: 'auto',
    borderRadius: 16,
    minWidth: theme.spacing(10),
    color: theme.palette.secondary.contrastText,
    fontSize: '0.75rem',
    fontWeight: 'bold'
  }
}));

const dateValueFormatter = params =>
  DateTime.fromFormat(params.value, 'yyyy-MM-dd').toFormat('MM/dd (ccc)');

const dateTimeComparator = (a, b) => a.diff(b).as('days');
const dateComparatorForSorting = (a, b) =>
  dateTimeComparator(
    DateTime.fromFormat(a, 'yyyy-MM-dd'),
    DateTime.fromFormat(b, 'yyyy-MM-dd')
  );
const jsDateToDay = jsDate =>
  DateTime.fromObject({
    year: jsDate.getFullYear(),
    month: jsDate.getMonth() + 1,
    day: jsDate.getDate()
  });
const dateComparatorForFiltering = (filterDate, cellValue) =>
  dateTimeComparator(
    DateTime.fromFormat(cellValue, 'yyyy-MM-dd'),
    jsDateToDay(filterDate)
  );

const TimeCardsTable = ({
  project,
  supervisors,
  workerHourRows,
  editableRows,
  setEditableRows,
  isEditing,
  toggleEditing,
  isLoading,
  updateManySupervisors,
  handleFilterChange,
  isEditable
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { settings } = useSettings();
  const gridContainerRef = useRef(null);
  const [visibleRowCount, setVisibleRowCount] = useState(0);
  const [visiblePersonnelCount, setVisiblePersonnelCount] = useState(0);
  const [gridApi, setGridApi] = useState(undefined);
  const [columnApi, setColumnApi] = useState(undefined);

  useEffect(() => {
    if (workerHourRows) {
      // Setting the row data here instead of via the AgGridReact component prop avoids unnecessary rowDataUpdated events
      gridApi?.setRowData(cloneDeep(workerHourRows));
    }
  }, [workerHourRows]);

  const supervisorsForFiltering = supervisors.map(
    supervisor =>
      `${supervisor.fullName} - ${supervisor.soteriaAdUser?.employeeId}`
  );

  const columnDefs = [
    {
      headerName: 'Name',
      field: 'tradePartnerPersonnel.personnel.fullName',
      resizable: true,
      sortable: true,
      filter: true,
      editable: false,
      initialSort: 'asc'
    },
    {
      headerName: 'Nickname',
      field: 'tradePartnerPersonnel.personnel.nickname',
      resizable: true,
      sortable: true,
      filter: true,
      editable: false
    },
    {
      headerName: 'ID',
      field: 'tradePartnerPersonnel.personnel.soteriaAdUser.employeeId',
      resizable: true,
      sortable: true,
      filter: true,
      editable: false
    },
    {
      headerName: 'Craft',
      field: 'workCraft',
      resizable: true,
      sortable: true,
      filter: true,
      editable: false
    },
    {
      headerName: 'Class',
      field: 'workClass',
      resizable: true,
      sortable: true,
      filter: true,
      editable: false
    },
    {
      headerName: 'Company',
      field: 'jobCostCompany',
      resizable: true,
      sortable: true,
      filter: true,
      editable: false
    },
    {
      headerName: 'Phase Code',
      field: 'phase.phaseCode',
      resizable: true,
      sortable: true,
      filter: true,
      editable: false
    },
    {
      headerName: 'Phase Description',
      field: 'phase.phaseDescription',
      resizable: true,
      sortable: true,
      filter: true,
      editable: false
    },
    {
      headerName: 'Supervisors',
      field: 'supervisedBy',
      cellEditor: 'timeCardSupervisorsEditor',
      cellEditorPopup: true,
      sortable: true,
      resizable: true,
      filter: true,
      filterValueGetter: params => {
        let supervisorNames = params.data.supervisedBy.map(
          supervisor =>
            `${supervisor.fullName} - ${supervisor.soteriaAdUser?.employeeId}`
        );
        return supervisorsForFiltering.filter(supervisorName =>
          supervisorNames.includes(supervisorName)
        );
      },
      filterParams: {
        filterOptions: ['contains', 'notContains']
      },
      editable: isEditable,
      singleClickEdit: true,
      cellEditorParams: {
        supervisors: cloneDeep(supervisors)
      },
      cellRenderer: function(params) {
        return `${params?.value?.length ?? 0} supervisor(s)`;
      },
      cellStyle: params => {
        if (params?.value?.length === 0) {
          return { backgroundColor: '#A6192E', color: 'white' };
        }
      },
      comparator: (a, b) => a.length - b.length,
      tooltipValueGetter: params => {
        return params.data.supervisedBy
          .map(
            supervisor =>
              `${supervisor.fullName} - ${supervisor.soteriaAdUser?.employeeId}`
          )
          .join(', ');
      }
    },
    {
      headerName: 'Default Supervisors',
      field: 'tradePartnerPersonnel.supervisedBy',
      cellEditor: 'timeCardSupervisorsEditor',
      cellEditorPopup: true,
      sortable: true,
      resizable: true,
      filter: true,
      filterValueGetter: params => {
        let supervisorNames = params.data.tradePartnerPersonnel?.supervisedBy.map(
          supervisor =>
            `${supervisor.fullName} - ${supervisor.soteriaAdUser?.employeeId}`
        );
        return supervisorsForFiltering.filter(supervisorName =>
          supervisorNames.includes(supervisorName)
        );
      },
      filterParams: {
        filterOptions: ['contains', 'notContains']
      },
      editable: false,
      singleClickEdit: true,
      cellEditorParams: {
        supervisors: cloneDeep(supervisors)
      },
      cellRenderer: function(params) {
        return `${params?.value?.length ?? 0} default supervisor(s)`;
      },
      comparator: (a, b) => a.length - b.length,
      tooltipValueGetter: params => {
        return params.data.tradePartnerPersonnel?.supervisedBy
          .map(
            supervisor =>
              `${supervisor.fullName} - ${supervisor.soteriaAdUser?.employeeId}`
          )
          .join(', ');
      }
    },
    {
      headerName: 'Post Date',
      field: 'postDate',
      resizable: true,
      sortable: true,
      filter: 'agDateColumnFilter',
      filterParams: {
        comparator: dateComparatorForFiltering,
        filterOptions: [
          'equals',
          'greaterThan',
          'lessThan',
          'notEqual',
          'inRange'
        ],
        inRangeInclusive: true
      },
      editable: false,
      valueFormatter: dateValueFormatter,
      comparator: dateComparatorForSorting,
      initialSort: 'asc'
    },
    {
      headerName: 'Hours',
      field: 'hours',
      sortable: true,
      filter: 'agNumberColumnFilter',
      resizable: true,
      editable: false
    }
  ];

  const onGridReady = event => {
    setGridApi(event.api);
    setColumnApi(event.columnApi);
  };

  const autoResizeColumns = event => {
    event.columnApi.autoSizeAllColumns(false);
    const gridContainerWidth = gridContainerRef.current.clientWidth;
    const columnStates = event.columnApi.getColumnState();
    const totalColumnWidth = columnStates
      .filter(columnState => !columnState.hide)
      .reduce(
        (accumulator, columnState) => accumulator + columnState.width ?? 0,
        0
      );
    if (
      gridContainerWidth <
      totalColumnWidth + APPROXIMATE_EXPECTED_GRID_WIDTH_OVERHEAD
    ) {
      event.api.sizeColumnsToFit();
    }
  };

  const handleChange = event => {
    // Stringify arrays before comparing values otherwise rowChanged and isEditing will always be true.
    const rowChanged =
      JSON.stringify(event.newValue) !== JSON.stringify(event.oldValue);

    if (rowChanged) {
      const editedRow = event.data;

      const exists = editableRows.some(row => row.id === editedRow.id);
      if (exists) {
        setEditableRows(
          editableRows.map(row => {
            if (row.id !== editedRow.id) {
              return row;
            }
            return {
              ...row,
              ...editedRow
            };
          })
        );
      } else {
        setEditableRows(editableRows.concat([editedRow]));
      }

      // If toggleEditing changes the value of isEditing, it will re-render this component.
      // Doing so earlier in this handler was causing the table to show the old value.
      if (!isEditing) {
        toggleEditing(true);
      }
    }
  };

  const onExport = () => {
    gridApi.exportDataAsCsv({
      fileName: `${project?.name}_${project.number}_safety_hours`,
      processCellCallback: params => {
        const timeCardColumnValues = params.value;
        if (
          Array.isArray(timeCardColumnValues) &&
          timeCardColumnValues.length
        ) {
          return timeCardColumnValues.map(
            supervisor =>
              `${supervisor.fullName} - ${supervisor.soteriaAdUser?.employeeId}`
          );
        }
        return timeCardColumnValues;
      }
    });
  };
  const onShowAllColumns = () => {
    columnApi.setColumnsVisible(
      columnDefs.map(definition => definition.field),
      true
    );
  };

  const onFilterChanged = gridApi => {
    const childrenAfterFilter = gridApi.getModel().rootNode.childrenAfterFilter;
    setVisibleRowCount(childrenAfterFilter.length);
    setVisiblePersonnelCount(
      Array.from(
        new Set(
          childrenAfterFilter.map(
            rowNode => rowNode.data.tradePartnerPersonnel?.id
          )
        )
      ).length
    );
    handleFilterChange(childrenAfterFilter.map(rowNode => rowNode.data));
  };

  return (
    <Grid container direction="column">
      <Grid item>
        <Grid container alignItems="center">
          <Grid item className={classes.buttonContainer}>
            <FormControl
              variant="standard"
              component="fieldset"
              className={classes.formControl}>
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="space-between">
                <Grid item className={classes.exportAndColumnsActionContainer}>
                  <Grid container alignContent="center">
                    <Grid item className={classes.tableActionButton}>
                      <Button
                        className={classes.styledButton}
                        startIcon={<ImportExportIcon color="action" />}
                        onClick={onExport}
                        disabled={isLoading}
                        variant="outlined">
                        {t('timeCardsPage.actions.exportButton')}
                      </Button>
                    </Grid>
                    <Grid item className={classes.tableActionButton}>
                      <Button
                        className={classes.styledButton}
                        variant="outlined"
                        onClick={onShowAllColumns}
                        disabled={isLoading}>
                        {t('timeCardsPage.actions.showAllColumnsButton')}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container alignContent="center">
                    <Tooltip
                      title={
                        editableRows.length
                          ? t('timeCardsPage.tooltips.notAvailableWhileEditing')
                          : ''
                      }>
                      <Grid item className={classes.bulkActionDialog}>
                        <EditSafetyHoursSupervisorsDialog
                          supervisors={supervisors}
                          getSupervisorsLoading={isLoading}
                          setSupervisorsCallback={updateManySupervisors}
                          setSupervisorsLoading={isLoading}
                          buttonLabel={t(
                            'timeCardsPage.actions.setSupervisors'
                          )}
                          setDefaultsLabel={t(
                            'timeCardsPage.setDefaultsLabel',
                            {
                              totalPersonnel: visiblePersonnelCount
                            }
                          )}
                          dialogTitle={t('timeCardsPage.dialogTitle')}
                          warningMessage={t('timeCardsPage.warningMessage', {
                            totalRows: visibleRowCount,
                            totalPersonnel: visiblePersonnelCount
                          })}
                          disabled={
                            visibleRowCount === 0 ||
                            !isEditable ||
                            !!editableRows.length
                          }
                        />
                      </Grid>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <div
          ref={gridContainerRef}
          className={classnames(
            classes.gridContainer,
            settings?.theme === DARK_THEME
              ? 'ag-theme-balham-dark'
              : 'ag-theme-balham'
          )}>
          <AgGridReact
            stopEditingWhenGridLosesFocus={true}
            suppressMenuHide={true}
            columnDefs={columnDefs}
            maintainColumnOrder={true}
            frameworkComponents={{
              timeCardSupervisorsEditor: TimeCardSupervisorsEditor
            }}
            onGridReady={onGridReady}
            onFirstDataRendered={event => {
              autoResizeColumns(event);
              onFilterChanged(event.api);
            }}
            onRowDataUpdated={event => {
              onFilterChanged(event.api);
            }}
            onGridSizeChanged={autoResizeColumns}
            onColumnVisible={autoResizeColumns}
            undoRedoCellEditing={true}
            undoRedoCellEditingLimit={20} // default is 10
            onCellValueChanged={event => handleChange(event)}
            onFilterChanged={event => onFilterChanged(event.api)}
            tooltipShowDelay={0}
          />
        </div>
      </Grid>
    </Grid>
  );
};

TimeCardsTable.propTypes = {
  project: PropTypes.object,
  supervisors: PropTypes.array.isRequired,
  workerHourRows: PropTypes.array.isRequired,
  editableRows: PropTypes.array.isRequired,
  setEditableRows: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  toggleEditing: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  queryParams: PropTypes.object.isRequired,
  setQueryParams: PropTypes.func.isRequired
};

export default withQueryParams({
  stripUnknownKeys: false
})(TimeCardsTable);
