import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography } from '@mui/material';

import useToast from 'hooks/useToast';
import StyledDialog from 'shared/Dialog';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import StyledButtonSecondary from 'shared/Buttons/ButtonSecondary';
import { APPROVE_AHA_REVIEW, GET_AHA_REVIEW } from 'graphql/aha/ahaReview';

const useStyles = makeStyles(theme => ({
  approveButtonContainer: {
    margin: '0 auto',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4)
  }
}));

const AhaReviewApprovalConfirmationDialog = ({ isOpen, setIsOpen }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { ahaReviewId } = useParams();
  const { displayToast } = useToast();

  const [approveAhaReview, { loading: isLoading }] = useMutation(
    APPROVE_AHA_REVIEW,
    {
      refetchQueries: [
        { query: GET_AHA_REVIEW, variables: { id: ahaReviewId } }
      ],
      awaitRefetchQueries: true
    }
  );

  const handleApprove = () => {
    approveAhaReview({ variables: { id: ahaReviewId } })
      .then(() => {
        displayToast(
          t('ahaReviewApprovalConfirmationDialog.toasts.success'),
          'success'
        );
        handleClose();
      })
      .catch(error => {
        console.error('Approve AHA Review Error: ', error);
        displayToast(
          t('ahaReviewApprovalConfirmationDialog.toasts.error'),
          'error'
        );
      });
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <StyledDialog
      maxWidth="sm"
      title={t('ahaReviewApprovalConfirmationDialog.title')}
      isOpen={isOpen}
      handleClose={handleClose}
      isLoading={isLoading}
      content={
        <Grid container>
          <Grid item xs={12}>
            <Typography>
              {t('ahaReviewApprovalConfirmationDialog.instructions')}
            </Typography>
          </Grid>
          <Grid item className={classes.approveButtonContainer}>
            <StyledButtonPrimary
              label={t(
                'ahaReviewApprovalConfirmationDialog.actions.approveButton'
              )}
              onClick={handleApprove}
              icon={<ThumbUpAltIcon />}
            />
          </Grid>
        </Grid>
      }
      actions={
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <StyledButtonSecondary
                  label={t(
                    'ahaReviewApprovalConfirmationDialog.actions.cancelButton'
                  )}
                  disabled={isLoading}
                  onClick={handleClose}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
    />
  );
};

AhaReviewApprovalConfirmationDialog.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func
};

export default AhaReviewApprovalConfirmationDialog;
