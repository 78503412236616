import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createUploadLink } from 'apollo-upload-client';
// import { WebSocketLink } from 'apollo-link-ws';'
// import { getMainDefinition } from 'apollo-utilities';

const url = new URL(process.env.REACT_APP_MAINTENANCE_URL);
const httpLink = createUploadLink({
  uri: url.origin
});

// TODO: set up ws when BE subscription is
// const wsLink = new WebSocketLink({
//   uri: 'wss://qa-soteria-maintenance.henselphelps.io/subscriptions',
//   options: {
//     reconnect: true
//   }
// });

// const link = split(
//   ({ query }) => {
//     const definition = getMainDefinition(query);
//     return (
//       definition.kind === 'OperationDefinition' &&
//       definition.operation === 'subscription'
//     );
//   },
//   // wsLink,
//   httpLink
// );

const cache = new InMemoryCache({
  freezeResults: true
});

export const client = new ApolloClient({
  cache,
  link: ApolloLink.from([httpLink])
});
