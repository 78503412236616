import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-final-form-hooks';
import { useMutation } from '@apollo/react-hooks';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography, Avatar } from '@mui/material';

import useStringIncludesHenselPhelps from 'hooks/useStringIncludesHenselPhelps';
import useToast from 'hooks/useToast';
import StyledDialog from 'shared/Dialog';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import StyledButtonSecondary from 'shared/Buttons/ButtonSecondary';
import AdUserSelect from 'components/ad/AdUserSelect';
import VerifyMatches from './VerifyMatches';
import { formatDateOfBirth } from 'utils';
import { UPDATE_ATTENDEE } from 'graphql/attendee';

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: theme.palette.background.default,
    borderRadius: 4,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    marginBottom: theme.spacing(1)
  },
  adContainer: {
    marginBottom: 20
  },
  verifyMatchesContainer: { paddingLeft: 10 }
}));

const VerifySelfRegisteredAttendeeDialog = ({
  isOpen,
  toggleIsOpen,
  selectedAttendee
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { displayToast } = useToast();
  const { stringIncludesHenselPhelps } = useStringIncludesHenselPhelps();
  const isHenselPhelpsEmployee = stringIncludesHenselPhelps(
    selectedAttendee?.tradePartner?.name
  );
  const [selectedAdUser, setSelectedAdUser] = useState(null);

  const [updateAttendee, { loading: isLoading }] = useMutation(UPDATE_ATTENDEE);

  const { form, values } = useForm({
    /* istanbul ignore next */
    onSubmit: () => {} // this function required for useForm but is not used
  });

  const { errors } = form.getState();

  const canSubmit = () => {
    return !errors.selectedPersonnelId;
  };

  const handleSubmit = () => {
    updateAttendee({
      variables: {
        input: {
          id: selectedAttendee?.id,
          status: 'Enrolled',
          personnelId:
            values.selectedPersonnelId === 'no matches'
              ? undefined
              : values.selectedPersonnelId
        }
      }
    })
      .then(({ data: { updateAttendee: attendee } }) => {
        displayToast(
          t('approveConfirmationDialog.toasts.success', {
            attendeeName: selectedAttendee?.fullName
          }),
          'success'
        );
        handleClose();
      })
      .catch(error => {
        console.error('Approve Attendee Error: ', error);
        displayToast(t('approveConfirmationDialog.toasts.error'), 'error');
      });
  };

  const handleClose = () => {
    toggleIsOpen(false);
    form.reset();
  };

  const renderRow = (label, value) => {
    return (
      <Grid item>
        <Grid container direction="row" spacing={1}>
          <Grid item>
            <Typography className="bold">{label}</Typography>
          </Grid>
          <Grid item>
            <Typography>{value}</Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderConfirmationFields = () => {
    return (
      <>
        {renderRow(
          t('verifySelfRegisteredAttendeeDialog.fields.tradePartner'),
          selectedAttendee?.tradePartner?.name
        )}
        {renderRow(
          t('verifySelfRegisteredAttendeeDialog.fields.firstName'),
          selectedAttendee?.firstName
        )}
        {renderRow(
          t('verifySelfRegisteredAttendeeDialog.fields.middleName'),
          selectedAttendee?.middleName ?? ''
        )}
        {renderRow(
          t('verifySelfRegisteredAttendeeDialog.fields.lastName'),
          selectedAttendee?.lastName
        )}
        {renderRow(
          t('verifySelfRegisteredAttendeeDialog.fields.nickname'),
          selectedAttendee?.nickname ?? ''
        )}
        {!isHenselPhelpsEmployee && (
          <>
            {renderRow(
              t('verifySelfRegisteredAttendeeDialog.fields.dob'),
              formatDateOfBirth(selectedAttendee?.dob)
            )}
            {renderRow(
              t('verifySelfRegisteredAttendeeDialog.fields.phoneNumber'),
              selectedAttendee?.contactInformation?.primaryPhone?.display ?? ''
            )}
            {renderRow(
              t('verifySelfRegisteredAttendeeDialog.fields.email'),
              selectedAttendee?.contactInformation?.emailAddress?.email ?? ''
            )}
          </>
        )}
        {renderRow(
          t('verifySelfRegisteredAttendeeDialog.fields.customId'),
          selectedAttendee?.customId ?? ''
        )}
        {renderRow(
          t('verifySelfRegisteredAttendeeDialog.fields.supervisor.title'),
          selectedAttendee?.isSupervisor
            ? t('verifySelfRegisteredAttendeeDialog.fields.supervisor.yes')
            : t('verifySelfRegisteredAttendeeDialog.fields.supervisor.no')
        )}
        {selectedAttendee?.trainings?.length > 1 && (
          <>
            {renderRow(
              t('verifySelfRegisteredAttendeeDialog.fields.training.title'),
              selectedAttendee.trainings
                .map(training => {
                  if (training.name === 'Other') {
                    return training.otherName;
                  }
                  return training.name;
                })
                .join(', ')
            )}
          </>
        )}
      </>
    );
  };

  return (
    <StyledDialog
      isLoading={isLoading}
      isOpen={isOpen}
      handleClose={() => toggleIsOpen(false)}
      title={t('verifySelfRegisteredAttendeeDialog.title')}
      content={
        <form>
          <Grid
            className={classes.container}
            container
            direction="column"
            spacing={1}>
            <Grid item>
              {selectedAttendee?.profileImage && (
                <Avatar
                  alt={t('verifySelfRegisteredAttendeeDialog.profileImage', {
                    attendeeName: selectedAttendee?.firstName
                  })}
                  className={classes.avatar}
                  src={selectedAttendee?.profileImage?.url}></Avatar>
              )}
              {renderConfirmationFields()}
            </Grid>
          </Grid>
          {!isHenselPhelpsEmployee && (
            <Grid container direction="column">
              <Grid item>
                <VerifyMatches
                  form={form}
                  selfRegisteredAttendee={selectedAttendee}
                />
              </Grid>
            </Grid>
          )}
          {isHenselPhelpsEmployee && (
            <Grid container direction="column">
              <Grid item className={classes.adContainer}>
                <AdUserSelect
                  includeOnboardingApplicantsWithNoAdUser
                  selectedUser={selectedAdUser}
                  handleChange={setSelectedAdUser}
                  placeholder={
                    <Typography className={classes.placeholderText}>
                      {t('projectPermissionsPage.addAdminPlaceHolder')}
                    </Typography>
                  }
                />
              </Grid>
              {selectedAdUser && (
                <Grid item className={classes.verifyMatchesContainer}>
                  <VerifyMatches
                    form={form}
                    selfRegisteredAttendee={selectedAdUser}
                  />
                </Grid>
              )}
            </Grid>
          )}
        </form>
      }
      actions={
        <Grid container justifyContent="space-between">
          <Grid item>
            <StyledButtonSecondary
              label={t(
                'verifySelfRegisteredAttendeeDialog.actions.cancelButton'
              )}
              disabled={isLoading}
              onClick={handleClose}
            />
          </Grid>
          <Grid item>
            <StyledButtonPrimary
              label={
                values.selectedPersonnelId &&
                values.selectedPersonnelId !== null &&
                values.selectedPersonnelId !== 'no matches'
                  ? t(
                      'verifySelfRegisteredAttendeeDialog.actions.selectMatchSubmitButton'
                    )
                  : t('verifySelfRegisteredAttendeeDialog.actions.submitButton')
              }
              disabled={!canSubmit() || isLoading}
              onClick={handleSubmit}
            />
          </Grid>
        </Grid>
      }
    />
  );
};

VerifySelfRegisteredAttendeeDialog.propTypes = {
  selectedAttendee: PropTypes.object,
  isOpen: PropTypes.bool,
  toggleIsOpen: PropTypes.func
};

export default VerifySelfRegisteredAttendeeDialog;
