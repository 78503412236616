import { GET_ALL_TRADE_PARTNERS_FOR_PROJECT } from 'graphql/tradePartner';
import { GET_TRADE_PARTNER_MONTH_WORKS } from 'graphql/tradePartnerMonthWork';
import useStringIncludesHenselPhelps from 'hooks/useStringIncludesHenselPhelps';
import useSoteriaQuery from 'hooks/useSoteriaQuery';

const useProjectWorkerHourRows = (projectId, year, month) => {
  const { stringIncludesHenselPhelps } = useStringIncludesHenselPhelps();

  const {
    data: { monthlyWorkerHours = [] } = {},
    loading: monthWorksLoading,
    error
  } = useSoteriaQuery({
    gql: GET_TRADE_PARTNER_MONTH_WORKS,
    queryHookOptions: {
      skip: !(projectId && year && month),
      variables: { projectId: projectId, year, month }
    }
  });

  const {
    data: { tradePartnersOnProject = [] } = {},
    loading: tradePartnersLoading,
    error: tradePartnersError
  } = useSoteriaQuery({
    gql: GET_ALL_TRADE_PARTNERS_FOR_PROJECT,
    queryHookOptions: {
      skip: !projectId,
      variables: { projectId: projectId }
    }
  });

  const monthWorksDictionary = indexMonthlyWorkerHoursByTradePartnerId(
    monthlyWorkerHours
  );

  const reducer = (rows, tradePartner) => {
    const monthWorks = monthWorksDictionary[tradePartner.id]
      ? monthWorksDictionary[tradePartner.id]
      : [null];
    monthWorks.forEach(monthWork => {
      if (!stringIncludesHenselPhelps(tradePartner?.company?.name)) {
        rows.push(getRow(tradePartner, monthWork));
      }
    });
    return rows;
  };
  const rows = tradePartnersOnProject.reduce(reducer, []);

  return {
    rows,
    loading: monthWorksLoading || tradePartnersLoading,
    error: error ?? tradePartnersError
  };
};

function indexMonthlyWorkerHoursByTradePartnerId(monthlyWorkerHours) {
  const reducer = (dictionary, monthWork) => {
    if (!dictionary[monthWork.tradePartner.id]) {
      dictionary[monthWork.tradePartner.id] = [];
    }
    dictionary[monthWork.tradePartner.id].push(monthWork);
    return dictionary;
  };
  return monthlyWorkerHours.reduce(reducer, {});
}

function getRow(tradePartner, monthWork) {
  return {
    id: tradePartner.id,
    name: tradePartner.name,
    isActive: tradePartner.isActive,
    hours: !monthWork?.hours || monthWork?.hours === 0 ? '' : monthWork.hours,
    month: monthWork?.month,
    year: monthWork?.year
  };
}

export default useProjectWorkerHourRows;
