import React from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { Grid, Typography, Link } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import VideoPlayer from '../../VideoPlayer';
import useHelpAndSupport from 'hooks/useHelpAndSupport';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  subTitle: { fontSize: '0.875rem' },
  title: { fontSize: '1.5rem' },
  notice: {
    borderLeft: '6px solid' + theme.palette.secondary.dark,
    backgroundColor: theme.palette.secondary.main,
    padding: theme.spacing(2)
  },
  accessNoticeTitle: { fontSize: '0.875rem', fontWeight: 'bold' },
  accessNoticeContent: { fontSize: '0.75rem' }
}));

const MyProjects = ({ tutorials }) => {
  const classes = useStyles();
  const { setSelectedHelpSection } = useHelpAndSupport();

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography className={classes.subTitle} color="textSecondary">
          Projects Tutorial
        </Typography>
        <Typography className={classes.title} color="textPrimary">
          View All Projects
        </Typography>
      </Grid>
      <Grid item>
        <Grid container className={classes.notice} direction="column">
          <Grid item>
            <Grid container direction="row" spacing={1} alignItems="center">
              <Grid item>
                <InfoIcon />
              </Grid>
              <Grid item>
                <Typography className={classes.accessNoticeTitle}>
                  Access notice
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Typography className={classes.accessNoticeContent}>
              This action is limited to Enterprise Admins only. For a complete
              description of permissioned roles in Safety Connect, see{' '}
              <Link
                data-testid="rolesLink"
                aria-label={'view list of user roles'}
                tabIndex="0"
                onKeyDown={() =>
                  setSelectedHelpSection(tutorials.permissions.roles)
                }
                onClick={() =>
                  setSelectedHelpSection(tutorials.permissions.roles)
                }
                underline="hover">
                Roles.
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <VideoPlayer
          data-testid="view-all-projects-video"
          url="/tutorials/projects/ViewAllProjects.mp4"
          tracks={[
            {
              kind: 'subtitles',
              src: '/tutorials/projects/ViewAllProjects.en.vtt',
              srcLang: 'en',
              default: true
            }
          ]}
        />
      </Grid>
    </Grid>
  );
};

MyProjects.propTypes = {
  tutorials: PropTypes.object.isRequired
};

export default MyProjects;
