import {
  AppBar,
  Box,
  Button,
  Grid,
  Tab,
  Tabs,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import ConfirmationDialog from 'components/common/ConfirmDialog';
import useSafeProject from 'hooks/useSafeProject';
import useSettings from 'hooks/useSettings';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import NavigationPrompt from 'react-router-navigation-prompt';
import StyledUnsavedChangesDialog from 'shared/UnsavedChangesDialog';
import { generateTransactionKey } from 'utils';
import GlobalActivitiesTabContent from './GlobalActivitiesTabContent';
import GlobalEquipmentTabContent from './GlobalEquipmentTabContent';
import GlobalStepsTabContent from './GlobalStepsTabContent';

const a11yProps = index => {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`
  };
};

const useStyles = makeStyles(theme => ({
  topContainer: { margin: theme.spacing(1, 0, 2, 0) },
  libraryTitle: { color: '#fff', fontSize: '1.25rem' },
  searchInput: {
    [theme.breakpoints.down('lg')]: { width: 'calc(100vw - 16px)' },
    '& .MuiInput-underline:before': {
      borderBottom: '2px solid' + theme.palette.primary.contrastText
    },
    '& .MuiInputBase-input': {
      fontSize: '1rem !important',
      fontWeight: 'bold !important',
      color: theme.palette.secondary.contrastText + '!important',
      padding: theme.spacing(1, 0, 0, 2),
      backgroundColor: theme.palette.background.paper,
      borderRadius: 4
    },
    '&:hover': {
      borderBottomColor: theme.palette.primary.main
    }
  },
  appBar: { backgroundColor: theme.libraryTabBackgroundColor },
  tabLabel: { color: '#fff' },
  editModeButton: { borderRadius: 16 },
  editModeButtonLabel: { fontSize: '0.75rem', fontWeight: 'bold' },
  lightThemeTabs: {
    height: 48,
    '& .MuiTabs-indicator': { backgroundColor: '#fff' },
    '& .MuiTab-root': {
      width: 168,
      height: 48
    }
  },
  darkThemeTabs: {
    height: 48,
    '& .MuiTabs-indicator': { backgroundColor: theme.palette.primary.main },
    '& .MuiTab-root': {
      width: 168,
      height: 48
    }
  }
}));

const AhaGlobalLibrary = ({ ahaTemplate, setAhaTemplate }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { settings } = useSettings();
  const { t } = useTranslation();
  const { projectId } = useParams();
  const { safeProject } = useSafeProject(projectId);
  const [activeTab, setActiveTab] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [
    categoryIdsWithUnsavedChanges,
    setCategoryIdsWithUnsavedChanges
  ] = useState([]);
  const shouldConfirmNavigation = categoryIdsWithUnsavedChanges.length > 0;
  const [unsavedChangesDialogIsOpen, setUnsavedChangesDialogIsOpen] = useState(
    false
  );
  const [discardWasClicked, setDiscardWasClicked] = useState(false);

  const projectAhaReferenceOptions =
    safeProject?.availableAhaReferenceOptions ?? [];

  const handleAddItemToProjectTemplate = (type, item) => {
    const getTypeToOrder = () => {
      let itemsToOrder;

      if (type === 'step') {
        itemsToOrder = ahaTemplate?.ahaSteps;
      } else if (type === 'equipment') {
        itemsToOrder = ahaTemplate?.ahaSteps;
      } else {
        itemsToOrder = ahaTemplate?.ahaActivitys;
      }

      return itemsToOrder;
    };

    const listToOrder = getTypeToOrder();

    const getOrder = arr => {
      if (arr.length > 0) {
        return (
          Math.max.apply(
            Math,
            arr.map(function(item) {
              return item.order;
            })
          ) + 1
        );
      } else {
        return 0;
      }
    };

    const itemToAdd = {
      ...item,
      transactionKey: generateTransactionKey(),
      order: getOrder(listToOrder),
      isActive: true
    };

    delete itemToAdd.id;

    if (type === 'step') {
      itemToAdd.ahaStepReferences = itemToAdd.ahaStepReferences.filter(
        reference =>
          projectAhaReferenceOptions.includes(reference.ahaReferenceOption)
      );
      setAhaTemplate({
        ...ahaTemplate,
        ahaSteps: [...ahaTemplate.ahaSteps, itemToAdd]
      });
    }

    if (type === 'equipment') {
      setAhaTemplate({
        ...ahaTemplate,
        ahaEquipments: [...ahaTemplate.ahaEquipments, itemToAdd]
      });
    }

    if (type === 'activity') {
      setAhaTemplate({
        ...ahaTemplate,
        ahaActivitys: [...ahaTemplate.ahaActivitys, itemToAdd]
      });
    }
  };

  const handleTabChange = (_, newValue) => {
    setActiveTab(newValue);
  };

  const handleEditButtonClick = () => {
    if (shouldConfirmNavigation) {
      setUnsavedChangesDialogIsOpen(true);
    } else {
      setIsEditing(!isEditing);
    }
  };

  const tabs = [
    {
      isActiveTab: activeTab === 0,
      label: (
        <Typography className={classes.tabLabel}>
          {t('globalLibrary.jobStepsTab.label')}
        </Typography>
      ),
      content: (() => {
        return (
          <GlobalStepsTabContent
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            categoryIdsWithUnsavedChanges={categoryIdsWithUnsavedChanges}
            setCategoryIdsWithUnsavedChanges={setCategoryIdsWithUnsavedChanges}
            discardWasClicked={discardWasClicked}
            setDiscardWasClicked={setDiscardWasClicked}
            unsavedChangesDialogIsOpen={unsavedChangesDialogIsOpen}
            setUnsavedChangesDialogIsOpen={setUnsavedChangesDialogIsOpen}
            handleAddGlobalStepToTemplate={stepToAdd =>
              handleAddItemToProjectTemplate('step', stepToAdd)
            }
          />
        );
      })()
    },
    {
      isActiveTab: activeTab === 1,
      label: (
        <Typography className={classes.tabLabel}>
          {t('globalLibrary.equipmentTab.label')}
        </Typography>
      ),

      content: (() => {
        return (
          <GlobalEquipmentTabContent
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            handleAddGlobalEquipmentToTemplate={equipmentToAdd =>
              handleAddItemToProjectTemplate('equipment', equipmentToAdd)
            }
          />
        );
      })()
    },
    {
      isActiveTab: activeTab === 2,
      label: (
        <Typography className={classes.tabLabel}>
          {t('globalLibrary.activitiesTab.label')}
        </Typography>
      ),

      content: (() => {
        return (
          <GlobalActivitiesTabContent
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            handleAddGlobalActivityToTemplate={activityToAdd =>
              handleAddItemToProjectTemplate('activity', activityToAdd)
            }
          />
        );
      })()
    }
  ];

  const renderUnsavedChangesConfirmationModal = () => {
    return (
      <NavigationPrompt
        when={(crntLocation, nextLocation) =>
          !nextLocation ||
          !nextLocation.pathname.startsWith(crntLocation.pathname)
        }>
        {({ onConfirm, onCancel }) => (
          <ConfirmationDialog
            show={true}
            cancel={onCancel}
            confirmation={
              'You have unsaved changes. Use the button at the top left corner of the page to save your progress or discard changes to continue.'
            }
            proceed={onConfirm}
            options={{
              theme,
              title: 'Unsaved Changes',
              rejectLabel: 'Back',
              confirmLabel: 'Discard'
            }}
          />
        )}
      </NavigationPrompt>
    );
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            className={classes.topContainer}>
            <Grid>
              <Typography className={classes.libraryTitle}>
                {t('globalLibrary.title')}
              </Typography>
            </Grid>
            <Grid item>
              <>
                {!isEditing && (
                  <Button
                    className={classes.editModeButton}
                    size="small"
                    variant="contained"
                    color="secondary"
                    startIcon={<EditIcon />}
                    onClick={handleEditButtonClick}>
                    <Typography className={classes.editModeButtonLabel}>
                      {t('globalLibrary.actions.editButton')}
                    </Typography>
                  </Button>
                )}
                {isEditing && (
                  <Button
                    className={classes.editModeButton}
                    size="small"
                    variant="contained"
                    color="secondary"
                    startIcon={<CancelIcon />}
                    onClick={handleEditButtonClick}>
                    <Typography className={classes.editModeButtonLabel}>
                      {t('globalLibrary.actions.toggleEditButton')}
                    </Typography>
                  </Button>
                )}
              </>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <>
            <AppBar position="static" className={classes.appBar}>
              <Tabs
                indicatorColor="secondary"
                textColor="inherit"
                value={activeTab}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="tabs"
                className={
                  settings.theme === 'light'
                    ? classes.lightThemeTabs
                    : classes.darkThemeTabs
                }>
                {tabs.map((tab, index) => {
                  return (
                    <Tab key={index} label={tab.label} {...a11yProps(index)} />
                  );
                })}
              </Tabs>
            </AppBar>
            {tabs.map((tab, index) => (
              <div
                key={index}
                value={activeTab}
                index={index}
                role="tabpanel"
                hidden={activeTab !== index}
                id={`scrollable-auto-tabpanel-${index}`}
                aria-labelledby={`scrollable-auto-tab-${index}`}>
                <Box p={1}>{tab.content}</Box>
              </div>
            ))}
          </>
        </Grid>
      </Grid>
      {shouldConfirmNavigation && renderUnsavedChangesConfirmationModal()}
      <StyledUnsavedChangesDialog
        unsavedChangesDialogIsOpen={unsavedChangesDialogIsOpen}
        setUnsavedChangesDialogIsOpen={setUnsavedChangesDialogIsOpen}
        discardWasClicked={discardWasClicked}
        setDiscardWasClicked={setDiscardWasClicked}
        content={
          <Grid container>
            <Grid item>
              <Typography>
                You have unsaved changes. Use the button at the top left corner
                of the page to save your progress or discard changes to
                continue.
              </Typography>
            </Grid>
          </Grid>
        }
      />
    </>
  );
};

AhaGlobalLibrary.propTypes = {
  ahaTemplate: PropTypes.object,
  setAhaTemplate: PropTypes.func.isRequired
};

export default AhaGlobalLibrary;
