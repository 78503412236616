import gql from 'graphql-tag';

// Queries

export const GET_ALL_GLOBAL_STEP_CATEGORIES = gql`
  query GetAllGlobalStepCategories {
    ahaGlobalStepCategories {
      id
      name
      stepCount
    }
  }
`;

export const GET_ALL_GLOBAL_STEPS_FOR_CATEGORY = gql`
  query GetAllGlobalStepsForCategory($id: ID!) {
    ahaStepsForGlobalCategory(ahaGlobalStepCategoryId: $id) {
      id
      name
      controls
      hazards
      riskAssessmentCode
      ahaStepReferences {
        id
        ahaReferenceOption
        value
      }
      ahaGlobalStepCategory {
        id
        name
      }
    }
  }
`;

export const GLOBAL_STEP_SEARCH = gql`
  query GlobalAhaStepsAsAhaGlobalStepCategorySubsets($search: String!) {
    globalAhaStepsAsAhaGlobalStepCategorySubsets(search: $search) {
      id
      name
      categoryStepCount
      ahaSteps {
        id
        name
        controls
        hazards
        riskAssessmentCode
        ahaStepReferences {
          id
          ahaReferenceOption
          value
        }
        ahaGlobalStepCategory {
          id
          name
        }
      }
    }
  }
`;

// Mutations

export const CREATE_GLOBAL_AHA_STEP_CATEGORY = gql`
  mutation CreateAhaGlobalStepCategory(
    $input: AhaGlobalStepCategoryCreateInput!
  ) {
    createAhaGlobalStepCategory(input: $input) {
      id
      name
    }
  }
`;

export const UPDATE_GLOBAL_AHA_STEP_CATEGORY = gql`
  mutation UpdateAhaGlobalStepCategory(
    $input: AhaGlobalStepCategoryUpdateInput!
  ) {
    updateAhaGlobalStepCategory(input: $input) {
      id
      name
    }
  }
`;

export const CREATE_GLOBAL_AHA_STEP = gql`
  mutation CreateGlobalAhaStep($input: AhaStepCreateInput!) {
    createGlobalAhaStep(input: $input) {
      id
      name
      isActive
      ahaStepReferences {
        id
      }
      riskAssessmentCode
      controls
      hazards
    }
  }
`;

export const UPDATE_GLOBAL_AHA_STEP = gql`
  mutation UpdateGlobalAhaStep($input: AhaStepUpdateInput!) {
    updateGlobalAhaStep(input: $input) {
      id
      name
      isActive
      ahaStepReferences {
        id
      }
      riskAssessmentCode
      controls
      hazards
    }
  }
`;

export const DELETE_GLOBAL_AHA_STEP_CATEGORY = gql`
  mutation DeleteAhaGlobalStepCategory($id: ID!) {
    deleteAhaGlobalStepCategory(id: $id) {
      id
    }
  }
`;

export const DELETE_GLOBAL_AHA_STEP = gql`
  mutation DeleteAhaGlobalStep($id: ID!) {
    deleteGlobalAhaStep(id: $id) {
      id
    }
  }
`;
