import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation, useSubscription } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';
import withQueryParams from 'react-router-query-params';
import cloneDeep from 'clone-deep';
import deepEqual from 'deep-equal';

import useToast from 'hooks/useToast';
import useLazyQueryPromise from 'hooks/useLazyQueryPromise';
import withOnlineAccessOnly from 'hocs/withOnlineAccessOnly';
import withAuthorization from 'hocs/withAuthorization';
import LoadingSpinner from 'components/common/LoadingSpinner';
import ErrorNotice from 'components/common/ErrorNotice';
import AhaSummaryViewEditor from 'components/ahas/AhaSummaryViewEditor';
import AhaDetailedViewEditor from 'components/ahas/AhaDetailedViewEditor';
import {
  GET_AHA_TEMPLATE,
  AHA_TEMPLATE_SUBSCRIPTION,
  UPDATE_GLOBAL_AHA_TEMPLATE,
  UPDATE_PROJECT_TEMPLATE
} from 'graphql/aha';
import useActiveRoute from 'hooks/useActiveRoute';
import StyledButtonSecondary from 'shared/Buttons/ButtonSecondary';

const AHA_SUMMARY_VIEW = 'summary';
const AHA_DETAILED_VIEW = 'detailed';

const ALLOWED_VIEW_PARAMS = [AHA_SUMMARY_VIEW, AHA_DETAILED_VIEW];

const AhaEditorPage = ({ queryParams }) => {
  const { view: activeView } = queryParams;
  const { ahaId } = useParams();
  const { isActiveRoute } = useActiveRoute();
  const { t } = useTranslation();
  const { displayToast } = useToast();
  const [modifiedAhaTemplate, setModifiedAhaTemplate] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const isGlobal = isActiveRoute(`/ahas/:ahaId/edit`);

  const { data, loading, error } = useQuery(GET_AHA_TEMPLATE, {
    variables: { id: ahaId },
    skip: !ahaId
  });

  const [
    getAhaTemplate,
    { loading: isLoadingLastModifiedCheck }
  ] = useLazyQueryPromise(GET_AHA_TEMPLATE, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
  });
  const originalAhaTemplate = data?.ahaTemplate;

  const getTemplate = () => {
    if (originalAhaTemplate?.ahaProjectTemplate) {
      const {
        ownedBy,
        reviewReminderCadenceDays,
        ...restAhaProjectTemplate
      } = originalAhaTemplate.ahaProjectTemplate;

      return {
        ...originalAhaTemplate,
        ahaProjectTemplate: { ...restAhaProjectTemplate }
      };
    }
    return originalAhaTemplate;
  };

  const ahaTemplate = getTemplate();

  const areSameTemplate = (a, b) => !!a && !!b && a.id === b.id;

  const hasChanges =
    areSameTemplate(ahaTemplate, modifiedAhaTemplate) &&
    !deepEqual(ahaTemplate, modifiedAhaTemplate);

  const hasExternalChanges =
    areSameTemplate(ahaTemplate, modifiedAhaTemplate) &&
    ahaTemplate.lastModified !== modifiedAhaTemplate.lastModified;

  const resetAhaTemplateChanges = useCallback(() => {
    if (ahaTemplate) {
      const clonedAhaTemplate = cloneDeep(ahaTemplate);
      setModifiedAhaTemplate(clonedAhaTemplate);
    }
  }, [ahaTemplate]);

  const onExternalChanges = useCallback(() => {
    // eslint-disable-next-line react/prop-types
    const Reloader = ({ toastKey }) => {
      const { t } = useTranslation();
      const { closeSnackbar } = useToast();
      return (
        <StyledButtonSecondary
          label={t('updateToast.reloadButton')}
          onClick={() => {
            resetAhaTemplateChanges();
            closeSnackbar(toastKey);
          }}
        />
      );
    };

    displayToast(
      t('ahaEditorPage.toasts.update.externalChanges'),
      'warning',
      Reloader
    );
  }, [displayToast, resetAhaTemplateChanges, t]);

  useEffect(() => {
    if (hasExternalChanges) {
      onExternalChanges();
    }
  }, [hasExternalChanges, onExternalChanges]);

  useEffect(() => {
    if (isSaving) {
      return;
    }
    if (
      (!modifiedAhaTemplate && ahaTemplate) ||
      (modifiedAhaTemplate &&
        ahaTemplate &&
        modifiedAhaTemplate.id !== ahaTemplate.id)
    ) {
      const clonedAhaTemplate = cloneDeep(ahaTemplate);
      setModifiedAhaTemplate(clonedAhaTemplate);
    }
  }, [ahaTemplate, modifiedAhaTemplate, isSaving]);

  const refetchQueries = [
    { query: GET_AHA_TEMPLATE, variables: { id: ahaId } }
  ];

  const [
    updateAhaProjectTemplate,
    { loading: isLoadingProjectTemplateUpdate }
  ] = useMutation(UPDATE_PROJECT_TEMPLATE, {
    refetchQueries,
    awaitRefetchQueries: true
  });

  const [
    updateGlobalAhaTemplate,
    { loading: isLoadingGlobalTemplateUpdate }
  ] = useMutation(UPDATE_GLOBAL_AHA_TEMPLATE, {
    refetchQueries,
    awaitRefetchQueries: true
  });

  useSubscription(AHA_TEMPLATE_SUBSCRIPTION, {
    skip: !ahaId,
    variables: { id: ahaId }
  });

  const isLoadingUpdate =
    isLoadingProjectTemplateUpdate || isLoadingGlobalTemplateUpdate;

  const isLoading = loading || isLoadingUpdate || isLoadingLastModifiedCheck;

  const handleUpdateTemplate = variables => {
    const updateFunction = isGlobal
      ? updateGlobalAhaTemplate
      : updateAhaProjectTemplate;

    return getAhaTemplate({ id: ahaId }).then(response => {
      const currentTemplate = response.data.ahaTemplate;
      if (
        areSameTemplate(currentTemplate, modifiedAhaTemplate) &&
        currentTemplate.lastModified !== modifiedAhaTemplate.lastModified
      ) {
        onExternalChanges();
      } else {
        setIsSaving(true);
        setModifiedAhaTemplate(null);
        return updateFunction({ variables })
          .then(() => {
            displayToast(t('ahaEditorPage.toasts.update.success'), 'success');
            setIsSaving(false);
          })
          .catch(error => {
            console.error('Update Template Error: ', error);
            displayToast(t('ahaEditorPage.toasts.update.error'), 'error');
          });
      }
    });
  };

  if (activeView === AHA_DETAILED_VIEW && loading) {
    return <LoadingSpinner />;
  }
  if (error) {
    return <ErrorNotice />;
  }

  if (activeView === AHA_SUMMARY_VIEW) {
    return (
      <AhaSummaryViewEditor
        ahaTemplate={modifiedAhaTemplate}
        originalAhaTemplate={originalAhaTemplate}
        isGlobal={isGlobal}
        hasChanges={hasChanges}
        hasExternalChanges={hasExternalChanges}
        setAhaTemplate={setModifiedAhaTemplate}
        handleSaveAhaTemplate={handleUpdateTemplate}
        isLoading={isLoading}
        resetAhaTemplateChanges={resetAhaTemplateChanges}
      />
    );
  }

  if (activeView === AHA_DETAILED_VIEW) {
    return (
      <AhaDetailedViewEditor
        ahaTemplate={modifiedAhaTemplate}
        originalAhaTemplate={originalAhaTemplate}
        isGlobal={isGlobal}
        hasChanges={hasChanges}
        hasExternalChanges={hasExternalChanges}
        setAhaTemplate={setModifiedAhaTemplate}
        handleSaveAhaTemplate={handleUpdateTemplate}
        isLoading={isLoading}
        resetAhaTemplateChanges={resetAhaTemplateChanges}
        isLoadingUpdate={isLoadingUpdate}
      />
    );
  }

  return null;
};

AhaEditorPage.propTypes = {
  queryParams: PropTypes.object.isRequired,
  setQueryParams: PropTypes.func.isRequired
};

export default withOnlineAccessOnly(
  withAuthorization(
    withQueryParams({
      stripUnknownKeys: true,
      keys: {
        view: {
          default: AHA_SUMMARY_VIEW,
          validate: (_, props) => validateParam(props.location.search)
        }
      }
    })(AhaEditorPage),
    {
      personnelOnProject: true
    }
  )
);

function validateParam(search) {
  const params = new URLSearchParams(search);
  const view = params.get('view');
  return ALLOWED_VIEW_PARAMS.includes(view?.toLowerCase());
}
