import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import PdfViewer from 'components/common/PdfViewer';
import WordDocViewer from 'components/common/WordDocViewer';
import ExcelViewer from 'components/common/ExcelViewer';
import DownloadIcon from '@mui/icons-material/GetApp';
import DeleteIcon from '@mui/icons-material/Delete';
import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';
import { Grid } from '@mui/material';

import useToast from 'hooks/useToast';
import StyledDialog from 'shared/Dialog';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import StyledButtonSecondary from 'shared/Buttons/ButtonSecondary';
import StyledDeleteConfirmation from 'shared/DeleteConfirmation';
import { DELETE_AHA_FILE } from 'graphql/aha/ahaFile';
import SoteriaFileDisplayImage from 'components/common/SoteriaFileDisplayImage';
import { fileIsImage, fileIsPdf, fileIsDoc, fileIsXls } from 'utils';

const useStyles = makeStyles(theme => ({
  link: { textDecoration: 'none' },
  disabledLink: { pointerEvents: 'none', cursor: 'default' }
}));

const AhaFileDialog = ({ isOpen, setIsOpen, ahaFile, refetchQuery }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { displayToast } = useToast();
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(
    false
  );
  const [isDeleteConfirmed, toggleIsDeleteConfirmed] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const file = ahaFile?.soteriaFile;

  const [deleteAhaFile, { loading }] = useMutation(DELETE_AHA_FILE);

  const isLoading = loading || isSaving;

  const handleDeleteConfirmation = () => {
    setIsDeleteConfirmationOpen(true);
  };

  const handleChange = event => {
    toggleIsDeleteConfirmed(event.target.checked);
  };

  const handleDeleteSubmit = () => {
    setIsSaving(true);
    deleteAhaFile({
      variables: { id: ahaFile.id }
    })
      .then(async () => {
        if (refetchQuery) {
          await refetchQuery();
        }
        displayToast(
          t('tradePartnerAhaCategoryFileDialog.toasts.success'),
          'success'
        );
        handleClose();
      })
      .catch(error => {
        setIsSaving(false);
        console.error('Delete Aha File Error: ', error);
        displayToast(
          t('tradePartnerAhaCategoryFileDialog.toasts.error'),
          'error'
        );
      });
  };

  const handleClose = () => {
    setIsDeleteConfirmationOpen(false);
    toggleIsDeleteConfirmed(false);
    setIsOpen(false);
    setIsSaving(false);
  };

  return (
    <>
      <StyledDialog
        title={'AHA File'}
        isOpen={isOpen}
        handleClose={() => setIsOpen(false)}
        content={
          <Grid container>
            <Grid item xs={12}>
              {fileIsImage(file) && <SoteriaFileDisplayImage file={file} />}
              {fileIsPdf(file) && !file.isMalware && <PdfViewer file={file} />}
              {fileIsDoc(file) && !file.isMalware && (
                <WordDocViewer file={file} />
              )}
              {fileIsXls(file) && !file.isMalware && (
                <ExcelViewer file={file} />
              )}
            </Grid>
          </Grid>
        }
        actions={
          <Grid container justifyContent="space-between">
            <Grid item>
              <StyledButtonSecondary
                label={'Cancel'}
                disabled={isLoading}
                onClick={handleClose}
              />
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={1}>
                <Grid item>
                  <a
                    className={classnames(classes.link, {
                      [`${classes.disabledLink}`]: !file
                    })}
                    href={file?.cachedUrl}
                    download={file?.name}>
                    <StyledButtonPrimary
                      icon={<DownloadIcon />}
                      label={'Download'}
                      disabled={isLoading || !file}
                    />
                  </a>
                </Grid>
                <Grid item>
                  <StyledButtonPrimary
                    icon={<DeleteIcon />}
                    label={'Delete'}
                    disabled={isLoading}
                    onClick={handleDeleteConfirmation}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      />
      <StyledDeleteConfirmation
        title={t('ahaFilesPage.deleteConfirmationTitle')}
        dialogIsOpen={isDeleteConfirmationOpen}
        isLoading={isLoading}
        handleClose={handleClose}
        warningMessage={t('ahaFilesPage.deleteConfirmationMessage')}
        isConfirmed={isDeleteConfirmed}
        handleChange={handleChange}
        handleSubmit={handleDeleteSubmit}
      />
    </>
  );
};

AhaFileDialog.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  ahaFile: PropTypes.object,
  refetchQuery: PropTypes.func
};

export default AhaFileDialog;
