import gql from 'graphql-tag';

// Queries

export const GET_PAGINATED_AHA_FILES_ON_PROJECT_TEMPLATE = gql`
  query GetPaginatedAhaFilesOnProjectTemplate(
    $ahaProjectTemplateId: ID!
    $first: Int!
    $order: [AhaFileOrderInput!]
    $search: String
    $skip: Int
  ) {
    paginatedAhaFilesOnProjectTemplate(
      ahaProjectTemplateId: $ahaProjectTemplateId
      first: $first
      order: $order
      search: $search
      skip: $skip
    ) {
      total
      ahaFiles {
        id
        description
        uploaded
        uploadedBy {
          id
          name
        }
        soteriaFile {
          id
          name
          url
          sizeInBytes
          contentType
          isScanned
          isMalware
          cachedUrl @client
          cachedFile @client
        }
      }
    }
  }
`;

// Mutations

export const CREATE_AHA_FILE = gql`
  mutation CreateAhaFile($input: AhaFileCreateInput!) {
    createAhaFile(input: $input) {
      id
    }
  }
`;

export const DELETE_AHA_FILE = gql`
  mutation DeleteAhaFile($id: ID!) {
    deleteAhaFile(id: $id) {
      id
    }
  }
`;
