import { useMemo } from 'react';
import { enabledFeatures } from 'constants/enabledFeatures';
import {
  tutorials as allTutorials,
  getTitles,
  getSections
} from 'components/tutorials';
import { tutorials } from 'constants/tutorials';

const useTutorials = () => {
  const features = enabledFeatures[process.env.REACT_APP_ENV];
  let enabledTutorials = Object.assign({}, allTutorials);
  for (const [key, value] of Object.entries(allTutorials)) {
    if (allTutorials[key]?.enabledFeature && !features[value.enabledFeature]) {
      delete enabledTutorials[key];
    }
  }
  const titles = getTitles(enabledTutorials);
  const sections = getSections(enabledTutorials);

  return useMemo(() => {
    return { tutorials, enabledTutorials, titles, sections };
  }, [enabledTutorials, sections, titles]);
};

export default useTutorials;
