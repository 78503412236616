import gql from 'graphql-tag';

// Queries

export const GET_AHA_TRADE_PARTNER_CATEGORY = gql`
  query GetAhaTradePartnerCategory($id: ID!) {
    ahaTradePartnerCategory(id: $id) {
      id
      name
      tradePartner {
        id
        name
      }
    }
  }
`;

export const GET_PAGINATED_AHA_TRADE_PARTNER_CATEGORIES = gql`
  query GetPaginatedAhaTradePartnerCategories(
    $first: Int!
    $search: String
    $skip: Int
    $order: [AhaTradePartnerCategoryOrderInput!]
    $filter: AhaTradePartnerCategoryFiltersInput
    $projectId: ID
    $tradePartnerId: ID
  ) {
    paginatedAhaTradePartnerCategories(
      first: $first
      search: $search
      skip: $skip
      order: $order
      filter: $filter
      projectId: $projectId
      tradePartnerId: $tradePartnerId
    ) {
      total
      ahaTradePartnerCategories {
        id
        name
        created
        tradePartner {
          id
          name
        }
        ahaFiles {
          id
          projectArea
          description
          uploaded
          uploadedBy {
            id
            name
            jobTitle
          }
        }
      }
    }
  }
`;

export const GET_PAGINATED_AHA_FILES_ON_TRADE_PARTNER_CATEGORY = gql`
  query GetPaginatedAhaFilesOnTradePartnerCategory(
    $ahaTradePartnerCategoryId: ID!
    $first: Int!
    $search: String
    $skip: Int
    $order: [AhaFileOrderInput!]
    $filter: AhaFileFiltersInput
  ) {
    paginatedAhaFilesOnTradePartnerCategory(
      ahaTradePartnerCategoryId: $ahaTradePartnerCategoryId
      first: $first
      search: $search
      skip: $skip
      order: $order
      filter: $filter
    ) {
      total
      ahaFiles {
        id
        uploaded
        description
        projectArea
        uploadedBy {
          id
          name
          jobTitle
        }
        ahaTradePartnerCategory {
          id
          name
          tradePartner {
            id
            name
          }
        }
        soteriaFile {
          id
          name
          url
          sizeInBytes
          contentType
          isScanned
          isMalware
          cachedUrl @client
          cachedFile @client
        }
      }
    }
  }
`;

// Mutations

export const CREATE_AHA_TRADE_PARTNER_CATEGORY = gql`
  mutation CreateAhaTradePartnerCategory(
    $input: AhaTradePartnerCategoryCreateInput!
  ) {
    createAhaTradePartnerCategory(input: $input) {
      id
      name
      tradePartner {
        id
        name
      }
      ahaFiles {
        id
        uploaded
        uploadedBy {
          id
          name
          jobTitle
        }
      }
    }
  }
`;

export const UPDATE_AHA_TRADE_PARTNER_CATEGORY = gql`
  mutation UpdateAhaTradePartnerCategory(
    $input: AhaTradePartnerCategoryUpdateInput!
  ) {
    updateAhaTradePartnerCategory(input: $input) {
      id
      name
      tradePartner {
        id
        name
      }
      ahaFiles {
        id
        uploaded
        uploadedBy {
          id
          name
          jobTitle
        }
      }
    }
  }
`;

export const DELETE_AHA_TRADE_PARTNER_CATEGORY = gql`
  mutation DeleteAhaTradePartnerCategory($id: ID!) {
    deleteAhaTradePartnerCategory(id: $id) {
      id
    }
  }
`;
