import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, Button } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  button: {
    borderRadius: 16,
    minWidth: theme.spacing(10),
    '&:hover': { backgroundColor: 'none !important' }
  },
  buttonText: { fontSize: '0.75rem', fontWeight: 'bold' }
}));

const StyledButtonSecondary = ({ label, onClick, className, ...rest }) => {
  const classes = useStyles();

  return (
    <Button
      data-testid="button-secondary"
      onClick={onClick}
      className={classNames(classes.button, className)}
      {...rest}>
      <Typography className={classes.buttonText}>{label}</Typography>
    </Button>
  );
};

StyledButtonSecondary.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string
};

export default StyledButtonSecondary;
