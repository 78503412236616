import { useMemo, useContext } from 'react';
import EnabledFeaturesContext from 'context/enabledFeatures';
import { features } from 'constants/enabledFeatures';
import { whatsNew } from 'constants/whatsNew';
const whatsNewItems = whatsNew?.items ?? [];

const useWhatsNew = () => {
  const { enabledFeatures } = useContext(EnabledFeaturesContext);
  let filteredWhatsNewItems = whatsNewItems;
  // eslint-disable-next-line no-unused-vars
  for (const [key, value] of Object.entries(features)) {
    if (!enabledFeatures[value]) {
      filteredWhatsNewItems = filteredWhatsNewItems.filter(
        item => item.enabledFeature !== value
      );
    }
  }

  return useMemo(() => {
    return { whatsNewItems: filteredWhatsNewItems };
  }, [filteredWhatsNewItems]);
};

export default useWhatsNew;
