import React, { useReducer, useEffect } from 'react';
import SettingsDetailsContext from '../../context/settings';
import { LIGHT_THEME } from '../../constants/settings';
import PropTypes from 'prop-types';

const initialState = {
  theme: LIGHT_THEME
};

let reducer = (settings, newSettings) => {
  if (newSettings === null) {
    localStorage.removeItem('settings');
    return initialState;
  }
  return { ...settings, ...newSettings };
};

const SettingsProvider = ({ children }) => {
  const localState = JSON.parse(localStorage.getItem('settings'));
  const [settings, setSettings] = useReducer(
    reducer,
    localState || initialState
  );

  useEffect(() => {
    localStorage.setItem('settings', JSON.stringify(settings));
  }, [settings]);

  return (
    <SettingsDetailsContext.Provider value={{ settings, setSettings }}>
      {children}
    </SettingsDetailsContext.Provider>
  );
};

SettingsProvider.propTypes = {
  children: PropTypes.node
};

export default SettingsProvider;
