import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import {
  Checkbox,
  FormControl,
  FormGroup,
  FormControlLabel
} from '@mui/material/';
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  checkbox: {
    '&checked': {
      color: theme.palette.secondary.main
    }
  }
}));

const StyledCheckboxList = ({
  listItems,
  selectedListItems,
  setSelectedListItems
}) => {
  const classes = useStyles();

  const handleToggle = value => () => {
    const currentIndex = selectedListItems.indexOf(value);
    const newChecked = [...selectedListItems];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setSelectedListItems(newChecked);
  };

  return (
    <>
      <div className={classes.root}>
        <FormControl component="fieldset" data-testid="checklist-component">
          {listItems.map(item => {
            const labelId = `checkbox-list-label-${item}`;
            return (
              <FormGroup key={item}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedListItems.indexOf(item) !== -1}
                      onChange={handleToggle(item)}
                      name={item}
                      inputProps={{ 'aria-label': labelId }}
                      color="primary"
                    />
                  }
                  label={item}
                />
              </FormGroup>
            );
          })}
        </FormControl>
      </div>
    </>
  );
};

StyledCheckboxList.propTypes = {
  listItems: PropTypes.array.isRequired,
  selectedListItems: PropTypes.array.isRequired,
  setSelectedListItems: PropTypes.func.isRequired
};

export default StyledCheckboxList;
