import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, Button } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  button: {
    width: 'auto',
    borderRadius: 16,
    minWidth: theme.spacing(10),
    backgroundColor: theme.palette.background.default
  },
  buttonText: { fontSize: '0.75rem', fontWeight: 'bold' }
}));

const ButtonMuted = ({ startIcon, label, onClick, className, ...rest }) => {
  const classes = useStyles();
  return (
    <Button
      variant="outlined"
      startIcon={startIcon}
      onClick={onClick}
      className={classNames(classes.button, className)}
      {...rest}>
      <Typography color="textSecondary" className={classes.buttonText}>
        {label}
      </Typography>
    </Button>
  );
};

ButtonMuted.propTypes = {
  startIcon: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string
};

export default ButtonMuted;
