export const RISK_ASSESSMENT_CODES = {
  low: {
    value: 'Low',
    label: 'L - Low Risk'
  },
  moderate: {
    value: 'Moderate',
    label: 'M - Moderate Risk'
  },
  high: {
    value: 'High',
    label: 'H - High-Risk'
  },
  extremelyHigh: {
    value: 'ExtremelyHigh',
    label: 'E - Extremely High Risk'
  }
};

export const STEP_REFERENCE_OPTIONS = [
  {
    value: 'CalOsha',
    label: 'CalOsha'
  },
  {
    value: 'Em385',
    label: 'EM385'
  },
  {
    value: 'FedOsha',
    label: 'FedOsha'
  },
  {
    value: 'Generic',
    label: 'Generic'
  },
  {
    value: 'Lni',
    label: 'L&I'
  }
];

export const SORT_ORDER_DIRECTION = {
  ascending: 'ASC',
  descending: 'DESC'
};

export const HENSEL_PHELPS_SORT_ORDER_OPTIONS = {
  created: {
    label: 'Created Date',
    value: 'created'
  },
  lastModified: {
    label: 'Last Modified Date',
    value: 'lastModified'
  },
  creator: {
    label: 'Owner',
    value: 'ownedBy_name'
  },
  type: {
    label: 'Type',
    value: 'ahaTemplate_type'
  }
};

export const TRADE_PARTNER_SORT_ORDER_OPTIONS = {
  created: {
    label: 'Created Date',
    value: 'created'
  },
  lastModified: {
    label: 'Last Modified Date',
    value: 'lastModified'
  }
};

export const TRADE_PARTNER_CATEGORY_FILES_SORT_ORDER_OPTIONS = {
  description: {
    label: 'Description',
    value: 'description'
  },
  uploaded: {
    label: 'Uploaded Date',
    value: 'uploaded'
  },
  uploadedBy: {
    label: 'Uploaded By',
    value: 'uploadedBy_name'
  }
};

export const PENDING_REVIEW_SORT_ORDER_OPTIONS = {
  description: {
    label: 'Description',
    value: 'description'
  },
  uploaded: {
    label: 'Uploaded Date',
    value: 'uploaded'
  },
  uploadedBy: {
    label: 'Uploaded By',
    value: 'uploadedBy_name'
  }
};

export const HENSEL_PHELPS_AHA_FILES_SORT_ORDER_OPTIONS = {
  description: {
    label: 'Description',
    value: 'description'
  },
  uploaded: {
    label: 'Uploaded Date',
    value: 'uploaded'
  },
  uploadedBy: {
    label: 'Uploaded By',
    value: 'uploadedBy_name'
  }
};

export const NOTIFICATION_FREQUENCY_OPTIONS = [
  { value: 0, label: 'No Additional Review Needed' },
  { value: 30, label: 'Every 30 Days' },
  { value: 45, label: 'Every 45 Days' },
  { value: 60, label: 'Every 60 Days' }
];

export const FILTER_API_KEYS = {
  createdAfter: 'createdAfter',
  createdBefore: 'createdBefore',
  lastModifiedBefore: 'lastModifiedBefore',
  lastModifiedAfter: 'lastModifiedAfter',
  tradePartner_isActive: 'tradePartner_isActive',
  ahaFiles_projectArea: 'ahaFiles_projectArea',
  hasPendingReview: 'hasPendingReview',
  ahaStatus: 'ahaStatus',
  recordStatus: 'recordStatus',
  hasAttachment: 'hasAttachment'
};

export const HENSEL_PHELPS_FILTER_OPTIONS = {
  hpModifiedLastThirtyDays: {
    label: 'Last 30 days',
    value: 'hpModifiedLastThirtyDays',
    // file deepcode ignore HardcodedNonCryptoSecret: these are keys in the key/value sense, not passkeys
    apiKey: FILTER_API_KEYS.lastModifiedAfter
  },
  hpModifiedLastOverThirtyDays: {
    label: 'Over 30 days',
    value: 'hpModifiedLastOverThirtyDays',
    apiKey: FILTER_API_KEYS.lastModifiedBefore
  },
  hpModifiedLastOverFortyFiveDays: {
    label: 'Over 45 days',
    value: 'hpModifiedLastOverFortyFiveDays',
    apiKey: FILTER_API_KEYS.lastModifiedBefore
  },
  hpModifiedLastOverSixtyDays: {
    label: 'Over 65 days',
    value: 'hpModifiedLastOverSixtyDays',
    apiKey: FILTER_API_KEYS.lastModifiedBefore
  },
  hpModifiedLastAllTime: {
    label: 'All',
    value: 'hpModifiedLastAllTime',
    apiKey: FILTER_API_KEYS.lastModifiedBefore
  },
  recordStatusPending: {
    label: 'Pending',
    value: 'recordStatusPending',
    apiKey: FILTER_API_KEYS.ahaStatus
  },
  recordStatusAccepted: {
    label: 'Accepted',
    value: 'recordStatusAccepted', // BE uses 'reviewed' while FE renames it to 'accepted'
    apiKey: FILTER_API_KEYS.ahaStatus
  },
  recordStatusRejected: {
    label: 'Rejected',
    value: 'recordStatusRejected',
    apiKey: FILTER_API_KEYS.ahaStatus
  },
  recordStatusAll: {
    label: 'All',
    value: 'recordStatusAll',
    apiKey: FILTER_API_KEYS.ahaStatus
  },
  ahaStatusActive: {
    label: 'Active',
    value: 'ahaStatusActive',
    //apiKey: FILTER_API_KEYS.isActive
    apiKey: FILTER_API_KEYS.recordStatus
  },
  ahaStatusInactive: {
    label: 'Inactive',
    value: 'ahaStatusInactive',
    apiKey: FILTER_API_KEYS.recordStatus
  },
  ahaStatusAll: {
    label: 'All',
    value: 'ahaStatusAll',
    apiKey: FILTER_API_KEYS.recordStatus
  },
  hasAttachment: {
    label: 'Includes attached file(s)',
    value: 'hasAttachment',
    apiKey: FILTER_API_KEYS.hasAttachment
  },
  hasNoAttachments: {
    label: 'No attached file(s)',
    value: 'hasNoAttachments',
    apiKey: FILTER_API_KEYS.hasAttachment
  },
  hasOrHasNoAttachments: {
    label: 'All',
    value: 'hasOrHasNoAttachments',
    apiKey: FILTER_API_KEYS.hasAttachment
  }
};

export const TRADE_PARTNER_ISACTIVE_FILTER_OPTIONS = {
  active: {
    label: 'Active',
    value: 'active',
    apiKey: FILTER_API_KEYS.tradePartner_isActive
  },
  inactive: {
    label: 'Inactive',
    value: 'inactive',
    apiKey: FILTER_API_KEYS.tradePartner_isActive
  },
  allStatus: {
    label: 'All',
    value: 'allStatus',
    apiKey: FILTER_API_KEYS.tradePartner_isActive
  }
};
