import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
import AddIcon from '@mui/icons-material/Add';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography, Card, Button } from '@mui/material';

import withAuthorization from 'hocs/withAuthorization';
import withOnlineAccessOnly from 'hocs/withOnlineAccessOnly';
import useSoteriaStore from 'hooks/useSoteriaStore';
import useDebounce from 'hooks/useDebounce';
import useAhaTemplateCategory from 'hooks/useAhaTemplateCategory';
import useToast from 'hooks/useToast';
import StyledFab from 'shared/Buttons/Fab';
import StyledTable from 'shared/Table';
import StyledDeleteConfirmation from 'shared/DeleteConfirmation';
import AddTradePartnerFileDialog from 'components/ahas/AddTradePartnerFileDialog';
import ErrorNotice from 'components/common/ErrorNotice';
import PageNotFoundNotice from 'components/common/PageNotFoundNotice';
import AhaFileDialog from 'components/ahas/AhaFileDialog';
import useTradepartnerAhaCategoryFilesListState from 'store/tradePartnerAhaCategoryFilesListState';
import { formatDate } from 'utils/dateTime';
import {
  GET_PAGINATED_AHA_FILES_ON_TRADE_PARTNER_CATEGORY,
  DELETE_AHA_TRADE_PARTNER_CATEGORY
} from 'graphql/aha/tradePartnerCategory';
import { GET_SAFE_PROJECT_FOR_AREAS } from 'graphql/safeProject';
import useSoteriaQuery from 'hooks/useSoteriaQuery';

const useStyles = makeStyles(theme => ({
  header: {
    backgroundImage: `url(${theme.ahaBackground})`,
    backgroundSize: 'cover',
    height: 125,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    paddingLeft: theme.spacing(10),
    [theme.breakpoints.down('lg')]: { paddingLeft: theme.spacing(4) },
    [theme.breakpoints.down('md')]: { paddingLeft: theme.spacing(2) }
  },
  title: {
    textAlign: 'center',
    fontSize: '2rem',
    lineHeight: 1,
    paddingTop: theme.spacing(4.5),
    [theme.breakpoints.down('lg')]: {
      paddingTop: theme.spacing(5.5),
      fontSize: '1.5rem'
    }
  },
  tradePartner: {
    textAlign: 'center',
    fontSize: '1.125rem',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    lineHeight: 1.5,
    [theme.breakpoints.down('lg')]: { fontSize: '1rem' }
  },
  type: {
    lineHeight: 1,
    [theme.breakpoints.down('lg')]: { fontSize: '0.875rem' }
  },
  mainContainer: { margin: theme.spacing(2) },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('lg')]: { justifyContent: 'flex-start' }
  },
  rowText: { fontSize: '0.875rem' },
  metaDataLabel: { fontWeight: 'bold' },
  metaData: {
    color: theme.palette.secondary.contrastText,
    fontWeight: 'normal'
  },
  metaDataCardContainer: { marginBottom: theme.spacing(2) },
  metaDataCard: { padding: theme.spacing(2) },
  actionsCard: { padding: theme.spacing(2) },
  actionsCardContainer: { marginBottom: theme.spacing(2) },
  actionsButton: {
    width: '100%',
    marginBottom: theme.spacing(1),
    borderRadius: 16
  },
  buttonLabel: { fontSize: '0.75rem', fontWeight: 'bold' }
}));

const TradePartnerAhaFilesPage = () => {
  const {
    store: { whatsNewIsOpen = false }
  } = useSoteriaStore();
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const { t } = useTranslation();
  const { displayToast } = useToast();
  const { ahaTradePartnerCategoryId, projectId } = useParams();
  const { ahaTradePartnerCategory } = useAhaTemplateCategory(
    ahaTradePartnerCategoryId
  );
  const [isAddFileDialogOpen, setIsAddFileDialogOpen] = useState(false);
  const [
    tradepartnerAhaCategoryFilesListState,
    { handleTradePartnerAhaCategoryFilesListStateChange }
  ] = useTradepartnerAhaCategoryFilesListState();
  const [initialState] = useState(tradepartnerAhaCategoryFilesListState);
  const [fileViewIsOpen, setFileViewIsOpen] = useState(false);
  const [selectedAhaFile, setSelectedAhaFile] = useState();
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = useState(
    false
  );
  const [isDeleteConfirmed, toggleIsDeleteConfirmed] = useState(false);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const projectAreaParam = params.get('projectArea');

  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const initialFilter = projectAreaParam
    ? { projectArea: projectAreaParam }
    : null;
  const {
    data: safeProjectForAreasData,
    loading: isLoadingProjectForAreas
  } = useSoteriaQuery({
    gql: GET_SAFE_PROJECT_FOR_AREAS,
    queryHookOptions: {
      skip: !projectId,
      variables: { projectId }
    }
  });

  const getApiSortOrder = order => {
    let apiSortOrderField = null;
    let direction = order.direction;

    switch (order.name) {
      case 'description':
        apiSortOrderField = 'description';
        break;
      case 'uploaded':
        apiSortOrderField = 'uploaded';
        break;
      case 'uploadedBy.name':
        apiSortOrderField = 'uploadedBy_name';
        break;
      default:
        console.error(`Sort order, "${order.name}", is not being handled!`);
    }
    return apiSortOrderField && direction
      ? [
          {
            [apiSortOrderField]: direction.toUpperCase()
          }
        ]
      : null;
  };

  const { data, loading: isLoadingForGetFiles, error, fetchMore } = useQuery(
    GET_PAGINATED_AHA_FILES_ON_TRADE_PARTNER_CATEGORY,
    {
      variables: {
        ahaTradePartnerCategoryId: ahaTradePartnerCategoryId,
        first: initialState.first,
        search: initialState.search,
        skip: initialState.skip,
        order: getApiSortOrder(initialState.order),
        filter: initialFilter
      },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true
    }
  );

  const [
    deleteAhaTradePartnerCategory,
    { loading: isLoadingForDelete }
  ] = useMutation(DELETE_AHA_TRADE_PARTNER_CATEGORY);

  const isLoading =
    isLoadingForGetFiles || isLoadingForDelete || isLoadingProjectForAreas;
  const tradePartnerName = ahaTradePartnerCategory?.tradePartner?.name;
  const tradePartnerCategory = ahaTradePartnerCategory?.name;
  const tradePartnerFiles =
    data?.paginatedAhaFilesOnTradePartnerCategory?.ahaFiles ?? [];

  const columns = [
    {
      name: 'id',
      label: 'Id',
      options: {
        filter: false,
        display: 'excluded'
      }
    },
    {
      name: 'description',
      label: 'Description',
      options: {
        filter: false,
        searchable: true,
        sort: true
      }
    },
    {
      name: 'projectArea',
      label: 'Project Area',
      options: {
        filter: true,
        searchable: true,
        sort: true,
        filterList:
          isInitialLoad && projectAreaParam
            ? [projectAreaParam]
            : tradepartnerAhaCategoryFilesListState.filterList?.[2]?.length
            ? tradepartnerAhaCategoryFilesListState.filterList[2]
            : null,
        filterOptions: {
          names: safeProjectForAreasData?.safeProject?.areas ?? []
        }
      }
    },
    {
      name: 'uploadedBy.name',
      label: 'Uploaded By',
      options: {
        filter: false,
        searchable: true,
        sort: true
      }
    },
    {
      name: 'uploaded',
      label: 'Uploaded',
      options: {
        filter: false,
        searchable: true,
        sort: true,
        // eslint-disable-next-line react/display-name
        customBodyRender: value => (
          <Typography className={classes.rowText}>
            {formatDate(value)}
          </Typography>
        )
      }
    }
  ];
  useEffect(() => {
    if (isInitialLoad) {
      setIsInitialLoad(false);
    }
  }, [isInitialLoad, setIsInitialLoad]);

  const openFileView = rowData => {
    const ahaFile = tradePartnerFiles.find(
      ahaFile => ahaFile.id === rowData[0]
    );
    setSelectedAhaFile(ahaFile ?? null);
    setFileViewIsOpen(true);
  };

  const loadMore = variables => {
    return fetchMore({
      variables,
      updateQuery: (_previousResult, { fetchMoreResult, _queryVariables }) => {
        return fetchMoreResult;
      }
    });
  };

  const handlePageChange = tableState => {
    const { rowsPerPage, page } = tableState;

    let skip =
      page < tradepartnerAhaCategoryFilesListState.page
        ? tradepartnerAhaCategoryFilesListState.skip - rowsPerPage
        : tradepartnerAhaCategoryFilesListState.skip + rowsPerPage;

    handleTradePartnerAhaCategoryFilesListStateChange({
      ...tradepartnerAhaCategoryFilesListState,
      page,
      skip
    });

    loadMore({
      search: tradepartnerAhaCategoryFilesListState.search,
      skip,
      filter: tradepartnerAhaCategoryFilesListState.filter,
      order: getApiSortOrder(tradepartnerAhaCategoryFilesListState.order)
    });
  };

  const handleSortChange = tableState => {
    const { sortOrder } = tableState;

    handleTradePartnerAhaCategoryFilesListStateChange({
      ...tradepartnerAhaCategoryFilesListState,
      order: sortOrder,
      page: 0,
      skip: 0
    });

    loadMore({
      search: tradepartnerAhaCategoryFilesListState.search,
      skip: 0,
      filter: tradepartnerAhaCategoryFilesListState.filter,
      order: getApiSortOrder(sortOrder)
    });
  };

  const handleSearchChange = ({ tableState, debouncedHandleSearchChange }) => {
    const { searchText } = tableState;

    handleTradePartnerAhaCategoryFilesListStateChange({
      ...tradepartnerAhaCategoryFilesListState,
      skip: 0,
      page: 0,
      search: searchText
    });

    loadMore({
      search: searchText,
      skip: 0,
      filter: tradepartnerAhaCategoryFilesListState.filter,
      order: getApiSortOrder(tradepartnerAhaCategoryFilesListState.order)
    });
  };

  const handleFilterChange = tableState => {
    const { filterList } = tableState;
    const filters = filterList.flat().map(filter => filter.toLowerCase());
    const selectedProjectArea = filterList[2]?.[0] || null;
    const selectedFilters = {};
    if (selectedProjectArea) {
      selectedFilters.projectArea = selectedProjectArea;
      history.push({ search: `?projectArea=${selectedProjectArea}` });
    } else {
      history.push({ search: '' });
    }

    handleTradePartnerAhaCategoryFilesListStateChange({
      ...tradepartnerAhaCategoryFilesListState,
      filter: selectedFilters,
      filterList: filters,
      skip: 0,
      page: 0
    });

    loadMore({
      search: tradepartnerAhaCategoryFilesListState.search,
      filter: selectedFilters,
      skip: 0,
      order: getApiSortOrder(tradepartnerAhaCategoryFilesListState.order)
    });
  };

  const { debounced: debouncedHandleSearchChange } = useDebounce(
    handleSearchChange
  );

  const refetchQuery = () => {
    return loadMore({
      first: tradepartnerAhaCategoryFilesListState.first,
      skip: tradepartnerAhaCategoryFilesListState.skip,
      filter: tradepartnerAhaCategoryFilesListState.filter,
      search: tradepartnerAhaCategoryFilesListState.search,
      order: getApiSortOrder(tradepartnerAhaCategoryFilesListState.order)
    });
  };

  const options = {
    onRowClick: rowData => openFileView(rowData),
    download: false,
    count: data?.paginatedTradePartnerPersonnelsOnProject?.total ?? null,
    rowsPerPage: tradepartnerAhaCategoryFilesListState.first,
    searchText: tradepartnerAhaCategoryFilesListState.search,
    page: tradepartnerAhaCategoryFilesListState.page,
    rowsPerPageOptions: [],
    sortOrder: tradepartnerAhaCategoryFilesListState.order,
    serverSide: true,
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          handlePageChange(tableState);
          break;
        case 'sort':
          handleSortChange(tableState);
          break;
        case 'filterChange':
        case 'resetFilters':
          handleFilterChange(tableState);
          break;
        case 'search':
        case 'onSearchClose':
          debouncedHandleSearchChange({
            tableState,
            tradepartnerAhaCategoryFilesListState
          });
          break;
        default:
      }
    }
  };

  const handleClose = () => {
    setIsConfirmDeleteDialogOpen(false);
    toggleIsDeleteConfirmed(false);
  };

  const handleDeleteSubmit = () => {
    deleteAhaTradePartnerCategory({
      variables: { id: ahaTradePartnerCategoryId }
    })
      .then(() => {
        displayToast(
          t('tradePartnerAhaFilesPage.toasts.deleteProjectTemplate.success', {
            name: ahaTradePartnerCategory?.tradePartner?.name
          }),
          'success'
        );
        handleClose();
        history.push(`/projects/${projectId}/ahas`);
      })
      .catch(error => {
        console.error('Delete Project Template Error: ', error);
        displayToast(
          t('tradePartnerAhaFilesPage.toasts.deleteProjectTemplate.error'),
          'error'
        );
      });
  };

  if (error) {
    if (error.message?.includes('not found')) {
      return <PageNotFoundNotice />;
    } else {
      return <ErrorNotice />;
    }
  }

  return (
    <>
      <StyledFab
        onClick={() => setIsAddFileDialogOpen(true)}
        shouldShift={whatsNewIsOpen}
        icon={<AddIcon />}
      />
      <Grid container justifyContent="center">
        <Grid item xs={12} className={classes.header}>
          <Typography color="textPrimary" className={classes.title}>
            {tradePartnerCategory}
          </Typography>
          <Typography color="textPrimary" className={classes.tradePartner}>
            {tradePartnerName} AHA Files
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.mainContainer}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Grid container>
                <Grid item xs={12} className={classes.metaDataCardContainer}>
                  <Card className={classes.metaDataCard}>
                    <Typography
                      color="textPrimary"
                      className="bold"
                      gutterBottom>
                      Details
                    </Typography>
                    <Typography
                      color="textSecondary"
                      className={classes.metaDataLabel}>
                      Trade Partner:{' '}
                      <span className={classes.metaData}>
                        {ahaTradePartnerCategory?.tradePartner?.name}
                      </span>
                    </Typography>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card className={classes.actionsCard}>
                    <Typography
                      color="textPrimary"
                      className="bold"
                      gutterBottom>
                      Actions
                    </Typography>
                    <Grid item xs={12}>
                      <Button
                        color="secondary"
                        className={classes.actionsButton}
                        onClick={() => setIsConfirmDeleteDialogOpen(true)}
                        variant="contained">
                        <Typography className={classes.buttonLabel}>
                          Delete
                        </Typography>
                      </Button>
                    </Grid>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={9} lg={9}>
              <LoadingOverlay
                active={isLoading}
                spinner
                fadeSpeed={50}
                styles={{
                  overlay: base => ({
                    ...base,
                    background: theme.palette.background.overlay
                  }),
                  spinner: base => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                      stroke: theme.palette.primary.main
                    }
                  })
                }}>
                <StyledTable
                  title={'Files'}
                  data={tradePartnerFiles}
                  columns={columns}
                  options={options}
                />
              </LoadingOverlay>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <AddTradePartnerFileDialog
        isAddFileDialogOpen={isAddFileDialogOpen}
        setIsAddFileDialogOpen={setIsAddFileDialogOpen}
        ahaTradePartnerCategoryId={ahaTradePartnerCategoryId}
        refetchQuery={refetchQuery}
      />
      <AhaFileDialog
        isOpen={fileViewIsOpen}
        setIsOpen={setFileViewIsOpen}
        ahaFile={selectedAhaFile}
        refetchQuery={refetchQuery}
      />
      <StyledDeleteConfirmation
        title={t('tradePartnerAhaFilesPage.deleteConfirmation.title')}
        dialogIsOpen={isConfirmDeleteDialogOpen}
        isLoading={isLoading}
        handleClose={handleClose}
        type="notice"
        warningMessage={t(
          'tradePartnerAhaFilesPage.deleteConfirmation.warningMessage',
          { tradePartnerName: ahaTradePartnerCategory?.tradePartner?.name }
        )}
        isConfirmed={isDeleteConfirmed}
        handleChange={event => toggleIsDeleteConfirmed(event.target.checked)}
        handleSubmit={handleDeleteSubmit}
      />
    </>
  );
};

export default withOnlineAccessOnly(
  withAuthorization(TradePartnerAhaFilesPage, {
    personnelOnProject: true
  })
);
