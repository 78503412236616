import gql from 'graphql-tag';

export const GET_ALL_UNLINKED_HP_EMPLOYEES = gql`
  query GetAllUnlinedHPEmployees {
    unlinkedHPTradePartnerPersonnels {
      id
      customId
      isActive
      isSupervisor
      tradePartner {
        id
        name
        project {
          id
        }
      }
      personnel {
        id
        nickname
        fullName
        contactInformation {
          emailAddress {
            email
          }
        }
      }
      created
    }
  }
`;
