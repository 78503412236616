import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import StyledAutoComplete from 'shared/AutoComplete';
import { GET_ALL_GLOBAL_STEP_CATEGORIES } from 'graphql/aha';
import useSoteriaQuery from 'hooks/useSoteriaQuery';

const AhaStepCategorySelect = ({
  selectedCategory,
  meta,
  handleChange,
  initialValues,
  isDisabled,
  ...rest
}) => {
  const { t } = useTranslation();

  const { data, loading } = useSoteriaQuery({
    gql: GET_ALL_GLOBAL_STEP_CATEGORIES
  });

  const options = data?.ahaGlobalStepCategories ?? [];

  return (
    <StyledAutoComplete
      label={t('AhaCategorySelect.label')}
      defaultValue={initialValues}
      selectedValue={selectedCategory}
      meta={meta}
      handleChange={handleChange}
      placeholder={t('AhaCategorySelect.placeholder')}
      isRequired={true}
      isDisabled={isDisabled}
      options={options.map(category => ({
        label: category.name,
        value: category
      }))}
      isLoading={loading}
      handleInputChange={() => {}}
      isCreatable
      isMulti={false}
      {...rest}
    />
  );
};

AhaStepCategorySelect.propTypes = {
  selectedCategory: PropTypes.any,
  meta: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  initialValues: PropTypes.array,
  isDisabled: PropTypes.bool
};

export default AhaStepCategorySelect;
