import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSubscription } from '@apollo/react-hooks';
import SettingsIcon from '@mui/icons-material/Settings';
import LinkOff from '@mui/icons-material/LinkOff';
import makeStyles from '@mui/styles/makeStyles';
import { Typography, Grid, IconButton } from '@mui/material';

import useRoles from 'hooks/useRoles';
import withAuthorization from 'hocs/withAuthorization';
import withOnlineAccessOnly from 'hocs/withOnlineAccessOnly';
import StyledNotice from 'shared/Notice';
import PersonnelInfoCard from 'components/personnel/PersonnelInfoCard';
import TradePartnerPersonnelStatusCard from 'components/tradepartners/TradePartnerPersonnelStatusCard';
import TradePartnerPersonnelProjectInfoCard from 'components/tradepartners/TradePartnerPersonnelProjectInfoCard';
import PersonnelJobHistoryList from 'components/personnel/PersonnelJobHistoryList';
import PersonnelTrainingList from 'components/personnel/PersonnelTrainingList';
import PersonnelFlags from 'components/personnel/PersonnelFlags';
import useTradePartnerPersonnel from 'hooks/useTradePartnerPersonnel';
import LoadingSpinner from 'components/common/LoadingSpinner';
import ErrorNotice from 'components/common/ErrorNotice';
import PageNotFoundNotice from 'components/common/PageNotFoundNotice';
import PersonnelMergeDialog from 'components/personnel/PersonnelMergeDialog';
import TradePartnerPersonnelRelinkDialog from 'components/personnel/TradePartnerPersonnelRelinkDialog';
import TradePartnerPersonnelDeleteCard from 'components/tradepartners/TradePartnerPersonnelDeleteCard';
import PersonnelHeader from 'components/personnel/PersonnelHeader';
import LinkHpPersonnelDialog from 'components/personnel/LinkHpPersonnelDialog';
import { TRADE_PARTNER_PERSONNEL_SUBSCRIPTION } from 'graphql/tradePartnerPersonnel';
import SafetyHoursSupervisorsCard from 'components/personnel/PersonnelSafetyHoursSupervisorCard';

const useStyles = makeStyles(theme => ({
  title: { fontSize: '2rem', display: 'inline' },
  linkButton: {
    color: theme.palette.primary.contrastText,
    fontWeight: 'bold',
    background: 'none',
    border: 'none',
    textDecoration: 'underline',
    fontSize: '0.875rem',
    '&:focus': {
      outline: 'none'
    },
    '&:hover': {
      cursor: 'pointer'
    }
  }
}));

const ProjectPersonnelPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { tradePartnerPersonnelId } = useParams();
  const { tradePartnerPersonnel, error, loading } = useTradePartnerPersonnel({
    tradePartnerPersonnelId,
    shouldIncludeGeneralRelations: true,
    shouldIncludeWorkHistory: true
  });
  const { isSupportRole } = useRoles();
  const [adLinkModalIsOpen, setAdLinkModalIsOpen] = useState(false);
  const [mergeDialogIsOpen, setMergeDialogIsOpen] = useState(false);
  const [relinkDialogIsOpen, setRelinkDialogIsOpen] = useState(false);

  useSubscription(TRADE_PARTNER_PERSONNEL_SUBSCRIPTION, {
    variables: { tradePartnerPersonnelId: tradePartnerPersonnelId }
  });

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    if (error.message?.includes('not found')) {
      return <PageNotFoundNotice />;
    } else {
      return <ErrorNotice />;
    }
  }

  if (tradePartnerPersonnel && !tradePartnerPersonnel.personnel) {
    return (
      <ErrorNotice message={t('projectPersonnelPage.personnelErrorNotice')} />
    );
  }

  return <>
    {adLinkModalIsOpen && (
      <LinkHpPersonnelDialog
        isOpen={adLinkModalIsOpen}
        toggleDialog={setAdLinkModalIsOpen}
        personnel={tradePartnerPersonnel?.personnel}
      />
    )}
    <Grid container>
      <Grid item xs={12}>
        <Typography color="textPrimary" className={classes.title}>
          {t('projectPersonnelPage.pageTitle')}
        </Typography>
        <IconButton
          onClick={() => setMergeDialogIsOpen(true)}
          style={{ marginBottom: 8 }}
          size="large">
          <SettingsIcon />
        </IconButton>
        {tradePartnerPersonnel.personnel.soteriaAdUser && isSupportRole && (
          <IconButton
            onClick={() => setRelinkDialogIsOpen(true)}
            style={{ marginBottom: 8 }}
            size="large">
            <LinkOff />
          </IconButton>
        )}
      </Grid>
      <PersonnelHeader tradePartnerPersonnel={tradePartnerPersonnel} />
      {tradePartnerPersonnel?.shouldBeAdLinked && (
        <StyledNotice
          type={'urgent'}
          message={
            <Grid container>
              <Grid item>{t('projectPersonnelPage.unlinkedWarning')}</Grid>
              <Grid item>
                <button
                  onClick={() => setAdLinkModalIsOpen(true)}
                  onKeyDown={() => setAdLinkModalIsOpen(true)}
                  className={classes.linkButton}
                  data-testid="link-employee-button">
                  {t('projectPersonnelPage.actions.linkEmployeeButton')}
                </button>
              </Grid>
            </Grid>
          }
        />
      )}
      <Grid container direction="row" spacing={3}>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <PersonnelInfoCard
                tradePartnerPersonnel={tradePartnerPersonnel}
              />
            </Grid>
            <Grid item>
              <TradePartnerPersonnelProjectInfoCard
                tradePartnerPersonnel={tradePartnerPersonnel}
                isHenselPhelpsEmployee={
                  tradePartnerPersonnel.isHenselPhelpsEmployee
                }
              />
            </Grid>
            <Grid item>
              <PersonnelJobHistoryList
                tradePartnerHistory={
                  tradePartnerPersonnel?.personnel?.tradePartnerHistory ?? []
                }
              />
            </Grid>
            <Grid item>
              <PersonnelTrainingList
                tradePartnerPersonnel={tradePartnerPersonnel}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <TradePartnerPersonnelStatusCard
                tradePartnerPersonnel={tradePartnerPersonnel}
              />
            </Grid>
            <Grid item>
              <PersonnelFlags tradePartnerPersonnel={tradePartnerPersonnel} />
            </Grid>
            {isSupportRole && (
              <Grid item>
                <TradePartnerPersonnelDeleteCard
                  tradePartnerPersonnel={tradePartnerPersonnel}
                />
              </Grid>
            )}
            {tradePartnerPersonnel.isHenselPhelpsEmployee &&
              tradePartnerPersonnel.personnel.soteriaAdUser?.employeeId && (
                <Grid item>
                  <SafetyHoursSupervisorsCard
                    tradePartnerPersonnel={tradePartnerPersonnel}
                  />
                </Grid>
              )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    <PersonnelMergeDialog
      mergeDialogIsOpen={mergeDialogIsOpen}
      setMergeDialogIsOpen={setMergeDialogIsOpen}
      tradePartnerPersonnel={tradePartnerPersonnel}
    />
    <TradePartnerPersonnelRelinkDialog
      dialogIsOpen={relinkDialogIsOpen}
      setDialogIsOpen={setRelinkDialogIsOpen}
      tradePartnerPersonnel={tradePartnerPersonnel}
    />
  </>;
};
export default withOnlineAccessOnly(withAuthorization(ProjectPersonnelPage));
