import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';

import StyledAutoComplete from 'shared/AutoComplete';
import { GET_AD_CONTACTS } from 'graphql/adContact';
import { GET_ONBOARDING_APPLICANTS } from 'graphql/onboardingApplicant';

const AdUserSelect = ({
  selectedUser,
  handleChange,
  isDisabled = false,
  isRequired = false,
  placeholder,
  customError,
  autoFocus = false,
  optionFilter = () => true,
  includeOnboardingApplicantsWithNoAdUser = false,
  ...rest
}) => {
  const [inputValue, handleInputChange] = useState('');

  const { data, loading } = useQuery(GET_AD_CONTACTS, {
    skip: !inputValue,
    variables: {
      search: inputValue,
      filter: { accountEnabled: true }
    },
    fetchPolicy: 'no-cache'
  });

  const {
    data: onboardingApplicantData,
    loading: onboardingApplicantLoading
  } = useQuery(GET_ONBOARDING_APPLICANTS, {
    skip: !inputValue || !includeOnboardingApplicantsWithNoAdUser,
    variables: {
      search: inputValue
    },
    fetchPolicy: 'no-cache'
  });

  const adUserOptions = data && data.adContacts ? data.adContacts : [];

  // TODO: remove when BE gets fixed
  const isOptionValid = (upn, name) => {
    const lowercaseName = name.toLowerCase();
    if (
      lowercaseName.includes('jobsite') ||
      lowercaseName.includes('jobesite') ||
      lowercaseName.includes('conference') ||
      lowercaseName.includes('room') ||
      upn.includes('safe') ||
      upn.includes('svc_') ||
      upn.includes('service')
    ) {
      return false;
    }
    return true;
  };

  const filteredAdUserOptions = adUserOptions.filter(function(item) {
    return isOptionValid(item.upn, item.name) && optionFilter(item);
  });

  const onboardingApplicantOptions =
    onboardingApplicantData && onboardingApplicantData.onboardingApplicants
      ? onboardingApplicantData.onboardingApplicants
      : [];

  const filteredOptions = [
    ...filteredAdUserOptions.map(item => ({
      label: `${item.name}${item.jobTitle ? ` (${item.jobTitle})` : ''}`,
      value: item.id,
      ...item
    })),
    ...onboardingApplicantOptions.map(item => ({
      label: `${item.name} (onboarding in progress - ${item.email})`,
      value: item.upn,
      ...item
    }))
  ];

  return (
    <StyledAutoComplete
      selectedValue={selectedUser}
      handleChange={handleChange}
      placeholder={placeholder}
      isRequired={isRequired}
      isDisabled={isDisabled}
      options={filteredOptions}
      isLoading={loading || onboardingApplicantLoading}
      handleInputChange={handleInputChange}
      customError={customError}
      autoFocus={autoFocus}
      {...rest}
    />
  );
};

AdUserSelect.propTypes = {
  isDisabled: PropTypes.bool,
  selectedUser: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  isRequired: PropTypes.bool,
  placeholder: PropTypes.node.isRequired,
  customError: PropTypes.any,
  autoFocus: PropTypes.any
};

export default AdUserSelect;
