import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import { DatePicker } from '@mui/x-date-pickers';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { isDateTimeInstance } from 'utils/dateTime';

import useToast from 'hooks/useToast';
import StyledProjectDetailsCard from 'shared/ProjectDetailsCard';
import { UPDATE_PROJECT } from 'graphql/project';

const useStyles = makeStyles(theme => ({
  activeProject: {
    fontWeight: 'bold',
    color: theme.palette.success.main,
    marginTop: theme.spacing(2)
  },
  completedProject: {
    fontWeight: 'bold',
    color: theme.palette.error.main,
    marginTop: theme.spacing(2)
  },
  input: {
    fontWeight: 'bold',
    color: theme.palette.error.main
  }
}));

const CompleteProjectCard = ({ project, isLoading }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { displayToast } = useToast();
  const [isEditing, setIsEditing] = useState(false);
  const completedDate = project.closed;
  const [selectedDate, setSelectedDate] = useState(project.closed);

  const [updateProject, { loading: isLoadingForUpdateProject }] = useMutation(
    UPDATE_PROJECT
  );

  const isLoadingForCard = isLoading || isLoadingForUpdateProject;

  const canSubmit = () => {
    return selectedDate !== completedDate;
  };

  const handleCancel = () => {
    setIsEditing(false);
    setSelectedDate(completedDate ?? null);
  };

  const handleSubmit = () => {
    updateProject({
      variables: {
        id: project?.id,
        closed: selectedDate
      }
    })
      .then(({ data: { updateProject: updatedProject } }) => {
        displayToast(t('completeProjectCard.toasts.success'), 'success');
        setIsEditing(false);
      })
      .catch(error => {
        console.error('Project Substantially Complete Error: ', error);
        displayToast(t('completeProjectCard.toasts.error'), 'error');
      });
  };

  const handleDateChange = date => {
    setSelectedDate(date);
  };

  function formatDate(date) {
    return isDateTimeInstance(date)
      ? date.toFormat('MM/yyyy')
      : DateTime.fromISO(date).toFormat('MM/yyyy');
  }

  return (
    <StyledProjectDetailsCard
      isEditing={isEditing}
      setIsEditing={setIsEditing}
      isLoading={isLoadingForCard}
      title={t('completeProjectCard.title')}
      editButtonLabel={t('completeProjectCard.editButtonLabel')}
      readContent={
        <Grid container>
          <Grid item xs={12}>
            <Typography>{t('completeProjectCard.instructions')}</Typography>
          </Grid>
          {!selectedDate && !completedDate && (
            <Grid item xs={12}>
              <Typography className={classes.activeProject}>
                {t('completeProjectCard.activeProject')}
              </Typography>
            </Grid>
          )}
          {(selectedDate || completedDate) && (
            <Grid item xs={12}>
              <Typography className={classes.completedProject}>
                {t('completeProjectCard.completedDateLabel')}{' '}
                {selectedDate
                  ? formatDate(selectedDate)
                  : formatDate(completedDate)}
              </Typography>
            </Grid>
          )}
        </Grid>
      }
      writeContent={
        <Grid container className="margin-top">
          <Grid item xs={12}>
            <DatePicker
              value={DateTime.fromISO(selectedDate)}
              onChange={date => handleDateChange(date)}
              DialogProps={{ disableBackdropClick: true }}
              InputProps={{ className: classes.input }}
              views={['year', 'month']}
              openTo="month"
              clearable
            />
          </Grid>
        </Grid>
      }
      canSubmit={canSubmit}
      handleCancel={handleCancel}
      handleSubmit={handleSubmit}
    />
  );
};

CompleteProjectCard.propTypes = {
  project: PropTypes.object,
  isLoading: PropTypes.bool
};

export default CompleteProjectCard;
