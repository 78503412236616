import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import { Typography, Grid, useTheme } from '@mui/material';

import StyledUserNoticeTemplate from 'components/common/UserNoticeTemplate';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import { SERVICENOW_URL } from 'constants/externalLinks';

const useStyles = makeStyles(theme => ({
  heading: { fontSize: '2rem' },
  img: { width: 200, padding: theme.spacing(3) }
}));

export const ErrorNotice = ({ message }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <StyledUserNoticeTemplate
      image={
        <img
          className={classes.img}
          src={theme.errorNotice}
          alt=""
          role="presentation"
        />
      }
      content={
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography color="textPrimary" className={classes.heading}>
              {t('errorNotice.heading')}
            </Typography>
          </Grid>
          {message && (
            <Grid item>
              <Typography color="textPrimary">{message}</Typography>
            </Grid>
          )}
          {!message && (
            <Grid item>
              <Typography color="textPrimary">
                {t('errorNotice.contactSupport')}
              </Typography>
            </Grid>
          )}
          <Grid item>
            <Grid container justifyContent="center">
              <Grid item>
                <StyledButtonPrimary
                  data-testid="servicenow-external-link"
                  label={t('helpAndSupportDialog.serviceNow')}
                  onClick={() =>
                    window.open(SERVICENOW_URL, '_blank', 'noopener')
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
    />
  );
};

ErrorNotice.propTypes = {
  message: PropTypes.string
};

export default ErrorNotice;
