import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import { Typography, Grid, Tabs, Tab, Box } from '@mui/material';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';

import StyledDialog from 'shared/Dialog';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import ButtonSecondary from 'shared/Buttons/ButtonSecondary';
import StyledNotice from 'shared/Notice';
import ButtonPrimary from 'shared/Buttons/ButtonPrimary';
import ThemeSwitch from 'components/appbar/ThemeSwitch';
import OfflineModeSwitch from 'components/offline/OfflineModeSwitch';
import LanguageSwitch from 'components/appbar/LanguageSwitch';
import useIsManualOfflineModeOn from 'store/manualOfflineMode';
import useIsOnline from 'store/onlineDetection';
import useSoteriaApolloClient from 'config/apolloConfig';
import useHelpAndSupport from 'hooks/useHelpAndSupport';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  };
}

const useStyles = makeStyles(theme => ({
  root: { flexGrow: 1, display: 'flex', width: '100%' },
  tabs: { borderRight: `1px solid ${theme.palette.divider}` },
  tabPanel: { width: '100%', padding: theme.spacing(2, 3) },
  contentText: { fontSize: '0.875rem' },
  container: { position: 'relative' },
  buttonContainer: { marginTop: theme.spacing(1) },
  noticeContainer: { margin: theme.spacing(2, 0, 1, 0) }
}));

// TODO: Add panel for button that will clear cache and un-register the SW

const SettingsDialog = props => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isOpen, toggleDialog, settingsTab, setSettingsTab } = props;
  const [value, setValue] = React.useState(settingsTab ?? 0);
  const handleChange = (event, newValue) => {
    setSettingsTab(newValue);
    setValue(newValue);
  };
  const { isOnline } = useIsOnline();
  const {
    isOfflineModeEnabled,
    refetchOfflineModeQueriesToCache
  } = useIsManualOfflineModeOn();
  const { purgeOfflineCache } = useSoteriaApolloClient();
  const { toggleHelp } = useHelpAndSupport();

  useEffect(() => {
    if (settingsTab !== value) {
      setValue(settingsTab);
    }
  }, [settingsTab, value]);

  const handleClose = () => {
    setSettingsTab(0);
    toggleDialog(false);
  };

  return (
    <StyledDialog
      data-testid="app-settings-dialog"
      isOpen={isOpen}
      handleClose={handleClose}
      disableBackdropClick={false}
      disableEscapeKeyDown={false}
      title={t('settingsDialog.title')}
      content={
        <Grid container id="drawer-container" className={classes.container}>
          <Grid item className={classes.root}>
            <Tabs
              indicatorColor="secondary"
              textColor="inherit"
              value={value}
              variant="scrollable"
              orientation="vertical"
              onChange={handleChange}
              indicatorColor="primary"
              className={classes.tabs}>
              <Tab
                style={{ marginRight: 32 }}
                label="Display Settings"
                {...a11yProps(0)}
              />
              <Tab
                style={{ marginRight: 32 }}
                label="Offline Settings"
                {...a11yProps(1)}
              />
            </Tabs>
            <TabPanel value={value} index={0} className={classes.tabPanel}>
              <Grid container direction="column" spacing={3}>
                <Grid item xs={12}>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center">
                    <Grid item>
                      <Typography className={classes.contentText}>
                        {t('settingsDialog.optionHeadings.theme')}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <ThemeSwitch />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center">
                    <Grid item>
                      <Typography className={classes.contentText}>
                        {t('settingsDialog.optionHeadings.language')}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <LanguageSwitch />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={1} className={classes.tabPanel}>
              <Grid container direction="column" spacing={3}>
                <Grid item xs={12}>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center">
                    <Grid item>
                      <Typography className={classes.contentText}>
                        {t('settingsDialog.optionHeadings.offlineMode')}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <OfflineModeSwitch />
                    </Grid>
                  </Grid>
                  <Grid item className={classes.noticeContainer}>
                    <StyledNotice
                      message={t('settingsDialog.offlineModeNotice')}
                    />
                  </Grid>
                  <Grid item>
                    <Typography className={classes.contentText}>
                      {t('settingsDialog.offlineModeDescription')}
                    </Typography>
                  </Grid>
                  <Grid item className={classes.buttonContainer}>
                    <ButtonPrimary
                      disabled={!isOfflineModeEnabled || !isOnline}
                      label={t('offlineModeSwitch.refreshCache')}
                      onClick={async () => {
                        await purgeOfflineCache();
                        if (refetchOfflineModeQueriesToCache) {
                          refetchOfflineModeQueriesToCache();
                        }
                      }}
                      icon={<RotateLeftIcon className={classes.icon} />}
                    />
                  </Grid>
                  <Grid item className={classes.buttonContainer}>
                    <ButtonSecondary
                      label={t('settingsDialog.learnMoreOfflineButton')}
                      onClick={() => toggleHelp(true, 'enableOfflineMode')}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </TabPanel>
          </Grid>
        </Grid>
      }
      actions={
        <StyledButtonPrimary
          onClick={handleClose}
          label={t('settingsDialog.closeButton')}
        />
      }
    />
  );
};

SettingsDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleDialog: PropTypes.func.isRequired,
  settingsTab: PropTypes.number,
  setSettingsTab: PropTypes.func.isRequired
};

export default SettingsDialog;
