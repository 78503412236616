import React, { useState } from 'react';
import PropTypes from 'prop-types';
import copy from 'copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-final-form-hooks';
import { useMutation } from '@apollo/react-hooks';
import EditIcon from '@mui/icons-material/Edit';
import CallIcon from '@mui/icons-material/Call';
import MailIcon from '@mui/icons-material/Mail';
import CakeIcon from '@mui/icons-material/Cake';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import makeStyles from '@mui/styles/makeStyles';
import {
  Grid,
  Typography,
  Card,
  LinearProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ClickAwayListener
} from '@mui/material';

import useToast from 'hooks/useToast';
import useStringIncludesHenselPhelps from 'hooks/useStringIncludesHenselPhelps';
import StyledButtonGroup from 'shared/Buttons/ButtonGroup';
import StyledButtonMuted from 'shared/Buttons/ButtonMuted';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import PersonnelFormFields from 'components/personnel/PersonnelFormFields';
import { formatDateOfBirth, getPhoneValues } from 'utils';
import { UPDATE_PERSONNEL } from 'graphql/personnel';
import {
  formatPhoneNumber,
  formatInputAndInternationalPhoneNumber
} from 'utils/formatPhoneNumber';
import SimilarPersonnelDialog from '../SimilarPersonnelDialog';
import { dateTimeToLuxonDateTime } from 'utils/dateTime';

const useStyles = makeStyles(theme => ({
  titleContainer: { paddingBottom: theme.spacing(1) },
  titleText: { fontWeight: 'bold' },
  card: { padding: theme.spacing(3) },
  notesContainer: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
    borderRadius: 4
  },
  accordion: {
    boxShadow: 'none',
    border: '1px solid ' + theme.palette.field.border,
    borderRadius: 4
  },
  accordionSummary: { height: 36, minHeight: 36 },
  serviceIdContainer: {
    border: '1px solid' + theme.palette.field.border,
    padding: theme.spacing(0.5, 1),
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(1),
    wordBreak: 'break-all'
  },
  serviceNowLink: { cursor: 'pointer' },
  instructions: { fontSize: '0.875rem', marginBottom: 16 },
  label: { fontSize: '0.75rem', fontWeight: 'bold' },
  buttonContainer: { alignSelf: 'flex-end' }
}));

const InfoItem = ({ icon, info }) => {
  return (
    <Grid item xs={12}>
      <Grid container direction="row" spacing={1} alignItems="center">
        <Grid item>{icon}</Grid>
        <Grid item>
          <Typography color="textSecondary" className="inline">
            {info}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

InfoItem.propTypes = {
  icon: PropTypes.node.isRequired,
  info: PropTypes.any.isRequired
};

const PersonnelInfoCard = ({ tradePartnerPersonnel }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isEditing, toggleEditing] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [
    relatedPersonnelDialogIsOpen,
    setRelatedPersonnelDialogIsOpen
  ] = useState(false);
  const { displayToast } = useToast();
  const [updatePersonnel, { loading: isLoading }] = useMutation(
    UPDATE_PERSONNEL
  );
  const personnel = tradePartnerPersonnel.personnel;
  const { stringIncludesHenselPhelps } = useStringIncludesHenselPhelps();
  const isHenselPhelps = stringIncludesHenselPhelps(
    tradePartnerPersonnel.tradePartner.name
  );

  const { form, values, pristine } = useForm({
    onSubmit: () => {},
    initialValues: {
      firstName: personnel.firstName ?? '',
      middleName: personnel.middleName ?? '',
      lastName: personnel.lastName ?? '',
      nickname: personnel.nickname ?? '',
      dob: personnel.dob ?? '',
      phoneNumber: personnel.contactInformation.primaryPhone?.number ?? '',
      email: personnel.contactInformation.emailAddress.email ?? '',
      notes: personnel.notes ?? ''
    }
  });

  const canSubmit = () => {
    const { error, errors, invalid } = form.getState();
    if (isHenselPhelps) {
      return !(errors.nickname || pristine || isLoading);
    } else {
      return !(pristine || error || invalid || isLoading);
    }
  };

  const getDob = () => {
    if (values.dob) {
      return dateTimeToLuxonDateTime(values.dob)?.toFormat('yyyy-MM-dd');
    }
    return undefined;
  };

  const personnelPhoneNumber =
    personnel.contactInformation.primaryPhone?.number;
  const personnelInputPhoneNumber = formatPhoneNumber(personnelPhoneNumber);
  const personnelInternationalPhoneNumber = formatInputAndInternationalPhoneNumber(
    personnelPhoneNumber
  )?.international;
  const validPersonnelPhoneNumber = personnelInternationalPhoneNumber
    ? personnelInternationalPhoneNumber
    : personnelInputPhoneNumber;

  const handleSubmit = () => {
    updatePersonnel({
      variables: {
        input: {
          id: personnel.id,
          firstName: values.firstName,
          middleName: values.middleName,
          lastName: values.lastName,
          nickname: values.nickname,
          dob: getDob(),
          contactInformation: {
            primaryPhone: getPhoneValues(
              values.phoneNumber,
              null,
              personnel.contactInformation.primaryPhone?.number,
              'Primary'
            ),
            emailAddress: {
              email: values.email
            }
          },
          notes: values.notes,
          flagsToAdd: [],
          flagsToDelete: [],
          flagsToUpdate: [],
          trainingsToAdd: [],
          trainingsToDelete: [],
          trainingsToUpdate: []
        }
      }
    })
      .then(({ data: { updatePersonnel: personnel } }) => {
        handleClose();
        displayToast(
          t('personnelInfoCard.toasts.update.success', {
            personnelName: personnel.fullName
          }),
          'success'
        );
      })
      .catch(error => {
        console.error('Update Personnel Error: ', error);
        displayToast(t('personnelInfoCard.toasts.update.error'), 'error');
      });
  };

  const handleClose = () => {
    form.reset();
    toggleEditing(false);
  };

  const toggleAccordion = () => {
    setIsExpanded(!isExpanded);
  };

  const handleClickAway = () => {
    setIsExpanded(false);
  };

  return (
    <>
      {isLoading && <LinearProgress color="primary" />}
      <Card className={classes.card}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container direction="column">
              <Grid item>
                <Grid
                  container
                  justifyContent="flex-end"
                  className={classes.titleContainer}>
                  <Grid item>
                    {isEditing && (
                      <Typography color="primary">
                        {t('personnelInfoCard.editMode.title')}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              {!isEditing && (
                <Grid item>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography
                        component="h2"
                        gutterBottom
                        className={classes.titleText}>
                        {t('personnelInfoCard.title')}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Grid container>
                        {personnel.dob && (
                          <InfoItem
                            icon={
                              <CakeIcon
                                aria-hidden={false}
                                title={t('personnelInfoCard.icon.dateOfBirth')}
                                color="action"
                              />
                            }
                            info={formatDateOfBirth(personnel.dob)}
                          />
                        )}
                        {personnel.contactInformation.primaryPhone?.number && (
                          <InfoItem
                            icon={
                              <CallIcon
                                aria-hidden={false}
                                title={t('personnelInfoCard.icon.phoneNumber')}
                                color="action"
                              />
                            }
                            info={
                              <Typography component="span" className="inline">
                                {validPersonnelPhoneNumber}
                              </Typography>
                            }
                          />
                        )}
                        {personnel.contactInformation.emailAddress?.email && (
                          <InfoItem
                            icon={
                              <MailIcon
                                aria-hidden={false}
                                title={t('personnelInfoCard.icon.email')}
                                color="action"
                              />
                            }
                            info={
                              personnel.contactInformation.emailAddress.email
                            }
                          />
                        )}
                        <Grid item xs={12} className="margin-top">
                          <Grid container direction="column">
                            <Grid item>
                              <Typography>
                                {t('personnelInfoCard.notes.title')}
                              </Typography>
                            </Grid>
                            <Grid item className={classes.notesContainer}>
                              <Typography>
                                {personnel?.notes
                                  ? personnel.notes
                                  : t(
                                      'personnelInfoCard.notes.noNotesDisclaimer'
                                    )}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} className="margin-top">
                          <ClickAwayListener onClickAway={handleClickAway}>
                            <Accordion
                              expanded={isExpanded}
                              onChange={toggleAccordion}
                              className={classes.accordion}>
                              <AccordionSummary
                                expandIcon={<KeyboardArrowDownIcon />}
                                className={classes.accordionSummary}>
                                <Typography variant="subtitle2">
                                  {t('personnelInfoCard.accessId.title')}
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Grid container direction="column">
                                  <Grid item>
                                    <Typography className="bold">
                                      {t('personnelInfoCard.copyId.title')}
                                    </Typography>
                                    <Typography
                                      className={classes.instructions}>
                                      {t(
                                        'personnelInfoCard.copyId.instructions'
                                      )}
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <Typography
                                      color="textSecondary"
                                      className={classes.label}>
                                      {t('personnelInfoCard.copyIdField.label')}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    className={classes.serviceIdContainer}>
                                    <Typography variant="subtitle2">
                                      {tradePartnerPersonnel.id}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    className={classes.buttonContainer}>
                                    <Grid
                                      container
                                      direction="row"
                                      justifyContent="flex-end"
                                      spacing={1}>
                                      <Grid item>
                                        <Typography>
                                          <StyledButtonPrimary
                                            label={t(
                                              'personnelInfoCard.findSimilarPersonnelButton.label'
                                            )}
                                            onClick={() =>
                                              setRelatedPersonnelDialogIsOpen(
                                                true
                                              )
                                            }
                                            onKeyDown={() =>
                                              setRelatedPersonnelDialogIsOpen(
                                                true
                                              )
                                            }
                                          />
                                        </Typography>
                                      </Grid>
                                      <Grid item>
                                        <Typography>
                                          <StyledButtonPrimary
                                            label={t(
                                              'personnelInfoCard.copyIdButton.label'
                                            )}
                                            onClick={() =>
                                              copy(
                                                tradePartnerPersonnel.id,
                                                displayToast(
                                                  t(
                                                    'personnelInfoCard.copyId.successToast'
                                                  ),
                                                  'success'
                                                )
                                              )
                                            }
                                          />
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </AccordionDetails>
                            </Accordion>
                          </ClickAwayListener>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item style={{ marginTop: 8 }}>
                      <Grid container justifyContent="flex-end">
                        <Grid item>
                          <StyledButtonMuted
                            startIcon={<EditIcon color="action" />}
                            label={t('personnelInfoCard.editButton.title')}
                            onClick={() => toggleEditing(!isEditing)}
                            disabled={isLoading}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {isEditing && (
                <Grid container direction="column" spacing={1}>
                  <Grid item>
                    <form>
                      <PersonnelFormFields
                        form={form}
                        isLoading={isLoading}
                        tradePartnerPersonnel={tradePartnerPersonnel}
                      />
                    </form>
                  </Grid>
                  <Grid item>
                    <StyledButtonGroup
                      onClickAltAction={handleClose}
                      labelAltAction={t('personnelInfoCard.actions.cancel')}
                      disabledAltAction={isLoading}
                      onClickMainAction={handleSubmit}
                      labelMainAction={t('personnelInfoCard.actions.submit')}
                      disabledMainAction={!canSubmit()}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Card>
      {relatedPersonnelDialogIsOpen && (
        <SimilarPersonnelDialog
          dialogIsOpen={relatedPersonnelDialogIsOpen}
          setDialogIsOpen={setRelatedPersonnelDialogIsOpen}
          personnelId={personnel.id}
        />
      )}
    </>
  );
};

PersonnelInfoCard.propTypes = {
  tradePartnerPersonnel: PropTypes.object.isRequired
};

export default PersonnelInfoCard;
