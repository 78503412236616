import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import { Grid, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import useToast from 'hooks/useToast';
import useConfirmDialog from 'hooks/useConfirmDialog';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import AdUserPermissionCard from 'components/ad/AdUserPermissionCard';
import { RESOLVE_REQUEST_FOR_ACCESS_TO_PROJECT } from 'graphql/project';

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(3),
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(1)
    }
  }
}));

let isResolvingList = [];

const setIsResolvingList = arr => {
  isResolvingList = arr;
};

export const ProjectAdminRequestsList = ({ requests, projectId }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const confirmAction = useConfirmDialog();
  const { displayToast } = useToast();
  const [resolveRequest] = useMutation(RESOLVE_REQUEST_FOR_ACCESS_TO_PROJECT);

  const isResolving = requesterId => {
    return isResolvingList.includes(requesterId);
  };

  const handleDismissRequestClick = requester => {
    confirmAction(
      t('projectAdminRequestsList.dismissRequestDialog.title'),
      t('projectAdminRequestsList.dismissRequestDialog.confirmation', {
        firstName: requester.firstName,
        lastName: requester.lastName
      }),
      t('projectAdminRequestsList.dismissRequestDialog.cancelButton'),
      t('projectAdminRequestsList.dismissRequestDialog.submitButton')
    ).then(
      result => {
        setIsResolvingList([...isResolvingList, requester.id]);
        resolveAccessRequest(requester, false)
          .then(data => {
            displayToast(
              t('projectAdminRequestsList.toasts.deny.success', {
                firstName: requester.firstName,
                lastName: requester.lastName
              }),
              'success'
            );
          })
          .catch(error => {
            console.error(error);
            displayToast(
              t('projectAdminRequestsList.toasts.deny.error'),
              'error'
            );
          })
          .finally(() => {
            setIsResolvingList(
              isResolvingList.filter(id => id !== requester.id)
            );
          });
      },
      () => {
        // cancel was pressed
      }
    );
  };

  function resolveAccessRequest(requester, isApproved) {
    return resolveRequest({
      variables: {
        requesterUpn: requester.upn,
        projectId: projectId,
        isApproved
      }
    });
  }

  const handleApproveRequestClick = requester => {
    confirmAction(
      t('projectAdminRequestsList.approveRequestDialog.title'),
      t('projectAdminRequestsList.approveRequestDialog.confirmation', {
        firstName: requester.firstName,
        lastName: requester.lastName
      }),
      t('projectAdminRequestsList.approveRequestDialog.cancelButton'),
      t('projectAdminRequestsList.approveRequestDialog.submitButton')
    ).then(
      result => {
        setIsResolvingList([...isResolvingList, requester.id]);
        resolveAccessRequest(requester, true)
          .then(data => {
            displayToast(
              t('projectAdminRequestList.toasts.approve.success', {
                firstName: requester.firstName,
                lastName: requester.lastName
              }),
              'success'
            );
          })
          .catch(error => {
            console.error(error);
            displayToast(
              t('projectAdminRequestList.toasts.approve.error'),
              'error'
            );
          })
          .finally(() => {
            setIsResolvingList(
              isResolvingList.filter(id => id !== requester.id)
            );
          });
      },
      () => {
        // cancel was pressed
      }
    );
  };

  return (
    <Grid
      container
      justifyContent="space-between"
      spacing={3}
      className={classes.container}>
      {requests.length < 1 && (
        <Typography color="textPrimary">
          {t('projectAdminRequestsList.noRequests')}
        </Typography>
      )}
      {requests.map(requester => (
        <Grid item xs={12} sm={12} md={12} lg={6} key={requester.id}>
          <AdUserPermissionCard
            user={requester}
            isLoading={isResolving(requester.id)}
            actions={
              <Grid container spacing={1}>
                <Grid item>
                  <StyledButtonPrimary
                    data-testid={`deny-requester-button-${requester.upn}`}
                    label={t('projectAdminRequestsList.dismissButton')}
                    disabled={isResolving(requester.id)}
                    onClick={() => handleDismissRequestClick(requester)}
                  />
                </Grid>
                <Grid item>
                  <StyledButtonPrimary
                    data-testid={`approve-requester-button-${requester.upn}`}
                    label={t('projectAdminRequestsList.approveButton')}
                    disabled={isResolving(requester.id)}
                    onClick={() => handleApproveRequestClick(requester)}
                  />
                </Grid>
              </Grid>
            }
          />
        </Grid>
      ))}
    </Grid>
  );
};

ProjectAdminRequestsList.propTypes = {
  requests: PropTypes.array.isRequired,
  projectId: PropTypes.string.isRequired
};

export default ProjectAdminRequestsList;
