import React, { useState } from 'react';
import PropTypes from 'prop-types';
import LoadingOverlay from 'react-loading-overlay';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import useDebounce from 'hooks/useDebounce';
import StyledTable from 'shared/Table';
import AhaFileDialog from 'components/ahas/AhaFileDialog';
import useAhaProjectTemplateFilesListState from 'store/ahaProjectTemplateFilesListState';
import { formatDate } from 'utils/dateTime';
import { GET_PAGINATED_AHA_FILES_ON_PROJECT_TEMPLATE } from 'graphql/aha/ahaFile';
import AddHenselPhelpsFileDialog from 'components/ahas/AddHenselPhelpsFileDialog';
import StyledButtonMuted from 'shared/Buttons/ButtonMuted';

const useStyles = makeStyles(theme => ({
  actionsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('lg')]: { justifyContent: 'flex-start' }
  },
  rowText: { fontSize: '0.875rem' },
  addButton: {
    '@media (max-width: 960px)': {
      marginBottom: 7
    }
  }
}));

const FilesTabContent = ({ projectTemplateId }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const [fileViewIsOpen, setFileViewIsOpen] = useState(false);
  const [isAddFileDialogOpen, setIsAddFileDialogOpen] = useState(false);
  const [selectedAhaFile, setSelectedAhaFile] = useState(null);
  const [
    ahaFilesOnProjectTemplateListState,
    { handleAhaFilesOnProjectListStateChange }
  ] = useAhaProjectTemplateFilesListState();
  const [initialAhaFilesOnProjectTemplateListState] = useState(
    ahaFilesOnProjectTemplateListState
  );

  const getApiSortOrder = order => {
    let apiSortOrderField = null;
    let direction = order.direction;

    switch (order.name) {
      case 'description':
        apiSortOrderField = 'description';
        break;
      case 'uploaded':
        apiSortOrderField = 'uploaded';
        break;
      case 'uploadedBy.name':
        apiSortOrderField = 'uploadedBy_name';
        break;
      default:
        console.error(`Sort order, "${order.name}", is not being handled!`);
    }
    return apiSortOrderField && direction
      ? [
          {
            [apiSortOrderField]: direction.toUpperCase()
          }
        ]
      : null;
  };

  const { data: paginatedFilesData, loading: isLoading, fetchMore } = useQuery(
    GET_PAGINATED_AHA_FILES_ON_PROJECT_TEMPLATE,
    {
      skip: !projectTemplateId,
      variables: {
        ahaProjectTemplateId: projectTemplateId,
        first: initialAhaFilesOnProjectTemplateListState.first,
        order: getApiSortOrder(initialAhaFilesOnProjectTemplateListState.order),
        search: initialAhaFilesOnProjectTemplateListState.search,
        skip: 0
      },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true
    }
  );

  const ahaFiles =
    paginatedFilesData?.paginatedAhaFilesOnProjectTemplate?.ahaFiles;

  const loadMore = variables => {
    return fetchMore({
      variables,
      updateQuery: (_previousResult, { fetchMoreResult, _queryVariables }) => {
        return fetchMoreResult;
      }
    });
  };

  const handlePageChange = tableState => {
    const { rowsPerPage, page } = tableState;

    let skip =
      page < ahaFilesOnProjectTemplateListState.page
        ? ahaFilesOnProjectTemplateListState.skip - rowsPerPage
        : ahaFilesOnProjectTemplateListState.skip + rowsPerPage;

    handleAhaFilesOnProjectListStateChange({
      ...ahaFilesOnProjectTemplateListState,
      page,
      skip
    });

    loadMore({
      search: ahaFilesOnProjectTemplateListState.search,
      skip,
      order: getApiSortOrder(ahaFilesOnProjectTemplateListState.order)
    });
  };

  const handleSortChange = tableState => {
    const { sortOrder } = tableState;

    handleAhaFilesOnProjectListStateChange({
      ...ahaFilesOnProjectTemplateListState,
      order: sortOrder,
      page: 0,
      skip: 0
    });

    loadMore({
      search: ahaFilesOnProjectTemplateListState.search,
      skip: 0,
      order: getApiSortOrder(sortOrder)
    });
  };

  const handleSearchChange = ({ tableState, debouncedHandleSearchChange }) => {
    const { searchText } = tableState;

    handleAhaFilesOnProjectListStateChange({
      ...ahaFilesOnProjectTemplateListState,
      skip: 0,
      page: 0,
      search: searchText
    });

    loadMore({
      search: searchText,
      skip: 0,
      order: getApiSortOrder(ahaFilesOnProjectTemplateListState.order)
    });
  };

  const { debounced: debouncedHandleSearchChange } = useDebounce(
    handleSearchChange
  );

  const openFileView = rowData => {
    const ahaFile = ahaFiles?.find(ahaFile => ahaFile.id === rowData[0]);
    setSelectedAhaFile(ahaFile);
    setFileViewIsOpen(true);
  };

  const columns = [
    {
      name: 'id',
      label: 'Id',
      options: {
        filter: false,
        display: 'excluded'
      }
    },
    {
      name: 'description',
      label: 'Description',
      options: {
        filter: false,
        searchable: true,
        sort: true
      }
    },
    {
      name: 'uploadedBy.name',
      label: 'Uploaded By',
      options: {
        filter: false,
        searchable: true,
        sort: true
      }
    },
    {
      name: 'uploaded',
      label: 'Date',
      options: {
        filter: false,
        searchable: false,
        sort: true,
        // eslint-disable-next-line react/display-name
        customBodyRender: value => (
          <Typography className={classes.rowText}>
            {formatDate(value)}
          </Typography>
        )
      }
    }
  ];

  const refetchQuery = () => {
    return loadMore({
      first: ahaFilesOnProjectTemplateListState.first,
      skip: ahaFilesOnProjectTemplateListState.skip,
      filter: ahaFilesOnProjectTemplateListState.filter,
      search: ahaFilesOnProjectTemplateListState.search,
      order: getApiSortOrder(ahaFilesOnProjectTemplateListState.order)
    });
  };

  const options = {
    filter: false,
    download: false,
    count:
      paginatedFilesData?.paginatedAhaFilesOnProjectTemplate?.total ?? null,
    rowsPerPage: ahaFilesOnProjectTemplateListState.first,
    searchText: ahaFilesOnProjectTemplateListState.search,
    searchPlaceholder: t('filesTabContent.searchPlaceholder'),
    rowsPerPageOptions: [],
    page: ahaFilesOnProjectTemplateListState.page,
    sortOrder: ahaFilesOnProjectTemplateListState.order,
    serverSide: true,
    onRowClick: rowData => openFileView(rowData),
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          handlePageChange(tableState);
          break;
        case 'sort':
          handleSortChange(tableState);
          break;
        case 'search':
        case 'onSearchClose':
          debouncedHandleSearchChange({
            tableState,
            ahaFilesOnProjectTemplateListState
          });
          break;
        default:
      }
    },
    // eslint-disable-next-line react/display-name
    customToolbar: () => {
      return (
        <StyledButtonMuted
          className={classes.addButton}
          startIcon={<AddIcon color="action" />}
          label={t('filesTabContent.addButtonLabel')}
          onClick={() => setIsAddFileDialogOpen(true)}
        />
      );
    }
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <LoadingOverlay
            active={isLoading}
            spinner
            fadeSpeed={50}
            styles={{
              overlay: base => ({
                ...base,
                background: theme.palette.background.overlay
              }),
              spinner: base => ({
                ...base,
                width: '50px',
                '& svg circle': {
                  stroke: theme.palette.primary.main
                }
              })
            }}>
            <StyledTable
              title={t('filesTabContent.title')}
              data={ahaFiles ?? []}
              columns={columns}
              options={options}
            />
          </LoadingOverlay>
        </Grid>
      </Grid>
      <AhaFileDialog
        isOpen={fileViewIsOpen}
        setIsOpen={setFileViewIsOpen}
        ahaFile={selectedAhaFile}
        refetchQuery={refetchQuery}
      />
      <AddHenselPhelpsFileDialog
        isAddFileDialogOpen={isAddFileDialogOpen}
        setIsAddFileDialogOpen={setIsAddFileDialogOpen}
        projectTemplateId={projectTemplateId}
        refetchQuery={refetchQuery}
      />
    </>
  );
};

FilesTabContent.propTypes = {
  files: PropTypes.any,
  projectTemplateId: PropTypes.string
};

export default FilesTabContent;
