import { createConfirmation } from 'react-confirm';
import ConfirmDialog from '../../components/common/ConfirmDialog';
import { useTheme } from '@mui/material';

const confirmDialog = createConfirmation(ConfirmDialog);

const useConfirmDialog = () => {
  const theme = useTheme();

  const confirm = (title, message, rejectLabel, confirmLabel) => {
    const options = { title, theme, rejectLabel, confirmLabel };
    return confirmDialog({ confirmation: message, options });
  };
  return confirm;
};

export default useConfirmDialog;
