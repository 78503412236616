import React from 'react';
import { IconButton } from '@mui/material';
import PropTypes from 'prop-types';

const StyledIconButton = ({ onClick, className, children, ...rest }) => {
  return (
    <IconButton onClick={onClick} className={className} {...rest} size="large">
      {children}
    </IconButton>
  );
};

StyledIconButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node.isRequired
};

export default StyledIconButton;
