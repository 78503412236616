import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography } from '@mui/material';

import useAhaReview from 'hooks/useAhaReview';

const useStyles = makeStyles(theme => ({
  border: { border: '1px solid black' },
  hrDivider: { padding: 0, margin: 0, width: '100%' },
  mediumFontSize: { fontSize: '0.75rem' },
  centeredText: { textAlign: 'center' },
  borderRightAndLeft: {
    borderRight: '1px solid black',
    borderLeft: '1px solid black'
  },
  rightBorder: { boxSize: 'border-box', borderRight: '1px solid black' },
  leftBorder: {
    boxSize: 'border-box',
    borderLeft: '1px solid black'
  },
  topBorder: { borderTop: '1px solid black', width: '100%' },
  fortyPixelHeight: { minHeight: 40, height: 'auto' }
}));

const CompetentPersonSignatureSection = ({ ahaTemplate }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { ahaReviewId } = useParams();
  const { ahaReview } = useAhaReview(ahaReviewId);

  const activitiesList = ahaReview
    ? ahaReview?.ahaTemplate?.ahaActivitys
    : ahaTemplate?.ahaActivitys ?? [];

  const getEmptyRowCount = () => {
    let rowCount;
    const maxPageRowCount = 12;
    const initializedRowCount = activitiesList?.length ?? 0;
    if (initializedRowCount > 12) {
      rowCount = 0;
    } else {
      rowCount = maxPageRowCount - initializedRowCount;
    }

    return rowCount;
  };

  const emptyRowsToAppend = getEmptyRowCount();

  return (
    <div>
      <div className="page-break-auto">
        <Grid item xs={12}>
          <Grid container className={classes.border}>
            <Grid item xs={12}>
              <Typography
                className={classnames(
                  'bold',
                  classes.centeredText,
                  classes.mediumFontSize
                )}>
                {t('competentPersonSignatureSection.signatureTable.title')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <hr className={classes.hrDivider} />
      </div>
      {activitiesList.map((activity, index) => (
        <div key={index} className="page-break-auto">
          <hr className={classes.hrDivider} />
          <Grid container>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography
                    className={classnames(
                      classes.mediumFontSize,
                      classes.padding,
                      classes.leftBorder
                    )}>
                    {activity.name}
                  </Typography>
                </Grid>
                <Grid item xs={6} className={classes.borderRightAndLeft}></Grid>
              </Grid>
            </Grid>
          </Grid>
          <hr className={classes.hrDivider} />
        </div>
      ))}
      {[...Array(emptyRowsToAppend)].map((e, i) => {
        return (
          <div key={i} className="page-break-auto">
            <hr className={classes.hrDivider} />
            <Grid container>
              <Grid item xs={12}>
                <Grid container>
                  <Grid
                    item
                    xs={6}
                    className={classnames(classes.leftBorder)}></Grid>
                  <Grid
                    item
                    xs={6}
                    className={classnames(classes.borderRightAndLeft)}>
                    <br />
                    <br />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <hr className={classes.hrDivider} />
          </div>
        );
      })}
    </div>
  );
};

CompetentPersonSignatureSection.propTypes = {
  ahaTemplate: PropTypes.object
};

export default CompetentPersonSignatureSection;
