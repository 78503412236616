import React, { useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import makeStyles from '@mui/styles/makeStyles';
import { IconButton, Menu, MenuItem, Typography } from '@mui/material';

import useHelpAndSupport from 'hooks/useHelpAndSupport';
import useSoteriaStore from 'hooks/useSoteriaStore';
import WhatsNewDrawer from 'components/appbar/WhatsNew/WhatsNewDrawer';
import TutorialDialog from 'components/tutorials/TutorialDialog';
import { SERVICENOW_URL } from 'constants/externalLinks';
import VersionDialog from '../VersionDialog';
import { domain, Domain } from 'config/domain';

const useStyles = makeStyles(theme => ({
  icon: { color: '#fff' },
  menu: { zIndex: '2000 !important' },
  menuListItem: { width: theme.spacing(25) },
  menuItemIcon: { fontSize: '1rem' },
  iconButtonForMobile: { padding: 0 }
}));

const CollapsedUserMenu = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const { toggleHelp } = useHelpAndSupport();
  const {
    store: { whatsNewIsOpen = false },
    setStore
  } = useSoteriaStore();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTutorialsClick = () => {
    toggleHelp(true);
    handleClose();
  };

  const handleWhatsNewClick = () => {
    setStore({ whatsNewIsOpen: !whatsNewIsOpen });
    handleClose();
  };

  const handleCheckForUpdatesClick = () => {
    setIsOpen(true);
    handleClose();
  };

  const menuItems = [
    <MenuItem key="checkForVersionUpdates" onClick={handleCheckForUpdatesClick}>
      <Typography>{t('helpMenu.checkForVersionUpdates')}</Typography>
    </MenuItem>
  ];
  if (domain === Domain.PRIMARY) {
    menuItems.push(
      <MenuItem
        key="support"
        className={classes.menuListItem}
        onClick={() => {
          window.open(SERVICENOW_URL, '_blank', 'noopener');
          handleClose();
        }}>
        <Typography>{t('helpMenu.support')}</Typography>
      </MenuItem>,
      <MenuItem
        key="reportBug"
        className={classes.menuListItem}
        onClick={() => {
          handleClose();
          window.open(SERVICENOW_URL, '_blank', 'noopener');
        }}>
        <Typography>{t('helpMenu.reportBug')}</Typography>
      </MenuItem>,
      <MenuItem
        key="suggestion"
        className={classes.menuListItem}
        onClick={() => {
          window.open(SERVICENOW_URL, '_blank', 'noopener');
          handleClose();
        }}>
        <Typography>{t('helpMenu.suggestion')}</Typography>
      </MenuItem>,
      <MenuItem key="tutorials" onClick={handleTutorialsClick}>
        <Typography>{t('helpMenu.tutorials')}</Typography>
      </MenuItem>,
      <MenuItem
        key="whatsNew"
        onClick={handleWhatsNewClick}
        data-testid="whatsNewButton">
        <Typography>{t('helpMenu.whatsNew')}</Typography>
      </MenuItem>
    );
  }

  return (
    <Fragment>
      <IconButton
        className={classes.iconButtonForMobile}
        onClick={handleClick}
        data-testid="collapsedMenuButton"
        size="large">
        <MoreVertIcon className={classes.icon} />
      </IconButton>
      <Menu
        MenuListProps={{ 'data-testid': 'collapsedMenu' }}
        className={classes.menu}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        {menuItems}
      </Menu>
      {domain === Domain.PRIMARY && (
        <Fragment>
          <WhatsNewDrawer />
          <TutorialDialog />
        </Fragment>
      )}
      <VersionDialog isOpen={isOpen} setIsOpen={setIsOpen} />
    </Fragment>
  );
};

export default CollapsedUserMenu;
