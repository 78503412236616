import React, { useState } from 'react';
import PropTypes from 'prop-types';
import LoadingOverlay from 'react-loading-overlay';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@apollo/react-hooks';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { useTheme } from '@mui/material/styles';
import {
  Grid,
  Typography,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  InputAdornment,
  Button
} from '@mui/material';

import useSoteriaQuery from 'hooks/useSoteriaQuery';
import useToast from 'hooks/useToast';
import useRoles from 'hooks/useRoles';
import useDebounce from 'hooks/useDebounce';
import StyledDeleteConfirmation from 'shared/DeleteConfirmation';
import UpsertStep from 'components/ahas/UpsertStep';
import CategoryStepList from './CategoryStepList';
import EditableGlobalStepCategory from './EditableGlobalStepCategory';
import useGlobalStepTabContentStyles from './useGlobalStepTabContentStyles';
import useAhaGlobalStepListState from 'store/ahaGlobalStepListState';
import {
  GET_ALL_GLOBAL_STEP_CATEGORIES,
  DELETE_GLOBAL_AHA_STEP_CATEGORY,
  GLOBAL_STEP_SEARCH
} from 'graphql/aha';

const GlobalStepsTabContent = ({
  isEditing,
  setIsEditing,
  categoryIdsWithUnsavedChanges,
  setCategoryIdsWithUnsavedChanges,
  discardWasClicked,
  setDiscardWasClicked,
  setUnsavedChangesDialogIsOpen,
  handleAddGlobalStepToTemplate
}) => {
  const styles = useGlobalStepTabContentStyles();
  const classes = styles();
  const theme = useTheme();
  const { t } = useTranslation();
  const { isAhaAdmin } = useRoles();
  const { displayToast } = useToast();
  const [expandedCategories, setExpandedCategories] = useState([]);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(
    false
  );
  const [isDeleteConfirmed, toggleIsDeleteConfirmed] = useState(false);
  const [isAddGlobalStepDialogOpen, setIsAddGlobalStepDialogOpen] = useState(
    false
  );
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categoriesBeingEdited, setCategoriesBeingEdited] = useState([]);

  const [
    ahaGlobalStepListState,
    { handleAhaGlobalStepListStateChange }
  ] = useAhaGlobalStepListState();
  const [search, setSearch] = useState(ahaGlobalStepListState.search);

  const { data, loading: isGetAllLoading } = useSoteriaQuery({
    gql: GET_ALL_GLOBAL_STEP_CATEGORIES
  });

  const { data: searchData, loading: isSearchLoading } = useQuery(
    GLOBAL_STEP_SEARCH,
    {
      skip: !ahaGlobalStepListState?.search,
      variables: {
        search: ahaGlobalStepListState?.search
      },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onCompleted: data => {
        const categories =
          data?.globalAhaStepsAsAhaGlobalStepCategorySubsets ?? [];
        setExpandedCategories(categories.map(category => category.id));
      }
    }
  );

  const globalStepCategories = ahaGlobalStepListState.search
    ? searchData?.globalAhaStepsAsAhaGlobalStepCategorySubsets ?? []
    : data?.ahaGlobalStepCategories ?? [];

  const [deleteGlobalAhaCategory, { loading: isDeleting }] = useMutation(
    DELETE_GLOBAL_AHA_STEP_CATEGORY,
    {
      refetchQueries: [{ query: GET_ALL_GLOBAL_STEP_CATEGORIES }],
      awaitRefetchQueries: true
    }
  );

  const isLoading = isGetAllLoading || isSearchLoading || isDeleting;

  const handleDebouncedSearchChange = ({ search, state }) => {
    handleAhaGlobalStepListStateChange({
      ...state,
      search
    });
  };

  const { debounced: debouncedHandleSearchChange } = useDebounce(
    handleDebouncedSearchChange
  );

  const handleSearchChange = event => {
    const search = event ? event.target.value : '';

    setSearch(search);
    debouncedHandleSearchChange({
      search,
      state: ahaGlobalStepListState
    });
  };

  const handleAccordionClick = category => {
    if (!expandedCategories.includes(category.id)) {
      setExpandedCategories([...expandedCategories, category.id]);
    } else {
      setExpandedCategories(
        expandedCategories.filter(item => item !== category.id)
      );
    }
  };

  const handleChange = event => {
    toggleIsDeleteConfirmed(event.target.checked);
  };

  const handleDeleteSubmit = () => {
    deleteGlobalAhaCategory({ variables: { id: selectedCategory.id } })
      .then(() => {
        displayToast(
          t('globalStepLibrary.toasts.delete.success', {
            name: selectedCategory.name
          }),
          'success'
        );
        handleClose();
      })
      .catch(error => {
        console.error('Delete Global Step Category Error: ', error);
        displayToast(t('globalStepLibrary.toasts.delete.error'), 'error');
      });
  };

  const handleClose = () => {
    setSelectedCategory(null);
    setIsDeleteConfirmationOpen(false);
    toggleIsDeleteConfirmed(false);
  };

  const handleAddGlobalJobStepButtonClick = () => {
    setIsAddGlobalStepDialogOpen(!isAddGlobalStepDialogOpen);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <TextField
            className={classes.searchInput}
            value={search}
            onChange={handleSearchChange}
            color="secondary"
            placeholder={t('globalStepLibrary.search')}
            margin="dense"
            variant="standard"
            inputProps={{
              'aria-label': t('globalStepLibrary.searchFieldLabel'),
              'aria-required': false
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    className={classes.icon}
                    aria-label={t('globalStepLibrary.searchFieldLabel')}
                  />
                </InputAdornment>
              ),
              endAdornment: search ? (
                <InputAdornment position="end">
                  <IconButton
                    aria-label={t('AHADashboardPage.clearSearch')}
                    size="small"
                    onClick={() => handleSearchChange('')}
                    className={classes.icon}>
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ) : null
            }}
            fullWidth={true}
          />
        </Grid>
        <Grid item xs={12} className={classes.cardsContainer}>
          <LoadingOverlay
            active={isLoading}
            spinner
            fadeSpeed={50}
            styles={{
              overlay: base => ({
                ...base,
                background: theme.palette.background.overlay
              }),
              spinner: base => ({
                ...base,
                width: '50px',
                '& svg circle': {
                  stroke: theme.palette.primary.main
                }
              })
            }}>
            {globalStepCategories.map(category => {
              return (
                <Accordion
                  key={category.id}
                  classes={{
                    root: classes.accordionRoot,
                    expanded: classes.accordionExpanded
                  }}
                  expanded={expandedCategories.includes(category.id)}>
                  <AccordionSummary
                    classes={{
                      root: categoriesBeingEdited.some(
                        categoryBeingEdited =>
                          categoryBeingEdited.id === category.id
                      )
                        ? classes.accordionSummaryRootEditing
                        : classes.accordionSummaryRoot,
                      expanded: classes.expanded,
                      content:
                        isEditing && expandedCategories.includes(category.id)
                          ? classes.accordionSummaryContentExpanded
                          : classes.accordionSummaryContent
                    }}>
                    <EditableGlobalStepCategory
                      category={category}
                      isGlobalEditing={isEditing}
                      isExpanded={expandedCategories.includes(category.id)}
                      setIsExpanded={() => {
                        handleAccordionClick(category);
                      }}
                      onDeleteClick={() => {
                        setSelectedCategory(category);
                        setIsDeleteConfirmationOpen(true);
                      }}
                      categoriesBeingEdited={categoriesBeingEdited}
                      setCategoriesBeingEdited={setCategoriesBeingEdited}
                      categoryIdsWithUnsavedChanges={
                        categoryIdsWithUnsavedChanges
                      }
                      setCategoryIdsWithUnsavedChanges={
                        setCategoryIdsWithUnsavedChanges
                      }
                      discardWasClicked={discardWasClicked}
                      setDiscardWasClicked={setDiscardWasClicked}
                      setUnsavedChangesDialogIsOpen={
                        setUnsavedChangesDialogIsOpen
                      }
                      setIsEditing={setIsEditing}
                    />
                  </AccordionSummary>
                  <AccordionDetails
                    classes={{
                      root: classes.accordionDetailsRoot
                    }}>
                    {expandedCategories.includes(category.id) && (
                      <CategoryStepList
                        category={category}
                        isGlobalEditing={isEditing}
                        categorySearchAhaSteps={
                          ahaGlobalStepListState?.search
                            ? category.ahaSteps
                            : null
                        }
                        handleAddGlobalStepToTemplate={
                          handleAddGlobalStepToTemplate
                        }
                      />
                    )}
                  </AccordionDetails>
                </Accordion>
              );
            })}
            <Typography className={classes.endOfListMessage}>
              {t('globalStepLibrary.stepsTab.endOfListMessage')}
            </Typography>
          </LoadingOverlay>
        </Grid>
        {isAhaAdmin && (
          <Grid item className={classes.addButtonContainer}>
            <Button
              className={classes.addButton}
              size="small"
              variant="contained"
              color="secondary"
              startIcon={<AddIcon />}
              onClick={handleAddGlobalJobStepButtonClick}>
              <Typography className={classes.addButtonLabel}>
                {t('globalStepLibrary.actions.addLibraryStepButton')}
              </Typography>
            </Button>
          </Grid>
        )}
      </Grid>
      <UpsertStep
        isOpen={isAddGlobalStepDialogOpen}
        setIsOpen={setIsAddGlobalStepDialogOpen}
        isGlobal={true}
      />
      <StyledDeleteConfirmation
        title={t('globalStepLibrary.deleteWarningTitle')}
        dialogIsOpen={isDeleteConfirmationOpen}
        isLoading={isDeleting}
        handleClose={handleClose}
        warningMessage={t('globalStepLibrary.deleteWarning', {
          categoryName: selectedCategory?.name
        })}
        isConfirmed={isDeleteConfirmed}
        handleChange={handleChange}
        handleSubmit={handleDeleteSubmit}
      />
    </>
  );
};

GlobalStepsTabContent.propTypes = {
  isEditing: PropTypes.bool,
  setIsEditing: PropTypes.func,
  categoryIdsWithUnsavedChanges: PropTypes.array,
  setCategoryIdsWithUnsavedChanges: PropTypes.func,
  discardWasClicked: PropTypes.bool,
  setDiscardWasClicked: PropTypes.func,
  setUnsavedChangesDialogIsOpen: PropTypes.func,
  handleAddGlobalStepToTemplate: PropTypes.func
};

export default GlobalStepsTabContent;
