import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useField, useForm } from 'react-final-form-hooks';
import makeStyles from '@mui/styles/makeStyles';
import { Grid } from '@mui/material';

import StyledDialog from 'shared/Dialog';
import StyledInput from 'shared/Input';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import StyledButtonSecondary from 'shared/Buttons/ButtonSecondary';
import useFormValidation from 'hooks/useFormValidation';

const useStyles = makeStyles(theme => ({
  fieldContainer: { marginTop: theme.spacing(1) }
}));

const EditAhaTemplateInformationDialog = ({
  ahaTemplate,
  setAhaTemplate,
  isOpen,
  setIsOpen
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isRequired } = useFormValidation();

  const { form, values, pristine } = useForm({
    /* istanbul ignore next */
    onSubmit: () => {},
    initialValues: {
      type: ahaTemplate?.type,
      notes: ahaTemplate?.ahaProjectTemplate?.notes ?? ''
    }
  });

  const typeField = useField('type', form, isRequired);
  const notesField = useField('notes', form);

  const canSubmit = () => {
    const { error, invalid } = form.getState();
    return !(invalid || error || pristine);
  };

  const handleSubmit = () => {
    setAhaTemplate({
      ...ahaTemplate,
      type: values.type,
      ...(ahaTemplate?.ahaProjectTemplate
        ? {
            ahaProjectTemplate: {
              ...ahaTemplate.ahaProjectTemplate,
              notes: values.notes
            }
          }
        : {})
    });

    handleClose();
  };

  const handleClose = () => {
    form.reset();
    setIsOpen(false);
  };

  return (
    <StyledDialog
      maxWidth="sm"
      isOpen={isOpen}
      handleClose={() => setIsOpen(false)}
      title={'Edit Template Info'}
      content={
        <Grid container>
          <Grid item xs={12} className={classes.fieldContainer}>
            <StyledInput
              label={'Activity/Work Task'}
              input={typeField.input}
              meta={typeField.meta}
              autoFocus={true}
              required
            />
          </Grid>
          {ahaTemplate?.ahaProjectTemplate && (
            <Grid item xs={12} className={classes.fieldContainer}>
              <StyledInput
                label={'Notes'}
                input={notesField.input}
                meta={notesField.meta}
                multiline={true}
                rows={5}
              />
            </Grid>
          )}
        </Grid>
      }
      actions={
        <Grid container justifyContent="space-between">
          <Grid item>
            <StyledButtonSecondary
              label={t('editAhaTemplateInformationDialog.actions.cancelButton')}
              onClick={handleClose}
            />
          </Grid>
          <Grid item>
            <StyledButtonPrimary
              label={t('editAhaTemplateInformationDialog.actions.closeButton')}
              disabled={!canSubmit()}
              onClick={handleSubmit}
            />
          </Grid>
        </Grid>
      }
    />
  );
};

EditAhaTemplateInformationDialog.propTypes = {
  ahaTemplate: PropTypes.object,
  setAhaTemplate: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  isGlobal: PropTypes.bool
};

export default EditAhaTemplateInformationDialog;
