import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography } from '@mui/material';

import StyledDialog from 'shared/Dialog';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import StyledButtonSecondary from 'shared/Buttons/ButtonSecondary';
import AhaPrintableDocument from 'components/ahas/AhaPrintableDocument';

const useStyles = makeStyles(theme => ({
  title: {
    textAlign: 'center',
    fontSize: '1.5rem'
  },
  subTitle: {
    textAlign: 'center',
    fontSize: '1.25rem',
    marginBottom: theme.spacing(2)
  },
  message: { textAlign: 'center' },
  img: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: 250,
    marginBottom: theme.spacing(2)
  }
}));

const AhaPrintDisclaimerDialog = ({
  isOpen,
  setIsOpen,
  ahaTemplate,
  isGlobal
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const componentRef = useRef();

  const handleClose = () => {
    setIsOpen(false);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  const handleSubmitPrintWithSafari = () => {
    handlePrint();
    handleClose();
  };

  return <>
    <StyledDialog
      maxWidth="sm"
      isOpen={isOpen}
      handleClose={handleClose}
      title={t('AhaPrintDisclaimerDialog.title')}
      content={
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          alignContent="center">
          <Grid item>
            <img
              src={theme.safariPrintDisclaimer}
              alt={t('AhaPrintDisclaimerDialog.imageAlt')}
              className={classes.img}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.title}>
              {t('AhaPrintDisclaimerDialog.mainTitle')}
            </Typography>
            <Typography className={classes.subTitle} gutterBottom>
              {t('AhaPrintDisclaimerDialog.subTitle')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.message}>
              {t('AhaPrintDisclaimerDialog.message')}
            </Typography>
          </Grid>
        </Grid>
      }
      actions={
        <Grid container justifyContent="space-between">
          <Grid item>
            <StyledButtonSecondary
              label={t('AhaPrintDisclaimerDialog.actions.cancelButton')}
              onClick={handleClose}
            />
          </Grid>
          <Grid item>
            <StyledButtonPrimary
              label={t(
                'AhaPrintDisclaimerDialog.actions.printWithSafariButton'
              )}
              onClick={handleSubmitPrintWithSafari}
            />
          </Grid>
        </Grid>
      }
    />
    <div style={{ display: 'none' }}>
      <AhaPrintableDocument
        ref={componentRef}
        ahaTemplate={ahaTemplate}
        isGlobal={isGlobal}
      />
    </div>
  </>;
};

AhaPrintDisclaimerDialog.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  ahaTemplate: PropTypes.object,
  isGlobal: PropTypes.bool
};

export default AhaPrintDisclaimerDialog;
