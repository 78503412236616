import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography } from '@mui/material';

import useAhaReview from 'hooks/useAhaReview';
import { formatDate } from 'utils/dateTime';

const useStyles = makeStyles(theme => ({
  projectInformationItem: {
    padding: theme.spacing(0, 1),
    fontSize: '0.75rem'
  },
  notesContainer: { height: 132 },
  divider: { padding: 0, margin: 0, width: '100%' },
  rejectedReviewWatermark: {
    position: 'absolute',
    fontSize: theme.spacing(15),
    bottom: 280,
    left: 280,
    fontWeight: 'bold',
    color: 'rgba(0,0,0,.2)',
    transform: 'rotate(-45deg)'
  }
}));

const ProjectInformationSection = ({ ahaTemplate }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { ahaReviewId } = useParams();
  const { ahaReview } = useAhaReview(ahaReviewId);

  const isRejectedReview = ahaReview?.rejecters.length > 0;

  const activityOrWorkTask = ahaReview
    ? ahaReview?.ahaProjectTemplate?.ahaTemplate?.type.toUpperCase()
    : ahaTemplate?.type.toUpperCase();

  return (
    <div>
      {isRejectedReview && (
        <Typography
          className={classnames({
            [`${classes.rejectedReviewWatermark}`]: isRejectedReview
          })}>
          Rejected
        </Typography>
      )}
      <Grid container>
        <Grid item xs={12}>
          <Typography className={classes.projectInformationItem}>
            {t('projectInformationSection.label.activityOrWorkTask')}
            {activityOrWorkTask}
          </Typography>
        </Grid>
        <hr className={classes.divider} />
        <Grid item xs={12}>
          <Typography className={classes.projectInformationItem}>
            {t('projectInformationSection.label.projectLocation')}
            {ahaReview
              ? ahaReview.project?.name
              : ahaTemplate?.ahaProjectTemplate?.location}
          </Typography>
        </Grid>
        <hr className={classes.divider} />
        <Grid item xs={12}>
          <Typography className={classes.projectInformationItem}>
            {t('projectInformationSection.label.contractor')}
            {ahaReview
              ? ahaReview.ahaProjectTemplate?.contractor
              : ahaTemplate?.ahaProjectTemplate?.contractor}
          </Typography>
        </Grid>
        <hr className={classes.divider} />
        <Grid item xs={12}>
          <Typography className={classes.projectInformationItem}>
            {t('projectInformationSection.label.datePrepared')}
            {formatDate(ahaReview ? ahaReview.created : ahaTemplate?.created)}
          </Typography>
        </Grid>
        <hr className={classes.divider} />
        <Grid item xs={12}>
          <Typography className={classes.projectInformationItem}>
            {t('projectInformationSection.label.preparedBy')}
            {ahaReview
              ? ahaReview.preparedBy?.name
              : ahaTemplate?.ahaProjectTemplate?.ownedBy?.name}
          </Typography>
        </Grid>
        <hr className={classes.divider} />
        <Grid item xs={12}>
          <Typography className={classes.projectInformationItem}>
            {t('projectInformationSection.label.reviewedBy')}
            {ahaReview?.approvers?.map(approver => approver.name).join(', ')}
          </Typography>
        </Grid>
        <hr className={classes.divider} />
        <Grid
          item
          xs={12}
          className={classnames('firefoxNotesHeight', classes.notesContainer)}>
          <Typography className={classes.projectInformationItem}>
            {t('projectInformationSection.label.notes')}
            {ahaReview
              ? ahaReview.ahaProjectTemplate?.notes
              : ahaTemplate?.ahaProjectTemplate?.notes}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

ProjectInformationSection.propTypes = {
  ahaTemplate: PropTypes.object
};

export default ProjectInformationSection;
