import { atom, useRecoilState } from 'recoil';

const defaultState = {
  search: ''
};

const addAhaStateAtom = atom({
  key: 'addAhaState',
  default: defaultState
});

function useAddAhaState() {
  const [addAhaState, setAddAhaState] = useRecoilState(addAhaStateAtom);

  function handleAddAhaStateChange(newState) {
    setAddAhaState({
      ...addAhaState,
      ...newState
    });
  }
  return [addAhaState, { handleAddAhaStateChange }];
}

export default useAddAhaState;
