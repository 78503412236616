import gql from 'graphql-tag';

// Subscriptions
export const PROJECT_WORKER_HOURS_SUBSCRIPTION = gql`
  subscription projectWorkerHours($projectId: ID!) {
    projectWorkerHours(projectId: $projectId) {
      id
      hours
      month
      year
      tradePartner {
        id
        name
      }
    }
  }
`;

// Queries
export const GET_TRADE_PARTNER_MONTH_WORKS = gql`
  query GetTradePartnerMonthWorks(
    $projectId: ID!
    $year: Int!
    $month: Month!
  ) {
    monthlyWorkerHours(projectId: $projectId, year: $year, month: $month) {
      id
      hours
      month
      year
      tradePartner {
        id
        name
      }
    }
  }
`;
