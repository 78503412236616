import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import EditIcon from '@mui/icons-material/Edit';
import makeStyles from '@mui/styles/makeStyles';
import {
  Grid,
  Card,
  Typography,
  Checkbox,
  FormControl,
  FormGroup,
  FormControlLabel,
  LinearProgress
} from '@mui/material';

import useToast from 'hooks/useToast';
import StyledButtonMuted from 'shared/Buttons/ButtonMuted';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import StyledButtonSecondary from 'shared/Buttons/ButtonSecondary';
import { UPDATE_PROJECT } from 'graphql/project';
import { STEP_REFERENCE_OPTIONS } from 'constants/ahas';

const useStyles = makeStyles(theme => ({
  card: { padding: theme.spacing(3) }
}));

const AhaOptionsCard = ({ project, isLoading }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { displayToast } = useToast();
  const [isEditing, toggleEditing] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(
    project?.availableAhaReferenceOptions?.length
      ? [...project.availableAhaReferenceOptions]
      : []
  );

  const getSelectedOptionsDisplay = () => {
    const displayOptions = [];

    STEP_REFERENCE_OPTIONS.forEach(stepReferenceOption => {
      if (selectedOptions.includes(stepReferenceOption.value)) {
        displayOptions.push(stepReferenceOption.label);
      }
    });

    return displayOptions.join(', ');
  };

  const [updateProject, { loading: isUpdateLoading }] = useMutation(
    UPDATE_PROJECT
  );

  const handleToggleOption = option => {
    if (selectedOptions.includes(option.value)) {
      setSelectedOptions(
        selectedOptions.filter(
          selectedOption => selectedOption !== option.value
        )
      );
    } else {
      setSelectedOptions([...selectedOptions, option.value]);
    }
  };

  const canSubmit = () => {
    const originalArray = project.availableAhaReferenceOptions?.length
      ? [...project.availableAhaReferenceOptions]
      : [];
    return JSON.stringify(originalArray) !== JSON.stringify(selectedOptions);
  };

  const handleSubmit = () => {
    updateProject({
      variables: {
        id: project?.id,
        availableAhaReferenceOptions: selectedOptions
      }
    })
      .then(({ data: { updateProject: updatedProject } }) => {
        displayToast(
          t('projectDetailsPage.ahaOptionsCard.toasts.update.success'),
          'success'
        );
        handleClose();
      })
      .catch(error => {
        console.error('Available Project AHA Options Error: ', error);
        displayToast(
          t('projectDetailsPage.ahaOptionsCard.toasts.update.error'),
          'error'
        );
      });
  };

  const handleClose = () => {
    setSelectedOptions(selectedOptions);
    toggleEditing(false);
  };

  return <>
    <Grid container direction="column" spacing={3}>
      <Grid item xs={12}>
        {isUpdateLoading && <LinearProgress color="primary" />}
        <Card className={classes.card}>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography className="bold" component="h2">
                    {t('projectDetailsPage.ahaOptionsCard.title')}
                  </Typography>
                </Grid>
                <Grid item>
                  {isEditing && (
                    <Typography color="primary">
                      {t('projectDetailsPage.ahaOptionsCard.editing')}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
            {!isEditing && (
              <>
                <Grid item>
                  <Typography component="span">
                    {getSelectedOptionsDisplay()}
                  </Typography>
                </Grid>
                <Grid item style={{ marginTop: 8 }}>
                  <Grid container justifyContent="flex-end" alignItems="flex-end">
                    <Grid item>
                      <StyledButtonMuted
                        startIcon={<EditIcon color="action" />}
                        label={t(
                          'projectDetailsPage.ahaOptionsCard.actions.editButton'
                        )}
                        onClick={() => toggleEditing(true)}
                        disabled={isLoading}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
            {isEditing && (
              <>
                <Grid item>
                  <FormControl variant="standard" component="fieldset" data-testid="checklist-component">
                    {STEP_REFERENCE_OPTIONS.map((option, index) => {
                      const labelId = `checkbox-list-label-${option}`;
                      return (
                        <FormGroup key={index} style={{ maxHeight: 30 }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={selectedOptions.includes(
                                  option.value
                                )}
                                onChange={() => handleToggleOption(option)}
                                name={option.label}
                                inputProps={{ 'aria-label': labelId }}
                                color="primary"
                              />
                            }
                            label={option.label}
                          />
                        </FormGroup>
                      );
                    })}
                  </FormControl>
                </Grid>
                <Grid item>
                  <Grid container justifyContent="flex-end">
                    <Grid item>
                      <StyledButtonSecondary
                        onClick={handleClose}
                        disabled={isLoading || isUpdateLoading}
                        label={t(
                          'projectDetailsPage.ahaOptionsCard.actions.cancelButton'
                        )}
                      />
                    </Grid>
                    <Grid item>
                      <StyledButtonPrimary
                        onClick={handleSubmit}
                        disabled={
                          !canSubmit() || isLoading || isUpdateLoading
                        }
                        label={t(
                          'projectDetailsPage.ahaOptionsCard.actions.submitButton'
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Card>
      </Grid>
    </Grid>
  </>;
};

AhaOptionsCard.propTypes = {
  project: PropTypes.object,
  isLoading: PropTypes.bool
};

export default AhaOptionsCard;
