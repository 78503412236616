import sha1 from 'sha1';
import PhoneNumber from 'awesome-phonenumber';
import { draftToMarkdown } from 'markdown-draft-js';
import { draftToMarkdownOptions } from 'constants/markdown';
import moment from 'moment';
import { dateTimeToLuxonDateTime } from 'utils/dateTime';
import { DateTime } from 'luxon';

export const REACT_APP_ENV = process.env.REACT_APP_ENV;

export const generateTransactionKey = () => {
  return Date.now() + (Math.random() * 100000).toFixed();
};

export const getPhoneNumber = number => {
  return new PhoneNumber(number);
};

export const isValidPhoneNumber = number => {
  return getPhoneNumber(number).isValid();
};

export const getDeterministicId = determiner => {
  // The allowed length of the ID is determined by the DB backend of Prisma (ex: Postgres)
  return sha1(determiner).slice(0, 25);
};

export const stringCompare = (a, b, reverse = false) => {
  const A = a.toUpperCase();
  const B = b.toUpperCase();

  let comparison = 0;
  if (A > B) {
    comparison = 1;
  } else if (A < B) {
    comparison = -1;
  }

  if (reverse) {
    return comparison * -1;
  } else {
    return comparison;
  }
};

export const getDateFromString = dateString => {
  if (dateString) {
    const dateArray = dateString.split('-');
    const year = dateArray[0];
    const month = parseInt(dateArray[1], 10) - 1;
    const date = dateArray[2];
    return new Date(year, month, date);
  }
  return null;
};

export const getMonthFromDate = (dateOrMoment, format = 'long') => {
  return resolveDate(dateOrMoment).toLocaleString('en-us', { month: format });
};

export const getMonthIndexFromDate = dateOrMoment => {
  return resolveDate(dateOrMoment).getMonth();
};

export const resolveDate = dateOrMoment => {
  return dateOrMoment._isAMomentObject ? dateOrMoment._d : dateOrMoment;
};

export const getYearFromDate = dateOrMoment => {
  return resolveDate(dateOrMoment).getFullYear();
};

// Serializes a Date into a RFC 3339 compliant date-string
// in the format YYYY-MM-DD.
export const serializeDate = date => {
  return date.toISOString().split('T')[0];
};

// Turn date string into a moment object and format it as 'MM/DD/YYYY'
export const formatDateOfBirth = momentDate => {
  const date =
    typeof momentDate === 'string'
      ? moment(getDateFromString(momentDate))
      : moment(momentDate);
  return date.format('MM/DD/\\X\\X\\X\\X');
};

// helper function to check that a phone number is valid
// if the field is cleared on the front end, the API receives an empty string
// sending empty string is a front end fix to allowing phone numbers to be deleted
// the API does not allow this currently
export const getPhoneValues = (number, extension, previousNumber, type) => {
  const phoneNumber = number ? getPhoneNumber(number) : null;

  if (phoneNumber && phoneNumber.isValid()) {
    return {
      number: phoneNumber.getNumber('e164'),
      display: phoneNumber.getNumber('international'),
      extension: extension ?? null,
      type: type
    };
  } else if ((!number || number === '+') && previousNumber) {
    // The previous number is meant to be deleted
    return {
      number: '',
      display: '',
      extension: '',
      type: type
    };
  } else {
    return null;
  }
};

export const fileIsPdf = file => {
  if (file) {
    if (file.type) {
      return file.type === 'application/pdf';
    }
    if (file.contentType) {
      return file.contentType === 'application/pdf';
    }
  }
  return false;
};

export const fileIsDoc = file => {
  if (file) {
    if (file.type) {
      return (
        file.type === 'application/msword' ||
        file.type ===
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      );
    }
    if (file.contentType) {
      return (
        file.contentType === 'application/msword' ||
        file.contentType ===
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      );
    }
  }
  return false;
};

export const fileIsXls = file => {
  if (file) {
    if (file.type) {
      return (
        file.type === 'application/vnd.ms-excel' ||
        file.type ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      );
    }
    if (file.contentType) {
      return (
        file.contentType === 'application/vnd.ms-excel' ||
        file.contentType ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      );
    }
  }
  return false;
};

export const fileIsImage = file => {
  if (file) {
    if (file.type) {
      return file['type'].split('/')[0] === 'image';
    }

    if (file.contentType) {
      return file['contentType'].split('/')[0] === 'image';
    }
  }
  return false;
};

// helper function to create a local image link from the blob storage download link
export const getCachedUrl = async url => {
  if (url) {
    return fetch(url)
      .then(function(response) {
        if (response.status === 200) {
          return response.blob();
        }
        return null;
      })
      .then(blob => {
        return blob ? URL.createObjectURL(blob) : null;
      })
      .catch(error => {
        return null;
      });
  }
  return null;
};

// helper function to get the entire image file back from blob storage
export const getCachedFile = async (url, name, fileType) => {
  if (url) {
    return fetch(url)
      .then(function(response) {
        if (response.status === 200) {
          return response.blob();
        }
        return null;
      })
      .then(blob => {
        return blob
          ? new File([blob], name, {
              type: fileType
            })
          : null;
      })
      .catch(error => {
        return null;
      });
  }
  return null;
};

const getDateTime = (date, time) => {
  const luxonDate = dateTimeToLuxonDateTime(date);
  const luxonTime = dateTimeToLuxonDateTime(time);

  return DateTime.fromObject({
    year: luxonDate.year,
    month: luxonDate.month,
    day: luxonDate.day,
    hour: luxonTime.hour,
    minute: luxonTime.minute,
    second: luxonTime.second,
    millisecond: luxonTime.millisecond
  });
};

export function isValidDateTimeRange({
  startDate,
  startTime,
  endDate,
  endTime
}) {
  let startDateTime = null;
  let endDateTime = null;

  if (startDate && startTime) {
    startDateTime = getDateTime(startDate, startTime);
  }

  if (endDate && endTime) {
    endDateTime = getDateTime(endDate, endTime);
  }

  if (startDateTime && endDateTime) {
    return startDateTime < endDateTime;
  }

  return true;
}
// helper function to choose an option from select components
export const chooseOptionFromSelect = async (
  getByText,
  getByLabelText,
  option,
  act,
  fireEvent,
  waitFor
) => {
  const DOWN_ARROW = { keyCode: 40 };
  await act(async () => {
    const getSelectItem = (getByLabelText, getByText) => async (
      selectLabel,
      itemText
    ) => {
      fireEvent.keyDown(getByLabelText(selectLabel), DOWN_ARROW);
      await waitFor(() => getByText(itemText));
      fireEvent.click(getByText(itemText));
    };

    const selectItem = getSelectItem(getByLabelText, getByText);
    await selectItem('select-input', option);
  });
};

export const getOrientationStatus = orientationHistory => {
  if (orientationHistory?.length >= 1) {
    // filter out any future orientations
    const pastOrientations = orientationHistory.filter(
      orientation =>
        Date.parse(orientation.startDateTime) < new Date() &&
        !orientation.isCancelled
    );
    // if past dates return true/ false if only future dates
    if (pastOrientations.length >= 1) {
      return true;
    } else {
      return false;
    }
  }
  return false;
};

// TODO: SPLIT OUT DIFFERENT UTILS INTO THEIR OWN FILES TO INCREASE PERFORMANCE

export const noop = () => {};

export const resetApp = () => window.location.reload();

export const sleep = ms => new Promise(res => setTimeout(res, ms));

export const getTradePartnerPersonnelLabel = tradePartnerPersonnel => {
  let label = '';
  if (
    tradePartnerPersonnel?.personnel?.lastName &&
    tradePartnerPersonnel?.personnel?.firstName
  ) {
    label = `${tradePartnerPersonnel.personnel?.lastName}, ${tradePartnerPersonnel.personnel?.firstName}`;
  }
  if (tradePartnerPersonnel?.personnel?.nickname) {
    label = `${label} "${tradePartnerPersonnel.personnel.nickname}"`;
  }
  if (tradePartnerPersonnel?.personnel?.middleName) {
    label = `${label} ${tradePartnerPersonnel.personnel.middleName}`;
  }

  if (tradePartnerPersonnel?.customId) {
    label = `${label} (id: ${tradePartnerPersonnel.customId})`;
  }

  return label;
};

export const getPersonnelLabel = personnel => {
  let label = '';
  if (personnel?.lastName && personnel?.firstName) {
    label = `${personnel.lastName}, ${personnel.firstName}`;
  }
  if (personnel?.nickname) {
    label = `${label} "${personnel.nickname}"`;
  }
  if (personnel?.middleName) {
    label = `${label} ${personnel.middleName}`;
  }

  return label;
};

export const getSoteriaAdUserLabel = sadUser => {
  let label = '';
  if (sadUser?.lastName && sadUser?.firstName) {
    label = `${sadUser.lastName}, ${sadUser.firstName}`;
  }
  if (sadUser?.email) {
    label = `${label} (${sadUser.email})`;
  }

  return label;
};

export const getMarkdownStringFromDraftJs = draftJsData => {
  return draftToMarkdown(JSON.parse(draftJsData), draftToMarkdownOptions);
};
