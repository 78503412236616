import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useField } from 'react-final-form-hooks';
import { CountryRegionData } from 'react-country-region-selector';
import { Grid } from '@mui/material';

import useFormValidation from 'hooks/useFormValidation';
import StyledInput from 'shared/Input';
import StyledSelect from 'shared/Select';
import StyledPhoneNumberField from 'shared/PhoneNumberField';
import { HP_TRADE_PARTNER_NAMES } from 'constants/hpTradePartnerNames';

const countries = CountryRegionData.map(option => option[0]);

const getRegions = country => {
  if (!country) {
    return [];
  }
  let regions = [];
  CountryRegionData.forEach(countryData => {
    if (countryData[0].toLowerCase() === country.toLowerCase()) {
      regions = countryData[2].split('|').map(regionPair => {
        // eslint-disable-next-line no-unused-vars
        let [regionName, regionCode] = regionPair.split('~');
        return regionCode;
      });
      return;
    }
  });
  return regions;
};

const CompanyFormFields = ({ form, isLoading, company }) => {
  const { t } = useTranslation();
  const {
    isRequired,
    isUrl,
    isRequiredOrDoesNotContainHenselPhelps,
    isPhoneNumber
  } = useFormValidation();

  const isHenselPhelpsCompany = HP_TRADE_PARTNER_NAMES.includes(
    company?.name.toLowerCase()
  );

  const name = useField(
    'name',
    form,
    isHenselPhelpsCompany ? isRequired : isRequiredOrDoesNotContainHenselPhelps
  );
  const streetAndNumber = useField('streetAndNumber', form, isRequired);
  const streetDetails = useField('streetDetails', form);
  const city = useField('city', form, isRequired);
  const stateOrProvince = useField('stateOrProvince', form, isRequired);
  const zipOrPostalCode = useField('zipOrPostalCode', form, isRequired);
  const country = useField('country', form, isRequired);
  const primaryPhoneNumber = useField(
    'primaryPhoneNumber',
    form,
    isPhoneNumber
  );
  const primaryPhoneExtension = useField('primaryPhoneExtension', form);
  const secondaryPhoneNumber = useField(
    'secondaryPhoneNumber',
    form,
    isPhoneNumber
  );
  const secondaryPhoneExtension = useField('secondaryPhoneExtension', form);
  const faxNumber = useField('faxNumber', form, isPhoneNumber);
  const website = useField('website', form, isUrl);
  const notes = useField('notes', form);

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item xs={12}>
        <Grid container direction="row" spacing={1}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <StyledInput
              input={name.input}
              meta={name.meta}
              disabled={isLoading}
              label={t('createCompanyDialog.steps.general.fields.name')}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <StyledInput
              input={streetAndNumber.input}
              meta={streetAndNumber.meta}
              disabled={isLoading}
              required
              label={t(
                'createCompanyDialog.steps.location.fields.streetAndNumber'
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <StyledInput
              input={streetDetails.input}
              meta={streetDetails.meta}
              disabled={isLoading}
              label={t(
                'createCompanyDialog.steps.location.fields.streetDetails'
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <StyledInput
              input={city.input}
              meta={city.meta}
              disabled={isLoading}
              required
              label={t('createCompanyDialog.steps.location.fields.city')}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <StyledInput
              fullWidth={true}
              input={zipOrPostalCode.input}
              meta={zipOrPostalCode.meta}
              disabled={isLoading}
              required
              label={t(
                'createCompanyDialog.steps.location.fields.zipOrPostalCode'
              )}
            />
          </Grid>
          <Grid item>
            <StyledSelect
              inputProps={{ 'data-testid': 'state-select' }}
              placeholder={t(
                'createCompanyDialog.steps.location.fields.select'
              )}
              margin="dense"
              label={t(
                'createCompanyDialog.steps.location.fields.stateOrProvince'
              )}
              required
              input={stateOrProvince.input}
              meta={stateOrProvince.meta}
              disabled={isLoading}
              options={getRegions(country.input.value).map(option => ({
                id: option,
                label: option,
                value: option
              }))}
            />
          </Grid>
          <Grid item>
            <StyledSelect
              inputProps={{ 'data-testid': 'country-select' }}
              placeholder={t(
                'createCompanyDialog.steps.location.fields.select'
              )}
              margin="dense"
              input={country.input}
              required
              label={t('createCompanyDialog.steps.location.fields.country')}
              meta={country.meta}
              disabled={isLoading}
              options={countries.map(option => ({
                id: option,
                label: option,
                value: option
              }))}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row" alignItems="center" spacing={1}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <StyledPhoneNumberField
                  label={t(
                    'createCompanyDialog.steps.contact.fields.primaryContact.phoneNumber'
                  )}
                  value={primaryPhoneNumber.input.value}
                  meta={primaryPhoneNumber.meta}
                  onChange={primaryPhoneNumber.input.onChange}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <StyledInput
                  type="number"
                  label={t(
                    'createCompanyDialog.steps.contact.fields.primaryContact.extension'
                  )}
                  input={primaryPhoneExtension.input}
                  meta={primaryPhoneExtension.meta}
                  disabled={isLoading || !primaryPhoneNumber.input.value}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row" alignItems="center" spacing={1}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <StyledPhoneNumberField
                  label={t(
                    'createCompanyDialog.steps.contact.fields.secondaryContact.phoneNumber'
                  )}
                  value={secondaryPhoneNumber.input.value}
                  meta={secondaryPhoneNumber.meta}
                  onChange={secondaryPhoneNumber.input.onChange}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <StyledInput
                  type="number"
                  input={secondaryPhoneExtension.input}
                  label={t(
                    'createCompanyDialog.steps.contact.fields.secondaryContact.extension'
                  )}
                  meta={secondaryPhoneExtension.meta}
                  disabled={isLoading || !secondaryPhoneNumber.input.value}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <StyledPhoneNumberField
              label={t('createCompanyDialog.steps.contact.fields.faxNumber')}
              value={faxNumber.input.value}
              meta={faxNumber.meta}
              onChange={faxNumber.input.onChange}
              disabled={isLoading}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <StyledInput
              input={website.input}
              meta={website.meta}
              label={t('createCompanyDialog.steps.contact.fields.website')}
              disabled={isLoading}
            />
          </Grid>
          <Grid item xs={12}>
            <StyledInput
              multiline={true}
              input={notes.input}
              meta={notes.meta}
              disabled={isLoading}
              label={t('createCompanyDialog.steps.contact.fields.notes')}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

CompanyFormFields.propTypes = {
  form: PropTypes.any.isRequired,
  isLoading: PropTypes.bool,
  company: PropTypes.object
};

export default CompanyFormFields;
