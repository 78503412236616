import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import { Grid, Card, Typography } from '@mui/material';

import useToast from 'hooks/useToast';
import StyledDialog from 'shared/Dialog';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import StyledButtonSecondary from 'shared/Buttons/ButtonSecondary';
import StyledNotice from 'shared/Notice';
import StyledConfirmationSwitch from 'shared/ConfirmationSwitch';
import { DELETE_COMPANY, GET_ALL_COMPANIES } from 'graphql/company';

const CompanyDeleteCard = ({ company }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [dialogIsOpen, toggleDialog] = useState(false);
  const [isConfirmed, toggleConfirmSwitch] = useState(false);
  const { displayToast } = useToast();

  const [deleteCompany, { loading }] = useMutation(DELETE_COMPANY, {
    update(cache, { data: { deleteCompany } }) {
      let result = null;
      try {
        result = cache.readQuery({
          query: GET_ALL_COMPANIES
        });
      } catch (err) {
        // https://github.com/apollographql/apollo-feature-requests/issues/1
      }
      if (result?.companies) {
        cache.writeQuery({
          query: GET_ALL_COMPANIES,
          data: {
            companies: result.companies.filter(
              company => company.id !== deleteCompany.id
            )
          }
        });
      }
    }
  });

  const handleClose = () => {
    toggleDialog(false);
    toggleConfirmSwitch(false);
  };

  const handleSubmit = () => {
    deleteCompany({
      variables: { id: company.id }
    })
      .then(() => {
        displayToast(
          t('companyDeleteCard.toasts.success', { companyName: company.name }),
          'success'
        );
        handleClose();
        history.push('/companies');
      })
      .catch(error => {
        console.error('Delete Company Error: ', error);
        displayToast(t('companyDeleteCard.toasts.error'), 'error');
      });
  };

  const handleChange = event => {
    toggleConfirmSwitch(event.target.checked);
  };

  return (
    <Fragment>
      <Card className="padding">
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Typography color="textPrimary" className="bold">
              {t('companyDeleteCard.title')}
            </Typography>
          </Grid>
          <Grid item>
            <Grid container direction="row" justifyContent="space-between">
              <Grid item>
                <Typography gutterBottom color="textSecondary">
                  {t('companyDeleteCard.remove')}
                </Typography>
              </Grid>
              <Grid item>
                <StyledButtonPrimary
                  disabled={loading}
                  label={t('companyDeleteCard.button.delete')}
                  onClick={() => toggleDialog(true)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
      <StyledDialog
        isOpen={dialogIsOpen}
        isLoading={loading}
        handleClose={handleClose}
        disableBackdropClick={false}
        disableEscapeKeyDown={false}
        maxWidth="sm"
        title={t('companyDeleteCard.confirmation.title')}
        content={
          <Grid container direction="column" spacing={1}>
            <StyledNotice
              type="warning"
              message={t('companyDeleteCard.confirmation.subTitle')}
            />
            <Grid item>
              <StyledConfirmationSwitch
                disabled={loading}
                checked={isConfirmed}
                onChange={handleChange}
                value={'isConfirmed'}
              />
            </Grid>
          </Grid>
        }
        actions={
          <Fragment>
            <StyledButtonSecondary
              label={t('companyDeleteCard.confirmation.actions.cancelButton')}
              disabled={loading}
              onClick={handleClose}
            />
            <StyledButtonPrimary
              label={t('companyDeleteCard.confirmation.actions.submitButton')}
              disabled={loading || !isConfirmed}
              onClick={handleSubmit}
            />
          </Fragment>
        }
      />
    </Fragment>
  );
};
CompanyDeleteCard.propTypes = {
  company: PropTypes.object.isRequired
};
export default CompanyDeleteCard;
