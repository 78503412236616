import makeStyles from '@mui/styles/makeStyles';
const useAddAhaDialogStyles = () => {
  return makeStyles(theme => ({
    searchInput: {
      maxWidth: 450,
      '& .MuiInput-underline:before': {
        borderBottom: '2px solid' + theme.palette.primary.main
      },
      '& .MuiInputBase-input': {
        fontSize: '1rem !important',
        fontWeight: 'bold !important',
        color: theme.palette.secondary.contrastText + '!important',
        padding: theme.spacing(1, 0, 0, 2),
        backgroundColor: theme.palette.background.paper,
        borderRadius: 4
      },
      '&:hover': {
        borderBottomColor: theme.palette.primary.main
      }
    },
    icon: { color: theme.palette.secondary.contrastText },
    titleContainer: {
      margin: theme.spacing(2, 0, 1, 0),
      [theme.breakpoints.down('lg')]: { margin: 0 }
    },
    title: { fontSize: '1.25rem', margin: theme.spacing(0, 2) },
    accordionRoot: {
      boxShadow: 'none',
      backgroundColor: '#fff !important',
      '&:before': { backgroundColor: theme.palette.background.paper },
      minHeight: 0
    },
    accordionExpanded: {
      margin: '0px !important'
    },
    accordionSummaryRoot: {
      minHeight: '0px !important',
      backgroundColor: theme.palette.background.paper,
      '&.Mui-focused': { backgroundColor: 'rgba(0,0,0,0) !important' },
      [theme.breakpoints.down('lg')]: { padding: 0 }
    },
    accordionSummaryExpanded: {
      minHeight: '0px !important',
      backgroundColor: theme.palette.background.default
    },
    expanded: {
      margin: '0px !important',
      minHeight: '0px !important'
    },
    accordionSummaryContent: {
      margin: '4px !important',
      '&:hover': { cursor: 'auto' }
    },
    accordionSummaryContentExpanded: {
      margin: '0px !important',
      backgroundColor: theme.palette.background.default,
      borderRadius: theme.spacing(1, 1, 0, 0),
      padding: theme.spacing(0)
    },
    accordionDetailsRoot: {
      padding: 0,
      backgroundColor: theme.palette.background.paper
    },
    heading: {
      color: theme.palette.secondary.contrastText
    },
    headingExpanded: {
      fontWeight: 'bold',
      color: theme.palette.secondary.contrastText
    },
    expandIcon: {
      color: 'gray',
      '&:hover': { cursor: 'pointer' }
    },
    expandIconWhileEditing: { marginTop: '13px !important' },
    categoryName: { '&:hover': { cursor: 'pointer' } },
    errorText: {
      fontSize: '0.75rem',
      color: theme.palette.error.main,
      marginLeft: theme.spacing(5)
    },
    noCategoriesMessage: {
      margin: theme.spacing(1, 0, 0, 2),
      fontSize: '0.75rem'
    },
    editModeButton: { borderRadius: 16 },
    editModeButtonLabel: { fontSize: '0.75rem', fontWeight: 'bold' },
    categorySelectContainer: { margin: theme.spacing(1, 2) }
  }));
};

export default useAddAhaDialogStyles;
