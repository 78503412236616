import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MUIRichTextEditor from 'mui-rte';
import classnames from 'classnames';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import HighlightIcon from '@mui/icons-material/Highlight';
import { FormHelperText, Typography, Grid } from '@mui/material';
import { markdownToDraft } from 'markdown-draft-js';
import { markdownToDraftOptions } from 'constants/markdown';

const getInitialValue = markdownString => {
  if (markdownString) {
    const contentState = markdownToDraft(
      markdownString,
      markdownToDraftOptions
    );
    return JSON.stringify(contentState);
  }

  return null;
};

const useStyles = makeStyles(theme => ({
  container: {
    border: '1px solid' + theme.palette.field.border,
    borderRadius: 4,
    padding: theme.spacing(0, 1.5),
    '&:hover': {
      border: '1px solid' + theme.palette.secondary.contrastText
    }
  },
  errorContainer: {
    border: '1px solid' + theme.palette.error.main,
    '&:hover': {
      border: '1px solid' + theme.palette.error.main
    }
  },
  labelText: { fontSize: '0.75rem', fontWeight: 'bold' },
  errorText: { color: theme.palette.error.main },
  editorLabelText: {
    fontSize: '0.875rem',
    color: theme.palette.secondary.contrastText
  }
}));

const DEFAULT_MAIN_TOOLBAR_CONTROLS = [
  'title',
  'bold',
  'italic',
  //'underline',
  //'highlight',
  //'soteria-highlight',
  'numberList',
  'bulletList',
  'clear'
];

const DEFAULT_INLINE_TOOLBAR_CONTROLS = [
  'bold',
  'italic',
  //'underline',
  //'highlight',
  //'soteria-highlight',
  'clear'
];

const StyledRichTextEditor = ({
  controls,
  label,
  initialValue,
  value,
  onChange,
  onSave = null,
  placeholder,
  required = true,
  hasError = false,
  disabled,
  error,
  autoSaveRef,
  ...rest
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [wasTouched, setTouched] = useState(false);
  const shouldShowAsError = hasError || (required && wasTouched && !value);
  const formattedInitialValue = getInitialValue(initialValue);

  return (
    <>
      {label && (
        <>
          {!wasTouched && !disabled && !shouldShowAsError && (
            <Typography color="textSecondary" className={classes.labelText}>
              {required ? `${label} *` : label}
            </Typography>
          )}
          {wasTouched && !shouldShowAsError && !disabled && (
            <Typography color="textSecondary" className={classes.labelText}>
              {required ? `${label} *` : label}
            </Typography>
          )}
          {wasTouched && shouldShowAsError && !disabled && (
            <Typography color="error" className={classes.labelText}>
              {required ? `${label} *` : label}
            </Typography>
          )}
          {disabled && !shouldShowAsError && (
            <Typography color="textSecondary" className={classes.labelText}>
              {label}
            </Typography>
          )}
        </>
      )}
      <Grid
        container
        className={classnames(classes.container, {
          [`${classes.errorContainer}`]: shouldShowAsError
        })}>
        <Grid item xs={12}>
          <MUIRichTextEditor
            id={label}
            defaultValue={formattedInitialValue} //Default content to load. Should be a stringified Draft.Model.Encoding.RawDraftContentState object.
            readOnly={disabled}
            inheritFontSize={true} // Inherit font size from parent. Useful for read only mode.
            error={wasTouched && shouldShowAsError}
            controls={controls ?? DEFAULT_MAIN_TOOLBAR_CONTROLS}
            customControls={[
              {
                name: 'soteria-highlight',
                icon: <HighlightIcon />,
                type: 'inline',
                inlineStyle: {
                  backgroundColor: theme.palette.richTextEditor.highlight,
                  color: '#000'
                }
              }
            ]}
            toolbar={true} // Defines if the main toolbar should be rendered.
            toolbarButtonSize={'small'} // small | medium
            inlineToolbar={false}
            inlineToolbarControls={DEFAULT_INLINE_TOOLBAR_CONTROLS}
            maxLength={null}
            label={
              placeholder ?? (
                <Typography className={classes.editorLabelText}>
                  Start typing...
                </Typography>
              )
            }
            onFocus={() => setTouched(true)}
            onBlur={() => {}}
            onSave={onSave} // Function triggered when the save button is pressed. The data is a stringified
            onChange={onChange} // Function triggered on any change in the editor (key input, delete, etc.). The state is a Draft.Model.ImmutableData.EditorState object.
            ref={autoSaveRef}
            {...rest}
          />
        </Grid>
      </Grid>
      {wasTouched && shouldShowAsError && (
        <FormHelperText className={classes.errorText}>
          Error: This field is required.
        </FormHelperText>
      )}
    </>
  );
};

StyledRichTextEditor.propTypes = {
  controls: PropTypes.array,
  initialValue: PropTypes.any,
  value: PropTypes.any,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  hasError: PropTypes.bool,
  error: PropTypes.string,
  autoSaveRef: PropTypes.any
};

export default StyledRichTextEditor;
