import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useForm, useField } from 'react-final-form-hooks';
import { useMutation } from '@apollo/react-hooks';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import makeStyles from '@mui/styles/makeStyles';
import {
  Grid,
  Typography,
  Card,
  Chip,
  LinearProgress
} from '@mui/material';

import useToast from 'hooks/useToast';
import StyledButtonMuted from 'shared/Buttons/ButtonMuted';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import StyledButtonSecondary from 'shared/Buttons/ButtonSecondary';
import StyledInput from 'shared/Input';
import { UPDATE_PROJECT } from 'graphql/project';

const useStyles = makeStyles(theme => ({
  label: {
    paddingTop: 1,
    [theme.breakpoints.down('md')]: {
      overflow: 'visible',
      textOverflow: 'inherit',
      whiteSpace: 'break-spaces'
    }
  },
  titleContainer: { paddingBottom: theme.spacing(1) },
  titleText: { fontWeight: 'bold' },
  card: { padding: theme.spacing(3) },
  buttonContainer: {
    justifyContent: 'flex-end '
  },
  jobsiteContainer: {
    alignItems: 'center'
  },
  chip: {
    margin: 5,
    overflow: 'visible',
    textOverflow: 'inherit',
    whiteSpace: 'break-spaces'
  },
  chipContent: {
    border: '1px solid' + theme.palette.primary.main,
    color: theme.palette.primary.main,
    [theme.breakpoints.down('md')]: { height: 'fit-content', maxWidth: 245 },
    overflow: 'visible',
    textOverflow: 'inherit',
    whiteSpace: 'break-spaces'
  },
  inputContainer: {
    width: '20%',
    marginRight: 10,
    [theme.breakpoints.down('md')]: { width: '100%' }
  },
  addAreaContainer: {
    marginTop: theme.spacing(2),
    alignItems: 'center',
    [theme.breakpoints.down('md')]: { flexDirection: 'column' }
  },
  addButton: {
    margin: theme.spacing(1, 0, 2, 0),
    [theme.breakpoints.down('md')]: { marginBottom: 18 }
  }
}));

const ProjectJobSiteCard = ({ isLoading, project }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isEditing, toggleEditing] = useState(false);
  const [jobsiteAreas, setJobsiteAreas] = useState(
    project?.areas?.length ? [...project.areas] : []
  );
  const [updateProject, { loading }] = useMutation(UPDATE_PROJECT);
  const { displayToast } = useToast();

  const { form } = useForm({
    /* istanbul ignore next */
    onSubmit: () => {} // this function required for useForm but is not used
  });
  const newProjectAreaName = useField('newProjectAreaName', form);

  const addNewArea = () => {
    setJobsiteAreas([...jobsiteAreas, newProjectAreaName.input.value]);
    form.reset();
    return;
  };

  const deleteArea = index => {
    const jobsiteAreasCopy = [...jobsiteAreas];
    if (index > -1) {
      jobsiteAreasCopy.splice(index, 1);
    }
    setJobsiteAreas([...jobsiteAreasCopy]);
    return;
  };

  const canSubmit = () => {
    const originalArray = project.areas?.length ? [...project.areas] : [];
    // prevents unnecessary api calls: only allow submit if changes have been made
    return JSON.stringify(originalArray) !== JSON.stringify(jobsiteAreas);
  };

  const onSubmit = () => {
    updateProject({
      variables: {
        id: project.id,
        areas: jobsiteAreas
      }
    })
      .then(({ data: { updateProject: updatedProject } }) => {
        displayToast(
          t('projectDetailsPage.projectJobSiteCard.toasts.update.success'),
          'success'
        );
        form.reset();
        toggleEditing(false);
      })
      .catch(error => {
        console.error('Request access Error: ', error);
        displayToast(
          t('projectDetailsPage.projectJobSiteCard.toasts.update.error'),
          'error'
        );
      });
  };

  const getJobsiteAreasDisplay = selectedOptions => {
    const projectAreas = [...project.areas];
    const sortedProjectAreas = projectAreas.sort();
    const display = sortedProjectAreas.map((jobsiteArea, index) => {
      return jobsiteArea;
    });

    return display.map((item, index) => {
      return <li key={index}>{item}</li>;
    });
  };

  return <>
    <Grid container direction="column" spacing={3}>
      <Grid item xs={12}>
        {loading && <LinearProgress color="primary" />}
        <Card className={classes.card} data-testid="project-areas-card">
          <Grid container direction="column">
            <Grid item>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography
                    gutterBottom
                    className={classes.titleText}
                    component="h2">
                    {t('projectDetailsPage.projectJobSiteCard.title')}
                  </Typography>
                </Grid>
                <Grid item>
                  {isEditing && (
                    <Typography color="primary">
                      {t('projectDetailsPage.projectJobSiteCard.editing')}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
            {!isEditing && (
              <>
                <Grid item>
                  <Grid container direction="column">
                    <ul>
                      <Typography>{getJobsiteAreasDisplay()}</Typography>
                    </ul>
                    {!project?.areas?.length && (
                      <Typography color="textSecondary" gutterBottom>
                        {t('projectDetailsPage.projectJobSiteCard.noAreas')}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Grid item style={{ marginTop: 8 }}>
                  <Grid container justifyContent="flex-end">
                    <Grid item>
                      <StyledButtonMuted
                        startIcon={<EditIcon color="action" />}
                        label={t(
                          'projectDetailsPage.projectJobSiteCard.actions.editJobsiteAreas'
                        )}
                        onClick={() => toggleEditing(true)}
                        disabled={isLoading}
                        data-testid="edit-jobsites-button"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
            {isEditing && (
              <>
                <Grid item>
                  <Grid container>
                    {jobsiteAreas &&
                      jobsiteAreas.map((jobsiteArea, index) => {
                        return (
                          <div key={index}>
                            <Grid item className={classes.chip}>
                              <Chip
                                className={classes.chipContent}
                                data-testid="jobsite-chip"
                                color="primary"
                                disabled={isLoading}
                                label={
                                  <Typography className={classes.label}>
                                    {jobsiteArea}
                                  </Typography>
                                }
                                variant="outlined"
                                size="small"
                                onDelete={() => deleteArea(index)}
                              />
                            </Grid>
                          </div>
                        );
                      })}
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container className={classes.addAreaContainer}>
                    <Grid item xs={12} className={classes.inputContainer}>
                      <StyledInput
                        disabled={isLoading}
                        fullWidth
                        label={t(
                          'projectDetailsPage.projectJobSiteCard.newAreaName'
                        )}
                        input={newProjectAreaName.input}
                        meta={newProjectAreaName.meta}
                      />
                    </Grid>
                    <Grid item xs={12} className={classes.addButton}>
                      <StyledButtonMuted
                        startIcon={
                          <AddIcon color="action" aria-hidden="true" />
                        }
                        label={t(
                          'projectDetailsPage.projectJobSiteCard.actions.addNewArea'
                        )}
                        onClick={() => addNewArea()}
                        disabled={
                          isLoading || !newProjectAreaName.input.value
                        }
                        data-testid="add-new-area-button"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container className={classes.buttonContainer}>
                    <Grid item>
                      <StyledButtonSecondary
                        onClick={() => {
                          toggleEditing(false);
                          setJobsiteAreas(
                            project?.areas?.length ? [...project.areas] : []
                          );
                        }}
                        disabled={isLoading || loading}
                        label={t(
                          'projectDetailsPage.projectJobSiteCard.actions.cancel'
                        )}
                        data-testid="cancel-update-project"
                      />
                    </Grid>
                    <Grid item>
                      <StyledButtonPrimary
                        data-testid="update-project-submit"
                        disabled={!canSubmit() || loading}
                        onClick={onSubmit}
                        label={t(
                          'projectDetailsPage.projectJobSiteCard.actions.submit'
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Card>
      </Grid>
    </Grid>
  </>;
};

ProjectJobSiteCard.propTypes = {
  isLoading: PropTypes.bool,
  project: PropTypes.object
};

export default ProjectJobSiteCard;
