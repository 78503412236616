import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-final-form-hooks';
import { useMutation } from '@apollo/react-hooks';
import EditIcon from '@mui/icons-material/Edit';
import { Grid, Typography, Card, LinearProgress } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import useToast from 'hooks/useToast';
import useStringIncludesHenselPhelps from 'hooks/useStringIncludesHenselPhelps';
import AttendeeFormFields from '../AttendeeFormFields';
import StyledButtonGroup from 'shared/Buttons/ButtonGroup';
import StyledButtonMuted from 'shared/Buttons/ButtonMuted';
import { UPDATE_ATTENDEE } from 'graphql/attendee';
import { formatDateOfBirth, getPhoneValues } from 'utils';
import {
  formatPhoneNumber,
  formatInputAndInternationalPhoneNumber
} from 'utils/formatPhoneNumber';
import { dateTimeToLuxonDateTime } from 'utils/dateTime';

const useStyles = makeStyles(theme => ({
  titleContainer: { paddingBottom: theme.spacing(1) },
  titleText: { fontWeight: 'bold' },
  card: { padding: theme.spacing(3) },
  notesContainer: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
    borderRadius: 4
  }
}));

const AttendeeInfoCard = ({ attendee }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isEditing, toggleEditing] = useState(false);
  const { displayToast } = useToast();
  const [updateAttendee, { loading: isLoading }] = useMutation(UPDATE_ATTENDEE);
  const { stringIncludesHenselPhelps } = useStringIncludesHenselPhelps();
  const isHenselPhelps = stringIncludesHenselPhelps(attendee.tradePartner.name);
  const attendeeIsApproved = attendee?.status === 'Approved';

  //attendee object has a phone number in attendee.personnel or attendee.contactInformation
  const attendeePhoneNumber = attendee.personnel?.contactInformation
    ?.primaryPhone?.number
    ? attendee.personnel?.contactInformation?.primaryPhone?.number
    : attendee.contactInformation?.primaryPhone?.number;
  const unformattedAttendeeInputPhoneNumber = formatInputAndInternationalPhoneNumber(
    attendeePhoneNumber
  )?.input;
  const formattedAttendeeInputPhoneNumber = formatPhoneNumber(
    unformattedAttendeeInputPhoneNumber
  );
  const attendeeInternationalPhoneNumber = formatInputAndInternationalPhoneNumber(
    attendeePhoneNumber
  )?.international;
  const validAttendeePhoneNumber = attendeeInternationalPhoneNumber
    ? attendeeInternationalPhoneNumber
    : formattedAttendeeInputPhoneNumber;

  const { form, values, pristine } = useForm({
    onSubmit: () => {},
    initialValues: {
      firstName: attendee.firstName ?? attendee.personnel?.firstName ?? '',
      middleName: attendee.middleName ?? attendee.personnel?.middleName ?? '',
      lastName: attendee.lastName ?? attendee.personnel?.lastName ?? '',
      nickname: attendee.nickname ?? attendee.personnel?.nickname ?? '',
      dob: attendee.dob ?? attendee.personnel?.dob ?? '',
      phoneNumber:
        attendee.contactInformation?.primaryPhone?.number ??
        attendee.personnel?.contactInformation?.primaryPhone?.number ??
        '',
      email:
        attendee.contactInformation?.emailAddress?.email ??
        attendee.personnel?.contactInformation?.emailAddress?.email ??
        '',
      customId: attendee.customId ?? '',
      isSupervisor: attendee.isSupervisor ?? undefined,
      notes: attendee.notes ?? attendee.personnel?.notes ?? ''
    }
  });

  const canSubmit = () => {
    const { error, errors, invalid } = form.getState();
    if (isHenselPhelps) {
      return !(errors.nickname || pristine || isLoading);
    } else {
      return !(pristine || error || invalid || isLoading);
    }
  };

  const getDob = () => {
    if (values.dob) {
      return dateTimeToLuxonDateTime(values.dob)?.toFormat('yyyy-MM-dd');
    }
    return undefined;
  };

  const handleSubmit = () => {
    updateAttendee({
      variables: {
        input: {
          id: attendee.id,
          personnelId: attendee.personnel?.id,
          firstName: values.firstName,
          middleName: values.middleName,
          lastName: values.lastName,
          nickname: values.nickname,
          dob: getDob(),
          contactInformation: {
            primaryPhone: getPhoneValues(
              values.phoneNumber,
              null,
              attendee.contactInformation.primaryPhone?.number,
              'Primary'
            ),
            emailAddress: {
              email: values.email
            }
          },
          customId: values.customId,
          isSupervisor: values.isSupervisor,
          notes: values.notes,
          trainingsToAdd: [],
          trainingsToDelete: [],
          trainingsToUpdate: []
        }
      }
    })
      .then(({ data: { updateAttendee: attendee } }) => {
        handleClose();
        displayToast(
          t('attendeeInfoCard.toasts.update.success', {
            attendeeName: attendee.fullName
          }),
          'success'
        );
      })
      .catch(error => {
        console.error('Update Attendee Error: ', error);
        displayToast(t('attendeeInfoCard.toasts.update.error'), 'error');
      });
  };

  const handleClose = () => {
    form.reset();
    toggleEditing(false);
  };

  return (
    <>
      {isLoading && <LinearProgress color="primary" />}
      <Card className={classes.card}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container direction="column">
              <Grid item>
                <Grid
                  container
                  justifyContent="flex-end"
                  className={classes.titleContainer}>
                  <Grid item>
                    {isEditing && (
                      <Typography color="primary">
                        {t('attendeeInfoCard.editMode.title')}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              {!isEditing && (
                <Grid item>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography
                        component="h2"
                        gutterBottom
                        className={classes.titleText}>
                        {t('attendeeInfoCard.title')}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Grid container direction="column">
                        <Typography>
                          {t('attendeeInfoCard.statusTitle')}{' '}
                          {attendee.status === 'SelfEnrolled'
                            ? t('attendeeInfoCard.status.selfEnrolled')
                            : attendee.status}
                        </Typography>
                        <Typography>
                          {t('attendeeInfoCard.dobTitle')}{' '}
                          {formatDateOfBirth(attendee.dob)}
                        </Typography>
                        {attendee.contactInformation?.primaryPhone?.number && (
                          <Typography component="span" className="inline">
                            {t('attendeeInfoCard.phoneNumberTitle')}{' '}
                            {validAttendeePhoneNumber}
                          </Typography>
                        )}
                        {!attendee.contactInformation?.primaryPhone?.number &&
                          attendee.personnel?.contactInformation?.primaryPhone
                            ?.number && (
                            <Typography component="span" className="inline">
                              {t('attendeeInfoCard.phoneNumberTitle')}{' '}
                              {validAttendeePhoneNumber}
                            </Typography>
                          )}
                        {attendee.contactInformation.emailAddress?.email && (
                          <Typography>
                            {t('attendeeInfoCard.emailTitle')}{' '}
                            {attendee.contactInformation.emailAddress.email}
                          </Typography>
                        )}
                        {!attendee.contactInformation.emailAddress?.email &&
                          attendee.personnel?.contactInformation.emailAddress
                            ?.email && (
                            <Typography>
                              {t('attendeeInfoCard.emailTitle')}{' '}
                              {
                                attendee.personnel?.contactInformation
                                  .emailAddress.email
                              }
                            </Typography>
                          )}
                        {attendee.customId && (
                          <Typography>
                            {t('attendeeInfoCard.customIdTitle')}{' '}
                            {attendee.customId}
                          </Typography>
                        )}
                        <Typography>
                          {t('attendeeInfoCard.tradePartnerTitle')}{' '}
                          {attendee.tradePartner.name}
                        </Typography>
                        <Typography>
                          {t('attendeeInfoCard.supervisorTitle')}{' '}
                          {attendee.isSupervisor
                            ? t('attendeeInfoCard.supervisor.yes')
                            : t('attendeeInfoCard.supervisor.no')}
                        </Typography>
                        <Grid item className="margin-top">
                          <Grid container direction="column">
                            <Grid item>
                              <Typography>
                                {t('attendeeInfoCard.notes.title')}
                              </Typography>
                            </Grid>
                            <Grid item className={classes.notesContainer}>
                              <Typography>
                                {attendee.notes && attendee.notes}
                                {!attendee.notes &&
                                  attendee.personnel?.notes &&
                                  attendee.personnel?.notes}
                                {!attendee.notes &&
                                  !attendee.personnel?.notes &&
                                  t('attendeeInfoCard.notes.noNotesDisclaimer')}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    {!attendeeIsApproved && (
                      <Grid item style={{ marginTop: 8 }}>
                        <Grid container justifyContent="flex-end">
                          <Grid item>
                            <StyledButtonMuted
                              startIcon={<EditIcon color="action" />}
                              label={t('attendeeInfoCard.editButton.title')}
                              onClick={() => toggleEditing(!isEditing)}
                              disabled={isLoading}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )}
              {isEditing && (
                <Grid container direction="column" spacing={1}>
                  <Grid item>
                    <form>
                      <AttendeeFormFields
                        form={form}
                        isLoading={isLoading}
                        attendee={attendee}
                      />
                    </form>
                  </Grid>
                  <Grid item>
                    <StyledButtonGroup
                      onClickAltAction={handleClose}
                      labelAltAction={t('attendeeInfoCard.actions.cancel')}
                      disabledAltAction={isLoading}
                      onClickMainAction={handleSubmit}
                      labelMainAction={t('attendeeInfoCard.actions.submit')}
                      disabledMainAction={!canSubmit()}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

AttendeeInfoCard.propTypes = {
  attendee: PropTypes.object.isRequired
};

export default AttendeeInfoCard;
