import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography, Card, LinearProgress } from '@mui/material';

import StyledButtonMuted from 'shared/Buttons/ButtonMuted';
import UpdateProjectForm from 'components/project/UpdateProjectForm';

const useStyles = makeStyles(theme => ({
  titleText: { fontWeight: 'bold' },
  card: { padding: theme.spacing(3) },
  label: {
    fontWeight: 'bold',
    fontSize: '0.875rem',
    display: 'inline',
    marginRight: 4
  },
  value: { display: 'inline' }
}));

const ProjectInfoCard = ({ isLoading, project }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isEditing, toggleEditing] = useState(false);

  const street = project?.location?.streetAndNumber ?? null;
  const city = project?.location?.city ?? null;
  const state = project?.location?.stateOrProvince ?? null;
  const zip = project?.location?.zipOrPostalCode ?? null;

  const displayAddress = `${street ? street + ', ' : ''} ${
    city ? city + ', ' : ''
  } ${state ? state : ''} ${zip ? zip : ''}`;

  return <>
    <Grid container direction="column" spacing={3}>
      <Grid item xs={12}>
        {isLoading && <LinearProgress color="primary" />}
        <Card className={classes.card} data-testid="project-info-card">
          <Grid container direction="column">
            <Grid item>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography
                    gutterBottom
                    className={classes.titleText}
                    component="h2">
                    {t('projectDetailsPage.projectInfoCard.title')}
                  </Typography>
                </Grid>
                <Grid item>
                  {isEditing && (
                    <Typography color="primary">
                      {t('projectDetailsPage.projectInfoCard.editing')}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
            {!isEditing && (
              <>
                <Grid item>
                  <Typography color="textSecondary" className={classes.label}>
                    {t('projectDetailsPage.projectInfoCard.address')}
                  </Typography>
                  <Typography className={classes.value}>
                    {displayAddress}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography color="textSecondary" className={classes.label}>
                    {t('projectDetailsPage.projectInfoCard.projectNumber')}
                  </Typography>
                  <Typography className={classes.value}>
                    {project?.number}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography color="textSecondary" className={classes.label}>
                    {t('projectDetailsPage.projectInfoCard.district')}
                  </Typography>
                  <Typography className={classes.value}>
                    {project?.districtNumber}
                  </Typography>
                </Grid>
                <Grid item style={{ marginTop: 8 }}>
                  <Grid container justifyContent="flex-end">
                    <Grid item>
                      <StyledButtonMuted
                        startIcon={<EditIcon color="action" />}
                        label={t(
                          'projectDetailsPage.projectInfoCard.actions.editGeneralInfo'
                        )}
                        onClick={() => toggleEditing(true)}
                        disabled={isLoading}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
            {isEditing && (
              <UpdateProjectForm
                project={project}
                toggleEditing={toggleEditing}
              />
            )}
          </Grid>
        </Card>
      </Grid>
    </Grid>
  </>;
};

ProjectInfoCard.propTypes = {
  isLoading: PropTypes.bool,
  project: PropTypes.object
};

export default ProjectInfoCard;
