import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, useField } from 'react-final-form-hooks';
import { useMutation } from '@apollo/react-hooks';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography } from '@mui/material';

import useFormValidation from 'hooks/useFormValidation';
import useToast from 'hooks/useToast';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import StyledUserNotice from 'components/common/UserNoticeTemplate';
import ProjectSelect from 'components/project/ProjectSelect';
import LoadingSpinner from 'components/common/LoadingSpinner';
import ErrorNotice from 'components/common/ErrorNotice';
import PageNotFoundNotice from 'components/common/PageNotFoundNotice';
import { CARBON_COPY_PROJECT } from 'graphql/project';

const useStyles = makeStyles(theme => ({
  img: { maxWidth: 250, [theme.breakpoints.down('md')]: { maxWidth: 150 } },
  title: {
    fontSize: '1.75rem',
    fontWeight: 'bold',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: { fontSize: '1.5rem' }
  },
  instructions: {
    textAlign: 'center',
    [theme.breakpoints.down('md')]: { fontSize: '0.875rem' }
  },
  warning: {
    fontWeight: 'bold',
    textAlign: 'center',
    margin: theme.spacing(3, 0),
    [theme.breakpoints.down('md')]: {
      fontSize: '0.875rem',
      margin: theme.spacing(1, 0)
    }
  },
  selectDetails: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: { fontSize: '0.875rem' }
  },
  buttonContainer: { margin: '0 auto' },
  submitButton: { width: 200, marginBottom: theme.spacing(3) }
}));

const TransferPersonnelPage = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const { isRequired } = useFormValidation();
  const { displayToast } = useToast();

  const [carbonCopyProject, { loading: isLoading }, error] = useMutation(
    CARBON_COPY_PROJECT
  );

  const { form, values } = useForm({
    /* istanbul ignore next */
    onSubmit: () => {} // this function required for useForm but is not used
  });

  const selectedOriginalProject = useField(
    'selectedOriginalProject',
    form,
    isRequired
  );

  const selectedCarbonCopyProject = useField(
    'selectedCarbonCopyProject',
    form,
    isRequired
  );

  const isSameProject =
    values.selectedOriginalProject &&
    values.selectedCarbonCopyProject &&
    values.selectedOriginalProject.number ===
      values.selectedCarbonCopyProject.number;

  const canSubmit = () => {
    const { error, invalid } = form.getState();
    return !(invalid || error || isSameProject || isLoading);
  };

  const handleSubmit = () => {
    carbonCopyProject({
      variables: {
        copyFromProjectId: values.selectedOriginalProject.id,
        copyToProjectId: values.selectedCarbonCopyProject.id
      }
    }).then(
      ({ data: { carbonCopyProject: project } }) => {
        displayToast(t('transferPersonnelPage.toasts.success'), 'success');
      },
      error => {
        console.error('Carbon Copy Error: ', error);
        displayToast(t('transferPersonnelPage.toasts.error'), 'error');
      }
    );
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error) {
    if (error.message?.includes('not found')) {
      return <PageNotFoundNotice />;
    } else {
      return <ErrorNotice />;
    }
  }

  return (
    <StyledUserNotice
      image={
        <img
          className={classes.img}
          src={theme.transferEmployees}
          alt=""
          role="presentation"
        />
      }
      content={
        <Grid
          container
          justifyContent="center"
          direction="column"
          spacing={3}
          alignContent="center">
          <Grid item xs={12} sm={12} md={10} lg={8}>
            <Typography component="h1" className={classes.title}>
              {t('transferPersonnelPage.title')}
            </Typography>
            <Typography component="h2" className={classes.instructions}>
              {t('transferPersonnelPage.instructions')}
            </Typography>
            <Typography component="h3" className={classes.warning}>
              {t('transferPersonnelPage.warning')}
            </Typography>
          </Grid>
          <Grid item>
            <Grid container direction="row" justifyContent="space-between" spacing={3}>
              <Grid item xs={12} sm={12} md={5} lg={5}>
                <Typography className={classes.selectDetails}>
                  {t('transferPersonnelPage.originalProject.selectDetails')}
                </Typography>
                <ProjectSelect
                  selectedProject={selectedOriginalProject.input.value || null}
                  meta={selectedOriginalProject.meta}
                  handleChange={selectedOriginalProject.input.onChange}
                  placeholder={
                    <Typography>
                      {t('transferPersonnelPage.originalProject.placeholder')}
                    </Typography>
                  }
                  label={t('transferPersonnelPage.originalProject.label')}
                  isRequired={true}
                  customError={
                    isSameProject
                      ? t('transferPersonnelPage.originalProject.customError')
                      : undefined
                  }
                  isDisabled={isLoading}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={5} lg={5}>
                <Typography className={classes.selectDetails}>
                  {t('transferPersonnelPage.destinationProject.selectDetails')}
                </Typography>
                <ProjectSelect
                  selectedProject={
                    selectedCarbonCopyProject.input.value || null
                  }
                  meta={selectedCarbonCopyProject.meta}
                  handleChange={selectedCarbonCopyProject.input.onChange}
                  placeholder={
                    <Typography>
                      {t(
                        'transferPersonnelPage.destinationProject.placeholder'
                      )}
                    </Typography>
                  }
                  label={t('transferPersonnelPage.destinationProject.label')}
                  isRequired={true}
                  customError={
                    isSameProject
                      ? t(
                          'transferPersonnelPage.destinationProject.customError'
                        )
                      : undefined
                  }
                  isDisabled={isLoading}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={classes.buttonContainer}>
            <StyledButtonPrimary
              className={classes.submitButton}
              label={t('transferPersonnelPage.actions.submitButton')}
              disabled={!canSubmit() || isLoading}
              onClick={handleSubmit}
            />
          </Grid>
        </Grid>
      }
    />
  );
};

// TODO: RESTRICT PAGE TO ONLY SUPPORT ROLE

export default TransferPersonnelPage;
