import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';

import useDebounce from 'hooks/useDebounce';
import StyledAutoComplete from 'shared/AutoComplete';
import { getPersonnelLabel } from 'utils';
import { TRADE_PARTNER_PERSONNELS_ON_PROJECT_SEARCH } from 'graphql/tradePartnerPersonnel';

const TradePartnerPersonnelSearchSelect = ({
  projectNumber,
  selectedTradePartnerPersonnel,
  label,
  handleChange,
  customError,
  isLoading,
  isDisabled,
  ...rest
}) => {
  const { t } = useTranslation();
  const [, handleInputChange] = useState('');
  const [searchValue, setSearchValue] = useState('');

  const { debounced: debouncedHandleSearchChange } = useDebounce(
    setSearchValue
  );

  const handleSearchInputChange = value => {
    handleInputChange(value);
    debouncedHandleSearchChange(value);
  };

  const { data, loading: isLoadingForSearch } = useQuery(
    TRADE_PARTNER_PERSONNELS_ON_PROJECT_SEARCH,
    {
      skip: !searchValue || !projectNumber,
      variables: {
        search: searchValue,
        projectNumber: projectNumber
      },
      fetchPolicy: 'no-cache'
    }
  );

  const options =
    data?.paginatedTradePartnerPersonnelsOnProject?.tradePartnerPersonnels ??
    [];

  return (
    <StyledAutoComplete
      label={label}
      selectedValue={selectedTradePartnerPersonnel}
      handleInputChange={handleSearchInputChange}
      handleChange={handleChange}
      placeholder={t('personnelSearchSelect.placeholder')}
      options={options.map(option => ({
        label: getPersonnelLabel(option.personnel),
        value: option.personnel.id,
        ...option
      }))}
      isLoading={isLoading || isLoadingForSearch}
      isDisabled={isDisabled || isLoadingForSearch}
      customError={customError}
      {...rest}
    />
  );
};

TradePartnerPersonnelSearchSelect.propTypes = {
  projectNumber: PropTypes.string,
  selectedTradePartnerPersonnel: PropTypes.any,
  label: PropTypes.any.isRequired,
  handleChange: PropTypes.func,
  customError: PropTypes.string,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool
};

export default TradePartnerPersonnelSearchSelect;
