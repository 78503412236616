import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { confirmable } from 'react-confirm';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import StyledDialog from 'shared/Dialog';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import StyledButtonSecondary from 'shared/Buttons/ButtonSecondary';

const ConfirmDialog = ({
  show,
  cancel,
  confirmation,
  content,
  proceed,
  options
}) => {
  const { theme, title, rejectLabel, confirmLabel } = options;
  const { t } = useTranslation();

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <StyledDialog
          data-testid="confirm-dialog"
          isOpen={show}
          handleClose={cancel}
          disableBackdropClick={false}
          disableEscapeKeyDown={false}
          maxWidth="sm"
          title={title || t('confirmDialog.title')}
          contentGroupHeader={confirmation}
          content={content}
          actions={
            <Fragment>
              <StyledButtonSecondary onClick={cancel} label={rejectLabel} />
              <StyledButtonPrimary
                onClick={() => proceed(true)}
                label={confirmLabel}
              />
            </Fragment>
          }
        />
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

ConfirmDialog.propTypes = {
  show: PropTypes.bool,
  cancel: PropTypes.func,
  confirmation: PropTypes.string,
  content: PropTypes.any,
  proceed: PropTypes.func,
  options: PropTypes.object
};

export default confirmable(ConfirmDialog);
