import { LOCAL_STORAGE_AHA_FILES_ON_PROJECT_TEMPLATE_SORT_ORDER_KEY } from 'constants/localStorage';
import { atom, useRecoilState } from 'recoil';

const DEFAULT_ROWS_PER_PAGE = 5;
const DEFAULT_SORT_ORDER = {
  name: 'type',
  direction: 'ASC'
};
const getStoredSortOrder = () => {
  const unserializedSortOrder = localStorage.getItem(
    LOCAL_STORAGE_AHA_FILES_ON_PROJECT_TEMPLATE_SORT_ORDER_KEY
  );
  if (unserializedSortOrder) {
    return JSON.parse(unserializedSortOrder);
  } else {
    return null;
  }
};
const order = getStoredSortOrder() ?? DEFAULT_SORT_ORDER;

const defaultState = {
  first: DEFAULT_ROWS_PER_PAGE,
  order,
  search: '',
  skip: 0
};

const ahaGlobalTemplatesListStateAtom = atom({
  key: 'ahaGlobalTemplatesListState',
  default: defaultState
});

function useAhaGlobalTemplatesListState() {
  const [
    ahaGlobalTemplatesListState,
    setAhaGlobalTemplatesListState
  ] = useRecoilState(ahaGlobalTemplatesListStateAtom);

  function handleAhaGlobalTemplatesListStateChange(newState) {
    setAhaGlobalTemplatesListState({
      ...ahaGlobalTemplatesListState,
      ...newState
    });
  }
  return [
    ahaGlobalTemplatesListState,
    { handleAhaGlobalTemplatesListStateChange }
  ];
}

export default useAhaGlobalTemplatesListState;
