import React from 'react';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import makeStyles from '@mui/styles/makeStyles';
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';

const useStyles = makeStyles(theme => ({
  accordion: {
    boxShadow: 'none',
    '&:before': {
      backgroundColor: 'rgba(0,0,0,0) !important'
    }
  },
  accordionSummary: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(0, 1)
  }
}));

const StyledAccordion = ({ title, content }) => {
  const classes = useStyles();

  return (
    <Accordion className={classes.accordion}>
      <AccordionSummary
        className={classes.accordionSummary}
        expandIcon={<ExpandMoreIcon />}>
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>{content}</AccordionDetails>
    </Accordion>
  );
};

StyledAccordion.propTypes = {
  title: PropTypes.any.isRequired,
  content: PropTypes.any.isRequired
};

export default StyledAccordion;
