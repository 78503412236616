import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useForm, useField } from 'react-final-form-hooks';
import { useMutation } from '@apollo/react-hooks';
import EditIcon from '@mui/icons-material/Edit';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Card, Typography, LinearProgress, Chip } from '@mui/material';

import useToast from 'hooks/useToast';
import useFormValidation from 'hooks/useFormValidation';
import StyledInput from 'shared/Input';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import StyledButtonSecondary from 'shared/Buttons/ButtonSecondary';
import StyledIconButton from 'shared/Buttons/IconButton';
import TradeSelect from 'components/tradepartners/TradeSelect';
import CompanyInfoCard from 'components/company/CompanyInfoCard';
import { UPDATE_TRADE_PARTNER } from 'graphql/tradePartner';

const useStyles = makeStyles(theme => ({
  title: { fontWeight: 'bold' },
  labelText: { fontSize: '0.625rem' },
  buttonPrimary: { marginLeft: theme.spacing(1) },
  notesContainer: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
    borderRadius: 4
  }
}));

const TradePartnerInfoForm = ({ tradePartner }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isEditing, toggleEditing] = useState(false);
  const [tradesChanged, setTradesChanged] = useState(false);
  const [trades, setTrades] = useState(
    tradePartner.trades?.map(trade => ({
      label: trade,
      value: trade
    })) ?? []
  );

  const { displayToast } = useToast();
  const [updateTradePartner, { loading }] = useMutation(UPDATE_TRADE_PARTNER);

  const { form, values, pristine } = useForm({
    /* istanbul ignore next */
    onSubmit: () => {}, // this function required for useForm but is not used
    initialValues: {
      displayName: tradePartner.name ?? '',
      notes: tradePartner.notes ?? ''
    }
  });

  const { isRequired } = useFormValidation();
  const displayName = useField('displayName', form, isRequired);
  const notes = useField('notes', form);

  const tradesAreInvalid = (() => {
    return (
      trades === null || typeof trades === 'undefined' || trades.length <= 0
    );
  })();

  const handleTradesChange = trades => {
    setTradesChanged(true);
    setTrades(trades);
  };

  const canSubmit = (() => {
    const { error, invalid } = form.getState();
    const touched = !pristine || tradesChanged;
    return !(invalid || loading || error || tradesAreInvalid || !touched);
  })();

  const handleClose = () => {
    toggleEditing(false);
    setTradesChanged(false);
    form.reset();
  };

  const validateTpDisplayName = tpDisplayName => {
    let validatedTpDisplayName;
    if (
      tpDisplayName === null ||
      typeof tpDisplayName === 'undefined' ||
      tpDisplayName.trim() === '' ||
      tpDisplayName.length <= 0
    ) {
      validatedTpDisplayName = tradePartner.name;
    }
    return validatedTpDisplayName;
  };

  const onSubmit = () => {
    updateTradePartner({
      variables: {
        id: tradePartner.id,
        name:
          values.displayName.trim() === ''
            ? validateTpDisplayName(values.displayName)
            : values.displayName,
        trades: trades?.map(trade => trade.value),
        notes: values.notes
      }
    })
      .then(({ data: { updateTradePartner: tradePartner } }) => {
        handleClose();
        displayToast(
          t('tradePartnerPage.toasts.update.success', {
            tradePartnerName: tradePartner.name
          }),
          'success'
        );
      })
      .catch(error => {
        console.error('Update TradePartner Error: ', error);
        displayToast(t('tradePartnerPage.toasts.update.error'), 'error');
      });
  };

  return (
    <form>
      <Grid container direction="column" spacing={3}>
        {tradePartner?.company && (
          <Grid item xs={12}>
            <CompanyInfoCard company={tradePartner.company} />
          </Grid>
        )}
        <Grid item xs={12}>
          {loading && <LinearProgress color="primary" />}
          <Card className="padding">
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Typography gutterBottom className={classes.title}>
                      {t(
                        'tradePartnerPage.tradePartnerInfo.relatedDetails.title'
                      )}
                    </Typography>
                  </Grid>
                  {tradePartner.isActive && (
                    <Grid item>
                      {!isEditing && (
                        <StyledIconButton
                          data-testid="edit-iconbutton"
                          onClick={() => toggleEditing(true)}
                          disabled={loading}
                          className="noPadding">
                          <EditIcon />
                        </StyledIconButton>
                      )}
                      {isEditing && (
                        <Typography color="primary">
                          {t(
                            'tradePartnerPage.tradePartnerInfo.relatedDetails.isEditing'
                          )}
                        </Typography>
                      )}
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="column">
                  <Grid item xs={12}>
                    {!isEditing && (
                      <Fragment>
                        {tradePartner.trades?.length > 0 && (
                          <Grid container direction="column">
                            <Grid item>
                              <Typography>
                                {t(
                                  'tradePartnerPage.tradePartnerInfo.relatedDetails.trades'
                                )}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Grid container direction="row" spacing={1}>
                                {tradePartner.trades?.map(trade => (
                                  <Grid item key={trade}>
                                    <Chip
                                      size="small"
                                      color="primary"
                                      variant="outlined"
                                      label={trade}
                                    />
                                  </Grid>
                                ))}
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                        {tradePartner.trades?.length < 1 && (
                          <Typography gutterBottom>
                            {t(
                              'tradePartnerPage.tradePartnerInfo.relatedDetails.noTrades'
                            )}
                          </Typography>
                        )}
                      </Fragment>
                    )}
                    {isEditing && (
                      <Grid item xs={12}>
                        <TradeSelect
                          isDisabled={loading}
                          selectedTrades={trades}
                          handleChange={handleTradesChange}
                          meta={{
                            error: tradesAreInvalid ? 'Required' : undefined
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container>
                  <Grid item xs={12}>
                    {!isEditing && (
                      <Grid container direction="column">
                        <Grid item>
                          <Typography>
                            {t(
                              'tradePartnerPage.tradePartnerInfo.relatedDetails.displayName'
                            )}
                          </Typography>
                        </Grid>
                        <Grid item className="margin-bottom">
                          <Typography color="textSecondary">
                            {tradePartner.name}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                  {isEditing && (
                    <Grid item xs={12}>
                      <StyledInput
                        disabled={loading}
                        input={displayName.input}
                        meta={displayName.meta}
                        label={t(
                          'tradePartnerPage.tradePartnerInfo.relatedDetails.fields.displayName'
                        )}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="column">
                  <Grid item xs={12}>
                    {!isEditing && (
                      <Grid container direction="column">
                        <Grid item>
                          <Typography>
                            {t(
                              'tradePartnerPage.tradePartnerInfo.relatedDetails.notes'
                            )}
                          </Typography>
                        </Grid>
                        <Grid item className={classes.notesContainer}>
                          <Typography>
                            {tradePartner.notes
                              ? tradePartner.notes
                              : t(
                                  'tradePartnerPage.tradePartnerInfo.relatedDetails.noNotes'
                                )}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                  {isEditing && (
                    <Grid item xs={12}>
                      <StyledInput
                        multiline={true}
                        rows={5}
                        disabled={loading}
                        input={notes.input}
                        meta={notes.meta}
                        label={t(
                          'tradePartnerPage.tradePartnerInfo.relatedDetails.fields.notes'
                        )}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              {isEditing && (
                <Grid container justifyContent="flex-end">
                  <Grid item className="margin-top">
                    <StyledButtonSecondary
                      className={classes.button}
                      onClick={handleClose}
                      label={t(
                        'tradePartnerPage.tradePartnerInfo.actions.cancel'
                      )}
                      disabled={loading}
                    />
                    <StyledButtonPrimary
                      className={classes.buttonPrimary}
                      onClick={onSubmit}
                      label={t(
                        'tradePartnerPage.tradePartnerInfo.actions.submit'
                      )}
                      disabled={!canSubmit}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </form>
  );
};

TradePartnerInfoForm.propTypes = {
  tradePartner: PropTypes.object
};

export default TradePartnerInfoForm;
