import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import useIsOnline from 'store/onlineDetection';

const INTERVAL_IN_MS = 5000;

// eslint-disable-next-line react/prop-types
const NetworkStatusWrapper = ({ children }) => {
  const { isOnline, setIsOnline } = useIsOnline();

  const isSupported =
    navigator?.onLine !== undefined && navigator?.onLine !== null;

  const handleNetworkStatusUpdate = useCallback(async () => {
    const pingUrl = `www.google.com`;
    try {
      await fetch(`${pingUrl}?_t=${parseInt(Math.random() * 10000)}`, {
        mode: 'no-cors'
      });
      if (!isOnline) {
        setIsOnline(true);
      }
      return;
    } catch (error) {
      if (isOnline) {
        setIsOnline(false);
      }
    }
  }, [isOnline, setIsOnline]);

  const handleConnectionChange = useCallback(() => {
    if (navigator.onLine) {
      // Confirm connections status to rule out false positives
      const intervalId = setInterval(() => {
        handleNetworkStatusUpdate().then(() => {
          return clearInterval(intervalId);
        });
      }, 2000);
      return;
    }

    return setIsOnline(false);
  }, [handleNetworkStatusUpdate, setIsOnline]);

  useEffect(() => {
    if (isSupported) {
      window.addEventListener('online', handleConnectionChange);
      window.addEventListener('offline', handleConnectionChange);

      return () => {
        window.removeEventListener('online', handleConnectionChange);
        window.removeEventListener('offline', handleConnectionChange);
      };
    } else {
      // Setup polling if browser does not support navigator.online
      const intervalId = setInterval(handleNetworkStatusUpdate, INTERVAL_IN_MS);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [handleConnectionChange, handleNetworkStatusUpdate, isSupported]);

  return <>{children}</>;
};

NetworkStatusWrapper.propTypes = {
  children: PropTypes.any
};

export default NetworkStatusWrapper;
