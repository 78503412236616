import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Card, Typography, IconButton } from '@mui/material';

import MarkdownRenderer from 'shared/MarkdownRenderer';

const useStyles = makeStyles(theme => ({
  card: { borderRadius: 0, boxShadow: 'none' },
  section: {
    border: '1px solid' + theme.borderGray,
    padding: theme.spacing(0, 1),
    [theme.breakpoints.down('xl')]: { padding: theme.spacing(0.5) }
  },
  name: {
    fontWeight: 'bold',
    display: 'inline',
    verticalAlign: '25%',
    [theme.breakpoints.down('xl')]: { fontSize: '0.875rem' }
  },
  sectionText: {
    [theme.breakpoints.down('xl')]: { fontSize: '0.875rem' }
  },
  racScoreContainer: {
    height: '100%',
    border: '1px solid' + theme.borderGray,
    fontWeight: 'bold'
  },
  racScore: { fontWeight: 'bold' },
  extremelyHighRiskBg: {
    backgroundColor: theme.palette.ahaRacMatrix.extremelyHighRisk
  },
  highRiskBg: {
    backgroundColor: theme.palette.ahaRacMatrix.highRisk
  },
  moderateRiskBg: {
    backgroundColor: theme.palette.ahaRacMatrix.moderateRisk
  },
  lowRiskBg: {
    backgroundColor: theme.palette.ahaRacMatrix.lowRisk
  },
  extremelyHighRiskLabel: {
    color: theme.palette.ahaRacMatrix.extremelyHighRiskLabel
  },
  highRiskLabel: {
    color: theme.palette.ahaRacMatrix.highRiskLabel
  },
  moderateRiskLabel: {
    color: theme.palette.ahaRacMatrix.moderateRiskLabel
  },
  lowRiskLabel: {
    color: theme.palette.ahaRacMatrix.lowRiskLabel
  }
}));

const AhaDetailedStepCard = ({
  step,
  setIsEditStepDialogOpen,
  setIsConfirmStepDeleteDialogOpen,
  setSelectedStep,
  isReadOnlyReview
}) => {
  const classes = useStyles();

  const onEditStepClick = step => {
    setSelectedStep(step);
    setIsEditStepDialogOpen(true);
  };

  const onDeleteStepClick = step => {
    setSelectedStep(step);
    setIsConfirmStepDeleteDialogOpen(true);
  };

  const getRiskAssessmentCode = () => {
    if (step) {
      if (step.riskAssessmentCode === 'ExtremelyHigh') {
        return 'E';
      }

      if (step.riskAssessmentCode === 'High') {
        return 'H';
      }

      if (step.riskAssessmentCode === 'Moderate') {
        return 'M';
      }

      if (step.riskAssessmentCode === 'Low') {
        return 'L';
      }
    }
  };

  const score = getRiskAssessmentCode();

  return (
    <Card key={step.id} className={classes.card}>
      <Grid container>
        <Grid item xs={12} sm={4} md={2} lg={2} className={classes.section}>
          {!isReadOnlyReview && <DragIndicatorIcon />}
          <Typography className={classes.name}>{step.name}</Typography>
        </Grid>
        <Grid item xs={12} sm={8} md={2} lg={2} className={classes.section}>
          <MarkdownRenderer markdownString={step.hazards} />
        </Grid>
        <Grid item xs={12} sm={12} md={7} lg={7} className={classes.section}>
          <Grid container direction="row">
            <Grid item xs={12} sm={10} md={10} lg={10}>
              <MarkdownRenderer markdownString={step.controls} />
              <Typography className={classes.sectionText} variant="overline">
                {step.ahaStepReferences
                  .map(reference => {
                    return `${reference.ahaReferenceOption}: ${reference.value}`;
                  })
                  .join(', ')}
              </Typography>
            </Grid>
            {!isReadOnlyReview && (
              <Grid item xs={12} sm={2} md={2} lg={2}>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <IconButton
                      size="small"
                      onClick={() => onEditStepClick(step)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => onDeleteStepClick(step)}>
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={1} lg={1}>
          <Grid
            container
            justifyContent="center"
            alignContent="center"
            className={classnames(classes.racScoreContainer, {
              [`${classes.extremelyHighRiskBg}`]:
                step.riskAssessmentCode === 'ExtremelyHigh',
              [`${classes.highRiskBg}`]: step.riskAssessmentCode === 'High',
              [`${classes.moderateRiskBg}`]:
                step.riskAssessmentCode === 'Moderate',
              [`${classes.lowRiskBg}`]: step.riskAssessmentCode === 'Low'
            })}>
            <Grid item>
              <Typography
                className={classnames(classes.racScore, {
                  [`${classes.extremelyHighRiskLabel}`]:
                    step.riskAssessmentCode === 'ExtremelyHigh',
                  [`${classes.highRiskLabel}`]:
                    step.riskAssessmentCode === 'High',
                  [`${classes.moderateRiskLabel}`]:
                    step.riskAssessmentCode === 'Moderate',
                  [`${classes.lowRiskLabel}`]: step.riskAssessmentCode === 'Low'
                })}>
                {score}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

AhaDetailedStepCard.propTypes = {
  step: PropTypes.object,
  setIsEditStepDialogOpen: PropTypes.func,
  setIsConfirmStepDeleteDialogOpen: PropTypes.func,
  setSelectedStep: PropTypes.func,
  isReadOnlyReview: PropTypes.bool
};

export default AhaDetailedStepCard;
