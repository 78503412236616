import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography } from '@mui/material';

import useToast from 'hooks/useToast';
import StyledDialog from 'shared/Dialog';
import StyledNotice from 'shared/Notice';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import StyledButtonSecondary from 'shared/Buttons/ButtonSecondary';
import AdUserSelect from 'components/ad/AdUserSelect';
import ScopeIndicator from 'components/ahas/AhaScopeIndicator';
import { UPDATE_PROJECT_TEMPLATE } from 'graphql/aha/projectTemplate';
import { GET_AHA_TEMPLATE } from 'graphql/aha/ahaTemplate';

const useStyles = makeStyles(theme => ({
  instructions: { marginBottom: theme.spacing(3) },
  label: { fontSize: '0.75rem', fontWeight: 'bold' }
}));

const TransferOwnershipDialog = ({
  transferOwnershipDialogIsOpen,
  toggleTransferOwnershipDialog,
  ahaTemplate
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { ahaId } = useParams();
  const { displayToast } = useToast();
  const [selectedAdUser, setSelectedAdUser] = useState(null);

  const currentOwner = ahaTemplate?.ahaProjectTemplate?.ownedBy?.name;

  const [updateAhaProjectTemplate, { loading: isLoading }] = useMutation(
    UPDATE_PROJECT_TEMPLATE,
    {
      refetchQueries: [{ query: GET_AHA_TEMPLATE, variables: { id: ahaId } }],
      awaitRefetchQueries: true
    }
  );

  const canSubmit = () => {
    return selectedAdUser;
  };

  const handleSubmit = () => {
    updateAhaProjectTemplate({
      variables: {
        input: {
          id: ahaTemplate?.ahaProjectTemplate?.id,
          ownedByUpn: selectedAdUser?.upn
        }
      }
    })
      .then(() => {
        displayToast(
          t('transferOwnershipDialog.toasts.success', {
            name: selectedAdUser?.name
          }),
          'success'
        );
        handleClose();
      })
      .catch(error => {
        console.error('Update Project Template Error', 'error');
        displayToast(t('transferOwnershipDialog.toasts.error'), 'error');
      });
  };

  const handleClose = () => {
    setSelectedAdUser(null);
    toggleTransferOwnershipDialog(false);
  };

  return (
    <StyledDialog
      maxWidth="sm"
      title={t('transferOwnershipDialog.title')}
      isOpen={transferOwnershipDialogIsOpen}
      handleClose={handleClose}
      isLoading={isLoading}
      content={
        <Grid container>
          <Grid item xs={12}>
            <ScopeIndicator isGlobal={false} />
          </Grid>
          <Grid item xs={12}>
            <StyledNotice message={t('transferOwnershipDialog.notice')} />
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.instructions}>
              {t('transferOwnershipDialog.instructions')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              className={classes.label}
              color="textSecondary"
              gutterBottom>
              Current Owner: {currentOwner}
            </Typography>
            <Typography className={classes.label} color="textSecondary">
              {t('transferOwnershipDialog.selectLabel')}
            </Typography>
            <AdUserSelect
              selectedUser={selectedAdUser}
              handleChange={setSelectedAdUser}
              isDisabled={isLoading}
              placeholder={
                <Typography>
                  {t('transferOwnershipDialog.selectPlaceholder')}
                </Typography>
              }
              isRequired={true}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null
              }}
              noOptionsMessage={() => null}
              loadingMessage={() => null}
              autoFocus={true}
            />
          </Grid>
        </Grid>
      }
      actions={
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <StyledButtonSecondary
                  label={t('transferOwnershipDialog.actions.cancelButton')}
                  disabled={isLoading}
                  onClick={handleClose}
                />
              </Grid>
              <Grid item>
                <StyledButtonPrimary
                  label={t('transferOwnershipDialog.actions.submitButton')}
                  disabled={!canSubmit() || isLoading}
                  onClick={handleSubmit}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
    />
  );
};

TransferOwnershipDialog.propTypes = {
  transferOwnershipDialogIsOpen: PropTypes.bool,
  toggleTransferOwnershipDialog: PropTypes.func,
  isGlobal: PropTypes.bool,
  ahaTemplate: PropTypes.object
};

export default TransferOwnershipDialog;
