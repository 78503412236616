export const ACHEIVEMENT = 'Achievement';
const RESOLVED = 'Resolved';
const HIGH = 'High';

export const FLAG_STATUS_OPTIONS = [
  { label: 'Attention Required', value: HIGH },
  { label: ACHEIVEMENT, value: ACHEIVEMENT },
  { label: RESOLVED, value: RESOLVED },
  { label: 'To Improve', value: 'ToImprove' }
];
