import { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';

import useTradePartnerAhaListState from 'store/tradePartnerAhaListState';
import { GET_PAGINATED_AHA_TRADE_PARTNER_CATEGORIES } from 'graphql/aha/tradePartnerCategory';
import useDebounce from 'hooks/useDebounce';
import {
  TRADE_PARTNER_SORT_ORDER_OPTIONS,
  TRADE_PARTNER_ISACTIVE_FILTER_OPTIONS,
  FILTER_API_KEYS,
  SORT_ORDER_DIRECTION
} from 'constants/ahas';

const useAhaTradePartnerTemplateCategories = (
  projectId,
  shouldSkip = false
) => {
  const [
    tradePartnerAhaListState,
    { handleTradePartnerAhaListStateChange }
  ] = useTradePartnerAhaListState(projectId);
  const [initialTradePartnerAhaListState] = useState(tradePartnerAhaListState);

  const [activeFilters, setActiveFilters] = useState({
    active:
      tradePartnerAhaListState.filter?.tradePartner_isActive ===
      TRADE_PARTNER_ISACTIVE_FILTER_OPTIONS.active.value,
    inactive:
      tradePartnerAhaListState.filter?.tradePartner_isActive ===
      TRADE_PARTNER_ISACTIVE_FILTER_OPTIONS.inactive.value,
    allStatus:
      tradePartnerAhaListState.filter?.tradePartner_isActive ===
      TRADE_PARTNER_ISACTIVE_FILTER_OPTIONS.allStatus.value,
    projectArea: tradePartnerAhaListState.filter?.ahaFiles_projectArea || null
  });

  const {
    data: { paginatedAhaTradePartnerCategories = null } = {},
    loading,
    fetchMore
  } = useQuery(GET_PAGINATED_AHA_TRADE_PARTNER_CATEGORIES, {
    skip: shouldSkip,
    variables: {
      filter: getApiFilter(initialTradePartnerAhaListState.filter),
      first: initialTradePartnerAhaListState.first,
      order: getApiSortOrder(initialTradePartnerAhaListState.order),
      projectId,
      search: initialTradePartnerAhaListState.search,
      skip: 0
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  });

  const total = paginatedAhaTradePartnerCategories?.total ?? 0;

  const resetUpdateQuery = (
    _previousResult,
    { fetchMoreResult, _queryVariables }
  ) => {
    return fetchMoreResult;
  };

  const loadMore = (variables, shouldReset = false) => {
    return fetchMore({
      variables,
      updateQuery: shouldReset
        ? resetUpdateQuery
        : (previousResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return previousResult;
            return Object.assign({}, previousResult, {
              paginatedAhaTradePartnerCategories: {
                ...previousResult.paginatedAhaTradePartnerCategories,
                ...fetchMoreResult.paginatedAhaTradePartnerCategories,
                total: fetchMoreResult.paginatedAhaTradePartnerCategories.total,
                ahaTradePartnerCategories: [
                  ...previousResult.paginatedAhaTradePartnerCategories
                    .ahaTradePartnerCategories,
                  ...fetchMoreResult.paginatedAhaTradePartnerCategories
                    .ahaTradePartnerCategories
                ]
              }
            });
          }
    });
  };

  const handleDebouncedSearchChange = ({ search, state, handleChange }) => {
    handleChange({
      ...state,
      search,
      skip: 0
    });

    const variables = {
      first: state.first,
      skip: 0,
      filter: getApiFilter(state.filter),
      order: getApiSortOrder(state.order),
      search
    };

    loadMore(variables, true);
  };

  const { debounced: debouncedHandleSearchChange } = useDebounce(
    handleDebouncedSearchChange
  );

  const toggleSortDirection = () => {
    let newDirection;

    if (
      tradePartnerAhaListState?.order?.direction ===
      SORT_ORDER_DIRECTION.ascending
    ) {
      newDirection = SORT_ORDER_DIRECTION.descending;
    } else {
      newDirection = SORT_ORDER_DIRECTION.ascending;
    }

    const newOrder = {
      ...tradePartnerAhaListState.order,
      direction: newDirection
    };

    handleTradePartnerAhaListStateChange({
      ...tradePartnerAhaListState,
      order: newOrder
    });

    const variables = {
      first: tradePartnerAhaListState.first,
      skip: 0,
      search: tradePartnerAhaListState.search,
      filter: getApiFilter(tradePartnerAhaListState.filter),
      order: getApiSortOrder(newOrder)
    };

    loadMore(variables, true);
  };

  const handleSortChange = event => {
    const sortByValue = event.target.value;

    const newOrder = {
      ...tradePartnerAhaListState.order,
      label: getSortByLabel(sortByValue),
      value: sortByValue
    };

    handleTradePartnerAhaListStateChange({
      ...tradePartnerAhaListState,
      order: newOrder
    });

    const variables = {
      first: tradePartnerAhaListState.first,
      skip: 0,
      search: tradePartnerAhaListState.search,
      filter: getApiFilter(tradePartnerAhaListState.filter),
      order: getApiSortOrder(newOrder)
    };

    loadMore(variables, true);
  };

  const handleFilterChange = (filterValue, filterName) => {
    let newFilter = {};

    if (filterName === 'isActive') {
      switch (filterValue) {
        case TRADE_PARTNER_ISACTIVE_FILTER_OPTIONS.active.value: {
          const filterIsActive = !activeFilters.active;
          newFilter = {
            [TRADE_PARTNER_ISACTIVE_FILTER_OPTIONS.active
              .apiKey]: filterIsActive
              ? TRADE_PARTNER_ISACTIVE_FILTER_OPTIONS.active.value
              : undefined
          };
          setActiveFilters({
            ...activeFilters,
            active: filterIsActive,
            inactive: false,
            allStatus: false
          });

          break;
        }

        case TRADE_PARTNER_ISACTIVE_FILTER_OPTIONS.inactive.value: {
          const filterIsActive = !activeFilters.inactive;
          newFilter = {
            [TRADE_PARTNER_ISACTIVE_FILTER_OPTIONS.inactive
              .apiKey]: filterIsActive
              ? TRADE_PARTNER_ISACTIVE_FILTER_OPTIONS.inactive.value
              : undefined
          };
          setActiveFilters({
            ...activeFilters,
            active: false,
            inactive: filterIsActive,
            allStatus: false
          });

          break;
        }

        case TRADE_PARTNER_ISACTIVE_FILTER_OPTIONS.allStatus.value: {
          const filterIsActive = !activeFilters.allStatus;
          newFilter = {
            [TRADE_PARTNER_ISACTIVE_FILTER_OPTIONS.allStatus
              .apiKey]: filterIsActive
              ? TRADE_PARTNER_ISACTIVE_FILTER_OPTIONS.allStatus.value
              : undefined
          };
          setActiveFilters({
            ...activeFilters,
            active: false,
            inactive: false,
            allStatus: filterIsActive
          });

          break;
        }

        default:
          break;
      }
    } else if (filterName === 'projectArea') {
      newFilter = { [FILTER_API_KEYS.ahaFiles_projectArea]: filterValue };
      setActiveFilters({
        ...activeFilters,
        projectArea: filterValue
      });
    }

    const newFilterState = {
      ...tradePartnerAhaListState.filter,
      ...newFilter
    };

    handleTradePartnerAhaListStateChange({
      ...tradePartnerAhaListState,
      filter: newFilterState
    });

    const variables = {
      first: tradePartnerAhaListState.first,
      skip: 0,
      search: tradePartnerAhaListState.search,
      filter: getApiFilter(newFilterState),
      order: getApiSortOrder(tradePartnerAhaListState.order)
    };

    loadMore(variables, true);
  };

  const refetch = () => {
    const variables = {
      first: tradePartnerAhaListState.first,
      skip: tradePartnerAhaListState.skip,
      search: tradePartnerAhaListState.search,
      filter: getApiFilter(tradePartnerAhaListState.filter),
      order: getApiSortOrder(tradePartnerAhaListState.order)
    };
    return loadMore(variables, true);
  };

  return {
    tradePartnerTemplateCategories:
      paginatedAhaTradePartnerCategories?.ahaTradePartnerCategories,
    total,
    loading,
    loadMore,
    handleSearch: debouncedHandleSearchChange,
    handleSortChange,
    toggleSortDirection,
    handleFilterChange,
    refetch
  };
};

function getApiFilter(filterState) {
  const filter = {};

  for (const [key, value] of Object.entries(filterState)) {
    switch (key) {
      case FILTER_API_KEYS.tradePartner_isActive:
        if (value === TRADE_PARTNER_ISACTIVE_FILTER_OPTIONS.active.value) {
          filter.tradePartner_isActive = true;
        } else if (
          value === TRADE_PARTNER_ISACTIVE_FILTER_OPTIONS.inactive.value
        ) {
          filter.tradePartner_isActive = false;
        } else if (
          value === TRADE_PARTNER_ISACTIVE_FILTER_OPTIONS.allStatus.value
        ) {
          filter.tradePartner_isActive = undefined;
        }
        break;
      case FILTER_API_KEYS.ahaFiles_projectArea:
        filter.ahaFiles_projectArea = value || undefined;
        break;
      default:
        break;
    }
  }

  return filter;
}

function getApiSortOrder(sortState) {
  return sortState.value && sortState.direction
    ? [
        {
          [sortState.value]: sortState.direction.toUpperCase()
        }
      ]
    : undefined;
}

function getSortByLabel(value) {
  const sortBy = Object.values(TRADE_PARTNER_SORT_ORDER_OPTIONS).find(
    option => option.value === value
  );

  return sortBy?.label ?? '';
}

export default useAhaTradePartnerTemplateCategories;
