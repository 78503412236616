import { atom, useRecoilState } from 'recoil';

import { DEFAULT_ROWS_PER_PAGE } from 'constants/listPageTable';

const DEFAULT_SORT_ORDER = {
  name: 'name',
  direction: 'asc'
};

const defaultState = {
  first: DEFAULT_ROWS_PER_PAGE,
  skip: 0,
  page: 0,
  filter: {},
  filterList: [],
  order: DEFAULT_SORT_ORDER,
  search: null
};

const tradePartnerListPageTableState = atom({
  key: 'tradePartnerListPageTableState',
  default: defaultState
});

function useTradePartnerListPageTableState() {
  const [tableState, setTableState] = useRecoilState(
    tradePartnerListPageTableState
  );

  function handleTableStateChange(newTableState) {
    setTableState({ ...tableState, ...newTableState });
  }
  return [tableState, { handleTableStateChange }];
}

export default useTradePartnerListPageTableState;
