/* eslint-disable react/display-name */
import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet-async';
import { REACT_APP_ENV } from 'utils';

function withDocumentTitle(WrappedComponent) {
  return props => {
    const title =
      REACT_APP_ENV === 'beta' ? 'Safety Connect (Beta)' : 'Safety Connect';
    return (
      <Fragment>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <WrappedComponent {...props} />
      </Fragment>
    );
  };
}

export default withDocumentTitle;
