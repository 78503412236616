import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ErrorIcon from '@mui/icons-material/Error';
import makeStyles from '@mui/styles/makeStyles';
import { Typography } from '@mui/material';

const useStyles = makeStyles(theme => ({
  fullSizePreview: {
    display: 'flex',
    justifyContent: 'center'
  },
  wordIcon: {
    width: '50%',
    height: '50%'
  },
  placeholderContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%'
  },
  imagePlaceholderText: {
    alignSelf: 'center',
    fontWeight: 'bold',
    marginTop: 2,
    [theme.breakpoints.down('lg')]: {
      textAlign: 'center'
    }
  }
}));

const WordDocViewer = ({ file }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <div className={classes.fullSizePreview}>
        {!file.cachedUrl && !file.isMalware && (
          <div className={classes.placeholderContainer}>
            <Typography className={classes.imagePlaceholderText}>
              {t('personnelTrainingDialog.readOnly.imagePlaceholderText')}
            </Typography>
          </div>
        )}

        {file.isMalware && (
          <div className={classes.placeholderContainer}>
            <ErrorIcon
              className={classes.wordIcon}
              data-testid="malware-icon-word-doc-view"
            />{' '}
            <Typography className={classes.imagePlaceholderText}>
              {t('personnelTrainingDialog.readOnly.isMalware')}
            </Typography>
          </div>
        )}

        {file.cachedUrl && !file.isMalware && (
          <div data-testid="word-doc-viewer">
            <Typography className={classes.imagePlaceholderText}>
              {t('personnelTrainingDialog.readOnly.imageTypeNotPreviewable', {
                documentType: 'Microsoft Word documents'
              })}
            </Typography>
          </div>
        )}
      </div>
    </>
  );
};

WordDocViewer.propTypes = {
  file: PropTypes.any
};

export default WordDocViewer;
