import React from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography } from '@mui/material';

import StyledNotice from 'shared/Notice';

const useStyles = makeStyles(theme => ({
  subTitle: { fontSize: '0.875rem' },
  title: { fontSize: '1.5rem' }
}));

const EnableOfflineMode = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography className={classes.subTitle} color="textSecondary">
          {t('tutorials.settings.pageTitle')}
        </Typography>
        <Typography className={classes.title} color="textPrimary">
          {t('tutorials.settings.enableOfflineMode.menuTitle')}
        </Typography>
      </Grid>
      <Grid item>
        <StyledNotice
          message={t('tutorials.settings.enableOfflineMode.performanceNotice')}
        />
      </Grid>
      <Grid item>
        <Typography color="textPrimary" className="margin-bottom">
          {t('tutorials.settings.enableOfflineMode.description')}
        </Typography>
        <Typography color="textPrimary">
          {t('tutorials.settings.enableOfflineMode.instructions')}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default EnableOfflineMode;
