/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AddIcon from '@mui/icons-material/Add';

import StyledTable from 'shared/Table';
import StyledButtonMuted from 'shared/Buttons/ButtonMuted';
import AttendeeTrainingDialog from '../AttendeeTrainingDialog';
import TrainingDialogViews from 'constants/trainings';
import useStringIncludesHenselPhelps from 'hooks/useStringIncludesHenselPhelps';
import StyledTableCellContent from 'components/common/StyledTableCellContent';
import {
  getStatusClassName,
  getColumnFormattedTrainingData,
  openTrainingDialog,
  closeTrainingDialog,
  getSortedTrainingStatuses,
  getSortedDates
} from 'utils/trainingTables';

const useStyles = makeStyles(theme => ({
  statusContainer: { display: 'flex' },
  expiredRow: {
    backgroundColor: theme.palette.training.expired,
    fontSize: '0.875rem'
  },
  expiringSoon: {
    backgroundColor: theme.palette.training.expiringSoon,
    fontSize: '0.875rem'
  },
  warningIcon: { 'padding-left': 5, 'font-size': '1.2rem' },
  tableText: { fontSize: '0.875rem' },
  addNewTrainingButton: {
    '@media (max-width: 960px)': {
      marginBottom: 7
    }
  }
}));

const AttendeeTrainingList = ({ attendee, tableType }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { stringIncludesHenselPhelps } = useStringIncludesHenselPhelps();
  const [trainingDialogIsOpen, toggleTrainingDialog] = useState(false);
  const [individualTrainingData, setIndividualTrainingData] = useState(null);
  const [trainingDialogView, setTrainingDialogView] = useState();
  const [historicalTrainings, setHistoricalTrainings] = useState([]);
  const [newTrainings, setNewTrainings] = useState([]);

  const { READ_ONLY, ADD_TRAINING } = TrainingDialogViews;
  const isHenselPhelpsEmployee = stringIncludesHenselPhelps(
    attendee.tradePartner.name
  );
  const attendeeIsApproved = attendee?.status === 'Approved';

  const FORMATTED_TRAINING_NAMES = {
    boomLift: t('personnelTrainingDialog.formattedTrainingNames.boomLift'),
    confinedSpace: t(
      'personnelTrainingDialog.formattedTrainingNames.confinedSpace'
    ),
    fallProtection: t(
      'personnelTrainingDialog.formattedTrainingNames.fallProtection'
    ),
    scissorLift: t(
      'personnelTrainingDialog.formattedTrainingNames.scissorLift'
    ),
    forkLift: t('personnelTrainingDialog.formattedTrainingNames.forkLift')
  };

  const TRAINING_STATUSES = {
    expired: t('attendeeTrainingList.expired'),
    expiringSoon: t('attendeeTrainingList.expiringSoon'),
    current: t('attendeeTrainingList.current')
  };

  useEffect(() => {
    (() => {
      if (attendee.personnel?.trainings.length > 0) {
        setHistoricalTrainings([...attendee.personnel.trainings]);
      } else {
        setHistoricalTrainings([]);
      }

      if (attendee.trainings.length > 0) {
        setNewTrainings([...attendee.trainings]);
      } else {
        setNewTrainings([]);
      }
    })();
  }, [attendee.personnel, attendee.trainings, attendee]);

  const columns = [
    {
      name: 'id',
      label: t('attendeeTrainingList.id'),
      options: {
        filter: false,
        display: 'excluded'
      }
    },
    {
      name: 'displayName',
      label: t('attendeeTrainingList.name'),
      options: {
        filter: false,
        searchable: true,
        sort: true,
        sortOrder: 'asc',
        customBodyRender: value => {
          return <StyledTableCellContent value={value} />;
        }
      }
    },
    {
      name: 'otherName',
      label: 'otherName',
      options: {
        filter: false,
        display: 'excluded'
      }
    },
    {
      name: 'awardedDate',
      label: t('attendeeTrainingList.awardedDate'),
      options: {
        filter: false,
        searchable: true,
        sort: true,
        sortCompare: order => {
          return (object1, object2) => {
            return getSortedDates(object1, object2, order);
          };
        },
        customBodyRender: value => (
          <>
            <div>{value}</div>
          </>
        )
      }
    },
    {
      name: 'expirationDate',
      label: t('attendeeTrainingList.expirationDate'),
      options: {
        filter: false,
        searchable: true,
        sort: true,
        sortCompare: order => {
          return (object1, object2) => {
            return getSortedDates(object1, object2, order);
          };
        },
        customBodyRender: value => {
          const expirationDate =
            value === null
              ? t('attendeeTrainingDialog.trainingForm.neverExpires')
              : value;

          return (
            <>
              <div>{expirationDate}</div>
            </>
          );
        }
      }
    },
    {
      name: 'files',
      label: t('attendeeTrainingList.files'),
      options: {
        filter: false,
        searchable: false,
        sort: false,
        customBodyRender: value => (
          <> {value && value.length > 0 ? <div>{value?.length}</div> : null}</>
        )
      }
    },
    {
      name: 'notes',
      label: t('attendeeTrainingList.notes'),
      options: {
        filter: false,
        display: 'excluded'
      }
    },
    {
      name: 'files',
      label: 'FileObjects',
      options: {
        filter: false,
        display: 'excluded'
      }
    },
    {
      name: 'status',
      label: t('attendeeTrainingList.status'),
      options: {
        filter: true,
        searchable: false,
        sort: true,
        sortCompare: order => {
          return (object1, object2) => {
            return getSortedTrainingStatuses(
              object1,
              object2,
              order,
              TRAINING_STATUSES
            );
          };
        },
        customBodyRender: value => {
          return (
            <>
              {value.isExpiringSoon && (
                <Tooltip
                  data-testid="expirationCount"
                  title={
                    value.numberOfDaysUntilExpiration > 1
                      ? `${value.numberOfDaysUntilExpiration} ${t(
                          'attendeeTrainingList.daysUntilExpiration'
                        )}`
                      : `${value.numberOfDaysUntilExpiration} ${t(
                          'attendeeTrainingList.dayUntilExpiration'
                        )}`
                  }>
                  <div className={classes.statusContainer}>
                    <Typography className={classes.tableText}>
                      {t('attendeeTrainingList.expiringSoon')}
                    </Typography>
                  </div>
                </Tooltip>
              )}

              <div className={classes.statusContainer}>
                {' '}
                <Typography className={classes.tableText}>
                  {value.isExpired && t('attendeeTrainingList.expired')}
                  {!value.isExpired &&
                    !value.isExpiringSoon &&
                    t('attendeeTrainingList.current')}
                </Typography>{' '}
              </div>
            </>
          );
        },
        filterOptions: {
          names: [
            t('attendeeTrainingList.expired'),
            t('attendeeTrainingList.expiringSoon'),
            t('attendeeTrainingList.current')
          ],
          logic: (status, filters) => {
            if (filters.length) {
              if (status.isExpired) {
                return !filters.includes(t('attendeeTrainingList.expired'));
              } else if (status.isExpiringSoon) {
                return !filters.includes(
                  t('attendeeTrainingList.expiringSoon')
                );
              } else if (!status.isExpired && !status.isExpiringSoon) {
                return !filters.includes(t('attendeeTrainingList.current'));
              }
            }

            return false;
          }
        }
      }
    },
    {
      name: 'name',
      label: 'nameField',
      options: {
        filter: false,
        display: 'excluded'
      }
    }
  ];

  const newTrainingsTableOptions = {
    filterType: 'multiselect',
    onRowClick: rowData =>
      openTrainingDialog(
        rowData,
        READ_ONLY,
        setIndividualTrainingData,
        setTrainingDialogView,
        toggleTrainingDialog
      ),
    customToolbar: () => {
      if (!isHenselPhelpsEmployee && !attendeeIsApproved) {
        return (
          <StyledButtonMuted
            className={classes.addNewTrainingButton}
            startIcon={<AddIcon color="action" />}
            label={t('attendeeTrainingList.addNewTrainingButton')}
            onClick={() => {
              openTrainingDialog(
                [],
                ADD_TRAINING,
                setIndividualTrainingData,
                setTrainingDialogView,
                toggleTrainingDialog
              );
            }}
          />
        );
      }
    },
    setRowProps: row => {
      const [, , , , expirationDate, , ,] = row;
      return {
        className: getStatusClassName(
          expirationDate.props.children.props.children,
          classes
        )
      };
    },
    sortOrder: {
      name: 'name',
      direction: 'asc'
    }
  };

  const historicalTrainingsTableOptions = {
    filterType: 'multiselect',
    onRowClick: rowData =>
      openTrainingDialog(
        rowData,
        READ_ONLY,
        setIndividualTrainingData,
        setTrainingDialogView,
        toggleTrainingDialog
      ),
    setRowProps: (row, dataIndex, rowIndex) => {
      const [, , , , expirationDate, , ,] = row;
      return {
        className: getStatusClassName(
          expirationDate.props.children.props.children,
          classes
        )
      };
    },
    sortOrder: {
      name: 'name',
      direction: 'asc'
    }
  };

  return (
    <>
      <StyledTable
        title={
          tableType === 'new'
            ? t('orientationAttendeePage.newTrainingsTableTile')
            : t('orientationAttendeePage.historicalTrainingsTableTitle')
        }
        data={
          tableType === 'new'
            ? getColumnFormattedTrainingData(
                newTrainings,
                FORMATTED_TRAINING_NAMES
              )
            : getColumnFormattedTrainingData(
                historicalTrainings,
                FORMATTED_TRAINING_NAMES
              )
        }
        columns={columns}
        options={
          tableType === 'new'
            ? newTrainingsTableOptions
            : historicalTrainingsTableOptions
        }
      />
      {trainingDialogIsOpen && (
        <AttendeeTrainingDialog
          isOpen={trainingDialogIsOpen}
          handleClose={() =>
            closeTrainingDialog(toggleTrainingDialog, setTrainingDialogView)
          }
          existingTrainingData={individualTrainingData}
          trainingDialogView={trainingDialogView}
          setTrainingDialogView={setTrainingDialogView}
          attendee={attendee}
          isHenselPhelpsEmployee={isHenselPhelpsEmployee}
          tableType={tableType}
        />
      )}
    </>
  );
};

AttendeeTrainingList.propTypes = {
  attendee: PropTypes.object,
  tableType: PropTypes.string.isRequired
};

export default AttendeeTrainingList;
