import gql from 'graphql-tag';

// Subscriptions
export const COMPANY_SUBSCRIPTION = gql`
  subscription company($companyId: ID!) {
    company(id: $companyId) {
      id
      name
      notes
      districts
      activeProjectCount
      trades
      location {
        display
        streetAndNumber
        streetDetail
        city
        stateOrProvince
        zipOrPostalCode
        country
        cityAndStateDisplay
      }
      contactInformation {
        website
        phoneNumbers {
          number
          display
          extension
          type
        }
        primaryPhone {
          number
          display
          extension
          type
        }
        secondaryPhone {
          number
          display
          extension
          type
        }
        faxPhone {
          number
          display
          extension
          type
        }
        emailAddress {
          email
          type
        }
      }
      tradePartners {
        id
        name
        notes
        trades
        isActive
        workerHoursMonthToDate
        workerHoursYearToDate
        workerHoursTotalToDate
        project {
          id
          name
          number
          description
          districtNumber
          isActive
          notes
          location {
            city
            stateOrProvince
            cityAndStateDisplay
          }
        }
      }
    }
  }
`;

// Queries
export const PAGINATED_COMPANIES = gql`
  query PaginatedCompanies(
    $filter: CompanysFilterInput
    $first: Int!
    $order: [CompanysOrderInput!]
    $search: String
    $skip: Int
  ) {
    paginatedCompanys(
      filter: $filter
      first: $first
      order: $order
      search: $search
      skip: $skip
    ) {
      total
      companys {
        id
        name
        notes
        districts
        activeProjectCount
        trades
        location {
          display
          streetAndNumber
          streetDetail
          city
          stateOrProvince
          zipOrPostalCode
          country
          cityAndStateDisplay
        }
        contactInformation {
          website
          phoneNumbers {
            number
            display
            extension
            type
          }
          primaryPhone {
            number
            display
            extension
            type
          }
          secondaryPhone {
            number
            display
            extension
            type
          }
          faxPhone {
            number
            display
            extension
            type
          }
          emailAddress {
            email
            type
          }
        }
      }
    }
  }
`;

export const COMPANY_SEARCH = gql`
  query CompanySearch(
    $filter: CompanysFilterInput
    $first: Int!
    $order: [CompanysOrderInput!]
    $search: String
    $skip: Int
  ) {
    companySearch(
      filter: $filter
      first: $first
      order: $order
      search: $search
      skip: $skip
    ) {
      count
      results {
        document {
          id
          name
          regions
          trades
          isActive
          city
          stateOrProvince
        }
      }
    }
  }
`;

export const GET_ALL_COMPANIES = gql`
  query GetAllCompanies {
    companies {
      id
      name
      notes
      districts
      activeProjectCount
      trades
      location {
        display
        streetAndNumber
        streetDetail
        city
        stateOrProvince
        zipOrPostalCode
        country
        cityAndStateDisplay
      }
      contactInformation {
        website
        phoneNumbers {
          number
          display
          extension
          type
        }
        primaryPhone {
          number
          display
          extension
          type
        }
        secondaryPhone {
          number
          display
          extension
          type
        }
        faxPhone {
          number
          display
          extension
          type
        }
        emailAddress {
          email
          type
        }
      }
    }
  }
`;

export const GET_COMPANY = gql`
  query GetSingleCompany($id: ID!) {
    company(id: $id) {
      id
      name
      notes
      districts
      activeProjectCount
      trades
      location {
        display
        streetAndNumber
        streetDetail
        city
        stateOrProvince
        zipOrPostalCode
        country
        cityAndStateDisplay
      }
      contactInformation {
        website
        phoneNumbers {
          number
          display
          extension
          type
        }
        primaryPhone {
          number
          display
          extension
          type
        }
        secondaryPhone {
          number
          display
          extension
          type
        }
        faxPhone {
          number
          display
          extension
          type
        }
        emailAddress {
          email
          type
        }
      }
      tradePartners {
        id
        name
        notes
        trades
        isActive
        workerHoursMonthToDate
        workerHoursYearToDate
        workerHoursTotalToDate
        project {
          id
          name
          number
          description
          districtNumber
          isActive
          notes
          location {
            city
            stateOrProvince
            cityAndStateDisplay
          }
        }
      }
    }
  }
`;

// Mutations
export const CREATE_COMPANY = gql`
  mutation CreateCompany(
    $transactionKey: String!
    $name: String!
    $notes: String
    $location: LocationCreateInput!
    $contactInformation: ContactInformationCreateInput
    $contacts: [ContactCreateInput!]!
  ) {
    createCompany(
      input: {
        transactionKey: $transactionKey
        name: $name
        notes: $notes
        location: $location
        contactInformation: $contactInformation
        contacts: $contacts
      }
    ) {
      id
      name
      notes
      location {
        display
        streetAndNumber
        streetDetail
        city
        stateOrProvince
        zipOrPostalCode
        country
        cityAndStateDisplay
      }
      contactInformation {
        website
        phoneNumbers {
          number
          display
          extension
          type
        }
        primaryPhone {
          number
          display
          extension
          type
        }
        secondaryPhone {
          number
          display
          extension
          type
        }
        faxPhone {
          number
          display
          extension
          type
        }
        emailAddress {
          email
          type
        }
      }
      contacts {
        id
        name
        title
        relationship
        contactInformation {
          website
          primaryPhone {
            number
            display
            extension
            type
          }
          secondaryPhone {
            number
            display
            extension
            type
          }
          emailAddress {
            email
            type
          }
        }
        location {
          display
          streetAndNumber
          streetDetail
          city
          stateOrProvince
          zipOrPostalCode
          country
          latitude
          longitude
          cityAndStateDisplay
        }
      }
      districts
      activeProjectCount
      trades
      tradePartners {
        id
        name
        notes
        trades
        isActive
        workerHoursMonthToDate
        workerHoursYearToDate
        workerHoursTotalToDate
        project {
          id
          name
          number
          description
          districtNumber
          isActive
          notes
        }
      }
    }
  }
`;

export const UPDATE_COMPANY = gql`
  mutation UpdateCompany(
    $id: ID!
    $name: String
    $notes: String
    $location: LocationUpdateInput
    $contactInformation: ContactInformationUpdateInput
    $contacts: [ContactCreateInput!]
  ) {
    updateCompany(
      input: {
        id: $id
        name: $name
        notes: $notes
        location: $location
        contactInformation: $contactInformation
        contacts: $contacts
      }
    ) {
      id
      name
      notes
      districts
      activeProjectCount
      trades
      location {
        display
        streetAndNumber
        streetDetail
        city
        stateOrProvince
        zipOrPostalCode
        country
        cityAndStateDisplay
      }
      contactInformation {
        website
        phoneNumbers {
          number
          display
          extension
          type
        }
        primaryPhone {
          number
          display
          extension
          type
        }
        secondaryPhone {
          number
          display
          extension
          type
        }
        faxPhone {
          number
          display
          extension
          type
        }
        emailAddress {
          email
          type
        }
      }
      tradePartners {
        id
        name
        notes
        trades
        isActive
        workerHoursMonthToDate
        workerHoursYearToDate
        workerHoursTotalToDate
        project {
          id
          name
          number
          description
          districtNumber
          isActive
          notes
        }
      }
    }
  }
`;

export const DELETE_COMPANY = gql`
  mutation DeleteCompany($id: ID!) {
    deleteCompany(id: $id) {
      id
    }
  }
`;
